import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import IconButton from "../components/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVertOutlined";
import Menu from '@material-ui/core/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {styles} from "../components/styles";
import {ListItemSecondaryAction} from "@material-ui/core";
import {CloseOutlined} from "@material-ui/icons";

function MenuListComposition({menuDatatest, items, buttonIcon, buttonSize, buttonStyle, dense=false, theme, itemsProvider, buttonRenderer, withClose, itemIconStyle, anchorOrigin, transformOrigin, disableCloseOnClick}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
        event.preventDefault() ;
        event.stopPropagation();
    };

    const handleClose = (event) => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    return (<>{
            (items || itemsProvider) &&
            <>
                {
                    buttonRenderer ? buttonRenderer(handleClick) :
                    <IconButton style={buttonStyle} onClick={handleClick} size={(buttonSize ? buttonSize : 'small')}>
                        {buttonIcon ? buttonIcon : <MoreVertIcon/>}
                    </IconButton>
                }
                {
                    anchorEl &&
                    <Menu
                        dense={true}
                        datatest={menuDatatest}
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        anchorOrigin={anchorOrigin}
                        transformOrigin={transformOrigin}
                    >
                        {
                            withClose &&
                            <div style={{display: 'flex'}}>
                                <div style={{flexGrow: '1'}}/>
                                <IconButton
                                    datatest={'closeButton'}
                                    style={{marginRight: '8px'}}
                                    size={'small'}
                                    onClick={handleClose}
                                    onMouseDown={handleClose}
                                ><CloseOutlined></CloseOutlined></IconButton>
                            </div>
                        }
                        {
                            itemsProvider && itemsProvider()
                        }
                        {
                            items && items.map((m, i) => <MenuItem datatest={m.label}
                                                                   disabled={m.disabled === true ? true : false}
                                                                   style={(items.length - 1 !== i) ? {borderBottom: '1px solid #F5F5F5'} : {}}
                                                                   key={m.label + i}

                                                                   onMouseDown={(ev) => {
                                                                       if (m.onMouseDown) {
                                                                           ev.stopPropagation();
                                                                           m.onMouseDown();
                                                                           handleClose(ev);
                                                                       }
                                                                   }}
                                                                   onClick={(ev) => {
                                                                       if (m.onMouseDown === undefined) {
                                                                           ev.stopPropagation();
                                                                           m.onClick(ev);
                                                                           if (!disableCloseOnClick) {
                                                                               handleClose(ev);
                                                                           }
                                                                       }
                                                                   }}>
                                {m.icon && <ListItemIcon style={itemIconStyle}>{m.icon}</ListItemIcon>}
                                <ListItemText>{m.label}</ListItemText>
                                {m.secondaryActionButton &&
                                    <ListItemSecondaryAction>{m.secondaryActionButton()}</ListItemSecondaryAction>}
                            </MenuItem>)
                        }

                    </Menu>
                }
            </>
        }</>
    );
}

export default withStyles(styles, {withTheme: true})(MenuListComposition);
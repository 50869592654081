import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';
import H2Title from "../components/H2Title";

const styles = theme => ({
});

let DetailsTitle = props => {
    const {title, actions} = props;
    let width = 'md'
    return  <div datatest={'detailsTitle'} style={isWidthUp(width, props.width) ? {display : 'flex', minHeight: '24px'}: {minHeight: '24px'}}>
        {title && <H2Title datatest={'title'} title={title} style={{minHeight: '24px'}}/>}
        {isWidthUp(width, props.width) ? <div style={{flexGrow : 1}}></div> : <div/>}
        {
            actions && actions.map((a, i) => <div style={isWidthUp(width, props.width) ? {}: {display: 'inline'}} key={i}>{a}</div>)
        }
    </div>;
}

DetailsTitle.propTypes = {
    title: PropTypes.string,
    actions: PropTypes.any,
};

export default withWidth()(withStyles(styles, {withTheme: true})(DetailsTitle));

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';

import {Button, Grid, IconButton} from "@material-ui/core";
import TextField from "../components/TextField";
import AddIcon from "@material-ui/icons/AddOutlined";
import FieldContainer from "../components/FieldContainer";
import {
    ALIAS_SYS_ETAG,
    ALIAS_SYS_RESULTS,
    ALIAS_SYS_VARIABLE_TYPE_RESPONSE,
    API_MODE_HTTP_API,
    GRAPH,
    ID,
    TYPE
} from "../Constants";
import {styles} from "./styles";
import ErrorMessage from "../components/ErrorMessage";
import GlobalsContext from "../components/GlobalsContext";
import {centerVertically, generateEGDomainId} from "./util";
import {isString} from 'lodash';
import {GreyDeleteIcon} from "./GreyStyleIcon";

let jp = require('jsonpath');

export function evaluatePath(json, globals, name, path) {
    if(!json || !path) {
        return "";
    }
    try {
        let value = jp.value(json, path);
        return value;
    } catch (e) {
        return 'Invalid path.'
    }
}

export function createVariable(type, name, path, value, index) {
    let variable = {
        [ID] : generateEGDomainId('globalvariable'),
        [TYPE]: type,
        name: name,
        path: path,
        value: value,
        index : index
    }
    return variable;
}

export function deleteVariable(variables, id) {
    let index = variables.findIndex(vr => vr[ID] === id);
    if(index >= 0) {
        variables.splice(index, 1);
    }
    return variables;
}

class Variable extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        let {variable} = this.props
        this.state = {
            name: variable && variable.name  ? variable.name : '',
            path: variable && variable.path  ? variable.path : ''
        }
    }

    evaluate = () => {
        const globals = this.context
        let {path, name} = this.state
        let {json}= this.props
        let value = evaluatePath(json, globals, name, path);
        // if value is undefined then clear the preview text box
        return value === undefined ? "" : (isString(value) ? value : JSON.stringify(value) );
    }

    onChange = ({ target: { name, value } }) => {
        let {onChange, variable} = this.props
        this.setState({[name] : value})
        variable[name] = value
        onChange && onChange(variable)
    }

    render() {
        let {name, path} = this.state
        let {json, onDelete} = this.props
        return <>
            <Grid item xs={3}>
                <TextField
                    datatest={'textField-name'}
                    paperStyle={{padding: '0'}}
                    name={'name'}
                    value={name}
                    fullWidth={true}
                    onChange={this.onChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    datatest={'textField-path'}
                    paperStyle={{padding: '0'}}
                    name={'path'}
                    value={path}
                    fullWidth={true}
                    onChange={this.onChange}
                />
            </Grid>
            <Grid item xs={5}>
                <div style={{display:'flex', height: '100%'}}>
                    {
                        json
                            ? <div style={{flexGrow: '1'}}>
                                <TextField
                                    datatest={'textField-value'}
                                    paperStyle={{padding: '0'}}
                                    name={'value'}
                                    value={this.evaluate()}
                                    fullWidth={true}
                                    readOnly={true}
                                />
                            </div>
                            : centerVertically(<ErrorMessage  color={'primary'} error={'Send request to view value.'}/>)
                    }
                    {
                        centerVertically(<IconButton datatest={'deleteButton'} style={{marginLeft : '4px'}} size={"small"} onClick={onDelete}>
                            <GreyDeleteIcon fontSize="small"/>
                        </IconButton>)
                    }
                </div>
            </Grid>

        </>;
    }

}

Variable.propTypes = {
    variable: PropTypes.object,
    json: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
};


class VariablesBuilder extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
    }

    addVariable = () => {
        let {variables} = this.props
        let variable = createVariable(ALIAS_SYS_VARIABLE_TYPE_RESPONSE,'', '', undefined, variables.length)
        variables.push(variable);
        this.onVariableChange();
    }

    getResourcesFromResponse = (responseJSON) => {
        if(responseJSON) {
            if(responseJSON[ALIAS_SYS_RESULTS]) {
                return {
                    resources : responseJSON[ALIAS_SYS_RESULTS],
                    basePath : `$..${ALIAS_SYS_RESULTS}`
                };
            } else if (responseJSON[GRAPH]) {
                return {
                    resources: responseJSON[GRAPH],
                    basePath: `$..${GRAPH}`
                };
            }
        } else {
            return {};
        }
    }

    addAllETags = () => {
        const {responseJSON, variables} = this.props;
        let results = this.getResourcesFromResponse(responseJSON);
        if(results && results.resources && results.resources.length > 0) {
            results.resources.forEach((r, i) => {
                let path = `${results.basePath}[${i}].${ALIAS_SYS_ETAG}`
                let value = jp.value(responseJSON, path);
                let variable = createVariable(ALIAS_SYS_VARIABLE_TYPE_RESPONSE,undefined, path, value, i)
                variables.push(variable);
            })
            this.onVariableChange()
        }
    }

    onVariableChange = () => {
        const {onChange, variables} = this.props;
        onChange && onChange(variables)
        this.setState({})
    }

    render() {
        const {variables, responseJSON, apiMode} = this.props;
        let results = this.getResourcesFromResponse(responseJSON);
        let hasResults = results && results.resources && results.resources.find(r => r[ALIAS_SYS_ETAG]);
        return <FieldContainer datatest={'variables'}>
            <Grid xs container spacing={1}>
                <Grid item xs={3}>Variable Name</Grid>
                <Grid item xs={4}>JSON Path</Grid>
                <Grid item xs={5}>Preview Value</Grid>
                {
                    variables.filter(v => v[TYPE] === ALIAS_SYS_VARIABLE_TYPE_RESPONSE).map((v, i) => {
                        return <Variable
                            key={v[ID]}
                            onChange={(v) => this.onVariableChange()}
                            onDelete={() => {
                                deleteVariable(variables, v[ID])
                                this.onVariableChange()
                            }}
                            json={responseJSON}
                            variable={v}
                        />;
                    })
                }
            </Grid>
            <div style={{marginTop : '8px', display: 'flex'}}>
                <div style={{flexGrow : '1'}}></div>
                {apiMode === API_MODE_HTTP_API.value && <Button
                    datatest={'setFromETags'}
                    disabled={!hasResults}
                    onClick={this.addAllETags}
                    color={"secondary"}
                    variant={"outlined"}>Set ETags from Response</Button>}
                <Button
                    datatest={'addVariableButton'}
                    onClick={this.addVariable}
                    color={"secondary"}
                    variant={"contained"}
                    style={{
                        marginLeft: '8px'
                    }}
                    startIcon={<AddIcon/>}>Add</Button>
            </div>
        </FieldContainer>;
    }

}

VariablesBuilder.defaultProps = {
};

VariablesBuilder.propTypes = {
    responseJSON: PropTypes.object,
    response: PropTypes.object,
    variables: PropTypes.array,
    onChange: PropTypes.func,
    apiMode: PropTypes.string
};

export default withStyles(styles, {withTheme: true})(VariablesBuilder);

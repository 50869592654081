import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import GridContainer from "../../components/GridContainer";
import Grid from "@material-ui/core/Grid";
import H2Title from "../../components/H2Title";
import SearchCard from "./SearchCard";
import {STYLE_GRID_ITEM_SPACING} from "../../Constants";
import SearchFilter, {SEARCH_COMPONENT_BUILDER_MODE} from "../apiplayground/SearchFilter";
import {traceSearchBuilderUpdate} from "../../components/Trace";
import {getDefaultLanguage} from "./WorkspaceSettingsDialog";
import MainHeaderBar from "../../components/MainHeaderBar";

const COMPONENT = 'SearchSetup';

class SearchSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let {onChange} = this.props;
        onChange();
    }

    render() {
        let {settings, configurations, aliasesMap, aliasesToIRIMap, ontology, onChange, stepName} = this.props;
        let search = settings.searchRequestObject.search;
        return <GridContainer>
            <Grid item xs={12} style={{paddingTop: 0}}>
                <MainHeaderBar title={stepName}></MainHeaderBar>
            </Grid>
            <Grid item xs={12}>
                <div style={{display: 'flex'}}>
                    <div datatest={'searchCardPreview'} style={{minWidth: '320px', maxWidth: '320px'}}>
                        <H2Title>Search Card Preview</H2Title>
                        <div style={{marginTop : '8px'}}>
                            <SearchCard
                                aliasesMap={aliasesMap}
                                aliasesToIRIMap={aliasesToIRIMap}
                                ontology={ontology}
                                configurations={configurations}
                                searchRequestObject={settings.searchRequestObject}
                                onChange={onChange}
                                browseLanguage={getDefaultLanguage(settings)}
                            />
                        </div>

                    </div>
                    <div style={{flexGrow: '1', marginLeft: '16px', maxWidth: 'calc(100% - 336px)'}}>
                        <H2Title>Search Configuration</H2Title>
                        <Grid container spacing={STYLE_GRID_ITEM_SPACING}>
                            <Grid item xs={12}>
                                <SearchFilter
                                    browseLanguage={getDefaultLanguage(settings)}
                                    settings={settings}
                                    customizations={{mode: SEARCH_COMPONENT_BUILDER_MODE}}
                                    configurations={configurations}
                                    aliasesMap={aliasesMap}
                                    search={search}
                                    onChange={(search) => {
                                        traceSearchBuilderUpdate(() => {
                                            console.log(search)
                                        }, COMPONENT);
                                        onChange();
                                        this.setState({})
                                    }}
                                />

                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Grid>
        </GridContainer>;

    }
}

SearchSetup.propTypes = {
    configurations: PropTypes.object,
    aliasesMap: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    stepName: PropTypes.string,
    aliasesToIRIMap: PropTypes.object,
    ontology: PropTypes.any,

};

export default withStyles(styles, {withTheme: true})(SearchSetup);

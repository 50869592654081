import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {traceRenderStart} from "../../components/Trace";
import {Grid, Typography} from "@material-ui/core";
import TextField from "../../components/TextField";
import {VALIDATION_DESCRIPTION_LENGTH, VALIDATION_LANGUAGE_NAME_LENGTH} from "../../Constants";
import {
    getMaxLengthMessage,
    getRequiredFieldMessage,
    isValidMaxLengthWithoutTrim,
    restrictMaximumCharacters
} from "../../components/util";
import H2Title from "../../components/H2Title";
import {isSuperadmin} from "../common/Profile";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const COMPONENT = 'AddDialog'
class AddDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name || '',
            description: props.description || ''
        }
    }

    shouldCreateBeDisabled = () => {
        let {name, nameError, descriptionError} = this.state
        if(!name || nameError || descriptionError) {
            return true;
        }
        return false;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    render() {
        traceRenderStart('', COMPONENT)

        let {theme, addButtonTitle, handleCancel, handleOk, title, open, helpText} = this.props
        let {name, nameError, description, descriptionError, storeInBackend} = this.state
        return  <Dialog
            aria-labelledby="form-dialog-title"
            open={open}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={title}/></DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {helpText && <Grid item xs={12}>{helpText}</Grid>}
                    {
                        false && isSuperadmin() &&
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        size={"small"}
                                        checked={storeInBackend || false}
                                        value={true}
                                        onChange={(e) => {
                                            const {target: {checked}} = e
                                            this.setState({storeInBackend : checked})
                                        }}
                                        name="storageOption"
                                    ></Switch>
                                }
                                label={<Typography style={{color: theme.palette.primary.main}}>Store In Backend Database</Typography>}
                            />

                        </Grid>
                    }
                    <Grid item xs={12}>
                        <TextField
                            label={'Name'}
                            id='name'
                            name='name'
                            value={name}
                            error={nameError}
                            onChange={(event) => {
                                this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_LANGUAGE_NAME_LENGTH))
                                const {target: {value}} = event;
                                if (!value || value.trim() === '') {
                                    this.setState({nameError: getRequiredFieldMessage('Name')})
                                } else if (!isValidMaxLengthWithoutTrim(value, VALIDATION_LANGUAGE_NAME_LENGTH)) {
                                    this.setState({nameError: getMaxLengthMessage(VALIDATION_LANGUAGE_NAME_LENGTH)})
                                } else {
                                    this.setState({nameError: ''})
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={'Description'}
                            id='description'
                            name='description'
                            value={description}
                            error={descriptionError}
                            multiline={true}
                            rowsMax="4"
                            rows="4"
                            onChange={(event) => {
                                this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_DESCRIPTION_LENGTH))
                                const {target: {value}} = event;
                                if (!isValidMaxLengthWithoutTrim(value, VALIDATION_DESCRIPTION_LENGTH)) {
                                    this.setState({descriptionError: getMaxLengthMessage(VALIDATION_DESCRIPTION_LENGTH)})
                                } else {
                                    this.setState({descriptionError: ''})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button datatest={'cancelButton'} onClick={handleCancel} variant={"outlined"} color="secondary">Cancel</Button>
                <Button
                    datatest={'addButton'}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => handleOk({title: name, description: description, storeInBackend : storeInBackend})}
                >{addButtonTitle || 'Add'}</Button>
            </DialogActions>
        </Dialog>;
    }
}

AddDialog.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    addButtonTitle: PropTypes.string,
    helpText: PropTypes.string,
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AddDialog);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import './APIPlayground.css';
import RequestResponse from "../../components/RequestResponse";
import {traceRequestRenderStart} from "../../components/Trace";
import {addGraphQLPayload, getForm} from "../../layouts/apiplayground/CreateRequest";
import {createHTTPRequestPayload} from "../../components/util";
import {
    ACTIVITY_CONTEXT,
    ALIAS_SYS_POST,
    ASYNC,
    GRAPH,
    HTTP_HEADER_PREFER,
    STYLE_GRID_ITEM_SPACING
} from "../../Constants";
import FieldContainer from "../../components/FieldContainer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import H4Title from "../../components/H4Title";
import {TextField as OtherTextField} from "@material-ui/core";


export function updateAsync(createRequestObject) {
    if(createRequestObject[ASYNC] === true) {
        let activityContext = createRequestObject[ACTIVITY_CONTEXT]
            ? `;activityContext=${createRequestObject[ACTIVITY_CONTEXT]}`
            : "";
        createRequestObject.headerMap = {
            [HTTP_HEADER_PREFER] : `respond-async${activityContext}`
        }
    } else if(createRequestObject.headerMap) {
        delete createRequestObject.headerMap[HTTP_HEADER_PREFER]
    }
}

export function addPayloads(createRequestObject, aliasesMap) {
    let shapeForms = createRequestObject.build
    addGraphQLPayload(createRequestObject, aliasesMap)
    if(!createRequestObject.hasManualHttpRequestBodyEdit) {
        let payload = createHTTPRequestPayload(shapeForms, aliasesMap)
        let body = JSON.parse(createRequestObject.body)
        body[ALIAS_SYS_POST] = payload[GRAPH]
        createRequestObject.body = JSON.stringify(body, null, 4)
    }
    updateAsync(createRequestObject)
}

const COMPONENT = "BulkRequest";
class BatchRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsePostBuilder : false
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
    }

    updateCreateRequestObject = () => {
        let {aliasesMap, requestObject} = this.props
        addPayloads(requestObject, aliasesMap)
        this.setDirty()
    }

    setDirty = () => {
        let {requestObject} = this.props
        if(!requestObject.dirty) {
            requestObject.dirty = true
            this.setState({})
        }
    }

    saveCreate = () => {
        let {onSave, requestObject} = this.props
        this.updateCreateRequestObject()
        onSave(requestObject)
    }

    onFormChange = (shapeForms) => {
        let {requestObject} = this.props
        requestObject.build = shapeForms
        this.updateCreateRequestObject()
    }

    getForm = () => {
        let {classes, theme, requestObject} = this.props
        let {collapsePostBuilder} = this.state
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.largeWidthPropertyLabelRoot
                }
            }
            : {};

        return <FieldContainer style={{backgroundColor: theme.palette.white.main}}>
            <FieldContainer style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>
                <H4Title style={{marginBottom: theme.spacing(1)}} title={'Async'}/>
                    <FormControlLabel
                        style={{marginLeft : '0px', marginBottom: theme.spacing(1)}}
                        control={
                            <Switch
                                key={'node-action-1'}
                                size="small"
                                checked={requestObject[ASYNC] || false}
                                value={requestObject[ASYNC]}
                                name="etagAll"
                                onChange={(value) => {
                                    requestObject[ASYNC] = !requestObject[ASYNC];
                                    this.onRequestObjectChange(requestObject);
                                }}
                            ></Switch>
                        }
                        label="Async Request"
                    />
                    {
                        requestObject[ASYNC] === true &&
                        <OtherTextField
                            label={'Activity Context'}
                            margin={"dense"}
                            variant="outlined"
                            fullWidth
                            value={requestObject[ACTIVITY_CONTEXT]}
                            onChange={(event) => {
                                requestObject[ACTIVITY_CONTEXT] = event.target.value;
                                this.onRequestObjectChange(requestObject);
                            }}
                            InputLabelProps={inputLabelProps}
                        />
                    }
            </FieldContainer>
            <FieldContainer>
                <H4Title style={{marginBottom: theme.spacing(1)}} title={'Builder for Resources to Create'}/>
                <div style={(collapsePostBuilder === true ? {display: 'none'} : {})}>
                {
                    getForm(
                        theme,
                        requestObject,
                        this.onFormChange,
                        () => {
                            this.updateCreateRequestObject()
                            this.setState({})
                        }
                    )
                }
                </div>
            </FieldContainer>
        </FieldContainer>;
    }

    onModeChange = (obj) => {
        let {onRequestObjectChange} = this.props
        this.setDirty();
        //update state to ensure that components are re-rendered for new mode
        this.setState({}, () => (onRequestObjectChange(obj)))
    }

    onRequestObjectChange = (obj) => {
        updateAsync(obj)
        this.props.onRequestObjectChange(obj)
        this.setState({})
    }

    getRequestResponse = () => {
        let {customizations, requestObject, registerSendAction} = this.props
        return <RequestResponse
            reqTabValue={0}
            requestBodyBuilder={this.getForm}
            requestObject={requestObject}
            onRequestObjectChange={this.onRequestObjectChange}
            onRequestSave={this.saveCreate}
            onModeChange={this.onModeChange}
            registerSendAction={registerSendAction}
            customizations={customizations}
        />;
    }

    render() {
        let {requestObject} = this.props
        traceRequestRenderStart(requestObject, COMPONENT)
        return (<>
            {this.getRequestResponse()}
        </>);
    }

}

BatchRequest.propTypes = {
    onSave: PropTypes.func,
    registerSendAction: PropTypes.func,
    exampleSetId: PropTypes.string,
    apiMode: PropTypes.object,
    aliasesMap: PropTypes.object,
    requestObject: PropTypes.object,
    onRequestObjectChange: PropTypes.func,
    customizations: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(BatchRequest);

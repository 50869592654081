import React, {Component, useEffect, useState} from "react";
import {
    Badge,
    Checkbox,
    Dialog,
    DialogContent,
    Grow,
    IconButton as MUIIconButton,
    ListItem,
    makeStyles,
    MuiThemeProvider,
    Paper,
    Popper,
    styled,
    TextField,
    Typography
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import PropTypes from "prop-types";
import {
    attachToWindowForTesting,
    centerVertically,
    flatten,
    getPropertyName,
    getResourceId,
    getSearchResult,
    handleBackendError,
    isEmptyArray,
    isObjectPropertyOrRDFConnectionProperty,
    isRequestSuccessful,
    removeEmpty,
    sort,
    toArray
} from "../../components/util";
import {
    ALIAS_MANAGEMENT_FOR_APPLICATION,
    ALIAS_MANAGEMENT_LINKED_TO,
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_MANAGEMENT_TYPE_CONFIGURATION,
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    AT_GRAPH,
    AT_TYPE,
    DATA,
    EASYGRAPH_DATA_APPLICATION_EXPLORER_GRAPH_VIEW,
    HTTP_HEADER_PREFER,
    ID,
    MIXIN,
    PATHS,
    TYPE
} from "../../Constants";
import MoreVertIcon from "@material-ui/icons/MoreVertOutlined";
import StyleOutlined from "@material-ui/icons/StyleOutlined";
import {traceComponentDidUpdateStart, traceRenderStart} from "../../components/Trace";
import cytoscape from "cytoscape";
import Tooltip from "@material-ui/core/Tooltip";
import CloseIcon from "@material-ui/icons/Close";
import queryString from "query-string";
import ReactDOM from "react-dom";
import {
    adjustMinWidth,
    computeLabelAndWidth,
    getDiagramLayout,
    getDiagramStyle,
    registerExtension
} from "../navigator/navigator-cytoscape-util";
import history from "../../history";
import uuid4 from "uuid/v4";
import {uniq} from "lodash";
import {searchLabelRecursively} from "../../components/TreeViewForIdSetup";
import CustomAliasTreeLabel from "../modelbuilder/CustomAliasTreeLabel";
import qs from "qs";
import GraphViewNodeTooltip, {
    DIRECTION_OPTION_INCOMING,
    DIRECTION_OPTIONS,
    getAllConnectedResources,
    getOutgoingProperties,
    isAllConnections,
    isIncomingOnly
} from "./GraphViewNodeTooltip";
import AccountTreeIcon from "@material-ui/icons/AccountTree";

import dagre from 'cytoscape-dagre';
import coseBilkent from "cytoscape-cose-bilkent";
import fcose from "cytoscape-fcose";
import popper from "cytoscape-popper";
import {getLabelProperties, getMostRelevantGraphViewSettings, getValuesObject} from "./SearchResultItem";
import Tree, {isAnyValueIdObject} from "./Tree";
import FieldContainer from "../../components/FieldContainer";
import {getHeader} from "./ResultAccordion";
import {
    ArrowBackOutlined,
    ArrowDownwardOutlined,
    ArrowForwardOutlined,
    CancelPresentation,
    CenterFocusStrongOutlined,
    DeleteOutlined,
    DoubleArrowOutlined,
    MenuOpenOutlined,
    MenuOutlined,
    NotInterestedOutlined,
    OpenWithOutlined,
    PhotoCameraOutlined,
    SaveOutlined,
    SettingsBackupRestoreOutlined,
    SettingsOutlined,
    TuneOutlined,
    ZoomInOutlined,
    ZoomOutOutlined
} from "@material-ui/icons";
import GraphViewSettings, {
    LAYOUT_COSE_BILKENT,
    LAYOUT_DAGREE,
    LAYOUT_DAGREE_LR,
    LAYOUT_FCOSE
} from "./GraphViewSettings";
import RefreshIcon from "@material-ui/icons/RefreshOutlined";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import {fade} from "@material-ui/core/styles/colorManipulator";
import fileDownload from "js-file-download";
import {
    isResourceDeleteEvent,
    isResourceRefreshEvent,
    isResourceUpdateEvent,
    loadResourceAndGet,
    withEvent
} from "./Event";
import {
    getUiLabelTranslation,
    UI_LABELS_DELETE_RESOURCE,
    UI_LABELS_EXPAND,
    UI_LABELS_EXPAND_INCOMING_CONNECTIONS,
    UI_LABELS_EXPAND_OUTGOING_CONNECTIONS,
    UI_LABELS_HIDE_THIS_EDGE,
    UI_LABELS_MORE, UI_LABELS_OPEN_NODE_SETTINGS,
    UI_LABELS_OPEN_STYLE_SETTINGS
} from "./UILabel";
import {deleteResource} from "./WithObjectSummary";
import CircularProgress from "@material-ui/core/CircularProgress";
import {withPermissions} from "../../service/permission-service";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import {searchIncomingConnectionResources} from "../../service/sparql-queries";
import {
    deleteManagementGraph,
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    patchManagementGraph,
    postManagementGraph
} from "../../service/graph-api";
import {getNewIdForWorkspace} from "./PagesSetup";
import SavedGraph from "./SavedGraph";
import {BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION} from "../../service/backend-paths";
import {value} from "lodash/seq";
import {isSuperadmin} from "../common/Profile";
import {setLinkedToId} from "./Workspaces";
import SaveDialogWithTitle, {ACTION_UPDATE} from "./SaveDialogWithTitle";
import GraphViewStyleSettings, {GRAPH_VIEW_BACKGROUND_COLOR} from "./GraphViewStyleSettings";

import {GRAPH_VIEW_SETTINGS,} from "./DataViewSetup";
import cloneDeep from "lodash/cloneDeep";
import tidytree from "cytoscape-tidytree";
import GraphViewLayoutSettings, {getLayoutOptionsForView} from "./GraphViewLayoutSettings";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {navigateTo} from "./Workspace";
import GraphViewNodeSettings, {
    getOnClickAction,
    getOnClickActionValue,
    getOnExpandButtonClick,
    getOnExpandButtonClickAction,
    hideIncomingConnections,
    hideMoreButton,
    hideOutgoingConnections,
    hideStyleButton,
    ON_CLICK_VALUE_OPEN_PAGE,
    ON_CLICK_VALUE_OPEN_VISUALISATION,
    showExpandButton
} from "./GraphViewNodeSettings";
import ButtonGroup from "@material-ui/core/ButtonGroup";

registerExtension(cytoscape, coseBilkent, LAYOUT_COSE_BILKENT);
registerExtension(cytoscape, fcose, LAYOUT_FCOSE);
registerExtension(cytoscape, popper, 'popper');
registerExtension(cytoscape, dagre, LAYOUT_DAGREE);
cytoscape.use(tidytree);


const COMPONENT = 'GraphView'


const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 0,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '0 2px',
        backgroundColor : 'unset',
        color : theme.palette.text.primary
    },
}));

export function graphViewLogo(theme, settings, onClick) {
    let logoContent  = !settings['logo']?.whiteBackgroundImageURL
        ? <HomeRoundedIcon onClick={onClick} datatest={'CancelPresentation'} fontSize={'large'}
                           style={{height: '24px', color: theme.palette.secondary.main }}/>
        : <img onClick={onClick} datatest={'CancelPresentation'} src={settings['logo']?.whiteBackgroundImageURL || settings['logo']?.imageURL} alt="Logo" style={{height: '24px'}}/>
    return centerVertically(
        logoContent
        ,{cursor : 'pointer', marginLeft : '0px', marginRight : '8px'}
    );
}

function SimplePopper({settings, graphNodeId, theme, style, onRender, options, onObjectToggle, onSettingsButtonClick, onExpandButtonClick, onStyleButtonClick, graphData, configurations, aliasesToIRIMap, ontology, languageCode, graphStyle}) {
    const classes = useStyles();
    const [popperRef, setPopperRef] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [incoming, setIncoming] = React.useState([]);

    useEffect(() => {
        const loadData = async () => {
            let r = await searchIncomingConnectionResources(graphNodeId, [], 100);
            if (isEmptyArray(toArray(r)) === false) {
                setIncoming(r);
            }
        };
        loadData().catch(e => {});
    }, [ graphNodeId]);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    /*
    Note : With below option set to true other components e.g. TextField can be added
     without this option set to true typing in the TextField does no work
    */
    const disablePortal = true;

    const {deltaW4Scale, bb, zoom} = options;

    const z1= zoom;
    const transformTmp =
        options.node?.data()?.thumbnail ?
            `translate(${(bb.w/1.3) - (deltaW4Scale/2 + (5 * z1))  }px, -${(20 * (1 - z1))+ bb.h - (bb.h/3) + deltaW4Scale }px) scale(${z1})`
        : `translate(${(bb.w/2) - (deltaW4Scale/2 + (5 * z1))  }px, -${(20 * (1 - z1))+ bb.h - 4 + deltaW4Scale }px) scale(${z1})`;


    let styleIn = {
        maxHeight : '28px',
        transform : transformTmp,
        marginRight: '0px'
    }
    let hiddenCount = [
            hideIncomingConnections(graphStyle, graphNodeId),
            hideOutgoingConnections(graphStyle, id),
            hideStyleButton(graphStyle, id)
        ].filter(v => v === true).length;

    if(hiddenCount > 0) {
        styleIn.marginLeft = `-${hiddenCount * (32 * zoom)}px`
    }
    //console.log(styleIn);

    let valueObject = graphData[graphNodeId];
    let objectProperties = getOutgoingProperties(graphData, graphNodeId, configurations, aliasesToIRIMap, ontology, languageCode);
    let allConnectedResources = getAllConnectedResources(objectProperties, valueObject, aliasesToIRIMap);


    const popperRefWidth = '80px';
    return (
        <div>
                <div  style={{display: 'flex', flexDirection: 'column', ...styleIn}}>
                    {anchorEl ?  <div>
                            <div style={{ width : popperRefWidth, height : '39px'}}></div>
                            <div style={{ width : popperRefWidth, height : '2px'}}  ref={(r) => setPopperRef(r)} ></div>
                            <div style={{ width : popperRefWidth, height : '39px'}}></div>
                        </div>
                     : <>
                            {   hideIncomingConnections(graphStyle, graphNodeId)? <div style={{height :'32px',minHeight :'32px', width :'100%'}}></div> :
                                <MUIIconButton
                                    disabled={isEmptyArray(incoming)}
                                    datatest={'incomingConnectionsButton'}
                                    style={{
                                        marginRight: '1px',
                                        marginBottom: '0px',
                                        color: isEmptyArray(incoming) ? theme.palette.grey : theme.palette.secondary.main,
                                    }}
                                    size={'small'}
                                    onClick={() => {
                                        searchIncomingConnectionResources(graphNodeId, [], 99).then(resources => {
                                            onObjectToggle(resources, true, {
                                                sourceGraphNodeId: graphNodeId,
                                                connectionDirection: DIRECTION_OPTIONS.find(op => op.label === DIRECTION_OPTION_INCOMING)
                                            });
                                        })
                                    }}
                                >
                                    <Tooltip placement={'top'}
                                             title={getUiLabelTranslation(settings, UI_LABELS_EXPAND_INCOMING_CONNECTIONS, languageCode, UI_LABELS_EXPAND_INCOMING_CONNECTIONS)}>
                                        <Badge
                                            badgeContent={incoming.length}
                                            max={99}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <ArrowDownwardOutlined/>
                                        </Badge>
                                    </Tooltip>
                                </MUIIconButton>
                            }


                        <div style={{}}>
                            {
                                hideMoreButton(graphStyle, graphNodeId) ? <div></div> :
                                <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_MORE, languageCode, UI_LABELS_MORE)}>
                                    <MUIIconButton
                                        datatest={'moreOptionsButton'}
                                        style={{
                                            marginRight: '1px',
                                            marginBottom: '0px',
                                            backgroundColor: theme.palette.secondary.main,
                                            color: theme.palette.white.main,
                                            padding: '4px'
                                        }}
                                        size={'small'}
                                        onClick={handleClick}
                                    >
                                        <MoreVertIcon/>
                                    </MUIIconButton>
                                </Tooltip>
                            }
                            {
                                hideStyleButton(graphStyle, graphNodeId) ? <></> :
                                <Tooltip
                                    title={getUiLabelTranslation(settings, UI_LABELS_OPEN_STYLE_SETTINGS, languageCode, UI_LABELS_OPEN_STYLE_SETTINGS)}>
                                    <MUIIconButton
                                        datatest={'styleButton'}
                                        style={{
                                            marginRight: '1px',
                                            marginBottom: '0px',
                                            backgroundColor: theme.palette.secondary.main,
                                            color: theme.palette.white.main,
                                            padding: '4px'
                                        }}
                                        size={'small'}
                                        onClick={() => onStyleButtonClick({elementId: graphNodeId})}
                                    >
                                        <StyleOutlined/>
                                    </MUIIconButton>
                                </Tooltip>
                            }
                            {
                                showExpandButton(graphStyle, graphNodeId) ?
                                <Tooltip
                                    title={getUiLabelTranslation(settings, UI_LABELS_EXPAND, languageCode, UI_LABELS_EXPAND)}>
                                    <MUIIconButton
                                        datatest={'focusButton'}
                                        style={{
                                            marginLeft: hideStyleButton(graphStyle, graphNodeId) && hideMoreButton(graphStyle, graphNodeId)  && '-8px',
                                            marginRight: '1px',
                                            marginBottom: '0px',
                                            backgroundColor: theme.palette.secondary.main,
                                            color: theme.palette.white.main,
                                            padding: '4px'
                                        }}
                                        size={'small'}
                                        onClick={() => onExpandButtonClick({graphNodeId: graphNodeId})}
                                    >
                                        <CenterFocusStrongOutlined/>
                                    </MUIIconButton>
                                </Tooltip> : <></>
                            }
                            {
                                isSuperadmin() ?
                                <Tooltip
                                    title={getUiLabelTranslation(settings, UI_LABELS_OPEN_NODE_SETTINGS, languageCode, UI_LABELS_OPEN_NODE_SETTINGS)}>
                                    <MUIIconButton
                                        datatest={'nodeSettingsButton'}
                                        style={{
                                            marginLeft: hideStyleButton(graphStyle, graphNodeId) && hideMoreButton(graphStyle, graphNodeId)  && '-8px',
                                            marginRight: '1px',
                                            marginBottom: '0px',
                                            backgroundColor: theme.palette.secondary.main,
                                            color: theme.palette.white.main,
                                            padding: '4px'
                                        }}
                                        size={'small'}
                                        onClick={() => onSettingsButtonClick({elementId: graphNodeId})}
                                    >
                                        <SettingsOutlined/>
                                    </MUIIconButton>
                                </Tooltip>
                                    : <></>
                            }
                        </div>
                            {
                                hideOutgoingConnections(graphStyle, graphNodeId) ? <div style={{height :'32px', minHeight : '32px', width :'100%'}}></div> :
                                    <MUIIconButton
                                        disabled={isEmptyArray(allConnectedResources)}
                                        datatest={'outgoingConnectionsButton'}
                                        style={{
                                            marginRight: '1px',
                                            color: isEmptyArray(allConnectedResources) ? theme.palette.grey : theme.palette.secondary.main,
                                        }}
                                        size={'small'}
                                        onClick={() => {
                                            onObjectToggle(allConnectedResources, true, {
                                                sourceGraphNodeId: graphNodeId
                                            });

                                        }}
                                    >
                                        <Tooltip placement={'bottom'}
                                                 title={getUiLabelTranslation(settings, UI_LABELS_EXPAND_OUTGOING_CONNECTIONS, languageCode, UI_LABELS_EXPAND_OUTGOING_CONNECTIONS)}>
                                            <Badge
                                                badgeContent={allConnectedResources.length}
                                                max={99}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                <ArrowDownwardOutlined/>
                                            </Badge>
                                        </Tooltip>
                                    </MUIIconButton>
                            }
                    </>
                    }
                </div>

            <Popper disablePortal={disablePortal} style={{zIndex: '1600'}} id={id} open={open} anchorEl={popperRef} transition>
                {({ TransitionProps }) => (
                    <Grow {...TransitionProps} timeout={300}>
                <Paper datatest={'moreOptionsPaper'} elevation={3}>
                    {onRender()}
                </Paper>
                    </Grow>)}
            </Popper>
        </div>
    );
}





export function nullToIdObjects(expanded, ids) {
    let nonNullExpanded = expanded.map((ex, index) => {
        if (ex) {
            return ex;
        }
        return {[ID]: ids[index]};
    });
    return nonNullExpanded;
}

export function RenderEdgesPane({graphEdges, cytoscapeObj}) {
    let [refresh, setRefresh] = useState();

    let uniq = {};

    cytoscapeObj.on('remove', 'edge', function (evt) {
        console.log(evt);
        //setRefresh(uuid4());
    })

    cytoscapeObj.edges().forEach(ge => {
        const label = ge.data().label;
        let color = ge.style('color');
        let key = label;
        if(uniq[key] === undefined) {
            uniq[key] = [color];
        }
        if(!uniq[key].includes(color)) {
            uniq[key].push(color);
        }
    });

    return <List datatest={'edgesPane'} style={{height : 'calc(100% - 48px)', overflow : 'auto'}}>{
        Object.keys(uniq).sort().map((k, i) => {
            let colors = uniq[k];
            let edges = cytoscapeObj.edges(function (element, i) {
                let data = element.data();
                return data.label === k && element.visible();
            });
            let checked = edges.size() > 0;
            const textStyle = {
                backgroundColor : colors[0],
                backgroundSize : '100%',
                backgroundImage : `linear-gradient(to right, ${colors.join(", ")})`,
                backgroundClip : 'text',
                '-webkit-background-clip' : 'text',
                '-moz-background-clip' : 'text',
                webkitTextFillColor : 'transparent',
                '-moz-text-fill-color' : 'transparent',
            };
            return <ListItem key={k} role={undefined} dense>
                <ListItemIcon style={{minWidth : 'unset'}}>
                    <Checkbox
                        key={uuid4()}
                        size={'small'}
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': i }}
                        defaultChecked={checked}
                        onChange={(event) => {
                            let checked = event.target.checked;
                            cytoscapeObj.edges().filter(function (element, i) {
                                let data = element.data();
                                return data.label === k;
                            }).forEach(edg => {
                                if(checked) {
                                    edg.style('visibility', 'visible');
                                } else {
                                    edg.style('visibility', 'hidden');
                                }
                            });
                        }}
                    />
                </ListItemIcon>
                <ListItemText datatest={k} style={textStyle} id={i} primary={`${k}`} />
            </ListItem>
        })
    }</List>;
}

const NAME_VISUALISATION = 'Visualisation';

export async function getSavedVisualisations(workspace) {
    const searchParams = {
        [ALIAS_MANAGEMENT_NAME] : NAME_VISUALISATION
    }
    setLinkedToId(searchParams, workspace[ID]);
    let headerMap = {
        [HTTP_HEADER_PREFER] : `return=representation;${MIXIN}="{ ${ID} ${ALIAS_SYS_ETAG} ${TYPE} ${ALIAS_MANAGEMENT_TITLE} ${ALIAS_MANAGEMENT_NAME}}";${DATA}=${PATHS}`
    }

    return new Promise((resolve, reject) => {
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, searchParams, headerMap, false)
            .then(pageObjectsResults => {
                if(isRequestSuccessful(pageObjectsResults)) {
                    pageObjectsResults.json().then(json => {
                        let pageObjects = getSearchResult(json);
                        resolve(pageObjects);
                    })
                } else {
                    resolve([]);
                }
            })
            .catch(reject);
    })
}

export async function getSavedVisualisationsOptions(workspace) {
    let savedVisualisations = (await getSavedVisualisations(workspace)).map(r => ({backingObject : r, value : r[ID], label : r[ALIAS_MANAGEMENT_TITLE]}));
    sort(savedVisualisations, 'label');
    return savedVisualisations;
}

export async function getAllVisualisationsData(workspaceId) {
    const searchParams = {
        [ALIAS_MANAGEMENT_LINKED_TO] : workspaceId,
        [ALIAS_MANAGEMENT_NAME] : NAME_VISUALISATION

    }
    let headerMap = {
    }

    return new Promise((resolve, reject) => {
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, searchParams, headerMap, false)
            .then(pageObjectsResults => {
                if(isRequestSuccessful(pageObjectsResults)) {
                    pageObjectsResults.json().then(json => {
                        let pageObjects = getSearchResult(json);
                        resolve(pageObjects);
                    })
                } else {
                    resolve([]);
                }
            })
            .catch(reject);
    })
}

const ZINDEX_TOOLBAR = 1000;
const ZINDEX_NODE_MORE_INFO = ZINDEX_TOOLBAR - 1;
export const ZINDEX_NODE_DETAILS_FORM_DATA = ZINDEX_TOOLBAR + 1;

export const MAX_ZOOM_LEVEL = 1;
export const MIN_ZOOM_LEVEL = .1;
export const ZOOM_STEP = .03;

class GraphViewInternal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            edgeColors : {},
            nodeColors : {},
            open: props.open || false,
            expanded : [],
            graphNodes : [],
            graphEdges: [],
            graphData: {},
            selectedProperties: [],
            layoutOptions : getLayoutOptionsForView(LAYOUT_DAGREE_LR,this.adjustMinZoom),
            settingsMenuPaneOpen : props.settingsMenuPaneOpen || false,
            fullScreen : props.fullScreen
        }
        this.graphStyle = {}
    }

    componentDidMount() {
        let {registerForEvents, embedded, visualisation} = this.props;
        this.syncSavedVisualisations();
        registerForEvents(this.onUpdateEvent);
        if(embedded) {
            this.initGraph();
            if(visualisation) {
                this.setState({showSavedVisualisation : visualisation}, () => this.loadVisualisation(visualisation.value));
            } else {
                this.prepareDataForGraphView();
            }
        }
        //this.prepareDataForGraphView();
    }

    syncSavedVisualisations = async () => {
        let {workspace} = this.props;
        let savedVisualisations = await getSavedVisualisationsOptions(workspace);
        this.setState({
            savedVisualisations : savedVisualisations
        });
        return savedVisualisations;
    }

    getFullVisualisationResource = async (id) => {
        const workspaceSearchParams = {
            [ID] : id
        }
        let headerMap = {};
        const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, headerMap).catch(handleBackendError(this));
        if(!isRequestSuccessful(workspaceSearchResult)) {
            return Promise.reject(workspaceSearchResult);
        }
        let workspaceJSON = await workspaceSearchResult.json();
        let result = getSearchResult(workspaceJSON).find(obj => obj[ID] === id);
        return result;
    }

    loadVisualisation = async (id) => {
        if(this.cytoscapeObj === undefined) {
            return ;
        }
        let result = await this.getFullVisualisationResource(id);
        if(!result) {
            this.cytoscapeObj.removeListener('remove', 'edge');
            this.cytoscapeObj.remove(this.cytoscapeObj.edges());
            this.cytoscapeObj.remove(this.cytoscapeObj.nodes());
            this.graphStyle = {};
            this.setState({
                leftPane: false,
                formData : undefined,
                graphNodes :[],
                graphEdges : [],
                graphData : {},
                searchData : [],
                showSavedVisualisation : undefined,
            })
            return ;
        }
        let visualisationSettings = JSON.parse(result[ALIAS_MANAGEMENT_SETTINGS]);
        let visJSON = visualisationSettings.graphJSON;
        let layoutName = visualisationSettings.layoutName;
        this.graphStyle = visualisationSettings.graphStyle || {};
        this.globalGraphStyle = visualisationSettings.globalGraphStyle || {};
        let savedLayoutOptions = visualisationSettings.layoutOptions || {};
        let layoutOptions = getLayoutOptionsForView(layoutName, this.adjustMinZoom);
        Object.keys(savedLayoutOptions).forEach(k => {
            layoutOptions[k] = savedLayoutOptions[k];
        })
        //console.log(visJSON);
        let {style, ...rest} = visJSON;
        let {nodes, edges} = rest.elements;
        let nodesArray = toArray(nodes);
        let edgesArray = toArray(edges);

        //close left pane as there is listener for remove nodes
        this.setState({leftPane: false, formData : undefined}, () => {
            this.cytoscapeObj.removeListener('remove', 'edge');
            this.cytoscapeObj.remove(this.cytoscapeObj.edges());
            this.cytoscapeObj.remove(this.cytoscapeObj.nodes());
            let {graphData, graphEdges, graphNodes, expanded} = this.state;
            expanded = [];
            graphNodes = [];
            graphEdges =  [];
            graphData = {};
            let {onExpand} = this.props;
            let ids = nodesArray.map(n => n.data?.[ID]).filter(id => id);
            onExpand(ids).then(async expanded => {
                let nonNullExpanded = nullToIdObjects(expanded, ids);
                nonNullExpanded.forEach(vo => graphData[vo[ID]] = vo);
                let newGraphNodes = await this.getNodes(graphData, ids);
                let searchData = graphNodes.map(n => n.data);
                edgesArray.forEach(e => {
                    let existingData = e.data;
                    let {source, propertyKey, target} = existingData;
                    let newData= this.createEdgeDataNode(source, propertyKey, target);
                    graphEdges.push({
                        data : newData?.data || e.data,
                        classes : e.classes
                    })
                });
                this.setState({zoomLevel : rest.zoom,  leftPane: false, layoutName, layoutOptions, graphData, graphEdges, graphNodes: newGraphNodes , searchData}, () => {

                    this.cytoscapeObj.add(nodesArray.map(e => {
                        let {group, data, position, classes} = e;
                        let newData = newGraphNodes.find(nd => nd.data[ID] === data[ID]);
                        let oldBackgroundColor = e.data.backgroundColor;
                        const newDataObject = newData.data;
                        if(!newDataObject.backgroundColor) {
                            newDataObject.backgroundColor = oldBackgroundColor;
                        }
                        let node = {
                            classes,
                            group,
                            data : newDataObject,
                            position
                        }
                        return node;
                    }))

                    this.updateDiagram();
                    this.refreshStyle(layoutOptions);
                    this.cytoscapeObj.fit();
                    this.cytoscapeObj.center();
                    const maxZoom = savedLayoutOptions['_eg_maximumZoom'];
                    const minZoom = savedLayoutOptions['_eg_minimumZoom'];
                    if(maxZoom) {
                        this.cytoscapeObj.maxZoom(maxZoom);
                    }
                    if(minZoom) {
                        this.cytoscapeObj.maxZoom(minZoom);
                    }
                });
            })

        });
        return result;


    };

    saveVisualisation = (title, action) => {
        let {showSavedVisualisation} = this.state;
        let {graphStyle, globalGraphStyle} = this;
        let graphJSON = this.cytoscapeObj.json();
        let zoom = this.cytoscapeObj.zoom();
        const {workspace} = this.props;
        const uuid = uuid4();
        let newIdForWorkspace = getNewIdForWorkspace(uuid);
        let obj = {
            [ID]: newIdForWorkspace,
            [ALIAS_MANAGEMENT_NAME]: NAME_VISUALISATION,
            [ALIAS_MANAGEMENT_TITLE]: title
        }
        obj[AT_CONTEXT] = getManagementContextURL();
        obj[TYPE] = ALIAS_MANAGEMENT_TYPE_CONFIGURATION;
        obj[ALIAS_MANAGEMENT_FOR_APPLICATION] = EASYGRAPH_DATA_APPLICATION_EXPLORER_GRAPH_VIEW;
        setLinkedToId(obj, workspace[ID]);

        //obj[ALIAS_MANAGEMENT_IS_PART_OF] = workspace[ID];
        let layoutName = this.state.layoutName || LAYOUT_DAGREE_LR ;
        let clonedLayoutOptions = cloneDeep(this.state.layoutOptions || {}) ;
        let clearLayoutOptions = removeEmpty(clonedLayoutOptions) ;
        //clean up unused data
        delete graphJSON.style;
        let clonedGraphStyle = cloneDeep(graphStyle);
        let clearGraphStyle = removeEmpty(clonedGraphStyle);
        let clonedGlobalGraphStyle = cloneDeep(globalGraphStyle);
        let clearGlobalGraphStyle = removeEmpty(clonedGlobalGraphStyle);

        let data = {
            graphJSON,
            zoom,
            layoutName,
            graphStyle : clearGraphStyle,
            globalGraphStyle : clonedGlobalGraphStyle,
            layoutOptions : clearLayoutOptions
        }
        obj[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(data);

        if(action === ACTION_UPDATE) {
            return new Promise((resolve, reject) => {
                this.getFullVisualisationResource(showSavedVisualisation.value).then(resource => {
                    resource[AT_CONTEXT] = obj[AT_CONTEXT];
                    resource[ALIAS_MANAGEMENT_SETTINGS] = obj[ALIAS_MANAGEMENT_SETTINGS];
                    patchManagementGraph(JSON.stringify(resource)).then(d => {
                        if (isRequestSuccessful(d)) {
                            d.json().then(j => {
                                resolve(j);
                            })
                        } else {
                            reject(d);
                        }
                    }).catch(e => {
                        reject(e);
                    })
                });
            })
        } else {
            return new Promise((resolve, reject) => {
                postManagementGraph(JSON.stringify(obj)).then(d => {
                    if (isRequestSuccessful(d)) {
                        d.json().then(j => {
                            resolve(j);
                        })
                    } else {
                        reject(d);
                    }
                })
            })
        }

    }

    deleteVisualisation = (visualisationToDelete) => {
        //let {savedVisualisations} = this.state;
        //let visualisationToDelete = savedVisualisations.find(v => v[ID] === id);

        let backingObject = visualisationToDelete.backingObject;
        deleteManagementGraph({
            [AT_CONTEXT] : getManagementContextURL(),
            [AT_GRAPH] : [
                backingObject
            ]
        }).then(r => {
            if(isRequestSuccessful(r)) {
                this.syncSavedVisualisations().then(r => {
                    this.setState({loading: false, showSavedVisualisation : undefined});
                    this.resetVisualisation();
                });
            }
        });

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.initGraph();
    }

    getIdSuffix = () => {
        const {visualisation, containerId} = this.props;
        return containerId+'-'+visualisation.value;
    }

    getContainerId = () => {
        const {embedded} = this.props;
        if(embedded) {
            return 'cy-'+this.getIdSuffix();

        } else {
            return 'cy';
        }
    }

    getContainerPopperId = () => {
        const {embedded} = this.props;
        if(embedded) {
            return 'cy-popper-'+this.getIdSuffix();

        } else {
            return 'cy-popper';
        }

    }

    initGraph = () => {
        traceComponentDidUpdateStart(COMPONENT)

        const { theme, aliasesToIRIMap } = this.props;
        const {graphData, graphNodes, graphEdges, layoutOptions} = this.state;
        let cy = document.getElementById(this.getContainerId())

        function handleEdgeMouseOut(outerThis) {
            return function (evt) {
                let node = evt.target;
                //node.unselect();
                let elementById = document.getElementById(outerThis.getContainerPopperId());
                ReactDOM.render(<></>, elementById);
                node.removeClass('onMouseOverEdgeClass');
            };
        }

        function clearMouseOverFromAllElements(outerThis) {
            outerThis.cytoscapeObj.elements().forEach(el => {
                if(!el.selected()) {
                    el.removeClass('onMouseOverEdgeClass');
                    el.removeClass('onMouseOverClass');
                }
            })
        }

        function handleMouseOverEdge(outerThis, cyOuter) {
            return function (evt) {
                clearMouseOverFromAllElements(outerThis);

                let target = evt.target;
                let elementId = target.id();
                let popper = target.popper({
                    content: () => {
                        let elementById = document.getElementById(outerThis.getContainerPopperId());
                        let z1 = cyOuter.zoom();
                        const transform = `scale(${z1})`;
                        let style = {
                            maxHeight: '28px',
                            transform: transform,
                            marginRight: '0px'
                        }

                        let graphNodeId = target.data().target;
                        let parentClassID = target.data().source;

                        let paper = <MuiThemeProvider theme={theme}>
                            {outerThis.getEdgeActionButtons(outerThis.props.theme, elementId, style)}
                        </MuiThemeProvider>;
                        ReactDOM.render(paper, elementById);

                        return elementById;
                    },
                    popper: {
                        positionFixed: true,
                        onCreate() {
                            //console.log('Popper creare')
                        },
                        modifiers: {
                            flip: {
                                enabled: false
                            },
                            preventOverflow: {
                                escapeWithReference: true
                            }
                        }
                    },
                });

                let update = () => {
                    popper.scheduleUpdate();
                };

                target.on('position', update);
                cyOuter.on('pan zoom resize', update);
                target.addClass('onMouseOverEdgeClass');
            };
        }

        function handleEdgeClick(outerThis) {
            return function (evt) {
                let edge = evt.target;
                edge.select();
                let nodeID = edge.id();
                let propertyKey = edge.data().propertyKey;
                let propertyIRI = aliasesToIRIMap[propertyKey] || propertyKey;
                if (outerThis.isRightContainerUndefined()) {
                    outerThis.setState({showRightContainer: true});
                }
                outerThis.navigateTo({node: {graphNodeId: propertyIRI}});

            };
        }

        function handleNodeClick(outerThis) {
            return function (evt) {
                let {location} = outerThis.props;
                let node = evt.target;
                outerThis.unselectAll();
                node.select();
                let nodeID = node.id();
                if (outerThis.isRightContainerUndefined()) {
                    outerThis.setState({showRightContainer: true});
                    /*
                    // if selected node is under the right container then recenter
                    let x = node.renderedPosition().x;
                    let threshold = minimized === true ? 800 : 600;
                    if(x > threshold) {
                        outerThis.diagramSearch(nodeID);
                    }

                     */
                }
                outerThis.navigateTo({node: {graphNodeId: nodeID}});
            };
        }

        function handleNodeMouseOut(outerThis) {
            return function (evt) {
                let params = queryString.parse(outerThis.props.location.search)
                let node = evt.target;
                if (params.graphNodeId !== node.id()) {
                    node.removeClass('onMouseOverClass');
                }
                let elementById = document.getElementById(outerThis.getContainerPopperId());
                ReactDOM.render(<></>, elementById);
                cy.style.cursor = 'default';

            };
        }

        function handleZoom(outerThis) {
            return function (evt) {
                let elementById = document.getElementById(outerThis.getContainerPopperId());
                ReactDOM.render(<></>, elementById);
                const zoomValue = this.cytoscapeObj.zoom();
                const normalizedZoomValue = this.normalizeZoomValue(zoomValue);
                outerThis.setState({zoomLevel : normalizedZoomValue});
            };
        }

        function handleTap(outerThis) {
            return function (event) {
                var target = event.target;
                if(target === outerThis.cytoscapeObj) {
                    let elementById = document.getElementById(outerThis.getContainerPopperId());
                    ReactDOM.render(<></>, elementById);
                    outerThis.cytoscapeObj.elements().forEach(el => {
                        el.removeClass('onMouseOverEdgeClass');
                        el.removeClass('onMouseOverClass');
                    })
                }
            };
        }

        function handleNodeMouseOver(outerThis, cyOuter) {
            return function (evt) {
                clearMouseOverFromAllElements(outerThis);
                let node = evt.target;
                let nodeID = node.id();
                let popper = node.popper({
                    content: () => {
                        let elementById = document.getElementById(outerThis.getContainerPopperId());
                        let z1 = cyOuter.zoom();
                        const bb = node.renderedBoundingBox({includeLabels: false, includeOverlays: false});
                        let w = 30
                        const deltaW4Scale = w * z1;
                        const transform = `translate(${(bb.w / 2) - (deltaW4Scale / 2 + (5 * z1))}px, -${(20 * (1 - z1)) + bb.h - 6}px) scale(${z1})`;

                        let style = {
                            maxHeight: '28px',
                            transform: transform,
                            marginRight: '0px'
                        }
                        let options = {
                            bb,
                            zoom: z1,
                            deltaW4Scale,
                            node: node
                        }
                        // theme does not work so pass it in
                        let paper = <MuiThemeProvider theme={theme}>
                            {outerThis.getNodeMoreButton(nodeID, style, outerThis, options)}
                        </MuiThemeProvider>;
                        ReactDOM.render(paper, elementById);
                        return elementById;
                    },
                    popper: {
                        positionFixed: true,
                        onCreate() {
                            //console.log('Popper creare')
                        },
                        modifiers: {
                            flip: {
                                enabled: false
                            },
                            preventOverflow: {
                                escapeWithReference: true
                            }
                        }
                    }
                });

                let update = () => {
                    popper.scheduleUpdate();
                    //console.log(node.popper())
                };

                node.on('position', update);

                cyOuter.on('pan zoom resize', update);

                node.addClass('onMouseOverClass');
                cy.style.cursor = 'pointer';
            };
        }

        if (cy && (!cy.innerHTML || !this.cytoscapeObj)) {
            let outerThis = this

            const elements = {
                nodes: graphNodes,
                edges: graphEdges
            }

            const diagramStyle = this.getDiagramStyle(layoutOptions.name);
            this.cytoscapeObj = cytoscape({
                container: cy,
                boxSelectionEnabled: false,
                layout: this.getDiagramLayout({
                    animationDuration : 1

                }),
                style: diagramStyle,
                elements: elements
            });
            cytoscape.warnings(false);

            let cyOuter = this.cytoscapeObj;

            this.cytoscapeObj.on('mouseover onetap', 'node', handleNodeMouseOver(outerThis, cyOuter));
            this.cytoscapeObj.on('mouseout', 'node', handleNodeMouseOut(outerThis));
            this.cytoscapeObj.on('click dbltap', 'node', handleNodeClick(outerThis));
            this.cytoscapeObj.on('click dbltap', 'edge', handleEdgeClick(outerThis));
            this.cytoscapeObj.on('mouseover onetap', 'edge', handleMouseOverEdge(outerThis, cyOuter));
            this.cytoscapeObj.on('mouseout', 'edge', handleEdgeMouseOut(outerThis));
            this.cytoscapeObj.on('pinchzoom scrollzoom', handleZoom(outerThis));
            this.cytoscapeObj.on('tap', handleTap(outerThis));
            //addAdjustWidth(this.cytoscapeObj, outerThis);

            setTimeout(() => {
                this.refreshDiagramLayout();
            }, 1);


            //this enable zoom with pinch
            this.cytoscapeObj.userZoomingEnabled(false);
            //set amx zoom to 1 so that on single resource node it does not zoom too much
            //after loading the graph max zoom is adjusted in adjustMinZoom method
            this.cytoscapeObj.maxZoom(MAX_ZOOM_LEVEL);
            //this.cytoscapeObj.zoom(1);
            this.cytoscapeObj.minZoom(MIN_ZOOM_LEVEL);
            //this.cytoscapeObj.center();
            this.cytoscapeObj.styleEnabled()

            // select focus node
            let params = queryString.parse(this.props.location.search)
            let focusObject = params
                ? graphData[params.graphNodeId]
                : undefined;
            if(focusObject) {
                this.diagramSearch(focusObject[ID]);
            }

            attachToWindowForTesting('cytoscapeObj', this.cytoscapeObj);
        }

    }

    scheduleDiagramRefresh = () => {
        setTimeout(() => {
            let {layoutOptions} = this.state;
            this.refreshDiagramLayout(layoutOptions);
        }, 600)
    }

    refreshDiagramLayout = (layoutOptions = {}) => {
        if(this.cytoscapeObj) {
            if (!layoutOptions.stop) {
                layoutOptions.stop = () => {
                    this.setState({zoomLevel: this.cytoscapeObj.zoom()})
                }
            }
            let allNewNodes = this.cytoscapeObj.filter(function (element, i) {
                return true;
            });
            if (allNewNodes && allNewNodes.length > 0) {
                this.refreshStyle(layoutOptions);
                const diagramLayout = this.getDiagramLayout(layoutOptions);
                const layout = this.cytoscapeObj.layout(diagramLayout);
                try {
                    layout.run();
                } catch (e) {
                    console.log('Error', e);
                    this.setState({layoutError : 'This layout cannot be applied to the data in the graph.'})
                }
                //allNewNodes.layout(diagramLayout).run();
            }
        }
    }

    refreshStyle = (layoutOptions) => {
        const style = this.getDiagramStyle(layoutOptions.name);
        this.cytoscapeObj.style().clear().fromJson(style).update();
    }


    getEdgeActionButtons = (theme, elementId, style = {}) => {
        let {settings, languageCode} = this.props;
        return <div style={style}>
            <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_HIDE_THIS_EDGE, languageCode, UI_LABELS_HIDE_THIS_EDGE)}>
                <MUIIconButton
                    datatest={'removeEdgeButton'}
                    style={{
                        marginRight: '4px',
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.white.main,
                    }}
                    size={'small'}
                    onClick={(e) => {
                        this.closeOpenInfoBoxes();
                        let oldEdges = this.cytoscapeObj.filter(function (element, i) {
                            let isEdge = element.isEdge();
                            let data = element.data();
                            return isEdge && data.id === elementId;
                        });
                        this.cytoscapeObj.remove(oldEdges);

                        //Close popper
                        let elementById = document.getElementById(this.getContainerPopperId());
                        ReactDOM.render(<></>, elementById);

                    }}
                >
                    <NotInterestedOutlined/>
                </MUIIconButton>
            </Tooltip>
            <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_OPEN_STYLE_SETTINGS, languageCode, UI_LABELS_OPEN_STYLE_SETTINGS)}>
                <MUIIconButton
                    datatest={'openStyleSettingsForEdge'}
                    style={{
                        marginRight: '4px',
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.white.main,
                    }}
                    size={'small'}
                    onClick={(e) => {
                        this.openStyleSettings({elementId : elementId});
                    }}
                >
                    <StyleOutlined/>
                </MUIIconButton>
            </Tooltip>

        </div> ;
    }

    navigateTo = ({node}) => {
        this.onNodeClick({node})
    }

    setFromRequestParams = (key, node) => {
        let {location} = this.props;
        let params = queryString.parse(location.search)
        if(params[key]) {
            node[key] = params[key];
        }
    }

    pushToHistory = (params) => {
        let {location} = this.props;
        history.push(`${location.pathname}?${qs.stringify(params)}`);
    }

    unselectAll = () => {
        this.cytoscapeObj.filter(function (element, i) {
            element.removeClass('onMouseOverClass');
            return element;
        }).unselect();
    }

    diagramSearch = (nodeId, center = true) => {
        this.unselectAll();
        let elementNode = this.cytoscapeObj.filter(function (element, i) {
            return element.isNode() && element.data('id') === nodeId;
        })[0];
        if(elementNode) {
            elementNode.select();
            center && this.cytoscapeObj.center(elementNode);
        }
    }


    getMoreItems = (outerThis, graphNodeId) => {
        let {graphData, graphNodes, graphEdges, mostRecentConnectionProperty} = outerThis.state;
        let {configurations, aliasesToIRIMap, aliasesMap, settings, onExpand, languageCode, ontology} = outerThis.props;
        return <GraphViewNodeTooltip
            key={graphNodeId}
            configurations={configurations}
            aliasesToIRIMap={aliasesToIRIMap}
            aliasesMap={aliasesMap}
            graphElement={this.cytoscapeObj.getElementById(graphNodeId)}
            graphData={graphData}
            graphNodes={graphNodes}
            graphEdges={graphEdges}
            graphNodeId={graphNodeId}
            settings={settings}
            onExpand={onExpand}
            languageCode={languageCode}
            onObjectToggle={this.onObjectToggle}
            ontology={ontology}
            mostRecentConnectionProperty={mostRecentConnectionProperty}
        />;
    }

    openStyleSettings = (obj) => {
        let {onInteraction} = this.props;
        onInteraction && onInteraction({type: 'OPEN_STYLE_SETTINGS'})
        this.closeOpenInfoBoxes();
        this.unselectAll();
        const graphNodeId = obj.elementId;
        let element = this.cytoscapeObj.filter(function (element, i) {
            return element.data('id') === graphNodeId;
        });
        element.select();
        this.setState({openStyleFor : {...obj} });
        //Close popper
        let elementById = document.getElementById(this.getContainerPopperId());
        ReactDOM.render(<></>, elementById);
    }

    navigateToVisualisation = (visualisationId) => {
        let {savedVisualisations} = this.state;
        let {onNodeNavigate} = this.props;
        let newValue =  savedVisualisations.find(sv => sv.value === visualisationId);
        this.cytoscapeObj.removeAllListeners();
        const elementById = document.getElementById(this.getContainerPopperId());
        ReactDOM.render(<></>, elementById);
        onNodeNavigate(newValue);
    }

    handleExpandButtonClick = (node) => {
        let {graphStyle} = this;
        const onExpandButtonClick = getOnExpandButtonClickAction(graphStyle, node.graphNodeId);
        if(onExpandButtonClick === ON_CLICK_VALUE_OPEN_VISUALISATION) {
            const visualisationId = getOnExpandButtonClick(graphStyle, node.graphNodeId)?.Visualisation;
            if(visualisationId) {
                this.navigateToVisualisation(visualisationId)
            }
        } else if (onExpandButtonClick === ON_CLICK_VALUE_OPEN_PAGE) {
            const url = getOnExpandButtonClick(graphStyle, node.graphNodeId)?.URL;
            if(url) {
                window.location = url;
            }
        } else {
            this.navigateTo({node});
        }
    }

    getNodeMoreButton = (graphNodeId, style = {}, outerThis, options = {}) => {
        let {graphData, graphNodes, graphEdges, mostRecentConnectionProperty} = outerThis.state;
        let {graphStyle} = this;
        let {configurations, theme, aliasesToIRIMap, aliasesMap, settings, onExpand, languageCode, ontology} = outerThis.props;

        return <div>
            <SimplePopper
                settings={settings}
                graphNodeId={graphNodeId}
                onObjectToggle={this.onObjectToggle}
                onStyleButtonClick={this.openStyleSettings}
                onSettingsButtonClick={this.onNodeSettings}
                onExpandButtonClick={this.handleExpandButtonClick}
                options={options}
                theme={theme}
                style={style}
                onRender={() => this.getMoreItems(outerThis, graphNodeId)}
                configurations={configurations}
                aliasesToIRIMap={aliasesToIRIMap}
                ontology={ontology}
                graphData={graphData}
                languageCode={languageCode}
                graphStyle={graphStyle}
            ></SimplePopper>

        </div>;

    }

    isRightContainerUndefined = () => {
        let {showRightContainer} = this.state;
        return showRightContainer === undefined;
    }

    getDiagramLayout = (options) => {
        let {graphNodes, layoutOptions} = this.state;
        let optionsToPass = {
            ...options,
            ...layoutOptions
        };
        return getDiagramLayout(graphNodes, optionsToPass)
    }

    getDiagramStyle = (layoutName) => {
        let {graphStyle, globalGraphStyle} = this;
        let {settings} = this.props;
        return getDiagramStyle(this.props.theme, layoutName, graphStyle, settings, globalGraphStyle);
    }

    getNodes = async (graphData) => {

        let all = Object.keys(graphData).map(async (k, j) => {
            return this.createNodeForDiagram(graphData[k])
        });
        return Promise.all(all);
    }

    getColorForEdge = (propertyKey) => {
        let {edgeColors} = this.state;
        if(edgeColors[propertyKey]) {
            return edgeColors[propertyKey];
        } else {
            const hue = (Object.keys(edgeColors).length + 1) * 137.508; // use golden angle approximation
            const color = `hsl(${hue},${Math.floor(Math.random()*60) + 40}%, ${Math.floor(Math.random()*30) + 20}%)`;
            edgeColors[propertyKey] = color;
            return color;
        }
    }

    getColorForNode = (typeKey) => {
        let {nodeColors} = this.state;
        if(nodeColors[typeKey]) {
            return nodeColors[typeKey];
        } else {
            const hue = (Object.keys(nodeColors).length + 1) * 137.508; // use golden angle approximation
            const color = `hsl(${hue},${Math.floor(Math.random()*60) + 40}%, ${Math.floor(Math.random()*30) + 20}%)`;
            nodeColors[typeKey] = color;
            return color;
        }
    }

    getEdges = (graphData, newNodes, options = {}) => {
        let {graphEdges} = this.state;
        let {aliasesToIRIMap, ontology, languageCode, settings} = this.props;
        let allGraphNodeIds = Object.keys(graphData);
        let nodesForEdges = allGraphNodeIds.filter(key => {
            if(options.connectionDirection && isIncomingOnly(options.connectionDirection) ) {
                return newNodes.includes(key);
            } else if (options.connectionDirection && isAllConnections(options.connectionDirection)) {
                return newNodes.includes(key);
            } else {
                return options.sourceGraphNodeId === undefined || options.sourceGraphNodeId === key;
            }
        });
        nodesForEdges.forEach(source => {
            const valueObject = graphData[source];
            let resourceLinkingProperties = Object.keys(valueObject).filter(propertyKey => {
                let propertyIri = aliasesToIRIMap[propertyKey] || propertyKey;
                let found = ontology.find(o => o[ID] === propertyIri);
                let propertyValue = valueObject[propertyKey];
                //If the property is not found in the ontology means next step will actually check if there is any connection
                let isObjectPropertyValue = found
                    ? propertyKey === TYPE || isObjectPropertyOrRDFConnectionProperty(found, aliasesToIRIMap) || isAnyValueIdObject(propertyValue)
                    : propertyKey === ID ? false : true;
                return isObjectPropertyValue;
            });
            let filteredResourceLinkingProperties = resourceLinkingProperties.filter(propertyKey => {
                if(options.connectToAll === true) {
                    return true;
                } else {
                    if(options.connectionProperty === undefined || options.connectionProperty.propertyKey === propertyKey) {
                        return true
                    }
                }
                return false;
            });
            filteredResourceLinkingProperties.forEach(propertyKey => {
                let propertyValues = toArray(valueObject[propertyKey])
                    .map(v => {
                        if(propertyKey === TYPE) {
                            return aliasesToIRIMap[v] || v;
                        }
                        return v;
                    }).map(vo => getResourceId(vo) || vo);
                let connections = propertyValues.filter(prVal => {
                        if (options.connectToAll === true) {
                            return allGraphNodeIds.includes(prVal);
                        } else if (options.connectionDirection && isIncomingOnly(options.connectionDirection) ) {
                            if(options.sourceGraphNodeId) {
                                return options.sourceGraphNodeId === prVal;
                            }
                            return allGraphNodeIds.includes(prVal);

                        } else if (options.connectionDirection && isAllConnections(options.connectionDirection)) {
                            return options.sourceGraphNodeId
                                ? options.sourceGraphNodeId === prVal || (options.sourceGraphNodeId === source && newNodes.includes(prVal))
                                : allGraphNodeIds.includes(prVal);
                        } else {
                            if(options.sourceGraphNodeId) {
                                return options.sourceGraphNodeId === prVal || (options.sourceGraphNodeId === source && newNodes.includes(prVal));
                            }
                            return newNodes.includes(prVal);
                        }

                });
                connections.forEach(target => {
                    let sourceToUse = source;
                    let targetToUse = target;
                    const edgeData = this.createEdgeDataNode(sourceToUse, propertyKey, targetToUse);
                    graphEdges.push(edgeData)
                })

            })
        });

        // Handle edge style here by setting class

    }

    createEdgeDataNode = (sourceToUse, propertyKey, targetToUse) => {
        let {aliasesToIRIMap, settings, ontology, languageCode} = this.props;
        let {graphStyle} = this;
        let edgeLabel = getPropertyName(aliasesToIRIMap, propertyKey, ontology, languageCode);

        let id = sourceToUse + "-" + propertyKey + "-" + targetToUse;
        let propertyIRI = aliasesToIRIMap[propertyKey] || propertyKey;
        let color = this.getColorForEdge(propertyKey);

        const edgeData = {
            data: {
                id: id,
                source: sourceToUse,
                target: targetToUse,
                label: edgeLabel,
                propertyKey,
                propertyIRI,
                "color": color
            }
        };
        return edgeData;
    }

    createNodeForDiagram = async (valueObject) => {
        let {settings, aliasesToIRIMap, languageCode, ontology, graph, theme} = this.props;
        let valueObjectsForView = await getValuesObject(valueObject, settings, aliasesToIRIMap, {value : languageCode}, ontology);
        let {title, thumbnail, instanceImage} = valueObjectsForView;
        let {label, width} = title ? computeLabelAndWidth(title) : computeLabelAndWidth(getResourceId(valueObject));
        let types = toArray(valueObject[TYPE] || valueObject[AT_TYPE]).sort();
        let typeIRIs = types.map(t => aliasesToIRIMap[t] || t);
        const graphViews = typeIRIs.map(t => getLabelProperties(settings, t, GRAPH_VIEW_SETTINGS));
        let colors = graphViews.filter(gs => gs && gs[GRAPH_VIEW_BACKGROUND_COLOR]).map(gs => gs[GRAPH_VIEW_BACKGROUND_COLOR]);
        let graphView = getMostRelevantGraphViewSettings(settings, typeIRIs);

        const dataNode = {
            data: {
                id: valueObject[ID],
                label: label,
                "type": "loop",
                thumbnail : thumbnail,
                instanceImage,
                typeIRIs,
                graphView
            }
        };
        return dataNode;
    }


    onUpdateEvent = async (event) => {
        let {ontology, aliasesMap, aliasesToIRIMap} = this.props;
        let isResourceUpdate = isResourceUpdateEvent(event) || isResourceDeleteEvent(event) || isResourceRefreshEvent(event)
        if(isResourceUpdate) {
            let {payload}= event;
            let resourceId = payload?.[ID];
            let {graphData, graphEdges, graphNodes} = this.state;
            if(resourceId && graphData[resourceId]) {
                let resource = await loadResourceAndGet(resourceId);
                if(resource) {
                    graphData[resourceId] = resource;
                } else {
                    delete graphData[resourceId];
                }
                let newGraphNodes = await this.getNodes(graphData);
                let newGraphEdges = graphEdges.filter(edg => {
                    let sourceResource = graphData[edg.data.source];
                    let targetResource = graphData[edg.data.target];
                    if(sourceResource === undefined || targetResource === undefined) {
                        return false;
                    }
                    let targetResourceId = edg.data.target;
                    let connectionProperty = edg.data.propertyKey;
                    let connectedResources = toArray(sourceResource[connectionProperty]);
                    let isConnectedDirect = connectedResources.includes(targetResourceId);
                    if(isConnectedDirect) {
                        return true;
                    }
                    return false;
                });
                this.setState({graphData, graphNodes : newGraphNodes , graphEdges : newGraphEdges}, this.updateDiagramAndRefresh)
            }
        }
    }



    prepareDataForGraphView = () => {
        let {data} = this.props;
        this.expandNodes(data)
    }

    updateDiagramAndRefresh = (newGraphNodes) => {
        let {layoutOptions} = this.state;
        this.updateDiagram();
        if(layoutOptions.autoRefreshLayout === false) {
            return;
        }
        let copy = cloneDeep(layoutOptions);
        copy.stop = this.adjustMinZoom;
        this.refreshDiagramLayout(copy);
    }

    adjustMinZoom = () => {
        let newZoom = this.cytoscapeObj.zoom();
        let maxZoom = this.cytoscapeObj.maxZoom();
        if(maxZoom < MAX_ZOOM_LEVEL) {
            this.cytoscapeObj.maxZoom(MAX_ZOOM_LEVEL);
        }
        const  zoomMinLevel = .4;
        if(newZoom < zoomMinLevel) {
            this.handleZoomChange(undefined, zoomMinLevel);
            this.setState({zoomLevel : zoomMinLevel})
        } else {
            this.setState({zoomLevel : newZoom})
        }
    }

    onObjectToggle = (id, toggleState, options) => {
        this.closeOpenInfoBoxes();
        const ids = toArray(id).map(oid => getResourceId(oid) || oid);
        if(options?.connectionProperty) {
            this.setState({mostRecentConnectionProperty : options.connectionProperty})
        }
        if(toggleState) {
            this.expandNodes(ids, options);
        } else {
            this.unExpandNodes(ids);
        }
    }

    onNodeSettings = (obj) => {
        this.closeOpenInfoBoxes();
        this.unselectAll();
        let element = this.cytoscapeObj.filter(function (element, i) {
            return element.data('id') === obj.elementId;
        });
        element.select();
        this.setState({openSettingsFor : obj});
    }

    expandNodes = (ids, options) => {
        let {graphData, graphEdges, graphNodes} = this.state;
        let {onExpand} = this.props;
        let nonExistingNode = ids.find(id => graphData[id] === undefined);
        let newNodes = ids.filter(id => graphData[id] === undefined);
        if(ids && ids.length > 0) {
            onExpand(ids).then( async expanded => {
                let nonNullExpanded = nullToIdObjects(expanded, ids);
                nonNullExpanded.forEach(vo => graphData[vo[ID]] = vo);
                let newGraphNodes = await this.getNodes(graphData, ids);
                this.getEdges(graphData, ids, options);
                let searchData = graphNodes.map(n => n.data);
                this.setState({graphData, graphNodes: newGraphNodes , searchData}, () => {
                    this.updateDiagramAndRefresh(newNodes);
                    //Keep walking till there are no more nodes being added to graph
                    if(options?.walk === true && nonExistingNode) {
                        let allIds = nonNullExpanded.map(vo => vo[options.connectionProperty.propertyKey]).filter(id => id).map(id => getResourceId(id) || id);
                        if(allIds.length > 0 && graphNodes.length > 100 || graphEdges.length > 100) {
                            this.setState({graphAlert : 'To much data! Auto walk stopped.'});
                        } else {
                            setTimeout(() => {
                                let uniqAllIds = new Set(flatten(allIds).filter(id => id));
                                this.expandNodes([...uniqAllIds], options)
                            }, 1000);
                        }
                    }
                });
            }).catch(() => {
                this.setState({dataLoadError : 'dataLoadError'})
            })
        } else {
            let size = graphEdges.length;
            this.getEdges(graphData, ids, options);
            if(graphEdges.length !== size) {
                this.setState({}, this.updateDiagramAndRefresh)
            }
        }
    }

    unExpandNodes = (ids) => {
        let {graphData, graphEdges} = this.state;

        ids.forEach(id => {
            delete graphData[id];
        })
        this.getNodes(graphData).then(( graphNodes ) => {

            let newGraphEdges = graphEdges.filter(edg => !(ids.includes(edg.data.source) || ids.includes(edg.data.target)));
            let searchData = graphNodes.map(n => n.data);
            this.setState({graphData, graphNodes, graphEdges : newGraphEdges, searchData}, this.updateDiagram);
        })
    }

    getChildren = (ids, graphData) => {
        let children = graphData.map(d => {
            if(ids.includes(d[ID])) {
                return d.children;
            }
        }).filter(ch => ch);
        children = flatten(children);
        children = children.map(ch => ch.id);
        let recursiveChildren = graphData.filter(t => t.children && t.children.length > 0)
            .map(t => this.getChildren(ids, t.children))
        return [...children, ...flatten(recursiveChildren)];
    }

    attachInTreeData = (graphData, newTree) => {
        graphData.forEach(td => {
            newTree.forEach(ntd => {
                if(td[ID] === ntd[ID]) {
                    Object.keys(ntd).forEach(k => {
                        td[k] = ntd[k];
                    });
                }
            });
            if(td.children) {
                this.attachInTreeData(td.children, newTree);
            }
        })
    }

    renderNodeLabel = (node, index) => {
        let maxWidth = 'calc(100%)';
        let labelText = node.labelText;
        return <Typography datatest={'nodeLabel-'+labelText} style={{maxWidth: maxWidth}} noWrap={true} component={"span"}>{labelText}</Typography>;
    }

    getCustomLabelForClass = ($node, index, isList) => {
        let {readOnly} = this.props;
        return <CustomAliasTreeLabel
            datatestOverride={'customAliasTreeLabel-'+$node.labelText}
            isList={isList}
            {...$node}
            renderLabel={() => this.renderNodeLabel($node, index)}
            renderActionsOnFocus={(nodeInner) => {
                if(readOnly) {
                    return <></>;
                }
            }}
        />;

    }

    renderTreeItem = (node, treeItem, index) => {
        return treeItem;
    }

    onSearch = (e, node) => {

        if (node) {
            const { expanded, graphData } = this.state;
            const { id } = node;
            const newExpandedItems = flatten(graphData.map(treeNode => searchLabelRecursively(treeNode, id, 'id')));
            const $expandedItems = uniq([ ...expanded, ...newExpandedItems]);
            this.onNodeClick({node});
            this.setState({ expanded: $expandedItems, currentSearchValue: id, scrollTo: true });
        }
    }

    onNodeClick = ({ node }) => {
        const {onExpand, settings, editMode, onNodeClick, eventRegister, languageCode, ontology, theme, data, location, aliasesToIRIMap, configurations, aliasesMap} = this.props;
        const {graphStyle} = this;
        const onClickAction = getOnClickAction(graphStyle, node.graphNodeId);
        if(onClickAction) {
            const clickActionValue = getOnClickActionValue(graphStyle, node.graphNodeId);
            if(onClickAction === ON_CLICK_VALUE_OPEN_VISUALISATION) {
                const visualisationId = clickActionValue?.Visualisation;
                if(visualisationId) {
                    this.navigateToVisualisation(visualisationId)
                    return;
                }
            } else if (onClickAction === ON_CLICK_VALUE_OPEN_PAGE) {
                const url = clickActionValue?.URL;
                if(url) {
                    window.location = url;
                    return;
                }
            }
        }

        if (onNodeClick) {
            this.closeOpenInfoBoxes();
            onNodeClick(node);
        } else {
            let params = queryString.parse(location.search);
            params.graphNodeId = node.graphNodeId;
            const ids = [node.graphNodeId];
            onExpand(ids).then(expanded => {
                let nonNullExpanded = nullToIdObjects(expanded, ids);

                let customizationsObject  = {
                    hideMockAll : true,
                    hideTopLevelExpandCollapse : true,
                    hideFirstLevelExpandCollapse : true,
                    hideAddTypeBlock : true,
                    hideRemoveBlock : true,
                    // viewLanguageCode : languageCode,
                    readOnly: editMode === true ? 'editMode' : true,
                    onObjectFetch: async (id) => {
                        let expanded = await onExpand([id]);
                        return expanded[0];
                    },
                    registerForUpdate : eventRegister,
                    autoFetchLinkedObject : true,
                    settings : settings
                };

                let expandedResource = nonNullExpanded[0];
                getValuesObject(expandedResource, settings, aliasesToIRIMap, {value : languageCode}, ontology).then((vo) => {
                    this.closeOpenInfoBoxes();
                    this.setState({formData : expandedResource, formDataValueObject : vo , uuid : uuid4(), customizations : customizationsObject})
                   // history.push(`${location.pathname}?${queryString.stringify(params)}`)
                })
                //let valueObject = expanded.find(e => e[ID] === node.graphNodeId);

                //let formData = createFormFromShape(1, aliasesToIRIMap[valueObject[TYPE]], configurations, aliasesMap, this.onFormChange, valueObject, customizationsObject);
            })
        }
    }

    updateDiagram = () => {
        let newEdges = this.state.graphEdges;
        let newNodes = this.state.graphNodes;
        let oldEdges = this.cytoscapeObj.filter(function (element, i) {
            return element.isEdge();
        });
        let oldNodes = this.cytoscapeObj.filter(function (element, i) {
            return element.isNode();
        });

        this.cytoscapeObj.remove(oldEdges);

        let nonExistingNodes = [];
        let maxPosition = 0;

        //If the node exists add it to the same position
        newNodes.forEach(graphNode => {
            let graphNodeId = graphNode.data[ID]
            let oldNode = this.cytoscapeObj.getElementById(graphNodeId);
            let position = oldNode.position();
            if(!oldNode || oldNode.length < 1) {
                nonExistingNodes.push(graphNode);
            } else {
                // if label is changed then update label
                let nodeForDiagram = graphNode;
                if(oldNode.data().label !== nodeForDiagram.data.label) {
                    nodeForDiagram.position = oldNode.position();
                    nodeForDiagram.selected = oldNode.selected();
                    this.cytoscapeObj.remove(oldNode);
                    this.cytoscapeObj.add([nodeForDiagram].map(e => {
                        e.group = 'nodes';
                        return e;
                    }))
                }

                if(position.x > maxPosition) {
                    maxPosition = position.x;
                }
            }
        });

        //create and add non-existing(new) nodes
        nonExistingNodes.forEach((nodeObject, i) => {
            let graphNodeId = nodeObject.data[ID];

            let newNode = nodeObject;

            this.cytoscapeObj.add([newNode].map(e => {
                e.group = 'nodes';
                return e;
            }));
            let newAddedNode = this.cytoscapeObj.getElementById(graphNodeId);
            if(newAddedNode) {
                adjustMinWidth(newAddedNode);
            }
        })

        // remove deleted nodes
        let nodesToRemove = this.cytoscapeObj.filter(function (element, i) {
            if(element.isNode()){
                let found = newNodes.find(o => o.data[ID] === element.data().id);
                if(found) {
                    return false;
                } else {
                    return true;
                };
            } else {
                return false;
            }
        })
        this.cytoscapeObj.remove(nodesToRemove);


        this.cytoscapeObj.add(newEdges.map(e => {
            e.group = 'edges';
            return e;
        }));


        //Close popper
        let elementById = document.getElementById(this.getContainerPopperId());
        ReactDOM.render(<></>, elementById);

    }

    getSelectedNodes = () => {
        return this.cytoscapeObj.filter(function (element, i) {
            return element.isNode() && element.selected();
        });
    }

    handleZoomChange = (action, valueToUse) => {
        const currentValue = this.cytoscapeObj.zoom();
        let value = action === 'zoomOut' ? currentValue - ZOOM_STEP : currentValue + ZOOM_STEP;
        if(valueToUse) {
            value = Number(valueToUse);
        }
        value  = this.normalizeZoomValue(value)
        let selectedNodes = this.getSelectedNodes();
        let position = selectedNodes.length > 0 ? selectedNodes[0].renderedPosition() : undefined;
        this.cytoscapeObj.zoom({
            level : value,
            renderedPosition : position
        });
        this.setState({zoomLevel : valueToUse || value});
    }

    normalizeZoomValue = (value) => {
        return Math.round(value * 1000) / 1000;
    }

    selectedPropertiesChange = (value) => {
    }

    closeOpenInfoBoxes = () => {
        this.setState({formData: undefined, uuid: undefined, openSettingsFor : undefined, openStyleFor : undefined, openLayoutSettings : undefined});
    }

    renderGraph = () => {
        traceRenderStart('', COMPONENT)
        let {onFullScreen, onFullScreenClose, embedded, workspace, theme, ontology, onClose, settings, languageCode, classes, data, minimized, location, aliasesToIRIMap, configurations, aliasesMap, permissionService, publishEvent, onMoreOptionsChange} = this.props;
        let {fullScreen, layoutName, layoutOptions, leftPane, graphAlert, graphEdges, savedVisualisations, showSavedVisualisation, uuid, layoutError, zoomLevel,  previousLayoutName, scrollTo, searchData, formData, formDataValueObject, settingsMenuPaneOpen, openStyleFor, openLayoutSettings} = this.state;
        const focusedNode = location && location.search && queryString.parse(location.search).graphNodeId;
        const formDataWidth = 608
        const styleContainerWidth = 320
        let {updateFailed, formDataDeleteLoading, showSaveDialog, openSettingsFor} = this.state;
        let canDelete = formData && permissionService.canDeleteResource(formData);


        let leftPaneOpen = leftPane === true || leftPane === undefined;
        const cyContainerWidth = formData
            ? `calc(100% - ${formDataWidth}px)`
            : openStyleFor || openLayoutSettings || openSettingsFor ? `calc(100% - ${styleContainerWidth}px)` : '100%';

        const zoomLevelValue = zoomLevel || (this.cytoscapeObj ? Math.round(this.cytoscapeObj.zoom() * 1000) / 1000 : 1);
        const find = toArray(savedVisualisations).find(sv => getResourceId(sv) === getResourceId(showSavedVisualisation));
        return  <div datatest={'label-'+find?.label} style={{height: '100%', display : 'flex'}}>
            {
                graphAlert && <AlertSnackbarContent
                    variant="error"
                    className={classes.margin}
                    autoHide={false}
                    message={graphAlert}
                    open={true}
                    onClose={() => {
                        this.setState({graphAlert: undefined})
                    }}
                />
            }
            {
                updateFailed &&
                <AlertSnackbarContent
                    onClose={() => this.setState({updateFailed : undefined})}
                    variant={'error'}
                    open={true}
                    autoHide={true}
                    message={updateFailed}
                />
            }
            {
                layoutError && <Dialog open={true}>
                    <DialogTitle id="form-dialog-title"><H2Title title={'Error'}/></DialogTitle>
                    <DialogContent>
                        <DialogContentText>{layoutError}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant={'contained'}
                            datatest={'confirmClose'}
                            onClick={() => this.setState({layoutError : undefined, layoutName : previousLayoutName})}
                            color="secondary">Close</Button>
                    </DialogActions>
                </Dialog>
            }
            {showSaveDialog && this.showSaveVisualisationDialog()}
            {
                toArray(graphEdges).length > 0 &&
                <div style={{
                    marginLeft : embedded ? '0px' : '8px',
                    width: leftPaneOpen ? 200 : 40,
                    height: 'calc(100% - 180px)',
                    marginTop: '96px',
                    zIndex: 1001,
                    position: 'absolute'
                }}>


                    <Paper style={{height : '100%', display : leftPaneOpen ? 'block' : 'none'}}>
                        {
                            <div style={{display : 'flex'}}>
                                <div style={{flexGrow : 1}}></div>
                            <IconButton color={'primary'} size={'small'}
                                        onClick={() => this.setState({leftPane: false})}><MenuOpenOutlined></MenuOpenOutlined></IconButton>
                            </div>
                        }
                        {leftPaneOpen && <RenderEdgesPane graphEdges={graphEdges} cytoscapeObj={this.cytoscapeObj}/>}
                    </Paper>
                    <Tooltip title={'Show Properties Pane'}>
                        <IconButton datatest={'propertiesPaneButton'} color={'primary'} style={{ display : leftPaneOpen ? 'none' : 'block'}} size={'small'}
                                      onClick={() => this.setState({leftPane: true})}><MenuOutlined></MenuOutlined></IconButton>
                    </Tooltip>

                </div>
            }
            <div id={this.getContainerId()} style={{ width : cyContainerWidth, height: 'calc(100%)'}}>
            </div>
            {
                formData &&
                <div datatest={'formDataBlock'} key={focusedNode + uuid} style={{
                    flexGrow : '1',
                    padding: '8px',
                    backgroundColor: theme.palette.white.main,
                    borderRadius: '4px',
                    zIndex : ZINDEX_NODE_DETAILS_FORM_DATA
                }}>
                    <FieldContainer  style={{
                        padding : '0px',
                        backgroundColor: theme.palette.grey.background,
                        height: '100%',
                    }}>
                        <div style={{display: 'flex', padding : '4px'}}>
                            <div style={{flexGrow: '1'}}></div>
                            <Tooltip title={'Close'}>
                                <IconButton datatest={'closeTreeButton'} size={'small'} onClick={this.closeOpenInfoBoxes}>
                                    <CloseIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div style={{ height: "calc(100% - 56px)", maxWidth : `${formDataWidth - 28}px`, overflowY : 'auto', overflowX : 'auto', borderRadius : '4px', margin : '0px 8px 8px 8px', paddingBottom : '8px', backgroundColor: theme.palette.white.main}}>
                            {
                                getHeader(
                                    formDataValueObject.title,
                                    formData,
                                    formDataValueObject.thumbnail,
                                    {borderBottom : '1px solid', borderBottomColor : fade(theme.palette.secondary.main, 0.5)},
                                    canDelete ? () => <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_DELETE_RESOURCE, languageCode, UI_LABELS_DELETE_RESOURCE)}>
                                        <IconButton
                                            size={'small'}
                                            onClick={async () => {
                                                this.setState({formDataDeleteLoading : true});
                                                let error = await deleteResource.call(this, formData[ID], publishEvent, formData, settings, languageCode);
                                                this.setState({formDataDeleteLoading : false})
                                                if(!error) {
                                                    this.setState({formData: undefined, uuid: undefined}, this.scheduleDiagramRefresh)
                                                }
                                            }}
                                        >{formDataDeleteLoading && <CircularProgress size={24} className={classes.buttonProgress} />}<DeleteOutlined/></IconButton>
                                    </Tooltip> : undefined

                                )
                            }
                            <Tree
                                location={location}
                                ontology={ontology}
                                configurations={configurations}
                                aliasesToIRIMap={aliasesToIRIMap}
                                aliasesMap={aliasesMap}
                                settings={settings}
                                resource={formData}
                                browseLanguage={this.getBrowseLanguage()}
                                theme={theme}
                                viewProperties={[]}
                                onConceptClick={(foundResource, details) => {
                                    let {location, onClose} = this.props;
                                    onClose && onClose();
                                    navigateTo(foundResource, details, location);
                                }}
                            />
                        </div>
                    </FieldContainer>
                </div>
            }
            {
                openStyleFor && <React.Fragment key={openStyleFor.graphNodeId}><GraphViewStyleSettings
                    workspace={this.props.workspace}
                    graphStyle={this.graphStyle}
                    location={location}
                    ontology={ontology}
                    configurations={configurations}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    settings={settings}
                    browseLanguage={this.getBrowseLanguage()}
                    onClose={this.closeOpenInfoBoxes}
                    openStyleFor={openStyleFor}
                    onStyleSettingsChange={() => {
                        this.refreshStyle(layoutOptions);
                    }}
                    styleContainerWidth={styleContainerWidth}
                    elementObject={this.cytoscapeObj.filter(e => {
                        return e.data().id === openStyleFor.elementId;
                    })[0]}
                /></React.Fragment>
            }
            {
                openSettingsFor && <React.Fragment key={openSettingsFor.elementId}>
                    <GraphViewNodeSettings
                        workspace={this.props.workspace}
                        graphStyle={this.graphStyle}
                        location={location}
                        ontology={ontology}
                        configurations={configurations}
                        aliasesToIRIMap={aliasesToIRIMap}
                        aliasesMap={aliasesMap}
                        settings={settings}
                        browseLanguage={this.getBrowseLanguage()}
                        onClose={this.closeOpenInfoBoxes}
                        openFor={openSettingsFor}
                        onSettingsChange={(graphSetting) => {
                            this.refreshStyle(layoutOptions);
                        }}
                        styleContainerWidth={styleContainerWidth}
                        elementObject={this.cytoscapeObj.filter(e => {
                                return e.data().id === openSettingsFor.elementId;
                        })[0]}
                    />
                </React.Fragment>
            }
            {
                openLayoutSettings && <GraphViewLayoutSettings
                    workspace={this.props.workspace}
                    location={location}
                    ontology={ontology}
                    configurations={configurations}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    settings={settings}
                    browseLanguage={this.getBrowseLanguage()}
                    onClose={this.closeOpenInfoBoxes}
                    onLayoutSettingsChange={(diagramStyle, key) => {
                        if(key) {
                            if(key === 'userZoomingEnabled') {
                                this.cytoscapeObj.userZoomingEnabled(layoutOptions[key]);
                            }
                            return;
                        }
                        if(diagramStyle) {
                            this.globalGraphStyle = diagramStyle;
                        }
                        const maxZoom = layoutOptions['_eg_maximumZoom'];
                        if(maxZoom) {
                            this.cytoscapeObj.maxZoom(Number(maxZoom));
                        }
                        const minZoom = layoutOptions['_eg_minimumZoom'];
                        if(minZoom) {
                            this.cytoscapeObj.minZoom(Number(minZoom));
                        }
                        this.refreshDiagramLayout(layoutOptions);
                    }}
                    graphStyle={this.globalGraphStyle}
                    styleContainerWidth={styleContainerWidth}
                    cytoscapeObj={this.cytoscapeObj}
                    layoutOptions={layoutOptions}
                    layoutName={layoutName}
                />
            }

            <div id={this.getContainerPopperId()} style={{zIndex : ZINDEX_NODE_MORE_INFO}}></div>

            <div key={fullScreen} style={{
                position: 'absolute',
                top: '0px' ,
                left: `${minimized ? 16 : (250 - 52) + 112}px`,
                zIndex: ZINDEX_TOOLBAR,
            }} >
                <Paper elevation={3} style={{display : 'flex', marginTop : '8px', padding : '4px 16px'}}>

                    {
                        embedded ? <></> : this.props.headerProvider ? centerVertically(this.props.headerProvider(), {cursor : 'pointer', marginLeft : '-8px'}) :
                        centerVertically(<Tooltip title={'Close visualisation'}>
                            <IconButton datatest={'CancelPresentation'} color={'primary'} style={{marginRight : '8px'}} size={'small'} onClick={() => {
                                this.setState({open: false, loading : false});
                                onClose && onClose();

                            }}>
                                <CancelPresentation></CancelPresentation>
                            </IconButton>
                        </Tooltip>)
                    }
                    {
                        fullScreen && graphViewLogo(theme, settings, () => {
                            onFullScreenClose(() => {
                                this.cytoscapeObj.resize();
                                this.cytoscapeObj.center();
                                this.cytoscapeObj.fit();
                                this.setState({fullScreen : undefined});
                            })
                        })
                    }
                    {
                        embedded && onFullScreen && fullScreen === undefined && centerVertically(<Tooltip title={'Full Screen'}>
                            <IconButton datatest={'FullScreen'} color={'primary'} style={{marginRight : '8px'}} size={'small'} onClick={() => {
                               onFullScreen(() => {
                                   this.cytoscapeObj.resize();
                                   this.cytoscapeObj.center();
                                   this.cytoscapeObj.fit();
                                   this.setState({fullScreen : true});

                               });

                            }}>
                                <OpenWithOutlined></OpenWithOutlined>
                            </IconButton>
                        </Tooltip>)
                    }
                    {
                        centerVertically(<Tooltip title={'Reset'}>
                            <IconButton datatest={'ResetPresentation'} color={'primary'} style={{marginRight : '8px'}} size={'small'} onClick={this.resetVisualisation}>
                                <SettingsBackupRestoreOutlined/>
                            </IconButton>
                        </Tooltip>)
                    }
                    { settingsMenuPaneOpen &&
                    <>
                    <GraphViewSettings
                        layout={layoutName || LAYOUT_DAGREE_LR}
                        onLayoutChange={(value) => {
                            let {layoutOptions, layoutName} = this.state;
                            let newLayoutName = value.target.value;
                            let layoutOptionsNew = getLayoutOptionsForView(newLayoutName, this.adjustMinZoom);
                            layoutOptionsNew['fit'] = layoutOptions['fit'];
                            layoutOptionsNew['userZoomingEnabled'] = layoutOptions['userZoomingEnabled'];
                            layoutOptionsNew['autoRefreshLayout'] = layoutOptions['autoRefreshLayout'];
                            layoutOptionsNew['_eg_maximumZoom'] = layoutOptions['_eg_maximumZoom'];
                            layoutOptionsNew['_eg_minimumZoom'] = layoutOptions['_eg_minimumZoom'];

                            this.setState({layoutOptions : layoutOptionsNew, previousLayoutName : layoutName, layoutName: newLayoutName}, () => this.refreshDiagramLayout(layoutOptions));

                        }}
                    />
                    {
                        centerVertically(
                            <Tooltip title={'Open Layout Settings'}>
                                <IconButton
                                    datatest={'TuneIcon'}
                                    color={'primary'}
                                    style={{marginRight: '8px'}}
                                    size={'small'}
                                    onClick={() => {
                                        this.closeOpenInfoBoxes();
                                        this.setState({openLayoutSettings : true});
                                    }}
                                >
                                    <TuneOutlined></TuneOutlined>
                                </IconButton>
                            </Tooltip>
                        )
                    }
                        {centerVertically(this.props.historyProvider())}
                    {
                        centerVertically(<Tooltip title={'Refresh'}><IconButton datatest={'RefreshIcon'} color={'primary'} style={{marginRight : '8px'}} size={'small'} onClick={() => {
                            let {layoutOptions} = this.state;
                            this.refreshDiagramLayout(layoutOptions);
                        }}>
                            <RefreshIcon></RefreshIcon>
                        </IconButton></Tooltip>)
                    }

                    {
                        centerVertically(<Tooltip title={'Take screenshot'}>
                            <IconButton datatest={'PhotoCameraOutlined'} color={'primary'} style={{marginRight : '8px'}} size={'small'} onClick={() => {
                                let blobData = this.cytoscapeObj.png({output : 'blob'});
                                let {data} = this.props;
                                let {graphNodes} = this.state;
                                let first = toArray(data)[0];
                                let label = graphNodes.filter(gn => gn.data.id === first).map(gn => gn.data.label);
                                fileDownload(blobData, 'graph_'+label+"_"+ new Date().toISOString() + ".png" )
                            }}>
                                <PhotoCameraOutlined></PhotoCameraOutlined>
                            </IconButton>
                        </Tooltip>)
                    }
                    { embedded ? <></> :
                        <>
                            <SavedGraph
                                style={{minWidth: 180, marginRight: '8px'}}
                                label={'Saved'}
                                workspace={workspace}
                                options={savedVisualisations || []}
                                value={showSavedVisualisation}
                                onChange={(event, newValue, reason) => {

                                    this.setState({showSavedVisualisation: newValue}, () => this.loadVisualisation(newValue.value));
                                }}

                            ></SavedGraph>
                            {
                                showSavedVisualisation && isSuperadmin() &&
                                centerVertically(<Tooltip title={'Delete Visualisation'}>
                                    <IconButton datatest={'deleteOutlined'} color={'primary'}
                                                style={{marginRight: '8px'}} size={'small'} onClick={() => {
                                        this.deleteVisualisation(showSavedVisualisation);
                                    }}>
                                        <DeleteOutlined color={'secondary'}></DeleteOutlined>
                                    </IconButton>
                                </Tooltip>)

                            }
                            {
                                isSuperadmin() &&
                                centerVertically(<Tooltip title={'Save Visualisation'}>
                                    <IconButton datatest={'saveOutlined'} color={'primary'}
                                                style={{marginRight: '8px'}} size={'small'} onClick={() => {
                                        this.setState({showSaveDialog: true})
                                    }}>
                                        <SaveOutlined></SaveOutlined>
                                    </IconButton>
                                </Tooltip>)

                            }
                        </>
                    }
                    {
                        <TextField
                            datatest={'zoomBox'}
                            style={{width :'140x', maxWidth : '140px'}}
                            value={zoomLevelValue}
                            onChange={(ev) => {
                                let {value} = ev.target;
                                this.handleZoomChange(undefined, value);
                            }}
                            InputProps={{
                                startAdornment : <Tooltip title={'Zoom In'}>
                                    <IconButton
                                        disabled={zoomLevelValue >= this.cytoscapeObj?.maxZoom()}
                                        datatest={'zoomIn'}
                                        color={'primary'}
                                        size={'small'}
                                        onClick={() => this.handleZoomChange('zoomIn')}
                                    >
                                        <ZoomInOutlined></ZoomInOutlined>
                                    </IconButton>
                                </Tooltip>,
                                endAdornment : <Tooltip title={'Zoom Out'}>
                                    <IconButton
                                        disabled={zoomLevelValue <= this.cytoscapeObj?.minZoom()}
                                        datatest={'zoomOut'}
                                        color={'primary'}
                                        style={{marginRight: '8px'}}
                                        size={'small'}
                                        onClick={() => this.handleZoomChange('zoomOut')}
                                    >
                                        <ZoomOutOutlined></ZoomOutOutlined>
                                    </IconButton>
                                </Tooltip>
                            }}
                        ></TextField>
                    }
                        </>
                    }
                    {
                        centerVertically(<Tooltip title={ settingsMenuPaneOpen ? 'Minimize': 'More'}>
                            <IconButton
                                datatest={'moreOptions'}
                                color={'primary'}
                                size={'small'}
                                onClick={() => {
                                    this.setState({settingsMenuPaneOpen: !settingsMenuPaneOpen});
                                    onMoreOptionsChange(!settingsMenuPaneOpen);
                                }}
                            >
                                <DoubleArrowOutlined style={settingsMenuPaneOpen ? {transform : 'rotate(180deg)'} : {}}></DoubleArrowOutlined>
                            </IconButton>
                        </Tooltip>, {paddingLeft : '8px', marginLeft : settingsMenuPaneOpen ? '16px' : '0px', borderRadius : '2px', borderLeft : '4px solid', borderColor : theme.palette.border.main})
                    }

                </Paper>
            </div>


        </div>;
    }

    getBrowseLanguage = () => {
        let {languageCode} = this.props;
        return {value: languageCode};
    }

    applyStyleSettings = async () => {
        let {graphData, graphEdges} = this.state;
        let {graphStyle} = this;
        this.cytoscapeObj.removeListener('remove', 'edge');
        this.cytoscapeObj.removeListener('remove', 'node');

        let newGraphNodes = await this.getNodes(graphData);
        let newGraphEdges = graphEdges.map(e => {
            let {source, propertyKey, target} = e.data;
            return this.createEdgeDataNode(source, propertyKey, target);
        });
        let oldEdges = this.cytoscapeObj.filter(function (element, i) {
            return element.isEdge();
        });
        let oldNodes = this.cytoscapeObj.filter(function (element, i) {
            return element.isNode();
        });

        this.cytoscapeObj.remove(oldEdges);

        newGraphNodes.forEach(graphNode => {
            let graphNodeId = graphNode.data[ID]
            let oldNode = this.cytoscapeObj.getElementById(graphNodeId);
            let position = oldNode.position();
            if(!oldNode || oldNode.length < 1) {
            } else {
                // if label is changed then update label
                let nodeForDiagram = graphNode;
                nodeForDiagram.position = oldNode.position();
                nodeForDiagram.selected = oldNode.selected();
                this.cytoscapeObj.remove(oldNode);
                this.cytoscapeObj.add([nodeForDiagram].map(e => {
                    e.group = 'nodes';
                    return e;
                }))

            }
        });
        this.cytoscapeObj.add(newGraphEdges.map(e => {
            e.group = 'edges';
            return e;
        }));

        this.setState({graphNodes : newGraphNodes , graphEdges : newGraphEdges}, this.updateDiagram)
    }


    resetVisualisation = () => {
        let {visualisation, onReset} = this.props;
        onReset();
    }

    showSaveVisualisationDialog = () => {
        let {showSavedVisualisation} = this.state;
        return <SaveDialogWithTitle
            title={showSavedVisualisation?.label}
            onSave={(saveVisualisationTitle, action) => {
                this.saveVisualisation(saveVisualisationTitle, action).then(r => {
                    this.syncSavedVisualisations().then(r => this.setState({showSaveDialog : false}));
                })
            }}
            onCancel={() => {
                this.setState({showSaveDialog : false});
            }}
        />;
    }

    renderDialog = () => {
        let {open, dataLoadError} = this.state;
        let {headerProvider, settings, visualisation} = this.props;
        return open && <Dialog
            datatest={'graphViewDialog'}
            aria-labelledby="Graph View"
            open={open}
            fullWidth={true}
            fullScreen={true}
            onRendered={() => {

                this.initGraph();
                if(visualisation) {
                    this.setState({showSavedVisualisation : visualisation}, () => this.loadVisualisation(visualisation.value));
                } else {
                    this.prepareDataForGraphView();
                }
            }}

        >

            <DialogContent style={{padding : '8px'}}>
                {dataLoadError &&
                    <AlertSnackbarContent
                        variant={'error'}
                        message={'Failed to load resources.'}
                        open={true}
                        autoHide={false}
                        onClose={() =>this.setState({dataLoadError : undefined})}
                    />
                }
                {settings && this.renderGraph()}
            </DialogContent>
        </Dialog>;
    }

    render() {
        let {hideButton, embedded, settings} = this.props;
        return <>
            {
                hideButton ||
                <Tooltip title={'Visualise'}>
                    <IconButton datatest={'graphViewButton'} onClick={(ev) => {
                        this.setState({open: true});
                    }} size={'small'}>
                        <AccountTreeIcon/>
                    </IconButton>
                </Tooltip>
            }
            {embedded ? (settings ? this.renderGraph() : <></>) : this.renderDialog()}
        </>;
    }


}

class GraphView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visualisation : props.visualisation,
            fullScreen : undefined,
            settingsMenuPaneOpen : false
        }
        this.history = [props.visualisation];
        this.historyPointer = -1;
    }

    historyProvider = () => {
        let {history, historyPointer} = this;
        let {visualisation} = this.props;
        if(visualisation === undefined || history.length <= 1) {
            return  <></>;
        }
        return <ButtonGroup size={'small'} style={{marginRight : '8px'}}>
            <IconButton
                datatest={'historyBack'}
                disabled={this.historyPointer === -1 || this.historyPointer - 1 < 0}
                color={'primary'}
                onClick={() => {
                    let v = this.history[this.historyPointer - 1];
                    this.historyPointer = this.historyPointer - 1;
                    this.setState({visualisation : v, keyId : uuid4()})
                }}
            ><ArrowBackOutlined/></IconButton>
            <IconButton
                datatest={'historyForward'}
                disabled={this.historyPointer === -1 || this.historyPointer === this.history.length - 1}
                color={'primary'}
                onClick={() => {
                    let v = this.history[this.historyPointer + 1];
                    this.historyPointer = this.historyPointer + 1;
                    this.setState({visualisation : v, keyId : uuid4()})
                }}
            >
                <ArrowForwardOutlined/>
            </IconButton>
        </ButtonGroup>;
    }

    render() {
        let {visualisation, onFullScreen, onFullScreenClose, ...rest} = this.props;
        let {fullScreen, settingsMenuPaneOpen} = this.state;
        let {history, historyPointer} = this;
        const onFullScreenFunc = onFullScreen ? (funct) => {
            onFullScreen(funct);
            this.setState({fullScreen: true, keyId: uuid4()})
        } : undefined;
        return <GraphViewInternal
                {...rest}
                settingsMenuPaneOpen={settingsMenuPaneOpen}
                key={this.state.keyId}
                fullScreen={fullScreen}
                visualisation={this.state.visualisation}
                historyProvider={this.historyProvider}
                onNodeNavigate={(v) => {
                    this.history.push(v);
                    this.historyPointer = this.history.length - 1;
                    this.setState({visualisation : v, keyId : uuid4()})
                }}
                onReset={() => {
                    this.history = [this.props.visualisation];
                    this.historyPointer = -1;
                    this.setState({ visualisation : visualisation, keyId : uuid4()})
                }}
                onFullScreenClose={(funct) => {
                    onFullScreenClose(funct);
                    this.setState({fullScreen: undefined, keyId : uuid4()});
                }}
                onFullScreen={onFullScreenFunc}
                onMoreOptionsChange={(settingsMenuPaneOpen) => {
                    this.setState({settingsMenuPaneOpen})
                }}
            />;
    }
}

GraphView.propTypes = {
    registerForEvents : PropTypes.func,
    open : PropTypes.bool,
    fullScreen: PropTypes.bool,
    embedded: PropTypes.bool,
    hideButton: PropTypes.bool,
    minimized: PropTypes.any,
    editMode: PropTypes.any,
    languageCode: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.array,
    visualisation: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    settings: PropTypes.object,
    data: PropTypes.any,
    onExpand: PropTypes.func,
    onClose: PropTypes.func,
    eventRegister: PropTypes.func,
    workspace : PropTypes.any,
    containerId : PropTypes.any,
    onNodeClick: PropTypes.func,
    onFullScreen: PropTypes.func,
    onInteraction: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(withEvent(withPermissions(GraphView)));

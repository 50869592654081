import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import FieldContainer from "../../components/FieldContainer";
import ErrorMessage from "../../components/ErrorMessage";
import {restrictMaximumCharacters, toArray, validateRequiredAndMaxLength} from "../../components/util";
import LanguageSearchSelect from "../apiplayground/LanguageSearchSelect";
import {LANGUAGES} from "../../components/LanguagesList";
import {FormControlLabel, FormHelperText, IconButton, RadioGroup, TextField, Typography} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import {DeleteOutline} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import H2Title from "../../components/H2Title";
import GridContainer from "../../components/GridContainer";
import MainHeaderBar from "../../components/MainHeaderBar";
import FormControl from "@material-ui/core/FormControl";
import {VALIDATION_LANGUAGE_CODE_LENGTH, VALIDATION_LANGUAGE_NAME_LENGTH} from "../../Constants";
import FormLabel from "@material-ui/core/FormLabel";
import uuid4 from "uuid/v4";

const LABEL_LANGUAGE_CODE = 'Language Code';

const LABEL_LANGUAGE_TITLE = 'Language Title';

const getLanguageHelpMessage = () => {
    return <FormHelperText>Select a language code. If the required code is not in the list type the language code and click Add.</FormHelperText>;
}

const setError = (settings, error, index, key) => {
    if (error) {
        if (!settings.browseLanguagesErrors) {
            settings.browseLanguagesErrors = {}
        }
        if(settings.browseLanguagesErrors[index] === undefined) {
            settings.browseLanguagesErrors[index] = {}
        }
        settings.browseLanguagesErrors[index][key] = error;
    } else {
        if (settings.browseLanguagesErrors) {
            if(settings.browseLanguagesErrors[index]) {
                delete settings.browseLanguagesErrors[index][key];
            }
            if(settings.browseLanguagesErrors[index] && Object.keys(settings.browseLanguagesErrors[index]).length === 0) {
                delete settings.browseLanguagesErrors[index];
            }
        }
    }
}

const validateDuplicateLanguageCode = (settings) => {
    let errors = [];
    settings.browseLanguages.forEach((bl, k) => {
        let code = bl.value;
        let found = settings.browseLanguages.find((bln, i) => code && bln.value === code && i !== k);
        if(found) {
            setError(settings, 'Duplicate', k, LABEL_LANGUAGE_CODE);
            errors.push(k);
        }
    });
    return errors;
}

const validateLanguageCode = (settings, value, index) => {
    let error = validateRequiredAndMaxLength(value, LABEL_LANGUAGE_CODE, VALIDATION_LANGUAGE_CODE_LENGTH)
    setError(settings, error, index, LABEL_LANGUAGE_CODE);
    return error;
}

const validateLanguageTitle = (settings, value, index) => {
    let error = validateRequiredAndMaxLength(value, LABEL_LANGUAGE_TITLE, VALIDATION_LANGUAGE_NAME_LENGTH);
    setError(settings, error, index, LABEL_LANGUAGE_TITLE)
}

const validate = (settings) => {
    delete settings.browseLanguagesErrors;
    if(settings.isMultilingual) {
        settings.browseLanguages.forEach((bl, k) => {
            validateLanguageCode(settings, bl.value, k);
            validateLanguageTitle(settings, bl.label, k);
        })
        validateDuplicateLanguageCode(settings);
    } else {
        let defaultLanguage = settings.browseLanguages[0];
        validateLanguageCode(settings, defaultLanguage.value, 0);
    }
}

const renderLanguageCodeError = (settings, index, label) => {
    return settings?.browseLanguagesErrors?.[index]?.[label]
        && <ErrorMessage error={(settings.browseLanguagesErrors[index][label])}/>;
}

export function MultilingualSettings({theme, settings, onChange, title}) {
    let browseLanguagesArray = toArray(settings.browseLanguages);

    const [refresh, setRefresh] = useState();

    const handleChange = () => {
        setRefresh(uuid4());
        onChange();
    }

    return <Grid item xs={12}>
        <H2Title title={ title || 'Supported Languages Setup'}/>
        <FieldContainer style={{display: 'flex', marginBottom: '8px', padding: '8px'}}>

            <FieldContainer datatest={'browseLanguagesArray'} style={{marginBottom: '8px'}}>
                {getLanguageHelpMessage()}

                {
                    browseLanguagesArray.map((bl, k) => {
                        return <div datatest={k} key={bl.value} style={{marginTop: '16px', display: 'flex'}}>
                            <div style={{width: '220px'}}>
                                <LanguageSearchSelect
                                    inputLabelPropsOverride={{}}
                                    autoFocus={true}
                                    label={LABEL_LANGUAGE_CODE}
                                    fullWidth={true}
                                    datatest={'languageCode'}
                                    multiple={false}
                                    value={bl.value}
                                    addNameToLabel={true}
                                    onChange={(val) => {
                                        let value = val ? val.value : '';
                                        bl.value = value;
                                        let error = validateLanguageCode(settings, value, k);
                                        if (!error) {
                                            if (bl.value) {
                                                let label = LANGUAGES.find(l => l.value === bl.value);
                                                bl.label = label ? label.label : '';
                                            }
                                        }
                                        let duplicateLanguageCode = validateDuplicateLanguageCode(settings);
                                        handleChange();
                                    }}
                                    excludeSuggestions={browseLanguagesArray.map(bl => bl.value)}
                                />
                                {renderLanguageCodeError(settings, k, LABEL_LANGUAGE_CODE)}
                            </div>
                            <div style={{width: '240px', marginLeft: '16px'}}>
                                <TextField
                                    datatest={'languageTitle'}
                                    fullWidth={true}
                                    label={LABEL_LANGUAGE_TITLE}
                                    margin={"dense"}
                                    value={bl.label ? bl.label : ''}
                                    onChange={(event) => {
                                        let newEvent = restrictMaximumCharacters(event, VALIDATION_LANGUAGE_NAME_LENGTH);
                                        const {target: {name, value}} = newEvent;
                                        bl.label = value;
                                        validateLanguageTitle(settings, value, k);
                                        handleChange();
                                    }}
                                />
                                {renderLanguageCodeError(settings, k, LABEL_LANGUAGE_TITLE)}
                            </div>
                            {
                                <div style={{marginLeft: '16px', marginTop: '6px'}}>
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                datatest={'isDefault'}
                                                checked={bl.isDefault === true}
                                                onChange={() => {
                                                    browseLanguagesArray.forEach((blo) => blo.isDefault = false);
                                                    bl.isDefault = true;
                                                    handleChange();
                                                }}
                                            />
                                        }
                                        label={<Typography style={{color: theme.palette.primary.main}}>Set as Default</Typography>}
                                    />
                                </div>

                            }
                            <div style={{marginLeft: '16px'}}>
                                <IconButton
                                    datatest={'deleteButton'}
                                    disabled={browseLanguagesArray.length === 1}
                                    onClick={() => {
                                        settings.browseLanguages = settings.browseLanguages.filter((blo, i) => i !== k);
                                        let found = settings.browseLanguages.find(bl => bl.isDefault === true);
                                        if (!found) {
                                            settings.browseLanguages[0].isDefault = true;
                                        }
                                        handleChange();
                                    }}
                                ><DeleteOutline/></IconButton>
                            </div>
                        </div>;
                    })
                }
                <div style={{marginTop: '16px'}}>
                    <Button
                        color={'secondary'}
                        variant={'contained'}
                        onClick={() => {
                            if (!settings.browseLanguages) {
                                settings.browseLanguages = [{
                                    isDefault: true
                                }]
                            }
                            settings.browseLanguages.push({});
                            validate(settings);
                            handleChange();
                        }}
                    >Add Language</Button>

                </div>
            </FieldContainer>
        </FieldContainer>
    </Grid>;
}

class LanguageSetup extends Component {
    constructor(props) {
        super(props);
        if(!props.settings.browseLanguages) {
            props.settings.browseLanguages = [];
        }
        this.state = {}
    }

    componentDidMount() {
        this.setupChanged();
    }

    renderLanguagesConfiguration = () => {
        let {theme, settings} = this.props;
        return <MultilingualSettings
            theme={theme}
            settings={settings}
            onChange={() => {
                this.setState({});
                this.setupChanged();
            }}
        />;
    }

    renderDefaultLanguage = () => {
        let {theme, settings} = this.props;
        let bl = settings.browseLanguages[0];
        return <Grid item xs={12}>
            <H2Title title={'Default Language'}/>
            <FieldContainer style={{marginBottom: '8px', padding: '16px'}}>
                <div>{getLanguageHelpMessage()}</div>
                <div style={{width: '220px', marginTop: '16px'}}>
                    <LanguageSearchSelect
                        inputLabelPropsOverride={{}}
                        label={LABEL_LANGUAGE_CODE}
                        fullWidth={true}
                        datatest={'defaultLanguageCode'}
                        multiple={false}
                        value={bl?.value || ""}
                        addNameToLabel={true}
                        onChange={(val) => {
                            let value = val ? val.value : '';
                            bl.value = value;
                            let error = validateLanguageCode(settings, value);
                            if(!error) {
                                if (bl.value) {
                                    let label = LANGUAGES.find(l => l.value === bl.value);
                                    bl.label = label ? label.label : '';
                                }
                            }
                            this.setState({});
                            this.setupChanged();
                        }}
                    />
                    {renderLanguageCodeError(settings, 0, LABEL_LANGUAGE_CODE)}
                </div>

            </FieldContainer>
        </Grid>;
    }

    setupChanged = () => {
        let {onChange, settings} = this.props;
        validate(settings);
        onChange(settings.browseLanguagesErrors);
    }

    render() {
        let {settings, stepName} = this.props;
        return <GridContainer>
            <Grid item xs={12} style={{paddingTop: 0}}>
                <MainHeaderBar title={stepName}></MainHeaderBar>
            </Grid>
            <Grid datatest={'multilingual'} item xs={12}>
                <FieldContainer style={{padding: '16px'}}>

                    <FormControl component="fieldset">
                        <FormLabel component="legend">Do you want to allow users to select from more than one language?</FormLabel>
                        <RadioGroup datatest={'isMultilingual'} style={{margin : '8px 0px'}} row aria-label="position" name="position" value={settings.isMultilingual === true ? "yes" : "no"} onChange={(event) => {
                            settings.isMultilingual = !settings.isMultilingual;
                            if(!settings.isMultilingual) {
                                settings.browseLanguages.forEach((blo) => blo.isDefault = false);
                                if (settings.browseLanguages.length > 0) {
                                    settings.browseLanguages[0].isDefault = true;
                                } else {
                                    settings.browseLanguages.push({isDefault: true});
                                }
                                let textSearchUi = settings.searchRequestObject?.search?.textSearch?.ui;
                                if(textSearchUi && textSearchUi.enableLanguageSelect) {
                                    textSearchUi.enableLanguageSelect = false;
                                }
                            } else {
                                settings.isMultilingualUI = true;
                            }
                            this.setupChanged();
                            this.setState({});

                        }}>
                            <FormControlLabel
                                value="yes"
                                control={<Radio datatest={'yes'} color="secondary" />}
                                label="Yes"
                                labelPlacement="end"
                            />
                            <FormControlLabel
                                value="no"
                                control={<Radio datatest={'no'} color="secondary" />}
                                label="No"
                                labelPlacement="end"
                            />
                        </RadioGroup>
                        <FormHelperText>With this setting enabled the language selection option is added and users will be able select a language.</FormHelperText>
                    </FormControl>
                    <div></div>


                </FieldContainer>

                {
                    settings.isMultilingual &&
                    <FieldContainer  style={{padding: '16px', marginTop : '16px'}}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Do you want to use different labels for each language on the site?</FormLabel>
                            <RadioGroup datatest={'isMultilingualUI'} style={{margin : '8px 0px'}} row aria-label="position" name="position" value={settings.isMultilingualUI === true ? "yes" : "no"} onChange={(event) => {
                                settings.isMultilingualUI = !settings.isMultilingualUI;
                                this.setupChanged();
                                this.setState({});

                            }}>
                                <FormControlLabel
                                    value="yes"
                                    control={<Radio datatest={'yes'} color="secondary" />}
                                    label="Yes"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="no"
                                    control={<Radio datatest={'no'} color="secondary" />}
                                    label="No"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                            <FormHelperText>With this setting enabled you will be able to configure labels in multiple languages.</FormHelperText>

                        </FormControl>


                    </FieldContainer>
                }

            </Grid>
            {
                settings.isMultilingual
                    ? this.renderLanguagesConfiguration()
                    : this.renderDefaultLanguage()
            }
        </GridContainer>;

    }

}

LanguageSetup.propTypes = {
    settings: PropTypes.object,
    onChange: PropTypes.func,
    stepName: PropTypes.string
};

export default withStyles(styles, {withTheme: true})(LanguageSetup);

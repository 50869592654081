import green from "@material-ui/core/colors/green";
import {PRIMARY_COLOR} from "../Constants";

const drawerWidth = 375;

export function styles(theme) {
  return {
    root: {
      overflow: "hidden",

    },


    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      marginTop: -24,
      paddingLeft: 14,
      paddingRight: 14
    },
    toolbar: theme.mixins.toolbar,
    menuButton: {
      marginLeft: -18,
      marginRight: 10,
    },
    treeTopButton: {
      margin: 5
    },
    paper: {
      padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    control: {
      padding: theme.spacing(2),
    },
    viewCard: {
      minWidth: 275,
    },
    buttonProgress: {
      color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    searchRoot: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      width: '95%',
      margin: '5px'
    },
    searchInput: {
      marginLeft: 8,
      flex: 1,
    },
    searchIconButton: {
      padding: 10,
    },
    margin: {
      margin: theme.spacing(1),
    },
    button: {
      margin: theme.spacing(1),
    },
    badgeMargin: {
        margin: theme.spacing(2),
    },
    badgePadding: {
        padding: `0 ${theme.spacing(2)}px`,
    },
    treeButton: {
      margin: '0px',
      padding: '0px'
    },
    treeFont : {
      fontFamily : theme.typography.fontFamily
    },
    tabItem : {
      minWidth : 50
    },
    dialog: {
    },
    conceptsTopLevel: {
      left: 10,
      width: 'calc(32.5% - 10px)',
      position: 'absolute',
      bottom: 0,
      top: 190,
      overflowX: 'hidden',
      overflowY: 'scroll'
    },
    conceptDetail: {
      right: 5,
      bottom: 0,
      top: 190,
      paddingRight: 20,
      overflowX: 'hidden',
      overflowY: 'scroll',
      width: 'calc(67.5% - 30px)',
      position: 'absolute'
    },
    slatStyle: {
      textDecoration: 'none',
      display: 'inline-block',
      border: 'solid 1px #ddd',
      borderRadius: '5px',
      padding: '3px 8px 3px 8px',
      marginTop: '8px',
      minHeight: '30px',
      marginBottom: '0px',
      width: 'calc(100% - 24px)',
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#eee'
      }
    },

    slatContainer: {},
    conceptInfo: {
        height: 'calc(100% - 22px)',
        padding: 10,
        backgroundColor: '#f8f8f8'
    },
      cardActionButton: {
          right: 0,
          float: 'right'
    },
      cardFooterActionButton: {
          right: 0,
          marginRight: '-8px',
          float: 'right'
      },
      detailsActionButton: {
          right: 0,
          float: 'right'
      }
      ,
      appBarClose: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#eee'
        },
        borderLeft:'2px solid #f1f1f1',
        marginTop:'12px',
        padding:'0px 4px',  position: 'absolute', right: 0, overflow: "hidden", zIndex: 1110
      },
    smallWidthLabelRootUnset: {
    },
      smallWidthLabelRoot: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '40px',
        fontSize : '14px',
        minHeight : '15px'
      },
      smallWidthPropertyLabelRoot: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '80px',
        fontSize : '14px',
        minHeight : '15px'
      },
      smallWidthPrecedenceLabelRoot: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '100px',
        fontSize : '14px',
        minHeight : '15px'
      },
      largeWidthPropertyLabelRoot: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        width: '180px',
        fontSize : '14px',
        minHeight : '15px'
      },

      smallWidthLabelShrink: {
        width: '100%',
        color: PRIMARY_COLOR,

      },
    svgIcon : {
      color: theme.palette.grey.level3,
      cursor : 'pointer',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        borderRadius : '4px',
      }
    }
  };
}


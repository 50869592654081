import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../../components/styles";
import {
    centerVertically,
    flatten,
    getApiConfigurationData,
    getDatasetLabel,
    getGraph,
    getRouteWithInstanceAndDataset,
    getSearchResult,
    handleAPIResponse,
    handleBackendError,
    isRequestSuccessful,
    sort,
    toArray
} from "../../components/util";
import AllPartsLayout from "../AllPartsLayout";
import {getHeader} from "../../components/header/APIPlaygroundHeader";
import {Grid, IconButton, Tooltip} from "@material-ui/core";
import {
    ALIAS_MANAGEMENT_DESCRIPTION,
    ALIAS_MANAGEMENT_FOR_APPLICATION,
    ALIAS_MANAGEMENT_HAS_PART,
    ALIAS_MANAGEMENT_ID_LABEL,
    ALIAS_MANAGEMENT_LINKED_TO,
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TYPE_CONFIGURATION,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    AT_CONTEXT,
    AT_GRAPH,
    COUNT_UPTO,
    DATA,
    EASYGRAPH_DATA_APPLICATION_EXPLORER,
    EASYGRAPH_DATA_NAMESPACE,
    HTTP_HEADER_PREFER,
    ID,
    IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE,
    LABEL_IMPORT_SITE,
    MIXIN,
    PAGE,
    PAGE_SIZE,
    PATHS,
    QUERY,
    ROUTE_APPS_EXPLORER_SITE,
    ROUTE_SITE,
    SORT_BY,
    STYLE_GRID_ITEM_SPACING,
    STYLE_MAIN_SPACING,
    TYPE
} from "../../Constants";
import {
    deleteManagementGraph,
    getAllConfigurations,
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    patchManagementGraph,
    postManagementGraph
} from "../../service/graph-api";
import CollectionCard from "../../components/CollectionCard";
import PropTypes from "prop-types";
import history from "../../history";
import qs from "qs";
import List from '@material-ui/core/List';
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import PublishIcon from "@material-ui/icons/Publish";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import {DashBoardSearch} from "../../components/DashBoardSearch";
import {LeftMenuIconButton} from "../../components/LeftMenuIconButton";
import {LeftMenuToggleButton} from "../../components/LeftMenuToggleButton";
import {
    BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION,
    BACKEND_PATH_MANAGEMENT_DATASET_SEARCH,
    BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH
} from "../../service/backend-paths";
import SiteDetailsDialog from "./SiteDetailsDialog";
import {createRequest} from "../apiplayground/Examples";
import {buildSearchRequestOptions} from "../apiplayground/SearchRequest";
import {LANGUAGES} from "../../components/LanguagesList";
import {DEFAULT_UI_LABELS, UI_LABELS} from "./UILabel";
import {isMultipleDatasetsInstance} from "../../Configs";
import {GreyEditIcon, GreyGetAppIcon} from "../../components/GreyStyleIcon";
import fileDownload from "js-file-download";
import ImportSiteDialog from "./ImportSiteDialog";
import {getImageURL} from "./BasicSetup";
import {isObject} from "lodash";
import {GRAPH_VIEW_BACKGROUND_IMAGE, GRAPH_VIEW_SETTINGS} from "./DataViewSetup";
import {getAllVisualisationsData} from "./GraphView";

export const EXPLORER = 'Explorer';


export function createObjectId(key) {
    return EASYGRAPH_DATA_NAMESPACE + 'object/' + key;
}

export const WORKSPACE_PREFER_HEADER_VALUE = `return=representation;${MIXIN}="{ ${ID} ${ALIAS_SYS_ETAG} ${TYPE} ${ALIAS_MANAGEMENT_NAME} ${ALIAS_MANAGEMENT_DESCRIPTION}}";${DATA}=${PATHS}`;

export function getFaviconImageURL(parsedSettings) {
    return parsedSettings['favicon']?.['imageURL'];
}

export function setFaviconImageURL(parsedSettings, value) {
    if(!parsedSettings['favicon']) {
        parsedSettings['favicon'] = {};
    }
    parsedSettings['favicon']['imageURL'] = value;
}

export function getLogoImageURL(parsedSettings) {
    return parsedSettings['logo']?.['imageURL'];
}

export function setLogoImageURL(parsedSettings, value) {
    if(!parsedSettings['logo']) {
        parsedSettings['logo'] = {};
    }
    parsedSettings['logo']['imageURL'] = value;
}


export function getBannerImageURL(parsedSettings) {
    return parsedSettings['banner']?.['imageURL'];
}

export function getWhiteBackgroundImageURL(parsedSettings) {
    return parsedSettings['logo']?.['whiteBackgroundImageURL'];
}

export function setWhiteBackgroundImageURL(parsedSettings, value) {
    if(!parsedSettings['logo']) {
        parsedSettings['logo'] = {};
    }
    parsedSettings['logo']['whiteBackgroundImageURL'] = value;
}


export function setBannerImageURL(parsedSettings, value, langKey) {
    if(!parsedSettings['banner']) {
        parsedSettings['banner'] = {};
    }
    if(!parsedSettings['banner']['imageURL']) {
        parsedSettings['banner']['imageURL'] = {};
    }
    if(langKey) {
        parsedSettings['banner']['imageURL'][langKey] = value;
    } else {
        parsedSettings['banner']['imageURL'] = value;
    }
}


export function setLinkedToId(obj, linkedToId) {

    let linkedToArray = toArray(linkedToId);
    if(linkedToArray.length > 1) {
        throw new Error("Only one value allowed for linking");
    }
    obj[ALIAS_MANAGEMENT_LINKED_TO] = linkedToArray[0];
}

class Workspaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workspaces: [],
            minimized: true,
            searchText: ''
        }
    }

    async componentDidMount() {
        await this.syncDataWithBackend()
    }

    syncDataWithBackend = async () => {
        this.setState({loading: true})
        if(isMultipleDatasetsInstance()) {
            const apiResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET_SEARCH).catch(handleBackendError(this));
            handleAPIResponse(
                this,
                apiResponse,
                () => {
                    apiResponse.json().then((response) => {
                        let datasets = getSearchResult(response);
                        this.setState({datasets : datasets});
                    }).catch((e) => {
                        this.setState({loading: false, apiErrorResponse: e, apiError: true});
                    })
                },
                handleBackendError(this)
            )

        }
        getAllConfigurations().then((response) => {

            this.syncWorkspaces().then(r => {
                this.setState({
                    configurations: response,
                    loading: false
                })
            }).catch((e) => {
                this.setState({loading: false, apiErrorResponse: e, apiError: true});
            })
        }).catch((e) => {
            this.setState({loading: false, apiErrorResponse: e, apiError: true});
        })

    }

    syncWorkspaces = async () => {
        let query = `{type(eq:["${ALIAS_MANAGEMENT_TYPE_CONFIGURATION}"]) ${ALIAS_MANAGEMENT_FOR_APPLICATION}(eq:"${EASYGRAPH_DATA_APPLICATION_EXPLORER}")}`;
        if(isMultipleDatasetsInstance()) {
            let found = this.getDataset();
            if (found) {
                let datasetId = found[ID];
                query = `{type(eq:["${ALIAS_MANAGEMENT_TYPE_CONFIGURATION}"]) ${ALIAS_MANAGEMENT_LINKED_TO}(eq:"${datasetId}") ${ALIAS_MANAGEMENT_FOR_APPLICATION}(eq:"${EASYGRAPH_DATA_APPLICATION_EXPLORER}")}`;
            } else {
                this.setState({
                    loading: false,
                    apiErrorResponse: 'Something has gone wrong with dataset selection.',
                    apiError: true
                });
                return;
            }
        }

        const searchParams = {
            [QUERY] : query,
            [SORT_BY] : `{${ALIAS_MANAGEMENT_NAME}}`,
            [PAGE] : 1,
            [PAGE_SIZE] : 1000,
            [COUNT_UPTO] : 1000
        }
        let headerMap = {
            [HTTP_HEADER_PREFER] : WORKSPACE_PREFER_HEADER_VALUE
        }
        const sites = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams, headerMap).catch(handleBackendError(this));
        if(isRequestSuccessful(sites)) {
            sites.json().then(json => {
                this.setState({
                    workspaces: getSearchResult(json), loading: false,
                })
            })
        } else {
            this.setState({loading: false, apiErrorResponse: sites, apiError: true});
        }
    }

    handleImportModel = (modelDetails, data) => {
    }

    openDialog = (key) => {
        return () => this.setState({[key]: true});
    }

    addNewWorkspace = (obj) => {
        const {configurations} = this.state;
        this.setState({loading : true});
        obj[AT_CONTEXT] = getManagementContextURL();
        obj[TYPE] = ALIAS_MANAGEMENT_TYPE_CONFIGURATION;
        obj[ALIAS_MANAGEMENT_FOR_APPLICATION] = EASYGRAPH_DATA_APPLICATION_EXPLORER;
        if(isMultipleDatasetsInstance()) {
            let found = this.getDataset();
            if(found) {
                let linkedToId = found[ID];
                setLinkedToId(obj, linkedToId);
            } else {
                this.setState({
                    loading: false,
                    showCreateModelDialog: false,
                    apiErrorResponse: 'Something has gone wrong with dataset selection.',
                    apiError: true
                });
                return;
            }
        }
        let apiConfigurationData = getApiConfigurationData(configurations);
        let defaultLanguage = LANGUAGES.find(l => l.value === 'en');
        defaultLanguage.isDefault = true;
        let settings = {
            searchRequestObject: {
                ...createRequest(
                    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
                    undefined,
                    apiConfigurationData[ID],
                    {}
                ),
                ...buildSearchRequestOptions(),
            },
            pages: [],
            browseLanguages: [defaultLanguage],
            homePage : {},
            banner : {
                showOnAllPages : true
            },
            labelProperties : {},
            [UI_LABELS] : DEFAULT_UI_LABELS
        }
        obj[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings)

        postManagementGraph(JSON.stringify(obj)).then(d => {
            if(d.status === 200) {
                this.setState({
                    loading: false,
                    showCreateModelDialog: false
                }, () => this.navigateToWorkspace(obj[ALIAS_MANAGEMENT_NAME]));
            } else {
                this.setState({
                    loading: false,
                    showCreateModelDialog: false,
                    apiErrorResponse: d,
                    apiError: true
                });

            }
        })
    }

    getDataset = () => {
        let {datasets} = this.state;
        let datasetLabel = getDatasetLabel();
        let found = datasets.find(dt => dt[ALIAS_MANAGEMENT_ID_LABEL] === datasetLabel);
        return found;
    }

    deleteWorkspace = async (coll) => {
        this.setState({loading: true});
        let workspaceId = coll[ID];
        let {pageWorkspaces, name} = await this.getAllWorkspaceData(workspaceId);

        await deleteManagementGraph({
            [AT_CONTEXT] : getManagementContextURL(),
            [AT_GRAPH] : [
                pageWorkspaces.filter(pw => pw).map(pw => {
                    return {
                        [ID] : pw[ID],
                        [ALIAS_SYS_ETAG] : pw[ALIAS_SYS_ETAG]
                    }
                })
            ]
        });
        await this.syncWorkspaces();
        this.setState({loading: false});
    }

    saveWorkspace = (obj, workspace) => {
        obj[AT_CONTEXT] = getManagementContextURL();
        obj[ID] = workspace[ID];
        obj[ALIAS_SYS_ETAG] = workspace[ALIAS_SYS_ETAG];
        return new Promise( resolve => {
            patchManagementGraph(JSON.stringify(obj)).then(async response => {
                if(isRequestSuccessful(response)) {
                    await this.syncWorkspaces();
                    resolve();
                } else {
                    this.setState({loading: false, apiError : true , apiErrorResponse : response});
                }
            })
        })
    }

    navigateToWorkspace = (id) => {
        history.push( `${getRouteWithInstanceAndDataset(ROUTE_APPS_EXPLORER_SITE)}/${id }`)
    }

    search = () => {
        let {searchText, workspaces} = this.state;
        let normalised = searchText.toLowerCase();
        if(normalised) {
            let found = workspaces.filter(c => c[ALIAS_MANAGEMENT_NAME] && c[ALIAS_MANAGEMENT_NAME].toLowerCase().includes(normalised));
            if(found.length === 0) {
                this.setState({noResults: true});
            }
            this.setState({workspaces: found});
        } else {
            this.setState({noResults: undefined});
            this.syncWorkspaces();
        }
    }

    getSearchComponent = () => {
        let { searchText } = this.state;
        return <DashBoardSearch
            placeholder={'Find'}
            onSearch={this.search}
            onSearchTextChange={(val, event, callBack) => this.setState({searchText: val}, callBack)}
            searchText={searchText}
        /> ;
    }

    showGetStartedButton = () => {
        let {theme} = this.props;
        let {workspaces, noResults, configurations} = this.state;
        if(noResults) {
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>No site found.</div>
            </div>;
        }
        if(configurations && (!workspaces || workspaces.length <= 0)) {
            let button = <Button datatest={'addNewSiteButton'} id={'addNewSiteButton'} startIcon={<AddIcon/>} variant="contained" color="secondary"
                          onClick={this.openDialog('showCreateModelDialog')}>
                     Add New Site
                </Button>;
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div datatest={'getStartedMessage'} style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>Add a site to get started</div>
                {button}
            </div>;
        } else {
            return <></>;
        }
    }

    getWorkspaceAndLinkedWorkspaces = async (id) => {
        const workspaceSearchParams = {
            [ID] : id
        }
        let headerMap = {};
        const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, headerMap);
        if(!isRequestSuccessful(workspaceSearchResult)) {
            return Promise.reject(workspaceSearchResult);
        }
        let workspaceJSON = await workspaceSearchResult.json();
        let pageWorkspace = getSearchResult(workspaceJSON).find(obj => obj[ID] === id);
        let all = toArray(pageWorkspace?.[ALIAS_MANAGEMENT_HAS_PART]).filter(p => p).map(p => this.getWorkspaceAndLinkedWorkspaces(p));
        let parts = await Promise.all(all);
        return [pageWorkspace, ...flatten(parts)];
    }


    exportWorkspace = async (workspaceId)  => {
        this.setState({loading : true});
        let {pageWorkspaces, name} = await this.getAllWorkspaceData(workspaceId);
        let data = JSON.stringify(pageWorkspaces, null, 4);
        fileDownload(data, 'site_' +name +"_"+new Date().toISOString() + "." + 'json')
        this.setState({loading : false});
    };


    async getAllWorkspaceData(workspaceId) {
        let pageWorkspaces = await this.getWorkspaceAndLinkedWorkspaces(workspaceId);
        let rootWorkspace = pageWorkspaces.find(ws => ws[ID] === workspaceId);
        let visualisationData = await getAllVisualisationsData(workspaceId);
        if(visualisationData.length > 0) {
            visualisationData.forEach(v =>pageWorkspaces.push(v));
        }
        let name = rootWorkspace[ALIAS_MANAGEMENT_NAME];
        //get objects used in the settings
        let rootWorkspaceSettings = rootWorkspace[ALIAS_MANAGEMENT_SETTINGS];
        let parsedSettings = JSON.parse(rootWorkspaceSettings);
        let faviconImageURL = getFaviconImageURL(parsedSettings);
        await this.addObjects(faviconImageURL, pageWorkspaces);
        let logoImageURL = getLogoImageURL(parsedSettings);
        await this.addObjects(logoImageURL, pageWorkspaces);
        let whiteBackgroundImageURL = getWhiteBackgroundImageURL(parsedSettings);
        await this.addObjects(whiteBackgroundImageURL, pageWorkspaces);
        let bannerImageURL = getBannerImageURL(parsedSettings);
        if (isObject(bannerImageURL)) {
            for (const [key, value] of Object.entries(bannerImageURL)) {
                let imageURL = value;
                await this.addObjects(imageURL, pageWorkspaces);
            }
        }
        if(parsedSettings?.labelProperties) {
            for (const [key, value] of Object.entries(parsedSettings.labelProperties)) {
                let classSettings = value;
                let classImageURL = classSettings?.[GRAPH_VIEW_SETTINGS]?.[GRAPH_VIEW_BACKGROUND_IMAGE];
                if(classImageURL) {
                    await this.addObjects(classImageURL, pageWorkspaces);
                }
            }
        }

        return {pageWorkspaces, name};
    }

    async addObjects(imageURL, pageWorkspaces) {
        if (imageURL) {
            let imageURLValue = imageURL.startsWith('url(') ? getImageURL(imageURL) : imageURL;
            let parsedURL = null;
            try {
                parsedURL = new URL(imageURLValue);
            } catch (e) {

            }
            if(parsedURL) {
                let urlSearchParams = parsedURL.searchParams;
                let key = urlSearchParams.get('key');
                if (key) {
                    let query = `{${ID}(eq:"${createObjectId(key)}")}`;
                    const searchParams = {
                        [QUERY]: query,
                        [PAGE_SIZE]: 1,
                        [COUNT_UPTO]: 1
                    }
                    let headerMap = {}
                    const objectSearchResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams, headerMap).catch(handleBackendError(this));
                    const objectSearchResponseJson = await objectSearchResponse.json();
                    let searchResult = getSearchResult(objectSearchResponseJson)[0];
                    pageWorkspaces.push(searchResult);
                }
            }
        }
    }

    getMiddleComponent = () => {
        const { width, classes, theme } = this.props;
        let { loading, noResults, minimized, workspaces, configurations } = this.state;
        let lg = minimized ? 3 : 4
        let md = minimized ? 3 : 4
        let sm = minimized ? 6 : 12
        let xs = 12
        let itemPadding = theme.spacing(STYLE_MAIN_SPACING) - (theme.spacing(STYLE_GRID_ITEM_SPACING) / 2 )
        return <div>
            <div style={{display: 'flex', paddingRight: itemPadding + 10}}>
                <div style={{flexGrow: 1}}></div>
                {
                    loading === true && <ProcessingBackdrop marginLeft={true} loading={true}/>
                }
            </div>
            <div style={{margin : '0px 0px 0px 20px'}}>
            <Grid xs container spacing={STYLE_GRID_ITEM_SPACING} justify="space-evenly">

                {
                    ((workspaces && workspaces.length > 0) || noResults === true) &&
                    <>
                        <Grid style={{paddingTop: '4px'}} justify={'flex-start'} container item xs={12} sm={6}>
                            {this.getSearchComponent()}
                        </Grid>
                        <Grid style={{paddingTop: '4px'}} justify={'flex-end'} container item xs={12} sm={6}>
                            {
                                centerVertically(
                                    <Button
                                        datatest={'addNewSiteButtonRight'}
                                        size={"medium"}
                                        startIcon={<AddIcon/>}
                                        color="secondary"
                                        onClick={this.openDialog('showCreateModelDialog')}
                                        variant={'contained'}>Add New Site</Button>
                                )
                            }
                        </Grid>
                    </>
                }
                {configurations && this.showGetStartedButton()}
                {
                sort(workspaces).map(obj => {
                    return  <Grid key={obj[ID]} item>
                        <CollectionCard
                            key={obj[ID]}
                            deleteConfirmMessage={'Do you really want to delete the site'}
                            url={`${ROUTE_SITE}?${qs.stringify({ id: obj.id })}`}
                            title={obj[ALIAS_MANAGEMENT_NAME]}
                            description={obj[ALIAS_MANAGEMENT_DESCRIPTION]}
                            onDelete={() => this.deleteWorkspace(obj)}
                            onOpen={() => this.navigateToWorkspace(obj[ALIAS_MANAGEMENT_NAME])}
                            actions={
                                <>
                                    <Tooltip title={'Edit name & description'}>
                                        <IconButton datatest={'editButton'} size={'small'} onClick={() => this.setState({showEditModelDialog : obj})}>
                                            <GreyEditIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={'Export'}>
                                        <IconButton datatest={'exportButton'} size={'small'} onClick={() => this.exportWorkspace(obj[ID])}>
                                            <GreyGetAppIcon/>
                                        </IconButton>
                                    </Tooltip>
                                </>
                            }
                        />
                    </Grid>;
                })
                }
            </Grid>
            </div>
        </div>;
    }

    getLeftIconButton = (icon, title, onClick) => {
        let {minimized} = this.state
        let {theme} = this.props
        return <LeftMenuIconButton theme={theme} title={title} onClick={onClick} icon={icon} minimized={minimized}/>;
    }

    getLeftButton = (icon, title, dialogKey) => {
        return this.getLeftIconButton(icon, title, this.openDialog(dialogKey));
    }

    isTestOntology = () => {
        let {configurations} = this.state;
        let found = getGraph(configurations).find(o => o[ID] === IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE);
        return found === undefined ? false : true;
    }

    getLeftComponent = () => {
        let {theme} = this.props
        let {minimized} = this.state
        let iconStyle =  {color:theme.palette.white.main}
        let width = minimized ? '52px' : '275px'
        return <>
            <LeftMenuToggleButton theme={theme} minimized={minimized} onClick={() => this.setState({minimized: !minimized})}/>
            <div style={{height : '100%', width : width, backgroundColor : theme.palette.secondary.dark}}>
                <List style={{padding: '0px'}}>
                    {
                        this.getLeftButton(
                            <PublishIcon style={iconStyle}/>,
                            LABEL_IMPORT_SITE,
                            'openImportSiteDialog'
                        )
                    }
                </List>
            </div>
        </>;
    }

    render() {
        let {location, theme} = this.props
        let {minimized} = this.state
        let {openImportSiteDialog, showCreateModelDialog, showEditModelDialog} = this.state
        let maxWidth = minimized ? '77px' : '300px';


        return (<>
            <AllPartsLayout
                apiError={this.state.apiError}
                apiErrorResponse={this.state.apiErrorResponse}
                onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                header={getHeader(EXPLORER)}
                middleStyle={{paddingLeft : '0px', paddingTop: '24px'}}
                middleComponent={this.getMiddleComponent()}
                leftStyle={{ backgroundColor : theme.palette.grey.background, width : maxWidth, border : 'none', padding: '0'}}
                leftComponentStyle={{padding: '0', height: '100%'}}
                leftComponentContainerStyle={{padding: '0', overflow: 'hidden'}}
                leftComponentScroll={{y: 'auto'}}
                leftComponent={this.getLeftComponent()}
                {...this.props}
            />
            {
                showCreateModelDialog === true
                && <SiteDetailsDialog
                    open={showCreateModelDialog}
                    title={'Add New Site'}
                    handleOk={(obj) => this.addNewWorkspace(obj)}
                    handleCancel={() => this.setState({showCreateModelDialog:false})}
                />
            }
            {
                showEditModelDialog !== undefined
                && <SiteDetailsDialog
                    dataset={isMultipleDatasetsInstance() && this.getDataset()}
                    name={showEditModelDialog[ALIAS_MANAGEMENT_NAME]}
                    description={showEditModelDialog[ALIAS_MANAGEMENT_DESCRIPTION]}
                    open={true}
                    title={'Edit Site Details'}
                    addButtonTitle={'Save'}
                    handleOk={ async (obj) => {
                        this.setState({showEditModelDialog:undefined});
                        this.setState({loading : true});
                        await this.saveWorkspace(obj, showEditModelDialog);
                        this.setState({loading : false});
                    }}
                    handleCancel={() => this.setState({showEditModelDialog:undefined})}
                />
            }
            {
                openImportSiteDialog === true
                && <ImportSiteDialog
                    dataset={isMultipleDatasetsInstance() ? this.getDataset() : undefined}
                    open={openImportSiteDialog}
                    onClose={async () => {
                        this.setState({openImportSiteDialog : undefined, loading : true});
                        await this.syncWorkspaces();
                        this.setState({loading : false});
                    }}
                />
            }
        </>
    );
    }
}

Workspaces.propTypes = {
    location: PropTypes.object,
};

export default withWidth()(withStyles(styles, {withTheme: true})(Workspaces));

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../../components/styles";
import {
    centerVertically,
    getRouteInstancePath,
    getRouteWithInstanceAndDataset,
    getSearchResult,
    getSystemContextURL,
    handleAPIResponse,
    handleBackendError,
    sort
} from "../../components/util";
import {Grid} from "@material-ui/core";
import {
    ALIAS_MANAGEMENT_DESCRIPTION,
    ALIAS_MANAGEMENT_ID_LABEL,
    ALIAS_MANAGEMENT_TYPE_DATASET,
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_ID_LABEL,
    AT_CONTEXT,
    ID,
    ROUTE_MANAGEMENT_DATASET,
    ROUTE_MANAGEMENT_HOME,
    STYLE_GRID_ITEM_SPACING,
    TYPE
} from "../../Constants";
import {
    deleteData,
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    patchData,
    postData
} from "../../service/graph-api";
import CollectionCard from "../../components/CollectionCard";
import PropTypes from "prop-types";
import history from "../../history";
import qs from "qs";
import {getModels, storeModelAndData} from "../../service/model-api";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import ImportModelDialog from "../../layouts/modelbuilder/ImportModelDialog";
import {DashBoardSearch} from "../../components/DashBoardSearch";
import {LeftMenuIconButton} from "../../components/LeftMenuIconButton";
import AddDatasetDialog from "../../layouts/management/AddDatasetDialog";
import {GreyEditIcon} from "../../components/GreyStyleIcon";
import IconButton from "../../components/IconButton";
import {isSuperadmin, setSelectedDataset} from "../../layouts/common/Profile";
import {isMultipleDatasetsInstance} from "../../Configs";
import {getLeftMenuItems} from "./AdminHome";
import MenuColumnAndDetailsLayout from "../../layouts/MenuColumnAndDetailsLayout";
import {
    BACKEND_PATH_MANAGEMENT_DATASET,
    BACKEND_PATH_MANAGEMENT_DATASET_SEARCH,
    BACKEND_PATH_MANAGEMENT_INSTANCE_CONFIGURATION
} from "../../service/backend-paths";

export async function addDataset(callerThis, obj, onSuccess) {
    let datasetName = obj[ALIAS_SYS_ID_LABEL];
    let model = {
        [AT_CONTEXT]: getManagementContextURL(),
        [TYPE]: ALIAS_MANAGEMENT_TYPE_DATASET,
        [ALIAS_MANAGEMENT_ID_LABEL]: datasetName,
        [ALIAS_MANAGEMENT_DESCRIPTION]: obj[ALIAS_SYS_DESCRIPTION]
    }
    let postResponse = await postData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET, model).catch(callerThis.handleBackendError);
    return handleAPIResponse(
        callerThis,
        postResponse,
        () => {
            callerThis.setState({showCreateDatasetDialog: false})
            onSuccess && onSuccess();
            return {};
        },
        () => {
            return postResponse;
        }
    )
}

class Datasets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            datasets: null,
            minimized: true,
            searchText: ''
        }
        //setCookie(COOKIE_DATASET, "");
    }

    async componentDidMount() {
        this.setState({loading: true});
        const instanceConfigurationResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_INSTANCE_CONFIGURATION).catch(handleBackendError(this));
        handleAPIResponse(
            this,
            instanceConfigurationResponse,
            () => {
                instanceConfigurationResponse.json().then((response) => {
                    let instanceConfiguration = response;
                    this.setState({instanceConfiguration: instanceConfiguration}, this.syncDataWithBackend);
                }).catch((e) => {
                    this.setState({loading: false, apiErrorResponse: e, apiError: true});
                })

            },
            handleBackendError(this)
        )

    }

    handleBackendError = (event) => {
        this.setState({loading: false, apiErrorResponse: event, apiError: true});
    }


    syncDataWithBackend = async () => {
        if (isMultipleDatasetsInstance()) {
            this.setState({loading: true});
            const apiResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET_SEARCH).catch(handleBackendError(this));
            handleAPIResponse(
                this,
                apiResponse,
                () => {
                    apiResponse.json().then((response) => {
                        let datasets = sort(getSearchResult(response), ALIAS_MANAGEMENT_ID_LABEL);
                        this.setState({datasets: datasets, loading: false});
                    }).catch((e) => {
                        this.setState({loading: false, apiErrorResponse: e, apiError: true});
                    })
                },
                handleBackendError(this)
            )
        } else {
            this.setState({loading: false, datasets: []});
        }
    }

    handleImportModel = (modelDetails, data) => {
        storeModelAndData(modelDetails, data)
        this.setState({datasets: getModels()})
    }

    openDialog = (key) => {
        return () => this.setState({[key]: true});
    }

    editDataset = async (newObj) => {
        let {dataset} = this.state;
        let model = {
            [AT_CONTEXT]: getSystemContextURL(),
            [ID]: dataset[ID],
            [TYPE]: ALIAS_MANAGEMENT_TYPE_DATASET,
            [ALIAS_SYS_ETAG]: dataset[ALIAS_SYS_ETAG],
            [ALIAS_MANAGEMENT_DESCRIPTION]: newObj[ALIAS_SYS_DESCRIPTION]
        }
        let patchResponse = await patchData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET, model).catch(handleBackendError(this));
        return handleAPIResponse(
            this,
            patchResponse,
            () => {
                this.setState({dataset: undefined, showEditDatasetDialog: false});
                this.syncDataWithBackend();
                return {};
            }
        )
    }


    handleAPIResponse = (res, onNon5xxResponse) => {
        if (res.status >= 500) {
            try {
                res.json().then((j) => {
                    this.setState({loading: false, apiErrorResponse: j, apiError: true})
                })
            } catch (e) {
                this.setState({loading: false, response: res, apiError: true})
            }
        } else {
            onNon5xxResponse();
        }
    }

    deleteDataset = async (dataset) => {
        this.setState({loading:true})
        let data = {
            [AT_CONTEXT]: getSystemContextURL(),
            ...dataset
        }
        let deleteResponse = await deleteData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_DATASET, data).catch(this.handleBackendError);
        handleAPIResponse(
            this,
            deleteResponse,
            this.syncDataWithBackend
        )
    }

    navigateToId = (dataset) => {
        let datasetLabel = dataset[ALIAS_MANAGEMENT_ID_LABEL];
        let path = isMultipleDatasetsInstance()
            ? `${getRouteInstancePath()}/dataset/${datasetLabel}${ROUTE_MANAGEMENT_HOME}`
            : `${getRouteInstancePath()}${ROUTE_MANAGEMENT_HOME}`;
        history.push(path);
        setSelectedDataset(datasetLabel);
    }

    search = () => {
        let {searchText, datasets} = this.state;
        let normalised = searchText.toLowerCase();
        if (normalised) {
            let found = datasets.filter(c => c[ALIAS_MANAGEMENT_ID_LABEL] && c[ALIAS_MANAGEMENT_ID_LABEL].toLowerCase().includes(normalised));
            if (found.length === 0) {
                this.setState({noResults: true});
            }
            this.setState({datasets: found});
        } else {
            this.setState({noResults: undefined});
            this.syncDataWithBackend().then(s => {});
        }
    }

    getSearchComponent = () => {
        let {searchText} = this.state;
        return <DashBoardSearch
            placeholder={'Find a dataset'}
            onSearch={this.search}
            onSearchTextChange={(val, event, callBack) => this.setState({searchText: val}, callBack)}
            searchText={searchText}
        />;
    }

    showGetStartedButton = () => {
        let {theme} = this.props;
        let {datasets, noResults} = this.state;
        if (noResults) {
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>No dataset found.</div>
            </div>;
        }
        if (isSuperadmin() && (!datasets || datasets.length <= 0)) {
            let button = <Button id={'addNewDatasetButton'} startIcon={<AddIcon/>} variant="contained" color="secondary"
                                 onClick={this.openDialog('showCreateDatasetDialog')}>
                Add New Dataset
            </Button>;
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div datatest={'getStartedMessage'} style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>Add a
                    dataset to get started
                </div>
                {button}
            </div>;
        } else if (!isSuperadmin() && (!datasets || datasets.length <= 0)) {
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>No dataset found. Make sure you have
                    permission to view datasets.
                </div>
            </div>;
        } else {
            return <></>;
        }
    }

    getMiddleComponent = () => {
        let {loading, noResults, datasets, instanceConfiguration} = this.state;

        return <div style={{height: 'calc(100% - 48px)',display :'flex', flexDirection : 'column'}}>
            <div>
                {
                    loading === true && <ProcessingBackdrop marginLeft={true} loading={true}/>
                }
                {
                    !instanceConfiguration || !datasets
                        ? <></>
                        : <Grid xs container spacing={2} justify="space-evenly">
                            {
                                ((datasets && datasets.length > 0) || noResults === true) &&
                                <>
                                    <Grid style={{paddingTop: '0px'}} justify={'flex-start'} container item xs={12} sm={6}>
                                        {centerVertically(this.getSearchComponent())}
                                    </Grid>
                                    <Grid style={{paddingTop: '0px'}} justify={'flex-end'} container item xs={12} sm={6}>
                                        {isSuperadmin() &&
                                        centerVertically(
                                            <Button
                                                datatest={'addNewDatasetButtonRight'}
                                                size={"medium"}
                                                startIcon={<AddIcon/>}
                                                color="secondary"
                                                onClick={this.openDialog('showCreateDatasetDialog')}
                                                variant={'contained'}>Add New Dataset</Button>
                                        )
                                        }
                                    </Grid>
                                </>
                            }
                            {isMultipleDatasetsInstance() && this.showGetStartedButton()}
                            {
                                isMultipleDatasetsInstance() && sort(datasets).map(dataset => {
                                    return <Grid key={dataset[ID]} item>
                                        <CollectionCard
                                            key={dataset[ID]}
                                            deleteConfirmMessage={'Do you really want to delete the dataset'}
                                            url={`${getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_DATASET)}?${qs.stringify({id: dataset.id})}`}
                                            title={dataset[ALIAS_MANAGEMENT_ID_LABEL]}
                                            description={dataset[ALIAS_MANAGEMENT_DESCRIPTION]}
                                            onDelete={isSuperadmin() ? () => this.deleteDataset(dataset) : undefined}
                                            onOpen={() => this.navigateToId(dataset)}
                                            actions={isSuperadmin() ? [
                                                <IconButton datatest={'editButton'} onClick={() => this.setState({
                                                    dataset: dataset,
                                                    showEditDatasetDialog: true
                                                })} size="small">
                                                    <GreyEditIcon/>
                                                </IconButton>
                                            ] : []}
                                        />
                                    </Grid>;
                                })
                            }
                        </Grid>
                }
            </div>
        </div>;

    }

    getLeftIconButton = (icon, title, onClick) => {
        let {minimized} = this.state
        let {theme} = this.props
        return <LeftMenuIconButton theme={theme} title={title} onClick={onClick} icon={icon} minimized={minimized}/>;
    }


    getLeftButton = (icon, title, dialogKey) => {
        return this.getLeftIconButton(icon, title, this.openDialog(dialogKey));
    }


    render() {
        let {location, theme} = this.props
        let {minimized, dataset} = this.state
        let {openImportModelDialog, showCreateDatasetDialog, showEditDatasetDialog} = this.state
        let maxWidth = minimized ? '77px' : '300px';


        return (<>
                <MenuColumnAndDetailsLayout
                    apiError={this.state.apiError}
                    apiErrorResponse={this.state.apiErrorResponse}
                    onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                    headerTitle={'Datasets'}
                    leftMenuItems={getLeftMenuItems(theme)}
                    leftColumnRenderer={undefined}
                    detailRenderer={this.getMiddleComponent}
                    location={location}
                />

                {
                    openImportModelDialog === true
                    && <ImportModelDialog
                        open={openImportModelDialog}
                        location={location}
                        onClose={() => this.setState({openImportModelDialog: false})}
                        eventHandler={this.handleImportModel}
                    />

                }
                {
                    showCreateDatasetDialog === true
                    && <AddDatasetDialog
                        open={showCreateDatasetDialog}
                        title={'Add New Dataset'}
                        handleOk={(obj) => {
                            return addDataset(this, obj, this.syncDataWithBackend);
                        }}
                        handleCancel={() => this.setState({showCreateDatasetDialog: false})}
                    />
                }
                {
                    showEditDatasetDialog === true && dataset
                    && <AddDatasetDialog
                        edit={true}
                        name={dataset[ALIAS_MANAGEMENT_ID_LABEL]}
                        description={dataset[ALIAS_MANAGEMENT_DESCRIPTION]}
                        open={showEditDatasetDialog}
                        title={'Edit Dataset'}
                        addButtonTitle={'Save'}
                        handleOk={(obj) => this.editDataset(obj)}
                        handleCancel={() => this.setState({dataset: undefined, showEditDatasetDialog: false})}
                    />
                }
            </>
        );
    }
}

Datasets.propTypes = {
    location: PropTypes.object

};

export default withWidth()(withStyles(styles, {withTheme: true})(Datasets));

import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../components/styles";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import FooterButton from "../components/FooterButton";

function FooterNextButton(props) {
    let {theme, title, disabled, onClick, ...other} = props
    return <FooterButton
        {...other}
        id={'nextButton'}
        datatest={'nextButton'}
        variant={"contained"} color={"secondary"}
        disabled={disabled}
        onClick={onClick}
        endIcon={<ArrowForwardIcon/> }
    >{title || 'Next'}</FooterButton>;
}

FooterNextButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
    title: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(FooterNextButton);

import {LAYOUT_TIDYTREE} from "../navigator/GraphViewSettings";
import {
    DEFAULT_EDGE_COLOR,
    DEFAULT_EDGE_LINE_STYLE,
    DEFAULT_EDGE_WIDTH,
    DEFAULT_FONT_SIZE,
    DEFAULT_NODE_BORDER_COLOR,
    DEFAULT_NODE_BORDER_STYLE,
    DEFAULT_NODE_BORDER_WIDTH,
    DEFAULT_NODE_HEIGHT,
    DEFAULT_NODE_PADDING,
    DEFAULT_NODE_TEXT_HALIGN,
    DEFAULT_NODE_TEXT_JUSTIFICATION,
    DEFAULT_NODE_TEXT_VALIGN,
    DEFAULT_NODE_TEXT_WRAP,
    DEFAULT_NODE_WIDTH,
    DEFAULT_TAXI_TURN,
    EDGE_CURVE_STYLE_TAXI, ELEMENT_STYLE,
    GRAPH_VIEW_BACKGROUND_COLOR,
    GRAPH_VIEW_EDGE_CURVE_STYLE, NODE_TYPE_STYLE, PROPERTY_IRI_STYLE, STYLE
} from "./GraphViewStyleSettings";
import {sort, sortByFunction, toArray} from "../../components/util";
import cytoscape from "cytoscape";
import {GRAPH_VIEW_BACKGROUND_IMAGE, GRAPH_VIEW_PRECEDENCE, GRAPH_VIEW_SETTINGS} from "./DataViewSetup";


export function getDiagramLayout(data, options = {}) {
    if(options.name === LAYOUT_TIDYTREE) {
        return options;
    }
    let layout = {
        name: data.length > 0 ? 'fcose' : 'random',
        idealEdgeLength : 200,
        tilingPaddingVertical : 200,
        tilingPaddingHorizontal : 200,
        fit: true,
        ...options,
    };

    return layout;
}


function getEdgeCurveStyle(elem, layoutName) {
    const edgeCurveStyle = elem.data()[GRAPH_VIEW_EDGE_CURVE_STYLE];
    if(edgeCurveStyle) {
        return edgeCurveStyle;
    }
    if(layoutName === LAYOUT_TIDYTREE) {
        return EDGE_CURVE_STYLE_TAXI;
    }
    return 'bezier';
}

export function getDefaultTextVAlign(elem) {
    if (elem.data().thumbnail) {
        return 'bottom';
    }
    return DEFAULT_NODE_TEXT_VALIGN;
}

export function getDefaultShape(elem) {
    if (elem.data().thumbnail) {
        return undefined;
    }
    return 'round-rectangle';
}

export function getDefaultNodeWidth(elem) {
    if (elem.data().thumbnail) {
        return '80px';
    }
    return DEFAULT_NODE_WIDTH;
}

export function getDefaultNodeHeight(elem) {
    if (elem.data().thumbnail) {
        return '80px';
    }
    return DEFAULT_NODE_HEIGHT;
}

export function getDefaultBackgroudOpacity(elem) {
    if (elem.data()[GRAPH_VIEW_BACKGROUND_COLOR]) {
        return .2;
    }
    return undefined;
}

export function getDefaultBackgroundHeight(elem) {
    if (elem.data().thumbnail) {
        return '80%';
    }
    return undefined;
}

export function getDefaultBackgroudWidth(elem) {
    if (elem.data().thumbnail) {
        return '80%';
    }
    return undefined;
}

export function getDefaultBackgroundFit(elem) {
    if (elem.data().thumbnail) {
        //Trying this . With class icons it seems to work better without contain;
        //return 'contain';
    }
    return undefined;
}

export function getDefaultBackgroundImage(elem) {
    if (elem.data().thumbnail) {
        return elem.data().thumbnail;
    }
    return undefined;
}

let cyForStylesOnly = cytoscape({
    headless: true
});
//this somehow ensure that call to style() returns object
cyForStylesOnly.setStyle();
const style = cyForStylesOnly.style();
let options = [];
Object.keys(style.propertyGroups).filter(k => !['visibility', 'compound', 'transition'].includes(k)).forEach(n => {
    options.push(...style.propertyGroups[n]);
})
export const sortedStyleProperties = sort(options, 'name');

const backgroundImageStyleKey = 'background-image';

const backgroundKeys = [ backgroundImageStyleKey, 'background-fit', 'background-width', 'background-height', 'background-position-x', 'background-position-y']

function getSingleValue(elem, graphStyle, styleKey, settings) {
    const nodeId = elem.data().id;
    const typeIRIs = elem.data().typeIRIs;

    //First get from element style
    let valueToUse = graphStyle[ELEMENT_STYLE]?.[nodeId]?.[styleKey];

    //Second get from the highest precedence type
    if (valueToUse === undefined) {
        const graphStylesForNodeTypes = typeIRIs.filter(t => {
            return graphStyle[NODE_TYPE_STYLE]?.[t]?.[STYLE]?.[styleKey];
        }).map(t => {
            return graphStyle[NODE_TYPE_STYLE][t];
        });
        const sorted = sortByFunction(graphStylesForNodeTypes, (v) => {
            return Number(v[GRAPH_VIEW_PRECEDENCE]);
        }, 'desc');

        valueToUse = sorted[0]?.[STYLE]?.[styleKey];
    }

    //Third from image setup
    if (valueToUse === undefined && styleKey === backgroundImageStyleKey) {
        valueToUse = elem.data().instanceImage;
    }

    //Last find from global
    if (valueToUse === undefined) {
        if(styleKey === backgroundImageStyleKey) {
            valueToUse = getBackgroundImageFromSettings(typeIRIs, settings);
            valueToUse = valueToUse.length > 0 ? valueToUse[0] : undefined;
        } else {
            let graphStylesForNodeTypes = typeIRIs
                .filter(t => settings.labelProperties?.[t]?.[GRAPH_VIEW_SETTINGS]?.[NODE_TYPE_STYLE]?.[t]?.[STYLE]?.[styleKey])
                .map(t => settings.labelProperties?.[t]?.[GRAPH_VIEW_SETTINGS]?.[NODE_TYPE_STYLE]?.[t]);
            const sorted = sortByFunction(graphStylesForNodeTypes, (v) => {
                return Number(v[GRAPH_VIEW_PRECEDENCE]);
            }, 'desc');

            valueToUse = sorted[0]?.[STYLE]?.[styleKey];
        }
    }
    return valueToUse;
}

export function getBackgroundImageFromSettings(typeIRIs, settings) {
    let graphStylesForNodeTypes = typeIRIs
        .filter(t => settings.labelProperties?.[t]?.[GRAPH_VIEW_SETTINGS]?.[GRAPH_VIEW_BACKGROUND_IMAGE])
        .map(t => settings.labelProperties?.[t]?.[GRAPH_VIEW_SETTINGS]);
    const sorted = sortByFunction(graphStylesForNodeTypes, (v) => {
        return Number(v[GRAPH_VIEW_PRECEDENCE]);
    }, 'desc');

    return sorted.map(s => s[GRAPH_VIEW_BACKGROUND_IMAGE]);
}

function getAllImagesForTypes(elem, graphStyle, settings) {
    const nodeId = elem.data().id;
    const typeIRIs = elem.data().typeIRIs;

    //First get from element style
    //if this found than even if there are multiple values style for element takes precedence and this is used
    let valueToUse = graphStyle[ELEMENT_STYLE]?.[nodeId]?.[backgroundImageStyleKey];

    //Second get from the highest precedence type
    if (valueToUse === undefined) {
        const graphStylesForNodeTypes = typeIRIs.filter(t => {
            return graphStyle[NODE_TYPE_STYLE]?.[t]?.[STYLE]?.[backgroundImageStyleKey];
        }).map(t => {
            return graphStyle[NODE_TYPE_STYLE][t];
        });
        const sorted = sortByFunction(graphStylesForNodeTypes, (v) => {
            return Number(v[GRAPH_VIEW_PRECEDENCE]);
        }, 'desc');

        valueToUse = sorted.map(s => s?.[STYLE]?.[backgroundImageStyleKey]);
    }

    //Third from image setup
    if (toArray(valueToUse).length === 0) {
        valueToUse = elem.data().instanceImage;
    }

    //Last find image from global
    if (toArray(valueToUse).length === 0) {
        valueToUse = getBackgroundImageFromSettings(typeIRIs, settings);
    }
    return toArray(valueToUse);
}


export function getGraphEdgeStyleValue(globalGraphStyle, styleKey) {
    return toArray(globalGraphStyle).filter(obj => obj.selector === 'edge').map(obj => obj.style[styleKey]).find(v => v);
}

export function getDiagramStyle(theme, layoutName, graphStyle=[], settings, globalGraphStyle) {

    const SELECTED_OVERLAY_COLOR = theme.palette.secondary.light;
    const ACTIVE_OVERLAY_COLOR = theme.palette.primary.light;
    const SELECTED_OVERLAY_OPACITY = '.15';
    const ACTIVE_OVERLAY_OPACITY = '.15';

    const nodeStyle = {
        selector: 'node',
        style : {
            'label':function (elem) {
                return '\u3164' + (elem.data().label || elem.data().id) + '\u3164';
            },
            'text-valign': function (elem) {
                return getDefaultTextVAlign(elem);
            },
            'text-halign': function (elem) {
                return DEFAULT_NODE_TEXT_HALIGN;
            } ,
            'shape': function (elem) {
                return getDefaultShape(elem);
            } ,
            'text-justification' : function (elem) {
                return DEFAULT_NODE_TEXT_JUSTIFICATION;
            } ,
            width: function (elem) {
                return getDefaultNodeWidth(elem);
            },
            height: function (elem) {
                return getDefaultNodeHeight(elem);
            },
            'background-color':  function (elem) {
                const value = elem.data().backgroundColor;
                if(value) {
                    return value;
                }
                return theme.palette.white.main;
            } ,
            'background-opacity' :function (elem) {
                if(elem.data().backgroundColor) {
                    return .2;
                }
                return getDefaultBackgroudOpacity(elem);
            } ,
            'border-style': function (elem) {
                return DEFAULT_NODE_BORDER_STYLE;
            },
            'border-color': function (elem) {
                return theme.palette.primary.main;
            },
            'border-width': function (elem) {
                return DEFAULT_NODE_BORDER_WIDTH;
            },
            'color': function(elem) {
                return theme.palette.primary.main;
            },
            "text-wrap": function (elem) {
                return DEFAULT_NODE_TEXT_WRAP;
            },
            padding : function (elem) {
                return DEFAULT_NODE_PADDING;
            } ,
            'background-image': function (elem) {
                return getDefaultBackgroundImage(elem);
            },
            'background-fit' : function (elem) {
                return getDefaultBackgroundFit(elem);
            },
            'background-width' : function (elem) {
                return getDefaultBackgroudWidth(elem);
            },
            'background-height' : function (elem) {
                return getDefaultBackgroundHeight(elem);
            }
        }
    };
    const edgeStyle = {
        selector: 'edge',
        style: {
            'curve-style':function (elem) {
                return getEdgeCurveStyle(elem, layoutName);
            } ,
            "taxi-direction" : function (elem) {
                if(getEdgeCurveStyle(elem, layoutName) === EDGE_CURVE_STYLE_TAXI) {
                    return "downward";
                }
            },
            "taxi-turn": function (elem) {
                if(getEdgeCurveStyle(elem, layoutName) === EDGE_CURVE_STYLE_TAXI) {
                    return DEFAULT_TAXI_TURN;
                }
            } ,
            "target-arrow-shape":function (elem) {
                return "triangle";
            } ,
            label: function (elem) {
                return elem.data().label;
            } ,
            width: function (elem) {
                return DEFAULT_EDGE_WIDTH;
            },
            "loop-direction":function (elem) {
                return '-60';
            } ,
            "loop-sweep":function (elem) {
                return '-45';
            } ,
            'color': function (elem) {
                const value = elem.data().color;
                if(value) {
                    return value;
                }
                return DEFAULT_EDGE_COLOR;
            },
            'line-color' :function (elem) {
                const value = elem.data().color;
                if(value) {
                    return value;
                }
                return DEFAULT_EDGE_COLOR;
            },
            'line-style' :function (elem) {
                return DEFAULT_EDGE_LINE_STYLE;
            },
            'source-arrow-color': function (elem) {
                const value = elem.data().color;
                if(value) {
                    return value;
                }
                return DEFAULT_EDGE_COLOR;
            },
            'target-arrow-color':function (elem) {
                const value = elem.data().color;
                if(value) {
                    return value;
                }
                return DEFAULT_EDGE_COLOR;
            },
            'font-size': function (elem) {
                return DEFAULT_FONT_SIZE;
            }
        }
    };
    let styles = [
        {
            selector: 'core',
            style: {
                'active-bg-opacity' : '.05'
            }
        },
        nodeStyle,
        edgeStyle,
        {
            selector: 'node:active',
            style : {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY
            }
        },
        {
            "selector": "node:selected",
            "style": {
                'overlay-color' : SELECTED_OVERLAY_COLOR,
                'overlay-opacity' : SELECTED_OVERLAY_OPACITY
            }
        },
        {
            "selector": ".onMouseOverClass",
            "style": {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY,
                'cursor' : 'pointer'
            }
        },
        {
            selector: 'edge:active',
            style : {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY
            }
        },
        {
            selector: 'edge:selected',
            style: {
                'overlay-color' : SELECTED_OVERLAY_COLOR,
                'overlay-opacity' : SELECTED_OVERLAY_OPACITY
            }
        },
        {
            selector: '.onMouseOverEdgeClass',
            style: {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY,
                'cursor' : 'pointer'
            }
        }
    ]

    //filter overlay props as those are used for styling when mouse over or selected
    //without filter overlays are applied and not removed on mouse out
    sortedStyleProperties.filter(so => !['overlay'].includes(so.groupKey)).forEach(so => {
        const styleKey = so.name;
        let defaultNodeStyle = nodeStyle.style[styleKey];
        nodeStyle.style[styleKey] = function (elem) {
            const label = elem.data().label;
            if(backgroundKeys.includes(styleKey)) {
                const imageValues = getAllImagesForTypes(elem, graphStyle, settings);

                const width = getSingleValue(elem, graphStyle, 'width', settings) || getDefaultBackgroudWidth(elem);
                const lowerCaseWidth = width+"".toLowerCase().trim();
                const normalisedWidth = lowerCaseWidth.replace(/\D/g,'');
                const height = getSingleValue(elem, graphStyle, 'height', settings) || getDefaultBackgroundHeight(elem);

                /*
                    Example style below renders multiple background images
                    note it can return array or space separated string
                    'height': 80,
                    'width': 80,
                    'background-image': ['https://live.staticflickr.com/7272/7633179468_3e19e45a0c_b.jpg', 'https://live.staticflickr.com/1261/1413379559_412a540d29_b.jpg'],
                    'background-fit': ['none', 'none'],
                    'background-width': '40 40',
                    'background-height': '80 80',
                    'background-position-x': '0 40',
                    'background-position-y': '0 0'
                 */
                if(imageValues.length > 1) {
                    let imageWidth = getSingleValue(elem, graphStyle, 'background-width', settings) || normalisedWidth/imageValues.length;
                    switch (styleKey) {
                        case 'background-fit':
                            return imageValues.map(m => getSingleValue(elem, graphStyle, 'background-fit', settings) || "none");
                        case 'background-width':
                            return imageValues.map(m =>  imageWidth);
                        case 'background-height':
                            return imageValues.map(m => getSingleValue(elem, graphStyle, 'background-height', settings) || height);
                        case 'background-position-x':
                            return imageValues.map((m, i) => getSingleValue(elem, graphStyle, 'background-position-x', settings) || (i*imageWidth));
                        case 'background-position-y':
                            return imageValues.map((m, i) => getSingleValue(elem, graphStyle, 'background-position-y', settings) || 6);
                        case backgroundImageStyleKey:
                            return imageValues.map((m, i) => m);
                    }
                }
            }

            let valueToUse = getSingleValue(elem, graphStyle, styleKey, settings);

            if (valueToUse !== undefined) {
                if(valueToUse === ' ') {
                    return undefined;
                }
                return valueToUse;
            }
            if(defaultNodeStyle) {
                return defaultNodeStyle(elem);
            }
        }
        let defaultEdgeStyle = edgeStyle.style[styleKey];
        edgeStyle.style[styleKey] = function (elem) {
            const propertyIRI = elem.data().propertyIRI;
            const edgeId = elem.data().id;
            const value0 = graphStyle[ELEMENT_STYLE]?.[edgeId]?.[styleKey];
            const value1 = graphStyle[PROPERTY_IRI_STYLE]?.[propertyIRI]?.[styleKey];
            const value1_1 = getGraphEdgeStyleValue(globalGraphStyle, styleKey);
            const value2 = settings.labelProperties?.[propertyIRI]?.[GRAPH_VIEW_SETTINGS]?.[PROPERTY_IRI_STYLE]?.[propertyIRI]?.[styleKey];
            let value = value0 || value1 || value1_1 || value2;
            if (value) {
                return value;
            }
            if(defaultEdgeStyle) {
                return defaultEdgeStyle(elem);
            }
        }
    })

    //console.log('styles', styles)

    return styles;


/*

    return [
        {
            selector: 'core',
            css: {
                'active-bg-opacity' : '.05'
            }
        },
        {
            selector: 'node',
            css: {
                'label':function (elem) {
                    const value = elem.data()[GRAPH_VIEW_LABEL];
                    if (value) {
                        return value;
                    }
                    return '\u3164' + (elem.data().label || elem.data().id) + '\u3164';
                },
                'text-valign': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_TEXT_VALIGN];
                    if (value) {
                        return value;
                    }
                    return getDefaultTextVAlign(elem);
                },
                'text-halign': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_TEXT_HALIGN];
                    if (value) {
                        return value;
                    }
                    return DEFAULT_NODE_TEXT_HALIGN;
                } ,
                'shape': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_SHAPE];
                    if (value) {
                        return value;
                    }
                    return getDefaultShape(elem);
                } ,
                'text-justification' : function (elem) {
                    const value = elem.data()[GRAPH_VIEW_TEXT_JUSTIFICATION];
                    if (value) {
                        return value;
                    }
                    return DEFAULT_NODE_TEXT_JUSTIFICATION;
                } ,
                width: function (elem) {
                    const value = elem.data()[GRAPH_VIEW_WIDTH];
                    if (value) {
                        return value;
                    }
                    return getDefaultNodeWidth(elem);
                },
                height: function (elem) {
                    const value = elem.data()[GRAPH_VIEW_HEIGHT];
                    if (value) {
                        return value;
                    }
                    return getDefaultNodeHeight(elem);
                },
                'background-color':  function (elem) {
                    const value = elem.data()[GRAPH_VIEW_BACKGROUND_COLOR];
                    if(value) {
                        return value;
                    }
                    return theme.palette.white.main;
                } ,
                'background-opacity' :function (elem) {
                    const value = elem.data()[GRAPH_VIEW_BACKGROUND_OPACITY];
                    if(value) {
                        return value;
                    }
                    return getDefaultBackgroudOpacity(elem);
                } ,
                'border-style': function (elem) {
                    const borderStyle = elem.data()[GRAPH_VIEW_BORDER_STYLE];
                    if(borderStyle) {
                        if(borderStyle === 'none') {
                            return undefined;
                        }
                        return borderStyle;
                    }
                    return DEFAULT_NODE_BORDER_STYLE;
                },
                'border-color': function (elem) {
                    const borderColor = elem.data()[GRAPH_VIEW_BORDER_COLOR];
                    if(borderColor) {
                        return borderColor;
                    }
                    return DEFAULT_NODE_BORDER_COLOR;
                },
                'border-width': function (elem) {
                    const borderStyle = elem.data()[GRAPH_VIEW_BORDER_STYLE];
                    if(borderStyle === 'none') {
                        return 0;
                    }
                    const borderWidth = elem.data()[GRAPH_VIEW_BORDER_WIDTH];
                    if(borderWidth) {
                        return borderWidth;
                    }
                    return DEFAULT_NODE_BORDER_WIDTH;
                },
                'color': function(elem) {
                    const value = elem.data()[GRAPH_VIEW_COLOR];
                    if(value) {
                        return value;
                    }
                    return theme.palette.primary.main;
                },
                "text-wrap": function (elem) {
                    const value = elem.data()[GRAPH_VIEW_TEXT_WRAP];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_NODE_TEXT_WRAP;
                },
                padding : function (elem) {
                    const value = elem.data()[GRAPH_VIEW_PADDING];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_NODE_PADDING;
                } ,
                'background-image': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_BACKGROUND_IMAGE];
                    if(value) {
                        return value;
                    }
                    return getDefaultBackgroundImage(elem);
                },
                'background-fit' : function (elem) {
                    const value = elem.data()[GRAPH_VIEW_BACKGROUND_FIT];
                    if(value) {
                        return value;
                    }
                    return getDefaultBackgroundFit(elem);
                },
                'background-width' : function (elem) {
                    const value = elem.data()[GRAPH_VIEW_BACKGROUND_WIDTH];
                    if(value) {
                        return value;
                    }
                    return getDefaultBackgroudWidth(elem);
                },
                'background-height' : function (elem) {
                    const value = elem.data()[GRAPH_VIEW_BACKGROUND_HEIGHT];
                    if(value) {
                        return value;
                    }
                    return getDefaultBackgroundHeight(elem);
                }
            }
        },
        {
            selector: 'edge',
            style: {
                'curve-style':function (elem) {
                    return getEdgeCurveStyle(elem, layoutName);
                } ,
                "taxi-direction" : function (elem) {
                    if(getEdgeCurveStyle(elem, layoutName) === EDGE_CURVE_STYLE_TAXI) {
                        const value = elem.data()[GRAPH_VIEW_EDGE_TAXI_DIRECTION];
                        if (value) {
                            return value;
                        }
                        return "downward";
                    }
                },
                "taxi-turn": function (elem) {
                    if(getEdgeCurveStyle(elem, layoutName) === EDGE_CURVE_STYLE_TAXI) {
                        const value = elem.data()[GRAPH_VIEW_EDGE_TAXI_TURN];
                        if (value) {
                            return value;
                        }
                        let found ;//toArray(styleFromLayout).filter(s => s.selector === 'edge').find(s => s.style["taxi-turn"]);
                        if(found) {
                            return found.style["taxi-turn"];
                        }
                        return DEFAULT_TAXI_TURN;
                    }
                } ,
                "target-arrow-shape": "triangle",
                label:function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_LABEL];
                    if(value === null) {
                        return ;
                    }
                    if (value) {
                        return value;
                    }
                    return elem.data().label;
                } ,
                'target-label': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_TARGET_LABEL];
                    if (value) {
                        return value;
                    }
                } ,
                'source-label': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_SOURCE_LABEL];
                    if (value) {
                        return value;
                    }
                },
                width: function (elem) {
                    const edgeWidth = elem.data()[GRAPH_VIEW_EDGE_WIDTH];
                    if(edgeWidth) {
                        return edgeWidth;
                    }
                    return DEFAULT_EDGE_WIDTH;
                },
                "loop-direction": '-60',
                "loop-sweep": '-45',
                'color': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_COLOR];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_EDGE_COLOR;
                },
                'line-color' :function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_LINE_COLOR];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_EDGE_COLOR;
                },
                'line-style' :function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_LINE_STYLE];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_EDGE_LINE_STYLE;
                },
                'source-arrow-color': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_LINE_COLOR];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_EDGE_COLOR;
                },
                'target-arrow-color':function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_LINE_COLOR];
                    if(value) {
                        return value;
                    }
                    return DEFAULT_EDGE_COLOR;
                },
                'font-size': function (elem) {
                    const value = elem.data()[GRAPH_VIEW_EDGE_FONT_SIZE];
                    if(value) {
                        if(value.endsWith('px')) {
                            return value;
                        }
                        return value+'px';
                    }
                    return DEFAULT_FONT_SIZE;
                }

            }
        },
        {
            selector: 'node:active',
            css : {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY
            }
        },
        {
            "selector": "node:selected",
            "style": {
                'overlay-color' : SELECTED_OVERLAY_COLOR,
                'overlay-opacity' : SELECTED_OVERLAY_OPACITY
            }
        },
        {
            "selector": ".onMouseOverClass",
            "style": {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY,
                'cursor' : 'pointer'
            }
        },
        {
            selector: 'edge:active',
            css : {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY
            }
        },
        {
            selector: 'edge:selected',
            style: {
                'overlay-color' : SELECTED_OVERLAY_COLOR,
                'overlay-opacity' : SELECTED_OVERLAY_OPACITY
            }
        },
        {
            selector: '.onMouseOverEdgeClass',
            style: {
                'overlay-color' : ACTIVE_OVERLAY_COLOR,
                'overlay-opacity' : ACTIVE_OVERLAY_OPACITY,
                'cursor' : 'pointer'
            }
        }
    ]
*/

}

export function adjustMinWidth(node) {
    let minWidth = node.data().thumbnail ? 0 : 100;
    if(node.width() < minWidth) {
        node.style('width', minWidth);
    } else {
        node.style('width', node.width() + 8);
    }
}

export function computeLabelAndWidth(title) {
    let length = 20;
    let label = title.length > length ? title.substring(0, length) + '...' : title;
    let width = title.length < 5 ? '100' : 'label';
    return {label, width};
}

export function registerExtension(cytoscape, extension, name) {
    if(!cytoscape.prototype[name]) {
        try {
            cytoscape.use(extension);
        } catch (e) {
            console.log(e);
        }
    }
}
import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {styles} from "../components/styles";
import {Grid, TextField as MUITextField} from "@material-ui/core";
import GlobalsContext from "../components/GlobalsContext";
import FieldContainer from "../components/FieldContainer";
import H2Title from "../components/H2Title";
import InstructionForAction from "../components/InstructionForAction";
import {GreyLockIcon} from "../components/GreyStyleIcon";


class ViewGlobalsDialog  extends React.Component {
    static contextType = GlobalsContext
    constructor(props) {
        super(props);
        this.state = {}
    }

    render () {
        const {classes, theme, handleClose} = this.props
        let globals = this.context.get()

        return <Dialog
            open={true}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.dialog}}
        >
            <DialogTitle><H2Title title={'Global Variables'}/></DialogTitle>
            <DialogContent style={{backgroundColor: theme.palette.grey.background, padding: '0px'}}>
                <FieldContainer datatest={'globals'} style={{backgroundColor: theme.palette.white.main}}>
                    {
                        Object.keys(globals) && Object.keys(globals).length > 0
                            ? <Grid container spacing={1}>
                                <Grid item xs={5}>Variable Name</Grid>
                                <Grid item xs={7}>Value</Grid>
                                {Object.keys(globals).map((k, i) => <React.Fragment key={i}>
                                    <Grid item xs={5}>
                                        <MUITextField
                                            datatest={'textField-name'}
                                            value={k}
                                            fullWidth={true}
                                            InputProps={{
                                                endAdornment : <GreyLockIcon/>
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={7}>
                                        <MUITextField
                                            datatest={'textField-value'}
                                            value={globals[k]}
                                            fullWidth={true}
                                            InputProps={{
                                                endAdornment : <GreyLockIcon/>
                                            }}
                                        />
                                    </Grid>
                                </React.Fragment>)}
                            </Grid>
                            : <InstructionForAction style={{margin: '32px 128px'}} text={"No variable set."}></InstructionForAction>
                    }

                </FieldContainer>
            </DialogContent>
            <DialogActions style={{padding: '12px 24px', borderTop: '1px solid ' + theme.palette.border.main}}>
                <Button datatest={'closeButton'} variant={"contained"} onClick={handleClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>;
    }
}


ViewGlobalsDialog.propTypes = {
    handleClose : PropTypes.func
}

export default withStyles(styles, {withTheme: true})(ViewGlobalsDialog);

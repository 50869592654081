import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "./styles";
import {Typography} from "@material-ui/core";
import GlobalsContext from "./GlobalsContext";
import {getUiLabelTranslationFromContext} from "./util";
import {UI_LABELS_FAILED, UI_LABELS_STATUS, UI_LABELS_SUCCESS} from "../layouts/navigator/UILabel";

class ResponseStatusBar extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {hasError, status, style, theme, ...rest} = this.props;
        let color = hasError ? 'red' : 'green';
        let computedStyle = style
            ? {...style}
            : {};
        let variant = this.props.variant || 'h4';
        return <div datatest={'responseStatusBar'} {...rest} style={computedStyle}>
            <Typography datatest={'statusText'} variant={variant} component={"span"}
                        style={{color: color}}> {hasError ? getUiLabelTranslationFromContext(this, UI_LABELS_FAILED) : getUiLabelTranslationFromContext(this, UI_LABELS_SUCCESS)}</Typography>
            {status && <>
                <Typography variant={variant} component={"span"} color={'textPrimary'}> {getUiLabelTranslationFromContext(this, UI_LABELS_STATUS)}: </Typography>
                <Typography datatest={'statusCode'} variant={variant} component={"span"}
                            style={{color: color}}> {status + ""}</Typography>
            </>}
        </div>;
    }
}

ResponseStatusBar.propTypes = {
    variant: PropTypes.string,
    hasError: PropTypes.bool,
    status: PropTypes.number,
    style: PropTypes.object
};

export default withStyles(styles, {withTheme: true})(ResponseStatusBar);
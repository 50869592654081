import React, {Component} from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Grid, IconButton, Popper, TextField as OtherTextField} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {ALIAS_MANAGEMENT_TITLE, ID, STYLE_GRID_ITEM_SPACING} from "../../Constants";
import Dialog from "@material-ui/core/Dialog";
import {centerVertically, getMultilingualValue, getResourceId, sort, toArray} from "../../components/util";
import {getDefaultLanguage} from "./WorkspaceSettingsDialog";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import ResultCard from "./ResultCard";
import {CancelOutlined} from "@material-ui/icons";
import uuid4 from "uuid/v4";

const useStyles = makeStyles({
    root: {
        maxWidth: 245,
    },
});

export function PageLinkCard({location, title, description, color, image}) {
    const classes = useStyles();

    return (
        <ResultCard variant={'button'} title={title}/>
    );
}

const styles = {
    dialogPaper: {
        minHeight: '80vh'
    },
};

class PageLinkSettingsDialog extends Component {
    constructor(props) {
        super(props);
        const {settings, aliasesMap} = props;
        let options = toArray(props.pageObjects).map(pageObject => {
            return {
                value : getResourceId(pageObject),
                label : getMultilingualValue(pageObject[ALIAS_MANAGEMENT_TITLE], getDefaultLanguage(settings)),
                backingObject : pageObject
            }
        });
        options = sort(options, 'label');

        this.state = {
            options,
            selected : null,
            selectedPages : [],
        }
    }

    render() {
        const {settings, aliasesMap, onCancel, onSave, location, theme, classes} = this.props;
        const {selected, options, selectedPages} = this.state;
        return <Dialog
            aria-labelledby="Select"
            open={true}
            fullWidth={true}
            maxWidth={'md'}
            datatest={'linkToPageSelectDialog'}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="form-dialog-title">Pages Block Setup</DialogTitle>
            <DialogContent style={{backgroundColor : theme.palette.grey.background}}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {
                            options.length === 0 ? <div>Please create a page first</div> :
                                <div style={{display :'flex', gap : '16px'}}>
                                    <Autocomplete
                                        key={selected}
                                        style={{
                                            width: 360
                                        }}
                                        PopperComponent={(props) => <Popper {...props} style={{ width: 360 }} placement='bottom-start' />}
                                        datatest={'autocompleteLinkPageSelect'}
                                        id="autocompleteLinkPageSelect"
                                        options={options}
                                        getOptionLabel={option => option.label ?  option.label : ''}
                                        getOptionSelected={(option, value) => {
                                            return option.value === value.value;
                                        }}
                                        multiple={false}
                                        onChange={(event, value, reason, details) => {
                                            const {selectedPages} = this.state;
                                            selectedPages.push(value);
                                            this.setState({selectedPages, selected : uuid4()});

                                        }}
                                        renderInput={params => (
                                            <OtherTextField
                                                label={'Select Page'}
                                                {...params}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        renderOption={(option, { selected }) => (
                                            <div datatest={option.label} style={{paddingLeft : '0px'}}>{option.label}</div>
                                        )}
                                        size={"small"}
                                    />
                                    {  false &&
                                        centerVertically(
                                            <Button
                                                color={'secondary'}
                                                disabled={selected ? false : true}
                                                variant={'contained'}
                                                size={'medium'}
                                                onClick={() => {
                                                    const {selectedPages} = this.state;
                                                    selectedPages.push(selected);
                                                    this.setState({selectedPages, selected : null});
                                                }}
                                            >Add Page</Button>
                                        )
                                    }
                                </div>
                        }

                    </Grid>
                {
                    selectedPages.map((sp, index) => {
                        return <Grid key={sp.label+index} item>
                            <div style={{textAlign : 'right'}}>
                                <IconButton
                                    datatest={'deletePage-'+index}
                                    size={'small'}
                                    onClick={() => {
                                        const {selectedPages} = this.state;
                                        this.setState({selectedPages : selectedPages.filter((sp, i) => i !== index)})
                                    }}
                                >
                                    <CancelOutlined/>
                                </IconButton>
                                <PageLinkCard location={location}  title={sp.label}/>
                            </div>
                        </Grid>;
                    })
                }

                </Grid>
            </DialogContent>
            <DialogActions>
                <Button datatest={'cancelButton'} variant={"outlined"} onClick={onCancel} color="secondary">Cancel</Button>
                <Button disabled={selectedPages.length < 1} datatest={'addBlockButton'}
                        style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"}
                        color="secondary" onClick={() => {
                            let {selectedPages} = this.state;
                            let data = selectedPages.map(sp => {
                                return {
                                    [ID] : sp.value
                                }
                            })
                    this.props.onSave(data);
                }}>Add</Button>
            </DialogActions>
        </Dialog>;
    }
}

PageLinkSettingsDialog.propTypes = {
    pageObjects: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    location: PropTypes.any,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,

};

export default withStyles(styles, {withTheme: true})(PageLinkSettingsDialog);

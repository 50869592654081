import React from 'react';
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
});

let H1Title = props => {
  const { title, style, color,noWrap, ...rest  } = props;

  return (<Typography {...rest} noWrap={noWrap === true ? true: false} variant="h1" color={color ? color : 'inherit'} style={style}>
                  {title ? title : props.children}
              </Typography>);
}

H1Title.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  noWrap: PropTypes.bool,

};

export default withStyles(styles)(H1Title);

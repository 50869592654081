import React from 'react';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
});

let DetailsViewButtonBar = props => {
  return <div>{props.children}</div>
}

DetailsViewButtonBar.propTypes = {
};

export default withStyles(styles, {withTheme: true})(DetailsViewButtonBar);

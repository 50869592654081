import React from "react";
import {IconButton, InputAdornment, withStyles} from "@material-ui/core";
import {KeyboardDatePicker as OtherDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DATE_FORMAT_CONTROL, EVENT_SHACL_VALIDATE} from "../../Constants";
import EventIcon from "@material-ui/icons/EventOutlined";
import {formatDate, getMockValueFromDefaultRegex, getPropertyValueForForm, registerMock} from "../../components/util";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import {isArray} from "lodash";
import {deleteButton} from "../../components/ShapeToForm/ArrayType";
import {withDelete} from "../../components/ShapeToForm/Text";
import {validateFromSHACL} from "./validation-util";
import ErrorMessage from "../ErrorMessage";

const StyledOtherDatePicker = withStyles((theme) => ({
    root: {
        "& .MuiIconButton-root" : {
            padding: '4px',
        }
    }
}))(OtherDatePicker);

export function DatePickerComponent({value, onChange, autoFocus, setRef, error}) {
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledOtherDatePicker
            datatest={'datePicker'}
            autoFocus={autoFocus}
            fullWidth={true}
            inputVariant="outlined"
            margin={"dense"}
            value={!value || value === '' ? null : value }
            onChange={onChange}
            onError={console.log}
            format={DATE_FORMAT_CONTROL}
            helperText={DATE_FORMAT_CONTROL}
            error={error}
            inputProps={{
                ref: setRef
            }}
            InputProps={{
                style : {
                    backgroundColor: 'white'
                },
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size={"small"}>
                            <EventIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    </MuiPickersUtilsProvider>;
}

class DatePicker extends React.Component {
    static contextType = MockingContext

    constructor(props) {
        super(props)
        let value = getPropertyValueForForm(props)

        this.state = (value && { value : new Date(value)}) || {}
    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let mocking = this.context;
        let {property, valueIndex, customizations} = this.props;
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName, property, valueIndex, customizations);
        }

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? mocking.getMockValue(property)
            : getMockValueFromDefaultRegex(property)

        this.updatePropertyValue(new Date(mockValue))
    }

    validate = (eventName) => {
        let {property, valueIndex, customizations} = this.props;

        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    updatePropertyValue = (date, rawValue) => {
        let {property, onChange, valueIndex} = this.props
        let formattedDate = formatDate(date) || rawValue
        if(isArray(property.value)) {
            property.value[valueIndex] = formattedDate;
        } else {
            property.value = formattedDate;
        }

        this.setState({value : date})
        this.validate(EVENT_SHACL_VALIDATE);
        onChange()
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {label, autoFocus, valueIndex, property, onChange, customizations} = this.props;
        let {value, validationError} = this.state;
        let component = <DatePickerComponent  setRef={this.setRef} error={validationError}  autoFocus={autoFocus} onChange={this.updatePropertyValue} value={value}/>;
        component = validationError ? <>{component}<div/><ErrorMessage error={validationError}/></> : component;
        return withDelete(
            component,
            customizations?.hideDelete ? undefined : deleteButton(valueIndex, property, onChange) ,
            true
        );
    }
}

export default withStyles({}, {withTheme: true})(DatePicker);

import React from 'react';
import FilterListIcon from "@material-ui/icons/FilterListOutlined";
import BubbleChartIcon from "@material-ui/icons/BubbleChartOutlined";
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import SortIcon from "@material-ui/icons/SortOutlined";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilderOutlined";
import LanguageIcon from "@material-ui/icons/Language";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import {Button as ButtonInternal, withStyles} from "@material-ui/core";
import H4Title from "../components/H4Title";
import {
    UI_LABELS_CONDITIONAL_GET,
    UI_LABELS_DATA,
    UI_LABELS_FACET,
    UI_LABELS_FILTERS, UI_LABELS_LANGUAGE,
    UI_LABELS_PAGING_COUNTING,
    UI_LABELS_SORT
} from "../layouts/navigator/UILabel";

export const FILTERS = 'filters'
export const CONNECTED_DATA = 'connected'
export const FACET = 'facet'
export const SORT = 'sort'
export const OTHER = 'other'
export const LANGUAGE = 'language'
export const CONDITIONAL_GET = 'get'

const StyledTabButton = withStyles(theme => ({
    root: {
        color: theme.palette.primary.main,
        boxShadow: 'none',
        textTransform: 'none',
        border: 'none',
        ...theme.typography.h3,
        marginRight: '2px',
        marginBottom : '1px',
        '&:hover': {
            backgroundColor: theme.palette.secondary.selected,
        },
    },
    startIcon : {
        marginRight : '4px'
    }
}))(ButtonInternal);


export function getButton(theme, name, tooltip, icon, onClick, disabled, selected) {
    let style = {
        backgroundColor: selected === name ? theme.palette.secondary.selected : theme.palette.border.main,
        color:  selected === name ? theme.palette.secondary.main : theme.palette.primary.main,
    }
  return <StyledTabButton
      datatest={tooltip+'Button'}
      key={name}
      style={style}
      onClick={() => {
          onClick({leftView: name, reqTabValue : 0})
      }}
      startIcon={icon}
  >
      <H4Title style={selected === name ? {color : theme.palette.secondary.main}: {}} noWrap={true}>{tooltip}</H4Title>
  </StyledTabButton>;
}

function getTooltip(getUiLabelTranslationFor, key) {
    return getUiLabelTranslationFor ? getUiLabelTranslationFor(key, key) : key;
}

export function searchLeftActionBar(theme, selected, onClick, disabled = [], getUiLabelTranslationFor) {

  let button = (name, tooltip, icon) => getButton(theme, name, tooltip, icon, onClick, disabled, selected)

  return ([
      button(FILTERS, getTooltip(getUiLabelTranslationFor, UI_LABELS_FILTERS), <FilterListIcon/>),
      button(CONNECTED_DATA, getTooltip(getUiLabelTranslationFor, UI_LABELS_DATA), <BubbleChartIcon/>),
      button(FACET, getTooltip(getUiLabelTranslationFor, UI_LABELS_FACET), <AssignmentIcon/>),
      button(SORT, getTooltip(getUiLabelTranslationFor, UI_LABELS_SORT), <SortIcon/>),
      button(OTHER, getTooltip(getUiLabelTranslationFor, UI_LABELS_PAGING_COUNTING), <QueryBuilderIcon/>),
      button(LANGUAGE, getTooltip(getUiLabelTranslationFor, UI_LABELS_LANGUAGE), <LanguageIcon/>),
      button(CONDITIONAL_GET, getTooltip(getUiLabelTranslationFor, UI_LABELS_CONDITIONAL_GET), <TrackChangesIcon/>),
  ]);
}




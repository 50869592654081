import React from "react";
import ConfirmDelete from "../components/ConfirmDeleteDialog";

export function ConfirmAreYouSure({deleteWarning, handleNo, handleYes}) {
    return <ConfirmDelete
        data={{value: deleteWarning}}
        title={'Are You Sure?'}
        cancelButtonTitle={'YES'}
        okButtonTitle={'NO'}
        handleCancel={handleYes}
        handleOk={handleNo}
        open={true}
    />;
}
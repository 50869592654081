import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withPermissions} from "../../service/permission-service";
import FieldContainer from "../../components/FieldContainer";
import {IconButton, TextField, Typography} from "@material-ui/core";
import {DeleteForeverOutlined, PersonOutline} from "@material-ui/icons";
import Chip from "@material-ui/core/Chip";
import {getUserIri, isSuperadmin} from "../common/Profile";
import {
    IRI_FEEDBACK_COMMENT,
    IRI_FEEDBACK_CREATED_BY,
    IRI_FEEDBACK_DATE_CREATED,
    IRI_FEEDBACK_DISPLAY_NAME
} from "../../Constants";

class FeedbackComment extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {feedback, theme, containerBorderColor, aliasesMap, compact, contextProvider, containerBackgroundColor, onDelete} = this.props;
        const dateCreatedAlias = aliasesMap[IRI_FEEDBACK_DATE_CREATED];
        const displayNameAlias = aliasesMap[IRI_FEEDBACK_DISPLAY_NAME];
        const commentAlias = aliasesMap[IRI_FEEDBACK_COMMENT];
        const createdByAlias = aliasesMap[IRI_FEEDBACK_CREATED_BY];
        let comment = feedback[commentAlias];
        const commentLang = comment && Object.keys(comment).find(k => k);
        const commentValue = comment && comment[commentLang];
        const dateCreated = feedback[dateCreatedAlias];

        const displayName = feedback[displayNameAlias];
        const dateCreatedObj = new Date(dateCreated);
        const canDelete = getUserIri() === feedback[createdByAlias] || isSuperadmin();
        return <FieldContainer datatest={'feedbackCommentFieldContainer'} style={{border : '1px solid '+containerBorderColor, backgroundColor : (containerBackgroundColor ||  theme.palette.white.main), padding : '8px', borderTopLeftRadius : '24px', borderBottomRightRadius : '24px'}}>
            <div style={{display : 'flex',}}>
                <Typography datatest={'timestamp'} variant={'caption'}>{dateCreatedObj.toLocaleString()}</Typography>
                <div style={{flexGrow : '1'}}></div>
                {canDelete && <IconButton datatest={'feedbackDeleteButton'} onClick={onDelete} size={'small'}><DeleteForeverOutlined></DeleteForeverOutlined></IconButton>}
            </div>
            <div style={{ margin : compact ? '0px 0px 16px 0px': '8px 0px 16px 0px', display :  compact ? 'block': 'flex'}}>
                <Chip
                    datatest={'username'}
                    variant={'outlined'}
                    icon ={ <PersonOutline />}
                    label={displayName}
                ></Chip>
            </div>
            {contextProvider && contextProvider()}
            <TextField
                datatest={'textareaField-feedback'}
                value={commentValue}
                label={'Feedback'}
                rows={2}
                fullWidth={true}
                style={{marginTop : '0px'}}
                multiline={true}
                variant={'outlined'}
                InputProps={{
                    readOnly : true


                }}
                onChange={(ev) => {
                    const {target: {name, value}} = ev;
                    this.setState({feedbackValue : value});
                }}
            ></TextField>
        </FieldContainer>;
    }
}

FeedbackComment.propTypes = {
    compact: PropTypes.any,
    feedback: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    contextProvider: PropTypes.any,
    containerBackgroundColor: PropTypes.any,
    containerBorderColor: PropTypes.any,
    onDelete: PropTypes.any
};

export default  withStyles({}, {withTheme: true})(withPermissions(FeedbackComment));

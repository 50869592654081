import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import {isArray, isEmpty} from 'lodash';
import {getAllConfigurations} from "../service/graph-api";
import {
    computePropertyOptionsForShape,
    computeShapeWithSuperClasses,
    createSelectOption,
    getAliasesMap,
    getApiConfigurationResource,
    getDatatypeProperties,
    getObjectProperties,
    getOntologyClasses, getOntologyProperties,
    getProp,
    getRdfProperties,
    getRequiredFieldMessage,
    getShapesData,
    isBooleanDatatype, isDatatypeLiteral,
    isMinGreaterThanMax,
    isPropertyDataTypeProperty,
    isPropertyObjectTypeProperty,
    isStringDatatype,
    restrictMaximumCharacters,
    sort,
    toArray,
    validateCount,
    validateZeroOrPositiveInteger
} from "./util";
import TextField from './TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {styles} from "../components/styles";

import {Grid, TextField as OtherTextField, Tooltip} from "@material-ui/core";
import IconButton from "./IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import FormControl from "@material-ui/core/FormControl";
import {
    ALIAS_OWL_CARDINALITY,
    ALIAS_OWL_MAX_CARDINALITY,
    ALIAS_OWL_MIN_CARDINALITY,
    ALIAS_OWL_ON_PROPERTY,
    ALIAS_RDFS_LABEL,
    ALIAS_RDFS_RANGE,
    ALIAS_RDFS_SUBCLASS_OF,
    ALIAS_SH_CLASS,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MAX_LENGTH,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_MIN_LENGTH,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_OR,
    ALIAS_SH_PATH,
    ALIAS_SH_PATTERN,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    DATATYPE_SUGGESTIONS,
    ID,
    LABEL_CLASS,
    LABEL_CLASS_FOR_RDF_LIST,
    LABEL_DATA_TYPE,
    LABEL_MAX_COUNT,
    LABEL_MAX_LENGTH,
    LABEL_MIN_COUNT,
    LABEL_MIN_LENGTH,
    LABEL_NODE_KIND,
    LABEL_NODE_KINDS,
    LABEL_PATH,
    LABEL_REGEX_PATTERN,
    MIN_MAX_LENGTH,
    RDF_FIRST,
    SH_IRI,
    SH_LITERAL,
    STYLE_GRID_ITEM_SPACING,
    TYPE_RDF_LIST,
    VALIDATION_MAX_GREATER_THAN_MIN_COUNT,
    VALIDATION_MAX_GREATER_THAN_MIN_LENGTH,
    VALIDATION_MIN_LESS_THAN_MAX_COUNT,
    VALIDATION_MIN_LESS_THAN_MAX_LENGTH,
    VALIDATION_REGEX_LENGTH
} from "../Constants";
import Label from "./Label";
import H3Title from "./H3Title";
import H2Title from "./H2Title";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ErrorMessage from "./ErrorMessage";
import FieldContainer from './FieldContainer';
import {renderDatatypeOption, validateRegex} from "../components/util";
import {getChipWithDelete} from "../layouts/apiplayground/SearchFilter";
import Typography from "@material-ui/core/Typography";

function initialState() {
    return {
        open: false,
        loading: false,
        label: '',
        propertyType: 'datatypeProperty',
        minCount: '',
        maxCount: '1',
        minLength: '',
        maxLength: '',
        path: null,
        datatype: null,
        pattern: null,
        classVal: null,
        listClass: null,

    };
};


const IRI = [
    {value: SH_IRI, label: LABEL_NODE_KINDS[SH_IRI], tooltip: SH_IRI}
];
const LITERAL =    [
    {value: SH_LITERAL, label: LABEL_NODE_KINDS[SH_LITERAL], tooltip: SH_LITERAL}
]

class CreateShapePropertyDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState()
    }

    handleClose = () => {
        this.setState({open: false});
    };

    assignIfSet = (name, object, propName, isSelect, isSingle) => {
        let objectPropName = propName ? propName : name
        if (this.state[name] && this.state[name] !== '') {
            let value = isSelect
                ? (isSingle ? this.state[name].value : this.state[name].map(o => o.value))
                : this.state[name]
            object[objectPropName] = value
        }
    }

    clearValidationErrors = () => {
        Object.keys(this.state).forEach(k => {
            if (k.endsWith('Error')) {
                this.setState({[k]: ''});
            }
        })
    }

    isValid = () => {
        let valid = [];
        let {path, classVal, nodeKind} = this.state
        if (!path) {
            this.setRequiredError('pathError', 'Path')
            valid.push(false);
        } else {
            this.setState({pathError: ''})
        }
        valid.push(this.validateMinCount());
        valid.push(this.validateMaxCount());
        if(this.isDatatypeProperty() && this.isStringDatatype()) {
            valid.push(this.validateMinLength());
            valid.push(this.validateMaxLength());
            valid.push(this.validatePattern());
        }

        if (this.isObjecttypeProperty() && !classVal) {
            this.setRequiredError('classValError', 'Class')
            valid.push(false);
        } else {
            this.setState({classValError: ''})
        }
        if (this.isNodeKindProperty() && !nodeKind) {
            this.setRequiredError('nodeKindError', 'Node kind')
            valid.push(false);
        } else {
            this.setState({nodeKindError: ''})
        }
        return valid.filter(v => v === false).length > 0 ? false : true;
    }

    validatePattern = () => {
        let {pattern} = this.state;
        let valid = true;
        if (pattern && this.isDatatypeProperty()) {
            let error = validateRegex(pattern, LABEL_REGEX_PATTERN, VALIDATION_REGEX_LENGTH);
            this.setState({patternError: error})
            if(error){
                valid = false;
            }
        }  else {
            this.setState({patternError: ''})
        }
        return valid;
    }

    validateMaxLength = () => {
        let {minLength, maxLength} = this.state;
        let valid = true;
        if (maxLength && this.isDatatypeProperty) {
            let maxLengthError = validateCount(maxLength, undefined, minLength, maxLength, VALIDATION_MAX_GREATER_THAN_MIN_LENGTH);
            this.setState({maxLengthError: maxLengthError})
            if (maxLengthError) {
                valid = false;
            }
        } else {
            this.setState({maxLengthError: ''})
        }
        return valid;
    }

    validateMinLength = () => {
        let {minLength, maxLength} = this.state;
        let valid = true;

        if (minLength && this.isDatatypeProperty()) {
            let minLengthError = validateCount(minLength, undefined, minLength, maxLength, VALIDATION_MIN_LESS_THAN_MAX_LENGTH);
            this.setState({minLengthError: minLengthError})
            if (minLengthError) {
                valid = false;
            }
        } else {
            this.setState({minLengthError: ''})
        }
        return valid;
    }

    validateMaxCount = () => {
        let {minCount, maxCount} = this.state;
        let valid = true;
        if (maxCount && !this.isBooleanDatatype()) {
            let maxCountError = validateCount(maxCount, undefined, minCount, maxCount, VALIDATION_MAX_GREATER_THAN_MIN_COUNT);
            this.setState({maxCountError: maxCountError});
            if (maxCountError) {
                valid = false;
            }
        } else {
            this.setState({maxCountError: ''})
        }
        return valid;
    }

    validateMinCount = () => {
        let {minCount, maxCount, datatype} = this.state
        let valid = true;
        if (minCount) {
            let datatypeIRI = datatype && datatype.value;
            let minCountError = validateCount(minCount, datatypeIRI, minCount, maxCount, VALIDATION_MIN_LESS_THAN_MAX_COUNT, validateZeroOrPositiveInteger);
            this.setState({minCountError: minCountError})
            if (minCountError) {
                valid = false;
            }
        } else {
            this.setState({minCountError: ''})
        }
        return valid;
    }

    isMinCountGreaterThanMaxCount = () => {
        let {minCount, maxCount} = this.state
        if(maxCount === '') {
            return false;
        }
        let min = Math.floor(Number(minCount));
        let max = Math.floor(Number(maxCount));
        let is = (min || min === 0) && (max || max === 0) && min > max;
        return is;
    }

    isMinLengthGreaterThanMaxLength = () => {
        let {minLength, maxLength} = this.state;
        return isMinGreaterThanMax(minLength, maxLength);
    }


    handleSubmit = () => {
        let property = {}
        let {classVal} = this.state
        let {editProp, shape} = this.props
        if (!this.isValid()) {
            return;
        }
        this.setState({loading: true})
        this.assignIfSet('path', property, ALIAS_SH_PATH, true, true)
        this.assignIfSet('minCount', property, ALIAS_SH_MIN_COUNT)
        this.assignIfSet('maxCount', property, ALIAS_SH_MAX_COUNT)
        if (this.isDatatypeProperty()) {
            this.assignIfSet('datatype', property, ALIAS_SH_DATATYPE, true, true)
            this.assignIfSet('minLength', property, ALIAS_SH_MIN_LENGTH)
            this.assignIfSet('maxLength', property, ALIAS_SH_MAX_LENGTH)
            this.assignIfSet('pattern', property, ALIAS_SH_PATTERN)
            this.assignIfSet('nodeKind', property, ALIAS_SH_NODE_KIND, true, true)
        } else if (this.isObjecttypeProperty()) {
            this.assignIfSet('nodeKind', property, ALIAS_SH_NODE_KIND, true, true)
            if (classVal.length > 1) {
                property[ALIAS_SH_OR] = classVal.map(c => {
                    return {
                        [ALIAS_SH_CLASS]: c.value,
                        [ALIAS_SH_NODE_KIND]: SH_IRI
                    };
                })
            } else {
                if (this.isClassRDFList()) {
                    property[ALIAS_SH_CLASS] = TYPE_RDF_LIST
                    property[ALIAS_SH_PROPERTY] = {
                        [ALIAS_SH_PATH]: RDF_FIRST,
                    }
                    this.assignIfSet('listClass', property[ALIAS_SH_PROPERTY], ALIAS_SH_CLASS, true, true)
                } else {
                    this.assignIfSet('classVal', property, ALIAS_SH_CLASS, true)
                }
            }
        } else {
            this.assignIfSet('nodeKind', property, ALIAS_SH_NODE_KIND, true, true);
            this.assignIfSet('minLength', property, ALIAS_SH_MIN_LENGTH);
            this.assignIfSet('maxLength', property, ALIAS_SH_MAX_LENGTH);
            this.assignIfSet('pattern', property, ALIAS_SH_PATTERN);

        }

        if (shape[ALIAS_SH_PROPERTY]) {
            let shapeProperty = shape[ALIAS_SH_PROPERTY]
            if (isArray(shapeProperty)) {
                if(editProp) {
                    this.props.shape[ALIAS_SH_PROPERTY] = [...shapeProperty.filter(p => p[ALIAS_SH_PATH] !== editProp), property]
                } else {
                    this.props.shape[ALIAS_SH_PROPERTY] = [...shapeProperty, property]
                }
            } else {
                if(editProp) {
                    this.props.shape[ALIAS_SH_PROPERTY] = property
                } else {
                    this.props.shape[ALIAS_SH_PROPERTY] = [shapeProperty, property]
                }
            }
        } else {
            this.props.shape[ALIAS_SH_PROPERTY] = property
        }
        let eventToSend = {
            action: "saveJSON",
            data: this.props.shape
        }
        this.props.eventHandler(eventToSend).then(() => {
            this.setState(initialState());
        });
    }

    isObjecttypeProperty = () => {
        let {propertyType} = this.state;
        return propertyType === 'classProperty';
    }

    setRequiredError = (key, name) => {
        this.setState({[key]: getRequiredFieldMessage(name)})
    }

    handleFieldChange = (event, callback) => {
        const {target: {name, value}} = event
        this.setState({
            [name]: value
        }, callback);
    }

    componentDidMount() {
    }

    syncData = (callback) => {
        this.setState({loading: true},
            () => {
            getAllConfigurations().then(r => {
                    let {shape, ontology} = this.props
                    let aliasesMap = getAliasesMap(r)
                    let suggestions = getOntologyClasses(r).map(o => {
                        return createSelectOption(o.id, aliasesMap);
                    })
                    suggestions.push({value: TYPE_RDF_LIST, label: TYPE_RDF_LIST})
                    let combinedShape = computeShapeWithSuperClasses(shape, getShapesData(r), ontology)
                    let objectPropertiesFromResponse = getObjectProperties(r);
                    let objectProperties = sort(computePropertyOptionsForShape(shape, combinedShape, objectPropertiesFromResponse, ontology)
                        .map(o => {
                            return createSelectOption(o.id, aliasesMap);
                        }), 'label')
                    let datatypePropertiesFromResponse = getDatatypeProperties(r);
                    let datatypeProperties = sort(computePropertyOptionsForShape(shape, combinedShape, datatypePropertiesFromResponse, ontology).map(o => {
                        return createSelectOption(o.id, aliasesMap);
                    }), 'label')

                    let ids = [...datatypePropertiesFromResponse, ...objectPropertiesFromResponse].map(p => p.id)
                    let rdfOnlyProperties = getOntologyProperties(r).filter(p => !ids.includes(p.id));
                    let rdfProperties = computePropertyOptionsForShape(shape, combinedShape, rdfOnlyProperties, ontology).map(o => {
                        return createSelectOption(o.id, aliasesMap);
                    })

                    this.setState({
                        configuration: getApiConfigurationResource(r),
                        targetClasses: suggestions,
                        objectProperties: objectProperties,
                        datatypeProperties: datatypeProperties,
                        rdfProperties: rdfProperties,
                        aliasesMap: aliasesMap,
                        loading: false
                    }, callback)
                })
            }
        )
    }

    isClassRDFList = () => {
        return this.state.classVal && this.state.classVal.map(c => c.value).includes(TYPE_RDF_LIST);
    }

    isNodeKindProperty = () => {
        return this.state.propertyType === 'nodekindProperty';
    }

    isDatatypeProperty = () => {
        return this.state.propertyType === 'datatypeProperty';
    }

    isClassProperty = () => {
        return this.state.propertyType === 'classProperty';
    }

    isNodeKindLiteral = () => {
        let {nodeKind} = this.state;
        return nodeKind && nodeKind.value === LITERAL[0].value;
    }


    getCardinalityValue = (propIRI, cardinalityName) => {
        const {shape, ontology} = this.props
        let targetClass = shape[ALIAS_SH_TARGET_CLASS]
        let owlClass = ontology.find(o => o.id === targetClass)
        let subClassOf = owlClass[ALIAS_RDFS_SUBCLASS_OF]
        let restrictions = subClassOf
            ? subClassOf.filter(r => r[ALIAS_OWL_ON_PROPERTY] === propIRI )
            : []
        let restriction = restrictions.find(r => r[cardinalityName] || r[ALIAS_OWL_CARDINALITY])
        let value = null
        if(restriction) {
            value = restriction[cardinalityName] ? restriction[cardinalityName] : restriction[ALIAS_OWL_CARDINALITY]
        }
        return value
    }

    isCardinalityInRestriction = (cardinalityName) => {
        const {editProp} = this.props
        let {path} = this.state
        let propIRI = editProp ? editProp : (path ? path.value : null)
        let cardinalityValue = this.getCardinalityValue(propIRI, cardinalityName)
        return cardinalityValue ? true : false;
    }

    getPathOptions = () => {
        let {datatypeProperties, objectProperties, rdfProperties} = this.state
        if(this.isDatatypeProperty()) {
            return datatypeProperties
        } else if (this.isObjecttypeProperty()) {
            return objectProperties
        } else {
            return sort([...datatypeProperties, ...objectProperties, ...rdfProperties], 'label')
        }
    }

    isBooleanDatatype = () => {
        let {datatype} = this.state;
        return datatype && isBooleanDatatype(datatype.value);
    }

    getDialogBody = () => {
        const {classes, theme, editProp} = this.props
        const {
            loading, propertyType, path, minCount, minCountError,
            maxCount, maxCountError, datatype, minLength, minLengthError,
            maxLength, maxLengthError, pattern, patternError, classVal, classValError,
            listClass, listClassError,
        } = this.state

        return <>
            <DialogTitle id="form-dialog-title"><H2Title
                title={editProp ? 'Edit property' : `Add New Property in ${getProp(this.props.shape, ALIAS_RDFS_LABEL)} Shape`}/></DialogTitle>
            {
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <Label label={'Choose Property Value Type'}/>
                                <RadioGroup
                                    aria-label="Property type"
                                    name="propertyType"
                                    className={classes.group}
                                    value={propertyType || 'datatypeProperty'}
                                    onChange={(event) => {
                                        const {target: {name, value}} = event
                                        let {open, ...other} = initialState();
                                        this.setState({
                                            ...other,
                                            path: '',
                                            nodeKind: '',
                                            [name]: value
                                        }, this.clearValidationErrors);
                                    }}
                                    row={true}
                                >
                                    <FormControlLabel disabled={editProp} value="datatypeProperty"
                                                      control={<Radio/>}
                                                      label={<H3Title title={LABEL_DATA_TYPE}/>}/>
                                    <FormControlLabel disabled={editProp} value="classProperty"
                                                      control={<Radio/>}
                                                      label={<H3Title title={"Class"}/>}/>
                                    <FormControlLabel disabled={editProp} value="nodekindProperty"
                                                      control={<Radio/>}
                                                      label={<H3Title title={LABEL_NODE_KIND}/>}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>{
                            editProp
                                ? path &&
                                <TextField label={"Path"} readOnly={true} value={path.label}></TextField>
                                : this.getPathSelection()
                        }</Grid>
                        {
                            (!propertyType || this.isDatatypeProperty()) &&
                            <Grid item xs={12}>{
                                editProp && datatype
                                    ? <TextField label={LABEL_DATA_TYPE} readOnly={true} value={datatype.label}/>
                                    : this.getDatatypeSelection()
                            }</Grid>
                        }
                        {
                            (!propertyType || this.isDatatypeProperty() || this.isNodeKindLiteral()) && (this.isStringDatatype(datatype) || this.isNodeKindLiteral()) &&
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <TextField name={"minLength"} value={minLength} label={LABEL_MIN_LENGTH}
                                               onChange={(ev) => {
                                                   this.handleFieldChange(restrictMaximumCharacters(ev, 8), () => {
                                                       this.validateMinLength();
                                                       this.validateMaxLength();
                                                   });
                                               }}
                                               error={minLengthError} helperText={minLengthError}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField name={"maxLength"} value={maxLength} label={LABEL_MAX_LENGTH}
                                               onChange={(ev) => {
                                                   this.handleFieldChange(restrictMaximumCharacters(ev, 8), () => {
                                                       this.validateMaxLength();
                                                       this.validateMinLength();
                                                   });
                                               }}
                                               error={maxLengthError} helperText={maxLengthError}/>
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField name={"pattern"} value={pattern} label={LABEL_REGEX_PATTERN}
                                               onChange={(ev) => {
                                                   this.handleFieldChange(restrictMaximumCharacters(ev, VALIDATION_REGEX_LENGTH), this.validatePattern);
                                               }}
                                               error={patternError} helperText={patternError}/>
                                </Grid>
                            </React.Fragment>
                        }
                        {
                            (propertyType && ['classProperty'].includes(propertyType)) &&

                            <Grid item xs={12}>
                                <FieldContainer datatest={'autocompleteClass'} style={{ minHeight:'70px' }}>
                                    <Label error={classValError} label={LABEL_CLASS}/>
                                    <Autocomplete
                                        id="classForOP"
                                        renderTags={(value, getTagProps) => {
                                            return value.map((option, index) => {
                                                return getChipWithDelete(theme, index, option.label, undefined, getTagProps({index}), false);
                                            })
                                        }}
                                        value={classVal ? classVal : []}
                                        options={this.state.targetClasses}
                                        getOptionLabel={option => option.label ? option.label : ''}
                                        onChange={(event, val) => this.setState({classVal: val})}
                                        renderInput={params => (
                                            <OtherTextField {...params} margin={"dense"} variant="outlined" fullWidth/>
                                        )}
                                        multiple={true}
                                        size={"small"}
                                    />
                                </FieldContainer>
                                <ErrorMessage extraPadding={4} error={classValError}/>

                            </Grid>
                        }
                        {
                            (propertyType && ['classProperty'].includes(propertyType)) &&
                            this.isClassRDFList() &&
                            <Grid item xs={12}>
                                <FieldContainer datatest={'autocompleteRDFListClass'} style={{ minHeight:'70px' }}>
                                    <Label error={listClassError} label={LABEL_CLASS_FOR_RDF_LIST}/>
                                    <Autocomplete
                                        id="classForRDFList"
                                        value={listClass }
                                        options={this.state.targetClasses}
                                        getOptionLabel={option => option.label ? option.label : ''}
                                        onChange={(event, val) => this.setState({listClass: val})}
                                        renderInput={params => (
                                            <OtherTextField {...params} margin={"dense"} variant="outlined" fullWidth/>
                                        )}
                                    />
                                </FieldContainer>
                                <ErrorMessage extraPadding={4} error={listClassError}/>

                            </Grid>
                        }

                        {
                            propertyType && this.isNodeKindProperty() &&

                            <Grid item xs={12}>{this.getNodeKind()}</Grid>
                        }
                        <Grid item xs={6}>
                            <TextField name={"minCount"} value={minCount} label={LABEL_MIN_COUNT}
                                       onChange={(ev) => {

                                           this.handleFieldChange(restrictMaximumCharacters(ev, 8), () => {
                                               this.validateMinCount();
                                               this.validateMaxCount();
                                           });
                                       }}
                                       error={minCountError}
                                       helperText={minCountError}
                                       readOnly={this.isCardinalityInRestriction(ALIAS_OWL_MIN_CARDINALITY)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField name={"maxCount"} value={maxCount} label={LABEL_MAX_COUNT}
                                       onChange={(ev) => {
                                           this.handleFieldChange(restrictMaximumCharacters(ev, MIN_MAX_LENGTH), () => {
                                               this.validateMaxCount();
                                               this.validateMinCount();
                                           });
                                       }}
                                       error={maxCountError}
                                       helperText={maxCountError}
                                       readOnly={this.isCardinalityInRestriction(ALIAS_OWL_MAX_CARDINALITY) || this.isBooleanDatatype()}
                            />
                        </Grid>

                    </Grid>
                </DialogContent>
            }
            <DialogActions>
                <Button datatest={'cancelButton'} variant={"outlined"} onClick={this.handleClose} color="secondary">Cancel</Button>
                <Button datatest={'saveCreateButton'} style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"}
                        disabled={loading || this.shouldSaveDisabled()} color="secondary"
                        onClick={this.handleSubmit}>{editProp ? 'Save' : 'Create'}</Button>
            </DialogActions>
        </>;
    }

    getDatatypeSelection = () => {
        const { datatype } = this.state

        return (<FieldContainer datatest={'autocomplete'+LABEL_DATA_TYPE} style={{ minHeight:'70px' }}>
            <Label label={LABEL_DATA_TYPE}/>
            <Autocomplete
                id="datatype"
                value={datatype}
                options={DATATYPE_SUGGESTIONS}
                getOptionLabel={option => option.label ? option.label  : ''}
                renderOption={renderDatatypeOption}
                onChange={(event, val) => {
                    if (val && val.value === 'http://www.w3.org/2001/XMLSchema#boolean') {
                        this.setState({maxCount: 1, nodeKind: null})
                    }
                    this.setState({datatype: val})
                }}
                renderInput={params => (
                    <OtherTextField {...params} margin={"dense"} variant="outlined" fullWidth/>
                )}
            />
        </FieldContainer>);
    }

    getPathSelection = () => {
        const {editProp} = this.props
        const {path, pathError} = this.state
        return <>
                <FieldContainer datatest={'autocompletePath'} style={{ minHeight:'70px' }}>
                <Label error={pathError} label={LABEL_PATH}/>
                <Autocomplete
                    id="pathAuto"
                    value={path}
                    options={this.getPathOptions()}
                    getOptionLabel={option => option.label ? option.label  : ''}
                    disabled={editProp ? true : false}
                    renderOption={(option, { selected }) => {
                        return <div>
                            <div>{option.label}</div>
                            <div><Typography variant={'caption'}>{option.tooltip}</Typography></div>
                        </div>;
                    }}
                    onChange={(event, val) => {
                        this.setState({path: val})
                        if (!val) {
                            return;
                        }
                        // If nodekind radio then do auto selection of nodekind
                        if (this.isNodeKindProperty()) {
                            if (!val) {
                                this.setState({nodeKind: null})
                            } else if (this.state.objectProperties.find(o => o.value === val.value)) {
                                this.setState({nodeKind: IRI[0]})
                            } else if (this.state.datatypeProperties.find(o => o.value === val.value)) {
                                this.setState({nodeKind: LITERAL[0]})
                            }
                        } else if (this.isDatatypeProperty()) {
                            let propIRI = val.value
                            // Set datatype to range of property
                            let property = this.props.ontology.find(o => o[ID] === propIRI)
                            if (property && property[ALIAS_RDFS_RANGE]) {
                                let datatype = DATATYPE_SUGGESTIONS.find(s => s.value === property[ALIAS_RDFS_RANGE]);
                                this.setState({datatype: datatype})
                                //If range is boolean set max count to 1
                                if (property[ALIAS_RDFS_RANGE] === 'http://www.w3.org/2001/XMLSchema#boolean') {
                                    this.setState({maxCount: '1'})
                                    return ;
                                }
                            }
                            //If cardinality restriction then set min/max
                            let minCardinalityValue = this.getCardinalityValue(propIRI, ALIAS_OWL_MIN_CARDINALITY)
                            if(minCardinalityValue) {
                                this.setState({minCount: minCardinalityValue})
                            }
                            let maxCardinalityValue = this.getCardinalityValue(propIRI, ALIAS_OWL_MAX_CARDINALITY)
                            if(maxCardinalityValue) {
                                this.setState({maxCount: maxCardinalityValue})
                            }
                        } else if (this.isObjecttypeProperty()) {
                            let propIRI = val.value
                            // Set datatype to range of property
                            let property = this.props.ontology.find(o => o[ID] === propIRI)
                            if (property && property[ALIAS_RDFS_RANGE]) {
                                let classVals = toArray(property[ALIAS_RDFS_RANGE]).map(p => this.state.targetClasses.find(o => o.value === p))
                                this.setState({classVal: classVals})
                            } else {
                                this.setState({classVal: null})
                            }
                        }
                    }}
                    renderInput={params => (
                        <OtherTextField {...params} margin={"dense"} variant="outlined" fullWidth/>
                    )}
                />
            </FieldContainer>
            <ErrorMessage extraPadding={4} error={pathError}/>
        </>;
    }

    shouldSaveDisabled = () => {
        const { datatype, classVal, nodeKind, path } = this.state;
        const {
            minCountError, maxCountError, minLengthError, maxLengthError, patternError, classValError, listClassError
        } = this.state

        if (isEmpty(path)) {
            return true;
        }
        if(minCountError || maxCountError || minLengthError || maxLengthError || patternError || classValError || listClassError) {
            return true;
        }

        if (this.isNodeKindProperty() && !isEmpty(nodeKind)) {
            return false;
        }
        if (this.isClassProperty() && !isEmpty(classVal)) {
            return false;
        }
        if (this.isDatatypeProperty() && !isEmpty(datatype)) {
            return false;
        }

        return true;
    };

    getNodeKind = () => {
        const {editProp} = this.props
        const {nodeKind, nodeKindError, path} = this.state
            let options = editProp
                ? this.getNodeKindOptions(editProp)
                : path && path.value ? this.getNodeKindOptions(path.value) : [...LITERAL, ...IRI]
            return <>
                <FieldContainer datatest={'autocomplete'+LABEL_NODE_KIND} style={{ minHeight:'70px' }}>
                    <Label error={nodeKindError} label={LABEL_NODE_KIND}/>
                    <Autocomplete
                        id="nodeKindSelect"
                        value={nodeKind}
                        options={options}
                        getOptionLabel={option => option.label ?  option.label : ''}
                        onChange={(event, val) => this.setState({nodeKind: val})}
                        renderInput={params => (
                            <OtherTextField {...params} margin={"dense"} variant="outlined" fullWidth />
                        )}
                    />
                </FieldContainer>
                <ErrorMessage extraPadding={4} error={nodeKindError}/>
                </>;
    }

    getNodeKindOptions = (editProp) => {
        let {ontology} = this.props
        if(isPropertyObjectTypeProperty(editProp, ontology)) {
            return IRI;
        } else if (isPropertyDataTypeProperty(editProp, ontology)) {
            return LITERAL;
        } else {
            return [...LITERAL, ...IRI]
        }
    }

    isStringDatatype = () => {
        let {datatype} = this.state;
        return datatype && isStringDatatype(datatype.value);
    }

    initialiseStateFromProperty = () => {
        const {shape, editProp} = this.props
        const {aliasesMap} = this.state
        let property = this.getShapeProperty(shape, editProp);
        this.setIfPresent(ALIAS_SH_MIN_COUNT, property, 'minCount');
        this.setIfPresent(ALIAS_SH_MAX_COUNT, property, 'maxCount');
        this.setIfPresent(ALIAS_SH_MIN_LENGTH, property, 'minLength');
        this.setIfPresent(ALIAS_SH_MAX_LENGTH, property, 'maxLength');
        this.setIfPresent(ALIAS_SH_PATTERN, property, 'pattern');
        this.setState({datatype: DATATYPE_SUGGESTIONS.find(o => o.value === property[ALIAS_SH_DATATYPE])})
        this.setState({path: createSelectOption(property[ALIAS_SH_PATH], aliasesMap)})
        if (property[ALIAS_SH_NODE_KIND]) {
            this.setState({nodeKind: createSelectOption(property[ALIAS_SH_NODE_KIND], LABEL_NODE_KINDS)})
        }
        if (property[ALIAS_SH_PROPERTY]) {
            this.setIfPresent('listClass', property[ALIAS_SH_PROPERTY]);
        }
        if (property[ALIAS_SH_CLASS] || property[ALIAS_SH_OR]) {
            this.setState({propertyType: 'classProperty'})
            if (property[ALIAS_SH_CLASS]) {
                this.setState({classVal: [createSelectOption(property[ALIAS_SH_CLASS], aliasesMap)]})
                if(TYPE_RDF_LIST === property[ALIAS_SH_CLASS] && property[ALIAS_SH_PROPERTY]) {
                    this.setState({listClass: createSelectOption(property[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS], aliasesMap)})
                }
            } else {
                this.setState({classVal: property[ALIAS_SH_OR].map(c => (createSelectOption(c[ALIAS_SH_CLASS], aliasesMap)))})
            }
        } else if (property[ALIAS_SH_DATATYPE]) {
            this.setState({propertyType: 'datatypeProperty'})
        } else {
            this.setState({propertyType: 'nodekindProperty'})
        }
    }

    getShapeProperty = (shape, editProp) => {
        return isArray(shape[ALIAS_SH_PROPERTY])
            ? shape[ALIAS_SH_PROPERTY].find(p => p[ALIAS_SH_PATH] === editProp)
            : shape[ALIAS_SH_PROPERTY];
    }

    setIfPresent = (key, property, stateVar) => {
        if (property[key]) {
            this.setState({[stateVar]: property[key]})
        } else {
            this.setState({[stateVar]: ''})
        }
    }

    render() {
        const {classes, theme, buttonIcon, editProp} = this.props
        const {
            loading,
        } = this.state
        return (
            <React.Fragment>
                <Tooltip arrow placement="bottom" title={editProp ? 'Edit Property' : 'Add New Property'}>
                    <IconButton datatest={'addEditPropertyButton'} className={editProp ? classes.cardActionButton : classes.detailsActionButton}
                                size={'small'} onClick={() => {
                        this.setState({open: true});
                    }}>
                        {buttonIcon ? buttonIcon : <AddCircleIcon/>}
                    </IconButton>
                </Tooltip>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    onRendered={() => {
                        this.syncData(() => {
                            console.log("sync callback : " + performance.now())

                            if (editProp) {
                                this.initialiseStateFromProperty();
                            } else {
                                let {open, ...other} = initialState();
                                this.setState(other);
                            }
                            this.clearValidationErrors();
                        });
                    }}
                >
                    {loading === false ? this.getDialogBody() : <DialogContent><div style={{padding: theme.spacing(STYLE_GRID_ITEM_SPACING), textAlign: 'center'}}><CircularProgress/></div></DialogContent>}
                </Dialog>

            </React.Fragment>
        )
    }

}

CreateShapePropertyDialog.propTypes = {
    eventHandler: PropTypes.func.isRequired,
    shape: PropTypes.object.isRequired,
    buttonIcon: PropTypes.object,
    editProp: PropTypes.string,
    ontology: PropTypes.array,
}

export default withStyles(styles)(CreateShapePropertyDialog);

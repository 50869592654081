import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../../components/styles";
import {centerVertically, getGraph, getRandomInt, getRouteWithInstanceAndDataset, sort} from "../../components/util";
import AllPartsLayout from "../AllPartsLayout";
import {getHeader} from "../../components/header/APIPlaygroundHeader";
import {Grid} from "@material-ui/core";
import {
    ALIAS_OWL_DATATYPE_PROPERTY,
    ALIAS_OWL_OBJECT_PROPERTY,
    ALIAS_SH_CLASS,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_PROPERTY_TYPE,
    ID,
    IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE,
    OBJECT_LINKING_PROPERTY,
    ROUTE_API_PLAYGROUND_EXAMPLES,
    ROUTE_SCHEMA_BUILDER_SCHEMA,
    STYLE_GRID_ITEM_SPACING,
    STYLE_MAIN_SPACING
} from "../../Constants";
import {getAllConfigurations} from "../../service/graph-api";
import CollectionCard from "../../components/CollectionCard";
import PropTypes from "prop-types";
import history from "../../history";
import qs from "qs";
import {addModel, deleteModel, getModels, storeModelAndData} from "../../service/model-api";
import List from '@material-ui/core/List';
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import PublishIcon from "@material-ui/icons/Publish";
import withWidth from "@material-ui/core/withWidth";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import ImportModelDialog from "../../layouts/modelbuilder/ImportModelDialog";
import {TYPE_OPTIONS} from "../../layouts/modelbuilder/Property";
import uuid4 from "uuid/v4";
import {
    createClassObject,
    createModelObject,
    createPropertyObject,
    createShapeObject,
    updateModifiedDate
} from "../../layouts/modelbuilder/util";
import AddModelDialog from "../../layouts/modelbuilder/AddModelDialog";
import {DashBoardSearch} from "../../components/DashBoardSearch";
import {LeftMenuIconButton} from "../../components/LeftMenuIconButton";
import {LeftMenuToggleButton} from "../../components/LeftMenuToggleButton";

export const SCHEMA_BUILDER = 'Schema Builder';

class Models extends Component {
    constructor(props) {
        super(props);
        let models = getModels()
        this.state = {
            models: models,
            minimized: true,
            searchText: ''
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
        this.setState({loading: true})
        getAllConfigurations().then((response) => {
            this.setState({
                configurations: response,
                loading: false
            })
        }).catch((e) => {
            this.setState({loading: false, apiErrorResponse: e, apiError: true});
        })
    }

    handleImportModel = (modelDetails, data) => {
        storeModelAndData(modelDetails, data)
        this.setState({models: getModels()})
    }

    openDialog = (key) => {
        return () => this.setState({[key]: true});
    }

    addNewModel = (obj) => {
        let modelObject = createModelObject(obj);
        updateModifiedDate(modelObject);
        addModel(modelObject);
        this.navigateToId(modelObject[ID]);
    }

    deleteModel = (coll) => {
        deleteModel(coll)
        this.setState({models: getModels()})
    }

    navigateToId = (id) => {
        history.push( `${getRouteWithInstanceAndDataset(ROUTE_SCHEMA_BUILDER_SCHEMA)}?${qs.stringify({ schemaId: id })}`)
    }

    search = () => {
        let {searchText, models} = this.state;
        let normalised = searchText.toLowerCase();
        if(normalised) {
            let found = models.filter(c => c[ALIAS_SYS_ID_LABEL] && c[ALIAS_SYS_ID_LABEL].toLowerCase().includes(normalised));
            if(found.length === 0) {
                this.setState({noResults: true});
            }
            this.setState({models: found});
        } else {
            this.setState({models: getModels(), noResults: undefined});
        }
    }

    getSearchComponent = () => {
        let { searchText } = this.state;
        return <DashBoardSearch
            placeholder={'Find a schema'}
            onSearch={this.search}
            onSearchTextChange={(val, event, callBack) => this.setState({searchText: val}, callBack)}
            searchText={searchText}
        /> ;
    }

    showGetStartedButton = () => {
        let {theme} = this.props;
        let {models, noResults} = this.state;
        if(noResults) {
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>No schemas found.</div>
            </div>;
        }
        if(!models || models.length <= 0) {
            let button = <Button id={'addNewSchemaButton'} startIcon={<AddIcon/>} variant="contained" color="secondary"
                          onClick={this.openDialog('showCreateModelDialog')}>
                     Add New Schema
                </Button>;
            return <div style={{paddingTop: '100px', textAlign: "center"}}>
                <div datatest={'getStartedMessage'} style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>Add a schema to get started</div>
                {button}
            </div>;
        } else {
            return <></>;
        }
    }

    getMiddleComponent = () => {
        const { width, classes, theme } = this.props;
        let { loading, noResults, minimized, models, autoGenerateSuccessMessage, autoGenerateErrorMessage, autoGenerateQuotaErrorMessage } = this.state;
        let lg = minimized ? 3 : 4
        let md = minimized ? 3 : 4
        let sm = minimized ? 6 : 12
        let xs = 12
        let itemPadding = theme.spacing(STYLE_MAIN_SPACING) - (theme.spacing(STYLE_GRID_ITEM_SPACING) / 2 )
        return <div>
            <div style={{display: 'flex', paddingRight: itemPadding + 10}}>
                <div style={{flexGrow: 1}}></div>
                {
                    autoGenerateSuccessMessage && <AlertSnackbarContent
                        variant="success"
                        className={classes.margin}
                        message={`Schema generated.`}
                        open={true}
                        onClose={() => {
                            this.setState({autoGenerateSuccessMessage: undefined})
                        }}
                    />
                }
                {
                    autoGenerateErrorMessage && <AlertSnackbarContent
                        variant="error"
                        className={classes.margin}
                        autoHide={false}
                        message={`Schema generation failed due to some unknown error. Please contact support team.`}
                        open={true}
                        onClose={() => {
                            this.setState({autoGenerateErrorMessage: undefined})
                        }}
                    />
                }
                {
                    autoGenerateQuotaErrorMessage && <AlertSnackbarContent
                        variant="error"
                        className={classes.margin}
                        autoHide={false}
                        message={`Schema generation failed. You have hit maximum limit of local storage. Please delete existing unused workspaces/scenarios/models and retry.`}
                        open={true}
                        onClose={() => {
                            this.setState({autoGenerateQuotaErrorMessage: undefined})
                        }}
                    />
                }
                {
                    loading === true && <ProcessingBackdrop marginLeft={true} loading={true}/>
                }
            </div>
            <div style={{margin : '0px 0px 0px 20px'}}>
            <Grid xs container spacing={STYLE_GRID_ITEM_SPACING} justify="space-evenly">

                {
                    ((models && models.length > 0) || noResults === true) &&
                    <>
                        <Grid style={{paddingTop: '4px'}} justify={'flex-start'} container item xs={12} sm={6}>
                            {this.getSearchComponent()}
                        </Grid>
                        <Grid style={{paddingTop: '4px'}} justify={'flex-end'} container item xs={12} sm={6}>
                            {
                                centerVertically(
                                    <Button
                                        datatest={'addNewSchemaButtonRight'}
                                        size={"medium"}
                                        startIcon={<AddIcon/>}
                                        color="secondary"
                                        onClick={this.openDialog('showCreateModelDialog')}
                                        variant={'contained'}>Add New Schema</Button>
                                )
                            }
                        </Grid>
                    </>
                }
                {this.showGetStartedButton()}
                {
                sort(models).map(coll => {
                    return  <Grid key={coll[ID]} item>
                        <CollectionCard
                            key={coll[ID]}
                            deleteConfirmMessage={'Do you really want to delete the schema'}
                            url={`${ROUTE_API_PLAYGROUND_EXAMPLES}?${qs.stringify({ id: coll.id })}`}
                            title={coll[ALIAS_SYS_ID_LABEL]}
                            description={coll[ALIAS_SYS_DESCRIPTION]}
                            onDelete={() => this.deleteModel(coll)}
                            onOpen={() => this.navigateToId(coll.id)}
                        />
                    </Grid>;
                })
                }
            </Grid>
            </div>
        </div>;
    }

    getLeftIconButton = (icon, title, onClick) => {
        let {minimized} = this.state
        let {theme} = this.props
        return <LeftMenuIconButton theme={theme} title={title} onClick={onClick} icon={icon} minimized={minimized}/>;
    }

    autoGenerateModel = () => {
        let modelObject = createModelObject({[ALIAS_SYS_ID_LABEL]: 'Test Model', [ALIAS_SYS_DESCRIPTION] : 'Large test model'});
        let classes = [];
        let shapes = [];
        let properties = [];
        let stringType = TYPE_OPTIONS.find(o => o.value === 'http://www.w3.org/2001/XMLSchema#string');
        let objectType = TYPE_OPTIONS.find(o => o.value === OBJECT_LINKING_PROPERTY);

        for(let i = 100; i < 200; i++) {
            let number = 'Class'+i;
            let classObject = createClassObject({title : number,  description : number}, modelObject, undefined);
            classes.push(classObject);
            let shapeObject = createShapeObject(modelObject, classObject);
            shapes.push(shapeObject);
            shapeObject[ALIAS_SH_PROPERTY] = [];

            for(let j = 10; j < 13 ; j ++) {
                let title = number + "_Prop"+j
                let propertyObject = createPropertyObject({title: title, type : [ALIAS_OWL_DATATYPE_PROPERTY], description : title, [ALIAS_SYS_PROPERTY_TYPE] : stringType}, modelObject);
                properties.push(propertyObject);
                shapeObject[ALIAS_SH_PROPERTY].push({
                    [ID]: "_:" + uuid4(),
                    [ALIAS_SH_PATH]: propertyObject[ID],
                    [ALIAS_SH_DATATYPE] : propertyObject[ALIAS_SYS_PROPERTY_TYPE].value
                });

            }
            for(let j = 16; j < 18 ; j ++) {
                let title = number + "_Prop"+j
                let propertyObject = createPropertyObject({title: title, type : [ALIAS_OWL_OBJECT_PROPERTY], description : title, [ALIAS_SYS_PROPERTY_TYPE] : objectType}, modelObject);
                properties.push(propertyObject);
                let randomInt = getRandomInt(classes.length);
                let randomClass = classes[randomInt];
                shapeObject[ALIAS_SH_PROPERTY].push({
                    [ID]: "_:" + uuid4(),
                    [ALIAS_SH_PATH]: propertyObject[ID],
                    [ALIAS_SH_CLASS] : randomClass[ID]
                });
            }


        }
        updateModifiedDate(modelObject);
        storeModelAndData(modelObject, [...shapes, ...classes, ...properties]);
        this.setState({});
    }

    getLeftButton = (icon, title, dialogKey) => {
        return this.getLeftIconButton(icon, title, this.openDialog(dialogKey));
    }

    isTestOntology = () => {
        let {configurations} = this.state;
        let found = getGraph(configurations).find(o => o[ID] === IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE);
        return found === undefined ? false : true;
    }

    getLeftComponent = () => {
        let {theme} = this.props
        let {minimized} = this.state
        let iconStyle =  {color:theme.palette.white.main}
        let width = minimized ? '52px' : '275px'
        return <>
            <LeftMenuToggleButton theme={theme} minimized={minimized} onClick={() => this.setState({minimized: !minimized})}/>
            <div style={{height : '100%', width : width, backgroundColor : theme.palette.secondary.dark}}>
                <List style={{padding: '0px'}}>
                    {
                        this.getLeftButton(
                            <PublishIcon style={iconStyle}/>,
                            "Import Schema",
                            'openImportModelDialog'
                        )
                    }
                </List>
            </div>
        </>;
    }

    render() {
        let {classes, location, theme} = this.props
        let {minimized, loading} = this.state
        let {openImportModelDialog, showCreateModelDialog, openAutoGenerateWorkspaceDialog} = this.state
        let maxWidth = minimized ? '77px' : '300px';


        return (<>
            <AllPartsLayout
                apiError={this.state.apiError}
                apiErrorResponse={this.state.apiErrorResponse}
                onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                header={getHeader(SCHEMA_BUILDER)}
                middleStyle={{paddingLeft : '0px', paddingTop: '24px'}}
                middleComponent={this.getMiddleComponent()}
                leftStyle={{ backgroundColor : theme.palette.grey.background, width : maxWidth, border : 'none', padding: '0'}}
                leftComponentStyle={{padding: '0', height: '100%'}}
                leftComponentContainerStyle={{padding: '0', overflow: 'hidden'}}
                leftComponentScroll={{y: 'auto'}}
                leftComponent={this.getLeftComponent()}
                {...this.props}
            />
            {
                openImportModelDialog === true
                && <ImportModelDialog
                    open={openImportModelDialog}
                    location={location}
                    onClose={() => this.setState({openImportModelDialog: false})}
                    eventHandler={this.handleImportModel}
                />

            }
            {
                showCreateModelDialog === true
                && <AddModelDialog
                    open={showCreateModelDialog}
                    title={'Add New Schema'}
                    handleOk={(obj) => this.addNewModel(obj)}
                    handleCancel={() => this.setState({showCreateModelDialog:false})}
                />
            }
        </>
    );
    }
}

Models.propTypes = {
    location: PropTypes.object,

};

export default withWidth()(withStyles(styles, {withTheme: true})(Models));

import React from "react";
import {Grid, Tooltip} from "@material-ui/core";
import {isArray} from 'lodash';
import {
    ALIAS_SH_CLASS,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MAX_LENGTH,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_MIN_LENGTH,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_OR,
    ALIAS_SH_PATH,
    ALIAS_SH_PATTERN,
    COMBINED_WITH_PROPERTY,
    FROM_SHAPE,
    ID,
    LABEL_MAX_COUNT,
    LABEL_MAX_LENGTH,
    LABEL_MIN_COUNT,
    LABEL_MIN_LENGTH,
    LABEL_REGEX_PATTERN,
    RENDER_MODE_SITE,
    TYPE_RDF_LIST
} from "../../Constants";
import {makeStyles} from '@material-ui/core/styles';
import {computeMinCount, getPropertyClasses, getPropertyNameFrom, toArray} from "../../components/util";

const useStyles = makeStyles(theme => ({
    noMaxWidth: {
        maxWidth: '375px',
    },
}));

function getRow(label, value) {
    if(value !== undefined) {
        return  <>
            <Grid item xs={3}>{label}</Grid>
            <Grid item xs={9}>{isArray(value) ? value.join(", "): value}</Grid>
        </>;
    } else {
        return <></>;
    }
}

export default function  FieldLabel(props) {
    const classes = useStyles();
    let {json, property, aliasesMap, isMandatory, customizations} = props;
    let propArr = [property, ...toArray(property[COMBINED_WITH_PROPERTY])];
    let tooltip = <div datatest={'tooltip'}>
        <Grid container spacing={1} style={{padding : '8px'}}>
            {
                property[ALIAS_SH_PATH] === ID
                    ? <>
                        <Grid item xs={12}>IRI of the resource.</Grid>
                    </>
                    : <>
                        <Grid item xs={3}>Property IRI</Grid>
                        <Grid item xs={9}>{property[ALIAS_SH_PATH]}</Grid>
                        <Grid item xs={3}>Alias</Grid>
                        <Grid item xs={9}>{aliasesMap[property[ALIAS_SH_PATH]]}</Grid>
                    </>
            }
        </Grid>
        {
            propArr.map(property => {
                let classTypeLabel = property[ALIAS_SH_CLASS] === TYPE_RDF_LIST
                    ? 'List Class'
                    : property[ALIAS_SH_OR] ? 'Or Classes' : 'Class'

                return <Grid key={property[ID]} container spacing={1} style={{padding : '8px'}}>
                    { getRow('From Shape', property[FROM_SHAPE])}
                    { getRow('Datatype', property[ALIAS_SH_DATATYPE])}
                    { getRow('Node Kind', property[ALIAS_SH_NODE_KIND])}
                    { getRow(classTypeLabel, getPropertyClasses(property))}
                    { getRow(LABEL_MIN_COUNT, property[ALIAS_SH_MIN_COUNT])}
                    { getRow(LABEL_MAX_COUNT, property[ALIAS_SH_MAX_COUNT])}
                    { getRow(LABEL_MIN_LENGTH, property[ALIAS_SH_MIN_LENGTH])}
                    { getRow(LABEL_MAX_LENGTH, property[ALIAS_SH_MAX_LENGTH])}
                    { getRow(LABEL_REGEX_PATTERN, property[ALIAS_SH_PATTERN])}
                </Grid>;
            })
        }
    </div>;

    let label = customizations?.renderMode === RENDER_MODE_SITE
        ? getPropertyNameFrom(property[ALIAS_SH_PATH], customizations)
        : aliasesMap[property[ALIAS_SH_PATH]] || property[ALIAS_SH_PATH];
    let labelNode = <span datatest={'label'}>{((computeMinCount(property) ? '*': '') + label)}</span>;

    return <Tooltip enterDelay={300} interactive classes={{ tooltip: classes.noMaxWidth }} title={tooltip}>
        {labelNode}
    </Tooltip>;
}


import React, {Component} from 'react';
import {fade, withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {SECONDARY_COLOR} from "../../Constants";
import {centerVertically} from "../../components/util";
import {Tooltip} from "@material-ui/core";

const styles = theme => ({
  label: {
  },
  labelFocused: {
    backgroundColor: fade(theme.palette.secondary.main, 0.1),
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  labelRoot: {
      display: "flex",
      alignItems: "center",
      paddingLeft: theme.spacing(0.5),
      marginLeft : '3px',
      border: '1px solid #ddd',
      borderRadius: "4px",
      minWidth : '264px',
      '&:hover': {
          borderColor : theme.palette.secondary.selected,
          backgroundColor: fade(theme.palette.secondary.selected, .05)
          ,
          '& span.renderActionsOnFocus': {
              // display : 'flex'
          }
      },
      '& span.renderActionsOnFocus': {
          // display : 'none',
          '& button' : {
              '& .MuiIconButton-label' : {
                  height : '24px'
              }
          }
      }
  },
  labelIcon: {
      marginRight: theme.spacing(1),
  },
  labelText: {
      paddingLeft : 8,
      color : theme.palette.primary.main
  },
    labelTextFocused: {
        color: theme.palette.secondary.main,
    },

});

class CustomAliasTreeLabel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focus : false
        }
    }

    onNodeClick = (e) => {
        let {onNodeClick} = this.props
        e.stopPropagation();
        if (onNodeClick) {
            onNodeClick(this.props);
        }
    };

    handleOnMouseOver = () => {
        this.setState({focus :true})
    }

    handleOnMouseOut = () => {
        this.setState({focus :false})
    }

    render() {
        const {classes, datatestOverride, datatest, tooltip, isList, node, item, renderLabel, renderLabelPrefix, renderActions, renderActionsOnFocus, labelText, isNodeFocused} = this.props;
        const labelFocusedClass = isNodeFocused ? classes.labelFocused : '';
        let computedStyle = isNodeFocused ? {borderColor : SECONDARY_COLOR} : {}
        let labelPrefix = renderLabelPrefix && renderLabelPrefix(node)
        let prefix = labelPrefix && centerVertically(<div style={{textAlign: 'center', width : '48px'}}>{labelPrefix}</div> )
        let {focus} = this.state;
        if(isList) {
            computedStyle = {
                ...computedStyle,
                marginLeft : '0px',
                width : 'calc(100% - 10px)',
            }
        }
        let nodeToReturn = (
            <div
                datatest={datatestOverride || datatest}
                onMouseEnter={this.handleOnMouseOver}
                onMouseLeave={this.handleOnMouseOut}
                style={computedStyle}
                className={`${classes.labelRoot} ${labelFocusedClass}`}
                onClick={this.onNodeClick}>
                { renderLabelPrefix && prefix}
                {!item && (
                    <Typography
                        noWrap={true}
                        component={'div'}
                        style={{

                            ...(prefix ? {fontSize : '14px'} : {fontWeight : '400'}),

                            minHeight : '32px',
                            display : 'flex',
                            flexDirection : 'column',
                            justifyContent : 'center',
                            marginLeft : labelPrefix === undefined ? 'none' : 4,
                            borderLeft : labelPrefix === undefined ? 'none' : '1px solid #ddd'
                        }}
                        className={`${isNodeFocused && classes.labelTextFocused} ${classes.labelText}`}
                    >
                        { (renderLabel ? renderLabel(this.props) : labelText)  }
                    </Typography>)
                }
                <div style={{flexGrow : '1'}}/>
                {renderActionsOnFocus && focus && renderActionsOnFocus(this.props)}
                {
                    renderActions && renderActions(this.props)
                        ? renderActions(this.props)
                        //add placeholder div only if no render action on focus
                        : renderActionsOnFocus ? <></> : <div style={{minWidth : "30px"}}/>
                }
            </div>
        )
        let tooltipToUse = tooltip || node.tooltip;

        return tooltipToUse
            ? <Tooltip title={tooltipToUse}>{nodeToReturn}</Tooltip>
            : nodeToReturn;
    }
};

export default withStyles(styles, {withTheme: true})(CustomAliasTreeLabel);

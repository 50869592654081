import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {styles} from "../../components/styles";
import {Grid} from "@material-ui/core";
import StepperHeader from "../../components/StepperHeader";
import FinishButton from "../../components/FinishButton";
import GridContainer from "../../components/GridContainer";
import MainHeaderBar from "../../components/MainHeaderBar";
import {
    getFileExtension,
    getMaxLengthMessage,
    getMaxSiteFileSize,
    getRequiredFieldMessage,
    getSearchResult, getShaclValidationFor,
    handleBackendError,
    isArrayOnly,
    isRequestSuccessful,
    isValidMaxLengthWithoutTrim,
    restrictMaximumCharacters,
    toArray
} from "../../components/util";
import {
    ALIAS_MANAGEMENT_DESCRIPTION,
    ALIAS_MANAGEMENT_FOR_APPLICATION,
    ALIAS_MANAGEMENT_HAS_PART,
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_IS_PART_OF, ALIAS_MANAGEMENT_LINKED_TO,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TYPE_CONFIGURATION,
    AT_CONTEXT,
    AT_GRAPH,
    COUNT_UPTO,
    EASYGRAPH_DATA_APPLICATION_EXPLORER,
    HTTP_HEADER_PREFER,
    ID, IRI_ACCOUNT_LABEL, IRI_MANAGEMENT_LABEL,
    PAGE_SIZE,
    QUERY,
    TYPE,
    VALIDATION_LANGUAGE_NAME_LENGTH,
    VALIDATION_SITE_FILE_MAX_COUNT,
    VALIDATION_SITE_FILE_MAX_SIZE, LABEL_IMPORT_SITE, ALIAS_MANAGEMENT_TYPE_OBJECT
} from "../../Constants";
import FileUpload from "../../components/FileUpload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import H2Title from "../../components/H2Title";
import FooterPreviousButton from "../../components/FooterPreviousButton";
import FooterNextButton from "../../components/FooterNextButton";
import {
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    postManagementGraph
} from "../../service/graph-api";
import {BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH} from "../../service/backend-paths";
import {
    createObjectId, getBannerImageURL,
    getFaviconImageURL,
    getLogoImageURL, getWhiteBackgroundImageURL, setBannerImageURL,
    setFaviconImageURL, setLinkedToId, setLogoImageURL, setWhiteBackgroundImageURL,
    WORKSPACE_PREFER_HEADER_VALUE
} from "./Workspaces";
import TextField from "../../components/TextField";
import uuid4 from "uuid/v4";
import {getNewIdForWorkspace, HAS_PARTS, PART_DATA} from "./PagesSetup";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import {isMultipleDatasetsInstance} from "../../Configs";
import {isObject} from "lodash";
import {getObjectURL} from "./UploadFileDialog";
import {GRAPH_VIEW_BACKGROUND_IMAGE, GRAPH_VIEW_SETTINGS} from "./DataViewSetup";
import {isVisualisation} from "./PageEditor";

const ALLOWED_TYPES = [".json"]

function initialState() {
    return {
        activeStep : 0,
        open: false,
        loading: false,
        name: '',
        valid : false,
        selectedFiles: [],
        expanded: []

    };
};


export async function getSiteForLabel(siteLabel) {
    let query = `{type(eq:["${ALIAS_MANAGEMENT_TYPE_CONFIGURATION}"]) ${ALIAS_MANAGEMENT_NAME}(eq:"${siteLabel}") ${ALIAS_MANAGEMENT_FOR_APPLICATION}(eq:"${EASYGRAPH_DATA_APPLICATION_EXPLORER}")}`;
    let dataset;
    if (dataset) {
        let datasetId = dataset[ID];
        query = `{type(eq:["${ALIAS_MANAGEMENT_TYPE_CONFIGURATION}"]) ${ALIAS_MANAGEMENT_LINKED_TO}(eq:"${datasetId}") ${ALIAS_MANAGEMENT_NAME}(eq:"${siteLabel}") ${ALIAS_MANAGEMENT_FOR_APPLICATION}(eq:"${EASYGRAPH_DATA_APPLICATION_EXPLORER}")}`;
    }
    const searchParams = {
        [QUERY]: query,
        [PAGE_SIZE]: 1,
        [COUNT_UPTO]: 1
    }
    let headerMap = {
        [HTTP_HEADER_PREFER]: WORKSPACE_PREFER_HEADER_VALUE
    }
    const sitesSearchResponse = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, searchParams, headerMap).catch(handleBackendError(this));
    if (!isRequestSuccessful(sitesSearchResponse)) {
        return Promise.reject(sitesSearchResponse);
    }
    let json = await sitesSearchResponse.json();
    let found = getSearchResult(json).find(ob => ob[ALIAS_MANAGEMENT_NAME] === siteLabel);
    return found;
}

export function isMainConfigurationForExplorer(a) {
    return toArray(a[ALIAS_MANAGEMENT_FOR_APPLICATION]).includes(EASYGRAPH_DATA_APPLICATION_EXPLORER);
}

class ImportSiteDialog  extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState()
    }

    isValid = () => {
        let {activeStep} = this.state
        switch (activeStep) {
            case 0:
                const {selectedFiles, selectedFilesError} = this.state
                let error = selectedFilesError && selectedFilesError === true ? true : false;
                return (selectedFiles && selectedFiles.length > 0 && !error)
            case 1:
                const {siteNameError} = this.state;
                return siteNameError ? false : true;
            default:
                return true
        }

    }


    handleClickOpen = (ev) => {
        this.setState({open: true});
        ev.preventDefault();
    };

    handleClose = () => {
        this.props.onClose()
        this.setState({...initialState()});
    };

    getSteps = () => {
        return ['Select File', 'Preview', 'Import'];
    }

    isLastStep = () => {
        const steps = this.getSteps();
        const {activeStep} = this.state
        return activeStep === steps.length - 1;
    }

    getFooter = () => {
        const {activeStep, loading} = this.state
        return this.isLastStep()
            ?  <>
                <div style={{flexGrow:1}}/>
                <div>
                    <FinishButton onClick={this.handleClose}/>
                </div>
            </>
            : <>
            <Button
                datatest={'cancelButton'}
                variant={"text"}
                onClick={this.handleClose}
                color="secondary"
            >
                Cancel
            </Button>
            <div style={{flexGrow:1}}/>
            {
                activeStep !== 0 &&
                <FooterPreviousButton
                    disabled={activeStep === 0 || loading}
                    onClick={this.handleBack}
                />
            }
            {
                <FooterNextButton
                    disabled={!this.isStepContentValid(activeStep) || loading}
                    onClick={this.handleNext}
                />
            }
        </>;
    }

    handleNext = async () => {
        let {activeStep, site} = this.state;
        let {dataset} = this.props;

        if(activeStep === 1) {
            this.setState({loading: true})

            let mainSite = site.find(s => s[ALIAS_MANAGEMENT_IS_PART_OF] === undefined);
            let siteLabel = mainSite[ALIAS_MANAGEMENT_NAME];
            let found = await getSiteForLabel(siteLabel).catch(e => {
                this.setState({loading: false, apiError: true, apiErrorResponse: e});
            });
            if(found === undefined) {
                let obj = {
                    [AT_CONTEXT] : getManagementContextURL(),
                    [AT_GRAPH] : site
                }
                let postResponse = await postManagementGraph(JSON.stringify(obj));
                if(isRequestSuccessful(postResponse)) {
                    this.setState({loading: false, activeStep: activeStep + 1});
                } else {
                    let text = await postResponse.text();
                    let postJson = JSON.parse(text);
                    let nameError = getShaclValidationFor(postJson, IRI_MANAGEMENT_LABEL).join("\n");
                    if(nameError) {
                        this.setState({loading: false, valid : false, siteNameError : nameError});
                    } else {
                        this.setState({loading: false, apiError: true, apiErrorResponse: postResponse});
                    }
                }

            } else {
                this.setState({loading: false, valid : false, siteNameError : 'Site with same name already exists.'})
            }
        } else {
            this.setState({loading: false, activeStep: activeStep + 1})
        }
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    isStepContentValid = () => {
        return this.state.valid
    }

    getMainHeaderBar = () => {
        return <Grid item xs={12} style={{paddingTop: 0}}>
            <MainHeaderBar title={this.getStepName()}></MainHeaderBar>
        </Grid>;
    }

    getStepName = () => {
        const {activeStep} = this.state
        return this.getSteps()[activeStep];
    }

    getStepContent = () => {
        const {activeStep} = this.state
        switch (activeStep) {
            case 0:
                return this.getSelectFileComponent();
            case 1:
                return this.getPreviewComponent();
            case 2:
                return this.getSuccessScreen();
            default:
                return undefined;
        }

    }

    getSuccessScreen = () => {
        let {theme} = this.props
        return <GridContainer style={{textAlign: 'center'}}>
            <Grid item xs={12}>
                <div>
                    <CheckCircleIcon fontSize="large" style={{fontSize: '80px', color: theme.palette.success.main}}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <H2Title style={{color: theme.palette.success.main}} title={'Import Successful'}/>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </GridContainer>;
    }

    hasCountError = (files) => {
        return files.length > VALIDATION_SITE_FILE_MAX_COUNT ? true : false;
    }

    onFilesSelect = (selectedFiles) => {
        let selectedFilesFromState = this.state.selectedFiles ? this.state.selectedFiles : [];
        let selectedFilesFiltered = selectedFiles.filter(f => ALLOWED_TYPES.includes("."+getFileExtension(f.name)))
            .filter(f => !selectedFilesFromState.find(sf => sf.name === f.name));
        let allFiles = selectedFilesFiltered.length > 0 ? [...selectedFilesFiltered, ...selectedFilesFromState] : selectedFilesFromState
        let selectedFilesError = this.hasCountError(allFiles);
        allFiles.forEach(f => {
            if(f.size > VALIDATION_SITE_FILE_MAX_SIZE){
                selectedFilesError = true
                this.setState({ [f.name+'error']: `File size should be less than ${getMaxSiteFileSize()}`})
            }
        })
        if(selectedFilesError) {
            this.setState({
                selectedFiles: allFiles,
                selectedFilesError: selectedFilesError,
                valid: (selectedFilesError === true ? false : true)
            });
        } else {
            let allDataArray;
            let promises = [];
            for (let selectedFile of allFiles) {
                let filePromise = new Promise(resolve => {
                    let fileReader = new FileReader();
                    fileReader.onload = () => {
                        let fileContent = fileReader.result;
                        resolve({name: selectedFile.name, fileContent: fileContent, file: selectedFile})
                    }
                    fileReader.readAsText(selectedFile)
                });
                promises.push(filePromise);
            }
            Promise.all(promises).then(fileObjects => {
                let idMap = {};
                fileObjects.forEach(p => {
                    allDataArray = JSON.parse(p.fileContent);
                    if(isArrayOnly(allDataArray)) {
                        let mainConfiguration = allDataArray.find(a => isMainConfigurationForExplorer(a));
                        let mainConfigurationOldId = mainConfiguration[ID];

                        let invalidType = allDataArray.find(o => o[TYPE] !== ALIAS_MANAGEMENT_TYPE_CONFIGURATION && o[TYPE] !== ALIAS_MANAGEMENT_TYPE_OBJECT);
                        if(invalidType) {
                            this.setState({ [p.name+'error']: 'File is not valid site file.'});
                            selectedFilesError = true;
                        } else if (!mainConfigurationOldId) {
                            this.setState({ [p.name+'error']: 'File is not valid site file. Missing key configuration.'});
                            selectedFilesError = true;
                        } else {
                            let allNonMainConfigurationDataArray = allDataArray.filter(ms => this.isConfigurationType(ms) && !isMainConfigurationForExplorer(ms));
                            let allObjectDataArray = allDataArray.filter(ms => this.isObjectType(ms));
                            //First generate new ids for all data
                            allDataArray.forEach(a => {
                                let oldId = a[ID];
                                if(this.isConfigurationType(a)) {
                                    if(idMap[oldId] === undefined) {
                                        const uuid = uuid4();
                                        let genId = getNewIdForWorkspace(uuid);
                                        idMap[oldId] = genId;
                                    }
                                } else if(this.isObjectType(a)) {
                                    if(idMap[oldId] === undefined) {
                                        let newUUIDKey = uuid4();
                                        let newId = createObjectId(newUUIDKey);
                                        idMap[oldId] = newId;
                                    }
                                }
                            })

                            //Update id in main configuration
                            let mainConfigurationNewId = idMap[mainConfigurationOldId];
                            mainConfiguration[ID] = mainConfigurationNewId;

                            //If there is dataset then update or add link to dataset
                            // else if instance is single dataset remove any dataset link
                            let {dataset} = this.props;
                            if (dataset) {
                                let datasetId = dataset[ID];
                                setLinkedToId(mainConfiguration, datasetId);
                            } else if (isMultipleDatasetsInstance() === false) {
                                delete mainConfiguration[ALIAS_MANAGEMENT_LINKED_TO];
                            }
                            this.updateSettingsHomePageQueriesAndHasParts(mainConfiguration, idMap);
                            //Now updated main config part ids and pages
                            if(mainConfiguration[ALIAS_MANAGEMENT_HAS_PART]) {
                                let settings = JSON.parse(mainConfiguration[ALIAS_MANAGEMENT_SETTINGS]);
                                //Generate ids for old ids
                                toArray(mainConfiguration[ALIAS_MANAGEMENT_HAS_PART]).forEach(p => {
                                    if(idMap[p] === undefined) {
                                        const uuid = uuid4();
                                        let genId = getNewIdForWorkspace(uuid);
                                        idMap[p] = genId;
                                    }
                                });
                                //Update page ids to new ids
                                mainConfiguration[ALIAS_MANAGEMENT_HAS_PART] = mainConfiguration[ALIAS_MANAGEMENT_HAS_PART].map(p => idMap[p]);
                                settings.pages = toArray(settings.pages).map(p => idMap[p]).filter(p => p);
                                settings.otherPages = toArray(settings.otherPages).map(p => idMap[p]).filter(p => p);
                                //set updated settings object
                                mainConfiguration[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings);
                            }


                            allNonMainConfigurationDataArray.forEach(st => {
                                let oldId = st[ID];
                                if(idMap[oldId] === undefined) {
                                    const uuid = uuid4();
                                    let genId = getNewIdForWorkspace(uuid);
                                    idMap[oldId] = genId;
                                }
                                let newId = idMap[oldId];
                                st[ID] = newId;

                                setLinkedToId(st, mainConfigurationNewId)
                                this.updateSettingsHomePageQueriesAndHasParts(st, idMap);

                                if(st[ALIAS_MANAGEMENT_HAS_PART]) {
                                    let settings = JSON.parse(st[ALIAS_MANAGEMENT_SETTINGS]);
                                    //Generate ids for old ids
                                    [...toArray(st[ALIAS_MANAGEMENT_HAS_PART]), ...toArray(st[ALIAS_MANAGEMENT_IS_PART_OF])].forEach(p => {
                                        if(idMap[p] === undefined) {
                                            const uuid = uuid4();
                                            let genId = getNewIdForWorkspace(uuid);
                                            idMap[p] = genId;
                                        }
                                    });
                                    //Update page ids to new ids
                                    st[ALIAS_MANAGEMENT_HAS_PART] = st[ALIAS_MANAGEMENT_HAS_PART].map(p => idMap[p]);
                                    if(st[ALIAS_MANAGEMENT_IS_PART_OF]) {
                                        st[ALIAS_MANAGEMENT_IS_PART_OF] = idMap[st[ALIAS_MANAGEMENT_IS_PART_OF]];
                                    }
                                    settings.pages = toArray(settings.pages).map(p => idMap[p]).filter(p => p);
                                    settings.otherPages = toArray(settings.otherPages).map(p => idMap[p]).filter(p => p);
                                    //set updated settings object
                                    st[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings);
                                } else if(st[ALIAS_MANAGEMENT_IS_PART_OF]) {
                                    if(idMap[st[ALIAS_MANAGEMENT_IS_PART_OF]] === undefined) {
                                        const uuid = uuid4();
                                        let genId = getNewIdForWorkspace(uuid);
                                        idMap[st[ALIAS_MANAGEMENT_IS_PART_OF]] = genId;
                                    }
                                    let newId = idMap[st[ALIAS_MANAGEMENT_IS_PART_OF]];
                                    st[ALIAS_MANAGEMENT_IS_PART_OF] = newId;
                                }
                            })
                            //Now process objects
                            allObjectDataArray.forEach(st => {
                                let oldObjectId = st[ID] ;
                                let oldSplit = oldObjectId.split("/");
                                let oldUUIDKey = oldSplit[oldSplit.length - 1];
                                let newObjectId = idMap[oldObjectId];
                                let newSplit = newObjectId.split("/");
                                let newUUIDKey = newSplit[newSplit.length - 1];

                                st[ID] = newObjectId;
                                let newImageObjectURL = getObjectURL(newUUIDKey);
                                let oldLinkedToIds = toArray(st[ALIAS_MANAGEMENT_LINKED_TO]);
                                let newLinkedToIds = oldLinkedToIds.map(oldLinkedToId => idMap[oldLinkedToId]).filter(o => o);
                                //it is possible that user might have pasted URLs of linked images from other sites
                                //So if there are no new linkedIds we set the id to new id of main workspace
                                if(newLinkedToIds.length < 1) {
                                    newLinkedToIds = toArray(mainConfigurationNewId);
                                }
                                setLinkedToId(st, newLinkedToIds);


                                let ws = allDataArray.find(ms => newLinkedToIds.includes(ms[ID]));
                                let parsedSettings = JSON.parse(ws[ALIAS_MANAGEMENT_SETTINGS]);
                                let faviconImageURL = getFaviconImageURL(parsedSettings);
                                if(faviconImageURL && faviconImageURL.includes(oldUUIDKey)) {
                                    setFaviconImageURL(parsedSettings, newImageObjectURL);
                                }
                                let logoImageURL = getLogoImageURL(parsedSettings);
                                if(logoImageURL && logoImageURL.includes(oldUUIDKey)) {
                                    setLogoImageURL(parsedSettings, newImageObjectURL);
                                }
                                let whiteBackgroundImageURL = getWhiteBackgroundImageURL(parsedSettings);
                                if(whiteBackgroundImageURL && whiteBackgroundImageURL.includes(oldUUIDKey)) {
                                    setWhiteBackgroundImageURL(parsedSettings, newImageObjectURL);
                                }

                                let bannerImageURL = getBannerImageURL(parsedSettings);
                                if(bannerImageURL) {
                                    let normalisedImageURL = newImageObjectURL.startsWith('url(') ? newImageObjectURL : 'url('+newImageObjectURL+')';
                                    if (isObject(bannerImageURL)) {
                                        for (const [key, value] of Object.entries(bannerImageURL)) {
                                            let imageURL = value;
                                            if(imageURL.includes(oldUUIDKey)) {
                                                setBannerImageURL(parsedSettings, normalisedImageURL, key);
                                            }
                                        }
                                    } else {
                                        if(bannerImageURL.includes(oldUUIDKey)) {
                                            setBannerImageURL(parsedSettings, normalisedImageURL);
                                        }
                                    }
                                }
                                if(parsedSettings?.labelProperties) {
                                    for (const [key, value] of Object.entries(parsedSettings.labelProperties)) {
                                        let classSettings = value;
                                        let classImageURL = classSettings?.[GRAPH_VIEW_SETTINGS]?.[GRAPH_VIEW_BACKGROUND_IMAGE];
                                        if(classImageURL && classImageURL.includes(oldUUIDKey)) {
                                            classSettings[GRAPH_VIEW_SETTINGS][GRAPH_VIEW_BACKGROUND_IMAGE] = newImageObjectURL;
                                        }
                                    }
                                }
                                ws[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(parsedSettings);
                            })
                        }
                    } else {
                        this.setState({ [p.name+'error']: 'File is not valid site file.'});
                        selectedFilesError = true;
                    }
                })
                this.setState({
                    site: allDataArray,
                    selectedFiles: allFiles,
                    selectedFilesError: selectedFilesError,
                    valid: (selectedFilesError === true ? false : true)
                });
            });
        }

    };

    isObjectType(ms) {
        return ms[TYPE] === ALIAS_MANAGEMENT_TYPE_OBJECT;
    }

    isConfigurationType(ms) {
        return ms[TYPE] === ALIAS_MANAGEMENT_TYPE_CONFIGURATION;
    }

    updateSettingsHomePageQueriesAndHasParts(st, idMap) {
        let settings = JSON.parse(st[ALIAS_MANAGEMENT_SETTINGS]);
        //Update Vis ids
        toArray(settings.homePage?.queries).forEach(q => {
            this.updatedIds(q, idMap);
        })
        toArray(settings[HAS_PARTS]).forEach(q => {
            this.updatedIds(q, idMap);
        })
        st[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings);
    }

    updatedIds = (q, idMap) => {
        if (isVisualisation(q)) {
            let oldVisId = q[PART_DATA];
            if (idMap[oldVisId] === undefined) {
                const uuid = uuid4();
                let genId = getNewIdForWorkspace(uuid);
                idMap[oldVisId] = genId;
            }
            q[PART_DATA] = idMap[oldVisId];
            q.uuid = uuid4();
        }

    }

    getSelectFileComponent = () => {
        let {selectedFiles} = this.state

        let content = <GridContainer>
            {this.getMainHeaderBar()}
            <Grid item xs={12}>
                <FileUpload
                    accept={ALLOWED_TYPES}
                    fileUploadMessage={`A maximum of ${VALIDATION_SITE_FILE_MAX_COUNT} file is allowed.`}
                    fileUploadMessageError={this.state.selectedFilesError}
                    dragNDropBoxTitle={"Drag 'n' Drop Schema File"}
                    files={ selectedFiles.map((f) => ({
                        f: f,
                        errors: (this.state[f.name + 'error'] ? [this.state[f.name + 'error']] : [])
                    }))}
                    onFilesSelect={this.onFilesSelect}
                    onDelete={(file) => {
                        let selectedFilesCopy = selectedFiles.filter(f => f.name !== file.name);
                        let errorName = file.name + 'error';
                        let otherError = selectedFilesCopy.find(fl => this.state[fl.name + 'error']) ? true : false;
                        let countError = this.hasCountError(selectedFilesCopy);
                        this.setState({selectedFiles: selectedFilesCopy,  [errorName]: null, selectedFilesError: (countError || otherError)});
                    }}
                />
            </Grid>
        </GridContainer>;

        return <>
            {content}
        </>;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    getPreviewComponent = () => {
        let {site, siteNameError} = this.state;
        let mainSite = site.find(s => s[ALIAS_MANAGEMENT_IS_PART_OF] === undefined && toArray(s[ALIAS_MANAGEMENT_FOR_APPLICATION]).includes(EASYGRAPH_DATA_APPLICATION_EXPLORER));
        return <GridContainer>
            {this.getMainHeaderBar()}
                <Grid item xs={12}>
                    <TextField
                        label={'Name'}
                        id='siteNameError'
                        name='siteNameError'
                        value={mainSite[ALIAS_MANAGEMENT_NAME]}
                        error={siteNameError}
                        onChange={(event) => {
                            const eventRestricted = restrictMaximumCharacters(event, VALIDATION_LANGUAGE_NAME_LENGTH);
                            mainSite[ALIAS_MANAGEMENT_NAME] = eventRestricted.target.value;
                            const value = event.target.value;
                            if (!value || value.trim() === '') {
                                this.setState({
                                    siteNameError: getRequiredFieldMessage('Name'),
                                    valid :false
                                })
                            } else if (!isValidMaxLengthWithoutTrim(value, VALIDATION_LANGUAGE_NAME_LENGTH)) {
                                this.setState({
                                    siteNameError: getMaxLengthMessage(VALIDATION_LANGUAGE_NAME_LENGTH),
                                    valid :false
                                })
                            } else {
                                this.setState({siteNameError: '', valid :true})
                            }
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        readOnly={true}
                        label={'Description'}
                        id='description'
                        name='description'
                        value={mainSite[ALIAS_MANAGEMENT_DESCRIPTION]}
                        multiline={true}
                        rowsMax="4"
                        rows="4"
                    />
                </Grid>
        </GridContainer>;
    }

    render () {
        const {classes, theme, open} = this.props;
        let {apiError, apiErrorResponse} = this.state;
        return <Dialog
            fullScreen={true}

            open={open}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.dialog}}
        >
            <DialogTitle style={{padding: '0px'}} disableTypography={true} id="form-dialog-title">
                <StepperHeader steps={this.getSteps()} activeStep={this.state.activeStep}
                               title={LABEL_IMPORT_SITE}></StepperHeader>
            </DialogTitle>
            <DialogContent tabIndex={-1} style={{outline: 'none', backgroundColor: theme.palette.grey.background, padding: '0px'}}>
                {apiError && <BackendErrorDialog open={apiError} error={apiErrorResponse} handleClose={() => this.setState({apiError: false, apiErrorResponse: undefined})}/>}

                {this.getStepContent()}
            </DialogContent>
            <DialogActions datatest={'dialogActions'} style={{padding: '12px 24px', borderTop: '1px solid ' + theme.palette.border.main}}>
                {this.getFooter()}
            </DialogActions>
        </Dialog>;
    }
}


ImportSiteDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
    dataset: PropTypes.any,
}

export default withStyles(styles, {withTheme: true})(ImportSiteDialog);

import React from "react";
import {Tooltip} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {RED_COLOR} from "../Constants";

export const StyledTooltip = withStyles({
    tooltip: {
        backgroundColor : RED_COLOR,
        maxWidth : '320px'

    },
    arrow: {
        color: RED_COLOR,
    }
})(Tooltip);

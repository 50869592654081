import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {traceRenderStart} from "../../components/Trace";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import FieldContainer from "../../components/FieldContainer";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import {getAllDatatypeProperties} from "../apiplayground/SearchFilter";
import {AT_DEFAULT, STYLE_GRID_ITEM_SPACING} from "../../Constants";
import LanguageSetup from "./LanguageSetup";
import DataViewSetup from "./DataViewSetup";
import StepperHeader from "../../components/StepperHeader";
import MainHeaderBar from "../../components/MainHeaderBar";
import UILabel from "./UILabel";
import {isString} from 'lodash';
import SearchSetup from "./SearchSetup";
import H4Title from "../../components/H4Title";
import HomePageQueriesSetup from "./HomePageQueriesSetup";
import PagesSetup from "./PagesSetup";
import FinishButton from "../../components/FinishButton";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import BasicSetup from "./BasicSetup";
import TranslationsSetup from "./TranslationsSetup";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";

class SaveButton extends Component {
    constructor(props) {
        super(props);
        props.changeListener(this.enableButton);
        this.state = {
            enabled: props.enabled
        }
    }

    enableButton = (errors) => {
        if(errors && Object.keys(errors).length > 0) {
            this.setState({enabled: false});
        } else {
            this.setState({enabled: true});
        }
    }

    handleClick = () => {
        this.setState({enabled: false});
        this.props.onClick();
    }

    render() {
        let {enabled} = this.state;
        let {title, endIcon, startIcon, theme, datatest, variant} = this.props;
        return <Button
            datatest={ datatest || 'saveAndClose'}
            disabled={enabled === true ? false : true}
            variant={variant || "contained"}
            onClick={this.handleClick}
            color="secondary"
            endIcon={endIcon }
            startIcon={startIcon}
            style={{
                marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)
            }}
        >{title || 'Save & Close'}</Button>;
    }
}


export function initSettingPath(parent, keysPathArray = []) {
    let parentNode = parent;
    keysPathArray.forEach(k => {
        if (!parentNode[k]) {
            parentNode[k] = {};
        } else if (isString(parentNode[k])) {
            parentNode[k] = {
                [AT_DEFAULT]: parentNode[k]
            };
        }
        parentNode = parentNode[k];
    });
    return parentNode;
}

export function getUILabel(label, theme, settings, onChange, keyPathObject, keysPathArray, helperText, containerStyle = {}) {
    return <FieldContainer datatest={keysPathArray.join('-')}  style={{padding: '24px', ...containerStyle}}>
        <H4Title title={label}></H4Title>
        <FieldContainer style={{marginTop: '8px', border: '2px solid', borderColor: theme.palette.white.main}}>
            {helperText && <Typography component={'div'} variant={"caption"}>{helperText}</Typography>}
            <UILabel
                settings={settings}
                onChange={onChange}
                helpText={''}
                valueObject={initSettingPath(keyPathObject, keysPathArray)}
            />
        </FieldContainer>
    </FieldContainer>;
}

export function getDefaultLanguage(settings) {
    return settings.browseLanguages.find((blo) => blo.isDefault === true);
}

export function isSettingPending(settings) {
    return settings.status === undefined;
}

const COMPONENT = 'WorkspaceSettingsDialog';

class WorkspaceSettingsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            pagesMenuTabValue: 0,
            allDatatypeProperties: getAllDatatypeProperties(props.configurations),
            changeListeners: [],
            completedSteps: []
        }
    }

    shouldCreateBeDisabled = () => {
        let {name, nameError, descriptionError} = this.state
        if (!name || nameError || descriptionError) {
            return true;
        }
        return false;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    setSettingsDirty = (step, errors) => {
        let {settings} = this.props;
        let {changeListeners} = this.state;
        //settings.dirty = true;
        changeListeners.forEach(l => l(errors));
    }


    getStepContent = () => {
        const {activeStep} = this.state
        switch (activeStep) {
            case 0:
                return this.getLanguageSetup();
            case 1:
                return this.getBasicsContent();
            case 2:
                return this.getTranslationsSetup();
            case 3:
                return this.getSearchTabContent();
            case 4:
                return this.getDataViewSetup()
            case 5:
                return this.getHomePageQueriesContent();
            case 6:
                return this.getPagesContent()
            case 7:
                return this.getOtherPagesContent()
            default:
                return undefined;
        }

    }

    setSettingsDirtyWithActiveStep = () => {
        let {activeStep} = this.state;
        return (errors) => this.setSettingsDirty(activeStep, errors)
    }

    getPagesContent = () => {
        let {settings, onExpand, workspace, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, location, shapes} = this.props;
        if (!settings.pages) {
            settings.pages = [];
        }
        return <React.Fragment key={'pages'}><PagesSetup
            workspace={workspace}
            settings={settings}
            pageArrayKey={'pages'}
            stepName={this.getStepName()}
            onChange={this.setSettingsDirtyWithActiveStep()}
            aliasesToIRIMap={aliasesToIRIMap}
            aliasesMap={aliasesMap}
            browseLanguage={browseLanguage}
            configurations={configurations}
            ontology={ontology}
            location={location}
            shapes={shapes}
            onExpand={onExpand}
        /></React.Fragment>;
    }

    getOtherPagesContent = () => {
        let {settings, onExpand, workspace, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, location, shapes} = this.props;
        if (!settings.otherPages) {
            settings.otherPages = [];
        }
        return <React.Fragment key={'otherPages'}><PagesSetup
            workspace={workspace}
            settings={settings}
            pageArrayKey={'otherPages'}
            stepName={this.getStepName()}
            onChange={this.setSettingsDirtyWithActiveStep()}
            aliasesToIRIMap={aliasesToIRIMap}
            aliasesMap={aliasesMap}
            browseLanguage={browseLanguage}
            configurations={configurations}
            ontology={ontology}
            location={location}
            shapes={shapes}
            onExpand={onExpand}
        /></React.Fragment>;
    }

    getBasicsContent = () => {
        let {settings, workspace, location, browseLanguage} = this.props;

        return <BasicSetup
            browseLanguage={browseLanguage}
            location={location}
            workspace={workspace}
            settings={settings}
            stepName={this.getStepName()}
            onChange={this.setSettingsDirtyWithActiveStep()}
        />;
    }

    getStepName = () => {
        const {activeStep} = this.state
        return this.getSteps()[activeStep];
    }

    getMainHeaderBar = () => {
        return <Grid item xs={12} style={{paddingTop: 0}}>
            <MainHeaderBar title={this.getStepName()}></MainHeaderBar>
        </Grid>;
    }

    getLanguageSetup = () => {
        let {settings} = this.props;
        return <LanguageSetup
            settings={settings}
            onChange={this.setSettingsDirtyWithActiveStep()}
            stepName={this.getStepName()}
        />;

    }


    getDataViewSetup = () => {
        let {location, settings, ontology, shapes, aliasesMap, configurations, workspace, aliasesToIRIMap} = this.props;
        return <DataViewSetup
            workspace={workspace}
            configurations={configurations}
            ontology={ontology}
            settings={settings}
            onChange={this.setSettingsDirtyWithActiveStep()}
            shapes={shapes}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            location={location}
            stepName={this.getStepName()}
        />;
    }

    getHomePageQueriesContent = () => {
        let {settings, aliasesMap, workspace, location, browseLanguage, shapes, configurations, ontology, aliasesToIRIMap, onExpand} = this.props;
        return <HomePageQueriesSetup
            stepName={this.getStepName()}
            aliasesMap={aliasesMap}
            settings={settings}
            workspace={workspace}
            onChange={this.setSettingsDirtyWithActiveStep()}
            location={location}
            ontology={ontology}
            onExpand={onExpand}
            shapes={shapes}
            aliasesToIRIMap={aliasesToIRIMap}
            configurations={configurations}
            browseLanguage={browseLanguage}
        />;
    }

    getTranslationsSetup = () => {
        let {settings, location, shapes, configurations, aliasesMap, ontology, aliasesToIRIMap} = this.props;
        return <TranslationsSetup
            stepName={this.getStepName()}
            configurations={configurations}
            aliasesMap={aliasesMap}
            settings={settings}
            onChange={this.setSettingsDirtyWithActiveStep()}
            ontology={ontology}
            aliasesToIRIMap={aliasesToIRIMap}
            location={location}
            shapes={shapes}
        />;
    }

    getSearchTabContent = () => {
        let {settings, configurations, aliasesMap, ontology, aliasesToIRIMap} = this.props;
        return <SearchSetup
            stepName={this.getStepName()}
            configurations={configurations}
            aliasesMap={aliasesMap}
            settings={settings}
            onChange={this.setSettingsDirtyWithActiveStep()}
            ontology={ontology}
            aliasesToIRIMap={aliasesToIRIMap}
        />;
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    getFooter = () => {
        const {activeStep, loading, changeListeners} = this.state;
        const {handleClose, settings, handleSave, theme} = this.props;
        return <>
            {
                isSettingPending(settings) ||
                <>
                    <Button datatest={'cancelButton'} variant={"text"} onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <SaveButton
                        enabled={settings.dirty}
                        changeListener={(listener) => changeListeners.push(listener)}
                        onClick={handleSave}
                        theme={theme}
                    />
                </>
            }
            <div style={{flexGrow: 1}}/>
            {
                activeStep !== 0  &&
                <SaveButton
                    key={'previous'+activeStep}
                    variant={'outlined'}
                    enabled={true}
                    changeListener={(listener) => changeListeners.push(listener)}
                    onClick={this.handleBack}
                    startIcon={<ArrowBackIcon/>}
                    title={'Previous'}
                    theme={theme}
                    datatest={'previousButton'}
                />

            }
            {
                (this.isLastStep()
                    ? isSettingPending(settings) && <FinishButton onClick={() => {
                        settings.status = 'done';
                        handleSave();
                    }}/>
                    : <SaveButton
                        key={'next'+activeStep}
                        enabled={true}
                        changeListener={(listener) => changeListeners.push(listener)}
                        onClick={this.handleNext}
                        endIcon={<ArrowForwardIcon/>}
                        title={'Next'}
                        theme={theme}
                    />)

            }
        </>;
    }

    isLastStep = () => {
        const steps = this.getSteps();
        const {activeStep} = this.state;

        return activeStep === steps.length - 1;
    }


    getSteps = () => {
        return ['Languages', 'Basics', 'Labels', 'Search', 'Data View', 'Home Page', 'Menu Pages', 'Other Pages'];
    }

    render() {
        traceRenderStart('', COMPONENT)
        const {classes, theme, open, settings, loading} = this.props;
        const {activeStep} = this.state;
        return <>
            {loading && <ProcessingBackdrop loading={loading}/>}
            <Dialog
                datatest={'settingsDialog'}
                fullScreen={true}
                open={open || false}
                aria-labelledby="form-dialog-title"
                classes={{paper: classes.dialog}}
            >
                <DialogTitle style={{padding: '0px'}} disableTypography={true} id="form-dialog-title">
                    <StepperHeader
                        appBarBackgroundColor={theme.palette.primary.main}
                        steps={this.getSteps()}
                        activeStep={activeStep}
                        title={'Site Settings'}
                        onClick={(index, label) => this.setState({activeStep: index})}
                        nonLinear={isSettingPending(settings) ? false : true}
                        completed={isSettingPending(settings) ? [] : this.getSteps().map((s, i) => i)}
                    />
                </DialogTitle>
                <DialogContent tabIndex={-1} style={{outline : 'none', backgroundColor: theme.palette.grey.background, padding: '0px'}}>
                    {this.getStepContent()}
                </DialogContent>
                <DialogActions style={{padding: '12px 24px', borderTop: '1px solid ' + theme.palette.border.main}}>
                    {this.getFooter()}
                </DialogActions>
            </Dialog>
        </>;
    }
}

WorkspaceSettingsDialog.propTypes = {
    open: PropTypes.bool,
    loading: PropTypes.bool,
    handleClose: PropTypes.func,
    handleSave: PropTypes.func,
    onSettingsChange: PropTypes.func,
    saveParentWorkspaceAndSettings: PropTypes.func,
    onThemeColorChange: PropTypes.func,
    workspace: PropTypes.object,
    settings: PropTypes.object,
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    location: PropTypes.object,
    browseLanguage: PropTypes.any,
    onExpand: PropTypes.func,

};

export default withStyles(styles, {withTheme: true})(WorkspaceSettingsDialog);

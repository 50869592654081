import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../components/styles";
import queryString from "query-string";
import AllPartsLayout from "./AllPartsLayout";
import {getHeader} from "../components/header/APIPlaygroundHeader";
import IconButton from "../components/IconButton";
import PropTypes from "prop-types";
import FieldContainer from "../components/FieldContainer";
import Tooltip from "@material-ui/core/Tooltip";
import {traceRenderStart} from "../components/Trace";
import ProcessingBackdrop from "../components/ProcessingBackdrop";
import List from "@material-ui/core/List";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import {theme} from "../theme";
import LeftBarTitle from "../components/LeftBarTitle";
import {LeftMenuIconButton} from "../components/LeftMenuIconButton";
import {LeftMenuToggleButton} from "../components/LeftMenuToggleButton";
import history from "../history";
import qs from "qs";


const COMPONENT = 'MenuColumnAndDetailsLayout.jsx'

const MODEL_VIEW = undefined;

function getLeftMenuTabValue(location) {
    let params = queryString.parse(location.search);
    return params.leftMenuTabValue ? Number(params.leftMenuTabValue) : MODEL_VIEW;
}

const MenuTabs = withStyles({
    root: {},
    indicator: {},
})(Tabs);

const MenuTab = withStyles({
    root: {
        textTransform: 'none',
        '@media (min-width: 600px)': {
            minWidth: "48px"
        }
    },
    selected: {
        color : theme.palette.secondary.main,
        backgroundColor: theme.palette.white.main,
        borderRadius: '4px 4px'
    }
})(Tab);

export function getLeftMenuComponent(callerThis, theme, minimized, items) {
    let width = minimized ? '52px' : '250px'
    return <>
        <LeftMenuToggleButton theme={theme} minimized={minimized} onClick={() => callerThis.setState({minimized: !minimized})}/>
        <div style={{height : '100%', width : width, backgroundColor : theme.palette.secondary.dark}}>
            <List datatest={"leftMenu"} style={{padding: '0px'}}>
                {
                    items && items.map(i => {
                        return <LeftMenuIconButton
                            disabled={i.disabled}
                            key={i.label}
                            theme={theme}
                            title={i.label}
                            onClick={i.onClick}
                            icon={i.icon}
                            minimized={minimized}
                        />;
                    })
                }
            </List>
        </div>
    </>;
}


export function getMenuColumnComponent(minimized, leftActionsRenderer, leftBarHeaderRenderer, leftBarRenderer) {
    let leftMenuWidth = minimized ? 77 : 275;
    let leftMenuMarginRight = 19;

    return <div style={{display: 'flex', height: '100%'}}>
        <div style={{minWidth: (leftMenuWidth+'px'), marginRight: leftMenuMarginRight+'px', height: '100%'}}>
            {leftActionsRenderer && leftActionsRenderer()}
        </div>
        <div style={{minWidth: `calc(100% - ${(leftMenuWidth+leftMenuMarginRight)+'px'})`, flexGrow: '1', height: '100%'}}>
            {leftBarHeaderRenderer && leftBarHeaderRenderer()}
            <FieldContainer style={{
                height: '100%',
                paddingTop: '0px',
                paddingRight: '0px',
                backgroundColor: theme.palette.white.main
            }}>
                <div datatest={'classesTabContent'} style={{height: '100%'}}>
                    {leftBarRenderer && leftBarRenderer()}
                </div>
            </FieldContainer>

        </div>
    </div>;
}


class MenuColumnAndDetailsLayout extends Component {
    constructor(props) {
        super(props);
        //this means import
        this.state = {
            leftMenuMinimized: false,
            expanded: [],
            loading: false,
            leftMenuTabValue: MODEL_VIEW,
            minimized: true,
            tabValue : 0,
        }
    }

    getLeftActions = () => {
        let {theme, leftMenuItems} = this.props;
        let {minimized} = this.state;
        return getLeftMenuComponent(this, theme, minimized, leftMenuItems);

    }

    pushToHistory = (params) => {
        let {location} = this.props;
        history.push(`${location.pathname}?${qs.stringify(params)}`);
    }

    getLeftBarHeader = () => {
        const {location, leftColumnTitle, leftColumnTitleOnClick, leftColumnMenuTabItems} = this.props;
        let leftMenuTabValue = getLeftMenuTabValue(location);


        return <>
            <LeftBarTitle title={leftColumnTitle} onClick={leftColumnTitleOnClick}/>
            {
                leftColumnMenuTabItems && leftColumnMenuTabItems.length > 0 &&
                <MenuTabs value={leftMenuTabValue ? leftMenuTabValue : 0} onChange={(event, newValue) => {
                    this.pushToHistory({leftMenuTabValue: newValue});
                    this.setState({loading : false})
                }} aria-label="request tabs">
                    {leftColumnMenuTabItems.map((i, index) => <MenuTab key={index} datatest={'mainTab'+i.label} label={i.label}/>)}
                </MenuTabs>
            }
        </>;
    }

    getLeftComponent = () => {
        const {leftColumnRenderer} = this.props;
        let {minimized} = this.state;

        if(!leftColumnRenderer) {
            let leftMenuWidth = minimized ? 77 : 275;
            let leftMenuMarginRight = 19;

            return <div style={{minWidth: (leftMenuWidth+'px'), marginRight: leftMenuMarginRight+'px', height: '100%'}}>
                {this.getLeftActions()}
            </div>;
        }
        return getMenuColumnComponent(minimized, this.getLeftActions, this.getLeftBarHeader,  leftColumnRenderer)

    }

    getHeaderButtons = () => {
        let {readOnly, detailsHeaderButtonItems} = this.props;

        return detailsHeaderButtonItems && <div style={{display : 'flex', marginTop : '8px'}}>
            <div style={{flexGrow: '1'}}/>
            {
                readOnly || !detailsHeaderButtonItems || detailsHeaderButtonItems.length === 0
                    ? <div style={{height : '30px'}}/>
                    : <>
                        {
                            detailsHeaderButtonItems.map(i => {
                                return <IconButton
                                    style={{marginRight: '6px'}}
                                    datatest={'formViewButton'}
                                    onClick={i.onClick}
                                    selected={i.selected}
                                >
                                    <Tooltip title={i.tooltip}>{i.icon}</Tooltip>
                                </IconButton>;
                            })
                        }
                    </>
            }
        </div>;
    }

    getMiddleComponent = () => {
        let {detailRenderer} = this.props
        let { loading} = this.state
        return <>
            {loading && <ProcessingBackdrop marginLeft={true} loading={true}/>}
            {this.getHeaderButtons()}
            {detailRenderer && detailRenderer()}
        </>;
    }


    render() {
        traceRenderStart("", COMPONENT)

        const { minimized} = this.state;
        const {theme, hideHeader, headerTitle, leftColumnRenderer} = this.props;
        const {apiError, apiErrorResponse, onApiErrorClose, isAccountProfile} = this.props;
        let maxWidth = leftColumnRenderer
            ? (minimized ? '452px' : '675px')
            : (minimized ? '77px' : '275px' ) ;
        let leftStyle = {
            padding: '0px',
            width: maxWidth,
            border: 'none',
            backgroundColor: theme.palette.grey.background
        }

        let leftComponentStyle = leftColumnRenderer
            ? {padding: '0px', 'height': 'calc(100vh - 64px)'}
            : {padding: '0', height: '100%'};
        let leftComponentContainerStyle = leftColumnRenderer
            ? {paddingRight: '0px'}
            : {padding: '0', overflow: 'hidden'};
        let leftComponentScroll = leftColumnRenderer
            ? {y: 'hidden', x: 'hidden'}
            : {y: 'auto'};

        let middleStyle = leftColumnRenderer
            ? {paddingLeft : '40px', paddingTop: '24px', paddingRight: '64px', paddingBottom: '0px'}
            : {marginLeft: '20px', paddingLeft : '0px', paddingTop: '24px', paddingBottom: '24px'};
        let middleComponentStyle = leftColumnRenderer
            ? {}
            : {};


        return (<>
                <AllPartsLayout
                    apiError={apiError}
                    apiErrorResponse={apiErrorResponse}
                    onApiErrorClose={onApiErrorClose}
                    header={ hideHeader || getHeader(headerTitle, isAccountProfile)}
                    leftStyle={leftStyle}
                    leftComponentScroll={leftComponentScroll}
                    leftComponentStyle={leftComponentStyle}
                    leftComponentContainerStyle={leftComponentContainerStyle}
                    leftComponent={this.getLeftComponent()}
                    middleComponent={this.getMiddleComponent()}
                    middleStyle={middleStyle}
                    middleComponentStyle={middleComponentStyle}
                    {...this.props}
                ></AllPartsLayout>
            </>
        );
    }

}

MenuColumnAndDetailsLayout.propTypes = {
    headerTitle: PropTypes.string,
    leftMenuItems: PropTypes.array,
    leftColumnTitle: PropTypes.string,
    leftColumnTitleOnClick: PropTypes.func,
    leftColumnMenuTabItems: PropTypes.array,
    leftColumnRenderer: PropTypes.func,
    detailRenderer: PropTypes.func,
    location: PropTypes.object,
    apiError: PropTypes.bool,
    apiErrorResponse : PropTypes.any,
    onApiErrorClose: PropTypes.func,
    isAccountProfile: PropTypes.bool

};

export default withStyles(styles, {withTheme: true})(MenuColumnAndDetailsLayout);

export const dcOntology = `
@prefix dc: <http://purl.org/dc/terms/> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
@prefix rdf: <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix ns0: <http://purl.org/dc/dcam/> .
@prefix owl: <http://www.w3.org/2002/07/owl#> .
@prefix foaf: <http://xmlns.com/foaf/0.1/> .

dc:
  dc:title "DCMI Metadata Terms - other"@en ;
  dc:publisher <http://purl.org/dc/aboutdcmi#DCMI> ;
  dc:modified "2012-06-14"^^xsd:date .

dc:abstract
  rdfs:label "Abstract"@en ;
  rdfs:comment "A summary of the resource."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:accessRights
  rdfs:label "Access Rights"@en ;
  rdfs:comment "Information about who access the resource or an indication of its security status."@en ;
  dc:description "Access Rights may include information regarding access or restrictions based on privacy, security, or other policies."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:RightsStatement ;
  dc:issued "2003-02-15"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:accrualMethod
  rdfs:label "Accrual Method"@en ;
  rdfs:comment "The method by which items are added to a collection."@en ;
  dc:description "Recommended practice is to use a value from the Collection Description Accrual Method Vocabulary [[DCMI-ACCRUALMETHOD](https://dublincore.org/groups/collections/accrual-method/)]."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:MethodOfAccrual ;
  rdfs:domain <http://purl.org/dc/dcmitype/Collection> ;
  dc:issued "2005-06-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:accrualPeriodicity
  rdfs:label "Accrual Periodicity"@en ;
  rdfs:comment "The frequency with which items are added to a collection."@en ;
  dc:description "Recommended practice is to use a value from the Collection Description Frequency Vocabulary [[DCMI-COLLFREQ](https://dublincore.org/groups/collections/frequency/)]."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Frequency ;
  rdfs:domain <http://purl.org/dc/dcmitype/Collection> ;
  dc:issued "2005-06-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:accrualPolicy
  rdfs:label "Accrual Policy"@en ;
  rdfs:comment "The policy governing the addition of items to a collection."@en ;
  dc:description "Recommended practice is to use a value from the Collection Description Accrual Policy Vocabulary [[DCMI-ACCRUALPOLICY](https://dublincore.org/groups/collections/accrual-policy/)]."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Policy ;
  rdfs:domain <http://purl.org/dc/dcmitype/Collection> ;
  dc:issued "2005-06-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:alternative
  rdfs:label "Alternative Title"@en ;
  rdfs:comment "An alternative name for the resource."@en ;
  dc:description "The distinction between titles and alternative titles is application-specific."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:audience
  rdfs:label "Audience"@en ;
  rdfs:comment "A class of agents for whom the resource is intended or useful."@en ;
  dc:description "Recommended practice is to use this property with non-literal values from a vocabulary of audience types."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:AgentClass ;
  dc:issued "2001-05-21"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:available
  rdfs:label "Date Available"@en ;
  rdfs:comment "Date that the resource became or will become available."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:bibliographicCitation
  rdfs:label "Bibliographic Citation"@en ;
  rdfs:comment "A bibliographic reference for the resource."@en ;
  dc:description "Recommended practice is to include sufficient bibliographic detail to identify the resource as unambiguously as possible."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2003-02-15"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:conformsTo
  rdfs:label "Conforms To"@en ;
  rdfs:comment "An established standard to which the described resource conforms."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Standard ;
  dc:issued "2001-05-21"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:contributor
  rdfs:label "Contributor"@en ;
  rdfs:comment "An entity responsible for making contributions to the resource."@en ;
  dc:description "The guidelines for using names of persons or organizations as creators apply to contributors."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Agent ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:coverage
  rdfs:label "Coverage"@en ;
  rdfs:comment "The spatial or temporal topic of the resource, spatial applicability of the resource, or jurisdiction under which the resource is relevant."@en ;
  dc:description "Spatial topic and spatial applicability may be a named place or a location specified by its geographic coordinates. Temporal topic may be a named period, date, or date range. A jurisdiction may be a named administrative entity or a geographic place to which the resource applies. Recommended practice is to use a controlled vocabulary such as the Getty Thesaurus of Geographic Names [[TGN](https://www.getty.edu/research/tools/vocabulary/tgn/index.html)]. Where appropriate, named places or time periods may be used in preference to numeric identifiers such as sets of coordinates or date ranges.  Because coverage is so broadly defined, it is preferable to use the more specific subproperties Temporal Coverage and Spatial Coverage."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Location, dc:Period, dc:Jurisdiction ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:created
  rdfs:label "Date Created"@en ;
  rdfs:comment "Date of creation of the resource."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:creator
  rdfs:label "Creator"@en ;
  rdfs:comment "An entity responsible for making the resource."@en ;
  dc:description "Recommended practice is to identify the creator with a URI.  If this is not possible or feasible, a literal value that identifies the creator may be provided."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Agent ;
  owl:equivalentProperty foaf:maker ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:date
  rdfs:label "Date"@en ;
  rdfs:comment "A point or period of time associated with an event in the lifecycle of the resource."@en ;
  dc:description "Date may be used to express temporal information at any level of granularity.  Recommended practice is to express the date, date/time, or period of time according to ISO 8601-1 [[ISO 8601-1](https://www.iso.org/iso-8601-date-and-time-format.html)] or a published profile of the ISO standard, such as the W3C Note on Date and Time Formats [[W3CDTF](https://www.w3.org/TR/NOTE-datetime)] or the Extended Date/Time Format Specification [[EDTF](http://www.loc.gov/standards/datetime/)].  If the full date is unknown, month and year (YYYY-MM) or just year (YYYY) may be used. Date ranges may be specified using ISO 8601 period of time specification in which start and end dates are separated by a '/' (slash) character.  Either the start or end date may be missing."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:dateAccepted
  rdfs:label "Date Accepted"@en ;
  rdfs:comment "Date of acceptance of the resource."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty.  Examples of resources to which a date of acceptance may be relevant are a thesis (accepted by a university department) or an article (accepted by a journal)."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2002-07-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:dateCopyrighted
  rdfs:label "Date Copyrighted"@en ;
  rdfs:comment "Date of copyright of the resource."@en ;
  dc:description "Typically a year.  Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2002-07-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:dateSubmitted
  rdfs:label "Date Submitted"@en ;
  rdfs:comment "Date of submission of the resource."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty.  Examples of resources to which a 'Date Submitted' may be relevant include a thesis (submitted to a university department) or an article (submitted to a journal)."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2002-07-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:description
  rdfs:label "Description"@en ;
  rdfs:comment "An account of the resource."@en ;
  dc:description "Description may include but is not limited to: an abstract, a table of contents, a graphical representation, or a free-text account of the resource."@en ;
  a rdf:Property ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:educationLevel
  rdfs:label "Audience Education Level"@en ;
  rdfs:comment "A class of agents, defined in terms of progression through an educational or training context, for which the described resource is intended."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:AgentClass ;
  dc:issued "2002-07-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:extent
  rdfs:label "Extent"@en ;
  rdfs:comment "The size or duration of the resource."@en ;
  dc:description "Recommended practice is to specify the file size in megabytes and duration in ISO 8601 format."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:SizeOrDuration ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:format
  rdfs:label "Format"@en ;
  rdfs:comment "The file format, physical medium, or dimensions of the resource."@en ;
  dc:description "Recommended practice is to use a controlled vocabulary where available. For example, for file formats one could use the list of Internet Media Types [[MIME](https://www.iana.org/assignments/media-types/media-types.xhtml)].  Examples of dimensions include size and duration."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:MediaType, dc:Extent ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:hasFormat
  rdfs:label "Has Format"@en ;
  rdfs:comment "A related resource that is substantially the same as the pre-existing described resource, but in another format."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Is Format Of."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:hasPart
  rdfs:label "Has Part"@en ;
  rdfs:comment "A related resource that is included either physically or logically in the described resource."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Is Part Of."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:hasVersion
  rdfs:label "Has Version"@en ;
  rdfs:comment "A related resource that is a version, edition, or adaptation of the described resource."@en ;
  dc:description "Changes in version imply substantive changes in content rather than differences in format. This property is intended to be used with non-literal values. This property is an inverse property of Is Version Of."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:identifier
  rdfs:label "Identifier"@en ;
  rdfs:comment "An unambiguous reference to the resource within a given context."@en ;
  dc:description "Recommended practice is to identify the resource by means of a string conforming to an identification system. Examples include International Standard Book Number (ISBN), Digital Object Identifier (DOI), and Uniform Resource Name (URN).  Persistent identifiers should be provided as HTTP URIs."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:instructionalMethod
  rdfs:label "Instructional Method"@en ;
  rdfs:comment "A process, used to engender knowledge, attitudes and skills, that the described resource is designed to support."@en ;
  dc:description "Instructional Method typically includes ways of presenting instructional materials or conducting instructional activities, patterns of learner-to-learner and learner-to-instructor interactions, and mechanisms by which group and individual levels of learning are measured.  Instructional methods include all aspects of the instruction and learning processes from planning and implementation through evaluation and feedback."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:MethodOfInstruction ;
  dc:issued "2005-06-13"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:isFormatOf
  rdfs:label "Is Format Of"@en ;
  rdfs:comment "A pre-existing related resource that is substantially the same as the described resource, but in another format."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Has Format."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:isPartOf
  rdfs:label "Is Part Of"@en ;
  rdfs:comment "A related resource in which the described resource is physically or logically included."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Has Part."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:isReferencedBy
  rdfs:label "Is Referenced By"@en ;
  rdfs:comment "A related resource that references, cites, or otherwise points to the described resource."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of References."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:isReplacedBy
  rdfs:label "Is Replaced By"@en ;
  rdfs:comment "A related resource that supplants, displaces, or supersedes the described resource."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Replaces."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:isRequiredBy
  rdfs:label "Is Required By"@en ;
  rdfs:comment "A related resource that requires the described resource to support its function, delivery, or coherence."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Requires."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:isVersionOf
  rdfs:label "Is Version Of"@en ;
  rdfs:comment "A related resource of which the described resource is a version, edition, or adaptation."@en ;
  dc:description "Changes in version imply substantive changes in content rather than differences in format. This property is intended to be used with non-literal values. This property is an inverse property of Has Version."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:issued
  rdfs:label "Date Issued"@en ;
  rdfs:comment "Date of formal issuance of the resource."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:language
  rdfs:label "Language"@en ;
  rdfs:comment "A language of the resource."@en ;
  dc:description "Recommended practice is to use either a non-literal value representing a language from a controlled vocabulary such as ISO 639-2 or ISO 639-3, or a literal value consisting of an IETF Best Current Practice 47 [[IETF-BCP47](https://tools.ietf.org/html/bcp47)] language tag."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:LinguisticSystem ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:license
  rdfs:label "License"@en ;
  rdfs:comment "A legal document giving official permission to do something with the resource."@en ;
  dc:description "Recommended practice is to identify the license document with a URI. If this is not possible or feasible, a literal value that identifies the license may be provided."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:LicenseDocument ;
  dc:issued "2004-06-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:mediator
  rdfs:label "Mediator"@en ;
  rdfs:comment "An entity that mediates access to the resource."@en ;
  dc:description "In an educational context, a mediator might be a parent, teacher, teaching assistant, or care-giver."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:AgentClass ;
  dc:issued "2001-05-21"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:medium
  rdfs:label "Medium"@en ;
  rdfs:comment "The material or physical carrier of the resource."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:PhysicalMedium ;
  ns0:domainIncludes dc:PhysicalResource ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:modified
  rdfs:label "Date Modified"@en ;
  rdfs:comment "Date on which the resource was changed."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:provenance
  rdfs:label "Provenance"@en ;
  rdfs:comment "A statement of any changes in ownership and custody of the resource since its creation that are significant for its authenticity, integrity, and interpretation."@en ;
  dc:description "The statement may include a description of any changes successive custodians made to the resource."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:ProvenanceStatement ;
  dc:issued "2004-09-20"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:publisher
  rdfs:label "Publisher"@en ;
  rdfs:comment "An entity responsible for making the resource available."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Agent ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:references
  rdfs:label "References"@en ;
  rdfs:comment "A related resource that is referenced, cited, or otherwise pointed to by the described resource."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Is Referenced By."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:relation
  rdfs:label "Relation"@en ;
  rdfs:comment "A related resource."@en ;
  dc:description "Recommended practice is to identify the related resource by means of a URI.  If this is not possible or feasible, a string conforming to a formal identification system may be provided."@en ;
  a rdf:Property ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:replaces
  rdfs:label "Replaces"@en ;
  rdfs:comment "A related resource that is supplanted, displaced, or superseded by the described resource."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Is Replaced By."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:requires
  rdfs:label "Requires"@en ;
  rdfs:comment "A related resource that is required by the described resource to support its function, delivery, or coherence."@en ;
  dc:description "This property is intended to be used with non-literal values. This property is an inverse property of Is Required By."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:rights
  rdfs:label "Rights"@en ;
  rdfs:comment "Information about rights held in and over the resource."@en ;
  dc:description "Typically, rights information includes a statement about various property rights associated with the resource, including intellectual property rights.  Recommended practice is to refer to a rights statement with a URI.  If this is not possible or feasible, a literal value (name, label, or short text) may be provided."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:RightsStatement ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:rightsHolder
  rdfs:label "Rights Holder"@en ;
  rdfs:comment "A person or organization owning or managing rights over the resource."@en ;
  dc:description "Recommended practice is to refer to the rights holder with a URI. If this is not possible or feasible, a literal value that identifies the rights holder may be provided."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Agent ;
  dc:issued "2004-06-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:source
  rdfs:label "Source"@en ;
  rdfs:comment "A related resource from which the described resource is derived."@en ;
  dc:description "This property is intended to be used with non-literal values. The described resource may be derived from the related resource in whole or in part. Best practice is to identify the related resource by means of a URI or a string conforming to a formal identification system."@en ;
  a rdf:Property ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:spatial
  rdfs:label "Spatial Coverage"@en ;
  rdfs:comment "Spatial characteristics of the resource."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:Location ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:subject
  rdfs:label "Subject"@en ;
  rdfs:comment "A topic of the resource."@en ;
  dc:description "Recommended practice is to refer to the subject with a URI. If this is not possible or feasible, a literal value that identifies the subject may be provided. Both should preferably refer to a subject in a controlled vocabulary."@en ;
  a rdf:Property ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:tableOfContents
  rdfs:label "Table Of Contents"@en ;
  rdfs:comment "A list of subunits of the resource."@en ;
  a rdf:Property ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:temporal
  rdfs:label "Temporal Coverage"@en ;
  rdfs:comment "Temporal characteristics of the resource."@en ;
  a rdf:Property ;
  ns0:rangeIncludes dc:PeriodOfTime ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:title
  rdfs:label "Title"@en ;
  rdfs:comment "A name given to the resource."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:type
  rdfs:label "Type"@en ;
  rdfs:comment "The nature or genre of the resource."@en ;
  dc:description "Recommended practice is to use a controlled vocabulary such as the DCMI Type Vocabulary [[DCMI-TYPE](http://dublincore.org/documents/dcmi-type-vocabulary/)]. To describe the file format, physical medium, or dimensions of the resource, use the property Format."@en ;
  a rdf:Property ;
  dc:issued "2008-01-14"^^xsd:date ;
  rdfs:isDefinedBy dc: .

dc:valid
  rdfs:label "Date Valid"@en ;
  rdfs:comment "Date (often a range) of validity of a resource."@en ;
  dc:description "Recommended practice is to describe the date, date/time, or period of time as recommended for the property Date, of which this is a subproperty."@en ;
  a rdf:Property ;
  rdfs:range rdfs:Literal ;
  dc:issued "2000-07-11"^^xsd:date ;
  rdfs:isDefinedBy dc: .
`

import React from 'react';
import PropTypes from 'prop-types';
import {Grid, withStyles} from '@material-ui/core';
import InstructionForAction from "./InstructionForAction";
import {LABEL_SUBCLASSES, LABEL_SUPERCLASSES, MESSAGE_SELECT_TO_VIEW_DETAILS} from "../Constants";
import DetailsTitle from "./DetailsTitle";
import {computeAllSubClasses, computeAllSuperClasses} from "./util";
import ClassHierarchyContainerSwitch from "./ClassHierarchyContainerSwitch";
import TextField from "./TextField";
import HelpIcon from "./HelpIcon";
import GridContainer from "../components/GridContainer";

function ContainerDetails(props) {
  let {classIRI, helpText, idGeneratingClasses, ontology, aliasesMap, onChange} = props
  let superClasses = computeAllSuperClasses(classIRI, ontology)
  let subClasses = computeAllSubClasses(classIRI, ontology)
  let titleActions = []
  if(helpText) {
    titleActions.push(<HelpIcon helpText={helpText}/>)
  }

  return (<div>
    {
      !classIRI && <>
        {helpText && <GridContainer noSpacing={true}>
          <Grid item xs={12}>
            <DetailsTitle title={''} actions={titleActions}/>
          </Grid>
        </GridContainer>
        }
      <InstructionForAction text={MESSAGE_SELECT_TO_VIEW_DETAILS}/>
      </>
    }
    {
      classIRI &&
      <GridContainer noSpacing={true}>
        <Grid item xs={12}>
          <DetailsTitle title={aliasesMap[classIRI]} actions={titleActions}/>
        </Grid>
        <Grid item xs={12}>
          <TextField id="iri" name="iri" value={classIRI} readOnly={true}/>
        </Grid>
        <Grid item xs={12} md={6}>
          <ClassHierarchyContainerSwitch
            title={LABEL_SUPERCLASSES}
            classesList={superClasses}
            aliasesMap={aliasesMap}
            idGeneratingClasses={idGeneratingClasses}
            handleFieldChange={onChange}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <ClassHierarchyContainerSwitch
              title={LABEL_SUBCLASSES}
              classesList={subClasses}
              aliasesMap={aliasesMap}
              idGeneratingClasses={idGeneratingClasses}
              handleFieldChange={onChange}
          />
        </Grid>
      </GridContainer>
    }
  </div>);
}

ContainerDetails.propTypes = {
  classIRI: PropTypes.string,
  idGeneratingClasses: PropTypes.array,
  ontology: PropTypes.array,
  aliasesMap: PropTypes.object,
  onChange: PropTypes.func,
  helpText: PropTypes.array

};

export default withStyles({}, {withTheme: true})(ContainerDetails);

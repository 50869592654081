import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {LABEL_PROPERTY_LANG} from "../../Constants";
import PropTypes from "prop-types";
import {Grid} from "@material-ui/core";
import {traceRenderStart} from "../../components/Trace";
import FieldContainer from "../../components/FieldContainer";
import Button from "@material-ui/core/Button";
import {
    addConnectedData,
    createChildConnection,
    deleteAllFilters,
    getConnections,
    getPropertiesSelect,
    getSelectOptions,
    isSelectOptionObjectType,
    setConnectedDataValue
} from "../../layouts/apiplayground/SearchMixin";
import {generateTree, renderBlock} from "../../layouts/apiplayground/SearchFilter";
import LanguageSearchSelect from "../../layouts/apiplayground/LanguageSearchSelect";
import Typography from "@material-ui/core/Typography";
import GlobalsContext from "../../components/GlobalsContext";
import {getUiLabelTranslationFromContext} from "../../components/util";
import {
    UI_LABELS_ADD_PROPERTY,
    UI_LABELS_LABEL_PROPERTY,
    UI_LABELS_LABEL_PROPERTY_LANGUAGE,
    UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1,
    UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2, UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2_VALUE,
    UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1_VALUE,
    UI_LABELS_PROPERTY
} from "../navigator/UILabel";


const COMPONENT = 'SearchFacet'
class SearchFacet extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    initialiseState = () => {
        return {
            filters: [
                {
                    label: '',
                    range: [],
                    filters: [
                        {
                            label : ''
                        }
                    ]
                }
                ,
                {
                    label : ''
                }
            ]
        };
    }

    addToMixin = (f) => {
        addConnectedData(f)
        this.props.onChange(this.props.facet)
    }

    propertyChange = (filter, level) => (value) => {
        if(value.value !== filter.value) {
            let connection = setConnectedDataValue(filter, value)
            deleteAllFilters(connection);
            if (level === 0 && isSelectOptionObjectType(value)) {
                this.addToMixin(connection);
            }
            this.props.onChange(this.props.facet)
        }
    }

    labelPropertyChange = (filter) => (value) => {
        if(!filter.labelProperty || value.value !== filter.labelProperty.value) {
            filter.labelProperty = value;
            this.props.onChange(this.props.facet)
        }
    }

    isCollapsed = (f) => {
        return f.expanded !== undefined && f.expanded === false;
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        return getUiLabelTranslationFromContext(this, key, defaultValue);
    }

    generateTree = (connection, level=0, hasAnyUnSelectedFilter) => {
        let {onChange, facet} = this.props
        let filters = getConnections(connection)
        let onDelete = level > 0 ? undefined : () => onChange(facet);
        let renderValue = (f, level) => this.getFacet(f, level);
        let containerStyle = level > 0 ? {marginBottom: '0px'} : {};
        return generateTree(filters, level, hasAnyUnSelectedFilter, onDelete, undefined, undefined, renderValue, containerStyle, undefined, this.getUiLabelTranslationFor);
    }


    filterDatatypeProperties = (properties) => {
        return properties.filter(p => !isSelectOptionObjectType(p));
    }

    getSelectOptions = (filter, level) => {
        let {aliasesMap, configurations} = this.props
        let selectOptions = getSelectOptions(configurations, aliasesMap, filter, false);
        if(level > 0) {
            selectOptions = this.filterDatatypeProperties(selectOptions);
        }
        return selectOptions;
    }

    getFacet = (filter, level) => {
        let {classes} = this.props;
        let selectOptions = this.getSelectOptions(filter, level);
        let label = getUiLabelTranslationFromContext(this, UI_LABELS_PROPERTY);
        return <div style={{display: 'flex'}}>
            <div datatest={'property'} style={{marginRight : '4px', width:'50%'}}>{getPropertiesSelect(selectOptions, filter, this.propertyChange(filter, level), label, classes, {width:'100%'})}</div>
            <div datatest={'labelProperty'} style={{width:'50%'}}>{this.getLabelProperty(filter, level)}</div>
        </div>;
    }

    getLabelProperty = (filter, level) => {
        let {classes} = this.props;
        let label = getUiLabelTranslationFromContext(this, UI_LABELS_LABEL_PROPERTY); ;
        let nextLevelConnection = createChildConnection(filter);
        if(isSelectOptionObjectType(filter)) {
            let selectOptions = this.getSelectOptions(nextLevelConnection, level + 1);
            return getPropertiesSelect(selectOptions, filter.labelProperty, this.labelPropertyChange(filter), label, classes, {width:'100%'}, undefined, 'largeWidthPropertyLabelRoot');
        } else {
            return <></>;
        }
    }

    render() {
        let {facet, theme, onChange} = this.props
        traceRenderStart( () => console.log(facet), COMPONENT)
        let {filters} = facet
        let hasAnyUnSelectedFilter = false
        return (<div datatest={'facetTabContent'}>
            <FieldContainer style={{marginTop: theme.spacing(1)}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <LanguageSearchSelect
                            datatest={'autocompleteLabelPropertyLanguage'}
                            label={getUiLabelTranslationFromContext(this, UI_LABELS_LABEL_PROPERTY_LANGUAGE) }
                            labelRootClass={'largeWidthPropertyLabelRoot'}
                            multiple={false}
                            value={facet[LABEL_PROPERTY_LANG]}
                            onChange={(val) => {
                                facet[LABEL_PROPERTY_LANG] = val;
                                onChange(facet);
                            }}
                        />
                        <Typography component={'p'} variant="body1" color="inherit">{
                            getUiLabelTranslationFromContext(this, UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1,  UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE1_VALUE)
                        }</Typography>
                        <Typography component={'p'} variant="body1" color="inherit">{
                            getUiLabelTranslationFromContext(this, UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2, UI_LABELS_LABEL_PROPERTY_LANGUAGE_HELP_LINE2_VALUE)
                        }</Typography>

                    </Grid>
                </Grid>
            </FieldContainer>
            {
                renderBlock(
                    '',
                    <>
                        {this.generateTree(facet, 0, hasAnyUnSelectedFilter)}
                        <div style={{display:'flex'}}>
                            <div style={{flexGrow : '1'}}></div>
                            <Button
                                datatest={'addPropertyButton'}
                                disabled={hasAnyUnSelectedFilter}
                                variant={"contained"}
                                onClick={() => {
                                    this.addToMixin(facet)
                                }}
                                color="secondary"
                            >
                                {getUiLabelTranslationFromContext(this, UI_LABELS_ADD_PROPERTY)}
                            </Button>
                        </div>
                    </>
                )
            }
        </div>);
    }
}

SearchFacet.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    facet: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(SearchFacet);

import React from "react";
import {Tooltip, withStyles} from "@material-ui/core";
import TextField from "../TextField";
import {
    getApiConfigurationResource,
    getDataDomainBaseURL,
    getPropertyValueForForm,
    isValidIRI,
    registerMock
} from "../util";
import {ALIAS_SH_PATH, EVENT_SHACL_VALIDATE, VALIDATION_INVALID_IRI_CHARACTERS_REGEX} from "../../Constants";
import IconButton from "@material-ui/core/IconButton";
import RefreshIcon from "@material-ui/icons/RefreshOutlined";
import uuid4 from "uuid/v4";
import {getUiLabelTranslationFrom, UI_LABELS_INVALID_IRI_VALUE} from "../../layouts/navigator/UILabel";

export function generateId(configurations, label) {
    return getDataDomainBaseURL(getApiConfigurationResource(configurations)) + label + '/' + uuid4();
}

class IdType extends React.Component {
    constructor(props) {
        super(props)
        let value = getPropertyValueForForm(props)
        this.state = {
            value : value === undefined ? '' : value
        }
    }

    componentDidMount() {
        if(getPropertyValueForForm(this.props) === undefined) {
            let {configurations, label} = this.props
            this.updatePropertyValue(generateId(configurations, label))
        }
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let {configurations, label, customizations} = this.props;
        if(eventName === EVENT_SHACL_VALIDATE) {
            let {value} = this.state;
            return this.validate(value);
        }
        this.updatePropertyValue(generateId(configurations, label));
    }


    validate = (value) => {
        let {customizations} = this.props;

        if (value && !isValidIRI(value)) {
            let uiLabelTranslationFrom = getUiLabelTranslationFrom(UI_LABELS_INVALID_IRI_VALUE, customizations);
            this.setState({validationError: uiLabelTranslationFrom});
            return {
                ref: this.inputRef,
                message: uiLabelTranslationFrom
            };
        } else {
            this.setState({validationError: undefined});
        }
        //do nothing
        return;
    }

    updatePropertyValue = (value) => {
        let {onChange, property} = this.props
        this.setState({value: value})
        if(value) {
            property.value = value;
        } else {
            delete property.value;
        }
        this.validate(value);
        onChange()
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {depth, containerClass, label, property, configurations} = this.props
        let {value, validationError} = this.state
        return <TextField
            inputProps={{
                ref: this.setRef
            }}
            placeholder={'If left blank the ID will be automatically generated'}
            paperStyle={{padding : '0px', paddingRight : '34px'}}
            key={containerClass+'-'+property[ALIAS_SH_PATH]+'-'+depth}
            value={value}
            onChange={ (event) => {
                this.updatePropertyValue(event.target.value)
            }}
            error={validationError}
            InputProps={{
                endAdornment: ( <>

                    <Tooltip title={'Regenerate ID'}>
                        <IconButton size={'small'} onClick={() => {
                            let id = generateId(configurations, label )
                            this.updatePropertyValue(id)
                        }}>
                            <RefreshIcon/>
                        </IconButton>
                    </Tooltip>
                   </>
                )
            }}
        />;
    }
}

export default withStyles({}, {withTheme: true})(IdType);

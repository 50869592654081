import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import PropTypes from "prop-types";
import {TextField as OtherTextField} from "@material-ui/core";
import {ORDER_PRECEDENCE, SORT_ASCENDING, SORT_DESCENDING} from "../../Constants";
import Button from "@material-ui/core/Button";
import {
    addConnectedData,
    getConnections,
    getPropertiesSelect,
    getSelectOptions,
    isSelectOptionObjectType,
    setConnectedDataValue
} from "../../layouts/apiplayground/SearchMixin";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {generateTree, renderBlock, renderObjectValue} from "../../layouts/apiplayground/SearchFilter";
import GlobalsContext from "../../components/GlobalsContext";
import {getUiLabelTranslationFromContext} from "../../components/util";
import {UI_LABELS_ADD_PROPERTY, UI_LABELS_PRECEDENCE, UI_LABELS_ORDER, UI_LABELS_PROPERTY} from "../navigator/UILabel";

export const SORT_ASCENDING_OPTION = {label: SORT_ASCENDING, value: SORT_ASCENDING}
export const SORT_DESCENDING_OPTION = {label: SORT_DESCENDING, value: SORT_DESCENDING}

export const SORT_DIRECTION_OPTIONS = [
    SORT_ASCENDING_OPTION,
    SORT_DESCENDING_OPTION
]

class SearchSort extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    addToMixin = (f) => {
        addConnectedData(f)
        this.props.onChange(this.props.sort)
    }

    propertyChange = (filter) => (value) => {
        setConnectedDataValue(filter, value)
        this.props.onChange(this.props.sort)
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        return getUiLabelTranslationFromContext(this, key, defaultValue);
    }


    generateTree = (connection, level=0, hasAnyUnSelectedFilter) => {
        let {onChange, sort} = this.props
        let filters = getConnections(connection)
        let onDelete = () => onChange(sort);
        let renderProperty = (f) => this.getProperty(f)
        let renderOperator = (f) => this.getOperator(f)
        let renderValue = (f, level, hasAnyUnSelectedFilter) => this.getValue(f, level, hasAnyUnSelectedFilter)
        return generateTree(filters, level, hasAnyUnSelectedFilter, onDelete, renderProperty, renderOperator, renderValue, undefined, undefined, this.getUiLabelTranslationFor);
    }

    getProperty = (filter) => {
        let {classes, aliasesMap, configurations} = this.props
        let objectOnly = false
        let selectOptions = getSelectOptions(configurations, aliasesMap, filter, objectOnly)
        let label = getUiLabelTranslationFromContext(this, UI_LABELS_PROPERTY)
        return getPropertiesSelect(selectOptions, filter, this.propertyChange(filter), label, classes);
    }

    getOperator = (f) => {
        let {sort, classes, onChange} = this.props;
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.smallWidthPrecedenceLabelRoot
                }
            }
            : {};

        return <div style={{display: 'flex'}}>
                    <Autocomplete
                        datatest={'autocompleteDirection'}
                        style={{width : '120px', marginRight : '4px'}}
                        value={f.order || ''}
                        options={SORT_DIRECTION_OPTIONS}
                        getOptionLabel={option => option.label ? option.label : ''}
                        onChange={(event, val) => {
                            f.order = val
                            onChange(sort)
                        }}
                        renderInput={params => (
                            <OtherTextField
                                label={getUiLabelTranslationFromContext(this, UI_LABELS_ORDER)}
                                {...params}
                                margin={"dense"}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={inputLabelProps}
                            />
                        )}
                        size={"small"}
                        disableClearable={true}
                    />
                <div style={{width: '120px'}}>
                    <OtherTextField
                        datatest={'textField-Precedence'}
                        value={f[ORDER_PRECEDENCE]||''}
                        margin={"dense"}
                        variant="outlined"
                        label={getUiLabelTranslationFromContext(this, UI_LABELS_PRECEDENCE)}
                        onChange={(event) => {
                            f[ORDER_PRECEDENCE] = event.target.value
                            onChange(sort)
                        }}
                        InputLabelProps={inputLabelProps}
                    />
                </div>
            </div> ;
    }

    getValue = (f, level, hasAnyUnSelectedFilter) => {
        let {sort, onChange, theme} = this.props;
        let renderTree = () => this.generateTree(f, level + 1, hasAnyUnSelectedFilter);
        let onAddButtonClick = () => {
            this.addToMixin(f);
            onChange(sort);
        };
        return f.value && isSelectOptionObjectType(f)  && renderObjectValue(renderTree, theme, hasAnyUnSelectedFilter , getUiLabelTranslationFromContext(this, UI_LABELS_ADD_PROPERTY), onAddButtonClick, '160px');
    }

    render() {
        let {theme, sort} = this.props;
        let hasAnyUnSelectedFilter = false;
        return <div datatest={'sortTabContent'}>{
            renderBlock(
                '',
                <>
                    {this.generateTree(sort, 0, hasAnyUnSelectedFilter)}
                    <div style={{display:'flex'}}>
                        <div style={{flexGrow : '1'}}></div>
                        <Button datatest={'addPropertyButton'} disabled={hasAnyUnSelectedFilter} variant={"contained"} onClick={() => this.addToMixin(sort)} color="secondary">
                            {getUiLabelTranslationFromContext(this, UI_LABELS_ADD_PROPERTY)}
                        </Button>
                    </div>
                </>
            )
        }</div>;
    }

}

SearchSort.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    sort: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(SearchSort);

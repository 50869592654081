import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import {generateMockingSetupId, getRegExForProperty, getRegExValue, getRequiredFieldMessage} from "./util";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import {styles} from "../components/styles";
import RandExp from "randexp";

import {Grid, TextField as OtherTextField, Tooltip} from "@material-ui/core";
import IconButton from "./IconButton";
import AddCircleIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import {
    ALIAS_SH_PATH,
    ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP,
    ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP,
    ID,
    STYLE_GRID_ITEM_SPACING,
    TYPE
} from "../Constants";
import H2Title from "./H2Title";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FieldLabel from "./ShapeToForm/FieldLabel";
import FieldContainer from "../components/FieldContainer";
import RegExField from "../components/RegExField";
import H4Title from "../components/H4Title";

const RANDOM_REGEX = 'Random from regular expression';
const RANDOM_FROM_LIST = 'Random from predefined list';

export const RANDOM_REGEX_VALUE = {
    value: ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP,
    label: RANDOM_REGEX
};
export const RANDOM_FROM_LIST_VALUE = {
    value: ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP,
    label: RANDOM_FROM_LIST
};
export const STRATEGY_OPTIONS = [
    RANDOM_REGEX_VALUE,
    //RANDOM_FROM_LIST_VALUE
];

class CreateShapePropertyMockingDialog extends React.Component {
    constructor(props) {
        super(props);
        let {mockingSetupObject, property} = this.props;
        this.state = {
            open: false,
            loading: false,
            strategy: mockingSetupObject
                ? STRATEGY_OPTIONS.find(o => o.value === mockingSetupObject[TYPE])
                : RANDOM_REGEX_VALUE,
            regexPattern: this.initialiseRegexPattern(mockingSetupObject, property)
        }
    }

    initialiseRegexPattern = (mockingSetupObject, property) => {
        if(mockingSetupObject && mockingSetupObject.pattern) {
            return mockingSetupObject.pattern;
        } else {
            return getRegExForProperty(property).source
        }
    };

    handleClose = () => {
        this.setState({open: false});
    };

    clearValidationErrors = () => {
        Object.keys(this.state).forEach(k => {
            if (k.endsWith('Error')) {
                this.setState({[k]: ''});
            }
        })
    };

    isValid = () => {
        const {regexPattern, strategy, lines} = this.state
        const {property} = this.props
        let valid = [];
        let strategyValue = strategy ? strategy.value : undefined
        if(!strategyValue) {
            valid.push(false);
        }
        switch (strategyValue) {
            case ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP:
                if(!regexPattern || getRegExValue(regexPattern, property) === null) {
                    valid.push(false);
                }
                break;
            case ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP:
                if(!lines) {
                    valid.push(false);
                }
                break;
            default :
                valid.push(true);
        }

        return valid.filter(v => v === false).length > 0 ? false : true;
    };

    handleSubmit = () => {
        let {shape, property, mockingSetupObject} = this.props
        let {regexPattern, strategy, lines} = this.state

        let mockObject = mockingSetupObject
            ? mockingSetupObject
            : {
                [ID]: generateMockingSetupId(),
                propertyIRI : property[ALIAS_SH_PATH],
                shapeIRI : shape[ID]
            }
        let strategyValue = strategy ? strategy.value : undefined
        switch (strategyValue) {
            case ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP:
                mockObject[TYPE] = ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP
                mockObject.pattern = regexPattern
                break;
            case ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP:
                mockObject[TYPE] = ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP
                mockObject.lines = lines
                break;
            default :
                break;
        }

        let eventToSend = {
            action: "saveJSON",
            data: mockObject
        }

        this.props.eventHandler(eventToSend).then(() => {
            this.setState({
                open: false,
                loading: false
            });
        });
    }

    setRequiredError = (key, name) => {
        this.setState({[key]: getRequiredFieldMessage(name)})
    }

    handleFieldChange = (event, callback) => {
        const {target: {name, value}} = event
        this.setState({
            [name]: value
        }, callback);
    }

    getComponentForStrategy = () => {
        let {strategy} = this.state
        let strategyValue = strategy ? strategy.value : undefined
        switch(strategyValue) {
            case ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP:
                return this.getRegExComponent();
            case ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP :
                return this.getComponentForPreDefinedListStrategy();
            default:
                return undefined;
        }
    }

    getRegExTestValue = (regexPattern) => {
        if(!regexPattern) {
            return null;
        }
        try {
            let value = new RandExp(new RegExp(regexPattern)).gen();
            return value ? value : null;
        } catch (e) {
            return null;
        }
    }


    getRegExComponent = () => {
        let {regexPattern} = this.state
        let {property} = this.props;
        return <RegExField
            property={property}
            regexPattern={regexPattern}
            onChange={this.handleFieldChange}
        />;
    }

    getComponentForPreDefinedListStrategy = () => {
        let {lines} = this.state
        return <Grid item xs={12}>
            <OtherTextField
                variant={'outlined'}
                fullWidth={true}
                label={'List of Lines'}
                name={'lines'}
                value={lines}
                onChange={this.handleFieldChange}
                multiline={true}
                rows={6}
            />
        </Grid>;
    }


    getDialogBody = () => {
        const {classes, theme, property, aliasesMap} = this.props
        const {loading, strategy} = this.state
        let inputLabelProps = classes
            ? {
                classes : {
                    root : classes.largeWidthPropertyLabelRoot,
                    shrink: classes.smallWidthLabelShrink
                },
                disabled : false
            }
            : {};

        return <>
            <DialogTitle id="form-dialog-title">
                <H2Title>Mocking Setup</H2Title>
            </DialogTitle>
            {
                <DialogContent>
                    <FieldContainer style={{padding : '8px'}}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <H4Title>
                                <FieldLabel  json={JSON.stringify(property, null, 4)} property={property} aliasesMap={aliasesMap}></FieldLabel>
                            </H4Title>
                        </Grid>
                        <Grid item xs={12}>
                                <Autocomplete
                                    value={strategy}
                                    options={STRATEGY_OPTIONS}
                                    disabled={true}
                                    onChange={(event, val) => {
                                        this.setState({strategy: val})
                                    }}
                                    getOptionLabel={option => option.label ?  option.label : ''}
                                    renderInput={params => (
                                        <OtherTextField
                                            label={'Mocking Strategy'}
                                            {...params}
                                            margin={"dense"}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={inputLabelProps}
                                        />
                                    )}
                                    disableClearable
                                />
                        </Grid>
                        {this.getComponentForStrategy()}
                    </Grid>
                    </FieldContainer>
                </DialogContent>
            }
            <DialogActions>
                <Button variant={"outlined"} onClick={this.handleClose} color="secondary">Cancel</Button>
                <Button style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"}
                        disabled={loading || !this.isValid()} color="secondary"
                        onClick={this.handleSubmit}>{'Save' }{loading &&
                <CircularProgress size={24} className={classes.buttonProgress}/>}</Button>
            </DialogActions>
        </>;
    }

    render() {
        const {classes, theme, buttonIcon, editProp} = this.props
        const {
            loading,
        } = this.state
        return (
            <React.Fragment>
                <Tooltip arrow placement="bottom" title={editProp ? 'Edit Property' : 'Add New Property'}>
                    <IconButton className={editProp ? classes.cardActionButton : classes.detailsActionButton}
                                size={'small'} onClick={() => {
                        this.setState({open: true});
                    }}>
                        {buttonIcon ? buttonIcon : <AddCircleIcon/>}
                    </IconButton>
                </Tooltip>
                <Dialog
                    maxWidth={"sm"}
                    fullWidth={true}
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    {loading === false ? this.getDialogBody() : <DialogContent><div style={{padding: theme.spacing(STYLE_GRID_ITEM_SPACING), textAlign: 'center'}}><CircularProgress/></div></DialogContent>}
                </Dialog>

            </React.Fragment>
        )
    }

}

CreateShapePropertyMockingDialog.propTypes = {
    eventHandler: PropTypes.func.isRequired,
    shape: PropTypes.object.isRequired,
    buttonIcon: PropTypes.object,
    property: PropTypes.object,
    mockingSetupObject: PropTypes.object,
    aliasesMap: PropTypes.object,
    ontology: PropTypes.array,
}

export default withStyles(styles)(CreateShapePropertyMockingDialog);

export const feedbackOntology = `
@prefix owl:  <http://www.w3.org/2002/07/owl#> .
@prefix rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix rdfs:  <http://www.w3.org/2000/01/rdf-schema#> .
@prefix xsd:  <http://www.w3.org/2001/XMLSchema#> .
@prefix egf:  <https://easygraph.graphifi.com/schema/feedback/> .

<https://easygraph.graphifi.com/schema/feedback> a owl:Ontology .

egf:dateCreated rdfs:range    xsd:dateTime ;
                rdf:type  owl:DatatypeProperty ;
                rdfs:label    "Date created"@en .

egf:displayName rdfs:range    xsd:string ;
                rdf:type  owl:DatatypeProperty ;
                rdfs:label    "Display name"@en .

egf:comment rdfs:range    rdf:langString   ;
                rdf:type  owl:DatatypeProperty ;
                rdfs:label    "Comment"@en .

egf:createdBy rdf:type  owl:ObjectProperty ;
                rdfs:label    "Created by"@en .

egf:status rdf:type  owl:ObjectProperty ;
              rdfs:label    "Status"@en .

egf:feedbackType rdf:type  owl:ObjectProperty ;
              rdfs:label    "Type of feedback"@en .

egf:subject rdf:type  owl:ObjectProperty ;
              rdfs:label    "Subject of feedback"@en .
egf:predicate rdf:type  owl:ObjectProperty ;
              rdfs:label    "Predicate of feedback"@en .
egf:object rdf:type rdf:Property ;
              rdfs:label    "Object of feedback"@en .

egf:Feedback rdf:type owl:Class;
     rdfs:label       "Feedback"@en ;
     rdfs:subClassOf owl:Thing ,
         [
             a owl:Restriction ;
             owl:onProperty egf:dateCreated ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:createdBy ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:displayName ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:status ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:feedbackType ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:subject ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:predicate ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:object ;
             owl:maxCardinality 1
         ],
         [
             a owl:Restriction ;
             owl:onProperty egf:comment ;
             owl:maxCardinality 1
         ].
`;

import React from "react";
import {withStyles} from "@material-ui/core";
import {STYLE_MAIN_SPACING} from "../Constants";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Header from "./header/Header";
import MainAppBar from "./header/MainAppBar";
import PropTypes from "prop-types";
import {toArray} from "./util";

const styles = () => ({});

let StepperHeader = props => {
    const {title, appBarBackgroundColor, isAccountProfile, steps, activeStep, theme, showStepper, onClick, nonLinear, completed} = props;

    const stepper = showStepper === undefined || showStepper === true
        ? <Stepper datatest={'stepper'} nonLinear={nonLinear} key={'headerStepper'} activeStep={activeStep} style={{padding: theme.spacing(2, STYLE_MAIN_SPACING)}}>
            {steps.map((label, index) => {
                let stepStyle = onClick ? {cursor : 'pointer'} : {}
                let labelStyle = activeStep === index ? {color : theme.palette.secondary.main} : {}
                return nonLinear === true ?
                    <Step style={stepStyle} key={label} completed={toArray(completed).includes(index)} onClick={() => onClick && onClick(index, label) }>
                        <StepLabel>
                            <span style={labelStyle}>{label}</span>
                        </StepLabel>
                    </Step>
                    : <Step style={stepStyle} key={label}>
                        <StepLabel>
                            <span style={labelStyle}>{label}</span>
                        </StepLabel>
                    </Step>
            })}
        </Stepper>
    :   <></>    ;
    return <Header components={[
        <MainAppBar appBarBackgroundColor={appBarBackgroundColor} isAccountProfile={isAccountProfile} key={'headerMainAppBar'} title={title}/>,
        <React.Fragment key={'stepperBar'}>{stepper}</React.Fragment>,
    ]}></Header>;
};

StepperHeader.propTypes = {
    appBarBackgroundColor: PropTypes.string,
    title: PropTypes.string,
    steps: PropTypes.array,
    activeStep: PropTypes.number,
    showStepper: PropTypes.bool,
    isAccountProfile: PropTypes.bool,
    onClick: PropTypes.func,
    nonLinear: PropTypes.bool,
    completed: PropTypes.array,

}


export default withStyles(styles, { withTheme: true })(StepperHeader);

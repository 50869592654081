import React from "react";
import {Popper, TextField as OtherTextField} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getChipWithDelete} from "../apiplayground/SearchFilter";
import {theme} from "../../theme";

const CustomPopper = function (props) {
    return (<Popper {...props} style={{ width: 360 }} placement='bottom-start' />)
}

export default function SavedSpreadsheet({workspace, options, value, onChange, label, classes, style, disableClearable = true}) {
    let inputLabelProps = {};

    return <Autocomplete
        PopperComponent={CustomPopper}
        style={style}
        datatest={'autocompleteMultiValueSelect'}
        id="textSearchPropertiesValueSelect"
        value={value || null}
        options={options}
        getOptionLabel={option => option.label ?  option.label : ''}
        getOptionSelected={(option, value) => {
            return option.value === value.value;
        }}
        multiple={false}
        onChange={onChange}
        renderInput={params => (
            <OtherTextField
                label={label}
                {...params}
                variant="outlined"
                fullWidth
                InputLabelProps={inputLabelProps}
            />
        )}
        renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
                return getChipWithDelete(theme, index, option.label, option.tooltip, getTagProps({index}));
            })
        }}
        renderOption={(option, { selected }) => (
            <div datatest={option.label} style={{paddingLeft : '0px'}}>{option.label}</div>
        )}
        size={"small"}
        disableClearable={disableClearable}
    />;
}


import {useDragResize, usePluginContext} from "@graphiql/react";
import React, {useCallback} from "react";
import './GraphQLDocExplorer.css';

function GraphQLDocExplorer() {
    const pluginContext = usePluginContext();
    const PluginContent = pluginContext?.visiblePlugin?.content;

    const pluginResize = useDragResize({
        defaultSizeRelation: 1 / 3,
        direction: 'horizontal',
        initiallyHidden: pluginContext?.visiblePlugin ? undefined : 'first',
        onHiddenElementChange(resizableElement) {
            if (resizableElement === 'first') {
                pluginContext?.setVisiblePlugin(null);
            }
        },
        sizeThresholdSecond: 200,
        storageKey: 'docExplorerFlex',
    });

    const onClickReference = useCallback(() => {
        if (pluginResize.hiddenElement === 'first') {
            pluginResize.setHiddenElement(null);
        }
    }, [pluginResize]);


    return  <div style={{display : 'block'}} className={'graphiql-container'}>
        {PluginContent ? <PluginContent /> : null}
    </div>;
}

export default GraphQLDocExplorer;

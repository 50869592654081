import React from 'react';
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";

import H1Title from "./H1Title";
import HelpIcon from "./HelpIcon";
import {toArray} from "./util";

const styles = theme => ({
});

class MainHeaderBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
    }

    render() {
        const {title, helpText, actions} = this.props;

        return (<Grid container spacing={0}>
            <Grid item xs={6}>
                <H1Title title={title} color={'primary'}/>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
                {actions && actions.map((o, i) => <React.Fragment key={i}>{o}</React.Fragment>)}
                {toArray(helpText).length > 0 && <HelpIcon helpText={helpText}/>}
            </Grid>
        </Grid>);
    }
}

MainHeaderBar.propTypes = {
    title: PropTypes.string.isRequired,
    actions: PropTypes.array,
    helpText: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node)
    ])
};

export default withStyles(styles)(MainHeaderBar);

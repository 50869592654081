import React from 'react';
import PropTypes from "prop-types";
import {AppBar, Toolbar, Typography, withStyles} from "@material-ui/core";
import {styles} from "../components/styles";
import Divider from "@material-ui/core/Divider";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';

let SecondaryAppBar = props => {
  const { title, actions } = props;

  return (<div style={{overflow:"hidden"}}><AppBar position="sticky" color={'inherit'} elevation={0}>
          <Toolbar variant={"dense"} disableGutters={true} style={{paddingLeft: '16px', paddingRight: '36px'}}>
              <Typography variant="subtitle1" color="inherit" style={{fontWeight:'bold', flexGrow: 1}}>
                  {title}
              </Typography>
              {actions && actions.map((o, i) => <React.Fragment key={i}>{o}</React.Fragment>)}
              {<IconButton size={"small"} aria-label="help" color="inherit">
                      <HelpOutlineIcon />
              </IconButton>}
          </Toolbar>
      </AppBar>
          <Divider/>
      </div>);
}

SecondaryAppBar.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.array,
  helpLink: PropTypes.string
};

export default withStyles(styles)(SecondaryAppBar);

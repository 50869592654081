import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import './APIPlayground.css';
import RequestResponse from "../../components/RequestResponse";
import {traceRequestRenderStart} from "../../components/Trace";

class ResetRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
    }

    saveRequest = () => {
        let {resetRequestObject, onSave} = this.props
        onSave(resetRequestObject)
    }

    setDirty = () => {
        let {resetRequestObject} = this.props
        if(!resetRequestObject.dirty) {
            resetRequestObject.dirty = true
            this.setState({})
        }
    }

    getRequestResponse = () => {
        let {resetRequestObject, customizations, registerSendAction, onRequestObjectChange} = this.props;
        customizations.hideRequestTab = true;
        return <RequestResponse
            reqTabValue={0}
            requestObject={resetRequestObject}
            onRequestObjectChange={ (obj) => {this.setDirty(); onRequestObjectChange(obj)}}
            onRequestSave={this.saveRequest}
            disableApiMode={true}
            registerSendAction={registerSendAction}
            customizations={customizations}
        />;
    }

    render() {
        let {resetRequestObject} = this.props
        traceRequestRenderStart(resetRequestObject, 'ResetRequest')

        return (<>
                {this.getRequestResponse()}
        </>);
    }

}

ResetRequest.propTypes = {
    onSave: PropTypes.func,
    registerSendAction: PropTypes.func,
    exampleSetId: PropTypes.string,
    apiMode: PropTypes.object,
    aliasesMap: PropTypes.object,
    resetRequestObject: PropTypes.object,
    onRequestObjectChange: PropTypes.func,
    customizations: PropTypes.object,

};

export default withStyles(styles, {withTheme: true})(ResetRequest);

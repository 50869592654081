import React from "react";
import {InputLabel, makeStyles, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {sort} from "../../components/util";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export const LAYOUT_DAGREE = 'dagre';
export const LAYOUT_DAGREE_TB = LAYOUT_DAGREE+'_rb';
export const LAYOUT_DAGREE_LR = LAYOUT_DAGREE+'_lr';
export const LAYOUT_FCOSE = 'fcose';
export const LAYOUT_RANDOM = 'random';
export const LAYOUT_COSE_BILKENT = 'coseBilkent';
export const LAYOUT_CISE = 'cise';
export const LAYOUT_BREADTHFIRST = 'breadthfirst';
export const LAYOUT_TIDYTREE = 'tidytree';
const LAYOUT_OPTIONS = [
    {value : LAYOUT_DAGREE_TB, label: 'Tree Top Bottom'},
    {value : LAYOUT_DAGREE_LR, label: 'Tree Left Right'},
    {value : LAYOUT_RANDOM, label: 'Random'},
    {value : LAYOUT_FCOSE, label: 'Cluster'},
    {value : LAYOUT_BREADTHFIRST, label: 'Tree Breadth First'},
    {value : LAYOUT_TIDYTREE, label: 'Simple Tree'}
];


export default function GraphViewSettings({layout, onLayoutChange}) {
    const classes = useStyles();


    return <>
        <FormControl size={'small'} variant="outlined" className={classes.formControl}>
            <InputLabel htmlFor="outlined-layout">Layout</InputLabel>
            <Select
                style={{minWidth : '140px', maxWidth : '140px'}}
                datatest={'layout'}
                value={layout || LAYOUT_RANDOM}
                onChange={onLayoutChange}
                label="Layout"
                inputProps={{
                    name: 'layout',
                    id: 'outlined-layout',
                }}
            >
                {sort(LAYOUT_OPTIONS, 'label').map(lo => <MenuItem key={lo.value} value={lo.value}>{lo.label}</MenuItem>)}
            </Select>
        </FormControl>
    </>;

}


import React from "react";
import PropTypes from 'prop-types';

import {Typography} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import {styles} from "../components/styles";

function ErrorMessage(props) {
    let {error, style, theme, extraPadding, color} = props
    let paddingToAdd = extraPadding ? extraPadding : 0;
    let computedStyle = {
        color: color ? color : theme.palette.error.main,
        paddingLeft: theme.spacing(1) + paddingToAdd,
        paddingRight: theme.spacing(1) + paddingToAdd,
        ...style
    }
    return error
        ? <Typography component={'div'} variant={"caption"} style={computedStyle}>{error}</Typography>
        : <React.Fragment/>;
}

ErrorMessage.propTypes = {
    style: PropTypes.object,
    error: PropTypes.any,
    extraPadding: PropTypes.number,
    color: PropTypes.string
};

export default withStyles(styles, {withTheme:true})(ErrorMessage);

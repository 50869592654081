import {ENABLE_ALL_TRACES, ENABLE_TRACE} from "../Configs";
import {TYPE} from "../Constants";

export const TRACE_RENDER_METHOD_START = 'RENDER_METHOD_START'
export const TRACE_COMPONENT_DID_MOUNT_START = 'COMPONENT_DID_MOUNT_METHOD_START'
export const TRACE_COMPONENT_DID_MOUNT_END = 'COMPONENT_DID_MOUNT_METHOD_END'
export const TRACE_COMPONENT_DID_UPDATE_START = 'COMPONENT_DID_UPDATE_METHOD_START'
export const TRACE_COMPONENT_DID_UPDATE_END = 'COMPONENT_DID_UPDATE_METHOD_END'
export const TRACE_SEARCH_BUILDER_UPDATE = 'SEARCH_BUILDER_UPDATE'
export const TRACE_DEBUG = 'TRACE_DEBUG'

function checkAndTrace(traceName, messageFunction, source) {
    if(ENABLE_TRACE) {
        let message = messageFunction && messageFunction()
        trace.trace(traceName, message, source)
    }
}

export function traceRenderStart(messageFunction, source) {
    checkAndTrace(TRACE_RENDER_METHOD_START, messageFunction, source)
}

export function traceSearchBuilderUpdate(messageFunction, source) {
    checkAndTrace(TRACE_SEARCH_BUILDER_UPDATE, messageFunction, source)
}

export function traceRequestRenderStart(obj, source) {
    let messageFunction = () => { return obj && ` ${obj[TYPE]} ${obj.id} ${obj.title}`}
    traceRenderStart(messageFunction, source)
}

export function traceComponentDidMountStart(source) {
    checkAndTrace(TRACE_COMPONENT_DID_MOUNT_START, undefined, source)
}

export function traceComponentDidMountEnd(source) {
    checkAndTrace(TRACE_COMPONENT_DID_MOUNT_END, undefined, source)
}

export function traceComponentDidUpdateStart(source) {
    checkAndTrace(TRACE_COMPONENT_DID_UPDATE_START, undefined, source)
}

export function traceComponentDidUpdateEnd(source) {
    checkAndTrace(TRACE_COMPONENT_DID_UPDATE_END, undefined, source)
}

export function traceDebug(messageFunction, source) {
    checkAndTrace(TRACE_DEBUG, messageFunction, source)
}

class Trace {
    constructor() {
        try {
            let setup = ENABLE_TRACE ? localStorage.getItem("EGTraceSetup") : '{}'
            this.traceSetup = setup ? JSON.parse(setup) : {}
            this.time = new Date().getTime()
        } catch (e) {
            console.log("Failed to initialize trace"+e)
        }
    }

    trace(name, message, source) {
        if(ENABLE_ALL_TRACES || (ENABLE_TRACE && this.traceSetup[name] === true)) {
            let newTime = new Date().getTime()
            console.log("Trace name: " + name +" source : " + source+ " " +JSON.stringify(message)+ " time : "+newTime+" elapsed : "+(newTime - this.time)+"ms" )
            this.time = newTime
        }
    }
}

const trace = new Trace()


import React from 'react';
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
});

let H2Title = props => {
  const { title, classes, className, style, noWrap, color, ...rest } = props;

  return (<Typography {...rest} noWrap={noWrap === true ? true: false} variant="h2" color={color ? color : 'inherit'} style={style} className={`${classes.title+(className ? " "+ className : "")}`}>
                  {title? title : props.children}
              </Typography>);
}

H2Title.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object,
  noWrap: PropTypes.bool,
};

export default withStyles(styles)(H2Title);

import {ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET, ID, TYPE} from "../Constants";
import {isMockingSetupType} from "../components/util";
import {cloneDeepWith} from 'lodash';

export const QUOTA_ERROR = "QUOTA_ERROR";
export const DATASET_LABEL = 'datasetLabel';

export function customizer(value, key) {
    if (['component', 'onMock', 'dirty'].includes(key)) {
        return null;
    } else {
        return undefined;
    }
}

function store(key, value) {
    let cloned = cloneDeepWith(value, customizer);
    try {
        localStorage.setItem(key, JSON.stringify(cloned));
    } catch (e) {
        let isStorageError = e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED');
        if(isStorageError) {
            throw new Error(QUOTA_ERROR);
        }
    }
}

export function getCollections() {
    let collections = JSON.parse(localStorage.getItem('collections'))
    return collections ? collections : [];
}

export function getCollection(id) {
    let collections = getCollections()
    return collections.find(c => c[ID] === id);
}

export function storeCollections(collections) {
    store('collections', collections)
}

export function addCollection(collection) {
    storeCollections([...getCollections(), collection])
}

export function updateCollection(collection) {
    let all = getCollections()
    let remaining = all.filter(c => c[ID] !== collection[ID])
    storeCollections([...remaining, collection])
}

export function storeWorkspaceAndData(collection, data) {
    let key = collection[ID]
    store(key, data)
    updateCollection(collection)
}


export function getCollectionData(collection) {
    let key = collection[ID]
    let item = localStorage.getItem(key);
    return item ? JSON.parse(item) : [];
}

export function getCollectionScenariosData(collection) {
    return getCollectionData(collection).filter(d => d[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET);
}

export function storeCollectionScenariosData(collection, scenariosData) {
    let otherData = getCollectionMockingSetupData(collection)
    return storeWorkspaceAndData(collection, [...scenariosData, ...otherData]);
}

export function getCollectionMockingSetupData(collection) {
    return getCollectionData(collection).filter(d =>  isMockingSetupType(d));
}

export function addCollectionMockingSetup(collection, mockingSetup) {
    let remaining = getCollectionData(collection).filter(d => d[ID] !== mockingSetup[ID])
    storeWorkspaceAndData(collection, [...remaining, mockingSetup])
}

export function deleteCollection(collection) {
    let all = getCollections()
    let remaining = all.filter(c => c[ID] !== collection[ID])
    storeCollections(remaining)
    localStorage.removeItem(collection[ID])
}

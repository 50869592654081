import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import qs from "qs";
import {aliases, bootstrap, data, getAllConfigurations, ontology, ontologyBootstrap, shacl} from "../service/graph-api";
import {
    centerVertically,
    computeAllSubClasses,
    computeAllSuperClasses,
    computeAllTopLevelClasses,
    computeClassShape,
    createAliasesMap,
    createContainerPayload,
    getAliasMappingData,
    getApiConfigurationResource,
    getBaseIRI,
    getContentType,
    getIdGeneratingClassIRIs,
    getMaxLengthMessage,
    getOntologyClasses,
    getProp,
    getRequiredFieldMessage,
    getRouteWithInstanceAndDataset,
    getShapesData,
    getSortedInverseNodes,
    getSystemContextURL,
    isNonBlankClass,
    isReadOnlyAPI,
    isSystemDefinedAlias,
    isValidBaseIRILength,
    isValidPrefix,
    isValidPrefixLength,
    reshapeClassForTreeData,
    restrictMaximumCharacters,
    sort,
    validateBaseIRI
} from "./util";
import TextField from '../components/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {styles} from "./styles";
import history from "../history";
import {Button, Checkbox, FormGroup, FormLabel, Grid, Paper} from "@material-ui/core";
import JSONEditorReact from "./JSONEditorReact";
import queryString from "query-string";
import {prefixes} from "./prefixes";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Footer from "./Footer";
import {
    ALIAS_RDFS_LABEL,
    ALIAS_RDFS_SUBCLASS_OF,
    ALIAS_SH_SHAPE,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_ALIAS,
    ALIAS_SYS_ALIAS_MAPPING,
    ALIAS_SYS_BASE_IRI,
    ALIAS_SYS_BASE_IRI_PREFIX,
    ALIAS_SYS_BOOTSTRAP_COMPLETE,
    ALIAS_SYS_CLASS_IRI,
    ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI,
    ALIAS_SYS_DATA_DOMAIN_BASE_IRI,
    ALIAS_SYS_DEFAULT_LANGUAGE,
    ALIAS_SYS_ENABLE_DATA_RESET,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_IDENTIFIER,
    ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION,
    ALIAS_SYS_IRI,
    ALIAS_SYS_IS_SYSTEM_ALIAS,
    ALIAS_SYS_PREFIX,
    ALIAS_SYS_PREFIX_MAPPING,
    ALIAS_SYS_PROPERTY_TYPE,
    API_CONFIGURATION_RESOURCE_BASE_IRI,
    API_CONFIGURATION_RESOURCE_TYPE,
    APPLICATION_NAME,
    AT_CONTEXT,
    AT_GRAPH,
    AT_ID,
    AT_TYPE,
    AT_VALUE,
    CONFIGURE_ALIASES,
    CONFIGURE_CONTAINERS,
    CONFIGURE_PREFIXES,
    GRAPH,
    ID,
    INVERSE_PROPERTIES,
    LABEL_BASE_IRI_FOR_DATA,
    LABEL_BASE_IRI_FOR_SCHEMA,
    LABEL_DATA_RESET_TYPE_YES,
    LABEL_DEFAULT_LANGUAGE,
    LABEL_FROM_ONTOLOGY,
    LABEL_FROM_SYSTEM,
    LABEL_ID_SETUP_LOWEST_CLASSES,
    LABEL_ID_SETUP_READ_ONLY,
    LOAD_MODEL,
    LOAD_YOUR_ONTOLOGY,
    MAIN_CONFIGURATION,
    MEDIA_TYPE_JSON_LD,
    MEDIA_TYPE_NTRIPLES,
    MEDIA_TYPE_TEXT_TURTLE,
    MEDIA_TYPE_TRIG,
    MESSAGE_JSONLD,
    MESSAGE_SELECT_TO_VIEW_DETAILS,
    PREVIEW_SHAPES,
    ROUTE_CONFIGURATION_MANAGER_HOME,
    ROUTE_MANAGEMENT_HOME,
    STYLE_GRID_ITEM_SPACING,
    SYSTEM_DEFINED_PREFIXES,
    TYPE,
    TYPE_OWL_THING,
    VALIDATION_ALIAS_NAME_REGEX,
    VALIDATION_BASE_IRI_MAX_LENGTH,
    VALIDATION_LANGUAGE_NAME_LENGTH,
    VALIDATION_MESSAGE_INVALID_ALIAS_NAME,
    VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL,
    VALIDATION_MESSAGE_YES_OR_EMPTY,
    VALIDATION_PREFIX_MAX_LENGTH,
    VALUE,
} from "../Constants";
import AllPartsLayout from "../layouts/AllPartsLayout";
import SecondaryAppBar from "./SecondaryAppBar";
import Instruction from "./Instruction";
import {SelectSingle} from "./Select";
import {LANGUAGES} from "./LanguagesList";
import uuid4 from 'uuid/v4'
import MainHeaderBar from "./MainHeaderBar";
import GridContainer from "./GridContainer";
import H2Title from "./H2Title";
import MainHeaderBarLeft from "./MainHeaderBarLeft";
import TreeViewForIdSetup from "./TreeViewForIdSetup";
import ContainerDetails from "./ContainerDetails";
import FinishButton from "./FinishButton";
import ShapeDetails from "./ShapeDetails";
import ErrorMessage from "./ErrorMessage";
import Label from "./Label";
import BootstrapHelp from "./BootstrapHelp";
import StepperHeader from "./StepperHeader";
import CustomLabel from "./CustomLabel";
import ProcessingBackdrop from "../components/ProcessingBackdrop";
import BootstrapLoadModel, {getCustomModelLoadSelection, ONTOLOGY_CONTENT} from "./BootstrapLoadModel";
import MenuListComposition from "../components/MoreMenuIcon";
import TuneIcon from "@material-ui/icons/Tune";
import {isValidProtocol} from "../components/util";
import {filterOntology, getModel, getModelData} from "../service/model-api";
import FooterNextButton from "../components/FooterNextButton";
import FooterPreviousButton from "../components/FooterPreviousButton";
import BackendErrorDialog from "../components/BackendErrorDialog";
import {handleInverseMaterialisationChange, InverseView} from "../layouts/systemmanager/Inverse";

const YOUR_ORAGANIZATION_NAME_PLACEHOLDER = '[YOUR_ORG]';
const dataDomainBaseIRIPlaceholder = ``
const configurationDomainBaseIRIPlaceholder = ``

function initialState() {

    return {
        open: true,
        loading: true,
        dataDomainBaseIRI: dataDomainBaseIRIPlaceholder,
        configurationDomainBaseIRI: configurationDomainBaseIRIPlaceholder,
        defaultLanguage: LANGUAGES.find(o => o.value === 'en'),
        valid: false,
        dataReset: '',
        json: "{}",
        activeStep: 0,
        treeData: [],
        file: "",
        loaded: 0,
        idGeneratingClasses: [],
        ontology: [],
        expanded: false,
        ontologyTTL: '',
        inverseExclusion: [],
        allInverseProps: [],
        aliasesMap: {},
        invalidAliases: {},
        mainConfigs : {},
        invalidAliasesSuggestions : {},
        aliasesGenerationResponse : {},
        generateSHACLResponse : {},
        prefixes : {},
        showAliases : 'none',
        conflictResolution : 'default',
        currentTestOntology: 1,
        loadOntology : [],
        selectedFiles : [],
        existingConfiguration: undefined
    };
};

const contentTypeMap = {
    'trig' : MEDIA_TYPE_TRIG,
    'ttl' : MEDIA_TYPE_TEXT_TURTLE,
    'jsonld' : MEDIA_TYPE_JSON_LD,
    'json' : MEDIA_TYPE_JSON_LD,
}

const MERGE = 'Reload complete model and merge manual changes from existing configuration';
export const MERGE_PARTIAL_MODEL_RELOAD = 'Load partial model, keep existing model and merge into existing configuration';
const WIPE_EXISTING = 'Wipe existing configuration and bootstrap again';
export const MERGE_OPTIONS = [
    {value : 'merge', label : MERGE},
    {value : 'merge-classes-in-payload-leave-other', label : MERGE_PARTIAL_MODEL_RELOAD},
    {value : 'wipe', label : WIPE_EXISTING}
];

class BootstrapDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.propName)
    }

    componentDidMount() {
        const configurations = getAllConfigurations()
        configurations.then((response) => {
            const apiConfiguration = getApiConfigurationResource(response);
            const shapes = getShapesData(response);
            if(apiConfiguration && shapes.length > 0) {
                let languageFromExistingConfiguration = apiConfiguration[ALIAS_SYS_DEFAULT_LANGUAGE]
                let defaultLanguage = LANGUAGES.find(l => l.value === languageFromExistingConfiguration);
                defaultLanguage = !defaultLanguage && languageFromExistingConfiguration
                    ? {value: languageFromExistingConfiguration, label: languageFromExistingConfiguration}
                    : defaultLanguage;
                this.setState({
                    existingConfigurationDialog: true,
                    existingConfigurationMergeStrategy : undefined,
                    existingConfigurationMergeStrategyError : true,
                    existingConfiguration: response,
                    dataDomainBaseIRI: apiConfiguration[ALIAS_SYS_DATA_DOMAIN_BASE_IRI],
                    configurationDomainBaseIRI: apiConfiguration[ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI],
                    dataReset: apiConfiguration[ALIAS_SYS_ENABLE_DATA_RESET] === 'true' && 'YES',
                    defaultLanguage: defaultLanguage
                });
            }
            this.setState({loading: false})
        }).catch((e) => {
            this.setState({loading: false, apiErrorResponse: e, apiError: true});
        });
    }

    isMergingWithExistingConfiguration = () => {
        return [MERGE, MERGE_PARTIAL_MODEL_RELOAD].includes(this.state.existingConfigurationMergeStrategy?.label)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.isValid() && !this.state.valid) {
            this.setState({valid: true})
        } else if (this.state.valid && !this.isValid()) {
            this.setState({valid: false})
        }
        let params = queryString.parse(this.props.location.search)
        if(!prevState.focusNode || params.id !== prevState.focusNode.id
            || this.state.addSuperClassPropsInShape !== prevState.addSuperClassPropsInShape) {
            let focusNode = this.searchInTree(params.id, this.state.treeData)
            if (focusNode) {
                this.setState({focusNode: focusNode})
            }
        }
        //If all files has been loaded go to next step
        if(this.state.selectedFiles && this.state.selectedFiles.length > 0 && this.state.loaded === this.state.selectedFiles.length) {
            this.setState({loading: false, activeStep : this.state.activeStep + 1, selectedFiles:[], loaded:0 });
        }
    }

    searchInTree = (id, treeData) => {
        if(treeData) {
            let found = treeData.find(o => o.id === id && o.title);
            if (found) {
                return found;
            } else {
                let found = treeData.map(o => this.searchInTree(id, o.children)).find(o => o)
                if (found) {
                    return found
                }
            }
        }
        return null;

    }



    handleClickOpen = () => {
        this.setState({open: true});
    };

    handleClose = () => {
        this.setState({open: false});
    };


    processSHACLGeneration = () => {
        let {loadOntology, ontology, activeStep, mainConfigs, ontologyTTL, customModelData} = this.state;
        if(ontology.length === 0) {
            this.setState({loading: true, treeData: []});
            let customModelLoadSelection = getCustomModelLoadSelection(loadOntology);
            if(customModelLoadSelection) {
                let response = {
                    [ID] : mainConfigs[ID],
                    [GRAPH] : customModelData
                }
                this.handleSHACLGenerationResponse(response, ontologyTTL);
            } else {
                let contentType = contentTypeMap['ttl']
                this.submitOntologyToBackend(ontologyTTL, contentType)
            }
        } else {
            this.setState({
                loading: false,
                activeStep: activeStep + 1,
            })
        }
    }

    generateAliases = () => {
        let {loadOntology, selectedFiles} = this.state;
        let customModelSelection = getCustomModelLoadSelection(loadOntology);
        if(!customModelSelection && this.state.ontologyTTL === '') {
            this.setState({loading: true, treeData: [], showAliases: 'none', conflictResolution: 'default'})
                let promises = [];
                for (let selectedFile of selectedFiles) {
                    let filePromise = new Promise(resolve => {
                        let fileReader = new FileReader();
                        fileReader.onload = () => {
                            let fileContent = fileReader.result
                            let prefixes = this.extractPrefixes(fileContent);
                            resolve({name : selectedFile.name, fileContent : fileContent, file: selectedFile, prefixes: prefixes});
                        }
                        fileReader.readAsText(selectedFile)
                    });
                    promises.push(filePromise);
                }
                loadOntology.filter(o => o.value !== LOAD_YOUR_ONTOLOGY).forEach(o => {
                    promises.push(new Promise(resolve => {
                        let fileContent = ONTOLOGY_CONTENT[o.value];
                        let prefixes = this.extractPrefixes(fileContent);
                        resolve({name : o.value, fileContent : fileContent, prefixes: prefixes});
                    }))
                })
                Promise.all(promises).then(fileObjects => {
                    let validationPromises = []
                    let allPrefixes = {}
                    fileObjects.map(f => f.prefixes).forEach(p => {
                        Object.keys(p).forEach(k => allPrefixes[k] = p[k])
                    })
                    this.setState({prefixes: allPrefixes})
                    //
                    fileObjects.forEach(f => {
                        validationPromises.push(new Promise((resolve, reject) => {
                            //only validate uploaded files
                            if(f.file) {
                                let contentType = getContentType(f)
                                ontology(f.fileContent, contentType, MEDIA_TYPE_JSON_LD).then(r => this.handleAPIResponse(r , () => {
                                    f.validationResult = r
                                    resolve(f)
                                })).catch(er => {
                                    this.handleAPIError(er);
                                    reject(er);
                                })
                            } else {
                                resolve(f)
                            }
                        }));
                    })
                    Promise.all(validationPromises).then(validationResults => {
                        //check validation results for uploaded files only
                        let fileValidationResults = validationResults.filter(r => r.file);
                        let hasInvalidFile = fileValidationResults.find(r => r.validationResult.status === 400);
                        if(hasInvalidFile) {
                            this.setState({selectedFilesError: true, loading: false});
                            fileValidationResults.filter(r => r.validationResult.status === 400).forEach(r => {
                                let fileName = r.file.name
                                r.validationResult.json().then(j => {
                                    this.setState({[fileName + 'error'] : j})
                                });
                            })
                        } else {
                            let convertPromises = []
                            validationResults.forEach(f => {
                                convertPromises.push(new Promise((resolve, reject) => {
                                    // Convert all files to ntriples
                                    let contentType = getContentType(f);
                                    data(f.fileContent, contentType, MEDIA_TYPE_NTRIPLES).then(r => this.handleAPIResponse(r, () => {
                                        r.text().then(t => {
                                            f.conversionResult = t;
                                            resolve(f);
                                        })
                                    })).catch(er => {
                                        this.handleAPIError(er);
                                        reject(er);
                                    })
                                }));
                            })
                            Promise.all(convertPromises).then(convertResults => {
                                let allUploadedFilesTTL = convertResults.filter(f => f.file).map(r => r.conversionResult).join('\n');
                                let allPrefdefinedFilesTTL = convertResults.filter(f => !f.file).map(r => r.conversionResult).join('\n');
                                let allTTL = ([allUploadedFilesTTL, allPrefdefinedFilesTTL]).join('\n');
                                //only validate full if uploaded files content else just submit
                                if(allUploadedFilesTTL) {
                                    ontology(allUploadedFilesTTL, MEDIA_TYPE_TEXT_TURTLE, MEDIA_TYPE_JSON_LD, 'full')
                                        .then(r => {
                                            this.handleAPIResponse(r, () =>
                                            {
                                                r.json().then(j => {
                                                    let warnings = j[GRAPH]
                                                        ? j[GRAPH].map(e => e[ALIAS_RDFS_LABEL].en)
                                                        : []
                                                    if (r.status === 200) {
                                                        if (warnings && warnings.length > 0) {
                                                            this.setState({
                                                                filesSectionWarningsOpen: true,
                                                                filesSectionWarnings: warnings,
                                                                allOntologyTTL: allTTL,
                                                                loading: false
                                                            })
                                                        } else {
                                                            this.submitForAliasesGenerationToBackend(allTTL, MEDIA_TYPE_TEXT_TURTLE)
                                                        }
                                                    } else if (r.status === 400) {
                                                        this.setState({
                                                            selectedFilesError: true,
                                                            filesSectionErrors: warnings,
                                                            loading: false
                                                        })
                                                    }
                                                })
                                            })
                                        })
                                        .catch(er => {
                                            this.handleAPIError(er);
                                        })
                                } else {
                                    this.submitForAliasesGenerationToBackend(allTTL, MEDIA_TYPE_TEXT_TURTLE)
                                }
                            })
                        }
                    })
                });

        } else if (customModelSelection) {
            let {mainConfigs} = this.state;
            let id = mainConfigs[AT_ID];

            // Generate system aliases and combine from model
            aliases(' ', id, MEDIA_TYPE_TEXT_TURTLE).then((r) => {

                this.handleAPIResponse(r, () => {
                    r.json().then(response => {
                        let model = getModel(customModelSelection.id);
                        let prefix = model[ALIAS_SYS_BASE_IRI_PREFIX];
                        let baseIRI = model[ALIAS_SYS_BASE_IRI];
                        let modelData = getModelData(model);
                        let idMap = {};
                        modelData.forEach(md => {
                            delete md[ALIAS_SYS_PROPERTY_TYPE];
                            if(md[TYPE] === ALIAS_SH_SHAPE) {
                                let shapeClass = modelData.find(cl => cl[ID] === md[ALIAS_SH_TARGET_CLASS]);
                                idMap[md[ID]] = mainConfigs[ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI] + 'shape/' +shapeClass[ALIAS_SYS_ID_LABEL];
                                md[ALIAS_RDFS_LABEL] = {
                                    "en" : shapeClass[ALIAS_SYS_ID_LABEL]
                                };
                            } else {
                                idMap[md[ID]] = model[ALIAS_SYS_BASE_IRI] + md[ALIAS_SYS_ID_LABEL];
                                md[ALIAS_RDFS_LABEL] = md[ALIAS_SYS_ID_LABEL];
                            }
                            return md;
                        });
                        function replacer(key, value) {
                            if(idMap[value]) {
                                return idMap[value];
                            } else if (value === "") {
                                return undefined;
                            } else {
                                return value;
                            }
                        }
                        let updatedModelData = JSON.parse(JSON.stringify(modelData, replacer));

                        this.setState({customModelData: updatedModelData ,prefixes: {[prefix] : baseIRI}} , () => {
                            let ontologyData = filterOntology(updatedModelData);
                            ontologyData.forEach(mr => {
                                let alias = mr[ALIAS_SYS_ID_LABEL];
                                let iri = baseIRI + alias;
                                let find = response[ALIAS_SYS_ALIAS_MAPPING].find(a => a[ALIAS_SYS_IRI] === iri && a[ALIAS_SYS_IS_SYSTEM_ALIAS] === "true")
                                //If alias is not already defined in system then add
                                if(find === undefined) {
                                    let aliasObject = {
                                        [ID]: "_:" + uuid4(),
                                        [ALIAS_SYS_ALIAS]: alias,
                                        [ALIAS_SYS_IRI]: iri,
                                        [ALIAS_SYS_IS_SYSTEM_ALIAS]: "false"
                                    }
                                    response[ALIAS_SYS_ALIAS_MAPPING].push(aliasObject);
                                }
                            })
                            //also set ontology allOntologyJSONLD in state
                            let allOntologyJSONLD = {
                                [GRAPH] : ontologyData
                            }
                            this.setState({allOntologyJSONLD});
                            this.handleAliasesGenerationResponse(response, undefined);
                        })
                    })
                })
            }).catch(this.handleAPIError)


        } else {
            this.setState({
                loading: false,
                activeStep: this.state.activeStep + 1,
            })
        }

    }

    extractPrefixes = (fileContent) => {
        const regex = /.*@prefix\s*(.*):\s*<(.*)>\s*./g;

        let m;
        let prefixes = {}
        while ((m = regex.exec(fileContent)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            if (m[1] && m[2]) {
                prefixes[m[1]] = m[2]
            }
        }
        return prefixes;
    }

    isPredefinedOntology = () => {
        let {loadOntology} = this.state;
        let found = loadOntology.filter(o => o.value !== LOAD_YOUR_ONTOLOGY);
        return found  && found.length > 0 ? true : false;
    }

    submitOntologyToBackend = (allOntologyTTL, contentType) => {
        shacl(allOntologyTTL, contentType).then((response) => {
            this.handleAPIResponse(response, () => {
                response.json().then(json => this.handleSHACLGenerationResponse(json, allOntologyTTL));
            })
        }).catch(this.handleAPIError)
    }

    handleSHACLGenerationResponse = (response, allOntologyTTL) => {
        let nextStep = this.state.activeStep + 1
        const treeData = sort(getShapesData(response).map(o => {
            return this.createNodeForTree(o)
        }), 'title')
        let allOntologyData = getOntologyClasses(response)
        let inverseProps = getSortedInverseNodes(response, createAliasesMap(this.state.mainConfigs));

        this.setState({
            treeData: treeData,
            ontology: allOntologyData,
            loading: false,
            activeStep: nextStep,
            ontologyTTL: allOntologyTTL,
            generateSHACLResponse: response,
            allInverseProps: inverseProps,
            json: '{}'
        });
    }

    submitForAliasesGenerationToBackend = (allOntologyTTL, contentType) => {
        let id = this.state.mainConfigs[AT_ID]
        data(allOntologyTTL, MEDIA_TYPE_TEXT_TURTLE, MEDIA_TYPE_JSON_LD).then(r => {
            r.json().then(json => {
                this.setState({allOntologyJSONLD : json})
                aliases(allOntologyTTL, id, contentType).then((response) => {
                    this.handleAPIResponse(response, () =>{
                        response.json().then(aliasesJson => {
                            this.handleAliasesGenerationResponse(aliasesJson, allOntologyTTL);
                        })
                    });
                }).catch(er => {
                    this.handleAPIError(er);
                });
            })
        })
    }

    handleAliasesGenerationResponse = (response, allOntologyTTL) => {
        let nextStep = this.state.activeStep + 1;

        let aliasesMapData = getAliasMappingData(response);
        let reversePrefixesBaseIRIToPrefix = this.createReversePrefixes();
        let allBaseIRIs = new Set();
        aliasesMapData.map(a => a[ALIAS_SYS_IRI])
            .map(i => getBaseIRI(i)).forEach(b => allBaseIRIs.add(b));
        Object.keys(SYSTEM_DEFINED_PREFIXES).forEach(k => allBaseIRIs.add(SYSTEM_DEFINED_PREFIXES[k]));

        let prefixesMapping = [...allBaseIRIs]
            .map(i => {
                let prefix = reversePrefixesBaseIRIToPrefix[i]
                return {
                    [ALIAS_SYS_BASE_IRI] : i,
                    [ALIAS_SYS_PREFIX] : prefix ? prefix : '',
                    error : !prefix ? getRequiredFieldMessage('Prefix'): undefined,
                    isSystemDefinedPrefix : SYSTEM_DEFINED_PREFIXES[prefix]
                }
            });
        let sortedPrefixMapping = sort(prefixesMapping, ALIAS_SYS_PREFIX);

        this.setState({
            activeStep: nextStep,
            loading : false,
            aliasesGenerationResponse : response,
            ontologyTTL: allOntologyTTL,
            prefixesMapping: sortedPrefixMapping
        })
    }

    computeAliases = () => {
        //First try to use system generated Aliases
        let {aliasesGenerationResponse, prefixesMapping, activeStep} = this.state
        this.setState({activeStep: activeStep + 1})
        let aliasesMapData = getAliasMappingData(aliasesGenerationResponse)
        let aliasesMap = this.createIRIToAliasMap(aliasesMapData);
        let invalidAliases = this.computeInvalidAliases(aliasesMap)
        let invalidAliasesKeys = Object.keys(invalidAliases)
        if(invalidAliasesKeys.length <= 0) {
            this.setState({aliasesMap: aliasesMap, loading: false})
            return;
        }

        //Next try to use prefixes for only conflicting Aliases
        let invalidAliasesSuggestions = {}
        let baseIRIToPrefixMap = {}
        prefixesMapping.forEach(m => {
            baseIRIToPrefixMap[m[ALIAS_SYS_BASE_IRI]] = m[ALIAS_SYS_PREFIX]
        })
        invalidAliasesKeys.forEach(k => {
            let iris = invalidAliases[k ]
            iris.forEach(i => {
                if(isSystemDefinedAlias(i, aliasesGenerationResponse)) {
                    invalidAliasesSuggestions[i] = k
                } else {
                    let baseIRI = getBaseIRI(i)
                    if (baseIRIToPrefixMap[baseIRI] && i !== baseIRI) {
                        let prefix = baseIRIToPrefixMap[baseIRI]
                        invalidAliasesSuggestions[i] = this.createAlias(i, prefix, k, aliasesGenerationResponse);
                    }
                }
            })
        })
        Object.keys(invalidAliasesSuggestions).forEach(k => aliasesMap[k] = invalidAliasesSuggestions[k])
        let invalidAliasesAfterSomePrefixes = this.computeInvalidAliases(aliasesMap)
        if(Object.keys(invalidAliasesAfterSomePrefixes).length <= 0) {
            this.setState({aliasesMap: aliasesMap, loading: false})
            return;
        }

        //Next try to use prefixes for all Aliases
        aliasesMap = this.createIRIToAliasMap(aliasesMapData)
        Object.keys(aliasesMap).forEach(k => {
            aliasesMap[k] = this.createAlias(k, baseIRIToPrefixMap[getBaseIRI(k)], aliasesMap[k], aliasesGenerationResponse)
        })
        let invalidAliasesAfterAllPrefixes = this.computeInvalidAliases(aliasesMap)
        this.setState({aliasesMap: aliasesMap})
        if(Object.keys(invalidAliasesAfterAllPrefixes).length > 0) {
            this.setState({invalidAliases: invalidAliases, newInvalidAliases: invalidAliases})
        }
        this.setState({loading: false})
    }

    createIRIToAliasMap = (aliasesMapData) => {
        let aliasesMap = {}
        aliasesMapData.map(a => aliasesMap[a[ALIAS_SYS_IRI]] = a[ALIAS_SYS_ALIAS])
        return aliasesMap;
    }

    createAlias = (iri, prefix, alias, aliasesGenerationResponse) => {
        let {allOntologyJSONLD} = this.state
        if(isSystemDefinedAlias(iri, aliasesGenerationResponse)) {
            return alias;
        }
        let prefixToAdd = prefix
        if(TYPE_OWL_THING === iri || getOntologyClasses(allOntologyJSONLD).find(c => c.id === iri)) {
            prefixToAdd = prefix.charAt(0).toUpperCase() + prefix.slice(1)
        }
        return prefixToAdd + alias.charAt(0).toUpperCase() + alias.slice(1);
    }

    createReversePrefixes = () => {
        let reversePrefixesBaseIRIToPrefix = {}
        // predefined internal prefixes
        Object.keys(prefixes).forEach(k => reversePrefixesBaseIRIToPrefix[prefixes[k]] = k)
        // prefixes in file and system defined prefixes take precedence
        Object.keys(this.state.prefixes).forEach(k => reversePrefixesBaseIRIToPrefix[this.state.prefixes[k]] = k)
        Object.keys(SYSTEM_DEFINED_PREFIXES).forEach(k =>  reversePrefixesBaseIRIToPrefix[SYSTEM_DEFINED_PREFIXES[k]] = k)
        return reversePrefixesBaseIRIToPrefix;
    }

    submitAliasesToBackend = () => {
        this.setState({loading: true, ontology:[]})
        let aliasesObject = this.state.mainConfigs
        aliasesObject[ALIAS_SYS_ALIAS_MAPPING] = getAliasMappingData(this.state.aliasesGenerationResponse).map(a => {
            a[ALIAS_SYS_ALIAS] = this.state.aliasesMap[a[ALIAS_SYS_IRI]]
            return a;
        })

        bootstrap(aliasesObject).then((r) => {
            this.handleAPIResponse(r, () => {
                this.setState({mainConfigs: aliasesObject});
                this.processSHACLGeneration();
            });
        }).catch(this.handleAPIError);
    }

    addFile = (event) => {
        this.setState({file:event.target.files[0]})
    }

    handleFieldChange = (event) => {
        const {target: {name, value, checked}} = event
        if(name === 'idGeneratingClasses') {
            let idGeneratingClasses = this.state.idGeneratingClasses
            let idGeneratingClassIRIs = getIdGeneratingClassIRIs(idGeneratingClasses)
            if(checked && !idGeneratingClassIRIs.includes(value)) {
                let superClasses = this.computeAllSuperClasses(value)
                let subClasses = this.computeAllSubClasses(value)
                idGeneratingClasses.filter(v => superClasses.includes(v[ALIAS_SYS_CLASS_IRI]) || subClasses.includes(v[ALIAS_SYS_CLASS_IRI]))
                    .forEach(c => c[ALIAS_SYS_IDENTIFIER] = "false");
                let found = idGeneratingClasses.find(c => c[ALIAS_SYS_CLASS_IRI] === value)
                found[ALIAS_SYS_IDENTIFIER] = "true"
                this.setState({[name]: idGeneratingClasses});
            } else {
                let found = idGeneratingClasses.find(c => c[ALIAS_SYS_CLASS_IRI] === value)
                found[ALIAS_SYS_IDENTIFIER] = "false"
                this.setState({
                    [name]: idGeneratingClasses
                });
            }
        } else if(name === 'loadOntology') {
            this.setState({[name]: value , ontologyTTL: ''});
        } else {
            this.setState({
                [name]: value
            });
        }
    }

    handleInverseChange = (event) => {
        const {target: {value, checked}} = event
        let {inverseExclusion} = this.state;
        let newValues = handleInverseMaterialisationChange(inverseExclusion, value, checked);
        this.setState({inverseExclusion: newValues});
    }

    handleAliasChange = (event) => {
        const {target: {name, value}} = event
        this.setAlias(name, value)
    }

    setAlias = (name, value) => {
        let aliasesMapNew = this.state.aliasesMap
        aliasesMapNew[name] = value
        let newInvalidAliases = this.computeInvalidAliases(aliasesMapNew)
        this.setState({aliasesMap : aliasesMapNew, newInvalidAliases : newInvalidAliases})
    }

    computeInvalidAliases = (aliasesMap) => {
        let invalidAliases = {};
        let reservedKeywordsAliases = {};
        // create reverse map of all aliases
        Object.keys(aliasesMap).forEach(iri => {
            let alias = aliasesMap[iri];
            if([ID, VALUE, TYPE, GRAPH, AT_ID, AT_VALUE, AT_TYPE, AT_GRAPH, AT_CONTEXT].includes(alias)) {
                let iriInInvalidAliases = reservedKeywordsAliases[alias];
                if(iriInInvalidAliases) {
                    reservedKeywordsAliases[alias] = [...iriInInvalidAliases, iri];
                } else {
                    reservedKeywordsAliases[alias] = [iri];
                }
            } else {
                let iriInInvalidAliases = invalidAliases[alias];
                if (iriInInvalidAliases) {
                    invalidAliases[alias] = [...iriInInvalidAliases, iri];
                } else {
                    invalidAliases[alias] = [iri];
                }
            }
        })

        // create map of aliases where one key has more than one values
        let reducedInvalidAliases = {};
        Object.keys(invalidAliases).forEach(k => {
            if(invalidAliases[k].length > 1) {
                reducedInvalidAliases[k] = invalidAliases[k]
            }
        })

        // If any of the alias is in reserved JSON-LD keywords add that to invalid list
        Object.keys(reservedKeywordsAliases).forEach(k => {
            reducedInvalidAliases[k] = reservedKeywordsAliases[k]
        })

        return reducedInvalidAliases;
    }

    isValid = () => {
        let {activeStep} = this.state
        switch (activeStep) {
            case 0:
                const {dataDomainBaseIRIError, configurationDomainBaseIRIError, defaultLanguageError, dataResetError, dataReset, existingConfigurationDialog, existingConfigurationMergeStrategyError} = this.state
                if(dataDomainBaseIRIError || configurationDomainBaseIRIError || defaultLanguageError
                    || dataResetError || (dataReset && dataReset !== 'YES') || (existingConfigurationDialog && existingConfigurationMergeStrategyError)) {
                    return false;
                } else {
                    const {dataDomainBaseIRI, configurationDomainBaseIRI, defaultLanguage} = this.state
                    return dataDomainBaseIRI && !dataDomainBaseIRI.includes(YOUR_ORAGANIZATION_NAME_PLACEHOLDER)
                        && configurationDomainBaseIRI && !configurationDomainBaseIRI.includes(YOUR_ORAGANIZATION_NAME_PLACEHOLDER)
                        && defaultLanguage;
                }
            case 1:
                const {selectedFiles, selectedFilesError, loadOntology} = this.state
                let isLoadYourOntology = loadOntology.find(o => o.value === LOAD_YOUR_ONTOLOGY);
                if(isLoadYourOntology && selectedFiles.length < 1) {
                    return false;
                }
                let error = selectedFilesError && selectedFilesError === true ? true : false;
                return (selectedFiles && selectedFiles.length > 0 && !error) || this.isPredefinedOntology();
            case 2:
                const {prefixesMapping} = this.state
                if(this.isMergingWithExistingConfiguration()) {
                    return true;
                }
                return prefixesMapping.find(m => m.error) ? false: true;
            case 3:
                const {newInvalidAliases} = this.state
                return newInvalidAliases && Object.keys(newInvalidAliases).length > 0 ? false : true;
            default:
                return true
        }

    }

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };

    isStepContentValid = () => {
        return this.state.valid
    }

    getSteps = () => {
        if(this.showBasics()) {
            return [MAIN_CONFIGURATION, LOAD_MODEL, CONFIGURE_PREFIXES,CONFIGURE_ALIASES, PREVIEW_SHAPES, CONFIGURE_CONTAINERS, INVERSE_PROPERTIES];
        } else {
            return [MAIN_CONFIGURATION, 'Load Model For Merge']
        }
    }

    onDescriptionInTree = (parentNode) => {
        this.setState({
            focusNode: parentNode.node,
            json : parentNode.node && parentNode.node.backingObject ? JSON.stringify(parentNode.node.backingObject, null, 4) : "{}"
        })
    }

    createNodeForTree = (o) => {
        let item = {};
        item.id = o.id;
        item.title = getProp(o, ALIAS_RDFS_LABEL);
        item.titleProp = ALIAS_RDFS_LABEL;
        item.backingObject = o;
        item.children = []
        return item;
    }

    onJSONSave = () => {
        this.setState({saving:true});
        let eventToSend = {action : "saveJSON", id: this.props.focusNode.id, data: JSON.parse(this.state.json)};
        this.props.eventHandler(eventToSend).then(() => {
            this.setState({saving:false});
        });
    };

    removeIdGeneratingClass = (value) => {
        this.setState(() => ({ idGeneratingClasses: [...this.state.idGeneratingClasses.filter(v => v !== value)] }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    computeIdGeneratingClasses = () => {
        let {ontology, generateSHACLResponse} = this.state
        let allClasses = ontology.filter(o => isNonBlankClass(o)).map(o => o.id)
        let allSuperClasses = [].concat.apply([], ontology.map(o => o[ALIAS_RDFS_SUBCLASS_OF])).filter(o => o && typeof o === 'string')
        let lowestClasses = allClasses.filter(c => !allSuperClasses.includes(c))
        let containers = getShapesData(generateSHACLResponse).map(s => {
            let targetClass = s[ALIAS_SH_TARGET_CLASS]
            let label = this.state.aliasesMap[targetClass].toLowerCase()
            return createContainerPayload(label, targetClass, lowestClasses.includes(targetClass))
        })
        return containers
    }

    computeAllSuperClasses = (classIRI) => {
        let {ontology} = this.state
        return computeAllSuperClasses(classIRI, ontology)
    }

    computeAllSubClasses = (classIRI) => {
        let {ontology} = this.state
        return computeAllSubClasses(classIRI, ontology)
    }

    handleNext = () => {
        let {activeStep} = this.state
        this.setState({loading: true}, () => {
            if(activeStep === 0) {
                if(this.isMergingWithExistingConfiguration()) {
                    this.setState({
                        loading: false,
                        activeStep: this.state.activeStep + 1,
                    })
                } else {
                    let eventToSend = {
                        action: "saveJSON",
                        data: {
                            '@id': API_CONFIGURATION_RESOURCE_BASE_IRI + uuid4(),
                            '@type': API_CONFIGURATION_RESOURCE_TYPE,
                            [ALIAS_SYS_DEFAULT_LANGUAGE]: this.state.defaultLanguage.value,
                            [ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI]: this.state.configurationDomainBaseIRI,
                            [ALIAS_SYS_DATA_DOMAIN_BASE_IRI]: this.state.dataDomainBaseIRI
                        }
                    }
                    if (this.state.dataReset === 'YES') {
                        eventToSend.data[ALIAS_SYS_ENABLE_DATA_RESET] = 'true'
                    }

                    eventToSend.data[AT_CONTEXT] = getSystemContextURL()
                    bootstrap(eventToSend.data)
                        .then((res) => {
                            this.handleAPIResponse(res, () => {
                                this.setState({
                                    loading: false,
                                    activeStep: this.state.activeStep + 1,
                                    mainConfigs: eventToSend.data
                                })
                            })
                        }).catch(this.handleAPIError);
                }
            } else if(activeStep === 1) {
                if(this.isMergingWithExistingConfiguration()) {
                    let {selectedFiles, existingConfigurationMergeStrategy} = this.state;
                    const formData = new FormData();
                    if(selectedFiles && selectedFiles.length > 0) {
                        selectedFiles.forEach(sf => {
                            formData.append('files', sf);
                        })
                    }
                    formData.append('existingConfiguration', existingConfigurationMergeStrategy?.value);
                    ontologyBootstrap(formData).then((r) => {
                        if(r.status === 200) {
                            this.setState({activeStep: this.getSteps().length});
                        } else {
                            this.handleAPIError(r);
                        }
                        this.setState({loading: false});
                    })

                } else {
                    this.generateAliases()
                }
            } else if(activeStep === 2) {
                this.computeAliases()
            } else if(activeStep === 3) {
                this.submitAliasesToBackend()
            } else if (activeStep === 4) {
                this.setState({
                    loading: false,
                    activeStep: this.state.activeStep + 1,
                    idGeneratingClasses: this.computeIdGeneratingClasses()
                })
            } else if(activeStep === 6) {
                this.handleFinish();
            } else if(this.state.activeStep === this.getSteps().length - 1) {
                this.setState({loading: false, open:false})
                history.push(getRouteWithInstanceAndDataset(ROUTE_CONFIGURATION_MANAGER_HOME))
            } else {
                this.setState({loading: false, activeStep: this.state.activeStep + 1})
            }
        })
    };

    handleAPIResponse = (res, onNon5xxResponse) => {
        if(res.status >= 500) {
            try {
                res.json().then((j) => {
                    this.setState({loading: false, apiErrorResponse: j, apiError: true})
                })
            } catch (e) {
                this.setState({loading: false, response: res, apiError: true})
            }
        } else {
            onNon5xxResponse();
        }
    }

    handleAPIError = (e) => {
        this.setState({loading: false, apiErrorResponse: e, apiError: true});
    }

    handleFinish = () => {
        this.setState({loading: true})
        let payload = {}
        payload[AT_CONTEXT] = getSystemContextURL()
        payload[AT_GRAPH] = []
        this.state.generateSHACLResponse[GRAPH].forEach(o => payload[AT_GRAPH].push(o))
        this.state.idGeneratingClasses.forEach(c => {
            payload[AT_GRAPH].push(c)
        })
        let mainConfigs = this.state.mainConfigs
        delete mainConfigs[AT_CONTEXT]
        mainConfigs[ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION] = this.state.inverseExclusion
        mainConfigs[ALIAS_SYS_BOOTSTRAP_COMPLETE] = 'yes'
        mainConfigs[ALIAS_SYS_PREFIX_MAPPING] = this.state.prefixesMapping
        payload[AT_GRAPH].push(mainConfigs)
        bootstrap(payload)
            .then((res) => this.handleAPIResponse(res, () => this.setState({loading: false, activeStep: this.getSteps().length})))
            .catch(this.handleAPIError)

    }

    getStepLeftMainHeader = () => {
        const {activeStep} = this.state
        switch (activeStep) {
            case 0:
            case 1:
            case 2:
            case 3:
                return undefined;
            case 4:
                return <MainHeaderBarLeft title={this.getStepName()}/>;
            case 5:
                return (
                    <MainHeaderBarLeft
                        title={this.getStepName()}
                    />
                );
            case 6:
            case 7:
                return undefined;
            default:
                return undefined;

        }
    }

    getStepLeftContent = () => {
        const {location} = this.props
        const {treeData, activeStep, ontology, idGeneratingClasses, aliasesMap} = this.state
        switch (activeStep) {
            case 0:
            case 1:
            case 2:
            case 3:
                return undefined;
            case 4:
                return <>{
                        treeData &&
                        <TreeViewForIdSetup
                            key={'shapesTree'}
                            disableViewType={true}
                            bootstrap
                            data={treeData.map(d => ({id: d.id, title: d.title, tooltip: d.id}))}
                            treeData={treeData}
                            location={location}
                            viewType={'list'}
                            toggle={false}
                            onNodeClick={(node) => {
                                history.push(
                                    `${location.pathname}?${qs.stringify({ id: node.id })}`
                                );
                                this.setState({selectedShapeIRI: node.id});
                            }}
                        />
                    }</>;
            case 5:
                let sortedTree = sort(treeData.map(d => { return {
                    id: d.backingObject[ALIAS_SH_TARGET_CLASS],
                    tooltip : d.backingObject[ALIAS_SH_TARGET_CLASS],
                    title : aliasesMap[d.backingObject[ALIAS_SH_TARGET_CLASS]]
                }}), 'title')

                const superClasses = computeAllTopLevelClasses(ontology);

                const classShape = superClasses.map(classIRI => computeClassShape(classIRI, ontology));

                classShape.forEach(shapeNode => {
                    reshapeClassForTreeData(shapeNode, aliasesMap);
                });

                return (
                    <TreeViewForIdSetup
                        key={'idTree'}
                        bootstrap
                        data={sortedTree}
                        location={location}
                        onChange={this.handleFieldChange}
                        idGeneratingClasses={idGeneratingClasses}
                        onNodeClick={({ id }) => {
                            history.push(
                                `${location.pathname}?${qs.stringify({ id })}`
                            );
                            this.setState({selectedContainerIRI: id});
                        }}
                        renderNode={$props => <CustomLabel {...$props} />}
                        treeData={classShape}
                        viewType={'tree'}
                        menuOptions={
                            centerVertically(
                                <MenuListComposition
                                buttonIcon={<TuneIcon datatest={'idOptionsButton'} color={'primary'}/>}
                                items={[
                                    {
                                        label: LABEL_ID_SETUP_READ_ONLY,
                                        onClick: () => {
                                            let idGeneratingClasses = this.state.idGeneratingClasses.map(c => {
                                                c[ALIAS_SYS_IDENTIFIER] = "false";
                                                return c;
                                            })
                                            this.setState({idGeneratingClasses: idGeneratingClasses})
                                        }
                                    },
                                    {
                                        label: LABEL_ID_SETUP_LOWEST_CLASSES,
                                        onClick: () => this.setState({idGeneratingClasses: this.computeIdGeneratingClasses()})
                                    }
                                ]}
                                />,
                        {marginRight: '8px'}
                            )
                        }
                    />
                );
            case 6:
            case 7:
                return undefined;
            default:
                return undefined;
        }
    }

    getStepActionContent = () => {
        const {activeStep} = this.state
        switch (activeStep) {
            case 0:
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
                return undefined;
            default:
                return undefined;

        }
    }

    getStepContent = () => {
        const {activeStep, apiError, apiErrorResponse} = this.state;
        if(apiError) {
            return <BackendErrorDialog
                open={apiError}
                error={apiErrorResponse}
                handleClose={() => this.setState({apiError : false, apiErrorResponse: undefined})}
            />;
        }
        switch (activeStep) {
            case 0:
                return this.getMainConfiguration();
            case 1:
                return this.getLoadOntology();
            case 2:
                if(this.isMergingWithExistingConfiguration()) {
                    return this.getSuccessScreen()
                } else {
                    return this.getConfigurePrefixes()
                }
            case 3:
                return this.getConfigureAliases()
            case 4:
                return this.getPreviewShapes();
            case 5:
                return this.getConfigureContainers();
            case 6:
                return this.getInverseProperties();
            default:
                return this.getSuccessScreen();
        }
    }

    getSuccessScreen = () => {
        let {theme} = this.props
        return <GridContainer style={{textAlign: 'center'}}>
            <Grid item xs={12}>
                <div>
                    <CheckCircleIcon fontSize="large" style={{fontSize: '80px', color: theme.palette.success.main}}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <H2Title style={{color: theme.palette.success.main}} title={this.isMergingWithExistingConfiguration() ? 'Merge Successful' : 'Bootstrap Successful'}/>
            </Grid>
            <Grid item xs={12}>
            </Grid>
            <Grid item xs={12}>
                <Button id={'goToApplicationsButton'} variant={"contained"} color={"secondary"} size={"large"}
                        onClick={() => history.push(getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_HOME))}>Go to Applications</Button>
            </Grid>
        </GridContainer>;
    }

    getPreviewJSONLDContext = () => {
        let contextResponse = this.state.JSONLDContext
        let context = {
            [AT_CONTEXT] : {}
        }
        Object.keys(contextResponse[AT_CONTEXT]).sort().forEach(k => context[AT_CONTEXT][k] = contextResponse[AT_CONTEXT][k])
        let json = JSON.stringify(context, null, 4)
        return <Grid container spacing={1}>
            <Grid item xs={12}>
                <Instruction text={MESSAGE_JSONLD}/>
            </Grid>
            <Grid item xs={12}>
                <JSONEditorReact
                    modes={['code', 'text', 'view']}
                    text={json}
                    indentation={4}
                    history={false}
                    height={'600px'}
                    expandAll={true}
                    search={false}
                    onEditable={() => {
                        return false
                    }}
                />
            </Grid>
        </Grid>;
    }

    getInverseProperties = () => {
        const {theme} = this.props
        const {aliasesMap, allInverseProps, inverseExclusion, inverseSearchText} = this.state
        return <GridContainer>
            {this.getMainHeaderBar()}
            <Grid datatest={'showAliases'} item xs={12}>
                <InverseView
                    theme={theme}
                    treeData={allInverseProps}
                    aliasesMap={aliasesMap}
                    inverseExclusion={inverseExclusion}
                    searchText={inverseSearchText}
                    onSearchTextChange={(val) => this.setState({inverseSearchText: val})}
                    onChange={this.handleInverseChange}
                />
            </Grid>
        </GridContainer>;
    }

    getConfigureContainers = () => {
        const {aliasesMap, idGeneratingClasses, ontology, selectedContainerIRI} = this.state;
        return <ContainerDetails
            helpText={this.getHelpText()}
            classIRI={selectedContainerIRI}
            idGeneratingClasses={idGeneratingClasses}
            onChange={this.handleFieldChange}
            aliasesMap={aliasesMap}
            ontology={ontology}
        />;
    }

    getPreviewShapes = () => {
        const {aliasesMap, ontology, addSuperClassPropsInShape, treeData, selectedShapeIRI} = this.state;
        return <ShapeDetails
            helpText={this.getHelpText()}
            isReadOnly={true}
            aliasesMap={aliasesMap}
            ontology={ontology}
            addSuperClassPropsInShape={addSuperClassPropsInShape}
            allShapes={treeData}
            shapeIRI={selectedShapeIRI}
            backendEventHandler={this.backendEventHandler}
            onAddSuperClassPropsInShapeChange={(value) => this.setState({addSuperClassPropsInShape: value})}
        />
    }

    getSelectNodeMessage = () => {
        return <div style={{height: '100%', verticalAlign:"middle"}}><Instruction text={MESSAGE_SELECT_TO_VIEW_DETAILS}/></div>;
    }

    getConfigureAliases = () => {
        const {theme} = this.props
        const {aliasesMap, invalidAliases, aliasesGenerationResponse, invalidAliasesSuggestions} = this.state
        let content = <GridContainer>
            {this.getMainHeaderBar()}
            <Grid datatest={'showAliases'} item xs={12}>
                <FormControl component="fieldset">
                    <FormLabel component="legend"><Label label={'Show Aliases'}/></FormLabel>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox
                                         name={'showAliasesOntology'}
                                         checked={this.state.showAliasesOntology}
                                         onChange={(event) => {
                                                const {target: {checked}} = event
                                                this.setState({showAliasesOntology: checked })
                                         }}
                                         value="showAliasesOntology" />}
                                         label={LABEL_FROM_ONTOLOGY}
                                    />
                        <FormControlLabel
                            control={<Checkbox
                                        name={'showAliasesSystem'}
                                        checked={this.state.showAliasesSystem}
                                        onChange={(event) => {
                                            const {target: {checked}} = event
                                            this.setState({showAliasesSystem: checked })
                                        }}
                                        value="showAliasesSystem" />}
                            label={LABEL_FROM_SYSTEM}
                        />
                    </FormGroup>
                </FormControl>
            </Grid>
            <Grid item xs={12}>{
                (Object.keys(invalidAliases).length > 0)
                    && <Instruction color={"error"} text={"There are conflicts in the aliases. Please resolve conflicts by editing conflicting aliases manually."}/>
            }</Grid>
            {
                Object.keys(invalidAliases) &&
                Object.keys(invalidAliases).sort().map(k => <Grid item xs={12}>
                        <GridContainer style={{ borderRadius: '4px', backgroundColor: 'white', padding : theme.spacing(1)}}>{
                            invalidAliases[k].map(i => {
                                let value = aliasesMap[i]
                                let suggestion = invalidAliasesSuggestions[i] ? "Suggested : " + invalidAliasesSuggestions[i] : ''
                                if(!value || value.trim() === '' || !VALIDATION_ALIAS_NAME_REGEX.test(value)) {
                                    suggestion = VALIDATION_MESSAGE_INVALID_ALIAS_NAME + suggestion;
                                }
                                let systemDefinedAlias = isSystemDefinedAlias(i, aliasesGenerationResponse)
                                suggestion = systemDefinedAlias ? undefined : suggestion

                                return <Grid key={k} item xs={6}>
                                        <TextField
                                            label={i}
                                            error = {suggestion}
                                            id={i}
                                            name={i}
                                            onChange={this.handleAliasChange}
                                            value={value}
                                            helperText={suggestion}
                                            readOnly={systemDefinedAlias}
                                        />
                                </Grid>;
                            })
                        }</GridContainer>
                </Grid>)
            }
            <Grid item xs={12}>{
                <Grid container spacing={STYLE_GRID_ITEM_SPACING}>{
                    this.createAliasesList(aliasesMap, aliasesGenerationResponse).map(obj => {
                        let k = obj.iri;
                        return <Grid key={k} item sm={12} md={6}>
                                    <TextField id="iri" name="iri"
                                               value={"" + k}
                                               label={aliasesMap[k]}
                                               readOnly={true}/>
                            </Grid>;
                        }
                    )
                }</Grid>
            }</Grid>
        </GridContainer>

        return content;
    }

    getConfigurePrefixes =  () => {
        const {theme} = this.props
        const {prefixesMapping} = this.state
        return <GridContainer>
            {this.getMainHeaderBar()}
                {prefixesMapping.map(i => {
                    return <Grid  datatest={'prefix-'+i[ALIAS_SYS_BASE_IRI]} key={i[ALIAS_SYS_BASE_IRI]} item sm={12} md={6}>
                        <Paper elevation={0} style={{padding: theme.spacing(0), backgroundColor: theme.palette.border.main}}>
                            <Grid container spacing={0}>
                                <Grid item xs={3}>
                                    <TextField
                                        label={'Prefix'}
                                        value={i[ALIAS_SYS_PREFIX]}
                                        error={i.error}
                                        disableErrorRendering={true}
                                        readOnly={i.isSystemDefinedPrefix ? true : false}
                                        onChange={(event) => {
                                            const {target: {value}} = event
                                            i[ALIAS_SYS_PREFIX] = value.substring(0, VALIDATION_PREFIX_MAX_LENGTH + 1)
                                            this.setState({prefixesMapping: prefixesMapping}, () => {
                                                if (!value) {
                                                    i.error = getRequiredFieldMessage('Prefix')
                                                    this.setState({prefixesMapping: prefixesMapping})
                                                } else if (SYSTEM_DEFINED_PREFIXES[value]) {
                                                    i.error = `Prefix value '${value}' is used by ${APPLICATION_NAME}. Please use some other value.`
                                                    this.setState({prefixesMapping: prefixesMapping})
                                                } else if(!isValidPrefixLength(value)) {
                                                    i.error = getMaxLengthMessage(VALIDATION_PREFIX_MAX_LENGTH)
                                                    this.setState({prefixesMapping: prefixesMapping})
                                                } else if(!isValidPrefix(value)) {
                                                    i.error = 'Please use lower case letters or numbers only. The first character cannot be a number.'
                                                    this.setState({prefixesMapping: prefixesMapping})
                                                } else {
                                                    let map = {}
                                                    prefixesMapping.forEach(i => {
                                                        let baseIRIList = map[i[ALIAS_SYS_PREFIX]]
                                                        if (baseIRIList) {
                                                            map[i[ALIAS_SYS_PREFIX]] = [...baseIRIList, i[ALIAS_SYS_BASE_IRI]]
                                                        } else {
                                                            map[i[ALIAS_SYS_PREFIX]] = [i[ALIAS_SYS_BASE_IRI]]
                                                        }
                                                    })
                                                    prefixesMapping.forEach(i => {
                                                        let prefix = i[ALIAS_SYS_PREFIX]
                                                        let list = map[prefix]
                                                        if (!prefix) {
                                                            i.error = getRequiredFieldMessage('Prefix')
                                                        } else if (list && list.length > 1) {
                                                            i.error = `Prefix '${prefix}' is used for multiple base IRIs ${list.join(', ')}`
                                                        } else {
                                                            i.error = undefined
                                                        }
                                                    })
                                                    let invalidList = Object.keys(map).filter(k => map[k] && map[k].length > 1)
                                                    if (invalidList) {
                                                        this.setState({prefixesMapping: prefixesMapping})
                                                    }
                                                }
                                            })
                                        }
                                    }/>
                                </Grid>
                                <Grid item xs={9}>
                                    <TextField label={'Base IRI'} key={i} value={i[ALIAS_SYS_BASE_IRI]} readOnly={true}/>
                                </Grid>
                            </Grid>
                        </Paper>
                        {i.error && <div datatest={'error'} style={{marginLeft : '4px'}}>
                            <ErrorMessage error={i.error}/>
                        </div>}
                    </Grid>
                })}
        </GridContainer>
    }

    createAliasesList = () => {
        const {aliasesMap, aliasesGenerationResponse, showAliasesSystem, showAliasesOntology} = this.state
        if(!showAliasesSystem && !showAliasesOntology) {
            return [];
        }
        let keys = Object.keys(aliasesMap)
        let filteredKey = showAliasesOntology && showAliasesSystem
            ? keys
            : (showAliasesOntology
                ? keys.filter(k => !isSystemDefinedAlias(k, aliasesGenerationResponse))
                : keys.filter(k => isSystemDefinedAlias(k, aliasesGenerationResponse))
            )
        return sort(filteredKey.map(k => ({
            alias: aliasesMap[k],
            iri: k
        })), 'alias');
    }

    getLoadOntology = () => {
        return <BootstrapLoadModel
            isMerging={this.isMergingWithExistingConfiguration()}
            props={this.props}
            state={this.state}
            getMainHeaderBar={this.getMainHeaderBar}
            setState={(obj) => this.setState(obj)}
            onProceed={() => {
                let {allOntologyTTL} = this.state;
                this.setState(
                    {filesSectionWarningsOpen: false, loading: true},
                    () => this.submitForAliasesGenerationToBackend(allOntologyTTL, MEDIA_TYPE_TEXT_TURTLE)
                );
            }}
        />;
    }


    handleBaseIRIChange = (event) => {
        this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_BASE_IRI_MAX_LENGTH));
        const {target: {name, value}} = event
        let errorKey = name+'Error'
        let existingError = this.state[errorKey]
        if (!isValidBaseIRILength(value)) {
            this.setState({[errorKey]: getMaxLengthMessage(VALIDATION_BASE_IRI_MAX_LENGTH)})
        } else if (existingError === getMaxLengthMessage(VALIDATION_BASE_IRI_MAX_LENGTH)) {
            this.setState({[errorKey]: undefined})
        }

        if(isValidProtocol(value)) {
            if (existingError === VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL) {
                this.setState({[errorKey]: undefined})
            }
        } else {
            this.setState({[errorKey]: VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL})
        }

    }

    handleBaseIRIBlur = (label) => (event) => {
        this.setState({[event.target.name + 'Error'] : this.validateBaseIRI(event, label)})
    }


    validateBaseIRI = (event, label) => {
        const {target: {value}} = event;
        return validateBaseIRI(value, label);
    }

    showBasics = () => {
        let {existingConfigurationDialog, existingConfigurationMergeStrategy} = this.state
        return existingConfigurationDialog === undefined
            || [WIPE_EXISTING].includes(existingConfigurationMergeStrategy?.label);
    }

    getMainConfiguration = () => {
        let {existingConfigurationDialog, existingConfiguration, existingConfigurationMergeStrategy, dataDomainBaseIRI, dataDomainBaseIRIError, configurationDomainBaseIRI, configurationDomainBaseIRIError, dataResetError} = this.state
        let MERGE_LABEL = '';
        let content = <GridContainer>
            {this.getMainHeaderBar()}
            <Grid item xs={12}>{
                existingConfigurationDialog
                && <>
                    <Instruction color={"error"} text={"Warning! API is already configured. Please choose what you want to do with existing configuration."}/>
                    <Instruction color={"error"} text={"It is recommended that you download existing configuration from System Manager and save it just in case you need to revert back to current configuration."}/>
                    <SelectSingle value={existingConfigurationMergeStrategy}
                      options={MERGE_OPTIONS}
                      label={MERGE_LABEL}
                      placeholder={'Choose an option'}
                      required={true}
                      error={existingConfigurationMergeStrategy === undefined ? "Please select an option" : undefined}
                      isClearable={false}
                      maxLength={VALIDATION_LANGUAGE_NAME_LENGTH}
                      onChange={(val) => {
                          let existingConfigurationMergeStrategyError = !val ? getRequiredFieldMessage(MERGE_LABEL) : '';
                          this.setState({
                              existingConfigurationMergeStrategy: val,
                              existingConfigurationMergeStrategyError
                          })
                      }}/>

                    {
                        [WIPE_EXISTING].includes(existingConfigurationMergeStrategy?.label) &&
                            <>

                                <Instruction color={"error"} text={"This option will wipe all the existing configurations."}></Instruction>
                            </>
                    }
                    {
                        [MERGE].includes(existingConfigurationMergeStrategy?.label) &&
                            <>

                                <Instruction text={"This option allow you to keep all the existing shapes, adds new properties in the existing shapes, removes properties which are no longer in model, adds shapes for new classes in the model and remove shapes for classes which are not in new model. For example you might want to use this option if you have made some backward compatible changes in the ontology and you have single ontology file for entire model."}></Instruction>
                            </>
                    }
                    {
                        [MERGE_PARTIAL_MODEL_RELOAD].includes(existingConfigurationMergeStrategy?.label) &&
                        <>

                            <Instruction text={"This option allow you to keep all the existing shapes, adds new properties in the existing shapes and adds shapes for new classes in the model. For example you might want to use this option if you are extending model by adding new ontology classes and properties and you have a separate ontology file for it."}></Instruction>
                        </>
                    }

                </>
            }</Grid>
            {
                this.showBasics() &&
                <>
                    <Grid item xs={6}>
                        <TextField
                            id={'dataDomainBaseIRI'}
                            label={LABEL_BASE_IRI_FOR_DATA}
                            name='dataDomainBaseIRI'
                            onChange={this.handleBaseIRIChange}
                            onBlur={this.handleBaseIRIBlur(LABEL_BASE_IRI_FOR_DATA)}
                            value={dataDomainBaseIRI}
                            error={dataDomainBaseIRIError}
                            helperText={dataDomainBaseIRIError}
                            required={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='configurationDomainBaseIRI'
                            label={LABEL_BASE_IRI_FOR_SCHEMA}
                            name='configurationDomainBaseIRI'
                            onChange={this.handleBaseIRIChange}
                            onBlur={this.handleBaseIRIBlur(LABEL_BASE_IRI_FOR_SCHEMA)}
                            value={configurationDomainBaseIRI}
                            error={configurationDomainBaseIRIError}
                            helperText={configurationDomainBaseIRIError}
                            required={true}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        {this.getLanguageSelect()}
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            id='dataReset'
                            label={LABEL_DATA_RESET_TYPE_YES}
                            name='dataReset'
                            onChange={(event) => {
                                const {target: {value}} = event

                                if (value && value.length >= 3 && value !== 'YES') {
                                    this.setState({dataResetError: VALIDATION_MESSAGE_YES_OR_EMPTY});
                                    this.handleFieldChange(event);
                                } else {
                                    this.setState({dataResetError: ''});
                                    this.handleFieldChange(event);
                                }

                            }}
                            value={this.state.dataReset}
                            error={dataResetError}
                            helperText={dataResetError}
                            maxLength={3}
                            onBlur={() => {
                                let {dataReset} = this.state;
                                if (dataReset && dataReset !== 'YES') {
                                    this.setState({dataResetError: VALIDATION_MESSAGE_YES_OR_EMPTY});
                                }
                            }}
                        />
                    </Grid>
                </>
            }
        </GridContainer> ;

        return <>
            {content}
        </>;
    }

    getMainHeaderBar = () => {
        let helpText = this.getHelpText();
        return <Grid item xs={12} style={{paddingTop: 0}}>
            <MainHeaderBar title={this.getStepName()} helpText={helpText && [helpText]}></MainHeaderBar>
        </Grid>;
    }

    getHelpText = () => {
        const {activeStep, existingConfigurationDialog} = this.state
        return existingConfigurationDialog !== undefined && activeStep === 0 ? undefined : <BootstrapHelp step={activeStep}/>;
    }

    getLanguageSelect = () => {
        let {defaultLanguage, defaultLanguageError} = this.state
        let label = LABEL_DEFAULT_LANGUAGE
        return <SelectSingle value={defaultLanguage}
                             options={LANGUAGES}
                             label={label}
                             placeholder={'Search'}
                             required={true}
                             error={defaultLanguageError}
                             helperText={defaultLanguageError}
                             isClearable={false}
                             maxLength={VALIDATION_LANGUAGE_NAME_LENGTH}
                             onChange={(val) => {
                                 let defaultLanguageError = !val ? getRequiredFieldMessage(label) : '';
                                 this.setState({defaultLanguage: val, defaultLanguageError: defaultLanguageError})
                             }}/>

    }

    getContent = (title, content) => {
        return <div style={{width: "100%", overflow: 'hidden', paddingBottom: '64px'}}>
            {this.getAppBar(title)}
            <div style={{
                height: '100%',
                overflowY: "scroll",
                paddingRight: '8px',
                paddingLeft: '16px',
                paddingTop: '16px'
            }}>{content}</div>
        </div>;
    }

    getAppBar = (title) => {
        return <SecondaryAppBar title={title}></SecondaryAppBar>;
    }

    getMiddleComponent = () => {
        const {activeStep} = this.state
        return <div className={"main"} style={{padding:'0px'}}>
            <React.Fragment>{this.getStepContent(activeStep)}</React.Fragment>
        </div>;
    }

    getFooter = () => {
        const {activeStep, loading, existingConfigurationDialog} = this.state
        return <Footer>
            <div style={{flexGrow:1}}/>
            {
                activeStep !== 0 &&
                    <FooterPreviousButton
                        disabled={activeStep === 0 || loading}
                        onClick={this.handleBack}
                    />
            }
            {
                this.isLastStep() && this.isMergingWithExistingConfiguration() === false
                    ? <FinishButton onClick={this.handleFinish}/>
                    : <FooterNextButton
                        title={this.isMergingWithExistingConfiguration() && this.isLastStep() && 'Merge'}
                        onClick={this.handleNext}
                        disabled={!this.isStepContentValid(activeStep) || loading}
                    />
            }
        </Footer>;
    }

    isLastStep = () => {
        const steps = this.getSteps();
        const {activeStep, idGeneratingClasses, existingConfigurationDialog} = this.state;

        //if no id generating class then last step is not required
        if(activeStep === steps.length - 2 && isReadOnlyAPI(idGeneratingClasses) && existingConfigurationDialog === undefined) {
            return true;
        }
        return activeStep === steps.length - 1;
    }

    getHeader = () => {
        const {activeStep} = this.state
        return <StepperHeader
            title={'Bootstrap'}
            activeStep={activeStep}
            showStepper={!this.isBootstrapFinished()}
            steps={this.getSteps()}
        />;
    }

    getStepName = () => {
        const {activeStep} = this.state
        return this.getSteps()[activeStep];
    }

    isBootstrapFinished = () => {
        const {activeStep} = this.state
        return !(activeStep < this.getSteps().length);
    }

    render() {
        const {loading} = this.state;

        return <>
            <AllPartsLayout
                header={this.getHeader()}
                leftMainHeader={this.getStepLeftMainHeader()}
                leftComponent={this.getStepLeftContent()}
                leftComponentScroll={{ y: 'hidden', x: 'hidden' }}
                leftComponentStyle={{ paddingTop: 0 }}
                middleActions={this.getStepActionContent()}
                middleComponent={this.getStepContent()}
                footer={this.isBootstrapFinished() ? <React.Fragment/>:this.getFooter()}
                {...this.props}
            />
            {loading && <ProcessingBackdrop loading={loading}/>}
        </>;
    }

}

BootstrapDialog.propTypes = {
    eventHandler: PropTypes.func.isRequired,
    location : PropTypes.object.isRequired
}

export default withStyles(styles, {withTheme: true})(BootstrapDialog);

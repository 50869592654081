//Do not import any other file here as this is also used in cypress testss

import {ADMINISTRATION} from "../Constants";

export const BACKEND_PATH_ACCOUNT = "egaAccount";
export const BACKEND_PATH_ACCOUNT_LOGIN = BACKEND_PATH_ACCOUNT + "/login";
export const BACKEND_PATH_ACCOUNT_LOGIN_LINKEDIN_STATE = BACKEND_PATH_ACCOUNT_LOGIN + "/linkedIn/state";
export const BACKEND_PATH_ACCOUNT_INSTANCE = BACKEND_PATH_ACCOUNT + "/instance";
export const BACKEND_PATH_ACCOUNT_SIGNUP = BACKEND_PATH_ACCOUNT + "/signup";
export const BACKEND_PATH_ACCOUNT_SIGNUP_OTP = BACKEND_PATH_ACCOUNT_SIGNUP + "/otp";
export const BACKEND_PATH_ACCOUNT_SIGNUP_CONFIRM = BACKEND_PATH_ACCOUNT_SIGNUP + "/confirm";
export const BACKEND_PATH_ACCOUNT_FORGOTTEN_USERNAME = BACKEND_PATH_ACCOUNT + "/forgotten/username";
export const BACKEND_PATH_ACCOUNT_FORGOTTEN_USERNAME_LIST = BACKEND_PATH_ACCOUNT + "/forgotten/username/list";
export const BACKEND_PATH_ACCOUNT_FORGOTTEN_PASSWORD = BACKEND_PATH_ACCOUNT + "/forgotten/password";
export const BACKEND_PATH_ACCOUNT_FORGOTTEN_PASSWORD_CHANGE = BACKEND_PATH_ACCOUNT_FORGOTTEN_PASSWORD + "/change";
export const BACKEND_PATH_ACCOUNT_USER = BACKEND_PATH_ACCOUNT + "/user";
export const BACKEND_PATH_ACCOUNT_USER_SEARCH = BACKEND_PATH_ACCOUNT_USER + "/search";
export const BACKEND_PATH_ACCOUNT_INSTANCE_SEARCH = BACKEND_PATH_ACCOUNT + "/instance/search";
export const BACKEND_PATH_ACCOUNT_INSTANCE_CONFIGURATION = BACKEND_PATH_ACCOUNT + "/instance/configuration";
export const BACKEND_PATH_ACCOUNT_TRIAL_DOWNLOAD_URL = BACKEND_PATH_ACCOUNT + "/trial/downloads/url";
export const BACKEND_PATH_ACCOUNT_TRIAL_IN_AWS_URL = BACKEND_PATH_ACCOUNT + "/trial/aws/url";
export const BACKEND_PATH_ACCOUNT_GRAPH_SEARCH = BACKEND_PATH_ACCOUNT + "/graph/search";

export const BACKEND_PATH_MANAGEMENT = "egmManagement";
export const BACKEND_PATH_MANAGEMENT_LOGIN = BACKEND_PATH_MANAGEMENT + "/login";
export const BACKEND_PATH_MANAGEMENT_DATASET = BACKEND_PATH_MANAGEMENT + "/dataset";
export const BACKEND_PATH_MANAGEMENT_USER = BACKEND_PATH_MANAGEMENT + "/user";
export const BACKEND_PATH_MANAGEMENT_USER_PROFILE = BACKEND_PATH_MANAGEMENT_USER + "/profile";
export const BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH = BACKEND_PATH_MANAGEMENT + "/graph/search";
export const BACKEND_PATH_MANAGEMENT_GRAPH = BACKEND_PATH_MANAGEMENT + "/graph";
export const BACKEND_PATH_MANAGEMENT_DATASET_SEARCH = BACKEND_PATH_MANAGEMENT + "/dataset/search";
export const BACKEND_PATH_MANAGEMENT_INSTANCE_CONFIGURATION = BACKEND_PATH_MANAGEMENT + "/instance/configuration";
export const BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION = BACKEND_PATH_MANAGEMENT + "/app/configuration";
export const BACKEND_PATH_MANAGEMENT_PUBLIC_KEY = BACKEND_PATH_MANAGEMENT + "/publickey";
export const BACKEND_PATH_MANAGEMENT_PUBLIC_KEY_GENERATE = BACKEND_PATH_MANAGEMENT_PUBLIC_KEY + "/generate";
export const BACKEND_PATH_MANAGEMENT_OBJECT = BACKEND_PATH_MANAGEMENT + '/object'


export const BACKEND_PATH_OPS_MANAGEMENT = "opsManagement";
export const BACKEND_PATH_OPS_MANAGEMENT_METRICS = BACKEND_PATH_OPS_MANAGEMENT + "/metrics";
export const BACKEND_PATH_EG_CONTEXT = "egContext";

export const BACKEND_PATH_GRAPH = "graph";
export const BACKEND_PATH_GRAPH_SEARCH = "graph/search";

export const BACKEND_PATH_ADMINISTRATION = 'administration'
export const BACKEND_PATH_ADMINISTRATION_CONFIGURATION = BACKEND_PATH_ADMINISTRATION+'/configuration'

export const BACKEND_PATH_RESET_DATA = ADMINISTRATION + '/reset/data'
export const BACKEND_PATH_DATA_IMPORT = ADMINISTRATION + '/data/import'
export const BACKEND_PATH_CUSTOM_DATA_IMPORT = ADMINISTRATION + '/custom/data/import'
export const BACKEND_PATH_ONTOLOGY_BOOTSTRAP = ADMINISTRATION + '/ontology/bootstrap'
export const BACKEND_PATH_SPARQL = ADMINISTRATION + '/sparql'
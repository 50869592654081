import React from "react";
import {withStyles} from "@material-ui/core";
import H1Title from "../components/H1Title";

function LeftBarTitle({theme, title, onClick, style}) {
    return <div style={ style ? style : {padding: '24px 0px 16px 0px'}}>

            <H1Title noWrap={true} style={{
                maxWidth: 'calc(100% - 8px)',
                color: theme.palette.primary.main,
            }}><div style={{display: 'inline', cursor : 'pointer'}} onClick={() => {
                onClick && onClick();
                return false;
            }}>{title}</div></H1Title>

    </div>;
}

export default withStyles({}, {withTheme: true})(LeftBarTitle);

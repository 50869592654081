import React, {Component} from 'react';
import {MuiThemeProvider, withStyles} from "@material-ui/core/styles";
import {styles} from "./components/styles";
import {matchPath, Redirect, Route, Router, Switch} from "react-router-dom";
import history from "./history";
import {
    COOKIE_MANAGEMENT_TOKEN,
    ROUTE_ACCOUNT_DETAILS,
    ROUTE_ACCOUNT_FORGOTTEN_PASSWORD,
    ROUTE_ACCOUNT_FORGOTTEN_USERNAME,
    ROUTE_ACCOUNT_HOME,
    ROUTE_ACCOUNT_INSTANCES,
    ROUTE_ACCOUNT_LOGIN,
    ROUTE_ACCOUNT_MONITORING,
    ROUTE_ACCOUNT_SIGNUP,
    ROUTE_API_PLAYGROUND,
    ROUTE_API_PLAYGROUND_EXAMPLES,
    ROUTE_API_PLAYGROUND_MOCK_CONFIGS,
    ROUTE_APPS_EXPLORER,
    ROUTE_APPS_EXPLORER_SITE,
    ROUTE_BOOTSTRAP,
    ROUTE_CONFIGURATION_MANAGER_CONTAINER,
    ROUTE_CONFIGURATION_MANAGER_EXPORT,
    ROUTE_CONFIGURATION_MANAGER_HOME,
    ROUTE_CONFIGURATION_MANAGER_IMPORT,
    ROUTE_CONFIGURATION_MANAGER_INVERSE,
    ROUTE_CONFIGURATION_MANAGER_ONTOLOGY,
    ROUTE_CONFIGURATION_MANAGER_PREFIXES,
    ROUTE_CONFIGURATION_MANAGER_RULE,
    ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHENTICATION,
    ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHORISATION,
    ROUTE_CONFIGURATION_MANAGER_SECURITY_ROLES,
    ROUTE_CONFIGURATION_MANAGER_SHAPE,
    ROUTE_DATA_IMPORT_EXPORT,
    ROUTE_MANAGEMENT_ADMIN_LOGIN,
    ROUTE_MANAGEMENT_DATASET,
    ROUTE_MANAGEMENT_HOME,
    ROUTE_MANAGEMENT_LOGIN,
    ROUTE_MANAGEMENT_LOGOUT,
    ROUTE_MANAGEMENT_MONITORING,
    ROUTE_MANAGEMENT_USER,
    ROUTE_MANAGEMENT_USER_PROFILE,
    ROUTE_SCHEMA_BUILDER,
    ROUTE_SCHEMA_BUILDER_SCHEMA,
    ROUTE_SPARQL_EDITOR
} from "./Constants";
import {getBackendBasePath, getCookie, hasAnyDatasetLevelAuthenticationDisabled} from "./components/util";
import {theme} from "./theme";


import PropTypes from "prop-types";
import {isAuthenticationDisabled} from "./Configs";
import {LinearProgress} from "@material-ui/core";
import ManagementLogout from "./layouts/management/ManagementLogout";
import Prefixes from "./layouts/systemmanager/Prefixes";

function getInstanceParam() {
    let instanceParam = getBackendBasePath()+"/:instance?/:instanceLabel?";
    return instanceParam;
}

export function getInstanceAndDatasetParam() {
    let instanceAndDataset = getBackendBasePath()+"/:instance?/:instanceLabel?/:dataset?/:datasetLabel?";
    return instanceAndDataset;
}

const Account = React.lazy(() => import("./layouts/account/Account"))
const AccountHome = React.lazy(() => import("./layouts/account/AccountHome"))
const Monitoring = React.lazy(() => import("./layouts/common/Monitoring"))
const Instances = React.lazy(() => import("./layouts/account/Instances"))
const AdminHome = React.lazy(() => import("./layouts/management/AdminHome"))
const Home = React.lazy(() => import("./layouts/systemmanager/Home"))
const Container = React.lazy(() => import("./layouts/systemmanager/Container"))
const Ontology = React.lazy(() => import("./layouts/systemmanager/Ontology"))
const Shape = React.lazy(() => import("./layouts/systemmanager/Shape"))
const Inverse = React.lazy(() => import("./layouts/systemmanager/Inverse"))
const ExtensionRule = React.lazy(() => import("./layouts/systemmanager/ExtensionRule"))
const Users = React.lazy(() => import("./layouts/management/Users"))
const UserProfile = React.lazy(() => import("./layouts/management/UserProfile"))
const Datasets = React.lazy(() => import("./layouts/management/Datasets"))
const Collections = React.lazy(() => import("./layouts/apiplayground/Collections"))
const Examples = React.lazy(() => import("./layouts/apiplayground/Examples"))
const MockingConfiguration = React.lazy(() => import("./layouts/apiplayground/MockingConfiguration"))
const ImportExport = React.lazy(() => import("./layouts/dataimportexport/ImportExport"))
const Bootstrap = React.lazy(() => import("./layouts/bootstrap/Bootstrap"))
const SPARQLEditor = React.lazy(() => import("./layouts/sparqlconsole/SPARQLEditor"))
const Models = React.lazy(() => import("./layouts/modelbuilder/Models"))
const Model = React.lazy(() => import("./layouts/modelbuilder/Model"))
const Import = React.lazy(() => import("./layouts/systemmanager/Import"))
const Export = React.lazy(() => import("./layouts/systemmanager/Export"))
const Authentication = React.lazy(() => import("./layouts/systemmanager/Authentication"))
const Authorisation = React.lazy(() => import("./layouts/systemmanager/Authorisation"))
const Roles = React.lazy(() => import("./layouts/systemmanager/Roles"))
const ManagementLogin = React.lazy(() => import("./layouts/management/ManagementLogin"))
const Login = React.lazy(() => import("./layouts/account/Login"))
const SignUp = React.lazy(() => import("./layouts/account/SignUp"))
const ForgottenUsername = React.lazy(() => import("./layouts/account/ForgottenUsername"))
const ForgottenPassword = React.lazy(() => import("./layouts/account/ForgottenPassword"))

const Workspaces = React.lazy(() => import("./layouts/navigator/Workspaces"))
const WorkspaceThemeProvider = React.lazy(() => import("./layouts/navigator/WorkspaceThemeProvider"))

let AccountDetailsView = props => {
    return (
        <MuiThemeProvider theme={theme}><Account {...props}/></MuiThemeProvider>
    );
};

let AccountHomeView = props => {
    return (
        <MuiThemeProvider theme={theme}><AccountHome {...props}/></MuiThemeProvider>
    );
};

let AccountMonitoringView = props => {
    return (
        <MuiThemeProvider theme={theme}><Monitoring isAccountProfile={true} {...props}/></MuiThemeProvider>
    );
};

let InstancesView = props => {
    return (
        <MuiThemeProvider theme={theme}><Instances {...props}/></MuiThemeProvider>
    );
};

let AdminHomeView = props => {
    return (
        <MuiThemeProvider theme={theme}><AdminHome {...props}/></MuiThemeProvider>
    );
};

let AdminMonitoringView = props => {
    return (
        <MuiThemeProvider theme={theme}><Monitoring {...props}/></MuiThemeProvider>
    );
};

let HomeView = props => {
    return (
        <MuiThemeProvider theme={theme}><Home {...props}/></MuiThemeProvider>
    );
};

let ContainerView = props => {
    return (
        <MuiThemeProvider theme={theme}><Container {...props}/></MuiThemeProvider>
    );
};
let OntologyView = props => {
    return (
        <MuiThemeProvider theme={theme}><Ontology {...props}/></MuiThemeProvider>
    );
};

let ShapeView = props => {
    return (
        <MuiThemeProvider theme={theme}><Shape {...props}/></MuiThemeProvider>
    );
};

let InverseView = props => {
    return (
        <MuiThemeProvider theme={theme}><Inverse {...props}/></MuiThemeProvider>
    );
};

let ExtensionRuleView = props => {
    return (
        <MuiThemeProvider theme={theme}><ExtensionRule {...props}/></MuiThemeProvider>
    );
};

let AdminUsersView = props => {
    return (
        <MuiThemeProvider theme={theme}><Users {...props}/></MuiThemeProvider>
    );
};

let AdminUsersProfileView = props => {
    return (
        <MuiThemeProvider theme={theme}><UserProfile {...props}/></MuiThemeProvider>
    );
};

let AdminDatasetsView = props => {
    return (
        <MuiThemeProvider theme={theme}><Datasets {...props}/></MuiThemeProvider>
    );
};

let APIPlaygroundCollectionsView = props => {
    return (
        <MuiThemeProvider theme={theme}><Collections {...props}/></MuiThemeProvider>
    );
};

let APIPlaygroundExamplesView = props => {
    return (
        <MuiThemeProvider theme={theme}><Examples {...props}/></MuiThemeProvider>
    );
};

let APIPlaygroundMockingView = props => {
    return (
        <MuiThemeProvider theme={theme}><MockingConfiguration {...props}/></MuiThemeProvider>
    );
};

let DataImportExportView = props => {
    return (
        <MuiThemeProvider theme={theme}><ImportExport {...props}/></MuiThemeProvider>
    );
};

let BootstrapView = props => {
    return (
        <MuiThemeProvider theme={theme}><Bootstrap {...props}/></MuiThemeProvider>
    );
};

let SPARQLEditorView = props => {
    return (
        <MuiThemeProvider theme={theme}><SPARQLEditor {...props}/></MuiThemeProvider>
    );
};

let ModelsView = props => {
    return (
        <MuiThemeProvider theme={theme}><Models {...props}/></MuiThemeProvider>
    );
};

let ModelView = props => {
    return (
        <MuiThemeProvider theme={theme}><Model {...props}/></MuiThemeProvider>
    );
};

let ImportView = props => {
    return (
        <MuiThemeProvider theme={theme}><Import {...props}/></MuiThemeProvider>
    );
};

let ExportView = props => {
    return (
        <MuiThemeProvider theme={theme}><Export {...props}/></MuiThemeProvider>
    );
};

let PrefixesView = props => {
    return (
        <MuiThemeProvider theme={theme}><Prefixes {...props}/></MuiThemeProvider>
    );
};

let AuthenticationView = props => {
    return (
        <MuiThemeProvider theme={theme}><Authentication {...props}/></MuiThemeProvider>
    );
};

let AuthorisationView = props => {
    return (
        <MuiThemeProvider theme={theme}><Authorisation {...props}/></MuiThemeProvider>
    );
};

let RolesView = props => {
    return (
        <MuiThemeProvider theme={theme}><Roles {...props}/></MuiThemeProvider>
    );
};

let ManagementLoginView = props => {
    return (
        <MuiThemeProvider theme={theme}><ManagementLogin {...props}/></MuiThemeProvider>
    );
};

let ManagementLogoutView = props => {
    return (
        <MuiThemeProvider theme={theme}><ManagementLogout {...props}/></MuiThemeProvider>
    );
};

let AccountLoginView = props => {
    return (
        <MuiThemeProvider theme={theme}><Login {...props}/></MuiThemeProvider>
    );
};
let AccountSignUpView = props => {
    return (
        <MuiThemeProvider theme={theme}><SignUp {...props}/></MuiThemeProvider>
    );
};
let AccountForgottenUsernameView = props => {
    return (
        <MuiThemeProvider theme={theme}><ForgottenUsername {...props}/></MuiThemeProvider>
    );
};

let AccountForgottenPasswordView = props => {
    return (
        <MuiThemeProvider theme={theme}><ForgottenPassword {...props}/></MuiThemeProvider>
    );
};


let NavigatorWorkspacesView = props => {
    return (
        <MuiThemeProvider theme={theme}><Workspaces {...props}/></MuiThemeProvider>
    );
};

let NavigatorWorkspaceView = props => {
    return (
        <WorkspaceThemeProvider {...props}/>
    );
};

let LoadingView = props => {
    return (
        <MuiThemeProvider theme={theme}><LinearProgress/></MuiThemeProvider>
    );
};

function PrivateRoute ({component: Component, homePage, path, ...rest}) {
    const match = matchPath(homePage, {
        path: path,
        exact: true,
        strict: false
    })

    const match2 = matchPath(homePage, {
        path: getInstanceAndDatasetParam()+ROUTE_APPS_EXPLORER_SITE+"/:label?",
        exact: true,
        strict: false
    })

    //console.log(match2)

    const renderComponent = match !== null
        || isAuthenticationDisabled()
        || hasAnyDatasetLevelAuthenticationDisabled() !== undefined
        || getCookie(COOKIE_MANAGEMENT_TOKEN) !== null

    return (
        <Route
            path={path}
            {...rest}
            render={(props) => {
                let componentToRender = renderComponent === true
                    ? <Component {...props} />
                    : <Redirect to={{pathname: '/login', state: {from: props.location}}}/>;
                return componentToRender;
            }}
        />
    )
}

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }


    render() {
        let {defaultRedirectPath, homePage} = this.props;
        return <React.Suspense fallback={<LoadingView/>}>
            <Router history={history}>
                <Switch>
                    <Route path={getInstanceParam()+ROUTE_MANAGEMENT_LOGIN} component={ManagementLoginView}/>
                    <Route path={getInstanceParam()+ROUTE_MANAGEMENT_ADMIN_LOGIN} component={ManagementLoginView}/>
                    <Route path={getInstanceParam()+ROUTE_MANAGEMENT_LOGOUT} component={ManagementLogoutView}/>

                    <Route path={getBackendBasePath()+ROUTE_ACCOUNT_LOGIN} component={AccountLoginView}/>
                    <Route path={getBackendBasePath()+ROUTE_ACCOUNT_SIGNUP} component={AccountSignUpView}/>
                    <Route path={getBackendBasePath()+ROUTE_ACCOUNT_FORGOTTEN_USERNAME}
                           component={AccountForgottenUsernameView}/>
                    <Route path={getBackendBasePath()+ROUTE_ACCOUNT_FORGOTTEN_PASSWORD}
                           component={AccountForgottenPasswordView}/>
                    <PrivateRoute path={getBackendBasePath()+ROUTE_ACCOUNT_HOME} component={AccountHomeView}/>
                    <PrivateRoute path={getBackendBasePath()+ROUTE_ACCOUNT_INSTANCES} component={InstancesView}/>
                    <PrivateRoute path={getBackendBasePath()+ROUTE_ACCOUNT_DETAILS} component={AccountDetailsView}/>
                    <PrivateRoute path={getBackendBasePath()+ROUTE_ACCOUNT_MONITORING} component={AccountMonitoringView}/>

                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_MANAGEMENT_HOME} component={AdminHomeView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_MANAGEMENT_USER} component={AdminUsersView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_MANAGEMENT_USER_PROFILE} component={AdminUsersProfileView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_MANAGEMENT_DATASET} component={AdminDatasetsView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_MANAGEMENT_MONITORING} component={AdminMonitoringView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_HOME} component={HomeView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_CONTAINER} component={ContainerView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_CONTAINER + "/view"} component={ContainerView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_ONTOLOGY} component={OntologyView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_ONTOLOGY + "/view"} component={OntologyView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_SHAPE} component={ShapeView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_SHAPE + "/view"} component={ShapeView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_INVERSE} component={InverseView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_INVERSE + "/view"} component={InverseView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_RULE} component={ExtensionRuleView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_RULE + "/view"} component={ExtensionRuleView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_IMPORT} component={ImportView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_EXPORT} component={ExportView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_SECURITY_ROLES} component={RolesView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_PREFIXES} component={PrefixesView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHENTICATION} component={AuthenticationView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHORISATION} component={AuthorisationView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_CONFIGURATION_MANAGER_SECURITY_ROLES + "/view"} component={RolesView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_API_PLAYGROUND_EXAMPLES} component={APIPlaygroundExamplesView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_API_PLAYGROUND} component={APIPlaygroundCollectionsView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_API_PLAYGROUND_MOCK_CONFIGS} component={APIPlaygroundMockingView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_SPARQL_EDITOR} component={SPARQLEditorView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_DATA_IMPORT_EXPORT} component={DataImportExportView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_BOOTSTRAP} component={BootstrapView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_SCHEMA_BUILDER_SCHEMA} component={ModelView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_SCHEMA_BUILDER} component={ModelsView}/>
                    <PrivateRoute homePage={homePage} path={getInstanceAndDatasetParam()+ROUTE_APPS_EXPLORER_SITE} component={NavigatorWorkspaceView}/>
                    <PrivateRoute path={getInstanceAndDatasetParam()+ROUTE_APPS_EXPLORER} component={NavigatorWorkspacesView}/>
                    <Redirect from="/" to={defaultRedirectPath}/>
                </Switch>
            </Router>
        </React.Suspense>;
    }
}

App.propTypes = {
    defaultRedirectPath: PropTypes.string.isRequired,
    homePage: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(App);

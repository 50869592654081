const ACTIVITY_ONTOLOGY = {
    "graph": [
        {
            "id": "https://easygraph.graphifi.com/schema/eg/ApiActivity",
            "type": "OwlClass",
            "rdfsLabel": {
                "en": "EgApiActivity"
            }
        },
        {
            "id": "https://easygraph.graphifi.com/schema/eg/AsyncApiActivity",
            "type": "OwlClass",
            "rdfsLabel": {
                "en": "EgAsyncApiActivity"
            },
            "rdfsSubClassOf": [
                "https://easygraph.graphifi.com/schema/eg/EgApiActivity"
            ]
        }
    ]
}

const ACTIVITY_SHAPES = {
    "graph": [
        {
            "type": "ShShape",
            "rdfsLabel": {
                "en": "EgApiActivity"
            },
            "shClosed": "true",
            "shIgnoredProperties": [
                "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
                "https://easygraph.graphifi.com/schema/eg/uuid"
            ],
            "shTargetClass": "https://easygraph.graphifi.com/schema/eg/ApiActivity",
            "shProperty": [
                {
                    "id": "_:genid-07c1296cf4584b1b800f2b3b5a8b4efd-node1e4ugl6cox37",
                    "shDatatype": "http://www.w3.org/2001/XMLSchema#dateTime",
                    "shPath": "https://easygraph.graphifi.com/schema/eg/startedAtTime",
                },
                {
                    "id": "_:genid-07c1296cf4584b1b800f2b3b5a8b4efd-node1e4ugl6cox38",
                    "shNodeKind": "http://www.w3.org/ns/shacl#IRI",
                    "shPath": "https://easygraph.graphifi.com/schema/eg/generated",
                },
                {
                    "id": "_:genid-07c1296cf4584b1b800f2b3b5a8b4efd-node1e4ugl6cox39",
                    "shNodeKind": "http://www.w3.org/ns/shacl#IRI",
                    "shPath": "https://easygraph.graphifi.com/schema/eg/used",
                },
                {
                    "id": "_:genid-07c1296cf4584b1b800f2b3b5a8b4efd-node1e4ugl6cox40",
                    "shNodeKind": "http://www.w3.org/ns/shacl#IRI",
                    "shPath": "https://easygraph.graphifi.com/schema/eg/invalidated",
                }
            ]
        }
        ,
        {
            "type": "ShShape",
            "rdfsLabel": {
                "en": "EgAsyncApiActivity"
            },
            "shClosed": "true",
            "shIgnoredProperties": [
                "http://www.w3.org/1999/02/22-rdf-syntax-ns#type",
                "https://easygraph.graphifi.com/schema/eg/uuid"
            ],
            "shTargetClass": "https://easygraph.graphifi.com/schema/eg/AsyncApiActivity",
            "shProperty": [
                {
                    "id": "_:genid-07c1296cf4584b1b800f2b3b5a8b4efd-node1e4ugl6cox41",
                    "shClass": "https://easygraph.graphifi.com/schema/eg/ActivityStatus",
                    "shPath": "https://easygraph.graphifi.com/schema/eg/status",
                },
                {
                    "id": "_:genid-07c1296cf4584b1b800f2b3b5a8b4efd-node1e4ugl6cox42",
                    "shNodeKind": "http://www.w3.org/ns/shacl#IRI",
                    "shPath": "https://easygraph.graphifi.com/schema/eg/activityContext",
                }
            ]
        }
    ]
}


export function getSystemShapes() {
    return ACTIVITY_SHAPES;
}

export function getSystemOntology() {
    return ACTIVITY_ONTOLOGY;
}

import React, {Component} from "react";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import {traceRenderStart} from "../../components/Trace";
import {scrollToView} from "../../components/util";

const useTreeItemStyles = makeStyles(theme => ({
  root: {
    scrollBehavior: 'smooth',
    color: theme.palette.primary.main,
    "&:focus > $content": {
      backgroundColor: 'inherit',
    },
    marginTop : '4px'
  },
  content: {
    width: `calc(100% - ${theme.spacing(1)}px)`,
    color: theme.palette.primary.main,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      // fontWeight: theme.typography.fontWeightRegular
    },
    "&:hover": {
      backgroundColor: 'inherit',
    },
  },
  group: {
    marginLeft: theme.spacing(2),
    "& $content": {
      paddingLeft: 0
    }
  },
  expanded: {
    '> & ': {
      backgroundColor: 'inherit',
    }
  },
  label: {
  },
}));


const ListItem = function (props) {
  const classes = useTreeItemStyles();
  const {
    color,
    bgColor,
    renderNode,
    index,
    ...other
  } = props;

  const { onScrollEnd, scrollTo, theme, LabelIcon, labelInfo, renderActions, node, renderLabel, onNodeClick, labelText, isNodeFocused, ...rest } = other;

  return (
      <div
          id={node.id}
          style={{cursor: 'pointer', marginTop :'4px'}}
          className={classes.labelRoot}
      >{renderNode(props, index)}</div>
  );
};

ListItem.defaultProps = {}

ListItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  item: PropTypes.bool
};

const styles = {
  root: (props) => ({
    flexGrow: 1,
    overflowY: 'scroll',
    maxHeight: `calc(100vh - ${props.bootstrap ? 350 : 285}px)`,
      ...props.rootStyle
  })
};


const COMPONENT = "List"
class NavList extends Component {
  static propTypes = {
    classes: PropTypes.any,
    data: PropTypes.any.isRequired,
    renderLabel: PropTypes.func,
    toggleProps: PropTypes.any,
    expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    focusedNode: PropTypes.string,
    onNodeClick: PropTypes.func,
    renderNode: PropTypes.func,
    renderTreeItem: PropTypes.func,
    renderActions: PropTypes.func,
    bootstrap: PropTypes.bool,
    scrollTo: PropTypes.bool,
    onScrollEnd: PropTypes.func,
    rootStyle: PropTypes.object
  };

  static defaultProps = {
    scrollTo: false,
  };

  componentDidMount() {
    const { scrollTo } = this.props;
    if (scrollTo) {
      this.scrollToNode();
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollTo } = this.props;
    if (scrollTo) {
      this.scrollToNode();
    }
  }

  scrollToNode() {
    const { focusedNode, onScrollEnd } = this.props;
    const node = document.getElementById(focusedNode);
      if (node) {
        setTimeout(() => {
            scrollToView(node);
            if (onScrollEnd) {
                onScrollEnd(node);
            }
        }, 300)
      }
  }

  render() {
      traceRenderStart( "", COMPONENT)
    const { classes, focusedNode, data, rootStyle, ...rest } = this.props;
    return <div
        datatest={'navList'}
        className={classes.root}
        style={rootStyle}>{data.map((node, index) => {
          return <ListItem
              datatest={node.title}
              key={node.id}
              nodeId={node.id}
              id={node.id}
              labelText={node.title}
              node={node}
              {...rest}
              isNodeFocused={focusedNode === node.id}
              index = {index}
          ></ListItem>
    })}</div>;
  }
}

export default withStyles(styles, { withTheme: true })(NavList);

import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import {isEmptyArray, toArray} from "../../components/util";
import {
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_SYS_ETAG,
    ID,
    LANG,
    VALIDATION_MESSAGE_REQUIRED_FIELD,
    VALUE
} from "../../Constants";
import {TextField as MuiTextField, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import cloneDeep from 'lodash/cloneDeep';

class PageTitleDialog extends Component {
    constructor(props) {
        super(props);
        let valueObject = [];
        if(props.pageWorkspace) {
             valueObject = toArray(cloneDeep(props.pageWorkspace[ALIAS_MANAGEMENT_TITLE]));
        }
        let browseLanguages = props.settings.isMultilingual
            ? props.settings.browseLanguages
            : [props.settings.browseLanguages[0]]

        browseLanguages.forEach(bl => {
            let found = valueObject.find(vo => vo[LANG] === bl.value);
            if(found === undefined) {
                valueObject.push({
                    [LANG]: bl.value,
                    [VALUE]: ""
                });
            }
        })

        this.state = {
            valueObject : valueObject,
            errors : {}
        }
    }



    render() {
        let {settings, pageWorkspace} = this.props;
        let {loading, valueObject, errors} = this.state;
        return <>
            {
            <Dialog
                aria-labelledby="form-dialog-title"
                open={true}
                fullWidth={true}
                maxWidth={'xs'}
                datatest={'pageTitleDialog'}
            >
                <DialogTitle id="form-dialog-title"><H2Title title={pageWorkspace ? 'Edit Page Title' : 'Add Page' }/></DialogTitle>
                <DialogContent>
                    <Typography>Enter page title. This value is shown in the menu.</Typography>
                    {loading && <ProcessingBackdrop loading={loading}/>}
                    <div>
                        {
                            valueObject.map(val => {
                                let lang = val[LANG];
                                let value = val[VALUE];
                                let bl = settings.browseLanguages.find(bl => bl.value && lang && bl.value.toLowerCase() === lang.toLowerCase());
                                let labelToUse = settings.isMultilingual
                                    ? bl.label+" ("+lang+")"
                                    : 'Title';

                                return <MuiTextField
                                    datatest={'textField-'+lang}
                                    fullWidth={true}
                                    label={labelToUse}
                                    value={value}
                                    error={errors[lang]}
                                    helperText={errors[lang]}
                                    onChange={(event) => {
                                        const {target: {value}} = event;
                                        val[VALUE] = value;
                                        if(!value) {
                                            errors[lang] = 'Value required.'
                                        } else if (value.length > 20) {
                                            errors[lang] = 'Maximum 20 characters.'
                                        } else {
                                            delete errors[lang];
                                        }
                                        this.setState({})
                                    }}
                                />;
                            })
                        }
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        datatest={'cancelButton'}
                        onClick={this.props.onCancel}
                        variant={"outlined"}
                        color="secondary"
                    >Cancel</Button>
                    <Button
                        datatest={'addButton'}
                        variant={"contained"}
                        color="secondary"
                        disabled={!isEmptyArray(Object.keys(errors)) || valueObject.find(vo => !vo[VALUE]) }
                        onClick={() => {
                            this.props.onSave(valueObject);
                        }}
                    >Save</Button>
                </DialogActions>
            </Dialog>
            }
        </>;

    }

}

PageTitleDialog.propTypes = {
    pageWorkspace: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    location: PropTypes.any,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,

};

export default withStyles({}, {withTheme: true})(PageTitleDialog);

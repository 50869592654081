import React from "react";
import {InputAdornment, withStyles} from "@material-ui/core";
import {KeyboardTimePicker as OtherTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {EVENT_SHACL_VALIDATE, TIME_FORMAT_CONTROL} from "../../Constants";
import {
    dateFromMockTime,
    formatTime,
    getMockValueFromDefaultRegex,
    getPropertyValueForForm,
    registerMock
} from "../../components/util";
import DateFnsUtils from "@date-io/date-fns";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import {isArray} from "lodash";
import {deleteButton} from "../../components/ShapeToForm/ArrayType";
import {withDelete} from "../../components/ShapeToForm/Text";
import IconButton from "@material-ui/core/IconButton";
import ScheduleIcon from "@material-ui/icons/Schedule";
import {validateFromSHACL} from "./validation-util";
import ErrorMessage from "../ErrorMessage";

const StyledOtherTimePicker = withStyles((theme) => ({
    root: {
        "& .MuiIconButton-root" : {
            padding: '4px',
        }
    }
}))(OtherTimePicker);

export function TimePickerComponent({value, onChange, autoFocus, setRef, error}) {
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledOtherTimePicker
            datatest={'timePicker'}
            showTodayButton
            todayLabel="now"
            autoFocus={autoFocus}
            fullWidth={true}
            inputVariant="outlined"
            margin={"dense"}
            views={["hours", "minutes", "seconds"]}
            ampm={false}
            format={TIME_FORMAT_CONTROL}
            helperText={TIME_FORMAT_CONTROL}
            error={error}
            value={!value || value === '' ? null : value}
            onChange={onChange}
            onError={console.log}
            inputProps={{
                ref: setRef
            }}
            InputProps = {{
                style :{backgroundColor: 'white'},
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton size={"small"}>
                            <ScheduleIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    </MuiPickersUtilsProvider>;
}

class TimePicker extends React.Component {
    static contextType = MockingContext

    constructor(props) {
        super(props)
        let value = getPropertyValueForForm(props)

        this.state = (value && {value : dateFromMockTime(value)}) || {}
    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let mocking = this.context;
        let {property} = this.props;
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName);
        }

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? mocking.getMockValue(property)
            : getMockValueFromDefaultRegex(property);

        this.updatePropertyValue(dateFromMockTime(mockValue));
    }

    validate = (eventName) => {
        let {property, valueIndex, customizations} = this.props;
        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    updatePropertyValue = (date, rawValue) => {
        let {property, onChange, valueIndex} = this.props
        let formattedDate = formatTime(date) || rawValue
        if(isArray(property.value)) {
            property.value[valueIndex] = formattedDate;
        } else {
            property.value = formattedDate;
        }

        this.validate(EVENT_SHACL_VALIDATE);
        this.setState({value : date});
        onChange()
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {label, autoFocus,valueIndex, property, onChange, customizations} = this.props
        let {value, validationError} = this.state
        let component = <TimePickerComponent setRef={this.setRef} error={validationError} value={value} autoFocus={autoFocus} onChange={this.updatePropertyValue}/>;
        component = validationError ? <>{component}<div/><ErrorMessage error={validationError}/></> : component;
        return withDelete(
            component,
            customizations?.hideDelete || deleteButton(valueIndex, property, onChange),
            true
        );
    }
}

export default withStyles({}, {withTheme: true})(TimePicker);

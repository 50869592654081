import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {
    centerVertically,
    getDataContextURL,
    getDataValue,
    getPropertyName,
    getResourceId,
    getSearchResult,
    getShaclResult,
    isBadRequestError,
    isRequestSuccessful,
    toArray
} from "../../components/util";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import {getUiLabelTranslation, UI_LABELS_ADD_FEEDBACK} from "./UILabel";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {
    ALIAS_SH_RESULT_MESSAGE,
    ALIAS_SH_RESULT_PATH,
    AT_CONTEXT,
    AT_ID,
    AT_TYPE,
    AT_VALUE,
    EASYGRAPH_DATA_FEEDBACKSTATUS_NEW,
    GRAPH,
    ID, IRI_FEEDBACK_DATE_CREATED, IRI_FEEDBACK_PREDICATE, IRI_FEEDBACK_SUBJECT,
    IRI_TYPE_FEEDBACK,
    LANG,
    PAGE_SIZE,
    QUERY,
    SORT_BY,
    TYPE,
    TYPE_RDF_TYPE,
    VALUE,
    XSD_DATETIME,
    XSD_STRING
} from "../../Constants";
import {getBorderColor, StringValue} from "./Tree";
import {Badge, IconButton, TextField, Tooltip, Typography} from "@material-ui/core";
import FieldContainer from "../../components/FieldContainer";
import {getValuesObject} from "./SearchResultItem";
import {loadResource, primeFromSearchResult} from "../../service/data-loader";
import {ForumOutlined, SendRounded, UnfoldMoreOutlined} from "@material-ui/icons";
import {graphSearchByMaps, postGraph} from "../../service/graph-api";
import ErrorMessage from "../../components/ErrorMessage";
import CloseIcon from "@material-ui/icons/Close";
import {VALUE_NODE_WIDTH_PX} from "./NodeContainer";
import {renderNodeValueMarkup, renderNodeValueMarkupWithoutIRI} from "./WithObjectSummary";
import {getUserIri, getUsername} from "../common/Profile";
import {renderBrowseLanguageSelect} from "./Workspace";
import FeedbackComment from "./FeedbackComment";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import {getFeedbackBackgroundColor, getFeedbackBorderColor, isLinkedResourceViewTypeWithoutIRI} from "./BasicSetup";


export async function searchFeedback(subject, predicate, aliasesMap) {
    const dateCreatedAlias = aliasesMap[IRI_FEEDBACK_DATE_CREATED] || IRI_FEEDBACK_DATE_CREATED;
    const subjectAlias = aliasesMap[IRI_FEEDBACK_SUBJECT];
    const predicateAlias = aliasesMap[IRI_FEEDBACK_PREDICATE];
    const subjectFilter =  subject ? `${subjectAlias}(eq:["${subject}"]) ` : '';
    const predicateFilter =  predicate ? `${predicateAlias}(eq:["${predicate}"]) ` : '';
    const query = `{type(eq:["${IRI_TYPE_FEEDBACK}"])  ${subjectFilter} ${predicateFilter} }`;
    const sortBy = `{ ${dateCreatedAlias} (order:"ascending") }`;
    let response = await graphSearchByMaps({[QUERY] : query, [SORT_BY]: sortBy, [PAGE_SIZE] : '1000'}, {}, false);
    if (response.status !== 200) {
        return Promise.reject(response);
    }
    let json = await response.json();
    primeFromSearchResult(json);
    let searchResult = getSearchResult(json);
    return searchResult;

}

class AddFeedbackDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            feedbackText :  ''
        }
    }

    componentDidMount() {
        let {context, settings, aliasesMap, aliasesToIRIMap, ontology, browseLanguage} = this.props;
        getValuesObject(context.resource, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
            this.setState({formDataValueObject : vo})
        });
        if(context?.[TYPE] === AT_ID) {
            const resourceId = context?.dataValue;
            loadResource(resourceId).then(resources => {
                let r = toArray(resources).find(r => getResourceId(r) === resourceId);
                if(r) {
                    getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
                        this.setState({objectValueResourceVO: vo})
                    });
                }
            })
        }
        searchFeedback(this.getFeedbackSubject(), this.getFeedbackPredicate(), aliasesMap).then(r => {
            this.setState({feedbackResult : r});
        })
    }

    submitFeedback = () => {
        const {feedbackValue, feedbackValueLang} = this.state;
        const {context, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let objectValue = this.getFeedbackObject(context);
        let payload = {
            [AT_CONTEXT] : [getDataContextURL()],
            [GRAPH] : [{
                [AT_TYPE]: [ IRI_TYPE_FEEDBACK],
                [IRI_FEEDBACK_SUBJECT]: {
                    [AT_ID]: this.getFeedbackSubject()
                },
                [IRI_FEEDBACK_PREDICATE]: {
                    [AT_ID]: this.getFeedbackPredicate()
                },
                "https://easygraph.graphifi.com/schema/feedback/createdBy": {
                    [AT_ID]: getUserIri()
                },
                "https://easygraph.graphifi.com/schema/feedback/status": {
                    [AT_ID]: EASYGRAPH_DATA_FEEDBACKSTATUS_NEW
                },
                "https://easygraph.graphifi.com/schema/feedback/displayName": {
                    [AT_TYPE]: XSD_STRING,
                    [AT_VALUE]: getUsername()
                },
                "https://easygraph.graphifi.com/schema/feedback/dateCreated": {
                    [AT_TYPE]: XSD_DATETIME,
                    [AT_VALUE]: new Date().toISOString()
                },
                "https://easygraph.graphifi.com/schema/feedback/comment": {
                    ["@language"] : feedbackValueLang || 'en',
                    [AT_VALUE] : feedbackValue
                },
            }]
        }
        if(objectValue) {
            payload[GRAPH][0]["https://easygraph.graphifi.com/schema/feedback/object"] = objectValue;
        }
        this.setState({loading : true});
        let payloadString = JSON.stringify(payload);
        return postGraph(payloadString).then(async (response) => {
            if(isRequestSuccessful(response)) {
                this.setState({feedbackValue : '', feedbackSuccess : true, loading: false, apiErrorResponse: undefined, apiError: false, errorMessageProvider : undefined});
                this.componentDidMount();
                this.setState({showComments : true})
            }  else if(isBadRequestError(response)) {
                let json = await response.json();
                this.setState({loading: false, errorMessageProvider : () => {
                        let shaclResult = getShaclResult(json);
                        let errors =  toArray(shaclResult).map(e => {
                            let propertyName = getPropertyName(aliasesToIRIMap, e[ALIAS_SH_RESULT_PATH], ontology, browseLanguage);
                            return <div>
                                <ErrorMessage
                                    error={`'${propertyName}' : ${e[ALIAS_SH_RESULT_MESSAGE]?.en || e[ALIAS_SH_RESULT_MESSAGE]}`}/>
                            </div>;
                        });
                        return errors;
                    }});
            } else {
                this.setState({loading: false, apiErrorResponse: response, apiError: true});
            }
        });

    }

    getFeedbackSubject = () => {
        const {context} = this.props;
        return getResourceId(context.resource);
    }

    getFeedbackPredicate = () => {
        const {context, aliasesToIRIMap} = this.props;
        if([TYPE, AT_TYPE].includes(context.propertyKey)) {
            return TYPE_RDF_TYPE;
        }
        return aliasesToIRIMap[context.propertyKey] || context.propertyKey;
    }

    getFeedbackObject = () => {
        const {context} = this.props;
        let objectValue = {};
        if (context[TYPE] === AT_ID) {
            objectValue[AT_ID] = getDataValue(context.dataValue);
        } else {
            if(context.dataValue) {
                if (context.dataValue[LANG]) {
                    objectValue["@language"] = context.dataValue[LANG];
                }
                if (context.dataValue[TYPE]) {
                    objectValue[AT_TYPE] = context.dataValue[TYPE];

                }
                objectValue[AT_VALUE] = getDataValue(context.dataValue);
            } else {
                return undefined;
            }
        }
        return objectValue;
    }

    renderData = () => {
        let {context, theme, settings, shapeProperty} = this.props;
        let {dataValue, propertyKey} = context;
        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.white.main
        }
        let ovo = this.state.objectValueResourceVO;

        return <div style={{paddingLeft: '32px'}} datatest={'container-'+propertyKey}>
            <div style={{borderLeft: '2px solid', borderColor: borderConnectionColor, display: 'flex'}}>
                {
                    centerVertically(
                        <div
                            style={{width: '16px', border: '1px solid', borderColor: borderConnectionColor}}
                        />,
                        {paddingTop: '6px'}
                    )
                }
                <div style={{paddingTop: '8px', minWidth: VALUE_NODE_WIDTH_PX, maxWidth : VALUE_NODE_WIDTH_PX, paddingRight: '16px'}}>
                    <div
                        datatest={'focusContainer-'+(propertyKey || '')}
                        style={computedStyle}
                    >
                        {   ovo ?
                            (isLinkedResourceViewTypeWithoutIRI(settings)
                                    ? renderNodeValueMarkupWithoutIRI(ovo.thumbnail, ovo.title, theme, undefined, getResourceId(ovo),undefined, ovo, false)
                                    : renderNodeValueMarkup(ovo.thumbnail, ovo.title, theme, undefined, getResourceId(ovo),undefined, ovo, false))
                            : <StringValue settings={settings} theme={theme} value={getDataValue(dataValue)}
                                         langCode={dataValue[LANG]} datatype={dataValue[TYPE]}
                                         shapeProperty={shapeProperty} isImageType={false}/>
                        }
                    </div>
                </div>
            </div>

        </div>;

    }

    renderProperty = () => {
        let {context, theme, aliasesToIRIMap, ontology, browseLanguage} = this.props;

        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.border.main
        }
        let propertyKey  = context.propertyKey;
        let propertyTitle = getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);


        return <div style={{paddingLeft: '16px'}} datatest={'container-'+propertyKey}>
            <div style={{borderLeft: '2px solid', borderColor: borderConnectionColor, display: 'flex'}}>
                {
                    centerVertically(
                        <div
                            style={{width: '16px', border: '1px solid', borderColor: borderConnectionColor}}
                        />,
                        {paddingTop: '6px'}
                    )
                }
                <div style={{paddingTop: '8px', minWidth: VALUE_NODE_WIDTH_PX, maxWidth : VALUE_NODE_WIDTH_PX, paddingRight: '16px'}}>
                    <div
                        datatest={'focusContainer-'+(propertyKey || '')}
                        style={computedStyle}
                    >
                        {
                            centerVertically(
                                <div datatest={'property-' + propertyKey}>
                                    {
                                        propertyKey &&
                                        // DO not use H4Title as it cause issue with tooltip
                                        <Tooltip arrow={true} placement={'bottom-start'}
                                                 title={aliasesToIRIMap[propertyKey] || propertyKey}>
                                            <Typography noWrap={true} component={'span'} variant="h4"
                                                        color={'primary'}>{propertyTitle}</Typography>
                                        </Tooltip>
                                    }
                                </div>, {flexGrow: '1'}
                            )
                        }

                    </div>
                </div>
            </div>
            {
                context.dataValue && this.renderData()
            }

        </div>;

    }

    renderSubject = () => {
        let {context, theme, settings} = this.props;
        let {propertyKey} = context;
        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.white.main
        }
        let ovo = this.state.formDataValueObject;

        return <div style={{paddingLeft: '16px'}} datatest={'container-'+propertyKey}>
            <div style={{display: 'flex'}}>

                <div style={{paddingTop: '8px', minWidth: VALUE_NODE_WIDTH_PX, maxWidth : VALUE_NODE_WIDTH_PX, paddingRight: '16px'}}>
                    <div
                        datatest={'focusContainer-'+(propertyKey || '')}
                        style={computedStyle}
                    >
                        {ovo && (isLinkedResourceViewTypeWithoutIRI(settings)
                            ? renderNodeValueMarkupWithoutIRI(ovo.thumbnail, ovo.title, theme, undefined, getResourceId(ovo),undefined, ovo, false)
                            : renderNodeValueMarkup(ovo.thumbnail, ovo.title, theme, undefined, getResourceId(ovo),undefined, ovo, false)) }
                    </div>
                </div>
            </div>
            {
                this.renderProperty()
            }

        </div>;

    }

    render() {
        let {context, theme, location, propertyKey,onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage} = this.props;
        let {showComments, feedbackValue, feedbackValueLang, feedbackResult} = this.state;
        let treeResource = {
            [ID] : getResourceId(context.resource)

        }
        if (propertyKey) {
            treeResource[context.propertyKey] = [];
            context.dataValue = undefined;
        } else if(context.dataValue) {
            if (context.dataValue && (context.dataValue[TYPE] || context.dataValue[LANG])) {
                treeResource[context.propertyKey] = context.dataValue
            } else {
                treeResource[context.propertyKey] = {
                    [TYPE]: context[TYPE],
                    [VALUE]: getDataValue(context.dataValue),
                    [LANG]: context[LANG]
                }
            }
        }

        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={true}
                    datatest={'addNewFeedbackDialog'}
                    classes={{ paper: classes.dialogPaper }}
                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <div style={{display: 'flex'}}>
                            {centerVertically(<ForumOutlined></ForumOutlined>, {marginRight : '8px'})}
                            {centerVertically(<H2Title title={getUiLabelTranslation(settings, UI_LABELS_ADD_FEEDBACK, browseLanguage, UI_LABELS_ADD_FEEDBACK) }/>)}
                            <div style={{flexGrow  :'1'}}></div>
                            <IconButton
                                size={'small'}
                                datatest={'cancelButton'}
                                onClick={onClose}
                                variant={"outlined"}
                                color="secondary"
                            ><CloseIcon></CloseIcon></IconButton>

                        </div>
                    </DialogTitle>
                    <DialogContent  style={{paddingBottom : '0px'}}>
                        <FieldContainer style={{backgroundColor: getFeedbackBackgroundColor(settings)}} >
                            <FieldContainer style={{backgroundColor :  theme.palette.white.main, padding : '0px 0px 8px 0px', maxHeight : '300px', overflow : 'auto'}}>

                                {this.renderSubject()}

                            </FieldContainer>

                        </FieldContainer>
                        <div style={{paddingLeft : '16px'}}>
                            <div style={{
                                borderLeft: '2px solid',
                                borderLeftColor: getBorderColor(theme),
                                borderLeftStyle: 'solid',
                                paddingLeft : '16px'
                            }}>

                            {
                                showComments && toArray(feedbackResult).map((f, index) => {
                                    const feedbackBackgroundColor = getFeedbackBackgroundColor(settings);
                                    return <div key={index}>
                                        <div style={{height : '16px'}}></div>
                                            <FeedbackComment
                                                containerBackgroundColor={feedbackBackgroundColor}
                                                containerBorderColor={getFeedbackBorderColor(settings)}
                                                feedback={f}
                                                settings={settings}
                                                aliasesMap={aliasesMap}
                                                aliasesToIRIMap={aliasesToIRIMap}
                                                location={location}
                                                browseLanguage={browseLanguage}
                                                configurations={configurations}
                                                ontology={ontology}
                                            />
                                    </div>;
                                })
                            }
                            </div>
                            {
                                <div style={{
                                    height: '64px',
                                    borderLeft: '2px solid',
                                    borderLeftColor: getBorderColor(theme),
                                    borderLeftStyle: 'solid'
                                }}>
                                    <div datatest={'showHideButtonContainer'} style={{height: '100%'}}>
                                        {feedbackResult &&
                                            centerVertically(
                                                <div>

                                                    <Badge badgeContent={toArray(feedbackResult).length}
                                                           color="secondary">
                                                        <IconButton datatest={'showHideCommentButton'} size={'small'} onClick={() => {
                                                            this.setState({showComments: showComments ? false : true})
                                                        }}>
                                                            {showComments ? <UnfoldLessIcon/> : <UnfoldMoreOutlined/>}
                                                        </IconButton>

                                                    </Badge>
                                                </div>
                                                , {height: '100%'}
                                            )
                                        }
                                    </div>
                                </div>
                            }

                        </div>
                    </DialogContent>
                    <DialogActions style={{paddingTop : '0px'}}>
                        <FieldContainer style={{backgroundColor: getFeedbackBackgroundColor(settings),flexGrow  :'1', display : 'flex'}}>
                            <TextField
                                datatest={'textareaField-feedbackDialogComment'}
                                value={feedbackValue}
                                label={'Feedback'}
                                placeholder={'Type your feedback and submit'}
                                autoFocus={true}
                                rows={3}
                                fullWidth={true}
                                style={{marginTop : '0px'}}
                                multiline={true}
                                variant={'outlined'}
                                InputProps={{
                                    endAdornment : <IconButton
                                        datatest={'dialogFeedbackSubmitButton'}
                                        disabled={feedbackValue ? false : true}
                                        color={'secondary'}
                                        onClick={this.submitFeedback}
                                    ><SendRounded></SendRounded></IconButton>,
                                    startAdornment : renderBrowseLanguageSelect(settings, feedbackValueLang || browseLanguage,
                                        (ev) => {
                                           this.setState({feedbackValueLang : ev.target.value});
                                    }, undefined, undefined, 'dialogFeedbackLanguageSelect')

                                }}
                                onChange={(ev) => {
                                    const {target: {value}} = ev;
                                    this.setState({feedbackValue : value});
                                }}
                            ></TextField>
                        </FieldContainer>

                    </DialogActions>
                </Dialog>
            }
        </>;
    }
}

AddFeedbackDialog.propTypes = {
    settings: PropTypes.any,
    search: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onAdd: PropTypes.any,
    parentObject: PropTypes.any,
    propertyKey: PropTypes.any,
    shapeProperty: PropTypes.any,
    nodeValue: PropTypes.any,
    context: PropTypes.any,

};

export default withStyles({}, {withTheme: true})(AddFeedbackDialog);

import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
    centerVertically,
    createPayloadObjectForProperty,
    getDataContextURL,
    getLocalName,
    getPropertyName,
    initPropertyValue, isArrayOnly,
    isObjectOnly, sort,
    toArray
} from "../../components/util";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import Typography from "@material-ui/core/Typography";
import {getValuesObject} from "./SearchResultItem";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_PATH,
    ALIAS_SH_RESULT_MESSAGE,
    ALIAS_SH_VALUE,
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    ID,
    LANG,
    SH_IRI,
    TYPE,
    XSD_ANY_URI,
    XSD_BOOLEAN,
    XSD_BYTE,
    XSD_DATE,
    XSD_DATETIME,
    XSD_DECIMAL,
    XSD_DOUBLE,
    XSD_DURATION,
    XSD_FLOAT,
    XSD_G_DAY,
    XSD_G_MONTH,
    XSD_G_MONTH_DAY,
    XSD_G_YEAR,
    XSD_G_YEAR_MONTH,
    XSD_INT,
    XSD_INTEGER,
    XSD_LANGUAGE,
    XSD_LONG,
    XSD_NEGATIVE_INTEGER,
    XSD_NON_NEGATIVE_INTEGER,
    XSD_NON_POSITIVE_INTEGER,
    XSD_NORMALIZED_STRING,
    XSD_POSITIVE_INTEGER,
    XSD_SHORT,
    XSD_TIME,
    XSD_TOKEN,
    XSD_UNSIGNED_BYTE,
    XSD_UNSIGNED_INT,
    XSD_UNSIGNED_LONG,
    XSD_UNSIGNED_SHORT
} from "../../Constants";
import ArrayType from "../../components/ShapeToForm/ArrayType";
import {cloneDeep, isArray, isString} from "lodash";
import FieldContainer from "../../components/FieldContainer";
import {EditAttributesOutlined} from "@material-ui/icons";
import GlobalsContext from "../../components/GlobalsContext";
import {withEvent} from "./Event";
import {patchResourceAndHandleMerge} from "./NodeContainer";
import H3Title from "../../components/H3Title";
import ErrorMessage from "../../components/ErrorMessage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {getUiLabelTranslation, UI_LABELS_CANCEL, UI_LABELS_MULTILINE_INPUT, UI_LABELS_SAVE} from "./UILabel";
import {sortValues} from "./Tree";
import {getLanguageSuggestionsForSite} from "./DataGridView";

const styles = {
    dialogPaper: {
        minHeight: '80vh',
        maxHeight: '80vh',
    },
};


export function renderProperty(propertyTitleIcon, propertyTitle) {
    return <div style={{padding: '4px 0px', display: "flex"}}>
        {centerVertically(propertyTitleIcon)}
        {
            centerVertically(
                <Typography component={'h4'} style={{width: 200}} noWrap={true}>
                    {propertyTitle}
                </Typography>
            )
        }
    </div>;
}

export function getSourceResourceDetails(sourceResourceTitle, sourceResource, propertyTitle, propertyTitleIcon) {
    return <>
        {
            centerVertically(
                <div>
                    <div style={{padding: '4px 0px', display: "flex"}}>
                        {centerVertically(<LabelOutlinedIcon color={'secondary'}
                                                             style={{marginRight: '4px'}}/>)}
                        {
                            centerVertically(
                                <H3Title style={{width: 200}} noWrap={true}>
                                    {sourceResourceTitle || getLocalName(sourceResource[ID])}
                                </H3Title>
                            )
                        }
                    </div>
                    {renderProperty(propertyTitleIcon, propertyTitle)}

                </div>,
                {margin: '0px 24px', flexGrow : '1'}
            )
        }
    </>;
}

export function renderErrors(errors, color) {
    return errors && errors.map((e, index) => <div key={index}>
            <ErrorMessage
                color={color}
                error={`Value '${e[ALIAS_SH_VALUE]?.value || e[ALIAS_SH_VALUE]}' : ${e[ALIAS_SH_RESULT_MESSAGE]?.en || e[ALIAS_SH_RESULT_MESSAGE]}`}/>
        </div>
    );
}

export function createPropertyShapeClone(propertyShape, sourceResource, propertyKey) {
    let propertyShapeClone = cloneDeep(propertyShape);
    initPropertyValue(propertyShapeClone);
    let propertyValue = cloneDeep(toArray(sourceResource[propertyKey]));
    let valueArray = [];
    propertyValue.forEach(vo => {
        if (isObjectOnly(vo)) {
            if (vo[LANG] || vo[TYPE]) {
                valueArray.push(vo);
            } else {
                Object.keys(vo).sort().forEach(lk => {
                    let lv = vo[lk];
                    toArray(lv).sort().forEach(vl => {
                        valueArray.push({[lk]: vl})
                    });
                })
            }
        } else if (isArray(vo)) {
            vo.forEach(vl => valueArray.push(vl));
        } else {
            valueArray.push(vo);
        }
    })
    if (valueArray.length < 1) {
        valueArray.push("");
    }
    propertyShapeClone.value = toArray(valueArray);
    return propertyShapeClone;
}

function createPropertyShapeCloneFrom(propertyShape, sourceResource, aliasesMap) {
    let propertyIri = propertyShape[ALIAS_SH_PATH];
    let propertyKey = aliasesMap[propertyIri] || propertyIri;
    let propertyShapeClone = createPropertyShapeClone(propertyShape, sourceResource, propertyKey);
    return propertyShapeClone;
}

class AddDataPropertyValueDialog extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        let {propertyShape, sourceResource, aliasesMap} = props;
        let propertyShapeClone = createPropertyShapeCloneFrom(propertyShape, sourceResource, aliasesMap);
        let multiline = toArray(propertyShapeClone.value).find(v => {
            let val = v?.value || v;
            if(isObjectOnly(val)) {
                let found = Object.keys(val).find(k => {
                    return val[k].includes('\n');
                });
                return found ? true : false;
            }
            return val ? val.includes('\n') : false;
        }) ? true : false;
        this.state = {
            propertyShapeClone : propertyShapeClone,
            multiline : multiline
        }
    }

    toFormValue = (value, shapeProperty) => {
        if(isObjectOnly(value)) {

        }
    }

    componentDidMount() {
        let {sourceResource, aliasesToIRIMap, ontology, settings, browseLanguage} = this.props;

        getValuesObject(sourceResource, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
            let {title} = vo;
            this.setState({sourceResourceTitle : title})
        })
    }

    onSave = async () => {
        let {propertyShapeClone} = this.state;
        let {onClose, onSaveSuccess, onSaveFailure, aliasesMap, sourceResource, publishEvent, settings, browseLanguage} = this.props;
        if(this.hasAnyChange() === false) {
            onClose && onClose();
            return;
        }
        let payload = {};
        createPayloadObjectForProperty(propertyShapeClone, payload, aliasesMap, true);
        let patchPayload = {
            [AT_CONTEXT] : getDataContextURL(),
            [ID] : sourceResource[ID],
            [ALIAS_SYS_ETAG] : sourceResource[ALIAS_SYS_ETAG],
            ...payload
        }
        let result = await patchResourceAndHandleMerge(patchPayload, publishEvent, settings, browseLanguage);
        if(toArray(result.errors).length > 0) {
            this.setState({errors : result.errors})
        } else {
            if (result.updateFailed) {
                onSaveFailure && onSaveFailure(result.updateFailed);
            } else {
                onSaveSuccess && onSaveSuccess();
            }
        }

    }

    handleValueChange = () => {
        let {propertyShapeClone} = this.state;
        this.setState({});
    }

    hasAnyChange = () => {
        let {sourceResource, propertyShape, aliasesMap} = this.props;
        let {propertyShapeClone} = this.state;
        let currentPropertyShapeClone = createPropertyShapeCloneFrom(propertyShape, sourceResource, aliasesMap);
        let newStringValue = JSON.stringify(propertyShapeClone.value);
        let currenStringValue = JSON.stringify(currentPropertyShapeClone.value);
        if(newStringValue == currenStringValue) {
            return false;
        }
        return true;

    }

    isSingleLine = (propertyShapeClone) => {
        return propertyShapeClone && propertyShapeClone[ALIAS_SH_DATATYPE]
            && [
                XSD_DECIMAL,
                XSD_DOUBLE,
                XSD_FLOAT,
                XSD_DURATION,
                XSD_INTEGER,
                XSD_NEGATIVE_INTEGER,
                XSD_NON_NEGATIVE_INTEGER,
                XSD_NON_POSITIVE_INTEGER,
                XSD_POSITIVE_INTEGER,
                XSD_BYTE,
                XSD_INT,
                XSD_SHORT,
                XSD_LONG,
                XSD_UNSIGNED_BYTE,
                XSD_UNSIGNED_INT,
                XSD_UNSIGNED_SHORT,
                XSD_UNSIGNED_LONG,
                XSD_DATETIME,
                XSD_DATE,
                XSD_TIME,
                XSD_BOOLEAN,
                XSD_G_DAY,
                XSD_G_MONTH,
                XSD_G_YEAR,
                XSD_G_MONTH_DAY,
                XSD_G_YEAR_MONTH,
                XSD_TOKEN,
                XSD_NORMALIZED_STRING,
                XSD_ANY_URI,
                XSD_LANGUAGE,
                SH_IRI,
            ].includes(propertyShapeClone[ALIAS_SH_DATATYPE]);
    }

    onValidationError = (validationError) => {
        this.setState({validationError})
    }

    clearNewLineRecursive = (value, parent, parentKey) => {
        if(isString(value)) {
            parent[parentKey] = value.replaceAll(/(?:\r\n|\r|\n)/g, '');
        } else if(isArrayOnly(value)) {
            value.forEach((v, index) => {
                this.clearNewLineRecursive(v, value, index);
            })
        } else {
            Object.keys(value).forEach(k => {
                this.clearNewLineRecursive(value[k], value, k);
            })
        }
    }


    clearNewLine = () => {
        let {propertyShapeClone} = this.state;
        this.clearNewLineRecursive(propertyShapeClone.value, propertyShapeClone, 'value');
        this.setState({propertyShapeClone});
    }

    render() {
        let {settings, sourceResource, theme, onClose, classes, aliasesToIRIMap, ontology, propertyShape, browseLanguage, aliasesMap, configurations} = this.props;
        let {sourceResourceTitle, validationError, apiError, apiErrorResponse, propertyShapeClone, errors, multiline} = this.state;

        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={true}
                    datatest={'addPropertyDialog'}
                    classes={{paper: classes.dialogPaper}}
                    onKeyDown={(ev) => {
                        if(ev.ctrlKey && ev.key === 'Enter') {
                            this.onSave().catch();
                        } else if (ev.key === 'Escape') {
                            onClose();
                        } else if(ev.key === 'Enter') {
                            //Only stop propagation but do not preventDefault as we want to add new line in text field
                            ev.stopPropagation();
                        }
                    }}
                >
                    <DialogTitle id="form-dialog-title">
                        <div style={{display: 'flex'}}>
                            {centerVertically(<H2Title title={'Update'}/>)}
                            {getSourceResourceDetails(sourceResourceTitle, sourceResource, getPropertyName(aliasesToIRIMap, propertyShape[ALIAS_SH_PATH], ontology, browseLanguage), <EditAttributesOutlined
                                style={{marginRight: '4px', color: theme.palette.primary.main}}/>)}
                            <div style={{flexGrow: '1'}}/>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{backgroundColor: theme.palette.grey.background}}>
                        {apiError && <BackendErrorDialog
                            handleClose={() => this.setState({apiError: undefined, apiErrorResponse: undefined})}
                            open={true} error={apiErrorResponse}/>}

                        <FieldContainer style={{maxWidth: '600px'}}>
                            {   this.isSingleLine(propertyShapeClone) ? <></>:
                                <FormControlLabel
                                    style={{marginBottom: '16px'}}
                                    control={
                                        <Switch
                                            datatest={'multiline'}
                                            size="small"
                                            checked={multiline || false}
                                            value={multiline}
                                            name="etagAll"
                                            onChange={(value) => {
                                                if(multiline === true) {
                                                    this.clearNewLine();
                                                }
                                                this.setState({multiline: !multiline})
                                            }}
                                        />
                                    }
                                    label={getUiLabelTranslation(settings, UI_LABELS_MULTILINE_INPUT, browseLanguage, UI_LABELS_MULTILINE_INPUT)}
                                />
                            }
                            <React.Fragment key={multiline}>
                            <ArrayType autoFocusIndex={0} depth={0} onChange={this.handleValueChange}
                                       property={propertyShapeClone} aliasesMap={aliasesMap} ontology={ontology}
                                       configurations={configurations} customizations={{multiline, languageSuggestions : getLanguageSuggestionsForSite(settings) , onValidationError : this.onValidationError}}/>
                            </React.Fragment>
                            {
                                renderErrors(errors)
                            }
                        </FieldContainer>
                    </DialogContent>
                    <DialogActions datatest={'dialogActions'}>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow: '1'}}/>
                        <Button
                            disabled={validationError || (this.hasAnyChange() === false ? true : false)}
                            datatest={'addButton'}
                            variant={"contained"}
                            color="secondary"
                            onClick={this.onSave}
                        >{getUiLabelTranslation(settings, UI_LABELS_SAVE, browseLanguage, UI_LABELS_SAVE)}</Button>
                    </DialogActions>
                </Dialog>
            }
        </>;

    }


}

AddDataPropertyValueDialog.propTypes = {
    configurations: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onSaveSuccess: PropTypes.any,
    onSaveFailure: PropTypes.any,
    sourceResource: PropTypes.any,
    propertyLabel: PropTypes.any,
    propertyShape: PropTypes.any,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,

};

export default withEvent(withStyles(styles, {withTheme: true})(AddDataPropertyValueDialog));

import React from "react";
import {withStyles} from "@material-ui/core";
import {KeyboardDateTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DATETIME_FORMAT_CONTROL, EVENT_SHACL_VALIDATE} from "../../Constants";
import {formatDateTime, getMockValueFromDefaultRegex, getPropertyValueForForm, registerMock} from "../util";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import {isArray} from "lodash";
import {deleteButton} from "../../components/ShapeToForm/ArrayType";
import {withDelete} from "../../components/ShapeToForm/Text";
import {validateFromSHACL} from "./validation-util";
import ErrorMessage from "../ErrorMessage";

const StyledKeyboardDateTimePicker = withStyles((theme) => ({
        root: {
            "& .MuiIconButton-root" : {
                padding: '4px',
            }
        }
}))(KeyboardDateTimePicker);

export function DateTimePickerComponent({value, onChange, autoFocus, label, setRef, error}) {
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <StyledKeyboardDateTimePicker
            label={label}
            datatest={'dateTimePicker'}
            autoFocus={autoFocus}
            ampm={false}
            fullWidth={true}
            inputVariant="outlined"
            margin={"dense"}
            value={!value || value === '' ? null : value }
            onChange={(date, value) => {
                if(date !== null && date.getTime()) {
                    onChange(date);
                } else {
                    onChange(value);
                }
            }}
            onError={console.log}
            format={DATETIME_FORMAT_CONTROL}
            helperText={DATETIME_FORMAT_CONTROL}
            error={error}
            inputProps={{
                ref: setRef
            }}
        />
    </MuiPickersUtilsProvider>;
}

class DateTimePicker extends React.Component {
    static contextType = MockingContext

    constructor(props) {
        super(props)
        let value = getPropertyValueForForm(props)

        this.state = (value && { value : new Date(value)}) || {}
    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let mocking = this.context;
        let {property, valueIndex, customizations} = this.props;
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName);
        }

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? mocking.getMockValue(property)
            : getMockValueFromDefaultRegex(property)

        this.updatePropertyValue(new Date(mockValue))
    }

    validate = (eventName) => {
        let {property, valueIndex, customizations} = this.props;
        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    updatePropertyValue = (date) => {
        let {property, onChange, valueIndex} = this.props;
        let formattedDate = formatDateTime(date)
        if(isArray(property.value)) {
            property.value[valueIndex] = formattedDate;
        } else {
            property.value = formattedDate;
        }
        this.setState({value : date});
        this.validate(EVENT_SHACL_VALIDATE);
        onChange()
    }

    render() {
        let {autoFocus, valueIndex, property, onChange, customizations} = this.props;
        let {value, validationError} = this.state;
        let component = <DateTimePickerComponent setRef={this.setRef} error={validationError} autoFocus={autoFocus} value={value} onChange={this.updatePropertyValue}/>;
        component = validationError ? <>{component}<div/><ErrorMessage error={validationError}/></> : component;
        return withDelete(
            component,
            customizations?.hideDelete ? undefined : deleteButton(valueIndex, property, onChange),
            true
        );
    }
}

export default withStyles({}, {withTheme: true})(DateTimePicker);

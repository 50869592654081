import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withPermissions} from "../../service/permission-service";
import {FeedbackOutlined} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";

class FeedbackWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }
    render() {
        const {onClick} = this.props;
        return <>

            <IconButton
                datatest={'feedbackIconButton'}
                size={'small'}
                onClick={onClick}
            ><FeedbackOutlined color={'primary'}></FeedbackOutlined></IconButton>
        </>;
    }
}

FeedbackWidget.propTypes = {
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    onClick: PropTypes.any,
};

export default  withStyles({}, {withTheme: true})(withPermissions(FeedbackWidget));

import React from "react";
import {Grid, Paper, withStyles} from "@material-ui/core";
import GridContainer from "../components/GridContainer";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";
import ErrorMessage from "../components/ErrorMessage";
import {
    ALIAS_RDFS_LABEL,
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ID_LABEL,
    DATATEST_loadDCOntology,
    DATATEST_loadGraphologiOntology,
    DATATEST_loadSKOSOntology,
    DATATEST_loadSKOSXLOntology,
    DATATEST_loadTestOntology,
    DATATEST_loadYourOntology,
    GRAPH,
    ID,
    LINK_COLOR, LOAD_DUBLIN_CORE_ONTOLOGY,
    LOAD_GRAPHOLOGI_ONTOLOGY,
    LOAD_SKOS_ONTOLOGY,
    LOAD_SKOSXL_ONTOLOGY,
    LOAD_TEST_ONTOLOGY,
    LOAD_YOUR_ONTOLOGY,
    MESSAGE_ONTOLOGY_LOAD,
    ONTOLOGY_ALLOWED_FORMATS,
    STYLE_GRID_ITEM_SPACING,
    VALIDATION_ONTOLOGY_FILE_MAX_COUNT,
    VALIDATION_ONTOLOGY_FILE_MAX_SIZE
} from "../Constants";
import H3Title from "../components/H3Title";
import FileUpload from "../components/FileUpload";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {getChipWithDelete} from "../layouts/apiplayground/SearchFilter";
import {styles} from "../components/styles";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import FieldContainer from "../components/FieldContainer";
import TurtleEditor from "../components/CodeMirror/TurtleEditor";
import {skosOntology} from "../data/skosOntology";
import {sort, centerVertically, getFileExtension, getMaxOntologyFileSize} from "../components/util";
import {BlockActionButton} from "../components/ShapeToForm/ArrayType";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import {organisationPersonImageSmall} from "../data/testOntology";
import {skosxlOntology} from "../data/skosXLOntology";
import {dcOntology} from "../data/dcOntology";
import {goCustom} from "../data/goCustom";
import {getModels} from "../service/model-api";

const GROUP_CUSTOM_MODEL = 'From Schema Builder';

const MODEL_OPTIONS = [
    {datatest : DATATEST_loadYourOntology, id: LOAD_YOUR_ONTOLOGY, value : LOAD_YOUR_ONTOLOGY, group : 'Upload'},
    {datatest : DATATEST_loadTestOntology, id: LOAD_TEST_ONTOLOGY, value : LOAD_TEST_ONTOLOGY, group : 'Getting Started'},
    {datatest : DATATEST_loadGraphologiOntology, id : LOAD_GRAPHOLOGI_ONTOLOGY, value : LOAD_GRAPHOLOGI_ONTOLOGY, group : 'Graphologi'},
    {datatest : DATATEST_loadSKOSOntology, id : LOAD_SKOS_ONTOLOGY, value : LOAD_SKOS_ONTOLOGY, group : 'Standard'},
    {datatest : DATATEST_loadSKOSXLOntology, id : LOAD_SKOSXL_ONTOLOGY,value : LOAD_SKOSXL_ONTOLOGY, group : 'Standard'},
    {datatest : DATATEST_loadDCOntology, id : LOAD_DUBLIN_CORE_ONTOLOGY,value : LOAD_DUBLIN_CORE_ONTOLOGY, group : 'Standard'},
]

const ONTOLOGY_URLS = {
    [LOAD_SKOS_ONTOLOGY] : 'http://www.w3.org/TR/skos-reference/skos.rdf',
    [LOAD_SKOSXL_ONTOLOGY] : 'http://www.w3.org/TR/skos-reference/skos-xl.rdf',
    [LOAD_DUBLIN_CORE_ONTOLOGY] : 'https://www.dublincore.org/specifications/dublin-core/dcmi-terms/dublin_core_terms.ttl'
}

export const ONTOLOGY_CONTENT = {
    [LOAD_TEST_ONTOLOGY] : organisationPersonImageSmall,
    [LOAD_GRAPHOLOGI_ONTOLOGY] : goCustom,
    [LOAD_SKOS_ONTOLOGY] : skosOntology,
    [LOAD_SKOSXL_ONTOLOGY] : skosxlOntology,
    [LOAD_DUBLIN_CORE_ONTOLOGY] : dcOntology
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function isOptionSelected(selectedOptions, option) {
    return selectedOptions && selectedOptions.map(o => o.value).includes(option);
}

function getBlockTitle(theme, title) {
    return <H3Title title={title} />;
}

function block(theme, selectedOptions, title, state,  setState, blockRenderer) {
    let colorStyle = {color: theme.palette.grey.level3, paddingRight: '0px'}
    let collapseKey = title+"Collapse";
    let isCollapsed = state[collapseKey] === true;
    return isOptionSelected(selectedOptions, title)  && <Grid item xs={12}>
        <Paper datatest={'block-'+title} elevation={0} style={{padding: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>
            <div  style={{display: "flex"}}>
                {centerVertically(getBlockTitle(theme, title))}
                <div style={{flexGrow: 1}}/>
                {
                    centerVertically(
                        <BlockActionButton
                            theme={theme}
                            title={isCollapsed ? 'Expand' : 'Collapse'}
                            onClick={() => setState({[collapseKey] : !isCollapsed})}
                            endIcon={ isCollapsed ? <UnfoldMoreIcon style={colorStyle}/>: <UnfoldLessIcon style={colorStyle}/> }
                            buttonProps={{
                                datatest: 'blockExpandCollapseButton'
                            }}
                        />
                    )
                }
            </div>
            { isCollapsed === true || blockRenderer()}
        </Paper>
    </Grid> ;
}

function previewIfSelectedOntology(theme, selectedOptions, title, ontology, state,  setState) {
    let blockRenderer = () => <Grid datatest={'blockDetails'} container spacing={STYLE_GRID_ITEM_SPACING}>
        <Grid item xs={12}>
            <FieldContainer>
                <TurtleEditor value={ontology} readOnly />
            </FieldContainer>
        </Grid>
    </Grid>
    return block(theme, selectedOptions, title, state,  setState, blockRenderer);
}

function showUrlIfSelectedOntology(theme, selectedOptions, title, state,  setState) {
    let url = ONTOLOGY_URLS[title];
    let blockRenderer = () => <Grid datatest={'blockDetails'} container spacing={STYLE_GRID_ITEM_SPACING}>
        <Grid item xs={12}>
            Download link <a  style={{cursor: 'pointer', color: LINK_COLOR}} href={url} target={'_blank'}>{url}</a>
        </Grid>
    </Grid>
    return block(theme, selectedOptions, title, state,  setState, blockRenderer);
}

function showModelIfSelectedCustomModel(theme, selectedOptions, state,  setState) {
    return selectedOptions.filter(o => o.group === GROUP_CUSTOM_MODEL).map(o => {
        let title = o.value;
        let blockRenderer = () => <Grid datatest={'blockDetails'} container spacing={STYLE_GRID_ITEM_SPACING}>
            <Grid item xs={12}>
                {o[ALIAS_SYS_DESCRIPTION]}
            </Grid>
        </Grid>
        return block(theme, selectedOptions, title, state,  setState, blockRenderer);
    });
}

function onFilesSelect(state, setState, selectedFiles) {
    let selectedFilesFromState = state.selectedFiles ? state.selectedFiles : [];
    let selectedFilesFiltered = selectedFiles.filter(f => ONTOLOGY_ALLOWED_FORMATS.includes("."+getFileExtension(f.name)))
        .filter(f => !selectedFilesFromState.find(sf => sf.name === f.name));
    let allFiles = selectedFilesFiltered.length > 0 ? [...selectedFilesFiltered, ...selectedFilesFromState] : selectedFilesFromState
    let selectedFilesError = allFiles.length > VALIDATION_ONTOLOGY_FILE_MAX_COUNT ? true : false
    allFiles.forEach(f => {
        if(f.size > VALIDATION_ONTOLOGY_FILE_MAX_SIZE){
            selectedFilesError = true
            setState({ [f.name+'error']: `File size should be less than ${getMaxOntologyFileSize()}`})
        }
    })
    setState({
        selectedFiles: allFiles,
        selectedFilesError: selectedFilesError,
        ontologyTTL:'',
        ontology : [],
        idGeneratingClasses:[],
        treeData :[]
    });
};

export function getCustomModelLoadSelection(loadOntology) {
    return loadOntology.find(o => o.group === GROUP_CUSTOM_MODEL);
}

const BootstrapLoadModel = ({state, props, isMerging, getMainHeaderBar, setState, onProceed}) => {
    const {classes, theme} = props;
    let {selectedFiles} = state;
    const { loadOntology, selectedFilesError, filesSectionWarnings, filesSectionWarningsOpen, filesSectionErrors } = state;
    let size = 12;
    selectedFiles = selectedFiles || [];
    let inputLabelProps = classes
        ? {
            classes : {
                shrink: classes.smallWidthLabelShrink,
            }
        }
        : {};
    let modelOptions = isMerging ? [...MODEL_OPTIONS.filter(o => o.id === LOAD_YOUR_ONTOLOGY)] : [...MODEL_OPTIONS];
    if(!isMerging) {
        sort(getModels(), ALIAS_SYS_ID_LABEL).forEach(m => {
            modelOptions.push(
                {
                    [ID]: m[ID],
                    [ALIAS_SYS_DESCRIPTION]: m[ALIAS_SYS_DESCRIPTION],
                    value: m[ALIAS_SYS_ID_LABEL],
                    group: GROUP_CUSTOM_MODEL,
                    datatest: m[ALIAS_SYS_ID_LABEL]
                }
            )
        });
    }

    let content =
        <GridContainer>
            {getMainHeaderBar()}
            {
                filesSectionWarningsOpen &&
                <ConfirmDeleteDialog
                    open={filesSectionWarningsOpen}
                    data={{value: <div>
                            {filesSectionWarnings.map((w, i) => <div key={i} style={{marginBottom: '16px'}}><ErrorMessage error={w} color={theme.palette.warning.light}/></div>)}
                        </div>}}
                    handleCancel={() => setState({filesSectionWarningsOpen: false})}
                    handleOk={onProceed}
                    title={'Warning'}
                    okButtonTitle={'Proceed'}
                    cancelButtonTitle={'I Want to Fix'}/>
            }
            <Grid item sm={12} lg={6}>
                <Paper
                    elevation={0}
                    style={{
                        padding: "12px",
                        backgroundColor: theme.palette.border.main
                    }}
                >
                    <H3Title title="Choose Options" />
                    <Autocomplete
                        datatest={'loadModelOptions'}
                        id={'loadModelOptions'}
                        multiple
                        disableCloseOnSelect
                        size="small"
                        options={modelOptions}
                        value={loadOntology || []}
                        groupBy={(option) => option.group}
                        getOptionLabel={(option) => option.value}
                        getOptionDisabled={(option) => {
                            let optionID = option.id;
                            if( [LOAD_SKOS_ONTOLOGY, LOAD_SKOSXL_ONTOLOGY].includes(optionID) && loadOntology.find(o => o.id === LOAD_GRAPHOLOGI_ONTOLOGY)) {
                                return true;
                            }
                            if( LOAD_GRAPHOLOGI_ONTOLOGY === option.id && loadOntology.find(o => [LOAD_SKOS_ONTOLOGY, LOAD_SKOSXL_ONTOLOGY].includes(o.id))) {
                                return true;
                            }
                            if(loadOntology) {
                                //If custom model is selected disable all other options
                                if(getCustomModelLoadSelection(loadOntology)) {
                                    return true;
                                }
                                // if option is already selected disable
                                let found = loadOntology.find(o => o.id === optionID);
                                if(found) {
                                    return true;
                                } else {
                                    // if custom model option is selected disable all other options
                                    if(option.group === GROUP_CUSTOM_MODEL && loadOntology && loadOntology.length > 0) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                            } else {
                                return false;
                            }
                        }}
                        renderOption={(option, { selected }) => (
                            <React.Fragment>
                                <div datatest={option.datatest} style={{paddingLeft : '16px'}}>{option.value}</div>
                            </React.Fragment>
                        )}
                        onChange={(event, val) => {
                            if(!isOptionSelected(val, LOAD_YOUR_ONTOLOGY)) {
                                setState({
                                    selectedFiles:[],
                                    selectedFilesError: undefined,
                                    filesSectionErrors: undefined,
                                    filesSectionWarnings: undefined
                                })
                            }
                            setState({ontologyTTL : '', loadOntology : val})
                        }}
                        renderInput={(params) => (
                            <TextField InputLabelProps={inputLabelProps}  fullWidth={true} {...params} variant="outlined"/>
                        )}
                        renderTags={(value, getTagProps) => {
                            return value.map((option, index) => {
                                return getChipWithDelete(theme, index, option.value, option.value, getTagProps({index}));
                            })
                        }}
                    />
                </Paper>
            </Grid>
            {
                block(
                    theme, loadOntology, LOAD_YOUR_ONTOLOGY, state, setState,
                    () =>
                        <FileUpload
                            paperStyle={{padding : 0}}
                            accept={ONTOLOGY_ALLOWED_FORMATS}
                            fileUploadMessage={MESSAGE_ONTOLOGY_LOAD}
                            fileUploadMessageError={selectedFilesError}
                            dragNDropBoxTitle={"Drag 'n' Drop Ontology Files"}
                            filesSectionWarnings={filesSectionWarnings}
                            filesSectionErrors={filesSectionErrors}
                            files={ selectedFiles.map((f) => ({
                                f: f,
                                errors: state[f.name + 'error'] && state[f.name + 'error'][GRAPH]
                                    ? state[f.name + 'error'][GRAPH].map(e => e[ALIAS_RDFS_LABEL].en)
                                    : (state[f.name + 'error'] ? [state[f.name + 'error']] : [])
                            }))}
                            onFilesSelect={(selectedFiles) => onFilesSelect(state, setState, selectedFiles)}
                            onDelete={(file) => {
                                let selectedFilesCopy = selectedFiles.filter(f => f.name !== file.name)
                                let errorName = file.name + 'error'
                                let otherError = selectedFilesCopy.find(fl => state[fl.name + 'error']) ? true : false;
                                setState({
                                    ontologyTTL: '',
                                    selectedFiles: selectedFilesCopy,
                                    [errorName]: null,
                                    selectedFilesError: otherError,
                                    filesSectionErrors: undefined,
                                    filesSectionWarnings: undefined
                                })
                            }}
                        >
                        </FileUpload>
                )
                }
            {
                previewIfSelectedOntology(theme, loadOntology, LOAD_TEST_ONTOLOGY, organisationPersonImageSmall, state, setState)
            }
            {
                previewIfSelectedOntology(theme, loadOntology, LOAD_GRAPHOLOGI_ONTOLOGY, goCustom, state, setState)
            }
            {
                Object.keys(ONTOLOGY_URLS).map(k => {
                    return showUrlIfSelectedOntology(theme, loadOntology, k, state, setState);
                })
            }
            {
                showModelIfSelectedCustomModel(theme, loadOntology, state, setState)
            }
        </GridContainer>;
    return content;

};


export default withStyles(styles, { withTheme: true })(BootstrapLoadModel);

import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {
    centerVertically,
    flatten,
    getLocalName,
    getObjectProperties,
    getPropertyName,
    getRdfProperties,
    getResourceId,
    isEmptyArray,
    isObjectPropertyOrRDFConnectionProperty,
    sort,
    sortByFunction,
    toArray
} from "../../components/util";
import {ID, TYPE} from "../../Constants";
import {Chip, IconButton, LinearProgress, TextField as OtherTextField, Tooltip} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
    AllInclusive,
    BlurOff,
    DirectionsWalkOutlined,
    ExposurePlus1,
    ExposurePlus2,
    NotInterestedOutlined
} from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import {getValuesObject} from "./SearchResultItem";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import Button from "@material-ui/core/Button";
import {nullToIdObjects} from "./GraphView";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import {withEvent} from "./Event";
import {isAnyValueIdObject} from "./Tree";
import {
    getBindingValue,
    searchIncomingConnectionResources,
    searchIncomingConnections
} from "../../service/sparql-queries";

const WIDTH = 320;

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: WIDTH - 24,
        backgroundColor: theme.palette.grey.background,
        marginTop : '4px',
        padding : '0px 8px 1px 8px',
        borderRadius : '4px'
    },
}));

function getLabel(value) {
    return value.title || getLocalName(value[ID]);
}

function PropertyList({data, graphNodeId, graphData, connectionProperty, connectionDirection, aliasesToIRIMap, settings, languageCode, onToggle, theme, graphNodes, graphEdges, totalCount}) {
    const classes = useStyles();

    const sortedData = sortByFunction(data, getLabel);
    const valueObject = graphData[graphNodeId];
    let propertyValue = connectionProperty.propertyKey === TYPE
        ? toArray(valueObject[connectionProperty.propertyKey]).map(v => aliasesToIRIMap[v]||v)
        :  valueObject[connectionProperty.propertyKey];
    return (
        <div className={classes.root} >
            { sortedData.length > 0 &&
                <div style={{ padding: '8px', display : 'flex', marginBottom : '8px', background : theme.palette.grey.background, borderRadius : '4px'}}>
                    {centerVertically(<Chip datatest={'countChip'} variant={"outlined"} size={'small'} title={totalCount} color={'secondary'} label={totalCount} />)}
                    <div style={{flexGrow : '1'}}></div>
                    {
                        centerVertically(
                            <StyledTooltipForPopperAutocomplete title={'Add nodes and edges just for this property.'}>
                                <IconButton datatest={'propAll'+'AddIcon'} size={"small"}
                                            onClick={(e) => {
                                                if(isOutgoingOnly(connectionDirection)) {
                                                    onToggle(propertyValue, true, {
                                                        sourceGraphNodeId: graphNodeId,
                                                        connectionProperty
                                                    })
                                                } else if(isIncomingOnly(connectionDirection)) {
                                                    searchIncomingConnectionResources(graphNodeId, toArray(connectionProperty.value), 1000).then(resources => {
                                                        onToggle(resources, true, {
                                                            sourceGraphNodeId: graphNodeId,
                                                            connectionProperty,
                                                            connectionDirection
                                                        })
                                                    })
                                                }
                                            }}>
                                    <AddIcon/>
                                </IconButton>
                            </StyledTooltipForPopperAutocomplete>,
                            {marginLeft: '4px'}
                        )
                    }
                    {
                        centerVertically(
                            <StyledTooltipForPopperAutocomplete title={'Add nodes and all edges for nodes connected by this property .'}>
                                <IconButton datatest={'propAll'+'ExposurePlus1'} size={"small"}
                                            onClick={(e) => {
                                                if(isOutgoingOnly(connectionDirection)) {
                                                    onToggle(propertyValue, true, {
                                                        sourceGraphNodeId: graphNodeId
                                                    })
                                                } else if (isIncomingOnly(connectionDirection)) {
                                                    searchIncomingConnectionResources(graphNodeId, toArray(connectionProperty.value), 1000).then(resources => {
                                                        onToggle(resources, true, {
                                                            sourceGraphNodeId: graphNodeId,
                                                            connectionDirection
                                                        })
                                                    })

                                                }
                                            }}>
                                    <ExposurePlus1/>
                                </IconButton>
                            </StyledTooltipForPopperAutocomplete>,
                            {marginLeft: '4px'}
                        )
                    }
                    {
                        centerVertically(
                            <StyledTooltipForPopperAutocomplete title={'Add all nodes and add selected connection to all other nodes.'}>
                                <IconButton datatest={'propAll'+'ExposurePlus2'} size={"small"}
                                            onClick={(e) => {
                                                if(isOutgoingOnly(connectionDirection)) {
                                                    onToggle(propertyValue, true, {
                                                        connectionProperty
                                                    })
                                                } else {
                                                    searchIncomingConnectionResources(graphNodeId, toArray(connectionProperty.value), 1000).then(resources => {
                                                        onToggle(resources, true, {
                                                            connectionProperty,
                                                            connectionDirection
                                                        })
                                                    })

                                                }
                                            }}>
                                    <ExposurePlus2/>
                                </IconButton>
                            </StyledTooltipForPopperAutocomplete>,
                            {marginLeft: '4px'}
                        )
                    }
                    {
                        centerVertically(
                            <StyledTooltipForPopperAutocomplete title={'Add nodes and edges for this property and add all connections between any added nodes.'}>
                                <IconButton datatest={'propAll'+'AllInclusive'} size={"small"}
                                            onClick={(e) => {
                                                if(isOutgoingOnly(connectionDirection)) {
                                                    onToggle(propertyValue, true, {
                                                        connectToAll: true
                                                    })
                                                } else if (isIncomingOnly(connectionDirection)) {
                                                    searchIncomingConnectionResources(graphNodeId, toArray(connectionProperty.value), 1000).then(resources => {
                                                        onToggle(resources, true, {
                                                            connectToAll: true,
                                                            connectionDirection
                                                        })
                                                    })
                                                }
                                            }}>
                                    <AllInclusive/>
                                </IconButton>
                            </StyledTooltipForPopperAutocomplete>,
                            {marginLeft: '4px'}
                        )
                    }
                    {
                        centerVertically(
                            <StyledTooltipForPopperAutocomplete title={'Auto walk this property path.'}>
                                <IconButton datatest={'propAll'+'DirectionsWalkOutlined'} size={"small"}
                                            onClick={(e) => {
                                                if(isOutgoingOnly(connectionDirection)) {
                                                    onToggle(propertyValue, true, {
                                                        connectionProperty,
                                                        walk: true
                                                    })
                                                } else if (isIncomingOnly(connectionDirection)) {
                                                    searchIncomingConnectionResources(graphNodeId, toArray(connectionProperty.value), 1000).then(resources => {
                                                        onToggle(resources, true, {
                                                            connectionProperty,
                                                            walk: true,
                                                            connectionDirection
                                                        })
                                                    })
                                                }
                                            }}>
                                    <DirectionsWalkOutlined/>
                                </IconButton>
                            </StyledTooltipForPopperAutocomplete>,
                            {marginLeft: '4px'}
                        )
                    }

                </div>
            }
            {sortedData.map((value, index) => {
                const label = getLabel(value);
                let targetId = value[ID];
                const hasEdge = isOutgoingOnly(connectionDirection)
                    ? graphEdges.find(ge => ge.data.target === targetId && ge.data.propertyKey === connectionProperty.propertyKey) ? true : false
                    : graphEdges.find(ge => ge.data.target === graphNodeId && ge.data.propertyKey === connectionProperty.propertyKey) ? true : false ;
                let valueArray = toArray(value?.summary?.value);

                const prop = connectionProperty?.propertyKey;
                return (
                    <div datatest={label} key={index + connectionProperty?.propertyKey} style={{ padding : '4px', marginBottom : '8px', borderRadius : '4px', border : '1px solid', borderColor : theme.palette.grey.level2, backgroundColor : theme.palette.white.main}}>
                        <div style={{padding : '4px', display : 'flex'}}>
                            {

                                centerVertically(
                                    <Typography noWrap={true} variant="body2" color={"inherit"} component="div">{
                                        valueArray.map((r, i) => {
                                            return <Chip datatest={'typeChip'} variant={'outlined'} key={r} size={'small'} label={r}/>;
                                        })
                                    }</Typography>,
                                    {maxWidth: (WIDTH - 168)+'px'}
                                )
                            }
                            <div style={{flexGrow : '1'}}></div>
                            <StyledTooltipForPopperAutocomplete title={'Add this node and edge.'}>
                                <IconButton datatest={prop+('AddIcon')} disabled={hasEdge} style={{marginLeft : '4px'}} size={"small"}
                                            onClick={() => {
                                                if(isOutgoingOnly(connectionDirection)) {
                                                    onToggle(targetId, !hasEdge, {sourceGraphNodeId: graphNodeId, connectionProperty})
                                                } else if(isIncomingOnly(connectionDirection)){
                                                    onToggle(targetId, !hasEdge, {sourceGraphNodeId: graphNodeId, connectionProperty, connectionDirection})
                                                }
                                            }}
                                >
                                    {<AddIcon/>}
                                </IconButton>
                            </StyledTooltipForPopperAutocomplete>
                            {
                                <>
                                    <StyledTooltipForPopperAutocomplete title={'Add this node and all edges.'}>
                                        <IconButton datatest={prop+'ExposurePlus1'} style={{marginLeft : '4px'}} size={"small"}
                                                    onClick={() => {
                                                        if(isOutgoingOnly(connectionDirection)) {
                                                            onToggle(targetId, !hasEdge, {sourceGraphNodeId: graphNodeId})
                                                        } else if(isIncomingOnly(connectionDirection)) {
                                                            onToggle(targetId, !hasEdge, {sourceGraphNodeId: graphNodeId, connectionProperty, connectionDirection})

                                                        }
                                                    }}
                                        >
                                            <ExposurePlus1/>
                                        </IconButton>
                                    </StyledTooltipForPopperAutocomplete>
                                    <StyledTooltipForPopperAutocomplete title={'Add this node and edge. Plus add the same edge from other nodes to the added node.'}>
                                        <IconButton datatest={prop+'ExposurePlus2'} style={{marginLeft : '4px'}} size={"small"} onClick={() => {
                                            if(isOutgoingOnly(connectionDirection)) {
                                                onToggle(targetId, !hasEdge, {connectionProperty})
                                            } else if(isIncomingOnly(connectionDirection)) {
                                                onToggle(targetId, !hasEdge, {connectionProperty, connectionDirection})
                                            }
                                        }}>
                                            <ExposurePlus2/>
                                        </IconButton>
                                    </StyledTooltipForPopperAutocomplete>
                                    <StyledTooltipForPopperAutocomplete title={'Add this node and add all edges to all other nodes.'}>
                                        <IconButton datatest={prop+'AllInclusive'} style={{marginLeft : '4px'}} size={"small"} onClick={() => {
                                            if(isOutgoingOnly(connectionDirection)) {
                                                onToggle(targetId, !hasEdge, {connectToAll: true})
                                            } else if (isIncomingOnly(connectionDirection)) {
                                                onToggle(targetId, !hasEdge, {connectToAll: true, connectionDirection})
                                            }
                                        }}>
                                            <AllInclusive/>
                                        </IconButton>
                                    </StyledTooltipForPopperAutocomplete>
                                </>
                            }

                        </div>
                        <div style={{display : 'flex', padding : '4px 0px'}}>
                            {centerVertically(<LabelOutlinedIcon color={'secondary'} style={{marginRight: '4px'}}/>)}
                            {
                                centerVertically(

                                        <Typography style={{width : WIDTH - 104}} variant={'h4'} noWrap={true}>
                                            <Tooltip title={targetId}><span>{label}</span></Tooltip>
                                        </Typography>

                                )
                            }
                            <div style={{flexGrow : '1'}}></div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
}
const StyledPopperAutocomplete = withStyles({
    popper :  {
        zIndex : '1600'
    },
    listbox : {
        maxHeight : '120px'
    },
    clearIndicator : {
        display : 'none'
    }
})(Autocomplete)

const StyledTooltipForPopperAutocomplete = withStyles({
    popper :  {
        zIndex : '1700'
    }
})(Tooltip)

export function isIncomingOnly(connectionDirection) {
    let connectionDirectionValue = connectionDirection || DIRECTION_OPTIONS[2];
    return connectionDirectionValue.label === DIRECTION_OPTIONS[1].label;
}

export function isOutgoingOnly(connectionDirection) {
    let connectionDirectionValue = connectionDirection || DIRECTION_OPTIONS[2];
    return connectionDirectionValue.label === DIRECTION_OPTIONS[2].label;
}

export function isAllConnections(connectionDirection) {
    let connectionDirectionValue = connectionDirection || DIRECTION_OPTIONS[2];
    return connectionDirectionValue.label === DIRECTION_OPTIONS[0].label;
}

export const DIRECTION_OPTION_INCOMING = 'Incoming';
export const DIRECTION_OPTIONS = [{label : 'All'},  {label : DIRECTION_OPTION_INCOMING}, {label : 'Outgoing'}];

export function getOutgoingProperties(graphData, graphNodeId, configurations, aliasesToIRIMap, ontology, languageCode) {
    let valueObject = graphData[graphNodeId];
    if (!valueObject) {
        return [];
    }
    let allObjectProperties = [...getObjectProperties(configurations), ...getRdfProperties(configurations)];
    let objectProperties = Object.keys(valueObject).map(k => {
        let iri = aliasesToIRIMap[k] || k;
        let propertyName = getPropertyName(aliasesToIRIMap, k, ontology, languageCode);
        let ontologyProperty = iri && allObjectProperties.find(ob => ob[ID] === iri);
        let propertyValue = valueObject[k];
        if (ontologyProperty && isObjectPropertyOrRDFConnectionProperty(ontologyProperty, aliasesToIRIMap) ) {
            return {label: propertyName, propertyKey: k, value: iri, count: toArray(valueObject[k]).length};
        } else if (isAnyValueIdObject(propertyValue)) {
            return {label: propertyName, propertyKey: k, value: iri, count: toArray(valueObject[k]).length};
        } else {
            return undefined;
        }
    }).filter(op => op);
    let typeValueArray = toArray(valueObject[TYPE]);
    if(!isEmptyArray(typeValueArray)) {
        objectProperties.push({label: TYPE, propertyKey: TYPE, value: TYPE, count: typeValueArray.length});
    }
    return sort(objectProperties, 'label');
}

export function getAllConnectedResources(objectProperties, valueObject, aliasesToIRIMap) {
    let allConnectedResources = objectProperties.map(op => {
        if (op.propertyKey === TYPE) {
            return valueObject[op.propertyKey] && toArray(valueObject[op.propertyKey]).map(v => aliasesToIRIMap[v] || v);
        }
        return valueObject[op.propertyKey];
    });
    return flatten(allConnectedResources);
}

class GraphViewNodeTooltip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            connectedData : [],
            incomingOptions : []
        }
    }

    componentDidMount() {
        let {onExpand, graphData, graphNodeId, settings, aliasesMap, aliasesToIRIMap, languageCode, ontology, loadMore} = this.props;
        searchIncomingConnections(graphNodeId).then(rows => {
            let incomingOptions = rows.map(row => {
                let iri = getBindingValue(row, 'property');
                let count = getBindingValue(row, 'count');
                let propertyName = getPropertyName(aliasesToIRIMap, iri, ontology, languageCode);
                let propertyKey = aliasesMap[iri] || iri;
                return {label: propertyName, propertyKey : propertyKey , value: iri, count : count};
            });
            let sorted = sort(incomingOptions, 'label')
            this.setState({incomingOptions: sorted})
        })
    }

    expandDataAndSet = (ids, val, loadAll) => {
        let {
            onExpand,
            graphData,
            graphNodeId,
            settings,
            aliasesToIRIMap,
            languageCode,
            ontology,
            loadMore
        } = this.props;
        this.setState({totalCount: ids.length});
        const defaultMaxItems = 20;
        let idsToExpand = ids;
        if (ids.length > defaultMaxItems && loadAll !== true) {
            idsToExpand = ids.slice(0, defaultMaxItems);
        }
        onExpand(idsToExpand).then(expanded => {
            let allExpanded = nullToIdObjects(expanded, ids);
            let all = allExpanded.map(ex => getValuesObject(ex, settings, aliasesToIRIMap, {value: languageCode}, ontology));
            Promise.all(all).then((values) => {
                this.setState({connectedData: values, loading: false, connectionProperty: val});
            })
        }).catch(() => {
            this.setState({dataLoadError: 'dataLoadError', loading: false})
        })

    }

    expandDataForProperty = (val, loadAll) => {
        let {
            onExpand,
            graphData,
            graphNodeId,
            settings,
            aliasesToIRIMap,
            languageCode,
            ontology,
            loadMore
        } = this.props;
        if(this.isOutgoingOnly()) {
            let valueObject = graphData[graphNodeId];
            let ids = val.propertyKey === TYPE
                ? toArray(val && valueObject[val.propertyKey]).map(t => aliasesToIRIMap[t]||t).map(vo => getResourceId(vo) || vo)
                : toArray(val && valueObject[val.propertyKey]).map(vo => getResourceId(vo) || vo);

            this.expandDataAndSet(ids, val, loadAll);
        } else if (this.isIncomingOnly()) {
            let property = val.value;
            searchIncomingConnectionResources(graphNodeId, toArray(property), 10000).then(resources => {
                this.expandDataAndSet(resources, val, loadAll);
            })
        }
    }

    getObjectPropertyOptions = () => {
        let {configurations, aliasesToIRIMap, ontology, languageCode, graphData, graphNodeId} = this.props;
        return getOutgoingProperties(graphData, graphNodeId, configurations, aliasesToIRIMap, ontology, languageCode);

    }

    onPropertyChange = (event, val, loadAll) => {
        this.setState({
            connectionProperty: val,
            connectedData: [],
            loading: true
        }, () => this.expandDataForProperty(val, loadAll));
    }

    onDirectionChange = (event, val, loadAll) => {
        this.setState({
            connectionProperty: undefined,

            connectedData: [],
            connectionDirection: val
        });
    }

    isIncomingOnly = () => {
        let {connectionDirection} = this.state;
        return isIncomingOnly(connectionDirection);
        let connectionDirectionValue = connectionDirection || DIRECTION_OPTIONS[2];
        return connectionDirectionValue.label === DIRECTION_OPTIONS[1].label;
    }

    isOutgoingOnly = () => {
        let {connectionDirection} = this.state;
        return isOutgoingOnly(connectionDirection);
    }

    isAllConnections = () => {
        let {connectionDirection} = this.state;
        return isAllConnections(connectionDirection);
    }

    connectedResources = (objectProperties, valueObject) => {
        let {aliasesToIRIMap} = this.props;
        return getAllConnectedResources(objectProperties, valueObject, aliasesToIRIMap);

    }

    render() {
        let {connectionProperty, connectionDirection, dataLoadError, loading, connectedData, incomingOptions, loadMore, totalCount} = this.state;
        let {theme, onObjectToggle, graphEdges, graphNodes, mostRecentConnectionProperty, graphElement, aliasesToIRIMap, browseLanguage, settings, languageCode, ontology, graphData, graphNodeId, onExpand} = this.props;
        let valueObject = graphData[graphNodeId];
        let connectionDirectionValue = connectionDirection || DIRECTION_OPTIONS[2];
        let objectProperties = this.getObjectPropertyOptions();
        const propertyOptions = this.isOutgoingOnly()
            ?  objectProperties
            :  this.isIncomingOnly()
                ? incomingOptions : [...objectProperties, ...incomingOptions]
        if(!connectionProperty) {
            connectionProperty = mostRecentConnectionProperty && propertyOptions.find(op => op.propertyKey === mostRecentConnectionProperty.propertyKey)
            connectionProperty = connectionProperty ? connectionProperty : propertyOptions[0];
            // it is possible that there may not be any connection property
            if(connectionProperty) {
                this.onPropertyChange(undefined, connectionProperty);
            }
        }
        const hasConnectionProperties = toArray(propertyOptions).length > 0 ;

        return <div key={connectionDirectionValue.label} style={{
            color: theme.palette.primary.main,
            height : '260px',
            width : WIDTH+'px',
            overflow : 'scroll',
            background : theme.palette.white.main,
            borderRadius :'4px',
            paddingLeft : '8px',
            paddingRight : '2px'
        }} onClick={(e) => e.stopPropagation()}>
            {
                dataLoadError &&
                    <AlertSnackbarContent
                        onClose={() => this.setState({dataLoadError : undefined})}
                        message={'Failed to load some resources.'}
                        variant={'error'}
                        open={true}
                        autoHide={false}
                    />
            }
            <div style={{display : 'flex'}}>
                    {centerVertically(<LabelOutlinedIcon color={'secondary'} style={{marginRight: '4px'}}/>)}
                    {
                        centerVertically(
                            <Typography variant={'h4'} noWrap={true}>
                                <span>{graphElement?.data().label}</span>
                            </Typography>

                        )
                    }

                <div style={{flexGrow : '1'}}></div>
                {
                    centerVertically(
                        <StyledTooltipForPopperAutocomplete title={'Hide all other nodes except this node.'}>
                            <IconButton
                                disabled={graphNodes.length === 1}
                                datatest={'BlurOff'}
                                style={{marginLeft: '4px'}}
                                size={'small'}
                                onClick={() => onObjectToggle(Object.keys(graphData).filter(key => key !== graphNodeId), false)}>
                                <BlurOff/>
                            </IconButton>
                        </StyledTooltipForPopperAutocomplete>
                    )
                }
                {
                    centerVertically(
                        <StyledTooltipForPopperAutocomplete title={'Hide this node.'}>
                            <IconButton
                                disabled={graphNodes.length === 1}
                                datatest={'NotInterestedOutlined'}
                                style={{marginLeft: '4px'}}
                                size={'small'}
                                onClick={() => onObjectToggle(graphNodeId, false)}
                            >
                                <NotInterestedOutlined/>
                            </IconButton>
                        </StyledTooltipForPopperAutocomplete>
                    )
                }

            </div>
            <div style={{display : 'flex', marginTop : '4px'}}>
                <div style={{width : '140px'}}>
                    {
                        <StyledPopperAutocomplete
                            disablePortal
                            datatest={'directionOptions'}
                            disbaleClearable
                            value={connectionDirectionValue}
                            options={DIRECTION_OPTIONS}
                            getOptionLabel={option => option.label ? option.label : ''}
                            onChange={this.onDirectionChange}
                            renderOption={(option, {selected}) => (
                                <div datatest={option.label}>
                                    {centerVertically(<Typography
                                        component={'span'}>{option.label}</Typography>)}
                                </div>
                            )}
                            renderInput={params => (
                                <OtherTextField
                                    {...params}
                                    label={'Connection'}
                                    margin={"dense"}
                                    variant="outlined"
                                />
                            )}
                            onBlur={(ev) => ev.stopPropagation()}
                            size={"small"}

                        />
                    }</div>

                {
                    centerVertically(
                    <div style={{paddingTop: '4px', display : 'flex'}}>
                        <StyledTooltipForPopperAutocomplete title={'Add all connected nodes.'}>
                            <IconButton
                                disabled={hasConnectionProperties === false}
                                datatest={'ExposurePlus1'}
                                style={{marginLeft: '4px'}}
                                size={"small"}
                                onClick={() => {
                                    if(this.isOutgoingOnly()) {
                                        let allConnectedResources = this.connectedResources(objectProperties, valueObject);
                                        allConnectedResources = flatten(allConnectedResources);
                                        onObjectToggle(allConnectedResources, true, {
                                            sourceGraphNodeId: graphNodeId
                                        });
                                    } else if(this.isIncomingOnly()) {
                                        searchIncomingConnectionResources(graphNodeId, [], 10000).then(resources => {
                                            onObjectToggle(resources, true, {
                                                sourceGraphNodeId: graphNodeId,
                                                connectionDirection : connectionDirection
                                            });
                                        })
                                    } else {
                                        let allConnectedResources = this.connectedResources(objectProperties, valueObject);
                                        searchIncomingConnectionResources(graphNodeId, [], 10000).then(resources => {
                                            onObjectToggle([graphNodeId, ...resources, ...allConnectedResources], true, {
                                                sourceGraphNodeId: graphNodeId,
                                                connectionDirection : connectionDirection
                                            });
                                        })
                                    }
                                }}>
                                <AddIcon/>
                            </IconButton>
                        </StyledTooltipForPopperAutocomplete>

                        <StyledTooltipForPopperAutocomplete
                            title={'Add all connected nodes and their connections.'}
                        >
                            <IconButton
                                disabled={hasConnectionProperties === false}

                                datatest={'AllInclusive'}
                                style={{marginLeft: '4px'}}
                                size={'small'}
                                onClick={() => {
                                    if(this.isOutgoingOnly()) {
                                        let allConnectedResources = this.connectedResources(objectProperties, valueObject);
                                        onObjectToggle(allConnectedResources, true, {connectToAll: true});
                                    } else if (this.isIncomingOnly()) {
                                        searchIncomingConnectionResources(graphNodeId, [], 10000).then(resources => {
                                            onObjectToggle(resources, true, {
                                                connectToAll: true,
                                                connectionDirection : connectionDirection
                                            });
                                        })
                                    }else {
                                        let allConnectedResources = this.connectedResources(objectProperties, valueObject);
                                        searchIncomingConnectionResources(graphNodeId, [], 10000).then(resources => {
                                            onObjectToggle([graphNodeId, ...resources, ...allConnectedResources], true, {
                                                connectionDirection : connectionDirection,
                                                connectToAll: true,
                                            });
                                        })
                                    }
                                }}
                            ><AllInclusive></AllInclusive></IconButton>
                        </StyledTooltipForPopperAutocomplete>
                    </div> , {flexGrow : '1'}
                    )
                }
            </div>
            {
                this.isAllConnections() ?
                        <></>
                    : <>
                    <div style={{display: 'flex'}}>
                        <div style={{flexGrow: '1'}}>{
                            hasConnectionProperties ?
                                <StyledPopperAutocomplete
                                    disablePortal
                                    datatest={'autocompleteProperties'}
                                    disbaleClearable
                                    value={connectionProperty}
                                    options={propertyOptions}
                                    getOptionLabel={option => option.label ? option.label : ''}
                                    onChange={this.onPropertyChange}
                                    renderOption={(option, {selected}) => (
                                        <div datatest={option.label} style={{flexGrow: '1', display: "flex"}}>
                                            {centerVertically(<Typography style={{width: (WIDTH - 120) + 'px'}}
                                                                          noWrap={true}
                                                                          component={'span'}>{option.label}</Typography>)}
                                            <div style={{flexGrow: '1'}}></div>
                                            {centerVertically(<Chip variant={"outlined"} size={'small'} title={option.count}
                                                                    color={'secondary'} label={option.count}/>)}
                                        </div>
                                    )}
                                    renderInput={params => (
                                        <OtherTextField
                                            {...params}
                                            label={'Property'}
                                            margin={"dense"}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    onBlur={(ev) => ev.stopPropagation()}
                                    size={"small"}

                                />
                                :  this.isOutgoingOnly()
                                    ? <div datatest={'noConnectionMessage'} style={{margin: '16px'}}>There is no outgoing connection from this resource to any other resource.</div>
                                    : <></>
                        }</div>
                    </div>
                    {loading && <div style={{margin : '16px'}}><LinearProgress color="secondary" /></div>}
                    {
                        connectionProperty &&
                        <PropertyList
                            graphNodeId={graphNodeId}
                            graphData={graphData}
                            data={connectedData}
                            connectionProperty={connectionProperty}
                            connectionDirection={connectionDirection}
                            languageCode={languageCode}
                            settings={settings}
                            aliasesToIRIMap={aliasesToIRIMap}
                            onToggle={onObjectToggle}
                            theme={theme}
                            graphEdges={graphEdges}
                            graphNodes={graphNodes}
                            totalCount={totalCount}
                        />
                    }
                    {loading || (connectedData && totalCount > connectedData.length && <Button style={{marginTop : '8px'}} color={"secondary"} fullWidth variant={'outlined'} size={'small'} onClick={() => this.onPropertyChange(undefined, connectionProperty, true)}>Load All {totalCount} Resources</Button>)}
                </>
            }
        </div>

    }

}

GraphViewNodeTooltip.propTypes = {
    registerForEvents : PropTypes.func,
    minimized: PropTypes.any,
    editMode: PropTypes.any,
    languageCode: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.array,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    graphNodeId: PropTypes.any,
    graphElement: PropTypes.any,
    graphData: PropTypes.object,
    graphNodes: PropTypes.any,
    graphEdges: PropTypes.any,
    mostRecentConnectionProperty: PropTypes.any,
    onSearch: PropTypes.func,
    searchButtonRenderer: PropTypes.func,
    onExpand: PropTypes.func,
    onObjectToggle: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(withEvent(GraphViewNodeTooltip));

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {getBrowseLanguageCode, getSearchResult, isEmptyArray} from "../../components/util";
import {ID} from "../../Constants";
import {Grid} from "@material-ui/core";
import SearchResultItem from "./SearchResultItem";
import InstructionForAction from "../../components/InstructionForAction";
import {getUiLabelTranslation, UI_LABELS_NO_RESULTS} from "./UILabel";
import {VIEW_MODE_LIST} from "./Workspace";


class SearchResult extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {location, onItemFocus, graphViewProvider, searchResult, browseLanguage,settings, aliasesMap, aliasesToIRIMap, ontology, viewMode, configurations} = this.props;
        let searchResultResources = getSearchResult(searchResult);
        let langCode = getBrowseLanguageCode(browseLanguage);
        return <>
            {
                isEmptyArray(searchResultResources) &&
                <Grid item xs={12}>
                    <InstructionForAction text={
                        getUiLabelTranslation(settings, UI_LABELS_NO_RESULTS, browseLanguage, 'No results found.')
                    }/>
                </Grid>
            }
            {
                searchResultResources.map((r, index) => {
                    return <Grid key={r[ID]+langCode+index} item xs={viewMode === VIEW_MODE_LIST && 12} >
                        <SearchResultItem
                            graphViewProvider={graphViewProvider}
                            location={location}
                            browseLanguage={browseLanguage}
                            settings={settings}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            resource={r}
                            ontology={ontology}
                            onItemFocus={onItemFocus}
                            viewMode={viewMode}
                            viewProperties={[]}
                            configurations={configurations}
                        />
                    </Grid>;
                })
            }
        </>;
    }
}

SearchResult.propTypes = {
    graphViewProvider: PropTypes.any,
    configurations: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    searchResult: PropTypes.object,
    onItemFocus : PropTypes.func,
    viewMode: PropTypes.string,

};

export default withStyles(styles, {withTheme:true})(SearchResult);

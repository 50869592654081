export const LANGUAGES = [
    {value: 'ar', label: 'Arabic'},
    {value: 'zh', label: '中国人'},
    {value: 'nl', label: 'Dutch; Flemish'},
    {value: 'en', label: 'English'},
    {value: 'fr', label: 'français'},
    {value: 'de', label: 'Deutsch'},
    {value: 'it', label: 'italiano'},
    {value: 'ja', label: '日本'},
    {value: 'ko', label: '한국인'},
    {value: 'nb', label: 'Norwegian Bokmål'},
    {value: 'nn', label: 'Norwegian Nynorsk'},
    {value: 'fa', label: 'Persian'},
    {value: 'pt', label: 'Português'},
    {value: 'pa', label: 'ਪੰਜਾਬੀ'},
    {value: 'ru', label: 'русский'},
    {value: 'es', label: 'Español'},
    {value: 'sv', label: 'svenska'},
]
import React from "react";
import GlobalsContext from "../../components/GlobalsContext";
import {
    centerVertically,
    getDataContextURL,
    getLocalName,
    getPropertyName,
    getUiLabelTranslationFromContext,
    isBlankNodeId,
    isEmptyArray,
    isObjectOnly,
    isPreconditionFailed,
    isRequestSuccessful,
    sort,
    toArray
} from "../../components/util";
import {getResourceUrl, getValuesObject} from "./SearchResultItem";
import {
    getUiLabelTranslation,
    UI_LABELS_BULK_EDIT_PROPERTY,
    UI_LABELS_DELETE_RESOURCE,
    UI_LABELS_NO_LABEL_AVAILABLE,
    UI_LABELS_REMOVE_CONNECTION,
    UI_LABELS_UPDATE_FAILED,
    UI_LABELS_UPDATE_PRECONDITION_FAILED
} from "./UILabel";
import {
    ALIAS_SH_CLASS,
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    AT_ID,
    EVENT_TYPE_RESOURCE_DELETE,
    HTTP_METHOD_DELETE,
    ID,
    MEDIA_TYPE_JSON_LD,
    TYPE,
    TYPE_RDF_LIST
} from "../../Constants";
import PropTypes from "prop-types";
import {
    clearResourceAndPublishDeleteEvent,
    createNodeInEditStateEvent,
    isNodeInEditStateEvent,
    loadResourceAndGet,
    registerUpdateEventListener,
    reloadResourceAndPublishRefreshEvent,
    withEvent
} from "./Event";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {Avatar, Chip, IconButton, LinearProgress, Tooltip, Typography} from "@material-ui/core";
import {
    ClearAllOutlined,
    DeleteOutlined,
    ExpandLess,
    LabelOffOutlined,
    LinkOffOutlined,
    ListAltOutlined,
    PageviewOutlined
} from "@material-ui/icons";
import LinkResourceDialog from "./LinkResourceDialog";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import NodeContainer, {NODE_ID_KEY, patchResourceAndHandleMerge, VALUE_NODE_WIDTH} from "./NodeContainer";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import H4Title from "../../components/H4Title";
import Tree, {getRootStyle, isInViewProperties} from "./Tree";
import {loadResource} from "../../service/data-loader";
import uuid4 from "uuid/v4";
import {isString} from "lodash";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import {updateGraph} from "../../service/graph-api";
import {PermissionService, withPermissions} from "../../service/permission-service";
import {getResourceViewOnClickURL} from "./DataViewSetup";
import cloneDeep from "lodash/cloneDeep";
import {isLinkedResourceViewTypeWithoutIRI} from "./BasicSetup";

export function getObjectLink(url, error, theme, iconStyle = {}) {
    return <a style={{cursor: 'pointer'}} datatest={'link'}
              href={url} target={"_blank"}> {
        centerVertically(error ?
            <OpenInNewOutlinedIcon
                fontSize={'small'}
                style={{
                    margin: '0px 16px',
                    color: theme.palette.link.main,
                    ...iconStyle
                }}
            />
            : <PageviewOutlined
                fontSize={'small'}
                style={{
                    margin: '0px 16px',
                    color: theme.palette.link.main,
                    ...iconStyle
                }}
            />)
    }
    </a>;
}

export function renderNodeValueMarkup(thumbnail, title, theme, url, resourceId, error, valueObject, loading) {
    let maxWidthForDetails = VALUE_NODE_WIDTH - 160;
    if(thumbnail) {
        maxWidthForDetails = (maxWidthForDetails - theme.spacing(7)) - 40;
    }
    return <React.Fragment>
        {
            thumbnail &&
            centerVertically(
                <Avatar
                    alt={title}
                    src={thumbnail}
                    style={{width: theme.spacing(7), height: theme.spacing(7)}}
                />,
                {margin: '0px 16px'}
            )

        }
        {
            centerVertically(
                <div datatest={'withIRI'}>

                    <div style={{display: 'flex'}}>
                        {
                            centerVertically(
                                <div>
                                    {
                                        url ?
                                        getObjectLink(url, error, theme)
                                            : <ClearAllOutlined fontSize={'small'}
                                                                style={{
                                                                    margin: '0px 16px',
                                                                    color: theme.palette.secondary.main
                                                                }} />
                                    }
                                </div>
                            )
                        }
                        {
                            centerVertically(
                                <Tooltip title={resourceId}>
                                    <Typography
                                        style={{maxWidth: `${maxWidthForDetails}px`}}
                                        noWrap={true}
                                        color={'textPrimary'}
                                    >{getLocalName(resourceId, true, 20)}</Typography>
                                </Tooltip>
                            )
                        }
                    </div>

                    {
                        title && <div style={{marginTop: '8px', display: 'flex'}}>
                            {centerVertically(<LabelOutlinedIcon color={'secondary'} style={{margin: '0px 16px'}}/>)}
                            {centerVertically(<H4Title style={{maxWidth: `${maxWidthForDetails}px`}} noWrap={true}>{title}</H4Title>)}
                        </div>
                    }
                    {
                        error && <div style={{marginTop: '8px', display: 'flex'}}>
                            {centerVertically(<LabelOffOutlined color={'secondary'} style={{margin: '0px 16px'}}/>)}
                            {centerVertically(<Typography noWrap={true} style={{maxWidth: `${maxWidthForDetails}px`}} color={'secondary'}>{error}</Typography>)}
                        </div>
                    }
                    {((!valueObject && !error) || loading) && <LinearProgress color={'secondary'}/>}
                </div>, {flexGrow : '1'}

            )
        }</React.Fragment>;
}

export function renderNodeValueMarkupWithoutIRI(thumbnail, title, theme, url, resourceId, error, valueObject, loading) {
    let maxWidthForDetails = VALUE_NODE_WIDTH - 160;
    if(thumbnail) {
        maxWidthForDetails = (maxWidthForDetails - theme.spacing(7)) - 40;
    }
    return <React.Fragment>

        {
            thumbnail &&
            centerVertically(
                <Avatar
                    alt={title}
                    src={thumbnail}
                    style={{width: theme.spacing(4), height: theme.spacing(4)}}
                />,
                {margin: '0px'}
            )

        }
        {
            centerVertically(
                <div datatest={'withoutIRI'}>


                    {
                        (title || resourceId) && <div style={{ display: 'flex'}}>
                            {url && getObjectLink(url, error, theme)}
                            {centerVertically(<H4Title style={{maxWidth: `${maxWidthForDetails}px`}} noWrap={true}>{title || resourceId}</H4Title>)}
                        </div>
                    }
                    {
                        error && <div style={{ display: 'flex'}}>
                            {centerVertically(<LabelOffOutlined color={'secondary'} style={{margin: '0px 16px'}}/>)}
                            {centerVertically(<Typography noWrap={true} style={{maxWidth: `${maxWidthForDetails}px`}} color={'secondary'}>{error}</Typography>)}
                        </div>
                    }
                    {((!valueObject && !error) || loading) && <LinearProgress color={'secondary'}/>}
                </div>, {flexGrow : '1'}

            )
        }</React.Fragment>;
}

class WithShowHide extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: props.show === undefined ?  true : props.show,
            [NODE_ID_KEY] : uuid4()
        }
    }

    componentDidMount() {
        let {registerForEvents} = this.props;
        registerForEvents(this.onUpdateEvent);
    }

    onUpdateEvent = (event) => {
        if(isNodeInEditStateEvent(event)) {
            let {nodeId, isInEditState} = this.state;
            if(event[NODE_ID_KEY] !== nodeId && isInEditState === true) {
            }
        }
    }

    handleOpenLink = () => {
        this.setState({openLink : true});
        let {nodeId} = this.state;
        let {publishEvent} = this.props;
        publishEvent(createNodeInEditStateEvent(nodeId));
    }

    onFocus = () => {
        let {settings, browseLanguage, customizations, permissionService, parentObject} = this.props;
        let showEditActions = !(customizations?.readOnly === true) && permissionService?.canUpdateResource(parentObject);

        return showEditActions && <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_BULK_EDIT_PROPERTY, browseLanguage, UI_LABELS_BULK_EDIT_PROPERTY)}>
            <IconButton
                datatest={'bulkEdit'}
                tabIndex={0}
                style={{marginLeft : '8px'}} size={'small'}
                onClick={this.handleOpenLink}
            >
                <ListAltOutlined/>
            </IconButton>
        </Tooltip>;
    }

    closeLinkDialog = () => {
        this.setState({openLink : false});
    }


    render() {
        let {show, openLink, updateFailed} = this.state;
        let {location, customizations, permissionService, shapeProperty, fragmentKey, length, objectKey, theme, aliasesToIRIMap, aliasesMap, nodeValue, nodeSubTree, keyTitle, parentObject, property, settings, ontology, browseLanguage, configurations} = this.props;
        let nodeActions = <div style={{display : 'flex'}}>
            {centerVertically(<Chip datatest={'countChip'} variant={"outlined"} size={'small'} title={length} color={'secondary'} label={length} />)}
            {
                updateFailed &&
                <AlertSnackbarContent
                    onClose={() => this.setState({updateFailed : undefined})}
                    variant={'error'}
                    open={true}
                    autoHide={true}
                    message={updateFailed}
                />
            }
            {
                openLink &&
                <LinkResourceDialog
                    onClose={this.closeLinkDialog}
                    onSaveSuccess={this.closeLinkDialog}
                    onSaveFailure={(updateFailed) => this.setState({updateFailed})}
                    sourceResource={parentObject}
                    linkProperty={aliasesMap[property] || property}
                    settings={settings}
                    ontology={ontology}
                    configurations={configurations}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={browseLanguage}
                    location={location}
                    shapeProperty={shapeProperty}
                />
            }
            <IconButton
                datatest={'expandNodeButton'}
                style={{marginLeft : '4px'}}
                size={'small'}
                color={'primary'}
                onClick={() => this.setState({show: !show})}
            >{show ? <ExpandLess/> : <ExpandMoreIcon></ExpandMoreIcon>}</IconButton>
        </div>;


        let nodeSubTree1 = show === true ? nodeSubTree : undefined;
        let resourceId = parentObject?.[ID];
        return <React.Fragment key={show+""}>
            <NodeContainer
                customizations={customizations}
                settings={settings}
                browseLanguage={browseLanguage}
                ontology={ontology}
                fragmentKey={fragmentKey}
                propertyKey={objectKey}
                theme={theme}
                aliasesToIRIMap={aliasesToIRIMap}
                aliasesMap={aliasesMap}
                location={location}
                nodeValue={nodeValue}
                nodeActions={nodeActions}
                nodeFocusActionsProvider={!(customizations?.readOnly === true) && permissionService.canUpdateResource(parentObject) ? this.onFocus : undefined}
                nodeSubTree={nodeSubTree1}
                propertyKeyTitle={keyTitle}
                context={this.props.context}
            />
        </React.Fragment>;
    }
}

WithShowHide.propTypes = {
    customizations: PropTypes.object,
    permissionService : PropTypes.instanceOf(PermissionService),
    registerForEvents : PropTypes.func,
    publishEvent : PropTypes.func,
    theme: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    configurations: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    aliasesMap: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    parentObject: PropTypes.object,
    property: PropTypes.any,
    shapeProperty: PropTypes.object,
    context: PropTypes.object,

}

WithShowHide = withEvent(withPermissions(WithShowHide))

export async function patchResourceForLinkOff(parentObject, property, resourceId, publishEvent, settings, browseLanguage) {
    let patchResourceId = parentObject[ID];
    let newValues = toArray(parentObject[property]).filter(id => id !== resourceId)
    let patchPayload = {
        [AT_CONTEXT]: getDataContextURL(),
        [ID]: patchResourceId,
        [ALIAS_SYS_ETAG]: parentObject[ALIAS_SYS_ETAG],
        [property]: isEmptyArray(newValues) ? null : newValues
    }
    let result = await patchResourceAndHandleMerge(patchPayload, publishEvent, settings, browseLanguage);
    await reloadResourceAndPublishRefreshEvent(publishEvent, resourceId);
    return result;
}

export async function deleteResource(resourceId, publishEvent, parentObject, settings, browseLanguage) {
    let resourceToDelete = await loadResourceAndGet(resourceId);
    if(!resourceToDelete) {
        let uiLabelTranslation = getUiLabelTranslation(settings, UI_LABELS_UPDATE_FAILED, browseLanguage, UI_LABELS_UPDATE_FAILED);
        this.setState({updateFailed: uiLabelTranslation});
        return uiLabelTranslation;
    }
    let payload = {
        [AT_CONTEXT]: getDataContextURL(),
        [ID]: resourceToDelete[ID],
        [ALIAS_SYS_ETAG]: resourceToDelete[ALIAS_SYS_ETAG]
    }
    let response = await updateGraph(payload, MEDIA_TYPE_JSON_LD, HTTP_METHOD_DELETE).catch(e => {
    });
    if (isRequestSuccessful(response)) {
        await reloadResourceAndPublishRefreshEvent(publishEvent, parentObject[ID]);
        await clearResourceAndPublishDeleteEvent(publishEvent, resourceId);
    } else if (isPreconditionFailed(response)) {
        await reloadResourceAndPublishRefreshEvent(publishEvent, parentObject[ID]);
        await reloadResourceAndPublishRefreshEvent(publishEvent, resourceId);
        let uiLabelTranslation1 = getUiLabelTranslation(settings, UI_LABELS_UPDATE_PRECONDITION_FAILED, browseLanguage, UI_LABELS_UPDATE_PRECONDITION_FAILED);
        this.setState({
            updateFailed: uiLabelTranslation1
        });
        return uiLabelTranslation1;
    } else {
        let uiLabelTranslation = getUiLabelTranslation(settings, UI_LABELS_UPDATE_FAILED, browseLanguage, UI_LABELS_UPDATE_FAILED);
        this.setState({updateFailed: uiLabelTranslation});
        return uiLabelTranslation;
    }
}

class ObjectSummaryNode extends React.Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {
            expanded : false
        }
    }

    componentDidMount() {
        registerUpdateEventListener(this, this.onEvent)
    }

    onEvent = () => {

    }

    expandObject = async () => {
        this.setState({ expanded : true});
    }

    showExpandCollapse = () => {
        let {error, viewProperties, aliasesMap, resource, settings} = this.props;
        if(error) {
            return false;
        }
        if(resource) {
            let found = Object.keys(resource).find(k => isInViewProperties(viewProperties, aliasesMap, k, settings))
            if(found) {
                return true;
            }
        }
        return false;
    }

    canEditNode = () => {
        let {permissionService, customizations, resource, nodeDataEditOnFocus} = this.props;
        if(customizations?.readOnly === true) {
            return false;
        }
        if(!resource || !permissionService) {
            return false;
        }
        if(permissionService.canUpdateResource(resource) === false &&  permissionService.canDeleteResource(resource) === false) {
            return false;
        }
        return true;
    }


    onNodeFocus = () => {
        let {resourceId, publishEvent, resource} = this.props;
        let {parentObject, property, settings, browseLanguage, permissionService, customizations} = this.props;
        let canDelete = resource && permissionService.canDeleteResource(resource);
        let canUpdate = permissionService.canUpdateResource(parentObject);
        if(customizations?.readOnly === true || (canUpdate !== true && canDelete !== true)) {
            return undefined;
        }
        return <>
            { canDelete &&
                <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_DELETE_RESOURCE, browseLanguage, UI_LABELS_DELETE_RESOURCE)}>
                    <IconButton datatest={'deleteResourceButton'} size={'small'} onClick={async () => {
                        return await deleteResource.call(this, resourceId, publishEvent, parentObject, settings, browseLanguage);
                    }}>
                        <DeleteOutlined/>
                    </IconButton>
                </Tooltip>
            }
            {
                canUpdate &&
                <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_REMOVE_CONNECTION, browseLanguage, UI_LABELS_REMOVE_CONNECTION)}>
                    <IconButton datatest={'removeLinkButton'} size={'small'} onClick={async () => {
                        this.setState({loading: true});
                        let result = await patchResourceForLinkOff(parentObject, property, resourceId, publishEvent, settings, browseLanguage);
                        if(result.updateFailed || result.errors) {
                            this.setState({updateFailed : result.updateFailed, errors : result.errors });
                        }
                        this.setState({loading: false});
                    }}>
                        <LinkOffOutlined/>
                    </IconButton>
                </Tooltip>
            }
        </>;

    }

    render() {
        let {resourceId, resource, aliasesMap, viewProperties, configurations,ontology, theme, aliasesToIRIMap, browseLanguage, settings, location, parentObject, propertyKeyInParentObject, indexInParentArray, depth } = this.props;

        let {valueObject, error, customizations} = this.props;
        let {expanded, updateFailed, loading} = this.state;
        let title = valueObject?.title;
        let thumbnail = valueObject?.thumbnail;
        //If there is error means resource is external link
        let url = error
            ? resourceId
            : isBlankNodeId(resourceId)
                ? undefined
                : resource
                    ? getResourceViewOnClickURL(resource, settings, aliasesMap, location) || getResourceUrl(location, resourceId)
                    : getResourceUrl(location, resourceId);

        let nodeActions;
        if(this.showExpandCollapse()) {
            nodeActions = expanded === true ?
                <IconButton
                    datatest={'expandLessButton'}
                    onClick={() => this.setState({expanded: false})}
                    variant={'text'} size={'small'}
                ><ExpandLess/></IconButton>
                : <IconButton
                    datatest={'expandMoreButton'}
                    onClick={this.expandObject}
                    variant={'text'} size={'small'}
                ><ExpandMoreIcon/></IconButton>;
        }

        let nodeValue = <>
            {
                updateFailed &&
                <AlertSnackbarContent
                    onClose={() => this.setState({updateFailed : undefined})}
                    variant={'error'}
                    open={true}
                    autoHide={true}
                    message={updateFailed}
                />
            }
            {isLinkedResourceViewTypeWithoutIRI(settings)
                ? renderNodeValueMarkupWithoutIRI(thumbnail, title, theme, url, resourceId, error, valueObject, loading)
                : renderNodeValueMarkup(thumbnail, title, theme, url, resourceId, error, valueObject, loading)}
        </>;

        let nodeSubTree = expanded === true && <Tree
            customizations={customizations}
            resource={resource}
            ontology={ontology}
            location={location}
            settings={settings}
            aliasesToIRIMap={aliasesToIRIMap}
            browseLanguage={browseLanguage}
            configurations={configurations}
            aliasesMap={aliasesMap}
            viewProperties={viewProperties}
            depth={depth + 1}
            theme={theme}
        />

        return <React.Fragment key={expanded+""}>
            <NodeContainer
                customizations={customizations}
                settings={settings}
                browseLanguage={browseLanguage}
                ontology={ontology}
                fragmentKey={resourceId}
                propertyKey={null}
                theme={theme}
                aliasesToIRIMap={aliasesToIRIMap}
                aliasesMap={aliasesMap}
                location={location}
                nodeValue={nodeValue}
                nodeActions={nodeActions}
                nodeFocusActionsProvider={isBlankNodeId(resourceId) || this.onNodeFocus() === undefined ? undefined : this.onNodeFocus}
                nodeContainerStyle={{
                    minHeight : isLinkedResourceViewTypeWithoutIRI(settings) ? undefined : '72px',
                }}
                nodeSubTree={nodeSubTree}
                context={this.props.context}
            />
        </React.Fragment>;

    }
}


ObjectSummaryNode.propTypes = {
    customizations: PropTypes.object,
    permissionService: PropTypes.instanceOf(PermissionService),
    theme: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    configurations: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    aliasesMap: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    resourceId: PropTypes.string,
    resource: PropTypes.object,
    valueObject: PropTypes.object,
    error: PropTypes.any,
    parentObject: PropTypes.object,
    property: PropTypes.any,
    depth: PropTypes.any,
    indexInParentArray: PropTypes.any,
    viewProperties: PropTypes.any,
    context: PropTypes.any

};

ObjectSummaryNode = withEvent(withPermissions(ObjectSummaryNode));

class WithObjectsSummary extends React.Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {
            resourcesMap: {},
            valueObjectsMap: {},
            resourceErrorMap: {},
            show : false
        }
    }

    onEvent = (event) => {
        let {parentObject, property, publishEvent} = this.props;
        let {resourcesMap, valueObjectsMap, resourceErrorMap } = this.state;
        let resourceIds = toArray(parentObject[property]);
        let deletedResourceId = event.payload?.[ID];
        if(event[TYPE] === EVENT_TYPE_RESOURCE_DELETE && deletedResourceId && resourceIds.includes(deletedResourceId)) {
            reloadResourceAndPublishRefreshEvent(publishEvent, parentObject[ID]).then(() => {
                this.loadResource(undefined, deletedResourceId).then(() => {
                    reloadResourceAndPublishRefreshEvent(publishEvent, deletedResourceId).then(() => {
                        this.setState({});
                    })
                });
            });
        } else {
            let handleDeletedResources1 = this.handleDeletedResources(resourceIds, resourcesMap);
            let handleDeletedResources2 = this.handleDeletedResources(resourceIds, valueObjectsMap);
            let handleDeletedResources3 = this.handleDeletedResources(resourceIds, resourceErrorMap);
            let toLoad = [...new Set([...handleDeletedResources1, ...handleDeletedResources2, ...handleDeletedResources3])];
            this.loadResources(toLoad, toLoad.length + 1);
        }
    }

    handleDeletedResources = (resourceIds, obj) => {
        Object.keys(obj).forEach(k => {
            if(!resourceIds.includes(k)) {
                delete obj[k];
            }
        });
        let rem = Object.keys(obj);
        return resourceIds.filter(id => rem.includes(id) === false);
    }

    componentDidMount() {
        registerUpdateEventListener(this, this.onEvent)
        let {parentObject, property} = this.props;
        let resourceIds = parentObject[property];
        this.loadResources(resourceIds);
    }

    loadResources = (resourceIds, batchSize = 100) => {
        let {resourceErrorMap} = this.state;

        let resourceIdStringArray = toArray(resourceIds)
            .map(id => isObjectOnly(id) ? id[ID] : id)
            .filter(id => isString(id));

        let resourceIdsArray = resourceIdStringArray.filter(id => isBlankNodeId(id) === false);
        let resourceBNodeIdsArray = resourceIdStringArray.filter(id => isBlankNodeId(id) === true);
        let {resourcesMap, valueObjectsMap} = this.state;
        resourceBNodeIdsArray.forEach(id => {
            let bNodeObject = toArray(resourceIds).find(idObj => idObj[ID] === id);
            valueObjectsMap[id] = { [ID] : id};
            resourcesMap[id] = bNodeObject;
        })
        this.setState({});

        for (let i = 0,j = resourceIdsArray.length; i < j; i += batchSize) {
            let batch = resourceIdsArray.slice(i, i + batchSize);
            loadResource(batch).then( (results) => {
                let allValues = results.map(async (resource, index) => {
                    let resourceId = batch[index];
                    await this.loadResource(resource, resourceId);
                });
                Promise.all(allValues).then(v => {
                    this.setState({});
                });
            }).catch((r) => {
                batch.forEach(id => {
                    console.log('Resource load failed', r);
                    resourceErrorMap[id] = 'Resource load failed. Status '+r;
                });
                this.setState({});
            })
        }

    }


    loadResource = async (resource, resourceId) => {
        let {settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let {valueObjectsMap, resourceErrorMap, resourcesMap} = this.state;

        if (resource) {
            let valuesObject = await getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology);
            valuesObject[TYPE] = resource[TYPE];
            valueObjectsMap[resourceId] = valuesObject;
            resourcesMap[resourceId] = resource;
        } else {
            delete valueObjectsMap[resourceId];
            delete resourcesMap[resourceId];
            resourceErrorMap[resourceId] = getUiLabelTranslationFromContext(this, UI_LABELS_NO_LABEL_AVAILABLE);
        }
    }

    render() {
        let { configurations, customizations, viewProperties, shapeProperty, ontology, depth, parentObject, property, theme, aliasesToIRIMap, aliasesMap, browseLanguage, settings, location, expand} = this.props;
        let {valueObjectsMap, resourceErrorMap, resourcesMap} = this.state;
        let resourceIds = toArray(parentObject[property]).map(v => {
            return v && v[ID] ? v[ID] : v;
        });

        let rootStyle = getRootStyle(theme);

        let resourceIdsArray = isEmptyArray(Object.keys(valueObjectsMap))
            ? toArray(resourceIds)
            : shapeProperty?.[ALIAS_SH_CLASS] === TYPE_RDF_LIST ? Object.keys(valueObjectsMap)  :  sort(Object.keys(valueObjectsMap).map(k => valueObjectsMap[k])).map(v => v[ID]);
        if(resourceErrorMap) {
            Object.keys(resourceErrorMap).filter(id => resourceIdsArray.includes(id) === false).map(k => resourceIdsArray.push(k));
        }

        if(isEmptyArray(resourceIdsArray)) {
            return <></>;
        }

        let nodeSubTree = resourceIdsArray.map((r, index) => {
            let valueObject = valueObjectsMap[r];
            let error = resourceErrorMap[r];
            let context = cloneDeep(this.props.context);
            context.dataValue = r;
            context[TYPE] =  AT_ID;
            context.valueIndexInArray = index;
            return <div key={r+index} style={rootStyle}>
                <ObjectSummaryNode
                    customizations={customizations}
                    resourceId={r}
                    resource={resourcesMap[r]}
                    valueObject={valueObject}
                    error={error}
                    browseLanguage={browseLanguage}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    settings={settings}
                    location={location}
                    theme={theme}
                    parentObject={parentObject}
                    depth={depth}
                    property={property}
                    indexInParentArray={index}
                    ontology={ontology}
                    configurations={configurations}
                    viewProperties={viewProperties}
                    context={context}

                />
            </div>;
        });


        return <WithShowHide
            customizations={customizations}
            length={resourceIdsArray.length}
            keyTitle={getPropertyName(aliasesToIRIMap, property, ontology, browseLanguage)}
            objectKey={property}
            fragmentKey={property}
            theme={theme}
            aliasesToIRIMap={aliasesToIRIMap}
            nodeSubTree={nodeSubTree}
            show={customizations?.expand === false ? false : (viewProperties.length > 0 || expand === true)}
            parentObject={parentObject}
            property={property}
            shapeProperty={shapeProperty}
            ontology={ontology}
            browseLanguage={browseLanguage}
            configurations={configurations}
            aliasesMap={aliasesMap}
            settings={settings}
            location={location}
            context={this.props.context}
        />;
    }

}

WithObjectsSummary.propTypes = {
    customizations: PropTypes.object,
    theme: PropTypes.object,
    location: PropTypes.object,
    shapeProperty: PropTypes.object,
    settings: PropTypes.object,
    configurations: PropTypes.any,
    aliasesToIRIMap: PropTypes.object,
    aliasesMap: PropTypes.object,
    parentObject: PropTypes.object,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    property: PropTypes.any,
    viewProperties: PropTypes.array,
    depth: PropTypes.any,
    expand : PropTypes.bool,
    context : PropTypes.bool
};

export default withEvent(withStyles(styles, {withTheme: true})(WithObjectsSummary));


import React from 'react';
import PropTypes from "prop-types";
import H2Title from "./H2Title";

let InstructionForAction = props => {
    let color = props.color ? props.color : 'initial';
    return (<H2Title color={color} style={{marginTop: '100px', textAlign : 'center', ...props.style}} title={props.text}>{props.children}</H2Title>);
}

InstructionForAction.propTypes = {
    color: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.object
};

export default InstructionForAction;

import React from "react";
import {FormControlLabel, withStyles} from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
    getMockValueFromDefaultRegex,
    getPropertyValueForForm,
    isRequiredProperty,
    registerMock
} from "../../components/util";
import {EVENT_SHACL_VALIDATE} from "../../Constants";
import {validateFromSHACL} from "./validation-util";
import ErrorMessage from "../ErrorMessage";

let VALUES = ['true', 'false', 'undefined']

class Boolean extends React.Component {
    constructor(props) {
        super(props)
        let value = getPropertyValueForForm(props)

        this.state = {
            value: value ? value : null
        }
    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let mocking = this.context;
        let {property, customizations, valueIndex} = this.props;
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName);
        }

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? mocking.getMockValue(property)
            : getMockValueFromDefaultRegex(property)

        this.updatePropertyValue(mockValue)
    }

    validate = (eventName) => {
        let {property, customizations, valueIndex} = this.props;

        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    updatePropertyValue = (value) => {
        let {property, onChange} = this.props
        this.setState({value: value})
        if(value === 'true' || value === 'false') {
            property.value = value
        } else {
            delete property.value
        }
        this.validate(EVENT_SHACL_VALIDATE);
        onChange()
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {label, property} = this.props
        let {value, validationError} = this.state
        return <div style={{marginLeft : '8px', marginTop : '6px'}}>
            <RadioGroup ref={this.setRef} row onChange={(event) => {
                let value = event.target.value
                this.updatePropertyValue(value)
            }} value={value}>
                <FormControlLabel
                    value={VALUES[0]}
                    control={<Radio/>}
                    label="True"
                    labelPlacement="end"
                />
                <FormControlLabel
                    value={VALUES[1]}
                    control={<Radio/>}
                    label="False"
                    labelPlacement="end"
                />
                {
                    !isRequiredProperty(property) && <FormControlLabel
                        value={VALUES[2]}
                        control={<Radio/>}
                        label="No Value"
                        labelPlacement="end"
                    />
                }
            </RadioGroup>
            {validationError && <ErrorMessage error={validationError}/>}
        </div>;
    }
}

export default withStyles({}, {withTheme: true})(Boolean);

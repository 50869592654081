import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../components/styles";
import H3Title from "./H3Title";

function Label(props) {
    let {error, label, theme, noWrap} = props
    let style = {
        color: error ?  theme.palette.warning.main : theme.palette.primary.main
    };
    return label ? <H3Title noWrap={noWrap} style={style} title={label}/> : <></>;
}

Label.propTypes = {
    required: PropTypes.bool,
    error: PropTypes.string,
    label: PropTypes.any,
    noWrap: PropTypes.bool

};

export default withStyles(styles, {withTheme: true})(Label);

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import TextField from "../components/TextField";
import {Grid} from "@material-ui/core";
import {styles} from "./styles";

function KeyValue(props) {
    const{label, value, readOnly} = props
    return <>
        <Grid item xs={4}>
            <TextField
                datatest={'textField-paramName'}
                paperStyle={{padding: '0'}}
                fullWidth={true}
                value={label}
                readOnly={readOnly}
            />
        </Grid>
        <Grid item xs={8}>
            <TextField
                datatest={'textField-'+label}
                paperStyle={{padding: '0'}}
                value={value}
                readOnly={readOnly}
            />
        </Grid>
    </>;
}

KeyValue.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
};

export default withStyles(styles)(KeyValue);
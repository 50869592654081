import React from 'react';
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";
import {STYLE_GRID_ITEM_SPACING, STYLE_MAIN_SPACING} from "../Constants";

const styles = theme => ({});

let GridContainer = props => {
    const {children, theme, style, noSpacing} = props;
    let itemPadding = theme.spacing(STYLE_MAIN_SPACING) - (theme.spacing(STYLE_GRID_ITEM_SPACING) / 2 )
    let computedStyle = {
        margin: 0,
        flexGrow: 0,
        maxWidth: '100%',
        flexBasis: '100%',
        padding: itemPadding,
    }
    if(noSpacing) {
        delete computedStyle.margin
        delete computedStyle.padding
    }
    computedStyle = {
        ...computedStyle,
        ...style
    }
    return (<Grid container spacing={STYLE_GRID_ITEM_SPACING} style={computedStyle}>{children}</Grid>);
}

GridContainer.propTypes = {
    style: PropTypes.object,
    noSpacing: PropTypes.bool,

};

export default withStyles(styles, {withTheme: true})(GridContainer);

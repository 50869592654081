import React from 'react';
import PropTypes from "prop-types";
import {isArray} from 'lodash';

let ReactKeyByIndex = props => {
    const {data} = props;
    if(isArray(data)) {
        return data.map((o, index) => <React.Fragment key={index}>{o}</React.Fragment>)
    } else {
        return data;
    }
}
ReactKeyByIndex.propTypes = {
    data: PropTypes.any
};

export default ReactKeyByIndex;

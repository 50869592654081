import React from "react";
import {loadResource} from "../../service/data-loader";
import {centerVertically, GetEditableClassNames, getResourceId, toArray} from "../../components/util";
import {getResourceUrl, getValuesObject} from "./SearchResultItem";
import {getObjectLink} from "./WithObjectSummary";
import H4Title from "../../components/H4Title";
import {getResourceViewOnClickURL} from "./DataViewSetup";
import PropTypes from "prop-types";
import {withEvent} from "./Event";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withPermissions} from "../../service/permission-service";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import {TYPE} from "../../Constants";
import {Avatar, Tooltip} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {SETTINGS_EDIT_CARD_IMAGE, SETTINGS_EDIT_CARD_TITLE} from "./Workspace";
import {WithAdminEdit} from "./WithAdminEdit";

export function renderSimpleObject(valueObject, url, theme, containerStyle = {}, disableTypes=false) {
    const title = valueObject.title || getResourceId(valueObject) ;
    const thumbnailMargin = {margin: '0px  8px 0px 0px'};
    return <div style={{ overflow : 'auto', maxWidth : '100%', marginBottom: '8px', display: 'flex', ...containerStyle}}>
        {url && centerVertically(getObjectLink(url, false, theme, {margin : '0px 8px 0px 0px'}), {paddingTop : '4px'})}
        <Tooltip title={getResourceId(valueObject.backingObject)}>
            <div style={{display : 'flex'}} datatest={'simpleObjectTitle'}>
            {
                centerVertically(
                    <div datatest={'simpleObjectImage'} className={GetEditableClassNames(SETTINGS_EDIT_CARD_IMAGE, getResourceId(valueObject))}>
                        <WithAdminEdit
                            style={{marginTop :'-16px', marginLeft :'-8px'}}
                            data={{
                                action: SETTINGS_EDIT_CARD_IMAGE,
                                className : 'editableComponentHighlightOffsetIn3',
                                id : getResourceId(valueObject),
                                resource : valueObject.backingObject
                            }}
                        />
                        {
                            !valueObject.thumbnail
                                ? <LabelOutlinedIcon color={'secondary'} style={thumbnailMargin}/>
                                : <Avatar
                                    alt={title}
                                    src={valueObject.thumbnail}
                                    style={{width: theme.spacing(3), height: theme.spacing(3), ...thumbnailMargin}}
                                />
                        }
                    </div>
                )
            }

            {centerVertically(<H4Title datatest={'simpleObjectTitleText'} noWrap={true} className={GetEditableClassNames(SETTINGS_EDIT_CARD_TITLE, getResourceId(valueObject))}>
                <WithAdminEdit
                    style={{marginTop :'0px', marginLeft : '4px'}}
                    data={{
                        action: SETTINGS_EDIT_CARD_TITLE,
                        id : getResourceId(valueObject),
                        resource : valueObject.backingObject
                    }}
                />{title}
            </H4Title>)}
            </div>
        </Tooltip>

        <div style={{flexGrow  :'1'}}></div>
        {   disableTypes === false &&
            valueObject.backingObject?.[TYPE] && toArray(valueObject.backingObject?.[TYPE]) &&
            toArray(valueObject.backingObject?.[TYPE]).map((t, index) => {
                return centerVertically(<Tooltip title={t} key={index}><Chip datatest={'type'} key={t} variant={'outlined'} size={'small'}
                                                                             label={t}/></Tooltip>, {marginLeft : '16px'}, undefined, index);
            })
        }
    </div>;
}


class ObjectNode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        const {resourceId , aliasesToIRIMap, browseLanguage, ontology, settings} = this.props;
        loadResource(resourceId).then(resources => {
            let r = toArray(resources).find(r => getResourceId(r) === resourceId);
            if(r) {
                getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
                    vo.backingObject = r;
                    this.setState({ovo: vo})
                });
            }
        })
    }

    render() {
        let {ovo} = this.state;
        let {resourceId, disableLink, theme, location, aliasesMap, settings, disableTypes} = this.props;
        if(!ovo) {
            return <div style={{display : 'flex'}}>
                {centerVertically(getObjectLink(resourceId, true, theme, {margin : '0px 8px 0px 0px'}), {paddingTop : '4px'})}
                {centerVertically(<H4Title noWrap={true} title={resourceId}></H4Title>)}
            </div>;
        }
        let url = disableLink ? undefined : getResourceViewOnClickURL(ovo.backingObject, settings, aliasesMap, location) || getResourceUrl(location, resourceId);
        return renderSimpleObject(ovo, url, theme, {marginBottom : '0px'}, disableTypes);
    }

}

ObjectNode.propTypes = {
    resourceId: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    disableTypes: PropTypes.bool,
    disableLink: PropTypes.bool,
}

export default withEvent(withStyles(styles, {withTheme: true})(withPermissions(ObjectNode)));



import React from 'react';
import PropTypes from "prop-types";

import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';
import {CardActions, CardHeader} from "@material-ui/core";
import IconButton from "../components/IconButton";
import {GreyDeleteIcon} from "../components/GreyStyleIcon";
import ConfirmDeleteDialog from "../components/ConfirmDeleteDialog";

const styles = theme => ({
    card: {
        width: 270,
    },
    cardLink: {
        textDecoration: 'none'
    },
    media: {
        height: 200,
    },
    action: {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#B83D1C',
            color: '#FFFFFF'
        }
    }
    ,
    description: {
        height: '100px',
        borderTop: `2px solid ${theme.palette.secondary.main}`,
    },
    cardTitle: {
        fontFamily: 'Rajdhani, sans-serif',
        letterSpacing: '0',
        fontSize: '24px',
        textAlign: 'left',
        overflow: "hidden",
        width: '243px'
    },
    avatar: {
        backgroundColor: 'light',
    },
});

class CollectionCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {theme, classes, url, title, description} = this.props;

        return this.getCard(theme, classes, url, title, description);
    }

    getCard = (theme, classes, url, title, description) => {
        let {focus, confirmDelete} = this.state
        let {onOpen, onDelete, deleteConfirmMessage} = this.props
        let focusColor = '#FFFFFF'
        let style = focus ? {
            cursor: 'pointer',
        } : {}
        return <>
            {
                confirmDelete &&
                <ConfirmDeleteDialog
                    open={confirmDelete}
                    title={'Are You Sure?'}
                    data={{value: `${deleteConfirmMessage || 'Do you really want to delete workspace'} '${title}'?`}}
                    handleCancel={() => this.setState({confirmDelete: false})}
                    handleOk={() => {
                        this.setState({confirmDelete: false})
                        onDelete && onDelete();
                    }}
                    okButtonTitle={'YES'}
                    cancelButtonTitle={'NO'}
                    primaryButton={'cancel'}
                />
            }
            <Card datatest={'card-'+title} className={classes.card} elevation={focus ? 8 : 1}>
                <CardActionArea onClick={onOpen} style={style}
                                onFocus={(e) => {
                                    this.setState({focus: true})
                                }}
                                onBlur={(e) => {
                                    this.setState({focus: false})
                                }}
                                onMouseEnter={(e) => this.setState({focus: true})}
                                onMouseLeave={(e) => this.setState({focus: false})}
                >
                    <CardHeader
                        style={{height: '112px', backgroundColor: theme.palette.primary.main}}
                        title={<div style={{height: '87px', overflow: "hidden"}}>
                            <Typography style={{color: theme.palette.white.main, textAlign: 'center'}}
                                           gutterBottom variant="h2" component="h2"
                                                className={classes.cardTitle}>{title}</Typography></div>}/>
                    <CardContent className={classes.description}>
                        <div style={{height: '100px', overflow: "hidden"}}>
                            <Typography style={{whiteSpace: "pre-line"}} variant="body2" color={"inherit"} component="p">{description}</Typography>
                        </div>
                    </CardContent>
                </CardActionArea>
                <CardActions>
                    {
                        onDelete &&
                        <IconButton datatest={'deleteButton'} onClick={() => this.setState({confirmDelete: true})}
                                    size="small">
                            <GreyDeleteIcon/>
                        </IconButton>
                    }
                    {this.props.actions}
                    <div style={{flexGrow: 1}}/>
                    {
                        onOpen &&
                        <IconButton datatest={'openButton'} onClick={onOpen} size="small">
                            <ArrowForwardIcon color={"secondary"}/>
                        </IconButton>
                    }
                </CardActions>
            </Card>
        </>;
    }
}

CollectionCard.propTypes = {
    deleteConfirmMessage: PropTypes.string,
    onDelete: PropTypes.func,
    onOpen: PropTypes.func,
    actions: PropTypes.array
};


export default withStyles(styles, {withTheme: true})(CollectionCard);

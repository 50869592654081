import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {withStyles} from "@material-ui/core";

export function LeftMenuIconButton({theme, minimized, icon, title, onClick, disabled}) {
    let listItemIconWidth = '36px'
    return <ListItem
        disabled={disabled === true}
        datatest={'listItem-'+title}
        button
        style={{
            color: theme.palette.white.main,
            justifyContent: 'left',
            textTransform: 'none',
            height: '40px',
            width: '100%',
            borderBottom: '1px solid #c05335',
            borderRadius: '0px',
            paddingLeft: '14px'
        }}
        onClick={onClick}
    >
        <ListItemIcon style={{minWidth: listItemIconWidth}}>
            { minimized ? <Tooltip title={title}>{icon}</Tooltip> : icon}
        </ListItemIcon>
        <ListItemText style={{color: theme.palette.white.main}} primary={(minimized ? "" : title)}/>
    </ListItem>;
}

export default withStyles({}, {withTheme: true})(LeftMenuIconButton);

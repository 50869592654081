import React, {Component, useContext} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    extractVariables,
    getRequestHeaderMapFromResponse,
    getUiLabelTranslationFromContext,
    replaceVariables
} from "./util";
import queryString from "query-string";
import {FormControlLabel, Grid, Switch, Typography} from "@material-ui/core";
import {styles} from "./styles";
import KeyValue from "./KeyValue";
import JSONEditorReact from "./JSONEditorReact";
import FieldContainer from "../components/FieldContainer";
import ErrorMessage from "../components/ErrorMessage";
import GlobalsContext from "../components/GlobalsContext";
import H4Title from "../components/H4Title";
import {
    UI_LABELS_PREVIEW_WITH_GLOBAL_VARIABLES_SUBSTITUTION, UI_LABELS_REQUEST_BODY, UI_LABELS_REQUEST_HEADERS,
    UI_LABELS_REQUEST_QUERY_PARAMETERS
} from "../layouts/navigator/UILabel";

function getTitle(theme, title, previewSwitchChecked, previewSwitchLabel, onPreviewToggle) {
    return <div style={{display: 'flex'}}>
        <H4Title title={title}/>
        <div style={{flexGrow: '1'}}/>
        {
            onPreviewToggle &&
            <FormControlLabel
                control={
                    <Switch
                        datatest={'previewVariableReplacedJSONSwitch'}
                        checked={previewSwitchChecked}
                        value={true}
                        size={"small"}
                        onChange={onPreviewToggle}
                        name="previewVariableReplacedJSONSwitch"
                    />
                }
                label={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>
                    {previewSwitchLabel}
                </Typography>}
            />
        }
    </div>;
}

export function getReplacementMessages(replaced, json) {
    return <>
        {replaced && replaced.error && (json && <ErrorMessage error={'Variables substitution failed. '+replaced.error.message}/>)}
        {replaced && replaced.missing && replaced.missing.map((m,i) => <ErrorMessage key={i} error={`No global value set for variable Name : ${m} `}/>)}
    </>;
}

class Request extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            otherTabs,
            previewReplacedBody,
            onPreviewReplacedBodyToggle,
            previewReplacedHeaders,
            onPreviewReplacedHeadersToggle,
            reqParamMap,
            reqHeaderMap,
            theme,
            reqURL,
            reqBody,
            response,
            reqBodyChange
        } = this.props;
        let requestHeaders =
            reqHeaderMap
                ? reqHeaderMap
                : getRequestHeaderMapFromResponse(response);
        let requestHeadersString = JSON.stringify(reqHeaderMap);
        let requestParams = reqParamMap ? reqParamMap : queryString.parse(reqURL.split("?")[1])
        let json = reqBody
        const globals = this.context;
        let replaced = json && previewReplacedBody ? replaceVariables(json, globals) : undefined
        let replacedHeaders = reqHeaderMap && previewReplacedHeaders ? replaceVariables(requestHeadersString, globals) : undefined
        requestHeaders = reqHeaderMap && previewReplacedHeaders ? JSON.parse(replacedHeaders.replaced) : requestHeaders
        let requestParamsKeys = Object.keys(requestParams);
        let reqHeaderMapVariables = extractVariables(requestHeadersString);
        return <FieldContainer style={{padding: '8px', backgroundColor: theme.palette.white.main}}>
            {
                requestParamsKeys && requestParamsKeys.length > 0 &&
                <FieldContainer datatest={'queryParameters'} style={{marginBottom: theme.spacing(1)}}>
                    <H4Title title={getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST_QUERY_PARAMETERS)}/>
                    <Grid container spacing={1}>
                        {requestParamsKeys.map(k => <KeyValue readOnly={true} key={k} label={k}
                                                              value={requestParams[k]}/>)}
                    </Grid>
                </FieldContainer>
            }
            <FieldContainer datatest={'requestHeaders'} style={{marginBottom: theme.spacing(1)}}>
                {
                    getTitle(
                        theme,
                        getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST_HEADERS) ,
                        (previewReplacedHeaders && previewReplacedHeaders === true ? true : false),
                        getUiLabelTranslationFromContext(this, UI_LABELS_PREVIEW_WITH_GLOBAL_VARIABLES_SUBSTITUTION),
                        reqHeaderMapVariables && Object.keys(reqHeaderMapVariables).length > 0
                            ? (e) => {
                                const {target: {checked}} = e
                                onPreviewReplacedHeadersToggle(checked)
                            }
                            : undefined
                    )
                }
                {getReplacementMessages(replacedHeaders, requestHeaders)}
                <Grid container spacing={1}>
                    {
                        requestHeaders && Object.keys(requestHeaders).map(k => <KeyValue readOnly={true} key={k}
                                                                                         label={k}
                                                                                         value={requestHeaders[k]}/>)
                    }
                </Grid>
            </FieldContainer>
            {
                json && <FieldContainer>
                    {
                        getTitle(
                            theme,
                            getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST_BODY),
                            (previewReplacedBody && previewReplacedBody === true ? true : false),
                            getUiLabelTranslationFromContext(this, UI_LABELS_PREVIEW_WITH_GLOBAL_VARIABLES_SUBSTITUTION),
                            (e) => {
                                const {target: {checked}} = e
                                onPreviewReplacedBodyToggle(checked)
                            }
                        )
                    }
                    {getReplacementMessages(replaced, json)}
                    {
                        previewReplacedBody
                            ? <JSONEditorReact
                                datatest={'requestPreview'}
                                key={"preview"}
                                modes={['code', 'view']}
                                enableSort={false}
                                text={replaced.replaced || ''}
                                indentation={4}
                                history={false}
                                height={'100%'}
                                expandAll={true}
                                search={false}
                                onEditable={() => {
                                    return false
                                }}
                            />
                            : <JSONEditorReact
                                datatest={'requestEdit'}
                                key={"edit"}
                                modes={['code', 'text', 'view']}
                                enableSort={false}
                                text={json}
                                indentation={4}
                                history={true}
                                height={'100%'}
                                expandAll={true}
                                search={false}
                                onChangeText={(val) => {
                                    if (val) {
                                        reqBodyChange(val)
                                    }
                                }}
                            />
                    }
                </FieldContainer>
            }

            {otherTabs && otherTabs.map(t => <FieldContainer>{t['TAB_COMPONENT']}</FieldContainer>)}
        </FieldContainer>;
    }
}

Request.propTypes = {
    reqBody: PropTypes.string,
    classes: PropTypes.object.isRequired,
    response: PropTypes.object,
    reqTabValue: PropTypes.number,
    reqVerb: PropTypes.string,
    reqURL: PropTypes.string,
    reqIconTooltip: PropTypes.string,
    reqTabChange: PropTypes.func.isRequired,
    reqAction: PropTypes.func.isRequired,
    reqBodyChange: PropTypes.func,
    reqIcon: PropTypes.object,
    otherTabs: PropTypes.array,
    title: PropTypes.string,
    titleActions: PropTypes.array,
    hideParamsTab: PropTypes.bool,
    hideHeadersTab: PropTypes.bool,
    hideCurlTab: PropTypes.bool,
    previewReplacedBody: PropTypes.bool,
    onPreviewReplacedBodyToggle: PropTypes.func,
    previewReplacedHeaders: PropTypes.bool,
    onPreviewReplacedHeadersToggle: PropTypes.func,
    onSave: PropTypes.func,
    reqParamMap: PropTypes.object,
    reqHeaderMap: PropTypes.object,
};

export default withStyles(styles, {withTheme:true})(Request);

import React from 'react';
import {UnControlled as CodeMirror} from 'react-codemirror2';

import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/turtle/turtle';
import './turtle.css';

function TurtleEditor({ value, readOnly }) {


  return ( <div className={'turtleCodeMirror'}>
    <CodeMirror
        value={value}
        options={{
          mode: 'turtle',
          lineNumbers: true,
          readOnly
        }}
      />
      </div>
  )
}

TurtleEditor.defaultProps = {
  readOnly: false
};

export default TurtleEditor;


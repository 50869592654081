import React from 'react';
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";

const styles = theme => ({
  title: {},
});

let H4Title = props => {
  const { title, classes, style, color, className, noWrap, datatest } = props;

  return (<Typography datatest={datatest} noWrap={noWrap} variant="h4" color={color ? color : 'primary'} style={style} className={classes.title+(className ? " "+ className : "")}>
                  {title? title : props.children}
              </Typography>);
}

H4Title.propTypes = {
  title: PropTypes.any,
  color: PropTypes.string,
  style: PropTypes.object,
  noWrap: PropTypes.bool
};

export default withStyles(styles)(H4Title);

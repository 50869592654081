import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../components/styles";
import FooterButton from "../components/FooterButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBackOutlined";

function FooterPreviousButton(props) {
    let {theme, disabled, onClick, ...other} = props
    return <FooterButton
        {...other}
        datatest={'previousButton'}
        variant={"outlined"}
        disabled={disabled}
        onClick={onClick}
        startIcon={<ArrowBackIcon/>}
    >Previous</FooterButton>;
}

FooterPreviousButton.propTypes = {
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(FooterPreviousButton);

import React, {PureComponent} from "react";
import {TextField as MUITextField} from "@material-ui/core";
import {restrictMaximumCharacters} from "./util";
import {GreyLockIcon} from "./GreyStyleIcon";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {traceRenderStart} from "./Trace";
import {ConfirmButton} from "./ConfirmButton";
import {StyledTooltip} from "./TooltipError";

const COMPONENT = 'EditableTextArea.jsx'
//this is to hide scrollbar without this global scrollbar styles add scroll
const MUITextFieldStyled = withStyles({
    root: {
        '& .MuiOutlinedInput-input::-webkit-scrollbar': {
            width : '0px',

        },
        '& .MuiOutlinedInput-multiline' : {
            paddingRight : '4px'
        }

    }
})(MUITextField);

class EditableTextArea extends PureComponent {
    constructor(props) {
        super(props);
        let {value} = props;
        this.state = {
            value : value,
            error : '',
            rows: 1
        }
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        traceRenderStart("", COMPONENT)
        let {classes, theme, label, validator, maxLength, inputLabelProps, fullWidth, readOnly, onChange} = this.props;
        let {value, error, rows} = this.state;

        return <StyledTooltip open={error ? true : false} placement={'bottom'} title={error}>
            <MUITextFieldStyled
                datatest={'textareaField-'+label}
                margin={'dense'}
                multiline={true}
                error={error ? true : false}
                rows={rows}
                fullWidth={fullWidth === undefined ? true : fullWidth}
                label={label}
                value={value}
                onChange={(event) => {
                    let newEvent = restrictMaximumCharacters(event, maxLength);
                    const {target: {name, value}} = newEvent;
                    let error = validator(value, label, maxLength);
                    this.setState({error : error, value: value});
                }}
                onFocus={() => {
                    this.setState({rows : undefined}, () => this.inputRef.focus());
                }}
                onBlur={(event) => {
                    const {target: {name, value}} = event;
                    if(value !== this.props.value) {
                        this.setState({value: this.props.value});
                    }
                    this.setState({error : '', rows : 1})
                }}
                inputProps={{
                    ref: this.setRef
                }}
                InputProps={{
                    endAdornment: readOnly
                        ? <GreyLockIcon theme={theme}/>
                        : (error || this.props.value === value)
                            ? undefined
                            : <ConfirmButton onClick={() => {
                                this.props.onConfirm(label, value);
                            }}/>
                    ,
                    readOnly: readOnly
                }}
                InputLabelProps={{shrink: true}}
            />
        </StyledTooltip>;
    }

}

EditableTextArea.propTypes = {
    readOnly: PropTypes.bool,
    value: PropTypes.string,
    label: PropTypes.string,
    fullWidth: PropTypes.bool,
    validator: PropTypes.func,
    maxLength: PropTypes.number,
    onConfirm: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(EditableTextArea);

import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
    centerVertically,
    getBrowseLanguageCode,
    getPropertyName,
    isObjectProperty,
    toArray
} from "../../components/util";
import {getLanguageLabelForSite} from "../apiplayground/SearchFilter";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ID,
    ORDER,
    ORDER_LANG,
    ORDER_NO_VALUE,
    ORDER_NO_VALUE_FIRST,
    ORDER_NO_VALUE_LAST,
    RDF_LANGSTRING,
    SH_LITERAL,
    SORT_ASCENDING,
    SORT_DESCENDING,
    TYPE
} from "../../Constants";
import {renderProperty} from "./AddDataPropertyValueDialog";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import {InputLabel, MenuItem, RadioGroup} from "@material-ui/core";
import {
    getUiLabelTranslation,
    UI_LABELS_APPLY,
    UI_LABELS_CANCEL,
    UI_LABELS_LANGUAGE,
    UI_LABELS_SORT,
    UI_LABELS_SORT_ASCENDING,
    UI_LABELS_SORT_DESCENDING,
    UI_LABELS_SORT_DIRECTION,
    UI_LABELS_SORT_EMPTY_OR_NO_VALUE,
    UI_LABELS_SORT_EMPTY_OR_NO_VALUE_ANY,
    UI_LABELS_SORT_EMPTY_OR_NO_VALUE_FIRST,
    UI_LABELS_SORT_EMPTY_OR_NO_VALUE_LAST
} from "./UILabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import LanguageSearchSelect from "../apiplayground/LanguageSearchSelect";
import Radio from "@material-ui/core/Radio";

const styles = {
    dialogPaper: {
    },
};

export const ANY = "any";

class AddSort extends Component {
    constructor(props) {
        super(props);
        let {browseLanguage, ontology, shapeProperty, sort} = props;
        let found = ontology.find(op => [ID, TYPE].includes(shapeProperty[ALIAS_SH_PATH]) || (op[ID] === shapeProperty[ALIAS_SH_PATH] && isObjectProperty(op)));
        let disableLang = found ? true : false;
        //check if shape has information to determine whether lang field is relevant or not
        if(disableLang === false) {
            if(SH_LITERAL === shapeProperty[ALIAS_SH_NODE_KIND]) {
                // do not disable
            } else {
                //Disable lang if shape property datatype is defined and is non lang
                let shapePropertyDatatype = shapeProperty?.[ALIAS_SH_DATATYPE];
                if(shapePropertyDatatype) {
                    if(shapePropertyDatatype !== RDF_LANGSTRING) {
                        disableLang = true;
                    }
                }
            }
        }


        this.state = {
            direction : sort?.[ORDER]?.value || SORT_ASCENDING,
            orderNoValue : sort?.[ORDER_NO_VALUE] || ORDER_NO_VALUE_LAST,
            orderLang : disableLang ? undefined : (sort?.[ORDER_LANG] || getBrowseLanguageCode(browseLanguage)),
            disableLang : disableLang
        }
    }

    apply = () => {
        let {onAdd} = this.props;
        let {direction, orderNoValue, orderLang} = this.state;
        onAdd(direction, orderNoValue === ANY ? undefined : orderNoValue, orderLang ? orderLang :  null);
    }

    render() {
        let {onClose, classes, settings, aliasesToIRIMap, ontology, browseLanguage, shapeProperty} = this.props;
        let {direction, orderNoValue, orderLang, disableLang} = this.state;
        const languageLabel = getUiLabelTranslation(settings, UI_LABELS_LANGUAGE, browseLanguage, UI_LABELS_LANGUAGE);

        const languageOtherProps = {
            addNameToLabel : true,
            suggestions : toArray(settings.browseLanguages).map(lv => {
                return {
                    value: lv.value,
                    label: getLanguageLabelForSite(lv)
                }
            })
        }

        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={true}
                    datatest={'addSortDialog'}
                    classes={{ paper: classes.dialogPaper }}
                    onKeyDown={(ev) => {
                        if(ev.ctrlKey && ev.key === 'Enter') {
                            this.apply();
                        } else if (ev.key === 'Escape') {
                            onClose();
                        } else if(ev.key === 'Enter') {
                            //Only stop propagation but do not preventDefault as we want to add new line in text field
                            ev.stopPropagation();
                        }
                    }}

                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <div style={{display: 'flex'}}>
                            {centerVertically(<H2Title title={getUiLabelTranslation(settings, UI_LABELS_SORT, browseLanguage, UI_LABELS_SORT) }/>)}
                            {
                                centerVertically(
                                    renderProperty(<LabelOutlinedIcon style={{marginRight: '4px'}} color={'secondary'}/>, getPropertyName(aliasesToIRIMap, shapeProperty[ALIAS_SH_PATH], ontology, browseLanguage)),
                                    {margin: '0px 24px', flexGrow : '1'}
                                )
                            }
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container xs={12} spacing={3}>
                            <Grid item xs={12}>
                                <FormControl style={{minWidth: '160px', maxWidth: '160px'}}
                                             size={'small'} variant="outlined">
                                    <InputLabel htmlFor="outlined-layout">{getUiLabelTranslation(settings, UI_LABELS_SORT_DIRECTION, browseLanguage, UI_LABELS_SORT_DIRECTION)}</InputLabel>
                                    <Select
                                        id={"outlined-layout"}
                                        datatest={'sortDirection'}
                                        value={direction}
                                        label={getUiLabelTranslation(settings, UI_LABELS_SORT_DIRECTION, browseLanguage, UI_LABELS_SORT_DIRECTION)}
                                        onChange={(event) => {
                                            this.setState({direction :  event.target.value})
                                        }}
                                        inputProps={{
                                            id: 'outlined-layout'
                                        }}
                                    >
                                        <MenuItem value={SORT_ASCENDING}>{getUiLabelTranslation(settings, UI_LABELS_SORT_ASCENDING, browseLanguage, UI_LABELS_SORT_ASCENDING)}</MenuItem>
                                        <MenuItem value={SORT_DESCENDING}>{getUiLabelTranslation(settings, UI_LABELS_SORT_DESCENDING, browseLanguage, UI_LABELS_SORT_DESCENDING)}</MenuItem>
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">{getUiLabelTranslation(settings, UI_LABELS_SORT_EMPTY_OR_NO_VALUE, browseLanguage, UI_LABELS_SORT_EMPTY_OR_NO_VALUE)}</FormLabel>
                                    <RadioGroup row={true} aria-label="orderNoValue" name="orderNoValue" value={orderNoValue} onChange={(event) => {
                                        let value = event.target.value;
                                        this.setState({orderNoValue : value});
                                    }}>
                                        <FormControlLabel value={ORDER_NO_VALUE_LAST} control={<Radio />} label={getUiLabelTranslation(settings, UI_LABELS_SORT_EMPTY_OR_NO_VALUE_FIRST, browseLanguage, UI_LABELS_SORT_EMPTY_OR_NO_VALUE_FIRST)} />
                                        <FormControlLabel value={ORDER_NO_VALUE_FIRST} control={<Radio />} label={getUiLabelTranslation(settings, UI_LABELS_SORT_EMPTY_OR_NO_VALUE_LAST, browseLanguage, UI_LABELS_SORT_EMPTY_OR_NO_VALUE_LAST)} />
                                        <FormControlLabel value={ANY} control={<Radio />} label={getUiLabelTranslation(settings, UI_LABELS_SORT_EMPTY_OR_NO_VALUE_ANY, browseLanguage, UI_LABELS_SORT_EMPTY_OR_NO_VALUE_ANY)} />
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            {
                                disableLang ? <></> :
                                <Grid item xs={12}>
                                    <LanguageSearchSelect
                                        datatest={'sortLanguage'}
                                        label={languageLabel}
                                        multiple={false}
                                        value={orderLang}
                                        onChange={(val) => {
                                            this.setState({orderLang: val});
                                        }}
                                        {...languageOtherProps}
                                    />
                                </Grid>
                            }
                        </Grid>
                        <div style={{height : '40px'}}></div>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow :'1'}}></div>
                        {
                                <Button
                                    datatest={'addButton'}
                                    variant={"contained"}
                                    color="secondary"
                                    onClick={this.apply}
                                >{getUiLabelTranslation(settings, UI_LABELS_APPLY, browseLanguage, UI_LABELS_APPLY)}</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>;
    }
}

AddSort.propTypes = {
    settings: PropTypes.any,
    sort: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    shapeProperty: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onAdd: PropTypes.any
};

export default withStyles(styles, {withTheme: true})(AddSort);


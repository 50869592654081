import React from "react";
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import {
  ROUTE_BOOTSTRAP,
  ROUTE_CONFIGURATION_MANAGER_IMPORT,
  ROUTE_MANAGEMENT_HOME,
  STYLE_GRID_ITEM_SPACING
} from "../Constants";
import withStyles from "@material-ui/core/styles/withStyles";
import history from "../history";
import {getRouteWithInstanceAndDataset} from "./util";

function ConfigurationEmptyDialog(props) {
  const {theme, open} = props

  return (<React.Fragment>{
    open && <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Configuration is empty. To get started please bootstrap or import configuration.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
            datatest={'bootstrapButton'}
            variant={"text"}
            onClick={() => history.push(getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_HOME))}
            color="secondary"
        >Home</Button>
        <div style={{flexGrow :1}}></div>
        <Button
            datatest={'bootstrapButton'}
            variant={"outlined"}
            onClick={() => history.push(getRouteWithInstanceAndDataset(ROUTE_BOOTSTRAP))}
            color="secondary"
        >Bootstrap</Button>
        <Button
            datatest={'importConfigurationButton'}
            style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}}
            variant={"outlined"}
            color="secondary"
            onClick={() => history.push(getRouteWithInstanceAndDataset(ROUTE_CONFIGURATION_MANAGER_IMPORT))}
        >Import Configuration</Button>
      </DialogActions>
    </Dialog>
  }</React.Fragment>);
}

ConfigurationEmptyDialog.propTypes = {
  open: PropTypes.bool
};

export default withStyles({}, {withTheme:true})(ConfigurationEmptyDialog);

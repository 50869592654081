import {GraphiQLProvider, ResponseEditor} from "@graphiql/react";
import {createGraphiQLFetcher} from "@graphiql/toolkit";
import {getEndpointForGraphiQL, graphQLFetcher} from "../../service/graph-api";
import React, {useEffect} from "react";
import {buildClientSchema, getIntrospectionQuery} from "graphql/index";

function GraphQLResponseViewer({response}) {
    const [schema, setSchema] = React.useState();


    useEffect(() => {
        let query = {
            "query": getIntrospectionQuery(),
            "operationName": "IntrospectionQuery"
        }
        graphQLFetcher(query).then(r => {
            let schemaToSet = buildClientSchema(r.data)
            setSchema(schemaToSet);
        })
    }, [])

    return  <GraphiQLProvider
        fetcher={createGraphiQLFetcher({url: getEndpointForGraphiQL(),})}
        schema={schema}
        response={response}
    ><ResponseEditor/>
    </GraphiQLProvider>;
}

export default GraphQLResponseViewer;

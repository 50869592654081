import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H1Title from "../../components/H1Title";
import {getUiLabelTranslation, UI_LABELS_CLOSE, UI_LABELS_HELP} from "./UILabel";
import DialogContent from "@material-ui/core/DialogContent";
import H2Title from "../../components/H2Title";
import DialogActions from "@material-ui/core/DialogActions";
import {Button} from "@material-ui/core";
import React from "react";

export function GraphViewStyleHelp({onClose, settings, browseLanguage}) {

    const getTranslation = (labelKey) => {
        return getUiLabelTranslation(settings, labelKey, browseLanguage, labelKey);
    }

    return <Dialog
        aria-labelledby="Help"
        open={true}
        fullWidth={true}
        maxWidth={'sm'}
        datatest={'helpDialog'}
    >
        <DialogTitle id="form-dialog-title">
            <H1Title id="style">{getTranslation(UI_LABELS_HELP)}</H1Title>
        </DialogTitle>
        <DialogContent>
            <div>
                <H2Title id="style">Style</H2Title>

                <p>Styles are similar to CSS styles in browser.</p>
                <ul>
                    <li>Colours may be specified by name (e.g. <code>red</code>), hex
                        (e.g. <code>#ff0000</code> or <code>#f00</code>),
                        RGB (e.g. <code>rgb(255, 0, 0)</code>), or HSL (e.g. <code>hsl(0, 100%, 50%)</code>).
                    </li>
                    <li>Values requiring a number, such as a length, can be specified in pixel values
                        (e.g. <code>24px</code>), unitless
                        values that are implicitly in pixels (e.g. <code>24</code>), or em values (e.g. <code>2em</code>).
                    </li>
                    <li>Opacity values are specified as numbers ranging on <code>0 &lt;= opacity &lt;=
                        1</code> (e.g <code>0.5</code>).
                    </li>
                    <li>Angles are measured in radians (e.g. <code>3.14rad</code>) or degrees (e.g. <code>180deg</code>),
                        clockwise.
                    </li>
                </ul>
                <H2Title id="style/node-body">Node body</H2Title>
                <p>Shape:</p>
                <ul>
                    <li><strong><code>width</code></strong> : The width of the node’s body.</li>
                    <li><strong><code>height</code></strong> : The height of the node’s body.</li>
                    <li><strong><code>shape</code></strong> : The shape of the node’s body. Note that each shape fits within
                        the
                        specified <code>width</code> and <code>height</code>, and so you may have to
                        adjust <code>width</code> and
                        <code>height</code>.
                    </li>
                    <li><strong><code>shape-polygon-points</code></strong> : An array (or a space-separated string) of
                        numbers ranging
                        on [-1, 1], representing alternating x and y values (i.e. <code>x1 y1 x2 y2, x3 y3 ...</code>). This
                        represents
                        the points in the polygon for the node’s shape.
                    </li>
                    <li><strong><code>corner-radius</code></strong> : The corner radius for <code>round-</code> shapes and
                        the <code>cut-rectangle</code>,
                        in px or em.
                    </li>
                </ul>
                <p>Background:</p>
                <ul>
                    <li><strong><code>background-color</code></strong> : The colour of the node’s body.</li>
                    <li><strong><code>background-blacken</code></strong> : Blackens the node’s body for values from 0 to 1;
                        whitens the
                        node’s body for values from 0 to -1.
                    </li>
                    <li><strong><code>background-opacity</code></strong> : The opacity level of the node’s background
                        colour.
                    </li>
                    <li><strong><code>background-fill</code></strong> : The filling style of the node’s body; may
                        be <code>solid</code>
                        (default), <code>linear-gradient</code>, or <code>radial-gradient</code>.
                    </li>
                </ul>
                <p>Gradient:</p>
                <ul>
                    <li><strong><code>background-gradient-stop-colors</code></strong> : The colours of the background
                        gradient stops
                        (e.g. <code>cyan magenta yellow</code>).
                    </li>
                    <li><strong><code>background-gradient-stop-positions</code></strong> : The positions of the background
                        gradient
                        stops (e.g. <code>0% 50% 100%</code>). If not specified or invalid, the stops will divide equally.
                    </li>
                    <li><strong><code>background-gradient-direction</code></strong> : For <code>background-fill:
                        linear-gradient</code>,
                        this property defines the direction of the background gradient.
                    </li>
                </ul>
                <p>Border:</p>
                <ul>
                    <li><strong><code>border-width</code></strong> : The size of the node’s border.</li>
                    <li><strong><code>border-style</code></strong> : The style of the node’s border.</li>
                    <li><strong><code>border-color</code></strong> : The colour of the node’s border.</li>
                    <li><strong><code>border-opacity</code></strong> : The opacity of the node’s border.</li>
                </ul>
                <p> Outline:</p>
                <ul>
                    <li><strong><code>outline-width</code></strong> : The size of the node’s outline.</li>
                    <li><strong><code>outline-style</code></strong> : The style of the node’s outline.</li>
                    <li><strong><code>outline-color</code></strong> : The colour of the node’s outline.</li>
                    <li><strong><code>outline-opacity</code></strong> : The opacity of the node’s outline.</li>
                    <li><strong><code>outline-offset</code></strong> : The offset of the node’s outline.</li>
                </ul>
                <p>Padding:</p>
                <p>A padding defines an addition to a node’s dimension. For example, <code>padding</code> adds to a node’s
                    outer (i.e.
                    total) width and height.</p>
                <ul>
                    <li><strong><code>padding</code></strong> : The amount of padding around all sides of the node. Either
                        percentage or
                        pixel value can be specified. For example, both <code>50%</code> and <code>50px</code> are
                        acceptable values. By
                        default, percentage padding is calculated as a percentage of node width.
                    </li>
                    <li><strong><code>padding-relative-to</code></strong> : Determines how padding is calculated if and only
                        if the
                        percentage unit is used. Accepts one of the keywords specified below.
                        <ul>
                            <li><strong><code>width</code></strong> : calculate padding as a percentage the node width.</li>
                            <li><strong><code>height</code></strong> : calculate padding as a percentage of the node height.
                            </li>
                            <li><strong><code>average</code></strong> : calculate padding as a percentage of the average of
                                the node
                                width and height.
                            </li>
                            <li><strong><code>min</code></strong> : calculate padding as a percentage of the minimum of the
                                node width
                                and height.
                            </li>
                            <li><strong><code>max</code></strong> : calculate padding as a percentage of the maximum of the
                                node width
                                and height.
                            </li>
                        </ul>
                    </li>
                </ul>
                <H2Title id="style/background-image">Background image</H2Title>
                <p>A background image may be applied to a node’s body. The following properties support multiple values
                    (space separated
                    or array) with associated indices.</p>
                <ul>
                    <code>background-image</code> : The URL that points to the image that should be used as the
                    node’s background. PNG, JPG, and SVG are supported formats.
                    <ul>
                        <li>You may use a <a target={'_blank'} href="https://en.wikipedia.org/wiki/Data_URI_scheme">data URI</a> to use
                            embedded
                            images, thereby saving a HTTP request.
                        </li>
                        <li>To put an image outside of the bounds of a node’s body, it is necessary to specify <code>background-clip:
                            none</code> and <code>bounds-expansion: n</code> for images that go <code>n</code> pixels beyond
                            the
                            bounding box of the node. Note that values of <code>n</code> should be relatively small for
                            performance.
                        </li>
                        <li><strong><code>background-image-crossorigin</code></strong>: All images are loaded with a <a target={'_blank'}
                                                                                                                        href="https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-crossorigin"><code>crossorigin</code></a>
                            attribute which may
                            be <code>anonymous</code> or <code>use-credentials</code> or <code>null</code>. These values
                            should be passed as a string (enclosed withing single or double quotes). The default is set to
                            <code>anonymous</code>.
                        </li>
                        <li><strong><code>background-image-opacity</code></strong> : The opacity of the background image.
                        </li>
                        <li><strong><code>background-image-smoothing</code></strong> : Determines whether background image
                            is smoothed
                            (<code>yes</code>, default) or not (<code>no</code>). This is only a hint, and the browser may
                            or may not
                            respect the value set for this property.
                        </li>
                        <li><strong><code>background-image-containment</code></strong> : Determines whether background image
                            is within
                            (<code>inside</code>, default) or over top of the node(<code>over</code>).
                        </li>
                        <li><strong><code>background-width</code></strong> : Specifies the width of the image. A percent
                            value (e.g. <code>50%</code>)
                            may be used to set the image width relative to the node width. If used in combination with
                            <code>background-fit</code>, then this value overrides the width of the image in calculating the
                            fitting —
                            thereby overriding the aspect ratio. The <code>auto</code> value is used by default, which uses
                            the width of the
                            image.
                        </li>
                        <li><strong><code>background-height</code></strong> : Specifies the height of the image. A percent
                            value (e.g.
                            <code>50%</code>) may be used to set the image height relative to the node height. If used in
                            combination with
                            <code>background-fit</code>, then this value overrides the height of the image in calculating
                            the fitting —
                            thereby overriding the aspect ratio. The <code>auto</code> value is used by default, which uses
                            the height of
                            the image.
                        </li>
                        <li><strong><code>background-fit</code></strong> : How the background image is fit to the node; may
                            be
                            <code>none</code> for original size, <code>contain</code> to fit inside node,
                            or <code>cover</code> to cover the
                            node.
                        </li>
                        <li><strong><code>background-repeat</code></strong> : Whether to repeat the background image; may be
                            <code>no-repeat</code>, <code>repeat-x</code>, <code>repeat-y</code>, or <code>repeat</code>.
                        </li>
                        <li><strong><code>background-position-x</code></strong> : The x position of the background image,
                            measured in
                            percent (e.g. <code>50%</code>) or pixels (e.g. <code>10px</code>).
                        </li>
                        <li><strong><code>background-position-y</code></strong> : The y position of the background image,
                            measured in
                            percent (e.g. <code>50%</code>) or pixels (e.g. <code>10px</code>).
                        </li>
                        <li><strong><code>background-offset-x</code></strong> : The x offset of the background image,
                            measured in percent
                            (e.g. <code>50%</code>) or pixels (e.g. <code>10px</code>).
                        </li>
                        <li><strong><code>background-offset-y</code></strong> : The y offset of the background image,
                            measured in percent
                            (e.g. <code>50%</code>) or pixels (e.g. <code>10px</code>).
                        </li>
                        <li><strong><code>background-width-relative-to</code></strong> : Changes whether the width is
                            calculated relative to
                            the width of the node or the width in addition to the padding; may
                            be <code>inner</code> or <code>include-padding</code>.
                            If not specified, <code>include-padding</code> is used by default.
                        </li>
                        <li><strong><code>background-height-relative-to</code></strong> : Changes whether the height is
                            calculated relative
                            to the height of the node or the height in addition to the padding; may
                            be <code>inner</code> or <code>include-padding</code>.
                            If not specified, <code>include-padding</code> is used by default.
                        </li>
                        <li><strong><code>background-clip</code></strong> : How background image clipping is handled; may be
                            <code>node</code> for clipped to node shape or <code>none</code> for no clipping.
                        </li>
                    </ul>
                </ul>
                <H2Title id="style/pie-chart-background">Pie chart background </H2Title>
                <p>These properties allow you to create pie chart backgrounds on nodes. Note that
                    16 slices maximum are supported per node, so in the properties <code>1 &lt;= i &lt;= 16</code>. Of
                    course, you must
                    specify a numerical value for each property in place of <code>i</code>. Each nonzero sized slice is
                    placed in order
                    of <code>i</code>, starting from the 12 o’clock position and working clockwise.</p>
                <ul>
                    <li><strong><code>pie-size</code></strong> : The diameter of the pie, measured as a percent of node size
                        (e.g.
                        <code>100%</code>) or an absolute length (e.g. <code>25px</code>).
                    </li>
                    <li><strong><code>pie-i-background-color</code></strong> : The colour of the node’s ith pie chart slice.
                    </li>
                    <li><strong><code>pie-i-background-size</code></strong> : The size of the node’s ith pie chart slice,
                        measured in
                        percent (e.g. <code>25%</code> or <code>25</code>).
                    </li>
                    <li><strong><code>pie-i-background-opacity</code></strong> : The opacity of the node’s ith pie chart
                        slice.
                    </li>
                </ul>
                <H2Title id="style/edge-line">Edge line</H2Title>
                <p>These properties affect the styling of an edge’s line:</p>
                <ul>
                    <li><strong><code>width</code></strong> : The width of an edge’s line.</li>
                    <li><strong><code>curve-style</code></strong> : The curving method used to separate two or more edges
                        between two
                        nodes.
                    </li>
                    <li><strong><code>line-color</code></strong> : The colour of the edge’s line.</li>
                    <li><strong><code>line-style</code></strong> : The style of the edge’s line.
                    </li>
                    <li><strong><code>line-cap</code></strong> : The cap style of the edge’s line.
                    </li>
                    <li><strong><code>line-opacity</code></strong> : The opacity of the edge’s line and arrow. Useful if you
                        wish to
                        have a separate opacity for the edge label versus the edge line. Note that the opacity value of the
                        edge element
                        affects the effective opacity of its line and label subcomponents.
                    </li>
                    <li><strong><code>line-fill</code></strong> : The filling style of the edge’s line.
                    </li>
                    <li><strong><code>line-dash-pattern</code></strong> : The <code>dashed</code> line pattern which
                        specifies
                        alternating lengths of lines and gaps. (e.g. <code>[6, 3]</code>).
                    </li>
                    <li><strong><code>line-dash-offset</code></strong> : The <code>dashed</code> line offset
                        (e.g. <code>24</code>). It
                        is useful for creating edge animations.
                    </li>
                </ul>
                <H2Title id="style/gradient">Gradient</H2Title>
                <ul>
                    <li><strong><code>line-gradient-stop-colors</code></strong> : The colours of the gradient stops
                        (e.g. <code>cyan
                            magenta yellow</code>).
                    </li>
                    <li><strong><code>line-gradient-stop-positions</code></strong> : The positions of the gradient stops
                        (e.g. <code>0%
                            50% 100%</code>). If not specified (or invalid), the stops will divide equally.
                    </li>
                </ul>
                <H2Title id="style/bezier-edges">Bezier edges</H2Title>
                <p>A bezier edge is bundled with all other parallel bezier edges. Each bezier edge is a <a target={'_blank'}
                                                                                                           href="https://en.wikipedia.org/wiki/B%C3%A9zier_curve#Quadratic_B%C3%A9zier_curves">quadratic bezier
                    curve</a>,
                    separated from the others by varying the curvature. If there is an odd number of parallel edges in a
                    bundle, then
                    the centre edge is drawn as a straight line.</p>
                <ul>
                    <li><strong><code>control-point-step-size</code></strong> : Along the line perpendicular from source to
                        target, this
                        value specifies the distance between successive bezier edges.
                    </li>
                    <li><strong><code>control-point-distance</code></strong> : A single value that
                        overrides <code>control-point-step-size</code>
                        with a manual value. Because it overrides the step size, bezier edges with the same value will
                        overlap. Thus,
                        it’s best to use this as a one-off value for particular edges if need be.
                    </li>
                    <li><strong><code>control-point-weight</code></strong> : A single value that weights control points
                        along the line
                        from source to target. The value usually ranges on [0, 1], with 0 towards the source node and 1
                        towards the
                        target node — but larger or smaller values can also be used.
                    </li>
                    <li><strong><code>edge-distances</code></strong> :
                        <ul>
                            <li>With value <code>intersection</code> (default), the line from source to target for
                                <code>segment-weights</code> is from the outside of the source node’s shape to the outside
                                of the target
                                node’s shape.
                            </li>
                            <li>With value <code>node-position</code>, the line is from the source position to the target
                                position.
                            </li>
                            <li>The <code>node-position</code> option makes calculating edge points easier — but it should
                                be used
                                carefully because you can create invalid points that <code>intersection</code> would have
                                automatically
                                corrected.
                            </li>
                            <li>With value <code>endpoints</code>, the line is from the manually-specified source endpoint
                                (via <code>source-endpoint</code>)
                                to the manually-specified target endpoint (via <code>target-endpoint</code>).
                                <ul>
                                    <li>A manual endpoint may be specified with a position, e.g. <code>source-endpoint: 20
                                        10</code>.
                                    </li>
                                    <li>A manual endpoint may be alternatively specified with an angle, e.g. <code>target-endpoint:
                                        90deg</code>.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <H2Title id="style/unbundled-bezier-edges">Unbundled bezier edges </H2Title>
                <p>For bezier edges with manual control points (<code>curve-style: unbundled-bezier</code>):
                </p>
                <p>An unbundled bezier edge is made of a series of one or more <a target={'_blank'}
                                                                                  href="https://en.wikipedia.org/wiki/B%C3%A9zier_curve#Quadratic_B%C3%A9zier_curves">quadratic bezier
                    curves</a>
                    — one control point per curve. The control points of unbundled bezier curves are specified manually,
                    using a
                    co-ordinate system relative to the source and target node. This maintains the overall curve shape
                    regardless of the
                    positions of the connected nodes.</p>
                <p>A quadratic bezier curve is specified by three points. Those points include the start point (P0), the
                    centre control
                    point (P1), and the end point (P2). Traditionally, all three points are called “control points”, but
                    only the centre
                    control point (P1) is referred to as the “control point” within this documentation for brevity and
                    clarity.</p>
                <p>The start point (P0) for the first quadratic bezier curve in the series is specified
                    by <code>source-endpoint</code>.
                    The end point (P2) for the last quadratic bezier curve in the series is specified
                    by <code>target-endpoint</code>.
                </p>
                <p>When two or more control points are specified for an unbundled bezier edge, each adjacent pair of bezier
                    curves is
                    joined at the midpoint of the two control points. In other words, the start point (P0) and end point
                    (P2) for a
                    quadratic bezier curve in the middle of the series are set implicitly. This makes most curves join
                    smoothly.</p>
                <ul>
                    <li><strong><code>control-point-distances</code></strong> : A series of values that specify for each
                        control point
                        the distance perpendicular to a line formed from source to target, e.g. <code>-20 20 -20</code>.
                    </li>
                    <li><strong><code>control-point-weights</code></strong> : A series of values that weights control points
                        along a
                        line from source to target, e.g. <code>0.25 0.5 0.75</code>. A value usually ranges on [0, 1], with
                        0 towards
                        the source node and 1 towards the target node — but larger or smaller values can also be used.
                    </li>
                    <li><strong><code>edge-distances</code></strong> :
                        <ul>
                            <li>With value <code>intersection</code> (default), the line from source to target for
                                <code>segment-weights</code> is from the outside of the source node’s shape to the outside
                                of the target
                                node’s shape.
                            </li>
                            <li>With value <code>node-position</code>, the line is from the source position to the target
                                position.
                            </li>
                            <li>The <code>node-position</code> option makes calculating edge points easier — but it should
                                be used
                                carefully because you can create invalid points that <code>intersection</code> would have
                                automatically
                                corrected.
                            </li>
                            <li>With value <code>endpoints</code>, the line is from the manually-specified source endpoint
                                (via <code>source-endpoint</code>)
                                to the manually-specified target endpoint (via <code>target-endpoint</code>).
                                <ul>
                                    <li>A manual endpoint may be specified with a position, e.g. <code>source-endpoint: 20
                                        10</code>.
                                    </li>
                                    <li>A manual endpoint may be alternatively specified with an angle, e.g. <code>target-endpoint:
                                        90deg</code>.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <H2Title id="style/haystack-edges">Haystack edges</H2Title>
                <p>For fast, straight line edges (<code>curve-style: haystack</code>):</p>
                <p>Haystack edges are a more performant replacement for plain, straight line edges. A haystack edge is drawn
                    as a
                    straight line from the source node to the target node, randomly placed along some angle from each node’s
                    centre. In
                    this manner, many parallel haystack edges make a tight bundle, especially when semitransparent. This
                    makes haystack
                    edges an effective way to visualise graphs with a high number of parallel edges.</p>
                <p><span className="important-indicator"></span> Loop edges and compound parent nodes are not supported by
                    haystack edges.
                    Also note that source and target arrows are not supported for haystack edges, as the arrows would be
                    behind the node
                    body. Mid arrows, however, are supported.</p>
                <ul>
                    <li><strong><code>haystack-radius</code></strong> : A value between 0 and 1 inclusive that indicates the
                        relative
                        radius used to position haystack edges on their connected nodes. The outside of the node is at 1,
                        and the centre
                        of the node is at 0. For simple graphs, a radius of 0 makes sense.
                    </li>
                </ul>
                <H2Title id="style/segments-edges">Segments edges</H2Title>
                <p>For edges made of several straight lines (<code>curve-style: segments</code>):
                </p>
                <p>A segment edge is made of a series of one or more straight lines, using a co-ordinate system relative to
                    the source
                    and target nodes. This maintains the overall line pattern regardless of the orientation of the positions
                    of the
                    source and target nodes.</p>
                <ul>
                    <li><strong><code>segment-distances</code></strong> : A series of values that specify for each segment
                        point the
                        distance perpendicular to a line formed from source to target, e.g. <code>-20 20 -20</code>.
                    </li>
                    <li><strong><code>segment-weights</code></strong> : A series of values that weights segment points along
                        a line from
                        source to target, e.g. <code>0.25 0.5 0.75</code>. A value usually ranges on [0, 1], with 0 towards
                        the source
                        node and 1 towards the target node — but larger or smaller values can also be used.
                    </li>
                    <li><strong><code>edge-distances</code></strong> :
                        <ul>
                            <li>With value <code>intersection</code> (default), the line from source to target for
                                <code>segment-weights</code> is from the outside of the source node’s shape to the outside
                                of the target
                                node’s shape.
                            </li>
                            <li>With value <code>node-position</code>, the line is from the source position to the target
                                position.
                            </li>
                            <li>The <code>node-position</code> option makes calculating edge points easier — but it should
                                be used
                                carefully because you can create invalid points that <code>intersection</code> would have
                                automatically
                                corrected.
                            </li>
                            <li>With value <code>endpoints</code>, the line is from the manually-specified source endpoint
                                (via <code>source-endpoint</code>)
                                to the manually-specified target endpoint (via <code>target-endpoint</code>).
                                <ul>
                                    <li>A manual endpoint may be specified with a position, e.g. <code>source-endpoint: 20
                                        10</code>.
                                    </li>
                                    <li>A manual endpoint may be alternatively specified with an angle, e.g. <code>target-endpoint:
                                        90deg</code>.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                </ul>
                <H2Title id="style/straight-edges">Straight edges</H2Title>
                <p>For straight line edges (<code>curve-style: straight</code>):</p>
                <p>A straight edge (<code>curve-style: straight</code>) is drawn as a single straight line from the outside
                    of the
                    source node shape to the outside of the target node shape. Endpoint and midpoint arrows are supported on
                    straight
                    edges. Straight edges are not generally suitable for multigraphs.</p>
                <H2Title id="style/straight-triangle-edges">Straight triangle edges</H2Title>
                <p>For straight triangle edges (<code>curve-style: straight-triangle</code>):</p>
                <p>A straight triangle edge (<code>curve-style: straight-triangle</code>) is drawn as a single straight
                    isosceles
                    triangle in the direction from the source to the target, with a triangle base at the source and a
                    triangle apex (a
                    point) at the target.</p>
                <p>The <code>width</code> property defines width of the triangle base. The <code>line-style</code>,
                    <code>line-cap</code>, <code>line-dash-pattern</code>, and <code>line-dash-offset</code> properties are
                    not
                    supported.</p>
                <H2Title id="style/taxi-edges">Taxi edges </H2Title>
                <p>For hierarchical, bundled edges (<code>curve-style: taxi</code>):</p>
                <p>A taxi edge (<code>curve-style: taxi</code>) is drawn as a series of right-angled lines (i.e. in <a target={'_blank'}
                                                                                                                       href="https://en.wikipedia.org/wiki/Taxicab_geometry">taxicab geometry</a>). The edge has a primary
                    direction
                    along either the x-axis or y-axis, which can be used to bundle edges in a hierarchy. That is, taxi edges
                    are
                    appropriate for trees and DAGs that are laid out in a hierarchical manner.</p>
                <p>A taxi edge has at most two visible turns: Starting from the source node, the edge goes in the primary
                    direction for
                    the specified distance. The edge then turns, going towards the target along the secondary axis. The
                    first turn can
                    be specified in order to bundle the edges of outgoing nodes. The second turn is implicit, based on the
                    first turn,
                    going the remaining distance along the main axis.</p>
                <p>When a taxi edge would be impossible to draw along the regular turning plan — i.e. one or more turns is
                    too close the
                    source or target — it is re-routed. The re-routing is carried out on a best-effort basis: Re-routing
                    prioritises the
                    specified direction for bundling over the specified turn distance. A <code>downward</code> edge, for
                    example, will
                    avoid going in the upward direction where possible. In practice, re-routing should not take place for
                    graphs that
                    are well laid out.</p>
                <p><span className="important-indicator"></span> Only <code>outside-to-node</code> endpoints are supported
                    for a taxi edge,
                    i.e. <code>source-endpoint: outside-to-node</code> and <code>target-endpoint: outside-to-node</code>.
                </p>
                <ul>
                    <li><strong><code>taxi-direction</code></strong> : The main direction of the edge, the direction
                        starting out from
                        the source node.
                    </li>
                    <li><strong><code>taxi-turn</code></strong> : The distance along the primary axis where the first turn
                        is applied.
                        <ul>
                            <li>This value may be an absolute distance (e.g. <code>20px</code>) or it may be a relative
                                distance between
                                the source and target (e.g. <code>50%</code>).
                            </li>
                            <li>A negative value may be specified to indicate a distance in the oppostite, target to source
                                direction
                                (e.g. <code>-20px</code>).
                            </li>
                        </ul>
                    </li>
                    <li><strong><code>taxi-turn-min-distance</code></strong> : The minimum distance along the primary axis
                        that is
                        maintained between the nodes and the turns.
                        <ul>
                            <li>This value only takes on absolute values (e.g. <code>5px</code>).</li>
                            <li>This property makes the taxi edge be re-routed when the turns would be otherwise too close
                                to the source
                                or target. As such, it also helps to avoid turns overlapping edge endpoint arrows.
                            </li>
                        </ul>
                    </li>
                    <li><strong><code>edge-distances</code></strong> : With value <code>intersection</code> (default), the
                        distances
                        (<code>taxi-turn</code> and <code>taxi-turn-min-distance</code>) are considered from the outside of
                        the source’s
                        bounds to the outside of the target’s bounds. With value <code>node-position</code>, the distances
                        are
                        considered from the source position to the target position. The <code>node-position</code> option
                        makes
                        calculating edge points easier — but it should be used carefully because you can create invalid
                        points that
                        <code>intersection</code> would have automatically corrected.
                    </li>
                </ul>
                <H2Title id="style/edge-arrow">Edge arrow</H2Title>
                <ul>
                    <li><strong><code>&lt;pos&gt;-arrow-color</code></strong> : The colour of the edge’s source arrow.</li>
                    <li><strong><code>&lt;pos&gt;-arrow-shape</code></strong> : The shape of the edge’s source arrow.
                    </li>
                    <li><strong><code>&lt;pos&gt;-arrow-fill</code></strong> : The fill state of the edge’s source arrow;
                        may be <code>filled</code>
                        or <code>hollow</code>.
                    </li>
                    <li><strong><code>&lt;pos&gt;-arrow-width</code></strong> : The width of the edge’s source arrow shape;
                        may be
                        <code>match-line</code>, a number (pixel), or a string with units
                        (<code>px</code> | <code>%</code> |
                        <code>em</code>). The <code>%</code> unit is based on the edge <code>width</code>.
                    </li>
                    <li><strong><code>arrow-scale</code></strong> : Scaling for the arrow size; may be any number &gt;= 0.
                    </li>
                </ul>
                <p>For each edge arrow property above, replace <code>&lt;pos&gt;</code> with one of</p>
                <ul>
                    <li><code>source</code> : Pointing towards the source node, at the end of the edge.</li>
                    <li><code>mid-source</code> : Pointing towards the source node, at the middle of the edge.</li>
                    <li><code>target</code> : Pointing towards the target node, at the end of the edge.</li>
                    <li><code>mid-target</code>: Pointing towards the target node, at the middle of the edge.</li>
                </ul>
                <p>Only mid arrows are supported on haystack edges.</p>
                <H2Title id="style/edge-endpoints">Edge endpoints</H2Title>
                <p><code>source-endpoint</code> &amp; <code>target-endpoint</code> : Specifies the endpoint of the source
                    side of the
                    edge and the target side of the edge, respectively.
                </p>
                <p>The endpoints for edges can be shifted away from the source and target node:</p>
                <ul>
                    <li><strong><code>source-distance-from-node</code></strong> : A value that shifts the edge away from the
                        source node
                        (default <code>0px</code>).
                    </li>
                    <li><strong><code>target-distance-from-node</code></strong> : A value that shifts the edge away from the
                        target node
                        (default <code>0px</code>).
                    </li>
                </ul>
                <p>Endpoint modification is not supported for <code>curve-style: haystack</code> edges .</p>
                <H2Title id="style/labels">Labels</H2Title>
                <p>Label text:</p>
                <ul>
                    <li><strong><code>label</code></strong> : The text to display for an element’s label. Use space to turn
                        off the label.
                    </li>
                    <li><strong><code>source-label</code></strong> : The text to display for an edge’s source label.</li>
                    <li><strong><code>target-label</code></strong> : The text to display for an edge’s target label.</li>
                </ul>
                <p>Basic font styling:</p>
                <ul>
                    <li><strong><code>color</code></strong> : The colour of the element’s label.</li>
                    <li><strong><code>text-opacity</code></strong> : The opacity of the label text, including its outline.
                    </li>
                    <li><strong><code>font-family</code></strong> : A <a target={'_blank'}
                                                                         href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-family">comma-separated list of font
                        names</a>
                        to use on the label text.
                    </li>
                    <li><strong><code>font-size</code></strong> : The size of the label text.</li>
                    <li><strong><code>font-style</code></strong> : A <a target={'_blank'}
                                                                        href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-style">CSS font style</a> to be applied
                        to the
                        label text.
                    </li>
                    <li><strong><code>font-weight</code></strong> : A <a target={'_blank'}
                                                                         href="https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight">CSS font weight</a> to be
                        applied to the
                        label text.
                    </li>
                    <li><strong><code>text-transform</code></strong> : A transformation to apply to the label text; may be
                        <code>none</code>,
                        <code>uppercase</code>, or <code>lowercase</code>.
                    </li>
                </ul>
                <p>Wrapping text:</p>
                <ul>
                    <li><strong><code>text-wrap</code></strong> : A wrapping style to apply to the label text; may
                        be <code>none</code>
                        for no wrapping (including manual newlines: <code>\n</code>), <code>wrap</code> for manual and/or
                        autowrapping,
                        or <code>ellipsis</code> to truncate the string and append ‘…’ based on <code>text-max-width</code>.
                        Note that
                        with <code>wrap</code>, text will always wrap on newlines (<code>\n</code>) and text may wrap on any
                        breakable
                        whitespace character — including <a target={'_blank'} href="https://en.wikipedia.org/wiki/Zero-width_space">zero-width
                            spaces</a>
                        (<code>\u200b</code>).
                    </li>
                    <li><strong><code>text-max-width</code></strong> : The maximum width for wrapped text, applied when
                        <code>text-wrap</code> is set to <code>wrap</code> or <code>ellipsis</code>. For only manual
                        newlines (i.e.
                        <code>\n</code>), set a very large value like <code>1000px</code> such that only your newline
                        characters would
                        apply.
                    </li>
                    <li><strong><code>text-overflow-wrap</code></strong> : The characters that may be used for possible
                        wrapping
                        locations when a line overflows <code>text-max-width</code>; may
                        be <code>whitespace</code> (default) or <code>anywhere</code>.
                        Note that <code>anywhere</code> is suited to <a target={'_blank'}
                                                                        href="https://en.wikipedia.org/wiki/CJK_characters">CJK</a>,
                        where the characters are in a grid and no whitespace exists. Using <code>anywhere</code> with text
                        in the Latin
                        alphabet, for example, will split words at arbitrary locations.
                    </li>
                    <li><strong><code>text-justification</code></strong> : The justification of multiline (wrapped) labels;
                        may be
                        <code>left</code>, <code>center</code>, <code>right</code>, or <code>auto</code> (default). The
                        <code>auto</code> value makes it so that a node’s label is justified along the node — e.g. a label
                        on the right
                        side of a node is left justified.
                    </li>
                    <li><strong><code>line-height</code></strong> : The line height of multiline text, as a relative,
                        unitless value. It
                        specifies the vertical spacing between each line. With value <code>1</code> (default), the lines are
                        stacked
                        directly on top of one another with no additional whitespace between them. With value <code>2</code>,
                        for
                        example, there is whitespace between each line equal to the visible height of a line of text.
                    </li>
                </ul>
                <p>Node label alignment:</p>
                <ul>
                    <li><strong><code>text-halign</code></strong> : The vertical alignment of a node’s label.
                    </li>
                    <li><strong><code>text-valign</code></strong> : The vertical alignment of a node’s label.
                    </li>
                </ul>
                <p>Edge label alignment:</p>
                <ul>
                    <li><strong><code>source-text-offset</code></strong> : For the source label of an edge, how far from the
                        source node
                        the label should be placed.
                    </li>
                    <li><strong><code>target-text-offset</code></strong> : For the target label of an edge, how far from the
                        target node
                        the label should be placed.
                    </li>
                </ul>
                <p>Margins:</p>
                <ul>
                    <li><strong><code>text-margin-x</code></strong> : A margin that shifts the label along the x-axis.</li>
                    <li><strong><code>text-margin-y</code></strong> : A margin that shifts the label along the y-axis.</li>
                    <li><strong><code>source-text-margin-x</code></strong> : (For the source label of an edge.)</li>
                    <li><strong><code>source-text-margin-y</code></strong> : (For the source label of an edge.)</li>
                    <li><strong><code>target-text-margin-x</code></strong> : (For the target label of an edge.)</li>
                    <li><strong><code>target-text-margin-y</code></strong> : (For the target label of an edge.)</li>
                </ul>
                <p>Rotating text:</p>
                <ul>
                    <li><strong><code>text-rotation</code></strong> : A rotation angle that is applied to the label.</li>
                    <li>Rotations are clockwise.</li>
                    <li>For edges, the special value <code>autorotate</code> can be used to align the label to the edge.
                    </li>
                    <li>For nodes, the label is rotated along its anchor point on the node, so a label margin may help for
                        some
                        usecases.
                    </li>
                    <li>The special value <code>none</code> can be used to denote <code>0deg</code>.</li>
                    <li>Rotations works best with left-to-right text.</li>
                    <li><strong><code>source-text-rotation</code></strong> : (For the source label of an edge.)</li>
                    <li><strong><code>target-text-rotation</code></strong> : (For the target label of an edge.)</li>
                </ul>
                <p>Outline:</p>
                <ul>
                    <li><strong><code>text-outline-color</code></strong> : The colour of the outline around the element’s
                        label text.
                    </li>
                    <li><strong><code>text-outline-opacity</code></strong> : The opacity of the outline on label text.</li>
                    <li><strong><code>text-outline-width</code></strong> : The size of the outline on label text.</li>
                </ul>
                <p>Background:</p>
                <ul>
                    <li><strong><code>text-background-color</code></strong> : A colour to apply on the text background.</li>
                    <li><strong><code>text-background-opacity</code></strong> : The opacity of the label background; the
                        background is
                        disabled for <code>0</code> (default value).
                    </li>
                    <li><strong><code>text-background-shape</code></strong> : The shape to use for the label background, can
                        be <code>rectangle</code>
                        or <code>round-rectangle</code>.
                    </li>
                    <li><strong><code>text-background-padding</code></strong> : A padding on the background of the label
                        (e.g
                        <code>5px</code>); zero padding is used by default.
                    </li>
                </ul>
                <p>Border:</p>
                <ul>
                    <li><strong><code>text-border-opacity</code></strong> : The width of the border around the label; the
                        border is
                        disabled for <code>0</code> (default value).
                    </li>
                    <li><strong><code>text-border-width</code></strong> : The width of the border around the label.</li>
                    <li><strong><code>text-border-style</code></strong> : The style of the border around the label.
                    </li>
                    <li><strong><code>text-border-color</code></strong> : The colour of the border around the label.</li>
                </ul>
                <H2Title id="style/overlay">Overlay</H2Title>
                <ul>
                    <li><strong><code>overlay-color</code></strong> : The colour of the overlay.</li>
                    <li><strong><code>overlay-padding</code></strong> : The area outside of the element within which the
                        overlay is
                        shown.
                    </li>
                    <li><strong><code>overlay-opacity</code></strong> : The opacity of the overlay.</li>
                    <li><strong><code>overlay-shape</code></strong> : The shape of the node overlay. Doesn’t apply to edges.
                    </li>
                </ul>
                <H2Title id="style/underlay">Underlay</H2Title>
                <p>These properties allow for the creation of underlays behind nodes or edges, and are often used in a
                    highlighted
                    state.</p>
                <ul>
                    <li><strong><code>underlay-color</code></strong> : The colour of the underlay.</li>
                    <li><strong><code>underlay-padding</code></strong> : The area outside of the element within which the
                        underlay is
                        shown.
                    </li>
                    <li><strong><code>underlay-opacity</code></strong> : The opacity of the underlay.</li>
                    <li><strong><code>underlay-shape</code></strong> : The shape of the node underlay; may be
                        <code>round-rectangle</code> (default), <code>ellipse</code>. Doesn’t apply to edges.
                    </li>
                </ul>
                <H2Title id="style/ghost">Ghost</H2Title>
                <p>The ghost properties allow for creating a ghosting effect, a semitransparent duplicate of the element
                    drawn at an
                    offset.</p>
                <ul>
                    <li><strong><code>ghost</code></strong> : Whether to use the ghost effect; may be <code>yes</code> or
                        <code>no</code>.
                    </li>
                    <li><strong><code>ghost-offset-x</code></strong> : The horizontal offset used to position the ghost
                        effect.
                    </li>
                    <li><strong><code>ghost-offset-y</code></strong> : The vertical offset used to position the ghost
                        effect.
                    </li>
                    <li><strong><code>ghost-opacity</code></strong> : The opacity of the ghost effect.</li>
                </ul>
            </div>
        </DialogContent>
        <DialogActions>
            <Button datatest={'cancelButton'} variant={"outlined"} onClick={onClose} color="secondary">{getTranslation(UI_LABELS_CLOSE)}</Button>
        </DialogActions>

    </Dialog> ;

}
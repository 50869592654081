import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {traceRenderStart} from "../../components/Trace";
import {Grid, TextField as OtherTextField} from "@material-ui/core";
import TextField from "../../components/TextField";
import {
    ALIAS_RDFS_SUBCLASS_OF,
    ALIAS_SYS_ID_LABEL,
    ID,
    VALIDATION_DESCRIPTION_LENGTH,
    VALIDATION_LANGUAGE_NAME_LENGTH
} from "../../Constants";
import {
    computeAllSuperClasses,
    getMaxLengthMessage,
    isValidMaxLengthWithoutTrim,
    restrictMaximumCharacters,
    sort,
    validateAlias,
    validateNameDuplication
} from "../../components/util";
import H2Title from "../../components/H2Title";
import FormControl from "@material-ui/core/FormControl";
import Label from "../../components/Label";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import H3Title from "../../components/H3Title";
import {getChipWithDelete} from "../../layouts/apiplayground/SearchFilter";
import AutoComplete from "../../components/AutoComplete";
import FieldContainer from "../../components/FieldContainer";
import QueueIcon from '@material-ui/icons/Queue';
import AddIcon from "@material-ui/icons/AddCircleOutlined";

const styles = {
    dialogPaper: {
        minWidth: '600px',
        maxWidth: '600px',
        minHeight: '460px'
    },
};

function getOptionsForSubClassSelect(parentClassID, ontologyClasses) {
    let allSuperClasses = computeAllSuperClasses(parentClassID, ontologyClasses);
    let allDirectSubClasses = ontologyClasses
        .filter(oc => oc[ALIAS_RDFS_SUBCLASS_OF] && oc[ALIAS_RDFS_SUBCLASS_OF].includes(parentClassID))
        .map(oc => oc[ID]);
    let options = sort(ontologyClasses.filter(c => c[ID] !== parentClassID).map(c => {
        let classID = c[ID];
        let disabled = undefined;
        if(allSuperClasses.includes(classID)) {
            disabled = " (Existing Superclass)";
        }
        if(allDirectSubClasses.includes(classID)) {
            disabled = " (Existing Subclass)";
        }
        return {label : c[ALIAS_SYS_ID_LABEL], value : classID, disabled : disabled};
    }), 'label');
    return options;
}

const NEW_CLASS = 'newClass';
const EXISTING_CLASS = 'existingClass';

const COMPONENT = 'AddClassDialog'
class AddClassDialog extends Component {
    constructor(props) {
        super(props);
        let {parentClassID, ontologyClasses} = props;
        let OBJECT_TYPE_OPTIONS = parentClassID
            ? getOptionsForSubClassSelect(parentClassID, ontologyClasses)
            : [];
        let hasSubClassOption = OBJECT_TYPE_OPTIONS.find(o => o.disabled === undefined);

        this.state = {
            name: props.name || '',
            description: props.description || '',
            classType : hasSubClassOption ? EXISTING_CLASS: NEW_CLASS,
            subClass: ''
        }
    }

    shouldCreateBeDisabled = () => {
        let {name, nameError, descriptionError, subClass} = this.state;
        if(this.isNewClass()) {
            if (!name || nameError || descriptionError) {
                return true;
            }
        } else if(!subClass) {
            return true;
        }
        return false;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    isNewClass = () => {
        let {classType} = this.state;
        return classType === NEW_CLASS;
    }

    getSubClassSelect = () => {
        let {theme, ontologyClasses, parentClassID} = this.props;
        let {subClass} = this.state;
        let OBJECT_TYPE_OPTIONS = getOptionsForSubClassSelect(parentClassID, ontologyClasses);
        return <FieldContainer style={{ minHeight:'70px' }}>
            <Label label={'Select Class'}/>
            <AutoComplete
                datatest={'selectClass'}
                value={subClass}
                options={OBJECT_TYPE_OPTIONS}
                getOptionLabel={option => {
                    let label = option.label ? option.label : '';
                    if(option.disabled) {
                        label = label + option.disabled
                    }
                    return label;
                }}
                getOptionDisabled={(option) => option.disabled === undefined ? false : true}
                onChange={(event, val) => {
                    this.setState({subClass: val})
                }}
                renderInput={params => (
                    <OtherTextField
                        {...params}
                        margin={"dense"}
                        variant="outlined"
                        fullWidth
                    />
                )}

                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return getChipWithDelete(theme, index, option.label, undefined, getTagProps({index}));
                    })
                }}
                size={"small"}
                disableClearable
            />
        </FieldContainer>;

    }

    handleAdd = (multiple) => {
        let {subClass, description, name} = this.state;
        let {handleOk, ontologyProperties, ontologyClasses} = this.props;
        let nameDuplication = validateNameDuplication(name, ontologyProperties, ontologyClasses);
        if(nameDuplication) {
            this.setState({nameError : nameDuplication});
            return;
        } else {
            if (multiple) {
                this.setState({name: '', description: '', subClass : ''} , () => this.nameRef && this.nameRef.focus());
            }
            if (this.isNewClass()) {
                handleOk({title: name, description: description}, multiple);
            } else {
                handleOk({[ID]: subClass.value}, multiple);
            }
        }
    }

    render() {
        traceRenderStart('', COMPONENT)

        let {classes, showExisting, ontologyProperties, ontologyClasses, addButtonTitle, handleCancel, handleOk, title, open, helpText} = this.props
        let {name, nameError, description, descriptionError, classType} = this.state
        return  <Dialog
            aria-labelledby="form-dialog-title"
            open={open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="form-dialog-title"><H2Title noWrap={true} title={title}/></DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {helpText && <Grid item xs={12}>{helpText}</Grid>}
                    {   showExisting &&
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <RadioGroup
                                    aria-label="Choose Option"
                                    name="classType"
                                    className={classes.group}
                                    value={classType || NEW_CLASS}
                                    onChange={(event) => {
                                        const {target: {name, value}} = event;
                                        this.setState({
                                            [name]: value
                                        });
                                    }}
                                    row={true}
                                >
                                    <FormControlLabel value="existingClass"
                                                      control={<Radio/>}
                                                      label={<H3Title title={"Add Existing Class"}/>}/>
                                    <FormControlLabel value="newClass"
                                                      control={<Radio/>}
                                                      label={<H3Title title={'Create New Class'}/>}/>
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    {
                        this.isNewClass()
                            ? <>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{
                                            ref: (input) => {this.nameRef = input}
                                        }}
                                        autoFocus={true}
                                        label={'Name'}
                                        id='name'
                                        name='name'
                                        value={name}
                                        error={nameError}
                                        onChange={(event) => {
                                            this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_LANGUAGE_NAME_LENGTH))
                                            const {target: {value}} = event;
                                            let error = validateAlias(value, 'Name');
                                            if (error) {
                                                this.setState({nameError: error})
                                            } else {
                                                let nameDuplication = validateNameDuplication(value, ontologyProperties, ontologyClasses);
                                                if(nameDuplication) {
                                                    this.setState({nameError : nameDuplication});
                                                } else {
                                                    this.setState({nameError: ''})
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={'Description'}
                                        id='description'
                                        name='description'
                                        value={description}
                                        error={descriptionError}
                                        multiline={true}
                                        rowsMax="4"
                                        rows="4"
                                        onChange={(event) => {
                                            this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_DESCRIPTION_LENGTH))
                                            const {target: {value}} = event;
                                            if (!isValidMaxLengthWithoutTrim(value, VALIDATION_DESCRIPTION_LENGTH)) {
                                                this.setState({descriptionError: getMaxLengthMessage(VALIDATION_DESCRIPTION_LENGTH)})
                                            } else {
                                                this.setState({descriptionError: ''})
                                            }
                                        }}
                                    />
                                </Grid>
                            </>
                            : <Grid item xs={12}>
                                {this.getSubClassSelect()}
                            </Grid>

                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    datatest={'cancelButton'}
                    onClick={handleCancel}
                    variant={"outlined"}
                    color="secondary"
                >Cancel</Button>
                <div style={{flexGrow: '1'}}/>
                <Button
                    datatest={'addButton'}
                    startIcon={<AddIcon/>}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => this.handleAdd(false)}
                >{addButtonTitle || 'Add'}</Button>
                <Button
                    datatest={'addMultipleButton'}
                    startIcon={<QueueIcon/>}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => this.handleAdd(true)}
                >{addButtonTitle || 'Multiple'}</Button>
            </DialogActions>
        </Dialog>;
    }
}

AddClassDialog.propTypes = {
    parentClassID: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    ontologyClasses: PropTypes.array,
    ontologyProperties: PropTypes.array,
    title: PropTypes.string,
    addButtonTitle: PropTypes.string,
    helpText: PropTypes.string,
    open: PropTypes.bool,
    showExisting: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AddClassDialog);

import React, {useState} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {
    centerVertically,
    getLocalName,
    getPropertyName,
    isArrayOnly,
    isEmptyArray,
    isObjectOnly,
    sort,
    toArray
} from "../../components/util";
import {DATATYPE_SUGGESTIONS, LANG, TYPE, VALUE} from "../../Constants";
import {Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography} from "@material-ui/core";
import {getResourceUrl, getValuesObject} from "./SearchResultItem";
import {CodeOutlined, EditAttributesOutlined, PageviewOutlined} from "@material-ui/icons";
import H3Title from "../../components/H3Title";
import {
    isAnyValueIdObject,
    isDatatypePropertyOrValue,
    isLangValue,
    langObjectToArray,
    langValueArray,
    StringValue
} from "./Tree";
import {getSourceResourceDetails} from "./AddDataPropertyValueDialog";
import Button from "@material-ui/core/Button";
import {isLangProperty} from "../apiplayground/SearchFilter";
import FeedbackWidget from "./FeedbackWidget";
import AddFeedbackDialog from "./AddFeedbackDialog";
import {cloneDeep} from "lodash";
import {isFeedbackEnabled} from "./BasicSetup";

const styles = {
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
};

export function RenderPropertyValueWithFeedback({index, sourceResource, value, theme, columnKey, location, aliasesToIRIMap, ontology, browseLanguage, settings, aliasesMap, configurations, context, customizations}) {
    const [focus, setFocus] = useState(false);
    const [addFeedback, setAddFeedback] = useState(false);
    let {langCode, datatype, chipValue} = value;

    const handleFocusOff = () => {
        if(focus === true) {
            setFocus(false);
        }
    }

    const handleFocusOn = () => {
        if(focus === false) {
            setFocus(true);
        }
    }
    let contextClone = context ? cloneDeep(context) : {} ;
    if(context) {
        contextClone.dataValue = {
            [VALUE] : chipValue,
            [LANG] : langCode,
            [TYPE] : datatype,
        };
        contextClone[LANG] = langCode;
        contextClone[TYPE] = datatype;
        contextClone[VALUE] = chipValue;
    }

    return <div
        datatest={'dataValueBlock'}
        key={index}
        style={{
            marginBottom: '8px',
            padding: '8px',
            borderRadius: '4px',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderColor: theme.palette.grey.level2,
            backgroundColor: theme.palette.white.main,
            display : 'flex'
        }}
        onFocus={handleFocusOn}
        onBlur={handleFocusOff}
        onMouseEnter={handleFocusOn}
        onMouseLeave={handleFocusOff}
        tabIndex={0}
    >
        {
            columnKey === TYPE
                ? <>

                    <div style={{display: 'flex'}}>
                        <div style={{flexGrow: 1}}></div>
                        <a style={{cursor: 'pointer'}} datatest={'link'}
                           href={getResourceUrl(location, aliasesToIRIMap[chipValue] || chipValue)}
                           target={"_blank"}>
                            <IconButton style={{
                                marginRight: '8px',
                                color: theme.palette.link.main
                            }} size={'small'}>
                                <PageviewOutlined style={{color: theme.palette.link.main}}/>
                            </IconButton>
                        </a>
                    </div>
                    <div style={{display: 'flex'}}>
                        <H3Title noWrap={true}
                                 title={getPropertyName(aliasesToIRIMap, chipValue, ontology, browseLanguage)}></H3Title>
                    </div>
                    <div style={{display: 'flex', margin: '8px 0px'}}>
                        <CodeOutlined color={'secondary'} style={{marginRight: '8px'}}/>
                        {
                            centerVertically(<Typography noWrap={true} color={'primary'}
                                                         component={'div'}>{chipValue}</Typography>,
                                {maxWidth: 'calc(100% - 48px)'})
                        }
                    </div>
                    <div style={{display: 'flex'}}>
                        {
                            centerVertically(
                                <Tooltip title={aliasesToIRIMap[chipValue] || chipValue}>
                                    <Typography noWrap={true} color={'primary'}
                                                component={'div'}>{getLocalName(aliasesToIRIMap[chipValue] || chipValue)}</Typography>
                                </Tooltip>,
                                {maxWidth: 'calc(100% - 48px)'}
                            )
                        }
                    </div>
                </>
                : <StringValue settings={settings} theme={theme} value={chipValue} langCode={langCode}
                               datatype={datatype} customizations={customizations}/>
        }
        {
            context && <>
                <div style={{flexGrow :1}}></div>
                {
                    focus && isFeedbackEnabled(settings) ? centerVertically(
                        <FeedbackWidget
                            onClick={(ev) => {
                                ev.preventDefault();
                                ev.stopPropagation();
                                setAddFeedback(true)
                            }}
                            settings={settings}
                            location={location}
                            aliasesToIRIMap={aliasesToIRIMap}
                            ontology={ontology}
                            aliasesMap={aliasesMap}
                            browseLanguage={browseLanguage}
                            configurations={configurations}
                        />, {marginLeft: '8px'}
                    ) : <div style={{minHeight : '30px'}}></div>
                }
                {
                    addFeedback && <AddFeedbackDialog
                        location={location}
                        context={ contextClone}
                        parentObject={sourceResource}
                        shapeProperty={contextClone.shapeProperty}
                        nodeValue={{}}
                        ontology={ontology}
                        browseLanguage={browseLanguage}
                        aliasesToIRIMap={aliasesToIRIMap}
                        aliasesMap={aliasesMap}
                        settings={settings}
                        onClose={() => {
                            setFocus(false);
                            setAddFeedback(false);
                        }}
                        onSaveSuccess={() => {
                            setAddFeedback(false);
                        }}
                    />
                }
            </>
        }

    </div>;

}
export function RenderPropertyValue({sourceResource, dataProperty, propertyShape, theme, columnKey, location, aliasesToIRIMap, ontology, browseLanguage, settings, aliasesMap, configurations, context, customizations}) {
    let value = sourceResource[dataProperty];
    let valueArray;
    if (isLangProperty(propertyShape) && !isLangValue(value)) {
        valueArray = langValueArray(value);
    } else {
        let isArrayValue = isArrayOnly(value);
        let isObjectValue = isObjectOnly(value);
        if(isDatatypePropertyOrValue(value, propertyShape, columnKey, ontology, aliasesToIRIMap) || isLangValue(value)) {
            valueArray = toArray(value);
        } else if (isObjectValue || (isArrayValue && !isEmptyArray(value) && isObjectOnly(value[0]) && !isAnyValueIdObject(value))) {
            valueArray = langObjectToArray(value);
        } else {
            valueArray = toArray(value);
        }
    }
    if (isEmptyArray(valueArray)) {
        return <></>;
    }
    valueArray = valueArray.map((v, i) => {
        let langCode = v.lang;
        let datatype = DATATYPE_SUGGESTIONS.find(s => s.value === v.type)?.value;
        let chipValue = v.value || v;
        return {
            langCode,
            datatype,
            chipValue
        };
    });
    let sortedValueArray = sort(valueArray, 'chipValue');
    sortedValueArray = sort(sortedValueArray, 'langCode');

    return <div>
        {
            sortedValueArray.map((v, i) => {
                return <RenderPropertyValueWithFeedback
                    key={i}
                    index={i}
                    value={v}
                    sourceResource={sourceResource}
                    dataProperty={dataProperty}
                    propertyShape={propertyShape}
                    theme={theme}
                    columnKey={columnKey}
                    location={location}
                    aliasesToIRIMap={aliasesToIRIMap}
                    ontology={ontology}
                    browseLanguage={browseLanguage}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    configurations={configurations}
                    context={context}
                    customizations={customizations}
                />
            })}</div>;
}

class ViewDataValueDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let {sourceResource, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        getValuesObject(sourceResource, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
            let {title} = vo;
            this.setState({sourceResourceTitle : title})
        })
    }

    renderDatatypePropertyValue = () => {
        let {theme, settings, propertyShape, aliasesMap, configurations, browseLanguage, sourceResource, dataProperty, columnKey, aliasesToIRIMap, ontology, location, context} = this.props;
        return <RenderPropertyValue
            sourceResource={sourceResource}
            dataProperty={dataProperty}
            propertyShape={propertyShape}
            theme={theme}
            columnKey={columnKey}
            location={location}
            aliasesToIRIMap={aliasesToIRIMap}
            ontology={ontology}
            browseLanguage={browseLanguage}
            settings={settings}
            aliasesMap={aliasesMap}
            configurations={configurations}
            context={context}
        />;

    }

    render() {
        let {theme, onClose, classes, sourceResource, aliasesToIRIMap, dataProperty, ontology, browseLanguage} = this.props;
        let {sourceResourceTitle} = this.state;

        return <Dialog
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            classes={{ paper: classes.dialogPaper }}
            onEscapeKeyDown={onClose}
            datatest={'viewDataValueDialog'}
        >
            <DialogTitle id="form-dialog-title">
                <div style={{display : 'flex'}}>
                    {getSourceResourceDetails(sourceResourceTitle, sourceResource, getPropertyName(aliasesToIRIMap, dataProperty, ontology, browseLanguage),
                        <EditAttributesOutlined style={{marginRight: '4px', color: theme.palette.primary.main}}/>)}
                </div>
            </DialogTitle>
            <DialogContent style={{backgroundColor : theme.palette.grey.background}}>
                { this.renderDatatypePropertyValue()}
            </DialogContent>
            <DialogActions>
                <div style={{flexGrow : '1'}}></div>
                <Button
                    datatest={'closeButton'}
                    variant={"contained"}
                    color="secondary"
                    onClick={onClose}
                >Close</Button>
            </DialogActions>
        </Dialog>;

    }

}

ViewDataValueDialog.propTypes = {
    location : PropTypes.any,
    configurations: PropTypes.any,
    settings: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    aliasesMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    sourceResource: PropTypes.any,
    dataProperty: PropTypes.any,
    propertyShape: PropTypes.any,
    ontology: PropTypes.any,
    theme: PropTypes.any,
    onClose: PropTypes.any,
}

export default withStyles(styles, {withTheme:true})(ViewDataValueDialog);

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {getAllConfigurations, putConfiguration} from "../../service/graph-api";
import {
  centerVertically,
  getApiConfigurationResource,
  getMaxLengthMessage,
  getRequiredFieldMessage,
  getSystemContextURL,
  isValidBaseIRILength,
  isValidPrefix,
  isValidPrefixLength,
  isValidProtocol,
  restrictMaximumCharacters,
  searchInTree,
  toArray
} from "../../components/util";
import queryString from 'query-string'
import {styles} from "../../components/styles";
import {
  ALIAS_SYS_BASE_IRI,
  ALIAS_SYS_PREFIX,
  ALIAS_SYS_PREFIX_MAPPING,
  APPLICATION_NAME,
  AT_CONTEXT,
  CONFIGURE_CONTAINERS,
  PREFIXES,
  STYLE_GRID_ITEM_SPACING,
  SYSTEM_DEFINED_PREFIXES,
  SYSTEM_MANAGER,
  VALIDATION_BASE_IRI_MAX_LENGTH,
  VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL,
  VALIDATION_PREFIX_MAX_LENGTH
} from "../../Constants";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import MenuColumnAndDetailsLayout from "../../layouts/MenuColumnAndDetailsLayout";
import {getLeftMenuItems} from "../../layouts/systemmanager/Home";
import {Grid, IconButton, Paper} from "@material-ui/core";
import TextField from "../../components/TextField";
import H1Title from "../../components/H1Title";
import {DeleteOutlined} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";


class Prefixes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeData: [],
      focusNode: null,
      loading: false,
      ontology: [],
      viewType: 'tree',
    }
  }

  componentDidMount() {
    this.syncDataWithBackend()
  }

  syncDataWithBackend = () => {
    this.setState({loading: true})
    const configurations = getAllConfigurations()
    configurations.then((response) => {
      let apiConfigResource = getApiConfigurationResource(response);
      let prefixesMapping = apiConfigResource[ALIAS_SYS_PREFIX_MAPPING];

      this.setState({
        prefixesMapping,
        loading:false,
        apiConfigResource
      })
    }).catch((e) => {
      this.setState({loading: false, apiErrorResponse: e, apiError: true});
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let params = queryString.parse(this.props.location.search)
    if(!prevState.focusNode || params.id !== prevState.focusNode.id) {
      let focusNode = searchInTree(params.id, this.state.treeData)
      if (focusNode) {
        this.setState({focusNode: focusNode})
      }
    }
  }

  handleDelete = async (toDelete) => {
    let {apiConfigResource, prefixesMapping} = this.state;
    this.setState({loading: true});
    apiConfigResource[AT_CONTEXT] = getSystemContextURL();
    const prefixesToKeep = this.getUniqueSortedPrefixesArray().filter(p => p !== toDelete[ALIAS_SYS_PREFIX]);
    apiConfigResource[ALIAS_SYS_PREFIX_MAPPING] = prefixesToKeep.map(p => toArray(prefixesMapping).find(pm => pm[ALIAS_SYS_PREFIX] === p)).filter(p => p);
    await putConfiguration(apiConfigResource);
    await this.syncDataWithBackend();
  }

  handleAdd = async (prefix, baseIRI) => {
    let {apiConfigResource, prefixesMapping} = this.state;
    this.setState({loading: true});
    apiConfigResource[AT_CONTEXT] = getSystemContextURL();
    const prefixesToKeep = this.getUniqueSortedPrefixesArray();
    apiConfigResource[ALIAS_SYS_PREFIX_MAPPING] = prefixesToKeep.map(p => toArray(prefixesMapping).find(pm => pm[ALIAS_SYS_PREFIX] === p)).filter(p => p);
    apiConfigResource[ALIAS_SYS_PREFIX_MAPPING].push({
      [ALIAS_SYS_PREFIX] : prefix,
      [ALIAS_SYS_BASE_IRI] : baseIRI,
    })
    await putConfiguration(apiConfigResource);
    await this.syncDataWithBackend();
  }

  getUniqueSortedPrefixesArray = () => {
    const {prefixesMapping} = this.state;
    const set = new Set(toArray(prefixesMapping).map(m => m[ALIAS_SYS_PREFIX]));
    const sorted = [...set].sort();
    return sorted;
  }

  getConfigurePrefixes =  () => {
    const {theme} = this.props;
    const {prefixesMapping} = this.state;
    const sorted = this.getUniqueSortedPrefixesArray();
    return <div style={{height: 'calc(100% - 48px)', display: 'flex', flexDirection: 'column'}}>
      <div>
        <Grid xs container spacing={2}>
      <Grid item xs={12}>
        <H1Title title={PREFIXES} color={'primary'}/>
      </Grid>
      {toArray(sorted).map(k => {
        let i = toArray(prefixesMapping).find(pm => pm[ALIAS_SYS_PREFIX] === k);
        const prefix = i[ALIAS_SYS_PREFIX];
        return <Grid datatest={'prefix-'+i[ALIAS_SYS_BASE_IRI]} key={i[ALIAS_SYS_BASE_IRI]} item sm={12}>
          <Paper elevation={0} style={{display : 'flex', padding: theme.spacing(0), backgroundColor: theme.palette.border.main}}>
            <Grid container spacing={0}>
              <Grid item xs={3}>
                <TextField
                  label={'Prefix'}
                  value={prefix}
                  disableErrorRendering={true}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={9}>
                <TextField label={'Base IRI'} key={i} value={i[ALIAS_SYS_BASE_IRI]} readOnly={true}/>
              </Grid>
            </Grid>
            {
              centerVertically(
              <div style={{width: '56px'}}>
                <IconButton disabled={SYSTEM_DEFINED_PREFIXES[k]} onClick={() => this.handleDelete(i)}><DeleteOutlined></DeleteOutlined></IconButton>
              </div>, {marginTop : '22px'}
              )
            }
          </Paper>

        </Grid>
      })}
          <Grid item sm={12}>
            <Button
                variant={'contained'}
                color={'secondary'}
                onClick={() => {
                  this.setState({showAddDialog : true});
                }}
            >Add Prefix</Button>
          </Grid>
        </Grid>
      </div>
    </div>;
  }


  getMiddleComponent = () => {
    const {prefixesToIRIMap, loading} = this.state;
    return loading
        ? <ProcessingBackdrop loading={true}/>
        : this.getConfigurePrefixes();
  }

  handleFieldChange = (event) => {
    const {target: {name, value}} = event;
    this.setState({
      [name]: value
    });
  };

  validateBaseIRI= (value, label) => {
    let {prefixesMapping} = this.state;
    if(!value) {
      return getRequiredFieldMessage(label);
    } else if (toArray(prefixesMapping).find(pm => pm[ALIAS_SYS_BASE_IRI] === value)) {
      return "Prefix is already defined for this base IRI.";
    } else if (!isValidBaseIRILength(value)) {
      return getMaxLengthMessage(VALIDATION_BASE_IRI_MAX_LENGTH);
    } else if (!/http[s]?:\/\/[^ "#\/]+\.[a-z]{2,6}\b[-a-zA-Z0-9@:%_\+.~//=]*[\/#]$/.test(value)) {
      if(!isValidProtocol(value)) {
        return VALIDATION_MESSAGE_INVALID_BASE_IRI_PROTOCOL;
      } else if(!["/", "#"].includes(value[value.length -1])) {
        return "Should end with / or #.";
      } else {
        return 'Invalid base IRI. Format should be http(s)://{domain}{path}{/ or #}';
      }
    }  else {
      return undefined;
    }

  }


  render() {
    const {showAddDialog, prefix, prefixError, baseIRI, baseIRIError, prefixesMapping} = this.state;
    const {theme, location} = this.props;
    return (
        <React.Fragment>
          {
              showAddDialog &&
              <Dialog
                  fullWidth={true}
                  maxWidth={'sm'}
                  open={true}
                  aria-labelledby="form-dialog-title"
              >
                <DialogTitle id="form-dialog-title">
                  <H2Title title={'Add Prefix'}/>
                </DialogTitle>
                <DialogContent>
                  <Grid container spacing={3}>
                    <Grid item sm={12}>
                      <TextField
                          label={'Prefix'}
                          id='prefix'
                          name='prefix'
                          value={prefix}
                          error={prefixError}
                          onChange={(event) => {
                            this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_PREFIX_MAX_LENGTH))
                            const {target: {value}} = event;
                            if (!value) {
                              this.setState({prefixError: getRequiredFieldMessage('Prefix')});
                            } else if (SYSTEM_DEFINED_PREFIXES[value]) {
                              let error = `Prefix value '${value}' is used by ${APPLICATION_NAME}. Please use some other value.`;
                              this.setState({prefixError: error});
                            } else if (toArray(prefixesMapping).find(pm => pm[ALIAS_SYS_PREFIX] === value)) {
                              const pm = toArray(prefixesMapping).find(pm => pm[ALIAS_SYS_PREFIX] === value);
                              this.setState({prefixError: "Prefix is already used for base IRI '"+pm[ALIAS_SYS_BASE_IRI]+"'."});
                            } else if(!isValidPrefixLength(value)) {
                              let error = getMaxLengthMessage(VALIDATION_PREFIX_MAX_LENGTH);
                              this.setState({prefixError: error});
                            } else if(!isValidPrefix(value)) {
                              let error = 'Please use lower case letters or numbers only. The first character cannot be a number.'
                              this.setState({prefixError: error});
                            } else {
                              this.setState({prefixError : ''});
                            }

                          }}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                          label={'Base IRI'}
                          id='baseIRI'
                          name='baseIRI'
                          value={baseIRI}
                          error={baseIRIError}
                          onChange={(event) => {
                            this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_BASE_IRI_MAX_LENGTH));
                            const {target: {value}} = event;
                            const validateBaseIRI1 = this.validateBaseIRI(value, 'Base IRI');
                            if(validateBaseIRI1) {
                              this.setState({baseIRIError: validateBaseIRI1});
                            } else {
                              this.setState({baseIRIError: ''});
                            }
                          }}

                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button datatest={'cancelButton'} variant={"outlined"} onClick={() => {
                    this.setState({showAddDialog : undefined, prefix : '', prefixError :'', baseIRI :'', baseIRIError : '' });
                  }} color="secondary">Cancel</Button>
                  <Button datatest={'saveButton'} style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"} color="secondary" onClick={async () => {
                    await this.handleAdd(prefix, baseIRI);
                    this.setState({showAddDialog : undefined, prefix : '', prefixError :'', baseIRI :'', baseIRIError : '' });
                  }}>Save</Button>
                </DialogActions>
              </Dialog>
          }
          <MenuColumnAndDetailsLayout
              apiError={this.state.apiError}
              apiErrorResponse={this.state.apiErrorResponse}
              onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}

              headerTitle={SYSTEM_MANAGER}
              leftMenuItems={getLeftMenuItems(theme)}
              leftColumnTitle={CONFIGURE_CONTAINERS}
              detailRenderer={this.getMiddleComponent}
              location={location}
          />
        </React.Fragment>
    );

  }
}

export default withStyles(styles, { withTheme: true })(Prefixes);

import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {traceRenderStart} from "../../components/Trace";
import {Grid} from "@material-ui/core";
import TextField from "../../components/TextField";
import {
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ID_LABEL,
    IRI_MANAGEMENT_DESCRIPTION,
    IRI_MANAGEMENT_LABEL,
    IRI_MANAGEMENT_PASSWORD,
    IRI_MANAGEMENT_USERNAME, VALIDATION_COMMON_LABEL_REGEX, VALIDATION_DATASET_LABEL_REGEX,
    VALIDATION_DESCRIPTION_LENGTH,
    VALIDATION_LANGUAGE_NAME_LENGTH, VALIDATION_MESSAGE_INVALID_COMMON_LABEL
} from "../../Constants";
import {
    getMaxLengthMessage, getShaclValidationFor, handleBackendError, isClientError,
    isValidMaxLengthWithoutTrim,
    restrictMaximumCharacters,
    validateRequiredAndMaxLength
} from "../../components/util";
import H2Title from "../../components/H2Title";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";

const styles = {
    dialogPaper: {
        minWidth: '600px',
        maxWidth: '600px',
        minHeight: '68vh'
    },
};

function initialState() {
    return {
        open: false,
        loading: false,
        name: '',
        description: '',
        nameError: '',
        descriptionError: '',
    };
}

const COMPONENT = 'AddDatasetDialog'
class AddDatasetDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name || '',
            description: props.description || ''
        }
    }

    shouldCreateBeDisabled = () => {
        let {name, nameError, description, descriptionError} = this.state;
        let propName = this.props.name;
        let propDescription = this.props.description;
        if(!name || nameError || descriptionError) {
            return true;
        }
        if(propName === name && propDescription === description) {
            return true;
        }
        return false;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    onFieldChange = (label, errorKey, length, validator) => (event) => {
        this.handleFieldChange(restrictMaximumCharacters(event, length))
        const {target: {value}} = event;
        let error = validator ? validator(value, label, length) : '';
        if(error) {
            this.setState({[errorKey]: error});
        } else {
            if (!VALIDATION_DATASET_LABEL_REGEX.test(value)) {
                this.setState({[errorKey]: VALIDATION_MESSAGE_INVALID_COMMON_LABEL});
            } else {
                this.setState({[errorKey]: ''});
            }
        }
    }

    handleSubmit = () => {
        let {handleOk} = this.props;
        let {name, description} = this.state;

        let obj = {[ALIAS_SYS_ID_LABEL]: name, [ALIAS_SYS_DESCRIPTION]: description};
        this.setState({loading: true});
        handleOk(obj).then((response) => {
            if(response) {
                if (response.status === 409) {
                    let nameError = "This dataset label is already used. Please try another.";
                    this.setState({loading: false, nameError: nameError});
                } else if (response.status === 400) {
                    response.json().then(json => {
                        let nameError = getShaclValidationFor(json, IRI_MANAGEMENT_LABEL).join("\n");
                        let descriptionError = getShaclValidationFor(json, IRI_MANAGEMENT_DESCRIPTION).join("\n");
                        if (nameError || descriptionError) {
                            this.setState({loading: false, nameError, descriptionError});
                        } else {
                            handleBackendError(this)(json);
                        }
                    })
                } else if (isClientError(response)) {
                    handleBackendError(this)(response);
                } else {
                    this.setState(initialState());
                }
            } else {
                this.setState(initialState());
            }
        });
    };


    render() {
        traceRenderStart('', COMPONENT)

        let {classes, edit, addButtonTitle, handleCancel, handleOk, title, open, helpText} = this.props
        let {loading, name, nameError, description, descriptionError} = this.state
        return  <Dialog
            id={'addNewSchemaDialog'}
            aria-labelledby="form-dialog-title"
            open={open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={title}/></DialogTitle>
            <DialogContent>
                {loading && <ProcessingBackdrop loading={loading}/>}
                <Grid container spacing={1}>
                    {helpText && <Grid item xs={12}>{helpText}</Grid>}
                    <Grid item xs={12}>
                        <TextField
                            readOnly={edit}
                            autoFocus={true}
                            label={'Name'}
                            id='name'
                            name='name'
                            value={name}
                            error={nameError}
                            onChange={this.onFieldChange('Name', 'nameError', VALIDATION_LANGUAGE_NAME_LENGTH, validateRequiredAndMaxLength)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            datatest={'textareaField-Description'}
                            label={'Description'}
                            id='description'
                            name='description'
                            value={description}
                            error={descriptionError}
                            multiline={true}
                            rowsMax="4"
                            rows="4"
                            onChange={(event) => {
                                this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_DESCRIPTION_LENGTH))
                                const {target: {value}} = event;
                                if (!isValidMaxLengthWithoutTrim(value, VALIDATION_DESCRIPTION_LENGTH)) {
                                    this.setState({descriptionError: getMaxLengthMessage(VALIDATION_DESCRIPTION_LENGTH)})
                                } else {
                                    this.setState({descriptionError: ''})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                {handleCancel && <Button onClick={handleCancel} variant={"outlined"} color="secondary">Cancel</Button>}
                <Button
                    datatest={'addButton'}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={this.handleSubmit}
                >{addButtonTitle || 'Add'}</Button>
            </DialogActions>
        </Dialog>;
    }

}

AddDatasetDialog.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    addButtonTitle: PropTypes.string,
    helpText: PropTypes.string,
    open: PropTypes.bool,
    edit: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AddDatasetDialog);

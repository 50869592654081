import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "./styles";
import {Grid} from "@material-ui/core";
import {centerVertically, getHeaderMap, getUiLabelTranslationFromContext, stringifyPatchReqBody} from "./util";
import JSONEditorReact from "./JSONEditorReact";
import KeyValue from "./KeyValue";
import FieldContainer from "./FieldContainer";
import InstructionForAction from "../components/InstructionForAction";
import H2Title from "../components/H2Title";
import ResponseStatusBar from "../components/ResponseStatusBar";
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/javascript/javascript';
import H4Title from "../components/H4Title";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import {
    UI_LABELS_REQUEST_NOT_SENT_HELP,
    UI_LABELS_REQUEST_NOT_SENT_HELP_VALUE, UI_LABELS_RESPONSE,
    UI_LABELS_RESPONSE_BODY, UI_LABELS_RESPONSE_HEADERS
} from "../layouts/navigator/UILabel";
import GlobalsContext from "./GlobalsContext";

class Response extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {theme, hasError, leftMenuMinimized, response, responseJSON} = this.props;
        let headerMap = response ? getHeaderMap(response) : []
        let json = stringifyPatchReqBody(leftMenuMinimized, responseJSON)
        return <FieldContainer style={{ padding : '8px', backgroundColor: theme.palette.white.main}}>{
            response
                ? <>
                    <ResponseStatusBar style={{marginBottom: theme.spacing(1)}} hasError={hasError} status={response.status}/>
                    <FieldContainer datatest={'responseHeaders'} style={{marginBottom: theme.spacing(1)}}>
                        <H4Title title={getUiLabelTranslationFromContext(this, UI_LABELS_RESPONSE_HEADERS)}/>
                        <Grid container spacing={1}>
                            {Object.keys(headerMap).map(k => <KeyValue  readOnly={true} key={k} label={k} value={headerMap[k]}/>)}
                        </Grid>
                    </FieldContainer>
                    <FieldContainer>
                        <H4Title title={getUiLabelTranslationFromContext(this, UI_LABELS_RESPONSE_BODY)}/>
                        {responseJSON && <JSONEditorReact
                            datatest={'responseJSON'}
                            modes={['code']}
                            enableSort={false}
                            text={json}
                            indentation={4}
                            history={false}
                            height={'100%'}
                            expandAll={true}
                            search={false}
                            enableTransform={false}
                            onEditable={() => {
                                return false
                            }}
                        />
                        }
                    </FieldContainer>
                </>
                : <div style={{minHeight: '250px'}}>
                    {
                        centerVertically(<InstructionForAction>
                            <PlayArrowIcon
                                style={{
                                    borderRadius: '50%',
                                    backgroundColor: theme.palette.grey.main,
                                    border: '1px solid rgba(158 158 158 .1)',
                                    padding: '4px',
                                    fontSize: '24px',
                                    color: theme.palette.secondary.main
                                }}
                            />
                            <H2Title title={getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST_NOT_SENT_HELP, UI_LABELS_REQUEST_NOT_SENT_HELP_VALUE)}/>
                        </InstructionForAction>)
                    }
                </div>
        }</FieldContainer>;
    }

}

Response.propTypes = {
    classes: PropTypes.object.isRequired,
    response: PropTypes.object,
    responseJSON: PropTypes.object,
    resTabValue: PropTypes.number,
    resTabChange: PropTypes.func,
    onResChangeText: PropTypes.func,
    selectedItemsIds: PropTypes.array,
    onItemSelectionChange: PropTypes.func,
    leftMenuMinimized: PropTypes.bool,
    hasError: PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(Response);

import React from 'react';
import PropTypes from "prop-types";
import {Tooltip, withStyles} from "@material-ui/core";
import withWidth from '@material-ui/core/withWidth';
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {isArray} from 'lodash';

const styles = theme => ({
});

class HelpIcon extends React.Component {
    constructor(props) {
        super(props)
        this.state = {};
    }

    render() {
        const {helpText} = this.props;
        const {helpOpen} = this.state;

        return <>{
            helpText &&
            <Tooltip
                title={
                    <div style={{padding : '8px'}}>{
                        isArray(helpText) && helpText.length > 0 ? helpText.map((t, i) => <div key={i}>{t}</div>) : <div>{helpText}</div>
                    }</div>
                }
                interactive={true}
                placement="left-start"
                onOpen={() => this.setState({helpOpen: true})}
                onClose={() => this.setState({helpOpen: false})}
            >
                <IconButton size={"small"} color={helpOpen ? 'secondary' : 'default'} aria-label="help">
                    <HelpOutlineIcon/>
                </IconButton>
            </Tooltip>
        }</>;
    }
}

HelpIcon.propTypes = {
    helpText: PropTypes.any
};

export default withWidth()(withStyles(styles, {withTheme: true})(HelpIcon));

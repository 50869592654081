import {EVENT_SHACL_VALIDATE, LANG, TYPE, VALUE} from "../../Constants";
import {
    computeMaxLength,
    computeMinCount,
    computeMinLength,
    getRegEx,
    isObjectOnly,
    isValidIRI,
    toArray
} from "../util";
import {
    getUiLabelTranslationFrom,
    UI_LABELS_VALIDATION_CHARACTERS_ALLOWED,
    UI_LABELS_VALIDATION_CHARACTERS_REQUIRED,
    UI_LABELS_VALIDATION_INVALID_DATATYPE_VALUE,
    UI_LABELS_VALIDATION_INVALID_VALUE,
    UI_LABELS_VALIDATION_MAXIMUM,
    UI_LABELS_VALIDATION_MINIMUM,
    UI_LABELS_VALIDATION_REQUIRED
} from "../../layouts/navigator/UILabel";


export function validateFromSHACL(eventName, property, valueIndex, customizations, fieldRef) {
    if(eventName === EVENT_SHACL_VALIDATE) {
        let minCount = computeMinCount(property);
        let indexToUse = valueIndex || 0;
        let valueToValidate = toArray(property[VALUE])[indexToUse];
        let language;
        let datatype;
        if(isObjectOnly(valueToValidate)) {
            if(valueToValidate[TYPE] || valueToValidate[LANG]) {
                language = valueToValidate[LANG];
                datatype = valueToValidate[TYPE];
                valueToValidate = valueToValidate[VALUE];
            } else {
                //deal with language object e.g. {"en" : "some value"}
                let key = Object.keys(valueToValidate)[0];
                valueToValidate = valueToValidate[key];
            }
        }
        if(datatype && isValidIRI(datatype) === false) {
            let uiLabelTranslationFrom = getUiLabelTranslationFrom(UI_LABELS_VALIDATION_INVALID_DATATYPE_VALUE, customizations);
            return {
                ref : fieldRef,
                message : uiLabelTranslationFrom
            };
        }
        let isValueSet = valueToValidate ? true : false;
        if(minCount !== undefined && isValueSet === false ) {
            let uiLabelTranslationFrom = getUiLabelTranslationFrom(UI_LABELS_VALIDATION_REQUIRED, customizations);
            return {
                ref : fieldRef,
                message : uiLabelTranslationFrom
            };
        }
        let minLength = computeMinLength(property);
        if(minLength !== undefined && valueToValidate.length < minLength) {
            let uiLabelTranslationFrom = `${getUiLabelTranslationFrom(UI_LABELS_VALIDATION_MINIMUM, customizations)} ${minLength} ${getUiLabelTranslationFrom(UI_LABELS_VALIDATION_CHARACTERS_REQUIRED, customizations)}`;
            return {
                ref : fieldRef,
                message : uiLabelTranslationFrom
            };
        }
        let maxLength = computeMaxLength(property);
        if(maxLength !== undefined && valueToValidate.length > maxLength) {
            let uiLabelTranslationFrom = `${getUiLabelTranslationFrom(UI_LABELS_VALIDATION_MAXIMUM, customizations)} ${maxLength} ${getUiLabelTranslationFrom(UI_LABELS_VALIDATION_CHARACTERS_ALLOWED, customizations)}`;
            return {
                ref : fieldRef,
                message : uiLabelTranslationFrom
            };
        }
        let regExp = getRegEx(property);
        if(regExp !== undefined && !new RegExp(toArray(regExp)[0]).test(valueToValidate)) {
            let uiLabelTranslationFrom = `${getUiLabelTranslationFrom(UI_LABELS_VALIDATION_INVALID_VALUE, customizations)}`;
            return {
                ref : fieldRef,
                message : uiLabelTranslationFrom
            };
        }

        return;
    }
}
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {getAllConfigurations, putConfiguration} from "../../service/graph-api";
import {
    getAliasesMap,
    getApiConfigurationResource,
    getSortedInverseNodes,
    searchInTree,
    toArray
} from "../../components/util";
import queryString from 'query-string'
import {styles} from "../../components/styles";
import {
    ALIAS_OWL_INVERSE_OF,
    ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION,
    AT_CONTEXT,
    INVERSE_PROPERTIES,
    SYSTEM_MANAGER
} from "../../Constants";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import MenuColumnAndDetailsLayout from "../../layouts/MenuColumnAndDetailsLayout";
import {getLeftMenuItems} from "../../layouts/systemmanager/Home";
import {Grid, Tooltip} from "@material-ui/core";
import H1Title from "../../components/H1Title";
import Label from "../../components/Label";
import FieldContainer from "../../components/FieldContainer";
import {DashBoardSearch} from "../../components/DashBoardSearch";
import Checkbox from "@material-ui/core/Checkbox";
import {canUpdateConfigs} from "../common/Profile";

export function InverseView({theme, treeData, aliasesMap, inverseExclusion, searchText, onSearchTextChange, onChange}) {
    let allInverseProps = treeData
        ? treeData.filter(d => {
            if(searchText) {
                return d.searchTitle.includes(searchText.toLowerCase());
            } else {
                return true;
            }
        })
        : []
    return <FieldContainer style={{maxWidth : '800px'}}>
        <Grid container={true} spacing={1}>
            <Grid container item xs={12}>
                <DashBoardSearch
                    placeholder={'Find'}
                    onSearch={() => onSearchTextChange(searchText)}
                    onSearchTextChange={(val) => {
                        onSearchTextChange(val);
                    }}
                    searchText={searchText}
                    style={{minWidth: '100%', maxWidth: '100%'}}
                />
            </Grid>
            <Grid style={{marginTop: '8px'}} container item xs={12}>
                <Grid item xs={8}><Label label={'Inverse Properties'}/></Grid>
                <Grid item xs={4}><Label label={'Materialise'}/></Grid>
            </Grid>
            {
                allInverseProps && allInverseProps.map(d => {
                    let isExcluded = inverseExclusion.find(ex => ex === d.id || ex === d[ALIAS_OWL_INVERSE_OF]);
                    let checked = isExcluded ? false : true;
                    return <Grid datatest={'inverseCard'} container item xs={12}>
                        <FieldContainer datatest={'inverseCard-'+d.displayTitle} style={{
                            padding: '8px',
                            width: '100%',
                            backgroundColor: theme.palette.white.main
                        }}>
                            <Grid container item xs={12}>
                                <Grid
                                    container item
                                    justify="center"
                                    direction="column" xs={8}
                                >
                                    <Tooltip
                                        placement={"bottom"}
                                        title={d.tooltip || ""}
                                    >
                                        <span>{d.displayTitle}</span>
                                    </Tooltip>
                                </Grid>
                                <Grid style={{
                                    borderLeft: '1px solid',
                                    borderLeftColor: theme.palette.border.main
                                }} item xs={4}>
                                    <Checkbox
                                        disabled={!canUpdateConfigs()}
                                        datatest={'checkbox'}
                                        value={d.id}
                                        name={'inverseExclusion'}
                                        onChange={onChange}
                                        checked={checked}
                                        color={'secondary'}
                                    />
                                </Grid>
                            </Grid>
                        </FieldContainer>
                    </Grid>;
                })
            }

        </Grid>
    </FieldContainer>;

}


export function handleInverseMaterialisationChange(currentExclusionList, value, checked) {
    let isPropExcluded = currentExclusionList.includes(value);
    if (checked && isPropExcluded) {
        let newValues = currentExclusionList.filter(v => v !== value);
        return newValues;
    } else if (!isPropExcluded) {
        let newValues = [...currentExclusionList, value];
        return newValues;
    } else {
        return currentExclusionList;
    }
}

class Inverse extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            focusNode: null,
            loading: false,
            inverseExclusion: []
        }
        this.backendEventHandler = this.backendEventHandler.bind(this);
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
        this.setState({loading: true})
        const ontology = getAllConfigurations()
        ontology.then((response) => {
            let aliasesMap = getAliasesMap(response)
            let treeData = getSortedInverseNodes(response, aliasesMap);
            let focusNode = null;
            if (this.state.focusNode) {
                focusNode = searchInTree(this.state.focusNode.id, treeData)
            }
            let APIConfiguration = getApiConfigurationResource(response)
            let inverseExclusion = APIConfiguration ? APIConfiguration[ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION] : []
            inverseExclusion = toArray(inverseExclusion);
            APIConfiguration[ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION] = inverseExclusion
            this.setState({
                aliasesMap: aliasesMap, treeData: treeData, focusNode: focusNode,
                loading: false, APIConfiguration: APIConfiguration,
                inverseExclusion: inverseExclusion,
                context: response[AT_CONTEXT]
            })
        }).catch((e) => {
            this.setState({loading: false, apiErrorResponse: e, apiError: true});
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let params = queryString.parse(this.props.location.search)
        if (!prevState.focusNode || params.id !== prevState.focusNode.id) {
            let focusNode = searchInTree(params.id, this.state.treeData)
            if (focusNode) {
                this.setState({focusNode: focusNode})
            }
        }
    }

    async backendEventHandler(event) {
        this.setState({loading: true})
        event[AT_CONTEXT] = this.state.context
        await putConfiguration(event)
        this.syncDataWithBackend()
        return
    }

    handleFieldChange = (event) => {
        const {target: {value, checked}} = event
        let config = this.state.APIConfiguration
        let currentValues = config[ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION]
        let newValues = handleInverseMaterialisationChange(currentValues, value, checked);
        config[ALIAS_SYS_INVERSE_PROPERTIES_EXCLUSION] = newValues
        this.backendEventHandler(config).then((r) => {})
        this.setState({inverseExclusion: newValues});
    }

    getMiddleComponent = () => {
        const {theme} = this.props;
        const {treeData, aliasesMap, inverseExclusion, loading, inverseSearchText} = this.state;
        return <>
            {loading && <ProcessingBackdrop loading={loading}/>}
            <div style={{height: 'calc(100% - 48px)', display: 'flex', flexDirection: 'column'}}>

                <div>
                    <Grid xs container spacing={2}>
                        <Grid item xs={12}>
                            <H1Title title={INVERSE_PROPERTIES} color={'primary'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InverseView
                                searchText={inverseSearchText}
                                onSearchTextChange={(val) => this.setState({inverseSearchText: val})}
                                theme={theme}
                                treeData={treeData}
                                aliasesMap={aliasesMap}
                                inverseExclusion={inverseExclusion}
                                onChange={this.handleFieldChange}
                            />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </>
    }

    render() {
        const {theme, location} = this.props;
        return (
            <MenuColumnAndDetailsLayout
                apiError={this.state.apiError}
                apiErrorResponse={this.state.apiErrorResponse}
                onApiErrorClose={() => this.setState({apiError: false, apiErrorResponse: undefined})}

                headerTitle={SYSTEM_MANAGER}
                leftMenuItems={getLeftMenuItems(theme)}
                leftColumnTitle={INVERSE_PROPERTIES}
                leftColumnRenderer={undefined}
                detailRenderer={this.getMiddleComponent}
                location={location}
            />
        );
    }

}

export default withStyles(styles, {withTheme: true})(Inverse);

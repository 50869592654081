import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {styles} from "../components/styles";
import {Grid} from "@material-ui/core";
import StepperHeader from "../components/StepperHeader";
import FinishButton from "../components/FinishButton";
import GridContainer from "../components/GridContainer";
import MainHeaderBar from "../components/MainHeaderBar";
import {
    generateExampleRequestId,
    generateExampleSetId,
    getFileExtension,
    getMaxExampleSetFileSize
} from "../components/util";
import {
    ALIAS_SYS_EXAMPLE_COLLECTION,
    ALIAS_SYS_EXAMPLE_EXAMPLE_SET,
    ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET,
    ID,
    TYPE,
    VALIDATION_SCENARIO_FILE_MAX_COUNT,
    VALIDATION_SCENARIO_FILE_MAX_SIZE
} from "../Constants";
import FileUpload from "../components/FileUpload";
import Examples from "../layouts/apiplayground/Examples";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import H2Title from "../components/H2Title";
import {updateContext} from "../components/ImportExampleSetDialog";
import FooterNextButton from "../components/FooterNextButton";
import FooterPreviousButton from "../components/FooterPreviousButton";

const ALLOWED_TYPES = [".json"]

function initialState() {
    return {
        activeStep : 0,
        open: false,
        loading: false,
        name: '',
        valid : false,
        selectedFiles: [],
        expanded: []

    };
};

class ImportScenarioDialog  extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.isValid() && !this.state.valid) {
            this.setState({valid: true})
        } else if (this.state.valid && !this.isValid()) {
            this.setState({valid: false})
        }
    }

    isValid = () => {
        let {activeStep} = this.state
        switch (activeStep) {
            case 0:
                const {selectedFiles, selectedFilesError} = this.state
                let error = selectedFilesError && selectedFilesError === true ? true : false;
                return (selectedFiles && selectedFiles.length > 0 && !error)
            default:
                return true
        }

    }


    handleClickOpen = (ev) => {
        this.setState({open: true});
        ev.preventDefault();
    };

    handleClose = () => {
        this.props.onClose()
        this.setState({...initialState()});
    };

    getSteps = () => {
        return ['Select Files', 'Preview', 'Upload'];
    }

    isLastStep = () => {
        const steps = this.getSteps();
        const {activeStep} = this.state
        return activeStep === steps.length - 1;
    }

    getFooter = () => {
        const {activeStep, loading} = this.state
        return this.isLastStep()
            ?  <>
                <div style={{flexGrow:1}}/>
                <div>
                    <FinishButton onClick={this.handleClose}/>
                </div>
            </>
            : <>
            <Button variant={"text"} onClick={this.handleClose} color="secondary">
                Cancel
            </Button>
            <div style={{flexGrow:1}}/>
            {
                activeStep !== 0 &&
                <FooterPreviousButton
                    disabled={activeStep === 0 || loading}
                    onClick={this.handleBack}
                />
            }
            {
                <FooterNextButton
                    disabled={!this.isStepContentValid(activeStep) || loading}
                    onClick={this.handleNext}
                />
            }
        </>;
    }

    handleNext = () => {
        let {activeStep, treeData} = this.state
        if(activeStep === 1) {
            this.props.eventHandler(treeData)
        }
        this.setState({loading: false, activeStep: activeStep + 1})
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    isStepContentValid = () => {
        return this.state.valid
    }

    getMainHeaderBar = () => {
        return <Grid item xs={12} style={{paddingTop: 0}}>
            <MainHeaderBar title={this.getStepName()}></MainHeaderBar>
        </Grid>;
    }

    getStepName = () => {
        const {activeStep} = this.state
        return this.getSteps()[activeStep];
    }

    getStepContent = () => {
        const {activeStep} = this.state
        switch (activeStep) {
            case 0:
                return this.getSelectFileComponent();
            case 1:
                return this.getPreviewComponent();
            case 2:
                return this.getSuccessScreen();
            default:
                return undefined;
        }

    }

    getSuccessScreen = () => {
        let {theme} = this.props
        return <GridContainer style={{textAlign: 'center'}}>
            <Grid item xs={12}>
                <div>
                    <CheckCircleIcon fontSize="large" style={{fontSize: '80px', color: theme.palette.success.main}}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <H2Title style={{color: theme.palette.success.main}} title={'Import Successful'}/>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </GridContainer>;
    }

    onFilesSelect = (selectedFiles) => {
        let {collectionDetails} = this.props
        let selectedFilesFromState = this.state.selectedFiles ? this.state.selectedFiles : [];
        let selectedFilesFiltered = selectedFiles.filter(f => ALLOWED_TYPES.includes("."+getFileExtension(f.name)))
            .filter(f => !selectedFilesFromState.find(sf => sf.name === f.name));
        let allFiles = selectedFilesFiltered.length > 0 ? [...selectedFilesFiltered, ...selectedFilesFromState] : selectedFilesFromState
        let selectedFilesError = allFiles.length > VALIDATION_SCENARIO_FILE_MAX_COUNT ? true : false
        allFiles.forEach(f => {
            if(f.size > VALIDATION_SCENARIO_FILE_MAX_SIZE){
                selectedFilesError = true
                this.setState({ [f.name+'error']: `File size should be less than ${getMaxExampleSetFileSize()}`})
            }
        })

        if(selectedFilesError) {
            this.setState({
                selectedFiles: allFiles,
                selectedFilesError: selectedFilesError,
                valid: (selectedFilesError === true ? false : true)
            });
        } else {

            let treeData = []
            let promises = [];
            for (let selectedFile of allFiles) {
                let filePromise = new Promise(resolve => {
                    let fileReader = new FileReader();
                    fileReader.onload = () => {
                        let fileContent = fileReader.result;
                        resolve({name: selectedFile.name, fileContent: fileContent, file: selectedFile})
                    }
                    fileReader.readAsText(selectedFile)
                });
                promises.push(filePromise);
            }
            Promise.all(promises).then(fileObjects => {
                fileObjects.forEach(p => {
                    let object = JSON.parse(p.fileContent)

                    let exampleSetObjects = object.filter(o => o[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET)
                    exampleSetObjects.forEach(exampleSetObject => {
                        let newExampleSetID = generateExampleSetId();
                        exampleSetObject[ID] = newExampleSetID;
                        exampleSetObject[ALIAS_SYS_EXAMPLE_COLLECTION] = collectionDetails[ID];
                        let requestObjects = exampleSetObject.children;
                        requestObjects.forEach(o => {
                            let newExampleRequestID = generateExampleRequestId()
                            o[ID] = newExampleRequestID
                            o[ALIAS_SYS_EXAMPLE_EXAMPLE_SET] = newExampleSetID
                        });
                        let updatedExampleSetObject = updateContext(exampleSetObject);
                        treeData.push(updatedExampleSetObject);
                    })
                })
                selectedFilesError = treeData.length < 1 ? 'No scenario found in the files.': undefined;
                this.setState({
                    treeData: treeData,
                    selectedFiles: allFiles,
                    selectedFilesError: selectedFilesError,
                    valid: (selectedFilesError === true ? false : true)
                });
            });
        }

    };

    getSelectFileComponent = () => {
        let {selectedFiles} = this.state

        let content = <GridContainer>
            {this.getMainHeaderBar()}
            <Grid item xs={12}>
                <FileUpload
                    accept={ALLOWED_TYPES}
                    fileUploadMessage={`A maximum of ${VALIDATION_SCENARIO_FILE_MAX_COUNT} file is allowed.`}
                    fileUploadMessageError={this.state.selectedFilesError}
                    dragNDropBoxTitle={"Drag 'n' Drop Scenario Files"}
                    files={ selectedFiles.map((f) => ({
                        f: f,
                        errors: (this.state[f.name + 'error'] ? [this.state[f.name + 'error']] : [])
                    }))}
                    onFilesSelect={this.onFilesSelect}
                    onDelete={(file) => {
                        let selectedFilesCopy = selectedFiles.filter(f => f.name !== file.name)
                        let errorName = file.name + 'error'
                        let otherError = selectedFilesCopy.find(fl => this.state[fl.name + 'error']) ? true : false;
                        this.setState({selectedFiles: selectedFilesCopy,  [errorName]: null, selectedFilesError: otherError})
                    }}
                />
            </Grid>
        </GridContainer>;

        return <>
            {content}
        </>;
    }

    getPreviewComponent = () => {
        const {treeData, focusNode, expanded} = this.state
        const {location, collectionDetails} = this.props

        return <Examples
            key={focusNode ? focusNode.id : 'previewExamples'}
            hideMockingSetup={true}
            readOnly={true}
            hideHeader={true}
            headerTitle={this.getStepName()}
            collection={collectionDetails}
            treeData={treeData}
            focusNode={focusNode}
            location={location}
            expanded={expanded}
            onTreeNodeToggle={(nodeIds) =>  {this.setState({expanded: nodeIds})}}
            scenariosTreeStyle={{ maxHeight: `calc(100vh - 336px)`}}
        />;
    }

    render () {
        const {classes, theme, open} = this.props
        return <Dialog
            fullScreen={true}

            open={open}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.dialog}}
        >
            <DialogTitle style={{padding: '0px'}} disableTypography={true} id="form-dialog-title">
                <StepperHeader steps={this.getSteps()} activeStep={this.state.activeStep}
                               title={'Import Scenarios'}></StepperHeader>
            </DialogTitle>
            <DialogContent style={{backgroundColor: theme.palette.grey.background, padding: '0px'}}>
                {this.getStepContent()}
            </DialogContent>
            <DialogActions style={{padding: '12px 24px', borderTop: '1px solid ' + theme.palette.border.main}}>
                {this.getFooter()}
            </DialogActions>
        </Dialog>;
    }
}


ImportScenarioDialog.propTypes = {
    eventHandler: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    collectionDetails: PropTypes.object,
    location: PropTypes.object,
    open: PropTypes.bool,
}

export default withStyles(styles, {withTheme: true})(ImportScenarioDialog);

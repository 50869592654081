import {Badge, LinearProgress, styled, Tooltip, Typography, useTheme} from "@material-ui/core";
import {centerVertically, getLocalName, getResourceId, toArray} from "../../components/util";
import React, {Fragment, useState} from "react";
import IconButton from "@material-ui/core/IconButton";
import {ExpandLessOutlined, ExpandMoreOutlined} from "@material-ui/icons";
import {lighten} from "@material-ui/core/styles";
import H3Title from "../../components/H3Title";
import Collapse from "@material-ui/core/Collapse";

export function findNodeInTreeData(treeData, newResourceToSelect, selectedNodeId, expandedNodeIds) {
    return treeData.find(tn => {
        if (getResourceId(newResourceToSelect) === getResourceId(tn) && selectedNodeId === tn.nodeId) {
            return tn;
        }
        if (expandedNodeIds.includes(tn.nodeId) && tn.children) {
            return findNodeInTreeData(tn.children, newResourceToSelect, selectedNodeId, expandedNodeIds);
        }
    })
}

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 0,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '0 2px',
        backgroundColor : 'unset',
        color : theme.palette.text.primary
    },
}));

const TreeNode = ({node, treeData, path, selectedNodeId, expandedNodeIds, onNodeSelect, onNodeToggle}) => {
    const theme = useTheme();
    const [active, setActive] = useState();

    let narrowerCount = node.childCount;
    const spanWidth = 48;
    const hasSuggestions = false;
    const {nodeId} = node;
    const selected = nodeId === selectedNodeId;
    let isExpanded = expandedNodeIds.includes(nodeId);

    const getTooltip = () => {
        return <table>{
            path.map((p, i) => <tr key={i}><td style={{paddingLeft : `${16 * i}px`}}>{p}</td></tr>)
        }</table>
    }

    return <Fragment key={isExpanded}>
        <div datatest={'treeNodeContainerDiv'} id={nodeId} style={{display: 'flex'}}>
            <span style={{minWidth: spanWidth + 'px'}}>
                 {
                     narrowerCount > 0 &&
                         <IconButton
                             datatest={'expandCollapseButton'}
                             style={{marginTop : '4px'}}
                             disabled={narrowerCount < 1}
                             size={'small'}
                             onClick={() => {
                                 onNodeToggle(node);
                             }}>
                             {

                                 <StyledBadge
                                     datatest={'countBadge'}
                                     badgeContent={narrowerCount}
                                     max={99}
                                 >
                                     { isExpanded ? <ExpandLessOutlined datatest={'collapseIcon'}/> :
                                         <ExpandMoreOutlined datatest={'expandIcon'}/>}
                                 </StyledBadge>
                             }
                         </IconButton>
                 }
            </span>
            <div
                datatest={'nodeDiv-'+node?.title}
                style={{
                    display: 'flex',
                    borderRadius: '4px',
                    marginBottom: '8px',
                    border: '1px solid',
                    borderLeft:'3px solid',
                    borderColor: hasSuggestions? theme.palette.warning.dark : (selected || active ? theme.palette.secondary.light : theme.palette.divider),
                    minWidth: '280px',
                    minHeight: '40px',
                    backgroundColor: selected || active ? lighten(theme.palette.secondary.light, 0.85) : undefined,
                }}
                onClick={() => {}}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
            >
                <Tooltip title={getTooltip()}>
                    <div onClick={() => {
                        onNodeSelect(node);
                    }} style={{height : '100%', flexGrow : 1, padding: '0px 16px', textDecoration : 'none', }} >
                        {centerVertically(
                            (node.title ? <H3Title id={nodeId+"-t"} noWrap={true} style={{ maxWidth : '280px', color : selected || active ? theme.palette.secondary.dark : undefined}}>
                                {node.title}
                            </H3Title> : <Typography noWrap={true} style={{ maxWidth : '280px', color : theme.palette.warning.dark}} variant={'caption'}>{getLocalName(getResourceId(node), true)}</Typography> )
                            , {height : '100%'}
                        )}
                    </div>
                </Tooltip>
            </div>
        </div>
        {isExpanded && <div datatest={'expandedDataDiv'} style={{marginLeft: spanWidth / 2}}>
            <div style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                <Collapse in={isExpanded ? true : false} timeout="auto" unmountOnExit>
                    {toArray(node.children).map((c, i) => {
                        let resourceId = getResourceId(c);
                        return <TreeNode
                            onNodeToggle={onNodeToggle}
                            onNodeSelect={onNodeSelect}
                            selectedNodeId={selectedNodeId}
                            expandedNodeIds={expandedNodeIds}
                            key={resourceId+'-'+i}
                            path={[...path, c.title||resourceId]}
                            treeData={treeData}
                            node={c}
                        />;
                    })}
                </Collapse>
            </div>
        </div>
        }
    </Fragment>;
}

export const LabelTree = ({treeData, treeLoading, selectedNodeId, expandedNodeIds, onNodeSelect , onNodeToggle}) => {

    const titleHeight = 28;
    const tabsHeight = 43;
    const searchHeight = 77;

    const treeContainerHeightAdjust = titleHeight + tabsHeight + searchHeight;
    let treeContainerStyle = {
        paddingTop: '16px',
        marginTop : '8px',
        marginRight: '2px',
        height: `calc(100% - ${treeContainerHeightAdjust}px)`,
        display: 'flex',
        flexDirection: 'column',
        overflow : 'auto'
    };

    return <div id={'treeContainerDiv'} datatest={'treeContainerDiv'} style={treeContainerStyle}>
        {treeLoading
            ? <LinearProgress></LinearProgress>
            : toArray(treeData).map((c) => {
                let resourceId = getResourceId(c);
                return <TreeNode
                    onNodeSelect={onNodeSelect}
                    onNodeToggle={onNodeToggle}
                    expandedNodeIds={expandedNodeIds}
                    selectedNodeId={selectedNodeId}
                    path={[c.title||resourceId]}
                    treeData={treeData}
                    key={resourceId}
                    node={c}
                ></TreeNode>;
            })}
    </div>;
}
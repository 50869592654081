import React from 'react';
import PropTypes from 'prop-types';
import {uniq} from 'lodash';
import {FormControlLabel, Switch, Typography, withStyles} from '@material-ui/core';
import AutoComplete from './AutoComplete';
import {flatten, getIdGeneratingClassIRIs} from "./util";
import queryString from "query-string";
import history from "../history";
import NavTree from './NavTree';
import CustomLabel from './CustomLabel';
import {styles} from "./styles";
import {canUpdateConfigs} from "../layouts/common/Profile";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {DATATEST_listViewButton, DATATEST_treeViewButton} from "../Constants";
import NavList from "../components/NavList/NavList";
import CustomAliasTreeLabel from "../layouts/modelbuilder/CustomAliasTreeLabel";
import {DashBoardSearch} from "../components/DashBoardSearch";
import IconButton from "../components/IconButton";
import FirstPageOutlinedIcon from "@material-ui/icons/FirstPageOutlined";
import NavigateBeforeOutlinedIcon from "@material-ui/icons/NavigateBeforeOutlined";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import LastPageOutlinedIcon from "@material-ui/icons/LastPageOutlined";

export function searchLabelRecursively(data, currentSearchValue, key = 'title', path = [], childrenKey = 'children') {
  if (!currentSearchValue || currentSearchValue.length === 0) {
    return [];
  }
  const keys = [];
  if (data[key] && data[key].toLowerCase() === currentSearchValue.toLowerCase()) {
    keys.push(...path, data.id);
  }

  let children = data[childrenKey];
  if (children && children.length >= 0) {
    children.forEach(child =>
        keys.push(...searchLabelRecursively(child, currentSearchValue, key, [...path, data.id], childrenKey))
    )
  }

  return uniq(keys);
}

export function syncResults(newVal, oldVal) {
  let sync = newVal && newVal.length > 2 ? true : false;
  sync = sync === false && newVal === '' && oldVal && oldVal.length > 0 ? true : sync;
  return sync;
}

export function renderPagination(page, pageSize, hasNextPage, totalCount, onPageLoad, containerStyle = {}, iconStyle = {}) {

  let numberTotalCount = Number(totalCount);
  let lastPage = Math.ceil(numberTotalCount / Number(pageSize));
  let start = ((page - 1) * pageSize) + 1;
  let end = page * pageSize;
  end = end > numberTotalCount ? numberTotalCount : end;
  if(Number(end) === 0) {
      start = 0
  }
  let style = {
    padding: "8px 12px 0px 0px",
    textAlign: 'center',
    ...containerStyle
  }
  return <div datatest={'pagination'} style={style}>
    <Typography datatest={'startEnd'} component={'span'}>{start}-{end}</Typography>
    <IconButton datatest={'firstButton'} disabled={page === 1} onClick={() => onPageLoad(1)} size={"small"}>
      <FirstPageOutlinedIcon style={iconStyle}/>
    </IconButton>
    <IconButton datatest={'previousButton'} disabled={page === 1} onClick={() => onPageLoad(Number(page) - 1)} size={"small"}>
      <NavigateBeforeOutlinedIcon style={iconStyle}/>
    </IconButton>
    <IconButton datatest={'nextButton'} disabled={!hasNextPage} onClick={() => onPageLoad(Number(page) + 1)} size={"small"}>
      <NavigateNextOutlinedIcon style={iconStyle}/>
    </IconButton>
    <IconButton datatest={'lastButton'} disabled={!hasNextPage} onClick={() => onPageLoad(lastPage)} size={"small"}>
      <LastPageOutlinedIcon style={iconStyle}/>
    </IconButton>
  </div>
}


const TREE_VIEW = 'Tree';
const LIST_VIEW = 'List';

class TreeViewForIdSetup extends React.Component {

  state = {
    currentSearchValue: '',
    expandedItems: [],
    scrollTo: false,
    viewType : (this.props.viewType === 'list' ? LIST_VIEW : TREE_VIEW)
  };

  static defaultProps = {
    toggle: true,
    idGeneratingClasses: [],
  };

  params = queryString.parse(this.props.location.search)

  onSearch = (e, node) => {

    if (node) {

      const { expandedItems } = this.state;
      const { id } = node;
      const { treeData, disabledNodeClickOnSearch } = this.props;
      const newExpandedItems = flatten(treeData.map(treeNode => searchLabelRecursively(treeNode, id, 'id')));
      const $expandedItems = uniq([ ...expandedItems, ...newExpandedItems]);
      if(!disabledNodeClickOnSearch) {
        this.onNodeClick({node});
      } else {
        this.setState({searchNodeId : id})
      }
      this.setState({ expandedItems: $expandedItems, currentSearchValue: id, scrollTo: true });
    }
  }


  onNodeClick = ({ node }) => {
    const { location, onNodeClick } = this.props;
    if (onNodeClick) {
      onNodeClick(node);
    } else {
      history.push(`${location.pathname}?${queryString.stringify({ id: node.id })}`)
    }
  }

  getViewButtonGroup = () => {
    let {viewType} = this.state;
    let {theme} = this.props;
    let StyledButton = withStyles({
      root : {
        '&:hover': {
          backgroundColor : theme.palette.white.main
        }
      },
      label : {
        textTransform : 'none'
      }
    })(Button);
    let isTreeView = viewType === TREE_VIEW;
    let isListView = viewType === LIST_VIEW;
    return <div style={{paddingRight : '8px' , paddingTop: '4px', paddingBottom : '4px'}}>
      <ButtonGroup fullWidth={true} style={{color: theme.palette.primary.main, borderColor : theme.palette.grey.level2}} size={'small'}>
        <StyledButton
            datatest={DATATEST_treeViewButton}
            disableRipple={true}
            color={isTreeView ? 'secondary' : 'primary'}
            style={{borderColor : isTreeView && theme.palette.secondary.main}}
            onClick={() => {
              this.setState({viewType : TREE_VIEW});
              const params = queryString.parse(this.props.location.search);
              if(params.classId) {
                this.expandAndNavigateTo(params.classId);
              }

            }}
        >{TREE_VIEW}</StyledButton>
        <StyledButton
            datatest={DATATEST_listViewButton}
            disableRipple={false}
            color={isListView ? 'secondary' : 'primary'}
            style={{borderColor : isListView ? theme.palette.secondary.main : theme.palette.grey.level2, borderLeftColor : theme.palette.secondary.main}}
            onClick={() => this.setState({viewType : LIST_VIEW})}
        >{LIST_VIEW}</StyledButton>
      </ButtonGroup>
    </div>;
  }



  getCustomLabelForClass = ($node, index, isList, toggle, toggleDisabled, idGeneratingClassesIRIs, toggleSwitchStateProvider, onChange, renderLabelPrefix) => {
    let id = $node.node?.id;
    let checked = toggleSwitchStateProvider ? toggleSwitchStateProvider($node.node) :idGeneratingClassesIRIs.includes(id);
    return <CustomAliasTreeLabel
        key={id+'-c-'+checked}
        datatestOverride={'customAliasTreeLabel'}
        isList={isList}
        {...$node}
        renderLabelPrefix={renderLabelPrefix}
        renderLabel={() => this.renderNodeLabel($node, index)}
        renderActions={( node ) => toggle && (
            <FormControlLabel
                control={
                  <Switch
                      disabled={!canUpdateConfigs() || toggleDisabled}
                      size="small"
                      name='idGeneratingClasses'
                      checked={toggleSwitchStateProvider ? toggleSwitchStateProvider(node) :idGeneratingClassesIRIs.includes(node.id)}
                      value={node.id}
                      onChange={onChange}
                  />
                }
            />
        )}
    />;

  }

  renderNodeLabel = (node, index) => {
    let maxWidth = 'calc(100%)';
    let labelText = node.labelText;
    let subTitle =  node.node?.subTitle;
    return <Typography datatest={'nodeLabel-'+labelText} style={{maxWidth: maxWidth}} noWrap={true} component={"span"}>
      <span style={subTitle ? {fontWeight : 'bold'} : {}}>{labelText}</span>
      {
        subTitle && <>
          <br></br>
          {node.id}
        </>
      }
    </Typography>;
  }

  renderTreeItem = (node, treeItem, index) => {
    return treeItem;
  }

  render() {
    let { onSearch, onSearchTextChange, containerHeightAdjust, searchRenderOption, searchFilterOptions, searchText, hasTabs, readOnly, disableViewType, menuOptions, treeData, data, idGeneratingClasses, onChange, toggle, toggleSwitchStateProvider, toggleDisabled, bootstrap, focusedNodeId, renderLabelPrefix } = this.props;
    let {viewType, searchNodeId} = this.state;
    const { scrollTo, expandedItems } = this.state;
    let idGeneratingClassesIRIs = idGeneratingClasses
        ? getIdGeneratingClassIRIs(idGeneratingClasses)
        : [];

    const params = queryString.parse(this.props.location.search)

    let treeContainerHeightAdjust = readOnly ? 320 : 208;
    if(!hasTabs) {
      treeContainerHeightAdjust = treeContainerHeightAdjust - 40;
    } else {
      treeContainerHeightAdjust = treeContainerHeightAdjust + 8;
    }
    if(disableViewType) {
      treeContainerHeightAdjust  = treeContainerHeightAdjust - 34;
    }
    if(bootstrap) {
      treeContainerHeightAdjust = treeContainerHeightAdjust - 72;
    }
    if(containerHeightAdjust !== undefined) {
      treeContainerHeightAdjust = treeContainerHeightAdjust + containerHeightAdjust;
    }

    let treeContainerStyle = {
      marginRight: '2px',
      height: `calc(100% - ${treeContainerHeightAdjust}px)`,
      display: 'flex',
      flexDirection: 'column'
    };

    return (
        <>
          <div style={{display: 'flex'}}>
            <div datatest={'searchBar'} style={{flexGrow: '1'}}>
              {
                onSearch ?
                <DashBoardSearch
                    placeholder={'Find'}
                    onSearch={onSearch}
                    onSearchTextChange={onSearchTextChange}
                    searchText={searchText||""}
                    style={{minWidth : '100%', maxWidth : '100%'}}
                />
                :
                <AutoComplete
                  datatest={'autocomplete-search'}
                  data={data}
                  id="tree-search"
                  onChange={this.onSearch}
                  textFieldPaperStyle={{padding: '0px 8px 0px 0px'}}
                  renderOption={searchRenderOption}
                  filterOptions={searchFilterOptions}
                />
              }
            </div>
            {menuOptions}
          </div>
          {disableViewType ? <></> : this.getViewButtonGroup()}
          <div datatest={'navListTree'} style={treeContainerStyle}>
          {
            viewType === TREE_VIEW && (
              <NavTree
                bootstrap={bootstrap}
                hasTabs={hasTabs}
                data={treeData}
                expanded={expandedItems}
                onNodeToggle={(e,n) => this.setState({ expandedItems: n })}
                onNodeClick={this.onNodeClick}
                renderNode={$props => <CustomLabel renderLabelPrefix={renderLabelPrefix} datatestOverride={'customAliasTreeLabel'} tooltip={$props.id} {...$props} />}
                focusedNode={searchNodeId || params.id || focusedNodeId}
                scrollTo={scrollTo}
                onScrollEnd={() => this.setState({ scrollTo: false })}
                renderActions={({ node }) => toggle && (
                  <FormControlLabel
                    control={
                      <Switch
                          disabled={!canUpdateConfigs() || toggleDisabled}
                        size="small"
                        name='idGeneratingClasses'
                        checked={toggleSwitchStateProvider ? toggleSwitchStateProvider(node) : idGeneratingClassesIRIs.includes(node.id)}
                        value={node.id}
                        onChange={onChange}
                      />
                    }
                  />
                )}
              />
            )
          }
          {
            viewType === LIST_VIEW && (
                <>
                  <NavList
                      rootStyle={{maxHeight: '100%'}}
                      data={data}
                      scrollTo={scrollTo}
                      focusedNode={searchNodeId || params.id || focusedNodeId}
                      onScrollEnd={() => this.setState({scrollTo: false})}
                      onNodeClick={this.onNodeClick}
                      renderTreeItem={this.renderTreeItem}
                      renderNode={($node, index) => {
                        return this.getCustomLabelForClass($node, index, true, toggle, toggleDisabled, idGeneratingClassesIRIs, toggleSwitchStateProvider, onChange, renderLabelPrefix);
                      }}
                  />
                  {this.props.paginationRenderer && this.props.paginationRenderer()}
                </>
            )
          }
          </div>
        </>
    );
  }
}


TreeViewForIdSetup.propTypes = {
  data: PropTypes.array,
  idGeneratingClasses: PropTypes.array,
  onChange: PropTypes.func,
  onNodeClick: PropTypes.func,
  location: PropTypes.object,
  disableViewType: PropTypes.bool,
  hasTabs: PropTypes.bool,
  viewType: PropTypes.oneOf(['list', 'tree']),
  paginationRenderer : PropTypes.func,
  menuOptions : PropTypes.any,
  onSearch: PropTypes.func,
  onSearchTextChange: PropTypes.func,
  searchRenderOption: PropTypes.func,
  searchFilterOptions: PropTypes.func,
  searchText: PropTypes.string,
  containerHeightAdjust: PropTypes.number,
  toggle: PropTypes.bool,
  toggleDisabled: PropTypes.bool,
  disabledNodeClickOnSearch: PropTypes.bool,
  toggleSwitchStateProvider : PropTypes.func,
  focusedNodeId : PropTypes.string,
  renderLabelPrefix: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(TreeViewForIdSetup);

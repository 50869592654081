import React from "react";
import {withStyles} from "@material-ui/core";
import TextField from "../TextField";
import {
    getApiConfigurationResource,
    getLanguageCodesSuggestions,
    getPropertyValueForForm,
    getRandomValueForLangString,
    langCodeToSelectValue,
    parseLangStringLiteral,
    registerMock
} from "../util";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import {deleteButton} from "./ArrayType";
import {enableMultiline, withDelete} from "./Text";
import {getAutoComplete, getLangChip} from "./Literal";
import {ALIAS_SYS_DEFAULT_LANGUAGE, EVENT_SHACL_VALIDATE} from "../../Constants";
import {isString} from "lodash";
import {validateFromSHACL} from "./validation-util";

function hasLanguageValue(value) {
    return value && Object.keys(value).length > 0
}

function setLanguagePropertyValue(lang, value, property, valueIndex) {
    let obj = {
        [lang ? [lang.value] : '']: value
    }
    if(valueIndex === undefined) {
        property.value = obj;
    } else {
        property.value[valueIndex] = obj;
    }
}

class LangString extends React.Component {
    static contextType = MockingContext

    constructor(props) {
        super(props)
        let {customizations} = props;
        let value = getPropertyValueForForm(props)
        let defaultLanguageCode = langCodeToSelectValue(getApiConfigurationResource(props.configurations)[ALIAS_SYS_DEFAULT_LANGUAGE])

        let valueToSet = hasLanguageValue(value) ? value[Object.keys(value)[0]] : '';
        let isMultiline = enableMultiline(valueToSet, customizations);
        let languageCodesSuggestions = customizations?.languageSuggestions || getLanguageCodesSuggestions();

        this.state = {
            lang:  hasLanguageValue(value) ? langCodeToSelectValue(Object.keys(value)[0]) : defaultLanguageCode,
            value: valueToSet,
            editLang : false,
            defaultLanguageCode: defaultLanguageCode,
            isMultiline,
            languageCodesSuggestions
        }
        let {property, valueIndex, onChange} = props;
        if(!hasLanguageValue(value)) {
            setLanguagePropertyValue(defaultLanguageCode, "", property, valueIndex);
            onChange();
        }
    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let {valueIndex, property, customizations} = this.props;
        let mocking = this.context;
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName);
        }

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? parseLangStringLiteral(mocking.getMockValue(property))
            : getRandomValueForLangString()

        let {lang, value} = mockValue;
        let found = langCodeToSelectValue(lang)
        this.setState({lang: found, value: value})
        this.updatePropertyValue(found, value)
    }

    validate = (eventName) => {
        let {valueIndex, property, customizations} = this.props;
        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    updatePropertyValue = (lang, value) => {
        let {valueIndex, property, onChange} = this.props
        if(!lang && !value) {
            valueIndex === undefined
                ? delete property.value
                : property.value.splice(valueIndex, 1);
        } else {
            setLanguagePropertyValue(lang, value, property, valueIndex);
        }
        this.validate(EVENT_SHACL_VALIDATE);
        onChange()
    }



    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {label, autoFocus, theme, valueIndex, property, onChange, customizations} = this.props
        let { value, lang, editLang, isMultiline, languageCodesSuggestions, validationError} = this.state
        return withDelete(
            <TextField
                multiline={isMultiline}
                rowsMax={isMultiline && customizations?.rowsMax ? customizations?.rowsMax : undefined}
                datatest={'langString'}
                autoFocus={autoFocus}
                paperStyle={{padding: 0}}
                value={value}
                onChange={ (event) => {
                    this.setState({value: event.target.value});
                    this.updatePropertyValue(this.state.lang, event.target.value)
                }}
                inputProps={{
                    ref: this.setRef
                }}
                error={validationError}
                InputProps={{
                    startAdornment :  (editLang || lang === '')
                        ? getAutoComplete(
                            languageCodesSuggestions,
                            lang,
                            (event, newVal) => {
                                let newLanguageCodeObject ;
                                if(isString(newVal)) {
                                    newLanguageCodeObject = {
                                        value : newVal,
                                        label : newVal
                                    }
                                } else {
                                    newLanguageCodeObject = newVal;
                                }

                                this.setState({lang: newLanguageCodeObject, editLang : false})
                                this.updatePropertyValue(newLanguageCodeObject, this.state.value);
                                this.inputRef.focus();

                            },
                            () => this.setState({editLang : false}),
                            editLang,
                            'autocompleteEditLang'
                        )
                        : getLangChip(theme, lang, () => this.setState({editLang:true} ))
                }}
            />,
            customizations?.hideDelete || deleteButton(valueIndex, property, onChange)
        );
    }
}

export default withStyles({}, {withTheme: true})(LangString);

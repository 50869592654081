import {withStyles} from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export const PrettoSlider = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    // height: `8px !important`,
    width: `8px !important`,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: '-8px !important',
    '&:focus,&:hover,&$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    width: `8px !important`,
    borderRadius: 4,
  },
  rail: {
    // height: `8px !important`,
    width: `8px !important`,
    height: 8,
    borderRadius: 4,
  },
}))(Slider);


import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
    flatten,
    getDataContextURL,
    getPropertyName,
    getShaclResult,
    invokeMock,
    isArrayOnly,
    isBadRequestError,
    isEmptyArray,
    isRequestSuccessful,
    replaceVariables,
    toArray
} from "../../components/util";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Form from "../../components/ShapeToForm/Form";
import {addPayloads} from "../apiplayground/CreateRequest";
import {postGraph} from "../../service/graph-api";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD,
    UI_LABELS_ADD_NEW_RESOURCES,
    UI_LABELS_CANCEL,
    UI_LABELS_SAVE
} from "./UILabel";
import {ALIAS_SH_RESULT_MESSAGE, ALIAS_SH_RESULT_PATH, EVENT_SHACL_VALIDATE, RENDER_MODE_SITE} from "../../Constants";
import ErrorMessage from "../../components/ErrorMessage";
import {getLanguageSuggestionsForSite} from "./DataGridView";

const styles = {
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
};


class AddNewResourceDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            createRequestObject : {},
            initialForms : []
        }
    }

    onFormChange = (shapeForms) => {
        let {createRequestObject} = this.state;
        createRequestObject.build = shapeForms;
        this.setState({});
    }

    getValidationError = (validations) => {
        let rest = validations.filter(v => v !== undefined && isEmptyArray(toArray(v)) === false);
        for(let v of rest) {
            if(isArrayOnly(v)) {
                if(isEmptyArray(v) === false) {
                    let returnValue = this.getValidationError(v);
                    if(returnValue) {
                        return returnValue;
                    }
                }
            } else {
                return v;
            }
        }
    }


    onSave = async () => {
        let {aliasesMap, aliasesToIRIMap, onSaveSuccess, ontology, browseLanguage} = this.props;
        let {createRequestObject} = this.state;
        let {initialForms} = this.state;
        let validations = await Promise.all(flatten(initialForms.map(f => invokeMock(f, EVENT_SHACL_VALIDATE))));
        let validationError = this.getValidationError(validations);
        if(validationError) {
            if(validationError.ref) {
                validationError.ref.focus();
            }
            return ;
        }

        addPayloads(createRequestObject, aliasesMap);
        let payload = createRequestObject.body;
        let {replaced} = replaceVariables(payload, {
            getItem : (key) => {
                if(key === 'DATA_CONTEXT_URL') {
                    return getDataContextURL();
                }
            }
        });
        return postGraph(replaced).then(async (response) => {
            if(isRequestSuccessful(response)) {
                onSaveSuccess && onSaveSuccess();
            }  else if(isBadRequestError(response)) {
                let json = await response.json();
                this.setState({loading: false, errorMessageProvider : () => {
                        let shaclResult = getShaclResult(json);
                        let errors =  toArray(shaclResult).map(e => {
                            let propertyName = getPropertyName(aliasesToIRIMap, e[ALIAS_SH_RESULT_PATH], ontology, browseLanguage);
                            return <div>
                                <ErrorMessage
                                    error={`'${propertyName}' : ${e[ALIAS_SH_RESULT_MESSAGE]?.en || e[ALIAS_SH_RESULT_MESSAGE]}`}/>
                            </div>;
                        });
                        return errors;
                    }});
            } else {
                this.setState({loading: false, apiErrorResponse: response, apiError: true});
            }
        });
    }


    render() {
        let {onClose, classes, theme} = this.props;
        let {settings, browseLanguage, aliasesToIRIMap, ontology, location} = this.props;
        let {apiError, apiErrorResponse, initialForms, errorMessageProvider} = this.state;
        return <>
            {
                <Dialog
                    datatest={'addNewResourceDialog'}
                    fullWidth={true}
                    maxWidth={'md'}
                    open={true}
                    classes={{ paper: classes.dialogPaper }}
                    scroll={'paper'}

                >
                    <DialogTitle id="form-dialog-title">
                        <H2Title title={getUiLabelTranslation(settings, UI_LABELS_ADD_NEW_RESOURCES, browseLanguage, UI_LABELS_ADD_NEW_RESOURCES)}/>
                    </DialogTitle>
                    <DialogContent tabIndex={-1} style={{backgroundColor : theme.palette.grey.background}}>
                        {apiError && <BackendErrorDialog handleClose={() => this.setState({apiError : undefined, apiErrorResponse : undefined})}  open={true} error={apiErrorResponse} />}
                        {errorMessageProvider && <BackendErrorDialog handleClose={() => this.setState({errorMessageProvider : undefined})}  open={true} errorMessageProvider={errorMessageProvider} />}
                        <Form
                            style={{padding : '0px', backgroundColor : theme.palette.grey.background}}
                            hideMockButton={true}
                            initialForms={initialForms}
                            onChange={this.onFormChange}
                            addButtonTitle={getUiLabelTranslation(settings, UI_LABELS_ADD, browseLanguage, UI_LABELS_ADD)}
                            addInitialForm={true}
                            customizations={{
                                ontology : ontology,
                                aliasesToIRIMap : aliasesToIRIMap,
                                settings : settings,
                                browseLanguage : browseLanguage,
                                location : location,
                                renderMode : RENDER_MODE_SITE,
                                labelWidth : '160px',
                                addButtonVariant : 'outlined',
                                languageSuggestions : getLanguageSuggestionsForSite(settings)
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow :'1'}}></div>
                        <Button
                            disabled={isEmptyArray(initialForms) }
                            datatest={'addButton'}
                            variant={"contained"}
                            color="secondary"
                            onClick={this.onSave}
                        >{getUiLabelTranslation(settings, UI_LABELS_SAVE, browseLanguage, UI_LABELS_SAVE)}</Button>
                    </DialogActions>
                </Dialog>
            }
        </>;

    }
}

AddNewResourceDialog.propTypes = {
    settings: PropTypes.any,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onSaveSuccess: PropTypes.any
};

export default withStyles(styles, {withTheme: true})(AddNewResourceDialog);


import React, {Component} from "react";
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import H2Title from "./H2Title";
import withStyles from "@material-ui/core/styles/withStyles";
import {ALIAS_RDFS_LABEL, GRAPH} from "../Constants";
import {getGraph, isClientError} from "../components/util";
import {isString} from 'lodash';


class BackendErrorDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    let {errorMessageProvider} = this.props;
    if(errorMessageProvider) {
    } else {
      this.getErrorMessage(this.props.error);
    }
  }


  getGraphError = async (response) => {
    if(response.bodyUsed) {
      return <></>;
    }
    let json = await response.json();
    if(json.message) {
      return <div>{json.message}</div>;
    }
    let graph = getGraph(json);
    return graph.map(e => <div>{e[ALIAS_RDFS_LABEL].en}</div>);
  }

  getErrorMessage = (error) => {
    if(error.message === 'Failed to fetch') {
      this.setState({errorMessage: 'Connection with backend API failed. Check that backend API is running and is reachable from browser.'});
    } else if(error[GRAPH]) {
      this.setState({errorMessage: error[GRAPH].map(e => <div>{e[ALIAS_RDFS_LABEL].en}</div>)});
    } else if(error.status && error.request && isClientError(error)) {
      //If response object is passed
        this.getGraphError(error).then(r => {
          this.setState({errorMessage: <div><div>HTTP STATUS : {error.status}</div><div>{error.request.url}</div><div>{r}</div></div>});
        })
    } else if(isString(error)) {
      this.setState({
        errorMessage: <div>
          <pre>{error}</pre>
        </div>
      });

    } else {
      this.setState({
        errorMessage: <div>
          <div>Something went wrong please contact support.</div>
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </div>
      });
    }
  }

  render() {
    const {open, error, title, handleClose, errorMessageProvider} = this.props;
    let {errorMessage} = this.state;

    return (<React.Fragment>{
      open && <Dialog
          open={open}
      >
        <DialogTitle id="form-dialog-title"><H2Title title={title ? title : 'Error'}/></DialogTitle>
          {errorMessageProvider && errorMessageProvider()}
        {error && <DialogContent>
          <DialogContentText>{errorMessage}</DialogContentText>
        </DialogContent>}
        <DialogActions>
          {
            handleClose &&
            <Button variant={'contained'} datatest={'confirmClose'} onClick={handleClose}
                    color="secondary">Close</Button>
          }
        </DialogActions>
      </Dialog>
    }</React.Fragment>);
  }

}


BackendErrorDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  error: PropTypes.object,
  errorMessageProvider: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
};

export default withStyles({}, {withTheme:true})(BackendErrorDialog);

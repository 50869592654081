import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    CBD,
    DATA,
    EASYGRAPH_ETAG,
    MIXIN_DEFAULT,
    PATHS,
    TYPE
} from "../../Constants";
import {
    computeAllSubClasses,
    computeShapeWithSuperClasses,
    flatten, getLocalName,
    getOntologyClasses,
    getPropertyTypes,
    getShapePropertyArray,
    getShapesData, getUiLabelTranslationFromContext,
    isObjectShapeProperty,
    sort, toArray
} from "../../components/util";
import PropTypes from "prop-types";
import {Popper, TextField as OtherTextField} from "@material-ui/core";
import {cloneDeep, isArray} from 'lodash';
import uuid4 from 'uuid/v4'
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import Button from "@material-ui/core/Button";
import FieldContainer from "../../components/FieldContainer";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {traceRenderStart} from "../../components/Trace";
import H4Title from "../../components/H4Title";
import {generateTree, MultiValueSelect, renderBlock, renderObjectValue} from "../../layouts/apiplayground/SearchFilter";
import Typography from "@material-ui/core/Typography";
import {computePropertyOptionsFromOntology} from "../../layouts/apiplayground/SearchRequest";
import {
    UI_LABELS_ADD_CONNECTION,
    UI_LABELS_CONNECTED, UI_LABELS_CONNECTION_PROPERTY, UI_LABELS_DATA_FILTER,
    UI_LABELS_NO_VALUE,
    UI_LABELS_NO_VALUE_HELP_VALUE,
    UI_LABELS_NONE,
    UI_LABELS_NONE_HELP,
    UI_LABELS_NONE_HELP_VALUE,
    UI_LABELS_PROPERTIES,
    UI_LABELS_SEARCH_RESULTS,
    UI_LABELS_SELECTED_PROPERTIES,
    UI_LABELS_SELECTED_PROPERTIES_HELP, UI_LABELS_SELECTED_PROPERTIES_HELP_VALUE
} from "../navigator/UILabel";
import GlobalsContext from "../../components/GlobalsContext";

const CLASS_IRIS = "classIRIs"

export function initialiseConnectedData(type) {
    return {
        type : type,
        [CLASS_IRIS] : [],
        filters : []
    }
}

export function initialiseSortData() {
    return {
        [CLASS_IRIS] : [],
        filters : []
    }
}

export function initialiseFacetData() {
    return {
        [CLASS_IRIS] : [],
        filters : []
    }
}

export function getConnections(connectedData) {
    return connectedData.filters;
}

export function setClassIRIs(connectedData, classIRIs) {
    connectedData[CLASS_IRIS] = classIRIs
    return connectedData;
}

export function createChildConnection(connection) {
    let newConnection = {id: uuid4(), parentClassIRIs : connection[CLASS_IRIS], filters:[]};
    return newConnection;
}

export function addConnectedData(connection) {
    let newConnection = createChildConnection(connection);
    connection.filters.push(newConnection);
    return newConnection;
}

export function setConnectedDataValue(connection, value) {
    Object.keys(value).forEach(k => {
        connection[k] = value[k]
    })
    return connection;
}
export function deleteAllFilters(connection) {
    connection.filters = []
}

export function computeTypes(filter, configurations) {
    let selectedTypes = filter.parentClassIRIs || []
    let allTypes = [...new Set([... selectedTypes, ...flatten(selectedTypes.map(c => computeAllSubClasses(c, getOntologyClasses(configurations))))])]
    return allTypes;
}

export function addTargetClass(p, targetClass) {
    let props = []
    if (isObjectShapeProperty(p)) {
        p[ALIAS_SH_TARGET_CLASS] = targetClass
        props.push(cloneDeep(p));
    } else {
        props.push(p);
    }
    return props;
}

function getDefaultProperties(configurations, aliasesMap, objectOnly) {
    let shapesProperties = flatten(getShapesData(configurations)
        .filter(o => o[ALIAS_SH_PROPERTY]).map(o => {
            if (isArray(o[ALIAS_SH_PROPERTY])) {
                return flatten(o[ALIAS_SH_PROPERTY].map(p => {
                    return addTargetClass(p, o[ALIAS_SH_TARGET_CLASS]);
                }));
            } else {
                return addTargetClass(o[ALIAS_SH_PROPERTY], o[ALIAS_SH_TARGET_CLASS]);
            }
        }));
    let optionsFromOntology = computePropertyOptionsFromOntology(configurations, objectOnly);
    optionsFromOntology.forEach(o => shapesProperties.push(o));
    return dedupeProperties(configurations, aliasesMap, shapesProperties, objectOnly);
}

function dedupeProperties(configurations, aliasesMap, shapesProperties, objectOnly) {
    let properties = []
    let propMap = {}
    shapesProperties.filter(p => objectOnly === true ? isObjectShapeProperty(p) : true ).forEach(p => {
        let alias = aliasesMap[p[ALIAS_SH_PATH]] || p[ALIAS_SH_PATH]
        let existing = propMap[alias]
        if(isObjectShapeProperty(p)) {
            const allClasses = getPropertyTypes(p, configurations)
            if(existing) {
                propMap[alias][CLASS_IRIS] = [...new Set([...toArray(propMap[alias][CLASS_IRIS]), ...allClasses])]
            } else {
                propMap[alias] = {
                    label : alias,
                    path : p[ALIAS_SH_PATH],
                    [CLASS_IRIS]: [...new Set(allClasses)],
                    'value': alias
                }
            }
        } else {
            propMap[alias] = {
                label : alias,
                path: p[ALIAS_SH_PATH],
                'value': alias
            }
        }
    })
    Object.keys(propMap).forEach(k => properties.push(propMap[k]))

    //Add default properties if not CBD
    if(objectOnly === false) {
        [
            {label: TYPE, value: TYPE},
            {label: aliasesMap[EASYGRAPH_ETAG], value: aliasesMap[EASYGRAPH_ETAG]},
        ].forEach(p => properties.push(p))
    }

    return sort(properties, 'label');
}

export function isSelectOptionObjectType(p) {
    return p[CLASS_IRIS] ? true : false;
}

export function getSelectOptions(configurations, aliasesMap, filter, objectOnly) {
    let allClasses = computeTypes(filter, configurations)
    if(allClasses && allClasses.length > 0) {
        const shapes = getShapesData(configurations)
        const ontology = getOntologyClasses(configurations)
        const shapeObjs = shapes.filter(o => allClasses.includes(o[ALIAS_SH_TARGET_CLASS]))
        let propertyOptions = []
        shapeObjs.forEach(shapeObj => {
            let computedShape = computeShapeWithSuperClasses(shapeObj, shapes, ontology)
            let properties = getShapePropertyArray(computedShape);
            properties.forEach(p => {
                addTargetClass(p, shapeObj[ALIAS_SH_TARGET_CLASS]).forEach(p => propertyOptions.push(p));
            })
        })
        let optionsFromOntology = computePropertyOptionsFromOntology(configurations, objectOnly);
        optionsFromOntology.forEach(o => propertyOptions.push(o));
        propertyOptions = dedupeProperties(configurations, aliasesMap, propertyOptions, objectOnly)
        return propertyOptions;
    } else {
        return getDefaultProperties(configurations, aliasesMap, objectOnly);
    }
}

const StyledPropertiesAutocomplete = withStyles({
    root : {width : '120px'},
    paper : { minWidth : '200px'}
})(Autocomplete)

const CustomPopper = function (props) {
    return (<Popper {...props} style={{ width: 360 }} placement='bottom-start' />)
}

export function getPropertiesSelect(properties, property, onChange, label, classes, style, labelStyle, labelRootClass) {
    let inputLabelProps = classes
        ? {
            classes : {
                shrink: classes.smallWidthLabelShrink,
                root : labelRootClass ? classes[labelRootClass] : classes.smallWidthPropertyLabelRoot
            },
            style : labelStyle
        }
        : {};

    let sorted = properties.sort((a, b) => {
        let aVal = a.value?.fromShape ? getLocalName(a.value.fromShape, false) : undefined;
        let bVal = b.value?.fromShape ? getLocalName(b.value.fromShape, false) : undefined;
        if(aVal === undefined ) {
            return 1;
        }
        if( bVal === undefined) {
            return -1;
        }
        if(aVal > bVal) {
            return 1
        }
        if(aVal < bVal) {
            return -1;
        }
        return 0;
    });

    return properties &&
        <StyledPropertiesAutocomplete
            PopperComponent={CustomPopper}
            datatest={'autocompleteProperties'}
            style={style}
            value={property||null}
            options={sorted}
            getOptionLabel={option => option.label ? option.label : ''}
            getOptionSelected={(option, value) => {
                return option.value === value.value;
            }}
            renderOption={(option, { selected }) => {
                return <div datatest={'autocompleteProperties-'+option.label}>
                    <div>{option.label}</div>
                    <div><Typography variant={'caption'}>{option.tooltip}</Typography></div>
                </div>;
            }}
            onChange={(event, val) => {
                onChange(val)
            }}
            groupBy={(option) => getLocalName(option.value.fromShape, false) || 'Non Shape'}
            renderInput={params => (
                <OtherTextField
                    label={label || 'Property'}
                    {...params}
                    margin={"dense"}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={inputLabelProps}
                />
            )}
            size={"small"}
            disableClearable
        />;
}

export function hasAnyUnSelectedProperty(filters) {
    if(filters && filters.length > 0) {
        let found = filters.find(f => f.value === undefined);
        if(found) {
            return true;
        } else {
            return filters.find(f => hasAnyUnSelectedProperty(f.filters));
        }
    } else {
        return false;
    }
}

export function isCollapsed(f) {
    return f && f.expanded !== undefined && f.expanded === false;
}


const COMPONENT = 'SearchMixin'
class SearchMixin extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    initialiseState = () => {
        return {
            filters: [
                {
                    label: '',
                    range: [],
                    filters: [
                        {
                            label : ''
                        }
                    ]
                }
                ,
                {
                    label : ''
                }
            ]
        };
    }


    getProp(type) {
        let {aliasesMap} = this.props
        let alias = aliasesMap[type]
        return {label: alias, value: alias};
    }

    addToMixin = (f) => {
        addConnectedData(f)
        this.props.onChange(this.props.mixin)
    }

    propertyChange = (filter) => (value) => {
        setConnectedDataValue(filter, value)
        this.props.onChange(this.props.mixin)
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        return getUiLabelTranslationFromContext(this, key, defaultValue);
    }

    generateTree = (connection, level=0, hasAnyUnSelectedFilter) => {
        let {classes, configurations, aliasesMap, theme, onChange, mixin} = this.props;
        let filters = getConnections(connection);
        let onDelete = () => onChange(mixin);
        let renderProperty = (f) => this.getProperty(f);
        let renderValue = (f, level) => this.getValue(f, level, hasAnyUnSelectedFilter);
        let renderTree = () => generateTree(filters, level, hasAnyUnSelectedProperty, onDelete, renderProperty, undefined, renderValue, undefined, undefined, this.getUiLabelTranslationFor);
        let allOptions = [];
        if(mixin.type === PATHS && level > 0) {
            allOptions = getSelectOptions(configurations, aliasesMap, createChildConnection(connection), false)
        }
        return <>
            {
                mixin.type === PATHS && level > 0 &&
                <MultiValueSelect
                    classes={classes}
                    options={allOptions}
                    value={connection.properties}
                    label={'Properties'}
                    onChange={(event, val) => {
                        connection.properties = val
                        onChange(mixin)
                    }}
                />
            }
            {renderTree()}
        </>;
    }

    getProperty = (filter) => {
        let {classes, aliasesMap, configurations, mixin, onChange} = this.props
        let selectOptions = getSelectOptions(configurations, aliasesMap, filter, true)
        return <>
            {getPropertiesSelect(selectOptions, filter, this.propertyChange(filter), getUiLabelTranslationFromContext(this, UI_LABELS_CONNECTION_PROPERTY), classes, {width : '200px'}, {width : '160px'})}
        </>;
    }

    getValue = (f, level, hasAnyUnSelectedFilter) => {
        let {theme} = this.props;
        let renderTree = () => this.generateTree(f, (level + 1), hasAnyUnSelectedFilter);
        let onAddButtonClick = () => this.addToMixin(f);
        return isSelectOptionObjectType(f) && renderObjectValue(renderTree, theme, hasAnyUnSelectedFilter, getUiLabelTranslationFromContext(this, UI_LABELS_ADD_CONNECTION), onAddButtonClick, '160px');
    }

    render() {
        let {configurations, aliasesMap, classes, mixin, theme, onChange} = this.props
        traceRenderStart( () => console.log(mixin), COMPONENT)
        let {filters} = mixin
        let hasAnyUnSelectedFilter = hasAnyUnSelectedProperty(filters)
        return (<div datatest={'dataTabContent'}>
            <FieldContainer datatest={'dataFilter'} style={{marginTop: theme.spacing(1), padding : '8px 8px 8px 8px'}}>
                <H4Title>{getUiLabelTranslationFromContext(this, UI_LABELS_DATA_FILTER)}</H4Title>
                <FormControl component="fieldset" fullWidth>
                    <RadioGroup
                        name="mixinType"
                        className={classes.group}
                        style={{marginLeft : '8px'}}
                        value={mixin.type || MIXIN_DEFAULT}
                        onChange={(event, value) => {
                            if(value === MIXIN_DEFAULT) {
                                delete mixin.type;
                            } else {
                                mixin.type = value;
                            }
                            this.props.onChange(mixin);
                        }}
                        row={true}
                    >
                        <FormControlLabel value={CBD} control={<Radio/>}
                                          label={getUiLabelTranslationFromContext(this, UI_LABELS_NONE)}/>
                        <FormControlLabel value={PATHS} control={<Radio/>}
                                          label={getUiLabelTranslationFromContext(this, UI_LABELS_SELECTED_PROPERTIES)}/>
                        <FormControlLabel value={MIXIN_DEFAULT} control={<Radio/>}
                                          label={getUiLabelTranslationFromContext(this, UI_LABELS_NO_VALUE)}/>
                    </RadioGroup>
                    <Typography variant="body1" color="inherit">{
                        mixin.type === PATHS
                            ? getUiLabelTranslationFromContext(this, UI_LABELS_SELECTED_PROPERTIES_HELP, UI_LABELS_SELECTED_PROPERTIES_HELP_VALUE)
                            : (
                                mixin.type === CBD
                                    ? getUiLabelTranslationFromContext(this, UI_LABELS_NONE_HELP, UI_LABELS_NONE_HELP_VALUE)
                                    : getUiLabelTranslationFromContext(this, UI_LABELS_NONE_HELP, UI_LABELS_NO_VALUE_HELP_VALUE)
                            )
                    }</Typography>
                </FormControl>
            </FieldContainer>
            {   mixin.type === PATHS &&
                renderBlock(
                    getUiLabelTranslationFromContext(this, UI_LABELS_SEARCH_RESULTS),
                        <MultiValueSelect
                            classes={classes}
                            options={getSelectOptions(configurations, aliasesMap, createChildConnection(mixin), false)}
                            value={mixin.properties}
                            label={getUiLabelTranslationFromContext(this, UI_LABELS_PROPERTIES)}
                            onChange={(event, val) => {
                                mixin.properties = val;
                                onChange(mixin);
                            }}
                        />
                )
            }
            {
                renderBlock(
                    getUiLabelTranslationFromContext(this, UI_LABELS_CONNECTED),
                    <>
                        {this.generateTree(mixin, 0, hasAnyUnSelectedFilter)}
                        <div style={{display: 'flex'}}>
                            <div style={{flexGrow: '1'}}></div>
                            <Button
                                datatest={'addConnectionButton'}
                                disabled={hasAnyUnSelectedFilter}
                                variant={"contained"}
                                onClick={() => {
                                    this.addToMixin(mixin)
                                }}
                                color="secondary"
                            >{getUiLabelTranslationFromContext(this, UI_LABELS_ADD_CONNECTION)}
                            </Button>
                        </div>
                    </>
                )
            }
            </div>);
    }

}

SearchMixin.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    mixin: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
}

export default withStyles(styles, {withTheme: true})(SearchMixin);

import {getCookie, hasDatasetLevelWriteAuthenticationDisabled, setCookie} from "../../components/util";
import {
    COOKIE_ACCOUNT_TOKEN,
    COOKIE_DATASET_ID,
    COOKIE_MANAGEMENT_TOKEN,
    COOKIE_SINGLE_SIGN_IN_ID
} from "../../Constants";
import jwt_decode from "jwt-decode";
import {
    isAuthenticationDisabled,
    isMultipleDatasetsInstance
} from "../../Configs";

let profile = {}

function initProfileIfRequired(jwtToken) {
    if(!profile.sub) {
        //if token is given is parameter than that takes preference
        let token = jwtToken ? jwtToken : getCookie(COOKIE_MANAGEMENT_TOKEN);
        if(token) {
            if(token.includes(".")) {
                try {
                    let jwtDecode = jwt_decode(token);
                    let sub = jwtDecode.sub;
                    profile = jwtDecode;
                } catch (e) {
                    console.log(e);
                }
            } else {

            }
        }
    }
}

export function isManagementUserLoggedIn() {
    return getCookie(COOKIE_MANAGEMENT_TOKEN) ? true : false;
}

export function getUsername() {
    initProfileIfRequired();
    return profile.sub && profile.sub.split(",")[0];
}

export function getUserIri() {
    initProfileIfRequired();
    let split = profile.sub && profile.sub.split(",");
    if(split?.length > 1) {
        return split[1];
    } else {
        return undefined;
    }
}

export function getAccountUsername() {
    let token = getCookie(COOKIE_ACCOUNT_TOKEN);
    if(token) {
        if(token.includes(".")) {
            try {
                let jwtDecode = jwt_decode(token);
                let sub = jwtDecode.sub;
                return sub.split(",")[0];
            } catch (e) {
                console.log(e);
            }
        } else {
            return getCookie(COOKIE_SINGLE_SIGN_IN_ID)
        }
    }
    return undefined;
}


export function setViewAsReadOnly(value) {
    initProfileIfRequired();
    profile.viewAsReadonly = value;
}

export function getViewAsReadOnly() {
    initProfileIfRequired();
    return profile.viewAsReadonly;
}

export function setPageEdit(value) {
    initProfileIfRequired();
    profile.enablePageEdit = value;
}

export function isPageEditEnabled() {
    initProfileIfRequired();
    return profile.enablePageEdit;
}

export function setSettingsEditAction(value) {
    initProfileIfRequired();
    profile.settingsEditAction = value;
}

export function getSettingsEditAction() {
    initProfileIfRequired();
    return profile.settingsEditAction;
}

export function isSuperadmin(ignoreViewFlag) {
    if(ignoreViewFlag !== true && getViewAsReadOnly()) {
        return false;
    }
    initProfileIfRequired();
    return isAuthenticationDisabled() || profile.egHasRole && profile.egHasRole.split(",").includes("superadmin");
}

export function isSSOUser(jwtToken) {
    initProfileIfRequired(jwtToken);
    return profile.egHasRole && profile.egHasRole.split(",").includes("ssouser");
}

export function isAccountSysadmin() {
    let token = getCookie(COOKIE_ACCOUNT_TOKEN);
    if(token) {
        if(token.includes(".")) {
            try {
                let jwtDecode = jwt_decode(token);
                let role = jwtDecode.egHasRole;
                return role && role.split(",").includes("accountsysadmin");
            } catch (e) {
                console.log(e);
            }
        }
    }
    return false;
}

export function getSelectedDataset() {
    //Return cookie value only if multiple datsets
    //For single dataset setup there is not datasetLabel in paths
    //This stops errors when user switch between multiple to single dataset configuration
    //without this on switch browser will keep sending requests to datasets even if the setting is
    //single dataset
    return isMultipleDatasetsInstance() && getCookie(COOKIE_DATASET_ID);
}

export function setSelectedDataset(label) {
    return setCookie(COOKIE_DATASET_ID, label);
}

export function clear() {
    profile = {};
}

export function canUpdateConfigs() {
    return isSuperadmin() || isAuthenticationDisabled() || hasDatasetLevelWriteAuthenticationDisabled();
}

export function setUserPreference(key , value) {
    localStorage.setItem(key, value);
}

export function getUserPreference(key ) {
    return localStorage.getItem(key);
}
import React from "react";
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../components/styles";
import {STYLE_GRID_ITEM_SPACING} from "../Constants";
import {Button} from "@material-ui/core";

function FooterButton(props) {
    let {theme, startIcon, endIcon, disabled, onClick, variant, ...other} = props
    return <div><Button
        {...other}
        color={"secondary"}
        variant={variant}
        disabled={disabled}
        onClick={onClick}
        startIcon={startIcon}
        endIcon={endIcon}
        style={{
            marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)
        }}
    >{props.children}</Button></div>;
}

FooterButton.propTypes = {
    variant: PropTypes.string,
    startIcon: PropTypes.object,
    endIcon: PropTypes.object,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,

};

export default withStyles(styles, {withTheme: true})(FooterButton);

import React, {Component} from 'react';
import PropTypes from "prop-types";
import {IconButton as IconButtonInternal, Tooltip} from "@material-ui/core";
import {fade} from '@material-ui/core/styles';
import {SECONDARY_COLOR} from "../Constants";

const styles = theme => ({
});

class IconButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {selected, onClick, size, tooltip, disabled, children, style, ...rest } = this.props;
    let styleComputed =  selected
        ? {
            backgroundColor: fade(SECONDARY_COLOR, .1),
            ...style
          }
        : {...style};
    let color = disabled ? undefined : selected ? "secondary" : "primary";
    let button = <IconButtonInternal {...rest} disabled={disabled ? true : false} size={size} style={styleComputed} color={color} onClick={onClick}>{
      children
    }</IconButtonInternal>
    return tooltip ? <Tooltip   title={tooltip} style={{marginLeft: 10}}>{button}</Tooltip> : button;
  }
}

IconButton.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  tooltip: PropTypes.any,
  style: PropTypes.object
};

export default IconButton;

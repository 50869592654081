import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';

import {Button, Grid, IconButton} from "@material-ui/core";
import TextField from "../components/TextField";
import FieldContainer from "../components/FieldContainer";
import {ALIAS_SYS_VARIABLE_TYPE_HEADER, API_MODE_HTTP_API, HTTP_HEADER_ETAG, ID, TYPE} from "../Constants";
import {styles} from "./styles";
import ErrorMessage from "../components/ErrorMessage";
import GlobalsContext from "../components/GlobalsContext";
import DeleteIcon from "@material-ui/icons/Delete";
import {centerVertically, getHeaderMap} from "../components/util";
import {isString} from 'lodash';
import {createVariable, deleteVariable, evaluatePath} from "../components/VariablesBuilder";

class HeaderVariable extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        let {variable} = this.props
        this.state = {
            name: variable && variable.name  ? variable.name : '',
            path: variable && variable.path  ? variable.path : ''
        }
    }

    evaluate = () => {
        const globals = this.context
        let {path, name} = this.state
        let {json}= this.props

        let value = evaluatePath(json, globals, name, `$..${path}`);
        // if value is undefined then clear the preview text box
        return value === undefined ? "" : (isString(value) ? value : JSON.stringify(value) );
    }

    onChange = ({ target: { name, value } }) => {
        let {onChange, variable} = this.props
        this.setState({[name] : value})
        variable[name] = value
        onChange && onChange(variable)
    }

    render() {
        let {name, path} = this.state
        let {json, onDelete} = this.props
        return <>
            <Grid item xs={3}>
                <TextField
                    paperStyle={{padding: '0'}}
                    name={'name'}
                    value={name}
                    fullWidth={true}
                    onChange={this.onChange}
                />
            </Grid>
            <Grid item xs={4}>
                <TextField
                    paperStyle={{padding: '0'}}
                    readOnly={true}
                    name={'path'}
                    value={path}
                    fullWidth={true}
                    onChange={this.onChange}
                />
            </Grid>
            <Grid item xs={5}>
                <div style={{display:'flex', height: '100%'}}>
                    {
                        json
                            ? <div style={{flexGrow: '1'}}>
                                <TextField
                                    paperStyle={{padding: '0'}}
                                    name={'value'}
                                    value={this.evaluate()}
                                    fullWidth={true}
                                    readOnly={true}
                                />
                            </div>
                            : centerVertically(<ErrorMessage  color={'primary'} error={'Send request to view value.'}/>)
                    }
                    {
                        centerVertically(<IconButton size={"small"} onClick={onDelete}>
                            <DeleteIcon fontSize="small"/>
                        </IconButton>)
                    }
                </div>
            </Grid>

        </>;
    }

}

HeaderVariable.propTypes = {
    variable: PropTypes.object,
    json: PropTypes.object,
    onChange: PropTypes.func,
    onDelete: PropTypes.func
};

export function getEtagHeaderValue(response) {
    let headerMap = getHeaderMap(response);
    const etagHeader = HTTP_HEADER_ETAG.toLowerCase();
    return Object.keys(headerMap).map((k, i) => {
        if(k.toLowerCase() === etagHeader) {
            let value = headerMap[k];
            return value;
        } else {
            return undefined;
        }
    }).find(v => v);

}

class HeaderVariablesBuilder extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        this.state = {}
    }

    addETagFromHeader = () => {
        const {variables, response} = this.props;
        let headerMap = getHeaderMap(response);
        const etagHeader = HTTP_HEADER_ETAG.toLowerCase();
        Object.keys(headerMap).forEach((k, i) => {
            if(k.toLowerCase() === etagHeader) {
                let value = headerMap[k];
                let variable = createVariable(ALIAS_SYS_VARIABLE_TYPE_HEADER, undefined, k, value, i);
                variables.push(variable);
                this.onVariableChange();
            }
        })
    };

    onVariableChange = () => {
        const {variables, onChange} = this.props;
        onChange && onChange(variables)
        this.setState({})
    }

    render() {
        const {variables, response, apiMode} = this.props;
        let headerMapJSON = response && getHeaderMap(response);
        let headerVariables = variables.filter(v => v[TYPE] === ALIAS_SYS_VARIABLE_TYPE_HEADER)
        return <FieldContainer>
            <Grid container spacing={1}>
                <Grid item xs={3}>Variable Name</Grid>
                <Grid item xs={4}>Header Name</Grid>
                <Grid item xs={5}>Preview Value</Grid>
                {
                    headerVariables.map((v, i) => {
                        return <HeaderVariable
                            key={v[ID]}
                            onChange={(v) => this.onVariableChange()}
                            onDelete={() => {
                                deleteVariable(variables, v[ID])
                                this.onVariableChange()
                            }}
                            json={headerMapJSON}
                            variable={v}
                        />;
                    })
                }
            </Grid>
            <FieldContainer disableLeftRightPadding={true}>
                <div style={{display: 'flex'}}>
                    <div style={{flexGrow : '1'}}></div>
                    {apiMode === API_MODE_HTTP_API.value && <Button
                        disabled={!headerMapJSON || headerVariables.find(v => v.path.toLowerCase() ===  HTTP_HEADER_ETAG.toLowerCase() )}
                        onClick={this.addETagFromHeader}
                        color={"secondary"}
                        variant={"outlined"}>Set ETag from Header</Button>}
                </div>
            </FieldContainer>
        </FieldContainer>;
    }

}

HeaderVariablesBuilder.defaultProps = {
};

HeaderVariablesBuilder.propTypes = {
    response: PropTypes.object,
    variables: PropTypes.array,
    onChange: PropTypes.func,
    apiMode: PropTypes.string
};

export default withStyles(styles, {withTheme: true})(HeaderVariablesBuilder);

import React from 'react';
import PropTypes from "prop-types";
import {Grid, Paper, TextField as OtherTextField, withStyles} from "@material-ui/core";
import {styles} from "./styles";
import CardActionButton from "./CardActionButton";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import EditIcon from '@material-ui/icons/EditOutlined';
import TextField from "./TextField";
import {
    ALIAS_SH_CLASS,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MAX_LENGTH,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_MIN_LENGTH,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_OR,
    ALIAS_SH_PATH,
    ALIAS_SH_PATTERN,
    ALIAS_SH_PROPERTY,
    LABEL_CLASS,
    LABEL_CLASS_FOR_RDF_LIST,
    LABEL_DATA_TYPE,
    LABEL_MAX_COUNT,
    LABEL_MAX_LENGTH,
    LABEL_MIN_COUNT,
    LABEL_MIN_LENGTH,
    LABEL_NODE_KINDS,
    STYLE_MAIN_SPACING,
    TYPE_RDF_LIST
} from "../Constants";
import {getDatatypeLabel} from "./util";
import CreateShapePropertyDialog from "./CreateShapePropertyDialog";
import Label from "./Label";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import LockIcon from "@material-ui/icons/Lock";
import {LABEL_NODE_KIND, LABEL_PATH, LABEL_PATTERN} from "../Constants";
import {ConfirmAreYouSure} from "../components/ConfirmAreYouSure";

class SHACLPropertyCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {

        const {classes, theme, focusNode, property, allowDelete=true, allowEdit=false, deleteHandler, width, leftAction, rightAction, aliasesMap, ontology} = this.props;
        let xsWidth = width ? width : 12
        let mdWidth = width ? width : 6
        let actionWidth = '32px'
        let cardStyle = leftAction
            ? { width : 'calc(100% - '+actionWidth+')', float: 'right'}
            : (rightAction ? { width : 'calc(100% - '+actionWidth+')', float: 'left'} : {})
        cardStyle = {
            height : '100%',
            ...cardStyle
        }
        let fieldStyle = {paddingTop: '0px'}
        let propertyName = aliasesMap[property[ALIAS_SH_PATH]];
        return (
        <Grid datatest={'shaclPropertyCard-'+ propertyName} key={focusNode.id + property[ALIAS_SH_PATH]} item xs={xsWidth} md={mdWidth}>
            {leftAction && <div style={{width: actionWidth, float: 'left', height: '100%', textAlign: 'left'}}>{leftAction}</div>}
            <div style={cardStyle}>
            <Paper elevation={0} style={{height : 'calc(100% - 8px)', padding: theme.spacing(1, 0), backgroundColor: theme.palette.border.main}}>
                <div style={{float: 'right', marginBottom : -theme.spacing(STYLE_MAIN_SPACING), marginTop: '-4px'}}>
                    {
                        allowEdit &&
                            <CreateShapePropertyDialog
                                aliasesMap={aliasesMap}
                                buttonIcon={<EditIcon/>}
                                editProp={property[ALIAS_SH_PATH]}
                                classes={classes}
                                theme={theme}
                                shape={focusNode.backingObject}
                                eventHandler={this.props.eventHandler}
                                ontology={ontology}
                            />
                    }
                    {
                        allowDelete &&
                            <CardActionButton
                                datatest={'deletePropertyButton'}
                                classes={classes}
                                title={'Delete property'}
                                onClick={() => this.setState({openDeleteConfirm: true})}
                                icon={<DeleteIcon/>}
                            />
                    }
                    {
                        this.state.openDeleteConfirm &&
                            <ConfirmAreYouSure
                                deleteWarning={`Delete property for path '${propertyName}' ?`}
                                handleNo={() => { this.setState({openDeleteConfirm: false}) }}
                                handleYes={() => deleteHandler(focusNode.backingObject, property[ALIAS_SH_PATH])}
                            />
                    }
                </div>
                <Grid  container spacing={1}>
                    <Grid item xs={12}>
                        <TextField paperStyle={fieldStyle} id="path" name="path" tooltip={property[ALIAS_SH_PATH]} value={propertyName} label={LABEL_PATH} readOnly={true}/>
                    </Grid>
                    {
                        property[ALIAS_SH_DATATYPE] && <Grid item xs={12}>
                            <TextField paperStyle={fieldStyle}  id="iri" name="iri" value={getDatatypeLabel(property[ALIAS_SH_DATATYPE])} label={LABEL_DATA_TYPE} readOnly={true}/>
                        </Grid>
                    }
                    {
                        (property[ALIAS_SH_OR] || property[ALIAS_SH_CLASS] )&& <Grid item xs={12}>
                            <div datatest={'autocompleteClass'} style={{ minHeight:'70px', borderRadius: '4px', padding: '12px', backgroundColor: 'rgb(238, 238, 238)',  ...fieldStyle  }}>
                                <Label label={LABEL_CLASS}/>
                                <Autocomplete
                                    id="classSelect"
                                    value={this.getPropertyClasses(property)}
                                    options={this.getPropertyClasses(property)}
                                    getOptionLabel={option => option.label ?  option.label : ''}
                                    multiple={true}
                                    disabled={true}
                                    disableClearable={true}
                                    renderInput={params => (
                                        <OtherTextField {...params} margin={"dense"} variant="outlined" fullWidth />
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        return value.map((option, index) => {
                                            let {onDelete, ...tagProps} = {...getTagProps({index})}
                                            return (<Tooltip key={index+option.tooltip} title={option.tooltip}>
                                                <Chip datatest={option.label} size={"small"} label={option.label} {...tagProps}/>
                                            </Tooltip>)
                                        })
                                    }}
                                    size={"small"}
                                    popupIcon={<LockIcon style={{color: theme.palette.grey.level2, paddingRight: theme.spacing(1)}} fontSize={"small"}/>}
                                />
                            </div>
                        </Grid>
                    }
                    {
                        property[ALIAS_SH_CLASS] && property[ALIAS_SH_CLASS] === TYPE_RDF_LIST && property[ALIAS_SH_PROPERTY] && property[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS] &&
                        <Grid item xs={12}>
                            <TextField paperStyle={fieldStyle}  id="classRDFList" name="classRDFList"
                                       tooltip={property[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS]}
                                       value={aliasesMap[property[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS]]}
                                       label={LABEL_CLASS_FOR_RDF_LIST}
                                       readOnly={true}/>

                        </Grid>
                    }
                    {
                        property[ALIAS_SH_NODE_KIND] && <Grid item xs={12}>
                            <TextField paperStyle={fieldStyle}  id="nodeKind" name="nodeKind"
                                       tooltip={property[ALIAS_SH_NODE_KIND]}
                                       value={LABEL_NODE_KINDS[property[ALIAS_SH_NODE_KIND]]} label={LABEL_NODE_KIND}
                                       readOnly={true}/>

                        </Grid>
                    }
                    {
                        property[ALIAS_SH_MIN_LENGTH] && <Grid item xs={4}>
                            <TextField paperStyle={fieldStyle}  id="minLength" name="minLength"
                                       value={"" + property[ALIAS_SH_MIN_LENGTH]} label={LABEL_MIN_LENGTH}
                                       readOnly={true}/>

                        </Grid>
                    }
                    {
                        property[ALIAS_SH_MAX_LENGTH] && <Grid item xs={4}>
                            <TextField paperStyle={fieldStyle}  id="maxLength" name="maxLength"
                                       value={"" + property[ALIAS_SH_MAX_LENGTH]} label={LABEL_MAX_LENGTH}
                                       readOnly={true}/>

                        </Grid>
                    }
                    {
                        property[ALIAS_SH_PATTERN] && <Grid item xs={4}>
                            <TextField paperStyle={fieldStyle}  id="pattern" name="pattern"
                                       value={"" + property[ALIAS_SH_PATTERN]} label={LABEL_PATTERN}
                                       readOnly={true}/>

                        </Grid>
                    }
                    {
                        property[ALIAS_SH_MIN_COUNT] && <Grid item xs={6}>
                            <TextField paperStyle={fieldStyle}  id="minCount" name="minCount" value={"" + property[ALIAS_SH_MIN_COUNT]} label={LABEL_MIN_COUNT} readOnly={true}/>
                        </Grid>
                    }
                    {
                        property[ALIAS_SH_MAX_COUNT] && <Grid item xs={6}>
                            <TextField paperStyle={fieldStyle}  id="maxCount" name="maxCount" value={"" + property[ALIAS_SH_MAX_COUNT]} label={LABEL_MAX_COUNT} readOnly={true}/>
                        </Grid>
                    }
                </Grid>
            </Paper>
            </div>
            {rightAction && <div style={{width: actionWidth, float: 'right', height: '100%', textAlign: 'right'}}>{rightAction}</div>}
        </Grid>
        );
    }

    getPropertyClasses = (property) => {
        let singleClass = property[ALIAS_SH_CLASS]
        if(singleClass) {
            return [{
                value: singleClass,
                label: this.props.aliasesMap[singleClass],
                tooltip: singleClass
            }];
        } else {
            return property[ALIAS_SH_OR].map(c => ({
                value: c[ALIAS_SH_CLASS],
                label: this.props.aliasesMap[c[ALIAS_SH_CLASS]],
                tooltip: c[ALIAS_SH_CLASS]
            }));
        }
    }
}

SHACLPropertyCard.propTypes = {
    classes: PropTypes.object.isRequired,
    focusNode: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    leftAction: PropTypes.object,
    rightAction: PropTypes.object,
    allowDelete: PropTypes.bool,
    allowEdit: PropTypes.bool,
    deleteHandler: PropTypes.func,
    eventHandler: PropTypes.func,
    width: PropTypes.number,
    ontology: PropTypes.array,
};

export default withStyles(styles, {withTheme: true})(SHACLPropertyCard);

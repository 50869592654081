import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import AllPartsLayout from "../AllPartsLayout";
import MainHeaderBarLeft from "../../components/MainHeaderBarLeft";
import {searchInTree, sort} from "../../components/util";
import TreeViewForIdSetup from "../../components/TreeViewForIdSetup";
import Grid from "@material-ui/core/Grid";
import {STYLE_GRID_ITEM_SPACING} from "../../Constants";
import DetailsTitle from "../../components/DetailsTitle";
import UILabel, {DEFAULT_UI_LABELS, UI_LABELS} from "./UILabel";
import FieldContainer from "../../components/FieldContainer";

class TranslationsSetup extends Component {
    constructor(props) {
        super(props);
        if(!props.settings[UI_LABELS]) {
            props.settings[UI_LABELS] = DEFAULT_UI_LABELS;
        }
        //If the key does not exist than copy from default
        Object.keys(DEFAULT_UI_LABELS).forEach(k => {
            if(!props.settings[UI_LABELS][k]) {
                props.settings[UI_LABELS][k] = DEFAULT_UI_LABELS[k];
            }
        })
        let treeData = Object.keys(props.settings[UI_LABELS]).map(d => ({
            id : d,
            title : d
        }));

        this.state = {
            treeData : sort(treeData)
        }
    }

    componentDidMount() {
        let {onChange} = this.props;
        onChange();
    }

    onNodeClick = (node) => {
        let {location} = this.props;
        let focusNode = searchInTree(node.id, this.state.treeData)
        if (focusNode) {
            this.setState({focusNode: focusNode})
        }
    }

    getStepLeftContent = () => {
        const {treeData, focusNode} = this.state;
        const {settings, theme, location, ontology, aliasesMap, configurations} = this.props;


        return <>
            <TreeViewForIdSetup
                bootstrap={true}
                hasTabs={false}
                data={treeData}
                treeData={treeData}
                location={location}
                viewType={'list'}
                toggle={false}
                disableViewType={true}
                onNodeClick={this.onNodeClick}
                focusedNodeId={focusNode?.id}
            />
        </>;
    }

    setupChanged = () => {
        let {onChange} = this.props;
        onChange();
    }

    middleContent = () => {
        let {settings, theme} = this.props;
        let {focusNode} = this.state;
        if (!focusNode) {
            return <></>;
        }
        let valueObject = settings[UI_LABELS][focusNode.id];

        return <Grid key={focusNode.id} container spacing={STYLE_GRID_ITEM_SPACING} xs={12}>
            <Grid item xs={12}>
                <DetailsTitle title={focusNode.id}/>
            </Grid>
            <Grid item xs={12}>
                <FieldContainer style={{ marginBottom: '8px', padding: '8px'}}>
                    <FieldContainer datatest={'translation'} style={{marginBottom: '8px'}}>
                        <UILabel
                            settings={settings}
                            valueObject={valueObject}
                            onChange={this.setupChanged}
                        />
                    </FieldContainer>
                </FieldContainer>
            </Grid>
        </Grid>
    }



    render() {
        const {stepName} = this.props;

        return <AllPartsLayout
            header={undefined}
            leftComponentScroll={{y: 'hidden', x: 'hidden'}}
            leftComponentStyle={{paddingTop: '0px'}}
            leftComponentContainerStyle={{paddingRight: '0px'}}
            leftMainHeader={<MainHeaderBarLeft title={stepName}/>}
            leftComponent={this.getStepLeftContent()}
            middleActions={undefined}
            middleComponent={this.middleContent()}
        />;
    }
}

TranslationsSetup.propTypes = {
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    location: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    stepName: PropTypes.string,
};

export default withStyles(styles, {withTheme: true})(TranslationsSetup);

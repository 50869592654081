import React from "react";
import {withStyles} from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {loadResource} from "../../service/data-loader";
import {getValuesObject} from "./SearchResultItem";
import {centerVertically, getPropertyName, isEmptyArray, sort, toArray} from "../../components/util";
import {Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {ID, LANG, TYPE, VALUE} from "../../Constants";
import {LinkOutlined} from "@material-ui/icons";
import ListResultItem from "./ListResultItem";
import {getSourceResourceDetails} from "./AddDataPropertyValueDialog";
import {isString} from "lodash";
import {StringValue} from "./Tree";

const styles = {
    dialogPaper: {
        minHeight: '90vh',
        maxHeight: '90vh',
    },
};

class ViewLinkedObjectDialog extends React.Component {
    constructor(props) {
        super(props);
        let nonIris = toArray(props.objectIris).filter(v => isString(v) === false);

        this.state = {
            loadedCount : 0,
            loaded : [],
            failed : new Set(),
            nonIris
        }
    }

    componentDidMount() {
        this.handleLoadMore().catch();
        let {sourceResource, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        getValuesObject(sourceResource, settings, aliasesToIRIMap, browseLanguage, ontology).then((vo) => {
            let {title} = vo;
            this.setState({sourceResourceTitle : title})
        })

    }

    handleLoadMore = async (event) => {
        event?.stopPropagation();
        event?.preventDefault();
        let {loaded, failed} = this.state;
        let {isCellSelected, objectIris, settings, aliasesToIRIMap, browseLanguage, ontology, theme, location} = this.props;
        let loadedCount = loaded.length;
        let toLoad = objectIris.filter(v => isString(v)).slice(loadedCount, loadedCount + 5);
        let resources = await loadResource(toLoad);
        let all = resources.map(async (r, index) => {
            let id = toLoad[index];
            if(r) {
                let vo = await getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology);
                vo.backingObject = r;
                return vo;
            } else {
                failed.add(id);
            }
            return {id};
        });
        Promise.all(all).then(vl => {
            let allLoaded1 = sort([...loaded, ...vl]);
            this.setState({ loaded : allLoaded1});
        })
    }

    render() {
        let {classes, sourceResource, secondaryActionButton, linkProperty, aliasesToIRIMap, configurations, aliasesMap, ontology, objectIris, theme, onClose, location, browseLanguage, settings} = this.props;
        let {loaded, nonIris, failed, sourceResourceTitle} = this.state;


        return <Dialog
            datatest={'viewLinkedObjectDialog'}
            fullWidth={true}
            maxWidth={'sm'}
            open={true}
            classes={{ paper: classes.dialogPaper }}
            onKeyDown={(ev) => {
                if (ev.key === 'Escape') {
                    onClose();
                } else if(ev.key === 'Enter') {
                    //Only stop propagation but do not preventDefault as we want to add new line in text field
                    ev.stopPropagation();
                }
            }}
        >
            <DialogTitle id="form-dialog-title">
                <div style={{display : 'flex'}}>
                    {
                        getSourceResourceDetails(
                            sourceResourceTitle,
                            sourceResource,
                            getPropertyName(aliasesToIRIMap, linkProperty, ontology, browseLanguage),
                            <LinkOutlined style={{marginRight: '4px', color : theme.palette.link.main}}/>
                        )

                    }
                    {centerVertically(secondaryActionButton)}
                </div>
            </DialogTitle>
            <DialogContent style={{backgroundColor : theme.palette.grey.background}}>
                {
                    nonIris &&
                    isEmptyArray(toArray(nonIris)) === false
                    && nonIris.map((io, i) => {
                            return <div datatest={'nonIris'} key={i} style={{
                                marginBottom: '8px',
                                padding: '8px',
                                borderRadius: '4px',
                                borderWidth: '1px',
                                borderStyle: 'solid',
                                borderColor: theme.palette.grey.level2,
                                backgroundColor: theme.palette.white.main
                            }}>
                                <StringValue settings={settings} value={io[VALUE]} datatype={io[TYPE]} langCode={io[LANG]}/>
                            </div>
                        }
                    )}
                {
                    loaded.map((vo, index) => {

                        const resourceId = vo?.[ID];
                        let hasFailedToLoad = failed.has(resourceId);
                        return hasFailedToLoad ?
                            <div datatest={'resourcePreviewBlock'} style={{width : '100%'}}>
                                <TextField fullWidth={true} variant={'outlined'} value={resourceId}></TextField>
                                <iframe
                                    style={{borderRadius : '4px', border : '1px solid', borderColor :  theme.palette.grey.level2, width : '100%'}}
                                    height="80"
                                    src={resourceId}>
                                </iframe>
                            </div>
                            : <ListResultItem
                                containerStyle={{overflow : 'auto', border : '1px solid', borderColor :  theme.palette.grey.level2, marginBottom : '8px'}}
                                viewProperties={[]}
                                hideTreeActions={true}
                                customizations={{readOnly: true}}
                                resource={vo.backingObject}
                                theme={theme}
                                configurations={configurations}
                                aliasesMap={aliasesMap}
                                aliasesToIRIMap={aliasesToIRIMap}
                                settings={settings}
                                location={location}
                                browseLanguage={browseLanguage}
                                ontology={ontology}
                                linkProperty={linkProperty}
                                sourceResource={sourceResource}
                                disableSelection={true}
                                hideIdInTitle={false}
                            />;
                    })
                }
                <div style={{textAlign : 'center'}}>{
                    objectIris.filter(v => isString(v)).length > loaded.length ?
                        <Button datatest={'loadMoreButton'} variant={'outlined'} color={'secondary'} size={'small'} onClick={this.handleLoadMore}>{
                            loaded.length === 0 ? 'Load' : 'Load More'
                        }</Button>
                        : <></>
                }</div>

            </DialogContent>
            <DialogActions>
                <div style={{flexGrow : '1'}}></div>
                <Button
                    datatest={'closeButton'}
                    variant={"contained"}
                    color="secondary"
                    onClick={onClose}
                >Close</Button>
            </DialogActions>
        </Dialog>;

    }



}

ViewLinkedObjectDialog.propTypes = {
    location : PropTypes.any,
    objectIris: PropTypes.any,
    configurations: PropTypes.any,
    settings: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    aliasesMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    sourceResource: PropTypes.any,
    linkProperty: PropTypes.any,
    ontology: PropTypes.any,
    theme: PropTypes.any,
    onClose: PropTypes.any,
    secondaryActionButton: PropTypes.any,
}

export default withStyles(styles, {withTheme:true})(ViewLinkedObjectDialog);

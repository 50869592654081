import React, {Component, createContext, useContext, useLayoutEffect, useMemo, useRef, useState} from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles as mainStyle} from "../../components/styles";
import {withEvent} from "./Event";
import PropTypes from "prop-types";
import {
    centerVertically,
    flatten,
    getBrowseLanguageCode,
    getSearchResult,
    handleBackendError,
    isRequestSuccessful,
    sort,
    sortByFunction,
    toArray
} from "../../components/util";
import {PermissionService, withPermissions} from "../../service/permission-service";
import './DataGridView.css';
import YasqeWrapper from "./sparql/YasqeWrapper";
import {
    deleteManagementGraph,
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    patchManagementGraph,
    postManagementGraph
} from "../../service/graph-api";
import Button from "@material-ui/core/Button";
import {EmptyRowsRenderer, EXTRA_ROWS, RowRenderer, StyledBadge} from "./DataGridView";
import {
    ALIAS_MANAGEMENT_FOR_APPLICATION,
    ALIAS_MANAGEMENT_LINKED_TO,
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_MANAGEMENT_TYPE_CONFIGURATION,
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    AT_GRAPH,
    DATA,
    EASYGRAPH_DATA_APPLICATION_EXPLORER_DATA_VISUALISATION,
    HTTP_HEADER_PREFER,
    ID,
    MIXIN,
    PAGE_SIZE,
    PATHS,
    SORT_ASCENDING,
    SORT_DESCENDING,
    TYPE,
    XSD_DATE,
    XSD_DATETIME,
    XSD_DECIMAL,
    XSD_DOUBLE,
    XSD_FLOAT,
    XSD_INT,
    XSD_INTEGER,
    XSD_LONG,
    XSD_NEGATIVE_INTEGER,
    XSD_NON_NEGATIVE_INTEGER,
    XSD_NON_POSITIVE_INTEGER,
    XSD_POSITIVE_INTEGER,
    XSD_SHORT,
    XSD_UNSIGNED_INT,
    XSD_UNSIGNED_LONG,
    XSD_UNSIGNED_SHORT
} from "../../Constants";
import YasrWrapper from "./sparql/YasrWrapper";
import uuid4 from "uuid/v4";
import {
    Area,
    Bar,
    CartesianGrid,
    ComposedChart,
    Legend,
    Line,
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    ResponsiveContainer,
    Scatter,
    Tooltip,
    XAxis,
    YAxis
} from "recharts";
import {
    InputLabel,
    LinearProgress,
    makeStyles,
    MenuItem,
    Popper,
    TextField as OtherTextField,
    TextField,
    Tooltip as MuiTooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import {ColorPicker} from "./BasicSetup";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import {
    ArrowDownwardOutlined,
    ArrowUpwardOutlined,
    DeleteOutlined,
    FullscreenExitOutlined,
    GetAppOutlined,
    HeightOutlined,
    OpenWithOutlined,
    PlayArrowOutlined,
    RefreshOutlined,
    SaveOutlined,
    SettingsBackupRestoreOutlined,
    SwapHorizOutlined
} from "@material-ui/icons";
import H2Title from "../../components/H2Title";
import {renderLogo} from "./Workspace";
import {getChipWithDelete} from "../apiplayground/SearchFilter";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {isSuperadmin} from "../common/Profile";
import IconButton from "@material-ui/core/IconButton";
import SaveDialogWithTitle, {ACTION_UPDATE} from "./SaveDialogWithTitle";
import {getNewIdForWorkspace} from "./PagesSetup";
import {setLinkedToId} from "./Workspaces";
import {BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION} from "../../service/backend-paths";
import H3Title from "../../components/H3Title";
import {
    getUiLabelTranslation,
    UI_LABELS_CANCEL,
    UI_LABELS_DOWNLOAD,
    UI_LABELS_DOWNLOAD_FORMAT_CSV,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB,
    UI_LABELS_FULL_SCREEN,
    UI_LABELS_FULL_SCREEN_EXIT,
    UI_LABELS_REFRESH,
    UI_LABELS_RESET_FILTERS,
    UI_LABELS_SORT,
    UI_LABELS_SPARQL_RUN
} from "./UILabel";
import ErrorMessage from "../../components/ErrorMessage";
import DataGrid from "react-data-grid";
import H4Title from "../../components/H4Title";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import {encodeValues, newLine} from "./DownloadDialog";
import fileDownload from "js-file-download";


const styles = () => {
    return Object.assign({
        queryManagerContainer: {
            float: 'right',
            clear: 'both',
            width: '300px',
            backgroundColor: '#e5e5e5',
            paddingTop: 12,
            borderRadius: 4
        },
        yasguiContainer: {
            clear: 'both'
        }

    }, mainStyle);
};
const MIXED_CHART_TYPES = ['Area', 'Bar', 'Line', 'Scatter'];
const RADAR_CHART = 'Radar Chart';
const DATA_GRID = 'Data Grid';
const VISUALISATION_TYPES = ['Mixed Chart', RADAR_CHART, DATA_GRID];

//Changing default query will cause failure in cypress tests too
const DEFAULT_QUERY =
`PREFIX xsd: <http://www.w3.org/2001/XMLSchema#>

SELECT (count(?s) as ?Count) ?Type (xsd:integer(FLOOR(RAND() * 100)) AS ?Random) WHERE {
?s a ?Type .
}
GROUP BY ?Type
LIMIT 10`;

const NAME_DATA_VISUALISATION = 'DataVisualisation';

export async function getSavedDataVisualisations(workspace) {
    const searchParams = {
        [ALIAS_MANAGEMENT_NAME] : NAME_DATA_VISUALISATION,
        [PAGE_SIZE] : 1000
    }
    setLinkedToId(searchParams, workspace[ID]);
    let headerMap = {
        [HTTP_HEADER_PREFER] : `return=representation;${MIXIN}="{ ${ID} ${ALIAS_SYS_ETAG} ${TYPE} ${ALIAS_MANAGEMENT_TITLE} ${ALIAS_MANAGEMENT_NAME}}";${DATA}=${PATHS}`
    }

    return new Promise((resolve, reject) => {
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, searchParams, headerMap, false)
            .then(pageObjectsResults => {
                if(isRequestSuccessful(pageObjectsResults)) {
                    pageObjectsResults.json().then(json => {
                        let pageObjects = getSearchResult(json);
                        resolve(pageObjects);
                    })
                } else {
                    resolve([]);
                }
            })
            .catch(reject);
    })
}

export async function getSavedDataVisualisationsOptions(workspace) {
    let savedVisualisations = (await getSavedDataVisualisations(workspace)).map(r => ({backingObject : r, value : r[ID], label : r[ALIAS_MANAGEMENT_TITLE]}));
    sort(savedVisualisations, 'label');
    return savedVisualisations;
}

export async function getAllDataVisualisationsData(workspaceId) {
    const searchParams = {
        [ALIAS_MANAGEMENT_LINKED_TO] : workspaceId,
        [ALIAS_MANAGEMENT_NAME] : NAME_DATA_VISUALISATION

    }
    let headerMap = {
    }

    return new Promise((resolve, reject) => {
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, searchParams, headerMap, false)
            .then(pageObjectsResults => {
                if(isRequestSuccessful(pageObjectsResults)) {
                    pageObjectsResults.json().then(json => {
                        let pageObjects = getSearchResult(json);
                        resolve(pageObjects);
                    })
                } else {
                    resolve([]);
                }
            })
            .catch(reject);
    })
}

const useStyles = makeStyles(theme => ({
    root: {
        blockSize :'100%',
        borderBottom : 'none'
    }
}));

export function StyledDataGrid({columns, rows, rowHeight, ...rest}) {
    const classes = useStyles();

    return (<DataGrid className={classes.root}  columns={columns} rows={rows} rowHeight={rowHeight} {...rest}/>);
}

const FilterContext = createContext(undefined);

function useFocusRef(isSelected) {
    const ref = useRef(null);

    useLayoutEffect(() => {
        if (!isSelected) return;
        ref.current?.focus({ preventScroll: true });
    }, [isSelected]);

    return {
        ref,
        tabIndex: isSelected ? 0 : -1
    };
}

function FilterRenderer({
    isCellSelected,
    column,
    children,
    onSortBy,
    sortValue,
    settings,
    browseLanguage
}) {
    const filters = useContext(FilterContext);
    const { ref, tabIndex } = useFocusRef(isCellSelected);

    const sortClick = () => {
        onSortBy(column);
    }
    return (
        <>
            <div datatest={'header-'+column.key} style={{ display: "flex", minHeight : '36px' }}>
                {
                    sortValue && centerVertically(sortValue.direction === SORT_ASCENDING ? <ArrowUpwardOutlined datatest={'arrowUp'} style={{fontSize : '20px'}}/> : <ArrowDownwardOutlined datatest={'arrowDown'} style={{fontSize : '20px'}}/>)
                }
                {
                    centerVertically(
                        <H3Title noWrap={true}>
                            <span>{column.name}</span>
                        </H3Title>
                    )
                }
                <div style={{ flexGrow: "1" }} />
                {
                    centerVertically(
                        sortValue ? <StyledBadge color="secondary" badgeContent={sortValue.index}>
                              <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_SORT, browseLanguage, UI_LABELS_SORT)}>
                                  <IconButton datatest={'sortButton'} onClick={sortClick} size={'small'}><HeightOutlined/></IconButton>
                              </MuiTooltip>
                        </StyledBadge> :
                            <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_SORT, browseLanguage, UI_LABELS_SORT)}>
                                <IconButton datatest={'sortButton'} onClick={sortClick} size={'small'}><HeightOutlined/></IconButton>
                            </MuiTooltip>
                    , {marginRight  :"4px"})
                }
            </div>

            {filters.enabled && <div>{children({ ref, tabIndex, filters, isCellSelected })}</div>}
        </>
    );
}

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

class DownloadDialogInner extends Component {
    constructor(props) {
        super(props);
        this.state = {
            format : UI_LABELS_DOWNLOAD_FORMAT_CSV,
            delimiter : ',',
        }
    }

    apply = () => {
        let {format, delimiter, customDelimiter} = this.state;
        let delimiterToUse = delimiter === UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM ? customDelimiter : delimiter;
        this.setState({loading : true})
        this.props.onDownload({
            format, delimiter : delimiterToUse
        });
    }

    render() {
        let {onClose, classes, settings, browseLanguage} = this.props;
        let {format, delimiter, customDelimiter, loading} = this.state;

        let widthStyle = {minWidth: '160px', maxWidth: '160px'};
        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={true}
                    datatest={'downloadDialog'}
                    classes={{ paper: classes.dialogPaper }}
                    onKeyDown={(ev) => {
                        if(ev.ctrlKey && ev.key === 'Enter') {
                            this.apply();
                        } else if (ev.key === 'Escape') {
                            onClose();
                        } else if(ev.key === 'Enter') {
                            //Only stop propagation but do not preventDefault as we want to add new line in text field
                            ev.stopPropagation();
                        }
                    }}

                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <div style={{display: 'flex'}}>
                            {centerVertically(<H2Title title={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD, browseLanguage, UI_LABELS_DOWNLOAD) }/>)}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container xs={12} spacing={3}>
                            {
                                format === UI_LABELS_DOWNLOAD_FORMAT_CSV && <>
                                    <Grid item xs={12}>
                                        {
                                            centerVertically(
                                                <FormControl style={widthStyle}
                                                             size={'small'} variant="outlined">
                                                    <InputLabel htmlFor="outlined-layout">{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR)}</InputLabel>
                                                    <Select
                                                        id={"outlined-layout"}
                                                        datatest={'separator'}
                                                        value={delimiter}
                                                        label={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR)}
                                                        onChange={(event) => {
                                                            this.setState({delimiter :  event.target.value})
                                                        }}
                                                        inputProps={{
                                                            id: 'outlined-layout'
                                                        }}
                                                    >
                                                        <MenuItem value={','}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA)}</MenuItem>
                                                        <MenuItem value={'\t'}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB)}</MenuItem>
                                                        <MenuItem value={UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM)}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                , {height : '100%'}
                                            )
                                        }

                                    </Grid>
                                    {
                                        delimiter === UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM &&
                                        <Grid item xs={12}>
                                            <TextField
                                                datatest={'customSeparator'}
                                                label={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE)}
                                                value={customDelimiter || ''}
                                                onChange={(ev) => {
                                                    let value = ev.target.value;
                                                    this.setState({customDelimiter: value});
                                                }}
                                            />
                                        </Grid>
                                    }
                                </>
                            }
                        </Grid>
                        <div style={{height : '40px'}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow :'1'}}/>
                        {
                            <Button
                                disabled={loading}
                                datatest={'downloadFileButton'}
                                variant={"contained"}
                                color="secondary"
                                onClick={this.apply}
                            >{loading && <CircularProgress size={24} className={classes.buttonProgress} />}{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD, browseLanguage, UI_LABELS_DOWNLOAD)}</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>;
    }
}

DownloadDialogInner.propTypes = {
    settings: PropTypes.any,
    browseLanguage: PropTypes.any,
    onClose: PropTypes.any,
    onDownload: PropTypes.any
};

const DownloadDialog = withStyles(styles, {withTheme: true})(DownloadDialogInner);

function DataGridWithFilters({ settings, browseLanguage, bindings, sparqlVarsToTitle, ref, onRefresh, typeMap}) {
    const theme = useTheme();
    let [filters, setFilters] = useState({
        enabled : true,
        data : {}
    });
    let [resetFilter, setResetFilters] = useState(uuid4())
    let [openDownloadDialog, setOpenDownloadDialog] = useState(false)
    let [fullScreen, setFullScreen] = useState(false)
    let [sortBy, setSortBy] = useState([])

    let rows = toArray(bindings).filter(b => {
        const keys = Object.keys(filters.data);
        const match = keys.filter(k => {
            let filterValue  = filters.data[k]?.value;
            if(!filterValue) {
                return k;
            }
            const objValue = b[k]?.value;
            const includes = objValue
                ? objValue.toLowerCase().includes(filterValue.toLowerCase())
                : false;
            return includes;
        });
        return match.length === keys.length;
    });

    toArray(sortBy).forEach(sb => {
        let datatype= typeMap[sb.variableName];
        const direction = sb.direction === SORT_DESCENDING ? 'desc' : 'asc';
        if ([XSD_DATE, XSD_DATETIME].includes(datatype)) {
            rows = sortByFunction(rows, (row) => {
                return new Date(row[sb.variableName]?.value);
            }, direction );
        } else if ([XSD_DECIMAL, XSD_DOUBLE, XSD_FLOAT, XSD_INTEGER, XSD_NEGATIVE_INTEGER, XSD_NON_NEGATIVE_INTEGER, XSD_NON_POSITIVE_INTEGER, XSD_POSITIVE_INTEGER, XSD_INT, XSD_SHORT, XSD_LONG, XSD_UNSIGNED_INT, XSD_UNSIGNED_SHORT, XSD_UNSIGNED_LONG].includes(datatype)) {
            rows = sortByFunction(rows, (row) => {
                return new Number((row[sb.variableName]?.value));
            }, direction );
        } else {
            rows = sortByFunction(rows, (row) => {
                return row[sb.variableName]?.value;
            }, direction );
        }
    })

    const onSortBy = (column, direction) => {
        const columnKey = column.key;
        let found = sortBy.find(sb => sb.variableName === columnKey);
        if(!found) {
            found = {
                variableName : columnKey,
                direction : SORT_ASCENDING
            }
        } else {
            found.direction = found.direction === SORT_ASCENDING ? SORT_DESCENDING : SORT_ASCENDING;
        }
        setSortBy([...sortBy.filter(sb => {
            return sb.variableName !== columnKey;
        }), found])
    }

    let columns = useMemo( () => {
        return sparqlVarsToTitle.map((v) => {
            let {title, variableName} = v;
            let index = sortBy.findIndex(sb => sb.variableName === variableName);
            let sortValue = sortBy[index];
            if(sortValue) {
                sortValue.index = sortBy.length - index;
            }
            return {
                resizable: true,
                sortable : true,
                width : 300,
                key : variableName,
                name : title,
                headerCellClass : 'dataGridFilter',
                headerRenderer: (p) => (
                    <FilterRenderer settings={settings} browseLanguage={browseLanguage} sortValue={sortValue} onSortBy={(c) => {
                        onSortBy(c);
                    }} {...p}>
                        {({ filters, isCellSelected, ...rest }) => {
                            return <TextField
                                datatest={'search'}
                                fullWidth={true}
                                variant={'outlined'}
                                size={'small'}
                                label={'Search'}
                                value={filters.data[variableName]?.value}
                                inputProps={
                                    rest
                                }
                                onChange={(event) => {
                                    let value = event.target.value;
                                    filters.data[variableName] = {
                                        value
                                    }
                                    setFilters({
                                        ...filters
                                    })
                                }}
                                onKeyDown={inputStopPropagation}
                            ></TextField>;
                        }}
                    </FilterRenderer>
                ),
                formatter : (cellEvent) => {
                    let { row, column, isCellSelected } = cellEvent;
                    return row[column.key]?.value || '';
                },
                editor : (cellEvent) => {
                    let { row, column, isCellSelected } = cellEvent;

                    return <input
                        style={{
                            appearance: "none",
                            boxSizing: "border-box",
                            inlineSize: "100%",
                            blockSize: "100%",
                            paddingBlock: 0
                        }}
                        value={row[column.key].value}
                    />;
                }
            }
        });
    }, [sparqlVarsToTitle, resetFilter, onSortBy])


    const downloadData = (options) => {
        const columnLabels = sparqlVarsToTitle.map(svt => svt.title);
        let headerValuesData = encodeValues(columnLabels, options.delimiter);
        const rowsData = rows.map(r => {
            let rowData = sparqlVarsToTitle.map(svt => {
                return  r[svt.variableName].value;
            })
            return encodeValues(rowData, options.delimiter);
        });
        let allData = [headerValuesData, ...rowsData].join(newLine)
        fileDownload(allData, 'data_' + new Date().toISOString() + ".csv")
        setOpenDownloadDialog(false);
    }

    let content = <FilterContext.Provider value={filters}>
        {
            openDownloadDialog &&
            <DownloadDialog
                settings={settings}
                browseLanguage={browseLanguage}
                onClose={() => setOpenDownloadDialog(false)}
                onDownload={downloadData}
            />
        }
        <div datatest={'topBar'} style={{display :'flex', gap : '8px', margin : fullScreen ? '6px': '6px 0px 18px'}}>
            {
                centerVertically(
                    fullScreen ? <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_FULL_SCREEN_EXIT, browseLanguage, UI_LABELS_FULL_SCREEN_EXIT)}>
                            <IconButton
                                datatest={'closeFullScreenButton'}
                                size={'small'} onClick={() => {
                                setFullScreen(false)
                            }}
                            ><FullscreenExitOutlined/></IconButton>
                        </MuiTooltip>:
                    <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_FULL_SCREEN, browseLanguage, UI_LABELS_FULL_SCREEN)}>
                        <IconButton
                            datatest={'fullScreenButton'}
                            size={'small'} onClick={() => {
                                setFullScreen(true)
                            }}
                        ><OpenWithOutlined/></IconButton>
                    </MuiTooltip>, {}
                )
            }{
                centerVertically(
                    <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_RESET_FILTERS, browseLanguage, UI_LABELS_RESET_FILTERS)}>
                        <IconButton
                            datatest={'resetFiltersButton'}
                            size={'small'} onClick={() => {
                                setFilters({
                                    enabled : true,
                                    data : {}
                                })
                                setSortBy([]);
                                setResetFilters(uuid4());
                            }}
                        ><SettingsBackupRestoreOutlined/></IconButton>
                    </MuiTooltip>, {}
                )
            }
            {
                centerVertically(
                    <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_REFRESH, browseLanguage, UI_LABELS_REFRESH)}>
                        <IconButton
                            datatest={'refreshDataButton'}
                            style={{padding : '0px'}}
                            size={'small'}
                            onClick={onRefresh}
                        ><RefreshOutlined/></IconButton>
                    </MuiTooltip>, {}
                )
            }
            {
                centerVertically(
                    <MuiTooltip title={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD, browseLanguage, UI_LABELS_DOWNLOAD)}>
                        <IconButton
                            datatest={'downloadButton'}
                            style={{padding : '0px'}}
                            size={'small'}
                            onClick={() => {setOpenDownloadDialog(true)}}
                        ><GetAppOutlined/></IconButton>
                    </MuiTooltip>, {}
                )
            }

        </div>
        <StyledDataGrid
            style={{maxHeight : `calc(100% - ${fullScreen ? 80 : 92}px)`}}
            rowKeyGetter={(row) => row.id}
            components={{
                rowRenderer: RowRenderer,
                noRowsFallback: <EmptyRowsRenderer settings={settings} browseLanguage={browseLanguage}/>
            }}
            ref={ref}
            columns={columns}
            rows={rows}
            rowHeight={36}
            onRowsChange={() => {
            }}
            headerRowHeight={filters.enabled ? 92 : undefined}
            onScroll={(event) => {
            }}
        />
        <div style={{display : 'flex', minHeight : '36px', marginTop : '4px', padding : '0px 16px', backgroundColor : theme.palette.grey.levelE0}}>
            <div style={{flexGrow : '1'}}></div>
            {
                rows.length > 0 && centerVertically(<Typography datatest={'rowCount'} color={'primary'}>Loaded {rows.length - EXTRA_ROWS} Rows</Typography>, {marginRight : '8px', minWidth: '104px'})
            }
        </div>

    </FilterContext.Provider>;

    return fullScreen ?
        <Dialog
            fullWidth={true}
            fullScreen={true}
            open={true}
            datatest={'fullScreen'}
            style={{}}
        >
            <DialogContent style={{overflow : 'hidden', padding : '1px'}}>{content}</DialogContent>
        </Dialog>
        : content;
}

class DataVisualisationView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewLanguage : getBrowseLanguageCode(props.browseLanguage),
            yasqeKey : "",
            visualisationHeight : '400',
            filters : {
                data : {},
                enabled : true
            },
            sparqlVarsToTitle : [],
            embeddedInitLoading : true
        }
        this.gridRef = React.createRef();

    }

    componentDidMount() {
        let {embedded} = this.props;
        if(embedded) {

        } else {
            this.syncSavedVisualisations().catch(r => {});
        }

    }

    syncSavedVisualisations = async () => {
        let {workspace} = this.props;
        let savedVisualisations = await getSavedDataVisualisationsOptions(workspace);
        this.setState({
            savedVisualisations : savedVisualisations
        });
        return savedVisualisations;
    }

    saveVisualisation = (title, action) => {

        const {workspace} = this.props;
        const {getQuery, yasqe} = this.state;
        const uuid = uuid4();
        let newIdForWorkspace = getNewIdForWorkspace(uuid);
        let obj = {
            [ID]: newIdForWorkspace,
            [ALIAS_MANAGEMENT_NAME]: NAME_DATA_VISUALISATION,
            [ALIAS_MANAGEMENT_TITLE]: title
        }
        obj[AT_CONTEXT] = getManagementContextURL();
        obj[TYPE] = ALIAS_MANAGEMENT_TYPE_CONFIGURATION;
        obj[ALIAS_MANAGEMENT_FOR_APPLICATION] = EASYGRAPH_DATA_APPLICATION_EXPLORER_DATA_VISUALISATION;
        setLinkedToId(obj, workspace[ID]);
        let {visualisationHeight, xAxisTitle, yAxisTitle, stacked, sparqlVarsToTitle} = this.state;
        let data = {
            dataSource : {
                query : getQuery(),
                queryType : yasqe.getQueryType()
            },
            visualisation : {
                visualisationType : this.getVisualisationTypeValue(),
                visualisationHeight : visualisationHeight,
                xAxis : this.getXAxisValue(),
                xAxisTitle,
                yAxisTitle,
                stacked,
                nonAxisVariables : {},
                sparqlVarsToTitle

            }
        }
        this.getNonAxisVariables().forEach(v => {

            let chartTypeKey = this.getChartTypeKey(v);
            let chartTypeValue = this.state[chartTypeKey] || MIXED_CHART_TYPES[0];
            let labelKey = this.getLabelKey(v);
            let labelValue = this.state[labelKey];
            let fillColorKey = this.getFillColorKey(v);
            let fillColorKeyValue = this.state[fillColorKey];
            let strokeColorKey = this.getStrokeColorKey(v);
            let strokeColorValue = this.state[strokeColorKey];
            data.visualisation.nonAxisVariables[v] = {
                [chartTypeKey] : chartTypeValue,
                [labelKey] : labelValue,
                [fillColorKey] : fillColorKeyValue,
                [strokeColorKey] : strokeColorValue
            }
        });

        obj[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(data);
        if(action === ACTION_UPDATE) {
            return new Promise((resolve, reject) => {
                let {showSavedVisualisation} = this.state;
                this.getFullResource(showSavedVisualisation.value).then(resource => {
                    resource[AT_CONTEXT] = obj[AT_CONTEXT];
                    resource[ALIAS_MANAGEMENT_SETTINGS] = obj[ALIAS_MANAGEMENT_SETTINGS];
                    patchManagementGraph(JSON.stringify(resource)).then(d => {
                        if (isRequestSuccessful(d)) {
                            d.json().then(j => {
                                resolve(j);
                            })
                        } else {
                            reject(d);
                        }
                    }).catch(e => {
                        reject(e);
                    })
                });
            });
        } else {
            return new Promise((resolve, reject) => {
                postManagementGraph(JSON.stringify(obj)).then(d => {
                    if (isRequestSuccessful(d)) {
                        d.json().then(j => {
                            resolve(j);
                        })
                    } else {
                        reject(d);
                    }
                })
            });
        }
    }

    deleteVisualisation = (visualisationToDelete) => {
        let backingObject = visualisationToDelete.backingObject;
        deleteManagementGraph({
            [AT_CONTEXT] : getManagementContextURL(),
            [AT_GRAPH] : [
                backingObject
            ]
        }).then(r => {
            if(isRequestSuccessful(r)) {
                this.syncSavedVisualisations().then(r => {
                    this.setState({loading: false, showSavedVisualisation : undefined});
                    this.resetVisualisation();
                });
            }
        });
    }

    resetVisualisation = () => {
        let {setQuery} = this.state;
        setQuery(DEFAULT_QUERY);
        this.setState({
            data : undefined
        })
    }

    getFullResource = async (id) => {
        const workspaceSearchParams = {
            [ID] : id
        }
        let headerMap = {};
        const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, headerMap).catch(handleBackendError(this));
        if(!isRequestSuccessful(workspaceSearchResult)) {
            return Promise.reject(workspaceSearchResult);
        }
        let workspaceJSON = await workspaceSearchResult.json();
        let result = getSearchResult(workspaceJSON).find(obj => obj[ID] === id);
        return result;
    }

    loadVisualisation = async (id) => {
        let result = await this.getFullResource(id);
        if(!result) {
            this.setState({
                showSavedVisualisation : undefined
            })
            return ;
        }
        let visualisationSettings = JSON.parse(result[ALIAS_MANAGEMENT_SETTINGS]);
        let {setQuery} = this.state;
        setQuery(visualisationSettings.dataSource.query);
        let {visualisationType, visualisationHeight, xAxis, xAxisTitle, yAxisTitle, stacked, sparqlVarsToTitle} = visualisationSettings.visualisation;
        this.setState({
            visualisationType,
            visualisationHeight,
            xAxis,
            xAxisTitle,
            yAxisTitle,
            stacked,
            sparqlVarsToTitle
        });
        Object.keys(visualisationSettings.visualisation.nonAxisVariables).forEach(k => {
            let keyObject = visualisationSettings.visualisation.nonAxisVariables[k];
            this.setState({...keyObject});
        });
        this.runQuery();

        return result;
    };


    onYasqeSetup = (yasqe, afterStateCallback) => {
        const setQuery = function (query) {
            yasqe.setValue(query);
        };
        const getQuery = function () {
            return yasqe.getValue();
        };
        yasqe.on("beforeChange", (instance, changeObj) => {
        });
        yasqe.on("change", (instance) => {
        });
        yasqe.on("query", () => {
            yasqe.gsStart = Date.now();
            yasqe.options.readOnly = true;
            this.setState({ queryRunning: true, isDirty: false, runTime: 0 });
        });
        yasqe.on("queryResponse", async (instance, req) => {
            const runTime = yasqe.gsStart ? Date.now() - yasqe.gsStart : 0;
            yasqe.options.readOnly = false;
            // Add in cancellation check here
            if (req instanceof Error) {
                this.setState({ error: true, errorMessage : req.status + " : "+ req.message , queryRunning: false, data: null });
            } else {
                const data = yasqe.getQueryType() === "DESCRIBE" ?
                    await req.text
                    :
                    JSON.parse(await req.text);
                let sparqlVarsToTitle;
                if (this.isDataGridView()) {
                    sparqlVarsToTitle = this.initSparqlVarsToTitle(data);
                } else {
                    sparqlVarsToTitle = this.state.sparqlVarsToTitle;
                }

                this.setState({
                    error : undefined,
                    errorMessage : undefined,
                    dataUUID: uuid4(),
                    data,
                    sparqlVarsToTitle : [...(sparqlVarsToTitle || [])],
                    queryRunning: false,
                    wait: true,
                    runTime,
                    resultsQueryType: yasqe.getQueryType(),
                    embeddedInitLoading: false
                });

            }
        });
        this.setState({ yasqe : yasqe, yasqeQuery: yasqe.query, setQuery, getQuery, queryValid: true }, afterStateCallback);

    };

    initSparqlVarsToTitle = (data) => {
        let {sparqlVarsToTitle} = this.state;

        const uniq = new Set(flatten(toArray(data?.['results']?.['bindings']).map(r => Object.keys(r))));
        const sparqlVars = [...uniq].sort();
        sparqlVars.forEach(v => {
            let found = toArray(sparqlVarsToTitle).find(svt => svt.variableName === v);
            if (!found) {
                sparqlVarsToTitle.push({variableName: v, title: v});
            }
        })
        //Remove columns which are not in results
        //this might happen if the query is changed and rerun
        sparqlVarsToTitle = sparqlVarsToTitle.filter(svt => uniq.has(svt.variableName));

        return sparqlVarsToTitle;
    }

    isSelectQuery = () => {
        let {yasqe} = this.state;
        let queryType = yasqe.getQueryType();
        return queryType === 'SELECT';
    }

    runQuery = () => {
        try {
            let yasqeQuery = this.state.yasqeQuery();
        }
        catch (e) {
            console.log("Error :", e)
            this.setState({
                error: true,
                errorMessage : "Could not run query."
            });
        }
    };


    renderDataSourcePanel = () => {
        let {yasqeKey} = this.state;
        let {settings, browseLanguage} = this.props;
        return <>
            <Accordion datatest={'data-source-panel'} defaultExpanded={true}>
                <AccordionSummary

                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="data-source-panel-content"
                    id="data-source-panel"
                >
                    <H2Title color={'primary'} title={'Data Source'}></H2Title>
                </AccordionSummary>
                <AccordionDetails>
                    <div style={{flexGrow : 1}}>
                        <div style={{ maxHeight : 'calc(100% - 36px)'}}>
                            <YasqeWrapper
                                classList={[]}
                                propertyList={[]}
                                key={yasqeKey}
                                initialQuery={DEFAULT_QUERY}
                                onSetup={this.onYasqeSetup} />
                        </div>
                        <div>
                            <Button
                                datatest={'runQueryButton'}
                                startIcon={<PlayArrowOutlined></PlayArrowOutlined>}
                                variant={'contained'}
                                color={'secondary'}
                                onClick={this.runQuery}
                            >{getUiLabelTranslation(settings, UI_LABELS_SPARQL_RUN, browseLanguage, UI_LABELS_SPARQL_RUN)}</Button>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>;
    }

    renderDataPanel = () => {
        let {data, dataUUID} = this.state;
        return <Accordion datatest={'data-panel'} defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="data-panel-content"
                id="data-panel"
            >
                <H2Title color={'primary'} title={'Data Preview'}></H2Title>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{flexGrow : 1, maxWidth : '100%'}}>
                <YasrWrapper key={dataUUID} data={data}></YasrWrapper>
                </div>
            </AccordionDetails>
        </Accordion>;
    }

    transformDataFor = () => {
        let {data} = this.state;
        let bindings = data?.["results"]?.["bindings"];
        let xAxisValue = this.getXAxisValue();
        let yAxisValue = this.getYAxisValue();
        return toArray(bindings).map(b => {
            let toReturn = {};
            Object.keys(b).forEach(k => {
                if(k!==xAxisValue) {
                    toReturn[k] = Number(b[k]['value']);
                } else {
                    toReturn[k] = b[k]['value'];
                }
            })
            return toReturn;
        });
    }

    renderLegend = (props) => {
        const { payload } = props;

        return (
            <div style={{display : 'flex', gap : '16px', alignItems : 'center', justifyContent : 'center'}}>
                {
                    payload.map((entry, index) => {
                        let o = entry.dataKey;
                        let fillColorKey = this.getFillColorKey(o);
                        let fillColorValue = this.state[fillColorKey];
                        let labelKey = this.getLabelKey(o);
                        let labelValue = this.state[labelKey] || o;
                        return <div style={{color: fillColorValue}} key={`item-${index}`}>{labelValue}</div>;
                    })
                }
            </div>
        );
    }

    getVisualisationTypeValue = () => {
        let {visualisationType} = this.state;
        return visualisationType || VISUALISATION_TYPES[0];
    }

    renderVisualisationTypeSelection = () => {
        let {visualisationHeight} = this.state;

        return <div style={{display : 'flex', gap : '24px', padding : '16px'}}>
            <FormControl size={'small'} variant="outlined" margin={'dense'} style={{minWidth : '200px'}} >
                <InputLabel htmlFor="visualisation-type-select-label">Visualisation Type</InputLabel>
                <Select
                    datatest={'visualisationType'}
                    label={'Visualisation Type'}
                    labelId="visualisation-type-select-label"
                    inputProps={{
                        name: 'Visualisation Type',
                        id: 'visualisation-type-select-label',
                    }}
                    value={this.getVisualisationTypeValue()}
                    name={'visualisationType'}
                    onChange={(ev) => {
                        let {value} = ev.target;
                        let {data} = this.state;
                        if(value === DATA_GRID && data) {
                            let sparqlVarsToTitle = this.initSparqlVarsToTitle(data);
                            this.setState({sparqlVarsToTitle});
                        }
                        this.setState({visualisationType: value, visualisationUUID : uuid4()});
                    }}
                >{
                    VISUALISATION_TYPES.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                }</Select>
            </FormControl>
            <TextField
                datatest={'containerHeight'}
                label={'Container Height'}
                value={visualisationHeight}
                onChange={(ev) => {
                    let {value} = ev.target;
                    this.setState({visualisationHeight : value, visualisationUUID : uuid4()});
                }}
            ></TextField>
        </div>;

    }

    isRadarChart = () => {
        let visualisationTypeValue = this.getVisualisationTypeValue();
        return visualisationTypeValue === RADAR_CHART;
    }

    isDataGridView = () => {
        let visualisationTypeValue = this.getVisualisationTypeValue();
        return visualisationTypeValue === DATA_GRID;
    }

    getNonAxisVariables = () => {
        let {xAxis} = this.state;
        let vars = this.getSPARQLVariables();
        let xAxisValue = this.getXAxisValue(xAxis, vars);
        return vars.filter(o => o != xAxisValue);
    }

    renderDataGridViewSettings = () => {
        let {sparqlVarsToTitle} = this.state;
        return <>
            <H4Title color={'primary'} title={'Column Order And Title Setup'}></H4Title>
        <div style={{display :'flex', gap : '16px', overflow : 'auto', padding : '16px'}}>

            {
                sparqlVarsToTitle.map((v, index) => {
                let {title, variableName} = v;
                return <React.Fragment key={variableName+"-"+index}>
                    <TextField
                        style={{minWidth : '180px'}}
                        datatest={variableName+"-label"}
                        label={variableName}
                        value={title}
                        onChange={(ev) => {
                            let {value} = ev.target;
                            v.title = value;
                            this.setState({sparqlVarsToTitle : [...sparqlVarsToTitle], visualisationUUID : uuid4()});
                        }}
                    />
                    {
                        index < sparqlVarsToTitle.length - 1 && centerVertically(
                            <IconButton
                                datatest={'swap-'+index}
                                size={'small'}
                                onClick={() => {
                                    let current = sparqlVarsToTitle[index];
                                    let next = sparqlVarsToTitle[index + 1];
                                    sparqlVarsToTitle[index + 1] = current;
                                    sparqlVarsToTitle[index] = next;
                                    this.setState({sparqlVarsToTitle : [...sparqlVarsToTitle], visualisationUUID : uuid4()})
                                }}
                            >
                                <SwapHorizOutlined/>
                            </IconButton>
                        )
                    }
                </React.Fragment>
            })
        }</div>
        </>;
    }

    renderVisualisationSettings = () => {
        let {theme} = this.props;
        let {xAxis, xAxisTitle, yAxisTitle, stacked} = this.state;
        if(this.isDataGridView()) {
            return this.renderDataGridViewSettings();
        }
        let vars = this.getSPARQLVariables();
        let xAxisValue = this.getXAxisValue(xAxis, vars);
        let nonAxisVariables = this.getNonAxisVariables();

        let countLabel = this.isRadarChart() ? 'Radius Axis' : 'X Axis';
        return <>
            <div key={this.getVisualisationTypeValue()} style={{display : 'flex', gap : '24px', padding : '16px'}}>
                <FormControl size={'small'} variant="outlined" margin={'dense'} style={{minWidth : '200px'}} >
                    <InputLabel htmlFor="xAxis-type-select-label">{countLabel}</InputLabel>
                    <Select
                        datatest={countLabel}
                        label={ countLabel}
                        labelId="xAxis-type-select-label"
                        value={xAxisValue}
                        name={'yAxis'}
                        onChange={(ev) => {
                            let {value} = ev.target;
                            this.setState({xAxis: value});
                        }}
                    >{
                        vars.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                    }</Select>
                </FormControl>
                {
                    this.isRadarChart() ? <></> : <>
                        <div>
                            <TextField
                                datatest={'xAxisTitle'}
                                label={'X Axis Title'}
                                value={xAxisTitle === undefined ? '' : xAxisTitle}
                                onChange={(ev) => {
                                    let {value} = ev.target;
                                    this.setState({xAxisTitle : value, visualisationUUID : uuid4()});
                                }}

                            />
                        </div>
                        <div>
                            <TextField
                                datatest={'yAxisTitle'}
                                label={'Y Axis Title'}
                                value={yAxisTitle === undefined ? '' : yAxisTitle}
                                onChange={(ev) => {
                                    let {value} = ev.target;
                                    this.setState({yAxisTitle : value, visualisationUUID : uuid4()});
                                }}

                            />
                        </div>
                        <div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        datatest={'stacked'}
                                        name={"stacked"}
                                        checked={stacked || false}
                                        value={stacked}
                                        onChange={(event) => {
                                            this.setState({[event.target.name]: event.target.checked });
                                        }}
                                    ></Switch>
                                }
                                label={<Typography>Stacked</Typography>}
                            />
                        </div>
                    </>
                }
            </div>
            {
                nonAxisVariables.map((o, oIndex) => {
                    let chartTypeKey = this.getChartTypeKey(o);
                    let chartTypeValue = this.state[chartTypeKey] || MIXED_CHART_TYPES[0];
                    let labelKey = this.getLabelKey(o);
                    let labelValue = this.state[labelKey];
                    let fillColorKey = this.getFillColorKey(o);
                    let fillColorKeyValue = this.state[fillColorKey];
                    let strokeColorKey = this.getStrokeColorKey(o);
                    let strokeColorValue = this.state[strokeColorKey];
                    return <div key={labelKey+oIndex}  style={{display : 'flex', gap : '24px', padding : '16px'}}>
                        <div><TextField label={'Query Variable'} value={o}/></div>
                        <div>
                            <TextField
                                datatest={labelKey+"-label"}
                                label={'Label'}
                                value={labelValue === undefined ? o : labelValue}
                                onChange={(ev) => {
                                    let {value} = ev.target;
                                    this.setState({[labelKey] : value, visualisationUUID : uuid4()});
                                }}

                            />
                        </div>
                        {   this.isRadarChart() ? <></> :
                            <div>
                                <FormControl margin={'dense'} size={'small'} variant="outlined"
                                             style={{minWidth: '124px'}}>
                                    <InputLabel htmlFor="chart-type-select-label">Chart Type</InputLabel>
                                    <Select
                                        datatest={labelKey+"-chartType"}
                                        label={'Chart Type'}
                                        labelId="chart-type-select-label"
                                        value={chartTypeValue}
                                        name={'yAxis'}
                                        onChange={(ev) => {
                                            let {value} = ev.target;

                                            this.setState({[chartTypeKey]: value, visualisationUUID: uuid4()});
                                        }}
                                        inputProps={{
                                            name: 'View',
                                            id: 'chart-type-select-label',
                                        }}
                                    >
                                        {
                                            MIXED_CHART_TYPES.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        }
                        <ColorPicker
                            minimal={true}
                            theme={theme}
                            settings={{[labelKey+'-fillColor'] : {titleColor : fillColorKeyValue}}}
                            onChange={(value) => {
                                this.setState({[fillColorKey] : value})
                            }}
                            objectKey={labelKey+'-fillColor'}
                            valueKey={'titleColor'}
                            label={'Fill Color'}
                            placeholderValue={'#FFFFFF'}
                        />
                        <ColorPicker
                            minimal={true}
                            theme={theme}
                            settings={{[labelKey+'-strokeColor'] : {titleColor : strokeColorValue}}}
                            onChange={(value) => {
                                this.setState({[strokeColorKey] : value})
                            }}
                            objectKey={labelKey+'-strokeColor'}
                            valueKey={'titleColor'}
                            label={'Stroke Color'}
                            placeholderValue={'#FFFFFF'}
                        />
                    </div>;
                })
            }
        </>;
    }

    getSPARQLVariables = () => {
        let {data} = this.state;
        const uniq = new Set(flatten(toArray(data?.['results']?.['bindings']).map(r => Object.keys(r))));
        return [...uniq];
    }

    renderDataGrid = () => {
        let {browseLanguage, settings} = this.props;
        let {data, sparqlVarsToTitle} = this.state;
        let bindings = data?.["results"]?.["bindings"];
        let typeMap = {};
        toArray(bindings).forEach(br => {
            Object.keys(br).filter(varName => typeMap[varName] !== 'string').forEach(varName => {
                let currentType = typeMap[varName];
                let newType = br[varName].datatype;
                if(!newType || ( currentType !== undefined && currentType !== newType)) {
                    typeMap[varName] = 'string';
                } else {
                    typeMap[varName] = newType;
                }
            })
        })
        return <DataGridWithFilters
            settings={settings}
            browseLanguage={browseLanguage}
            sparqlVarsToTitle={sparqlVarsToTitle}
            bindings={bindings}
            ref={this.gridRef}
            sparqlVariablesTitle={this.state}
            onRefresh={this.runQuery}
            typeMap={typeMap}
        />;
    }

    renderVisualisation = () => {
        let {xAxis, xAxisTitle, yAxisTitle, stacked, savedVisualisations, showSavedVisualisation} = this.state;
        let {embedded} = this.props;
        let vars = this.getSPARQLVariables();
        if(this.isDataGridView()) {
            return this.renderDataGrid();
        }
        let xAxisValue = this.getXAxisValue(xAxis, vars);
        let nonAxisVariables = this.getNonAxisVariables();
        let transformedData = this.transformDataFor();
        let maxArray = nonAxisVariables.map(v => {
            let numbers = transformedData.map(d => d[v]).filter(Number);
            return Math.max(...numbers);
        });
        let max = Math.max(...flatten(maxArray));

        let minArray = nonAxisVariables.map(v => {
            let numbers = transformedData.map(d => d[v]).filter(Number);
            return Math.min(...numbers);
        });
        let min = Math.min(...flatten(minArray));

        return <ResponsiveContainer datatest={'responsiveContainer-'+embedded} width="100%" height="100%">
            {
                this.isRadarChart()
                    ? <RadarChart data={transformedData}>
                        <PolarGrid />
                        <PolarAngleAxis dataKey={xAxisValue} />
                        <PolarRadiusAxis/>
                        {
                            nonAxisVariables.map((v, vIndex) => {
                                let fillColorKey = this.getFillColorKey(v);
                                let fillColorKeyValue = this.state[fillColorKey];
                                let strokeColorKey = this.getStrokeColorKey(v);
                                let strokeColorValue = this.state[strokeColorKey];
                                let labelKey = this.getLabelKey(v);
                                let labelValue = this.state[labelKey] || v;

                                return <Radar key={labelKey+vIndex} name={labelValue} dataKey={v} stroke={strokeColorValue} fill={fillColorKeyValue} fillOpacity={0.6} />;
                            })
                        }
                        <Tooltip></Tooltip>
                        <Legend content={this.renderLegend} verticalAlign="top" height={36}/>
                    </RadarChart>
                    : <ComposedChart
                            data={transformedData}
                            margin={{
                                top: 20,
                                right: 20,
                                bottom: 20,
                                left: 20,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis label={xAxisTitle ? {value: xAxisTitle, position: 'bottom'} : {}} dataKey={xAxisValue}/>
                            <YAxis label={yAxisTitle ? {value: yAxisTitle, angle: -90, position: 'insideLeft'} : {}}/>
                            <Tooltip></Tooltip>
                            <Legend content={this.renderLegend} verticalAlign="top" height={36}/>
                            {
                                nonAxisVariables.map((v, vIndex) => {
                                    let chartTypeKey = this.getChartTypeKey(v);
                                    let chartTypeValue = this.state[chartTypeKey] || MIXED_CHART_TYPES[0];
                                    let fillColorKey = this.getFillColorKey(v);
                                    let fillColorKeyValue = this.state[fillColorKey];
                                    let strokeColorKey = this.getStrokeColorKey(v);
                                    let strokeColorValue = this.state[strokeColorKey];
                                    let keyId = chartTypeKey + vIndex;

                                    if (chartTypeValue === 'Bar') {
                                        return <Bar key={keyId} stackId={stacked && "bar"} dataKey={v} stroke={strokeColorValue}
                                                    fill={fillColorKeyValue}/>;
                                    } else if (chartTypeValue === 'Line') {
                                        return <Line key={keyId} stackId={stacked && "line"} dataKey={v} stroke={strokeColorValue}
                                                     fill={fillColorKeyValue}/>;
                                    } else if (chartTypeValue === 'Scatter') {
                                        return <Scatter key={keyId} stackId={stacked && "scatter"} dataKey={v} stroke={strokeColorValue}
                                                        fill={fillColorKeyValue}/>;

                                    } else if (chartTypeValue === 'Area') {
                                        return <Area key={keyId} stackId={stacked && "area"} dataKey={v} stroke={strokeColorValue}
                                                     fill={fillColorKeyValue}/>;

                                    }
                                    return;
                                })
                            }
                        </ComposedChart>
                }
            </ResponsiveContainer>;
    }

    renderVisualisationPanel = () => {
        let {visualisationHeight, visualisationUUID, dataUUID} = this.state;
        return <Accordion key={dataUUID} datatest={'visualisation-panel'} defaultExpanded={true}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="visualisation-panel-content"
                id="visualisation-panel"
            >
                <H2Title color={'primary'} title={'Visualisation Preview'}></H2Title>
            </AccordionSummary>
            <AccordionDetails>
                <div style={{flexGrow : 1, maxWidth : '100%'}}>
                    <div>
                        <Accordion datatest={'visualisation-settings-panel'} defaultExpanded={false}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="visualisation-settings-panel-content"
                                id="visualisation-settings-panel"
                            >
                                <H3Title color={'primary'} title={'Settings'}></H3Title>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{maxWidth :'100%'}}>
                                    {this.renderVisualisationTypeSelection()}
                                    {this.renderVisualisationSettings()}
                                </div>
                            </AccordionDetails>
                        </Accordion>
                        {
                            <div datatest={'visualisationDiv'} key={visualisationUUID} style={{height : visualisationHeight+'px'}}>
                                {this.renderVisualisation()}
                            </div>
                        }
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>;
    }

    getStrokeColorKey(o) {
        return o + "-strokeColor";
    }

    getFillColorKey(o) {
        return o + "-fillColor";
    }

    getVisualisationTypeKey(o) {
        return o + "-visualisationType";
    }

    getChartTypeKey(o) {
        return o + "-chartType";
    }

    getLabelKey(o) {
        return o + "-labelKey";
    }

    getXAxisValue = () => {
        let {xAxis} = this.state;
        let vars = this.getSPARQLVariables();
        return xAxis || ( vars.length > 0 && vars[0]);
    }

    getYAxisValue = () => {
        let {yAxis, data} = this.state;
        let vars = this.getSPARQLVariables();
        return yAxis || (vars.length > 0 && vars[0]);
    }

    renderDialog = () => {
        let {data, showSavedVisualisation, showSaveDialog, savedVisualisations, error, errorMessage} = this.state;
        let {classes, embedded, settings, theme, location} = this.props;
        let savedOptions = [];
        let superadmin = isSuperadmin();
        return <div
            style={{minHeight : '100%', padding : '16px 0px', backgroundColor : theme.palette.grey.background}}
        >
            { embedded ? <></> : <div style={{display : 'flex', gap : '16px'}}>
                {
                    centerVertically(renderLogo(classes, location, settings, theme, {margin: '0px 8px' }, 'sheets'), { })
                }
                <Autocomplete
                    style={{minWidth : '200px'}}
                    PopperComponent={(props) => {
                        return (<Popper {...props} style={{ width: 360 }} placement='bottom-start' />);
                    }}
                    datatest={'autocompleteMultiValueSelect'}
                    id="textSearchPropertiesValueSelect"
                    value={showSavedVisualisation || null}
                    options={savedVisualisations}
                    getOptionLabel={option => option.label ?  option.label : ''}
                    getOptionSelected={(option, value) => {
                        return option.value === value.value;
                    }}
                    multiple={false}
                    onChange={(event, newValue, reason) => {
                        this.setState({showSavedVisualisation: newValue}, () => this.loadVisualisation(newValue.value));
                    }}
                    renderInput={params => (
                        <OtherTextField
                            label={'Saved'}
                            {...params}
                            variant="outlined"
                            fullWidth
                        />
                    )}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => {
                            return getChipWithDelete(theme, index, option.label, option.tooltip, getTagProps({index}));
                        })
                    }}
                    renderOption={(option, { selected }) => (
                        <div datatest={option.label} style={{paddingLeft : '0px'}}>{option.label}</div>
                    )}
                    size={"small"}
                    disableClearable={true}
                />
                {
                    showSavedVisualisation && superadmin && centerVertically(<MuiTooltip title={'Delete Visualisation'}>
                        <IconButton datatest={'DeleteOutlined'} color={'primary'}
                                    style={{marginRight: '8px'}} size={'small'} onClick={() => {
                            this.deleteVisualisation(showSavedVisualisation);
                        }}>
                            <DeleteOutlined color={'secondary'}></DeleteOutlined>
                        </IconButton>
                    </MuiTooltip>)

                }
                {
                    centerVertically(<MuiTooltip title={'Save'}>
                        <IconButton datatest={'PhotoCameraOutlined'} color={'primary'}
                                    style={{marginRight: '8px'}} size={'small'} onClick={() => {
                            this.setState({showSaveDialog: true})
                        }}>
                            <SaveOutlined></SaveOutlined>
                        </IconButton>
                    </MuiTooltip>)

                }
                {
                    showSaveDialog && <SaveDialogWithTitle
                        title={showSavedVisualisation?.label}
                        onSave={(saveVisualisationTitle, action) => {
                            this.saveVisualisation(saveVisualisationTitle, action).then(r => {
                                this.syncSavedVisualisations().then(r => this.setState({showSaveDialog : false}));
                            })
                        }}
                        onCancel={() => {
                            this.setState({showSaveDialog : false});
                        }}
                    />
                }
            </div>}
            <div style={{margin : '8px 16px', display : 'flex', flexDirection : 'column', gap : '16px'}}>
                <div>
                    {this.renderDataSourcePanel()}
                </div>
                {error && <ErrorMessage error={errorMessage}/>}
                <div>
                    {data && this.renderDataPanel()}
                </div>
                <div>
                    {data && this.isSelectQuery() && this.renderVisualisationPanel()}
                </div>
            </div>
        </div>;
    }

    render() {
        let {embedded} = this.props;
        let {embeddedInitLoading} = this.state;
        return <>
            { embedded ? <>
                <div datatest={'yasqe-embedded'} style={{display : 'none'}}>
                <YasqeWrapper
                    classList={[]}
                    propertyList={[]}
                    key={""}
                    initialQuery={DEFAULT_QUERY}
                    onSetup={(v) => {
                        this.onYasqeSetup(v, () => {
                            let {visualisation} = this.props;
                            this.loadVisualisation(visualisation.backingObject[ID]).then(() => {});
                        });
                    }} />
                </div>
                {embeddedInitLoading ? <LinearProgress color={'secondary'}/> : this.renderVisualisation()}
            </>  : this.renderDialog()}
        </>;
    }
}

DataVisualisationView.propTypes = {
    permissionService: PropTypes.instanceOf(PermissionService),
    publishEvent : PropTypes.func,
    registerForEvents : PropTypes.func,
    embedded : PropTypes.bool,
    fullScreen: PropTypes.bool,
    hideButton: PropTypes.bool,
    minimized: PropTypes.any,
    editMode: PropTypes.any,
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    spreadsheet: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    workspace: PropTypes.object,
    settings: PropTypes.object,
    visualisation: PropTypes.any,
    onExpand: PropTypes.func,
    onClose: PropTypes.func,
    eventRegister: PropTypes.func,


};

export default withStyles(styles, {withTheme: true})(withPermissions(withEvent(DataVisualisationView)));

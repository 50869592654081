import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "./styles";
import {
    getBatchEndpoint,
    getEndpointForGraphiQL,
    getGraphEndpoint,
    getResetDataEndpoint,
    graphQLPost,
    graphSearchByMaps,
    postBatch,
    postGraph,
    resetData,
    updateGraph
} from "../service/graph-api";
import SendIcon from "@material-ui/icons/SendOutlined";
import Request from "../components/Request";
import Response from "../components/Response";
import {
    ALIAS_SYS_EXAMPLE_TYPE_BATCH,
    ALIAS_SYS_EXAMPLE_TYPE_CREATE,
    ALIAS_SYS_EXAMPLE_TYPE_DELETE,
    ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    ALIAS_SYS_EXAMPLE_TYPE_UPDATE,
    ALIAS_SYS_VARIABLE_TYPE_HEADER,
    API_MODE,
    API_MODE_GRAPHQL,
    API_MODE_HTTP_API,
    EXAMPLE_TYPE_TO_COLOR,
    EXAMPLE_TYPE_TO_TITLE,
    HTTP_HEADER_ACCEPT,
    HTTP_HEADER_CONTENT_TYPE,
    HTTP_METHOD_DELETE,
    HTTP_METHOD_GET,
    HTTP_METHOD_PATCH,
    HTTP_METHOD_POST,
    ID,
    MEDIA_TYPE_JSON_LD,
    STYLE_GRID_ITEM_SPACING,
    TYPE
} from "../Constants";
import qs from 'qs'
import GraphQLEditor from "../components/CodeMirror/GraphQLEditor";
import {
    byteSize,
    centerVertically,
    getFetchTimeMillis,
    getHeaderMap,
    getTabLabel, getUiLabelTranslationFromContext,
    prettifyGraphQLQuery,
    replaceVariables,
    replaceVariablesByString,
    stringifyPatchReqBody,
} from "./util";
import {Grid, TextField as MUITextField} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../components/TabPanel";
import uuid4 from 'uuid/v4'
import {GraphiQL} from "graphiql/esm/components/GraphiQL";
import FieldContainer from "../components/FieldContainer";
import ResponseStatusBar from "../components/ResponseStatusBar";
import './CodeMirror/GraphQLEditor.css';
import Save from '@material-ui/icons/Save';
import BuildIcon from '@material-ui/icons/Build';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import SyncIcon from '@material-ui/icons/Sync';
import Autocomplete from "@material-ui/lab/Autocomplete";
import VariablesBuilder, {evaluatePath} from "../components/VariablesBuilder";
import GlobalsContext from "../components/GlobalsContext";
import CollectionDetails from "../layouts/apiplayground/CollectionDetails";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecordTwoTone";
import H2Title from "../components/H2Title";
import {traceRequestRenderStart} from "./Trace";
import HeaderVariablesBuilder, {getEtagHeaderValue} from "../components/HeaderVariablesBuilder";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Label from "../components/Label";
import H4Title from "../components/H4Title";
import {GreyLockIcon} from "./GreyStyleIcon";
import {
    UI_LABELS_BUILD, UI_LABELS_CANCEL, UI_LABELS_IF_NONE_MATCH, UI_LABELS_MODE,
    UI_LABELS_REQUEST,
    UI_LABELS_REQUEST_BODY, UI_LABELS_REQUEST_SETTINGS,
    UI_LABELS_RESPONSE, UI_LABELS_SAVE
} from "../layouts/navigator/UILabel";
import GraphQLResponseViewer from "./CodeMirror/GraphQLResponseViewer";

export function getDirtyIndicator(requestObject) {
    return requestObject && requestObject.dirty && centerVertically(<FiberManualRecordIcon datatest={'dirty'} color={'secondary'} style={{fontSize: '1em'}}/>);
}

function getRequestTitle(requestObject) {
    return <div style={{display:'flex'}}>
        <H2Title style={{color: EXAMPLE_TYPE_TO_COLOR[requestObject[TYPE]]}} title={EXAMPLE_TYPE_TO_TITLE[requestObject[TYPE]]}/>
    </div>;
}

class RequestDescription extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        let {requestObject} = props
        this.state = {
            apiMode : requestObject.mode
                ? API_MODE.find(m => m.value === requestObject.mode)
                : API_MODE_HTTP_API,

        }
    }

    handleSave = () => {
        let {requestObject, onSave} = this.props
        let {apiMode} = this.state
        if(requestObject.mode !== apiMode.value) {
            requestObject.mode = apiMode.value
        }
        onSave(requestObject)
    }

    render() {
        let {open, requestObject, onCancel} = this.props
        let {apiMode} = this.state
        let {disableApiMode} = requestObject
        return <>
            <Dialog
                maxWidth={"sm"}
                fullWidth={true}
                aria-labelledby="Request Settings"
                open={open}
            >
                <DialogTitle id="form-dialog-title"><H2Title title={getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST_SETTINGS)}/></DialogTitle>
                <DialogContent>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <FieldContainer>
                            <Label label={getUiLabelTranslationFromContext(this, UI_LABELS_MODE)}/>
                            <Autocomplete
                                datatest={'autocompleteMode'}
                                disabled={ disableApiMode === true}
                                disableClearable={true}
                                value={apiMode ? apiMode : API_MODE_HTTP_API}
                                options={API_MODE}
                                onChange={(event, val) => {
                                    this.setState({apiMode : val})
                                }}
                                getOptionLabel={option => option.label ?  option.label : ''}
                                renderInput={params => (
                                    <MUITextField disabled={disableApiMode && disableApiMode === true} {...params} margin={"dense"} variant="outlined" fullWidth />
                                )}
                            />
                            </FieldContainer>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        datatest={'cancelButton'}
                        onClick={onCancel}
                        variant={"outlined"}
                        color="secondary"
                    >{getUiLabelTranslationFromContext(this, UI_LABELS_CANCEL)}</Button>
                    <Button
                        disabled={disableApiMode === true}
                        datatest={'saveButton'}
                        variant={"contained"}
                        color="secondary"
                        onClick={this.handleSave}
                    >{getUiLabelTranslationFromContext(this, UI_LABELS_SAVE)}</Button>
                </DialogActions>
            </Dialog>
        </>;
    }


}

RequestDescription.propTypes = {
    open: PropTypes.bool,
    requestObject: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    onModeChange: PropTypes.func
};


class RequestResponse extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        const {reqTabValue, requestObject} = this.props;
        this.state = {
            tabValue: 0,
            resTabValue: 0,
            reqTabValue: reqTabValue ? reqTabValue : 0,
            reqBody : requestObject.body ? requestObject.body : undefined,
            graphQLOperationName: requestObject.operationName,
            graphQLQuery: prettifyGraphQLQuery(requestObject.query),
            variables: requestObject.variables,
            reqKey: uuid4(),
            name : requestObject.title,
            description : requestObject.description,
            apiMode : requestObject.mode
                ? API_MODE.find(m => m.value === requestObject.mode)
                : API_MODE_HTTP_API,
            dirty : requestObject.dirty,
            loading: false
        }
    }

    isGraphQL = () => {
        const {requestObject} = this.props;
        return  requestObject.mode === API_MODE_GRAPHQL.value ;
    }

    getAPIRequestComponent = () => {
        const {reqBody, response, resTabValue, reqTabValue, responseJSON} = this.state;
        const {classes, requestObject, registerSendAction, onRequestSave} = this.props;
        let verbToActionMap = this.getRequestVerbToActionMap()
        let {reqAction} = verbToActionMap
        if(registerSendAction) {
            let onSend = registerSendAction().onSend
            if(onSend) {
                onSend(requestObject[ID], reqAction)
            }
        }
        if(registerSendAction) {
            let onSave = registerSendAction().onSave
            if(onSave) {
                onSave(requestObject[ID], onRequestSave)
            }
        }

        return <>{this.displayRequestResponse(
                classes,
                reqBody,
                verbToActionMap.reqUrl,
                verbToActionMap.reqVerb,
                reqTabValue,
                (val) => this.setState({reqTabValue: val}),
                <SendIcon/>,
                'Send',
                reqAction,
                verbToActionMap.reqHeaderMap,
                response,
                resTabValue,
                (val) => this.setState({resTabValue: val}),
                responseJSON,
                () => {
                }
            )}</>;
    }

    getRequestVerbToActionMap = () => {
        const {requestObject} = this.props;
        let {type} = requestObject
        if (type === ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA) {
            return {
                reqVerb: HTTP_METHOD_DELETE,
                reqUrl: getResetDataEndpoint(),
                reqAction: this.resetData
            };
        } else if(this.isGraphQL()) {
            return {
                reqVerb: HTTP_METHOD_POST,
                reqUrl: getEndpointForGraphiQL(),
                reqAction: this.postGraphQL
            };
        } else {
            switch (type) {
                case ALIAS_SYS_EXAMPLE_TYPE_CREATE :
                    return {
                        reqVerb: HTTP_METHOD_POST,
                        reqUrl: getGraphEndpoint(),
                        reqAction: this.postData,
                        reqHeaderMap: {
                            [HTTP_HEADER_CONTENT_TYPE]: MEDIA_TYPE_JSON_LD,
                            [HTTP_HEADER_ACCEPT]: MEDIA_TYPE_JSON_LD
                        }
                    };
                case ALIAS_SYS_EXAMPLE_TYPE_UPDATE :
                    return {
                        reqVerb: HTTP_METHOD_PATCH,
                        reqUrl: getGraphEndpoint(),
                        reqAction: this.updateData,
                        reqHeaderMap: {
                            [HTTP_HEADER_CONTENT_TYPE]: MEDIA_TYPE_JSON_LD,
                            [HTTP_HEADER_ACCEPT]: MEDIA_TYPE_JSON_LD
                        }
                    };
                case ALIAS_SYS_EXAMPLE_TYPE_DELETE :
                    return {
                        reqVerb: HTTP_METHOD_DELETE,
                        reqUrl: getGraphEndpoint(),
                        reqAction: this.deleteData,
                        reqHeaderMap: {
                            [HTTP_HEADER_CONTENT_TYPE]: MEDIA_TYPE_JSON_LD,
                            [HTTP_HEADER_ACCEPT]: MEDIA_TYPE_JSON_LD
                        }

                    };
                case ALIAS_SYS_EXAMPLE_TYPE_SEARCH :
                    let {paramMap, headerMap} = requestObject
                    return {
                        reqVerb: HTTP_METHOD_GET,
                        reqUrl: `${getGraphEndpoint()}/search?${qs.stringify(paramMap, {
                            indices: false
                        })}`,
                        reqHeaderMap: {
                            [HTTP_HEADER_ACCEPT]: MEDIA_TYPE_JSON_LD,
                            ...headerMap
                        },
                        reqAction: this.search
                    };
                case ALIAS_SYS_EXAMPLE_TYPE_BATCH : {
                    let {headerMap} = requestObject
                    return {
                        reqVerb: HTTP_METHOD_POST,
                        reqUrl: getBatchEndpoint(),
                        reqAction: this.postBatchData,
                        reqHeaderMap: {
                            [HTTP_HEADER_CONTENT_TYPE]: MEDIA_TYPE_JSON_LD,
                            [HTTP_HEADER_ACCEPT]: MEDIA_TYPE_JSON_LD,
                            ...headerMap
                        }
                    };
                }
                default :
                    return undefined;
            }
        }

    }

    postGraphQL = () => {
        let {operationName, query, variables} = this.props.requestObject
        let payload = {}
        let replacedVariables = this.replaceVariables(variables)
        payload.variables = replacedVariables && JSON.parse(replacedVariables)
        payload.query = query
        payload.operationName = operationName
        return graphQLPost(payload).then(this.handleResponse);
    }

    resetData = () => {
        return resetData().then(this.handleResponse);
    }

    getReqBody = () => {
        const {requestObject} = this.props;
        let payload = requestObject.body
        let reqBodyRep = this.replaceVariables(payload);
        return reqBodyRep;
    }

    getRequestHeaders = () => {
        let {headerMap} = this.props.requestObject;
        let replacedHeaders = headerMap ? JSON.parse(this.replaceVariables(headerMap)) : {};
        return replacedHeaders;
    }

    getRequestParamsMap = () => {
        let {paramMap} = this.props.requestObject;
        let replacedParams = paramMap ? JSON.parse(this.replaceVariablesByString(paramMap)) : {};
        return replacedParams;
    }

    postData = () => {
        return postGraph(this.getReqBody()).then(this.handleResponse);
    }

    postBatchData = () => {
        let requestHeaders = this.getRequestHeaders();
        requestHeaders[HTTP_HEADER_CONTENT_TYPE] = MEDIA_TYPE_JSON_LD;
        requestHeaders[HTTP_HEADER_ACCEPT] = MEDIA_TYPE_JSON_LD;

        return postBatch(this.getReqBody(), requestHeaders).then(this.handleResponse);
    }

    replaceVariables = (content) => {
        let globals = this.context
        let replacedVariables = replaceVariables(content, globals)
        return replacedVariables.error ? content : replacedVariables.replaced
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        return getUiLabelTranslationFromContext(this, key, defaultValue);
    }


    replaceVariablesByString = (content) => {
        let globals = this.context;
        let replacedVariables = replaceVariablesByString(content, globals);
        return replacedVariables.error ? content : replacedVariables.replaced;
    }

    updateData = () => {
        return updateGraph(this.getReqBody()).then(this.handleResponse);
    }

    deleteData = () => {
        return updateGraph(this.getReqBody(), MEDIA_TYPE_JSON_LD, HTTP_METHOD_DELETE).then(this.handleResponse);
    }

    search = () => {
        let {paramMap} = this.props.requestObject;
        let replacedHeaders = this.getRequestHeaders();
        let replacedParams = this.getRequestParamsMap();
        return graphSearchByMaps(replacedParams, replacedHeaders).then(this.handleResponse);
    }

    handleResponse = (r) => {
        return r.text().then((text) => {
            try {
                const json = JSON.parse(text);
                return new Promise(resolve => resolve(this.invokeOnResponse(r, text, json)));
            } catch(err) {
                return new Promise(resolve => resolve(this.invokeOnResponse(r, text, undefined)));
            }
        })
    }

    evaluateVariables = (response, json) => {
        let {requestObject} = this.props
        const globals = this.context
        let headerMap = getHeaderMap(response);
        if(requestObject.onResponseVariables && requestObject.onResponseVariables.length > 0) {
            requestObject.onResponseVariables.forEach(v => {
                let value = v[TYPE] && v[TYPE] === ALIAS_SYS_VARIABLE_TYPE_HEADER
                    ? evaluatePath(headerMap, globals, v.name, v.path)
                    : evaluatePath(json, globals, v.name, v.path)
                globals.setItem(v.name, value);
            })
        }
    }

    hasError = (response, json) => {
        if(!response) {
            return ;
        }
        return this.isGraphQL() ? response.status >= 400 || (json && json.errors ? true :false) : response.status >= 400
    }

    invokeOnResponse = (response, text, json ) => {
        let {requestObject, registerSendAction, customizations} = this.props
        this.evaluateVariables(response, json)
        let  status = {
            id : requestObject[ID],
            hasError : this.hasError(response, json),
            status : response.status,
            timeInMillis : getFetchTimeMillis(response),
            size : byteSize(text),
            response: response,
            responseText : text
        }
        let tabValue = this.props.requestBodyBuilder ? 2 : (this.hideRequestTab() ? 0 : 1);
        this.setState({ loading: false, responseJSON: json, responseText: text, tabValue: tabValue, response: response})
        if(registerSendAction) {
            let onResponse = registerSendAction().onResponse
            if(onResponse) {
                onResponse(requestObject[ID], status)
            }
        }

        return status;
    }

    onPreviewReplacedBodyToggle = (checked) => {
        this.setState({previewReplacedBody: checked})
    }

    showGraphQLResponse = (response, responseJSONObject) => {
        let {theme, requestObject} = this.props
        let json = stringifyPatchReqBody(false, responseJSONObject)
        return <FieldContainer style={{padding : '8px',backgroundColor: theme.palette.white.main}}>
            <ResponseStatusBar hasError={this.hasError(response, responseJSONObject)}/>
            <FieldContainer style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>
                <H4Title title={'Result'}/>
                <GraphQLResponseViewer
                    response={json}
                ></GraphQLResponseViewer>
            </FieldContainer>
        </FieldContainer>;
    }


    onRequestObjectChange = () => {
        let {onRequestObjectChange, requestObject} = this.props
        if(onRequestObjectChange) {
            onRequestObjectChange(requestObject)
        }
    }

    hideRequestTab = () => {
        let {customizations} = this.props;
        let hideRequestTab = customizations && customizations.hideRequestTab === true;
        return hideRequestTab;
    }

    displayRequestResponse = (classes, reqBody, reqURL, reqVerb, reqTabValue, reqTabChange, reqIcon, reqIconTooltip, reqAction, reqHeaderMap, response, resTabValue, resTabChange, responseJSON, onResChangeText) => {
        const {theme, customizations, onRequestSave, onResponseItemSelectionChange, requestBodyBuilder, requestObject} = this.props;
        const {openSettingsDialog, loading, tabValue, reqKey, previewReplacedBody, graphQLRequestResponse, previewReplacedHeaders} = this.state
        let {disableApiMode} = requestObject

        let readOnly = customizations && customizations.readOnly;
        let embedded = customizations && customizations.embedded;
        let hideRequestTab = this.hideRequestTab();
        let tabIndex = 0
        const MenuTab = withStyles({
            root: {
                textTransform: 'none',
            },
            selected: {
                color : theme.palette.secondary.main,
                backgroundColor : theme.palette.white.main,
                borderRadius: '4px 4px'
            }
        })(Tab);

        let requestURLComponent = <MUITextField
            datatest={'textField-requestEndpoint'}
            variant={"outlined"}
            fullWidth={true}
            margin={"dense"}
            value={reqURL}
            InputProps={{
                startAdornment: <InputAdornment position="start">{reqVerb}</InputAdornment>,
                endAdornment: <GreyLockIcon datatest={'readOnly'} fontSize={"small"}/>
            }}
        />;

        return <div style={{ paddingRight : embedded === false ? '0px' : '0px' }}>
            {
                openSettingsDialog &&
                    <RequestDescription
                        open={openSettingsDialog}
                        requestObject={requestObject}
                        disableApiMode={disableApiMode}
                        onSave={(requestObject) => {
                            onRequestSave(requestObject);
                            this.setState({tabValue: 0})
                            this.setState({openSettingsDialog: false, response : undefined, responseJSON : undefined});
                        }}
                        onCancel={() => this.setState({openSettingsDialog: false})}
                    />
            }

            {
                customizations?.titleRenderer
                    ? customizations.titleRenderer(loading, undefined, this.getOnRun(customizations, reqAction), requestURLComponent, this.onSettingsOpen)
                    : <CollectionDetails
                        customizations={customizations}
                        collectionDetails={requestObject}
                        onSave={onRequestSave}
                        saveTitle={'Save'}
                        formTitle={embedded === false ? getRequestTitle(requestObject) : ''}
                        readOnly={readOnly}
                        onRun={this.getOnRun(customizations, reqAction)}
                        running={loading}
                        onChange={this.onRequestObjectChange}
                        hideActions={customizations && customizations.hideSave}
                        hideSettings={customizations && customizations.hideSettings}
                        renderAtBottom={requestURLComponent}
                        onSettings={this.onSettingsOpen}
                    />
            }
            { readOnly || <FieldContainer style={{marginTop : '8px', padding : (embedded === false ? '0px' : '8px'), backgroundColor: theme.palette.grey.background}}>
                <Tabs value={tabValue} onChange={(event, newValue) => {
                    this.setState({tabValue: newValue})
                }} aria-label="request tabs">
                    {requestBodyBuilder && <MenuTab datatest={'buildTab'} className={classes.tabItem} label={getTabLabel(<BuildIcon fontSize={'small'}/>,  getUiLabelTranslationFromContext(this, UI_LABELS_BUILD))}/>}
                    {hideRequestTab || <MenuTab datatest={'requestTab'} className={classes.tabItem} label={getTabLabel(<CompareArrowsIcon fontSize={'small'}/>, getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST))}/>}
                    <MenuTab datatest={'responseTab'} className={classes.tabItem} label={getTabLabel(<SyncIcon fontSize={'small'}/>, getUiLabelTranslationFromContext(this, UI_LABELS_RESPONSE))}/>
                    {customizations?.customTabsRenderer && customizations.customTabsRenderer().map((tabLabel) => <MenuTab key={tabLabel} datatest={'responseTab'} className={classes.tabItem} label={tabLabel}/>)}
                </Tabs>
                {requestBodyBuilder && <TabPanel value={tabValue} index={tabIndex++}>
                        {requestBodyBuilder()}
                    </TabPanel>
                }
                { hideRequestTab ||
                    <TabPanel lazy={true} value={tabValue} index={tabIndex++}>{
                        this.isGraphQL() ? <FieldContainer style={{ padding : '8px', backgroundColor: theme.palette.white.main}}>
                            <GraphQLEditor
                                onEditQuery={(value) => {
                                    requestObject.query = value
                                    requestObject.hasManualGraphQLQueryEdit = true
                                    this.onRequestObjectChange()
                                }}
                                onEditVariables={(value) => {
                                    requestObject.variables = value
                                    requestObject.hasManualGraphQLQueryEdit = true
                                    this.onRequestObjectChange()
                                }}
                                query={requestObject.query}
                                variables={requestObject.variables}
                                response={graphQLRequestResponse}
                                reqAction={() => {
                                }}
                                requestBodyBuilder={requestBodyBuilder}
                                previewReplacedBody={previewReplacedBody}
                                onPreviewReplacedBodyToggle={this.onPreviewReplacedBodyToggle}
                            />
                        </FieldContainer> : <Request
                            key={reqKey}
                            otherTabs={[]}
                            title={getUiLabelTranslationFromContext(this, UI_LABELS_REQUEST_BODY) }
                            classes={classes}
                            reqBody={requestObject.body}
                            reqURL={reqURL}
                            reqVerb={reqVerb}
                            reqTabValue={reqTabValue}
                            reqIcon={reqIcon}
                            reqIconTooltip={reqIconTooltip}
                            response={response}
                            reqAction={reqAction}
                            reqTabChange={reqTabChange}
                            reqBodyChange={(body) => {
                                requestObject.body = body
                                requestObject.hasManualHttpRequestBodyEdit = true
                                this.onRequestObjectChange()
                            }}
                            reqHeaderMap={reqHeaderMap}
                            previewReplacedBody={previewReplacedBody}
                            onPreviewReplacedBodyToggle={this.onPreviewReplacedBodyToggle}
                            previewReplacedHeaders={previewReplacedHeaders}
                            onPreviewReplacedHeadersToggle={(checked) => this.setState({previewReplacedHeaders: checked})}
                        />
                    }</TabPanel>
                }
                <TabPanel lazy={true} value={tabValue} index={tabIndex++}>
                    <div style={{backgroundColor : theme.palette.white.main}}>
                    {
                        loading === false
                            ? ( this.isGraphQL() && responseJSON
                                    ? this.showGraphQLResponse(response, responseJSON)
                                    : <Response
                                        hasError={this.hasError(response, responseJSON)}
                                        classes={classes}
                                        resTabValue={resTabValue}
                                        response={response}
                                        responseJSON={responseJSON}
                                        resTabChange={resTabChange}
                                        onResChangeText={onResChangeText}
                                        onItemSelectionChange={onResponseItemSelectionChange}
                                    />
                            )
                        : <div style={{minHeight: '250px'}}/>
                    }
                    {
                        customizations.hideVariablesBuilder !== true && loading === false && response &&
                        <FieldContainer style={{padding :'8px', backgroundColor: theme.palette.white.main}}>
                            {   getEtagHeaderValue(response) &&
                                <FieldContainer style={{marginBottom: theme.spacing(1)}}>
                                    <H4Title title={"Set Global Variables From Response Headers"}/>
                                    <HeaderVariablesBuilder
                                        response={response}
                                        variables={requestObject.onResponseVariables}
                                        onChange={(v) => {
                                            requestObject.onResponseVariables = v;
                                            this.onRequestObjectChange();
                                        }}
                                        apiMode={requestObject.mode || API_MODE_HTTP_API.value}
                                    />
                                </FieldContainer>
                            }
                            {   responseJSON &&
                                <FieldContainer>
                                    <H4Title title={"Set Global Variables From Response Body"}/>
                                    <VariablesBuilder
                                        responseJSON={responseJSON}
                                        variables={requestObject.onResponseVariables}
                                        onChange={(v) => {
                                            requestObject.onResponseVariables = v;
                                            this.onRequestObjectChange();
                                        }}
                                        apiMode={requestObject.mode || API_MODE_HTTP_API.value}
                                    />
                                </FieldContainer>
                            }
                        </FieldContainer>
                    }
                    </div>
                </TabPanel>
                {customizations?.customTabPanelRenderer && customizations?.customTabPanelRenderer().map((renderer) => {
                    return <TabPanel key={tabValue} lazy={true} value={tabValue} index={tabIndex++}>
                        <div style={{backgroundColor : theme.palette.white.main}}>
                            <FieldContainer style={{padding :'8px', backgroundColor: theme.palette.white.main}}>
                                {renderer()}
                            </FieldContainer>
                        </div>
                    </TabPanel>;
                })}
            </FieldContainer>}
        </div>;
    }


    onSettingsOpen = () => {
        this.setState({openSettingsDialog: true})
    }


    getOnRun = (customizations, reqAction) => {
        return customizations.hideRun === true ? undefined : () => {
            this.setState({loading: true}, () => setTimeout(reqAction, 500));
        };
    }

    render() {
        let {requestObject} = this.props
        traceRequestRenderStart(requestObject, 'RequestResponse')
        return this.getAPIRequestComponent()
    }
}

RequestResponse.propTypes = {
    requestObject: PropTypes.object,
    requestBodyBuilder: PropTypes.func,
    onRequestObjectChange: PropTypes.func,
    onResponseItemSelectionChange: PropTypes.func,
    onRequestSave: PropTypes.func,
    onModeChange: PropTypes.func,
    reqTabValue: PropTypes.number,
    customizations: PropTypes.object,
    registerSendAction: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(RequestResponse);

import React from 'react';
import PropTypes from "prop-types";
import {Grid, TextField as OtherTextField, withStyles} from "@material-ui/core";
import {styles} from "../components/styles";
import {
    ALIAS_SH_PATH,
    ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP,
    ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP,
    STYLE_MAIN_SPACING,
    TYPE
} from "../Constants";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FieldLabel from "../components/ShapeToForm/FieldLabel";
import FieldContainer from "../components/FieldContainer";
import EditIcon from "@material-ui/icons/EditOutlined";
import CreateShapePropertyMockingDialog, {
    RANDOM_FROM_LIST_VALUE,
    RANDOM_REGEX_VALUE,
    STRATEGY_OPTIONS
} from "../components/CreateShapePropertyMockingDialog";
import {getRegExForProperty, isPropertyDataTypeProperty} from "../components/util";
import Instruction from "../components/Instruction";
import RegExField from "../components/RegExField";
import H4Title from "../components/H4Title";

class SHACLPropertyMocking extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    getMockingStrategy = () => {
        const {mockingSetupObject} = this.props;
        if(mockingSetupObject && mockingSetupObject.lines) {
            return RANDOM_FROM_LIST_VALUE;
        } else {
            return RANDOM_REGEX_VALUE;
        }
    }

    getRegexValue = () => {
        let {mockingSetupObject} = this.props
        let {property} = this.props
        return mockingSetupObject && mockingSetupObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP
            ? mockingSetupObject.pattern
            : getRegExForProperty(property).source
    }

    isLinesView = () => {
        let {mockingSetupObject} = this.props
        return mockingSetupObject && mockingSetupObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP;
    }

    getMockingDetailsForDatatypeProperty = () => {
        const {mockingSetupObject, property, classes} = this.props;
        let fieldStyle = {paddingTop: '0px'}
        let inputLabelProps = classes
            ? {
                classes : {
                    root : classes.largeWidthPropertyLabelRoot,
                    shrink: classes.smallWidthLabelShrink
                },
                disabled : false
            }
            : {};
        return <>
            <Grid item xs={12}>
                    <Autocomplete
                        value={this.getMockingStrategy()}
                        options={STRATEGY_OPTIONS}
                        disabled={true}
                        getOptionLabel={option => option.label ?  option.label : ''}
                        renderInput={params => (
                            <OtherTextField
                                label={'Mocking Strategy'}
                                {...params}
                                margin={"dense"}
                                variant="outlined"
                                fullWidth
                                InputLabelProps={inputLabelProps}
                            />
                        )}
                    />
            </Grid>
            {
                this.isLinesView() && <Grid item xs={12}>
                    <OtherTextField
                        fullWidth={true}
                        label={'List of Lines'}
                        name={'lines'}
                        value={mockingSetupObject.lines}
                        multiline={true}
                        rows={6}
                        readOnly={true}
                        variant="outlined"
                    />
                </Grid>
            }
            {
                !this.isLinesView() &&
                    <RegExField
                        property={property}
                        regexPattern={this.getRegexValue()}
                        readOnly={true}
                    />
            }
        </>;
    }

    getMockingDetailsForObjectProperty = () => {
        return <Grid xs={12} item>
            <FieldContainer style={{padding : '0px'}}>
                <Instruction text={'Object properties are mocked with search or based on nested template.'} />
            </FieldContainer>
        </Grid>;
    }

    render() {
        const {classes, theme, focusNode, property, allowEdit=false, width, aliasesMap, ontology, mockingSetupObject} = this.props;
        let xsWidth = width ? width : 12
        let mdWidth = width ? width : 6
        let isDatatypeProperty = isPropertyDataTypeProperty(property[ALIAS_SH_PATH], ontology)
        return ( <Grid key={focusNode.id + property[ALIAS_SH_PATH]} item xs={xsWidth} md={mdWidth}>
                <FieldContainer style={{height : 'calc(100% - 16px)', padding: '8px'}}>
                    <div style={{float: 'right', marginBottom : -theme.spacing(STYLE_MAIN_SPACING), marginTop: '-4px'}}>
                        {
                            allowEdit && isDatatypeProperty &&
                            <CreateShapePropertyMockingDialog
                                aliasesMap={aliasesMap}
                                buttonIcon={<EditIcon/>}
                                property={property}
                                classes={classes} theme={theme}
                                shape={focusNode.backingObject}
                                eventHandler={this.props.eventHandler}
                                ontology={ontology}
                                mockingSetupObject={mockingSetupObject}
                            />
                        }
                    </div>

                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <H4Title noWrap={true}>
                                <FieldLabel json={JSON.stringify(property, null, 4)} property={property} aliasesMap={aliasesMap} />
                            </H4Title>
                        </Grid>
                        {
                            isPropertyDataTypeProperty(property[ALIAS_SH_PATH], ontology)
                                ? this.getMockingDetailsForDatatypeProperty()
                                : this.getMockingDetailsForObjectProperty()
                        }
                    </Grid>
                </FieldContainer>
            </Grid>
        );
    }

}

SHACLPropertyMocking.propTypes = {
    classes: PropTypes.object.isRequired,
    focusNode: PropTypes.object.isRequired,
    property: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    leftAction: PropTypes.object,
    rightAction: PropTypes.object,
    allowDelete: PropTypes.bool,
    allowEdit: PropTypes.bool,
    eventHandler: PropTypes.func,
    width: PropTypes.number,
    ontology: PropTypes.array,
    mockingSetupObject: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(SHACLPropertyMocking);

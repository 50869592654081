import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {PARAM_LANG} from "../../Constants";
import PropTypes from "prop-types";
import {Box, Grid} from "@material-ui/core";
import FieldContainer from "../../components/FieldContainer";
import LanguageSearchSelect from "../../layouts/apiplayground/LanguageSearchSelect";
import Typography from "@material-ui/core/Typography";
import GlobalsContext from "../../components/GlobalsContext";
import {
    UI_LABELS_LANGUAGE,
    UI_LABELS_SEARCH_LANGUAGE_HELP,
    UI_LABELS_SEARCH_LANGUAGE_HELP_VALUE
} from "../navigator/UILabel";
import {getUiLabelTranslationFromContext} from "../../components/util";

class SearchLang extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {theme, otherOptions, onChange} = this.props

        return (<div datatest={'languageTabContent'}>
            <Box style={{marginTop:theme.spacing(1)}}>
                <FieldContainer>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <LanguageSearchSelect
                                datatest={'autocompleteLanguage'}
                                label={getUiLabelTranslationFromContext(this, UI_LABELS_LANGUAGE)}
                                multiple={false}
                                value={otherOptions[PARAM_LANG]}
                                onChange={(val) => {
                                    otherOptions[PARAM_LANG] = val;
                                    onChange(otherOptions)
                                }}
                            />
                            <Typography variant="body1" color="inherit">{
                                getUiLabelTranslationFromContext(this, UI_LABELS_SEARCH_LANGUAGE_HELP, UI_LABELS_SEARCH_LANGUAGE_HELP_VALUE)
                            }</Typography>

                        </Grid>
                    </Grid>
                </FieldContainer>
            </Box>
        </div>);

    }

}

SearchLang.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    otherOptions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(SearchLang);

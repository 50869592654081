import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {
    createGraphQLMutationPayload,
    createHTTPRequestPayload,
    getLanguageCodesSuggestions
} from "../../components/util";
import {GRAPHQL_CREATE_OPERATION} from "../../Constants";
import './APIPlayground.css';
import RequestResponse from "../../components/RequestResponse";
import Form from "../../components/ShapeToForm/Form";
import ErrorMessage from "../../components/ErrorMessage";
import {Button} from "@material-ui/core";
import FieldContainer from "../../components/FieldContainer";
import {traceRequestRenderStart} from "../../components/Trace";
import WarningIcon from '@material-ui/icons/Warning';
import {getLanguageLabelForSite} from "./SearchFilter";

export function addGraphQLPayload(createRequestObject, aliasesMap) {
    let shapeForms = createRequestObject.build
    if(!createRequestObject.hasManualGraphQLQueryEdit) {
        let mutationQuery = shapeForms && shapeForms.length > 0
            ? createGraphQLMutationPayload(shapeForms, false, aliasesMap)
            : '';
        createRequestObject.query = mutationQuery.query
        createRequestObject.variables = mutationQuery.variables
        createRequestObject.operationName = GRAPHQL_CREATE_OPERATION
    }
}

export function addPayloads(createRequestObject, aliasesMap) {
    let shapeForms = createRequestObject.build
    addGraphQLPayload(createRequestObject, aliasesMap)
    if(!createRequestObject.hasManualHttpRequestBodyEdit) {
        let payload = createHTTPRequestPayload(shapeForms, aliasesMap)
        createRequestObject.body = JSON.stringify(payload, null, 4)
    }
}

export function getForm(theme, createRequestObject, onFormChange, onManualEditChange) {
    let header = (createRequestObject.hasManualHttpRequestBodyEdit || createRequestObject.hasManualGraphQLQueryEdit)
        && <FieldContainer style={{marginBottom : '8px', border: '2px solid', borderColor : theme.palette.secondary.main, padding : '8px', backgroundColor : theme.palette.white.main}}>

            <WarningIcon color={'secondary'}/>
            <ErrorMessage style={{padding: '0px'}} error={<>Request body generation using the Builder has been disabled because you have manually changed the mode or edited the request body in JSON view. To enable the form click the 'RESET' button below. Note this action will regenerate the request body from this form and any manual changes will be lost.</>} />
            <div style={{marginTop : '8px', display : 'flex'}}>
                <div style={{flexGrow : '1'}}></div>
                <Button
                    variant={'contained'}
                    color={'secondary'}
                    size={"small"}
                    onClick={(e) => {
                        delete createRequestObject.hasManualHttpRequestBodyEdit
                        delete createRequestObject.hasManualGraphQLQueryEdit
                        onManualEditChange()
                    }}
                >Reset</Button>
            </div>
        </FieldContainer>
    // If there is no form initialize so that forms can be added to array
    if(!createRequestObject.build) {
        createRequestObject.build = []
    }
    let languageCodesSuggestions = getLanguageCodesSuggestions().map(lv => {
        return {
            value: lv.value,
            label: getLanguageLabelForSite(lv)
        }
    });
    return <>
        <Form customizations={{hideSearchConnected : true , languageSuggestions : languageCodesSuggestions}} header={header} initialForms={createRequestObject.build} style={{padding : '8px', backgroundColor : theme.palette.white.main}} onChange={onFormChange}/>
    </>;
}


class CreateRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
    }

    updateCreateRequestObject = () => {
        let {aliasesMap, createRequestObject} = this.props
        addPayloads(createRequestObject, aliasesMap)
        this.setDirty()
    }

    setDirty = () => {
        let {createRequestObject} = this.props
        if(!createRequestObject.dirty) {
            createRequestObject.dirty = true
            this.setState({})
        }
    }

    onFormChange = (shapeForms) => {
        let {createRequestObject} = this.props
        createRequestObject.build = shapeForms
        this.updateCreateRequestObject()
    }


    saveCreate = () => {
        let {onSave, createRequestObject} = this.props
        this.updateCreateRequestObject()
        onSave(createRequestObject)
    }

    getForm = () => {
        let {theme, createRequestObject} = this.props
        return getForm(
            theme,
            createRequestObject,
            this.onFormChange,
            () => {
                this.updateCreateRequestObject()
                this.setState({})
            }
        );
    }

    onModeChange = (obj) => {
        let {onRequestObjectChange} = this.props
        this.setDirty();
        //update state to ensure that components are re-rendered for new mode
        this.setState({}, () => (onRequestObjectChange(obj)))
    }

    getRequestResponse = () => {
        let {customizations, createRequestObject, registerSendAction, onRequestObjectChange} = this.props
        return <RequestResponse
            requestBodyBuilder={this.getForm}
            reqTabValue={0}
            requestObject={createRequestObject}
            onRequestObjectChange={ (obj) => {this.setDirty(); onRequestObjectChange(obj)}}
            onRequestSave={this.saveCreate}
            onModeChange={this.onModeChange}
            registerSendAction={registerSendAction}
            customizations={customizations}
        />;
    }

    render() {
        let {createRequestObject} = this.props
        traceRequestRenderStart(createRequestObject, 'CreateRequest')

        return (<>
            {this.getRequestResponse()}
        </>);
    }

}

CreateRequest.propTypes = {
    onSave: PropTypes.func,
    registerSendAction: PropTypes.func,
    exampleSetId: PropTypes.string,
    apiMode: PropTypes.object,
    aliasesMap: PropTypes.object,
    createRequestObject: PropTypes.object,
    onRequestObjectChange: PropTypes.func,
    customizations: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(CreateRequest);

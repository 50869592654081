import React, {Component} from 'react';
import {fade, withStyles} from '@material-ui/core/styles';

import {cloneDeepWith, uniq} from 'lodash';
import {styles} from "../../components/styles";
import {
    centerVertically,
    createNodeForView,
    flatten,
    generateExampleRequestId,
    generateExampleSetId,
    getAliasesMap,
    getAllOntologyData,
    getApiConfigurationResource,
    getContainerData,
    getDataContextURL,
    getMockValueFromDefaultRegex,
    getProp,
    getRandomIntFromInterval,
    getRouteWithInstanceAndDataset,
    getShapesData,
    isReadOnlyAPI,
    searchInTree,
    sort,
    stringifyPatchReqBody,
    restrictMaxLength
} from "../../components/util";
import queryString from "query-string";
import AllPartsLayout from "../AllPartsLayout";
import APIPlaygroundHeader from "../../components/header/APIPlaygroundHeader";
import './APIPlayground.css';
import InstructionForAction from "../../components/InstructionForAction";
import IconButton from "../../components/IconButton";
import {Grid, IconButton as MUIIconButton, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import SortByAlphaIcon from "@material-ui/icons/SortByAlpha";
import MenuListComposition from "../../components/MoreMenuIcon";
import NavTree from '../../components/NavTree/NavTree';
import qs from "qs";
import history from "../../history";
import {
    ALIAS_RDFS_LABEL,
    ALIAS_SH_PATH,
    ALIAS_SYS_DELETE,
    ALIAS_SYS_EXAMPLE_COLLECTION,
    ALIAS_SYS_EXAMPLE_EXAMPLE_SET,
    ALIAS_SYS_EXAMPLE_TYPE_BATCH,
    ALIAS_SYS_EXAMPLE_TYPE_COLLECTION,
    ALIAS_SYS_EXAMPLE_TYPE_CREATE,
    ALIAS_SYS_EXAMPLE_TYPE_DELETE,
    ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET,
    ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP,
    ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    ALIAS_SYS_EXAMPLE_TYPE_UPDATE,
    ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP,
    ALIAS_SYS_PATCH,
    ALIAS_SYS_POST,
    AT_CONTEXT,
    EXAMPLE_TYPE_TO_COLOR,
    EXAMPLE_TYPE_TO_MENU_LABEL,
    EXAMPLE_TYPE_TO_TITLE,
    FROM_SHAPE,
    GRAPH,
    ID,
    LABEL_PLAYGROUND_DASHBOARD,
    MESSAGE_SELECT_TO_VIEW_DETAILS,
    ROUTE_API_PLAYGROUND,
    STYLE_GRID_ITEM_SPACING,
    TYPE,
    VARIABLE_DATA_CONTEXT_URL
} from "../../Constants";
import GridContainer from "../../components/GridContainer";
import fileDownload from "js-file-download";
import PropTypes from "prop-types";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import FieldContainer from "../../components/FieldContainer";
import {getAllConfigurations} from "../../service/graph-api";
import CustomLabel from "../../components/CustomLabel";
import CollectionDetails from "../../layouts/apiplayground/CollectionDetails";
import MockingConfiguration from "../../layouts/apiplayground/MockingConfiguration";
import GlobalsContext from "../../components/GlobalsContext";
import ViewGlobalsDialog from "../../components/ViewGlobalsDialog";
import TreeViewForIdSetup, {searchLabelRecursively} from "../../components/TreeViewForIdSetup";
import ScenarioDetails, {showRequest} from "../../layouts/apiplayground/ScenarioDetails";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import RandExp from "randexp";
import {
    customizer,
    getCollection,
    getCollectionData,
    getCollectionMockingSetupData,
    getCollectionScenariosData,
    storeCollectionScenariosData,
    storeWorkspaceAndData,
    updateCollection
} from "../../service/playground-api";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from "@material-ui/icons/MoreVertOutlined";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import {getDirtyIndicator} from "../../components/RequestResponse";
import {traceComponentDidUpdateStart, traceRenderStart} from "../../components/Trace";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import Button from "@material-ui/core/Button";
import AddDialog from "../../layouts/apiplayground/AddDialog";
import ImportScenarioDialog from "../../components/ImportScenarioDialog";
import AutoComplete from "../../components/AutoComplete";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import List from "@material-ui/core/List";
import LeftBarTitle from "../../components/LeftBarTitle";
import {LeftMenuIconButton} from "../../components/LeftMenuIconButton";

const getListStyle = (isDraggingOver, theme) => ((isDraggingOver ? {
    marginBottom: 48,
    //paddingBottom: 4,
    //background: isDraggingOver ? fade(theme.palette.secondary.selected, .05) : "inherit",
} : {
   // marginTop: 4,

}));

const getItemStyle = (isDragging, draggableStyle, theme) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",

    // change background colour if dragging
    background: isDragging ? fade(theme.palette.secondary.selected, .05) : "inherit",
    // styles we need to apply on draggables
    ...draggableStyle
});

function createRequestBody() {
    return stringifyPatchReqBody(false, {
        [AT_CONTEXT]: [VARIABLE_DATA_CONTEXT_URL],
        [GRAPH]: []
    });
}

function createBatchRequestBody() {
    return stringifyPatchReqBody(false, {
        [AT_CONTEXT]: [VARIABLE_DATA_CONTEXT_URL],
        [ALIAS_SYS_POST]: [],
        [ALIAS_SYS_PATCH]: [],
        [ALIAS_SYS_DELETE]: [],
    });
}

export function createRequest(type, exampleSetId, apiConfigIRI, obj) {
    let request = {
        id: generateExampleRequestId(),
        type: type,
        [ALIAS_SYS_EXAMPLE_EXAMPLE_SET]: exampleSetId,
        apiConfigIRI: apiConfigIRI,
        title: obj && obj.title ? obj.title : 'Request',
        description: obj && obj.description ? obj.description : ''
    };
    if (type === ALIAS_SYS_EXAMPLE_TYPE_CREATE || type === ALIAS_SYS_EXAMPLE_TYPE_DELETE || type === ALIAS_SYS_EXAMPLE_TYPE_UPDATE) {
        request.body = createRequestBody();
    } else if (type === ALIAS_SYS_EXAMPLE_TYPE_BATCH) {
        request.body = createBatchRequestBody();
    } else if (type === ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA) {
        request.disableApiMode = true;
    }
    request.onResponseVariables = []
    return request;
}

export function createScenarioObject(obj, collectionDetails) {
    return {
        id: generateExampleSetId(),
        type: ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET,
        [ALIAS_SYS_EXAMPLE_COLLECTION]: collectionDetails[ID],
        apiConfigIRI: collectionDetails.apiConfigIRI,
        title: obj.title,
        description: obj.description,
        children: []
    };
}

export function getTitlePrefixComponent(color, prefix) {
    return <Typography datatest={'titlePrefix'} style={{color: color}} component={'div'}
                       variant={"caption"}>{prefix}</Typography>;
}


const COMPONENT = 'Examples'

class Examples extends Component {
    constructor(props) {
        super(props);
        //this means import
        if (props.collection) {
            let {treeData, mockingObjects, collection, focusNode, expanded} = props
            this.state = {
                leftMenuMinimized: false,
                collectionDetails: collection,
                treeData: treeData,
                mockingObjects: mockingObjects,
                expanded: expanded,
                loading: false,
                reqTabValue: 1,
                resTabValue: 0,
                previewPayload: '{}',
                postReqBody: '{}',
                leftMenuTabValue: 0,
                globals: {},
                focusNode: collection,
                minimized: true,
            }
        } else {
            let params = queryString.parse(props.location.search)
            let collectionDetails = getCollection(params.collectionId) || getCollection(params.id)
            if (collectionDetails) {
                let collectionTreeData = getCollectionScenariosData(collectionDetails)
                let expanded = params.expanded ? params.expanded.split(',') : []
                this.state = {
                    leftMenuMinimized: false,
                    collectionDetails: collectionDetails,
                    treeData: collectionTreeData,
                    mockingObjects: getCollectionMockingSetupData(collectionDetails),
                    expanded: expanded ? expanded : [],
                    loading: false,
                    reqTabValue: 1,
                    resTabValue: 0,
                    previewPayload: '{}',
                    postReqBody: '{}',
                    leftMenuTabValue: 0,
                    globals: {},
                    minimized: true,

                }
            } else {
                this.state = {}
            }
        }
    }

    componentDidMount() {
        let {collectionDetails} = this.state;
        if (!collectionDetails) {
            history.push(getRouteWithInstanceAndDataset(ROUTE_API_PLAYGROUND));
        }
        this.syncDataWithBackend();
        this.setGlobalsItem("DATA_CONTEXT_URL", getDataContextURL());
    }

    syncDataWithBackend = () => {
        this.setState({loading: true})
        const ontology = getAllConfigurations()
        ontology.then((response) => {
            const treeData = getShapesData(response).map(o => {
                return createNodeForView(o, () => {
                    return getProp(o, ALIAS_RDFS_LABEL);
                });
            }).map(o => {
                o.tooltip = o.id
                return o;
            })
            let aliasesMap = getAliasesMap(response)
            let apiConfigurationResource = getApiConfigurationResource(response);
            this.setState({
                aliasesMap: aliasesMap,
                mockSetupTreeData: sort(treeData, 'title'),
                loading: false,
                APIConfiguration: apiConfigurationResource,
                ontology: getAllOntologyData(response),
                configurations: response
            })
        })

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        traceComponentDidUpdateStart(COMPONENT)
    }

    getTitlePrefix = (id) => {
        let {treeData} = this.state
        let type = searchInTree(id, treeData)[TYPE];
        let color = EXAMPLE_TYPE_TO_COLOR[type]
        let title = EXAMPLE_TYPE_TO_TITLE[type]
        return title && getTitlePrefixComponent(color, title);
    }

    renderNodeLabel = (node, index) => {
        let {theme} = this.props;
        let {treeData} = this.state;
        return (<div datatest={'nodeLabel'} style={{display: 'flex'}}>
            {getDirtyIndicator(searchInTree(node[ID], treeData))}
            <div>{node.labelText}</div>
        </div>);
    }

    renderTreeItem = (node, treeItem, index) => {
        let {theme, readOnly} = this.props
        let id = node[ID]
        if (readOnly) {
            return treeItem;
        }
        return node[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET ?
            <DragDropContext key={id} onDragEnd={this.onRequestsReorder}>
                <Droppable droppableId={id}>
                    {(provided, snapshot) => (<div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver, theme)}
                        >{
                            treeItem
                        }</div>
                    )}
                </Droppable>
            </DragDropContext> :
            (<Draggable key={id} draggableId={id} index={index}>
                {(provided, snapshot) => (
                    <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                            theme
                        )}
                    >
                        {treeItem}
                    </div>
                )}</Draggable>)
            ;
    }

    onSearch = (e, node) => {
        if (node) {
            const {expanded, treeData} = this.state;
            const {id} = node;
            const newExpandedItems = flatten(treeData.map(treeNode => searchLabelRecursively(treeNode, id, 'id')));
            const expandedItems = uniq([...expanded, ...newExpandedItems]);
            this.navigateTo({node: node})
            this.setState({searchNode: node, expanded: expandedItems}, () => {
                this.setState({scrollTo: true})
            });
        }
    }

    getDataForSearch = (dataForView) => {
        let allData = [...dataForView, ...([].concat.apply([], dataForView.map(t => t.children)))];
        return allData;
    }

    getSearchLabel = (node) => {
        let type = node[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET ? 'Scenario' : 'Request';
        return <div style={{display: 'flex'}}>
            {centerVertically(<Typography style={{width: '60px'}} component={'div'}
                                          variant={"caption"}>{type}</Typography>)}
            <div>{node.title}</div>
        </div>;
    }

    getDataForView = (treeData) => {
        let dataForView = [];
        if(treeData && treeData.length > 0) {
            treeData.forEach(n => {
                let node = {
                    [ID]: n[ID],
                    title: n.title,
                    [TYPE]: n[TYPE],
                    children: n.children.map(c => {
                        let childNode = {
                            [ID]: c[ID],
                            title: c.title,
                            [TYPE]: c[TYPE],
                        };
                        return childNode;
                    })
                };
                dataForView.push(node);
            });
        }
        return dataForView;
    }

    getLeftIconButton = (icon, title, onClick) => {
        let {minimized} = this.state
        let {theme} = this.props
        return <LeftMenuIconButton theme={theme} title={title} onClick={onClick} icon={icon} minimized={minimized}/>;
    }

    getLeftActions = () => {
        let {theme} = this.props;
        let {minimized} = this.state;
        let iconStyle = {color: theme.palette.white.main}
        let width = minimized ? '52px' : '250px'

        return <>
            <div style={{
                borderLeft: '1px solid',
                borderLeftColor: '#c05335',
                backgroundColor: theme.palette.secondary.dark,
                float: 'right',
                marginTop: theme.spacing(3),
                width: theme.spacing(3)
            }}>
                <IconButton onClick={() => this.setState({minimized: !minimized})} style={{padding: '8px 0px'}}
                            size={'small'}>
                    {
                        minimized
                            ? <ChevronRightIcon style={{color: theme.palette.white.main}}/>
                            : <ChevronLeftIcon style={{color: theme.palette.white.main}}/>
                    }
                </IconButton>
            </div>
            <div style={{height: '100%', width: width, backgroundColor: theme.palette.secondary.dark}}>
                <List style={{padding: '0px'}}>
                    {
                        this.getLeftIconButton(
                            <ViewModuleIcon style={iconStyle}/>,
                            LABEL_PLAYGROUND_DASHBOARD,
                            () => history.push(getRouteWithInstanceAndDataset(ROUTE_API_PLAYGROUND))
                        )
                    }
                    {
                        this.getLeftIconButton(
                            <PublishIcon style={iconStyle}/>,
                            "Import Scenario",
                            () => this.setState({openImportScenarioDialog: true})
                        )
                    }
                    {
                        this.getLeftIconButton(
                            <GetAppIcon style={iconStyle}/>,
                            "Export Workspace",
                            this.exportCollection
                        )
                    }
                    {
                        this.getLeftIconButton(
                            <VisibilityIcon style={iconStyle}/>,
                            "View Global Variables",
                            () => this.setState({openViewGlobalsDialog: true})
                        )
                    }

                </List>
            </div>
        </>;

    }

    getLeftComponent = () => {
        const {searchNode, sortAToZ, treeData, mockSetupTreeData, expanded, leftMenuTabValue, scrollTo} = this.state;
        const {onTreeNodeToggle, scenariosTreeStyle, location, theme, readOnly} = this.props;
        const params = queryString.parse(location.search);
        let treeStyle = {maxHeight: '100%'};
        let treeContainerStyle = {
            marginRight: '8px',
            height: readOnly ? 'calc(100% - 352px)' : 'calc(100% - 240px)',
            display: 'flex',
            flexDirection: 'column'
        };
        let mockContainerStyle = {
            height: readOnly ? 'calc(100% - 324px)' :'calc(100% - 204px)',
            padding : '8px',
            display: (leftMenuTabValue === 1 ? 'block' : 'none')
        };
        let {minimized} = this.state;

        let computedMockTreeStyle = {maxHeight: '100%'};

        let dataForView = this.getDataForView(treeData);
        let dataForSearch = this.getDataForSearch(dataForView);

        return <div style={{display: 'flex', height: '100%'}}>
            <div style={{minWidth: (minimized ? '77px' : '275px'), marginRight: '8px', height: '100%'}}>
                {readOnly || this.getLeftActions()}
            </div>
            <div style={{minWidth: `calc(100% - ${(minimized ? '85px' : '283px')})`, flexGrow: '1', height: '100%'}}>
                {this.getLeftBarHeader()}
                <FieldContainer style={{
                    height: '100%',
                    paddingTop: '0px',
                    paddingRight: '0px',
                    backgroundColor: theme.palette.white.main
                }}>
                    <div datatest={'scenarioTreeTabContent'} style={{height: '100%', display: (leftMenuTabValue === 0 ? 'block' : 'none')}}>
                        {!treeData || treeData.length < 1
                            ? <div style={{paddingTop: '100px', textAlign: "center"}}>
                                <div style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>Add a scenario to get
                                    started
                                </div>
                                <Button datatest={'addScenarioButton'} startIcon={<AddIcon/>} variant="contained" color="secondary"
                                        onClick={this.showCreateScenarioDialog}>
                                     Add Scenario
                                </Button>
                            </div>
                            : <>
                                <div style={{display: 'flex'}}>
                                    <div style={{flexGrow: '1'}}>
                                        <AutoComplete
                                            data={dataForSearch}
                                            id="treeview-search"
                                            onChange={this.onSearch}
                                            renderOption={(option, {selected}) => (
                                                <React.Fragment>
                                                    {this.getSearchLabel(option)}
                                                </React.Fragment>
                                            )}
                                            textFieldPaperStyle={{padding: '0px 10px 0px 4px'}}
                                        />
                                    </div>
                                    {
                                        readOnly || centerVertically(
                                            <Tooltip title={"Add Scenario"}>
                                                <MUIIconButton
                                                    datatest={'addScenarioButton'}
                                                    size={'small'}
                                                    onClick={this.showCreateScenarioDialog}
                                                >
                                                    <AddIcon color={'primary'}/>
                                                </MUIIconButton>
                                            </Tooltip>
                                        )
                                    }
                                    {
                                        centerVertically(
                                            <IconButton
                                                datatest={'sortScenariosButton'}
                                                selected={sortAToZ}
                                                size={'small'}
                                                onClick={() => this.setState({sortAToZ: !sortAToZ})}>
                                                    <Tooltip title={"Sort"}><SortByAlphaIcon color={'primary'}/></Tooltip>
                                            </IconButton>,
                                            {marginRight: '8px'}
                                        )
                                    }

                                </div>
                                <div datatest={'scenarioTree'} style={treeContainerStyle}>
                                    <NavTree
                                        rootStyle={treeStyle}
                                        data={sortAToZ === true ? sort(dataForView, 'title') : dataForView}
                                        expanded={expanded}
                                        scrollTo={scrollTo}
                                        focusedNode={params.id}
                                        onScrollEnd={() => this.setState({scrollTo: false})}
                                        renderActions={this.renderActions}
                                        onNodeClick={this.navigateTo}
                                        renderTreeItem={this.renderTreeItem}
                                        renderNode={($node, index) => {
                                            return <CustomLabel
                                                datatestOverride={'customAliasTreeLabel'}
                                                {...$node}
                                                renderLabel={() => this.renderNodeLabel($node, index)}
                                                renderLabelPrefix={(nodeInner) => {
                                                    return this.getTitlePrefix(nodeInner.id);
                                                }}
                                            />;
                                        }}
                                        onNodeToggle={(ev, nodeIds) => {
                                            if (onTreeNodeToggle) {
                                                onTreeNodeToggle(nodeIds)
                                            }
                                            this.setState({expanded: nodeIds});
                                        }
                                        }
                                    />
                                </div>
                            </>
                        }
                    </div>
                    <div datatest={'mockSetupList'} style={mockContainerStyle}>
                        {
                            mockSetupTreeData && <TreeViewForIdSetup
                                data={mockSetupTreeData}
                                location={location}
                                treeData={mockSetupTreeData}
                                viewType={'list'}
                                toggle={false}
                                disableViewType={true}
                            />
                        }
                    </div>
                </FieldContainer>
            </div>
        </div>;
    }

    renderActions = ({isNodeFocused, node}) => {
        let {readOnly} = this.props
        if (readOnly) {
            return [];
        }
        return isNodeFocused && this.getItemMenu(node);
    }

    onRequestsReorder = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let sourceIndex = result.source.index
        let sourceScenarioId = result.source.droppableId
        let destinationIndex = result.destination.index
        let destinationScenarioId = result.destination.droppableId
        let {treeData, collectionDetails} = this.state
        let {readOnly} = this.props

        let sourceScenarioNode = searchInTree(sourceScenarioId, treeData)
        let destinationScenarioNode = searchInTree(destinationScenarioId, treeData)
        const removed = sourceScenarioNode.children.splice(sourceIndex, 1)[0];
        destinationScenarioNode.children.splice(destinationIndex, 0, removed);
        if (!readOnly) {
            storeWorkspaceAndData(collectionDetails, treeData)
        }
        this.setState({treeData})
    }

    onMockingSetupUpdate = (mockingSetupObject) => {
        let {readOnly} = this.props
        // if readOnly then update array do not create new array
        if (readOnly) {
            let {mockingObjects} = this.state
            let index = mockingObjects.findIndex(m => m[ID] === mockingSetupObject[ID]);
            index >= 0
                ? mockingObjects[index] = mockingSetupObject
                : mockingObjects.push(mockingSetupObject)
            this.setState({mockingObjects: mockingObjects})
        } else {
            this.setState({mockingObjects: this.getMockingSetupFromStore()})
        }
    }

    getMiddleComponent = () => {
        let {location, readOnly} = this.props
        let {mockingObjects} = this.state
        let params = queryString.parse(location.search)
        let {apiMode, treeData, exampleSetId, aliasesMap, leftMenuTabValue, collectionDetails, configurations} = this.state
        let focusObject = params ? searchInTree(params.id, treeData) : undefined

        if (!collectionDetails || !configurations) {
            return <ProcessingBackdrop marginLeft={true} loading={true}/>;
        }

        // if aliasesMap is not set then syncDataWithBackend has not finished
        if (aliasesMap === undefined) {
            return;
        }

        // if not found in tree then show collection details object
        focusObject = focusObject
            ? focusObject
            : collectionDetails
        if (leftMenuTabValue === 1) {
            return <MockingConfiguration
                collectionDetails={collectionDetails}
                location={location}
                mockingObjects={mockingObjects}
                onMockingSetupUpdate={this.onMockingSetupUpdate}
                readOnly={readOnly}
            />;
        }
        if (!focusObject) {
            return <GridContainer noSpacing={true}>
                <Grid item xs={12}>
                    <InstructionForAction text={MESSAGE_SELECT_TO_VIEW_DETAILS}/>
                </Grid>
            </GridContainer>;
        }
        if (focusObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_COLLECTION) {
            let collectionDetails = focusObject
            return <CollectionDetails
                readOnly={readOnly}
                formTitle={'Workspace Details'}
                onSave={(collectionDetails) => {
                    // If readOnly do not update in the backend just update in memory and reset dirty
                    delete collectionDetails.dirty
                    if (!readOnly) {
                        updateCollection(collectionDetails)
                    }

                    let node = {node: {}}
                    this.setState({collectionDetails: collectionDetails}, () => this.navigateTo(node))
                }}
                collectionDetails={collectionDetails}
            />;
        } else if (focusObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET) {
            let scenarioID = focusObject[ID]
            let requestObjects = this.getRequestObjectsInSet(scenarioID)
            return <ScenarioDetails
                readOnly={readOnly}
                key={scenarioID}
                onSaveScenario={(scenarioObject) => {
                    let node = {node: {}}
                    this.saveScenario(scenarioObject)
                }}
                onAddRequest={this.addRequest}
                onDeleteRequest={this.deleteRequestObject}
                onSaveRequest={this.handleCreateExampleRequest}
                scenarioObject={focusObject}
                requestObjects={requestObjects}
                renderRequestTitlePrefix={this.getTitlePrefix}
                onRequestsReorder={this.onRequestsReorder}
                aliasesMap={aliasesMap}
            />;
        } else {
            return showRequest(focusObject, exampleSetId, apiMode, aliasesMap, undefined, this.handleCreateExampleRequest, () => {
            }, {embedded: false, readOnly: readOnly});
        }
    }

    getRequestObjectsInSet = (id) => {
        const {treeData} = this.state
        let parentNode = searchInTree(id, treeData)
        let requestObjects = parentNode.children;
        return requestObjects;
    }

    getRequestTabValue = (requestObject) => {
        return requestObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_SEARCH ? 0 : 2
    }

    minimizeLeftMenu = () => {
        const {leftMenuMinimized, previewPayload, postReqBody} = this.state;
        let previewPayloadStringified = stringifyPatchReqBody(!leftMenuMinimized, JSON.parse(previewPayload));
        let postReqBodyStringified = stringifyPatchReqBody(!leftMenuMinimized, JSON.parse(postReqBody));
        this.setState({
            leftMenuMinimized: !leftMenuMinimized,
            postReqBody: postReqBodyStringified,
            previewPayload: previewPayloadStringified
        })
    }

    handleImportExampleSets = (treeDataFromImport) => {
        let {treeData, collectionDetails} = this.state
        storeCollectionScenariosData(collectionDetails, [...treeData, ...treeDataFromImport])
        this.setState({treeData: getCollectionScenariosData(collectionDetails)})
    }

    handleCreateExampleRequest = (requestObject, index) => {
        let {collectionDetails, treeData} = this.state
        delete requestObject.dirty
        let {id} = requestObject
        let parentId = requestObject[ALIAS_SYS_EXAMPLE_EXAMPLE_SET]
        let parentNode = searchInTree(parentId, treeData)
        //check if it is save or create new
        let existing = parentNode.children.find(c => c[ID] === id)
        delete parentNode.dirty
        if (parentNode.children && !existing) {
            if (index !== undefined) {
                parentNode.children.splice(index, 0, requestObject)
            } else {
                parentNode.children.push(requestObject)
            }
        }
        storeWorkspaceAndData(collectionDetails, treeData)
        this.setState({treeData})
        return Promise.resolve('')
    }

    createExampleRequestNode = (event) => {
        let {id, label} = event
        return {
            id: id,
            title: label,
            onLabelClick: this.navigateTo,
            actions: {create: false}
        };
    }

    showCreateScenarioDialog = () => {
        this.setState({showCreateScenarioDialog: true})
    }

    handleCreateScenario = (exampleSetNode) => {
        return this.saveScenario(exampleSetNode, () => {
            this.setState({focusNode: exampleSetNode, showCreateScenarioDialog: false})
            this.navigateTo({node: {[ID]: exampleSetNode[ID]}})
        })
    };

    handleImportScenarios = (exampleSetNodes) => {
        let {collectionDetails, treeData} = this.state;
        let newTreeData = [...treeData, ...exampleSetNodes];
        storeCollectionScenariosData(collectionDetails, newTreeData);
        this.setState({treeData: newTreeData});
    };

    saveScenario = (exampleSetNode, callBack) => {
        let {collectionDetails, treeData} = this.state
        let found = treeData.find(n => n[ID] === exampleSetNode[ID])
        //if new save
        if (!found) {
            treeData.push(exampleSetNode)
        }
        delete collectionDetails.dirty
        delete exampleSetNode.dirty
        storeCollectionScenariosData(collectionDetails, treeData)
        this.setState({treeData}, callBack)
    }


    navigateTo = ({node}) => {
        let {location} = this.props;
        let {collectionDetails, expanded} = this.state;
        history.push(`${location.pathname}?${node.id ? qs.stringify({
            collectionId: collectionDetails[ID],
            id: node.id,
            expanded: expanded.join(',')
        }) : ''}`)
    }

    getActions = (requestObj) => {
        let id = requestObj[ID]
        return [this.getItemMenu(requestObj)];
    }

    exportCollection = () => {
        let {collectionDetails} = this.state
        // Get data from store as export is for saved changes only
        let allObjects = [getCollection(collectionDetails[ID]), ...getCollectionData(collectionDetails)]
        let name = collectionDetails.title
        fileDownload(JSON.stringify(allObjects, null, 4), name + '_' + new Date().toISOString() + ".json")
    }

    duplicateExampleSet = (exampleSetObjectID) => {
        let {treeData} = this.state
        let exampleSetObject = searchInTree(exampleSetObjectID, treeData)
        let clone = cloneDeepWith(exampleSetObject, customizer);
        // Regenerate ids for scenario and its children
        let newExampleSetID = generateExampleSetId()
        clone[ID] = newExampleSetID
        clone.title = exampleSetObject.title + " Copy"
        let requestObjects = clone.children
        requestObjects.forEach(o => {
            let newExampleRequestID = generateExampleRequestId()
            o[ID] = newExampleRequestID
            o[ALIAS_SYS_EXAMPLE_EXAMPLE_SET] = newExampleSetID
        })
        this.saveScenario(clone)
    }

    exportExampleSet = (exampleSetObjectID) => {
        let {treeData} = this.state
        let exampleSetObject = searchInTree(exampleSetObjectID, treeData)
        let name = exampleSetObject.title
        fileDownload(JSON.stringify(cloneDeepWith([exampleSetObject], customizer), null, 4), name + '_' + new Date().toISOString() + ".json")
    }

    deleteExampleSet = (exampleSetObjectID) => {
        let {treeData} = this.state
        let index = treeData.map(i => i[ID]).indexOf(exampleSetObjectID);
        treeData.splice(index, 1)
        this.handleObjectDelete(exampleSetObjectID, treeData)
    }

    deleteRequestObject = (requestObjectID) => {
        let {treeData} = this.state
        let requestObject = searchInTree(requestObjectID, treeData)
        let exampleSetObjectID = requestObject[ALIAS_SYS_EXAMPLE_EXAMPLE_SET]
        let index = treeData.map(i => i[ID]).indexOf(exampleSetObjectID);
        let children = treeData[index]['children']
        let requestObjectIndex = children.map(i => i[ID]).indexOf(requestObjectID)
        children.splice(requestObjectIndex, 1)
        this.handleObjectDelete(requestObjectID, treeData)
    }

    handleObjectDelete = (objectID, treeData) => {
        let {focusNode, collectionDetails} = this.state
        if (focusNode && focusNode.id === objectID) {
            this.setState({focusNode: undefined})
        }
        storeCollectionScenariosData(collectionDetails, treeData)
        this.setState({treeData})
    }

    addRequest = (exampleSetId, type, index, openNewRequest) => {
        let {collectionDetails} = this.state
        let request = createRequest(type, exampleSetId, collectionDetails.apiConfigIRI)
        this.handleCreateExampleRequest(request, index).then(r => {
            let {expanded} = this.state
            if (!expanded.includes(exampleSetId)) {
                expanded.push(exampleSetId)
            }
            let newFocusNode = searchInTree(request.id, this.state.treeData)
            this.setState({focusNode: newFocusNode, expanded: expanded})
            if (openNewRequest === undefined || openNewRequest === true) {
                this.navigateTo({node: newFocusNode})
            }
        })
    }

    getMenuOptionsForExampleSet = (exampleSetObject) => {
        let exampleSetObjectID = exampleSetObject[ID]
        let containerData = getContainerData(this.state.configurations);
        let isReadOnly = isReadOnlyAPI(containerData);
        return [
            {
                label: EXAMPLE_TYPE_TO_MENU_LABEL[ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA],
                onClick: () => {
                    this.addRequest(exampleSetObjectID, ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA)
                }
            },
            {
                label: EXAMPLE_TYPE_TO_MENU_LABEL[ALIAS_SYS_EXAMPLE_TYPE_CREATE],
                onClick: () => {
                    this.addRequest(exampleSetObjectID, ALIAS_SYS_EXAMPLE_TYPE_CREATE)
                },
                disabled : isReadOnly
            },
            {
                label: EXAMPLE_TYPE_TO_MENU_LABEL[ALIAS_SYS_EXAMPLE_TYPE_SEARCH],
                onClick: () => {
                    this.addRequest(exampleSetObjectID, ALIAS_SYS_EXAMPLE_TYPE_SEARCH)
                }
            },
            {
                label: EXAMPLE_TYPE_TO_MENU_LABEL[ALIAS_SYS_EXAMPLE_TYPE_UPDATE],
                onClick: () => {
                    this.addRequest(exampleSetObjectID,
                        ALIAS_SYS_EXAMPLE_TYPE_UPDATE
                    )
                },
                disabled : isReadOnly
            },
            {
                label: EXAMPLE_TYPE_TO_MENU_LABEL[ALIAS_SYS_EXAMPLE_TYPE_DELETE],
                onClick: () => {
                    this.addRequest(exampleSetObjectID,
                        ALIAS_SYS_EXAMPLE_TYPE_DELETE
                    )
                },
                disabled : isReadOnly
            },
            {
                label: EXAMPLE_TYPE_TO_MENU_LABEL[ALIAS_SYS_EXAMPLE_TYPE_BATCH],
                onClick: () => {
                    this.addRequest(exampleSetObjectID,
                        ALIAS_SYS_EXAMPLE_TYPE_BATCH
                    )
                },
                disabled : isReadOnly
            },
            {
                label: 'Duplicate',
                onClick: () => this.duplicateExampleSet(exampleSetObjectID)
            },
            {
                label: 'Export',
                onClick: () => this.exportExampleSet(exampleSetObjectID)
            },
            {
                label: 'Delete',
                onClick: () => this.deleteExampleSet(exampleSetObjectID)
            }
        ];
    }

    getMenuOptionsForRequestObject = (requestObject) => {
        let requestObjectID = requestObject[ID]
        return [
            {
                label: 'Delete',
                onClick: () => this.deleteRequestObject(requestObjectID)
            }
        ];
    }

    getItemMenu = (requestObj) => {
        let {readOnly} = this.props
        if (readOnly) {
            return [];
        }
        let items = requestObj[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_EXAMPLE_SET
            ? this.getMenuOptionsForExampleSet(requestObj)
            : this.getMenuOptionsForRequestObject(requestObj)
        return <MenuListComposition items={items} key={requestObj[ID] + '-menu'}
                                    buttonIcon={<MoreVertIcon datatest={'itemMoreOptionsIcon'} color={'secondary'}/>}/>;
    }

    viewCollectionDetails = () => {
        const {collectionDetails} = this.state;
        this.setState({leftMenuTabValue: 0}, () => this.navigateTo({node: {[ID]: collectionDetails[ID]}}))
    }

    getLeftBarHeader = () => {
        const {theme, readOnly, hideMockingSetup} = this.props;
        const {collectionDetails, leftMenuTabValue} = this.state;
        const MenuTabs = withStyles({
            root: {},
            indicator: {},
        })(Tabs);
        const MenuTab = withStyles({
            root: {
                textTransform: 'none',
                '@media (min-width: 600px)': {
                    minWidth: "48px"
                }
            },
            selected: {
                color : theme.palette.secondary.main,
                backgroundColor: theme.palette.white.main,
                borderRadius: '4px 4px'
            }
        })(Tab);

        return <>
            <LeftBarTitle title={collectionDetails ? collectionDetails.title : ''} onClick={this.viewCollectionDetails}/>
            <MenuTabs value={leftMenuTabValue} onChange={(event, newValue) => {
                this.setState({leftMenuTabValue: newValue})
            }} aria-label="request tabs">
                <MenuTab label="Scenarios"/>
                {hideMockingSetup || <MenuTab label="Mock Setup"/>}
            </MenuTabs>
        </>;
    }

    setGlobalsItem = (key, value) => {
        let {globals} = this.state
        let currentValue = globals && globals[key]
        if (value === undefined && currentValue === undefined) {
            return;
        } else {
            if (currentValue !== value && globals) {
                globals[key] = value
                //this.setState({globals})
            }
        }
    }

    getGlobals = () => {
        let {globals} = this.state
        return globals;
    }

    getGlobalsItem = (key) => {
        let {globals} = this.state
        let value = globals[key]
        return value;
    }

    getMockingSetupFromStore = () => {
        let {collectionDetails} = this.state
        let mockingObjects = getCollectionMockingSetupData(collectionDetails)
        return mockingObjects;
    }


    getMockValue = (property) => {
        let mockingObjects = this.getMockingSetupFromStore()
        let mockingObject = mockingObjects && mockingObjects.find(o => o.shapeIRI === property[FROM_SHAPE] && o.propertyIRI === property[ALIAS_SH_PATH])
        if (mockingObject) {
            if (mockingObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_REGEX_MOCKING_SETUP) {
                let regexValue = new RandExp(mockingObject.pattern).gen()
                return restrictMaxLength(regexValue, property);
            } else if (mockingObject[TYPE] === ALIAS_SYS_EXAMPLE_TYPE_VALUES_LIST_MOCKING_SETUP) {
                let lines = mockingObject.lines ? mockingObject.lines.split('\n') : [""]
                let number = getRandomIntFromInterval(0, lines.length - 1)
                return lines[number];
            } else {
                throw new Error('Unknown mocking type : ' + mockingObject[TYPE] + ' Setup object is : ' + JSON.stringify(mockingObject));
            }
        } else {
            let value = getMockValueFromDefaultRegex(property);
            return restrictMaxLength(value, property)
        }

    }

    render() {
        traceRenderStart("", COMPONENT)

        const {minimized, showCreateScenarioDialog, leftMenuMinimized, openViewGlobalsDialog, collectionDetails, configurations, openImportScenarioDialog} = this.state;
        const {hideHeader, theme, location} = this.props;
        let maxWidth = minimized ? '452px' : '675px';
        const leftStyle = {
            padding: '0px',
            width: maxWidth,
            border: 'none',
            backgroundColor: theme.palette.grey.background
        }
        return (
            <GlobalsContext.Provider value={{
                get: this.getGlobals,
                getItem: this.getGlobalsItem,
                setItem: this.setGlobalsItem,
                allConfigurations: configurations
            }}>
                <MockingContext.Provider value={{getMockValue: this.getMockValue}}>
                    <AllPartsLayout
                        header={
                            hideHeader || <APIPlaygroundHeader location={this.props.location}/>
                        }
                        leftStyle={leftStyle}
                        leftComponentScroll={{y: 'hidden', x: 'hidden'}}
                        leftComponentStyle={{padding: '0px', 'height': '100vh'}}
                        leftComponentContainerStyle={{paddingRight: '0px'}}
                        leftComponent={!leftMenuMinimized ? this.getLeftComponent() : undefined}
                        middleComponent={this.getMiddleComponent()}
                        middleStyle={{paddingLeft : '16px', paddingTop: '24px', paddingRight: '8px', paddingBottom: '8px'}}
                        {...this.props}
                    ></AllPartsLayout>
                    {
                        openViewGlobalsDialog === true &&
                        <ViewGlobalsDialog handleClose={() => this.setState({openViewGlobalsDialog: false})}/>
                    }
                    {
                        showCreateScenarioDialog === true
                        && <AddDialog
                            title={'Add New Scenario'}
                            open={showCreateScenarioDialog}
                            handleCancel={() => this.setState({showCreateScenarioDialog: false})}
                            handleOk={(obj) => this.handleCreateScenario(createScenarioObject(obj, collectionDetails))}
                        />
                    }
                    {
                        openImportScenarioDialog === true
                        && <ImportScenarioDialog
                            collectionDetails={collectionDetails}
                            open={openImportScenarioDialog}
                            location={location}
                            onClose={() => this.setState({openImportScenarioDialog: false})}
                            eventHandler={this.handleImportScenarios}
                        />

                    }
                </MockingContext.Provider>
            </GlobalsContext.Provider>
        );
    }
}

Examples.propTypes = {
    hideHeader: PropTypes.bool,
    readOnly: PropTypes.bool,
    headerTitle: PropTypes.string,
    hideMenuActions: PropTypes.bool,
    hideMockingSetup: PropTypes.bool,
    collection: PropTypes.object,
    treeData: PropTypes.array,
    mockingObjects: PropTypes.array,
    focusNode: PropTypes.object,
    mockTreeStyle: PropTypes.object,
    scenariosTreeStyle: PropTypes.object,
    expanded: PropTypes.array,
    onTreeNodeToggle: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(Examples);

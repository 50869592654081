import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PropTypes from "prop-types";
import {Button, Grid, IconButton, Paper, Tooltip, Typography, withStyles} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import logoBG from "../../images/EasyGraphLogoCrop.png";
import {
    COOKIE_ACCOUNT_TOKEN,
    COOKIE_FIRSTNAME,
    COOKIE_IMAGE_URL,
    COOKIE_LASTNAME,
    COOKIE_MANAGEMENT_TOKEN,
    ROUTE_ACCOUNT_FORGOTTEN_PASSWORD,
    ROUTE_ACCOUNT_FORGOTTEN_USERNAME,
    ROUTE_ACCOUNT_HOME,
    ROUTE_ACCOUNT_LOGIN,
    ROUTE_ACCOUNT_SIGNUP, ROUTE_MANAGEMENT_ADMIN_LOGIN,
    ROUTE_MANAGEMENT_LOGIN,
    STYLE_MAIN_SPACING,
    VALIDATION_FEEDBACK_FILE_MAX_SIZE,
    VALIDATION_FEEDBACK_MAX_LENGTH
} from "../../Constants";
import H2Title from "../H2Title";
import {
    getSSOEndpoint,
    isAuthenticationDisabled,
    isMangedInstance,
    isSSOEnabled,
    loginWithLinkedInPage
} from "../../Configs";
import {
    centerVertically,
    getCookie,
    getMainBarRouteAndInstanceInfo,
    getManagementHomeLink,
    getMaxFeedbackFileSize,
    getMaxLengthMessage,
    getRouteWithInstance, getSettingsFromContext,
    hasAnyDatasetLevelAuthenticationDisabled,
    isManagementLogin,
    restrictMaximumCharacters,
    setCookie
} from "../util";
import Avatar from "@material-ui/core/Avatar";
import Popover from '@material-ui/core/Popover';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import TextField from "../../components/TextField";
import Dropzone from "react-dropzone";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ErrorMessage from "../../components/ErrorMessage";
import {submitFeedback} from "../../service/graph-api";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from "@material-ui/core/CircularProgress";
import green from "@material-ui/core/colors/green";
import {clear, getUserIri, getUsername, isSSOUser, isSuperadmin} from "../../layouts/common/Profile";
import GlobalsContext from "../GlobalsContext";
import {getAppBarButtonColor} from "../../layouts/navigator/BasicSetup";
import {FileCopyOutlined} from "@material-ui/icons";

const useStyles = theme => ({
    menuButton: {
        marginRight: theme.spacing(2),
    },
    gutters : {
        padding: theme.spacing(0, STYLE_MAIN_SPACING)
    },
    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

});

export async function logoutFromAccount() {
    clear();
    setCookie(COOKIE_ACCOUNT_TOKEN, "")
    setCookie(COOKIE_FIRSTNAME, "")
    setCookie(COOKIE_LASTNAME, "")
    setCookie(COOKIE_IMAGE_URL, "")
    window.location = ROUTE_ACCOUNT_LOGIN;
}

async function logoutFromManagement(tokenCookie, route) {
    //get the value before clearing
    const jwtToken = getCookie(COOKIE_MANAGEMENT_TOKEN);
    setCookie(COOKIE_MANAGEMENT_TOKEN, "");
    if(isSSOEnabled()) {
        //If non SSO user then just go to admin-login as non SSO users are admin users
        if(isSSOUser(jwtToken)) {
            window.location = getSSOEndpoint()+ '/logout';
        } else {
            window.location = getRouteWithInstance(ROUTE_MANAGEMENT_ADMIN_LOGIN);
        }
    } else {
        window.location = getRouteWithInstance(ROUTE_MANAGEMENT_LOGIN);
    }
}

export function getUsernameToShow(isAccountProfile, maxLength=10) {
    let nameToShow = '';
    if(isAccountProfile) {
        let firstName = getCookie(COOKIE_FIRSTNAME);
        let lastName = getCookie(COOKIE_LASTNAME);
        let fullName = firstName + " " + lastName;
        nameToShow = fullName;
    } else {
        nameToShow = getUsername();
    }
    if(nameToShow.length > maxLength) {
        return nameToShow.substring(0, maxLength)+"...";
    } else {
        return nameToShow;
    }
}



class MainAppBar extends React.Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {
            popupOpen: false,
            feedbackError : false,
            selectedFilesError : false,
            feedback: ''
        }
    }

    sendFeedback = () => {
        let {feedback , selectedFiles} = this.state;
        this.setState({loading: true});
        const formData = new FormData();
        if(selectedFiles && selectedFiles.length > 0) {
            formData.append('file', selectedFiles[0]);
        }
        formData.append('feedback', feedback);
        submitFeedback(formData).then((r) => {
            if(r.status === 200) {
                this.setState({
                    feedbackSuccess : true,
                    feedback : '',
                    selectedFiles : [],
                    loading: false
                });
            } else {
                this.setState({
                    feedbackSuccess : false,
                    loading: false
                });
            }
        })
    }

    onFilesSelect = (selectedFiles) => {
        this.setState({
            selectedFilesError : selectedFiles.find(f => f.size > VALIDATION_FEEDBACK_FILE_MAX_SIZE)
                ? `File size should be less than ${getMaxFeedbackFileSize()}`
                : undefined,
            selectedFiles: selectedFiles
        });
    };

    isFeedbackInValid = () => {
        let {feedback, feedbackError, selectedFilesError} = this.state;
        return !feedback || feedbackError || selectedFilesError;
    }

    getMessage = () => {
        let {theme} = this.props;
        let {feedbackSuccess} = this.state;
        return <>
        {
            feedbackSuccess !== undefined &&
            <div style={{display: 'flex', marginBottom : '8px'}}>
                {
                    feedbackSuccess === true
                        ? <><CheckCircleIcon fontSize="large" style={{color: theme.palette.success.main}}/> { centerVertically(<Typography variant="body2">Feedback sent.</Typography>) }</>
                        : <><ErrorIcon fontSize="large" style={{color: theme.palette.error.main}}/> {centerVertically(<Typography variant="body2">Failed to send feedback, please try again later.</Typography>)}</>
                }
            </div>
        }
        </>;
    }


    getLoginButton = () => {
        let {loading, feedback, feedbackError, popupOpen, anchorPopup, selectedFiles, selectedFilesError} = this.state
        let {theme, classes, isAccountProfile, userPopperContent} = this.props;
        let colorStyle = {color: theme.palette.grey.level3};
        const settingsFromContext = getSettingsFromContext(this);
        let buttonStyle = getAppBarButtonColor(settingsFromContext)
            ? {color : getAppBarButtonColor(settingsFromContext)}
            : {};


        if(isAccountProfile && getCookie(COOKIE_ACCOUNT_TOKEN) || getCookie(COOKIE_MANAGEMENT_TOKEN)) {
                let usernameToShow = getUsernameToShow(isAccountProfile);
                let fullUsername = getUsernameToShow(isAccountProfile, 1000);
                let username = usernameToShow === fullUsername ? fullUsername : <Tooltip title={fullUsername}><div>{usernameToShow}</div></Tooltip>;
                return <div>
                    <Button
                        style={buttonStyle}
                        datatest={'buttonAccountProfile'}
                        color="inherit"
                        onClick={(ev) => this.setState({feedbackSuccess : undefined, popupOpen: true, anchorPopup: ev.currentTarget})}
                        endIcon={
                            !isAccountProfile || getCookie(COOKIE_IMAGE_URL) === 'null' || !getCookie(COOKIE_IMAGE_URL)
                                ? <Avatar style={{border: '2px solid rgba(51, 122, 183, .8)'}}><AccountCircleIcon/></Avatar>
                                : <Avatar style={{border: '2px solid rgba(51, 122, 183, .8)'}} src={getCookie(COOKIE_IMAGE_URL)}></Avatar>
                        }
                    >
                        {username}
                    </Button>
                    <Popover
                        id={'logout'}
                        open={popupOpen}
                        anchorEl={anchorPopup}
                        onClose={() => this.setState({popupOpen: false, anchorPopup: null}) }
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <Paper  style={{width: '400px'}}>
                            {
                                isAccountProfile &&
                                <div datatest={'feedback'} style={{
                                    padding: theme.spacing(2),
                                    borderBottom: '1px solid',
                                    borderColor: theme.palette.grey.level2
                                }}>
                                    {this.getMessage()}
                                    <TextField
                                        name={'feedback'}
                                        label={'Feedback'}
                                        rows={'4'}
                                        multiline={true}
                                        value={feedback}
                                        error={feedbackError}
                                        onChange={(event) => {
                                            let ev = restrictMaximumCharacters(event, VALIDATION_FEEDBACK_MAX_LENGTH);
                                            const {target: {name, value, checked}} = ev;
                                            let errorKey = name + 'Error';
                                            let existingError = this.state[errorKey];
                                            if (!(value.length <= VALIDATION_FEEDBACK_MAX_LENGTH)) {
                                                this.setState({[errorKey]: getMaxLengthMessage(VALIDATION_FEEDBACK_MAX_LENGTH)})
                                            } else if (existingError) {
                                                this.setState({[errorKey]: false})
                                            }
                                            this.setState({feedback: value})
                                        }}
                                    />
                                    {feedbackError === false && <ErrorMessage
                                        error={`${feedback.length} of ${VALIDATION_FEEDBACK_MAX_LENGTH} characters.`}
                                        style={colorStyle}/>}

                                    <div style={{display: 'flex', marginTop: '8px'}}>
                                        <div style={{flexGrow: '1', marginRight: '8px'}}>
                                            <Dropzone multiple={false} accept={"image/*"} onDrop={this.onFilesSelect}>
                                                {({getRootProps, getInputProps}) => (
                                                    <section>
                                                        <div {...getRootProps()}>
                                                            <input {...getInputProps()} />
                                                            <IconButton size={'small'}><AttachFileIcon/></IconButton>
                                                            {selectedFiles && selectedFiles.map((f, i) => <Typography
                                                                key={i} component={'span'}
                                                                variant="body2">{f.name}</Typography>)}
                                                        </div>
                                                    </section>
                                                )}
                                            </Dropzone>

                                        </div>
                                        {
                                            centerVertically(
                                                <Button
                                                    disabled={loading || this.isFeedbackInValid()}
                                                    variant={'contained'}
                                                    color={'secondary'}
                                                    onClick={this.sendFeedback}
                                                >Send{loading &&
                                                <CircularProgress size={24} className={classes.buttonProgress}/>}</Button>
                                            )
                                        }
                                    </div>
                                    {selectedFilesError && <ErrorMessage error={selectedFilesError}/>}
                                </div>
                            }

                            { isSSOEnabled() && <div style={{display : "flex", padding : theme.spacing(2)}}>
                                <Tooltip title={'Copy to clipboard'}>
                                    <IconButton size={'small'} onClick={() => {navigator.clipboard.writeText(getUserIri() || '')}}>
                                        <FileCopyOutlined fontSize={'small'}/>
                                    </IconButton>
                                </Tooltip>
                                <input style={{
                                    border: 'none',
                                    backgroundColor: 'unset',
                                    width: '100%',
                                    outline: 'none',
                                    webkitAppearance: 'none'
                                }} readOnly={true} value={getUserIri()}></input>
                            </div>
                            }
                            {userPopperContent && userPopperContent()}
                            <div style={{textAlign : "center", padding: theme.spacing(2)}}>
                                <Button
                                    size={"large"}
                                    color={"secondary"}
                                    variant={"outlined"}
                                    onClick={() => {
                                        clear();
                                        return isAccountProfile
                                            ? logoutFromAccount()
                                            : logoutFromManagement();
                                    }}
                                >LOG OUT</Button>
                            </div>
                        </Paper>
                    </Popover>
                </div>;
            } else {
                if(isAccountProfile === true) {
                    if (window.location.href !== loginWithLinkedInPage()) {
                        window.location = ROUTE_ACCOUNT_LOGIN;
                        return <></>;
                        //return <Button color="inherit" onClick={() => window.location = ROUTE_LOGIN}>Login</Button>;
                    }
                } else {
                    if (!isManagementLogin() && !isAuthenticationDisabled() && !hasAnyDatasetLevelAuthenticationDisabled()) {
                        //window.location = getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_LOGIN);
                        return <></>;
                        //return <Button color="inherit" onClick={() => window.location = ROUTE_LOGIN}>Login</Button>;
                    } else {
                        return <></>;
                    }

                }
            }
    }

    getHomeLink = () => {
        let {isAccountProfile, homeLink} = this.props;
        if(homeLink) {
            return homeLink;
        }
        if(isMangedInstance() && (isAccountProfile || isManagementLogin())) {
            let href = window.location.href;
            const loginRoutes = [ROUTE_ACCOUNT_LOGIN, ROUTE_ACCOUNT_FORGOTTEN_PASSWORD, ROUTE_ACCOUNT_FORGOTTEN_USERNAME, ROUTE_ACCOUNT_SIGNUP];
            if(loginRoutes.find(r => href.includes(r))) {
                return ROUTE_ACCOUNT_LOGIN;
            } else {
                return ROUTE_ACCOUNT_HOME;
            }
        } else if (!isMangedInstance() && isManagementLogin()) {
            return getRouteWithInstance(ROUTE_MANAGEMENT_LOGIN);
        } else {
            return getManagementHomeLink();
        }
    }

    render() {
        let {classes, appBarBackgroundColor, expandRightToCenterSpace, disableProfile, logo, title, titleStyle, toolbarStyle, rightButtonsRenderer, logoSectionRenderer} = this.props;
        const settingsFromContext = getSettingsFromContext(this);
        let appBarStyle = settingsFromContext
            ? {backgroundColor : (appBarBackgroundColor ? appBarBackgroundColor :  settingsFromContext['banner']?.['appBarBackgroundColor'])}
            : {};
        return (
            <div className={classes.grow}>
                <AppBar style={appBarStyle} elevation={0} position="static">
                    <Toolbar style={toolbarStyle || {}} className={classes.gutters} >
                        <Grid container alignItems={"center"}>
                            <Grid item xs={4} style={{textAlign: 'left'}}>
                                {
                                    logoSectionRenderer
                                        ? logoSectionRenderer()
                                        : <NavLink className={classes.menuButton} to={this.getHomeLink()}>
                                            <img datatest={'egLogoImageLink'} src={logo ? logo : logoBG} alt="Logo"
                                                 style={{height: '33px'}}/>
                                        </NavLink>
                                }
                            </Grid>
                            {
                                expandRightToCenterSpace ||
                                <Grid item xs={4} style={{textAlign: 'center'}}>
                                    <div>
                                        {title &&
                                        <H2Title style={{textAlign: 'center', ...titleStyle}} color={"inherit"}
                                                 title={title}/>}
                                        <div
                                            style={{textAlign: 'center', ...titleStyle}}>{getMainBarRouteAndInstanceInfo()}</div>
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={expandRightToCenterSpace ? 8 : 4 } style={{textAlign: 'right', overflow : 'auto'}}>
                                <div style={ rightButtonsRenderer ? {display : 'flex'} : {}}>
                                    {rightButtonsRenderer && rightButtonsRenderer()}
                                    {disableProfile || this.getLoginButton()}
                                </div>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

MainAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
    logo: PropTypes.object,
    homeLink: PropTypes.string,
    title: PropTypes.string,
    titleStyle: PropTypes.object,
    toolbarStyle: PropTypes.object,
    appBarBackgroundColor: PropTypes.string,
    leftTitle: PropTypes.string,
    rightTitle: PropTypes.string,
    disableProfile: PropTypes.bool,
    rightButtonsRenderer: PropTypes.func,
    logoSectionRenderer: PropTypes.func,
    userPopperContent: PropTypes.func,
    isAccountProfile: PropTypes.bool,
    expandRightToCenterSpace: PropTypes.bool,

};

export default withStyles(useStyles, {withTheme: true})(MainAppBar);

import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import PropTypes from "prop-types";
import {centerVertically, getLocalName, isEmptyArray, toArray} from "../../components/util";
import {Avatar, IconButton, Tooltip, Typography} from "@material-ui/core";
import H3Title from "../../components/H3Title";
import {VALUE} from "../../Constants";
import Chip from "@material-ui/core/Chip";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import {DeleteOutlined, LinkOffOutlined, PageviewOutlined} from "@material-ui/icons";
import {withEvent} from "./Event";
import {DEFAULT_ROW_HEIGHT} from "./DataGridView";
import {isString} from "lodash";

export function renderResourceDetails(resourceId, theme, vo, url, hasFailedToLoad, onLinkOff, onDelete) {
    return <div key={resourceId} style={{
        marginBottom: '8px',
        padding: '8px',
        borderRadius: '4px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.grey.level2,
        backgroundColor: theme.palette.white.main
    }}>
        {url &&
            <div style={{display: 'flex', marginBottom: '8px'}}>
                <div style={{flexGrow: '1'}}></div>
                {
                    centerVertically(
                        <a style={{cursor: 'pointer'}} datatest={'link'}
                           href={url} target={"_blank"}><IconButton style={{
                            marginRight: '8px',
                            color: theme.palette.link.main
                        }} size={'small'}>{
                            centerVertically(hasFailedToLoad ?
                                <OpenInNewOutlinedIcon/>
                                : <PageviewOutlined
                                />)
                        }</IconButton>
                        </a>)
                }
                {onLinkOff && <IconButton datatest={'removeLinkButton'} onClick={() => onLinkOff(resourceId)} style={{marginRight: '8px'}}
                                          size={'small'}><LinkOffOutlined></LinkOffOutlined></IconButton>}
                {onDelete && <IconButton datatest={'deleteResourceButton'} onClick={() => onDelete(resourceId)} style={{marginRight: '8px'}}
                                         size={'small'}><DeleteOutlined></DeleteOutlined></IconButton>}
            </div>
        }
        <div style={{display : 'flex'}}>
        {
            vo.thumbnail &&
            centerVertically(
                <Avatar
                    alt={vo.title}
                    src={vo.thumbnail}
                    style={{width: theme.spacing(7), height: theme.spacing(7)}}
                />,
                {marginRight: '8px'}
            )

        }
        {
            centerVertically(
                <div style={{width: '100%'}}>
                    {vo.title && <H3Title title={vo.title} noWrap={true}></H3Title>}
                    {
                        <>
                            <Typography color={'primary'} style={{marginTop: vo.title ? '8px' : '0px'}}
                                        noWrap={true} variant="body2"
                                        component="div">{getLocalName(resourceId, true, 8) || ' '}</Typography>
                        </>
                    }
                </div>,
                {flexGrow: 1, maxWidth: `calc(100% - ${(vo.thumbnail ? '64' : '0')}px)`}
            )
        }
        </div>
    </div>;
}

export function getStyledTooltip(theme) {
    return  withStyles({
        arrow: {
            color: theme.palette.secondary.main
        },
        tooltip: {
            backgroundColor: theme.palette.grey.background,
            boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.4), 0px 2px 2px 0px rgba(0,0,0,0.34), 0px 1px 5px 0px rgba(0,0,0,0.32)',
            minWidth: '300px'
        }
    })(Tooltip);
}



class ObjectSummaryTooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open : props.isCellSelected
        }
    }

    componentDidMount() {
    }

    render() {
        let {imageURL, linkProperty, enableCellTooltip, objectIris, theme, onLinkOff, onDelete} = this.props;
        let {open} = this.state;
        let valueArray = toArray(objectIris);

        if(isEmptyArray(valueArray)) {
            return <div datatest={'objectType'} style={{width : '100%', display : 'flex'}}/>;
        }

        let hasCount = valueArray.length > 1;
        let imageBlockSize = DEFAULT_ROW_HEIGHT - 4;
        let inputMaxWidthDeduct =  hasCount ? 40 : 0;
        inputMaxWidthDeduct = imageURL ? inputMaxWidthDeduct + imageBlockSize : inputMaxWidthDeduct;
        let content = <div datatest={'objectType'} style={{width : '100%', display : 'flex'}}>
            {
                imageURL &&
                    centerVertically(
                    <div
                        datatest={'imagePreview'}
                        style={{
                            borderRadius : '4px',
                            marginRight : '4px',
                            backgroundSize: '100%',
                            display: 'inline-block',
                            blockSize: imageBlockSize+'px',
                            inlineSize: imageBlockSize+'px',
                            verticalAlign: 'middle',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${imageURL})`
                        }}
                    />
                    )
            }
            {centerVertically(<input readOnly={true} value={isString(valueArray[0]) ? valueArray[0] : valueArray[0][VALUE] } style={{
                border: 'none',
                backgroundColor: 'unset',
                width: '100%',
                outline: 'none',
                webkitAppearance: 'none'
            }}></input>, {maxWidth : `calc(100% - ${inputMaxWidthDeduct}px)`, flexGrow : '1'})}


            {
                hasCount ?
                centerVertically(<div style={{marginLeft : '8px'}}><Chip datatest={'countChip'} variant={'outlined'} color={'secondary'} size="small"
                           label={valueArray.length > 99 ? '+99' : valueArray.length}/></div>)
                    : <></>
            }

        </div>;

        //console.log("render", isCellSelected, linkProperty)
        return content;

    }
}

ObjectSummaryTooltip.propTypes = {
    location : PropTypes.any,
    isCellSelected : PropTypes.any,
    enableCellTooltip : PropTypes.any,
    objectIris: PropTypes.any,
    settings: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    ontology: PropTypes.any,
    theme: PropTypes.any,
    onLinkOff: PropTypes.any,
    onDelete: PropTypes.any,
    imageURL: PropTypes.bool,

}

export default withStyles(styles, {withTheme:true})(withEvent(ObjectSummaryTooltip));

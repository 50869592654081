import React from 'react';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import {Typography, withStyles} from "@material-ui/core";
import {styles} from "./styles";

function TabPanel(props) {
    const {classes, children, value, lazy, index, boxStyle, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {lazy && lazy === true ? (value === index && <Box style={boxStyle}>{children}</Box>) : <Box style={boxStyle}>{children}</Box>}
        </Typography>
    );
}

TabPanel.propTypes = {
    lazy : PropTypes.bool,
    value : PropTypes.number,
    index : PropTypes.number,
    boxStyle : PropTypes.object,
};

export default withStyles(styles)(TabPanel);
import React from 'react';
import PropTypes from "prop-types";
import {Button as ButtonInternal, withStyles} from "@material-ui/core";
import {STYLE_GRID_ITEM_SPACING} from "../Constants";
import DoneIcon from "@material-ui/icons/DoneOutlined";

const styles = () => ({
});

const StyledButton = withStyles(theme => ({
  root: {
    color: theme.palette.white.main,
    backgroundColor: theme.palette.success.dark,
    marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING),
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(ButtonInternal);

let FinishButton = props => {
  const {endIcon, onClick, disabled, ...other } = props;

  return <StyledButton
      id={'finishButton'}
      size={"medium"}
      endIcon={ <DoneIcon/>}
      datatest={'finishButton'}
      {...other}
      disabled={disabled}
      variant="contained"
      color={'primary'}
      onClick={onClick}
      endIcon={endIcon}
  >Finish</StyledButton>;
}

FinishButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(FinishButton);

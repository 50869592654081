import AllPartsLayout from "../AllPartsLayout";
import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {getNavigateToConceptLink, getNavigateToOntologyLink, ResourceView} from "./LeftTreeComponents";
import queryString from "query-string";
import {NavLink, withRouter} from "react-router-dom";
import {getLabelProperties, getResourceUrl, getValuesObject} from "./SearchResultItem";
import {TYPE_OWL_CLASS, TYPE_OWL_ONTOLOGY, TYPE_RDFS_CLASS} from "../../Constants";
import Typography from "@material-ui/core/Typography";
import {loadResource} from "../../service/data-loader";
import {
    getBrowseLanguageCode,
    getResourceId,
    isAnnotationProperty,
    isDatatypeProperty,
    isObjectProperty,
    isOwlClass,
    isRdfsClass,
    scrollToView,
    sort,
    toArray
} from "../../components/util";
import {Grid, makeStyles, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import throttle from 'lodash/throttle';
import history from "../../history";
import {
    filterExpansionPath,
    getSubClasses,
    getSubProperties,
    getTopLevelAnnotationProperties,
    getTopLevelDataProperties,
    getTopLevelObjectProperties,
    getTopLevelOntologyClasses,
    searchOntologyAnnotationProperties,
    searchOntologyClasses,
    searchOntologyDataProperties,
    searchOntologyObjectProperties,
    TYPE_OWL_ANNOTATION_PROPERTY,
    TYPE_OWL_DATATYPE_PROPERTY,
    TYPE_OWL_OBJECT_PROPERTY
} from "../../service/sparql-queries";
import {LABEL_PROPERTY_FOR_SEARCH} from "./DataViewSetup";
import uuid4 from "uuid/v4";
import {styles} from "../../components/styles";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {getTypeIRIs, TreeHeader} from "./TaxonomyView";
import {isOntologyViewPath} from "./Workspace";
import {findNodeInTreeData, LabelTree} from "./LabelTree";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export function getAlias(aliasesMap , iri) {
    return aliasesMap[iri] || iri;
}


const Search = ({ontologyId,
                    aliasesToIRIMap,
                    location,
                    settings,
                    ontology,
                    onSelect,
                    aliasesMap,
                    browseLanguage,
                    treeTab}) => {
    const classes = useStyles();

    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);



    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                let browseLanguageCode = getBrowseLanguageCode(browseLanguage);
                let classIRIs = [];
                if (isClassView(treeTab)) {
                    classIRIs = [TYPE_OWL_CLASS, TYPE_RDFS_CLASS]
                } else if (isObjectPropertyTab(treeTab)) {
                    classIRIs = [TYPE_OWL_OBJECT_PROPERTY];
                } else if (isDataPropertyTab(treeTab)) {
                    classIRIs = [TYPE_OWL_DATATYPE_PROPERTY];
                } else if (isAnnotationPropertyTab(treeTab)) {
                    classIRIs = [TYPE_OWL_ANNOTATION_PROPERTY];
                }

                let labelProperties = getLabelProperties(settings, classIRIs, LABEL_PROPERTY_FOR_SEARCH);
                let searchProperties = toArray(labelProperties?.filters).map(f  => {
                    let {property} = f;
                    return property.tooltip;
                });

                let searchFunction;
                if (isClassView(treeTab)) {
                    searchFunction = searchOntologyClasses
                } else if (isObjectPropertyTab(treeTab)) {
                    searchFunction = searchOntologyObjectProperties;
                } else if (isDataPropertyTab(treeTab)) {
                    searchFunction = searchOntologyDataProperties;
                } else if (isAnnotationPropertyTab(treeTab)) {
                    searchFunction = searchOntologyAnnotationProperties;
                }

                searchFunction(ontologyId, request.input, searchProperties).then(ids => {
                    loadResource(ids).then(resources => {
                        Promise.all(resources.map(async r => {
                            let valuesObject = await getValuesObject(r, settings, aliasesToIRIMap, browseLanguage, ontology);
                            if(valuesObject.title === undefined) {
                                valuesObject.title = getResourceId(r);
                            }
                            valuesObject.backingObject = r;
                            return valuesObject;
                        })).then(r => {
                            callback(r);
                        })

                    })
                })
            }, 200),
        [treeTab],
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            if(value) {
                setOptions(value ? [value] : []);
            } else {
                fetch({ input: inputValue }, (results) => {
                    if (active) {
                       setOptions(results);
                    }
                });
            }
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                setOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch, treeTab]);

    return (
        <Autocomplete
            datatest={'ontologySearch'}
            id="free-solo-demo"
            freeSolo
            value={value}
            style={{ marginRight: 16 }}
            options={options}
            filterOptions={(x) => x}

            getOptionLabel={(option) => (typeof option === 'string' ? option : option.title)}
            onChange={(event, newValue, reason) => {
                if (reason === 'select-option') {
                    onSelect(newValue.backingObject);

                } else {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                }
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}

            renderInput={(params) => (
                <TextField {...params} label="Search" margin="normal" variant="outlined" />
            )}
            renderOption={(option) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item xs>
                            <Typography variant="h3" color="textSecondary">
                                {option.title || getResourceId(option)}
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}

export const CLASS_VIEW = "CLASS_VIEW";
export const OBJECT_VIEW = "OBJECT_VIEW";
export const DATATYPE_VIEW = "DATATYPE_VIEW";
export const ANNOTATION_VIEW = "ANNOTATION_VIEW";

function isClassView(treeTab) {
    return treeTab === CLASS_VIEW;
}

function isObjectPropertyTab(treeTab) {
    return treeTab === OBJECT_VIEW;
}

function isDataPropertyTab(treeTab) {
    return treeTab === DATATYPE_VIEW;
}

function isAnnotationPropertyTab(treeTab) {
    return treeTab === ANNOTATION_VIEW;
}

export const StyledButton = withStyles((theme) => ({
    root : {
        '&:hover': {
            backgroundColor : theme.palette.white.main
        }
    },
    label : {
        textTransform : 'none'
    }
}))(Button);


class OntologyViewInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData : [],
            resource : undefined,
            selectedNodeId : undefined,
            expandedNodeIds : []
        }
        //Below handles browser back/forward button
        this.backListener = this.props.history.listen( (location, action) => {
            //Here we deal with taxonomy path only everything else is handled in other components
            if(isOntologyViewPath(location)) {
                const {ontologyResource, treeData} = this.state;
                let params = queryString.parse(location.search);
                let currentlyLoadedOntologyId = ontologyResource ? getResourceId(ontologyResource) : this.getOntologyId();
                let newOntologyId = params['ontologyId'];
                let newResourceId = params['resourceId'];
                //Each action in taxonomy browser push to history, so we handle all conditions below
                // Browser forward and back button generates POP but handling is same
                //A page refresh is handled by first call to componentDidMount
                //If no change in taxonomy do not reload just select the resource
                if (currentlyLoadedOntologyId === newOntologyId) {
                    let currentResource = this.state.resource;
                    let {treeTab} = this.state;
                    let {aliasesToIRIMap} = this.props;
                    loadResource(newResourceId).then((newResources) => {
                        let newResource = newResources.find(r => getResourceId(r) === newResourceId);
                        const typeIRIs = new Set(newResource ? getTypeIRIs(newResource, aliasesToIRIMap) : []);
                        const currentTypeIRIs = new Set(currentResource ? getTypeIRIs(currentResource, aliasesToIRIMap) : []);
                        const hasType = [...currentTypeIRIs].find(ti => typeIRIs.has(ti));
                        const hasOntologyTypeInCurrent = [...currentTypeIRIs].find(ti => ti === TYPE_OWL_ONTOLOGY);
                        const hasClassInNew = [...typeIRIs].find(ti => [TYPE_OWL_CLASS, TYPE_RDFS_CLASS].includes(ti)) ;
                        const newTreeTabValue = this.getTreeTabValue(newResource, true);
                        const newTreeTabIsSameAsCurrent = treeTab === newTreeTabValue;

                        if(newTreeTabIsSameAsCurrent || (hasOntologyTypeInCurrent && hasClassInNew) || hasType) {
                            this.loadAndSelectResource(ontologyResource, newResourceId).catch(() => {});
                        } else {
                            const treeTabValue = this.getTreeTabValue(newResource, true);
                            this.setState({treeTab : treeTabValue, treeData : [], treeLoading: true,}, () => {
                                this.loadAndSelectResource(ontologyResource, newResourceId).catch(() => {});
                            })
                        }
                    })
                } else {
                    this.loadOntology(newOntologyId, newResourceId);
                }
            }
        })
    }

    componentDidMount() {
        this.setState({treeLoading : true, treeData : [] });
        let ontologyId = this.getOntologyId();
        let resourceId = this.getResourceId();
        this.setState({treeLoading: true, treeData : []});
        this.loadOntology(ontologyId, resourceId);
    }

    loadOntology = (ontologyId, resourceIdParam) => {
        let {settings, aliasesMap, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        loadResource(ontologyId).then(async (resources) => {
            let ontologyResource = resources.find(r => r && getResourceId(r) === ontologyId);
            if(ontologyResource) {
                let ontologyResourceVO = await getValuesObject(ontologyResource, settings, aliasesToIRIMap, browseLanguage, ontology);

                this.setState({
                    ontologyResource: ontologyResource,
                    ontologyResourceId: getResourceId(ontologyResourceVO),
                    conceptSchemeTitle: ontologyResourceVO.title,
                    ontologyTitle : ontologyResourceVO.title,
                    ontologyThumbnail : ontologyResourceVO.thumbnail,
                    treeData : [],
                    treeLoading: true,
                    resource : undefined
                }, async () => {
                    await this.loadAndSelectResource(ontologyResource, resourceIdParam);
                })
            }
        })
    }

    loadAndSelectResource = async (ontology, resourceId) => {
        //By default, set the middle resource to ontology
        let resource = ontology;
        if (resourceId) {
            let concepts = await loadResource(resourceId);
            resource = concepts.find(r => r && getResourceId(r) === resourceId);
        }
        let {treeData} = this.state;
        let currentResource = this.state.resource;
        //Load relevant tree data only if needed
        const newTreeTabValue = this.getTreeTabValue(resource);
        const currentTreeTabValue = this.getTreeTabValue(currentResource);
        if(newTreeTabValue !== currentTreeTabValue || toArray(treeData).length < 1){
            treeData = await this.getTopLevelTreeData(newTreeTabValue);
        }
        this.setState(
            {
                treeData: treeData,
                resource: resource,
                treeLoading: false
            },
            () => {
                if (resource) {
                    const nodeElement2 = document.getElementById('middleContentId');
                    nodeElement2 && scrollToView(nodeElement2, 'instant');
                    this.handleResourceSelect(treeData, resource);
                }
            }
        )
    }

    handleResourceSelect = async (treeData, newResourceToSelect) => {
        let {selectedNodeId, expandedNodeIds} = this.state;
        const foundNode = findNodeInTreeData(treeData, newResourceToSelect, selectedNodeId, expandedNodeIds);
        if(foundNode) {
            return;
        }
        let path = await this.findExpansionPath(newResourceToSelect);
        let treeDataToUse = treeData;
        let node = undefined;
        let leafNode;
        let newExpandedNodeIds = [];
        if (path.length > 0) {
            for (let i = 0; i < path.length; i++) {
                let p = path[i];
                node = treeDataToUse.find(td => getResourceId(td) === p);
                if (node.children === undefined) {
                    let narrowerIds = toArray(await this.getNarrowerIds(node.backingObject));
                    node.children = await this.getValueObjectsForTree(narrowerIds);
                }
                newExpandedNodeIds.push(node.nodeId);
                treeDataToUse = node.children;
            }
            leafNode = node.children.find(n => getResourceId(n) === getResourceId(newResourceToSelect));
        } else {
            leafNode = treeDataToUse.find(td => getResourceId(td) === getResourceId(newResourceToSelect));
        }
        if(!leafNode || selectedNodeId === leafNode.nodeId) {
            return;
        }
        let allExpandedNodeIdsSet = new Set([...expandedNodeIds, ...newExpandedNodeIds]);
        this.setState({resource: newResourceToSelect, expandedNodeIds: [...allExpandedNodeIdsSet], selectedNodeId : leafNode.nodeId}, () => {
            const nodeElement = document.getElementById(leafNode.nodeId);
            nodeElement && scrollToView(nodeElement, 'instant');
        })
    }

    getTreeTabValue = (resource, ignoreState=false) => {
        let {aliasesToIRIMap} = this.props;
        let treeTabFromState = this.state.treeTab
        if(treeTabFromState && ignoreState === false) {
            return treeTabFromState;
        }
        let treeTab = CLASS_VIEW;
        if(resource) {
            if (isObjectProperty(resource)) {
                treeTab = OBJECT_VIEW;
            } else if (isDatatypeProperty(resource)) {
                treeTab = DATATYPE_VIEW
            } else if (isAnnotationProperty(resource)) {
                treeTab = ANNOTATION_VIEW
            }
        }
        return treeTab;
    }

    getTopLevelTreeData = async (treeTab) => {
         let ontologyId = this.getOntologyId();
         let topResourceIds;
         if(isClassView(treeTab)) {
             topResourceIds = await getTopLevelOntologyClasses(ontologyId);
         } else if (isObjectPropertyTab(treeTab)) {
             topResourceIds = await getTopLevelObjectProperties(ontologyId);
         } else if (isDataPropertyTab(treeTab)) {
             topResourceIds = await getTopLevelDataProperties(ontologyId);
         } else if (isAnnotationPropertyTab(treeTab)) {
             topResourceIds = await getTopLevelAnnotationProperties(ontologyId);
         }
        let vos = await this.getValueObjectsForTree(topResourceIds);
        return vos;
    }

    onTreeNodeClick = async (node) => {
        let {location} = this.props;
        let ontologyId = this.getOntologyId();
        this.setState({selectedNodeId : node.nodeId}, () => {
            history.push(getNavigateToOntologyLink(ontologyId, getResourceId(node), location, undefined));
        })
    }

    onTreeNodeToggle = async (node) => {
        let {expandedNodeIds} = this.state;
        if(expandedNodeIds.includes(node.nodeId)) {
            const filteredNodes = expandedNodeIds.filter(nid => nid !== node.nodeId);
            this.setState({expandedNodeIds : [...filteredNodes]})
        } else {
            let narrowerIds = toArray(await this.getNarrowerIds(node.backingObject));
            //Set children only if needed
            //if user had expanded and then collapsed, then on expand children are already there so no need to set again
            if(narrowerIds.length > 0 && !node.children) {
                node.children = await this.getValueObjectsForTree(narrowerIds);
            }
            this.setState({expandedNodeIds : [...expandedNodeIds, node.nodeId]})
        }
    }

    getNarrowerIds = async (resource) => {
        let id = getResourceId(resource);
        let narrowerIds = isClassView(this.getTreeTabValue(resource))
            ? await getSubClasses(id)
            : await getSubProperties(id);
        return narrowerIds;
    }

    getValueObjectsForTree = async (resourceIds) => {
        let {settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let resources = await loadResource(resourceIds);
        let vos = resources.filter(r => r).map(async tc => {
            let valueObject = await getValuesObject(tc, settings, aliasesToIRIMap, browseLanguage, ontology);
            let narrowerIds = toArray(await this.getNarrowerIds(tc));
            valueObject.backingObject = tc;
            valueObject.nodeId = uuid4();
            valueObject.tooltip = getResourceId(tc);
            valueObject.childCount = narrowerIds.length;
            return valueObject;
        });
        return sort(await Promise.all(vos));
    }

    getUrlParam = (key) => {
        let {location} = this.props;
        let params = queryString.parse(location.search);
        return params[key];
    }

    getOntologyId = () => {
        return this.getUrlParam('ontologyId');
    }

    getResourceId = () => {
        return this.getUrlParam('resourceId');
    }

    findExpansionPath = async (selectedResource) => {
        let {aliasesMap} = this.props;
        let hierarchyKey = isClassView(this.getTreeTabValue(selectedResource))
            ? 'http://www.w3.org/2000/01/rdf-schema#subClassOf'
            : 'http://www.w3.org/2000/01/rdf-schema#subPropertyOf';
        let hierarchyKeyAlias = getAlias(aliasesMap, hierarchyKey)
        let path = [];
        let broaderArray = toArray(selectedResource[hierarchyKeyAlias]).map(r => getResourceId(r) || r);
        broaderArray = await filterExpansionPath(this.getOntologyId(), broaderArray);
        let broader = broaderArray.find(b => b);
        while (broader) {
            let broaderId = getResourceId(broader) || broader;
            path = [broaderId, ...path];
            let broaderResource = toArray(await loadResource(broaderId)).find(b => b);
            if (broaderResource) {
                let broaderArray = toArray(broaderResource[hierarchyKeyAlias]);
                broaderArray = await filterExpansionPath(this.getOntologyId(), broaderArray);
                broader = broaderArray.find(b => b);
            }
        }
        return path;
    }

    handleTreeViewChange = (event, treeTab) => {
        this.setState({treeLoading: true, treeData: [], treeTab : treeTab}, async () => {
            let vos = await this.getTopLevelTreeData(treeTab);
            this.setState({treeLoading: false, treeData: vos, resource: undefined})
        });
    }

    getViewButtonGroup = () => {
        let {theme} = this.props;
        let {resource, treeTab} = this.state;
        let treeTabValue = this.getTreeTabValue(resource);
        return <div style={{paddingRight : '16px' , paddingTop: '8px', paddingBottom : '4px'}}>
            <ButtonGroup
                fullWidth={true}
                style={{
                     color: theme.palette.primary.main,
                     borderColor : theme.palette.grey.level2
                }}
                size={'small'}
            >
                <StyledButton
                    datatest={CLASS_VIEW}
                    disableRipple={true}
                    disabled={isClassView(treeTabValue)}
                    color={isClassView(treeTabValue) ? 'secondary' : 'primary'}
                    style={{borderColor : isClassView(treeTabValue) && theme.palette.secondary.main}}
                    onClick={(ev) => this.handleTreeViewChange(ev, CLASS_VIEW)}
                >Class</StyledButton>
                <StyledButton
                    datatest={OBJECT_VIEW}
                    disableRipple={false}
                    disabled={isObjectPropertyTab(treeTabValue)}
                    color={isObjectPropertyTab(treeTabValue) ? 'secondary' : 'primary'}
                    style={{borderColor : isObjectPropertyTab(treeTabValue) ? theme.palette.secondary.main : theme.palette.grey.level2,  ...(isClassView(treeTabValue) ? {borderLeftColor : theme.palette.secondary.main} : {}) }}
                    onClick={(ev) => this.handleTreeViewChange(ev, OBJECT_VIEW)}
                >Object</StyledButton>
                <StyledButton
                    datatest={DATATYPE_VIEW}
                    disableRipple={false}
                    disabled={isDataPropertyTab(treeTabValue)}
                    color={isDataPropertyTab(treeTabValue) ? 'secondary' : 'primary'}
                    style={{borderColor : isDataPropertyTab(treeTabValue) ? theme.palette.secondary.main : theme.palette.grey.level2,  ...(isObjectPropertyTab(treeTabValue) ? {borderLeftColor : theme.palette.secondary.main} : {})}}
                    onClick={(ev) => this.handleTreeViewChange(ev, DATATYPE_VIEW)}
                >Datatype</StyledButton>
                <StyledButton
                    datatest={ANNOTATION_VIEW}
                    disableRipple={false}
                    disabled={isAnnotationPropertyTab(treeTabValue)}
                    color={isAnnotationPropertyTab(treeTabValue) ? 'secondary' : 'primary'}
                    style={{borderColor : isAnnotationPropertyTab(treeTabValue) ? theme.palette.secondary.main : theme.palette.grey.level2,  ...(isDataPropertyTab(treeTabValue) ? {borderLeftColor : theme.palette.secondary.main} : {})}}
                    onClick={(ev) => this.handleTreeViewChange(ev, ANNOTATION_VIEW)}
                >Annotation</StyledButton>
            </ButtonGroup>
        </div>;
    }

    getLeftContent = () => {
        let {treeData, treeLoading, selectedNodeId, expandedNodeIds} = this.state;
        return <LabelTree
            treeData={treeData}
            treeLoading={treeLoading}
            onNodeSelect={this.onTreeNodeClick}
            onNodeToggle={this.onTreeNodeToggle}
            expandedNodeIds={expandedNodeIds}
            selectedNodeId={selectedNodeId}
        />;
    }

    middleContent = () => {
        let {theme, location, configurations, graphViewProvider, viewProperties, settings, aliasesMap, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let {resource, ontologyResource} = this.state;
        let resourceToUse = resource?.backingObject || resource || ontologyResource;
        const resourceId = getResourceId(resourceToUse);
        return <div datatest={'middleContent'} key={resourceId}>
            <ResourceView
                resource={resourceToUse}
                theme={theme}
                middleLoading={false}
                viewProperties={viewProperties}
                configurations={configurations}
                ontology={ontology}
                location={location}
                aliasesToIRIMap={aliasesToIRIMap}
                settings={settings}
                aliasesMap={aliasesMap}
                browseLanguage={browseLanguage}
                graphViewProvider={graphViewProvider}
                onConceptClick={(foundResource, details) => {
                    let resourceId = getResourceId(foundResource);
                    if(details.navigateInTaxonomy) {
                        return history.push(getNavigateToConceptLink(details.navigateInTaxonomy, resourceId, location));
                    } else if (details.navigateInOtherTaxonomy) {
                        return history.push(getNavigateToConceptLink(details.navigateInOtherTaxonomy, resourceId, location));
                    } else if (details.navigateInOntology) {
                        if(isOwlClass(foundResource) || isRdfsClass(foundResource) || resourceId === details.navigateInOntology) {
                            return history.push(getNavigateToOntologyLink(details.navigateInOntology, resourceId, location, undefined, CLASS_VIEW));
                        } else if (isObjectProperty(foundResource)){
                            return history.push(getNavigateToOntologyLink(details.navigateInOntology, resourceId, location, undefined, OBJECT_VIEW));
                        } else if (isDatatypeProperty(foundResource)) {
                            return history.push(getNavigateToOntologyLink(details.navigateInOntology, resourceId, location, undefined, DATATYPE_VIEW));
                        } else if (isAnnotationProperty(foundResource)) {
                            return history.push(getNavigateToOntologyLink(details.navigateInOntology, resourceId, location, undefined, ANNOTATION_VIEW));
                        }
                        return history.push(getResourceUrl(location, resourceId));
                    } else if (details.navigateInOtherOntology) {
                        let ontologyId = details.navigateInOntology;
                        if(isOwlClass(foundResource) || isRdfsClass(foundResource)) {
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, CLASS_VIEW));
                        } else if (isObjectProperty(foundResource)){
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, OBJECT_VIEW));
                        } else if (isDatatypeProperty(foundResource)) {
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, DATATYPE_VIEW));
                        } else if (isAnnotationProperty(foundResource)) {
                            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, ANNOTATION_VIEW));
                        }
                        return history.push(getResourceUrl(location, resourceId));
                    } else {
                        return history.push(getResourceUrl(location, resourceId));
                    }
                }}

            />
        </div>;
    }

    render() {
        let {theme, location, headerProvider, settings, aliasesMap, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        let {resource, ontologyTitle, ontologyThumbnail, ontologyResource} = this.state;
        return <React.Fragment key={this.getOntologyId()}><AllPartsLayout
                    resizeable={true}
                    header={headerProvider()}
                    leftComponentScroll={{y: 'hidden', x: 'hidden'}}
                    leftComponentStyle={{paddingTop: '0px'}}
                    leftStyle={{paddingTop: '8px',height : `calc(100% - ${0}px)`}}
                    leftComponentContainerStyle={{paddingRight: '0px'}}
                    middleStyle={{paddingTop: '8px',height : `calc(100% - ${0}px)`, maxWidth : 'calc(100% - 48px)'}}
                    leftMainHeader={<>
                    <NavLink datatest={'ontologyHomeLink'} to={getNavigateToOntologyLink(this.getOntologyId(), undefined, location, undefined, 0)}>
                        {TreeHeader(ontologyThumbnail, ontologyTitle, theme, ontologyResource)}
                    </NavLink>
                        {this.getViewButtonGroup()}
                    <React.Fragment key={this.getTreeTabValue(resource)}>
                        <Search
                            ontologyId={this.getOntologyId()}
                            aliasesMap={aliasesMap}
                            browseLanguage={browseLanguage}
                            settings={settings}
                            ontology={ontology}
                            aliasesToIRIMap={aliasesToIRIMap}
                            location={location}
                            onSelect={(foundResource) => {
                                return history.push(getNavigateToOntologyLink(this.getOntologyId(), getResourceId(foundResource), location, undefined, undefined));
                            }}
                            treeTab={this.getTreeTabValue(resource)}
                        />
                    </React.Fragment>
                    </>}
                    leftComponent={this.getLeftContent()}
                    middleActions={undefined}
                    middleComponent={this.middleContent()}
        /></React.Fragment>;
    }

}

export const OntologyView = withStyles(styles, {withTheme:true})(withRouter(OntologyViewInternal));

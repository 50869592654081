import React from 'react';
import PropTypes from "prop-types";
import {Grid, withStyles} from "@material-ui/core";
import H1Title from "./H1Title";
import LeftIcon from "@material-ui/icons/ChevronLeftOutlined";
import RightIcon from "@material-ui/icons/ChevronRightOutlined";
import IconButton from "@material-ui/core/IconButton";

let MainHeaderBarLeft = props => {
  const { gridStyle, title, actionsColumns, otherItems, actions, theme, showMinimizeButton, onMinimizeButtonToggle, leftMenuMinimized } = props;
  let titleColumns = actionsColumns ? 12 - actionsColumns : 8;
  let actionsColumnsComputed = actionsColumns ? actionsColumns : 4;
  return (<div style={{position:'relative'}}>
      {
          leftMenuMinimized === true ? <></> :
          <Grid container spacing={0} style={gridStyle || {}}>
              <Grid item xs={titleColumns}>
                  <H1Title title={title} color={'primary'}/>
              </Grid>
              <Grid item xs={actionsColumnsComputed} style={{textAlign: 'right', paddingRight: theme.spacing(2)}}>
                  {actions && actions.map((o, i) => <React.Fragment key={i}>{o}</React.Fragment>)}
              </Grid>
            { otherItems && otherItems.map((item, i) => <Grid key={i} item={true} xs={12} style={{paddingRight: theme.spacing(2)}}>{item}</Grid>)}
          </Grid>
      }
      {
          showMinimizeButton &&
          <div style={{borderRadius: '4px', right: '-24px', top: '4px', backgroundColor: "white", position: 'absolute'}}>
              <IconButton style={{backgroundColor: "white"}} size={'small'} onClick={onMinimizeButtonToggle}>{
                  leftMenuMinimized === true ? <RightIcon/> : <LeftIcon/>
              }</IconButton>
          </div>
      }
  </div>);
}

MainHeaderBarLeft.propTypes = {
  title: PropTypes.string.isRequired,
  actions: PropTypes.array,
  actionsColumns: PropTypes.number,
  otherItems: PropTypes.array,
  showMinimizeButton: PropTypes.bool,
  leftMenuMinimized: PropTypes.bool,
  onMinimizeButtonToggle: PropTypes.func,
  gridStyle: PropTypes.object
};

export default withStyles({}, {withTheme: true})(MainHeaderBarLeft);

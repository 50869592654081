import uuid4 from "uuid/v4";
import {
    ALIAS_OWL_CLASS,
    ALIAS_OWL_INVERSE_OF,
    ALIAS_RDFS_SUBCLASS_OF,
    ALIAS_SH_SHAPE,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_BASE_IRI,
    ALIAS_SYS_BASE_IRI_PREFIX,
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_IS_MODEL_RESOURCE_OF,
    ALIAS_SYS_MODIFIED_DATE,
    ALIAS_SYS_PROPERTY_TYPE,
    ALIAS_SYS_TYPE_SCHEMA,
    ID,
    TYPE
} from "../../Constants";
import {formatDateTime} from "../../components/util";

function createIRI(baseIRI, label) {
    return `${baseIRI}${label}/${uuid4()}`;
}

export function createModelObject(obj) {
    let baseIRI = obj[ALIAS_SYS_BASE_IRI];
    let id = createIRI(baseIRI,'model');
    let model = {
        [ID]: id,
        [TYPE] : ALIAS_SYS_TYPE_SCHEMA,
        [ALIAS_SYS_ID_LABEL]: obj[ALIAS_SYS_ID_LABEL],
        [ALIAS_SYS_BASE_IRI_PREFIX]: obj[ALIAS_SYS_BASE_IRI_PREFIX],
        [ALIAS_SYS_BASE_IRI]: baseIRI,
        [ALIAS_SYS_DESCRIPTION]: obj[ALIAS_SYS_DESCRIPTION],
        [ALIAS_SYS_IS_MODEL_RESOURCE_OF]: id
    }
    return model;
}

export function createShapeObject(modelDetails, classObject) {
    let baseIRI = modelDetails[ALIAS_SYS_BASE_IRI];
    let id = createIRI(baseIRI,'shape');
    return {
        id: id,
        type: ALIAS_SH_SHAPE,
        [ALIAS_SYS_IS_MODEL_RESOURCE_OF]: modelDetails[ID],
        [ALIAS_SH_TARGET_CLASS] : classObject[ID]
    };
}

function createObject(nameTitleObject, modelDetails, type, idLabel) {
    let baseIRI = modelDetails[ALIAS_SYS_BASE_IRI];
    let id = createIRI(baseIRI,idLabel);
    let object = {
        [ID]: id,
        [TYPE]: type,
        [ALIAS_SYS_ID_LABEL]: nameTitleObject.title,
        [ALIAS_SYS_IS_MODEL_RESOURCE_OF]: modelDetails[ID]
    };
    if (nameTitleObject.description) {
        object[ALIAS_SYS_DESCRIPTION] = nameTitleObject.description;
    }
    return object;
}

export function createClassObject(nameTitleObject, modelDetails, parentClassID) {
    let object = createObject(nameTitleObject, modelDetails, ALIAS_OWL_CLASS, 'class');
    if(parentClassID) {
        object[ALIAS_RDFS_SUBCLASS_OF] = [parentClassID];
    }
    return object;
}

export function createPropertyObject(nameTitleObject, modelDetails) {
    let object = createObject(nameTitleObject, modelDetails, nameTitleObject[TYPE], 'property');
    if(nameTitleObject[ALIAS_OWL_INVERSE_OF]) {
        object[ALIAS_OWL_INVERSE_OF] = nameTitleObject[ALIAS_OWL_INVERSE_OF];
    }
    object[ALIAS_SYS_PROPERTY_TYPE] = nameTitleObject[ALIAS_SYS_PROPERTY_TYPE];
    return object;
}

export function createAliasesMap(objects) {
    let aliasesMap = {}
    objects.forEach(o => aliasesMap[o[ID]] = o[ALIAS_SYS_ID_LABEL]);
    return aliasesMap;
}

export function updateModifiedDate(modelDetails) {
    modelDetails[ALIAS_SYS_MODIFIED_DATE] = formatDateTime(new Date());
    return modelDetails;
}

import React from "react";
import {Grid, withStyles} from "@material-ui/core";
import {
    LABEL_BASE_IRI_FOR_DATA,
    LABEL_BASE_IRI_FOR_SCHEMA,
    LABEL_DATA_RESET,
    LABEL_DEFAULT_LANGUAGE
} from "../Constants";

const styles = theme => ({});

const BootstrapHelp = ({ step }) => {
    let spacing = 1;
    switch (step) {
        case 0:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={3}>
                        {LABEL_BASE_IRI_FOR_DATA}
                    </Grid>
                    <Grid item xs={9}>
                        This will be used for data resources created through the API. It should be an http or https IRI
                        and end with a /
                    </Grid>
                    <Grid item xs={3}>
                        {LABEL_BASE_IRI_FOR_SCHEMA}
                    </Grid>
                    <Grid item xs={9}>
                        This will be used for shapes and other schema resources generated by EasyGraph during the
                        bootstrap process.
                    </Grid>
                    <Grid item xs={3}>
                        {LABEL_DEFAULT_LANGUAGE}
                    </Grid>
                    <Grid item xs={9}>
                        This is the default language that will be used for searches.
                    </Grid>
                    <Grid item xs={3}>
                        {LABEL_DATA_RESET}
                    </Grid>
                    <Grid item xs={9}>
                        If YES is entered a test endpoint is enabled that can be used to reset the data in EasyGraph.
                        This is useful for integration testing.
                    </Grid>
                </Grid>
            ];
        case 1:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        Choose from loading your own ontology or load one of the predefined test ontologies for a quick
                        route to getting set up.
                    </Grid>
                    <Grid item xs={12}>
                        You also need to upload any ontologies referred to via owl:import statements. Supported formats
                        are Turtle, RDF/XML N-Triples and JSON-LD.
                    </Grid>
                </Grid>
            ];
        case 2:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        Aliases are short names for properties and a feature of JSON-LD. EasyGraph attempts to
                        automatically generate aliases based on the IRIs of classes and properties in the ontologies.
                    </Grid>
                    <Grid item xs={12}>
                        However, there is always the possibility of conflicts. In that situation EasyGraph will prepend
                        prefixes for property names. Each conflicting Base IRI (namespace) can be given a prefix.
                    </Grid>
                    <Grid item xs={12}>
                        EasyGraph will use prefixes defined in any loaded ontologies as a starting point.
                    </Grid>
                </Grid>
            ];
        case 3:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        By this step EasyGraph has generated all of the aliases that will be used by the system. These
                        can be categorised into those that are derived from the loaded ontologies and those generated by
                        the EasyGraph system.
                    </Grid>
                    <Grid item xs={12}>
                        You can choose which sets of aliases to view. Note that this screen is just for information - no
                        action is necessary.
                    </Grid>
                </Grid>
            ];
        case 4:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        EasyGraph will have now generated shapes based on the loaded ontologies. The shapes define the
                        ‘schema’ that will be used for each class of resource in the APIs.
                    </Grid>
                    <Grid item xs={12}>
                        As classes can be in a hierarchy in an ontology you have the option to view just those
                        properties that are directly associated with a class or, to also include all properties
                        inherited through the hierarchy as well.
                    </Grid>
                    <Grid item xs={12}>
                        This screen is just informative. No action is necessary.
                    </Grid>
                </Grid>
            ];
        case 5:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        The API needs to know which types of resources should have their own IRI patterns. As classes
                        are arranged in a hierarchy a decision needs to be made at which point in the hierarchy this
                        happens.
                    </Grid>
                    <Grid item xs={12}>
                        It may be that you simply want all resources to have the same IRI pattern, in which case
                        selecting Thing, as the top level class, will achieve.
                    </Grid>
                </Grid>
            ];
        case 6:
            return [
                <Grid container spacing={spacing}>
                    <Grid item xs={12}>
                        It is generally very useful for a semantic system to automatically generate properties in both
                        directions if the ontology defines such inverse properties (for example hasPart/isPartOf). This
                        ensures consistency in that both directions of the relationship are in sync with each other.
                    </Grid>
                    <Grid item xs={12}>
                        Select all of the inverses for which you want this to happen. We recommend selecting everything.
                    </Grid>
                </Grid>
            ];
        default: return null;
    };
};


export default withStyles(styles, { withTheme: true })(BootstrapHelp);

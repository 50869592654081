import React from 'react';
import PropTypes from "prop-types";
import {Button, withStyles} from "@material-ui/core";

const styles = () => ({
});

let DetailsViewButton = props => {
  const {theme, disabled, startIcon, endIcon, selected, onClick, style, datatest} = props;
  let baseStyle = {
    color: theme.palette.primary.main,
    boxShadow: 'none',
    textTransform: 'none',
    border: 'none',
    ...theme.typography.h3,
    marginRight: theme.spacing(2)
  }
  return selected
      ? <Button
          disabled={disabled}
          datatest={datatest ||'detailsViewButton'}
          color={'secondary'}
          style={{
              ...baseStyle,
              backgroundColor: theme.palette.secondary.selected,
              '&:hover': {
                backgroundColor: theme.palette.secondary.selected,
              },
              color: theme.palette.secondary.main,
              ...style
          }}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
      >{props.children}</Button>
      : <Button
          disabled={disabled}
          datatest={ datatest || 'detailsViewButton'}
          color={"primary"}
          style={{
              ...baseStyle,
              backgroundColor: theme.palette.border.main,
              ...style
          }}
          onClick={onClick}
          startIcon={startIcon}
          endIcon={endIcon}
      >{props.children}</Button>
}

DetailsViewButton.propTypes = {
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
  style: PropTypes.object,
  startIcon: PropTypes.object,
  endIcon: PropTypes.object,
};

export default withStyles(styles, {withTheme: true})(DetailsViewButton);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {styles} from "../../components/styles";

import {withStyles} from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
import {Divider, IconButton, TextField as OtherTextField, Typography} from "@material-ui/core";
import {
    ALIAS_SH_CLASS,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MAX_LENGTH,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_MIN_LENGTH,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_OR,
    ALIAS_SH_PATH,
    ALIAS_SH_PATTERN,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_PROPERTY_TYPE,
    DATATYPE_SUGGESTIONS,
    FROM_SHAPE,
    ID,
    LABEL_DATA_TYPE,
    LABEL_INHERITED_FROM,
    LABEL_INVERSE_OF_CAPS,
    LABEL_LIST,
    LABEL_MAX_COUNT,
    LABEL_MAX_LENGTH,
    LABEL_MIN_COUNT,
    LABEL_MIN_LENGTH,
    LABEL_NODE_KIND,
    LABEL_OBJECT,
    LABEL_OBJECT_LINKING,
    LABEL_OBJECT_TYPE,
    LABEL_PROPERTY_TYPE_CAPS,
    LABEL_REGEX_PATTERN,
    LABEL_TYPE_FOR_LIST,
    LINK_COLOR,
    MIN_MAX_LENGTH,
    OBJECT_LINKING_PROPERTY,
    RDF_FIRST,
    SECONDARY_COLOR,
    SH_IRI,
    SH_LITERAL,
    TYPE_RDF_LIST,
    VALIDATION_MAX_GREATER_THAN_MIN_COUNT,
    VALIDATION_MAX_GREATER_THAN_MIN_LENGTH,
    VALIDATION_MIN_LESS_THAN_MAX_COUNT,
    VALIDATION_MIN_LESS_THAN_MAX_LENGTH,
    VALIDATION_REGEX_LENGTH
} from "../../Constants";
import {
    centerVertically,
    findInversePropertyObject,
    getPropertyClasses,
    isBooleanDatatype,
    isObjectProperty,
    isStringDatatype,
    sort,
    validateCount,
    validateRegex,
    validateZeroOrPositiveInteger
} from "../../components/util";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getChipWithDelete} from "../../layouts/apiplayground/SearchFilter";
import H4Title from "../../components/H4Title";
import {GreyLockIcon} from "../../components/GreyStyleIcon";
import {ConfirmAreYouSure} from "../../components/ConfirmAreYouSure";
import EditableTextField from "../../components/EditableTextField";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";


export const TYPE_OPTIONS = [
    {label : LABEL_OBJECT_LINKING, value : OBJECT_LINKING_PROPERTY, group : LABEL_OBJECT},
    {label : LABEL_LIST, value : TYPE_RDF_LIST, group : LABEL_OBJECT},
    ...(sort(DATATYPE_SUGGESTIONS, 'label').map(o => ({...o, group : LABEL_DATA_TYPE}))),
    {label : 'Literal', value : SH_LITERAL, group : LABEL_NODE_KIND},
];

const StyledObjectTypeAutocomplete = withStyles({
    root : {
        width : '100%',
    },
    paper : { minWidth : '100px'}
})(Autocomplete)

export function renderContainer(title, content, theme) {
    let propTitle = title && <H4Title style={{paddingBottom: '8px'}}>{title}</H4Title>;
    return <div style={{borderRadius : '4px', opacity : '1', boxShadow : '0px 1px 3px #00000033', backgroundColor: theme.palette.grey.background, marginBottom: '12px'}}>
        {propTitle}
        {content}
    </div>;
}

export function getReadOnlyField(label, style, value) {

    return <OtherTextField
        style={style}
        value={value}
        label={label}
        margin={"dense"}
        variant="outlined"
        fullWidth
        InputLabelProps={{
            shrink : true,
        }}
        InputProps={{
            readOnly : true,
            endAdornment: <GreyLockIcon fontSize={"small"}/>
        }}
    />;
}



export const ERROR_KEY_SUFFIX = '_errorEG';

const StyledAccordionSummary = withStyles({
    root : {
        minHeight : '40px'
    },
    content : {
        cursor :'default',
        height : '40px',
        margin : '0px 4px 0px 0px',
        "$expanded > &": {
            cursor :'default',
            margin : '0px',
        },
        borderRight : '1px solid #E0E0E0',
        maxWidth : 'calc(100% - 32px)'
    }
})(AccordionSummary)

const StyledAccordion = withStyles({
    root : {
        "& .Mui-expanded.MuiAccordionSummary-content": {
            margin : '0px 4px 0px 0px',
        },
    }
})(Accordion);

export function updatePropertyClass(p, val, isList) {
    delete p[ALIAS_SH_CLASS];
    delete p[ALIAS_SH_PROPERTY];
    delete p[ALIAS_SH_OR];
    if (val) {
        if (isList) {
            p[ALIAS_SH_CLASS] = TYPE_RDF_LIST;
            if(val.value) {
                p[ALIAS_SH_PROPERTY] = {
                    [ALIAS_SH_PATH]: RDF_FIRST,
                    [ALIAS_SH_CLASS]: val.value
                };
            }
        } else if (val.length === 1) {
            p[ALIAS_SH_CLASS] = val[0].value
        } else if (val.length > 1) {
            p[ALIAS_SH_OR] = val.map(c => {
                return {
                    [ALIAS_SH_CLASS]: c.value,
                    [ALIAS_SH_NODE_KIND]: SH_IRI
                };
            })
        }
    }
}

class Property extends Component {
    constructor(props) {
        super(props);
        let {ontologyProperties, propertyGroup} = props;
        this.state = {
            ontologyProperty : ontologyProperties.find(op => op[ID] === propertyGroup[0][ALIAS_SH_PATH])
        }
    }

    onChange = (eventName, val) => {
        this.props.onChange(this.props.property, eventName, val);
    }

    minCountValidator = (value, p) => validateCount(value, p[ALIAS_SH_DATATYPE], value, p[ALIAS_SH_MAX_COUNT], VALIDATION_MIN_LESS_THAN_MAX_COUNT, validateZeroOrPositiveInteger)

    minCountValidatorForList = (value, p) => {
        if(value === '') {
          return '';
        }
        if(!(value === '1' || value === '0')) {
            return 'Value '+value+' is invalid for List. Enter 0, 1 or leave empty.';
        } else {
            return '';
        }
    }

    maxCountValidator = (value, p) => validateCount(value, undefined, p[ALIAS_SH_MIN_COUNT], value, VALIDATION_MAX_GREATER_THAN_MIN_COUNT)

    renderProperty = () => {
        let {theme, classObject, propertyGroup, columnView, expanded, onExpand} = this.props;
        let {ontologyProperty} = this.state;
        let id = ontologyProperty[ID];
        let content = <div datatest={'propertyGroup-'+ontologyProperty[ALIAS_SYS_ID_LABEL]}>
            <StyledAccordion
                expanded={expanded}
                onChange={(ev, expanded) => onExpand(ev, expanded, id)}
                style={{
                    margin: '0px',
                    borderRadius : '4px',
                    opacity : '1',
                    boxShadow : '0px 1px 3px #00000033',
                    backgroundColor: theme.palette.grey.background,
                    marginBottom: '12px',
                }}
            >
                <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    id={"additional-actions1-header" + id}
                    IconButtonProps={{
                        size: 'small'
                    }}
                    style={{
                        borderBottom : expanded ? '1px solid #E0E0E0' : 'none',
                        padding : '0px 8px 0px 8px',
                        margin : '0px',
                        minHeight : '40px'
                    }}
                >
                    {centerVertically(this.renderPropertyTitle(), {maxWidth : 'calc(100% - 8px)'})}
                </StyledAccordionSummary>
                <AccordionDetails style={{display : 'block', padding: '0px'}}>
                    {
                        propertyGroup.map((p, i) => {
                            let readOnly = this.isReadOnly(p);
                            let {propertyClassObjectID, inheritedFromLabel} = this.getInheritedFromLabel(p, classObject);

                            return <div datatest={'propertyGroupProperty-'+(inheritedFromLabel || '')} key={p[ALIAS_SH_PATH] + p[ID]}>
                                { i > 0 && <Divider style={{backgroundColor: theme.palette.grey.levelE0}}/>}
                                {
                                    this.renderPropertyDetails(p, readOnly, propertyClassObjectID, inheritedFromLabel)
                                }
                                {
                                    this.renderMinCountMaxCount(p, readOnly)
                                }
                                {
                                    columnView && readOnly === false && <div style={{display: 'flex', padding : '0px 12px 8px'}}>
                                        <div style={{flexGrow : '1'}}/>
                                        {this.getDeleteButton(p)}
                                    </div>
                                }
                            </div>;
                        })
                    }
                </AccordionDetails>
            </StyledAccordion>
        </div>;
        return content;
    }

    renderExtraInfo = (label, value, onClick, labelStyle) => {
        let {theme, columnView} = this.props;
        let basicValueStyle = {
            flexGrow : 1,
            color: theme.palette.grey.level21,
            fontSize : '14px',
            fontWeight : '400'
        }
        let style = onClick
            ? {
                ...basicValueStyle,
                cursor: 'pointer',
                color : LINK_COLOR
            }
            : basicValueStyle;
        let labelWidth = 104;
        let basicLabelStyle = {
            color: theme.palette.grey.level61,
            minWidth : labelWidth+'px',
            maxWidth : labelWidth+'px',
            fontSize : '11px',
            fontWeight : '500'
        };
        let labelStyleComputed = labelStyle ? {...basicLabelStyle, ...labelStyle} : basicLabelStyle;
        return <div style={{display : 'flex'}}>
            {
                centerVertically(
                    <Typography component={'div'} style={labelStyleComputed} variant={'caption'}>{label}</Typography>,
                    {marginTop : '2px'}
                )
            }
            {
                centerVertically(
                    <Typography component={'div'} style={style} onClick={onClick} noWrap={true} variant={'caption'}>{value}</Typography>,
                    columnView ? {maxWidth : '96px'} : {maxWidth : `calc(100% - ${labelWidth}px)`}
                )
            }
        </div>
    }

    renderPropertyTitle = () => {
        let {ontologyProperty} = this.state;
        let label = ontologyProperty[ALIAS_SYS_ID_LABEL];
        return <H4Title style={{color: LINK_COLOR}} noWrap={true}>
            <div datatest={'link-'+label} style={{color: LINK_COLOR, display : 'inline',cursor: 'pointer'}}
                  onClick={(ev) => {
                      ev.stopPropagation();
                      this.props.onNameClick(ontologyProperty[ID]);
                  }}>
                {label}
            </div>
        </H4Title>;
    }

    getDeleteButton = (property) => {
        let {onDelete} = this.props;
        let {ontologyProperty} = this.state;

        let deleteButton = centerVertically(
            <IconButton datatest={'deletePropertyFromClass-'+ontologyProperty[ALIAS_SYS_ID_LABEL]} size={'small'} onClick={() => onDelete(property)}>
                    <DeleteIcon/>
                </IconButton>,
            {height : '30px'}
        )
        return deleteButton;
    }

    renderPropertyDetails = (property, readOnly, propertyClassObjectID, inheritedFromLabel) => {
        let {columnView} = this.props;
        let {ontologyProperties} = this.props;
        let {ontologyProperty} = this.state;
        let inversePropertyLabel = this.getInversePropertyLabel();
        let propertyType = this.findPropertyType(property);
        return <div style={{display: 'flex', padding : '10px 12px'}}>
            {
                centerVertically(
                    <div>
                        <div style={{marginBottom : "2px"}}>{this.renderExtraInfo(LABEL_PROPERTY_TYPE_CAPS, propertyType.label)}</div>
                        {
                            inversePropertyLabel &&
                            <div style={{marginBottom : "2px"}}>{
                                this.renderExtraInfo(
                                    LABEL_INVERSE_OF_CAPS,
                                    inversePropertyLabel,
                                    () => this.props.onNameClick(findInversePropertyObject(ontologyProperty[ID], ontologyProperties)[ID])
                                )
                            }</div>
                        }
                        {
                            inheritedFromLabel &&
                            <div style={{marginBottom : "2px"}}>{
                                this.renderExtraInfo(
                                    LABEL_INHERITED_FROM,
                                    inheritedFromLabel,
                                    () => this.props.onNameClick(propertyClassObjectID),
                                    {color : SECONDARY_COLOR}
                                )
                            }</div>
                        }
                    </div>,
                    {maxWidth : `calc(100% - ${columnView ? 0: 32}px)`, overflow : 'hidden'}
                )
            }
            {
                columnView || readOnly || <>
                    <div style={{flexGrow: '1'}}/>
                    {this.getDeleteButton(property)}
                </>
            }
        </div>;
    }

    getInheritedFromLabel = (property, classObject) => {
        let shapeAndClass = this.getShapeAndClass(property);
        let propertyClassObject = shapeAndClass.classObject;
        let propertyClassObjectID = propertyClassObject[ID];
        let inheritedFromLabel = propertyClassObjectID === classObject[ID]
            ? undefined
            : propertyClassObject[ALIAS_SYS_ID_LABEL];
        return {propertyClassObjectID, inheritedFromLabel};
    }

    getInversePropertyLabel = () => {
        let {ontologyProperty} = this.state;
        let {ontologyProperties} = this.props;
        let inverseProperty = ontologyProperty && isObjectProperty(ontologyProperty)
            ? findInversePropertyObject(ontologyProperty[ID], ontologyProperties)
            : undefined;
        let inverseValue = inverseProperty
            ? inverseProperty[ALIAS_SYS_ID_LABEL]
            : undefined ;
        return inverseValue;
    }

    renderMinCountMaxCount = (property, readOnly) => {
        let {columnView} = this.props;
        let {minMaxStyle} = this.computeStyle(columnView);
        return <div style={{padding : '0px 12px 10px'}}>
            <div style={{display: 'flex'}}>
                <div style={{marginRight: '8px'}}>
                    {this.renderMinMaxComponent(property, readOnly, LABEL_MIN_COUNT, ALIAS_SH_MIN_COUNT, minMaxStyle, this.isListType(property) ? this.minCountValidatorForList : this.minCountValidator)}
                </div>
                {this.renderMaxCountComponent(property, readOnly, minMaxStyle)}
                {columnView || this.renderOptionsForType(property, readOnly)}
            </div>
            {columnView && this.renderOptionsForType(property, readOnly)}
        </div>;
    }

    renderMaxCountComponent = (property, readOnly, minMaxStyle) => {
        if(isBooleanDatatype(property[ALIAS_SH_DATATYPE])) {
            return <></>;
        }
        // list type max count is 1 and not editable
        return <div style={{marginRight: '8px'}}>
            {
                this.isListType(property)
                    ? this.renderMinMaxComponent({[ALIAS_SH_MAX_COUNT] : 1}, true, LABEL_MAX_COUNT, ALIAS_SH_MAX_COUNT, minMaxStyle)
                    : this.renderMinMaxComponent(property, readOnly, LABEL_MAX_COUNT, ALIAS_SH_MAX_COUNT, minMaxStyle, this.maxCountValidator)
            }
        </div>;
    }

    computeStyle = (columnView) => {
        return true ? {
            style : {minWidth : '198px', maxWidth : '198px'},
            minMaxStyle : {minWidth : '95px', maxWidth : '95px'}
        } : {};
    }

    minLengthValidator = (value, p) => validateCount(value, undefined, value, p[ALIAS_SH_MAX_LENGTH], VALIDATION_MIN_LESS_THAN_MAX_LENGTH)

    maxLengthValidator = (value, p) => validateCount(value, undefined, p[ALIAS_SH_MIN_LENGTH], value, VALIDATION_MAX_GREATER_THAN_MIN_LENGTH)

    renderOptionsForType = (p, readOnly) => {
        let {columnView} = this.props;
        let {style, minMaxStyle} = this.computeStyle(columnView);
        let propertyType = this.findPropertyType(p);

        if(isStringDatatype(propertyType.value)) {
            if(columnView) {
                let {style, minMaxStyle} = this.computeStyle(columnView);
                return <>
                    <div style={{display: 'flex', marginTop: '8px'}}>
                        {this.renderRegexComponent(p, readOnly, 'Regex Pattern', style)}
                    </div>
                    <div style={{display: 'flex', marginTop: '8px'}}>
                        <div
                            style={{marginRight: '8px'}}>{this.renderMinMaxComponent(p, readOnly, LABEL_MIN_LENGTH, ALIAS_SH_MIN_LENGTH, minMaxStyle, this.minLengthValidator)}</div>
                        <div
                            style={{marginRight: '8px'}}>{this.renderMinMaxComponent(p, readOnly, LABEL_MAX_LENGTH, ALIAS_SH_MAX_LENGTH, minMaxStyle, this.maxLengthValidator)}</div>
                    </div>

                </>
            } else {
                return <>
                    <div style={{flexGrow: '1', marginRight : '8px'}}>{this.renderRegexComponent(p, readOnly, LABEL_REGEX_PATTERN)}</div>
                    <div style={{marginRight: '8px'}}>{this.renderMinMaxComponent(p, readOnly, LABEL_MIN_LENGTH, ALIAS_SH_MIN_LENGTH, minMaxStyle, this.minLengthValidator)}</div>
                    <div>{this.renderMinMaxComponent(p, readOnly, LABEL_MAX_LENGTH, ALIAS_SH_MAX_LENGTH, minMaxStyle, this.maxLengthValidator)}</div>
                </>;
            }
        } else if (propertyType.group === LABEL_OBJECT) {
            if(columnView) {
                return <>
                    <div style={{display: 'flex', marginTop: '8px'}}>
                        {this.getObjectTypeSelect(p, readOnly, style)}
                    </div>
                </>;
            } else {
                return <>
                    <div style={{flexGrow: '1', minWidth : '0'}}>
                        {this.getObjectTypeSelect(p, readOnly)}
                    </div>
                </>;
            }
        } else {
            return <></>;
        }
    }

    renderMinMaxComponent = (p, readOnly, label, onChangeValueKey, style, validateFunction) => {
        let computedStyle = style ? style : {width : '100px'};
        return <div datatest={label} style={computedStyle}>
            <EditableTextField
                label={label}
                value={p[onChangeValueKey] || ''}
                readOnly={readOnly}
                onConfirm={(label, value) => {
                    p[onChangeValueKey] = value;
                    this.onChange();
                }}
                maxLength={MIN_MAX_LENGTH}
                validator={(value) => validateFunction(value, p)}
            />
        </div>;
    }

    renderRegexComponent = (p, readOnly, label, style) => {
        return <div datatest={label} style={style ?  style : {width : '100%'}}>
            <EditableTextField
                label={label}
                value={p[ALIAS_SH_PATTERN] || ''}
                readOnly={readOnly}
                onConfirm={(label, value) => {
                    p[ALIAS_SH_PATTERN] = value;
                    this.onChange();
                }}
                maxLength={VALIDATION_REGEX_LENGTH}
                validator={validateRegex}
            />
        </div>;
    }

    findPropertyType = (p) => {
        let {ontologyProperty} = this.state;
        return ontologyProperty[ALIAS_SYS_PROPERTY_TYPE];
    }

    isReadOnly = (p) => {
        let {classObject} = this.props;
        let shapeAndClass = this.getShapeAndClass(p);
        return shapeAndClass.classObject[ID] !== classObject[ID];
    }

    getShapeAndClass = (p) => {
        let {shapes, ontologyClasses} = this.props;
        let selectedShape = shapes.find(s => s[ID] === p[FROM_SHAPE]);
        let classObject = ontologyClasses.find(c => c[ID] === selectedShape[ALIAS_SH_TARGET_CLASS]);
        return {selectedShape, classObject};
    }

    isListType = (property) => {
        let propertyType = this.findPropertyType(property);
        return propertyType.value === TYPE_RDF_LIST;
    }

    getObjectTypeSelect = (p, readOnly, style) => {
        let {theme, classes, shapes, ontologyClasses} = this.props;
        let OBJECT_TYPE_OPTIONS = sort(shapes.map(s => {
            let classID = s[ALIAS_SH_TARGET_CLASS];
            let classObject = ontologyClasses.find(o => o[ID] === classID);
            return {label : classObject[ALIAS_SYS_ID_LABEL], value : classID};
        }), 'label');
        let isList = this.isListType(p);
        let propertyClasses = getPropertyClasses(p) || [];
        let value = propertyClasses.filter(p => p !== undefined && p.length !== 0)
            .map(c => {
                let found = OBJECT_TYPE_OPTIONS.find(o => o.value === c);
                return found ? found : {
                    label : c, value : c
                };
            });
        value = isList ? value.length > 0 ? value[0] : '' : value;
        let inputLabelProps = {
                classes : {
                    disabled: classes.smallWidthLabelShrink
                }
            };
        let StyledTextField = withStyles({
            root: {
                "& .MuiFormLabel-root.Mui-disabled": {
                    color: 'rgba(0, 0, 0, 1)',
                }
            }
        })(OtherTextField);

        return <form autoComplete="off" noValidate="novalidate">
            <StyledObjectTypeAutocomplete
                datatest={'objectType'}
            disabled={readOnly}
            style={style ? style : {}}
            value={value}
            options={OBJECT_TYPE_OPTIONS}
            getOptionLabel={option => option.label ? option.label : ''}
            onChange={(event, val) => {
                updatePropertyClass(p, val, isList);
                this.onChange();
                this.setState({});
            }}
            renderInput={params => (
                <StyledTextField
                    label={isList ? LABEL_TYPE_FOR_LIST : LABEL_OBJECT_TYPE}
                    {...params}
                    margin={"dense"}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        shrink: true,
                        ...inputLabelProps
                    }}
                />
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                    return getChipWithDelete(theme, index, option.label, undefined, getTagProps({index}), readOnly);
                })
            }}
            size={"small"}
            multiple={ isList ? false : true}
            disableClearable={value === ''}
            filterSelectedOptions={true}
            />
        </form>;

    }

    render() {
        let {warning, warningOkAction} = this.state;
        return <>
            {
                warning &&
                <ConfirmAreYouSure
                    deleteWarning={warning}
                    handleNo={() => {
                        this.setState({warning: undefined})
                    }}
                    handleYes={warningOkAction}
                />
            }
            {this.renderProperty()}
        </>;
    }
}

Property.propTypes = {
    expanded: PropTypes.bool,
    propertyGroup: PropTypes.array,
    classObject: PropTypes.object,
    shapes: PropTypes.array,
    ontologyClasses: PropTypes.array,
    ontologyProperties: PropTypes.array,
    onExpand: PropTypes.func,
    onChange: PropTypes.func,
    onDelete: PropTypes.func,
    onNameClick: PropTypes.func
}
export default withStyles(styles, {withTheme: true})(Property);

import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {traceRenderStart} from "../../components/Trace";
import {Grid, TextField as OtherTextField, Typography} from "@material-ui/core";
import TextField from "../../components/TextField";
import {
    ALIAS_OWL_DATATYPE_PROPERTY,
    ALIAS_OWL_INVERSE_OF,
    ALIAS_OWL_OBJECT_PROPERTY,
    ALIAS_SH_PATH,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_PROPERTY_TYPE,
    FROM_SHAPE,
    ID,
    LABEL_PROPERTY_TYPE,
    OBJECT_LINKING_PROPERTY,
    SECONDARY_COLOR,
    SH_IRI,
    TYPE,
    TYPE_RDF_LIST,
    VALIDATION_DESCRIPTION_LENGTH,
    VALIDATION_LABEL_LENGTH
} from "../../Constants";
import {
    computeShapePropertiesWithSuperClasses,
    flatten,
    getShapePropertyArray,
    isBNode,
    renderDatatypeOption,
    restrictMaximumCharacters,
    sort,
    validateAlias,
    validateDescription,
    validateNameDuplication
} from "../../components/util";
import H2Title from "../../components/H2Title";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import H3Title from "../../components/H3Title";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TYPE_OPTIONS} from "../../layouts/modelbuilder/Property";
import Label from "../../components/Label";
import FieldContainer from "../../components/FieldContainer";
import QueueIcon from "@material-ui/icons/Queue";
import {createAliasesMap} from "../../layouts/modelbuilder/util";

function defaultWrapper(title, content) {
    return <FieldContainer style={{ minHeight:'70px' }}>
        <Label label={title}/>
        {content}
    </FieldContainer> ;
}

export function getInverseOfSelect(classes, ontologyProperties, value, onChange, excludeFromOptions, textLabel, contentWrapper) {
    let objectProperties = ontologyProperties
        .filter(o => o[ALIAS_SYS_PROPERTY_TYPE] && isObjectLinkingTypeValue(o[ALIAS_SYS_PROPERTY_TYPE].value) && !isBNode(o));
    //Find properties which has inverse
    let inverseToExclude = flatten(objectProperties.filter(op => op[ALIAS_OWL_INVERSE_OF])
        .map(op => [op[ID], op[ALIAS_OWL_INVERSE_OF]]))
        //show selected option
        .filter(id => id !== value);
    if(excludeFromOptions) {
        excludeFromOptions.forEach(p => inverseToExclude.push(p))
    }
    //exclude existing inverse properties from options
    let allOptions = objectProperties
        .filter(op => !inverseToExclude.includes(op[ID]))
        .map(o => ({[ID] : o[ID], [ALIAS_SYS_ID_LABEL]: o[ALIAS_SYS_ID_LABEL]}));
    let inverseOptions = sort(allOptions, [ALIAS_SYS_ID_LABEL]);
    let selectedValue = value ? inverseOptions.find(o => o[ID] === value[ID] || o[ID] === value) : null;
    let content = <Autocomplete
        datatest={'inverseOf'}
        value={selectedValue}
        options={inverseOptions}
        getOptionLabel={option => option[ALIAS_SYS_ID_LABEL] ? option[ALIAS_SYS_ID_LABEL] : ''}
        onChange={(event, val, reason) => {
            console.log("val :" + val+ ": reason"+reason)
            if(event.customReason === 'input' && val === null) {
            } else {
                onChange(val);
            }
        }}
        onInputChange={(ev, valIn, reason) => {
            if(ev) {
                ev.customReason = reason;
            }
            console.log("valIn :" + valIn+ ": reason"+reason);
        }}
        renderInput={params => (
            <OtherTextField
                {...params}
                label={textLabel}
                margin={"dense"}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                    shrink : true
                }}
            />
        )}
        size={"small"}
        multiple={false}
        clearText={'Remove Inverse'}
    />

    return contentWrapper
        ? contentWrapper(content)
        : defaultWrapper('Inverse Property', content);
}

export function getPropertyTypeSelect(classes, value, onChange, textLabel, contentWrapper) {
    let content = <Autocomplete
        datatest={'propertyType'}
        value={value}
        options={TYPE_OPTIONS}
        groupBy={(option) => option.group}
        getOptionLabel={option => option.label ? option.label : ''}
        onChange={(event, val) => {
            onChange(val)
        }}
        renderOption={renderDatatypeOption}
        renderInput={params => (
            <OtherTextField
                {...params}
                label={textLabel}
                margin={"dense"}
                variant="outlined"
                fullWidth
                InputLabelProps={{
                    shrink: true
                }}
            />
        )}
        size={"small"}
        disableClearable
    />
    return contentWrapper
        ? contentWrapper(content)
        : defaultWrapper(LABEL_PROPERTY_TYPE, content);

}


export const NEW_PROPERTY = 'NEW_PROPERTY';
export const EXISTING_PROPERTY = "EXISTING_PROPERTY";

const COMPONENT = 'AddPropertyDialog'

function getPropertyOptions(parentClassID, shapes, ontologyProperties) {
    let shape = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === parentClassID);
    let existingShapeProperties = getShapePropertyArray(shape).map(p => p[ALIAS_SH_PATH]);
    let options = ontologyProperties.filter(op => !existingShapeProperties.includes(op[ID]));
    return options;
}

function initialiseState(props) {
    let {ontologyProperties, shapes, parentClassID} = props;
    let hasOptions = getPropertyOptions(parentClassID, shapes, ontologyProperties).length > 0;
    return {
        name: props.name || '',
        description: props.description || '',
        addMethod : props.parentClassID && hasOptions ? EXISTING_PROPERTY : NEW_PROPERTY,
        [TYPE] : null,
        inverseProperty: null,
        existingProperty : null
    };
}

export function isObjectLinkingTypeValue(typeValue) {
    return typeValue && [OBJECT_LINKING_PROPERTY].includes(typeValue);
}

export function isObjectTypeValue(typeValue) {
    return typeValue && [OBJECT_LINKING_PROPERTY, TYPE_RDF_LIST, SH_IRI].includes(typeValue);
}

const styles = {
    dialogPaper: {
        minWidth: '600px',
        maxWidth: '600px',
        minHeight: '90vh'
    },
};

class AddPropertyDialog extends Component {
    constructor(props) {
        super(props);
        this.state = initialiseState(props);
    }

    shouldCreateBeDisabled = () => {
        let {name, type, nameError, existingProperty} = this.state;
        if(this.isNewProperty()) {
            if (!name || nameError || !type) {
                return true;
            }
        } else if(!existingProperty) {
            return true;
        }
        return false;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    isNewProperty = () => {
        let {addMethod} = this.state;
        return addMethod === NEW_PROPERTY;
    }


    getExistingPropertiesSelect = () => {
        let {ontologyClasses, ontologyProperties, shapes, parentClassID} = this.props;
        let {existingProperty} = this.state;
        let options = getPropertyOptions(parentClassID, shapes, ontologyProperties);
        let shape = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === parentClassID);
        let shapePropertiesFromSuperClasses = computeShapePropertiesWithSuperClasses(shape, shapes, ontologyClasses, true);
        let aliasesMap = createAliasesMap(ontologyProperties);

        return <FieldContainer style={{ minHeight:'70px' }}>
            <Label label={'Select Property'}/>
            <Autocomplete
                datatest={'existingPropertySelect'}
                value={existingProperty}
                options={options}
                getOptionLabel={option => option[ALIAS_SYS_ID_LABEL] ? option[ALIAS_SYS_ID_LABEL] : ''}
                onChange={(event, val) => {
                    this.setState({existingProperty : val})
                }}
                renderOption={(option) => {
                    let inheritedFromLabel = shapePropertiesFromSuperClasses.filter(p => {
                        return p[ALIAS_SH_PATH] === option[ID];
                    }).map(ip => {
                        let selectedShape = shapes.find(s => s[ID] === ip[FROM_SHAPE]);
                        let classObject = ontologyClasses.find(c => c[ID] === selectedShape[ALIAS_SH_TARGET_CLASS]);
                        return classObject[ALIAS_SYS_ID_LABEL];
                    });
                    return <React.Fragment>
                        <div style={{paddingLeft : '16px'}}>
                            <div>{option[ALIAS_SYS_ID_LABEL] ? option[ALIAS_SYS_ID_LABEL] : ''}</div>
                            {   inheritedFromLabel.length > 0 &&
                                <div style={{maxWidth : '480px', display : 'flex'}}>
                                    <Typography noWrap={true} component={'div'} style={{ minWidth : '100px', color: SECONDARY_COLOR}}
                                                variant={'caption'}>INHERITED FROM</Typography>
                                    <div style={{width : '379px'}}>{inheritedFromLabel.map(i => <Typography noWrap={true} style={{ paddingLeft : '8px'}} component={'div'}
                                                                                  variant={'caption'}>{i}</Typography>)}</div>
                                </div>
                            }
                        </div>
                    </React.Fragment>;
                }}
                renderInput={params => (
                    <OtherTextField
                        {...params}
                        margin={"dense"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                size={"small"}
                multiple={false}
            />
        </FieldContainer> ;

    }

    isObjectType = () => {
        let {type} = this.state;
        let typeValue = type ? type.value : null;
        let isObject = isObjectTypeValue(typeValue);
        return isObject;
    }

    isObjectLinkingType = () => {
        let {type} = this.state;
        let typeValue = type ? type.value : null;
        return isObjectLinkingTypeValue(typeValue);
    }

    handleAdd = (multiple) => {
        let {handleOk} = this.props;
        let {name, description, type, addMethod, inverseProperty, existingProperty} = this.state;
        if (this.isNewProperty()) {
            let {ontologyClasses, ontologyProperties} = this.props;
            let nameDuplication = validateNameDuplication(name, ontologyProperties, ontologyClasses);
            if(nameDuplication) {
                this.setState({nameError : nameDuplication});
            } else {
                let object = {
                    title: name,
                    description: description,
                    addMethod,
                    existingProperty
                };
                object.type =
                    this.isObjectType()
                        ? ALIAS_OWL_OBJECT_PROPERTY
                        : ALIAS_OWL_DATATYPE_PROPERTY;
                object[ALIAS_SYS_PROPERTY_TYPE] = type;
                object[ALIAS_OWL_INVERSE_OF] = inverseProperty ? inverseProperty[ID] : null;
                handleOk(object, multiple);
            }
        } else {
            handleOk({addMethod, existingProperty}, multiple);
        }
        if(multiple) {
            let state = initialiseState(this.props);
            delete state.addMethod;
            this.setState(state, () => this.isNewProperty() && this.nameRef.focus());
        }
    }


    render() {
        traceRenderStart('', COMPONENT)

        let {classes, parentClassID, shapes, ontologyProperties, ontologyClasses, addButtonTitle, handleCancel, title, open, helpText} = this.props
        let {name, type, nameError, inverseProperty, description, descriptionError, addMethod} = this.state;
        return  <Dialog
            aria-labelledby="form-dialog-title"
            open={open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="form-dialog-title"><H2Title noWrap={true} title={title}/></DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {helpText && <Grid item xs={12}>{helpText}</Grid>}
                    {
                        parentClassID &&
                        <Grid item xs={12}>
                            <FormControl component="fieldset" fullWidth>
                                <RadioGroup
                                    aria-label="Choose Option"
                                    name="addMethod"
                                    className={classes.group}
                                    value={addMethod}
                                    onChange={(event) => {
                                        const {target: {name, value}} = event;
                                        this.setState({
                                            [name]: value
                                        });
                                    }}
                                    row={true}
                                >
                                    <FormControlLabel
                                        disabled={getPropertyOptions(parentClassID, shapes, ontologyProperties).length === 0}
                                        value={EXISTING_PROPERTY}
                                        control={<Radio/>}
                                        label={<H3Title title={"Add Existing Property"}/>}
                                    />
                                    <FormControlLabel
                                        value={NEW_PROPERTY}
                                        control={<Radio/>}
                                        label={<H3Title title={'Create New Property'}/>}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                    }
                    {
                        this.isNewProperty()
                            ? <>
                                <Grid item xs={12}>
                                    <TextField
                                        inputProps={{
                                            ref: (input) => {this.nameRef = input}
                                        }}
                                        autoFocus={true}
                                        label={'Name'}
                                        id='name'
                                        name='name'
                                        value={name}
                                        error={nameError}
                                        onChange={(event) => {
                                            this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_LABEL_LENGTH))
                                            const {target: {value}} = event;
                                            let error = validateAlias(value, 'Name');
                                            if (error) {
                                                this.setState({nameError: error})
                                            } else {
                                                let nameDuplication = validateNameDuplication(value, ontologyProperties, ontologyClasses);
                                                if(nameDuplication) {
                                                    this.setState({nameError : nameDuplication});
                                                } else {
                                                    this.setState({nameError: ''})
                                                }
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label={'Description'}
                                        id='description'
                                        name='description'
                                        value={description}
                                        error={descriptionError}
                                        multiline={true}
                                        rowsMax="3"
                                        rows="3"
                                        onChange={(event) => {
                                            this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_DESCRIPTION_LENGTH))
                                            const {target: {value}} = event;
                                            this.setState({descriptionError: validateDescription(value)});
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {getPropertyTypeSelect(classes, type, (val) => this.setState({type:val}))}
                                </Grid>
                                {
                                    this.isObjectLinkingType() &&
                                    <Grid item xs={12}>
                                        {getInverseOfSelect(classes, ontologyProperties, inverseProperty, (val) => this.setState({inverseProperty : val}))}
                                    </Grid>
                                }
                            </>
                            : <Grid item xs={12}>
                                {this.getExistingPropertiesSelect()}
                            </Grid>

                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    datatest={'cancelButton'}
                    onClick={handleCancel}
                    variant={"outlined"}
                    color="secondary"
                >Cancel</Button>
                <div style={{flexGrow: '1'}}/>
                <Button
                    datatest={'addButton'}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => this.handleAdd(false)}
                >{addButtonTitle || 'Add'}</Button>
                <Button
                    datatest={'addMultipleButton'}
                    startIcon={<QueueIcon/>}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => this.handleAdd(true)}
                >{addButtonTitle || 'Multiple'}</Button>
            </DialogActions>
        </Dialog>;
    }

}

AddPropertyDialog.propTypes = {
    parentClassID: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    shapes: PropTypes.array,
    ontologyClasses: PropTypes.array,
    ontologyProperties: PropTypes.array,
    title: PropTypes.string,
    addButtonTitle: PropTypes.string,
    helpText: PropTypes.string,
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AddPropertyDialog);

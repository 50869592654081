import {withStyles} from '@material-ui/core/styles';
import "@triply/yasgui/build/yasgui.min.css";
import {default as Yasr} from "@triply/yasr";

import PropTypes from 'prop-types';
import React, {Component} from 'react'
import {styles as mainStyle} from "../../../components/styles";

import "./yasr.css";


class YasrWrapper extends Component {

    _setRef(componentNode) {
        this._rootNode = componentNode;
    }

    componentDidMount() {
        let {data} = this.props;
        //By setting persistenceId to '' we turn off the storage . Without this if the response from SPARQL is large it throws storage full error
        //If you change this setting then it is best to kill the process and do npm start
        const yasr = new Yasr(this._rootNode, {persistenceId : '', maxPersistentResponseSize : 0, persistencyExpire : 0}, data);
    }

    render() {
        return (
            <div>
                <div ref={this._setRef.bind(this)} />
            </div>
        );
    }
}

YasrWrapper.defaultProps = {
};

YasrWrapper.propTypes = {
    data: PropTypes.object
};

export default withStyles(mainStyle)(YasrWrapper);
import React from "react";
import {TextField as OtherTextField, withStyles} from "@material-ui/core";
import TextField from "../TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
    centerVertically,
    computeAllSubClasses,
    createFormFromShape,
    createSelectOption,
    flatten,
    getIdGeneratingClassIRIs,
    getPropertyValueForForm,
    getRandomInt,
    getSearchQuery,
    getShaclORClasses,
    invokeMock, isValidIRI,
    registerMock,
    renderFormFromShape,
    sort,
    toArray
} from "../util";
import {
    ALIAS_SH_CLASS,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_RESULTS,
    EQUAL_TO,
    EVENT_SHACL_VALIDATE,
    ID,
    TYPE,
    TYPE_RDF_LIST, VALUE
} from "../../Constants";
import IconButton from "@material-ui/core/IconButton";
import {graphSearch} from "../../service/graph-api";
import {isObject} from 'lodash';
import {deleteButton} from "./ArrayType";
import ErrorMessage from "../../components/ErrorMessage";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import {renderForm} from "./Form";
import {styles} from "../styles";
import {validateFromSHACL} from "./validation-util";
import {getUiLabelTranslationFrom, UI_LABELS_VALIDATION_INVALID_VALUE} from "../../layouts/navigator/UILabel";

export const OBJECT_ID_TYPE = {
    value: ID,
    label: 'Id'
}

export const EMBED_OBJECT_TYPE = {
    value: 'Object',
    label: 'Object'
}

const CONNECT_TYPES =  [
    OBJECT_ID_TYPE,
    EMBED_OBJECT_TYPE
]

async function search(searchQuery, valueIndex, callback) {
    let searchResult = await graphSearch(searchQuery, undefined, undefined, undefined, undefined, valueIndex);
    let json = await searchResult.json();
    if (json) {
        let results = json[ALIAS_SYS_RESULTS]
        let item = valueIndex === undefined
            ? results[getRandomInt(results.length)]
            : results[valueIndex]
        if(item && item[ID]) {
            return callback(item[ID])
        }
    }
}

class ObjectType extends React.Component {
    constructor(props) {
        super(props)
        let valueForForm = getPropertyValueForForm(props);
        let isObjectType = isObject(valueForForm)
        let {depth, aliasesMap, onChange, configurations} = props

        this.state = {
            expanded : true,
            types: [],
            value: valueForForm ? valueForForm : '',
            connectType: isObjectType ? EMBED_OBJECT_TYPE : OBJECT_ID_TYPE ,
            valueType: isObjectType ? createSelectOption(valueForForm[ALIAS_SH_TARGET_CLASS], props.aliasesMap) : null ,
            searchDialogOpen: false,
            shapeForm : isObjectType ? renderFormFromShape(valueForForm, depth, configurations, aliasesMap, onChange) : {}
        }
    }

    componentDidMount() {
        let {valueIndex, property, aliasesMap, ontology, containers} = this.props
        let idGeneratingClassIRIs = getIdGeneratingClassIRIs(containers)
        let propertyClasses = this.getClasses()
        let allSubClasses = [].concat.apply([], propertyClasses.map(c => computeAllSubClasses(c, ontology)))
        let classTypes = [...propertyClasses, ...allSubClasses]
            .filter(c => idGeneratingClassIRIs.includes(c)).map(c => createSelectOption(c, aliasesMap))
        let types = [
            ...classTypes
        ]
        this.setState({types: types})

        registerMock(valueIndex, property, this.onMock)
    }


     onMock = (eventName) => {
        let {valueIndex, property, customizations} = this.props;
         if(eventName === EVENT_SHACL_VALIDATE) {
             if(this.isObjectIdType()) {
                 return this.validate(eventName);
             } else {
                 this.setState({validationError: undefined});
                 return flatten(toArray(this.invokeObjectMocks(valueIndex, property, eventName)));
             }
         }

         if(this.isObjectIdType()) {
            let searchCriteria = this.getSearchCriteria()
            let searchQuery = searchCriteria
                ? getSearchQuery(searchCriteria)
                : undefined
            return search(searchQuery, valueIndex, this.setIDValue);
        } else {
             this.invokeObjectMocks(valueIndex, property);
         }
    }

    validate = (eventName) => {
        let {valueIndex, property, customizations} = this.props;
        let indexToUse = valueIndex || 0;
        let valueToValidate = toArray(property[VALUE])[indexToUse];
        let validationMessage;
        if(!isValidIRI(valueToValidate)) {
            validationMessage =  {
                ref : this.inputRef,
                message : getUiLabelTranslationFrom(UI_LABELS_VALIDATION_INVALID_VALUE, customizations)
            }
            this.setState({validationError: validationMessage.message});
        }
        if(!validationMessage) {
            validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
            if (validationMessage) {
                this.setState({validationError: validationMessage.message});
            } else {
                this.setState({validationError: undefined});
            }
        }
        return validationMessage;
    }

    invokeObjectMocks = (valueIndex, property, eventName) => {
        if (valueIndex === undefined) {
            return invokeMock(property.value, eventName)
        } else {
            return property.value.map(v => invokeMock(v, eventName))
        }
    }

    getClasses = () => {
        let {property} = this.props
        if(property[ALIAS_SH_CLASS] === TYPE_RDF_LIST) {
            return property[ALIAS_SH_PROPERTY] ? [property[ALIAS_SH_PROPERTY][ALIAS_SH_CLASS]] : [];
        } else if (property[ALIAS_SH_CLASS]) {
            return [property[ALIAS_SH_CLASS]];
        } else {
            let shaclORClasses = getShaclORClasses(property);
            return shaclORClasses ? shaclORClasses : [];
        }
    }

    createObject = (valueType) => {
        let {depth, valueIndex, aliasesMap, onChange, configurations, property, customizations} = this.props
        if(!valueType || valueType.value === 'id') {
            if(valueIndex === undefined) {
                delete property.value
            } else {
                property.value.splice(valueIndex, 1)
            }
            this.setState({shapeForm : null, value: ''})
        } else {
            let jsonObj = createFormFromShape(depth, valueType.value, configurations, aliasesMap, onChange, undefined, customizations)
            if (valueIndex === undefined) {
                property.value = jsonObj
            } else {
                property.value[valueIndex] = jsonObj
            }
            this.setState({shapeForm : jsonObj, value: ''})
        }
    }

    isObjectIdType = () => {
        let {connectType} = this.state
        return connectType && connectType.value === ID;
    }

    closeSearchDialog = () => {
        this.setState({searchDialogOpen : false})
    }

    setIDValue = (value) => {
        let {valueIndex, onChange, property} = this.props
        this.setState({value: value});
        if(valueIndex === undefined) {
            property.value = value
        } else {
            property.value[valueIndex] = value
        }
        this.validate(EVENT_SHACL_VALIDATE);
        onChange()
    }

    handleSearchItemSelect = (selected, item) => {
        this.closeSearchDialog()
        this.setIDValue(item[ID])
    }

    getSearchCriteria = () => {
        let {types} = this.state
        let values = types.filter(t => t.value !== ID)
        return values && values.length > 0
            ? {
                textSearch: {},
                filters: [
                    {
                        id: "78a891b7-d25c-411f-aa5d-207d398140b0",
                        operator: {label: EQUAL_TO, value: EQUAL_TO},
                        property: {label: TYPE, value: TYPE},
                        value: values
                    }
                ]
            }
            : undefined;
    }

    getSelectConnectType = () => {
        let {valueIndex, property, classes, onChange} = this.props;
        let {connectType, types} = this.state;
        let connectTypes = types && types.length > 0
            ? CONNECT_TYPES
            : [OBJECT_ID_TYPE]
        let inputLabelProps = classes
            ? {
                classes : {
                    root : classes.largeWidthPropertyLabelRoot,
                    shrink: classes.smallWidthLabelShrink
                },
                disabled : false
            }
            : {};

        return <Autocomplete
            style={{width : '114px', marginRight : '8px'}}
            value={connectType}
            options={connectTypes}
            datatest={'autocompleteConnectType'}
            getOptionLabel={option => option.label ? option.label : ''}
            onChange={(event, val) => {
                this.setState({connectType: val, validationError : undefined});
                if(!val || val.value === ID) {
                    if(valueIndex === undefined) {
                        property.value = "";
                    } else {
                        property.value[valueIndex] = "";
                    }
                    this.setState({valueType: null ,shapeForm : null, value: ''});
                    onChange();
                } else if(types && types.length === 1) {
                    this.onTypeChange(types[0]);
                } else {
                    onChange();
                }
            }}
            renderInput={params => (
                <OtherTextField  label={'Value Type'} {...params} margin={"dense"} variant="outlined" fullWidth InputLabelProps={inputLabelProps}/>
            )}
            size={"small"}
            disableClearable
        />;

    }

    onTypeChange = (val) => {
        let {onChange} = this.props;
        this.setState({valueType: val});
        this.createObject(val);
        onChange();
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {classes, valueIndex, property, aliasesMap, autoFocus, onChange, theme, customizations} = this.props;
        let {expanded, types, value, valueType, shapeForm, validationError} = this.state;
        let colorStyle = {color: theme.palette.grey.level3, paddingRight: '0px'}
        let inputLabelProps = classes
            ? {
                classes : {
                    root : classes.largeWidthPropertyLabelRoot,
                    shrink: classes.smallWidthLabelShrink
                },
                disabled : false
            }
            : {};


        return <div datatest={'objectTypeContainer'} style={{marginLeft: '4px',marginTop: '8px', padding : '8px', borderRadius : '4px', backgroundColor : theme.palette.white.main}}>
            {   this.isObjectIdType() ||
                <div style={{display: 'flex'}}>
                    <div style={{flexGrow : '1'}}/>
                    {   !valueType ||
                        (
                            !expanded
                                ? <IconButton datatest={'expandButton'} size={'small'} onClick={() => this.setState({expanded: !expanded})}>
                                    <ErrorMessage style={colorStyle} error={'Expand'}/><UnfoldMoreIcon style={colorStyle}/>
                                </IconButton>
                                : <IconButton datatest={'collapseButton'} size={'small'}  onClick={() => this.setState({expanded: !expanded})}>
                                    <ErrorMessage style={colorStyle} error={'Collapse'}/><UnfoldLessIcon style={colorStyle}/>
                                </IconButton>
                        )
                    }
                    { customizations?.hideDelete || centerVertically(deleteButton(valueIndex, property, onChange))}
                </div>
            }
                <div style={{display : 'flex'}}>
                    {
                        this.getSelectConnectType()
                    }
                    {
                        this.isObjectIdType()
                            ? <>
                                <div  datatest={'objectId'} style={{flexGrow: '1'}}>
                                    <TextField
                                        autoFocus={autoFocus}
                                        error={validationError}
                                        inputProps={{
                                            ref: this.setRef
                                        }}
                                        paperStyle={{backgroundColor: theme.palette.white.main, padding : '0px'}}
                                        value={value}
                                        onChange={ (event) => {
                                            this.setIDValue(event.target.value)
                                        }}
                                    />
                                </div>
                                {customizations?.hideDelete || centerVertically(deleteButton(valueIndex, property, onChange))}
                            </>
                            : <Autocomplete
                                datatest={'autocompleteObjectType'}
                                style={{flexGrow: '1'}}
                                value={valueType}
                                options={sort(types, 'label')}
                                getOptionLabel={option => option.label ? option.label : ''}
                                onChange={(event, val) => {
                                    this.onTypeChange(val);
                                }}
                                renderInput={params => (
                                    <OtherTextField InputLabelProps={inputLabelProps} label={'Type'} {...params} margin={"dense"} variant="outlined"
                                                    fullWidth/>
                                )}
                                size={"small"}
                            />


                    }
                </div>
                {
                    expanded === false || (
                        valueType && !this.isObjectIdType() &&
                        <div datatest={'formFields'}>
                            <div>
                                {renderForm(theme, shapeForm, aliasesMap)}
                            </div>
                        </div>
                    )
                }
            </div>;
    }
}

export default withStyles(styles, {withTheme: true})(ObjectType);

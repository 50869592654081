import Tooltip from "@material-ui/core/Tooltip";
import {IconButton as MUIIconButton} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";

export function ConfirmButton({onClick}) {
    return <Tooltip title={'Confirm change'}>
        <MUIIconButton  datatest={'confirmChangeButton'} onMouseDown={(ev) => ev.preventDefault()} color={'secondary'} size={'small'} onClick={onClick}>
            <DoneIcon/>
        </MUIIconButton>
    </Tooltip>;
}
import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../../components/styles";
import {getHeader} from "../../components/header/APIPlaygroundHeader";
import {getHeader as getAccordianHeader} from "./ResultAccordion";

import GlobalsContext from "../../components/GlobalsContext";
import PropTypes from "prop-types";
import SettingsApplicationsOutlinedIcon from '@material-ui/icons/SettingsApplicationsOutlined';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {
    Badge,
    Card,
    CircularProgress,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Paper,
    Switch as MuiSwitch,
    Tooltip
} from '@material-ui/core';

import {
    centerVertically,
    excludeHidden,
    getAliasesMap,
    getAliasToIRIMap,
    getAllOntologyData,
    getBrowseLanguageCode,
    getContainerData,
    GetEditableClassNames,
    getGraph,
    getMultilingualValue,
    getQuery,
    getResourceId,
    getRouteWithInstanceAndDataset,
    getSearchResult,
    getShapesData,
    getTextQuery,
    graphqlStringToObjectValue,
    handleBackendError,
    isAnnotationProperty,
    isDatatypeProperty,
    isObjectOnly,
    isObjectProperty,
    isOntology,
    isOwlClass,
    isRdfsClass,
    isRequestSuccessful,
    resetSearchRequestValues,
    toArray,
    typesOptionsForLanguage
} from "../../components/util";
import {
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    graphSearchByMaps,
    patchManagementGraph
} from "../../service/graph-api";
import {BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION} from "../../service/backend-paths";
import {
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_HAS_NEXT_PAGE,
    ALIAS_SYS_RESULTS,
    AT_CONTEXT,
    AT_DEFAULT,
    FONT_1,
    HTML_SPACE,
    ID,
    LABEL_PROPERTY_LANG,
    PAGE,
    PAGE_SIZE,
    QUERY,
    ROUTE_APPS_EXPLORER,
    ROUTE_APPS_EXPLORER_SITE,
    ROUTE_MANAGEMENT_HOME,
    SITE,
    UPDATE_EVENT_LISTENER_REGISTER,
    UPDATE_EVENT_PUBLISHER
} from "../../Constants";
import withWidth from "@material-ui/core/withWidth";
import logoBG from "../../images/EasyGraphLogoRGBReversedCroped.png";
import Typography from "@material-ui/core/Typography";
import H1Title from "../../components/H1Title";
import WorkspaceSettingsDialog, {isSettingPending} from "./WorkspaceSettingsDialog";
import SearchCard, {getTextSearchField} from "./SearchCard";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import {matchPath, NavLink, Route, Switch, withRouter} from "react-router-dom";
import {getInstanceAndDatasetParam} from "../../App";
import {nonEmptyToUndefinedQuery, updateSearchRequest} from "../apiplayground/SearchRequest";
import qs from 'qs';
import queryString from "query-string";
import {getTypesOptions} from "../apiplayground/SearchFilter";
import {cloneDeep, debounce} from "lodash";
import history from "../../history";
import Select from '@material-ui/core/Select';
import FormControl from "@material-ui/core/FormControl";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import BackendErrorDialog from "../../components/BackendErrorDialog";
import {loadResource, primeFromSearchResult} from "../../service/data-loader";
import SearchResult from "./SearchResult";
import SearchResultItem, {getResourceUrl, getValuesObject, VIEW_MODE_ACCORDION} from "./SearchResultItem";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD_NEW_RESOURCES,
    UI_LABELS_BACK,
    UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS,
    UI_LABELS_OPEN_GRAPH_VISUALISATION,
    UI_LABELS_OPEN_SHEETS_VIEWS
} from "./UILabel";
import {
    getViewAsReadOnly,
    isPageEditEnabled,
    isSSOUser,
    isSuperadmin,
    setPageEdit,
    setSettingsEditAction,
    setViewAsReadOnly
} from "../common/Profile";
import H2Title from "../../components/H2Title";
import graphifiLogoBG from "../../images/GraphifiLogoCrop.png";
import PageView, {getPartContainerStyle, renderContentForView, renderGrid, renderLinkToPageBlock} from "./PageView";
import uuid4 from "uuid/v4";
import {getPagesForWorkspace, isExampleRequest, PART_DATA, processForOpen, processForSave} from "./PagesSetup";
import GraphView, {
    getSavedVisualisationsOptions,
    graphViewLogo,
    nullToIdObjects,
    ZINDEX_NODE_DETAILS_FORM_DATA
} from "./GraphView";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ArrowForwardIcon from "@material-ui/icons/ArrowForwardOutlined";
import AddNewResourceDialog from "./AddNewResourceDialog";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import {isResourceUpdateEvent} from "./Event";
import {GLOBAL_CONTEXT_ALL_CONFIGURATIONS, GLOBAL_CONTEXT_PERMISSIONS_SERVICE} from "./GlobalContextProvider";
import DataGridView from "./DataGridView";
import {
    AddOutlined,
    ArrowBackOutlined,
    AssessmentOutlined,
    BubbleChartOutlined,
    ViewListOutlined
} from "@material-ui/icons";
import {TaxonomyView} from "./TaxonomyView";
import {ANNOTATION_VIEW, CLASS_VIEW, DATATYPE_VIEW, OBJECT_VIEW, OntologyView} from "./OntologyView";
import {getResourceViewOnClickURL} from "./DataViewSetup";
import VisualisationSelectionDialog from "./VisualisationSelectionDialog";
import {
    getContainerHeight,
    isContentType,
    isDataVisualisation, isGridType,
    isLinksToPagesBlock,
    isSpreadsheet,
    isVisualisation
} from "./PageEditor";
import FieldContainer from "../../components/FieldContainer";
import CloseIcon from "@material-ui/icons/Close";
import {fade} from "@material-ui/core/styles/colorManipulator";
import Tree from "./Tree";
import {initHomePageQueries} from "./HomePageQueriesSetup";
import DataVisualisationView from "./DataVisualisationView";
import {getAppBarButtonColor, getBannerContentContainerStyle, isButtonsCardDisabled} from "./BasicSetup";
import FeedbackPanel from "./FeedbackPanel";
import "./content.css";
import "./site-edit.css";
import {getNavigateToConceptLink, getNavigateToOntologyLink} from "./LeftTreeComponents";
import {isQuickEditDisabled, WithAdminEdit} from "./WithAdminEdit";
import SettingsEditPanel from "./ResizeablePanel";

export const RESOURCE_PAGE_PATH = "/:site?/resource?";

const DATA_GRID_VIEW_PAGE_PATH = "/:site?/view/dataGrid";
const GRAPH_VISUALISATION_VIEW_PAGE_PATH = "/:site?/view/graphVisualisation";
const DATA_VISUALISATION_VIEW_PAGE_PATH = "/:site?/view/dataVisualisation";

const SITE_MENU_PAGE_PATH = "/:site?/page/:page?";

const SITE_TAXONOMY_PAGE_PATH = "/:site?/taxonomy?";

const SITE_PATH = "/:site?";

export function getTitle(pathName) {
    let split = pathName.split("/");
    let title = '';
    for (let i = 0; i < split.length; i++) {
        if (split[i] === SITE && (i + 1) < split.length) {
            title = split[i + 1];
        }
    }
    return title;
}

export function getSiteHomePath(location) {
    let pathName = location.pathname;
    return getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${getTitle(pathName)}`)
}

export const SETTINGS_EDIT_ACTION_LOGO_EDIT = 'SETTINGS_EDIT_ACTION_LOGO_EDIT';
export const SETTINGS_EDIT_ACTION_BANNER_CONTAINER = 'SETTINGS_EDIT_ACTION_BANNER_CONTAINER';
export const SETTINGS_EDIT_APP_BAR = 'SETTINGS_EDIT_APP_BAR';
export const SETTINGS_EDIT_ACTION_BANNER_CONTENT_CONTAINER = 'SETTINGS_EDIT_ACTION_BANNER_CONTENT_CONTAINER';
export const SETTINGS_EDIT_ACTION_BANNER_TITLE = 'SETTINGS_EDIT_ACTION_BANNER_TITLE';
export const SETTINGS_EDIT_ACTION_BANNER_SEARCH_CONTAINER = 'SETTINGS_EDIT_ACTION_BANNER_SEARCH_CONTAINER';
export const SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE = 'SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE';
export const SETTINGS_EDIT_ACTION_BANNER_SEARCH = 'SETTINGS_EDIT_ACTION_BANNER_SEARCH';
export const SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL = 'SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_ALL';
export const SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER = 'SETTINGS_EDIT_ACTION_SEARCH_LEFT_CARD_FILTER';
export const SETTINGS_EDIT_ACTION_TRANSLATION = 'SETTINGS_EDIT_ACTION_TRANSLATION';
export const SETTINGS_EDIT_HOME_PAGE_TEMPLATE = 'SETTINGS_EDIT_HOME_PAGE_TEMPLATE';
export const SETTINGS_EDIT_HOME_PAGE_EDIT_PART = 'SETTINGS_EDIT_HOME_PAGE_EDIT_PART';
export const SETTINGS_EDIT_HOME_PAGE_EDIT_ALL = 'SETTINGS_EDIT_HOME_PAGE_EDIT_ALL';
export const SETTINGS_EDIT_PAGE_TEMPLATE = 'SETTINGS_EDIT_PAGE_TEMPLATE';
export const SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE = 'SETTINGS_EDIT_MENU_OTHER_PAGE_TEMPLATE';
export const SETTINGS_EDIT_MENU_PAGES = 'SETTINGS_EDIT_MENU_PAGES';
export const SETTINGS_EDIT_LANGUAGES = 'SETTINGS_EDIT_LANGUAGES';
export const SETTINGS_EDIT_CARD_IMAGE = 'SETTINGS_EDIT_CARD_IMAGE';
export const SETTINGS_EDIT_CARD_TITLE = 'SETTINGS_EDIT_CARD_TITLE';
export const SETTINGS_EDIT_CARD_DESCRIPTION = 'SETTINGS_EDIT_CARD_DESCRIPTION';
export const SETTINGS_EDIT_CARD_OTHER_PROPERTIES = 'SETTINGS_EDIT_CARD_OTHER_PROPERTIES';
export const SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES = 'SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES';
export const SETTINGS_EDIT_RESOURCE_VIEW = 'SETTINGS_EDIT_RESOURCE_VIEW';
export const SETTINGS_EDIT_BUTTONS_CARD = 'SETTINGS_EDIT_BUTTONS_CARD';
export const SETTINGS_EDIT_PROPERTY = 'SETTINGS_EDIT_PROPERTY';

export function renderBanner(settings, theme, workspace, browseLanguage, onSearch) {

    let {textSearch} = settings && settings.searchRequestObject.search;

    let backgroundImageURL = settings && ((settings.banner && settings.banner.imageURL && getMultilingualValue(settings.banner.imageURL, browseLanguage)) || `url(${logoBG})`);
    let searchCardPadding = settings?.['searchBar']?.['padding'];
    let contentStyle = getBannerContentContainerStyle(settings);
    let contentStyleParsed;
    try {
        contentStyleParsed = JSON.parse(contentStyle);
    } catch (e) {

    }

    const height = settings && ((settings.banner && settings.banner.height) || '320px');

    return <div className={ GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_CONTAINER) }>
        <WithAdminEdit data={{action : SETTINGS_EDIT_ACTION_BANNER_CONTAINER}}></WithAdminEdit>
    <div
        datatest={'banner'}
        style={{
            height: `${height}`,
            backgroundColor: settings?.banner?.backgroundColor ? settings.banner.backgroundColor : theme.palette.primary.main,
            backgroundRepeat: 'no-repeat',
            backgroundSize: `${settings && ((settings.banner && settings.banner.backgroundSize) || '50%')}`,
            backgroundPosition: `${settings && ((settings.banner && settings.banner.backgroundPosition) || 'top 0px right -50px')}`,
            backgroundImage: `${backgroundImageURL}`,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
        }}
    >

        <div datatest={'bannerContentContainer'} className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_CONTENT_CONTAINER)} style={contentStyleParsed ? contentStyleParsed : {marginTop: '-80px', textAlign: 'center'}}>
            <WithAdminEdit style={{marginTop: '-24px'}} data={{action : SETTINGS_EDIT_ACTION_BANNER_CONTENT_CONTAINER}}/>
            {workspace &&
                <H1Title
                    className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_TITLE)}
                    datatest={'title'}
                    style={{
                        textAlign:`${settings && ((settings.banner && settings.banner.titleAlignment) || 'center')}`,
                        fontSize: `${settings && ((settings.banner && settings.banner.titleFontSize) || '48px')}`,
                        color: `${settings && ((settings.banner && settings.banner.titleColor) || theme.palette.white.main)}`
                    }}
                >
                    <WithAdminEdit style={{marginLeft : '48px'}} data={{action : SETTINGS_EDIT_ACTION_BANNER_TITLE}}/>
                    {renderBannerTitle(settings, workspace, browseLanguage)}
                </H1Title>}
            {textSearch && browseLanguage && textSearch.disable !== true && textSearch.showInFilter !== true
                && <div className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_SEARCH_CONTAINER)}>
                <WithAdminEdit
                    data={{action : SETTINGS_EDIT_ACTION_BANNER_SEARCH_CONTAINER}}
                />
                <div datatest={'bannerSearchCardContainer'} style={{padding: searchCardPadding ? searchCardPadding : '0px 40px 0px 40px'}}>

                    <Paper datatest={'textSearchPaper'}>
                        <div style={{padding: '20px 80px'}}>
                            <H2Title
                                className={GetEditableClassNames(SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE)}
                                style={{textAlign: 'left'}}
                            >
                                <WithAdminEdit
                                    data={{action : SETTINGS_EDIT_ACTION_BANNER_SEARCH_PAPER_TITLE}}
                                />
                                {(textSearch.ui && textSearch.ui.label && getMultilingualValue(textSearch.ui.label, browseLanguage))}
                            </H2Title>
                            {
                                getTextSearchField(
                                    textSearch,
                                    browseLanguage,
                                    () => onSearch(),
                                    settings,
                                    { minWidth: '160px', maxWidth: '160px'}
                                )
                            }
                        </div>
                    </Paper>
                </div></div>
            }
        </div>
    </div></div>;
}

function renderBannerTitle(settings, workspace, browseLanguage) {
    return settings === undefined || settings.banner === undefined || settings.banner.title === undefined ? workspace?.title : getMultilingualValue(settings.banner.title, browseLanguage);
}


export function renderLogo(classes, location, settings, theme, style, sectionKey = 'logo', onLogoClick) {

    const logoContent = <>{
        settings && settings[sectionKey] && settings[sectionKey].imageURL
            ?
            <img datatest={'egLogoImageLink'} src={settings[sectionKey].imageURL} alt="Logo" style={{height: '33px'}}/>
            : <HomeRoundedIcon datatest={'HomeRoundedIcon'} fontSize={'large'}
                               style={{color: sectionKey === 'sheets' ? theme.palette.secondary.main : theme.palette.white.main}}/>
    }</>;
    return <NavLink  onClick={onLogoClick} style={style} className={`${classes.menuButton} ${GetEditableClassNames(SETTINGS_EDIT_ACTION_LOGO_EDIT)}`} to={getSiteHomePath(location)}>
        <WithAdminEdit data={{action : SETTINGS_EDIT_ACTION_LOGO_EDIT}}/>
        {logoContent}
    </NavLink>;
}

export function renderBrowseLanguageSelect(settings, browseLanguage, onChange, label, readOnly, datatest) {
    const browseLanguageCode = getBrowseLanguageCode(browseLanguage);
    return centerVertically(
        <div className={GetEditableClassNames(SETTINGS_EDIT_LANGUAGES)} datatest={datatest || 'browseLanguageSelect'} style={{marginRight: '4px'}}>
            <WithAdminEdit data={{action : SETTINGS_EDIT_LANGUAGES}}/>

            <FormControl size={'small'} style={{minWidth: '124px', maxWidth: '124px'}} variant="outlined">
                {label && <InputLabel htmlFor="language-select-label">{label}</InputLabel>}
                <Select
                    readOnly={readOnly}
                    labelId="language-select-label"
                    value={browseLanguageCode}
                    onChange={onChange}
                >
                    {settings && toArray(settings.browseLanguages).map(l => <MenuItem datatest={'browseLanguageSelect-'+l.value} key={l.value}
                                                                                      value={l.value}>{l.label}{" (" + l.value + ")"}</MenuItem>)}
                </Select>
            </FormControl>
        </div>
    )

}

function headerBarWrapper(childrenProvider) {
    return <div style={{flexGrow: '1'}}>
        <div style={{display: 'flex'}}>
            <div style={{flexGrow: '1'}}></div>
            {childrenProvider && childrenProvider()}
        </div>
    </div>;
}

export function renderHeaderBarRightMenu(settings, pageObjects, browseLanguage, theme, onNavigateToPage, onBrowseLanguageChange, onSettingsClick) {
    let buttonColor = getAppBarButtonColor(settings);
    return headerBarWrapper(() => {
        return <>
            {
                centerVertically(<div datatest={'menuButtons'} className={GetEditableClassNames(SETTINGS_EDIT_MENU_PAGES)}>
                    <WithAdminEdit data={{action : SETTINGS_EDIT_MENU_PAGES}}></WithAdminEdit>
                    {
                    settings && pageObjects && settings.pages && settings.pages.map(p => {
                        let pageObject = pageObjects.find(po => po[ID] === p);
                        let title = pageObject && getMultilingualValue(pageObject[ALIAS_MANAGEMENT_TITLE], browseLanguage)
                        return <Button

                            key={title}
                            datatest={'menuButton-' + title}
                            onClick={() => onNavigateToPage(title)}
                            style={{color: buttonColor ? buttonColor : theme.palette.white.main, marginRight: '8px'}}
                            variant={'text'}
                        >{title}</Button>;
                    })
                }</div>)
            }
            {
                settings && settings.isMultilingual && renderBrowseLanguageSelect(settings, browseLanguage, onBrowseLanguageChange)
            }
            {
                isSuperadmin() ? <IconButton
                        datatest={'settingsButton'}
                        onClick={onSettingsClick}
                        style={{color: buttonColor ? buttonColor : theme.palette.white.main}}>
                        <Badge color="secondary" variant="dot" invisible={!settings ? true : !settings.dirty}>
                            <SettingsApplicationsOutlinedIcon style={{color: buttonColor ? buttonColor : theme.palette.white.main}} fontSize={'large'}/>
                        </Badge>
                    </IconButton>
                    : <div style={{height : '56px'}}>{HTML_SPACE}</div>
            }
        </>;
    });
}


export function isPathFor(location, path) {
    let searchPath = getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + path;
    const isSearchPath = matchPath(location.pathname, {
        path: searchPath,
        exact: true,
        strict: false
    })
    return isSearchPath;
}

export function isMenuPagePath(location) {
    return isPathFor(location, SITE_MENU_PAGE_PATH);
}


export function isSearchPath(location) {
    return isPathFor(location, "/:site?/search");
}

export function isResourceSearchPath(location) {
    return isPathFor(location, "/:site?/resource");
}

export function isGraphVisualisationPath(location) {
    return isPathFor(location, GRAPH_VISUALISATION_VIEW_PAGE_PATH);
}

export function isTaxonomyViewPath(location) {
    return isPathFor(location, "/:site?/taxonomy");
}

export function isOntologyViewPath(location) {
    return isPathFor(location, "/:site?/ontology");
}

export const PARAM_VISUALISE = 'visualise';


export const visualise = 'Visualise';
export const VIEW_MODE_LIST = 'List';
const RESULT_VIEW = [
    {value : 'Grid'},
    {value : VIEW_MODE_LIST},
    {value : visualise}
];

export function navigateTo(foundResource, details, location) {
    let resourceId = getResourceId(foundResource);
    if (details.navigateInTaxonomy) {
        return history.push(getNavigateToConceptLink(details.navigateInTaxonomy, resourceId, location, undefined));
    } else if (details.navigateInOtherTaxonomy) {
        return history.push(getNavigateToConceptLink(details.navigateInOtherTaxonomy, resourceId, location));
    } else if (details.navigateInOntology || details.navigateInOtherOntology) {
        let ontologyId = details.navigateInOntology || details.navigateInOtherOntology;
        if (isOwlClass(foundResource) || isRdfsClass(foundResource)) {
            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, CLASS_VIEW));
        } else if (isObjectProperty(foundResource)) {
            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, OBJECT_VIEW));
        } else if (isDatatypeProperty(foundResource)) {
            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, DATATYPE_VIEW));
        } else if (isAnnotationProperty(foundResource)) {
            return history.push(getNavigateToOntologyLink(ontologyId, resourceId, location, undefined, ANNOTATION_VIEW));
        } else if(isOntology(foundResource)) {
            return history.push(getNavigateToOntologyLink(ontologyId, undefined, location, undefined, undefined));
        }
        return history.push(getResourceUrl(location, resourceId));
    } else {
        return history.push(getResourceUrl(location, resourceId));
    }
}

export function getPartId(index) {
    return "index-" + index;
}

export function renderRequestResult(part, index, browseLanguage, theme, location, settings, aliasesMap, aliasesToIRIMap, ontology, actionsOnCardFocus, pageWorkspace, pageWorkspaceSettings, customizations) {
    let title = getMultilingualValue(part.title, browseLanguage);
    return <div
        className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), 'editableComponentOutlineOut2', customizations)}
        datatest={'queryBlock'}
        key={title + index}
        style={getPartContainerStyle(part, {})}
    >

        <WithAdminEdit
            style={{marginTop : '-24px'}}
            data={{
                action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART,
                id : getPartId(index),
                part:part,
                index:index,
                pageWorkspace, pageWorkspaceSettings, customizations
            }}
        />

        <Grid datatest={title} container spacing={2}>
            <Grid item xs={12}>
                <Grid container justify="flex-start" spacing={2}>
                    <SearchResult
                        location={location}
                        settings={settings}
                        browseLanguage={browseLanguage}
                        aliasesMap={aliasesMap}
                        aliasesToIRIMap={aliasesToIRIMap}
                        searchResult={part.searchResult}
                        ontology={ontology}
                        onItemFocus={actionsOnCardFocus}

                    />
                </Grid>
            </Grid>
        </Grid>
    </div>;
}

export function cardFocusActions(resource, onClick, settings, aliasesMap, location) {
    return <div className={GetEditableClassNames(SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES, getResourceId(resource), 'editableComponentOutlineIn4')} style={{padding: '0px 8px', display: 'flex'}}>
        <WithAdminEdit
            style={{marginTop :'-8px', marginLeft : '40px'}}
            data={{
                action: SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES,
                id : getResourceId(resource),
                className : 'editableComponentHighlightOffsetIn3',
                resource
            }}
        />
        <Tooltip title={'Visualise'}>
            <IconButton datatest={'cardVisualise'} size={'small'} onClick={onClick}>
                <AccountTreeIcon></AccountTreeIcon>
            </IconButton>
        </Tooltip>
        <div style={{flexGrow: '1'}}></div>
        <NavLink
            to={getResourceViewOnClickURL(resource, settings, aliasesMap, location) || getResourceUrl(location, resource[ID])}>
            <Tooltip title={'View'}>
                <IconButton datatest={'cardOpen'} size={'small'}>
                    <ArrowForwardIcon></ArrowForwardIcon>
                </IconButton>
            </Tooltip>
        </NavLink>
    </div>;
}

class Workspace extends Component {
    constructor(props) {
        super(props);
        this.state = {
            browseLanguage : '',
            viewProperties : [],
            globals: {},
            settingsHeight : 300
        };
        this.debouncedSearch = debounce(this.debouncedSearchInner, 800);
        this.connectedData = {}
        this.updateEventListeners = []
    }

    updateEventPublisher = async (event) => {
        //console.log('Update event', event);
        for(let i =0 ; i < this.updateEventListeners.length; i++) {
            let method = this.updateEventListeners[i];
            await method(event);
        }
    }

    registerUpdateEventListener = (method) => {
        //console.log('Update event register', method);
        this.updateEventListeners.push(method);
    }

    onEvent = (event) => {
        let {resource} = this.state;
        let {location} = this.props;
        let {payload, response} = event;
        if(resource && isResourceUpdateEvent(event) && payload[ID] === resource[ID]) {
            if(isResourceSearchPath(location)) {
                this.searchResource(resource[ID]);
            }
        }
    }



    componentDidMount() {
        this.registerUpdateEventListener(this.onEvent);
        this.props.setGlobalsItem(UPDATE_EVENT_PUBLISHER, this.updateEventPublisher);
        this.props.setGlobalsItem(UPDATE_EVENT_LISTENER_REGISTER, this.registerUpdateEventListener);

        this.syncDataWithBackend(true).then(()=>{
        });
        //Below handles browser back/forward button
        this.backListener = this.props.history.listen( (location, action) => {
            let isSearchPathPage = isSearchPath(location);
            let isResourcePathPage = isResourceSearchPath(location);
            if(isTaxonomyViewPath(location) || isOntologyViewPath(location)) {
                return;
            }
            if (action === "POP") {
                let {settings, aliasesMap, configurations, aliasesToIRIMap, ontology, browseLanguage} = this.state;
                settings && resetSearchRequestValues(settings.searchRequestObject);
                if(isSearchPathPage) {
                    this.syncFromUrl(true, settings, aliasesMap, configurations, aliasesToIRIMap, ontology, browseLanguage);
                    this.search(false);
                } else if(!isResourcePathPage) {
                    // refresh home page queries if there are no search results
                    // this mean user might have opened or refreshed resource view page and then press home button
                    // which means data for home page is not loaded
                    let {defaultLanguage} = this.getDefaultLanguage(settings);
                    this.syncHomePageQueries(settings, defaultLanguage);
                } else {
                    this.setState({});
                }
            } else {
                if(!isSearchPathPage) {
                    let {settings} = this.state;
                    settings && resetSearchRequestValues(settings.searchRequestObject);
                    window.scrollTo(0, 0);
                    this.setState({searchResult : undefined})
                    if(isResourceSearchPath(location)) {
                        let params = queryString.parse(location.search);
                        let resourceID = params[ID];
                        this.searchResource(resourceID);
                    } else {
                        // refresh home page queries if there are no search results
                        // this mean user might have opened or refreshed resource view page and then press home button
                        // which means data for home page is not loaded
                        if(settings) {
                            if(!isTaxonomyViewPath(location)) {
                                let {defaultLanguage} = this.getDefaultLanguage(settings);
                                this.syncHomePageQueries(settings, defaultLanguage);
                            }
                        }
                    }
                    this.setState({});
                }
            }
        });
    }


    getSiteLabel = () => {
        let pathName = this.props.location.pathname
        return getTitle(pathName);
    }

    syncDataWithBackend = async(syncSearchCard) => {
        let {onThemeColorChange} = this.props;
        this.setState({loading: true})
        let siteLabel  = this.getSiteLabel();
        if(!siteLabel) {
            this.pageNotFound('');
            return;
        }
        const workspaceSearchParams = {
            //This parameter is also used in backend API so if this changed also change it Instance Context Setup filter as well
            [ALIAS_MANAGEMENT_NAME] : siteLabel
        }
        const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, {}, false).catch(handleBackendError(this));
        if(workspaceSearchResult.status !== 200) {
            this.setState({loading: false, apiError: true, apiErrorResponse: workspaceSearchResult});
            return;
        }
        workspaceSearchResult.json().then(json => {
            let workspace = getSearchResult(json).find(obj => obj[ALIAS_MANAGEMENT_NAME] === siteLabel);
            if(!workspace) {
                this.pageNotFound(siteLabel);
                return;
            }
            let settings = JSON.parse(workspace[ALIAS_MANAGEMENT_SETTINGS]);
            initHomePageQueries(settings);
            settings.homePage?.queries.forEach(q => processForOpen(q));
            toArray(settings.pages).forEach(p => {
               // p.contentState = EditorState.createWithContent(convertFromRaw(p.contentState));
            });

            let {defaultLanguage, browseLanguage} = this.getDefaultLanguage(settings);

            //Load connected pages to show menu
            getPagesForWorkspace(workspace).then(pageObjects => {
                this.setState({
                    pageObjects: pageObjects,
                    workspace: workspace,
                    settings: settings,
                    browseLanguage: browseLanguage
                }, () => {
                        let response = this.props.getGlobalsItem(GLOBAL_CONTEXT_ALL_CONFIGURATIONS);
                        let aliasesMap = getAliasesMap(response);
                        let aliasToIRIMap = getAliasToIRIMap(aliasesMap);
                        let ontology = getAllOntologyData(response);

                        this.syncFromUrl(syncSearchCard, settings, aliasesMap, response, aliasToIRIMap, ontology, browseLanguage);
                        const {location} = this.props;
                        //if browser is loaded with search url then update search result as well
                        let isSearchPathPage = isSearchPath(location);
                        let isResourceSearchPathPage = isResourceSearchPath(location);
                        if(isGraphVisualisationPath(location)) {
                            getSavedVisualisationsOptions(workspace).then(options => {
                                let params = queryString.parse(location.search);
                                let visualisationId = params['visualisationId'];
                                let found = options.find(o => o.value === visualisationId);
                                this.setState({openVisualisation : found});
                            })
                        } else if(isSearchPathPage) {
                            this.searchForRequest( settings.searchRequestObject, false);
                        } else {
                            let params = queryString.parse(location.search);
                            let resourceID = params[ID];
                            if (isResourceSearchPathPage && resourceID !== undefined) {
                                this.searchResource(resourceID);
                            } else {
                                this.syncHomePageQueries(settings, defaultLanguage);
                            }
                        }
                        this.setState({
                            configurations: response,
                            aliasesMap : aliasesMap,
                            aliasToIRIMap: aliasToIRIMap,
                            containers: getContainerData(response),
                            shapes: getShapesData(response),
                            ontology: ontology,
                            aliasesToIRIMap : getAliasToIRIMap(aliasesMap),
                            loading: false
                        }, () => {
                            if(isSettingPending(settings)) {
                                this.openSettings();
                            }

                        });


                });
                this.props.setGlobalsItem('workspace', workspace);
                this.props.setGlobalsItem('workspaceSettings', settings);
            }).catch(handleBackendError(this));

            //callback allows to remove some unnecessary renders as globalThemeLoaded is used to check if render should wait
            if(onThemeColorChange && settings.theme) {
                onThemeColorChange(settings.theme, () => {
                    this.setState({
                        globalThemeLoaded : true
                    })
                });
            } else {
                this.setState({globalThemeLoaded : true});
            }

            let favicon = document.getElementById("favicon");
            if(favicon && settings.favicon && settings.favicon.imageURL) {
                favicon.href = settings.favicon.imageURL;
            };
            let siteTitle = document.getElementById("siteTitle");
            if(siteTitle) {
                siteTitle.innerText = this.getSiteLabel();
            };
        })
    }

    getDefaultLanguage = (settings) => {
        let defaultLanguage = toArray(settings.browseLanguages).find(bl => bl.isDefault === true);
        let browseLanguage = defaultLanguage ? defaultLanguage.value : '';
        return {defaultLanguage, browseLanguage};
    }

    syncHomePageQueries = (settings, dl) => {
        toArray(settings?.homePage?.queries).filter(q => q['disable'] !== true).filter(q => isExampleRequest(q)).forEach(searchRequest => {
            if (searchRequest?.otherOptions) {
                searchRequest.otherOptions.lang = dl;
            }
            updateSearchRequest(searchRequest, searchRequest.search, searchRequest.facet, searchRequest.mixin, searchRequest.sort, searchRequest.otherOptions);
            let {paramMap, headerMap} = searchRequest;
            this.searchAndCache(paramMap, headerMap).then(o => {
                searchRequest.searchResult = o.json;
                this.setState({});
            }).catch(response => {
                this.setState({apiError: true, apiErrorResponse: response});
            })
        })
    }

    searchResource = (resourceID) => {
        loadResource(resourceID).then(resources => {
            let resource = resources.find(r => r && r[ID] === resourceID);
            if (resource) {
                this.setState({resource: resource})
            } else {
                this.setState({apiError: true, apiErrorResponse: `Resource not found for id '${resourceID}'`});
            }
        })
    }

    pageNotFound = (title) => {
        this.setState({loading: false, apiError: true, apiErrorResponse: 'Invalid link. Page not found ' + title});
    }

    syncFromUrl = (syncSearchCard, settings, aliasesMap, configurations, aliasesToIRIMap, ontology, browseLanguage) => {
        if (syncSearchCard && settings && aliasesMap && configurations) {
            let typesOptions = getTypesOptions(aliasesMap, configurations);
            typesOptions = typesOptionsForLanguage(typesOptions, aliasesToIRIMap, ontology, browseLanguage)
            let {filters, textSearch} = settings.searchRequestObject.search;
            let params = queryString.parse(this.props.location.search);
            let filtersForSync = excludeHidden(filters);
            graphqlStringToObjectValue(params.query, filtersForSync, textSearch, typesOptions);
        }
    }

    getLeftComponent = () => {
        return <></>;
    }


    navigateToPage = (title) => {
        let {location} = this.props;
        return history.push( `${getSiteHomePath(location)}/page/${title}`);
    }

    userPopperContent = () => {
        let {theme} = this.props;
        if(isSSOUser() && !isSuperadmin()) {
            return <></>;
        }
        return <div style={{padding : '16px', borderBottom  :'1px solid', borderColor : theme.palette.grey.level2}}>
            <div style={{marginBottom : '8px'}}>
                <NavLink to={getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_HOME)}>
                    <Button fullWidth={true} variant={'text'}>Management Home</Button>
                </NavLink>
            </div>
            <div style={{marginBottom : '8px'}}>
                <NavLink to={getRouteWithInstanceAndDataset(ROUTE_APPS_EXPLORER)}>
                    <Button fullWidth={true} variant={'text'}>Explorer Home</Button>
                </NavLink>
            </div>
            <div style={{marginBottom : '8px', padding : '0px 16px'}}>
                {
                    isSuperadmin(false) &&
                    <FormControlLabel
                        control={
                            <MuiSwitch
                                size="small"
                                datatest={'quickEditSwitch'}
                                checked={isPageEditEnabled()}
                                onChange={() => {
                                    setPageEdit(isPageEditEnabled() === true ? false : true);
                                    setSettingsEditAction(undefined);
                                    this.setState({settingsEditData : undefined});
                                }}
                            />
                        }
                        label={'Enable quick page edit'}
                    />
                }
            </div>
            <div style={{padding : '0px 16px'}}>
                {
                    isSuperadmin(true) &&
                    <FormControlLabel
                        control={
                            <MuiSwitch
                                datatest={'readOnlyBrowseSwitch'}
                                size="small"
                                checked={getViewAsReadOnly()}
                                onChange={() => {
                                    setViewAsReadOnly(getViewAsReadOnly() === true ? false : true);
                                    setPageEdit(false);
                                    this.setState({})
                                }}
                            />
                        }
                        label={'Browse as read only user'}
                    />
                }
            </div>

        </div>
    }

    getLogoRenderer = (onLogoClick) => {
        let {classes, theme, location} = this.props;
        let {settings} = this.state;

        return renderLogo(classes, location, settings, theme, undefined, undefined, onLogoClick);
    }


    onBrowseLanguageChange = (event) => {
        let {settings} = this.state;
        let {location} = this.props;

        let textSearch = settings && settings.searchRequestObject
            && settings.searchRequestObject.search && settings.searchRequestObject.search.textSearch;
        let searchPath = isSearchPath(location);
        if (textSearch && !searchPath) {
            textSearch.lang = getBrowseLanguageCode(event.target.value)
        }
        this.setState({browseLanguage: event.target.value});
    }

    getRightButtonsRenderer = () => {
        let {theme, location} = this.props;
        let {settings, browseLanguage, pageObjects} = this.state;

        return renderHeaderBarRightMenu( settings, pageObjects, browseLanguage, theme, this.navigateToPage, this.onBrowseLanguageChange, this.openSettings);
    }

    openSettings = () => {
        let searchRequest = this.state.settings.searchRequestObject;
        resetSearchRequestValues(searchRequest);
        setPageEdit(false);
        setSettingsEditAction(undefined);
        this.setState({openSettings : true, settingsEditData : undefined});
    }

    search = (pushToHistory = true) => {
        let searchRequest = this.state.settings?.searchRequestObject;
        searchRequest && this.searchForRequest(searchRequest, pushToHistory);
    }

    searchForRequest = (searchRequest, pushToHistory) => {
        this.setState({searchLoading : true});
        let facetFilters = searchRequest.search.filters.filter(f => f.enableFacet);
        let mapped = facetFilters.filter(f => f.property).map(f => {
            let {value, label} = f.property;
            if(isObjectOnly(value)) {
                value = label;
            }
            return { value, label , labelProperty : f.labelProperty};
        });
        searchRequest.facet = {
            "classIRIs": [],
            filters : mapped
        }
        updateSearchRequest(searchRequest, searchRequest.search, searchRequest.facet, searchRequest.mixin, searchRequest.sort, searchRequest.otherOptions);
        this.debouncedSearch(searchRequest, pushToHistory);
    }

    debouncedSearchInner = async (searchRequest, pushToHistory, page, pageSize = 12, loadMore = false ) => {

        let functionToRun = async () => {
            //If user has filters then reset query to user filters by excluding non default hidden filters including
            let search = searchRequest.search;
            let userFilters = excludeHidden(search.filters);
            let userFiltersQuery = nonEmptyToUndefinedQuery(`{${getTextQuery(search.textSearch)} ${getQuery(userFilters, '')} }`);
            if(userFiltersQuery) {
                let allUserFilterPlusHiddenFilter = search.filters.filter(f => f.applyOnEmpty !== true);
                let newQuery = `{${getTextQuery(search.textSearch)} ${getQuery(allUserFilterPlusHiddenFilter, '')} }`;
                searchRequest.paramMap[QUERY] = newQuery;
            }

            let {paramMap, headerMap} = searchRequest;
            let {workspace, searchResult} = this.state;
            if (page !== undefined) {
                paramMap[PAGE] = page;
            }
            paramMap[PAGE_SIZE] = pageSize;
            paramMap[LABEL_PROPERTY_LANG] = getBrowseLanguageCode(this.getBrowseLanguageObject())
            try {
                let o = await this.searchAndCache(paramMap, headerMap);
                let {json, response} = o;
                if (loadMore === true) {
                    json[ALIAS_SYS_RESULTS] = [
                        ...getSearchResult(searchResult),
                        ...getSearchResult(json)
                    ];
                }
                // If there are more requests then don't bother about updating page just run the  next request
                if (!this.latestFunction ) {
                    this.setState({
                        searchResult: json,
                        searchResultPage: paramMap[PAGE],
                        searchResultPageSize: paramMap[PAGE_SIZE],
                        searchLoading: false,
                        loadMore: false,
                        searchResponse: response
                    });

                    if (pushToHistory) {
                        //Only push user filters to history
                        let userParamMap = {
                            [QUERY] : userFiltersQuery
                        }
                        let userParamMapURL = `search?${qs.stringify(userParamMap, {indices: false})}`;
                        let siteLabel = this.getSiteLabel();
                        history.push(`${getRouteWithInstanceAndDataset(ROUTE_APPS_EXPLORER_SITE)}/${siteLabel}/${userParamMapURL}`);
                    }
                } else {
                    functionToRun = this.latestFunction.functionToRun;
                    this.latestFunction = undefined;
                    await functionToRun();
                }
            } catch (e) {
                this.setState({searchLoading: false, loadMore : false, loading: false, apiError: true, apiErrorResponse: e});
            }
        };

        //If a request is already in progress then just update latest
        if(this.inProgress === true) {
            this.latestFunction = {
                token :  Date.now(),
                functionToRun : functionToRun
            };
        } else {
            this.inProgress = true;
            await functionToRun();
            this.inProgress = false;
        }
    }

    searchAndCache = async (paramMap, headerMap, latestRequestToken) => {
        let response = await graphSearchByMaps(paramMap, headerMap);
        try {
            let json = await response.json();
            primeFromSearchResult(json);
            return {
                json,
                response,
                latestRequestToken
            };
        } catch (e) {
            return Promise.reject(response);
        }
    }

    getSearchCard = () => {
        let {aliasesToIRIMap, searchResult, ontology, settings, configurations, aliasesMap, browseLanguage} = this.state;
        if(!settings || !aliasesMap || !configurations) {
            return <></>;
        }
        return <SearchCard
            settings={settings}
            browseLanguage={this.getBrowseLanguageObject(browseLanguage)}
            configurations={configurations}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            ontology={ontology}
            searchRequestObject={settings.searchRequestObject}
            onChange={this.search}
            searchResult={searchResult}
        />;
    }

    getBrowseLanguageObject = () => {
        let {browseLanguage} = this.state;
        return {value: browseLanguage};
    }


    saveWorkspaceAndSettings = async () => {
        let {settings, workspace} = this.state;
        let {onSaveSettings} = this.props;
        this.setState({loading:true});
        delete settings.dirty;
        let cloned = cloneDeep(settings);
        toArray(cloned.homePage?.queries).forEach(q => {
            processForSave(q);
            delete q.searchResult;
        });
        workspace[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(cloned);
        await this.saveWorkspace(workspace);
        onSaveSettings();
    }

    saveWorkspace = (workspace) => {
        workspace[AT_CONTEXT] = getManagementContextURL();
        return new Promise(resolve => {
            patchManagementGraph(JSON.stringify(workspace)).then(response => {
                if(isRequestSuccessful(response)) {
                    response.json().then(responseJson => {
                        let graph = getGraph(responseJson);
                        let found = graph.find(r => r[ID] === workspace[ID]);
                        workspace[ALIAS_SYS_ETAG] = found[ALIAS_SYS_ETAG];
                        this.setState({workspace});
                        resolve(workspace);
                    })
                } else {
                    this.setState({loading: false, apiError : true , apiErrorResponse : response});
                }
            })
        })
    }

    renderSettingsDialog = () => {
        let { workspace, settings, aliasesToIRIMap, openSettings, configurations, shapes, ontology, aliasesMap, loading, browseLanguage} = this.state;
        let {location, onThemeColorChange} = this.props;
        return openSettings && <WorkspaceSettingsDialog
            saveParentWorkspaceAndSettings={this.saveWorkspaceAndSettings}
            location={location}
            loading={loading}
            configurations={configurations}
            workspace={workspace}
            settings={settings}
            shapes={shapes}
            ontology={ontology}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            open={openSettings}
            handleClose={() => this.setState({openSettings : false}, () => this.syncDataWithBackend(true))}
            handleSave={() => this.setState({openSettings : false}, this.saveWorkspaceAndSettings)}
            onThemeColorChange={onThemeColorChange}
            browseLanguage={browseLanguage}
            onExpand={this.expandObject}

        />;
    }



    getMenuPageContent = () => {
        let {location} = this.props;
        let { pageObjects, workspace, settings, aliasesToIRIMap, configurations, shapes, ontology, aliasesMap, browseLanguage} = this.state;

        let split = location.pathname.split("/");
        let pageTile = '';
        for(let i = 0 ;i < split.length;i++) {
            if(split[i] === 'page' && (i + 1) < split.length ) {
                pageTile = split[i+1];
            }
        }
        if(!settings || !settings.pages) {
            return <></>;
        }
        let page = pageObjects.find(p => {
            //Check if labels matches in any language
            //this is to ensure that even if the user changes browse language on page old menu label from URL found
            let found = settings.browseLanguages.find(bl => getMultilingualValue(p.title, bl) === pageTile);
            return found || p.title[AT_DEFAULT] === pageTile ;
        });
        let pageWorkspaceId = page?.[ID];
        return pageWorkspaceId && <React.Fragment key={pageWorkspaceId + getBrowseLanguageCode(browseLanguage)}>
            <PageView
                settings={settings}
                aliasesMap={aliasesMap}
                browseLanguage={browseLanguage}
                pageWorkspaceId={pageWorkspaceId}
                location={location}
                workspace={workspace}
                configurations={configurations}
                ontology={ontology}
                shapes={shapes}
                onExpand={this.expandObject}
                aliasesToIRIMap={aliasesToIRIMap}
                isPreview={false}
            />
        </React.Fragment>
    }

    getSearchResult = () => {
        let { searchResult, configurations, resultView, loadMore, searchResultPage, ontology, settings, aliasesMap, aliasesToIRIMap, browseLanguage} = this.state;
        let {location} = this.props;
        let searchResultResources = getSearchResult(searchResult);

        return aliasesToIRIMap && <>

            <Grid container style={{flexGrow : 1}}  spacing={2} >
                <Grid  item  xs={12}>
                    <Grid container justify={searchResultResources.length === 2 ? "flex-start" : "space-between"} spacing={4}>
                        <SearchResult
                            configurations={configurations}
                            graphViewProvider={this.graphViewer}
                            location={location}
                            searchResult={searchResult}
                            settings={settings}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            browseLanguage={browseLanguage}
                            ontology={ontology}
                            onItemFocus={this.onResourceItemCardFocus}
                            viewMode={resultView}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <div style={{textAlign : 'center', padding : '24px'}}>
                        {
                            searchResult && searchResult[ALIAS_SYS_HAS_NEXT_PAGE] === 'true' &&
                            <Button disabled={loadMore} color={'secondary'}  size={'large'} variant={'contained'} onClick={this.onLoadMore}>{loadMore && <CircularProgress color={'secondary'} size={24}/>}Load More</Button>
                        }
                    </div>
                </Grid>

            </Grid>

        </>;
    }

    onLoadMore = () => {
        let {searchResultPage} = this.state;
        let searchRequest = this.state.settings.searchRequestObject;
        let currentPage = Number(searchResultPage === undefined ? 1 : searchResultPage);
        this.setState({loadMore: true});
        this.debouncedSearch(searchRequest, true, currentPage + 1, undefined, true);
    }

    getHomePageContent = () => {
        let {location, theme} = this.props;
        let {openGraphViewerFor, resultView, searchResult, openDataGridViewerFor, settings, selectedVisualisation} = this.state;
        if(isMenuPagePath(location)) {
            return <></>;
        }
        const isSearchPathPage = isSearchPath(location);

        let showBanner = this.showBannerOnAllPages();
        let leftWidth = 320;
        let leftMargin = 40;
        let rightContentMarginLeft = 24;
        let rightContentMarginRight = 24;
        let rightContentMaxWidth ='calc(100% - ' +(leftWidth + leftMargin + rightContentMarginLeft + rightContentMarginRight)+'px)';
        let isMainContentOnly = settings?.homePage?.hideLeftColumn === true;

        const mainContentStyle = !isMainContentOnly ? {
                flexGrow: '1' ,
                minWidth : rightContentMaxWidth,
                marginLeft: rightContentMarginLeft+'px',
                marginRight: rightContentMarginRight+'24px'
            }
            : {
                padding : settings?.homePage?.middleContentPadding,
                width : settings?.homePage?.middleContentWidth,
                margin : settings?.homePage?.middleContentWidth ? 'auto' : undefined
            };

        const isHomePageContent = !isSearchPathPage && !isResourceSearchPath(location);

        if(isHomePageContent) {
            mainContentStyle.display = "flex";
            mainContentStyle.flexDirection = "column";
            mainContentStyle.gap = settings?.['componentGap'] || "16px";
        }


        return <>
            {isSearchPathPage === false || showBanner ? this.getBanner() : <></>}
            <div style={isSearchPathPage && showBanner === false  ? {} : {marginTop: '0px'}}>
                <div datatest={'homePageContent'} className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_TEMPLATE)} style={{outlineOffset : '-3px', display: (isMainContentOnly ? undefined : 'flex' ), ...(isSearchPathPage ? {marginTop: '32px'} : {})}}>
                    <WithAdminEdit style={{marginTop: '0px'}} data={{action: SETTINGS_EDIT_HOME_PAGE_TEMPLATE}}></WithAdminEdit>

                    {
                        isMainContentOnly ? <></> :
                        <div style={{minWidth: leftWidth+'px', maxWidth: leftWidth+'px', marginLeft: leftMargin+'px'}}>
                            {
                                isSearchPathPage
                                    ? <></>
                                    : <div>
                                        <Typography style={{
                                            color: theme.palette.white.main,
                                            fontWeight: '500',
                                            fontFamily: FONT_1,
                                            fontSize: '20px'
                                        }}>&nbsp;</Typography>
                                    </div>
                            }
                            <div>
                                {this.getLeftCards()}
                            </div>
                        </div>
                    }
                    <div datatest={'mainContentContainer'} className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_ALL)} style={mainContentStyle}>
                        {isHomePageContent && <WithAdminEdit style={{marginLeft: '-24px'}} data={{action: SETTINGS_EDIT_HOME_PAGE_EDIT_ALL}}/> }

                        {
                            isSearchPathPage &&
                            <div style={{marginBottom : '32px'}}>

                                {
                                    centerVertically(
                                        <div datatest={'searchResultView'} style={{marginRight: '4px'}}>
                                            <FormControl size={'small'} variant="outlined" style={{minWidth : '124px'}} >
                                                <InputLabel htmlFor="result-view-select-label">View</InputLabel>
                                                <Select
                                                    label={'View'}
                                                    labelId="result-view-select-label"
                                                    value={resultView || RESULT_VIEW[0].value}
                                                    onChange={(ev) => {
                                                        let resultView = ev.target.value;
                                                        if(resultView === visualise) {
                                                            let searchResultResources = getSearchResult(searchResult).map(r => r[ID]);
                                                            this.setState({openGraphViewerFor : searchResultResources})
                                                        } else {
                                                            this.setState({resultView});
                                                        }
                                                    }}
                                                    inputProps={{
                                                        name: 'View',
                                                        id: 'result-view-select-label',
                                                    }}
                                                >
                                                    {
                                                        RESULT_VIEW.map(l => <MenuItem key={l.value} value={l.value}>{l.value}</MenuItem>)
                                                    }
                                                </Select>
                                            </FormControl>
                                        </div>
                                    )
                                }
                            </div>
                        }

                        {openGraphViewerFor && this.graphViewer(openGraphViewerFor)}
                        {openDataGridViewerFor && this.dataGridViewer()}
                        {this.renderPageContent()}
                    </div>
                </div>
            </div>
        </>;
    }


    renderVisualisationSelectDialog = () => {
        const { workspace, settings, browseLanguage} = this.state;
        const {theme} = this.props;
        return <VisualisationSelectionDialog
            settings={settings}
            browseLanguage={browseLanguage}
            workspace={workspace}
            onCancel={() => {
                this.setState({showVisualisationSelectDialog : false, selectedVisualisation : undefined, openVisualisation : undefined});
            }}
            onSelect={(selectedVisualisation) => {
                this.openVisualisationPage(selectedVisualisation.value);
                this.setState({showVisualisationSelectDialog : false, openVisualisation : selectedVisualisation});
            }}
        />;
    }



    getLeftCards = () => {
        let {location, theme, permissions, getGlobalsItem} = this.props;
        let {workspace, graphFullScreen , addNewResource, addNewResourceSuccess, settings, aliasesMap, ontology, browseLanguage, aliasesToIRIMap, showVisualisationSelectDialog, openVisualisation} = this.state;
        if(graphFullScreen) {
            return  <></>;
        }
        let disableSheetsButton = settings?.sheets?.disabled ? true : false ;
        let disableCreateResourceButton = settings?.createResourceButton?.disabled ? true : false ;
        let disableVisualiseButton = settings?.graphVisualisationButton?.disabled ? true : false ;
        let disableDataVisualisationButton = settings?.dataVisualisationButton?.disabled ? true : false ;
        let disableSecondaryCard = isButtonsCardDisabled(settings) || disableSheetsButton && disableCreateResourceButton && disableVisualiseButton && disableDataVisualisationButton;
        let resourcePagePath = isResourceSearchPath(location);

        let createButton = disableCreateResourceButton === false && getGlobalsItem(GLOBAL_CONTEXT_PERMISSIONS_SERVICE).canCreateAnyResourceType() &&
            <Button
                datatest={'addResourceButton'}
                onClick={() => this.setState({addNewResource: true})} fullWidth={true}
                color={'secondary'}
                variant={'outlined'}
                startIcon={<AddOutlined></AddOutlined>}
                className={GetEditableClassNames(SETTINGS_EDIT_ACTION_TRANSLATION, UI_LABELS_ADD_NEW_RESOURCES)}
            >
                <WithAdminEdit style={{left :'0px'}} data={{action: SETTINGS_EDIT_ACTION_TRANSLATION, id : UI_LABELS_ADD_NEW_RESOURCES}}/>
                {getUiLabelTranslation(settings, UI_LABELS_ADD_NEW_RESOURCES, browseLanguage, UI_LABELS_ADD_NEW_RESOURCES)}
            </Button>;

        let visualisationButton = disableVisualiseButton === false &&
            <Button
                datatest={'openVisualisationButton'}
                onClick={async () => {
                    this.setState({showVisualisationSelectDialog: true});
                }}
                fullWidth={true}
                color={'secondary'}
                variant={'outlined'}
                startIcon={<BubbleChartOutlined></BubbleChartOutlined>}
                className={GetEditableClassNames(SETTINGS_EDIT_ACTION_TRANSLATION, UI_LABELS_OPEN_GRAPH_VISUALISATION)}
            >
                <WithAdminEdit style={{left :'0px'}} data={{action: SETTINGS_EDIT_ACTION_TRANSLATION, id : UI_LABELS_OPEN_GRAPH_VISUALISATION}}/>
                {getUiLabelTranslation(settings, UI_LABELS_OPEN_GRAPH_VISUALISATION, browseLanguage, UI_LABELS_OPEN_GRAPH_VISUALISATION)}</Button>;

        let sheetsButton = disableSheetsButton
            ? <></>
            : <Button
                datatest={'openSheetViewButton'}
                onClick={() => {
                    let path = getSiteHomePath(location) + '/view/dataGrid';
                    history.push(`${path}`);
                }}
                fullWidth={true}
                color={'secondary'}
                variant={'outlined'}
                startIcon={<ViewListOutlined/>}
                className={GetEditableClassNames(SETTINGS_EDIT_ACTION_TRANSLATION, UI_LABELS_OPEN_SHEETS_VIEWS)}
            >
                <WithAdminEdit style={{left :'0px'}} data={{action: SETTINGS_EDIT_ACTION_TRANSLATION, id : UI_LABELS_OPEN_SHEETS_VIEWS}}/>
                {getUiLabelTranslation(settings, UI_LABELS_OPEN_SHEETS_VIEWS, browseLanguage, UI_LABELS_OPEN_SHEETS_VIEWS)}</Button>;

        let dataVisualisationButton = disableDataVisualisationButton
            ? <></>
            : <Button
                datatest={'openDataVisualisationButton'}
                onClick={() => {
                    let path = getSiteHomePath(location) + '/view/dataVisualisation';
                    history.push(`${path}`);
                }}
                fullWidth={true}
                color={'secondary'}
                variant={'outlined'}
                startIcon={<AssessmentOutlined/>}
                className={GetEditableClassNames(SETTINGS_EDIT_ACTION_TRANSLATION, UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS)}
            >
                <WithAdminEdit style={{left :'0px'}} data={{action: SETTINGS_EDIT_ACTION_TRANSLATION, id : UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS}}/>
                {getUiLabelTranslation(settings, UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS, browseLanguage, UI_LABELS_OPEN_DATA_VISUALISATION_VIEWS)}</Button>;

        let buttonsArray = [createButton, sheetsButton, visualisationButton, dataVisualisationButton];
        let secondryCardContainerStyle = disableSecondaryCard
            ? {
                minHeight : '40px',
                marginTop : '24px'
            }
            : {}
        return <>
            { resourcePagePath ? <></> : this.getSearchCard() }
            <div datatest={'secondaryCardContainer'} style={secondryCardContainerStyle} className={GetEditableClassNames(SETTINGS_EDIT_BUTTONS_CARD)}>
                <WithAdminEdit data={{action : SETTINGS_EDIT_BUTTONS_CARD}} />
            { disableSecondaryCard ? <></> :
                <Card datatest={'backCard'} style={{
                    marginTop: resourcePagePath ? '0px' : '24px',
                    padding: '16px',
                    color: theme.palette.primary.main
                }}>
                    {

                        <div>
                            {
                                addNewResourceSuccess && <AlertSnackbarContent
                                    open={true}
                                    autoHide={true}
                                    variant={'success'}
                                    message={'New resource(s) created.'}
                                    onClose={() => this.setState({addNewResourceSuccess: undefined})}
                                />
                            }
                            {
                                addNewResource &&
                                <AddNewResourceDialog
                                    ontology={ontology}
                                    browseLanguage={browseLanguage}
                                    aliasesToIRIMap={aliasesToIRIMap}
                                    aliasesMap={aliasesMap}
                                    settings={settings}
                                    onClose={() => this.setState({addNewResource: undefined})}
                                    onSaveSuccess={() => {
                                        this.setState({addNewResource: undefined, addNewResourceSuccess: true})
                                    }}
                                />
                            }
                            {
                                showVisualisationSelectDialog && this.renderVisualisationSelectDialog()
                            }
                            {openVisualisation && this.graphViewer(undefined, openVisualisation, () => {
                                let {location} = this.props;
                                history.push( `${getSiteHomePath(location)}`);
                                this.onGraphDialogClose();

                            })}
                            {
                                buttonsArray.filter(b => b).map((b, index) =>{
                                    return <div style={{marginTop : index > 0 ? '16px' : '0px'}}>{b}</div>;
                                })
                            }

                        </div>
                    }
                    {
                        resourcePagePath &&
                        <div style={{marginTop: '16px'}}>
                            <Button
                                onClick={() => history.goBack()}
                                fullWidth={true}
                                color={'secondary'}
                                variant={'outlined'}
                                startIcon={<ArrowBackOutlined/>}
                            >{getUiLabelTranslation(settings, UI_LABELS_BACK, browseLanguage, UI_LABELS_BACK)}</Button>
                        </div>
                    }
                </Card>
            }
            </div>
            </>

    }

    renderPageContent = () => {
        let {location} = this.props;
        if(isSearchPath(location)) {
            return this.getSearchResult();
        } else if(isResourceSearchPath(location)) {
            return this.getResourcePage();
        } else {
            return this.getHomePageCards();
        }
    }

    onObjectFetch = async (id) => {
        let {connectedData} = this;
        let idArray = toArray(id).map(id => getResourceId(id) || id);
        let resources = await loadResource(idArray);
        return cloneDeep(resources);
    }

    expandObject = (idArray) => {
        return this.onObjectFetch(idArray);
    }

    onGraphDialogClose = () => {
        this.setState({graphViewId : uuid4(), openVisualisation : undefined, openGraphViewerFor : undefined});
    }

    graphViewer = (resourceId, selectedVisualisation, onLogoClick) => {
        let {theme, location, history} = this.props;

        let {globalThemeLoaded, graphViewId, loading, workspace, settings, browseLanguage, aliasesMap, aliasesToIRIMap, configurations, ontology, openGraphViewerFor} = this.state;
        const hasData = openGraphViewerFor || selectedVisualisation;
        const loadGraphView = settings && loading === false && globalThemeLoaded === true;
        return loadGraphView ? <div key={(graphViewId||getResourceId(selectedVisualisation?.backingObject))+""}><GraphView
            headerProvider={
                () => graphViewLogo(theme, settings, (onLogoClick || this.onGraphDialogClose))
            }
            workspace={workspace}
            settings={settings}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            languageCode={getBrowseLanguageCode(browseLanguage)}
            configurations={configurations}
            ontology={ontology}
            location={location}
            onExpand={this.expandObject}
            data={toArray(resourceId)}
            visualisation={selectedVisualisation}
            minimized={true}
            onClose={() => {
                this.onGraphDialogClose()
            }}
            open={hasData}
            hideButton={hasData ? true : false}
            history={history}
        /></div> : <></>;
    }

    dataGridViewer = () => {
        let {theme, location} = this.props;

        let {graphViewId, workspace, settings, browseLanguage, aliasesMap, aliasesToIRIMap, configurations, ontology, openDataGridViewerFor} = this.state;
        return settings ? <div key={graphViewId+toArray(openDataGridViewerFor).length} style={{height : '100%'}}>

            <DataGridView
            headerProvider={
                () => getHeader(
                    " ",
                    undefined,
                    () => <><div style={{flexGrow : '1'}}/></>,
                    getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                    this.getLogoRenderer,
                    this.userPopperContent,
                    true,
                    {padding : '0px 32px 0px 64px'},
                    false
                )
            }
            workspace={workspace}
            settings={settings}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            browseLanguage={getBrowseLanguageCode(browseLanguage)}
            configurations={configurations}
            ontology={ontology}
            shapes={getShapesData(configurations)}
            location={location}
            onExpand={this.expandObject}
            data={openDataGridViewerFor}
            minimized={true}
            onClose={() => {
                history.push( getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`));
            }}
            open={openDataGridViewerFor ? true : false}
            hideButton={openDataGridViewerFor ? true : false}

        /></div> : <></>;
    }

    dataVisualisationViewer = () => {
        let {theme, location} = this.props;

        let {graphViewId, workspace, settings, browseLanguage, aliasesMap, aliasesToIRIMap, configurations, ontology, openDataGridViewerFor} = this.state;
        return settings ? <div key={graphViewId+toArray(openDataGridViewerFor).length} style={{height : '100%'}}>

            <DataVisualisationView
                headerProvider={
                    () => getHeader(
                        " ",
                        undefined,
                        () => <><div style={{flexGrow : '1'}}/></>,
                        getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                        this.getLogoRenderer,
                        this.userPopperContent,
                        true,
                        {padding : '0px 32px 0px 64px'},
                        false
                    )
                }
                workspace={workspace}
                settings={settings}
                aliasesMap={aliasesMap}
                aliasesToIRIMap={aliasesToIRIMap}
                browseLanguage={getBrowseLanguageCode(browseLanguage)}
                configurations={configurations}
                ontology={ontology}
                shapes={getShapesData(configurations)}
                location={location}
                onExpand={this.expandObject}
                data={openDataGridViewerFor}
                minimized={true}
                onClose={() => {
                    history.push( getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`));
                }}
                open={openDataGridViewerFor ? true : false}
                hideButton={openDataGridViewerFor ? true : false}

            /></div> : <></>;
    }

    getResourcePage = () => {
        let {theme, location} = this.props;
        let {viewProperties, configurations, ontology, resource, aliasesMap, aliasesToIRIMap, settings, browseLanguage} = this.state;
        return resource && <div key={resource?.[ID]}>
            <Typography style={{
                color: theme.palette.white.main,
                fontWeight: '500',
                fontFamily: FONT_1,
                fontSize: '20px'
            }}>{HTML_SPACE}</Typography>
            <div>
                <SearchResultItem
                    viewProperties={viewProperties}
                    configurations={configurations}
                    ontology={ontology}
                    resource={resource}
                    location={location}
                    aliasesToIRIMap={aliasesToIRIMap}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    browseLanguage={browseLanguage}
                    viewMode={VIEW_MODE_ACCORDION}
                    graphViewProvider={this.graphViewer}
                />
            </div>
        </div>;
    }

    showBannerOnAllPages = () => {
        let {settings} = this.state;

        return settings && settings.banner && settings.banner.showOnAllPages;
    }


    onResourceItemCardFocus = (resource) => {
        let {location} = this.props;
        let {settings, aliasesMap} = this.state;
        const onClick = (ev) => {
            this.setState({openGraphViewerFor : resource[ID]})
            ev.preventDefault();
            ev.stopPropagation();
        };

        return cardFocusActions(resource, onClick, settings, aliasesMap, location);
    }



    getHomePageCards = () => {
        let {theme, location} = this.props;
        let {settings, browseLanguage, aliasesMap, aliasesToIRIMap, ontology, configurations, graphFullScreen} = this.state;
        return <>
            {
                aliasesMap && toArray(settings?.homePage?.queries).map((q, i) => {
                    return this.renderPart(q, i);
                })
            }
            {
                this.isGraphViewFullScreen() ? <></> :
                <FeedbackPanel
                    settings={settings}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    ontology={ontology}
                    location={location}
                    configurations={configurations}
                    browseLanguage={ getBrowseLanguageCode(browseLanguage)}
                ></FeedbackPanel>
            }

        </>;
    }

    renderPart = (part, index, customizations) => {
        let {settings, browseLanguage, graphFullScreen} = this.state;
        if (part['disable'] === true) {
            return <></>;
        }
        //do not render other stuff as this will cause scroll
        if (this.isGraphViewFullScreen() && graphFullScreen !== part[PART_DATA]) {
            return <div></div>;
        }
        let toRender;
        if (isExampleRequest(part)) {
            toRender = this.renderRequestResult(part, index, customizations);
        } else if (isVisualisation(part)) {
            toRender = this.renderVisualisationType(part, index, customizations);
        } else if (isDataVisualisation(part)) {
            toRender = this.renderDataVisualisationType(part, index, customizations);
        } else if (isContentType(part)) {
            toRender = renderContentForView(browseLanguage, settings, part, index, undefined, undefined, customizations)
        } else if (isSpreadsheet(part)) {
            toRender = this.renderSpreadsheetType(part, index, customizations)
        } else if (isLinksToPagesBlock(part)) {
            toRender = this.renderLinkToPageBlockType(part, index, customizations)
        } else if (isGridType(part)) {
            toRender = renderGrid(part, index, this.renderPart, undefined, undefined, customizations)
        }
        return <>
            {toRender}
        </>;
    }

    renderRequestResult = (part, index, customizations) => {
        let {theme, location} = this.props;
        let {settings, browseLanguage, aliasesMap, aliasesToIRIMap, ontology} = this.state;
        return renderRequestResult(part, index, browseLanguage, theme, location, settings, aliasesMap, aliasesToIRIMap, ontology, this.onResourceItemCardFocus, undefined, undefined, customizations);
    }

    isGraphViewFullScreen() {
        let {graphFullScreen} = this.state;
        return graphFullScreen !== undefined;
    }

    closeTreePopup = () => {
        this.setState({formData: undefined, uuid: undefined});
    }

    renderResourceTreePopup = (height) => {
        let {theme, location} = this.props;
        let {formData, formDataValueObject, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology} = this.state;
        const formDataWidth = 608;
        let languageCode = getBrowseLanguageCode(browseLanguage);
        return <div key={getResourceId(formDataValueObject) || getResourceId(formData)} datatest={'formDataBlock'} style={{
            flexGrow : '1',
            padding: '16px 8px 8px 8px',
            backgroundColor: theme.palette.white.main,
            borderRadius: '4px',
            zIndex : ZINDEX_NODE_DETAILS_FORM_DATA,
            position : 'absolute',
            right : '4px',
            height : height
        }}>
            <FieldContainer  style={{
                padding : '0px',
                backgroundColor: theme.palette.grey.background,
                height: '100%',
            }}>
                <div style={{display: 'flex', padding : '4px'}}>
                    <div style={{flexGrow: '1'}}></div>
                    <Tooltip title={'Close'}>
                        <IconButton datatest={'closeTreeButton'} size={'small'} onClick={this.closeTreePopup}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </div>

                <div style={{ height: "calc(100% - 56px)", maxWidth : `${formDataWidth - 28}px`, overflowY : 'auto', overflowX : 'auto', borderRadius : '4px', margin : '0px 8px 8px 8px', paddingBottom : '8px', backgroundColor: theme.palette.white.main}}>
                    {
                        getAccordianHeader(
                            formDataValueObject.title,
                            formData,
                            formDataValueObject.thumbnail,
                            {borderBottom : '1px solid', borderBottomColor : fade(theme.palette.secondary.main, 0.5)},
                            undefined

                        )
                    }
                    <Tree
                        location={location}
                        ontology={ontology}
                        configurations={configurations}
                        aliasesToIRIMap={aliasesToIRIMap}
                        aliasesMap={aliasesMap}
                        settings={settings}
                        resource={formData}
                        browseLanguage={{value : languageCode}}
                        theme={theme}
                        viewProperties={[]}
                        onConceptClick={(foundResource, details) => {
                            this.closeTreePopup();
                            this.setState({graphViewId : uuid4(), openVisualisation : undefined, openGraphViewerFor : undefined});
                            return navigateTo(foundResource, details, location);
                        }}
                    />
                </div>
            </FieldContainer>
        </div>;

    }

    renderVisualisationType = (part, index, customizations) => {
        let {theme, location, history} = this.props;
        let {formData, graphFullScreen, formDataPartIndex, workspace, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology} = this.state;
        if(!part[PART_DATA]) {
            part[PART_DATA] = {}
        }
        let selectedVisualisation = {value : part[PART_DATA] , backingObject : {[ID] : part[PART_DATA]}};
        const marginTop = 16;
        const containerHeight = getContainerHeight(part);
        const poUpHeightToUse = graphFullScreen === selectedVisualisation.value
            ? 'calc(100% - 32px)'
            : (Number(containerHeight.replace('px', '')) - marginTop )+ 'px';
        const containerStyle = graphFullScreen === selectedVisualisation.value ? {
                position: 'absolute',
                top : '0px',
                right : '0px',
                backgroundColor : theme.palette.white.main,
                zIndex : '1005',
                width : '100%',
                height: '100%'
            }
            : {
                position:'relative',
                height: containerHeight,
                padding : '16px',
                borderRadius : '4px',
                backgroundColor : theme.palette.white.main
            };
        return <>
            <div
                className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
                key={selectedVisualisation.value+"-"+index}
                datatest={'visualisation-'+index}
                style={getPartContainerStyle(part, containerStyle)}
            >
                <WithAdminEdit style={{marginTop : '-40px'}} data={{action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART, id : getPartId(index), part : part, index : index, customizations}}></WithAdminEdit>
                {formData && formDataPartIndex === index && this.renderResourceTreePopup(poUpHeightToUse)}
            <GraphView
                embedded={true}
                workspace={workspace}
                settings={settings}
                aliasesMap={aliasesMap}
                aliasesToIRIMap={aliasesToIRIMap}
                languageCode={getBrowseLanguageCode(browseLanguage)}
                configurations={configurations}
                ontology={ontology}
                location={location}
                onExpand={this.expandObject}
                data={[]}
                visualisation={selectedVisualisation}
                minimized={true}
                onClose={() => {
                    this.setState({graphViewId : uuid4(), openVisualisation : undefined, openGraphViewerFor : undefined});
                }}
                open={true}
                hideButton={true}
                onInteraction={() => {
                    this.closeTreePopup();
                }}
                onNodeClick={(node) => {
                    const ids = [node.graphNodeId];
                    this.expandObject(ids).then(expanded => {
                        let nonNullExpanded = nullToIdObjects(expanded, ids);

                        let customizationsObject  = {
                            hideMockAll : true,
                            hideTopLevelExpandCollapse : true,
                            hideFirstLevelExpandCollapse : true,
                            hideAddTypeBlock : true,
                            hideRemoveBlock : true,
                            // viewLanguageCode : languageCode,
                            readOnly: true,
                            onObjectFetch: async (id) => {
                                let expanded = await this.expandObject([id]);
                                return expanded[0];
                            },
                            autoFetchLinkedObject : true,
                            settings : settings
                        };

                        let expandedResource = nonNullExpanded[0];
                        getValuesObject(expandedResource, settings, aliasesToIRIMap, {value : getBrowseLanguageCode(browseLanguage)}, ontology).then((vo) => {
                            this.setState({formDataPartIndex : index ,formData : expandedResource, formDataValueObject : vo , customizations : customizationsObject})
                        })

                    })
                }}
                onFullScreen={(callback) => {
                    this.setState({graphFullScreen : selectedVisualisation.value}, callback);
                }}
                onFullScreenClose={(callback) => {
                    this.setState({graphFullScreen : undefined}, callback);
                }}

            />
        </div>
        </>;

    }

    openVisualisationPage = (selectedVisualisationId) => {
        let {location} = this.props;
        let path = getSiteHomePath(location) + '/view/graphVisualisation';
        let paramMap = {
            visualisationId : selectedVisualisationId
        }
        history.push(`${path}?${qs.stringify(paramMap)}`);
    }

    renderDataVisualisationType = (part, index, customizations) => {
        let {theme, location} = this.props;
        let {workspace, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology} = this.state;
        let partId = part[PART_DATA];
        if(!partId) {
            part[PART_DATA] = {}
        }
        let selectedVisualisation = {value : partId , backingObject : {[ID] : partId}};

        const mainStyle = {padding : '16px', position:'relative', height: getContainerHeight(part), borderRadius : '4px', backgroundColor : theme.palette.white.main};
        return <>
            <div
                className={ GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
                datatest={'dataVisualisation-'+index}
                style={getPartContainerStyle(part, mainStyle)}
            >
                <WithAdminEdit
                    style={{marginTop : '-32px'}}
                    data={{action : SETTINGS_EDIT_HOME_PAGE_EDIT_PART, id : getPartId(index), part:part, index:index, customizations}}
                ></WithAdminEdit>
                <DataVisualisationView
                    embedded={true}
                    workspace={workspace}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={getBrowseLanguageCode(browseLanguage)}
                    configurations={configurations}
                    ontology={ontology}
                    location={location}
                    visualisation={selectedVisualisation}
                    open={true}
                    hideButton={true}
                />
            </div>
        </>;

    }

    renderSpreadsheetType = (part, index, customizations) => {
        let {theme, location} = this.props;
        let {workspace, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology} = this.state;
        if(!part[PART_DATA]) {
            part[PART_DATA] = {}
        }
        let selected = {value : part[PART_DATA] , backingObject : {[ID] : part[PART_DATA]}};

        const mainStyle = {
            height: getContainerHeight(part),
            padding : '16px',
            borderRadius : '4px',
            backgroundColor : theme.palette.white.main,
        };
        return <>
            <div
                datatest={'spreadsheet-'+index}
                style={getPartContainerStyle(part, mainStyle)}
                className={GetEditableClassNames(SETTINGS_EDIT_HOME_PAGE_EDIT_PART, getPartId(index), undefined, customizations)}
            >
                <WithAdminEdit style={{marginTop: '-32px'}} data={{action: SETTINGS_EDIT_HOME_PAGE_EDIT_PART, id: getPartId(index), index: index, part: part, customizations}}></WithAdminEdit>
                <DataGridView
                    headerProvider={
                        () => getHeader(
                            " ",
                            undefined,
                            () => <><div style={{flexGrow : '1'}}/></>,
                            getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                            this.getLogoRenderer,
                            this.userPopperContent,
                            true,
                            {padding : '0px 32px 0px 64px'},
                            false
                        )
                    }
                    workspace={workspace}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    browseLanguage={getBrowseLanguageCode(browseLanguage)}
                    configurations={configurations}
                    ontology={ontology}
                    shapes={getShapesData(configurations)}
                    location={location}
                    onExpand={this.expandObject}
                    minimized={true}
                    onClose={() => {
                        history.push( getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`));
                    }}
                    embedded={true}
                    spreadsheet={selected}

                />
            </div>
        </>;

    }

    renderLinkToPageBlockType = (part, index, customizations) => {
        let {theme, location} = this.props;

        let {pageObjects, settings} = this.state;
        return renderLinkToPageBlock(part, pageObjects, settings, location, index, undefined, undefined, customizations);
    }

    getBanner = () => {
        let {theme} = this.props
        let {workspace, settings, browseLanguage} = this.state
        return renderBanner(settings, theme, workspace, browseLanguage, () => this.search(true));
    }

    getBannerTitle = () => {
        let {settings, workspace, browseLanguage} = this.state;

        return renderBannerTitle(settings, workspace, browseLanguage);
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        let {settings} = this.state
        return getUiLabelTranslation(settings, key, this.getBrowseLanguageObject(), defaultValue);
    }

    render() {
        let {location, theme, classes} = this.props;
        let {configurations, graphFullScreen, ontology, settings, searchLoading, apiError, loading, apiErrorResponse} = this.state;
        let {browseLanguage, aliasesMap, aliasesToIRIMap, viewProperties, workspace} = this.state;

        let isPageView = location.pathname.split("/").find(s => s === 'page');
        let isDataGridView = location.pathname.split("/").find(s => s === 'dataGrid');
        let isDataVisualisationView = location.pathname.split("/").find(s => s === 'dataVisualisation');
        let isGraphVisualisationView = location.pathname.split("/").find(s => s === 'graphVisualisation');
        let params = queryString.parse(this.props.location.search);

        let containerStyle = {backgroundColor: theme.palette.grey.background, minHeight : '100%'};
        let taxonomyPath = getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + SITE_TAXONOMY_PAGE_PATH;

        return (<>
                {
                    searchLoading &&
                    <div style={{zIndex : 1000, position : 'fixed', top : '50%', right : '50%'}}>
                        <CircularProgress color={'secondary'}/>
                    </div>
                }

            <GlobalsContext.Consumer>
                {(value) => {
                    const taxonomyViewPath = isTaxonomyViewPath(location);
                    const ontologyViewPath = isOntologyViewPath(location);
                    return <GlobalsContext.Provider value={{
                    ...value,
                    allConfigurations: value.getGlobalsItem(GLOBAL_CONTEXT_ALL_CONFIGURATIONS),
                    getUiLabelTranslationFor: this.getUiLabelTranslationFor,
                    getSettings : () => {
                        return this.state.settings;
                    },
                    onSettingsEdit: (data) => {
                        this.setState({settingsEditData : data})
                    },
                    settingsEditData : this.state.settingsEditData
                }}>
                        <Switch>



                        {apiError && <BackendErrorDialog open={apiError} error={apiErrorResponse}
                                                     handleClose={() => this.setState({
                                                         apiError: false,
                                                         apiErrorResponse: undefined
                                                     })}/>}
                    {loading && <ProcessingBackdrop loading={true}/>}
                    {configurations && this.renderSettingsDialog()}
                        {
                            taxonomyViewPath ?
                                settings && <React.Fragment key={this.getBrowseLanguageObject().value}><TaxonomyView
                                    ontology={ontology}
                                    aliasesMap={aliasesMap}
                                    aliasesToIRIMap={aliasesToIRIMap}
                                    browseLanguage={browseLanguage}
                                    settings={settings}
                                    configurations={configurations}
                                    viewProperties={viewProperties}
                                    graphViewProvider={this.graphViewer}
                                    headerProvider = { () => {
                                        return getHeader(
                                            this.showBannerOnAllPages() === false && (isPageView || isSearchPath(location)) ? (this.getBannerTitle() || " ") : " ",
                                            undefined,
                                            this.getRightButtonsRenderer,
                                            getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                                            this.getLogoRenderer,
                                            this.userPopperContent,
                                            true,
                                            {padding: '0px 32px 0px 64px'},
                                            false
                                        );
                                    }}
                                /></React.Fragment>
                                : ontologyViewPath ?
                                    settings && <React.Fragment key={this.getBrowseLanguageObject().value}><OntologyView
                                        middle={this.getHomePageCards}
                                        ontology={ontology}
                                        aliasesMap={aliasesMap}
                                        aliasesToIRIMap={aliasesToIRIMap}
                                        browseLanguage={browseLanguage}
                                        settings={settings}
                                        configurations={configurations}
                                        viewProperties={viewProperties}
                                        graphViewProvider={this.graphViewer}
                                        headerProvider = { () => {
                                            return getHeader(
                                                this.showBannerOnAllPages() === false && (isPageView || isSearchPath(location)) ? (this.getBannerTitle() || " ") : " ",
                                                undefined,
                                                this.getRightButtonsRenderer,
                                                getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                                                this.getLogoRenderer,
                                                this.userPopperContent,
                                                true,
                                                {padding: '0px 32px 0px 64px'},
                                                false
                                            );

                                        }}
                                    /></React.Fragment> :
                            isDataVisualisationView ?
                                <Route path={getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + DATA_VISUALISATION_VIEW_PAGE_PATH}
                                                             component={this.dataVisualisationViewer}/>
                                :
                            isDataGridView ?
                                <Route path={getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + DATA_GRID_VIEW_PAGE_PATH}
                                       component={this.dataGridViewer}/>
                                : <div key={this.getBrowseLanguageObject().value}
                                 style={{...containerStyle, paddingBottom: this.isGraphViewFullScreen() ? '0px' : '56px' }}>
                                {
                                    taxonomyViewPath ? <></> :
                                    getHeader(
                                        this.showBannerOnAllPages() === false && (isPageView || isSearchPath(location)) ? (this.getBannerTitle() || " ") : " ",
                                        undefined,
                                        this.getRightButtonsRenderer,
                                        getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                                        this.getLogoRenderer,
                                        this.userPopperContent,
                                        true,
                                        {padding: '0px 32px 0px 64px'},
                                        false
                                    )
                                }
                                    {
                                            <>
                                                <Route
                                                    path={getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + SITE_MENU_PAGE_PATH}
                                                    component={this.getMenuPageContent}/>
                                                <Route
                                                    path={getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + RESOURCE_PAGE_PATH}
                                                    component={this.getHomePageContent}/>
                                                <Route path={getInstanceAndDatasetParam() + ROUTE_APPS_EXPLORER_SITE + SITE_PATH}
                                                       component={this.getHomePageContent}/>
                                            </>
                                    }

                                <div>
                                    {
                                        this.isGraphViewFullScreen() === false && !ontologyViewPath && !taxonomyViewPath && settings && isPageView !== true && <>
                                            <div datatest={'bottomBar'} style={{
                                                display: 'flex',
                                                marginTop: '24px',
                                                paddingTop: theme.spacing(3),
                                                borderTop: '1px solid #9E9E9E'
                                            }}>
                                                <div style={{flexGrow: '1'}}></div>
                                                <div style={{marginRight: '24px'}}>
                                                    <Typography color={"inherit"} variant="caption" component={'span'}>
                                                        Built with EasyGraph from
                                                        <a href={'https://www.graphifi.com/'} target={'_blank'}>
                                                            <img alt={'Graphifi'} src={graphifiLogoBG} style={{
                                                                height: '20px',
                                                                marginLeft: '16px',
                                                                verticalAlign: 'middle'
                                                            }}/>
                                                        </a>
                                                    </Typography>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        }

                    </Switch>
                        {
                            isPageEditEnabled() && this.state.settingsEditData && <SettingsEditPanel
                                settingsEditData={this.state.settingsEditData}
                                settingsHeight={this.state.settingsHeight}
                                workspace={this.state.workspace}
                                configurations={configurations}
                                aliasesMap={aliasesMap}
                                aliasesToIRIMap={aliasesToIRIMap}
                                ontology={ontology}
                                browseLanguage={browseLanguage}
                                settings={settings}
                                theme={theme}
                                location={location}
                                onExpand={this.expandObject}
                                onResizeStop={(e, direction, ref, d) => {
                                    this.setState({
                                        settingsHeight: this.state.settingsHeight + d.height,
                                    });
                                }}
                                onApply={() => {
                                    setSettingsEditAction(undefined);
                                    this.setState({settingsEditData : undefined});
                                    this.saveWorkspaceAndSettings();
                                }}
                                onCancel={() => {
                                    setSettingsEditAction(undefined);
                                    this.setState({settingsEditData : undefined});
                                }}
                                onPageEditSave={this.props.onSaveSettings}
                                onMenuPageChange={() => {
                                    getPagesForWorkspace(workspace).then(pageObjects => {
                                        this.setState({pageObjects: pageObjects})
                                    })
                                }}
                                classes={classes}
                            />
                        }
                    </GlobalsContext.Provider>
            }}
            </GlobalsContext.Consumer>
            </>
        );
    }

}


Workspace.propTypes = {
    setGlobalsItem: PropTypes.func,
    getGlobalsItem: PropTypes.func,
    location: PropTypes.object,
    onThemeColorChange: PropTypes.func,
    onSaveSettings: PropTypes.func
};

export default withWidth()(withStyles(styles, {withTheme: true})(withRouter(Workspace)));

import React from "react";
import {isArray} from "lodash";
import TextField from "../../components/TextField";
import {
    centerVertically,
    getMockValueFromDefaultRegex,
    getPropertyValueForForm,
    registerMock
} from "../../components/util";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import {deleteButton} from "../../components/ShapeToForm/ArrayType";
import {EVENT_SHACL_VALIDATE} from "../../Constants";
import {validateFromSHACL} from "./validation-util";

export function withDelete(component, deleteButton, hasHelperText) {
    return <div style={{display : 'flex'}}>
        <div style={{flexGrow : '1', marginRight : '4px'}}>
            {component}
        </div>
        {deleteButton && centerVertically(deleteButton, hasHelperText ? {marginTop : '-16px'} : {})}
    </div>;
}

export function enableMultiline(value, customizations) {
    let isMultiline = customizations && customizations.multiline;
    if (!isMultiline && customizations?.multiline === 'auto') {
        isMultiline = value?.includes('\n');
    }
    return isMultiline;
}

class Text extends React.Component {
    static contextType = MockingContext

    constructor(props) {
        super(props)
        let value = getPropertyValueForForm(props);
        let isMultiline = enableMultiline(value, props.customizations);

        this.state = {
            defaultValue : value ? value : '',
            isMultiline ,
            components: []
        }
    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let {valueIndex, property, customizations} = this.props;
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName, property, valueIndex, customizations);
        }
        let value = this.generateRandomValue();
        if(this.inputRef) {
            this.inputRef.value = value;
        }
        this.onChange(value);
    }

    validate = (eventName) => {
        let {valueIndex, property, customizations} = this.props;
        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    generateRandomValue = () => {
        let mocking = this.context;
        let {property} = this.props;

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? mocking.getMockValue(property)
            : getMockValueFromDefaultRegex(property);

        return mockValue;
    }

    onChange = (value) => {
        let {valueIndex, property, onChange} = this.props;
        if(isArray(property.value)) {
            property.value[valueIndex] = value;
        } else {
            property.value = value;
        }
        //Set the value for property before invoking validation
        this.validate(EVENT_SHACL_VALIDATE);
        onChange();
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {label, autoFocus, valueIndex, property, onChange, customizations} = this.props;
        let {defaultValue, isMultiline, validationError} = this.state;

        return withDelete(
            <TextField

                multiline={isMultiline}
                rowsMax={isMultiline && customizations?.rowsMax ? customizations?.rowsMax : undefined}
                autoFocus={autoFocus}
                paperStyle={{padding : '0px'}}
                defaultValue={defaultValue}
                onChange={ (event) => this.onChange(event.target.value)}
                inputProps={{
                    ref: this.setRef
                }}
                error={validationError}
            />,
            customizations?.hideDelete || deleteButton(valueIndex, property, onChange)
        );
    }
}

export default Text;

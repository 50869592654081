import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import GridContainer from "../../components/GridContainer";
import Grid from "@material-ui/core/Grid";
import {getResourceId, toArray} from "../../components/util";
import MainHeaderBar from "../../components/MainHeaderBar";
import {getPlayButton} from "../apiplayground/CollectionDetails";
import {getUILabel, initSettingPath} from "./WorkspaceSettingsDialog";
import ErrorMessage from "../../components/ErrorMessage";
import PageEditor from "./PageEditor";
import {RadioGroup, TextField, Tooltip} from "@material-ui/core";
import PageView from "./PageView";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import Button from "@material-ui/core/Button";
import FieldContainer from "../../components/FieldContainer";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";


const HOME_PAGE_QUERIES_ERRORS = 'homePageQueriesErrors';

export function titleRenderer(theme, settings, onChange, requestObject) {
    return function(running, disableRunButton, onRun) {
        return <div style={{display: 'flex'}}>
            {getPlayButton(running, disableRunButton, theme, onRun)}
            <div style={{flexGrow : '1'}}>{getUILabel('Title', theme, settings, onChange, requestObject, ['title'])}</div>
        </div>
    }
}

export function initHomePageQueries(settings) {
    if (settings.homePage === undefined) {
        settings.homePage = {};
    }
    if (settings.homePage.queries === undefined) {
        settings.homePage.queries = []
    }
}

export function HomePageTemplateSetting({settings, onChange}) {
    const [hideLeftColumn, setHideLeftColumn] = useState(settings?.homePage?.hideLeftColumn === true ? "yes" : "no");

    return <FieldContainer>
        <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">Page Content Template</FormLabel>
                <RadioGroup style={{margin: '8px 0px'}} row aria-label="position" name="position"
                            value={hideLeftColumn} onChange={() => {
                    initSettingPath(settings, ['homePage']);
                    settings.homePage.hideLeftColumn = settings?.homePage.hideLeftColumn ? false : true;
                    setHideLeftColumn(settings?.homePage?.hideLeftColumn === true ? "yes" : "no");
                    //onChange && onChange();
                }}>
                    <FormControlLabel
                        value="yes"
                        control={<Radio datatest={'readOnlySwitchYes'} color="secondary"/>}
                        label="Content only"
                        labelPlacement="end"
                    />
                    <FormControlLabel
                        value="no"
                        control={<Radio datatest={'readOnlySwitchYes'} color="secondary"/>}
                        label="Column on left and content on right"
                        labelPlacement="end"
                    />
                </RadioGroup>
            </FormControl>
        </div>
        {
            hideLeftColumn === "yes" && <Grid container spacing={3}>
                <Grid item xs={12}>
                    <TextField
                        datatest={'middleContentWidth'}
                        placeholder={'e.g. 920px or 100%'}
                        variant={'outlined'}
                        label={'Content Width'}
                        defaultValue={settings?.homePage?.['middleContentWidth']}
                        onChange={(ev) => {
                            let value = ev.target.value;
                            initSettingPath(settings, ['homePage']);
                            settings.homePage['middleContentWidth'] = value;
                            //this.setState({});
                        }}
                        helperText={'Default is 100%'}
                    />
                    <TextField
                        datatest={'middleContentPadding'}
                        style={{marginLeft :'24px'}}
                        variant={'outlined'}
                        label={'Content Padding'}
                        defaultValue={settings?.homePage?.['middleContentPadding']}
                        onChange={(ev) => {
                            let value = ev.target.value;
                            initSettingPath(settings, ['homePage']);
                            settings.homePage['middleContentPadding'] = value;
                            //this.setState({});
                        }}
                        helperText={'For possible values search CSS value padding'}
                    />
                </Grid>
            </Grid>
        }
    </FieldContainer>;
}


class HomePageQueriesSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.setupChanged();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.setupChanged();
    }

    setupChanged = () => {
        let {onChange, settings} = this.props;
        this.validate();
        onChange(settings[HOME_PAGE_QUERIES_ERRORS]);
    }

    validate = () => {
        let {settings} = this.props;
        delete settings[HOME_PAGE_QUERIES_ERRORS];
        if(!settings.homePage || toArray(settings.homePage.queries).length === 0) {
            settings[HOME_PAGE_QUERIES_ERRORS] = {
                'all' : 'Please click open page editor button and add something to show on home page.'
            }
        }
    }

    editPageDialog = () => {
        let {pageWorkspace, pageWorkspaceSettings, editPageDialogOpen} = this.state;
        let {workspace, onExpand, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, shapes, location} = this.props;

        return settings && <PageEditor
            editPageDialogOpen={editPageDialogOpen}
            pageWorkspace={pageWorkspace}
            pageWorkspaceSettings={pageWorkspaceSettings}
            workspace={workspace}
            settings={settings}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            browseLanguage={browseLanguage}
            configurations={configurations}
            ontology={ontology}
            shapes={shapes}
            location={location}
            onSave={async () =>  {
                this.validate();
                this.setState({editPageDialogOpen: false});
            }}
            onExpand={onExpand}
            onClose={async () =>  {
                this.validate();
                this.setState({editPageDialogOpen: false});
            }}
            partsProvider={this.getQueryParts}
        />;

    }

    getQueryParts = () => {
        let {settings} = this.props;
        return toArray(settings.homePage?.queries);
    }

    render() {
        let {location, onChange, settings, workspace, browseLanguage, shapes, stepName, theme, aliasesMap, aliasesToIRIMap, configurations, ontology, onExpand} = this.props;
        let {editPageDialogOpen, loading, apiError, apiErrorResponse, showSpreadsheetSelectDialog, showVisualisationSelectDialog, showDataVisualisationSelectDialog, showLinkToPageSelectDialog} = this.state;
        initHomePageQueries(settings);
        return <>
            {loading && <ProcessingBackdrop loading={loading}/>}
            {
                editPageDialogOpen && this.editPageDialog()
            }
            <GridContainer key={(getResourceId(workspace)|| '')}>
                <Grid item xs={12} style={{paddingTop: 0}}>
                    <MainHeaderBar title={stepName}></MainHeaderBar>
                    {apiError && <BackendErrorDialog open={apiError} error={apiErrorResponse} handleClose={() => this.setState({apiError : false, apiErrorResponse : undefined})}/>}
                </Grid>
                <Grid item xs={12}>
                    <HomePageTemplateSetting settings={settings} onChange={onChange}/>
                </Grid>

                {settings[HOME_PAGE_QUERIES_ERRORS]?.all && <Grid item xs={12}><ErrorMessage error={settings[HOME_PAGE_QUERIES_ERRORS].all}/></Grid>}

                <Grid item xs={12}>
                    {
                        <>
                            {
                                <div style={{margin : '24px 0px'}}>
                                    <Tooltip title={'To edit the content shown on the page open the editor.'}>
                                        <Button datatest={'pageEditorButton'} style={{marginRight : '16px'}} size={'small'} onClick={() => this.setState({editPageDialogOpen : true})} variant={'contained'} color={'secondary'}>Edit Content</Button>
                                    </Tooltip>
                                </div>
                            }
                            <div>
                                <PageView
                                    settings={settings}
                                    aliasesMap={aliasesMap}
                                    browseLanguage={browseLanguage}
                                    pageWorkspaceId={getResourceId(workspace)}
                                    aliasesToIRIMap={aliasesToIRIMap}
                                    configurations={configurations}
                                    ontology={ontology}
                                    shapes={shapes}
                                    location={location}
                                    onExpand={onExpand}
                                    workspace={workspace}
                                    isPreview={true}
                                    partsProvider={this.getQueryParts}
                                />
                            </div>
                        </>
                    }

                </Grid>
            </GridContainer>
        </>;
    }

}

HomePageQueriesSetup.propTypes = {
    workspace: PropTypes.object,
    aliasesMap: PropTypes.object,
    settings: PropTypes.object,
    onChange: PropTypes.func,
    stepName: PropTypes.string,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    location: PropTypes.object,
    onExpand: PropTypes.func,

};

export default withStyles(styles, {withTheme: true})(HomePageQueriesSetup);

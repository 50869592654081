import React from "react";
import {TextField as OtherTextField, withStyles} from "@material-ui/core";
import TextField from "../TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {ALIAS_SYS_DEFAULT_LANGUAGE, EVENT_SHACL_VALIDATE, LANG, TYPE, VALUE, XSD_STRING} from "../../Constants";
import {
    getApiConfigurationResource,
    getDataValue,
    getLanguageCodesSuggestions,
    getLiteralDatatypeSuggestions,
    getLocalName,
    getMockValueFromDefaultRegex,
    getPropertyValueForForm,
    isDatatypeLiteral,
    langCodeToSelectValue,
    parseDatatypeLiteral,
    parseLangStringLiteral,
    registerMock
} from "../../components/util";
import MockingContext from "../../components/ShapeToForm/MockingContext";
import {deleteButton} from "../../components/ShapeToForm/ArrayType";
import {enableMultiline, withDelete} from "../../components/ShapeToForm/Text";
import {styles} from "../../components/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import {isString} from 'lodash';
import {
    getUiLabelTranslationFrom,
    UI_LABELS_INVALID_DATATYPE_VALUE,
    UI_LABELS_LITERAL_TYPE
} from "../../layouts/navigator/UILabel";
import EditIcon from "@material-ui/icons/EditOutlined";
import {validateFromSHACL} from "./validation-util";

export const TYPE_LANGUAGE = 'Language';
export const TYPE_DATATYPE = 'Datatype';

export const TYPES = [
    {value : TYPE_LANGUAGE},
    {value : TYPE_DATATYPE}
]


const Text = withStyles({
    root : {marginTop : '0px', marginBottom : '0px', paddingTop: '0px', paddingBottom: '0px'},
    marginDense : { paddingTop: '0px', paddingBottom: '0px'}
})(OtherTextField);

function getChip(theme, tooltip, label, onClick) {
    const StyledChip = withStyles({
        root : {
            backgroundColor : theme.palette.grey.level3,
            marginRight : '8px',
            color: theme.palette.white.main
        },
        labelSmall : {
            minWidth : '14px'
        },
        clickable : {
            '&:hover': {
                backgroundColor: theme.palette.grey.level3
            }
        }
    })(Chip)
    return <Tooltip title={tooltip}>
        <StyledChip
            datatest={label}
            onClick={onClick}
            label={label}
            size={"small"}
            deleteIcon={onClick && <EditIcon/>}
            onDelete={onClick}
        />
    </Tooltip>;
}

export function getLangChip(theme, langValueObject, onClick) {
    let title = langValueObject.label;
    let value = langValueObject.value;
    return getChip(theme, title, value, onClick);
}

function getTextBox(autoFocus, datatest) {
    return (params) => {
        let {InputProps, ...rest} = params
        return <Text
            {...rest}
            datatest={datatest}
            margin={"none"}
            variant="outlined"
            fullWidth
            InputProps={{
                ...InputProps,
                autoFocus : autoFocus,
                style: {paddingTop: '0px', paddingBottom: '0px'}
            }}

        ></Text>;
    };
}

const StyledLiteralAutocomplete = withStyles({
    root : {marginRight : '8px', width : '160px'},
    paper : { minWidth : '200px'}
})(Autocomplete)

export function getAutoComplete(options, value, onChange, onBlur, autoFocus, datatest) {
    return <StyledLiteralAutocomplete
        datatest={datatest}
        value={value}
        options={options}
        getOptionLabel={option => option.value ? getLocalName(option.value, false) : ''}
        renderOption={(option, { selected }) => (
            <React.Fragment>
                <div datatest={option.datatest}>{option.label}</div>
            </React.Fragment>
        )}
        onChange={onChange}
        onBlur={onBlur}
        renderInput={getTextBox(autoFocus, 'textBox')}
        size={"small"}
        disableClearable
        freeSolo={true}
    />;
}

function setLiteralPropertyValue(typeOrLangcode, value, property, valueIndex, isLang) {
    let obj = {
        [VALUE]: value
    }
    if(isLang) {
        obj[LANG] = typeOrLangcode.value
    } else {
        obj[TYPE] = typeOrLangcode.value
    }
    if(valueIndex === undefined) {
        property.value = obj
    } else {
        property.value[valueIndex] = obj
    }

}

function findOrCreateDatatype(configurations, aliasesMap, datatype) {
    let found = getLiteralDatatypeSuggestions(configurations, aliasesMap).find(t => t.value === datatype);
    if(found) {
        return found;
    } else {
        return {value: datatype, label: getLocalName(datatype, false)};
    }
}

export function getLiteralTypeSelect(classes, value, onChange, getUiLabelTranslationFor) {
    let inputLabelProps = classes
        ? {
            classes : {
                shrink: classes.smallWidthLabelShrink
            },
            disabled : false
        }
        : {};

    return <Autocomplete
        datatest={'autocompleteLiteralTypeSelect'}
        style={{width: '128px', marginRight: '8px'}}
        value={value}
        options={TYPES}
        getOptionLabel={option => option.value ? option.value : ''}
        onChange={onChange}
        renderInput={(params) => {
            return <OtherTextField
                label={getUiLabelTranslationFor?.(UI_LABELS_LITERAL_TYPE) || UI_LABELS_LITERAL_TYPE}
                {...params}
                margin={"dense"}
                variant="outlined"
                fullWidth
                InputLabelProps={inputLabelProps}
            />;
        }}
        size={"small"}
        disableClearable
    />;
}

class Literal extends React.Component {
    static contextType = MockingContext

    constructor(props) {
        super(props);
        let {configurations, aliasesMap, customizations} = props;
        let value = getPropertyValueForForm(props);
        const datatype = value && value.type ? value.type : (isString(value) && value[LANG] === undefined ? XSD_STRING : undefined);
        let defaultLanguageCode = langCodeToSelectValue(getApiConfigurationResource(configurations, aliasesMap)[ALIAS_SYS_DEFAULT_LANGUAGE]);


        let dataValue = getDataValue(value);
        let valueToSet = dataValue != undefined ? dataValue : value ? value : '';
        let isMultiline = enableMultiline(valueToSet, customizations);
        let languageCodesSuggestions = customizations?.languageSuggestions || getLanguageCodesSuggestions();

        this.state = {
            type : datatype ? TYPES[1] : TYPES[0],
            datatypeOrLanguageCode: datatype
                ? findOrCreateDatatype(configurations, aliasesMap, datatype)
                : (value &&  value.lang ? langCodeToSelectValue(value.lang) : defaultLanguageCode),
            value: valueToSet,
            editAutocomplete : false,
            defaultLanguageCode : defaultLanguageCode,
            defaultDatatype : getLiteralDatatypeSuggestions(configurations, aliasesMap).find(t => t.value === 'http://www.w3.org/2001/XMLSchema#string'),
            isMultiline,
            languageCodesSuggestions
        }
        let {property, valueIndex, onChange} = props;
        // if setting default language above then update in property
        if(!value && !value.type && !value.lang) {
            setLiteralPropertyValue(defaultLanguageCode, "", property, valueIndex, true);
            onChange();
        }

    }

    componentDidMount() {
        let {valueIndex, property} = this.props
        registerMock(valueIndex, property, this.onMock)
    }

    onMock = (eventName) => {
        let type = "";
        let value = ""
        let datatypeOrLanguageCode = ""
        let mocking = this.context
        let {property, configurations, aliasesMap, valueIndex, customizations} = this.props
        if(eventName === EVENT_SHACL_VALIDATE) {
            return this.validate(eventName, property, valueIndex, customizations);
        }

        let mockValue = mocking && mocking.getMockValue && mocking.getMockValue(property) !== undefined
            ? mocking.getMockValue(property)
            : getMockValueFromDefaultRegex(property)

        if(isDatatypeLiteral(mockValue)) {
            type = TYPES[1]
            let parsed = parseDatatypeLiteral(mockValue);
            value = parsed.value;
            let parsedType = parsed.type;
            datatypeOrLanguageCode = findOrCreateDatatype(configurations, aliasesMap, parsedType);
        } else {
            type = TYPES[0]
            let parsed = parseLangStringLiteral(mockValue);
            value = parsed.value;
            let parsedLang = parsed.lang;
            datatypeOrLanguageCode = langCodeToSelectValue(parsedLang)
        }
        this.setState({type, value, datatypeOrLanguageCode})
        this.updatePropertyValue(type, value, datatypeOrLanguageCode)
    }

    validate = (eventName) => {
        let {property, valueIndex, customizations} = this.props;

        let validationMessage = validateFromSHACL(eventName, property, valueIndex, customizations, this.inputRef);
        if (validationMessage) {
            this.setState({validationError: validationMessage.message});
        } else {
            this.setState({validationError: undefined});
        }
        return validationMessage;
    }

    updatePropertyValue = (type, value, datatypeOrLanguageCode) => {
        let {valueIndex, property, onChange, customizations} = this.props;
        let isLanguageTypeComponent = this.isLanguageType(type);

        setLiteralPropertyValue(datatypeOrLanguageCode, value, property, valueIndex, this.isLanguageType(type));
        let validationError = undefined;
        if(isLanguageTypeComponent === false) {
            if(datatypeOrLanguageCode.value.includes(':') === false) {
                validationError = getUiLabelTranslationFrom(UI_LABELS_INVALID_DATATYPE_VALUE, customizations);
            }
        }
        this.setState({validationError});
        this.validate(EVENT_SHACL_VALIDATE);
        customizations?.onValidationError?.(validationError);
        onChange({validationError});
    }


    getAutoComplete = (options) => {
        let {theme, customizations} = this.props;
        let {type, value, datatypeOrLanguageCode, editAutocomplete} = this.state;
        let isLanguageTypeComponent = this.isLanguageType(type);
        let autoFocusSelect = isLanguageTypeComponent === false && datatypeOrLanguageCode === '';
        if(editAutocomplete || datatypeOrLanguageCode === '') {
            return getAutoComplete(
                options,
                datatypeOrLanguageCode,
                (event, newDatatypeOrLanguageCode) => {
                    let newDatatypeOrLanguageCodeObject ;
                    if(isString(newDatatypeOrLanguageCode)) {
                        //if user types label and enter still try to find the matching datatype
                        if(isLanguageTypeComponent === false) {
                            newDatatypeOrLanguageCodeObject = options.find(o => o.label === newDatatypeOrLanguageCode);
                        }
                        if(!newDatatypeOrLanguageCodeObject) {
                            newDatatypeOrLanguageCodeObject = {
                                value: newDatatypeOrLanguageCode,
                                label: isLanguageTypeComponent ? newDatatypeOrLanguageCode : getLocalName(newDatatypeOrLanguageCode, false)
                            }
                        }
                    } else {
                        newDatatypeOrLanguageCodeObject = newDatatypeOrLanguageCode;
                    }
                    this.setState({datatypeOrLanguageCode: newDatatypeOrLanguageCodeObject, editAutocomplete : false});
                    this.updatePropertyValue(type, value, newDatatypeOrLanguageCodeObject );
                    this.inputRef.focus();
                },
                () => this.setState({editAutocomplete : false}),
                editAutocomplete || autoFocusSelect,
                'autocompleteEditDatatypeOrLanguageCode'
            );
        } else {
            if(isLanguageTypeComponent) {
                return getLangChip(theme, datatypeOrLanguageCode,() => this.setState({editAutocomplete:true}));
            } else {
                return getChip(theme, datatypeOrLanguageCode.value, datatypeOrLanguageCode.label,() => this.setState({editAutocomplete:true})) ;
            }
        }
    }

    isLanguageType = (type) => {
        return type.value === TYPE_LANGUAGE;
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        let {classes, label, autoFocus,valueIndex, property, onChange, configurations, aliasesMap, customizations} = this.props;
        let {type, languageCodesSuggestions, isMultiline, value, datatypeOrLanguageCode, defaultLanguageCode, defaultDatatype, validationError} = this.state;
        let typeSelect = this.isLanguageType(type)
            ? this.getAutoComplete(languageCodesSuggestions)
            : this.getAutoComplete(getLiteralDatatypeSuggestions(configurations, aliasesMap));



        return withDelete(

            <div style={{display : 'flex'}}>

            {
                <div datatest={'literalTypeSelect'}>
                    {
                        getLiteralTypeSelect(
                            classes,
                            type,
                            (ev, newLiteralType) => {
                                let defaultDatatypeOrLanguageCode = this.isLanguageType(newLiteralType)
                                    ? defaultLanguageCode
                                    : defaultDatatype;
                                this.setState({type: newLiteralType, datatypeOrLanguageCode: defaultDatatypeOrLanguageCode})
                                this.updatePropertyValue(newLiteralType, this.state.value, defaultDatatypeOrLanguageCode)
                            }
                        )
                    }
                </div>
            }
            <div datatest={'literalValueContainer'} style={{flexGrow : '1'}}>
                <TextField
                    multiline={isMultiline}
                    rowsMax={isMultiline && customizations?.rowsMax ? customizations?.rowsMax : undefined}
                    autoFocus={autoFocus}
                    paperStyle={{padding : '0px'}}
                    value={value}
                    onChange={ (event) => {
                        this.setState({value: event.target.value});
                        this.updatePropertyValue(type, event.target.value, datatypeOrLanguageCode)
                    }}
                    InputProps={{
                        startAdornment  : typeSelect
                    }}
                    inputProps={{
                        ref: this.setRef
                    }}
                    error={validationError}
                />
            </div>

            </div>,
            customizations?.hideDelete || deleteButton(valueIndex, property, onChange)
        );
    }
}

export default withStyles(styles, {withTheme: true})(Literal);

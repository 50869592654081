import React, {Component, useState} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import {
    centerVertically,
    getBrowseLanguageCode,
    getMultilingualValue,
    getRouteWithInstanceAndDataset,
    getShapesData,
    getUiLabelTranslationFromContext,
    toArray
} from "../../components/util";
import {
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    EXAMPLE_TYPE_TO_COLOR,
    ID,
    ROUTE_APPS_EXPLORER_SITE,
    TYPE
} from "../../Constants";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FieldContainer from "../../components/FieldContainer";
import UILabel, {EXAMPLE_TYPE_TO_TITLE_KEY, UI_LABELS_SEARCH} from "./UILabel";
import {ContentState, convertFromHTML, EditorState} from "draft-js";
import H3Title from "../../components/H3Title";
import {Grid, IconButton, Paper, Switch, TextField, Tooltip, Typography} from "@material-ui/core";
import {
    AssessmentOutlined,
    BubbleChartOutlined,
    DeleteOutline,
    DescriptionOutlined,
    ExpandLess,
    PlayCircleOutline,
    SwapHorizOutlined,
    SwapVertOutlined,
    ViewListOutlined,
    ViewWeekOutlined,
    WebOutlined
} from "@material-ui/icons";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {createRequest, getTitlePrefixComponent} from "../apiplayground/Examples";
import {getDefaultLanguage} from "./WorkspaceSettingsDialog";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {showRequest} from "../apiplayground/ScenarioDetails";
import {initHomePageQueries, titleRenderer} from "./HomePageQueriesSetup";
import {
    getPagesForWorkspace,
    HAS_PARTS,
    isExampleRequest,
    PART_DATA,
    TYPE_CONTENT,
    TYPE_DATA_VISUALISATION,
    TYPE_GRID,
    TYPE_LINKS_TO_PAGES_BLOCK,
    TYPE_SPREADSHEET,
    TYPE_VISUALISATION
} from "./PagesSetup";
import MenuListComposition from "../../components/MoreMenuIcon";
import AddIcon from "@material-ui/icons/Add";
import PageContentEditor from "./PageContentEditor";
import uuid4 from "uuid/v4";
import VisualisationSelectionDialog from "./VisualisationSelectionDialog";
import GraphView, {getSavedVisualisationsOptions} from "./GraphView";
import SpreadsheetSelectionDialog from "./SpreadsheetSelectionDialog";
import DataGridView, {getSavedSpreadsheetsOptions} from "./DataGridView";
import {getHeader} from "../../components/header/APIPlaygroundHeader";
import history from "../../history";
import DataVisualisationView, {getSavedDataVisualisationsOptions} from "./DataVisualisationView";
import PageLinkSettingsDialog from "./PageLinkSettingsDialog";
import {getSiteHomePath} from "./Workspace";
import ResultCard, {CARD_WIDTH} from "./ResultCard";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {getPageParts} from "./PageView";
import {withWhiteBorder} from "./BasicSetup";
import FormLabel from "@material-ui/core/FormLabel";
import ErrorMessage from "../../components/ErrorMessage";


export function getContainerHeight(part) {
    return part['containerHeight'] || '600px';
}

export function getContainerWidth(part) {
    return part['containerWidth'] || CARD_WIDTH+'px';
}

function GetWidthHeightSetup({part, index, mode, parentPart}) {
    const [refresh, setRefresh] = useState();

    const value = parentPart?.['widthType'] || WIDTH_TYPE_CSS;

    return isColumnMode(mode) ? <div>
            {
                value === WIDTH_TYPE_CSS ?
                    <TextField
                        datatest={'containerWidth-' + index}
                        placeholder={'e.g. 500px 20%'}
                        variant={'outlined'}
                        label={'Width'}
                        defaultValue={part['containerWidth']}
                        onChange={(ev) => {
                            let value = ev.target.value;
                            part['containerWidth'] = value;
                            //this.setState({});
                        }}
                        size={'small'}
                    />
                    : <TextField
                        type={'number'}
                        inputProps={{
                            min : 1,
                            step : 1,
                            max : 12
                        }}
                        datatest={'columnSpan-' + index}
                        placeholder={'Number from 1 to 12'}
                        variant={'outlined'}
                        label={'Card Span'}
                        defaultValue={part['columnSpan']}
                        onChange={(ev) => {
                            let value = ev.target.value;
                            part['columnSpan'] = value;
                            //this.setState({});
                        }}
                        size={'small'}
                        style={{width : '120px'}}
                    />
            }
        </div>
        :
        <TextField
            datatest={'containerHeight-' + index}
            style={{marginLeft: '16px'}}
            placeholder={'e.g. 500px'}
            variant={'outlined'}
            label={'Container Height'}
            defaultValue={getContainerHeight(part)}
            onChange={(ev) => {
                let value = ev.target.value;
                part['containerHeight'] = value;
                //this.setState({});
            }}
            size={'small'}
        ></TextField>;
}

export function WithPlaceHolder({contentProvider}) {
    const [load, setLoad] = useState(false);
    return load ? contentProvider() : <div style={{textAlign : 'center'}}><Button onClick={() => setLoad(true)} variant={'outlined'}>Preview</Button> </div>
}

export function renderVisualisation(part, index) {
    let {
        theme,
        workspace,
        onExpand,
        settings,
        aliasesMap,
        aliasesToIRIMap,
        browseLanguage,
        configurations,
        ontology,
        location,
        mode,
        parentPart
    } = this.props;
    let {visualisationsData} = this.state;
    if (!part[PART_DATA]) {
        part[PART_DATA] = {}
    }
    let selectedVisualisation = toArray(visualisationsData).find(v => v.value === part[PART_DATA]);

    const containerStyle = {
        position: 'relative',
        margin: '0px 16px',
        height: getContainerHeight(part) ,
        padding: '16px',
        borderRadius: '4px',
        backgroundColor: theme.palette.white.main
    };
    if(isColumnMode(mode)) {
        containerStyle.width = getContainerWidth(part)
    }
    let content = !selectedVisualisation ||
        <div style={containerStyle}>
            <GraphView
                containerId={'pageEditor-'+index}
                embedded={true}
                workspace={workspace}
                settings={settings}
                aliasesMap={aliasesMap}
                aliasesToIRIMap={aliasesToIRIMap}
                languageCode={getBrowseLanguageCode(browseLanguage)}
                configurations={configurations}
                ontology={ontology}
                location={location}
                onExpand={onExpand}
                data={[]}
                visualisation={selectedVisualisation}
                minimized={true}
                onClose={() => {
                    this.setState({
                        graphViewId: uuid4(),
                        openVisualisation: undefined,
                        openGraphViewerFor: undefined
                    });
                }}
                open={true}
                hideButton={true}
            />
        </div>

    return withPartBar.call(
        this, index, mode, part, 'Graph Visualisation', GRAPH_ICON, content, () => <GetWidthHeightSetup part={part} index={index} mode={mode} parentPart={parentPart}/>
    );
}

export function renderDataVisualisation(part, index) {
    let {
        theme,
        workspace,
        onExpand,
        settings,
        aliasesMap,
        aliasesToIRIMap,
        browseLanguage,
        configurations,
        ontology,
        location,
        mode,
        parentPart
    } = this.props;
    let {dataVisualisationsData} = this.state;
    if (!part[PART_DATA]) {
        part[PART_DATA] = {}
    }
    let selectedVisualisation = toArray(dataVisualisationsData).find(v => v.value === part[PART_DATA]);

    const containerStyle = {
        position: 'relative',
        margin: '0px 16px',
        height: getContainerHeight(part) ,
        padding: '16px',
        borderRadius: '4px',
        backgroundColor: theme.palette.white.main
    };
    let content = !selectedVisualisation ||
        <div datatest={'dataVisualisation-'+index} style={containerStyle}>
            <DataVisualisationView
                embedded={true}
                visualisation={selectedVisualisation}
                workspace={workspace}
                settings={settings}
                aliasesMap={aliasesMap}
                aliasesToIRIMap={aliasesToIRIMap}
                browseLanguage={getBrowseLanguageCode(browseLanguage)}
                configurations={configurations}
                ontology={ontology}
                location={location}
                onExpand={onExpand}
                visualisation={selectedVisualisation}
                minimized={true}
                onClose={() => {}}
                open={true}
                hideButton={true}

            />
        </div>
    return withPartBar.call(
        this, index, mode, part, 'Data Visualisation', DATA_VIS_ICON, content, () => <GetWidthHeightSetup part={part} index={index} mode={mode} parentPart={parentPart}/>
    );
}

export function renderLinkToPagesBlock(part, index) {
    let {
        theme,
        workspace,
        onExpand,
        settings,
        aliasesMap,
        aliasesToIRIMap,
        browseLanguage,
        configurations,
        ontology,
        location,
        mode,
        parentPart
    } = this.props;
    let {pageObjects} = this.state;
    if (!part[PART_DATA]) {
        part[PART_DATA] = []
    }
    let selectedPageObjects = part[PART_DATA].map(v =>  {
        const found = toArray(pageObjects).find(pd => pd[ID] === v[ID]);
        return found;
    }).filter(o => o);

    let content = <Grid container spacing={3}>
        {
            selectedPageObjects.map((pageObject, index) => {
                let title = getMultilingualValue(pageObject[ALIAS_MANAGEMENT_TITLE], getDefaultLanguage(settings))
                return  <Grid key={title+index} item>
                    <ResultCard variant={'button'} title={title} url={`${getSiteHomePath(location)}/page/${title}`}/>
                </Grid>;
            })
        }
    </Grid>;
    return withPartBar.call(
        this, index, mode, part, 'Pages Block', PAGES_BLOCK_ICON, content, () => <GetWidthHeightSetup part={part} index={index} mode={mode} parentPart={parentPart}/>
    );
}


export function renderSpreadsheet(part, index) {
    let {
        theme,
        workspace,
        onExpand,
        settings,
        aliasesMap,
        aliasesToIRIMap,
        browseLanguage,
        configurations,
        ontology,
        location,
        mode,
        parentPart
    } = this.props;
    let {spreadsheetsData} = this.state;
    if (!part[PART_DATA]) {
        part[PART_DATA] = {}
    }
    let selected = toArray(spreadsheetsData).find(v => v.value === part[PART_DATA]);


    const containerStyle = {
        position: 'relative',
        margin: '0px 16px',
        height: getContainerHeight(part) ,
        padding: '16px',
        borderRadius: '4px',
        backgroundColor: theme.palette.white.main
    };
    let content = <>
        {selected === undefined && <H3Title color={'secondary'}>Spreadsheet not found</H3Title> }
        {!selected ||
            <div style={containerStyle}>
                <WithPlaceHolder
                    contentProvider={() => {
                        return <DataGridView
                            headerProvider={
                                () => getHeader(
                                    " ",
                                    undefined,
                                    () => <><div style={{flexGrow : '1'}}/></>,
                                    getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`),
                                    this.getLogoRenderer,
                                    this.userPopperContent,
                                    true,
                                    {padding : '0px 32px 0px 64px'},
                                    false
                                )
                            }
                            workspace={workspace}
                            settings={settings}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={aliasesToIRIMap}
                            browseLanguage={getBrowseLanguageCode(browseLanguage)}
                            configurations={configurations}
                            ontology={ontology}
                            shapes={getShapesData(configurations)}
                            location={location}
                            onExpand={this.expandObject}
                            minimized={true}
                            onClose={() => {
                                history.push( getRouteWithInstanceAndDataset(`${ROUTE_APPS_EXPLORER_SITE}/${this.getSiteLabel()}`));
                            }}
                            embedded={true}
                            spreadsheet={selected}

                        />;
                    }}
                />
            </div>
        }
    </>;
    return withPartBar.call(
        this, index, mode, part, 'Sheet', SPREADSHEET_ICON, content, () => <GetWidthHeightSetup part={part} index={index} mode={mode} parentPart={parentPart}/>
    );

}

function getPageContentEditor(part, valueKey, theme) {
    try {
        return <PageContentEditor
            contentState={part[PART_DATA][valueKey]}
            onChange={(contentState) => {
                part[PART_DATA][valueKey] = contentState;
            }}
            colors={getColorsArray(theme)}
        />;
    } catch (e) {
        return <div>Failed to load editor</div>
    }
}

function isColumnMode(mode) {
    return mode === 'column';
}

function toggleExpandCollapse(isCollapsed, index, collapsedContentIds) {
    if(isCollapsed) {
        let ids = collapsedContentIds.filter(ind => ind !== index);
        this.setState({collapsedContentIds: ids});
    } else {
        collapsedContentIds.push(index);
        this.setState({collapsedContentIds: collapsedContentIds});
    }
}

function getExpandCollapseButton(mode, isCollapsed, collapsedContentIds, index) {
    let iconStyle = isColumnMode(mode) ? {} : {};
    let button = isCollapsed ? <IconButton
            onClick={() => toggleExpandCollapse.call(this, isCollapsed, index, collapsedContentIds)}
            size={'small'}
            datatest={'componentExpandCollapseButton'}
        >
            <ExpandMoreIcon style={iconStyle}/>
        </IconButton>
        : <IconButton
            datatest={'componentExpandCollapseButton'}
            onClick={() => toggleExpandCollapse.call(this, isCollapsed, index, collapsedContentIds)}
            size={'small'}
            style={iconStyle}
        >
            <ExpandLess/>
        </IconButton>;
    return button;
}

function withPartBar(index, mode, part, title, icon, content, otherActionsProvider, datatestkey) {

    return <FieldContainer style={{marginBottom: '16px'}} key={index} datatest={`${datatestkey||"pageTabContent"}-` + index}>
        <div>
            {this.getDeleteButton(index, title, part, icon)}
        </div>
        {
            this.isCollapsed(index) ? <></> :
            <div style={{display: 'flex', flexDirection: 'column', gap: '16px', paddingTop: '16px'}}>
                <div style={{display: 'flex', gap : '16px'}}>
                    {centerVertically(<ContainerStyleSettings mode={mode} index={index} part={part}/>)}
                    {otherActionsProvider?.()}
                </div>
                <div className={'content'} style={{padding: '16px', border: '1px solid ' + this.props.theme.palette.white.main}}>
                    {content}
                </div>
            </div>
        }
    </FieldContainer>;
}

export function renderContent(part, index) {
    let { theme, mode, settings, partsProvider} = this.props;

    if (!part[PART_DATA]) {
        part[PART_DATA] = {}
    }

    return withPartBar.call(
            this,
            index,
            mode,
            part,
            'Content',
            CONTENT_ICON,
                <UILabel
                    valueObject={part[PART_DATA]}
                    onChange={() => {
                    }}
                    settings={settings}
                    componentRenderer={(label, valueKey) => {
                        if (!part[PART_DATA][valueKey]) {
                            const contentState = EditorState.createWithContent(
                                ContentState.createFromBlockArray(
                                    convertFromHTML('<p>Click here to edit page content.</p>')
                                )
                            );
                            part[PART_DATA][valueKey] = contentState;
                        }

                        return <FieldContainer datatest={'content-' + label}>
                            <H2Title style={{marginBottom: '8px'}} title={label}></H2Title>
                            <Paper>
                                {getPageContentEditor(part, valueKey, theme)}
                            </Paper>
                        </FieldContainer>;
                    }}
                />,
        undefined,
        'contentEditor'
        );
}

export function renderGrid(part, index) {
    let {settings, mode, theme, pageWorkspace, shapes, pageWorkspaceSettings, workspace, aliasesMap, aliasesToIRIMap, configurations, browseLanguage, ontology, location, onExpand} = this.props;
    if (!part[PART_DATA]) {
        part[PART_DATA] = []
    }


    let value = part['widthType'] || WIDTH_TYPE_CSS;

    return withPartBar.call(
        this,
        index,
        mode,
        part,
        'Columns',
        COLUMNS_ICON,
        <PageEditor
            theme={theme}
            mode={'column'}
            dialog={false}
            editPageDialogOpen={false}
            pageWorkspace={pageWorkspace}
            pageWorkspaceSettings={pageWorkspaceSettings}
            workspace={workspace}
            settings={settings}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            browseLanguage={browseLanguage}
            configurations={configurations}
            ontology={ontology}
            shapes={shapes}
            location={location}
            onSave={this.saveSettings}
            onExpand={onExpand}
            onClose={async () =>  {
                await this.loadWorkspaceForPage(pageWorkspace[ID]).then(() => {
                    this.setState({editPageDialogOpen: false});
                })
            }}
            parentPart={part}
            partsProvider={() => {
                return part[PART_DATA];
            }}
        />,
        () => {
            return <>
                {
                    centerVertically(
                        <TextField
                            datatest={'containerHeight-' + index}
                            placeholder={'e.g. 500px'}
                            variant={'outlined'}
                            label={'Container Height'}
                            defaultValue={getContainerHeight(part)}
                            onChange={(ev) => {
                                let value = ev.target.value;
                                part['containerHeight'] = value;
                                //this.setState({});
                            }}
                            size={'small'}
                        ></TextField>
                    )
                }
            {
                centerVertically(
                    <FormControl size={"small"} component="fieldset" fullWidth>
                        <FormLabel component="span"><Typography variant={'caption'} component={'span'}>Column Width Type</Typography></FormLabel>
                        <RadioGroup

                            name="classType"
                            value={value}
                            onChange={(event) => {
                                const {target: {name, value}} = event;
                                part['widthType'] = value;
                                this.setState({});
                            }}
                            row={true}
                        >
                            <FormControlLabel
                                value={WIDTH_TYPE_CSS}
                                control={<Radio/>}
                                label={<Typography variant={'caption'} component={'span'}>CSS</Typography>}
                            />
                            <FormControlLabel
                                value={WIDTH_TYPE_CARDS_BASED}
                                control={<Radio/>}
                                label={<Typography variant={'caption'} component={'span'}>Relative To Data Cards</Typography>}
                            />
                        </RadioGroup>
                    </FormControl>, {marginLeft : '16px'}
                )
            }</>;
        }
    );
}

function getConnectorMenuItem(index, type, label) {
    return {
        label: label,
        onClick: () => {
            this.addRequest(index, type)
        }
    };
}

const COLUMNS_ICON = <ViewWeekOutlined/>;
const CONTENT_ICON = <DescriptionOutlined/>;
const GRAPH_ICON = <BubbleChartOutlined/>;
const DATA_VIS_ICON = <AssessmentOutlined/>;

const PAGES_BLOCK_ICON = <WebOutlined/>;

const REQUEST_ICON =
    <PlayCircleOutline/>;

const SPREADSHEET_ICON =
    <ViewListOutlined/>;

export function pageComponentSelector(index, pageComponentsArray, theme, mode) {

    let id = '1';
    const isColumn = isColumnMode(mode);
    let buttonStyle = isColumn ? {marginBottom: '8px'} : {marginRight: '8px'};
    const addContainerStyle =  isColumn ? {marginBottom: '16px'} : {marginRight: '16px'};
    return <div datatest={'addPageComponent-' + index + "-" + mode}
                style={{textAlign: 'center', padding: '8px', display: 'flex'}}>
        <Paper elevation={0} style={{
            display: 'flex',
            flexDirection: (isColumn ? 'column' : undefined),
            padding: (isColumn ? '8px' : '16px')
        }}>
            {centerVertically(<Typography component={'span'}
                                          variant={"caption"}>Add </Typography>, addContainerStyle)}
            <Tooltip title={'Add Content'}>
                <IconButton
                    color={'primary'}
                    style={buttonStyle}
                    datatest={'addContentButton-' + index}
                    size={'small'}
                    onClick={() => {
                        let {collapsedContentIds} = this.state;
                        let part = {
                            uuid: uuid4(),
                            [TYPE]: TYPE_CONTENT
                        };
                        pageComponentsArray.splice(index, 0, part);
                        //On add we also need to adjust expanded panel indexes
                        let collapsedContentIds1 = collapsedContentIds.map(id => {
                            if (id >= index) {
                                return id + 1;
                            } else {
                                return id;
                            }
                        });
                        this.setState({collapsedContentIds: collapsedContentIds1});
                    }}
                >{CONTENT_ICON}</IconButton>
            </Tooltip>
            {
                isColumnMode(mode) ? <></> :
                    <Tooltip title={'Add Columns'}>
                        <IconButton
                            color={'primary'}
                            style={buttonStyle}
                            datatest={'addColumnsButton-' + index}
                            size={'small'}
                            onClick={() => {
                                let {collapsedContentIds} = this.state;
                                let part = {
                                    uuid: uuid4(),
                                    [TYPE]: TYPE_GRID
                                };
                                pageComponentsArray.splice(index, 0, part);
                                //On add we also need to adjust expanded panel indexes
                                let collapsedContentIds1 = collapsedContentIds.map(id => {
                                    if (id >= index) {
                                        return id + 1;
                                    } else {
                                        return id;
                                    }
                                });
                                this.setState({collapsedContentIds: collapsedContentIds1});
                            }}
                        >{COLUMNS_ICON}</IconButton>
                    </Tooltip>
            }
            {
                isColumn ? <></> :
                    <Tooltip title={'Add Graph Visualisation'}>
                        <IconButton
                            color={'primary'}
                            style={buttonStyle}
                            datatest={'addVisualisationButton-' + index}
                            size={'small'}
                            onClick={() => {
                                this.setState({
                                    showVisualisationSelectDialog: true,
                                    showVisualisationSelectDialogIndex: index
                                });
                            }}
                        >{GRAPH_ICON}</IconButton>
                    </Tooltip>
            }
            <Tooltip title={'Add Data Visualisation'}>
                <IconButton
                    color={'primary'}
                    style={buttonStyle}
                    datatest={'addDataVisualisationButton-' + index}
                    size={'small'}
                    onClick={() => {
                        this.setState({
                            showDataVisualisationSelectDialog: true,
                            showDataVisualisationSelectDialogIndex: index
                        });
                    }}
                >{DATA_VIS_ICON}</IconButton>
            </Tooltip>
            <Tooltip title={'Add Spreadsheet'}>
                <IconButton
                    color={'primary'}
                    style={buttonStyle}
                    datatest={'addSpreadsheetButton-' + index}
                    size={'small'}
                    onClick={() => {
                        this.setState({showSpreadsheetSelectDialog: true, showSpreadsheetSelectDialogIndex: index});
                    }}
                >{SPREADSHEET_ICON}</IconButton>
            </Tooltip>
            <Tooltip title={'Add Pages Block'}>
                <IconButton
                    color={'primary'}
                    style={buttonStyle}
                    datatest={'addLinkToPageButton-' + index}
                    size={'small'}
                    onClick={() => {
                        this.setState({showLinkToPageSelectDialog: true, showLinkToPageSelectDialogIndex: index});
                    }}
                >{PAGES_BLOCK_ICON}</IconButton>
            </Tooltip>
            {
                isColumn ? <></> :
                    <MenuListComposition
                        buttonRenderer={(onClick) => {
                            return <Tooltip title={'Add Request'}>
                                <IconButton
                                    color={'primary'}
                                    style={buttonStyle}
                                    size={'small'}
                                    onClick={onClick}
                                    datatest={'addRequestButton-' + index}
                                >{REQUEST_ICON}</IconButton></Tooltip>;
                        }}
                        menuDatatest={'addRequestMiddleMenu-' + index}
                        items={[
                            getConnectorMenuItem.call(this, index, ALIAS_SYS_EXAMPLE_TYPE_SEARCH, getUiLabelTranslationFromContext(this, UI_LABELS_SEARCH)),
                        ]}
                        buttonIcon={
                            <><AddIcon
                                datatest={'addRequestMiddleButton-' + index}
                                style={{
                                    fontSize: '20px',
                                    borderRadius: '50%',
                                    backgroundColor: theme.palette.secondary.main,
                                    border: '1px solid rgba(158 158 158 .1)',
                                    padding: '0px',
                                    color: theme.palette.white.main,
                                    marginRight: '8px'
                                }}
                            /> <Typography component={'span'} variant={"caption"}>Add Request</Typography>
                            </>
                        }
                        buttonSize={"small"}
                    />
            }
        </Paper>
        <div style={{flexGrow: '1'}}></div>
    </div>;
}

export function isVisualisation(p) {
    return p[TYPE] === TYPE_VISUALISATION;
}

export function isSpreadsheet(p) {
    return p[TYPE] === TYPE_SPREADSHEET;
}

export function isDataVisualisation(p) {
    return p[TYPE] === TYPE_DATA_VISUALISATION;
}

export function isLinksToPagesBlock(p) {
    return p[TYPE] === TYPE_LINKS_TO_PAGES_BLOCK;
}

function getColorsArray(theme) {
    let colors = [];
    [theme.palette.primary, theme.palette.secondary, theme.palette.error, theme.palette.success, theme.palette.link].forEach(obj => {
        for(let [key, value] of Object.entries(obj)) {
            colors.push(value);
        }
    })
    return colors;
}

export function isContentType(p) {
    return p[TYPE] === TYPE_CONTENT;
}

export function isGridType(p) {
    return p[TYPE] === TYPE_GRID;
}

export const QUERY_TYPE_DATA_QUERY = "dataQuery";
export const QUERY_TYPE_API_EXAMPLE = "apiExample";
export const WIDTH_TYPE_CSS = "WIDTH_TYPE_CSS";
export const WIDTH_TYPE_CARDS_BASED = "WIDTH_TYPE_CARDS_BASED";

export function renderQueryTypeOptions(part, readOnly=false) {
    return <div style={{display: 'flex'}}>
        <FormControl component="fieldset" fullWidth>
            <RadioGroup
                aria-label="Choose Request Option"
                name="classType"
                defaultValue={part['queryType'] || QUERY_TYPE_API_EXAMPLE}
                onChange={(event) => {
                    const {target: {name, value}} = event;
                    part['queryType'] = value;
                }}
                row={true}
            >
                <FormControlLabel value={QUERY_TYPE_API_EXAMPLE}
                                  control={<Radio disabled={readOnly}/>}
                                  label={<H3Title title={"API Example"}/>}/>
                <FormControlLabel value={QUERY_TYPE_DATA_QUERY}
                                  control={<Radio disabled={readOnly}/>}
                                  label={<H3Title title={'Data Query'}/>}/>
            </RadioGroup>
        </FormControl>
        <div style={{flexGrow: '1'}}></div>
    </div>;
}

export function PageWidthSettings({partsProvider, pageWorkspaceSettings, onChange}) {
    return <div style={{marginRight: '6px'}}>
        {
            partsProvider ? <></> :
                <TextField
                    datatest={'pageWidth'}
                    style={{width : '280px'}}
                    placeholder={'e.g. 920px or 90%'}
                    variant={'outlined'}
                    label={'Page Width'}
                    defaultValue={pageWorkspaceSettings['pageWidth']}
                    onChange={(ev) => {
                        let value = ev.target.value;
                        pageWorkspaceSettings['pageWidth'] = value;
                        onChange && onChange();
                        //this.setState({});
                    }}
                    helperText={'To align the content in center of the page you can set this value.'}
                ></TextField>
        }
    </div>;
}

export const PART_CONTAINER_STYLE_KEY = 'containerStyle';

export function ContainerStyleSettings({mode, index, part}) {
    const [error, setError] = useState();

    return <div>
        <TextField
            style={{ minWidth: (isColumnMode(mode) ? '180px' : '280px')}}
            datatest={'containerStyle-' + index}
            variant={'outlined'}
            label={'Container Style'}
            defaultValue={part[PART_CONTAINER_STYLE_KEY] || `{}`}
            error={error}
            inputProps={{
                style : {resize : 'vertical', overflow : 'auto'}
            }}
            onChange={(ev) => {
                let value = ev.target.value;
                try {
                    JSON.parse(value);
                    part[PART_CONTAINER_STYLE_KEY] = value;
                    setError(undefined)
                } catch (e) {
                    setError('Should be a valid JSON object')
                }
            }}
            size={'small'}
            multiline={true}
            rows={3}
        />
        {error &&  <ErrorMessage error={error}/> }
    </div>;
}

class PageEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsedContentIds : props.partIndex !== undefined ? [] : getPageParts(props.partsProvider, props.pageWorkspaceSettings).map((p, i) => i)
        }
    }

    componentDidMount() {
        const {workspace} = this.props;
        getSavedVisualisationsOptions(workspace).then(r => {
            this.setState({visualisationsData: r});
        });
        getSavedDataVisualisationsOptions(workspace).then(r => {
            this.setState({dataVisualisationsData: r});
        });
        getSavedSpreadsheetsOptions(workspace).then(r => {
            this.setState({spreadsheetsData: r});
        });
        getPagesForWorkspace(workspace).then(r => {
            this.setState({pageObjects : r});
        });
    }

    renderVisualisationSelectDialog = () => {
        const {workspace, pageWorkspaceSettings, partsProvider} = this.props;
        this.initHasPartsIfNeeded();

        return <VisualisationSelectionDialog
            workspace={workspace}
            onCancel={() => {
                this.setState({showVisualisationSelectDialog : false, showVisualisationSelectDialogIndex : undefined});
            }}
            onSelect={(selectedVisualisation) => {
                let {collapsedContentIds, showVisualisationSelectDialogIndex} = this.state;
                let index = showVisualisationSelectDialogIndex;
                let part = {
                    uuid :  uuid4(),
                    [TYPE] : TYPE_VISUALISATION,
                    data : selectedVisualisation.backingObject[ID]
                };
                getPageParts(partsProvider, pageWorkspaceSettings).splice(index, 0, part);
                //On add we also need to adjust expanded panel indexes
                let collapsedContentIds1 = collapsedContentIds.map(id => {
                    if(id >= index) {
                        return id + 1;
                    } else {
                        return id;
                    }
                });
                this.setState({collapsedContentIds : collapsedContentIds1});
                this.setState({showVisualisationSelectDialog : false, showVisualisationSelectDialogIndex : undefined});
            }}
        />;
    }

    initHasPartsIfNeeded = () => {
        const {pageWorkspaceSettings, partsProvider} = this.props;
        if (!partsProvider && !pageWorkspaceSettings[HAS_PARTS]) {
            pageWorkspaceSettings[HAS_PARTS] = [];
        }
    }

    renderDataVisualisationSelectDialog = () => {
        const {workspace, pageWorkspaceSettings, partsProvider} = this.props;
        this.initHasPartsIfNeeded();

        return <VisualisationSelectionDialog
            workspace={workspace}
            label={'Select Data Visualisation'}
            optionsFunction={getSavedDataVisualisationsOptions}
            onCancel={() => {
                this.setState({showDataVisualisationSelectDialog : false, showDataVisualisationSelectDialogIndex : undefined});
            }}
            onSelect={(selectedVisualisation) => {
                let {collapsedContentIds, showDataVisualisationSelectDialogIndex} = this.state;
                let index = showDataVisualisationSelectDialogIndex;
                let part = {
                    uuid :  uuid4(),
                    [TYPE] : TYPE_DATA_VISUALISATION,
                    data : selectedVisualisation.backingObject[ID]
                };
                getPageParts(partsProvider, pageWorkspaceSettings).splice(index, 0, part);
                //On add we also need to adjust expanded panel indexes
                let collapsedContentIds1 = collapsedContentIds.map(id => {
                    if(id >= index) {
                        return id + 1;
                    } else {
                        return id;
                    }
                });
                this.setState({collapsedContentIds : collapsedContentIds1});
                this.setState({showDataVisualisationSelectDialog : false, showDataVisualisationSelectDialogIndex : undefined});
            }}
        />;
    }

    renderSpreadsheetSelectDialog = () => {
        const {workspace, pageWorkspaceSettings, partsProvider} = this.props;
        this.initHasPartsIfNeeded();

        return <SpreadsheetSelectionDialog
            workspace={workspace}
            onCancel={() => {
                this.setState({showSpreadsheetSelectDialog : false, showSpreadsheetSelectDialogIndex : undefined});
            }}
            onSelect={(selected) => {
                let {collapsedContentIds, showSpreadsheetSelectDialogIndex} = this.state;
                let index = showSpreadsheetSelectDialogIndex;
                let part = {
                    uuid :  uuid4(),
                    [TYPE] : TYPE_SPREADSHEET,
                    data : selected.backingObject[ID]
                };
                getPageParts(partsProvider, pageWorkspaceSettings).splice(index, 0, part);
                //On add we also need to adjust expanded panel indexes
                let collapsedContentIds1 = collapsedContentIds.map(id => {
                    if(id >= index) {
                        return id + 1;
                    } else {
                        return id;
                    }
                });
                this.setState({collapsedContentIds : collapsedContentIds1});
                this.setState({showSpreadsheetSelectDialog : false, showSpreadsheetSelectDialogIndex : undefined});
            }}
        />;
    }

    renderLinkToPageSelectDialog = () => {
        let {settings, location, aliasesMap, pageWorkspaceSettings, partsProvider} = this.props;
        let {pageObjects} = this.state;
        return toArray(pageObjects).length > 0 ? <PageLinkSettingsDialog
            settings={settings}
            aliasesMap={aliasesMap}
            pageObjects={pageObjects}
            location={location}
            onCancel={() => {
                this.setState({showLinkToPageSelectDialog: undefined, showLinkToPageSelectDialogIndex : undefined})
            }}
            onSave={(partData) => {
                let {collapsedContentIds, showLinkToPageSelectDialogIndex} = this.state;
                let index = showLinkToPageSelectDialogIndex;
                let part = {
                    uuid :  uuid4(),
                    [TYPE] : TYPE_LINKS_TO_PAGES_BLOCK,
                    [PART_DATA] : partData
                };
                getPageParts(partsProvider, pageWorkspaceSettings).splice(index, 0, part);
                //On add we also need to adjust expanded panel indexes
                let collapsedContentIds1 = collapsedContentIds.map(id => {
                    if(id >= index) {
                        return id + 1;
                    } else {
                        return id;
                    }
                });
                this.setState({collapsedContentIds : collapsedContentIds1});
                this.setState({showLinkToPageSelectDialog : false, showLinkToPageSelectDialogIndex : undefined});
            }}
        /> : <></>;
    }

    addPageComponentDialog = (index) => {
        let {theme, pageWorkspaceSettings, partsProvider, mode} = this.props;
        if(partsProvider) {
            return pageComponentSelector.call(this, index, partsProvider(), theme, mode);
        }
        if(!pageWorkspaceSettings[HAS_PARTS]) {
            pageWorkspaceSettings[HAS_PARTS] = [];
        }
        return pageComponentSelector.call(this, index, pageWorkspaceSettings[HAS_PARTS], theme, mode);

    }

    addRequest = (index, type) => {
        let {workspace, settings, pageWorkspace, pageWorkspaceSettings, partsProvider} = this.props;
        if(partsProvider) {
            initHomePageQueries(settings);
            let request = createRequest(type, workspace[ID], workspace.apiConfigIRI, {title: 'Some Request'});
            partsProvider().splice(index, 0, request);
        } else {
            if(!pageWorkspaceSettings[HAS_PARTS]) {
                pageWorkspaceSettings[HAS_PARTS] = [];
            }
            let request = createRequest(type, pageWorkspace[ID], workspace.apiConfigIRI, {title: 'Example Request'});
            let part = {
                [TYPE] : type,
                data : request
            };
            pageWorkspaceSettings[HAS_PARTS].splice(index, 0, part);
        }
        this.setState({})
    }

    isCollapsed = (index) => {
        let {collapsedContentIds} = this.state;
        let isCollapsed = toArray(collapsedContentIds).find(id => id === index);
        return isCollapsed === undefined ? false : true;
    }

    getDeleteButton = (index, title, part, icon) => {
        let {partIndex, mode} = this.props;
        let {collapsedContentIds} = this.state;
        let isCollapsed = this.isCollapsed(index);

        const deleteButton = <IconButton
            disabled={partIndex !== undefined}
            datatest={'deleteComponentButton-' + index}
            size={'small'}
            onClick={this.deleteComponent(index)}
        >
            <DeleteOutline></DeleteOutline>
        </IconButton>;
        return <div style={{display: 'flex', gap : (isColumnMode(mode) ? '8px' : '16px')  , flexDirection : (isColumnMode(mode) ? 'column' : undefined)}}>
            <div
                onClick={() => toggleExpandCollapse.call(this, isCollapsed, index, collapsedContentIds)}
                style={{display : 'flex', gap : (isColumnMode(mode) ? '8px' : '16px'), cursor : 'pointer'}}
            >
                {centerVertically(icon)}
                {title && centerVertically(<H3Title title={title}></H3Title>)}
            </div>
            {isColumnMode(mode) ? <></> : <div style={{flexGrow: '1'}}></div>}
            {centerVertically(this.getDisableSwitch(part, index), {marginLeft : '8px'})}
            {isColumnMode(mode) ? <div>{deleteButton}</div> : centerVertically(deleteButton)}
            { isColumnMode(mode) ? <></> : <div>{getExpandCollapseButton.call(this, mode, isCollapsed, collapsedContentIds, index)}</div>}

        </div>;
    }

    deleteComponent = (k) => () => {
        let {pageWorkspaceSettings, partsProvider} = this.props;
        let {collapsedContentIds} = this.state;
        let parts = partsProvider ? partsProvider() : pageWorkspaceSettings[HAS_PARTS];

        parts.splice(k, 1);

        //On delete we also need to adjusted collapsed panel indexes
        let collapsedContentIds1 = collapsedContentIds.filter(id => id !== k).map(id => {
            if(id > k) {
                return id - 1;
            } else {
                return id;
            }
        });

        this.setState({collapsedContentIds : collapsedContentIds1});

    }

    getDisableSwitch = (part, index) => {
        let {theme} = this.props;
        return <FormControlLabel
            control={
                <Switch
                    datatest={'disableSwitch-'+index}
                    defaultChecked={part['disable'] === true}
                    value={true}
                    size={"small"}
                    onChange={() => {
                        part['disable'] = part['disable'] === true ? false : true;
                    }}
                />
            }
            label={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>
                Disable
            </Typography>}
        />;
    }


    renderSearchRequest = (part, index) => {
        let {theme, settings, aliasesMap, partsProvider, mode} = this.props;
        let {expandedIds} = this.state;
        let searchRequest = partsProvider ? part : part[PART_DATA];
        let id = searchRequest[ID];
        let expanded = toArray(expandedIds).includes(searchRequest[ID]);
        let summaryComponentStyle = {padding: '8px', borderRight: '1px solid #EEEEEE'}
        let prefixLabel = getUiLabelTranslationFromContext(this, EXAMPLE_TYPE_TO_TITLE_KEY[part[TYPE]]);

        let content = <Accordion TransitionProps={{unmountOnExit: true}}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon datatest={'expandButton'}/>}
                aria-label="Expand"
                aria-controls="additional-actions1-content"
                id={"additional-actions1-header" + id}
                IconButtonProps={{
                    size: 'small'
                }}
                style={{
                    borderBottom: expanded ? '1px solid #EEEEEE' : 'none',
                    minHeight: 'unset',
                    padding: '0px 8px 0px 8px',
                    margin: '0px'
                }}
            >
                {centerVertically(getTitlePrefixComponent(EXAMPLE_TYPE_TO_COLOR[part[TYPE]], prefixLabel), {
                    textAlign: 'center',
                    width: '48px', ...summaryComponentStyle,
                    paddingTop: '10px'
                })}
                <div style={{paddingLeft: '8px'}}/>
                {
                    centerVertically(
                        <div>
                            <H3Title datatest={'requestTitle'} color={'primary'}>
                                {getMultilingualValue(searchRequest.title, getDefaultLanguage(settings))}
                            </H3Title>
                        </div>
                    )
                }
            </AccordionSummary>
            <AccordionDetails style={{padding: '0px'}}>
                <div datatest={'requestDetails'} style={{
                    width: 'calc(100% - 16px)',
                    padding: '8px',
                }}>{
                    showRequest(
                        searchRequest, searchRequest[ID],
                        undefined,
                        aliasesMap,
                        undefined,
                        () => {
                        },
                        () => {
                        },
                        {
                            hideSettings: false,
                            hideRun: false,
                            hideSave: true,
                            titleRenderer: titleRenderer(theme, settings, () => {
                            }, searchRequest)
                        }
                    )
                }</div>
            </AccordionDetails>
        </Accordion>

        return withPartBar.call(
            this, index, mode, part, partsProvider ? 'Data Query' : 'Example API Request', REQUEST_ICON, content, () => {
                    //If partProvider is there it means home page so we just show data query
                    return partsProvider
                        ? renderQueryTypeOptions({queryType : QUERY_TYPE_DATA_QUERY}, true)
                        : renderQueryTypeOptions(part)
            },
            'panel'
        );

    }

    renderPageContent = () =>  {
        let {loading, showLinkToPageSelectDialog, showVisualisationSelectDialog, showSpreadsheetSelectDialog, showDataVisualisationSelectDialog} = this.state;
        let { pageWorkspaceSettings, partIndex, mode, settings, partsProvider} = this.props;
        const isColumn = mode === 'column';
        const pageParts = getPageParts(partsProvider, pageWorkspaceSettings);

        const partContainerStyle = isColumn
            ? {display :'flex', overflow: 'auto'}
            : {};
        return <>
            {loading && <ProcessingBackdrop loading={loading}/>}
            {
                showVisualisationSelectDialog && this.renderVisualisationSelectDialog()
            }
            {
                showSpreadsheetSelectDialog && this.renderSpreadsheetSelectDialog()
            }
            {
                showDataVisualisationSelectDialog && this.renderDataVisualisationSelectDialog()
            }
            {
                showLinkToPageSelectDialog && this.renderLinkToPageSelectDialog()
            }
            <div>
                {
                    isColumnMode(mode) || partIndex !== undefined ? <></> :
                        <div style={{padding : '8px'}}>
                            <TextField
                                datatest={'componentGap'}
                                variant={'outlined'}
                                label={'Gap Between Components'}
                                defaultValue={(pageWorkspaceSettings ? pageWorkspaceSettings?.['componentGap'] : settings['componentGap']) || `8px`}
                                style={{minWidth: '200px'}}
                                onChange={(ev) => {
                                    let value = ev.target.value;
                                    if (pageWorkspaceSettings) {
                                        pageWorkspaceSettings['componentGap'] = value;
                                    } else {
                                        settings['componentGap'] = value;
                                    }
                                }}
                                size={'small'}
                            />
                        </div>
                }
                <div style={partContainerStyle}>

                    <div style={{marginBottom: '16px'}}>
                        {
                            partIndex === undefined && this.addPageComponentDialog(0)
                        }
                    </div>
                    {
                        pageParts.filter((p, index) => partIndex === undefined || partIndex === index ).map((p, index) => {
                            let component;
                            if (isContentType(p)) {
                                component = renderContent.call(this, p, index);
                            } else if (isGridType(p)) {
                                component = renderGrid.call(this, p, index);
                            } else if (isExampleRequest(p)) {
                                component = this.renderSearchRequest(p, index);
                            } else if (isVisualisation(p)) {
                                component = renderVisualisation.call(this, p, index);
                            } else if (isSpreadsheet(p)) {
                                component = renderSpreadsheet.call(this, p, index);
                            } else if (isDataVisualisation(p)) {
                                component = renderDataVisualisation.call(this, p, index);
                            } else if (isLinksToPagesBlock(p, index)) {
                                component = renderLinkToPagesBlock.call(this, p, index);
                            }
                            let isCollapsed = toArray(this.state.collapsedContentIds).find(id => id === index);
                            let swapButton = <IconButton datatest={'swapComponent-' + index} onClick={() => {
                                    let allParts = getPageParts(this.props.partsProvider, pageWorkspaceSettings);
                                    let first = allParts[index];
                                    let second = allParts[index + 1];
                                    allParts[index] = second;
                                    allParts[index + 1] = first;
                                    this.setState({});
                                }}>
                                    {isColumn ? <SwapHorizOutlined color={'primary'}/> : <SwapVertOutlined color={'primary'}/>}
                                </IconButton>;
                            return <React.Fragment key={index + "-" + p.uuid + "-" + isCollapsed}>
                                {isColumn ? <div style={{width : (100 / pageParts.length)+'%' }}>{withWhiteBorder(component)}</div> : component}
                                    {
                                        partIndex === undefined &&
                                        <div style={{marginBottom: '16px', display: 'flex', flexDirection : isColumn ? 'column' : undefined}}>
                                            {
                                                isColumn ? <div>{this.addPageComponentDialog(index + 1)}</div> :  this.addPageComponentDialog(index + 1)
                                            }
                                            {
                                                index < pageParts.length - 1 &&
                                                (isColumn ? <div style={{textAlign : 'center'}}>{swapButton}</div> : centerVertically(swapButton))
                                            }
                                        </div>
                                    }
                            </React.Fragment>
                        })

                    }
                </div>
            </div>
        </>;
    }

    renderPageDialog = () => {
        let {loading, uuid, showLinkToPageSelectDialog, showVisualisationSelectDialog, showSpreadsheetSelectDialog, showDataVisualisationSelectDialog} = this.state;
        let {editPageDialogOpen, pageWorkspaceSettings, pageWorkspace, settings, partsProvider} = this.props;
        let title = partsProvider ? 'Home Page' : getMultilingualValue(pageWorkspace[ALIAS_MANAGEMENT_TITLE], getDefaultLanguage(settings));

        return <>
            <Dialog
                datatest={'pageEditor'}
                aria-labelledby="form-dialog-title"
                open={editPageDialogOpen}
                fullScreen={true}
            >
                <DialogTitle id="form-dialog-title" style={{borderBottom: '1px solid'}}>
                    <div style={{display: 'flex'}}>
                        {centerVertically(<H2Title title={title}/>)}
                        <div style={{flexGrow: '1'}}/>
                        <PageWidthSettings partsProvider={partsProvider} pageWorkspaceSettings={pageWorkspaceSettings}/>
                    </div>
                </DialogTitle>
                <DialogContent>
                    {this.renderPageContent(loading, showVisualisationSelectDialog, showSpreadsheetSelectDialog, showDataVisualisationSelectDialog, showLinkToPageSelectDialog, pageWorkspaceSettings)}

                </DialogContent>
                <DialogActions datatest={'pageEditorDialogActions'} style={{borderTop: '1px solid'}}>
                    <Button
                        datatest={'cancelButton'}
                        onClick={this.props.onClose}
                        variant={"outlined"}
                        color="secondary"
                    >Close</Button>
                    <Button
                        datatest={'addButton'}
                        variant={"contained"}
                        color="secondary"
                        onClick={() => {
                            this.setState({loading: true});
                            this.props.onSave().then(() => {
                                this.setState({loading: false});
                            })
                        }}
                    >Save</Button>
                </DialogActions>
            </Dialog>
            }
        </>;

    }

    render() {
        let {dialog} = this.props;
        let {loading, showLinkToPageSelectDialog, showVisualisationSelectDialog, showSpreadsheetSelectDialog, showDataVisualisationSelectDialog} = this.state;
        let {pageWorkspaceSettings} = this.props;

        return dialog === false
            ? this.renderPageContent(loading, showVisualisationSelectDialog, showSpreadsheetSelectDialog, showDataVisualisationSelectDialog, showLinkToPageSelectDialog, pageWorkspaceSettings)
            : this.renderPageDialog();
    }
}

PageEditor.propTypes = {
    mode: PropTypes.any,
    editPageDialogOpen: PropTypes.bool,
    dialog: PropTypes.bool,
    pageWorkspace: PropTypes.any,
    pageWorkspaceSettings: PropTypes.any,
    workspace: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    location: PropTypes.object,
    onClose : PropTypes.func,
    onSave : PropTypes.func,
    onExpand: PropTypes.func,
    partsProvider: PropTypes.func,
    partIndex: PropTypes.number,
    parentPart: PropTypes.any

};

export default withStyles({}, {withTheme: true})(PageEditor);

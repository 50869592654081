import React, {Component, useContext, useEffect} from 'react';
import PropTypes from "prop-types";
import {getEndpointForGraphiQL, graphQLFetcher} from "../../service/graph-api";
import {buildClientSchema, getIntrospectionQuery} from "graphql";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Switch,
    Typography,
    useTheme
} from '@material-ui/core';
import './GraphQLEditor.css';
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../styles";
import H2Title from "../../components/H2Title";
import DialogTitle from "@material-ui/core/DialogTitle";
import FieldContainer from "../../components/FieldContainer";
import GlobalsContext from "../../components/GlobalsContext";
import {attachToWindowForTesting, replaceVariables} from "../util";
import {getReplacementMessages} from "../Request";
import H4Title from "../../components/H4Title";
import {DOC_EXPLORER_PLUGIN, GraphiQLProvider, QueryEditor, useEditorContext, VariableEditor} from '@graphiql/react';
import {createGraphiQLFetcher} from '@graphiql/toolkit';

import DocExplorerWrapper from './GraphQLDocExplorer'

function QueryEditorWrapper({query, schema, onClickReference, onEdit}) {
    const { queryEditor } = useEditorContext();

    useEffect(() => {
        attachToWindowForTesting('graphQLQueryEditorComponent', queryEditor);
        console.log('graphQLQueryEditorComponent', queryEditor);
    }, [queryEditor]);

    return <QueryEditor
            onClickReference={onClickReference}
            onEdit={onEdit}
        />;
}

function VariableEditorWrapper({readOnly, onClickReference, onEdit}) {
    const { variableEditor } = useEditorContext();

    useEffect(() => {
        attachToWindowForTesting('graphQLVariableEditorComponent', variableEditor);
        console.log('graphQLVariableEditorComponent', variableEditor);
    }, [variableEditor]);

    return <VariableEditor
        readOnly={readOnly}
        onEdit={onEdit}
        onClickReference={onClickReference}
    />;
}

function GraphQLEditorInternal({
                                   mode,
                                   query,
                                   variables,
                                   onEditQuery,
                                   onEditVariables,
                                   reqAction,
                                   response,
                                   previewReplacedBody,
                                   onPreviewReplacedBodyToggle,
                               }) {

    const theme = useTheme();
    const globals = useContext(GlobalsContext);

    const [schema, setSchema] = React.useState();
    const [openDocExplorer, setOpenDocExplorer] = React.useState();
    const [reference, setReference] = React.useState();


    useEffect(() => {
        let query = {
            "query": getIntrospectionQuery(),
            "operationName": "IntrospectionQuery"
        }
        graphQLFetcher(query).then(r => {
            let schemaToSet = buildClientSchema(r.data)
            setSchema(schemaToSet);
        })
    }, [])

    const handleEditQuery = (value) => {
        onEditQuery(value);
    };

    const handleClickReference = referenceValue => {
        setOpenDocExplorer(true);
        setReference(referenceValue);
    };

    let replaced = variables && previewReplacedBody ? replaceVariables(variables, globals) : undefined

    return <div className={'graphqlEditor'}>
        <Dialog
            fullWidth={true}
            maxWidth={"sm"}
            open={openDocExplorer}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={'GraphQL Schema Explorer'}/></DialogTitle>
            <DialogContent>
                <div style={{minHeight: '250px'}}>
                    <div>
                        <GraphiQLProvider
                            fetcher={createGraphiQLFetcher({url: getEndpointForGraphiQL(),})}
                            query={query}
                            schema={schema}
                            visiblePlugin={DOC_EXPLORER_PLUGIN}
                        >
                            <DocExplorerWrapper></DocExplorerWrapper>
                        </GraphiQLProvider>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"outlined"}
                    onClick={() => setOpenDocExplorer(!openDocExplorer)}
                    color="secondary"
                >{'Close'}</Button>
            </DialogActions>
        </Dialog>

        <FieldContainer style={{marginBottom: theme.spacing(1)}}>
            <H4Title style={{marginBottom: theme.spacing(1)}} title={'Query'}/>
            {schema &&  <GraphiQLProvider
                fetcher={createGraphiQLFetcher({url: getEndpointForGraphiQL(),})}
                query={query}
                schema={schema}
            ><QueryEditorWrapper query={query} schema={schema} onClickReference={handleClickReference} onEdit={handleEditQuery} ></QueryEditorWrapper></GraphiQLProvider>
            }
        </FieldContainer>
        <FieldContainer>
            <div style={{marginBottom: theme.spacing(1), display : 'flex'}}>
                <H4Title title={'Query Variables'}/>
                <div style={{flexGrow: '1'}}/>
                <FormControlLabel
                    control={
                        <Switch
                            checked={previewReplacedBody && previewReplacedBody === true ? true : false}
                            value={true}
                            size={"small"}
                            onChange={(e) => {
                                const {target: {checked}} = e
                                onPreviewReplacedBodyToggle(checked)
                            }}
                            name="previewVariableReplacedJSONSwitch"
                        />
                    }
                    label={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>
                        Preview Query Variables with Global Variables Substitution
                    </Typography>}
                />
            </div>
            {getReplacementMessages(replaced, variables)}

            {
                previewReplacedBody ?  <GraphiQLProvider
                        key={"i"+previewReplacedBody}
                        fetcher={createGraphiQLFetcher({url: getEndpointForGraphiQL(),})}
                        query={query}
                        schema={schema}
                        variables={replaced.replaced}
                    >
                        <VariableEditorWrapper readOnly={true}/>

                    </GraphiQLProvider>
                    : <GraphiQLProvider
                        key={"i"+previewReplacedBody}
                        fetcher={createGraphiQLFetcher({url: getEndpointForGraphiQL(),})}
                        query={query}
                        schema={schema}
                        variables={variables}
                    >
                        <VariableEditorWrapper
                            onEdit={onEditVariables}
                            onClickReference={handleClickReference}
                        />

                    </GraphiQLProvider>
            }
        </FieldContainer>

    </div>;

}

class GraphQLEditor extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return <GraphQLEditorInternal {...this.props}></GraphQLEditorInternal>
    }

}

GraphQLEditor.defaultProps = {
    readOnly: false,
};

GraphQLEditor.propTypes = {
    mode: PropTypes.string,
    query: PropTypes.any,
    variables: PropTypes.any,
    onEditQuery: PropTypes.func,
    onEditVariables: PropTypes.func,
    reqAction: PropTypes.func,
    response: PropTypes.string,
    previewReplacedBody: PropTypes.bool,
    onPreviewReplacedBodyToggle: PropTypes.func,
}

export default withStyles(styles, {withTheme: true})(GraphQLEditor);


import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {getDatasetLabel, getRouteInstancePath} from "../../components/util";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {ALIAS_MANAGEMENT_ID_LABEL, ROUTE_MANAGEMENT_HOME} from "../../Constants";
import history from "../../history";
import PropTypes from "prop-types";
import {isManagementUserLoggedIn, setSelectedDataset} from "../../layouts/common/Profile";

const styles = {
    formControl: {
        minWidth : '270px',
        maxWidth : '270px'
    }
};

const COMPONENT = 'DatasetSelect';

class DatasetSelect extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    handleChange = (event) => {
        let datasetLabel = event.target.value;
        let path = `${getRouteInstancePath()}/dataset/${datasetLabel}${ROUTE_MANAGEMENT_HOME}`;
        history.push(path);
        setSelectedDataset(datasetLabel);
    }

    render() {
        let {classes, datasets}= this.props;
        let datasetLabel = getDatasetLabel();
        return (
            <FormControl size={"small"} variant="outlined" className={classes.formControl}>
                <InputLabel id="demo-simple-select-outlined-label">Select Dataset</InputLabel>
                <Select
                    disabled={!isManagementUserLoggedIn()}
                    datatest={'selectDataset'}
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={datasetLabel}
                    onChange={this.handleChange}
                    label="Select Dataset"
                >
                    {
                        datasets.map((d, i) => {
                            return <MenuItem key={d[ALIAS_MANAGEMENT_ID_LABEL]+'  '+i} datatest={d[ALIAS_MANAGEMENT_ID_LABEL]} value={d[ALIAS_MANAGEMENT_ID_LABEL]}>{d[ALIAS_MANAGEMENT_ID_LABEL]}</MenuItem>;
                        })
                    }
                </Select>
            </FormControl>
        );
    }

}

DatasetSelect.propTypes = {
    datasets: PropTypes.array
};

export default withStyles(styles, {withTheme: true})(DatasetSelect);

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {styles} from "../../components/styles";
import {
    getGraph,
    getRouteWithInstanceAndDataset,
    getSearchResult,
    getTabLabel,
    handleAPIResponse,
    handleBackendError,
    restrictMaximumCharacters,
    setCookie,
    validatePassword,
    validateRequiredAndMaxLength
} from "../../components/util";
import AllPartsLayout from "../AllPartsLayout";
import {
    ALIAS_ACCOUNT_EMAIL,
    ALIAS_ACCOUNT_FIRSTNAME,
    ALIAS_ACCOUNT_LASTNAME,
    ALIAS_ACCOUNT_NEW_PASSWORD,
    ALIAS_ACCOUNT_PASSWORD,
    ALIAS_ACCOUNT_SINGLE_SIGN_ON_ID,
    ALIAS_ACCOUNT_USERNAME,
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    COOKIE_FIRSTNAME,
    COOKIE_LASTNAME,
    ID, LABEL_ACCOUNT, LABEL_CONFIRM_NEW_PASSWORD, LABEL_CURRENT_PASSWORD,
    LABEL_EMAIL,
    LABEL_FIRSTNAME, LABEL_HOME,
    LABEL_LASTNAME, LABEL_MONITORING,
    LABEL_NEW_PASSWORD,
    LABEL_USERNAME,
    ROUTE_ACCOUNT_DETAILS,
    ROUTE_ACCOUNT_HOME,
    ROUTE_ACCOUNT_MONITORING,
    ROUTE_MANAGEMENT_HOME,
    STYLE_GRID_ITEM_SPACING,
    VALIDATION_FIRSTNAME_LENGTH,
    VALIDATION_LASTNAME_LENGTH,
    VALIDATION_PASSWORD_LENGTH
} from "../../Constants";
import {
    deleteData,
    getAccountContextURL,
    getBaseEndpoint,
    getData,
    patchData
} from "../../service/graph-api";
import PropTypes from "prop-types";
import history from "../../history";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import withWidth from "@material-ui/core/withWidth";
import MainAppBar, {getUsernameToShow, logoutFromAccount} from "../../components/header/MainAppBar";
import Header from "../../components/header/Header";
import AccountBoxOutlinedIcon from '@material-ui/icons/AccountBoxOutlined';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import LeftBarTitle from "../../components/LeftBarTitle";
import FieldContainer from "../../components/FieldContainer";
import TextField from "../../components/TextField";
import ErrorMessage from "../../components/ErrorMessage";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import {DeleteForeverOutlined, WarningOutlined} from "@material-ui/icons";
import H2Title from "../../components/H2Title";
import {ConfirmAreYouSure} from "../../components/ConfirmAreYouSure";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import {isAccountSysadmin} from "../../layouts/common/Profile";
import SpeedOutlinedIcon from "@material-ui/icons/SpeedOutlined";
import MenuColumnAndDetailsLayout, {getLeftMenuComponent} from "../../layouts/MenuColumnAndDetailsLayout";
import {BACKEND_PATH_ACCOUNT_USER, BACKEND_PATH_ACCOUNT_USER_SEARCH} from "../../service/backend-paths";

export function getLeftComponent(callerThis, theme, minimized) {
    return getLeftMenuComponent(callerThis, theme, minimized, getLeftMenuItems(theme));
}

export function navigateToRoute(route) {
    return () => history.push(route);
}

export function getLeftMenuItems(theme) {
    let iconStyle =  {color: theme.palette.white.main}
    let items = [
        {
            label: LABEL_HOME,
            icon : <HomeOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRoute(ROUTE_ACCOUNT_HOME)
        }
    ];
        items.push({
            label: LABEL_ACCOUNT,
            icon : <AccountBoxOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRoute(ROUTE_ACCOUNT_DETAILS)
        })
    if(isAccountSysadmin()) {
        items.push({
            label: LABEL_MONITORING,
            icon : <SpeedOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRoute(ROUTE_ACCOUNT_MONITORING)
        })
    }
    return items;
}


class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            minimized: true,
            leftMenuTabValue : 0
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = async () => {
        this.setState({loading: true});
        const apiResponse = await getData(getBaseEndpoint(), BACKEND_PATH_ACCOUNT_USER_SEARCH).catch(handleBackendError(this));
        handleAPIResponse(
            this,
            apiResponse,
            () => {
                apiResponse.json().then((response) => {
                    let searchResult = getSearchResult(response);
                    this.setState({user: searchResult[0], loading: false});
                }).catch((e) => {
                    this.setState({loading: false, apiErrorResponse: e, apiError: true});
                })
            },
            handleBackendError(this)
        )

        //this.setState({loading: true});
    }

    updateUser = async (user) => {
        this.setState({loading: true});
        let patchPayload = {
            [AT_CONTEXT] : getAccountContextURL(),
            [ID] : user[ID],
            [ALIAS_SYS_ETAG] : user[ALIAS_SYS_ETAG],
            [ALIAS_ACCOUNT_FIRSTNAME] : user[ALIAS_ACCOUNT_FIRSTNAME],
            [ALIAS_ACCOUNT_LASTNAME] : user[ALIAS_ACCOUNT_LASTNAME],
            [ALIAS_ACCOUNT_EMAIL] : user[ALIAS_ACCOUNT_EMAIL]
        }
        const apiResponse = await patchData(getBaseEndpoint(), BACKEND_PATH_ACCOUNT_USER, patchPayload).catch(handleBackendError(this));
        handleAPIResponse(
            this,
            apiResponse,
            () => {
                apiResponse.json().then(j => {
                    setCookie(COOKIE_FIRSTNAME, user[ALIAS_ACCOUNT_FIRSTNAME]);
                    setCookie(COOKIE_LASTNAME, user[ALIAS_ACCOUNT_LASTNAME]);
                    let updatedUser = getGraph(j).find(u => u[ID] === user[ID]);
                    user[ALIAS_SYS_ETAG] = updatedUser[ALIAS_SYS_ETAG];
                    this.setState({loading: false});
                })
            },
            () => {
                this.setState({loading: false});
            }
        )

    }

    updatePassword = async () => {
        this.setState({loading: true});
        let {user, currentPassword, newPassword} = this.state;
        let patchPayload = {
            [AT_CONTEXT] : getAccountContextURL(),
            [ID] : user[ID],
            [ALIAS_SYS_ETAG] : user[ALIAS_SYS_ETAG],
            [ALIAS_ACCOUNT_PASSWORD] : currentPassword,
            [ALIAS_ACCOUNT_NEW_PASSWORD] : newPassword
        }
        const apiResponse = await patchData(getBaseEndpoint(), `${BACKEND_PATH_ACCOUNT_USER}/${user[ALIAS_ACCOUNT_USERNAME]}/password`, patchPayload, false).catch(handleBackendError(this));
        handleAPIResponse(
            this,
            apiResponse,
            () => {
                apiResponse.json().then(j => {
                    let updatedUser = getGraph(j).find(u => u[ID] === user[ID]);
                    user[ALIAS_SYS_ETAG] = updatedUser[ALIAS_SYS_ETAG];
                    this.setState({loading: false});
                    this.resetState();
                })
            },
            () => {
                if(apiResponse.status === 401) {
                   this.setState({apiErrorResponse: "Current password is wrong or your session has expired.", apiError: true});
                } else {
                    handleBackendError(this)(apiResponse);
                }
                this.setState({loading: false});
                this.resetState();
            }
        )
    }

    deleteAccount = async () => {
        this.setState({loading: true});
        let {user} = this.state;
        let deletePayload = {
            [AT_CONTEXT] : getAccountContextURL(),
            [ID] : user[ID],
            [ALIAS_SYS_ETAG] : user[ALIAS_SYS_ETAG]
        }
        const apiResponse = await deleteData(getBaseEndpoint(), `${BACKEND_PATH_ACCOUNT_USER}`, deletePayload).catch(handleBackendError(this));
        handleAPIResponse(
            this,
            apiResponse,
            () => {
                this.setState({loading: false});
                this.resetState();
                logoutFromAccount();
            },
            () => {
                if(apiResponse.status === 401) {
                    this.setState({apiErrorResponse: "You are not authorized to perform this action or your session has expired.", apiError: true});
                } else {
                    handleBackendError(this)(apiResponse);
                }
                this.setState({loading: false});
                this.resetState();
            }
        )

    }

    navigateToId = (dataset) => {
        history.push(`${getRouteWithInstanceAndDataset(ROUTE_MANAGEMENT_HOME)}`)
    }

    resetState = async () => {
        let {enableSave} = this.state;
        if(enableSave === true) {
            await this.syncDataWithBackend();
            this.setState({enableSave: false});
        }

        this.setState({
            confirmAccountDelete: false,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
    }

    getTabs = () => {
        const {theme} = this.props;
        const {leftMenuTabValue} = this.state;
        const MenuTabs = withStyles({
            root: {},
            indicator: {},
        })(Tabs);
        const MenuTab = withStyles({
            root: {
                textTransform: 'none',
                '@media (min-width: 600px)': {
                    minWidth: "48px"
                }
            },
            selected: {
                color : theme.palette.secondary.main,
                backgroundColor: theme.palette.white.main,
                borderRadius: '4px 4px'
            }
        })(Tab);

        return <>
            <LeftBarTitle style={{padding : '0px'}} title={getUsernameToShow(true)}/>
            <MenuTabs value={leftMenuTabValue} onChange={(event, newValue) => {
                this.resetState();
                this.setState({leftMenuTabValue: newValue})
            }} aria-label="request tabs">
                <MenuTab label="Details"/>
                <MenuTab label="Security"/>
                <div style={{flexGrow : '1'}}></div>
                <MenuTab style={{color : 'red'}} label={getTabLabel(<DeleteForeverOutlined/>, "Delete Account")}/>
            </MenuTabs>
        </>;
    }

    isLinkedInUser = () => {
        let {user} = this.state;
        return user[ALIAS_ACCOUNT_SINGLE_SIGN_ON_ID] && user[ALIAS_ACCOUNT_SINGLE_SIGN_ON_ID].length > 0;
    }

    shouldSaveBeDisabled = () => {
        let {firstnameError, lastnameError, emailError, enableSave} = this.state;
        if(firstnameError || lastnameError || emailError) {
            return true;
        }
        return enableSave !== true;
    }

    shouldPasswordSaveBeDisabled = () => {
        let {currentPassword, currentPasswordError, newPassword, newPasswordError, confirmNewPassword, confirmNewPasswordError} = this.state;
        if(!currentPassword || currentPasswordError ||
            !newPassword || newPasswordError ||
            !confirmNewPassword || confirmNewPasswordError) {
            return true;
        }
        return false;
    }

    handleFieldChange = (key, errorKey, label, maxLength) => (ev) => {
        let {user} = this.state;
        let {target:{value}} = restrictMaximumCharacters(ev, maxLength);
        user[key] = value;
        let error = validateRequiredAndMaxLength(value, label, maxLength);
        if(error) {
            this.setState({[errorKey]: error});
        } else {
            this.setState({[errorKey] : ''});
        }
        this.setState({enableSave : true});
    }

    handlePasswordFieldChange = (key, errorKey) => (event) => {
        let ev = restrictMaximumCharacters(event, VALIDATION_PASSWORD_LENGTH);
        const { target: { name, value } } = ev;
        this.setState({[key] : value, [errorKey]: validatePassword(value)});
    }

    handlePasswordConfirmFieldChange = (event) => {
        const { target: { name, value } } = event;
        this.setState({confirmNewPassword: value});
        let {newPassword} = this.state;
        if(value !== newPassword) {
            this.setState({confirmNewPasswordError : 'Confirm password value does not match with password.'});
        } else {
            this.setState({confirmNewPasswordError : ''})
        }
    }

    renderUserDetails = () => {
        let {theme} = this.props;
        let {leftMenuTabValue, user, firstnameError, lastnameError, emailError, } = this.state;
        let {currentPassword, currentPasswordError, newPassword, newPasswordError, confirmNewPassword, confirmNewPasswordError} = this.state;
        let {confirmAccountDelete} = this.state;
        if(this.isLinkedInUser()) {
            return <div>
                <ErrorMessage error={'These options are not available because you have signed in with LinkedIn account.'}></ErrorMessage>
            </div>;
        }
        return <>
            {this.getTabs()}
            <FieldContainer style={{
                backgroundColor: theme.palette.white.main
            }}>
                <div datatest={'userDetailsTabContent'} style={{display: (leftMenuTabValue === 0 ? 'block' : 'none')}}>
                    <Grid container spacing={STYLE_GRID_ITEM_SPACING}>
                        <Grid item xs={12}>
                            <TextField
                                label={LABEL_USERNAME}
                                id='username'
                                name='username'
                                value={user[ALIAS_ACCOUNT_USERNAME]}
                                readOnly={true}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={LABEL_FIRSTNAME}
                                value={user[ALIAS_ACCOUNT_FIRSTNAME]}
                                error={firstnameError}
                                helperText={firstnameError}
                                onChange={this.handleFieldChange(ALIAS_ACCOUNT_FIRSTNAME, 'firstnameError', LABEL_FIRSTNAME, VALIDATION_FIRSTNAME_LENGTH)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={LABEL_LASTNAME}
                                value={user[ALIAS_ACCOUNT_LASTNAME]}
                                error={lastnameError}
                                helperText={lastnameError}
                                onChange={this.handleFieldChange(ALIAS_ACCOUNT_LASTNAME, 'lastnameError', LABEL_LASTNAME, VALIDATION_LASTNAME_LENGTH)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label={LABEL_EMAIL}
                                value={user[ALIAS_ACCOUNT_EMAIL]}
                                error={emailError}
                                helperText={emailError}
                                onChange={this.handleFieldChange(ALIAS_ACCOUNT_EMAIL, 'emailError', LABEL_EMAIL, 500)}
                            />
                        </Grid>
                        <Grid justify={"flex-start"} container item xs={12}>
                            <Button
                                datatest={'saveUserDetailsButton'}
                                disabled={this.shouldSaveBeDisabled()}
                                variant={'contained'}
                                color={"secondary"}
                                onClick={() => this.updateUser(user)}
                            >Save</Button>
                        </Grid>

                    </Grid>
                </div>
                <div datatest={'userSecurityTabContent'} style={{display: (leftMenuTabValue === 1 ? 'block' : 'none')}}>
                    <Grid container spacing={STYLE_GRID_ITEM_SPACING}>
                        <Grid item xs={12}>
                            <TextField
                                error={currentPasswordError}
                                helperText={currentPasswordError}
                                onChange={this.handlePasswordFieldChange('currentPassword', 'currentPasswordError')}
                                type="password"
                                value={currentPassword || ''}
                                label={LABEL_CURRENT_PASSWORD}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={newPasswordError}
                                helperText={newPasswordError || ''}
                                onChange={this.handlePasswordFieldChange('newPassword','newPasswordError')}
                                type="password"
                                value={newPassword}
                                label={LABEL_NEW_PASSWORD}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                error={confirmNewPasswordError}
                                helperText={confirmNewPasswordError}
                                onChange={this.handlePasswordConfirmFieldChange}
                                type="password"
                                value={confirmNewPassword || ''}
                                label={LABEL_CONFIRM_NEW_PASSWORD}
                            />
                        </Grid>
                        <Grid justify={"flex-start"} container item xs={12}>
                            <Button
                                datatest={'changePasswordButton'}
                                disabled={this.shouldPasswordSaveBeDisabled()}
                                variant={'contained'}
                                color={"secondary"}
                                onClick={() => this.updatePassword(user)}
                            >Change</Button>
                        </Grid>
                    </Grid>
                </div>
                <div datatest={'userDeleteTabContent'} style={{display: (leftMenuTabValue === 3 ? 'block' : 'none')}}>
                    <Grid style={{textAlign: 'center'}} container spacing={STYLE_GRID_ITEM_SPACING}>
                        <Grid item xs={12}>
                            <WarningOutlined style={{ fontSize: 40, color : 'red' }}/>
                        </Grid>
                        <Grid item xs={12}>
                            <H2Title style={{color : 'red'}} title={'Delete Account'}></H2Title>
                        </Grid>
                        <Grid item xs={12}>
                            This action will permanently delete your account.
                        </Grid>
                        <Grid alignContent={'center'} justify={'center'} container item xs={12}>
                            {
                                confirmAccountDelete &&
                                <ConfirmAreYouSure
                                    deleteWarning={'Do you really want to delete your account?'}
                                    handleNo={() => this.setState({confirmAccountDelete : false})}
                                    handleYes={this.deleteAccount}
                                />
                            }
                            <Button
                                datatest={'deleteAccountButton'}
                                variant={'contained'}
                                color={"secondary"}
                                onClick={() => this.setState({confirmAccountDelete : true})}
                            >Delete Account</Button>
                        </Grid>

                    </Grid>
                </div>
            </FieldContainer>
        </>;

    }

    getMiddleComponent = () => {
        let { loading, user} = this.state;
        return <div>
            {
                loading === true && <ProcessingBackdrop marginLeft={true} loading={true}/>
            }
            {
                user && <div style={{maxWidth: '800px'}}>
                    {this.renderUserDetails()}
                </div>
            }
        </div>;
    }

    render() {
        let {theme, location} = this.props
        return (<>
            <MenuColumnAndDetailsLayout
                apiError={this.state.apiError}
                apiErrorResponse={this.state.apiErrorResponse}
                onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                headerTitle={'Account Details'}
                leftMenuItems={getLeftMenuItems(theme)}
                leftColumnRenderer={undefined}
                detailRenderer={this.getMiddleComponent}
                location={location}
                isAccountProfile={true}
            />
        </>);
    }
}

Account.propTypes = {
    location: PropTypes.object,

};

export default withWidth()(withStyles(styles, {withTheme: true})(Account));

import React from "react";
import ReactDOM from "react-dom";
import {
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_SYS_API_CONFIGURATION,
    ALIAS_SYS_AUTHENTICATION,
    COOKIE_ACCOUNT_TOKEN,
    COOKIE_FIRSTNAME,
    COOKIE_HASH,
    COOKIE_IMAGE_URL,
    COOKIE_LASTNAME,
    COOKIE_MANAGEMENT_TOKEN,
    COOKIE_SINGLE_SIGN_IN_ID,
    COOKIE_SYSTEM,
    EASYGRAPH_DATA_AUTHENTICATION_ALL_UPDATES,
    EASYGRAPH_DATA_AUTHENTICATION_DISABLED,
    INSTANCE_CONFIGURATION_SETTING_HOME_PAGE,
    QUERY,
    ROUTE_ACCOUNT_HOME,
    ROUTE_ACCOUNT_LOGIN,
    ROUTE_MANAGEMENT_HOME,
    ROUTE_MANAGEMENT_LOGIN,
    TYPE
} from "./Constants";
import {
    getBackendBasePath,
    getCookie,
    getDatasetLabel,
    getRouteWithInstance,
    getSearchResult,
    isAnyAccountRouteOrHome,
    isManagementAdminLogin,
    isManagementAdminLogout,
    isManagementLogin,
    isRequestSuccessful,
    setCookie
} from "./components/util";
import {
    getAppConfigurationAPIEndpoint,
    initAppConfigs,
    isAccountInstance,
    isAuthenticationDisabled,
    isMangedInstance,
    LOGIN_WITH_LINKED_IN,
    loginWithLinkedInPage,
    setDatasetLevelAuthenticationType
} from "./Configs";
import fetch from "isomorphic-fetch";
import App from "./App";
import reportWebVitals from './reportWebVitals';
import {getInstanceConfiguration, graphSearchByMaps} from "./service/graph-api";

export function getInstanceConfigurationSettings(instanceConfiguration) {
    let instanceConfigurationSettings = instanceConfiguration && instanceConfiguration[ALIAS_MANAGEMENT_SETTINGS]?.[0];
    let parsed = instanceConfigurationSettings ? JSON.parse(instanceConfigurationSettings) : null;
    return parsed;
}

(async () => {
    let appConfigEndpoint = getAppConfigurationAPIEndpoint();

    let res = await fetch( appConfigEndpoint, {}).catch(e => {
        console.log("Failed to connect to backend");
    });

    if(res) {
        let json = await res.json();
        initAppConfigs(json);
    }
    let homePagePath;
    let isCurrentPageHomePagePath;
    if(isAccountInstance() === false) {
        let instanceConfiguration = await getInstanceConfiguration();
        let parsed = getInstanceConfigurationSettings(instanceConfiguration);
        homePagePath = parsed?.[INSTANCE_CONFIGURATION_SETTING_HOME_PAGE];
        const windowsPath = decodeURI(window.location.href)
        isCurrentPageHomePagePath = homePagePath && windowsPath.includes(homePagePath);
    }

    var redirect = false;
    if(homePagePath && !isManagementLogin() && !isManagementAdminLogin()) {
        if(isCurrentPageHomePagePath === false) {
            let homePageURL = `${getBackendBasePath()}${homePagePath}`;
            redirect = true;
            window.location = homePageURL;
        }
    } else if(LOGIN_WITH_LINKED_IN === true && isAnyAccountRouteOrHome() && isMangedInstance() && !homePagePath) {
            let url_string = window.location.href
            let url = new URL(url_string);
            let token = url.searchParams.get("token");
            let expiresIn = url.searchParams.get("expiresIn");
            let system = url.searchParams.get("system");
            let hash = url.searchParams.get("hash");
            let firstName = url.searchParams.get("firstName");
            let lastName = url.searchParams.get("lastName");
            let imageUrl = url.searchParams.get("imageUrl");
            let id = url.searchParams.get("id");

            if (token && expiresIn) {
                setCookie(COOKIE_ACCOUNT_TOKEN, token, expiresIn);
                setCookie(COOKIE_SYSTEM, system, expiresIn);
                setCookie(COOKIE_SINGLE_SIGN_IN_ID, id, expiresIn);
                setCookie(COOKIE_HASH, hash, expiresIn);
                setCookie(COOKIE_FIRSTNAME, firstName, expiresIn);
                setCookie(COOKIE_LASTNAME, lastName, expiresIn);
                if (imageUrl !== null) {
                    setCookie(COOKIE_IMAGE_URL, imageUrl, expiresIn);
                }
                window.location = ROUTE_ACCOUNT_HOME;
                redirect = true;
            } else {
                let cookie = getCookie(COOKIE_ACCOUNT_TOKEN);
                if (!cookie && window.location.href !== loginWithLinkedInPage()) {
                    redirect = true;
                    window.location = loginWithLinkedInPage();
                } else {
                    if ( cookie && window.location.href !== loginWithLinkedInPage() && !window.location.href.includes(ROUTE_ACCOUNT_HOME)) {
                        redirect = true;
                        window.location = ROUTE_ACCOUNT_HOME;
                    }
                }
            }
    } else {
        let cookie = getCookie(COOKIE_MANAGEMENT_TOKEN);
        let dataset = getDatasetLabel();
        let authenticate = !isAuthenticationDisabled();
        if(dataset && !isAuthenticationDisabled()) {
            const response = await graphSearchByMaps({[QUERY] : `{type(eq:[${ALIAS_SYS_API_CONFIGURATION}])}`}, {}, false).catch(() => {});
            if(isRequestSuccessful(response)) {
                let results = await response.json();
                let apiConfiguration = getSearchResult(results).find(r => r[TYPE] === ALIAS_SYS_API_CONFIGURATION);
                let authType = apiConfiguration && apiConfiguration[ALIAS_SYS_AUTHENTICATION];
                if (authType === EASYGRAPH_DATA_AUTHENTICATION_DISABLED || authType === EASYGRAPH_DATA_AUTHENTICATION_ALL_UPDATES) {
                    setDatasetLevelAuthenticationType(dataset, authType);
                    if (authenticate === true) {
                        authenticate = false;
                    }
                }
            }
        }
        if (!cookie && !isManagementLogin() && !isManagementAdminLogin() && !isManagementAdminLogout() && authenticate) {
            let routeWithInstanceAndDataset = getRouteWithInstance(ROUTE_MANAGEMENT_LOGIN);
            redirect = true;
            window.location = routeWithInstanceAndDataset;
        }

    }

    if(redirect !== true) {

        const defaultEndpoint = isMangedInstance() && isAnyAccountRouteOrHome()
            ? ROUTE_ACCOUNT_LOGIN
            : isAuthenticationDisabled() ? getRouteWithInstance(ROUTE_MANAGEMENT_HOME) : getRouteWithInstance(ROUTE_MANAGEMENT_LOGIN)

        async function getRedirectPath() {
            if (window.location.href !== loginWithLinkedInPage()) {
                if(isMangedInstance() && isAnyAccountRouteOrHome() && !homePagePath) {
                    return ROUTE_ACCOUNT_LOGIN;
                } else {
                    if(isManagementLogin() && !isAuthenticationDisabled()) {
                        return getRouteWithInstance(ROUTE_MANAGEMENT_LOGIN);
                    } else {
                        if(homePagePath) {
                            return homePagePath;
                        } else {
                            return isAuthenticationDisabled() ? getRouteWithInstance(ROUTE_MANAGEMENT_HOME) : getRouteWithInstance(ROUTE_MANAGEMENT_LOGIN);
                        }
                    }
                }
            } else {
                return loginWithLinkedInPage()
            }
        }

        getRedirectPath().then(r => {
            return ReactDOM.render(<App defaultRedirectPath={r} homePage={homePagePath}/>,
                document.getElementById("root")
            );
        })
    }

})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import {LINK_COLOR, PRIMARY_COLOR} from "../Constants";
import cytoscape from "cytoscape";
import dagre from "cytoscape-dagre";

export const NODE_WIDTH = 150;

export function getDiagramLayout(data, isSubclassOnlyView, options = {}) {
    let layout = {
        name: data.length > 0 ? 'fcose' : 'random',
        idealEdgeLength : 200,
        tilingPaddingVertical : 200,
        tilingPaddingHorizontal : 200,
        fit: true,
        ...options,
    };
    if(isSubclassOnlyView) {
        layout.nodeSep = 90;
        layout.name = 'dagre';
        layout.rankDir = 'TB';
        layout.animate = true;
        layout.animationDuration = options.animationDuration || 700;
    }
    return layout;
}

export function getDiagramStyle(theme, isSubclassOnlyView, edgeColor) {
    return [
        {
            selector: 'core',
            css: {
                'active-bg-opacity' : '.05'
            }
        },
        {
            selector: 'node',
            css: {
                'label':function (elem) {
                    return '\u3164' + (elem.data().label || elem.data().id) + '\u3164';
                },
                'text-valign': function (elem) {
                    if(elem.data().thumbnail) {
                        return 'bottom';
                    }
                    return 'center';
                } ,
                'shape': function (elem) {
                    if(elem.data().thumbnail) {
                        return undefined;
                    }
                    return 'round-rectangle';
                } ,
                'text-justification' : 'left',
                width: function (elem) {
                    if(elem.data().thumbnail) {
                        return '80px';
                    }
                    return 'label';
                },
                height: function (elem) {
                    if(elem.data().thumbnail) {
                        return '80px';
                    }
                    return '34px';
                },
                'background-color':  function (elem) {
                    if(elem.data().backgroundColor) {
                        return elem.data().backgroundColor;
                    }
                    return theme.palette.white.main;
                } ,
                'background-opacity' :function (elem) {
                    if(elem.data().backgroundColor) {
                        return .2;
                    }
                    return undefined;
                } ,
                'border-style': 'solid',
                'border-color': theme.palette.primary.main,
                'border-width': '1',
                'color': theme.palette.primary.main,
                "text-wrap": "wrap",
                padding : '0',
                'background-image': function (elem) {
                    if(elem.data().thumbnail) {
                        return elem.data().thumbnail;
                    }
                    return undefined;
                },
                'background-fit' : function (elem) {
                    if(elem.data().thumbnail) {
                        //Trying this . With class icons it seems to work better without contain;
                        //return 'contain';
                    }
                    return undefined;
                },
                'background-width' : function (elem) {
                    if (elem.data().thumbnail) {
                        return '80%';
                    }
                    return undefined;
                },
                'background-height' : function (elem) {
                    if (elem.data().thumbnail) {
                        return '80%';
                    }
                    return undefined;
                }
            }
        },
        {
            selector: 'node:active',
            css : {
                'overlay-opacity' : '.05'
            }
        }
        ,
        {
            "selector": "node:selected",
            "style": {
                "border-width": "1px",
                "border-color": theme.palette.secondary.selected,
                'color': theme.palette.secondary.main,
                'background-color' : '#faece9',
            }
        },
        {
            "selector": ".onMouseOverClass",
            "style": {
                "border-color": theme.palette.secondary.selected,
                'color': theme.palette.secondary.main,
                'background-color' : '#fdf6f4',
                'cursor' : 'pointer'
            }
        },
        {
            selector: 'edge',
            style: {
                width: 1,
                'curve-style': 'bezier',
                label: 'data(label)',
                "target-arrow-shape": "data(arrow)",
                "loop-direction": '-60',
                "loop-sweep": '-45',
                'color': edgeColor ? edgeColor : LINK_COLOR,
                'text-margin-y': '-10px',
                'line-color' : edgeColor ? edgeColor : LINK_COLOR,
                'source-arrow-color': theme.palette.grey.level2,
                'target-arrow-color':  edgeColor ? edgeColor : LINK_COLOR,
                'font-size': '12px'

            }
        }
        , {
            selector: '.subclass',
            style: {
                "curve-style": isSubclassOnlyView ? "taxi" : "bezier",
                label: isSubclassOnlyView ? '' : 'data(label)',
                "taxi-direction": "downward",
                "taxi-turn": 20,
                "taxi-turn-min-distance": 5,
                "line-style": 'dotted',
                "source-arrow-shape": "data(arrow)",
                "source-arrow-fill": "hollow",
                "target-arrow-shape": "none",
                "target-arrow-fill": "none",
                'color': theme.palette.grey.level3,
                'text-margin-y': '10px',
                'font-size': '12px',
                'line-color' : theme.palette.grey.level2,
                'source-arrow-color': theme.palette.grey.level2
            }
        },
        {
            selector: '.onMouseOverEdgeClass',
            style: {
                'line-color': theme.palette.secondary.main,
                'target-arrow-color': theme.palette.secondary.main,
                'source-arrow-color': theme.palette.secondary.main,
                "target-arrow-shape": "data(arrow)",
                'color': theme.palette.secondary.main,
                width : 2,
                'z-index' : 9999999
            }
        },
        {
            selector: 'edge:selected',
            style: {
               // 'line-color' : theme.palette.secondary.main,
               // 'source-arrow-color': theme.palette.secondary.main
            }
        }
    ]
}

export function adjustMinWidth(node) {
    let minWidth = node.data().thumbnail ? 0 : 100;
    if(node.width() < minWidth) {
        node.style('width', minWidth);
    } else {
        node.style('width', node.width() + 8);
    }
}

export function computeLabelAndWidth(title) {
    let length = 20;
    let label = title.length > length ? title.substring(0, length) + '...' : title;
    let width = title.length < 5 ? '100' : 'label';
    return {label, width};
}

export function addAdjustWidth(cytoscapeObj, outerThis) {
    cytoscapeObj.on('layoutstop', function(ev) {
        outerThis.cytoscapeObj.batch(function(){
            outerThis.cytoscapeObj.filter(function (element, i) {
                return element.isNode();
            }).forEach(n => {
                adjustMinWidth(n);
            })
        })
    });
}

export function registerExtension(cytoscape, extension, name) {
    if(!cytoscape.prototype[name]) {
        try {
            cytoscape.use(extension);
        } catch (e) {
            console.log(e);
        }
    }
}
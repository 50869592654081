import React from "react";
import GlobalsContext from "../../components/GlobalsContext";
import {
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    EVENT_TYPE_NODE_IN_EDIT_STATE,
    EVENT_TYPE_RESOURCE_DELETE,
    EVENT_TYPE_RESOURCE_PATCH,
    EVENT_TYPE_RESOURCE_REFRESH,
    GRAPH,
    ID,
    TYPE,
    UPDATE_EVENT_LISTENER_REGISTER,
    UPDATE_EVENT_PUBLISHER
} from "../../Constants";
import {clear, loadResource} from "../../service/data-loader";
import {NODE_ID_KEY} from "./NodeContainer";

export function withEvent(WrappedComponent) {
    return class extends React.Component {
        static contextType = GlobalsContext;

        constructor(props) {
            super(props);
        }

        render() {
            let globals = this.context;
            let registerForEvents = globals?.getGlobalsItem?.(UPDATE_EVENT_LISTENER_REGISTER);
            let publishEvent = globals?.getGlobalsItem?.(UPDATE_EVENT_PUBLISHER);
            return <WrappedComponent {...this.props} registerForEvents={registerForEvents} publishEvent={publishEvent}/>;
        }
    }
}

export async function loadResourceAndGet(resourceId) {
    try {
        let results = await loadResource(resourceId);
        let found = results.find(rs => rs && rs[ID] === resourceId);
        return found;
    } catch (e) {
        return null;
    }
}

/**
 * This method merge changes from the patch request and updates cached resource
 * @param publishEvent
 * @param event
 * @returns {Promise<*>}
 */
export async function mergeAndPublishEventForResourceUpdate(publishEvent, event) {
    let {payload, response} = event;
    const resourceId = payload[ID];
    let found = await loadResourceAndGet(resourceId);
    let responseJson = await response.json();
    Object.keys(payload).forEach(k => {
        if(k !== ID && k !== ALIAS_SYS_ETAG && k !== AT_CONTEXT) {
            if(payload[k]) {
                found[k] = payload[k];
            } else {
                delete found[k];
            }
        } else if (k === ALIAS_SYS_ETAG) {
            let eTag = responseJson[GRAPH].find(it => it[ID] === resourceId)[ALIAS_SYS_ETAG];
            found[k] = eTag;
        }
    })
    let returnValue = publishEvent?.(event);
    return returnValue ;
}

export async function reloadResourceAndPublishRefreshEvent(publishEvent, resourceId) {
    await clear(resourceId);
    let resource = await loadResourceAndGet(resourceId);
    publishEvent?.({[TYPE]: EVENT_TYPE_RESOURCE_REFRESH, payload: {[ID]: resourceId}});
}

export async function clearResourceAndPublishDeleteEvent(publishEvent, resourceId) {
    await clear(resourceId);
    publishEvent?.({[TYPE] : EVENT_TYPE_RESOURCE_DELETE, payload : {[ID] : resourceId}});
}

export function createResourcePatchEvent(patchPayload, response) {
    return {
        [TYPE]: EVENT_TYPE_RESOURCE_PATCH,
        payload : patchPayload,
        response : response
    };
}

export function createNodeInEditStateEvent(nodeId) {
    return {
        [TYPE]: EVENT_TYPE_NODE_IN_EDIT_STATE,
        [NODE_ID_KEY] : nodeId
    };
}

export function isResourceDeleteEvent(event) {
    return event && event[TYPE] === EVENT_TYPE_RESOURCE_DELETE;
}

export function isResourceUpdateEvent(event) {
    return event && event[TYPE] === EVENT_TYPE_RESOURCE_PATCH;
}

export function isResourceRefreshEvent(event) {
    return event && event[TYPE] === EVENT_TYPE_RESOURCE_REFRESH;
}

export function isNodeInEditStateEvent(event) {
    return event && event[TYPE] === EVENT_TYPE_NODE_IN_EDIT_STATE;
}



export async function publishEvent(callerThis, event) {
    let globals = callerThis.context;
    let returnValue = globals?.getGlobalsItem(UPDATE_EVENT_PUBLISHER)?.(event);
    return returnValue ;
}


export function registerUpdateEventListener(callerThis, method) {
    let globals = callerThis.context;
    globals?.getGlobalsItem(UPDATE_EVENT_LISTENER_REGISTER)?.(method);
}


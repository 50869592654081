import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {
    createNodeForView,
    getAliasesMap,
    getAllOntologyData,
    getApiConfigurationResource,
    getProp,
    getShapesData,
    sort
} from "../../components/util";
import queryString from "query-string";
import './APIPlayground.css';
import {ALIAS_RDFS_LABEL, ALIAS_SYS_EXAMPLE_COLLECTION, ID} from "../../Constants";
import PropTypes from "prop-types";
import {getAllConfigurations} from "../../service/graph-api";
import ShapeMockingDetails from "../../components/ShapeMockingDetails";
import {addCollectionMockingSetup, getCollectionMockingSetupData} from "../../service/playground-api";

class MockingConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
            loading: false
        }
        this.backendEventHandler = this.backendEventHandler.bind(this);
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
        this.setState({loading: true})
        const ontology = getAllConfigurations()
        ontology.then((response) => {
                const treeData = getShapesData(response).map(o => {
                    return createNodeForView(o, () => {
                        return getProp(o, ALIAS_RDFS_LABEL);
                    });
                }).map(o => {
                    o.tooltip = o.id
                    return o;
                })
                let aliasesMap = getAliasesMap(response)
                this.setState({
                    aliasesMap: aliasesMap, treeData: sort(treeData, 'title'), loading: false,
                    APIConfiguration: getApiConfigurationResource(response), ontology: getAllOntologyData(response)
                })
            }
        )

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    async backendEventHandler(event) {
        let {collectionDetails, onMockingSetupUpdate, readOnly} = this.props
        this.setState({loading: true})
        let mockObject = event.data
        mockObject[ALIAS_SYS_EXAMPLE_COLLECTION] = this.props.collectionDetails[ID]

        if (event.action === 'saveJSON') {
            //if readOnly i.e. import collection then do not store
            if(!readOnly) {
                addCollectionMockingSetup(collectionDetails, mockObject)
            }
        } else if (event.action === 'deleteNode') {

        }
        this.syncDataWithBackend()
        if(onMockingSetupUpdate) {
            onMockingSetupUpdate(mockObject)
        }
    }

    getMiddleComponent = () => {
        const {aliasesMap, ontology, addSuperClassPropsInShape, treeData,} = this.state;
        let {readOnly} = this.props
        let params = queryString.parse(this.props.location.search)
        let shapeIRI =params.id
        return <ShapeMockingDetails
            isReadOnly={readOnly}
            aliasesMap={aliasesMap}
            ontology={ontology}
            addSuperClassPropsInShape={addSuperClassPropsInShape}
            allShapes={treeData}
            shapeIRI={shapeIRI}
            mockingSetupObjects={this.getMockingSetupObjects(shapeIRI)}
            backendEventHandler={this.backendEventHandler}
            onAddSuperClassPropsInShapeChange={(value) => this.setState({addSuperClassPropsInShape: value})}
        />
    }

    getMockingSetupObjects = (shapeIRI) => {
        let mockingObjects = this.getAllMockingSetupObjects()
        let shapeMockingObjects =  mockingObjects ? mockingObjects.filter(o => o.shapeIRI === shapeIRI) : []
        return shapeMockingObjects;
    }

    getAllMockingSetupObjects = () => {
        let {mockingObjects, collectionDetails} = this.props
        if(mockingObjects) {
            return mockingObjects;
        } else {
            let mockingObjects = getCollectionMockingSetupData(collectionDetails)
            return mockingObjects
        }
    }

    render() {
        return this.getMiddleComponent();
    }
}

MockingConfiguration.propTypes = {
    location: PropTypes.object,
    collectionDetails: PropTypes.object,
    readOnly: PropTypes.bool,
    mockingObjects: PropTypes.array,
    onMockingSetupUpdate : PropTypes.func
};

export default withStyles(styles, {withTheme: true})(MockingConfiguration);

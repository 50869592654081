import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import {Typography, withStyles} from '@material-ui/core';
import MainAppBar from "../../components/header/MainAppBar";
import {LINK_COLOR, ROUTE_MANAGEMENT_LOGIN, STYLE_GRID_ITEM_SPACING} from "../../Constants";
import logoBG from "../../images/EasyGraphLogoRGBReversedCroped.png";
import Button from "@material-ui/core/Button";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import history from "../../history";
import H4Title from "../../components/H4Title";


class ManagementLogout extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(window.location.search);
    }

    getMiddleComponent = () => {
        let {theme} = this.props;
        let {apiError, apiErrorResponse} = this.state;
        return <div
            style={{backgroundColor: 'white', borderRadius: '4px', padding: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>
            {apiError && <BackendErrorDialog handleClose={() => this.setState({apiError:false, apiErrorResponse:undefined})} open={apiError} error={apiErrorResponse}/>}
            <Grid container style={{textAlign: 'center'}} spacing={1}>
                <Grid item container xs={12} justify={'center'}>
                    <H4Title style={{margin : '24px 0px'}}>You have logged out.</H4Title>
                </Grid>
                <Grid item container xs={12}>
                    <Button
                        datatest={"loginButton"}
                        fullWidth={true}
                        onClick={() => {
                            history.push(ROUTE_MANAGEMENT_LOGIN);
                        }}
                        variant={'contained'}
                        color={'secondary'}
                    >Login Again</Button>
                </Grid>
                <Grid item xs={12}>
                    <div style={{
                        paddingTop: '16px',
                        width: '100%',
                        textAlign: 'center',
                        borderTop: '1px solid',
                        borderColor: theme.palette.border.main,
                        marginTop: '40px'
                    }}>
                        <Typography style={{color: theme.palette.grey.level2}} variant="caption" component={'span'}>
                            EasyGraph from <a href={'https://www.graphifi.com'} target={'_blank'}
                                              style={{color: LINK_COLOR}}>Graphifi</a>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>;
    }


    render() {
        let {theme} = this.props;

        return (
            <div className={'full-screen'} style={{
                overflow: 'auto',
                backgroundColor: theme.palette.primary.main,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '60%',
                backgroundPosition: 'bottom -160px right -160px',
                backgroundImage: `url(${logoBG})`,
            }}>
                <div style={{overflow: 'hidden'}}>
                    <MainAppBar disableProfile={true}/>
                </div>
                <div style={{
                    overflow: 'auto',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{maxWidth: 352}}>{
                             this.getMiddleComponent()
                    }</div>
                </div>
            </div>
        );
    }

}

export default withStyles({}, {withTheme: true})(ManagementLogout);

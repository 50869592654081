import React from "react";
import PropTypes from 'prop-types';

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {styles} from "../../components/styles";
import {Grid} from "@material-ui/core";
import StepperHeader from "../../components/StepperHeader";
import FinishButton from "../../components/FinishButton";
import GridContainer from "../../components/GridContainer";
import MainHeaderBar from "../../components/MainHeaderBar";
import {
    getAllOntologyData,
    getApiConfigurationResource,
    getFileExtension,
    getLocalName,
    getMaxExampleSetFileSize,
    getPropertyClasses,
    getShapePropertyArray,
    getShapesData,
    isAnnotationProperty,
    isClass,
    isDatatypeProperty,
    isObjectProperty
} from "../../components/util";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_IS_MODEL_RESOURCE_OF,
    ALIAS_SYS_PROPERTY_TYPE,
    ALIAS_SYS_TYPE_SCHEMA,
    FROM_SHAPE,
    ID,
    OBJECT_LINKING_PROPERTY,
    SH_LITERAL,
    TYPE,
    TYPE_RDF_LIST,
    VALIDATION_MODEL_FILE_MAX_COUNT,
    VALIDATION_MODEL_FILE_MAX_SIZE
} from "../../Constants";
import FileUpload from "../../components/FileUpload";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import H2Title from "../../components/H2Title";
import Model from "../../layouts/modelbuilder/Model";
import {createModelObject} from "../../layouts/modelbuilder/util";
import {filterOntology, filterOntologyClasses, filterOntologyProperties, filterShapes} from "../../service/model-api";
import {TYPE_OPTIONS} from "../../layouts/modelbuilder/Property";
import FooterPreviousButton from "../../components/FooterPreviousButton";
import FooterNextButton from "../../components/FooterNextButton";

const ALLOWED_TYPES = [".json", ".jsonld"]

function initialState() {
    return {
        activeStep : 0,
        open: false,
        loading: false,
        name: '',
        valid : false,
        selectedFiles: [],
        expanded: []

    };
};


class ImportModelDialog  extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.isValid() && !this.state.valid) {
            this.setState({valid: true})
        } else if (this.state.valid && !this.isValid()) {
            this.setState({valid: false})
        }
    }

    isValid = () => {
        let {activeStep} = this.state
        switch (activeStep) {
            case 0:
                const {selectedFiles, selectedFilesError} = this.state
                let error = selectedFilesError && selectedFilesError === true ? true : false;
                return (selectedFiles && selectedFiles.length > 0 && !error)
            default:
                return true
        }

    }


    handleClickOpen = (ev) => {
        this.setState({open: true});
        ev.preventDefault();
    };

    handleClose = () => {
        this.props.onClose()
        this.setState({...initialState()});
    };

    getSteps = () => {
        return ['Select File', 'Preview', 'Import'];
    }

    isLastStep = () => {
        const steps = this.getSteps();
        const {activeStep} = this.state
        return activeStep === steps.length - 1;
    }

    getFooter = () => {
        const {activeStep, loading} = this.state
        return this.isLastStep()
            ?  <>
                <div style={{flexGrow:1}}/>
                <div>
                    <FinishButton onClick={this.handleClose}/>
                </div>
            </>
            : <>
            <Button
                datatest={'cancelButton'}
                variant={"text"}
                onClick={this.handleClose}
                color="secondary"
            >
                Cancel
            </Button>
            <div style={{flexGrow:1}}/>
            {
                activeStep !== 0 &&
                <FooterPreviousButton
                    disabled={activeStep === 0 || loading}
                    onClick={this.handleBack}
                />
            }
            {
                <FooterNextButton
                    disabled={!this.isStepContentValid(activeStep) || loading}
                    onClick={this.handleNext}
                />
            }
        </>;
    }

    handleNext = () => {
        let {activeStep, ontologyClasses, ontologyProperties, shapes, modelDetails} = this.state
        if(activeStep === 1) {
            this.props.eventHandler(modelDetails, [...ontologyClasses, ...ontologyProperties, ...shapes])
        }
        this.setState({loading: false, activeStep: activeStep + 1})
    }

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    isStepContentValid = () => {
        return this.state.valid
    }

    getMainHeaderBar = () => {
        return <Grid item xs={12} style={{paddingTop: 0}}>
            <MainHeaderBar title={this.getStepName()}></MainHeaderBar>
        </Grid>;
    }

    getStepName = () => {
        const {activeStep} = this.state
        return this.getSteps()[activeStep];
    }

    getStepContent = () => {
        const {activeStep} = this.state
        switch (activeStep) {
            case 0:
                return this.getSelectFileComponent();
            case 1:
                return this.getPreviewComponent();
            case 2:
                return this.getSuccessScreen();
            default:
                return undefined;
        }

    }

    getSuccessScreen = () => {
        let {theme} = this.props
        return <GridContainer style={{textAlign: 'center'}}>
            <Grid item xs={12}>
                <div>
                    <CheckCircleIcon fontSize="large" style={{fontSize: '80px', color: theme.palette.success.main}}/>
                </div>
            </Grid>
            <Grid item xs={12}>
                <H2Title style={{color: theme.palette.success.main}} title={'Import Successful'}/>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </GridContainer>;
    }

    hasCountError = (files) => {
        return files.length > VALIDATION_MODEL_FILE_MAX_COUNT ? true : false;
    }

    onFilesSelect = (selectedFiles) => {
        let {existingModelDetails, shapes, ontologyClasses, ontologyProperties} = this.props;
        let selectedFilesFromState = this.state.selectedFiles ? this.state.selectedFiles : [];
        let selectedFilesFiltered = selectedFiles.filter(f => ALLOWED_TYPES.includes("."+getFileExtension(f.name)))
            .filter(f => !selectedFilesFromState.find(sf => sf.name === f.name));
        let allFiles = selectedFilesFiltered.length > 0 ? [...selectedFilesFiltered, ...selectedFilesFromState] : selectedFilesFromState
        let selectedFilesError = this.hasCountError(allFiles);
        allFiles.forEach(f => {
            if(f.size > VALIDATION_MODEL_FILE_MAX_SIZE){
                selectedFilesError = true
                this.setState({ [f.name+'error']: `File size should be less than ${getMaxExampleSetFileSize()}`})
            }
        })
        if(selectedFilesError) {
            this.setState({
                selectedFiles: allFiles,
                selectedFilesError: selectedFilesError,
                valid: (selectedFilesError === true ? false : true)
            });
        } else {
            let modelDetailsToSet;
            let clashedIds = [];
            let clashedLabels = [];
            let allData = []
            let promises = [];
            for (let selectedFile of allFiles) {
                let filePromise = new Promise(resolve => {
                    let fileReader = new FileReader();
                    fileReader.onload = () => {
                        let fileContent = fileReader.result;
                        resolve({name: selectedFile.name, fileContent: fileContent, file: selectedFile})
                    }
                    fileReader.readAsText(selectedFile)
                });
                promises.push(filePromise);
            }
            Promise.all(promises).then(fileObjects => {

                fileObjects.forEach(p => {
                    let object = JSON.parse(p.fileContent);
                    let apiConfigurationObject = getApiConfigurationResource(object);
                    let modelDetailsFromImport = object.find && object.find(o => o[TYPE] === ALIAS_SYS_TYPE_SCHEMA);
                    if (!modelDetailsFromImport && !existingModelDetails) {
                        selectedFilesError = true;
                        this.setState({
                            selectedFilesError,
                            valid: false,
                            [p.name + 'error']: `File is not valid schema file.`
                        })
                    } else {
                        if(existingModelDetails) {
                            let modelID = existingModelDetails[ID];
                            modelDetailsToSet = existingModelDetails;
                            if(apiConfigurationObject && Object.keys(apiConfigurationObject).length > 0) {
                                let allOntologyData = getAllOntologyData(object);
                                let shapesData = getShapesData(object);
                                // if shapes has property not defined in ontology remove
                                let ids = allOntologyData.map(o => o[ID]);
                                shapesData.forEach(s => {
                                    let property = getShapePropertyArray(s).filter(p => !p[ALIAS_SH_PATH] || ids.includes(p[ALIAS_SH_PATH]));
                                    s[ALIAS_SH_PROPERTY] = property;
                                })
                                allOntologyData.forEach(o => {
                                    o[ALIAS_SYS_ID_LABEL] = getLocalName(o[ID], false);
                                    o[ALIAS_SYS_IS_MODEL_RESOURCE_OF] = modelID;
                                    if (isClass(o)) {
                                        // do nothing
                                    } else {
                                        let shapeProperty;
                                        shapesData.forEach(s => {
                                            let property = getShapePropertyArray(s).find(p => p[ALIAS_SH_PATH] === o[ID]);
                                            if (property && !shapeProperty) {
                                                shapeProperty = property;
                                            }
                                        })
                                        if (isObjectProperty(o)) {
                                            if (shapeProperty) {
                                                let shClass = getPropertyClasses(shapeProperty);
                                                let isList = shClass === TYPE_RDF_LIST;
                                                if (isList) {
                                                    o[ALIAS_SYS_PROPERTY_TYPE] = TYPE_OPTIONS.find(o => o.value === TYPE_RDF_LIST);
                                                } else {
                                                    o[ALIAS_SYS_PROPERTY_TYPE] = TYPE_OPTIONS.find(o => o.value === OBJECT_LINKING_PROPERTY);
                                                }
                                            } else {
                                                o[ALIAS_SYS_PROPERTY_TYPE] = TYPE_OPTIONS.find(o => o.value === OBJECT_LINKING_PROPERTY);
                                            }
                                        } else if (isDatatypeProperty(o) || isAnnotationProperty(o)) {
                                            let shDatatype = shapeProperty && shapeProperty[ALIAS_SH_DATATYPE];
                                            if (shapeProperty && shDatatype) {
                                                let propertyType = TYPE_OPTIONS.find(o => o.value === shDatatype);
                                                if (propertyType) {
                                                    o[ALIAS_SYS_PROPERTY_TYPE] = propertyType;
                                                } else {
                                                    o[ALIAS_SYS_PROPERTY_TYPE] = TYPE_OPTIONS.find(o => o.value === SH_LITERAL);
                                                }
                                            } else {
                                                o[ALIAS_SYS_PROPERTY_TYPE] = TYPE_OPTIONS.find(o => o.value === SH_LITERAL);
                                            }
                                        }
                                    }
                                })
                                shapesData.forEach(s => {
                                    s[ALIAS_SYS_IS_MODEL_RESOURCE_OF] = modelID;
                                    getShapePropertyArray(s).forEach(p => {
                                        p[FROM_SHAPE] = s[ID];
                                    })
                                })
                                allData = [...allOntologyData, ...shapesData];
                            } else if (modelDetailsFromImport) {
                                let ontology = filterOntology(object);
                                let shapes = filterShapes(object);
                                if(ontology.length === 0) {
                                    selectedFilesError = true;
                                    this.setState({
                                        selectedFilesError,
                                        valid: false,
                                        [p.name + 'error']: "Invalid file. No class or property defined in the file."
                                    })
                                } else {
                                    allData = [...ontology, ...shapes];
                                    allData.forEach(s => {
                                        s[ALIAS_SYS_IS_MODEL_RESOURCE_OF] = modelID;
                                    })
                                }
                            } else {
                                selectedFilesError = true;
                                this.setState({
                                    selectedFilesError,
                                    valid: false,
                                    [p.name + 'error']: "Invalid file. File should be either a full configuration file or a schema file."
                                })
                            }
                            let idToLabelMap = {}
                            let labelToIdMap = {}
                            allData.forEach(d => {
                                idToLabelMap[d[ID]] = d[ALIAS_SYS_ID_LABEL];
                                labelToIdMap[d[ALIAS_SYS_ID_LABEL]] = d[ID];
                            })

                            let allExistingData = [...ontologyClasses, ...ontologyProperties, ...shapes];
                            let newIds = new Set(Object.keys(idToLabelMap));
                            let newLabels = new Set(Object.keys(labelToIdMap));
                            allExistingData.forEach(d => {
                                let id = d[ID];
                                if(newIds.has(id)) {
                                    clashedIds.push(id);
                                }
                                let label = d[ALIAS_SYS_ID_LABEL];
                                if(label && newLabels.has(label)) {
                                    clashedLabels.push(label)
                                }
                            })
                            if(clashedIds.length > 0) {
                                selectedFilesError = true;
                                this.setState({
                                    selectedFilesError,
                                    valid: false,
                                    [p.name + 'error']: "Imported file contains ids which are already used in model. Clashing IDs are " + clashedIds.join(', ')
                                })
                            }
                            if(clashedLabels.length > 0) {
                                selectedFilesError = true;
                                this.setState({
                                    selectedFilesError,
                                    valid: false,
                                    [p.name + 'error']: "Imported file contains names which are already used in model. Clashing names are " + clashedLabels.join(', ')
                                })
                            }

                        } else {
                            if(modelDetailsFromImport) {
                                let ontology = filterOntology(object);
                                let shapes = filterShapes(object);
                                if(ontology.length === 0) {
                                    selectedFilesError = true;
                                    this.setState({
                                        selectedFilesError,
                                        valid: false,
                                        [p.name + 'error']: "Invalid file. No class or property defined in the file."
                                    })
                                } else {
                                    let newModelObject = createModelObject(modelDetailsFromImport);
                                    allData = [...ontology, ...shapes];
                                    let newModelID = newModelObject[ID];
                                    allData.forEach(object => {
                                        object[ALIAS_SYS_IS_MODEL_RESOURCE_OF] = newModelID;
                                    })
                                    modelDetailsFromImport[ID] = newModelID;
                                    modelDetailsToSet = modelDetailsFromImport;
                                }
                            } else {
                                selectedFilesError = true;
                                this.setState({
                                    selectedFilesError,
                                    valid: false,
                                    [p.name + 'error']: "Invalid file. File should be a valid schema file."
                                })
                            }
                        }
                    }

                })
                this.setState({
                    modelDetails: modelDetailsToSet,
                    ontologyClasses: filterOntologyClasses(allData),
                    ontologyProperties: filterOntologyProperties(allData),
                    shapes: filterShapes(allData),
                    selectedFiles: allFiles,
                    selectedFilesError: selectedFilesError,
                    valid: (selectedFilesError === true ? false : true)
                });
            });
        }

    };

    getSelectFileComponent = () => {
        let {selectedFiles} = this.state

        let content = <GridContainer>
            {this.getMainHeaderBar()}
            <Grid item xs={12}>
                <FileUpload
                    accept={ALLOWED_TYPES}
                    fileUploadMessage={`A maximum of ${VALIDATION_MODEL_FILE_MAX_COUNT} file is allowed.`}
                    fileUploadMessageError={this.state.selectedFilesError}
                    dragNDropBoxTitle={"Drag 'n' Drop Schema File"}
                    files={ selectedFiles.map((f) => ({
                        f: f,
                        errors: (this.state[f.name + 'error'] ? [this.state[f.name + 'error']] : [])
                    }))}
                    onFilesSelect={this.onFilesSelect}
                    onDelete={(file) => {
                        let selectedFilesCopy = selectedFiles.filter(f => f.name !== file.name);
                        let errorName = file.name + 'error';
                        let otherError = selectedFilesCopy.find(fl => this.state[fl.name + 'error']) ? true : false;
                        let countError = this.hasCountError(selectedFilesCopy);
                        this.setState({selectedFiles: selectedFilesCopy,  [errorName]: null, selectedFilesError: (countError || otherError)});
                    }}
                />
            </Grid>
        </GridContainer>;

        return <>
            {content}
        </>;
    }

    getPreviewComponent = () => {
        const {shapes, modelDetails, ontologyClasses, ontologyProperties, focusNode, expanded} = this.state
        const {location} = this.props

        return <Model
            key={focusNode ? focusNode.id : 'previewExamples'}
            readOnly={true}
            hideHeader={true}
            headerTitle={this.getStepName()}
            modelDetails={modelDetails}
            ontologyClasses={ontologyClasses}
            ontologyProperties={ontologyProperties}
            shapes={shapes}
            existingOntologyClasses={this.props.ontologyClasses}
            existingOntologyProperties={this.props.ontologyProperties}
            existingShapes={this.props.shapes}
            focusNode={focusNode}
            location={location}
            expanded={expanded}
            onTreeNodeToggle={(nodeIds) =>  {this.setState({expanded: nodeIds})}}
            mockTreeStyle={{ maxHeight: `calc(100vh - 318px)`}}
            scenariosTreeStyle={{ maxHeight: `calc(100vh - 376px)`}}
        />;
    }

    render () {
        const {classes, theme, open} = this.props
        return <Dialog
            fullScreen={true}

            open={open}
            aria-labelledby="form-dialog-title"
            classes={{paper: classes.dialog}}
        >
            <DialogTitle style={{padding: '0px'}} disableTypography={true} id="form-dialog-title">
                <StepperHeader steps={this.getSteps()} activeStep={this.state.activeStep}
                               title={'Import Schema'}></StepperHeader>
            </DialogTitle>
            <DialogContent style={{backgroundColor: theme.palette.grey.background, padding: '0px'}}>
                {this.getStepContent()}
            </DialogContent>
            <DialogActions datatest={'dialogActions'} style={{padding: '12px 24px', borderTop: '1px solid ' + theme.palette.border.main}}>
                {this.getFooter()}
            </DialogActions>
        </Dialog>;
    }
}


ImportModelDialog.propTypes = {
    eventHandler: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    existingModelDetails: PropTypes.object,
    shapes: PropTypes.array,
    ontologyClasses: PropTypes.array,
    ontologyProperties: PropTypes.array,
    location: PropTypes.object,
    open: PropTypes.bool,
}

export default withStyles(styles, {withTheme: true})(ImportModelDialog);

import React from "react";
import {makeStyles} from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from "@material-ui/icons/Edit";
import {GetApp} from "@material-ui/icons";

export const lightIconStyle = makeStyles( theme => ({
    root : {
        color: theme.palette.grey.level2
    }
}));

const darkIconStyle = makeStyles( theme => ({
    root : {
        color: theme.palette.grey.level3
    }
}));

export function GreyLockIcon(props) {
    const classes = lightIconStyle();
    let fontSizeComp = props.fontSize ? props.fontSize : 'small';
    let {fontSize, ...rest} = props;
    return <LockIcon fontSize={fontSizeComp} className={classes.root} {...props}/>;
}

export function GreyDeleteIcon(props) {
    const classes = darkIconStyle();
    let {className, ...rest} = props;
    return <DeleteIcon className={classes.root} {...rest}/>;
}

export function GreyEditIcon(props) {
    const classes = darkIconStyle();
    let {className, ...rest} = props;
    return <EditIcon className={classes.root} {...rest}/>;
}

export function GreyGetAppIcon(props) {
    const classes = darkIconStyle();
    let {className, ...rest} = props;
    return <GetApp className={classes.root} {...rest}/>;
}

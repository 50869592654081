import React from "react";
import {TextField as OtherTextField, Tooltip, withStyles} from "@material-ui/core";
import {styles} from "../../components/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptionsFromLabel, getLanguageCodesSuggestions, toArray} from "../../components/util";
import {isArray, isEqual, isString, uniqWith} from "lodash";
import PropTypes from "prop-types";
import {getChipWithDelete} from "../../layouts/apiplayground/SearchFilter";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

class LanguageSearchSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue : ''
        };
    }

    callOnChange = (val) => {
        let {onChange} = this.props;
        if(isArray(val)) {
            onChange(uniqWith(val, isEqual));
        } else {
            onChange(val);
        }
    }

    render() {
        let {classes, autoFocus, tooltip, extraDense, datatest, theme, value, multiple, label, labelRootClass, excludeSuggestions, inputLabelPropsOverride} = this.props;
        let {inputValue} = this.state;
        let langValue = multiple === true ? (value ? value : []) : (value ? value : '')
        let inputLabelProps = inputLabelPropsOverride !== undefined ? inputLabelPropsOverride : classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : labelRootClass ? classes[labelRootClass] : classes.smallWidthPrecedenceLabelRoot
                }
            }
            : {};

            return <Autocomplete
                freeSolo={true}
                datatest={datatest}
                multiple={multiple}
                value={langValue}
                options={this.getOptions()}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    if (option.value) {
                        return option.value;
                    }
                    return option.label;
                }}
                renderOption={option => option.label}
                onChange={(event, newValIn, reason) => {
                    if (!reason || reason === 'select-option') {
                        let objects = toArray(newValIn).map(newVal => {
                            let newLanguageCodeObject;
                            if (isString(newVal)) {
                                newLanguageCodeObject = {
                                    value: newVal,
                                    label: newVal
                                }
                            } else {
                                newLanguageCodeObject = newVal;
                            }
                            return newLanguageCodeObject;
                        });
                        this.setState({inputValue: ''})
                        this.callOnChange(multiple ? objects : objects[0])
                    }
                }}
                onInputChange={(event, newVal, reason) => {
                    if (reason === 'input') {
                        if (newVal) {
                            let newLanguageCodeObject;
                            if (isString(newVal)) {
                                newLanguageCodeObject = {
                                    value: newVal,
                                    label: newVal
                                }
                            } else {
                                newLanguageCodeObject = newVal;
                            }
                            this.setState({inputValue: newLanguageCodeObject});
                        } else {
                            if(multiple === false) {
                                this.callOnChange();
                            }
                            this.setState({inputValue: ''});
                        }
                    }
                }}
                renderInput={params => {

                    let component = extraDense
                        ? <OtherTextField
                            style={{padding : '0px', margin : '0px', minWidth : '140px'}}
                            autoFocus={autoFocus}
                            label={label}
                            {...params}
                            margin={"dense"}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={ inputLabelProps}
                            InputProps={{
                                ...params.InputProps,
                                style: {padding : '1px'},
                                endAdornment : tooltip && <Tooltip title={tooltip}><HelpOutlineIcon fontSize={'small'} style={{padding : '0px'}}/></Tooltip>
                            }}
                            inputProps={{
                                ...params.inputProps,
                                style: {padding : '2px 4px'}
                            }}
                        />
                        : <OtherTextField
                            label={label}
                            {...params}
                            margin={"dense"}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={ inputLabelProps}
                        />;

                    return component;
                }}
                filterOptions={(options, params) => {
                    let paramsNew = {
                        inputValue: params.inputValue || (inputValue && inputValue.value) || ''
                    }
                    const filtered = createFilterOptionsFromLabel(options, paramsNew);
                    let newLanguageCodeObject;
                    if (paramsNew.inputValue !== '' && !filtered.find(v => v.value === paramsNew.inputValue)) {
                        newLanguageCodeObject = {
                            value: paramsNew.inputValue,
                            label: `Add "${paramsNew.inputValue}"`,
                        };
                        filtered.push(newLanguageCodeObject);
                    }
                    return filtered;
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return getChipWithDelete(theme, index, option.value, option.value, getTagProps({index}));
                    })
                }}
                size={"small"}
                disableClearable
                onBlur={() => {
                    if (inputValue) {
                        let newLanguageCodeObject;
                        if (isString(inputValue)) {
                            newLanguageCodeObject = {
                                value: inputValue,
                                label: inputValue
                            };
                        } else {
                            newLanguageCodeObject = inputValue;
                        }
                        let langValueNew = multiple ? [...langValue, newLanguageCodeObject] : newLanguageCodeObject;
                        this.setState({inputValue: ''});
                        this.callOnChange(langValueNew);
                    }
                }}
            />;

    }

    getOptions = () => {
        let {excludeSuggestions, addNameToLabel, suggestions} = this.props;
        if(suggestions !== undefined) {
            return suggestions;
        }
        return getLanguageCodesSuggestions().filter(o => !excludeSuggestions || !excludeSuggestions.includes(o.value)).map(o => ({
            value: o.value,
            label: addNameToLabel ? `${o.value}  (${o.label})` : o.value
        }));
    }
}

LanguageSearchSelect.propTypes = {
    onChange: PropTypes.func,
    autoFocus: PropTypes.bool,
    value : PropTypes.any,
    labelRootClass : PropTypes.string,
    datatest : PropTypes.string,
    multiple : PropTypes.any,
    label : PropTypes.any,
    inputLabelPropsOverride : PropTypes.any,
    excludeSuggestions : PropTypes.array,
    suggestions : PropTypes.array,
    addNameToLabel: PropTypes.bool,
    extraDense: PropTypes.bool,
    tooltip: PropTypes.any,
}

export default withStyles(styles, {withTheme: true})(LanguageSearchSelect);

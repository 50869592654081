import React from "react";
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import {Grid, Typography} from '@material-ui/core';

import {ALIAS_MANAGEMENT_FILE_NAME} from "../../Constants";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import {styles} from "../../components/styles";
import PublishIcon from '@material-ui/icons/Publish';
import {getObjectStoreEndpoint, uploadObject} from "../../service/graph-api";
import Dropzone from "react-dropzone";
import H2Title from "../../components/H2Title";
import qs from "qs";
import {setLinkedToId} from "./Workspaces";

function initialState() {
    return {
        loading: false,
        fileContent: '',
        fileContentError: '',
        selectedFiles: []
    };
}


export function getObjectURL(key) {
    let paramString = qs.stringify({key: key}, {indices: false});
    let url = getObjectStoreEndpoint() + "?" + paramString;
    return url;
}

class UploadFileDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = initialState(this.props.propName)
    }

    handleError = (e) => {
        let {onBackendError} = this.props;
        this.setState({loading: false, open: false});
        onBackendError && onBackendError(e);
    }


    onFilesSelect = (selectedFiles) => {
        if(selectedFiles && selectedFiles.length > 0) {
            const {linkedTo} = this.props;
            let params = {
                [ALIAS_MANAGEMENT_FILE_NAME] : selectedFiles[0].name
            };
            setLinkedToId(params, linkedTo);

            uploadObject(selectedFiles[0], params).then((r) => {
                if(r.status === 200) {
                    r.json().then((json) => {
                        let key = json.key;
                        let url = getObjectURL(key);
                        this.props.onSuccess(url);
                    })
                } else {

                }
            })

        }
    }


    render() {
        const {theme, style} = this.props;
        const {apiErrorResponse, apiError, selectedFiles} = this.state;

        return (
            <div style={style ? style : {}}>
                {apiError && <BackendErrorDialog open={apiError} error={apiErrorResponse}
                                                 handleClose={() => this.setState({
                                                     apiErrorResponse: undefined,
                                                     apiError: undefined
                                                 })}/>}
                <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Dropzone multiple={false} onDrop={this.onFilesSelect}>
                                    {({getRootProps, getInputProps}) => (
                                        <section>
                                            <div {...getRootProps()}>
                                                <input {...getInputProps()} />
                                                <div datatest={'dropzone'} style={{
                                                    border: "2px dashed",
                                                    borderColor: theme.palette.grey.level61,
                                                    padding: '16px',
                                                    textAlign: 'center'
                                                }}>
                                                    <H2Title>Drag 'n' Drop Image file </H2Title>
                                                    <Typography style={{margin : '8px, 0px'}}>or</Typography>
                                                    <Button variant={'outlined'} startIcon={<PublishIcon color={'primary'}/>}>Browse
                                                        files</Button>
                                                </div>
                                                {selectedFiles && selectedFiles.map((f, i) => <Typography
                                                    key={i} component={'span'}
                                                    variant="body2">{f.name}</Typography>)}
                                            </div>
                                        </section>
                                    )}
                                </Dropzone>

                            </Grid>

                        </Grid>

      </div>
        )
    }

}

UploadFileDialog.propTypes = {
    linkedTo: PropTypes.any,
    buttonIcon: PropTypes.object,
    style: PropTypes.object,
    onSuccess: PropTypes.func,
};


export default withStyles(styles, {withTheme: true})(UploadFileDialog);

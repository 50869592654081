import React from "react";
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import 'jsoneditor/dist/jsoneditor.css';

import JSONEditor from 'jsoneditor';

import withStyles from "@material-ui/core/styles/withStyles";
import './JSONEditorReact.css';
import expand from '../images/icons/expand.svg';
import collapse from '../images/icons/collapse.svg';
import format from '../images/icons/format.svg';
import compact from '../images/icons/compact.svg';
import {attachToWindowForTesting} from "../components/util";


const styles = theme => ({});


class JSONEditorReact extends React.Component {
  constructor(props) {
    super(props);
    this.setState({})
  }

  componentDidMount () {
    // copy all properties into options for the editor
    // (except the properties for the JSONEditorReact component itself)
    const options = Object.assign({}, this.props);
    delete options.json;
    delete options.text;

    if(this.props.height === '100%') {
      options.onModeChange = (newMode, oldMode) => { this.setHeight(newMode, oldMode, options.onModeChange);}
    }

    this.jsoneditor = new JSONEditor(this.container, options);

    if(this.props.height === '100%') {
      this.jsoneditor.aceEditor.setOptions({
        maxLines: 10000
      })
    }

    if ('json' in this.props) {
      this.jsoneditor.set(this.props.json);
    }
    if ('text' in this.props) {
      this.jsoneditor.setText(this.props.text);
    }
    this.schema = cloneDeep(this.props.schema);
    this.schemaRefs = cloneDeep(this.props.schemaRefs);
    let {datatest} = this.props;
    if(datatest) {
      attachToWindowForTesting('jsonEditor'+datatest, this.jsoneditor);
    }

  }

  componentWillUpdate(nextProps, nextState) {
    if ('json' in nextProps) {
      this.jsoneditor.update(nextProps.json);
    }

    if ('text' in nextProps) {
      this.jsoneditor.updateText(nextProps.text);
    }

    if ('mode' in nextProps) {
      this.jsoneditor.setMode(nextProps.mode);
    }

    // store a clone of the schema to keep track on when it actually changes.
    // (When using a PureComponent all of this would be redundant)
    const schemaChanged = !isEqual(nextProps.schema, this.schema);
    const schemaRefsChanged = !isEqual(nextProps.schemaRefs, this.schemaRefs);
    if (schemaChanged || schemaRefsChanged) {
      this.schema = cloneDeep(nextProps.schema);
      this.schemaRefs = cloneDeep(nextProps.schemaRefs);
      this.jsoneditor.setSchema(nextProps.schema, nextProps.schemaRefs);
    }
  }

  componentWillUnmount () {
    if (this.jsoneditor) {
      this.jsoneditor.destroy();
    }
  }

  setHeight = (newMode, oldMode, onModeChange) => {
    if(newMode === 'code') {
      this.jsoneditor && this.jsoneditor.aceEditor.setOptions({
        maxLines: 10000
      })
    }
  }

  render() {
    // img tags below are for custom icons. with these refs custom icons are available
    // from /static/media ednpoit and CSS JSONEditorReact refers these icons
    // to override default icons
    return (<>
        <div style={{display: 'none'}}>
          <img alt="expand" src={expand}/>
          <img alt="collapse" src={collapse}/>
          <img alt="format" src={format}/>
          <img alt="compact" src={compact}/>
        </div>
        <div style={{height: this.props.height}}  className="jsoneditorReactContainer" ref={elem => this.container = elem} />
        </>
    );
  }
}

export default withStyles(styles)(JSONEditorReact);

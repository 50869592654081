import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import './APIPlayground.css';
import {IconButton as MUIIconButton, Typography} from "@material-ui/core";
import CollectionDetails from "../../layouts/apiplayground/CollectionDetails";
import {
    ALIAS_SYS_EXAMPLE_TYPE_BATCH,
    ALIAS_SYS_EXAMPLE_TYPE_CREATE,
    ALIAS_SYS_EXAMPLE_TYPE_DELETE,
    ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    ALIAS_SYS_EXAMPLE_TYPE_UPDATE,
    EXAMPLE_TYPE_TO_MENU_LABEL,
    ID,
    STYLE_GRID_ITEM_SPACING,
    TYPE
} from "../../Constants";
import H2Title from "../../components/H2Title";

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from "@material-ui/core/AccordionDetails";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {centerVertically, getContainerData, isReadOnlyAPI} from "../../components/util";
import {traceComponentDidMountStart, traceComponentDidUpdateStart, traceRenderStart} from "../../components/Trace"
import H3Title from "../../components/H3Title";
import ResponseStatusBar from "../../components/ResponseStatusBar";
import MenuListComposition from "../../components/MoreMenuIcon";
import AddIcon from "@material-ui/icons/Add";
import CreateRequest from "../../layouts/apiplayground/CreateRequest";
import SearchRequest from "../../layouts/apiplayground/SearchRequest";
import UpdateRequest from "../../layouts/apiplayground/UpdateRequest";
import DeleteRequest from "../../layouts/apiplayground/DeleteRequest";
import ResetRequest from "../../layouts/apiplayground/ResetRequest";
import LinearProgress from '@material-ui/core/LinearProgress';
import {getDirtyIndicator} from "../../components/RequestResponse";
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import BatchRequest from "../../layouts/apiplayground/BatchRequest";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ErrorIcon from "@material-ui/icons/Error";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import FieldContainer from "../../components/FieldContainer";
import {BlockActionButton} from "../../components/ShapeToForm/ArrayType";
import GlobalsContext from "../../components/GlobalsContext";

export function showRequest(requestObject, exampleSetId, apiMode, aliasesMap, registerSendAction, onSaveRequest, onRequestObjectChange, customizations) {
    let type = requestObject[TYPE]
    switch (type) {
        case ALIAS_SYS_EXAMPLE_TYPE_CREATE : {
            return <CreateRequest
                key={requestObject[ID]}
                createRequestObject={requestObject}
                aliasesMap={aliasesMap}
                exampleSetId={exampleSetId}
                onSave={onSaveRequest}
                apiMode={apiMode}
                registerSendAction={registerSendAction}
                onRequestObjectChange={onRequestObjectChange}
                customizations={customizations}
            />;
        }
        case ALIAS_SYS_EXAMPLE_TYPE_SEARCH : {
            return <SearchRequest
                key={requestObject[ID]}
                searchRequestObject={requestObject}
                aliasesMap={aliasesMap}
                exampleSetId={exampleSetId}
                onSave={onSaveRequest}
                apiMode={apiMode}
                registerSendAction={registerSendAction}
                onRequestObjectChange={onRequestObjectChange}
                customizations={customizations}
            />;
        }
        case ALIAS_SYS_EXAMPLE_TYPE_UPDATE : {
            return <UpdateRequest
                key={requestObject[ID]}
                patchRequestObject={requestObject}
                aliasesMap={aliasesMap}
                exampleSetId={exampleSetId}
                onSave={onSaveRequest}
                apiMode={apiMode}
                registerSendAction={registerSendAction}
                onRequestObjectChange={onRequestObjectChange}
                customizations={customizations}
            />;
        }
        case ALIAS_SYS_EXAMPLE_TYPE_DELETE : {
            return <DeleteRequest
                key={requestObject[ID]}
                requestObject={requestObject}
                aliasesMap={aliasesMap}
                exampleSetId={exampleSetId}
                onSave={onSaveRequest}
                apiMode={apiMode}
                registerSendAction={registerSendAction}
                onRequestObjectChange={onRequestObjectChange}
                customizations={customizations}
            />;
        }
        case ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA : {
            return <ResetRequest
                key={requestObject[ID]}
                resetRequestObject={requestObject}
                aliasesMap={aliasesMap}
                exampleSetId={exampleSetId}
                onSave={onSaveRequest}
                apiMode={apiMode}
                registerSendAction={registerSendAction}
                onRequestObjectChange={onRequestObjectChange}
                customizations={customizations}
            />;
        }
        case ALIAS_SYS_EXAMPLE_TYPE_BATCH : {
            return <BatchRequest
                key={requestObject[ID]}
                requestObject={requestObject}
                aliasesMap={aliasesMap}
                exampleSetId={exampleSetId}
                onSave={onSaveRequest}
                apiMode={apiMode}
                registerSendAction={registerSendAction}
                onRequestObjectChange={onRequestObjectChange}
                customizations={customizations}
            />;
        }
        default : {
            return undefined;
        }
    }
}

const SCENARIO_DETAILS = "ScenarioDetails"
class ScenarioDetails extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        let {requestObjects} = props

        //show loading only if there are requests to render as rendering requests take time
        // other wise it is fast
        let loading = requestObjects && requestObjects.length > 0;

        this.state = {
            runAllResponseRegister: {},
            runAllRegister: {},
            saveAllRegister: {},
            expandedIds: [],
            refsData : {
                requests : {}
            },
            loading: loading
        }
    }

    componentDidMount() {
        traceComponentDidMountStart(SCENARIO_DETAILS)
        let {loading} = this.state
        if(loading) {
            if(this.hasRequestObjects) {
                setTimeout(() => {
                    this.setState({loading: false})
                }, 500)
            } else {
                this.setState({loading: false})
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        traceComponentDidUpdateStart(SCENARIO_DETAILS)

    }

    onRequestObjectChange = (requestObject) => {
    }

    registerSendAction = () => {
        return {
            onSave: (id, saveAction) => {
                let {saveAllRegister} = this.state;
                saveAllRegister[id] = saveAction
            },
            onSend: (id, sendAction) => {
                let {runAllRegister} = this.state;
                runAllRegister[id] = sendAction
            },
            onResponse: (id, status) => {
                let {runAllResponseRegister} = this.state;
                runAllResponseRegister[id] = status;
                this.setState({runAllResponseRegister})
            }
        }
    }

    getConnectorMenuItem = (id, type, index, disabled) => {
        let {onAddRequest} = this.props
        return {
            label: EXAMPLE_TYPE_TO_MENU_LABEL[type],
            onClick: () => {
                onAddRequest(id, type, (index + 1), false)
            },
            disabled: disabled
        };
    }

    getConnector = (id, index, hasPrevious, hasNext) => {
        let {theme} = this.props;
        let globals = this.context;
        let allConfigurations = globals.allConfigurations;
        let containers = getContainerData(allConfigurations);
        let isReadOnly = isReadOnlyAPI(containers);
        let lineStyle = {height:'8px', width : '50%', borderRight: '1px solid #9E9E9E'}

        return <div style={{padding : '0px', textAlign: 'center', marginTop : (hasPrevious === false ? '-24px' : '0px')}}>
            {hasPrevious && <div style={lineStyle} />}
                 <div>
                     <MenuListComposition
                         menuDatatest={'addRequestMiddleMenu-'+index}
                         items={[
                             this.getConnectorMenuItem(id, ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA, index, false),
                             this.getConnectorMenuItem(id, ALIAS_SYS_EXAMPLE_TYPE_CREATE, index, isReadOnly),
                             this.getConnectorMenuItem(id, ALIAS_SYS_EXAMPLE_TYPE_SEARCH, index, false),
                             this.getConnectorMenuItem(id, ALIAS_SYS_EXAMPLE_TYPE_UPDATE, index, isReadOnly),
                             this.getConnectorMenuItem(id, ALIAS_SYS_EXAMPLE_TYPE_DELETE, index, isReadOnly),
                             this.getConnectorMenuItem(id, ALIAS_SYS_EXAMPLE_TYPE_BATCH, index, isReadOnly),
                         ]}
                         buttonIcon={
                             this.hasRequestObjects() ?
                                 <AddIcon
                                     datatest={'addRequestMiddleButton-'+index}
                                     style={{
                                         fontSize: '20px',
                                         color: theme.palette.grey.level2
                                     }}
                                 />
                                 : <><AddIcon
                                     datatest={'addRequestMiddleButton-'+index}
                                     style={{
                                         fontSize: '20px',
                                         borderRadius: '50%',
                                         backgroundColor: theme.palette.secondary.main,
                                         border: '1px solid rgba(158 158 158 .1)',
                                         padding: '0px',
                                         color: theme.palette.white.main,
                                         marginRight : '8px'
                                     }}
                                 /> <Typography component={'span'} variant={"caption"}>Add Request</Typography></>
                         }
                         buttonSize={this.hasRequestObjects() ? "medium" : "small"}
                         buttonStyle={ this.hasRequestObjects() && {
                             borderRadius: '50%',
                             backgroundColor: this.hasRequestObjects() ? theme.palette.white.main : theme.palette.secondary.main,
                             border: '1px solid rgba(158 158 158 .1)',
                             padding: '0px'
                         }}
                     />
                 </div>
            {hasNext && <div style={lineStyle}/>}
        </div>;
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        let {scenarioObject, onRequestsReorder} = this.props
        onRequestsReorder(scenarioObject[ID], result.source.index, result.destination.index)
    }

    toggleExpanded = (ev, expanded, id) => {
        let {expandedIds} = this.state
        if(expanded === true) {
            expandedIds.push(id)
            this.setState({expandedIds})
        } else {
            this.setState({expandedIds: expandedIds.filter(i => i !== id)})
        }
    }

    showDetails = () => {
        let {requestObjects, scenarioObject} = this.props
        return <>
            {this.getConnector(scenarioObject[ID], -1, false, this.hasRequestObjects())}
            {this.hasRequestObjects() && <>
                {
                    requestObjects.map((r, index) => {
                        let id = r[ID]
                        return this.getExpansionPanel(id, r, index);
                    })
                }
                <div style={{height: '128px'}}></div>
            </>
            }
        </>;
    }

    addRef = (id, ref) => {
        let {refsData} = this.state
        refsData.requests[id] = ref;
    }

    getExpansionPanel = (id ,  r, index) => {
        let {theme, aliasesMap, classes, requestObjects, scenarioObject, onSaveRequest, onDeleteRequest, renderRequestTitlePrefix} = this.props
        let {runAllResponseRegister, expandedIds, running} = this.state;
        let status = runAllResponseRegister[id]
        let isLast = index === (requestObjects.length - 1)
        let summaryComponentStyle = {padding: '8px', borderRight : '1px solid #EEEEEE'}
        let expanded = expandedIds.includes(id)
        return <div datatest={'panel-'+index}  key={id}>
            <Accordion
                expanded={expanded}
                onChange={(ev, expanded) => this.toggleExpanded(ev, expanded, id)}
                style={{
                    margin: '0px',
                    borderRadius: '4px'
                }}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon datatest={'expandButton'}/>}
                    aria-label="Expand"
                    aria-controls="additional-actions1-content"
                    id={"additional-actions1-header" + id}
                    IconButtonProps={{
                        size: 'small'
                    }}
                    style={{
                        borderBottom : expanded ? '1px solid #EEEEEE' : 'none',
                        minHeight: 'unset',
                        padding : '0px 8px 0px 8px',
                        margin : '0px'
                    }}
                >
                    <div style={summaryComponentStyle}>
                        <MUIIconButton
                            disableRipple={running}
                            datatest={'playButton'}
                            style={{
                                borderRadius: '50%',
                                backgroundColor: theme.palette.grey.main,
                                border: '1px solid rgba(158 158 158 .1)',
                                padding: '4px'
                            }}
                            onClick={(e) => {
                                running || this.runRequest(id);
                                e.stopPropagation();
                            }}
                        >
                            <PlayArrowIcon
                                style={{
                                    fontSize: '24px',
                                    color: running ?  theme.palette.grey.level2 : theme.palette.secondary.main                                }}
                            />
                        </MUIIconButton>
                    </div>
                    {
                        <div datatest={'panelMenuContainer'} style={summaryComponentStyle}>
                            <MenuListComposition
                                menuDatatest={'panelMenu-'+index}
                                items={[
                                    {
                                        label: 'Delete',
                                        onClick: () => onDeleteRequest(id)
                                    }
                                ]}
                            />
                        </div>
                    }
                    {centerVertically(renderRequestTitlePrefix(id), {textAlign : 'center', width : '48px' ,...summaryComponentStyle, paddingTop : '10px'})}
                    <div style={{paddingLeft: '8px'}}/>
                    {getDirtyIndicator(r)}
                    {
                        centerVertically(
                            <div ref={(ref) => this.addRef(id, ref)}><H3Title datatest={'requestTitle'} color={'primary'}>{r.title}</H3Title></div>
                        )
                    }
                    {
                        status && status.status === 'inprogress'
                            ? centerVertically(
                                    <LinearProgress datatest={'progressLine'} style={{width: '100%'}} color="secondary"/>,
                                    {minWidth : '40px', flexGrow: '1', padding: '0px 16px'}
                                )
                            : <div style={{flexGrow: '1'}}/>
                    }
                    {
                        status && status.status !== 'pending' && status.status !== 'inprogress' && centerVertically(
                            <ResponseStatusBar
                                variant={'caption'}
                                hasError={status.hasError}
                                style={{padding: '0px', marginLeft: '8px', marginRight: '8px'}}
                            />
                        )
                    }
                    {
                        status && status.status !== 'pending' && status.status !== 'inprogress' && centerVertically(
                            (status.hasError
                            ? <ErrorIcon datatest={'errorIcon'}  style={{fontSize: '24px', color: theme.palette.error.main}}></ErrorIcon>
                            : <CheckCircleIcon  datatest={'successIcon'}  style={{fontSize: '24px', color: theme.palette.success.main}}/>)
                        )

                    }

                </AccordionSummary>
                <AccordionDetails style={{padding: '0px'}}>
                    <div datatest={'requestDetails'} style={{
                        width: 'calc(100% - 16px)',
                        padding: '8px',
                    }}>{
                        showRequest(r, id, r.apiMode, aliasesMap, this.registerSendAction, onSaveRequest, this.onRequestObjectChange, {hideRun : true, hideSave: false, searchAndConnect : false})
                    }</div>
                </AccordionDetails>
            </Accordion>
            {!isLast && this.getConnector(scenarioObject[ID], index, true, true)}
            {isLast && this.getConnector(scenarioObject[ID], requestObjects.length - 1, true, false)}
        </div>;
    }

    hasRequestObjects = () => {
        let {requestObjects} = this.props
        return requestObjects && requestObjects.length > 0;
    }

    saveAll = (collectionDetails) => {
        let {onSaveScenario} = this.props
        let {saveAllRegister} = this.state
        onSaveScenario(collectionDetails)
        Object.keys(saveAllRegister).forEach(k => saveAllRegister[k]());
    }

    runRequest = (id) => {
        this.runAll([id])
    }

    runAllRequests = () => {
        let {requestObjects} = this.props
        let ids = requestObjects.map(r => r[ID])
        this.runAll(ids)
    }

    runAll = (ids) => {
        let {runAllRegister} = this.state;
        let fun = (id, action) => {
            let {runAllResponseRegister} = this.state;
            runAllResponseRegister[id] = {status: 'inprogress'};
            this.setState({runAllResponseRegister}, () => {
                    action().then(s => {
                        ids = ids.filter(id => id !== s[ID]);
                        if (ids && ids.length > 0) {
                            let {requests} = this.state.refsData
                            if(requests[id]) {
                                /*
                                requests[id].scrollIntoView({
                                    block: 'center',
                                    behavior: 'smooth'
                                })
                                */

                            }
                            fun(ids[0], runAllRegister[ids[0]]);
                        } else {
                            this.setState({running : false})
                        }
                        let {runAllResponseRegister} = this.state;
                        runAllResponseRegister[s[ID]] = s;
                        this.setState({runAllResponseRegister})
                    })
                }
            )
        }
        let {runAllResponseRegister} = this.state;
        ids.forEach(id => runAllResponseRegister[id] = {status: 'pending'})
        this.setState({running : true, runAllResponseRegister}, () => fun(ids[0], runAllRegister[ids[0]]))

    }

    getExpandCollapseAllButton = () => {
        let {expandedIds} = this.state
        let {requestObjects, theme} = this.props
        if(!requestObjects) {
            return;
        }
        let colorStyle = {color: theme.palette.grey.level3, paddingRight: '0px'}
        return <>
            <BlockActionButton
                theme={theme}
                title={'Expand All'}
                onClick={() => this.setState({expandedIds: requestObjects.map(r => r[ID])})}
                endIcon={<UnfoldMoreIcon style={colorStyle}/>}
                buttonProps={{
                    datatest: 'expandAllButton'
                }}
            />
            <BlockActionButton
                theme={theme}
                title={'Collapse All'}
                onClick={() => this.setState({expandedIds: []})}
                endIcon={<UnfoldLessIcon style={colorStyle}/>}
                buttonProps={{
                    datatest: 'collapseAllButton'
                }}
            />
        </>
    }

    render() {
        traceRenderStart(undefined, SCENARIO_DETAILS)

        let {theme, scenarioObject, readOnly, onSaveScenario} = this.props
        let {loading, running} = this.state
        if(loading) {
            return <ProcessingBackdrop marginLeft={true} loading={loading}/>
        }
        return <FieldContainer disableLeftRightPadding={true} style={{paddingTop : '0px', backgroundColor : theme.palette.grey.background}}>
            <div>
                <CollectionDetails
                    formTitle={'Scenario Details'}
                    onSave={this.saveAll}
                    saveTitle={'Save All'}
                    collectionDetails={scenarioObject}
                    readOnly={readOnly}
                    onRun={this.runAllRequests}
                    disableRunButton={this.hasRequestObjects() === false}
                    running={running}
                />

            </div>
            {readOnly ||
                <div datatest={'scenarioRequests'} style={{marginTop : theme.spacing(STYLE_GRID_ITEM_SPACING)}}>
                    <div datatest={'scenarioRequestsTitleBar'} style={{display: 'flex'}}>
                        <H2Title datatest={'scenarioRequestsTitle'}>Requests</H2Title>
                        <div style={{flexGrow: 1}}/>
                        {this.hasRequestObjects() === false || this.getExpandCollapseAllButton()}
                    </div>
                    {this.showDetails()}
                </div>
            }
        </FieldContainer>;
    }

}

ScenarioDetails.propTypes = {
    scenarioObject: PropTypes.object,
    readOnly: PropTypes.bool,
    requestObjects: PropTypes.array,
    onSaveScenario: PropTypes.func,
    onAddRequest: PropTypes.func,
    onDeleteRequest: PropTypes.func,
    onSaveRequest: PropTypes.func,
    onRequestsReorder: PropTypes.func,
    renderRequestTitlePrefix: PropTypes.func,
    aliasesMap: PropTypes.object
};

export default withStyles(styles, {withTheme: true})(ScenarioDetails);

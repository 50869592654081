import React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, Paper, withStyles} from '@material-ui/core';
import {LABEL_SELECTED_FILES, STYLE_GRID_ITEM_SPACING} from "../Constants";
import Instruction from "./Instruction";
import H1Title from "./H1Title";
import H3Title from "./H3Title";
import ErrorMessage from "./ErrorMessage";
import Dropzone from 'react-dropzone'
import IconButton from "./IconButton";
import NoteIcon from '@material-ui/icons/NoteOutlined';
import {sort} from "./util";
import {GreyDeleteIcon} from "../components/GreyStyleIcon";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

function FileUpload(props) {
  let {theme, multiple, accept, paperStyle, filesSectionWarnings, filesSectionErrors, fileUploadMessage, fileUploadMessageError, warningMessage, dragNDropBoxTitle, files, onDelete, onFilesSelect} = props
  let computedPaperStyle = paperStyle || {};
  return (<Paper elevation={0} style={{padding: theme.spacing(STYLE_GRID_ITEM_SPACING), ...computedPaperStyle}}>
        <Grid container spacing={STYLE_GRID_ITEM_SPACING}>
          <Grid item xs={6}>
            <React.Fragment>
              <Instruction color={fileUploadMessageError ? 'error': undefined} text={fileUploadMessage}/>
              <br/>
              <Instruction color={"error"} text={warningMessage}/>
              <Dropzone multiple={multiple} accept={accept} onDrop={selectedFiles => {
                onFilesSelect(selectedFiles)
              }}>
                {({getRootProps, getInputProps}) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div datatest={'dropzone'} style={{
                          border: "2px dashed",
                          borderColor : theme.palette.border.main,
                          padding: '16px',
                          textAlign: 'center'
                        }}>
                          <H1Title title={dragNDropBoxTitle}/>
                          <div style={{marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}>or</div>
                          <Button variant={'outlined'} color={"secondary"}>BROWSE FILES</Button>
                        </div>
                      </div>
                    </section>
                )}
              </Dropzone>
              <br/>
            </React.Fragment>
          </Grid>
          <Grid item xs={6}>

            {
              files && files.length > 0 &&
              <div>
                <H3Title color={files.find(f => f.errors && f.errors.length > 0) || fileUploadMessageError ? 'secondary' : undefined} style={{marginBottom: theme.spacing(2)}} title={LABEL_SELECTED_FILES}/>
                { filesSectionWarnings &&
                  <div>
                    {filesSectionWarnings.map((w, i) => <ErrorMessage key={i} error={w} color={theme.palette.warning.light}/>)}
                  </div>
                }
                { filesSectionErrors &&
                <div>
                  {filesSectionErrors.map((w, i) => <ErrorMessage key={i} error={w}/>)}
                </div>
                }
                {
                  sort(files.map(f => ({...f, name: f.f.name})), 'name').map((file, i) => {
                    let errors = file.errors
                    let f = file.f
                    let hasError = errors && errors.length > 0  ? true : false;
                    let hasSuccess = file.success && file.success.length > 0  ? true : false;
                    let paperStyle = {
                      backgroundColor: theme.palette.border.main,
                      borderColor: theme.palette.error.main,
                      borderStyle: hasError ? 'solid' : 'none',
                      borderSize: hasError ? '2px' : '0px',
                      padding: theme.spacing(1)
                    }
                    return <Grid key={f.name+i} alignItems={"center"} container={true} spacing={1} >
                      <Grid item xs={12}>
                        <Paper elevation={0} style={paperStyle}>
                          <Grid container spacing={0}>
                            <Grid key={'nfile-'+i} item xs={10}>
                              <NoteIcon  size={"small"}  style={{verticalAlign:'middle', marginRight: theme.spacing(2)}}/>
                              <H3Title datatest={'fileTitle-'+f.name} style={{display: 'inline'}} title={f.name}/>
                            </Grid>
                            <Grid key={'dfile-'+i} item xs={2} style={{textAlign: 'right'}}>
                              {
                                hasSuccess
                                    ? <CheckCircleIcon datatest={'successIcon-'+f.name} style={{fontSize: '24px', color: theme.palette.success.main}}/>
                                    : <IconButton datatest={'deleteButton-'+f.name} onClick={() => {onDelete(file, i)}} size={"small"} >
                                      <GreyDeleteIcon  fontSize="small"/>
                                    </IconButton>
                              }
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>
                      <Grid key={'msgfile-'+i} item xs={12} style={{paddingTop: 0, paddingBottom: theme.spacing(1)}}>{
                        hasError && errors.map(e => <div datatest={'error-'+f.name}><ErrorMessage error={e}/></div>)
                      }</Grid>
                    </Grid>;
                  })
                }
              </div>
            }
          </Grid>
          <Grid item xs={12}>{
            props.children
          }</Grid>
        </Grid>
      </Paper>
  );
}

FileUpload.propTypes = {
  paperStyle: PropTypes.any,
  fileUploadMessage: PropTypes.string,
  fileUploadMessageError: PropTypes.bool,
  warningMessage: PropTypes.string,
  filesSectionWarnings: PropTypes.array,
  filesSectionErrors: PropTypes.array,
  dragNDropBoxTitle: PropTypes.string,
  multiple: PropTypes.bool,
  accept: PropTypes.array,
  files: PropTypes.array,
  onDelete: PropTypes.func,
  onFilesSelect: PropTypes.func,
};

export default withStyles({}, {withTheme: true})(FileUpload);

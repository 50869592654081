import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import './APIPlayground.css';
import RequestResponse from "../../components/RequestResponse";
import {traceRequestRenderStart} from "../../components/Trace";


class UpdateRequest extends Component {
    constructor(props) {
        super(props);
        let {patchRequestObject} = this.props
        this.state = {
            patchRequestObject : patchRequestObject
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
    }

    savePatchRequest = () => {
        let {patchRequestObject} = this.state
        let {onSave} = this.props
        onSave(patchRequestObject)
    }

    setDirty = () => {
        let {patchRequestObject} = this.props
        if(!patchRequestObject.dirty) {
            patchRequestObject.dirty = true
            this.setState({})
        }
    }

    onModeChange = (obj) => {
        let {onRequestObjectChange} = this.props
        this.setDirty();
        //update state to ensure that components are re-rendered for new mode
        this.setState({}, () => (onRequestObjectChange(obj)))
    }

    getRequestResponse = () => {
        let {customizations, registerSendAction, onRequestObjectChange} = this.props
        let {patchRequestObject} = this.state
        return <RequestResponse
            reqTabValue={0}
            requestObject={patchRequestObject}
            onRequestObjectChange={(obj) => {this.setDirty(); onRequestObjectChange(obj)}}
            onRequestSave={this.savePatchRequest}
            onModeChange={this.onModeChange}
            registerSendAction={registerSendAction}
            customizations={customizations}
        />;
    }

    render() {
        let {patchRequestObject} = this.props
        traceRequestRenderStart(patchRequestObject, 'UpdateRequest')

        return (<>
                {this.getRequestResponse()}
        </>);
    }

}

UpdateRequest.propTypes = {
    onSave: PropTypes.func,
    registerSendAction: PropTypes.func,
    exampleSetId: PropTypes.string,
    apiMode: PropTypes.object,
    aliasesMap: PropTypes.object,
    patchRequestObject: PropTypes.object,
    onRequestObjectChange: PropTypes.func,
    customizations: PropTypes.object,

};

export default withStyles(styles, {withTheme: true})(UpdateRequest);

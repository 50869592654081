import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {centerVertically, excludeHidden, getPropertyName, isEmptyArray, toArray} from "../../components/util";
import SearchFilter, {getDefaultProperties, SEARCH_COMPONENT_PROPERTY_MODE} from "../apiplayground/SearchFilter";
import {traceSearchBuilderUpdate} from "../../components/Trace";
import {ALIAS_SH_PATH, ID, TYPE} from "../../Constants";
import {cloneDeep} from "lodash";
import uuid4 from "uuid/v4";
import {renderProperty} from "./AddDataPropertyValueDialog";
import LabelOutlinedIcon from "@material-ui/icons/LabelOutlined";
import {Typography} from "@material-ui/core";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD_AND_CONDITION,
    UI_LABELS_APPLY,
    UI_LABELS_CANCEL,
    UI_LABELS_FILTER
} from "./UILabel";

const styles = {
    dialogPaper: {
    },
};

class AddNewFilter extends Component {
    constructor(props) {
        super(props);
        let {aliasesMap, search, aliasesToIRIMap, configurations, typesToExclude, browseLanguage, shapeProperty} = props;
        const allPropertyOptions = getDefaultProperties(configurations);
        let incomingPropertyIri = shapeProperty[ALIAS_SH_PATH];
        const isIdOrType = [ID, TYPE].includes(incomingPropertyIri);
        let found = allPropertyOptions.find(pr => {
            if(isIdOrType) {
                let propertyIri = pr.value;
                return propertyIri === incomingPropertyIri;
            } else {
                let propertyIri = pr.value[ALIAS_SH_PATH];
                return propertyIri === incomingPropertyIri;
            }
        });
        let filters =  excludeHidden(toArray(search.filters)).filter(f => {
            if(isIdOrType) {
                const path = f.property?.value;
                return path && path === found?.value;
            } else {
                const path = f.property?.value?.[ALIAS_SH_PATH];
                return path && path === found?.value[ALIAS_SH_PATH];
            }
        })
        if(isEmptyArray(filters)) {
            let filter = {id: uuid4()};
            filter.property = found;
            filters.push(filter);
        }
        this.state = {
            searchProperty : found,
            searchToBuild : {
                filters : cloneDeep(filters)
            }
        }
    }

    applyFilter = () => {
        let {onAdd, search, shapeProperty} = this.props;
        let {searchProperty, searchToBuild} = this.state;

        let {filters} = search;
        let incomingPropertyIri = shapeProperty[ALIAS_SH_PATH];
        const isIdOrType = [ID, TYPE].includes(incomingPropertyIri);

        let currentPropPath = isIdOrType ? searchProperty.value : searchProperty.value[ALIAS_SH_PATH];
        let filtersFromBuilder = searchToBuild.filters;

        let mergedFilters = filters.filter(f => {
            const path = isIdOrType ? f.property?.value : f.property?.value?.[ALIAS_SH_PATH];
            if(path === currentPropPath) {
                return false;
            }
            return true;
        })
        filtersFromBuilder.forEach(fb => {
            mergedFilters.push(fb);
        })
        search.filters = [...toArray(search.filters).filter(f => f.hidden), ...mergedFilters];
        onAdd(mergedFilters);
    }

    render() {
        let {onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage, shapeProperty} = this.props;
        let {searchProperty, searchToBuild} = this.state;

        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
                    open={true}
                    datatest={'addNewFilterDialog'}
                    classes={{ paper: classes.dialogPaper }}
                    onKeyDown={(ev) => {
                        if(ev.ctrlKey && ev.key === 'Enter') {
                            this.applyFilter();
                        } else if (ev.key === 'Escape') {
                            onClose();
                        } else if(ev.key === 'Enter') {
                            //Only stop propagation but do not preventDefault as we want to add new line in text field
                            ev.stopPropagation();
                        }
                    }}

                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <div style={{display: 'flex'}}>
                            {centerVertically(<H2Title title={getUiLabelTranslation(settings, UI_LABELS_FILTER, browseLanguage, UI_LABELS_FILTER) }/>)}
                            {
                                centerVertically(
                                    renderProperty(<LabelOutlinedIcon style={{marginRight: '4px'}} color={'secondary'}/>, getPropertyName(aliasesToIRIMap, shapeProperty[ALIAS_SH_PATH], ontology, browseLanguage)),
                                    {margin: '0px 24px', flexGrow : '1'}
                                )
                            }
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        {
                            searchProperty
                                ? <SearchFilter
                                        customizations={{
                                            mode: SEARCH_COMPONENT_PROPERTY_MODE,
                                            hideTextSearch: true,
                                            hideAddFilter: false,
                                            hideRemoveBlockButton: false,
                                            noBlockBackgroundColor: false,
                                            title: ' ',
                                            property: searchProperty,
                                            translateAllLabels : true,
                                            addFilterButtonTitle : getUiLabelTranslation(settings, UI_LABELS_ADD_AND_CONDITION, browseLanguage, UI_LABELS_ADD_AND_CONDITION),
                                            addFilterButtonVariant : 'outlined'
                                        }}
                                        configurations={configurations}
                                        aliasesMap={aliasesMap}
                                        aliasesToIRIMap={aliasesToIRIMap}
                                        ontology={ontology}
                                        browseLanguage={browseLanguage}
                                        search={searchToBuild}
                                        onChange={(search) => {
                                            traceSearchBuilderUpdate(() => {
                                                console.log(search)
                                            }, 'AddNewFilter');
                                            this.setState({});
                                        }}
                                        settings={settings}
                                    />
                                : <Typography component={'p'}>Filter can't be added as property '{shapeProperty[ALIAS_SH_PATH]}' is not defined in the configuration.</Typography>
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow :'1'}}></div>
                        {
                            searchProperty &&
                                <Button
                                    datatest={'applyFilterButton'}
                                    variant={"contained"}
                                    color="secondary"
                                    onClick={this.applyFilter}
                                >{getUiLabelTranslation(settings, UI_LABELS_APPLY, browseLanguage, UI_LABELS_APPLY)}</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>;
    }
}

AddNewFilter.propTypes = {
    settings: PropTypes.any,
    search: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    shapeProperty: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onAdd: PropTypes.any
};

export default withStyles(styles, {withTheme: true})(AddNewFilter);


import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {getMetric} from "../../service/graph-api";
import {styles} from "../../components/styles";
import AllPartsLayout from "../AllPartsLayout";
import {Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import GridContainer from "../../components/GridContainer";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import {isAccountSysadmin, isSuperadmin} from "./Profile";
import Header from "../../components/header/Header";
import MainAppBar from "../../components/header/MainAppBar";
import {ROUTE_ACCOUNT_MONITORING, ROUTE_MANAGEMENT_MONITORING} from "../../Constants";
import history from "../../history";
import {Switch, TextField as OtherTextField, TextField, Typography} from "@material-ui/core";
import {getRouteWithInstanceAndDataset, toArray} from "../../components/util";
import queryString from "query-string";
import InputAdornment from "@material-ui/core/InputAdornment";
import LinearProgress from '@material-ui/core/LinearProgress';
import Autocomplete from "@material-ui/lab/Autocomplete";
import H2Title from "../../components/H2Title";
import {getLeftComponent as getLeftComponentManagement} from "../management/AdminHome";
import {getLeftComponent as getLeftComponentAccount} from "../account/Account";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
    },
}));

function LinearDeterminate({key, maxSeconds, onEnd, disabled}) {
    const classes = useStyles();
    const [completed, setCompleted] = React.useState(0);
    React.useEffect(() => {
        function progress() {
            if(!maxSeconds || disabled) {
                return;
            }
            let delta = ( 1 / maxSeconds ) * 100;

            setCompleted((oldCompleted) => {
                let newValue = oldCompleted + delta;
                if (newValue > 100) {
                    onEnd && onEnd();
                    return 0;
                }

                return Math.min(newValue, 100);
            });
        }

        const timer = setInterval(progress, 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div key={key} className={classes.root}>
            <LinearProgress variant="determinate" value={completed} color="secondary" />
        </div>
    );
}

export function renderChart(theme, title, chartRenderer) {
    return <div style={{padding : '8px', backgroundColor: theme.palette.white.main, borderRadius: '4px', paddingBottom : "62px"}}>
        <div datatest={'configurationStatChart'} className={"chart-container"} style={{width: '100%', height: '308px'}}>
            <H2Title style={{marginTop : "24px", marginBottom : "8px"}} title={title}/>
            <ResponsiveContainer width={'100%'} height="100%">
                {chartRenderer && chartRenderer()}
            </ResponsiveContainer>
        </div>
    </div>;
}

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const {
        fill, x, y, width, height,
    } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
        return (
            <div className="custom-tooltip">
                <div className="label">{`${label}`}</div>
                <div className="label">{`${payload[0].value}`}</div>
                {
                    payload[0].payload && payload[0].payload.serverId &&
                    <div className="label">{payload[0].payload.serverId}</div>
                }
            </div>
        );
    }

    return null;
};

const toPercent = (decimal) => `${decimal}%`;
const toMB = (decimal) => `${decimal}MB`;

const VIEW_CPU = "CPU";
const VIEW_TRAFFIC = "TRAFFIC";
const VIEW_MEMORY = "MEMORY";


const CPU_AND_THREADS = 'CPU & Threads';
const JVM_AND_GC = 'JVM Memory & GC';
const API_REQUESTS = 'API Requests';

const MONITORING_TYPE_OPTIONS = [
    {id: VIEW_TRAFFIC, label: API_REQUESTS},
    {id: VIEW_CPU, label: CPU_AND_THREADS},
    {id: VIEW_MEMORY, label: JVM_AND_GC},
]
class Monitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            view: VIEW_CPU,
            loading: false,
            minimized: true,
            autoRefresh : true,
            refreshIntervalInSeconds :  3,
            samplesToKeep : 60,
            data:[],
            cpuUsage: [],
            tomcatThreadsCurrent : [],
            httpServerRequests: [],
            httpServerRequestsClientError: [],
            jvmGCPauseTotalTime : [],
            serverIds : [],
            serverIdsSelected : [],
            monitoringType : MONITORING_TYPE_OPTIONS[1]
        }
    }

    componentDidMount() {
        this.syncData();
    }

    componentWillUnmount() {
    }

    syncData = () => {
        if((isSuperadmin() || isAccountSysadmin()) && this.state.autoRefresh) {
            let params = this.getParams(this.props);
            switch (params.view) {
                case VIEW_TRAFFIC:
                    this.viewHttpServerRequestsSuccess('outcome:SUCCESS', 'httpServerRequests');
                    this.viewHttpServerRequestsSuccess('outcome:CLIENT_ERROR', 'httpServerRequestsClientError');
                    return;
                case VIEW_MEMORY:
                    getMetric('jvm.memory.max', 'area:heap').then(data => {
                        let newValue = data.measurements.map(o => o.value)[0];
                        this.setState({jvmMemoryMax : this.toMBOrGBValue(newValue)});
                    });
                    getMetric('jvm.memory.max', 'area:nonheap').then(data => {
                        let newValue = data.measurements.map(o => o.value)[0];
                        this.setState({jvmMemoryMaxNonHeap : this.toMBOrGBValue(newValue)});
                    });
                    this.viewJvmMemoryUsed();
                    this.viewJvmGCPauseTotalTime();
                    this.viewJvmGCPauseCount();
                    this.viewJvmMemoryUsedNonHeap();
                    return;
                default:
                    this.viewCPUUsage();
                    this.viewTomcatThreadsCurrent();
            }
        }
    }

    viewMetric = (metricKey, metricTag, stateDataKey, valueFormat) => {
        getMetric(metricKey, metricTag).then(data => {
            let currentData = this.state[stateDataKey] || [];
            let that = this;
            let newData = []
            let start = 0;
            let maxSamples = this.state.samplesToKeep || 60;
            if(currentData.length >= maxSamples) {
                // If samples to keep value is reduced then adjust
                start = (currentData.length - maxSamples) + 1;
            }
            for (let i = start; i < currentData.length; i++) {
                newData.push(currentData[i])
            }
            let serverIdsNew = [];

            if(data && data.measurements) {
                let {serverIds} = this.state;
                let newValue = data.measurements
                    .map(o => ({time : this.getTime(), serverId : data.serverId, ...valueFormat(o)}))
                    .filter(o => o.value !== undefined)[0];
                newData.push(newValue)
                serverIdsNew = new Set([...serverIds, data.serverId])
            }
            this.setState({[stateDataKey] : newData,  serverIds: [...serverIdsNew]})
        });
    }


    viewCPUUsage = () => {
        this.viewMetric('system.cpu.usage', undefined, 'cpuUsage', (measurement) =>  ({value : measurement.value * 100}))
    }

    viewTomcatThreadsCurrent = () => {
        this.viewMetric('tomcat.threads.current', undefined, 'tomcatThreadsCurrent', (measurement) =>  ({value : measurement.value}))
    }

    viewJvmMemoryUsed = () => {
        this.viewMetric('jvm.memory.used', 'area:heap', 'jvmMemoryUsed', (measurement) =>  ({value : this.toMBOrGBValue(measurement.value)}))
    }

    viewJvmGCPauseTotalTime = () => {
        this.viewMetric('jvm.gc.pause', undefined, 'jvmGCPauseTotalTime', this.deltaValueFormat('TOTAL_TIME', 'jvmGCPauseTotalTime', 1000))
    }

    viewJvmGCPauseCount = () => {
        this.viewMetric('jvm.gc.pause', undefined, 'jvmGCPauseCount', this.deltaValueFormat('COUNT', 'jvmGCPauseCount'))
    }

    viewJvmMemoryUsedNonHeap = () => {
        this.viewMetric('jvm.memory.used', 'area:nonheap', 'jvmMemoryUsedNonHeap', (measurement) =>  ({value : this.toMBOrGBValue(measurement.value)}))
    }

    viewHttpServerRequestsSuccess = (tag, stateKey) => {
        this.viewMetric('http.server.requests', tag,  stateKey, this.deltaValueFormat('COUNT', stateKey))
    }

    deltaValueFormat = (field, stateKey, scale = 1) => (measurement) =>  {
        if(measurement.statistic !== field) {
            return {};
        }
        let httpServerRequests = this.getData(stateKey);
        let last = httpServerRequests && httpServerRequests.length > 0 && httpServerRequests[httpServerRequests.length - 1];
        let value = last && last.count ? Math.abs(measurement.value - last.count) : 0;
        return {
            value : value * scale,
            count : measurement.value
        };
    };


    pad = (value) => {
        if(!value || value <= 9) {
            return '0'+value;
        } else {
            return value;
        }
    }

    getTime = () => {
        let date = new Date();
        return `${this.pad(date.getHours())}:${this.pad(date.getMinutes())}:${this.pad(date.getSeconds())}`;
    }


    getCPUUsageChart = () => {
        const {theme} = this.props;
        let data = this.getData('cpuUsage') ;

        return renderChart(theme,'CPU', () => {
            return <AreaChart
                    stackOffset="expand"
                    data={data}
                    margin={{
                        top: 10, right: 30, left: 0, bottom: 0,
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3"/>
                    <YAxis dateKey={'value'} tickFormatter={toPercent}/>
                    <XAxis  dataKey="time"/>
                    <Tooltip content={<CustomTooltip/>}/>
                    <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8"/>
                </AreaChart>
        });
    }

    getTomcatThreadsCurrent = () => {
        const {theme} = this.props;
        let data = this.getData('tomcatThreadsCurrent') ;
        return renderChart(theme,'Threads', () => {
            return <AreaChart
                stackOffset="expand"
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <YAxis dateKey={'value'} domain={[0, 210]}/>
                <XAxis  dataKey="time"/>
                <Tooltip content={<CustomTooltip/>}/>
                <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8"/>
            </AreaChart>
        });
    }

    toMBOrGBValue = (value) => {
        let {jvmMemoryMax} = this.state;
        let newValue = value / (1024 * 1024);
        return Math.round((newValue + Number.EPSILON) * 100) / 100;
    }

    getData = (stateKey) => {
        const {serverIdsSelected} = this.state;
        let unfilteredData = this.state[stateKey];
        let serverIdsSelectedLabels = serverIdsSelected.map(v => v.label)
        let data = serverIdsSelected && serverIdsSelected.length > 0
            ? this.state[stateKey].filter(o => o.serverId && serverIdsSelectedLabels.includes(o.serverId))
            : unfilteredData ;
        return data;
    }

    getChartTitle = (label) => {
        return <H2Title style={{marginTop : "24px", marginBottom : "8px"}} title={label}/>;
    }

    getMemoryChart = (label, stateKey, maxStateKey) => {
        const {theme} = this.props;
        const max = this.state[maxStateKey];
        if(!max) {
            return <></>;
        }
        let data = this.getData(stateKey) ;
        return renderChart(theme, label, () => {
            return <AreaChart
                stackOffset="expand"
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <YAxis dateKey={'value'} domain={[0, max]} />
                <XAxis  dataKey="time"/>
                <Tooltip content={<CustomTooltip/>}/>
                <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8"/>
            </AreaChart>
        });
    }

    getHttpServerRequests = (label, stateKey) => {
        const {theme} = this.props;
        let data = this.getData(stateKey) ;
        return renderChart(theme, label, () => {
            return <LineChart
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <YAxis dateKey={'value'}/>
                <XAxis  dataKey="time"/>
                <Tooltip content={<CustomTooltip/>}/>
                <Line type="monotone" dataKey="value" stroke="#8884d8" activeDot={{ r: 8 }} />
            </LineChart>;
        });
    }

    getParams = (props) => {
        let {location} = props;
        let params = queryString.parse(this.props.location.search)
        return params;
    }



    renderCharts = () => {
        let params = this.getParams(this.props);

        switch (params.view) {
            case VIEW_MEMORY:
                return <>
                    <Grid item xs={12}>{
                        this.getMemoryChart('JVM Heap Memory in MB', 'jvmMemoryUsed', 'jvmMemoryMax')
                    }</Grid>
                    <Grid item xs={12}>{
                        this.getHttpServerRequests('JVM GC Pause Time in Millis', 'jvmGCPauseTotalTime')
                    }</Grid>
                    <Grid item xs={12}>{
                        this.getHttpServerRequests('JVM GC Pause Count', 'jvmGCPauseCount')
                    }</Grid>
                    <Grid item xs={12}>{
                        this.getMemoryChart('JVM Non Heap Memory in MB', 'jvmMemoryUsedNonHeap', 'jvmMemoryMaxNonHeap')
                    }</Grid>
                </>;
            case VIEW_TRAFFIC:
                return <>
                    <Grid item xs={12}>{
                        this.getHttpServerRequests('Http Requests Success', 'httpServerRequests')
                    }</Grid>
                    <Grid item xs={12}>{
                        this.getHttpServerRequests('Http Requests Client Error', 'httpServerRequestsClientError')
                    }</Grid>
                </>
            case VIEW_CPU:
            default:
                return <>
                    <Grid item xs={12}>{
                        this.getCPUUsageChart()
                    }</Grid>
                    <Grid item xs={12}>{
                        this.getTomcatThreadsCurrent()
                    }</Grid>
                </>;
        }
    }

    getMiddleComponentTitle = () => {
        let params = this.getParams(this.props);
        switch (params.view) {
            case VIEW_MEMORY:
                return JVM_AND_GC;
            case VIEW_TRAFFIC:
                return API_REQUESTS;
            default:
                return CPU_AND_THREADS;
        }
    }

    handleRadioChange = (event) => {
        this.setState({trialOption :  event.target.value});
    };

    getMiddleComponent = () => {
        let {serverIds, monitoringType, serverIdsSelected, loading, refreshIntervalInSeconds, autoRefresh, refreshIntervalInSecondsError, samplesToKeep, samplesToKeepError} = this.state;
        return loading
            ? <ProcessingBackdrop loading={true}/>
            : <GridContainer  style={{paddingLeft : '0px', paddingTop : '8px'}}>

                {
                    (isSuperadmin() || isAccountSysadmin()) && <>
                        <Grid spacing={1} justify={'flex-end'} container item xs={12}>
                            <Grid item xs={4}>
                                {
                                    <Autocomplete
                                        multiple={false}
                                        datatest={'monitoringTypeMetricId'}
                                        style={{width: '100%'}}
                                        value={monitoringType}
                                        options={MONITORING_TYPE_OPTIONS}
                                        getOptionLabel={option => option.label}
                                        getOptionDisabled={(option) => {
                                            let found = serverIdsSelected.find(s => s.label === option.label)
                                            return found ? true : false;
                                        }}
                                        onChange={(event, val) => {

                                            this.setState({monitoringType : val});
                                            this.switchView(val.id)();
                                        }}
                                        renderInput={params => (
                                            <OtherTextField
                                                label={'Monitor'}
                                                {...params}
                                                margin={"dense"}
                                                variant="outlined"
                                                fullWidth
                                            />
                                        )}
                                        size={"small"}
                                        disableClearable={true}
                                    />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Autocomplete
                                    multiple={true}
                                    datatest={'autocompleteServerId'}
                                    style={{width: '100%'}}
                                    value={serverIdsSelected}
                                    options={serverIds.map(s => ({label:s}))}
                                    getOptionLabel={option => option.label}
                                    getOptionDisabled={(option) => {
                                        let found = serverIdsSelected.find(s => s.label === option.label)
                                        return found ? true : false;
                                    }}
                                    onChange={(event, val) => {

                                        this.setState({serverIdsSelected : toArray(val)})
                                    }}
                                    renderInput={params => (
                                        <OtherTextField
                                            label={'Filter By Server'}
                                            {...params}
                                            margin={"dense"}
                                            variant="outlined"
                                            fullWidth
                                        />
                                    )}
                                    size={"small"}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={!autoRefresh}
                                    fullWidth={true}
                                    label={'Auto Refresh'}
                                    value={(refreshIntervalInSeconds)}
                                    error={refreshIntervalInSecondsError}
                                    helperText={refreshIntervalInSecondsError && 'Should be a positive number.'}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start">
                                            <Switch
                                                size={'small'}
                                                checked={autoRefresh}
                                                onClick={(ev) => this.setState({autoRefresh: !autoRefresh}, this.syncData)}
                                            />
                                        </InputAdornment>,
                                        endAdornment: <InputAdornment position="end">
                                            <Typography style={{marginRight : '4px'}} variant={"caption"}>Seconds</Typography>
                                        </InputAdornment>,
                                    }}
                                    onChange={(event) => {
                                        const {target: {name, value}} = event;
                                        let numberValue = Math.floor(Number(value));
                                        if(!numberValue) {
                                            this.setState({refreshIntervalInSecondsError: true})
                                        } else {
                                            this.setState({refreshIntervalInSecondsError: false})
                                        }
                                        this.setState({refreshIntervalInSeconds: value});
                                    }}
                                />
                                <LinearDeterminate disabled={!autoRefresh} key={refreshIntervalInSeconds + autoRefresh} onEnd={this.syncData} maxSeconds={refreshIntervalInSeconds}></LinearDeterminate>
                            </Grid>
                            <Grid item xs={2}>
                                <TextField
                                    disabled={!autoRefresh}
                                    value={samplesToKeep}
                                    fullWidth={true}
                                    label={'Samples To Keep'}
                                    error={samplesToKeepError}
                                    helperText={samplesToKeepError && 'Should be a positive number.'}
                                    onChange={(event) => {
                                        const {target: {name, value}} = event;
                                        let numberValue = Math.floor(Number(value));
                                        if(!numberValue) {
                                            this.setState({samplesToKeepError: true})
                                        } else {
                                            this.setState({samplesToKeepError: false})
                                        }
                                        this.setState({samplesToKeep: value});
                                    }}

                                />
                            </Grid>
                        </Grid>
                        {this.renderCharts()}
                    </>
                }
            </GridContainer>;
    }

    getActionsComponent() {
        return [];
    }

    switchView = (view) => () => {
        let route = this.props.isAccountProfile ? ROUTE_ACCOUNT_MONITORING : ROUTE_MANAGEMENT_MONITORING;
        history.push(`${getRouteWithInstanceAndDataset(route)}?view=${view}`);
    }

    render() {
        let {theme, isAccountProfile} = this.props;
        let {minimized} = this.state;
        let maxWidth = minimized ? '77px' : '300px';

        return (
            <AllPartsLayout
                apiError={this.state.apiError}
                apiErrorResponse={this.state.apiErrorResponse}
                onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                header={<Header  collapsible={false}  components={
                        [
                            <MainAppBar isAccountProfile={isAccountProfile} key={'main-app-bar'} title={'Monitoring'}/>,
                        ]
                    }/>
                }
                middleStyle={{paddingTop : '0px'}}
                middleComponent={this.getMiddleComponent()}
                leftStyle={{ backgroundColor : theme.palette.grey.background, width : maxWidth, border : 'none', padding: '0'}}
                leftComponentStyle={{padding: '0', height: '100%'}}
                leftComponentContainerStyle={{padding: '0', overflow: 'hidden'}}
                leftComponentScroll={{y: 'auto'}}
                leftComponent={isAccountProfile ? getLeftComponentAccount(this, theme, minimized) : getLeftComponentManagement(this, theme, minimized)}

                {...this.props}
            ></AllPartsLayout>
        );

    }

}

Monitoring.propTypes = {
    isAccountProfile: PropTypes.bool
};


export default withStyles(styles, {withTheme: true})(Monitoring);

import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '../components/IconButton';
import { withStyles } from '@material-ui/core/styles';
import {styles} from "../components/styles";
import PropTypes from "prop-types";

class CardActionButton extends React.Component {

    render() {
        let {icon, title, ...rest} = this.props
        return (
            <IconButton {...rest} size={"small"} onClick={this.props.onClick}>
                {
                    title
                        ? <Tooltip placement="bottom" title={title}>{icon}</Tooltip>
                        : icon
                }
            </IconButton>
        );
    }
}

CardActionButton.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    icon: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired
};


export default withStyles(styles, {withTheme: true})(CardActionButton)

import {executeFetch, getEndpointWithInstanceAndDataset} from "./graph-api";
import {getResourceId, isEmptyArray, toArray} from "../components/util";
import {getSPARQLEndpointPathFromAppConfig} from "../Configs";
import {TYPE_SKOS_XL_LABEL} from "../Constants";
import {IRI_SKOS_IN_SCHEME} from "../layouts/navigator/TaxonomyView";

export const SPARQL_BINDING_VALUE = 'value'

export function getBindingValue(obj, variable) {
    return obj?.[variable]?.[SPARQL_BINDING_VALUE];
}

export function escapeForSPARQL(value) {
    value = value.replaceAll("\\", "\\\\");
    value = value.replaceAll("\t", "\\t");
    value = value.replaceAll("\n", "\\n");
    value = value.replaceAll("\r", "\\r");
    value = value.replaceAll("\b", "\\b");
    value = value.replaceAll("\f", "\\f");
    value = value.replaceAll("\"", "\\\"");
    value = value.replaceAll("'", "\\'");
    return value;
}

export function adjustForObo(ontologyId) {
    if(ontologyId.startsWith('http://purl.obolibrary.org/obo/')) {
        let tokes = ontologyId.split('/');
        let last = tokes[tokes.length - 1];
        let name = (last.split('.'))[0];
        if(name.includes('-')) {
            name = name.split('-')[0]
        }
        return 'http://purl.obolibrary.org/obo/'+name.toUpperCase();
    }

    return ontologyId;
}
export async function filterExpansionPath(ontologyId, resources) {
    const resourcesArray = toArray(resources).map(r => getResourceId(r) || r);
    if(isEmptyArray(resourcesArray)) {
        return [];
    }
    const filterClause = resourcesArray.length > 0
        ? "FILTER(?subject IN (<"+resourcesArray.join(">, <")+">))" : '';

    const body = new URLSearchParams({ 'query' : `
        SELECT ?subject
        WHERE {
          ?subject a ?object.
          ${filterClause}
          OPTIONAL {?subject <http://www.w3.org/2000/01/rdf-schema#isDefinedBy> ?isDefinedBY}
          filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
        }
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());

}

export async function getOntologyIRIs() {
    let query = `
        SELECT DISTINCT ?subject
        WHERE {
          ?subject a <http://www.w3.org/2002/07/owl#Ontology>.
        }
    `;
    const body = new URLSearchParams({ 'query' : query});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }
    return Promise.reject(r.text());
}

export async function getTaxonomyXLLabels(conceptSchemeId) {
    let query = `
        SELECT DISTINCT ?subject
        WHERE {
          ?subject a <${TYPE_SKOS_XL_LABEL}>.
          ?subject <${IRI_SKOS_IN_SCHEME}> <${conceptSchemeId}>
        }
    `;
    const body = new URLSearchParams({ 'query' : query});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export async function searchTaxonomyConcepts(conceptScheme, text , langCode = 'en') {

    const query = `
    PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
    PREFIX skos-xl: <http://www.w3.org/2008/05/skos-xl#>
    PREFIX text: <http://jena.apache.org/text#>
    SELECT DISTINCT ?subject WHERE {
        {
            ?subject a skos:Concept .
            ?subject skos:inScheme <${conceptScheme}>.
            ?subject skos:prefLabel ?label.
            FILTER(contains(lcase(?label), lcase('${escapeForSPARQL(text)}')))
           # FILTER (langmatches(lang(?label), '${langCode}')))
        } UNION {
            ?subject a skos:Concept .
            ?subject skos:inScheme <${conceptScheme}>.
            ?subject skos-xl:prefLabel ?xlLabel.
            ?xlLabel skos-xl:literalForm ?label.
            FILTER(contains(lcase(?label), lcase('${escapeForSPARQL(text)}')))
           # FILTER (langmatches(lang(?label), 'en')))
        }
    } 
    LIMIT 5
    `;
    const body = new URLSearchParams({ 'query' : query});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());

}

export async function searchTaxonomyXLLabels(conceptScheme, text, langCode = 'en') {

    const query = `
    PREFIX skos: <http://www.w3.org/2004/02/skos/core#>
    PREFIX skos-xl: <http://www.w3.org/2008/05/skos-xl#>
    PREFIX text: <http://jena.apache.org/text#>
    SELECT DISTINCT ?subject WHERE {
        ?subject a skos-xl:Label .
        ?subject skos:inScheme <${conceptScheme}>.
        ?subject skos-xl:literalForm ?label.
        FILTER(contains(lcase(?label), lcase('${escapeForSPARQL(text)}')))
       # FILTER (langmatches(lang(?label), '${langCode}')))
    } 
    LIMIT 5
    `;
    const body = new URLSearchParams({ 'query' : query});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());

}

export async function getTopLevelOntologyClasses(ontologyId) {
    let query = `
        SELECT DISTINCT ?subject
        WHERE {
            {
              ?subject a ?object.
              filter(?object IN (<http://www.w3.org/2000/01/rdf-schema#Class> , <http://www.w3.org/2002/07/owl#Class>))
              OPTIONAL {?subject <http://www.w3.org/2000/01/rdf-schema#isDefinedBy> ?isDefinedBY}
              filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
              MINUS{
                ?subject <http://www.w3.org/2000/01/rdf-schema#subClassOf> ?l . 
                FILTER (!isblank(?l))
                FILTER (?subject != ?l)
                filter(strstarts(str(?l), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
              }
            }
            UNION 
            {
              ?subject a ?object.
              filter(?object IN (<http://www.w3.org/2000/01/rdf-schema#Class> , <http://www.w3.org/2002/07/owl#Class>))
              OPTIONAL {?subject <http://www.w3.org/2000/01/rdf-schema#isDefinedBy> ?isDefinedBY}
              filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
              ?subject <http://www.w3.org/2000/01/rdf-schema#subClassOf> <http://www.w3.org/2002/07/owl#Thing>  
            }
        }
    `;
    const body = new URLSearchParams({ 'query' : query});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export async function getSubClasses(classId) {
    const body = new URLSearchParams({ 'query' : `
        SELECT DISTINCT ?subject
        WHERE {
          ?subject <http://www.w3.org/2000/01/rdf-schema#subClassOf> <${classId}>.
          FILTER (!isblank(?subject))
        }
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export async function searchOntologyClasses(ontologyId, text, searchProperties) {
    const filterClause = toArray(searchProperties).length > 0
        ? "FILTER(?filterProps IN (<"+searchProperties.join(">, <")+">))" : '';

    const textSearchClause = text
        ? `FILTER regex(str(?searchObject), '${escapeForSPARQL(text)}')` : '';

    const body = new URLSearchParams({ 'query' : `
        SELECT ?subject
        WHERE {
          ?subject a ?object.
          filter(?object IN (<http://www.w3.org/2000/01/rdf-schema#Class> , <http://www.w3.org/2002/07/owl#Class>))
          FILTER (!isblank(?subject))
          OPTIONAL {?subject <http://www.w3.org/2000/01/rdf-schema#isDefinedBy> ?isDefinedBY}
          filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))

          ?subject ?filterProps ?searchObject.
          ${filterClause}
          ${textSearchClause}
        }
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export const TYPE_RDF_PROPERTY = "http://www.w3.org/1999/02/22-rdf-syntax-ns#Property";
export const TYPE_OWL_OBJECT_PROPERTY = "http://www.w3.org/2002/07/owl#ObjectProperty";
export const TYPE_OWL_DATATYPE_PROPERTY = "http://www.w3.org/2002/07/owl#DatatypeProperty";
export const TYPE_OWL_ANNOTATION_PROPERTY = "http://www.w3.org/2002/07/owl#AnnotationProperty";

async function properties(ontologyId, type) {
    const body = new URLSearchParams({
        'query': `
        SELECT DISTINCT ?subject
        WHERE {
          {  
              {SELECT ?subjectType WHERE { ?subjectType <http://www.w3.org/2000/01/rdf-schema#subClassOf>* <${type}>.  }}  
              ?subject a ?subjectType.
              FILTER (!isblank(?subject))
    
              filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
    
              MINUS{
                ?subject <http://www.w3.org/2000/01/rdf-schema#subPropertyOf> ?l . 
                            filter(strstarts(str(?l), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
    
                FILTER (!isblank(?l))
              }
          } 
          UNION 
          {
              {SELECT ?subjectType WHERE { ?subjectType <http://www.w3.org/2000/01/rdf-schema#subClassOf>* <${type}>.  }}  
              ?subject a ?subjectType.
              FILTER (!isblank(?subject))
              filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))
              ?subject <http://www.w3.org/2000/01/rdf-schema#subPropertyOf> ?subPropertyOf
              FILTER(?subPropertyOf IN (<http://www.w3.org/2002/07/owl#topObjectProperty> , <http://www.w3.org/2002/07/owl#topDataProperty>)) 

          }
        }
    `
    });
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export async function getTopLevelObjectProperties(ontologyId) {
    return await properties(ontologyId, TYPE_OWL_OBJECT_PROPERTY);
}

export async function getTopLevelDataProperties(ontologyId) {
    return await properties(ontologyId, TYPE_OWL_DATATYPE_PROPERTY);
}

export async function getTopLevelAnnotationProperties(ontologyId) {
    return await properties(ontologyId, TYPE_OWL_ANNOTATION_PROPERTY);
}

export async function getSubProperties(propertyId) {
    const body = new URLSearchParams({ 'query' : `
        SELECT DISTINCT ?subject
        WHERE {
          ?subject <http://www.w3.org/2000/01/rdf-schema#subPropertyOf> <${propertyId}>.
          FILTER (!isblank(?subject))
        }
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export async function searchOntologyProperties(ontologyId, text, searchProperties, type) {
    const filterClause = toArray(searchProperties).length > 0
        ? "FILTER(?filterProps IN (<"+searchProperties.join(">, <")+">))" : '';

    const textSearchClause = text
        ? `FILTER regex(str(?searchObject), '${escapeForSPARQL(text)}')` : '';

    const body = new URLSearchParams({ 'query' : `
        SELECT DISTINCT ?subject
        WHERE {
          {SELECT ?subjectType WHERE { ?subjectType <http://www.w3.org/2000/01/rdf-schema#subClassOf>* <${type}>.  }}  
          ?subject a ?subjectType.

          OPTIONAL {?subject <http://www.w3.org/2000/01/rdf-schema#isDefinedBy> ?isDefinedBY}
          filter(strstarts(str(?subject), '${adjustForObo(ontologyId)}') || (?isDefinedBY = <${ontologyId}> ))

          ?subject ?filterProps ?searchObject.
          ${filterClause}
          ${textSearchClause}
        }
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));
    }

    return Promise.reject(r.text());
}

export async function searchOntologyObjectProperties(ontologyId, text, searchProperties) {
    return await searchOntologyProperties(ontologyId, text, searchProperties, TYPE_OWL_OBJECT_PROPERTY);
}

export async function searchOntologyDataProperties(ontologyId, text, searchProperties) {
    return await searchOntologyProperties(ontologyId, text, searchProperties, TYPE_OWL_DATATYPE_PROPERTY);
}

export async function searchOntologyAnnotationProperties(ontologyId, text, searchProperties) {
    return await searchOntologyProperties(ontologyId, text, searchProperties, TYPE_OWL_ANNOTATION_PROPERTY);
}

export async function searchIncomingConnections(resourceId, searchProperties) {
    const filterClause = toArray(searchProperties).length > 0
        ? "FILTER(?property IN (<"+searchProperties.join(">, <")+">))" : '';

    const body = new URLSearchParams({ 'query' : `
        SELECT ?property (count(?property) as ?count)
        WHERE {
          ?subject ?property <${resourceId}>.
          ${filterClause}
        } GROUP BY ?property
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings;
    }

    return Promise.reject(r.text());
}

export async function searchIncomingConnectionResources(resourceId, searchProperties, limit= 20) {
    const filterClause = toArray(searchProperties).length > 0
        ? "FILTER(?property IN (<"+searchProperties.join(">, <")+">))" : '';

    const body = new URLSearchParams({ 'query' : `
        SELECT ?subject
        WHERE {
        
          ?subject ?property <${resourceId}>.
          ${filterClause}
          FILTER (!isblank(?subject))
        } LIMIT ${limit}
    `});
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type" : "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'subject'));;
    }

    return Promise.reject(r.text());
}

export async function getAllPropertiesUsedInData() {
    const body = new URLSearchParams({
        'query': `
        SELECT DISTINCT ?property
        WHERE {
          ?subject ?property ?object.
        }
        `
    });
    const requestOptions = {
        method: "POST",
        body,
        headers: {
            "Accept": "application/sparql-results+json",
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8"
        }
    }

    let r = await executeFetch(getEndpointWithInstanceAndDataset(), requestOptions, getSPARQLEndpointPathFromAppConfig());
    if (r.status === 200) {
        const j = await r.json()
        let bindings = j["results"]?.["bindings"];
        return bindings.map(b => getBindingValue(b, 'property'));
    }

    return Promise.reject(r.text());
}

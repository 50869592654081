import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {NavLink} from 'react-router-dom';
import {ROUTE_HELP, ROUTE_TESTS} from "../Constants";
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardOutlined';

const styles = theme => ({
    card : {
        width: 274
    },
    cardLink: {
        textDecoration: 'none'
    },
    media: {
        height: 200,
    },
    action : {
        '&:hover': {
            cursor: 'pointer',
            backgroundColor: '#B83D1C',
            color: '#FFFFFF'
        }
    }
    ,
    description: {
        height: '100px',
        borderTop: `2px solid ${theme.palette.secondary.main}`

    },
    cardTitle: {
        fontFamily:'Rajdhani, sans-serif',
        letterSpacing:'0',
        fontSize:'24px',
        textAlign: 'left'
    },
    avatar: {
        backgroundColor: 'light',
    },
});

class ApplicationCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {theme, target, classes, url, title, description, thumbnail, letter, backgroundColor } = this.props;

        let card = this.getCard(theme, classes, backgroundColor, letter, title, thumbnail, description);
        return (
            [ROUTE_TESTS, ROUTE_HELP].includes(url)
                ? <a href={url} target={target ? target : 'self'}>{card}</a>
                : <NavLink to={url} className={classes.cardLink}>{card}</NavLink>
        )
    }

    getCard(theme, classes, backgroundColor, letter, title, thumbnail, description) {
        let {cardMediaStyle} =  this.props;
        let cardMediaStyleToApply = {
            backgroundSize: '28%',
            backgroundColor:theme.palette.primary.main
        }
        if(cardMediaStyle) {
            cardMediaStyleToApply = {
                ...cardMediaStyleToApply,
                ...cardMediaStyle
            }
        }
        let {focus} = this.state
        let focusColor = '#FFFFFF'
        let style = focus  ? {
            cursor: 'pointer',
            backgroundColor: theme.palette.secondary.main,
            color: focusColor,
        } : {}
        return <Card datatest={'applicationCard-'+title} id={'cardId' + title.replace(' ', '')} className={classes.card} elevation={focus ? 12 : 1}>
            <CardActionArea style={style}
                            onFocus={(e) => {
                                this.setState({focus: true})
                            }}
                            onBlur={(e) => {
                                this.setState({focus: false})
                            }}
                            onMouseEnter={(e) => this.setState({focus: true})}
                            onMouseLeave={(e) => this.setState({focus: false})}
            >
            <CardMedia style={cardMediaStyleToApply} className={classes.media} image={thumbnail}  title={title}
                       />
            <CardContent className={classes.description}>
                <Typography style={{ color: focus ? focusColor : undefined }} gutterBottom variant="h2" color={ 'primary'} component="h2" className={classes.cardTitle}>{title}</Typography>
                <Typography style={{ color: focus ? focusColor : undefined }} variant="body2" color={"inherit"} component="p">{description}</Typography>
                <ArrowForwardIcon color={"secondary"} style={{
                    color: focus ? focusColor : undefined,
                    position: 'absolute',
                    bottom: theme.spacing(2),
                    right: theme.spacing(2)
                }}></ArrowForwardIcon>
            </CardContent>
            </CardActionArea>
        </Card>;
    }
}

export default withStyles(styles, {withTheme:true})(ApplicationCard);

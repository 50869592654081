import React from 'react';
import PropTypes from "prop-types";
import {Typography} from "@material-ui/core";

let Instruction = props => {
    let color = props.color ? props.color : 'initial';
    return (<Typography color={color} style={{textAlign : 'justify', ...props.style}}>{props.text}</Typography>);
}

Instruction.propTypes = {
    color: PropTypes.string,
    text: PropTypes.string,
    style: PropTypes.object
};

export default Instruction;

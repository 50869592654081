import React from "react";
import {TextField as OtherTextField, withStyles} from "@material-ui/core";
import {styles} from "../../components/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {createFilterOptionsFromLabel, getLiteralDatatypeSuggestions, toArray} from "../../components/util";
import {isArray, isEqual, isString, uniqWith} from "lodash";
import PropTypes from "prop-types";
import {getChipWithDelete} from "../../layouts/apiplayground/SearchFilter";

class DatatypeSearchSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inputValue : ''
        };
    }

    callOnChange = (val) => {
        let {onChange} = this.props;
        if(isArray(val)) {
            onChange(uniqWith(val, isEqual));
        } else {
            onChange(val);
        }
    }

    render() {
        let {classes, theme, value, multiple, label, labelRootClass, configurations} = this.props;
        let {inputValue} = this.state;
        let langValue = multiple === true ? (value ? value : []) : (value ? value : '')
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : labelRootClass ? classes[labelRootClass] : classes.smallWidthPrecedenceLabelRoot
                }
            }
            : {};

            return <Autocomplete
                datatest={'autocompleteDatatype'}
                freeSolo={true}
                multiple={multiple}
                value={langValue}
                options={getLiteralDatatypeSuggestions(configurations).map(o => ({value: o.value, label: o.label}))}
                getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === 'string') {
                        return option;
                    }
                    //if user entered option return full value
                    if(option.label && option.label.startsWith('Add "')) {
                        return option.value;
                    }
                    if (option.label) {
                        return option.label;
                    }
                    return option.label;
                }}
                renderOption={option => option.label}
                onChange={(event, newValIn, reason) => {
                    if (!reason || reason === 'select-option') {
                        let objects = toArray(newValIn).map(newVal => {
                            let newLanguageCodeObject;
                            if (isString(newVal)) {
                                newLanguageCodeObject = {
                                    value: newVal,
                                    label: newVal
                                }
                            } else {
                                newLanguageCodeObject = newVal;
                            }
                            return newLanguageCodeObject;
                        });
                        this.setState({inputValue: ''})
                        this.callOnChange(multiple ? objects : objects[0])
                    }
                }}
                onInputChange={(event, newVal, reason) => {
                    if (reason === 'input') {
                        if (newVal) {
                            let newLanguageCodeObject;
                            if (isString(newVal)) {
                                newLanguageCodeObject = {
                                    value: newVal,
                                    label: newVal
                                }
                            } else {
                                newLanguageCodeObject = newVal;
                            }
                            this.setState({inputValue: newLanguageCodeObject});
                        } else {
                            if(multiple === false) {
                                this.callOnChange();
                            }
                            this.setState({inputValue: ''});
                        }
                    }
                }}
                renderInput={params => (
                    <OtherTextField
                        label={label}
                        {...params}
                        margin={"dense"}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={inputLabelProps}
                    />
                )}
                filterOptions={(options, params) => {
                    let paramsNew = {
                        inputValue: params.inputValue || (inputValue && inputValue.value) || ''
                    }
                    const filtered = createFilterOptionsFromLabel(options, paramsNew);
                    let newLanguageCodeObject;
                    if (paramsNew.inputValue !== '' && !filtered.find(v => v.value === paramsNew.inputValue)) {
                        newLanguageCodeObject = {
                            value: paramsNew.inputValue,
                            label: `Add "${paramsNew.inputValue}"`,
                        };
                        filtered.push(newLanguageCodeObject);
                    }
                    return filtered;
                }}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return getChipWithDelete(theme, index, option.value, option.value, getTagProps({index}));
                    })
                }}
                size={"small"}
                disableClearable
                onBlur={() => {
                    if (inputValue) {
                        let newLanguageCodeObject;
                        if (isString(inputValue)) {
                            newLanguageCodeObject = {
                                value: inputValue,
                                label: inputValue
                            };
                        } else {
                            newLanguageCodeObject = inputValue;
                        }
                        let langValueNew = multiple ? [...langValue, newLanguageCodeObject] : newLanguageCodeObject;
                        this.setState({inputValue: ''});
                        this.callOnChange(langValueNew);
                    }
                }}
            />;

    }
}

DatatypeSearchSelect.propTypes = {
    configurations: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    value : PropTypes.any,
    labelRootClass : PropTypes.string,
    multiple : PropTypes.any,
    label : PropTypes.any
}

export default withStyles(styles, {withTheme: true})(DatatypeSearchSelect);

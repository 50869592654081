import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel, Grid, Tooltip, Typography, withStyles} from '@material-ui/core';
import InstructionForAction from "./InstructionForAction";
import {
    ALIAS_RDFS_LABEL,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS, DATATEST_viewInheritedPropertiesSwitch, FROM_SHAPE, LABEL_IRI, LABEL_TARGET_CLASS,
    MESSAGE_SELECT_TO_VIEW_DETAILS,
    STYLE_GRID_ITEM_SPACING,
    VIEW_MIDDLE_CARDS,
    VIEW_MIDDLE_JSONLD
} from "../Constants";
import DetailsTitle from "./DetailsTitle";
import {
    computeShapeWithSuperClasses,
    distinct,
    getProp,
    getPropertyArray,
    getShapePropertyArray,
    searchInTree,
    sort
} from "./util";
import TextField from "./TextField";
import CreateShapePropertyDialog from "./CreateShapePropertyDialog";
import IconButton from "./IconButton";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";
import SHACLPropertyCard from "./SHACLPropertyCard";
import DetailsViewButtonBar from "./DetailsViewButtonsBar";
import DetailsViewButton from "./DetailsViewButton";
import JSONEditorReact from "./JSONEditorReact";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import ViewAgendaIcon from "@material-ui/icons/ViewAgendaOutlined";
import CodeIcon from '@material-ui/icons/CodeOutlined';
import H2TitleFont2 from "./H2TitleFont2";
import HelpIcon from "./HelpIcon";
import {isArray} from 'lodash';
import H2Title from "../components/H2Title";


class ShapeDetails extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }

  getSuperClassPropsSwitch = () => {
    let {addSuperClassPropsInShape, onAddSuperClassPropsInShapeChange, theme} = this.props
    return  <FormControlLabel
            control={
                <Switch
                    datatest={DATATEST_viewInheritedPropertiesSwitch}
                    checked={addSuperClassPropsInShape && addSuperClassPropsInShape === true ? true  : false}
                    value={true}
                    size={"small"}
                    onChange={(e) => {
                        const {target: {checked}} = e
                        onAddSuperClassPropsInShapeChange(checked === true ? true: false)
                    }}
                    name="addSuperClassPropsInShape"
                />
            }
            label={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>Include Details from Class Hierarchy</Typography>}
        />;
  }

  getActionsComponent = () => {
    return <DetailsViewButtonBar>
          <DetailsViewButton
              selected={this.isMiddleViewCards()}
              onClick={() => {
                this.setState({columnAction: VIEW_MIDDLE_CARDS})
              }}
              startIcon={<ViewAgendaIcon/>}
          >Details</DetailsViewButton>
          <DetailsViewButton
              selected={this.isMiddleViewJSONLD()}
              onClick={() => {
                this.setState({columnAction: VIEW_MIDDLE_JSONLD})
              }}
              startIcon={<CodeIcon/>}
          >View JSON-LD</DetailsViewButton>
        </DetailsViewButtonBar>;
  }

  isMiddleViewCards = () => {
    let {columnAction} = this.state
    return !columnAction || columnAction === VIEW_MIDDLE_CARDS;
  }

  isMiddleViewJSONLD = () => {
    let {columnAction} = this.state
    return columnAction && columnAction === VIEW_MIDDLE_JSONLD;
  }

  removeProperty = (shape, path) => {
    let newShape = shape
    if (isArray(shape[ALIAS_SH_PROPERTY])) {
      newShape[ALIAS_SH_PROPERTY] = shape[ALIAS_SH_PROPERTY].filter(v => v[ALIAS_SH_PATH] !== path)
    } else if (shape[ALIAS_SH_PROPERTY][ALIAS_SH_PATH] === path) {
      delete newShape[ALIAS_SH_PROPERTY]
    }
    this.props.backendEventHandler({
      action: "saveJSON",
      data: newShape
    }).then((r) => {
    })
  }

  getJSONView = (jsonObj) => {
      let shapePropertyArray = sort(getShapePropertyArray(jsonObj), ALIAS_SH_PATH);
      jsonObj[ALIAS_SH_PROPERTY] = shapePropertyArray;
      let json = JSON.stringify(jsonObj, null, 4)
      const modes = ['code', 'view'];
      return <Grid
        item
        xs={12}
    >
      <JSONEditorReact
          modes={modes}
          enableSort={false}
          text={json}
          indentation={4}
          history={false}
          height={'100%'}
          expandAll={true}
          search={false}
          onEditable={() => {
            return false
          }}
      />
    </Grid>;
  }

  getPropertiesByShape = (properties, classes, focusNode) => {
    let {aliasesMap, allShapes} = this.props
    let shapeIds = properties.filter(p => p[FROM_SHAPE] && p[FROM_SHAPE] !== focusNode.id).map(p => p[FROM_SHAPE]).filter(distinct)
    return shapeIds.map(id => {
      let shapeNode = searchInTree(id, allShapes);
      let title = getProp(shapeNode.backingObject, ALIAS_RDFS_LABEL)
      return <Grid datatest={'superclass-'+title} style={{marginTop : '24px'}} key={id} container xs={12} spacing={STYLE_GRID_ITEM_SPACING}>
        <Grid item xs={12}>
          <H2Title datatest={'title'} title={title}/>
        </Grid>
        <Grid item xs={12}>
          <TextField value={"" + id} label={LABEL_IRI} readOnly={true}/>
        </Grid>
        <Grid item xs={12}>
          <TextField tooltip={shapeNode.backingObject[ALIAS_SH_TARGET_CLASS]}
                     value={aliasesMap[shapeNode.backingObject[ALIAS_SH_TARGET_CLASS]]} label={LABEL_TARGET_CLASS}
                     readOnly={true}/>
        </Grid>
        {
          sort(properties.filter(p => p[FROM_SHAPE] === id), 'path').map(p => {
            return <SHACLPropertyCard aliasesMap={aliasesMap} classes={classes} focusNode={focusNode}
                                      property={p}
                                      allowDelete={false}/>;
          })
        }</Grid>;
    });
  }


  getDetails = (focusNode) => {
    let {classes, theme, aliasesMap, helpText, allShapes, ontology, addSuperClassPropsInShape, isReadOnly, backendEventHandler} = this.props
    let jsonObj = focusNode
        ? (
            addSuperClassPropsInShape
                ? computeShapeWithSuperClasses(focusNode.backingObject, allShapes.map(o => o.backingObject), ontology, true, false, false)
                : focusNode.backingObject)
        : JSON.parse("{}")
    let properties = jsonObj[ALIAS_SH_PROPERTY] && isArray(jsonObj[ALIAS_SH_PROPERTY])
        ? jsonObj[ALIAS_SH_PROPERTY]
        : (jsonObj[ALIAS_SH_PROPERTY] ? [jsonObj[ALIAS_SH_PROPERTY]] : [])

    let titleActions = [
      <div style={{paddingTop: '4px'}}>{this.getSuperClassPropsSwitch()}</div>
    ]

    if(!isReadOnly) {
      titleActions.push(<CreateShapePropertyDialog
          aliasesMap={aliasesMap}
          classes={classes} theme={theme}
          shape={focusNode.backingObject}
          eventHandler={backendEventHandler}
          ontology={ontology}
      />);

      titleActions.push(<React.Fragment>
        <Tooltip placement="bottom" title={'Delete'}>
          <IconButton datatest={'deleteShapesButton'} size={'small'} onClick={() => {
            this.setState({openDeleteConfirm: true});
          }} className={classes.detailsActionButton}>
            <DeleteIcon/>
          </IconButton>
        </Tooltip>
        <ConfirmDeleteDialog
            title={`Are you sure you want to delete the ${getProp(jsonObj, ALIAS_RDFS_LABEL)} shape?`}
            open={this.state.openDeleteConfirm}
            key={'deleteConfirmDialog'}
            handleCancel={() => {
              this.setState({openDeleteConfirm: false})
            }}
            handleOk={() => {
              return backendEventHandler({
                action: 'deleteNode',
                id: jsonObj.id
              }).then(r => this.setState({openDeleteConfirm: false}));
            }}
            data={{value: ''}}/>
      </React.Fragment>)
    }
    if(helpText) {
        titleActions.push(<HelpIcon helpText={helpText}/>)
    }
    return (<>
        <Grid container xs={12} spacing={STYLE_GRID_ITEM_SPACING}>
          <Grid item xs={12}>{
              (jsonObj || helpText) && <DetailsTitle title={ jsonObj ? getProp(jsonObj, ALIAS_RDFS_LABEL) : ''} actions={titleActions}/>
          }</Grid>
          <Grid item xs={12}>
            {this.getActionsComponent()}
          </Grid>
          <Grid item xs={12}>{
            jsonObj &&
            <TextField value={"" + jsonObj.id} label={LABEL_IRI} readOnly={true}/>
          }</Grid>
          <Grid item xs={12}>{
            jsonObj &&
            <TextField tooltip={jsonObj[ALIAS_SH_TARGET_CLASS]} value={aliasesMap[jsonObj[ALIAS_SH_TARGET_CLASS]]} label={LABEL_TARGET_CLASS} readOnly={true}/>
          }</Grid>
          {
              (!this.state.columnAction || this.isMiddleViewCards())
              && this.renderPropertyCards(focusNode, aliasesMap, isReadOnly, classes, ontology, backendEventHandler)
          }
          { this.isMiddleViewJSONLD() && this.getJSONView(jsonObj) }
        </Grid>
        {
            this.isMiddleViewCards() && addSuperClassPropsInShape &&
                this.getPropertiesByShape(properties, classes, focusNode)
        }
        </>
    );
  }


    renderPropertyCards = (focusNode) => {
        let {classes, aliasesMap, ontology, isReadOnly, backendEventHandler} = this.props;
        let propertyArray = getPropertyArray(focusNode.backingObject);
        let sorted = sort(propertyArray, 'path');
        console.log(sorted);
        return sorted.map((p) => {
            console.log(p);
            return <SHACLPropertyCard aliasesMap={aliasesMap} allowEdit={!isReadOnly} key={p[ALIAS_SH_PATH]}
                                      classes={classes} focusNode={focusNode} property={p}
                                      allowDelete={!isReadOnly} ontology={ontology} deleteHandler={this.removeProperty}
                                      eventHandler={backendEventHandler}/>;
        });
    }

    render() {
     let {shapeIRI, helpText, allShapes} = this.props
      let focusNode = allShapes.find(o => o.id === shapeIRI)
      let titleActions = []
      if(helpText) {
          titleActions.push(<HelpIcon helpText={helpText}/>)
      }

      return (<div>
          {!focusNode && <>
            {helpText && <Grid container spacing={STYLE_GRID_ITEM_SPACING} xs={12}>
                    <Grid item xs={12}><DetailsTitle title={''} actions={titleActions}/>
                    </Grid>
                </Grid>
            }
            <InstructionForAction text={MESSAGE_SELECT_TO_VIEW_DETAILS}/>
            </>
          }
          {focusNode && this.getDetails(focusNode)}
        </div>);
    }

}


ShapeDetails.propTypes = {
    shapeIRI: PropTypes.string,
    allShapes: PropTypes.array,
    addSuperClassPropsInShape: PropTypes.bool,
    onAddSuperClassPropsInShapeChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    ontology: PropTypes.array,
    aliasesMap: PropTypes.object,
    backendEventHandler: PropTypes.func,
    helpText: PropTypes.array
};

export default withStyles({}, {withTheme: true})(ShapeDetails);

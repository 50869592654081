import React, {Component} from 'react';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';

import {isString, uniq} from 'lodash';
import {styles} from "../../components/styles";
import {
    attachToWindowForTesting,
    centerVertically,
    computeAllSubClasses,
    computeAllSuperClasses,
    computeClassesTree,
    computeShapePropertiesWithSuperClasses,
    distinct,
    findInversePropertyObject,
    flatten,
    getPropertyClasses,
    getRouteWithInstanceAndDataset,
    getShapePropertyArray,
    isBooleanDatatype,
    isConnectionProperty,
    isDatatypeProperty,
    isEmptyArray,
    isObjectProperty,
    isObjectShapeProperty,
    isOwlClass,
    isPropertyClass,
    isStringDatatype,
    scrollToView,
    searchInTree,
    sort,
    sortShapeProperties,
    toArray,
    validateAlias,
    validateBaseIRI,
    validateDescription,
    validateModelName,
    validateNameDuplication,
    validatePrefix
} from "../../components/util";
import queryString from "query-string";
import AllPartsLayout from "../AllPartsLayout";
import {getHeader} from "../../components/header/APIPlaygroundHeader";
import InstructionForAction from "../../components/InstructionForAction";
import IconButton from "../../components/IconButton";
import {FormControlLabel, Grid, IconButton as MUIIconButton, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutlined";
import NavTree from '../../components/NavTree/NavTree';
import qs from "qs";
import history from "../../history";
import {
    ALIAS_OWL_DATATYPE_PROPERTY,
    ALIAS_OWL_INVERSE_OF,
    ALIAS_OWL_OBJECT_PROPERTY,
    ALIAS_RDFS_SUBCLASS_OF,
    ALIAS_SH_CLASS,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MAX_LENGTH,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_MIN_LENGTH,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_OR,
    ALIAS_SH_PATH,
    ALIAS_SH_PATTERN,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_BASE_IRI,
    ALIAS_SYS_BASE_IRI_PREFIX,
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ID_LABEL,
    ALIAS_SYS_MODIFIED_DATE,
    ALIAS_SYS_PROPERTY_TYPE,
    ALIAS_SYS_TYPE_SCHEMA,
    DATATEST_graphViewButton,
    DATATEST_listViewButton,
    DATATEST_treeViewButton,
    ID,
    LABEL_BASE_IRI,
    LABEL_DESCRIPTION,
    LABEL_EXPORT_SCHEMA,
    LABEL_IMPORT_SCHEMA,
    LABEL_INVERSE_OF,
    LABEL_NAME,
    LABEL_NODE_KIND,
    LABEL_PREFIX,
    LABEL_PROPERTY_TYPE,
    LABEL_SCHEMA_DASHBOARD,
    LABEL_SUBCLASS_OF,
    LABEL_SUBCLASSES,
    LABEL_SUPERCLASSES,
    LABEL_UPDATE_DATE,
    MESSAGE_SELECT_TO_VIEW_DETAILS,
    OBJECT_LINKING_PROPERTY,
    RED_COLOR,
    ROUTE_SCHEMA_BUILDER,
    SECONDARY_COLOR,
    SH_IRI, SH_LITERAL,
    STYLE_GRID_ITEM_SPACING,
    TYPE,
    TYPE_RDF_LIST,
    VALIDATION_BASE_IRI_MAX_LENGTH,
    VALIDATION_DESCRIPTION_LENGTH,
    VALIDATION_LABEL_LENGTH,
    VALIDATION_PREFIX_MAX_LENGTH
} from "../../Constants";
import GridContainer from "../../components/GridContainer";
import fileDownload from "js-file-download";
import PropTypes from "prop-types";
import FieldContainer from "../../components/FieldContainer";
import ViewGlobalsDialog from "../../components/ViewGlobalsDialog";
import {searchLabelRecursively} from "../../components/TreeViewForIdSetup";
import {
    filterOntology,
    filterOntologyClasses,
    filterOntologyProperties,
    filterShapes,
    getModel,
    getModelData,
    storeModelAndData,
    updateModel
} from "../../service/model-api";
import Tooltip from "@material-ui/core/Tooltip";
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import {traceComponentDidUpdateStart, traceRenderStart} from "../../components/Trace";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import Button from "@material-ui/core/Button";
import AutoComplete from "../../components/AutoComplete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {SCHEMA_BUILDER} from "./Models";
import Property, {ERROR_KEY_SUFFIX, updatePropertyClass} from "../../layouts/modelbuilder/Property";
import uuid4 from 'uuid/v4'
import cytoscape from 'cytoscape';
import {PrettoSlider} from "../../components/Slider";
import coseBilkent from 'cytoscape-cose-bilkent';
import fcose from 'cytoscape-fcose';
import AddPlainIcon from '@material-ui/icons/Add';
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import AddClassDialog from "../../layouts/modelbuilder/AddClassDialog";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import H4Title from "../../components/H4Title";
import AddPropertyDialog, {
    getInverseOfSelect,
    getPropertyTypeSelect,
    isObjectLinkingTypeValue,
    isObjectTypeValue,
    NEW_PROPERTY
} from "../../layouts/modelbuilder/AddPropertyDialog";
import popper from 'cytoscape-popper';
import ReactDOM from "react-dom";
import {theme} from "../../theme";
import {ArrowLeftIcon} from "@material-ui/pickers/_shared/icons/ArrowLeftIcon";
import {ArrowRightIcon} from "@material-ui/pickers/_shared/icons/ArrowRightIcon";
import dagre from 'cytoscape-dagre';
import ImportModelDialog from "../../layouts/modelbuilder/ImportModelDialog";
import MergeTypeIcon from '@material-ui/icons/MergeType';
import {createAliasesMap, createClassObject, createPropertyObject, createShapeObject, updateModifiedDate} from "./util";
import {
    addAdjustWidth,
    adjustMinWidth,
    computeLabelAndWidth,
    getDiagramLayout,
    getDiagramStyle, registerExtension
} from "../../components/cytoscope-util";
import {GreyDeleteIcon} from "../../components/GreyStyleIcon";
import LinkOffIcon from '@material-ui/icons/LinkOff';
import {ConfirmAreYouSure} from "../../components/ConfirmAreYouSure";
import LeftBarTitle from "../../components/LeftBarTitle";
import CustomAliasTreeLabel from "../../layouts/modelbuilder/CustomAliasTreeLabel";
import NavList from "../../components/NavList";
import EditableTextField from "../../components/EditableTextField";
import EditableTextArea from "../../components/EditableTextArea";
import Collapse from '@material-ui/core/Collapse';
import MenuListComposition from "../../components/MoreMenuIcon";
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import RefreshOutlinedIcon from '@material-ui/icons/RefreshOutlined';
import Divider from "@material-ui/core/Divider";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListAltIcon from "@material-ui/icons/ListAlt";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import Chip from "@material-ui/core/Chip";
import H3Title from "../../components/H3Title";
import CallMadeIcon from '@material-ui/icons/CallMade';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import {BlockActionButton} from "../../components/ShapeToForm/ArrayType";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import {LeftMenuIconButton} from "../../components/LeftMenuIconButton";
import {LeftMenuToggleButton} from "../../components/LeftMenuToggleButton";
import JSONEditorReact from "../../components/JSONEditorReact";
import {cloneDeepWith} from 'lodash';


registerExtension(cytoscape, coseBilkent, 'coseBilkent');
registerExtension(cytoscape, fcose, 'fcose');
registerExtension(cytoscape, popper, 'popper');
registerExtension(cytoscape, dagre, 'dagre');

const COMPONENT = 'Model.jsx'

const TREE_VIEW = 'Tree';
const LIST_VIEW = 'List';

const GRAPH_VIEW = 'Graph';
const FORM_VIEW = 'Form';

const MODEL_VIEW = undefined;
const CLASS_VIEW = 0;
const PROPERTIES_VIEW = 1;

function getLeftMenuTabValue(location) {
    let params = queryString.parse(location.search);
    return params.leftMenuTabValue ? Number(params.leftMenuTabValue) : MODEL_VIEW;
}

const chipIcon = <CallMadeIcon
    style={{
        borderRadius : '50%',
        backgroundColor: SECONDARY_COLOR,
        color: '#FFFFFF',
        width : '18px',
        height : '18px',
        padding : '4px'
    }}
/>
const NavigationChip = withStyles({
    root : {
        maxWidth : 'calc(100% - 12px)'
    }
})(Chip);

const PROPERTY_DELETE = 'PROPERTY_DELETE';
const ADD_SUBCLASS_EXISTING = 'ADD_SUBCLASS_EXISTING';
const ADD_NEW_SUBCLASS = 'ADD_NEW_SUBCLASS';
const CREATE_NEW_CLASS = 'CREATE_NEW_CLASS';


const MIN_ZOOM = .2;

class Model extends Component {
    constructor(props) {
        super(props);
        //this means import
        if (props.readOnly) {
            this.state = {
                leftMenuMinimized: false,
                modelDetails: props.modelDetails,
                shapes: props.shapes,
                ontologyClasses: props.ontologyClasses,
                ontologyProperties: props.ontologyProperties,
                expanded: [],
                loading: false,
                leftMenuTabValue: MODEL_VIEW,
                minimized: true,
                tabValue : 0,
                propertyViewTabValue: 0,
                viewType : TREE_VIEW,
                resetID: uuid4(),
                changeListeners : [],
                viewPropertyEdges: false,
                viewInheritedPropertyEdges: false,
                viewSubclassOfEdges: true,
                showRightContainer: undefined,
                expandedPropertyIds : []
            }

        } else {
            let params = queryString.parse(props.location.search)
            let modelDetails = getModel(params.schemaId)
            if (modelDetails) {
                let modelData = getModelData(modelDetails);
                let shapes = filterShapes(modelData);
                let ontologyClasses = filterOntologyClasses(modelData);
                let ontologyProperties = filterOntologyProperties(modelData);
                let leftMenuTabValue = params.leftMenuTabValue ? Number(params.leftMenuTabValue) : MODEL_VIEW;
                let expanded = params.expanded ? params.expanded.split(',') : []
                this.state = {
                    leftMenuMinimized: false,
                    modelDetails: modelDetails,
                    shapes: shapes,
                    ontologyClasses: ontologyClasses,
                    ontologyProperties: ontologyProperties,
                    expanded: expanded ? expanded : [],
                    loading: false,
                    leftMenuTabValue: leftMenuTabValue,
                    minimized: true,
                    tabValue : 0,
                    propertyViewTabValue: 0,
                    viewType : TREE_VIEW,
                    resetID: uuid4(),
                    changeListeners : [],
                    viewPropertyEdges: false,
                    viewInheritedPropertyEdges: false,
                    viewSubclassOfEdges: true,
                    showRightContainer: undefined,
                    expandedPropertyIds : []
                }
            } else {
                this.state = {loading : false}
            }
        }
    }

    componentDidMount() {
        let {modelDetails} = this.state;
        if (!modelDetails) {
            history.push(getRouteWithInstanceAndDataset(ROUTE_SCHEMA_BUILDER));
        }
        this.syncDataWithBackend();
        attachToWindowForTesting('cytoscapeObj', this.cytoscapeObj);
    }

    syncDataWithBackend = () => {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        traceComponentDidUpdateStart(COMPONENT)

        const { theme } = this.props;
        let cy = document.getElementById('cy')
        if (cy && !cy.innerHTML) {
            let outerThis = this
            let {shapes, minimized, ontologyClasses} = this.state

            const elements = {
                nodes: this.getNodes(),
                edges: this.getEdges(shapes, ontologyClasses)
            }

            this.cytoscapeObj = cytoscape({
                container: cy,
                boxSelectionEnabled: false,
                layout: this.getDiagramLayout({
                    animationDuration : 1

                }),
                style: this.getDiagramStyle(),
                elements: elements
            });

            let cyOuter = this.cytoscapeObj;

            //below is fix for flickering watch https://github.com/cytoscape/cytoscape.js/issues/2709
            // not sure if it fixes it TODO
            ///this.cytoscapeObj._private.renderer.data.eleTxrCache.checkTextureUtility = function () {}
            //this.cytoscapeObj._private.renderer.data.lblTxrCache.checkTextureUtility = function () {}
            //this.cytoscapeObj._private.renderer.data.slbTxrCache.checkTextureUtility = function () {}
            //this.cytoscapeObj._private.renderer.data.tlbTxrCache.checkTextureUtility = function () {}
            //this.cytoscapeObj._private.renderer.data.lyrTxrCache.checkTextureUtility = function () {}
            this.cytoscapeObj.on('mouseover', 'node', function (evt) {
                console.log(evt.renderedPosition);

                let node = evt.target;
                let nodeID = node.id();
                let popper = node.popper({
                    content: () => {
                        let elementById = document.getElementById('cy-popper');
                        let z1 = cyOuter.zoom();
                        const bb = node.renderedBoundingBox({ includeLabels: false, includeOverlays: false });
                        let w = 30

                        const deltaW4Scale = w * z1 ;
                        const transform = `translate(${(bb.w/2) - (deltaW4Scale/2 + (5 * z1))  }px, -${(20 * (1 - z1))+ bb.h - 6 }px) scale(${z1})`;

                        let style = {
                            maxHeight : '28px',
                            transform : transform,
                            marginRight: '0px'
                        }
                        // theme does not work so pass it in
                        let paper = <MuiThemeProvider theme={theme}>
                            {outerThis.getAddSubClassButton(nodeID, style)}
                        </MuiThemeProvider>;
                        ReactDOM.render(paper, elementById);
                        return elementById;
                    },
                    popper : {
                        positionFixed : true,
                        onCreate() {
                            //console.log('Popper creare')
                        },
                        modifiers: {
                            flip: {
                                enabled: false
                            },
                            preventOverflow: {
                                escapeWithReference: true
                            }
                        }
                    }
                });

                let update = () => {
                    popper.scheduleUpdate();
                    //console.log(node.popper())
                };

                node.on('position', update);

                cyOuter.on('pan zoom resize', update);

                node.addClass('onMouseOverClass');
                //history.push(`${outerThis.props.location.pathname}?${qs.stringify({id: node.id()})}`)
            });

            this.cytoscapeObj.on('mouseout', 'node', function (evt) {
                let params = queryString.parse(outerThis.props.location.search)
                let node = evt.target;
                if(params.classId !== node.id()) {
                    node.removeClass('onMouseOverClass');
                }
                let elementById = document.getElementById('cy-popper');
                ReactDOM.render(<></>, elementById);
            });

            this.cytoscapeObj.on('click', 'node', function (evt) {
                let {location} = outerThis.props;
                let params = queryString.parse(location.search)
                let node = evt.target;
                outerThis.unselectAll();
                node.select();
                let nodeID = node.id();
                if(outerThis.isRightContainerUndefined() || !params.classId) {
                    outerThis.setState({showRightContainer: true});
                    // if selected node is under the right container then recenter
                    let x = node.renderedPosition().x;
                    let threshold = minimized === true ? 800 : 600;
                    if(x > threshold) {
                        outerThis.diagramSearch(nodeID);
                    }
                }
                outerThis.navigateTo({node : {classId : nodeID}});
            });

            this.cytoscapeObj.on('click', 'edge', function (evt) {
                let edge = evt.target;
                console.log(edge.renderedBoundingBox());
            })
            this.cytoscapeObj.on('mouseover', 'edge', function (evt) {
                let node = evt.target;
                if(node.data().label !== LABEL_SUBCLASS_OF) {
                    return;
                }
                let nodeID = node.id();
                let popper = node.popper({
                    content: () => {
                        let elementById = document.getElementById('cy-popper');
                        let z1 = cyOuter.zoom();
                        const transform = `scale(${z1})`;
                        let style = {
                            maxHeight : '28px',
                            transform : transform,
                            marginRight: '0px'
                        }

                        let classID = node.data().target;
                        let parentClassID = node.data().source;

                        let paper = <MuiThemeProvider theme={theme}>
                            {outerThis.getRemoveLinkButton(outerThis.props.theme, classID, parentClassID, style)}
                        </MuiThemeProvider>;
                        ReactDOM.render(paper, elementById);

                        return elementById;
                    },
                    renderedPosition: () => {
                        let pos = {
                            x : evt.renderedPosition.x,
                            y : evt.renderedPosition.y - 8,
                        };
                        return pos;
                    },
                });

                let update = () => {
                    popper.scheduleUpdate();
                };

                node.on('position', update);

                cyOuter.on('pan zoom resize', update);

                node.addClass('onMouseOverEdgeClass');

            })
            this.cytoscapeObj.on('mouseout', 'edge', function (evt) {
                let node = evt.target;
                node.unselect();
                let elementById = document.getElementById('cy-popper');
                ReactDOM.render(<></>, elementById);
                node.removeClass('onMouseOverEdgeClass');
            });


            addAdjustWidth(this.cytoscapeObj, outerThis);

            setTimeout(() => {
                this.refreshDiagramLayout();
            }, 1);


            this.cytoscapeObj.userZoomingEnabled(false);
            //this.cytoscapeObj.zoom(.6);
            this.cytoscapeObj.maxZoom(1);
            //this.cytoscapeObj.minZoom(MIN_ZOOM);
            //this.cytoscapeObj.center();
            this.cytoscapeObj.styleEnabled()

            // select focus node
            let params = queryString.parse(this.props.location.search)
            let focusObject = params
                ? searchInTree(params.classId, this.state.ontologyClasses)
                : undefined;
            if(focusObject) {
                this.diagramSearch(focusObject[ID]);
            }

            attachToWindowForTesting('cytoscapeObj', this.cytoscapeObj);
        }

    }


    getEdges = (shapes, ontologyClasses) => {
        let {ontologyProperties, viewPropertyEdges, viewSubclassOfEdges, viewInheritedPropertyEdges} = this.state;
        let classToShape = {}
        shapes.forEach(s => classToShape[s[ALIAS_SH_TARGET_CLASS]] = s.id)
        let edges = []
        if(viewPropertyEdges || viewInheritedPropertyEdges) {
            shapes.forEach(shape => {
                let source = shape[ALIAS_SH_TARGET_CLASS];
                let sourceLabel = this.getClassLabel(ontologyClasses, source);
                let allProperties = viewInheritedPropertyEdges === true
                    ? computeShapePropertiesWithSuperClasses(shape, shapes, ontologyClasses, !viewPropertyEdges)
                    : getShapePropertyArray(shape);
                let objectProps = allProperties.filter(p => isObjectShapeProperty(p))
                objectProps.forEach(p => {
                    let propClasses = getPropertyClasses(p);
                    let propertyObject = ontologyProperties.find(op => op[ID] === p[ALIAS_SH_PATH]);
                    propClasses && propClasses.forEach(c => {
                        let target = c;
                        let targetLabel = this.getClassLabel(ontologyClasses, target);

                        let label = propertyObject[ALIAS_SYS_ID_LABEL];
                        let id = source + "::" + target + "::" + label
                        let classes = 'property'
                        if (source && target) {
                            edges.push({
                                data: {
                                    id: id,
                                    source: source,
                                    target: target,
                                    label: label,
                                    "arrow": "triangle",
                                    sourceLabel,
                                    targetLabel,
                                },
                                classes: classes
                            })
                        } else {
                            //TODO report some errors
                        }
                    })
                });
            });
        }

        if(viewSubclassOfEdges) {
            let allClasses = new Set(ontologyClasses.map(o => o[ID]));
            ontologyClasses.forEach(cl => {
                let target = cl[ID];
                let targetLabel = this.getClassLabel(ontologyClasses, target);
                let subClassOfArray = cl[ALIAS_RDFS_SUBCLASS_OF];
                if (subClassOfArray) {
                    subClassOfArray.filter(scOf => allClasses.has(scOf)).forEach(scOf => {
                        let source = scOf
                        let sourceLabel = this.getClassLabel(ontologyClasses, source);

                        let id = "subclass-" + target + "::" + source
                        let classes = 'subclass'
                        edges.push({
                            data: {
                                id: id,
                                source: source,
                                target: target,
                                label: LABEL_SUBCLASS_OF,
                                "arrow": "triangle",
                                sourceLabel,
                                targetLabel,
                            },
                            classes: classes
                        })

                    })
                }
            })
        }

        return edges;
    }


    updateDiagram = (shapes, allClassObjects, options = {newClassObject : undefined, searchFocus:true}) => {
        if(!this.isDiagramView() || !allClassObjects) {
            return;
        } else {
            let newEdges = this.getEdges(shapes ,allClassObjects);
            let oldEdges = this.cytoscapeObj.filter(function (element, i) {
                return element.isEdge();
            });
            let oldNodes = this.cytoscapeObj.filter(function (element, i) {
                return element.isNode();
            });

            this.cytoscapeObj.remove(oldEdges);

            let nonExistingNodes = [];
            let maxPosition = 0;

            //If the node exists add it to the same position
            allClassObjects.forEach(classObject => {
                let oldNode = this.cytoscapeObj.getElementById(classObject[ID]);
                let position = oldNode.position();
                if(!oldNode || oldNode.length < 1) {
                    nonExistingNodes.push(classObject);
                } else {
                    // if label is changed then update label
                    let nodeForDiagram = this.createNodeForDiagram(classObject);
                    if(oldNode.data().label !== nodeForDiagram.data.label) {
                        nodeForDiagram.position = oldNode.position();
                        nodeForDiagram.selected = oldNode.selected();
                        this.cytoscapeObj.remove(oldNode);
                        this.cytoscapeObj.add([nodeForDiagram].map(e => {
                            e.group = 'nodes';
                            return e;
                        }))
                        let newAddedNode = this.cytoscapeObj.getElementById(classObject[ID]);
                        if(newAddedNode) {
                            adjustMinWidth(newAddedNode);
                        }
                    }

                    if(position.x > maxPosition) {
                        maxPosition = position.x;
                    }
                }
            });

            //create and add non-existing(new) nodes
            nonExistingNodes.forEach((classObject, i) => {
                let newNode = this.createNodeForDiagram(classObject);
                /*
                let x = maxPosition + 200;
                newNode.position = {
                    x : x,
                    y : 0
                };
                */

                this.cytoscapeObj.add([newNode].map(e => {
                    e.group = 'nodes';
                    return e;
                }));
                let newAddedNode = this.cytoscapeObj.getElementById(classObject[ID]);
                if(newAddedNode) {
                    adjustMinWidth(newAddedNode);
                }
            })

            // remove deleted nodes
            let nodesToRemove = this.cytoscapeObj.filter(function (element, i) {
                if(element.isNode()){
                    let found = allClassObjects.find(o => o[ID] === element.data().id);
                    if(found) {
                        return false;
                    } else {
                        return true;
                    };
                } else {
                    return false;
                }
            })
            this.cytoscapeObj.remove(nodesToRemove);


            this.cytoscapeObj.add(newEdges.map(e => {
                e.group = 'edges';
                return e;
            }));


            //Close popper
            let elementById = document.getElementById('cy-popper');
            ReactDOM.render(<></>, elementById);

        }
    }

    refreshDiagramLayout = (layoutOptions = {}) => {
        if(!this.isDiagramView()) {
            return;
        }
        if(this.cytoscapeObj) {
            if (!layoutOptions.stop) {
                layoutOptions.stop = () => {
                    this.setState({zoomLevel: this.cytoscapeObj.zoom()})
                }
            }
            let allNewNodes = this.cytoscapeObj.filter(function (element, i) {
                return true;
            });
            if (allNewNodes && allNewNodes.length > 0) {
                this.cytoscapeObj.style().clear().fromJson(this.getDiagramStyle()).update();
                allNewNodes.layout(this.getDiagramLayout(layoutOptions)).run();
            }
        }
    }

    adjustMinZoom = () => {
        let newZoom = this.cytoscapeObj.zoom();
        const  zoomMinLevel = .4;
        if(newZoom < zoomMinLevel) {
            this.handleZoomChange(undefined, zoomMinLevel);
            this.setState({zoomLevel : zoomMinLevel})
        } else {
            this.setState({zoomLevel : newZoom})
        }
    }

    getDiagramLayout = (options) => {
        let {ontologyClasses} = this.state;
        return getDiagramLayout(ontologyClasses, this.isSubclassOfViewOnly(), options)
    }

    isSubclassOfViewOnly = () => {
        let {viewPropertyEdges, viewSubclassOfEdges, viewInheritedPropertyEdges} = this.state;
        return viewSubclassOfEdges && viewPropertyEdges === false && viewInheritedPropertyEdges === false;
    }

    getDiagramStyle = () => {
        return getDiagramStyle(this.props.theme, this.isSubclassOfViewOnly());
    }

    diagramSearch = (nodeId, center = true) => {
        this.unselectAll();
        let elementNode = this.cytoscapeObj.filter(function (element, i) {
            return element.isNode() && element.data('id') === nodeId;
        })[0];
        if(elementNode) {
            elementNode.select();
            center && this.cytoscapeObj.center(elementNode);
        }
    }

    selectNode = (nodeId) => {
        this.unselectAll();
        let elementNode = this.cytoscapeObj.getElementById(nodeId);
        elementNode && elementNode.select();
    }


    unselectAll = () => {
        this.cytoscapeObj.filter(function (element, i) {
            element.removeClass('onMouseOverClass');
            return element;
        }).unselect();
    }

    getSelectedNodes = () => {
        return this.cytoscapeObj.filter(function (element, i) {
            return element.isNode() && element.selected();
        });
    }

    getNodes = () => {
        let {ontologyClasses} = this.state;
        let allNonTop = ontologyClasses.filter(c => c[ALIAS_RDFS_SUBCLASS_OF]);
        let allTop =  sort(ontologyClasses.filter(c => !c[ALIAS_RDFS_SUBCLASS_OF]), ALIAS_SYS_ID_LABEL);
        return [...allTop, ...allNonTop].map((classObject, j) => {
            return this.createNodeForDiagram(classObject)
        });
    }

    createNodeForDiagram = (classObject) => {
        let title = classObject[ALIAS_SYS_ID_LABEL];
        let {label, width} = computeLabelAndWidth(title)
        //let label = title.length > length ? title.substring(0, length) + '...' : title;
        //let width = title.length < 5 ? '100' : 'label';
        return {data: {id: classObject[ID], label: label, "type": "loop", width : width}};
    }



    getTitlePrefix = (id) => {
        return ;
    }

    renderNodeLabel = (node, index) => {
        let maxWidth = 'calc(100%)';
        let labelText = node.labelText;
        return <Typography datatest={'nodeLabel-'+labelText} style={{maxWidth: maxWidth}} noWrap={true} component={"span"}>{labelText}</Typography>;
    }

    renderTreeItem = (node, treeItem, index) => {
        return treeItem;
    }

    onSearch = (dataForSearch, center) => (e, node) => {
        if (node) {
            const {expanded} = this.state;
            const {id} = node;
            const newExpandedItems = flatten(dataForSearch.map(treeNode => searchLabelRecursively(treeNode, id, 'id', [])));
            const expandedItems = this.isPropertiesTabView() ? newExpandedItems : uniq([...expanded, ...newExpandedItems]);
            if(this.isPropertiesTabView()) {
                this.navigateTo({node: {propId : id, leftMenuTabValue : PROPERTIES_VIEW}});
            } else {
                this.navigateTo({node: {classId : id, leftMenuTabValue : CLASS_VIEW}});
            }
            if(this.isDiagramView()) {
                this.diagramSearch(node.id, center);
            } else {
                this.setState({searchNode: node, expanded: expandedItems}, () => {
                    this.setState({scrollTo: true})
                });
            }
        }
    }


    getDataForView = () => {
        if(this.isPropertiesTabView()) {
            let dataForView = [];
            let {ontologyProperties} = this.state;
            ontologyProperties.forEach(n => {
                let node = {
                    [ID]: n[ID],
                    title: n[ALIAS_SYS_ID_LABEL],
                    [TYPE]: n[TYPE]
                };
                dataForView.push(node);
            });
            return dataForView;
        }
        let {ontologyClasses} = this.state;
        if(this.isTreeView() && !this.isDiagramView()) {
            let aliasesMap = {}
            ontologyClasses.forEach(o => aliasesMap[o[ID]] = o[ALIAS_SYS_ID_LABEL]);
            let classesTree = computeClassesTree(ontologyClasses, aliasesMap, ALIAS_SYS_ID_LABEL);
            return classesTree;
        } else {
            let dataForView = [];
            ontologyClasses.forEach(n => {
                let node = {
                    [ID]: n[ID],
                    title: n[ALIAS_SYS_ID_LABEL],
                    [TYPE]: n[TYPE]
                };
                dataForView.push(node);
            });
            return dataForView;
        }
    }

    getLeftIconButton = (icon, title, onClick) => {
        let {minimized} = this.state
        let {theme} = this.props
        return <LeftMenuIconButton theme={theme} title={title} onClick={onClick} icon={icon} minimized={minimized}/>;
    }

    getLeftActions = () => {
        let {theme} = this.props;
        let {minimized} = this.state;
        let iconStyle = {color: theme.palette.white.main}
        let width = minimized ? '52px' : '250px'

        return <>
            <LeftMenuToggleButton theme={theme} minimized={minimized} onClick={() => this.setState({minimized: !minimized})}/>
            <div style={{height: '100%', width: width, backgroundColor: theme.palette.secondary.dark}}>
                <List style={{padding: '0px'}}>
                    {
                        this.getLeftIconButton(
                            <ViewModuleIcon style={iconStyle}/>,
                            LABEL_SCHEMA_DASHBOARD,
                            () => history.push(getRouteWithInstanceAndDataset(ROUTE_SCHEMA_BUILDER))
                        )
                    }
                    {
                        this.getLeftIconButton(
                            <PublishIcon style={iconStyle}/>,
                            LABEL_IMPORT_SCHEMA,
                            () => this.setState({openImportModelDialog: true})
                        )
                    }
                    {
                        this.getLeftIconButton(
                            <GetAppIcon style={iconStyle}/>,
                            LABEL_EXPORT_SCHEMA,
                            this.exportModel
                        )
                    }
                </List>
            </div>
        </>;

    }

    isPropertiesTabView = () => {
        let leftMenuTabValue = getLeftMenuTabValue(this.props.location);
        return !this.isDiagramView() && leftMenuTabValue === PROPERTIES_VIEW;
    }

    isClassTabView = () => {
        let leftMenuTabValue = getLeftMenuTabValue(this.props.location);
        return !this.isDiagramView() && leftMenuTabValue === CLASS_VIEW;
    }

    getLeftComponent = () => {
        const {theme, readOnly} = this.props;
        let {minimized} = this.state;
        let leftMenuWidth = minimized ? 77 : 275;
        let leftMenuMarginRight = 19;

        return <div style={{display: 'flex', height: '100%'}}>
            <div style={{minWidth: (leftMenuWidth+'px'), marginRight: leftMenuMarginRight+'px', height: '100%'}}>
                {readOnly || this.getLeftActions()}
            </div>
            <div style={{minWidth: `calc(100% - ${(leftMenuWidth+leftMenuMarginRight)+'px'})`, flexGrow: '1', height: '100%'}}>
                {this.getLeftBarHeader()}
                <FieldContainer style={{
                        height: '100%',
                        paddingTop: '0px',
                        paddingRight: '0px',
                        backgroundColor: theme.palette.white.main
                }}>
                    {
                        this.isDiagramView()
                            ? this.getDiagram()
                            :  !this.isPropertiesTabView() ?

                                <div datatest={'classesTabContent'} key={'renderClasses'} style={{height: '100%'}}>
                                    {
                                        this.renderClasses()
                                    }
                                </div>
                            :
                                <div datatest={'propertiesTabContent'}  key={'renderProperties'}  style={{height: '100%'}}>
                                    {
                                        this.renderProperties()
                                    }
                                </div>
                    }
                </FieldContainer>
            </div>
        </div>;
    }

    getViewButtonGroup = () => {
        let {viewType, ontologyClasses} = this.state;
        let {theme} = this.props;
        let StyledButton = withStyles({
            root : {
                '&:hover': {
                    backgroundColor : theme.palette.white.main
                }
            },
            label : {
                textTransform : 'none'
            }
        })(Button);
        let isTreeView = viewType === TREE_VIEW;
        let isListView = viewType === LIST_VIEW;
        return <div style={{paddingRight : '12px' , paddingTop: '4px', paddingBottom : '4px'}}>
            <ButtonGroup fullWidth={true} style={{color: theme.palette.primary.main, borderColor : theme.palette.grey.level2}} size={'small'}>
                <StyledButton
                    datatest={DATATEST_treeViewButton}
                    disableRipple={true}
                    color={isTreeView ? 'secondary' : 'primary'}
                    style={{borderColor : isTreeView && theme.palette.secondary.main}}
                    onClick={() => {
                        this.setState({viewType : TREE_VIEW});
                        const params = queryString.parse(this.props.location.search);
                        if(params.classId) {
                            this.expandAndNavigateTo(params.classId);
                        }

                    }}
                >{TREE_VIEW}</StyledButton>
                <StyledButton
                    datatest={DATATEST_listViewButton}
                    disableRipple={false}
                    color={isListView ? 'secondary' : 'primary'}
                    style={{borderColor : isListView ? theme.palette.secondary.main : theme.palette.grey.level2, borderLeftColor : theme.palette.secondary.main}}
                    onClick={() => this.setState({viewType : LIST_VIEW})}
                >{LIST_VIEW}</StyledButton>
            </ButtonGroup>
        </div>;
    }

    renderClasses = () => {
        const {onTreeNodeToggle, location, theme, readOnly} = this.props;
        const {ontologyClasses, expanded, scrollTo} = this.state;
        const params = queryString.parse(location.search);
        let treeStyle = {maxHeight: '100%'};
        let treeContainerStyle = {
            marginRight: '2px',
            height: readOnly ? 'calc(100% - 320px)' : 'calc(100% - 208px)',
            display: 'flex',
            flexDirection: 'column'
        };
        let dataForView  = this.getDataForView();

        return <>
            {(!ontologyClasses || ontologyClasses.length < 1)
                ? readOnly
                    ? this.getNoItemsComponent('No class defined.')
                    : this.getNoItemsComponent('Add a class to get started.', 'Add Class', this.showCreateResourceDialog)
                : <>
                    {
                        <>
                            {this.renderSearchBar(this.getSearchOptions(ontologyClasses), dataForView, readOnly)}
                            {this.getViewButtonGroup()}
                        </>
                    }
                    <div style={treeContainerStyle}>
                        {
                            this.isTreeView() ?
                            <NavTree
                                rootStyle={treeStyle}
                                data={sort(dataForView, 'title')}
                                expanded={expanded}
                                scrollTo={scrollTo}
                                focusedNode={params.classId}
                                onScrollEnd={() => this.setState({scrollTo: false})}
                                onNodeClick={this.viewObject}
                                renderTreeItem={this.renderTreeItem}
                                renderNode={($node, index) => {
                                    return this.getCustomLabelForClass($node, index, false);
                                }}
                                onNodeToggle={(ev, nodeIds) => {
                                    if (onTreeNodeToggle) {
                                        onTreeNodeToggle(nodeIds)
                                    }
                                    this.setState({expanded: nodeIds});
                                }}
                            />
                            : <NavList
                                rootStyle={treeStyle}
                                data={sort(dataForView, 'title')}
                                scrollTo={scrollTo}
                                focusedNode={params.classId}
                                onScrollEnd={() => this.setState({scrollTo: false})}
                                onNodeClick={this.viewObject}
                                renderTreeItem={this.renderTreeItem}
                                renderNode={($node, index) => {
                                    return this.getCustomLabelForClass($node, index, true);
                                }}
                            />
                        }
                    </div>
                </>
            }
        </>;
    }

    getAddSubClassButton = (classID, style = {}) => {
        let {theme} = this.props;
        return <Tooltip title={'Add Subclass'}>
            <MUIIconButton
                datatest={'addSubclass'}
                style={{
                    marginRight: '1px',
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.white.main,
                    ...style
                }}
                size={'small'}
                onClick={(e) => {
                    this.setState({
                        openCreateSubClassDialog: true,
                        parentClassID: classID
                    })
                    e && e.stopPropagation();
                }}
            >
                <AddPlainIcon/>
            </MUIIconButton>
        </Tooltip>;

    }

    getRemoveLinkButton = (theme, classID, parentClassID, style = {}) => {
        return <Tooltip title={'Remove Link From Superclass'}>
            <MUIIconButton
                datatest={'removeLinkFromSuperclass'}
                style={{
                    marginRight: '4px',
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.white.main,
                    ...style
                }}
                size={'small'}
                onClick={(e) => {
                    let {ontologyClasses} = this.state;
                    let classObject = ontologyClasses.find(c => c[ID] === classID);
                    this.removeParentClass(classObject, parentClassID);
                    e.stopPropagation();
                }}
            >
                <LinkOffIcon/>
            </MUIIconButton>
        </Tooltip>;
    }

    getCustomLabelForClass = ($node, index, isList) => {
        let {readOnly} = this.props;
        return <CustomAliasTreeLabel
            datatestOverride={'customAliasTreeLabel-'+$node.labelText}
            isList={isList}
            {...$node}
            renderLabel={() => this.renderNodeLabel($node, index)}
            renderActionsOnFocus={(nodeInner) => {
                if(readOnly) {
                    return <></>;
                }
                let parentClassID = nodeInner.node.parentClassID;
                const classID = nodeInner.id;
                return <>
                    {
                        parentClassID && this.getRemoveLinkButton(theme, classID, parentClassID)
                    }
                    {this.getAddSubClassButton(classID)}
                </>;
            }}
        />;

    }


    renderProperties = () => {
        const {location, readOnly} = this.props;
        const {ontologyProperties, expanded, scrollTo} = this.state;
        const params = queryString.parse(location.search);
        let treeStyle = {maxHeight: '100%'};
        let treeContainerStyle = {
            marginRight: '2px',
            height: readOnly ? 'calc(100% - 288px)' : 'calc(100% - 172px)',
            display: 'flex',
            flexDirection: 'column'
        };
        let dataForView = this.getDataForView();

        return <>
            {(!ontologyProperties || ontologyProperties.length < 1)
                ? readOnly
                    ? this.getNoItemsComponent('No property defined.')
                    : this.getNoItemsComponent('Add a property to get started', 'Add Property', () => this.setState({openAddNewPropertyDialog: true}))
                : <>
                    {this.renderSearchBar(this.getSearchOptions(ontologyProperties), dataForView, readOnly)}
                    <div style={treeContainerStyle}>
                        <NavList
                            rootStyle={treeStyle}
                            data={sort(dataForView, 'title')}
                            expanded={expanded}
                            scrollTo={scrollTo}
                            focusedNode={params.propId}
                            onScrollEnd={() => this.setState({scrollTo: false})}
                            onNodeClick={this.viewObject}
                            renderTreeItem={this.renderTreeItem}
                            renderNode={($node, index) => {
                                return <CustomAliasTreeLabel
                                    isList={true}
                                    {...$node}
                                    renderLabel={() => this.renderNodeLabel($node, index)}
                                    renderLabelPrefix={(nodeInner) => {
                                        return this.getTitlePrefix(nodeInner.id);
                                    }}
                                />;
                            }}
                        />
                    </div>
                </>
            }
        </>;
    }

    getNoItemsComponent = (message, buttonTitle, onAddButtonClick) => {
        return <div style={{paddingTop: '100px', textAlign: "center"}}>
            <div style={{marginBottom: theme.spacing(STYLE_GRID_ITEM_SPACING)}}>{message}</div>
            {
                buttonTitle &&
                <Button datatest={'noItemButton'} startIcon={<AddIcon/>}  variant="contained" color="secondary"
                    onClick={onAddButtonClick}>
                    {buttonTitle}
                </Button>
            }
        </div>;
    }

    isTreeView = () => {
        return this.state.viewType === TREE_VIEW;
    }

    renderSearchBar = (data, dataForSearch, readOnly) => {

        return <div style={{display: 'flex'}}>
            <div datatest={'searchBar'} style={{flexGrow: '1'}}>
                <AutoComplete
                    data={sort(data)}
                    id="treeview-search"
                    onChange={this.onSearch(dataForSearch)}
                    renderOption={(option, {selected}) => (
                        <React.Fragment>
                            <div style={{width : 'calc(100%)', textOverflow : 'ellipsis', overflow: 'hidden'}}>{option.title}</div>
                        </React.Fragment>
                    )}
                    renderInput={(params) => {
                        let {label, ...rest} = params;
                        return <TextField
                            placeholder={this.isPropertiesTabView() ? 'Find a property' : 'Find a class'}
                            fullWidth = {true}
                            {...rest}
                            variant = "outlined"
                        />;
                    }}
                    textFieldPaperStyle={{padding: '0px 10px 0px 4px'}}
                />
            </div>
            <div style={{margin : this.isDiagramView() ? '0px 0px 0px 12px' : '0px 8px', display : 'flex'}}>
            {
                readOnly
                    ? this.getMergedViewButton()
                    : this.isPropertiesTabView()
                        ? centerVertically(
                            <Tooltip title={"Add Property"}>
                                <MUIIconButton
                                    datatest={'addPropertyIconButton'}
                                    size={'small'}
                                    onClick={() => this.setState({openAddNewPropertyDialog: true})}>
                                    <AddIcon color={'primary'}/>
                                </MUIIconButton>
                            </Tooltip>
                        )
                        : <>{
                            centerVertically(
                                <Tooltip title={"Add Class"}>
                                    <MUIIconButton
                                        datatest={'addClassIconButton'}
                                        size={'small'}
                                        onClick={this.showCreateResourceDialog}>
                                        <AddIcon color={'primary'}/>
                                    </MUIIconButton>
                                </Tooltip>
                            )
                        }</>
            }

            </div>
        </div>;
    }

    getMergedViewButton = () => {
        let {viewMerged} = this.state;
        let {ontologyClasses, existingShapes, existingOntologyClasses, existingOntologyProperties} = this.props;
        let disabled = this.isClassTabView()
            ? isEmptyArray(existingOntologyClasses) || isEmptyArray(ontologyClasses)
            : isEmptyArray(existingOntologyProperties)|| isEmptyArray(existingOntologyProperties);

        return disabled ? <></> :centerVertically(
            <IconButton
                datatest={'viewMerged-'+ (viewMerged ? false : true) }
                tooltip={viewMerged ? "View Imported Only" : "View Imported and Existing"}
                selected={viewMerged}
                size={'small'}
                onClick={() => {
                    let {shapes, ontologyClasses, ontologyProperties} = this.props;

                    if(!viewMerged || viewMerged === false) {
                        let mergedShapes = [...toArray(shapes), ...toArray(existingShapes)]
                        let mergedOntologyClasses = [...toArray(ontologyClasses), ...toArray(existingOntologyClasses)]
                        let mergedOntologyProperties = [...toArray(ontologyProperties), ...toArray(existingOntologyProperties)]
                        this.setState({
                            viewMerged: true,
                            shapes : mergedShapes,
                            ontologyClasses : mergedOntologyClasses,
                            ontologyProperties : mergedOntologyProperties
                        })
                    } else {
                        this.setState({
                            viewMerged: false,
                            shapes,
                            ontologyClasses,
                            ontologyProperties
                        })
                    }
                }}>
                <MergeTypeIcon color={'primary'}/>
            </IconButton>
        );
    }

    updateModelDetails = (key) => (label, value) => {
        let {modelDetails} = this.state;
        modelDetails[key] = value;
        updateModifiedDate(modelDetails);
        updateModel(modelDetails);
        this.setState({modelDetails: modelDetails});
    }

    updateOntologyObjectDetails = (object, key) => (label, value) => {
        object[key] = value;
        if(isOwlClass(object)) {
            this.updateClass(object);
        } else {
            this.saveProperty(object);
        }
    }

    getHeaderButtons = () => {
        let {ontologyClasses} = this.state;
        let {readOnly} = this.props;

        return <div style={{display : 'flex', marginTop : '8px'}}>
            <div style={{flexGrow: '1'}}/>
            {
                readOnly
                    ? <div style={{height : '30px'}}/>
                    : <>
                        <IconButton
                            style={{marginRight: '6px'}}
                            datatest={'formViewButton'}
                            onClick={() => {
                                if(this.cytoscapeObj) {
                                    this.cytoscapeObj.destroy();
                                    let cy = document.getElementById('cy')
                                    cy.innerHTML = '';
                                }
                                this.setState({mainViewType: FORM_VIEW})
                            }}
                            selected={!this.isDiagramView()}
                        >
                            <Tooltip title={'Form View'}><ListAltIcon/></Tooltip>
                        </IconButton>
                        <IconButton
                            datatest={DATATEST_graphViewButton}
                            onClick={() => this.setState({mainViewType: GRAPH_VIEW})}
                            selected={this.isDiagramView()}
                        >
                            <Tooltip title={'Graph View'}><AccountTreeIcon /></Tooltip>
                        </IconButton>
                    </>
            }
        </div>;
    }

    getMiddleComponent = () => {
        let {location, readOnly} = this.props
        let params = queryString.parse(location.search)
        let { loading, resetID, ontologyClasses, ontologyProperties, modelDetails} = this.state
        let focusObject = undefined;
        let hasDataOnLeft = true;
        if(this.isPropertiesTabView()) {
            focusObject = searchInTree(params.propId, ontologyProperties);
            hasDataOnLeft = ontologyProperties === undefined || ontologyProperties.length < 1 ? false : true;
        } else if (this.isClassTabView()) {
            focusObject = searchInTree(params.classId, ontologyClasses);
            hasDataOnLeft = ontologyClasses === undefined || ontologyClasses.length < 1 ? false : true;
        } else {
            focusObject = modelDetails;
        }

        if (!modelDetails || loading) {
            return <ProcessingBackdrop marginLeft={true} loading={true}/>;
        }

        // if not found in tree then show collection details object
        if (!focusObject && hasDataOnLeft) {
            return <>
                {this.getHeaderButtons()}
                <GridContainer noSpacing={true}>
                    <Grid item xs={12}>
                        <InstructionForAction text={MESSAGE_SELECT_TO_VIEW_DETAILS}/>
                    </Grid>
                </GridContainer>
            </>;
        }
        // If no focus node and no data on left show nothing as left menu will have big add button
        // to get started
        if(!focusObject && hasDataOnLeft === false) {
            return <>{this.getHeaderButtons()}</>;
        }
        return <>
            {this.getHeaderButtons()}
            {this.withResetID(this.getRenderer(focusObject)(focusObject))}
        </>;
    }

    getRenderer = (focusObject) => {
        if (focusObject[TYPE] === ALIAS_SYS_TYPE_SCHEMA) {
            return this.renderModelDetailsEditor;
        } else if ( isOwlClass(focusObject)) {
            return this.renderShapeEditor;
        } else if (isPropertyClass(focusObject)) {
            return this.renderPropertyEditor;
        } else {
            return () => {};
        }
    }

    withResetID = (children) => {
        let {resetID} = this.state;
        return <React.Fragment key={resetID}>{children}</React.Fragment>
    }

    renderModelDetailsEditor = (modelDetails) => {
        let {readOnly} = this.props;
        return <>
            <div style={{marginBottom : '8px'}}>
                <EditableTextField
                    value={modelDetails[ALIAS_SYS_ID_LABEL]}
                    readOnly={readOnly}
                    label={LABEL_NAME}
                    onConfirm={this.updateModelDetails(ALIAS_SYS_ID_LABEL)}
                    validator={validateModelName}
                    maxLength={VALIDATION_LABEL_LENGTH}
                />
                <div style={{display: 'flex'}}>
                    <div style={{width: '196px', marginRight: '4px'}}>
                        <EditableTextField
                            value={modelDetails[ALIAS_SYS_BASE_IRI_PREFIX]}
                            readOnly={readOnly}
                            label={LABEL_PREFIX}
                            onConfirm={this.updateModelDetails(ALIAS_SYS_BASE_IRI_PREFIX)}
                            validator={validatePrefix}
                            maxLength={VALIDATION_PREFIX_MAX_LENGTH}
                        />
                    </div>
                    <div style={{flexGrow: '1'}}>
                        <EditableTextField
                            value={modelDetails[ALIAS_SYS_BASE_IRI]}
                            readOnly={readOnly}
                            label={LABEL_BASE_IRI}
                            onConfirm={this.updateModelDetails(ALIAS_SYS_BASE_IRI)}
                            validator={validateBaseIRI}
                            maxLength={VALIDATION_BASE_IRI_MAX_LENGTH}
                        />
                    </div>
                </div>
                <EditableTextArea
                    value={modelDetails[ALIAS_SYS_DESCRIPTION]}
                    readOnly={readOnly}
                    label={LABEL_DESCRIPTION}
                    onConfirm={this.updateModelDetails(ALIAS_SYS_DESCRIPTION)}
                    validator={validateDescription}
                    maxLength={VALIDATION_DESCRIPTION_LENGTH}
                />
                <EditableTextField
                    key={modelDetails[ALIAS_SYS_MODIFIED_DATE]}
                    value={modelDetails[ALIAS_SYS_MODIFIED_DATE]}
                    readOnly={true}
                    label={LABEL_UPDATE_DATE}
                    onConfirm={this.updateModelDetails(ALIAS_SYS_MODIFIED_DATE)}
                />
            </div>
        </>;
    }

    validateName = (currentValue) => (value) => {
        let {ontologyClasses, ontologyProperties} = this.state;
        let error = validateAlias(value, LABEL_NAME);
        if(!error) {
            error = validateNameDuplication(value, ontologyProperties, ontologyClasses);
            //if error is about same object ignore i.e. the new value is same as old
            if(error && currentValue === value) {
                error = '';
            }
        }
        return error;
    };

    handlePropertyTypeChange = (selectedProperty) => {
        let {modelDetails, ontologyProperties, shapes} = this.state;
        let selectedPropertyID = selectedProperty[ID];
        let propertyFromOntology = ontologyProperties.find(p => p[ID] === selectedPropertyID);
        let restOfPropertiesFromOntology = ontologyProperties.filter(p => p[ID] !== selectedPropertyID);
        let ontologyPropertiesToSet = [propertyFromOntology, ...restOfPropertiesFromOntology];

        this.updatePropertyType(propertyFromOntology, shapes, restOfPropertiesFromOntology);
        this.setState({ontologyProperties : ontologyPropertiesToSet, shapes : shapes});

        let modelFromStore = getModel(modelDetails[ID]);
        let modelDataFromStore = getModelData(modelFromStore);
        let ontologyPropertiesFromStore = filterOntologyProperties(modelDataFromStore);
        let propertyFromOntologyFromStore = ontologyPropertiesFromStore.find(p => p[ID] === selectedPropertyID);
        let restOfPropertiesFromOntologyFromStore = ontologyPropertiesFromStore.filter(p => p[ID] !== selectedPropertyID);
        let shapesDataFromStore = filterShapes(modelDataFromStore);
        let ontologyClassesFromStore = filterOntologyClasses(modelDataFromStore);

        let allDataForStore = [propertyFromOntologyFromStore, ...restOfPropertiesFromOntologyFromStore, ...shapesDataFromStore, ...ontologyClassesFromStore];

        this.updatePropertyType(propertyFromOntologyFromStore, shapesDataFromStore, restOfPropertiesFromOntologyFromStore);
        this.storeModelAndData(modelFromStore, allDataForStore);

        this.setState({typeChangeNewValue : undefined});
    }

    updatePropertyType = (property, shapes, ontologyProperties) => {
        const {typeChangeNewValue} = this.state;
        const selectedPropertyID = property[ID];

        let oldValue = property[ALIAS_SYS_PROPERTY_TYPE] && property[ALIAS_SYS_PROPERTY_TYPE].value;
        let newValue = typeChangeNewValue.value;

        let oldDataTypeIsString = isStringDatatype(oldValue);
        let newDataTypeIsString = isStringDatatype(newValue);

        // only object linking can have inverse else delete inverse
        if(isObjectProperty(property) && OBJECT_LINKING_PROPERTY !== newValue) {
            delete property[ALIAS_OWL_INVERSE_OF];
            let inversePropertyObject = findInversePropertyObject(selectedPropertyID, ontologyProperties);
            if(inversePropertyObject) {
                delete inversePropertyObject[ALIAS_OWL_INVERSE_OF];
            }
        }
        property[TYPE] = isObjectTypeValue(newValue)
            ? ALIAS_OWL_OBJECT_PROPERTY
            : ALIAS_OWL_DATATYPE_PROPERTY;

        property[ALIAS_SYS_PROPERTY_TYPE] = typeChangeNewValue;

        if (oldDataTypeIsString && newDataTypeIsString) {
            return;
        } else {
            shapes.forEach(sh => {
                let shapePropertyArray = getShapePropertyArray(sh);
                shapePropertyArray.forEach(p => {
                    if (p[ALIAS_SH_PATH] === selectedPropertyID) {
                        delete p[ALIAS_SH_CLASS];
                        delete p[ALIAS_SH_NODE_KIND];
                        delete p[ALIAS_SH_DATATYPE];
                        delete p[ALIAS_SH_OR];
                        delete p[ALIAS_SH_PROPERTY];
                        delete p[ALIAS_SH_PATTERN];
                        delete p[ALIAS_SH_MIN_LENGTH];
                        delete p[ALIAS_SH_MAX_LENGTH];
                        if(isBooleanDatatype(newValue) || TYPE_RDF_LIST === newValue) {
                            delete p[ALIAS_SH_MAX_COUNT];
                            if(isBooleanDatatype(newValue) && p[ALIAS_SH_MIN_COUNT]) {
                                p[ALIAS_SH_MIN_COUNT] = 1;
                            }
                        }
                        if(typeChangeNewValue.group === LABEL_NODE_KIND) {
                            p[ALIAS_SH_NODE_KIND] = newValue;
                        } else if(!isObjectTypeValue(newValue)) {
                            p[ALIAS_SH_DATATYPE] = newValue;
                        }
                    }
                })
            })
        }
    }

    handleDelete = (selectedObject) => {
        let {ontologyClasses, shapes} = this.state;
        return () => {
            let id = selectedObject[ID];
            let action =  isOwlClass(selectedObject)
                ? () => this.deleteClassObject(id)
                : () => this.deletePropertyObject(id);

            let warning =  '';
            if(isOwlClass(selectedObject)) {
                let allSubClasses = computeAllSubClasses(id, ontologyClasses)
                    .filter(c => id !== c);
                let shape = shapes.filter(s => s[ALIAS_SH_TARGET_CLASS] !== id).find(s => {
                    let propertyArray = getShapePropertyArray(s);
                    let found = propertyArray.find(p => {
                        let propertyClasses = new Set(getPropertyClasses(p));
                        if(propertyClasses.has(id)) {
                           return true;
                        } else {
                            return false;
                        }
                    });
                    return found ? true : false;
                });

                warning = <>
                    <div>Do you really want to delete this class?</div>
                    {
                        allSubClasses.length > 0 && <div style={{color: RED_COLOR , marginTop : '16px'}}>There are subclasses defined. This action will remove any relationships.</div>
                    }
                    {
                        shape && <div style={{color: RED_COLOR, marginTop : '16px'}}>Properties in other classes are referring to this class. This action will remove the references.</div>
                    }
                </>
            } else {
                warning = 'Do you really want to delete this property?'
            }
            this.setState({deleteWarning : warning , deleteOkAction : action});
        } ;
    }

    renderPropertyEditor = (selectedProperty, columnView) => {
        let {classes, theme, readOnly} = this.props;
        let {ontologyProperties, modelDetails, propertyViewTabValue, typeChangeNewValue} = this.state;
        let inverseProperty = findInversePropertyObject(selectedProperty[ID], ontologyProperties);
        let tabIndex = 0
        const MenuTab = this.getMenuTab(theme);

        let tabs = [
            <MenuTab datatest={'propertyAttributes'} key={'Attributes'} className={classes.tabItem}
                     label={'Attributes'}/>,
            <MenuTab datatest={'propertyClasses'} key={'Classes'} className={classes.tabItem}
                     label={'Classes'}/>,
            <MenuTab datatest={'schema'} key={'Schema'} className={classes.tabItem}
                     label={'Schema'}/>
        ]

        return <React.Fragment key={selectedProperty[ID]}>
            {
                typeChangeNewValue &&
                    <ConfirmAreYouSure
                        deleteWarning={"If this property is used in Classes any constraint which is not valid for the new type will be removed."}
                        handleNo={() => this.setState({typeChangeNewValue: undefined})}
                        handleYes={() => this.handlePropertyTypeChange(selectedProperty)}
                    />
            }
            <FieldContainer disableLeftRightPadding={true} style={{ backgroundColor: theme.palette.grey.background}}>
                <div>{this.renderNameAndDescription(selectedProperty)}</div>
                {
                    readOnly ||
                    <div style={{marginTop: theme.spacing(1)}}>
                        {
                            this.getTabs(propertyViewTabValue, tabs, this.handleDelete(selectedProperty), 'propertyViewTabValue')
                        }
                        <FieldContainer style={{
                            padding: '12px',
                            backgroundColor: theme.palette.white.main
                        }}>
                            {
                                propertyViewTabValue === 0 &&
                                <div>
                                    <FieldContainer>{
                                        getPropertyTypeSelect(
                                            classes,
                                            selectedProperty[ALIAS_SYS_PROPERTY_TYPE],
                                            (val) => {
                                                this.setState({typeChangeNewValue : val});
                                            },
                                            LABEL_PROPERTY_TYPE,
                                            (c) => c
                                        )
                                    }</FieldContainer>

                                    {
                                        isObjectLinkingTypeValue(selectedProperty[ALIAS_SYS_PROPERTY_TYPE] ? selectedProperty[ALIAS_SYS_PROPERTY_TYPE].value : null) &&
                                        <FieldContainer style={{marginTop : '12px'}}>{
                                            getInverseOfSelect(
                                                classes,
                                                ontologyProperties,
                                                inverseProperty ? inverseProperty[ID] : undefined,
                                                (val) => {
                                                    let modelDetailsFromStore = getModel(modelDetails[ID]);
                                                    let modelData = getModelData(modelDetails);
                                                    let ontologyPropertiesFromStore = filterOntologyProperties(modelData);
                                                    let ontologyPropertyFromStore = ontologyPropertiesFromStore.find(op => op[ID] === selectedProperty[ID]);
                                                    let inverseOntologyPropertyFromStore = inverseProperty && ontologyPropertiesFromStore.find(op => op[ID] === inverseProperty[ID]);

                                                    if (val) {
                                                        ontologyPropertyFromStore[ALIAS_OWL_INVERSE_OF] = val[ID];
                                                        selectedProperty[ALIAS_OWL_INVERSE_OF] = val[ID];
                                                        if (inverseProperty && inverseProperty[ALIAS_OWL_INVERSE_OF] !== val[ID]) {
                                                            delete inverseProperty[ALIAS_OWL_INVERSE_OF];
                                                        }
                                                        if (inverseOntologyPropertyFromStore && inverseOntologyPropertyFromStore[ALIAS_OWL_INVERSE_OF] && inverseOntologyPropertyFromStore[ALIAS_OWL_INVERSE_OF] !== val[ID]) {
                                                            delete inverseOntologyPropertyFromStore[ALIAS_OWL_INVERSE_OF];
                                                        }
                                                        this.storeModelAndData(modelDetailsFromStore, modelData);
                                                    } else {

                                                        delete selectedProperty[ALIAS_OWL_INVERSE_OF];
                                                        delete ontologyPropertyFromStore[ALIAS_OWL_INVERSE_OF];
                                                        if (inverseProperty[ALIAS_OWL_INVERSE_OF]) {
                                                            delete inverseProperty[ALIAS_OWL_INVERSE_OF];
                                                        }
                                                        if (inverseOntologyPropertyFromStore && inverseOntologyPropertyFromStore[ALIAS_OWL_INVERSE_OF]) {
                                                            delete inverseOntologyPropertyFromStore[ALIAS_OWL_INVERSE_OF];
                                                        }
                                                        this.storeModelAndData(modelDetailsFromStore, modelData);
                                                    }
                                                    this.setState({});
                                                },
                                                [selectedProperty[ID]],
                                                LABEL_INVERSE_OF,
                                                (content) => {
                                                    return <>{content}</>;
                                                }
                                            )
                                        }</FieldContainer>

                                    }
                                </div>
                            }
                            {
                                propertyViewTabValue === 1 &&
                                <div datatest={'Used by Classes'} style={{minHeight: 'calc(110px - 24px)'}}>
                                    <H3Title style={{fontWeight : '400'}}>Used by Classes</H3Title>
                                    {this.getPropertyUsedInClasses(selectedProperty)}
                                </div>
                            }
                            {
                                propertyViewTabValue === 2 && this.getJSONViewForProperty(selectedProperty)

                            }
                        </FieldContainer>
                    </div>
                }
            </FieldContainer>
        </React.Fragment>;
    }

    getJSONViewForProperty = (selectedProperty) => {
        let {ontologyProperties} = this.state;
        let ontologyProperty = ontologyProperties.find(op => op[ID] === selectedProperty[ID]);
        return this.renderJSONView(ontologyProperty);
    }


    getMenuTab = (theme) => {
        return withStyles({
            root: {
                textTransform: 'none',
            },
            selected: {
                color: theme.palette.secondary.main,
                backgroundColor: theme.palette.white.main,
                borderRadius: '4px 4px'
            }
        })(Tab);
    }

    deleteProperty = (classObject, selectedShape) => (p) => {
        let shapePropertyArray = getShapePropertyArray(selectedShape);
        selectedShape[ALIAS_SH_PROPERTY] = shapePropertyArray.filter(pr => pr[ID] !== p[ID]);
        this.saveResource(classObject, selectedShape, undefined, PROPERTY_DELETE);
    }

    getSuperClassPropsSwitch = (disabled = false) => {
        let {theme} = this.props;
        let {addSuperClassPropsInShape} = this.state;
        let switchOn = (addSuperClassPropsInShape && addSuperClassPropsInShape === true ? true : false) ;

        return  <FormControlLabel
            style={this.isDiagramView() ? {marginRight : '4px'} : {}}
            control={
                <Switch
                    datatest={'viewInheritedPropertiesSwitch'}
                    checked={switchOn}
                    value={true}
                    onChange={(e) => {
                        const {target: {checked}} = e
                        this.setState({addSuperClassPropsInShape:checked === true ? true: false})
                    }}
                    name="addSuperClassPropsInShape"
                />
            }
            label={<Typography style={{color : theme.palette.grey.level61, ...theme.typography.caption}} variant={'caption'} component={'span'}>Inherited Properties</Typography>}
        />;
    }

    getTabs = (tabValue, tabs, onDelete, tabValueKey) => {
        if(!this.isDiagramView()) {
            return <div datatest={'tabsBar'} style={{display: 'flex'}}>
                <Tabs style={{flexGrow: '1'}} value={tabValue} onChange={(event, newValue) => {
                    this.setState({[tabValueKey]: newValue})
                }} aria-label="tabs">
                    {tabs}
                </Tabs>
                {centerVertically(
                    <MUIIconButton
                        datatest={'deleteButton'}
                        size={'small'}
                        onClick={onDelete}
                    ><GreyDeleteIcon></GreyDeleteIcon></MUIIconButton>)}
            </div>;
        } else {
            return <div>
                <div style={{display: 'flex', marginBottom : '8px'}}>
                    <div style={{flexGrow : '1'}}></div>
                    {centerVertically(
                        <MUIIconButton
                            datatest={'deleteButton'}
                            size={'small'}
                            onClick={onDelete}
                        ><GreyDeleteIcon></GreyDeleteIcon></MUIIconButton>)}
                </div>
                <Tabs value={tabValue} onChange={(event, newValue) => {
                    this.setState({tabValue: newValue})
                }} aria-label="tabs">
                    {tabs}
                </Tabs>
            </div>;
        }

    }

    renderNameAndDescription = (object) => {
        let {readOnly} = this.props;
        return <>
            <EditableTextField
                value={object[ALIAS_SYS_ID_LABEL]}
                readOnly={readOnly}
                label={LABEL_NAME}
                onConfirm={this.updateOntologyObjectDetails(object, ALIAS_SYS_ID_LABEL)}
                validator={this.validateName(object[ALIAS_SYS_ID_LABEL])}
                maxLength={VALIDATION_LABEL_LENGTH}
            />
            <div style={{height: '4px'}}/>
            <EditableTextArea
                value={object[ALIAS_SYS_DESCRIPTION]}
                readOnly={readOnly}
                label={LABEL_DESCRIPTION}
                onConfirm={this.updateOntologyObjectDetails(object, ALIAS_SYS_DESCRIPTION)}
                validator={validateDescription}
                maxLength={VALIDATION_DESCRIPTION_LENGTH}
            />
        </>;
    }

    expandAll = (groupedProperties) => () => {
        let propKeys = Object.keys(groupedProperties);
        this.setState({expandedPropertyIds: propKeys.map(k => groupedProperties[k][0][ALIAS_SH_PATH])})
    }

    collapseAll = () => {
        this.setState({expandedPropertyIds: []});
    }

    getExpandCollapseAllButton = (groupedProperties) => {
        let {theme} = this.props;
        let colorStyle = {paddingRight: '0px'};
        let propKeys = Object.keys(groupedProperties);
        const EXPAND_ALL = 'Expand All';
        const COLLAPSE_ALL = 'Collapse All';
        const disabled = propKeys.length === 0;
        if(this.isDiagramView()) {
            return <div>
                <Tooltip title={EXPAND_ALL}>
                    <MUIIconButton
                        datatest={'expandAll'}
                        size={'small'}
                        onClick={this.expandAll(groupedProperties)}
                        disabled={disabled}
                    >
                        <UnfoldMoreIcon style={colorStyle}/>
                    </MUIIconButton>
                </Tooltip>
                <Tooltip title={COLLAPSE_ALL}>
                    <MUIIconButton
                        datatest={'collapseAll'}
                        size={'small'}
                        onClick={this.collapseAll}
                        disabled={disabled}
                    >
                        <UnfoldLessIcon style={colorStyle}/>
                    </MUIIconButton>
                </Tooltip>
            </div>
        }
        return <div>
            <BlockActionButton
                theme={theme}
                title={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>{EXPAND_ALL}</Typography>}
                onClick={this.expandAll(groupedProperties)}
                buttonProps= {{
                    datatest : 'expandAll',
                    size : 'medium',
                    style : {
                        marginLeft : '0px',
                    },
                    disabled : disabled,
                    startIcon : <UnfoldMoreIcon style={colorStyle}/>
                }}
            />
            <BlockActionButton
                theme={theme}
                title={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>{COLLAPSE_ALL}</Typography>}
                onClick={this.collapseAll}
                buttonProps={{
                    datatest : 'collapseAll',
                    size : 'medium',
                    style : {
                    },
                    disabled : disabled,
                    startIcon : <UnfoldLessIcon style={colorStyle}/>
                }}
            />
        </div>;
    }

    toggleExpanded = (ev, expanded, id) => {
        let {expandedPropertyIds} = this.state
        if(expanded === true) {
            expandedPropertyIds.push(id)
            this.setState({expandedPropertyIds})
        } else {
            this.setState({expandedPropertyIds: expandedPropertyIds.filter(i => i !== id)})
        }
    }

    renderShapeEditor = (selectedClass, columnView) => {
        let {classes, theme, readOnly} = this.props;
        let {minimized, tabValue, expandedPropertyIds, scrollToEnd, newPropertyID, shapes, ontologyClasses, ontologyProperties, addSuperClassPropsInShape} = this.state;
        let selectedShape = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === selectedClass[ID])
        let classObject = ontologyClasses.find(o => o[ID] === selectedClass[ID]);

        let tabIndex = 0
        const MenuTab = this.getMenuTab(theme);

        let tabs = [
            <MenuTab datatest={'classProperties'} key={'Properties'} className={classes.tabItem}
                     label={'Properties'}/>,
            <MenuTab datatest={'classHierarchy'} key={'Hierarchy'} className={classes.tabItem}
                     label={'Hierarchy'}/>,
            <MenuTab datatest={'schema'} key={'Schema'} className={classes.tabItem}
                     label={'Schema'}/>
        ];

        let shapeProperties = getShapePropertyArray(selectedShape)
        let shapePropertiesFromSuperClasses = computeShapePropertiesWithSuperClasses(selectedShape, shapes, ontologyClasses, true);
        let shapePropertyArray = addSuperClassPropsInShape
            ? [...shapeProperties, ...shapePropertiesFromSuperClasses]
            : shapeProperties;
        let aliasesMap = createAliasesMap(ontologyProperties);
        let sortedShapePropertyArray = sortShapeProperties(shapePropertyArray, aliasesMap);
        let groupedProperties = {}
        sortedShapePropertyArray.forEach(p => {
            if(!groupedProperties[p[ALIAS_SH_PATH]]) {
                groupedProperties[p[ALIAS_SH_PATH]] = [];
            }
            groupedProperties[p[ALIAS_SH_PATH]].push(p);
        })

        let hasPropertiesFromSuperClass = shapePropertiesFromSuperClasses && shapePropertiesFromSuperClasses.length > 0;
        let hasSuperClass = toArray(classObject[ALIAS_RDFS_SUBCLASS_OF]).length > 0 ? true : false;
        return <React.Fragment key={selectedShape[ALIAS_SH_TARGET_CLASS]}>
            <FieldContainer disableLeftRightPadding={true} style={{paddingTop : '0px', backgroundColor : theme.palette.grey.background}}>
                <div>{this.renderNameAndDescription(classObject)}</div>
                {   readOnly ||
                    <div style={{marginTop: theme.spacing(1)}}>
                        {
                            this.getTabs(tabValue, tabs, this.handleDelete(selectedClass), 'tabValue')
                        }
                        <div style={{
                            paddingLeft : columnView && tabValue === 1 ? '0px': '12px',
                            paddingRight : columnView && tabValue === 1 ? '0px': '12px',
                            paddingTop : '0px',
                            paddingBottom : '0px',
                            backgroundColor: theme.palette.white.main,
                        }}>
                            {
                                tabValue === 0 &&
                                <>
                                    <div style={{padding: '4px 0px'}}>
                                        <div style={{display: 'flex'}}>
                                            {hasSuperClass && this.getSuperClassPropsSwitch(!hasPropertiesFromSuperClass)}
                                            <div style={{flexGrow: '1'}}/>
                                            {centerVertically(this.getExpandCollapseAllButton(groupedProperties))}
                                        </div>
                                    </div>
                                    {
                                        Object.keys(groupedProperties).map((k) => {
                                            let propertyGroup = groupedProperties[k];
                                            let p = propertyGroup[0];
                                            let expanded = expandedPropertyIds.includes(p[ALIAS_SH_PATH])
                                            let propertyPath = p[ALIAS_SH_PATH];
                                            return <div key={p[ID]} id={p[ID]} datatest={'propertyBlock'}>{
                                                <Collapse
                                                    addEndListener={() => {
                                                        let {expandedPropertyIds} = this.state;
                                                        this.setState({scrollToEnd : undefined, newPropertyID : undefined});
                                                        if(!expandedPropertyIds.includes(propertyPath)) {
                                                            this.toggleExpanded(undefined, true, propertyPath);
                                                        }
                                                    }}
                                                    timeout={300}
                                                    in={p[ID] !== newPropertyID ? true : p[ID] === newPropertyID && scrollToEnd === true}
                                                >
                                                    <Property
                                                        expanded={expanded}
                                                        onExpand={this.toggleExpanded}
                                                        columnView={columnView || minimized === false}
                                                        key={propertyPath}
                                                        classObject={classObject}
                                                        shapes={shapes}
                                                        ontologyClasses={ontologyClasses}
                                                        ontologyProperties={ontologyProperties}
                                                        propertyGroup={propertyGroup}
                                                        onNameClick={(id) => this.expandAndNavigateTo(id)}
                                                        onDelete={(p) => {
                                                            let action  = () => this.deleteProperty(classObject, selectedShape)(p);
                                                            this.setState({
                                                                deleteWarning : 'Do you really want to delete this property from this class?',
                                                                deleteOkAction : action
                                                            })
                                                        }}
                                                        onChange={(p, key, val) => {
                                                            this.updateClass(classObject, selectedShape);
                                                        }}
                                                    />
                                                </Collapse>
                                            }</div>;
                                        })
                                    }
                                    <div style={{display: 'flex', padding : '8px 0px 20px'}}>
                                        <div style={{flexGrow: '1'}}></div>
                                        <Button
                                            datatest={'addPropertyToClass'}
                                            onClick={() => {
                                                this.setState({
                                                    openAddPropertyDialog: true,
                                                    parentClassID: classObject[ID]
                                                });
                                            }}
                                            startIcon={<AddIcon/>}
                                            color={'secondary'}
                                            variant={'contained'}
                                        >Add</Button>
                                    </div>
                                </>
                            }
                            {
                                tabValue === 1 && this.renderClassHierarchy(classObject, columnView)
                            }
                            { tabValue === 2 && this.getJSONView(classObject, selectedShape, shapePropertyArray, hasSuperClass, hasPropertiesFromSuperClass)}
                        </div>
                    </div>
                }
            </FieldContainer>
            <div style={{height: '64px'}}></div>
        </React.Fragment>;
    }

    getJSONView = (classObject, shapeObj, shapePropertyArray, hasSuperClass, hasPropertiesFromSuperClass) => {
        let {classes} = this.props;
        let {classViewRawTabValue}= this.state;
        let selectedTabValue = classViewRawTabValue === undefined ? 0 : classViewRawTabValue;
        let objectForView;
        if(selectedTabValue === 1) {
            objectForView = cloneDeepWith(shapeObj);
            objectForView[ALIAS_SH_PROPERTY] = shapePropertyArray;
        } else {
            objectForView = classObject
        }

        const MenuTab = this.getMenuTab(theme);

        return <div style={{padding: '16px 0px'}}>
            <Tabs style={{padding: '4px 0px'}} value={selectedTabValue} onChange={(event, newValue) => {
                this.setState({classViewRawTabValue: newValue})
            }} aria-label="tabs">
                <MenuTab datatest={'classProperties'} key={'OWL Class'} className={classes.tabItem}
                         label={'OWL Class'}/>,
                <MenuTab datatest={'classHierarchy'} key={'SHACL Shape'} className={classes.tabItem}
                         label={'SHACL Shape'}/>,
            </Tabs>
            {
                selectedTabValue === 0
                    ? <>
                        {this.renderJSONView(objectForView)}
                    </>
                   :
                    <>
                        {
                            hasSuperClass &&
                            <div style={{padding: '4px 0px'}}>
                                {this.getSuperClassPropsSwitch(!hasPropertiesFromSuperClass)}
                            </div>
                        }
                        {this.renderJSONView(objectForView)}
                    </>
            }
        </div>;
    }


    renderJSONView = (objectForView) => {
        const modes = ['code'];
        let json = JSON.stringify(objectForView, null, 4);

        return <JSONEditorReact
            modes={modes}
            enableSort={false}
            text={json}
            indentation={4}
            history={false}
            height={'100%'}
            expandAll={true}
            search={false}
            onEditable={() => {
                return false;
            }}
        />;
    }

    renderClassHierarchy = (classObject, columnView) => {
        let {ontologyClasses} = this.state;
        let {theme} = this.props;
        let directSuperClasses = this.getSubclassIDs(classObject)
            .map(c => this.findClassObject(c, ontologyClasses))
            .filter(c => c);
        let directSubClasses = ontologyClasses.filter(o => this.getSubclassIDs(o).includes(classObject[ID]));
        let wrapperFunction = (label, style) => (content) => {
            let wrapperStyle = {
                width: columnView ? 'calc(100% - 24px)' :'50%',
                padding : `12px ${columnView ? 12 : 0}px 4px`,
                ...style
            }
            if(columnView) {
                wrapperStyle.minHeight = '55px';
            }
            return <div style={wrapperStyle}>
                <div datatest={label} style={{height: 'calc(100% - 24px)'}}>
                    <H3Title style={{fontWeight : '400'}}>{label}</H3Title>
                    {content}
                </div>
            </div>;
        }
        let containerStyle = columnView ? {flexDirection : 'column'} : {minHeight: '110px'}
        return <div style={{ display:'flex', ...containerStyle}}>
            {this.getHierarchyClasses(classObject, computeAllSuperClasses, directSuperClasses, this.removeParentClass, wrapperFunction(LABEL_SUPERCLASSES))}
            {<div style={{height : columnView ? '1px' : 'inherit', width: columnView ? '100%' : '1px', backgroundColor : theme.palette.grey.levelE0}}/>}
            {this.getHierarchyClasses(classObject, computeAllSubClasses, directSubClasses, undefined, wrapperFunction(LABEL_SUBCLASSES, columnView ? {paddingBottom : '4px', paddingTop : '12px'} : {paddingLeft : '12px'}))}
        </div>;

    }

    removeParentClass = (classObject, parentClassID) => {
        let {shapes} = this.state;
        classObject[ALIAS_RDFS_SUBCLASS_OF] = this.getSubclassIDs(classObject).filter(co => co !== parentClassID);
        this.saveResource(
            classObject,
            this.findShapeObject(classObject[ID], shapes),
            () => {
                let {shapes, ontologyClasses} = this.state;
                if(this.isDiagramView()) {
                    this.updateDiagram(shapes, ontologyClasses);
                    this.refreshDiagramLayout({
                        stop : this.adjustMinZoom
                    })
                }
            }
        );
    }

    findClassObject = (classID, ontologyClasses) => {
        return ontologyClasses.find(oc => oc[ID] === classID);
    }

    findShapeObject = (classID, shapes) => {
        return shapes.find(oc => oc[ALIAS_SH_TARGET_CLASS] === classID);
    }

    getClassesBlockTitle = (title) => {
        let {theme} = this.props;
        return <H4Title style={{color: theme.palette.grey.level61, fontWeight : '400', padding: '8px 0px'}}>{title}</H4Title>;
    }

    getHierarchyClasses = (classObject, classFunction, directClasses, onRemove, wrapperProvider) => {
        let {theme} = this.props;
        let {ontologyClasses} = this.state;
        let directClassIDs = directClasses.map(c => c[ID]);
        let otherSuperClassesIDs = classFunction(classObject[ID], ontologyClasses)
            .filter(c => !directClassIDs.includes(c));
        let otherSuperClasses = otherSuperClassesIDs
            .map(c => {
                let found = this.findClassObject(c, ontologyClasses);
                return found;
            })
            // class may not be found if external ontology is imported so filter those
            .filter(c => c);
        let content = <div datatest={'classHierarchy'}>
            {   directClasses && directClasses.length > 0 &&
                <div datatest={'direct'}>
                    {this.getClassesBlockTitle('Direct')}
                    {
                        sort(directClasses, ALIAS_SYS_ID_LABEL).map(c => {
                            let title = c[ALIAS_SYS_ID_LABEL];
                            let parentClassID = c[ID];
                            return <div key={parentClassID} style={{marginBottom: '8px'}}>
                                <NavigationChip
                                    datatest={title}
                                    icon={chipIcon}
                                    onClick={() => this.expandAndNavigateTo(parentClassID)}
                                    label={title}
                                    onDelete={(onRemove ? (ev) => {
                                        onRemove && onRemove(classObject, parentClassID);
                                    } : undefined)}
                                />
                            </div>;
                        })
                    }
                </div>
            }
            {
                otherSuperClasses && otherSuperClasses.length > 0 &&
                <div datatest={'throughHierarchy'}>
                    {this.getClassesBlockTitle('Through Hierarchy')}
                    {
                        sort(otherSuperClasses, ALIAS_SYS_ID_LABEL).map(c => {
                            let title = c[ALIAS_SYS_ID_LABEL];
                            return <div key={c[ID]} style={{marginBottom: '8px'}}>
                                <NavigationChip
                                    datatest={title}
                                    label={title}
                                    icon={chipIcon}
                                    onClick={() => this.expandAndNavigateTo(c[ID])}
                                />
                            </div>;
                        })
                    }
                </div>
            }
        </div>;
        return wrapperProvider(content);
    }

    getPropertyUsedInClasses = (propertyObject, onRemove) => {
        let {theme} = this.props;
        let {ontologyClasses, shapes} = this.state;
        let usedInClassess = shapes.filter(s => {
            let property = getShapePropertyArray(s).find(p => p[ALIAS_SH_PATH] === propertyObject[ID]);
            if(property) {
                return true;
            } else {
                return false;
            }
        }).map(s => s[ALIAS_SH_TARGET_CLASS]);

        let directClasses = usedInClassess.map(c => this.findClassObject(c, ontologyClasses));

        let otherSubClasses = (flatten(usedInClassess.map(c => {
            let subclasses = computeAllSubClasses(c, ontologyClasses).filter(c => !usedInClassess.includes(c));
            return subclasses;
        }))).filter(distinct).map(c => this.findClassObject(c, ontologyClasses));
        return <div datatest={'propertyClasses'}>
            {
                directClasses && directClasses.length > 0 &&
                <div datatest={'direct'}>
                    {this.getClassesBlockTitle('Direct')}
                    {
                        sort(directClasses, ALIAS_SYS_ID_LABEL).map(c => {
                            let title = c[ALIAS_SYS_ID_LABEL];
                            let parentClassID = c[ID];
                            return <div key={parentClassID} style={{marginBottom: '8px'}}>
                                <NavigationChip
                                    datatest={title}
                                    icon={chipIcon}
                                    onClick={() => {
                                        this.expandAndNavigateTo(parentClassID)
                                    }}
                                    label={title}
                                />
                            </div>;
                        })
                    }
                </div>
            }
            {
                otherSubClasses && otherSubClasses.length > 0 &&
                <div datatest={'throughHierarchy'}>
                    {this.getClassesBlockTitle('Through Hierarchy')}
                    {
                        sort(otherSubClasses, ALIAS_SYS_ID_LABEL).map(c => {
                            let title = c[ALIAS_SYS_ID_LABEL];
                            return <div key={c[ID]} style={{marginBottom: '8px'}}>
                                <NavigationChip
                                    datatest={title}
                                    label={title}
                                    icon={chipIcon}
                                    onClick={() => this.expandAndNavigateTo(c[ID])}
                                />
                            </div>;
                        })
                    }
                </div>
            }
        </div>;
    }

    showCreateResourceDialog = () => {
        this.setState({showCreateResourceDialog: true})
    }

    handleCreateProperty = (object, parentClassID, multiple) => {
        let {shapes, ontologyProperties, modelDetails} = this.state;
        let shapeObject = this.findShapeObject(parentClassID, shapes);
        let model = getModel(modelDetails[ID]);
        let modelData = getModelData(modelDetails);
        let propertyID;
        let propertyObject;
        // if new then create property for ontology else find existing
        if(object.addMethod === NEW_PROPERTY) {
            propertyObject = createPropertyObject(object, modelDetails);
            propertyID = propertyObject[ID];
            let updatedOntologyProperties = [...ontologyProperties, propertyObject];
            this.setState({ontologyProperties: updatedOntologyProperties}, () => {
                if(this.isPropertiesTabView()) {
                    this.onSearch(updatedOntologyProperties)(undefined, propertyObject)
                }
            });
        } else {
            propertyID = object.existingProperty.id;
            propertyObject = ontologyProperties.find(op => op[ID] === propertyID);
        }
        let shapeProperty;
        // if parent class then attach to shape of class
        if(parentClassID) {
            let properties = shapeObject[ALIAS_SH_PROPERTY] || [];
            shapeProperty = {
                [ID]: "_:" + uuid4(),
                fromShape: shapeObject[ID],
                [ALIAS_SH_PATH]: propertyID
            };
            if(isObjectProperty(propertyObject)) {
                shapeProperty[ALIAS_SH_NODE_KIND] = SH_IRI;
            } else if (propertyObject[ALIAS_SYS_PROPERTY_TYPE].group === LABEL_NODE_KIND) {
                shapeProperty[ALIAS_SH_NODE_KIND] = propertyObject[ALIAS_SYS_PROPERTY_TYPE].value;
            } else if (isDatatypeProperty(propertyObject)) {
                shapeProperty[ALIAS_SH_DATATYPE] = propertyObject[ALIAS_SYS_PROPERTY_TYPE].value;
            }
            shapeObject[ALIAS_SH_PROPERTY] = [...properties, shapeProperty];
        }
        //Add updated shape
        let allData = modelData.filter(d => {
            if(shapeObject && shapeObject[ID] === d[ID]) {
                return false;
            } else {
                return true;
            }
        });
        if(shapeObject) {
            allData.push(shapeObject);
        }
        //Add new property
        if(object.addMethod === NEW_PROPERTY && propertyObject) {
            allData.push(propertyObject);
        }
        this.storeModelAndData(model, allData);
        if(!multiple) {
            this.setState({openAddNewPropertyDialog: false, openAddPropertyDialog : false});
        }
        this.setState({shapes: shapes, newPropertyID : shapeProperty && shapeProperty[ID]}, () => shapeProperty && this.scrollToNode(shapeProperty[ID]));
    }

    scrollToNode = (focusedNodeId) => {
        const node = document.getElementById(focusedNodeId);
        if (node) {
            setTimeout(() => {
                scrollToView(node, 'auto');
                setTimeout(() => {this.setState({scrollToEnd: true})}, 200);
            }, 100)
        }
    }


    handleCreateSubClass = (nameTitleObject, parentClassID, multiple) => {
        let subclassID = nameTitleObject[ID];
        //Existing class
        if(subclassID) {
            let {shapes, ontologyClasses} = this.state;
            let subclass = ontologyClasses.find(c => c[ID] === subclassID);
            let superClassess = this.getSubclassIDs(subclass);
            subclass[ALIAS_RDFS_SUBCLASS_OF] = [...superClassess, parentClassID];
            let shapeObject = this.findShapeObject(subclassID, shapes);
            this.saveResource(subclass, shapeObject, () => {
                let {shapes, ontologyClasses, expanded} = this.state
                if (!expanded.includes(parentClassID)) {
                    expanded.push(parentClassID)
                }
                this.setState({
                    expanded: expanded
                })
                if(multiple !== true) {
                    this.setState({
                        showCreateResourceDialog: false,
                        openCreateSubClassDialog : false
                    });
                }
                this.updateDiagram(shapes, ontologyClasses, {reason: ADD_SUBCLASS_EXISTING});
                this.refreshDiagramLayout();

            }, ADD_SUBCLASS_EXISTING);
        } else {
            // new class
            this.handleCreateResource(nameTitleObject, parentClassID, multiple);
        }
    }

    handleCreateResource = (nameTitleObject, parentClassID, multiple) => {
        let {modelDetails} = this.state;
        let classObject = createClassObject(nameTitleObject, modelDetails, parentClassID);
        let shapeObject = createShapeObject(modelDetails, classObject);

        return this.saveResource(
            classObject,
            shapeObject,
            () => {
                let {expanded, shapes, ontologyClasses} = this.state
                if (parentClassID && !expanded.includes(parentClassID)) {
                    expanded.push(parentClassID)
                }
                this.setState({
                    expanded: expanded,
                    focusNode: classObject,

                });
                if(multiple !== true) {
                    this.setState({
                        showCreateResourceDialog: false,
                        openCreateSubClassDialog : false
                    });
                }
                this.updateDiagram(shapes, ontologyClasses, {reason: parentClassID ? ADD_NEW_SUBCLASS : CREATE_NEW_CLASS});
                let center = this.isRightContainerUndefined() ? true : false;
                this.onSearch(ontologyClasses, center)(undefined, classObject);
                this.refreshDiagramLayout({
                    stop: this.adjustMinZoom
                });
            },
            parentClassID ? ADD_NEW_SUBCLASS : CREATE_NEW_CLASS
        );
    };

    isRightContainerUndefined = () => {
        let {showRightContainer} = this.state;
        return showRightContainer === undefined;
    }

    handleImportModel = (newModelDetails, allData) => {
        let {modelDetails, shapes, ontologyClasses, ontologyProperties} = this.state;
        let newShapes = filterShapes(allData);
        let newOntologyClasses = filterOntologyClasses(allData);
        let newOntologyProperties = filterOntologyProperties(allData);

        let newAllData = [...allData, ...shapes, ...ontologyClasses, ...ontologyProperties];
        this.storeModelAndData(modelDetails, newAllData);
        this.setState({
            shapes: [...shapes, ...newShapes],
            ontologyClasses : [...ontologyClasses, ...newOntologyClasses],
            ontologyProperties : [...ontologyProperties, ...newOntologyProperties]
        });
    };

    updateClass = (classObject) => {
        let {shapes} = this.state;
        let shapeObject = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === classObject[ID]);
        return this.saveResource(
            classObject,
            shapeObject,
            () => {
                let {shapes, ontologyClasses} = this.state;
                this.updateDiagram(shapes, ontologyClasses);
            }
        );
    }

    saveProperty = (propertyObject) => {
        let {modelDetails} = this.state;
        let modelDetailsToSave = getModel(modelDetails[ID]);
        let modelData = getModelData(modelDetails);
        let ontology = filterOntology(modelData);
        let shapesFromStore = filterShapes(modelData);

        let ontologyToSave = [...ontology.filter(o => o[ID] !== propertyObject[ID]), propertyObject];

        this.setState({
            ontologyProperties: filterOntologyProperties(ontologyToSave)
        })

        this.saveData(shapesFromStore, ontologyToSave, modelDetailsToSave, undefined, undefined, undefined);
    }

    saveResource = (classObject, shapeObject, callBack) => {
        let {modelDetails, ontologyClasses, shapes} = this.state;
        let modelDetailsToSave = getModel(modelDetails[ID]);
        let modelData = getModelData(modelDetails);
        let ontology = filterOntology(modelData);
        let shapesFromStore = filterShapes(modelData);

        let shapesToSave = [...shapesFromStore.filter(s => s[ID] !== shapeObject[ID]), shapeObject];
        let classID = classObject[ID];
        let ontologyToSave = [...ontology.filter(c => c[ID] !== classID), classObject];

        let ontologyClassessToSet = [...ontologyClasses.filter(o => o[ID] !== classID), classObject]
        let shapesToSet = [...shapes.filter(o => o[ALIAS_SH_TARGET_CLASS] !== classID), shapeObject]
        this.setState({
            ontologyClasses: ontologyClassessToSet,
            shapes: shapesToSet
        })
        this.saveData(shapesToSave, ontologyToSave, modelDetailsToSave, callBack, shapeObject, classObject);
    }

    saveData = (shapesToSave, ontologyClassessToSave, modelDetails, callBack, shapeObject, classObject) => {
        let allData = [...shapesToSave, ...ontologyClassessToSave];
        this.storeModelAndData(modelDetails, allData);
        this.setState({}, () => {
            callBack && callBack();
        })
    }


    storeModelAndData = (modelDetails, allData) => {
        updateModifiedDate(modelDetails);
        storeModelAndData(modelDetails, allData);
    }

    viewObject = (node) => {
        let nodeToView = {
        }
        if(this.isPropertiesTabView()) {
            nodeToView.propId = node.id;
        } else {
            nodeToView.classId = node.id;
        }
        nodeToView.leftMenuTabValue = this.isPropertiesTabView() ? PROPERTIES_VIEW : CLASS_VIEW ;
        this.navigateTo({node :nodeToView});
    }

    expandAndNavigateTo = (objID) => {
        let {ontologyClasses, ontologyProperties, expanded} = this.state;
        let classObject =  ontologyClasses.find(oc => oc[ID] === objID);
        let propertyObject =  ontologyProperties.find(oc => oc[ID] === objID);
        let node = undefined;
        if(classObject && this.isDiagramView()) {
            this.onSearch(ontologyClasses)(undefined, classObject);
        }
        if(classObject) {
            let superClasses = computeAllSuperClasses(objID, ontologyClasses);
            superClasses.forEach(c => {
                if(!expanded.includes(c)) {
                    expanded.push(c);
                }
            })
            node = {node: {classId: objID , leftMenuTabValue : CLASS_VIEW}};
            if(superClasses) {
                this.setState({expanded: expanded}, () => this.navigateTo(node))
            } else {
                this.navigateTo(node);
            }
        } else if (propertyObject) {
            node = {node: {propId: objID, leftMenuTabValue : PROPERTIES_VIEW}};
            this.setState({mainViewType: FORM_VIEW}, () => this.navigateTo(node));
        } else {
            this.navigateTo(node);
        }
    }

    navigateTo = ({node}) => {
        let {modelDetails, expanded} = this.state;
        let params = {};
        //set defaults and if clauses will override
        this.setFromRequestParams('classId', params);
        this.setFromRequestParams('propId', params);
        this.setFromRequestParams('leftMenuTabValue', params);

        if(node.classId) {
            params.classId = node.classId;
        }
        if(node.propId) {
            params.propId = node.propId;
        }
        if(node.leftMenuTabValue !== undefined) {
            params.leftMenuTabValue = node.leftMenuTabValue;
        }
        params.schemaId = node.schemaId || modelDetails[ID];
        if(expanded && expanded.length > 0) {
            params.expanded = expanded.join(',');
        }
        this.pushToHistory(params);
    }

    pushToHistory = (params) => {
        let {location} = this.props;
        history.push(`${location.pathname}?${qs.stringify(params)}`);
    }

    setFromRequestParams = (key, node) => {
        let {location} = this.props;
        let params = queryString.parse(location.search)
        if(params[key]) {
            node[key] = params[key];
        }
    }

    exportModel = () => {
        let {modelDetails} = this.state
        // Get data from store as export is for saved changes only
        let allObjects = [getModel(modelDetails[ID]), ...getModelData(modelDetails)]
        let name = modelDetails[ALIAS_SYS_ID_LABEL]

        function replacer(key,value) {
            if(['nameError', 'descriptionError', 'dirty'].includes(key) || key && key.endsWith && key.endsWith(ERROR_KEY_SUFFIX)) {
                return undefined;
            } else {
                return value;
            }
        }

        fileDownload(JSON.stringify(allObjects, replacer, 4), name + '_' + new Date().toISOString() + ".json")
    }

    getSubclassIDs = (classObject) => {
        let subClasses = classObject[ALIAS_RDFS_SUBCLASS_OF];
        if(subClasses) {
            return subClasses.filter(c => isString(c)).map(c => c);
        } else {
            return [];
        }
    }

    deleteClassObject = (classID) => {
        let {focusNode, modelDetails, shapes, ontologyClasses, ontologyProperties} = this.state;
        let classObject = searchInTree(classID, ontologyClasses);
        let shape = shapes.find(o => o[ALIAS_SH_TARGET_CLASS] === classID);
        let shapeID = shape[ID];
        let allSubClasses = classObject
            ? computeAllSubClasses(classID, ontologyClasses)
            : [];
        //remove reference from all the sub classes
        allSubClasses.forEach(c => {
            let classFound = ontologyClasses.find(o => o[ID] === c);
            let remaining = this.getSubclassIDs(classFound).filter(c => c !== classID);
            if(remaining.length > 0) {
                classFound[ALIAS_RDFS_SUBCLASS_OF] = remaining;
            } else {
                delete classFound[ALIAS_RDFS_SUBCLASS_OF];
            }
        })
        let updatedShapes = shapes.filter(s => s[ID] !== shapeID);
        let updatedOntologyClasses = ontologyClasses.filter(o =>o[ID] !== classID);
        let updatedOntologyData = [...updatedOntologyClasses, ...ontologyProperties];
        //remove reference to class from properties
        shapes.forEach(s => {
            let propertyArray = getShapePropertyArray(s);
            propertyArray.forEach(p => {
                let propertyClasses = new Set(getPropertyClasses(p));
                if(propertyClasses.has(classID)) {
                    let remaining = [...propertyClasses].filter(c => c !== classID).map(c => {
                        return {value : c};
                    });
                    let isList = p[ALIAS_SH_CLASS] === TYPE_RDF_LIST;
                    updatePropertyClass(p, remaining, isList);
                }
            })
        })

        this.storeModelAndData(modelDetails, [...updatedShapes, ...updatedOntologyData]);
        if (focusNode && focusNode.id === classID) {
            let {expanded} = this.state;
            let expandedRemaining = expanded.filter(e => e !== classID);
            this.setState({focusNode: undefined, expanded : expandedRemaining});
        }
        this.setState({shapes : updatedShapes, ontologyClasses : updatedOntologyClasses});

        if(this.isDiagramView()) {
            this.updateDiagram(updatedShapes, updatedOntologyClasses);
        }
    }

    deletePropertyFromShapes = (shapes, propertyID) => {
        shapes.forEach(s => {
            s[ALIAS_SH_PROPERTY] = getShapePropertyArray(s).filter(p => p[ALIAS_SH_PATH] !== propertyID);
        })
    }

    deletePropertyObject = (propertyID) => {
        let {modelDetails, shapes, ontologyProperties} = this.state;

        let modelDetailsToSave = getModel(modelDetails[ID]);
        let modelData = getModelData(modelDetails);
        let ontologyClassesToSave = filterOntologyClasses(modelData);
        let ontologyPropertiesFromStore = filterOntologyProperties(modelData);
        let ontologyPropertiesToSave = ontologyPropertiesFromStore.filter(d => d[ID] !== propertyID);
        let ontologyToSave = [...ontologyClassesToSave, ...ontologyPropertiesToSave];
        let shapesToSave = filterShapes(modelData);

        this.deletePropertyFromShapes(shapesToSave, propertyID);
        let inverseFromStore = ontologyPropertiesFromStore.find(op => op[ALIAS_OWL_INVERSE_OF] === propertyID);
        if(inverseFromStore) {
            delete inverseFromStore[ALIAS_OWL_INVERSE_OF];
        }
        this.saveData(shapesToSave, ontologyToSave, modelDetailsToSave, undefined, undefined, undefined);

        let ontologyPropertiesToSet = ontologyProperties.filter(d => d[ID] !== propertyID);
        this.deletePropertyFromShapes(shapes, propertyID);
        let inverseFromState = ontologyPropertiesToSet.find(op => op[ALIAS_OWL_INVERSE_OF] === propertyID);
        if(inverseFromState) {
            delete inverseFromState[ALIAS_OWL_INVERSE_OF];
        }
        this.setState({shapes : shapes, ontologyProperties: ontologyPropertiesToSet});
    }

    getMenuOptionsForClassObject = (requestObject) => {
        let requestObjectID = requestObject[ID]
        return [
            {
                label: 'Delete',
                onClick: () => this.deleteClassObject(requestObjectID)
            }
        ];
    }

    getMenuOptionsForPropertyObject = (requestObject) => {
        let requestObjectID = requestObject[ID]
        return [
            {
                label: 'Delete',
                onClick: () => this.deletePropertyObject(requestObjectID)
            }
        ];
    }

    viewModelDetails = () => {
        const {modelDetails} = this.state;
        this.pushToHistory({schemaId: modelDetails[ID], leftMenuTabValue : MODEL_VIEW});
    }

    getLeftBarHeader = () => {
        const {theme, location} = this.props;
        const {modelDetails, ontologyClasses, ontologyProperties} = this.state;
        let leftMenuTabValue = getLeftMenuTabValue(location);
        const MenuTabs = withStyles({
            root: {},
            indicator: {},
        })(Tabs);
        const MenuTab = withStyles({
            root: {
                textTransform: 'none',
                '@media (min-width: 600px)': {
                    minWidth: "48px"
                }
            },
            selected: {
                color : theme.palette.secondary.main,
                backgroundColor: theme.palette.white.main,
                borderRadius: '4px 4px'
            }
        })(Tab);


        let viewModel = () => {
            if(this.isDiagramView()) {
                this.setState({mainViewType: FORM_VIEW});
            }
            this.viewModelDetails();
            return false;
        };

        return <>
            {
                this.isDiagramView()
                    ? <div style={{display : 'flex'}}>
                        <LeftBarTitle style={{minWidth : '348px'}} title={modelDetails[ALIAS_SYS_ID_LABEL]} onClick={viewModel}/>
                        <div style={{flexGrow : '1'}}></div>
                        {<div style={{paddingTop : '1px', paddingRight : '70px'}}>{this.getHeaderButtons()}</div>}
                    </div>
                    : <LeftBarTitle title={modelDetails[ALIAS_SYS_ID_LABEL]} onClick={viewModel}/>
            }
            {
                !this.isDiagramView() &&
                <MenuTabs value={leftMenuTabValue ? leftMenuTabValue : 0} onChange={(event, newValue) => {
                    let params = queryString.parse(this.props.location.search);
                    params.leftMenuTabValue = newValue;
                    this.pushToHistory(params);
                    this.setState({loading : false})
                }} aria-label="request tabs">
                    <MenuTab datatest={'mainTabClasses'} label="Classes"/>
                    <MenuTab  datatest={'mainTabProperties'} label="Properties"/>
                </MenuTabs>
            }
        </>;
    }

    getSearchOptions = (idLabelObjects) => {
        return sort(idLabelObjects.map(o => ({[ID]: o[ID], title: o[ALIAS_SYS_ID_LABEL]})));
    }

    getDiagram = () => {
        let {location, theme, readOnly} = this.props;
        let {openGraphViewOptions, minimized, zoomLevel} = this.state;
        let params = queryString.parse(location.search);
        let {resetID, shapes, ontologyClasses, ontologyProperties, showRightContainer} = this.state;
        let dataForSearch =  this.getDataForView();

        let focusObject = params ? searchInTree(params.classId, ontologyClasses) : undefined
        let {viewSubclassOfEdges, viewPropertyEdges, viewInheritedPropertyEdges} = this.state;
        let rightContainerWidth = !focusObject || showRightContainer !== true ? '0' : '276';
        let width = focusObject ? `calc(100% - ${rightContainerWidth}px - 8px)` : '100%';
        let hasConnectionProperty = shapes.find(s => {
            let find = getShapePropertyArray(s).find(p => isConnectionProperty(p));
            return find ? true : false;
        });
        let hasInheritedConnectionProperty = shapes.find(s => {
            let propertiesWithSuperClasses = computeShapePropertiesWithSuperClasses(s, shapes, ontologyClasses, true);
            let find = propertiesWithSuperClasses.find(p => isConnectionProperty(p));
            return find ? true : false;
        });
        let updateDiagram = () => setTimeout(() => {
            this.unselectAll();
            this.updateDiagram(shapes, ontologyClasses);
            this.refreshDiagramLayout({stop : this.adjustMinZoom});
        } , 200);
        let rightContainerTop = 136;
        return (
            ontologyClasses.length === 0
                ? <div style={{paddingRight : '12px'}}>{this.getNoItemsComponent('Add a class to get started.', 'Add Class', this.showCreateResourceDialog)}</div>
                : <React.Fragment key={'diagramView'}>
                    <div style={{display : 'flex', width : width}}>
                        <div style={{width: '355px'}}>{this.renderSearchBar(this.getSearchOptions(ontologyClasses), dataForSearch, readOnly)}</div>
                        {
                            centerVertically(
                                <Tooltip title={"Reset"}>
                                    <MUIIconButton
                                        datatest={'resetGraphButton'}
                                        size={'small'}
                                        onClick={this.refreshDiagramLayout}>
                                        <RefreshOutlinedIcon color={'primary'}/>
                                    </MUIIconButton>
                                </Tooltip>,
                                {margin : '0px 4px'}
                            )
                        }
                        {

                            centerVertically(
                                <MenuListComposition
                                    buttonIcon={<MoreHorizIcon datatest={'moreMenuButton'} color={'primary'}/>}
                                    itemsProvider={() => {
                                        return [
                                            <ListItem key={'download'} role={undefined} dense button onClick={() => {
                                                let data = this.cytoscapeObj.png({output : 'blob'})
                                                fileDownload(data, 'schema_' + new Date().toISOString() + ".png" )
                                            }}>
                                                <ListItemIcon>
                                                    <ImageOutlinedIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary={`Download Image`}/>
                                            </ListItem>,
                                            <Divider key={'divider'} />,
                                            <List key={'view'} subheader={<ListSubheader>View</ListSubheader>}>
                                                <ListItem role={undefined} dense button onClick={(e) => {
                                                    this.setState({viewSubclassOfEdges: !viewSubclassOfEdges}, updateDiagram)
                                                }}>
                                                    <ListItemIcon>
                                                        <Switch
                                                            datatest={'viewSubclassOfEdges'}
                                                            checked={viewSubclassOfEdges}
                                                            value={true}
                                                            size={"small"}
                                                            name="viewSubclassOfEdges"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={LABEL_SUBCLASS_OF}/>
                                                </ListItem>
                                                <ListItem disabled={!hasConnectionProperty} role={undefined} dense button
                                                          onClick={(e) => {
                                                              this.setState({viewPropertyEdges: !viewPropertyEdges}, updateDiagram)
                                                          }}>
                                                    <ListItemIcon>
                                                        <Switch
                                                            datatest={'viewPropertyEdges'}
                                                            disabled={!hasConnectionProperty}
                                                            checked={viewPropertyEdges}
                                                            value={true}
                                                            size={"small"}
                                                            name="viewPropertyEdges"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`Object Linking Properties`}/>
                                                </ListItem>
                                                <ListItem disabled={!hasInheritedConnectionProperty} role={undefined} dense button
                                                          onClick={(e) => {
                                                              this.setState({viewInheritedPropertyEdges: !viewInheritedPropertyEdges}, updateDiagram)
                                                          }}>
                                                    <ListItemIcon>
                                                        <Switch
                                                            datatest={'viewInheritedPropertyEdges'}
                                                            checked={viewInheritedPropertyEdges}
                                                            value={true}
                                                            size={"small"}
                                                            name="viewInheritedPropertyEdges"
                                                        />
                                                    </ListItemIcon>
                                                    <ListItemText primary={`Inherited Object Linking Properties`}/>
                                                </ListItem>
                                            </List>
                                        ];
                                    }}
                                />
                            )
                        }
                    </div>
                    <div id={'cy'} style={{width: width, height: 'calc(100% - 164px)'}}>
                    </div>
                    <div id={'cy-popper'}></div>
                    <div style={{
                        position: 'absolute',
                        top: '240px',
                        left: `${minimized ? 112 : (250 - 52) + 112}px`,
                        zIndex: 1000,
                        width: 50,
                        height: 200,
                    }}>
                        <PrettoSlider
                            key={zoomLevel}
                            orientation="vertical"
                            defaultValue={ (zoomLevel && Math.round(zoomLevel * 100) / 100 ) || 1}
                            aria-labelledby="vertical-slider"
                            min={MIN_ZOOM}
                            max={1}
                            step={0.02}
                            onChange={this.handleZoomChange}
                            valueLabelDisplay="auto"
                        />
                    </div>
                    {   focusObject &&
                        <>
                            <div style={{
                                top: (rightContainerTop+16)+'px',
                                position: 'absolute',
                                right: rightContainerWidth+'px'
                            }}>
                                <div style={{ width: '24px',height: '30px', backgroundColor: theme.palette.grey.background}}>
                                    {
                                        centerVertically(
                                            <MUIIconButton
                                                datatest={'rightContainerButton'}
                                                size={'small'}
                                                onClick={() => {
                                                    this.setState({showRightContainer:!showRightContainer})
                                                    if(!showRightContainer === true && this.isDiagramView()) {
                                                        this.diagramSearch(focusObject[ID])
                                                    }
                                                }}
                                            >
                                                {showRightContainer ? <ArrowRightIcon/> :<ArrowLeftIcon/>}
                                            </MUIIconButton>
                                        )
                                    }
                                </div>
                            </div>
                            { showRightContainer &&
                                <div key={resetID} style={{
                                    top: rightContainerTop+'px',
                                    position: 'absolute',
                                    bottom: '32px',
                                    right: 0,
                                    zIndex: 1000,
                                    width: rightContainerWidth + 'px',
                                    height: `calc(100% - ${rightContainerTop + 28}px)`,
                                    paddingRight: '4px'
                                }}>
                                    <FieldContainer style={{
                                        backgroundColor: theme.palette.grey.background,
                                        height: '100%',
                                        overflowX: 'hidden',
                                        overflowY: 'scroll'
                                    }}>
                                        {this.renderShapeEditor(focusObject, true)}
                                    </FieldContainer>
                                </div>
                            }
                        </>
                    }
                </React.Fragment>
            );

    }

    handleZoomChange = (e, value) => {
        let selectedNodes = this.getSelectedNodes();
        let position = selectedNodes.length > 0 ? selectedNodes[0].renderedPosition() : undefined;
        console.log("position" + position);
        this.cytoscapeObj.zoom({
            level : value,
            renderedPosition : position
        });
    }

    isDiagramView = () => {
        const {mainViewType} = this.state;
        return mainViewType === GRAPH_VIEW;
    }

    getClassLabel = (ontologyClasses, classID) => {
        return ontologyClasses.find(c => c[ID] === classID)[ALIAS_SYS_ID_LABEL];
    }

    render() {
        traceRenderStart("", COMPONENT)

        const {ontologyClasses, minimized, shapes, ontologyProperties, openAddPropertyDialog, openCreateSubClassDialog,
            openAddNewPropertyDialog, parentClassID, showCreateResourceDialog, leftMenuMinimized,
            openViewGlobalsDialog, modelDetails, configurations, openImportModelDialog,
            deleteWarning, deleteOkAction} = this.state;
        const {theme, location, hideHeader} = this.props;
        let maxWidth = minimized ? '452px' : '675px';
        let leftStyle = {
            padding: '0px',
            width: maxWidth,
            border: 'none',
            backgroundColor: theme.palette.grey.background
        }
        leftStyle = this.isDiagramView() ? {...leftStyle,  'width': '100%', 'height': '100%'} : leftStyle

        return (<>
                    <AllPartsLayout
                        apiError={this.state.apiError}
                        apiErrorResponse={this.state.apiErrorResponse}
                        onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}
                        header={ hideHeader || getHeader(SCHEMA_BUILDER)}
                        leftStyle={leftStyle}
                        leftComponentScroll={{y: 'hidden', x: 'hidden'}}
                        leftComponentStyle={{padding: '0px', 'height': 'calc(100vh - 64px)'}}
                        leftComponentContainerStyle={{paddingRight: '0px'}}
                        leftComponent={!leftMenuMinimized ? this.getLeftComponent() : undefined}
                        middleComponent={this.isDiagramView() ? undefined : this.getMiddleComponent()}
                        middleStyle={{paddingLeft : '40px', paddingTop: '0px', paddingRight: '64px', paddingBottom: '0px'}}
                        {...this.props}
                    ></AllPartsLayout>
                {
                    deleteWarning &&
                    <ConfirmAreYouSure
                        deleteWarning={deleteWarning}
                        handleNo={() => {
                            this.setState({deleteWarning: undefined, deleteOkAction:  undefined})
                        }}
                        handleYes={() => {
                            deleteOkAction();
                            this.setState({deleteWarning: undefined, deleteOkAction:  undefined});
                        }}
                    />
                }
                    {
                        openViewGlobalsDialog === true &&
                        <ViewGlobalsDialog handleClose={() => this.setState({openViewGlobalsDialog: false})}/>
                    }
                    {
                        showCreateResourceDialog === true
                        && <AddClassDialog
                            showExisting={false}
                            title={'Add New Class'}
                            open={showCreateResourceDialog}
                            ontologyClasses={ontologyClasses}
                            ontologyProperties={ontologyProperties}
                            handleCancel={() => this.setState({showCreateResourceDialog: false})}
                            handleOk={(obj, multiple) => this.handleCreateResource(obj, undefined, multiple)}
                        />
                    }
                {
                    openCreateSubClassDialog === true
                    && <AddClassDialog
                        showExisting={true}
                        parentClassID={parentClassID}
                        title={'Add Subclass Of ' + this.getClassLabel(ontologyClasses, parentClassID)}
                        ontologyClasses={ontologyClasses}
                        ontologyProperties={ontologyProperties}
                        open={openCreateSubClassDialog}
                        handleCancel={() => this.setState({openCreateSubClassDialog: false})}
                        handleOk={(obj, multiple) => this.handleCreateSubClass(obj, parentClassID, multiple)}
                    />
                }
                {
                    openAddNewPropertyDialog === true
                    && <AddPropertyDialog
                        title={'Add New Property'}
                        shapes={shapes}
                        ontologyClasses={ontologyClasses}
                        ontologyProperties={ontologyProperties}
                        open={openAddNewPropertyDialog}
                        handleCancel={() => this.setState({openAddNewPropertyDialog: false})}
                        handleOk={(obj, multiple) => this.handleCreateProperty(obj, undefined, multiple)}
                    />
                }
                {
                    openAddPropertyDialog === true
                    && <AddPropertyDialog
                        parentClassID={parentClassID}
                        title={'Add Property to ' + this.getClassLabel(ontologyClasses, parentClassID)}
                        shapes={shapes}
                        ontologyClasses={ontologyClasses}
                        ontologyProperties={ontologyProperties}
                        open={openAddPropertyDialog}
                        handleCancel={() => this.setState({openAddPropertyDialog: false})}
                        handleOk={(obj, multiple) => this.handleCreateProperty(obj, parentClassID, multiple)}
                    />
                }
                {
                    openImportModelDialog === true
                    && <ImportModelDialog
                        existingModelDetails={modelDetails}
                        ontologyClasses={ontologyClasses}
                        ontologyProperties={ontologyProperties}
                        shapes={shapes}
                        open={openImportModelDialog}
                        location={location}
                        onClose={() => this.setState({openImportModelDialog: false})}
                        eventHandler={this.handleImportModel}
                    />
                }
            </>
        );
    }

}

Model.propTypes = {
    hideHeader: PropTypes.bool,
    readOnly: PropTypes.bool,
    headerTitle: PropTypes.string,
    hideMenuActions: PropTypes.bool,
    hideMockingSetup: PropTypes.bool,
    modelDetails: PropTypes.object,
    shapes: PropTypes.array,
    ontologyClasses: PropTypes.array,
    ontologyProperties: PropTypes.array,
    existingShapes: PropTypes.array,
    existingOntologyClasses: PropTypes.array,
    existingOntologyProperties: PropTypes.array,
    focusNode: PropTypes.object,
    mockTreeStyle: PropTypes.object,
    scenariosTreeStyle: PropTypes.object,
    expanded: PropTypes.array,
    onTreeNodeToggle: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(Model);

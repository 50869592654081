import React from "react";
import {Backdrop, CircularProgress, Grid} from "@material-ui/core";
import {STYLE_GRID_ITEM_SPACING} from "../Constants";
import H2Title from "../components/H2Title";
import withStyles from "@material-ui/core/styles/withStyles";
import {styles} from "../components/styles";
import PropTypes from "prop-types";

class ProcessingBackdrop extends React.Component {
    render() {
        let {loading, marginLeft, theme} = this.props

        return <Backdrop datatest={'processingBackdrop'} style={{
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: 'rgba(19, 41, 75, .2)'
        }} open={loading}>
            <Grid spacing={STYLE_GRID_ITEM_SPACING} container style={{marginLeft: marginLeft ? '40px' : '', textAlign: 'center'}}>
                <Grid item xs={12}>
                    <H2Title title={'Processing'} style={{color: theme.palette.secondary.dark}}/>
                </Grid>
                <Grid item xs={12}>
                    <CircularProgress color="secondary" />
                </Grid>
            </Grid>
        </Backdrop>;
    }
}

ProcessingBackdrop.propTypes = {
    loading: PropTypes.bool,
    marginLeft : PropTypes.bool
}

export default withStyles(styles, {withTheme: true})(ProcessingBackdrop);

import React, {useContext} from "react";
import IconButton from "@material-ui/core/IconButton";
import {SettingsOutlined} from "@material-ui/icons";
import {isPageEditEnabled, setSettingsEditAction} from "../common/Profile";
import GlobalsContext from "../../components/GlobalsContext";
import {isQuickEditDisabled} from "../../components/util";


export function WithAdminEdit({style = {}, data={}}) {
    const globals = useContext(GlobalsContext);


    const isDisabled = isQuickEditDisabled(data?.customizations);

    return <React.Fragment>
        {
            isPageEditEnabled() && !isDisabled ?
            <IconButton
                datatest={data.action}
                className={'adminSiteEditButton'}
                color={'secondary'}
                onClick={(ev) => {
                    ev.stopPropagation();
                    ev.preventDefault();
                    setSettingsEditAction(data);
                    globals.onSettingsEdit(data);
                }}
                size={'small'}
                style={style}
            ><SettingsOutlined></SettingsOutlined></IconButton> : <></>
        }
    </React.Fragment>
}
import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withEvent} from "./Event";
import PropTypes from "prop-types";
import FieldContainer from "../../components/FieldContainer";
import StyleOutlined from "@material-ui/icons/StyleOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {ZINDEX_NODE_DETAILS_FORM_DATA} from "./GraphView";
import H4Title from "../../components/H4Title";
import TextField from "@material-ui/core/TextField";
import {
    centerVertically,
    flatten,
    getPropertyName,
    restrictMaximumCharacters,
    sort,
    sortByFunction,
    toArray
} from "../../components/util";
import {
    Button,
    Grid,
    InputLabel,
    MenuItem,
    RadioGroup,
    TextField as OtherTextField,
    Typography
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD_STYLE,
    UI_LABELS_CLOSE,
    UI_LABELS_ELEMENT_STYLES,
    UI_LABELS_GLOBAL_PROPERTY_STYLES,
    UI_LABELS_GLOBAL_STYLES,
    UI_LABELS_HELP,
    UI_LABELS_IMAGE_URL,
    UI_LABELS_NO_STYLE,
    UI_LABELS_PROPERTY_STYLES,
    UI_LABELS_PROPERTY_VALUE,
    UI_LABELS_SELECT_STYLE_PROPERTY,
    UI_LABELS_STYLE_FOR_EDGE,
    UI_LABELS_STYLE_FOR_EDGE_TYPE,
    UI_LABELS_STYLE_FOR_NODE,
    UI_LABELS_STYLE_FOR_NODE_TYPE,
    UI_LABELS_STYLE_NODE_BACKGROUND_IMAGE_HELP,
    UI_LABELS_STYLE_SCOPE,
    UI_LABELS_STYLE_SETTINGS,
    UI_LABELS_TYPE,
    UI_LABELS_TYPE_STYLES
} from "./UILabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import H3Title from "../../components/H3Title";
import Select from "@material-ui/core/Select";
import {ColorPicker, ImageSetting, renderImage} from "./BasicSetup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {getChipWithDelete} from "../apiplayground/SearchFilter";
import {LINK_COLOR} from "../../Constants";
import {GRAPH_VIEW_BACKGROUND_IMAGE, GRAPH_VIEW_PRECEDENCE, GRAPH_VIEW_SETTINGS} from "./DataViewSetup";
import {sortedStyleProperties} from "./navigator-cytoscape-util";
import {DeleteOutlined} from "@material-ui/icons";
import FormLabel from "@material-ui/core/FormLabel";
import {cloneDeep} from "lodash";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import H1Title from "../../components/H1Title";
import H2Title from "../../components/H2Title";
import {GraphViewStyleHelp} from "./GraphViewStyleHelp";


export const EDGE_CURVE_STYLE_TAXI = 'taxi';

export const GRAPH_VIEW_BACKGROUND_COLOR = 'background-color';


export const GRAPH_VIEW_EDGE_CURVE_STYLE = 'GRAPH_VIEW_EDGE_CURVE_STYLE';


export const DEFAULT_EDGE_WIDTH=1;
export const DEFAULT_EDGE_COLOR=LINK_COLOR;
export const DEFAULT_FONT_SIZE='12px';
export const DEFAULT_EDGE_LINE_STYLE='solid';
export const DEFAULT_TAXI_TURN = '30px';
export const DEFAULT_NODE_BORDER_COLOR=LINK_COLOR;
export const DEFAULT_NODE_BORDER_WIDTH=2;
export const DEFAULT_NODE_BORDER_STYLE='solid';
export const DEFAULT_NODE_TEXT_VALIGN='center';
export const DEFAULT_NODE_TEXT_HALIGN='center';
export const DEFAULT_NODE_TEXT_JUSTIFICATION='left';
export const DEFAULT_NODE_WIDTH='label';
export const DEFAULT_NODE_HEIGHT='34px';
export const DEFAULT_NODE_TEXT_WRAP = 'wrap';
export const DEFAULT_NODE_PADDING = 0;


export const ELEMENT_SETTINGS = 'ELEMENT_SETTINGS';
export const NODE_TYPE_STYLE = 'NODE_TYPE_STYLE';
export const PROPERTY_IRI_STYLE = 'PROPERTY_IRI_STYLE';
export const ELEMENT_STYLE = 'ELEMENT_STYLE';
export const STYLE = 'STYLE';

class GraphViewStyleSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodeStyleScope : UI_LABELS_STYLE_FOR_NODE,
            edgeStyleScope : UI_LABELS_STYLE_FOR_EDGE,
            selectedType : null,
            selectedProperty : null,
            selectedPropertyValue : {},
            typeOptions : []
        }
    }

    componentDidMount() {
        this.setup();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.openStyleFor?.elementId !== this.props.openStyleFor?.elementId) {
            this.setup();
        }
    }

    setup = () => {
        let {aliasesToIRIMap, ontology, browseLanguage} = this.props;
        let typeIRIs = this.getTypeIRIs();
        let typeOptions = typeIRIs.map(t => {
            return {
                id: t,
                label: getPropertyName(aliasesToIRIMap, t, ontology, browseLanguage)
            }
        });
        typeOptions = sort(typeOptions, 'label');
        const selectedType = typeOptions.length > 0 ? typeOptions[0] : null;
        this.setState({typeOptions, selectedType})
    }

    getSourceElement = () => {
        let {elementObject, openStyleFor} = this.props;
        return elementObject;
    }

    applyStyleChange = () => {
        let {onStyleSettingsChange, graphStyle, openStyleFor} = this.props;
        let {nodeStyleScope, edgeStyleScope, selectedType, selectedProperty, selectedPropertyValue, typePrecedence} = this.state;
        const {elementId} = openStyleFor;
        const sourceElement = this.getSourceElement();
        const sourcePropertyIRI = sourceElement.data().propertyIRI;
        if(this.isNodeStyle()) {
            if(nodeStyleScope === UI_LABELS_STYLE_FOR_NODE_TYPE) {
                if(!graphStyle[NODE_TYPE_STYLE]) {
                    graphStyle[NODE_TYPE_STYLE] = {};
                }
                if(!graphStyle[NODE_TYPE_STYLE][selectedType.id]) {
                    graphStyle[NODE_TYPE_STYLE][selectedType.id] = {
                        [GRAPH_VIEW_PRECEDENCE] : 0,
                        [STYLE] : {}
                    };
                }
                if(!graphStyle[NODE_TYPE_STYLE][selectedType.id][STYLE]) {
                    graphStyle[NODE_TYPE_STYLE][selectedType.id][STYLE] = {}
                }
                graphStyle[NODE_TYPE_STYLE][selectedType.id][STYLE][selectedProperty.name] = selectedPropertyValue[selectedProperty.name];
                graphStyle[NODE_TYPE_STYLE][selectedType.id][GRAPH_VIEW_PRECEDENCE] = typePrecedence;
            } else if (this.isEmbeddedForAType()) {
                if(!graphStyle[NODE_TYPE_STYLE]) {
                    graphStyle[NODE_TYPE_STYLE] = {};
                }
                if(!graphStyle[NODE_TYPE_STYLE][elementId]) {
                    graphStyle[NODE_TYPE_STYLE][elementId] = {};
                }
                if(!graphStyle[NODE_TYPE_STYLE][elementId][STYLE]) {
                    graphStyle[NODE_TYPE_STYLE][elementId][STYLE] = {};
                }
                graphStyle[NODE_TYPE_STYLE][elementId][STYLE][selectedProperty.name] = selectedPropertyValue[selectedProperty.name];
            } else {
                if(!graphStyle[ELEMENT_STYLE]) {
                    graphStyle[ELEMENT_STYLE] = {};
                }
                if(!graphStyle[ELEMENT_STYLE][elementId]) {
                    graphStyle[ELEMENT_STYLE][elementId] = {};
                }
                graphStyle[ELEMENT_STYLE][elementId][selectedProperty.name] = selectedPropertyValue[selectedProperty.name];
            }
        } else {
            if(edgeStyleScope === UI_LABELS_STYLE_FOR_EDGE_TYPE || this.isEmbeddedForAType()) {
                if(!graphStyle[PROPERTY_IRI_STYLE]) {
                    graphStyle[PROPERTY_IRI_STYLE] = {};
                }
                if(!graphStyle[PROPERTY_IRI_STYLE][sourcePropertyIRI]) {
                    graphStyle[PROPERTY_IRI_STYLE][sourcePropertyIRI] = {};
                }
                graphStyle[PROPERTY_IRI_STYLE][sourcePropertyIRI][selectedProperty.name] = selectedPropertyValue[selectedProperty.name];
            } else {
                if(!graphStyle[ELEMENT_STYLE]) {
                    graphStyle[ELEMENT_STYLE] = {};
                }
                if(!graphStyle[ELEMENT_STYLE][elementId]) {
                    graphStyle[ELEMENT_STYLE][elementId] = {};
                }
                graphStyle[ELEMENT_STYLE][elementId][selectedProperty.name] = selectedPropertyValue[selectedProperty.name];
            }
        }
        this.setState({selectedPropertyValue : {}, typePrecedence : 0});
        this.styleChanged();
    }

    styleChanged = () => {
        let {onStyleSettingsChange} = this.props;
        onStyleSettingsChange && onStyleSettingsChange();
    }

    getTranslation = (labelKey) => {
        let {settings, browseLanguage} = this.props;
        return getUiLabelTranslation(settings, labelKey, browseLanguage, labelKey);
    }

    isNodeStyle = () => {
        let {openStyleFor} = this.props;
        const sourceElement = this.getSourceElement();
        return sourceElement?.isNode() === true;
    }

    renderExistingStylesForElement = () => {
        let {openStyleFor, graphStyle, onStyleSettingsChange} = this.props;
        let {selectedProperty} = this.state;
        const elementId = openStyleFor.elementId;
        const graphStyleForElement = graphStyle[ELEMENT_STYLE]?.[elementId];
        const styleKeys = Object.keys(graphStyleForElement ? graphStyleForElement : {}).filter(k => ![GRAPH_VIEW_SETTINGS, selectedProperty?.name].includes(k));
        return <Grid datatest={'existingStylesForElement'} item xs={12}>
            <H4Title title={this.getTranslation(UI_LABELS_ELEMENT_STYLES)}></H4Title>
            {

                styleKeys.length < 1
                    ? this.renderNoStyle()
                    : sort(styleKeys).map(k => {
                    let property = sortedStyleProperties.find(p => p.name === k);
                    return <FieldContainer datatest={'block-'+k} key={k} style={{marginTop : '8px'}}>
                        <div style={{display :'flex'}}>
                            <H4Title title={k}/>
                            <div style={{flexGrow : '1'}}></div>
                            <IconButton
                                datatest={'deleteButton'}
                                size={'small'}
                                onClick={() => {
                                    delete graphStyleForElement[property.name];
                                    this.setState({});
                                    onStyleSettingsChange && onStyleSettingsChange();
                                }

                            }>
                                <DeleteOutlined></DeleteOutlined>
                            </IconButton>
                        </div>
                        {this.renderPropertyValueType( property, graphStyleForElement, this.getTranslation(UI_LABELS_PROPERTY_VALUE), this.styleChanged)}
                    </FieldContainer>;
                })
            }
        </Grid>;
    }

    renderExistingStylesForNodeType = (graphStyle, headingLabel, readOnly, otherPropertiesProvider, onTypeScopeChange) => {
        let {theme, onStyleSettingsChange} = this.props;
        let {typeOptions} = this.state;
        if(typeOptions.length === 0) {
            //return coz types are not loaded yet
            return <></>;
        }
        const typeIRIs = this.getTypeIRIs();
        const graphStylesForNodeTypes = typeIRIs.filter(t => {
            const styleObject = graphStyle?.[NODE_TYPE_STYLE]?.[t];
            //check that there is atleast one style key setup
            //there may not be style key because setting might be just for precedence
            return styleObject?.[STYLE] && Object.keys(styleObject?.[STYLE]).length >0;

        }).map(t => {
            return {
                [t] : graphStyle[NODE_TYPE_STYLE][t]
            }
        });
        const sorted = sortByFunction(graphStylesForNodeTypes, (v) => {
            let typeIRI = Object.keys(v)[0];
            return Number(v[typeIRI][GRAPH_VIEW_PRECEDENCE]);
        }, 'desc');
        return <Grid datatest={'stylesForNodeType'} item xs={12}>
            <H4Title title={headingLabel}></H4Title>
            {
                sorted.length < 1
                    ? this.renderNoStyle()
                    : sorted.map((obj, index) => {
                        let typeIRI = Object.keys(obj)[0];
                        let style = obj[typeIRI];
                        const selectedType = typeOptions.find(to => to.id === typeIRI);
                        return <FieldContainer style={{marginTop : index > 0 ? '8px' : '0px'}} key={index}>
                            {
                                this.isEmbeddedForAType() ? <></> :
                                this.renderTypeScopeSettings(
                                    selectedType,
                                    obj[typeIRI][GRAPH_VIEW_PRECEDENCE],
                                    (change) => onTypeScopeChange && onTypeScopeChange(obj, typeIRI, change),
                                    {paddingLeft : '0px'},
                                    readOnly
                                )
                            }
                            {otherPropertiesProvider && otherPropertiesProvider(typeIRI)}
                            {
                                sort(Object.keys(style[STYLE])).map((k, index) => {
                                    let property = sortedStyleProperties.find(p => p.name === k);
                                    return <FieldContainer datatest={'block-'+k} key={k} style={{marginTop : (index > 0 ? '8px' : '0px'), borderStyle : 'solid', borderWidth : '2px', borderColor : theme.palette.white.main}}>
                                        <div style={{display :'flex'}}>
                                            <H4Title  title={k}/>
                                            <div style={{flexGrow : '1'}}></div>
                                            {
                                                readOnly ? <></> :
                                                <IconButton
                                                    datatest={'deleteButton'}
                                                    size={'small'}
                                                    onClick={() => {
                                                        delete style[STYLE][property.name];
                                                        this.setState({});
                                                        onStyleSettingsChange && onStyleSettingsChange();
                                                    }
                                                    }>
                                                    <DeleteOutlined/>
                                                </IconButton>
                                            }
                                        </div>
                                        {this.renderPropertyValueType( property, style[STYLE], this.getTranslation(UI_LABELS_PROPERTY_VALUE), this.styleChanged, readOnly)}
                                    </FieldContainer>;
                                })
                            }
                        </FieldContainer>;
                    })
            }
        </Grid>;
    }

    getTypeIRIs = () => {
        const typesArray = toArray(this.getSourceElement().data().typeIRIs);
        return  flatten(typesArray).filter(t => t);
    }

    renderNoStyle() {
        return <div><Typography style={{textAlign: 'center', paddingTop: '8px'}}>{this.getTranslation(UI_LABELS_NO_STYLE)}</Typography></div>;
    }

    renderExistingStylesForEdgePropertyIRI = () => {
        let {graphStyle, onStyleSettingsChange} = this.props;
        const sourceElement = this.getSourceElement();
        const sourcePropertyIRI = sourceElement.data().propertyIRI;
        const graphStyleForElement = graphStyle?.[PROPERTY_IRI_STYLE]?.[sourcePropertyIRI];
        const styleKeys = Object.keys(graphStyleForElement ? graphStyleForElement : {}).filter(k => ![GRAPH_VIEW_SETTINGS].includes(k));
        return <Grid datatest={'stylesForEdgePropertyIRI'} item xs={12}>
            <H4Title title={this.getTranslation(UI_LABELS_PROPERTY_STYLES)}></H4Title>
            {

                styleKeys.length < 1
                    ? <div><Typography style={{textAlign :'center', padding :'16px'}}>{this.getTranslation(UI_LABELS_NO_STYLE)}</Typography></div>
                    : sort(styleKeys).map(k => {
                        let property = sortedStyleProperties.find(p => p.name === k);
                        return <FieldContainer datatest={"block-"+k} style={{marginTop : '8px'}}>
                            <div style={{display :'flex'}}>
                                <H4Title style={{maxWidth : 'calc(100% - 80px)'}} title={k}/>
                                <div style={{flexGrow : '1'}}></div>
                                <IconButton
                                    datatest={'deleteButton'}
                                    size={'small'}
                                    onClick={() => {
                                        delete graphStyleForElement[property.name];
                                        this.setState({});
                                        onStyleSettingsChange && onStyleSettingsChange();
                                    }

                                    }>
                                    <DeleteOutlined></DeleteOutlined>
                                </IconButton>
                            </div>
                            {this.renderPropertyValueType( property, graphStyleForElement, this.getTranslation(UI_LABELS_PROPERTY_VALUE), this.styleChanged)}
                        </FieldContainer>;
                    })
            }
        </Grid>;
    }

    renderNodeStyleScopeSetting = () => {
        let {theme, graphStyle} = this.props;
        let {nodeStyleScope, selectedType, typeOptions, typePrecedence} = this.state;
        let defaultPrecedenceValue = selectedType && graphStyle?.[NODE_TYPE_STYLE]?.[selectedType.id]?.[GRAPH_VIEW_PRECEDENCE];
        return <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">{this.getTranslation(UI_LABELS_STYLE_SCOPE)}</FormLabel>
                <RadioGroup
                    datatest="styleScopeNodeRadioGroup"
                    style={{marginLeft: '8px'}}
                    onChange={(event, value) => {
                        this.setState({nodeStyleScope: value});
                    }}
                    row={false}
                    value={nodeStyleScope}
                >
                    <FormControlLabel
                        value={UI_LABELS_STYLE_FOR_NODE}
                        control={<Radio/>}
                        label={this.getTranslation(UI_LABELS_STYLE_FOR_NODE)}
                    />
                    <FormControlLabel
                        value={UI_LABELS_STYLE_FOR_NODE_TYPE}
                        control={<Radio/>}
                        label={this.getTranslation(UI_LABELS_STYLE_FOR_NODE_TYPE)}
                    />
                </RadioGroup>
            </FormControl>
            {
                nodeStyleScope === UI_LABELS_STYLE_FOR_NODE_TYPE
                    ? this.renderTypeScopeSettings(
                        selectedType,
                        typePrecedence || defaultPrecedenceValue,
                        (changeObject) => {
                            this.setState(changeObject);
                        }

                    )
                    : <></>
            }
        </div>;
    }

    renderTypeScopeSettings = ( selectedType, typePrecedence, onChange, containerStyle, readOnly) => {
        let {theme} = this.props;
        let {typeOptions} = this.state;
        return <div datatest={'typeScopeBlock'} style={{paddingLeft: '40px', ...containerStyle}}>
            <Autocomplete
                disabled={readOnly}
                datatest={'classSelect'}
                value={selectedType}
                options={typeOptions}
                getOptionLabel={option => option.label ? option.label : ''}
                getOptionSelected={(option, value) => {
                    return option.id === value.id;
                }}
                multiple={false}
                onChange={(event, val) => {
                    onChange && onChange({selectedType: val})
                }}
                renderInput={params => (
                    <OtherTextField
                        label={this.getTranslation(UI_LABELS_TYPE)}
                        {...params}
                        margin={"dense"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return getChipWithDelete(theme, index, option.label, option.id, getTagProps({index}));
                    })
                }}
                size={"small"}
                disableClearable={true}
            />
            <TextField
                disabled={readOnly}
                datatest={'precedence'}
                fullWidth={true}
                type={'number'}
                InputProps={{
                    readOnly :readOnly
                }}
                inputProps={{
                    min : 0,
                    step : 1
                }}
                label={'Precedence'}
                value={typePrecedence === undefined || typePrecedence === null  ? '' :  typePrecedence}
                onChange={(ev) => {
                    onChange && onChange({typePrecedence : ev.target.value})
                }}
            ></TextField>
        </div>;
    }

    renderEdgeStyleScopeSettings = () => {
        let {settings, browseLanguage} = this.props;
        let {edgeStyleScope} = this.state;

        return <div>
            <FormControl component="fieldset">
                <FormLabel component="legend">{this.getTranslation(UI_LABELS_STYLE_SCOPE)}</FormLabel>
                <RadioGroup
                    datatest="styleScopeEdgeRadioGroup"
                    value={edgeStyleScope}
                    style={{marginLeft: '8px'}}
                    onChange={(event, value) => {
                        this.setState({edgeStyleScope: value});
                    }}
                    row={false}
                >
                    <FormControlLabel
                        value={UI_LABELS_STYLE_FOR_EDGE}
                        control={<Radio/>}
                        label={this.getTranslation(UI_LABELS_STYLE_FOR_EDGE)}
                    />
                    <FormControlLabel
                        value={UI_LABELS_STYLE_FOR_EDGE_TYPE}
                        control={<Radio/>}
                        label={this.getTranslation(UI_LABELS_STYLE_FOR_EDGE_TYPE)}
                    />
                </RadioGroup>
            </FormControl>
        </div>;
    }

    renderPropertyValueType = (selectedProperty, styleObject, label, onChange, readOnly) => {
        if(selectedProperty) {
            //initGraphStyleIfRequired();
            const type = selectedProperty.type;
            const enums = type.enums;
            const propertyName = selectedProperty.name;
            if(propertyName === 'background-image') {
                let {theme} = this.props;
                let tempObj = {
                    "tempImageKey" : styleObject
                }

                return <ImageSetting
                    readOnly={readOnly === true}
                    column={true}
                    label={<H4Title title={this.getTranslation(UI_LABELS_IMAGE_URL)}/>}
                    workspace={this.props.workspace}
                    valueObject={tempObj}
                    objectKey={'tempImageKey'}
                    valueKey={propertyName}
                    helpMessage={this.getTranslation(UI_LABELS_STYLE_NODE_BACKGROUND_IMAGE_HELP)}
                    innerTextLabel={undefined}
                    containerStyle={{padding : '0px'}}
                    onFileUpload={() => {
                        onChange && onChange();
                    }}
                />;

            } else if(type.number) {
                let step = type.min !== undefined && type.max !== undefined
                    ? (type.max - type.min)/100
                    : undefined;
                let isNumberOnly = enums === undefined;
                return <>
                    <TextField
                        datatest={'numberText'}
                        label={label}
                        type={isNumberOnly  ? 'number' : undefined}
                        InputProps={{
                            readOnly : readOnly
                        }}
                        inputProps={{
                            min : type.min,
                            step : step,
                            max : type.max
                        }}
                        fullWidth={true}
                        defaultValue={styleObject?.[propertyName]}
                        onChange={(event) => {
                            const maxLength = 500;
                            let newEvent = restrictMaximumCharacters(event, maxLength);
                            const {target: {value}} = newEvent;
                            styleObject[propertyName] = value;
                            onChange && onChange();
                        }}
                    />

                </>;
            } else if (type.color) {
                let {theme} = this.props;
                let tempObj = {
                   "tempKey" : styleObject
                }
                return <ColorPicker
                    readOnly={readOnly}
                    defaultValue={styleObject?.[propertyName]}
                    theme={theme}
                    settings={tempObj}
                    onChange={(newValue) => {
                        //this is required here as for some reason color picker is calling onChange before setting the value
                        styleObject[propertyName] = newValue;
                        onChange && onChange();
                    }}
                    objectKey={"tempKey"}
                    valueKey={propertyName}
                    placeholderValue={''}
                    containerStyle={{padding: '0px'}}
                />;
            } else if (enums) {
                return <FormControl style={{marginTop : '8px'}} size={'small'} fullWidth variant="outlined">
                    <InputLabel htmlFor="style-value-label">{label}</InputLabel>
                    <Select
                        readOnly={readOnly}
                        datatest={'select'}
                        label={label}
                        id="style-value-label"
                        defaultValue={styleObject?.[propertyName]}
                        onChange={(event) => {
                            const {target: {value}} = event;
                            styleObject[propertyName] = value;
                            onChange && onChange();
                        }}
                    >
                        {enums.map(l => <MenuItem key={l} value={l}>{l}</MenuItem>)}
                    </Select>
                </FormControl>;
            } else {
                return <TextField
                    datatest={'textType'}
                    label={label}
                    fullWidth={true}
                    InputProps={{
                        readOnly : readOnly
                    }}
                    defaultValue={styleObject?.[propertyName]}
                    onChange={(event) => {
                        const maxLength = 500;
                        let newEvent = restrictMaximumCharacters(event, maxLength);
                        const {target: {value}} = newEvent;
                        styleObject[propertyName] = value;
                        onChange && onChange();
                    }}
                />;

            }

        }
        return <></>;
    }

    disableButton = () => {
        let {selectedProperty, selectedPropertyValue} = this.state;
        if(!selectedProperty) {
            return true;
        } else {
            let valueKey = selectedProperty.name;
            if(!selectedPropertyValue || (selectedPropertyValue[valueKey] === undefined || selectedPropertyValue[valueKey] === null || selectedPropertyValue[valueKey] === '')) {
                return true;
            }
            return false;
        }
    }

    renderStyleSetup = () => {
        let {openStyleFor, hideProperty} = this.props;
        let {selectedProperty, selectedPropertyValue} = this.state;
        const filterGroups = this.isNodeStyle()
            ? ['edgeLine', 'edgeArrow']
            : ['backgroundImage', 'pie', 'compound', 'nodeBorder', 'nodeOutline'];
        const options = sortedStyleProperties
            .filter(p => !toArray(hideProperty).includes(p.name))
            .filter(p => {
                const isFiltered = filterGroups.includes(p.groupKey);
                return isFiltered ? false : true;
            });
        return <>
            <H4Title title={this.getTranslation(UI_LABELS_ADD_STYLE)}></H4Title>
            <FieldContainer>
                {
                    this.isEmbeddedForAType()
                    ? <></>
                    : this.isNodeStyle() ? this.renderNodeStyleScopeSetting() : this.renderEdgeStyleScopeSettings()
                }

            <Autocomplete
                datatest={'propertySelect'}
                options={options}
                value={selectedProperty || null}
                getOptionLabel={option => option.name ?  option.name : ''}
                getOptionSelected={(option, value) => {
                    return option.name === value.name;
                }}
                multiple={false}
                onChange={(event, val) => {
                    //set default value
                    const type = val.type;
                    const enums = type.enums;
                    const propertyName = val.name;
                    if(enums) {
                        selectedPropertyValue[propertyName] = enums[0];
                    }
                    this.setState({selectedProperty: val});
                }}
                renderInput={params => (
                    <OtherTextField
                        label={this.getTranslation(UI_LABELS_SELECT_STYLE_PROPERTY)}
                        {...params}
                        margin={"dense"}
                        variant="outlined"
                        fullWidth
                    />
                )}
                renderOption={(option, { selected }) => (
                    <div datatest={"option-"+option.name}>{option.name}</div>
                )}
                size={"small"}
                disableClearable={true}
            />
            <div style={{marginTop : '8px'}} key={selectedProperty?.name}>
                {
                    this.renderPropertyValueType(
                        selectedProperty,
                        selectedPropertyValue,
                        this.getTranslation(UI_LABELS_PROPERTY_VALUE),
                        () => this.setState({})
                    )}
            </div>

            <div style={{display : 'flex', marginTop : '8px'}}>
                {this.state.openHelp && this.renderHelp()}
                <Button variant={'text'} onClick={() => this.setState({openHelp : true})}>Help</Button>
                <div style={{flexGrow : '1'}}></div>
                <Button
                    disabled={this.disableButton()}
                    datatest={'applyButton'}
                    size={'small'}
                    color={'secondary'}
                    variant={'outlined'}
                    onClick={() => {
                        this.setState({selectedProperty : '', selectedPropertyValue :{} })
                        this.applyStyleChange();
                    }}
                >Apply</Button>
            </div>

        </FieldContainer></>;
    }

    isEmbeddedForAType = () => {
        return this.getSourceElement()?.embeddedForType === true;
    }

    renderGlobalBackgroundImage = (type) => {
        let {settings, theme} = this.props;
        const valueObject = settings.labelProperties?.[type];

        return <FieldContainer datatest={'globalBackgroundImage'} style={{margin : ('8px 0px'), borderStyle : 'solid', borderWidth : '2px', borderColor : theme.palette.white.main}}>
            <div>
                <H4Title  title={'Image URL'}/>
                <TextField
                    InputProps={{
                        readOnly : true
                    }}
                    fullWidth={true}
                    defaultValue={valueObject?.[GRAPH_VIEW_SETTINGS]?.[GRAPH_VIEW_BACKGROUND_IMAGE]}
                />
                <div style={{textAlign : 'center'}}>
                    {renderImage(valueObject?.[GRAPH_VIEW_SETTINGS]?.[GRAPH_VIEW_BACKGROUND_IMAGE], true)}
                </div>
            </div>
        </FieldContainer>;
    }

    renderGlobalStyle = () => {
        let {settings} = this.props;
        const readOnly = this.isEmbeddedForAType() ? false : true;

        return <>
            {
                this.isNodeStyle()
                    ? this.getTypeIRIs().map(t => this.renderExistingStylesForNodeType(settings.labelProperties?.[t]?.[GRAPH_VIEW_SETTINGS], this.getTranslation(UI_LABELS_GLOBAL_STYLES), readOnly, this.renderGlobalBackgroundImage))
                    : this.renderGlobalStylesForPropertyIRI()
            }
        </>;
    }

    renderGlobalStylesForPropertyIRI = () => {
        let {onStyleSettingsChange, settings} = this.props;
        const sourceElement = this.getSourceElement();
        const sourcePropertyIRI = sourceElement.data().propertyIRI;
        const graphStyleForElement = settings.labelProperties?.[sourcePropertyIRI]?.[GRAPH_VIEW_SETTINGS]?.[PROPERTY_IRI_STYLE]?.[sourcePropertyIRI];
        const styleKeys = Object.keys(graphStyleForElement ? graphStyleForElement : {})
            .filter(k => ![GRAPH_VIEW_SETTINGS].includes(k));
        const readOnly = this.isEmbeddedForAType() ? false : true;
        return <Grid datatest={'globalStylesForPropertyIRI'} item xs={12}>
            <H4Title title={this.getTranslation(UI_LABELS_GLOBAL_PROPERTY_STYLES)}></H4Title>
            {
                styleKeys.length < 1
                    ? <div><Typography style={{textAlign :'center', padding :'16px'}}>{this.getTranslation(UI_LABELS_NO_STYLE)}</Typography></div>
                    : sort(styleKeys).map(k => {
                        let property = sortedStyleProperties.find(p => p.name === k);
                        return <FieldContainer datatest={"block-"+k} style={{marginTop : '8px'}}>
                            <div  style={{display :'flex'}}>
                                <H4Title style={{maxWidth : 'calc(100% - 80px)'}} title={k}/>
                                <div style={{flexGrow : '1'}}></div>
                                {
                                    readOnly ? <></> :
                                    <IconButton
                                        datatest={'deleteButton'}
                                        size={'small'}
                                        onClick={() => {
                                            delete graphStyleForElement[property.name];
                                            this.setState({});
                                            onStyleSettingsChange && onStyleSettingsChange();
                                        }}>
                                        <DeleteOutlined></DeleteOutlined>
                                    </IconButton>
                                }
                            </div>
                            {this.renderPropertyValueType( property, graphStyleForElement, this.getTranslation(UI_LABELS_PROPERTY_VALUE), this.styleChanged, readOnly)}
                        </FieldContainer>;
                    })
            }
        </Grid>;
    }


    render() {
        let {onClose, openStyleFor, theme, styleContainerWidth, containerStyle, graphStyle} = this.props;
        const elementId = openStyleFor.elementId;
        let containerStyleToApply = containerStyle ? containerStyle : {}
        return <div datatest={'graphViewStyleSettings'} key={elementId} style={{
            flexGrow : '1',
            padding: '8px',
            backgroundColor: theme.palette.white.main,
            borderRadius: '4px',
            zIndex : ZINDEX_NODE_DETAILS_FORM_DATA,
            ...containerStyleToApply
        }}>
            <FieldContainer  style={{
                padding : '0px',
                backgroundColor: theme.palette.grey.background,
                height: '100%',
                ...containerStyleToApply
            }}>
                <div style={{display: 'flex', padding : '4px'}}>
                    {centerVertically(<StyleOutlined></StyleOutlined>)}
                    {centerVertically(<H3Title>{this.getTranslation(UI_LABELS_STYLE_SETTINGS)}</H3Title>, {marginLeft : '8px'})}
                    <div style={{flexGrow: '1'}}></div>
                    {
                        onClose &&
                        <Tooltip title={'Close'}>
                            <IconButton datatest={'closeStyleButton'} size={'small'} onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </div>
                <div style={{ height: "calc(100% - 56px)", maxWidth : `${styleContainerWidth - 28}px`, overflowY : 'auto', overflowX : 'auto', borderRadius : '4px', margin : '0px 8px 8px 8px', paddingBottom : '8px', backgroundColor: theme.palette.white.main}}>
                    <FieldContainer style={{
                        backgroundColor: theme.palette.white.main,
                    }}>
                        <Grid container spacing={3}>
                            {this.isEmbeddedForAType() || this.renderExistingStylesForElement()}
                            {
                                this.isEmbeddedForAType()
                                    ? <></>
                                    : this.isNodeStyle()
                                        ? this.renderExistingStylesForNodeType(
                                            graphStyle,
                                            this.getTranslation(UI_LABELS_TYPE_STYLES),
                                            false,
                                            undefined,
                                            (obj, typeIRI, change) => {
                                                if(change.typePrecedence) {
                                                    obj[typeIRI][GRAPH_VIEW_PRECEDENCE] = change.typePrecedence;
                                                } else if (change.selectedType) {
                                                    const clonedData = cloneDeep(graphStyle[NODE_TYPE_STYLE][typeIRI]);
                                                    graphStyle[NODE_TYPE_STYLE][change.selectedType.id] = clonedData;
                                                    delete graphStyle[NODE_TYPE_STYLE][typeIRI];
                                                }
                                                this.styleChanged();
                                                this.setState({});
                                            }
                                        )
                                        : this.renderExistingStylesForEdgePropertyIRI()
                            }
                            {this.renderGlobalStyle()}
                            <Grid datatest={'styleSetup'} item xs={12}>
                                {this.renderStyleSetup()}
                            </Grid>
                        </Grid>
                    </FieldContainer>
                </div>
            </FieldContainer>
        </div>;
    }

    renderHelp = () => {
        let {settings, browseLanguage} = this.props;
        return <GraphViewStyleHelp
            settings={settings}
            onClose={() => this.setState({openHelp : false})}
            browseLanguage={browseLanguage}
        />;
    }
}

GraphViewStyleSettings.propTypes = {
    workspace: PropTypes.any,
    minimized: PropTypes.any,
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.array,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    openStyleFor: PropTypes.any,
    graphStyle: PropTypes.object,
    onClose: PropTypes.func,
    onStyleSettingsChange: PropTypes.func,
    styleContainerWidth: PropTypes.any,
    elementObject: PropTypes.any,
    containerStyle: PropTypes.any,
    hideProperty: PropTypes.any,
};

export default withStyles(styles, {withTheme: true})(withEvent(GraphViewStyleSettings));

import React, {Component} from "react";
import PropTypes from "prop-types";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import BaseTreeItem from "@material-ui/lab/TreeItem";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Typography from "@material-ui/core/Typography";
import {traceRenderStart} from "../../components/Trace";
import {scrollToView} from "../../components/util";

const useTreeItemStyles = makeStyles(theme => ({
  root: {
      scrollBehavior: 'smooth',
      color: theme.palette.primary.main,
      "&:focus > $content $label": {
          backgroundColor: 'inherit',
      },
      marginTop : '4px',
      "&$selected > $content $label": {
          backgroundColor: "inherit"
      },
        "&:hover > $content $label": {
            backgroundColor: 'inherit',
        },
      '&$selected > $content $label:hover, &$selected:focus > $content $label': {
          backgroundColor: 'inherit'
      }
  },
  content: {
      width: `calc(100%)`,
      color: theme.palette.primary.main,
      borderTopRightRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      "$expanded > &": {
          // fontWeight: theme.typography.fontWeightRegular
      },
      "&:hover": {
          backgroundColor: 'inherit',
      },
  },
  group: {
      marginLeft: theme.spacing(2),
      "& $content": {
          paddingLeft: 0
      }
  },
  expanded: {
      '> & ': {
        backgroundColor: 'inherit',
      }
  },
  label: {
      width : 'calc(100% - 30px)'
  },
    selected : {

    }
}));

const TreeItem = function (props) {
  const classes = useTreeItemStyles();
  const {
    color,
    bgColor,
    renderNode,
    index,
    ...other
  } = props;

  const { onScrollEnd, scrollTo, theme, LabelIcon, labelInfo, renderActions, node, renderLabel, onNodeClick, labelText, isNodeFocused, ...rest } = other;

  return (
    <BaseTreeItem
      label={
        renderNode ? renderNode(props, index) : (
          <div className={classes.labelRoot} onClick={onNodeClick ? (ev) => { ev.stopPropagation(); onNodeClick(props);  } : undefined}>
              {LabelIcon}
              {labelText && (
                  <Typography
                      variant="body2"
                      color="inherit"
                      className={classes.labelText}
                  >
                      {renderLabel ? renderLabel(props) : labelText}
                  </Typography>
              )}
              <Typography variant="caption" color="inherit">
                  {labelInfo}
              </Typography>
              {renderActions && renderActions(props)}
          </div>
        )
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label
      }}
      {...rest}
    />
  );
};

TreeItem.defaultProps = {}

TreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
  item: PropTypes.bool
};

const styles = {
  root: (props) => ({
    flexGrow: 1,
    overflowY: 'scroll',
    maxHeight: `calc(100vh - ${props.bootstrap ? (props.hasTabs ? 350 : (350 - 40)) :(props.hasTabs ? 285 : (285 - 40))}px)`,
      ...props.rootStyle
  })
};

const recursiveTreeItem = (node = {}, extraProps = {}, index) => {
  const { children = [] } = node;
  const {hasTabs, focusedNode = '', renderTreeItem, ...rest } = extraProps;
  const child = children.length > 0
      ? children.map((n, index) => recursiveTreeItem(n, extraProps, index))
      : null;

  let treeItem = (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      id={node.id}
      datatest={node.id}
      labelText={node.title}
      node={node}
      {...rest}
      isNodeFocused={focusedNode === node.id}
      index = {index}
    >
      {child}
    </TreeItem>
  );
  return renderTreeItem ? renderTreeItem(node, treeItem, index) : treeItem;
};

const COMPONENT = "NavTree"
class NavTree extends Component {
  static propTypes = {
    classes: PropTypes.any,
    data: PropTypes.any.isRequired,
    renderLabel: PropTypes.func,
    toggleProps: PropTypes.any,
    expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    focusedNode: PropTypes.string,
    onNodeClick: PropTypes.func,
    renderNode: PropTypes.func,
    renderTreeItem: PropTypes.func,
    renderActions: PropTypes.func,
    hasTabs: PropTypes.bool,
    scrollTo: PropTypes.bool,
    onScrollEnd: PropTypes.func,
    rootStyle: PropTypes.object
  };

  static defaultProps = {
    scrollTo: false,
  };

  componentDidMount() {
    const { scrollTo } = this.props;
    if (scrollTo) {
      this.scrollToNode();
    }
  }

  componentDidUpdate(prevProps) {
    const { scrollTo } = this.props;
    if (scrollTo) {
      this.scrollToNode();
    }
  }

  scrollToNode() {
    const { focusedNode, onScrollEnd } = this.props;
    const node = document.getElementById(focusedNode);
      if (node) {
        setTimeout(() => {
            scrollToView(node);
            if (onScrollEnd) {
                onScrollEnd(node);
            }
        }, 300)
      }
  }

  render() {
      traceRenderStart( "", COMPONENT)
    const { classes, data, onNodeToggle, rootStyle, ...rest } = this.props;
    return (
      <TreeView
          datatest={'navListTree'}
        onNodeToggle={onNodeToggle}
        className={classes.root}
        expanded={rest.expanded}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        style={rootStyle}
      >
        {data.map((node, index) => recursiveTreeItem(node, rest, index))}
      </TreeView>
    );
  }
}

export default withStyles(styles, { withTheme: true })(NavTree);

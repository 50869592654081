import {Dialog, DialogContent, DialogTitle} from "@material-ui/core";
import SavedGraph from "./SavedGraph";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {STYLE_GRID_ITEM_SPACING} from "../../Constants";
import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {getSavedVisualisationsOptions} from "./GraphView";
import {getUiLabelTranslation, UI_LABELS_OPEN_GRAPH_VISUALISATION, UI_LABELS_SELECT_VISUALISATION} from "./UILabel";

class VisualisationSelectionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            savedVisualisations : []
        };
    }

    componentDidMount() {
        let {optionsFunction} = this.props;
        let functionToUse = optionsFunction || getSavedVisualisationsOptions;
        functionToUse(this.props.workspace).then(options => this.setState({savedVisualisations: options}));
    }

    renderVisualisationSelectDialog = () => {
        const {savedVisualisations, selectedVisualisation} = this.state;
        const {theme, workspace, label, settings, browseLanguage} = this.props;
        return <Dialog
            aria-labelledby="Select Visualisation"
            open={true}
            fullWidth={true}
            maxWidth={'xs'}
            datatest={'selectVisualisationDialog'}
        >
            <DialogTitle id="form-dialog-title"> {label || getUiLabelTranslation(settings, UI_LABELS_SELECT_VISUALISATION, browseLanguage, UI_LABELS_SELECT_VISUALISATION)}</DialogTitle>
            <DialogContent>
                <div style={{height: '100px'}}>
                    <SavedGraph
                        label={label || 'Select Visualisation'}
                        workspace={workspace}
                        options={savedVisualisations || []}
                        value={selectedVisualisation}
                        onChange={(event, newValue, reason) => {
                            this.setState({selectedVisualisation: newValue});
                        }}

                    ></SavedGraph>
                </div>
            </DialogContent>
            <DialogActions>
                <Button datatest={'cancelButton'} variant={"outlined"} onClick={() => {
                    this.props.onCancel();
                }} color="secondary">Cancel</Button>
                <Button disabled={!selectedVisualisation} datatest={'selectButton'}
                        style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"}
                        color="secondary" onClick={() => {
                            this.props.onSelect(selectedVisualisation);
                }}>Open</Button>
            </DialogActions>
        </Dialog>;
    }

    render() {
        return this.renderVisualisationSelectDialog();
    }
}

VisualisationSelectionDialog.propTypes = {
    settings: PropTypes.object,
    browseLanguage: PropTypes.object,
    workspace: PropTypes.object,
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
    optionsFunction: PropTypes.func,
    label: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(VisualisationSelectionDialog);

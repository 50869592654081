import React, {Component} from "react";
import {getAllConfigurations} from "../../service/graph-api";
import GlobalsContext from "../../components/GlobalsContext";
import {LinearProgress} from "@material-ui/core";
import uuid4 from "uuid/v4";
import {PermissionService} from "../../service/permission-service";


export const GLOBAL_CONTEXT_ALL_CONFIGURATIONS = 'GLOBAL_CONTEXT_ALL_CONFIGURATIONS';
export const GLOBAL_CONTEXT_PERMISSIONS_SERVICE = 'GLOBAL_CONTEXT_PERMISSIONS_SERVICE';


export function withGlobalContext(WrappedComponent) {

    return class extends Component {
        constructor(props) {
            super(props);
            this.state = {
                globals: {}
            }
        }

        componentDidMount() {
            getAllConfigurations().then(allConfigurations => {
                this.setGlobalsItem(GLOBAL_CONTEXT_ALL_CONFIGURATIONS, allConfigurations);
                this.setGlobalsItem(GLOBAL_CONTEXT_PERMISSIONS_SERVICE, new PermissionService(allConfigurations));
                this.setState({keyId : uuid4()});
            });
        }

        setGlobalsItem = (key, value) => {
            let {globals} = this.state
            let currentValue = globals && globals[key]
            if (value === undefined && currentValue === undefined) {
                return;
            } else {
                if (currentValue !== value && globals) {
                    globals[key] = value
                    //this.setState({globals})
                }
            }
        }

        getGlobals = () => {
            let {globals} = this.state
            return globals;
        }

        getGlobalsItem = (key) => {
            let {globals} = this.state
            let value = globals[key]
            return value;
        }


        render() {
            let {keyId} = this.state;
            //wait for configurations load
            return keyId === undefined ? <LinearProgress/> : <React.Fragment key={keyId}>
                <GlobalsContext.Provider value={{
                    get: this.getGlobals,
                    getGlobalsItem: this.getGlobalsItem,
                    setGlobalsItem: this.setGlobalsItem
                }}>
                    <WrappedComponent {...this.props} getGlobalsItem={this.getGlobalsItem} setGlobalsItem={this.setGlobalsItem}/>
                </GlobalsContext.Provider>
            </React.Fragment>;
        }
    }

}

import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withEvent} from "./Event";
import {getSavedVisualisationsOptions, ZINDEX_NODE_DETAILS_FORM_DATA} from "./GraphView";
import FieldContainer from "../../components/FieldContainer";
import {centerVertically, sort} from "../../components/util";
import H3Title from "../../components/H3Title";
import {getUiLabelTranslation, UI_LABELS_SETTINGS} from "./UILabel";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {FormControlLabel, Grid, InputLabel, MenuItem, Switch, TextField} from "@material-ui/core";
import PropTypes from "prop-types";
import SavedGraph from "./SavedGraph";
import {ELEMENT_SETTINGS} from "./GraphViewStyleSettings";
import {SettingsOutlined} from "@material-ui/icons";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import H4Title from "../../components/H4Title";

export function getOnClickAction(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['onClickAction'];
}

export function getOnClickActionValue(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['onClickActionValue'];
}

export function getOnExpandButtonClickAction(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['onExpandButtonClickAction'];
}

export function getOnExpandButtonClick(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['onExpandButtonClick'];
}

export function hideIncomingConnections(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['hideIncomingConnectionsButton'] === true;
}

export function hideOutgoingConnections(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['hideOutgoingConnectionsButton'] === true;
}

export function hideMoreButton(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['hideMoreButton'] === true;
}

export function hideStyleButton(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['hideStyleButton'] === true;
}

export function showExpandButton(graphStyle, id) {
    return graphStyle[ELEMENT_SETTINGS]?.[id]?.['hideExpandButton'] === false;
}

export const ON_CLICK_VALUE_SHOW_DATA = "ON_CLICK_SHOW_DATA";
export const ON_CLICK_VALUE_OPEN_VISUALISATION = "ON_CLICK_OPEN_VISUALISATION";
export const ON_CLICK_VALUE_OPEN_PAGE = "ON_CLICK_OPEN_PAGE";


const ON_CLICK_ACTIONS = sort([
    {label : 'Show Resource Data' , value : ON_CLICK_VALUE_SHOW_DATA},
    {label : 'Open Visualisation', value : ON_CLICK_VALUE_OPEN_VISUALISATION},
    {label : 'Open Page', value : ON_CLICK_VALUE_OPEN_PAGE}
], 'label');

class GraphViewNodeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            graphSettings  : {},
            savedVisualisations : []
        }
    }

    componentDidMount() {
        getSavedVisualisationsOptions(this.props.workspace).then(options => this.setState({savedVisualisations: options}));
    }

    getTranslation = (labelKey) => {
        let {settings, browseLanguage} = this.props;
        return getUiLabelTranslation(settings, labelKey, browseLanguage, labelKey);
    }

    handleSwitchChange = (ev) => {
        let {checked, name} = ev.target;
        this.setValue(name, checked);
    }

    setValue = (settingKey, value,) => {
        let {onSettingsChange, graphStyle, openFor} = this.props;
        if (!graphStyle[ELEMENT_SETTINGS]) {
            graphStyle[ELEMENT_SETTINGS] = {};
        }
        if (!graphStyle[ELEMENT_SETTINGS][openFor.elementId]) {
            graphStyle[ELEMENT_SETTINGS][openFor.elementId] = {};
        }
        const graphStyleForElement = graphStyle?.[ELEMENT_SETTINGS]?.[openFor.elementId];
        graphStyleForElement[settingKey] = value;
        this.setState({}, () => onSettingsChange());
    }

    getValue = (settingKey) => {
        let {graphStyle, openFor} = this.props;
        const graphStyleForElement = graphStyle?.[ELEMENT_SETTINGS]?.[openFor.elementId];
        return graphStyleForElement?.[settingKey];
    }

    render() {
        let {onClose, openFor, theme, styleContainerWidth, containerStyle, workspace,  } = this.props;
        let { savedVisualisations, onClickAction} = this.state;
        const elementId = openFor.elementId;
        let containerStyleToApply = containerStyle ? containerStyle : {};
        const onExpandButtonClickVisualisation = savedVisualisations.find(to => to.value === this.getValue('onExpandButtonClick')?.Visualisation);
        const onExpandButtonClickActionValue = this.getValue('onExpandButtonClickAction')  || ON_CLICK_VALUE_SHOW_DATA;
        const onClickActionValue = this.getValue('onClickAction')  || ON_CLICK_VALUE_SHOW_DATA;
        const onClickActionValueVisualisation = savedVisualisations.find(to => to.value === this.getValue('onClickActionValue')?.Visualisation);

        return <div datatest={'graphViewNodeSettings'} key={elementId} style={{
            flexGrow : '1',
            padding: '8px',
            backgroundColor: theme.palette.white.main,
            borderRadius: '4px',
            zIndex : ZINDEX_NODE_DETAILS_FORM_DATA,
            ...containerStyleToApply
        }}>
            <FieldContainer  style={{
                padding : '0px',
                backgroundColor: theme.palette.grey.background,
                height: '100%',
                ...containerStyleToApply
            }}>
                <div style={{display: 'flex', padding : '4px'}}>
                    {centerVertically(<SettingsOutlined></SettingsOutlined>)}
                    {centerVertically(<H3Title>{this.getTranslation(UI_LABELS_SETTINGS)}</H3Title>, {marginLeft : '8px'})}
                    <div style={{flexGrow: '1'}}></div>
                    {
                        onClose &&
                        <Tooltip title={'Close'}>
                            <IconButton datatest={'closeNodeSettingsButton'} size={'small'} onClick={onClose}>
                                <CloseIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </div>
                <div style={{ height: "calc(100% - 56px)", maxWidth : `${styleContainerWidth - 28}px`, overflowY : 'auto', overflowX : 'auto', borderRadius : '4px', margin : '0px 8px 8px 8px', paddingBottom : '8px', backgroundColor: theme.palette.white.main}}>
                    <FieldContainer style={{
                        backgroundColor: theme.palette.white.main,
                    }}>
                        <Grid container spacing={2}>
                            <Grid datatest={'incomingConnections'} item xs={12}>
                                <H4Title title={'Incoming Connections Button'}></H4Title>
                                <FieldContainer style={{}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        datatest={'hideIncomingConnectionsButton'}
                                                        name={'hideIncomingConnectionsButton'}
                                                        size="small"
                                                        checked={this.getValue('hideIncomingConnectionsButton')}
                                                        onChange={this.handleSwitchChange}
                                                    />
                                                }
                                                label={'Hide'}
                                            />
                                        </Grid>
                                    </Grid>
                                </FieldContainer>
                            </Grid>
                            <Grid datatest={'nodeSettings'} item xs={12}>
                                <H4Title title={'Outgoing Connections Button'}></H4Title>
                                <FieldContainer style={{}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        datatest={'hideOutgoingConnectionsButton'}
                                                        name={'hideOutgoingConnectionsButton'}
                                                        size="small"
                                                        checked={this.getValue('hideOutgoingConnectionsButton')}
                                                        onChange={this.handleSwitchChange}
                                                    />
                                                }
                                                label={'Hide'}
                                            />
                                        </Grid>
                                    </Grid>
                                </FieldContainer>
                            </Grid>
                            <Grid datatest={'nodeSettings'} item xs={12}>
                                <H4Title title={'More Options Button'}></H4Title>
                                <FieldContainer style={{}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        datatest={'hideMoreButton'}
                                                        name={'hideMoreButton'}
                                                        size="small"
                                                        checked={this.getValue('hideMoreButton')}
                                                        onChange={this.handleSwitchChange}
                                                    />
                                                }
                                                label={'Hide'}
                                            />
                                        </Grid>
                                    </Grid>
                                </FieldContainer>
                            </Grid>
                            <Grid datatest={'nodeSettings'} item xs={12}>
                                <H4Title title={'Style Button'}></H4Title>
                                <FieldContainer style={{}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        datatest={'hideStyleButton'}
                                                        name={'hideStyleButton'}
                                                        size="small"
                                                        checked={this.getValue('hideStyleButton')}
                                                        onChange={this.handleSwitchChange}
                                                    />
                                                }
                                                label={'Hide'}
                                            />
                                        </Grid>
                                    </Grid>
                                </FieldContainer>
                            </Grid>
                            <Grid datatest={'nodeSettings'} item xs={12}>
                                <H4Title title={'Expand Button'}></H4Title>
                                <FieldContainer style={{}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        datatest={'hideExpandButton'}
                                                        name={'hideExpandButton'}
                                                        size="small"
                                                        checked={this.getValue('hideExpandButton') !== false }
                                                        onChange={this.handleSwitchChange}
                                                    />
                                                }
                                                label={'Hide'}
                                            />
                                        </Grid>
                                        {   this.getValue('hideExpandButton') !== false ? <></> :
                                            <>
                                                <Grid item xs={12}>
                                                    <FormControl fullWidth={true} size={'small'} variant="outlined">
                                                        <InputLabel htmlFor="onExpandButtonClickAction">On Expand Button Click</InputLabel>
                                                        <Select
                                                            datatest={'onExpandButtonClickAction'}
                                                            value={onExpandButtonClickActionValue }
                                                            onChange={(event) => {
                                                                this.setValue('onExpandButtonClickAction', event.target.value);
                                                            }}
                                                            label="On Expand Button Click"
                                                            inputProps={{
                                                                name: 'onExpandButtonClickAction',
                                                                id: 'onExpandButtonClickAction',
                                                            }}
                                                        >
                                                            {ON_CLICK_ACTIONS.map(lo => <MenuItem key={lo.value} value={lo.value}>{lo.label}</MenuItem>)}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        onExpandButtonClickActionValue === ON_CLICK_VALUE_OPEN_VISUALISATION
                                                            ? <SavedGraph
                                                                label={'Visualisation'}
                                                                workspace={workspace}
                                                                options={savedVisualisations || []}
                                                                value={onExpandButtonClickVisualisation}
                                                                onChange={(event, newValue, reason) => {
                                                                    this.setValue('onExpandButtonClick', {'Visualisation' : newValue.value});
                                                                }}
                                                            ></SavedGraph>
                                                            : onExpandButtonClickActionValue === ON_CLICK_VALUE_OPEN_PAGE
                                                                ? <TextField
                                                                    fullWidth={true}
                                                                    name={''}
                                                                    datatest={'onExpandPageURL'}
                                                                    label={'Page URL'}
                                                                    value={(this.getValue('onExpandButtonClick')?.URL ||  '')}
                                                                    onChange={(ev) => {
                                                                        let {value} = ev.target;
                                                                        this.setValue('onExpandButtonClick', { 'URL' : value});
                                                                    }}
                                                                ></TextField>
                                                                : <></>

                                                    }
                                                </Grid>
                                            </>
                                        }
                                    </Grid>
                                </FieldContainer>
                            </Grid>
                            <Grid datatest={'nodeSettings'} item xs={12}>
                                <H4Title title={'On Node Click'}></H4Title>
                                <FieldContainer style={{}}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <FormControl fullWidth={true} size={'small'} variant="outlined">
                                                <InputLabel htmlFor="onClickAction">Action</InputLabel>
                                                <Select
                                                    datatest={'onClickAction'}
                                                    value={onClickActionValue }
                                                    onChange={(event) => {
                                                        this.setValue('onClickAction', event.target.value);
                                                    }}
                                                    label="Action"
                                                    inputProps={{
                                                        name: 'onClickAction',
                                                        id: 'onClickAction',
                                                    }}
                                                >
                                                    {ON_CLICK_ACTIONS.map(lo => <MenuItem key={lo.value} value={lo.value}>{lo.label}</MenuItem>)}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                onClickActionValue === ON_CLICK_VALUE_OPEN_VISUALISATION
                                                    ? <SavedGraph
                                                        datatest={'visualisationSelect'}
                                                        label={'Visualisation'}
                                                        workspace={workspace}
                                                        options={savedVisualisations || []}
                                                        value={onClickActionValueVisualisation}
                                                        onChange={(event, newValue, reason) => {
                                                            this.setValue('onClickActionValue', {'Visualisation' : newValue.value});
                                                        }}
                                                    ></SavedGraph>
                                                    : onClickActionValue === ON_CLICK_VALUE_OPEN_PAGE
                                                        ? <TextField
                                                            fullWidth={true}
                                                            name={''}
                                                            label={'Page URL'}
                                                            value={(this.getValue('onClickActionValue')?.URL ||  '')}
                                                            onChange={(ev) => {
                                                                let {value} = ev.target;
                                                                this.setValue('onClickActionValue', { 'URL' : value});
                                                            }}
                                                        ></TextField>
                                                        : <></>

                                            }
                                        </Grid>
                                    </Grid>
                                </FieldContainer>
                            </Grid>
                        </Grid>
                    </FieldContainer>
                </div>
            </FieldContainer>
        </div>;
    }

}

GraphViewNodeSettings.propTypes = {
    workspace: PropTypes.any,
    minimized: PropTypes.any,
    browseLanguage: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.array,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    openFor: PropTypes.any,
    graphStyle: PropTypes.object,
    onClose: PropTypes.func,
    onSettingsChange: PropTypes.func,
    styleContainerWidth: PropTypes.any,
    elementObject: PropTypes.any,
    containerStyle: PropTypes.any,
    hideProperty: PropTypes.any,
};


export default withStyles(styles, {withTheme: true})(withEvent(GraphViewNodeSettings));

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {getAllConfigurations, getData, getEndpointWithInstanceAndDataset, graphSearch} from "../../service/graph-api";
import {styles} from "../../components/styles";
import {
    getApiConfigurationResource,
    getContainerData,
    getDatasetLabel,
    getDatatypeProperties,
    getExtensionRulesData,
    getGraph,
    getObjectProperties,
    getOntologyClasses,
    getRouteWithInstanceAndDataset,
    getShapesData,
    handleAPIResponse,
    handleBackendError
} from "../../components/util";
import {Bar, BarChart, CartesianGrid, Cell, Tooltip, XAxis, YAxis} from 'recharts';
import {
    ALIAS_MANAGEMENT_CAN_VIEW,
    ALIAS_MANAGEMENT_ID_LABEL,
    ALIAS_MANAGEMENT_TYPE_USER,
    ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI,
    ALIAS_SYS_DATA_DOMAIN_BASE_IRI,
    ALIAS_SYS_DEFAULT_LANGUAGE,
    ALIAS_SYS_ENABLE_DATA_RESET,
    ALIAS_SYS_IDENTIFIER,
    ALIAS_SYS_TYPE_ROLE,
    AUTHENTICATION,
    AUTHORISATION,
    CLASSES,
    CONFIGURE_CONTAINERS,
    EXPORT,
    IMPORT,
    INVERSE_PROPERTIES,
    LABEL_BASE_IRI_FOR_DATA,
    LABEL_BASE_IRI_FOR_SCHEMA,
    LABEL_DATA_RESET_TYPE_YES,
    LABEL_DEFAULT_LANGUAGE,
    ONTOLOGY,
    PREFIXES,
    ROLES,
    ROUTE_CONFIGURATION_MANAGER_CONTAINER,
    ROUTE_CONFIGURATION_MANAGER_EXPORT,
    ROUTE_CONFIGURATION_MANAGER_HOME,
    ROUTE_CONFIGURATION_MANAGER_IMPORT,
    ROUTE_CONFIGURATION_MANAGER_INVERSE,
    ROUTE_CONFIGURATION_MANAGER_ONTOLOGY,
    ROUTE_CONFIGURATION_MANAGER_PREFIXES,
    ROUTE_CONFIGURATION_MANAGER_RULE,
    ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHENTICATION,
    ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHORISATION,
    ROUTE_CONFIGURATION_MANAGER_SECURITY_ROLES,
    ROUTE_CONFIGURATION_MANAGER_SHAPE,
    RULES,
    SHAPES,
    SYSTEM_MANAGER
} from "../../Constants";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import MenuColumnAndDetailsLayout from "../../layouts/MenuColumnAndDetailsLayout";
import {canUpdateConfigs, isSuperadmin} from "../../layouts/common/Profile";
import history from "../../history";
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined';
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import GavelOutlinedIcon from '@material-ui/icons/GavelOutlined';
import PublishIcon from "@material-ui/icons/Publish";
import GetAppIcon from "@material-ui/icons/GetApp";
import H1Title from "../../components/H1Title";
import ConfigurationEmptyDialog from "../../components/ConfigurationEmptyDialog";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import BlockOutlinedIcon from '@material-ui/icons/BlockOutlined';
import H2Title from "../../components/H2Title";
import {renderChart} from "../../layouts/common/Monitoring";
import {BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH} from "../../service/backend-paths";
import TextField from "../../components/TextField";
import {isMultipleDatasetsInstance} from "../../Configs";
import {ShortTextOutlined} from "@material-ui/icons";

export function navigateToRoute(route) {
    return () => history.push(`${getRouteWithInstanceAndDataset(route)}`);
}

export function getLeftMenuItems(theme, isConfigurationEmpty) {
    let iconStyle =  {color: theme.palette.white.main}
    let items = [
        {
            disabled : isConfigurationEmpty,
            label: 'Home',
            icon : <HomeOutlinedIcon style={iconStyle}/>,
            onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_HOME)
        }
    ];
    items.push({
        disabled : isConfigurationEmpty,
        label: CONFIGURE_CONTAINERS,
        icon : <VpnKeyOutlinedIcon style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_CONTAINER)
    })
    items.push({
        disabled : isConfigurationEmpty,
        label: PREFIXES,
        icon : <ShortTextOutlined style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_PREFIXES)
    })
    items.push({
        disabled : isConfigurationEmpty,
        label: ONTOLOGY,
        icon : <CategoryOutlinedIcon style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_ONTOLOGY)
    })
    items.push({
        disabled : isConfigurationEmpty,
        label: SHAPES,
        icon : <ListAltOutlinedIcon style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_SHAPE)
    })
    items.push({
        disabled : isConfigurationEmpty,
        label: INVERSE_PROPERTIES,
        icon : <SyncAltOutlinedIcon style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_INVERSE)
    })
    items.push({
        disabled : isConfigurationEmpty,
        label: 'Rules',
        icon : <GavelOutlinedIcon style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_RULE)
    })
    if(canUpdateConfigs()) {
        items.push({
            label: IMPORT,
            icon: <PublishIcon style={iconStyle}/>,
            onClick: navigateToRoute(ROUTE_CONFIGURATION_MANAGER_IMPORT)
        })
    }
    items.push({
        disabled : isConfigurationEmpty,
        label: EXPORT,
        icon : <GetAppIcon style={iconStyle}/>,
        onClick : navigateToRoute(ROUTE_CONFIGURATION_MANAGER_EXPORT)
    })
    items.push({
        disabled : isConfigurationEmpty,
        label: AUTHENTICATION,
        icon: <VerifiedUserOutlinedIcon style={iconStyle}/>,
        onClick: navigateToRoute(ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHENTICATION)
    })
    if(isSuperadmin()) {
        items.push({
            disabled : isConfigurationEmpty,
            label: AUTHORISATION,
            icon: <BlockOutlinedIcon style={iconStyle}/>,
            onClick: navigateToRoute(ROUTE_CONFIGURATION_MANAGER_SECURITY_AUTHORISATION)
        })
        items.push({
            disabled : isConfigurationEmpty,
            label: ROLES,
            icon: <AssignmentIndOutlinedIcon style={iconStyle}/>,
            onClick: navigateToRoute(ROUTE_CONFIGURATION_MANAGER_SECURITY_ROLES)
        })
    }

    return items;
}


const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${x + width / 2}, ${y}
          C${x + width / 2},${y + height / 3} ${x + 2 * width / 3},${y + height} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
    const {
        fill, x, y, width, height,
    } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill}/>;
};

TriangleBar.propTypes = {
    fill: PropTypes.string,
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
};

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload) {
        return (
            <div className="custom-tooltip">
                <p className="label">{`${label} : ${payload[0].value}`}</p>
            </div>
        );
    }

    return null;
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {loading: false, data:[], cpuUsage: []}
    }

    componentDidMount() {
        this.viewStat();
    }

    viewStat = async () => {
        this.setState({loading:true})
        getAllConfigurations().then(async r => {
            const apiConfiguration = getApiConfigurationResource(r);
            let defaultLanguage = apiConfiguration[ALIAS_SYS_DEFAULT_LANGUAGE];
            let dataDomainBaseIRI = apiConfiguration[ALIAS_SYS_DATA_DOMAIN_BASE_IRI];
            let configurationDomainBaseIRI = apiConfiguration[ALIAS_SYS_CONFIGURATION_DOMAIN_BASE_IRI];
            let dataReset = apiConfiguration[ALIAS_SYS_ENABLE_DATA_RESET] === 'true' && 'YES';

            const data = [
                {name: CLASSES, count: getOntologyClasses(r).length},
                {name: CONFIGURE_CONTAINERS, count: getContainerData(r).filter(c => c[ALIAS_SYS_IDENTIFIER] === 'true').length},
                {name: "Object properties", count: getObjectProperties(r).length},
                {name: "Datatype properties", count: getDatatypeProperties(r).length},
                {name: SHAPES, count: getShapesData(r).length},
                {name: RULES, count: getExtensionRulesData(r).length}
            ];
            this.setState({
                loading:false,
                columnAction: "viewStat",
                data: data,
                dataDomainBaseIRI,
                configurationDomainBaseIRI,
                dataReset,
                defaultLanguage
            });
            if(getGraph(r).length > 0) {
                await this.searchUsersAndRolesStat();
            } else {
                this.setState({isConfigurationEmpty : true})
            }
        }).catch((e) => {
            this.setState({loading: false, apiErrorResponse: e, apiError: true});
        })
    }

    searchUsersAndRolesStat = async () => {
        if(isSuperadmin()) {
            let paramsMap = {
                query : isMultipleDatasetsInstance()
                    ? `{type(eq:[${ALIAS_MANAGEMENT_TYPE_USER}]) ${ALIAS_MANAGEMENT_CAN_VIEW}{ ${ALIAS_MANAGEMENT_ID_LABEL}(eq:["${getDatasetLabel()}"])}}`
                    : `{type(eq:[${ALIAS_MANAGEMENT_TYPE_USER}])}`,
                page : 1,
                pageSize : 1,
                countUpto: 1000

            };

            getData(getEndpointWithInstanceAndDataset(), BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH, paramsMap)
                .then((apiResponse) => {
                    handleAPIResponse(
                        this,
                        apiResponse,
                        () => {
                            apiResponse.json().then((response) => {
                                let count = response.egTotalCount;
                                this.setState({
                                    userCount: count
                                })
                            }).catch((e) => {
                                this.setState({loading: false, apiErrorResponse: e, apiError: true});
                            })
                        }
                    )

                }).catch(handleBackendError(this));


            graphSearch(`{type(eq:["${ALIAS_SYS_TYPE_ROLE}"])}`, undefined, undefined, undefined, undefined, 1, 1, 1000)
                .then((apiResponse) => {
                    handleAPIResponse(
                        this,
                        apiResponse,
                        () => {
                            apiResponse.json().then((response) => {
                                let count = response.egTotalCount;
                                this.setState({
                                    rolesCount: count
                                })
                            }).catch((e) => {
                                this.setState({loading: false, apiErrorResponse: e, apiError: true});
                            })
                        },
                        () => {
                            apiResponse.json().then((response) => {
                                let message = response.message && response.message.includes("Configuration is empty")
                                    ? response.message : response;
                                this.setState({loading: false, apiErrorResponse: message, apiError: true});
                            }).catch((e) => {
                                this.setState({loading: false, apiErrorResponse: e, apiError: true});
                            })
                        },
                    )

                }).catch(handleBackendError(this));
        }

    }

    pad = (value) => {
        if(!value || value <= 9) {
            return '0'+value;
        } else {
            return value;
        }
    }

    getConfigurationStatChart = () => {
        const {theme} = this.props;
        const {data} = this.state;
        return renderChart(theme,'Configuration Statistics', () => {
            return <BarChart
                data={data}
                margin={{
                    top: 10, right: 30, left: 0, bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="name"/>
                <YAxis/>
                <Tooltip content={<CustomTooltip/>}/>
                <Bar dataKey="count" fill="#8884d8"  label={{position: 'top'}}>
                    {
                        data.map((entry, index) => {
                            const hue = (index % 20 + 1) * 137.508; // use golden angle approximation
                            const color = `hsl(${hue},${Math.floor(1*20) + 40}%, ${Math.floor(1*30) + 20}%)`;

                            return <Cell key={`cell-${index}`} fill={color}/>;
                        })
                    }
                </Bar>
            </BarChart>
        });
    }

    getMiddleComponent = () => {
        let {loading, isConfigurationEmpty} = this.state;
        let {dataDomainBaseIRI, configurationDomainBaseIRI, defaultLanguage, dataReset} = this.state;
        let {theme} = this.props;

        return loading
            ? <ProcessingBackdrop loading={true}/>
            : <div style={{height: 'calc(100% - 48px)', paddingRight: '64px', display :'flex', flexDirection : 'column'}}>
                <div>
                    {isConfigurationEmpty && <ConfigurationEmptyDialog open={isConfigurationEmpty}/>}
                    <Grid xs container spacing={2}>
                        <Grid item xs={12}>
                            <H1Title title={'Home'} color={'primary'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{
                                display: 'flex',
                                padding: '24px 8px',
                                backgroundColor: theme.palette.white.main,
                                borderRadius: '4px'
                            }}>
                                <Grid container spacing={2} xs={12}>
                                    <Grid item xs={12}>
                                        <H2Title title={'Basics'}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id={'dataDomainBaseIRI'}
                                            label={LABEL_BASE_IRI_FOR_DATA}
                                            name='dataDomainBaseIRI'
                                            value={dataDomainBaseIRI}
                                            readOnly={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='configurationDomainBaseIRI'
                                            label={LABEL_BASE_IRI_FOR_SCHEMA}
                                            name='configurationDomainBaseIRI'
                                            value={configurationDomainBaseIRI}
                                            readOnly={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='defaultLanguage'
                                            label={LABEL_DEFAULT_LANGUAGE}
                                            name='defaultLanguage'
                                            value={defaultLanguage}
                                            readOnly={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            id='dataReset'
                                            label={LABEL_DATA_RESET_TYPE_YES}
                                            name='dataReset'
                                            value={dataReset}
                                            readOnly={true}
                                        />
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                        <Grid item xs={12}>{
                            this.getConfigurationStatChart()
                        }</Grid>
                        {
                            isSuperadmin() &&
                            <>
                                <Grid item xs={6}>
                                    <div style={{
                                        display: 'flex',
                                        padding: '24px 8px',
                                        backgroundColor: theme.palette.white.main,
                                        borderRadius: '4px'
                                    }}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}><H2Title title={'User Count'}/></Grid>
                                            <Grid datatest={'userCount'} item xs={6}>
                                                <H2Title title={this.state.userCount || ''}/>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{
                                        display: 'flex',
                                        padding: '24px 8px',
                                        backgroundColor: theme.palette.white.main,
                                        borderRadius: '4px'
                                    }}>
                                        <Grid container xs={12}>
                                            <Grid item xs={6}><H2Title title={'Role Count'}/></Grid>
                                            <Grid datatest={'roleCount'} item xs={6}><H2Title title={this.state.rolesCount || ''}/></Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </>
                        }
                    </Grid>
                </div>
            </div>;
    }

    render() {
        const {theme, location } = this.props;

        return (
            <MenuColumnAndDetailsLayout
                apiError={this.state.apiError}
                apiErrorResponse={this.state.apiErrorResponse}
                onApiErrorClose={() => this.setState({apiError:false, apiErrorResponse: undefined})}

                headerTitle={SYSTEM_MANAGER}
                leftMenuItems={getLeftMenuItems(theme)}
                leftColumnRenderer={undefined}
                detailRenderer={this.getMiddleComponent}
                location={location}
            />

        );

    }

}

export default withStyles(styles, {withTheme: true})(Home);

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {HTTP_HEADER_IF_NONE_MATCH} from "../../Constants";
import PropTypes from "prop-types";
import {TextField as OtherTextField} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FieldContainer from "../../components/FieldContainer";
import GlobalsContext from "../../components/GlobalsContext";
import {getUiLabelTranslationFromContext} from "../../components/util";
import {UI_LABELS_FETCH_ETAG_FOR_PAGE, UI_LABELS_IF_NONE_MATCH} from "../navigator/UILabel";

class SearchConditionalGet extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {classes, theme, otherOptions, onChange} = this.props
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.largeWidthPropertyLabelRoot
                }
            }
            : {};

        return (<div datatest={'conditionalGetTabContent'}>
                <FieldContainer style={{marginTop:theme.spacing(1)}}>
                        <OtherTextField
                            datatest={'textField-If None Match'}
                            margin={"dense"}
                            variant="outlined"
                            fullWidth
                            label={getUiLabelTranslationFromContext(this, UI_LABELS_IF_NONE_MATCH)}
                            value={otherOptions[HTTP_HEADER_IF_NONE_MATCH]}
                            onChange={(event) => {
                                otherOptions[HTTP_HEADER_IF_NONE_MATCH] = event.target.value
                                onChange(otherOptions)
                            }}
                            InputLabelProps={inputLabelProps}
                        />

                </FieldContainer>
                <FieldContainer style={{ marginTop:theme.spacing(1)}}>
                        <FormControlLabel
                            style={{marginLeft : '1px'}}
                            control={
                                <Switch
                                    datatest={'eTagAll'}
                                    key={'node-action-1'}
                                    size="small"
                                    checked={otherOptions.etagAll || false}
                                    value={otherOptions.etagAll}
                                    name="etagAll"
                                    onChange={(value) => {
                                        otherOptions.etagAll = !otherOptions.etagAll;
                                        onChange(otherOptions);
                                    }}
                                ></Switch>
                            }
                            label={getUiLabelTranslationFromContext(this, UI_LABELS_FETCH_ETAG_FOR_PAGE)}
                        />
                </FieldContainer>
        </div>);

    }

}

SearchConditionalGet.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    otherOptions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(SearchConditionalGet);

import {IconButton, TextField} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";
import React from "react";

export function DashBoardSearch({searchText, onSearchTextChange, placeholder, onSearch, style = {}}) {

    return <TextField

        datatest={'dashboardSearch'}
        style={{minWidth : '270px', maxWidth : '270px', ...style}}
        fullWidth={true}
        value={searchText}
        margin={"dense"}
        placeholder={placeholder}
        onChange={(event) => {
            onSearchTextChange(event.target.value, event, undefined)
        }}
        onKeyPress={(ev) => {
            if (ev.key === 'Enter') {
                onSearch();
                ev.preventDefault();
            }
        }}
        InputProps={{
            endAdornment : <>
                {
                    searchText && <IconButton title={'Clear'} datatest={'clearFilter'} size={'small'} onClick={(ev) => {
                        onSearchTextChange('', ev, onSearch);
                    }}>
                        <Tooltip title={"Clear Filter"}>
                            <ClearIcon color={"inherit"}/>
                        </Tooltip>
                    </IconButton>
                }
                <IconButton
                    datatest={'applyFilter'}
                    size={'small'}
                    onClick={onSearch}
                >
                    <Tooltip title={"Apply filter"}>
                        <SearchIcon color={"secondary"}/>
                    </Tooltip>
                </IconButton>
            </>
        }}
    />;
}

export const prefixes = {
    "madsrdf": "http://www.loc.gov/mads/rdf/v1#",
    "bflc": "http://id.loc.gov/ontologies/bflc/",
    "rdf": "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
    "foaf": "http://xmlns.com/foaf/0.1/",
    "yago": "http://yago-knowledge.org/resource/",
    "rdfs": "http://www.w3.org/2000/01/rdf-schema#",
    "dbo": "http://dbpedia.org/ontology/",
    "dbp": "http://dbpedia.org/property/",
    "dc": "http://purl.org/dc/elements/1.1/",
    "gr": "http://purl.org/goodrelations/v1#",
    "owl": "http://www.w3.org/2002/07/owl#",
    "spacerel": "http://data.ordnancesurvey.co.uk/ontology/spatialrelations/",
    "skos": "http://www.w3.org/2004/02/skos/core#",
    "geo": "http://www.w3.org/2003/01/geo/wgs84_pos#",
    "dcat": "http://www.w3.org/ns/dcat#",
    "xsd": "http://www.w3.org/2001/XMLSchema#",
    "bf": "http://id.loc.gov/ontologies/bibframe/",
    "sd": "http://www.w3.org/ns/sparql-service-description#",
    "qb": "http://purl.org/linked-data/cube#",
    "sioc": "http://rdfs.org/sioc/ns#",
    "ont": "http://purl.org/net/ns/ontology-annot#",
    "org": "http://www.w3.org/ns/org#",
    "xtypes": "http://purl.org/xtypes/",
    "dcterms": "http://purl.org/dc/terms/",
    "prov": "http://www.w3.org/ns/prov#",
    "onto": "http://www.ontotext.com/",
    "dbpedia": "http://dbpedia.org/resource/",
    "void": "http://rdfs.org/ns/void#",
    "commerce": "http://search.yahoo.com/searchmonkey/commerce/",
    "dct": "http://purl.org/dc/terms/",
    "rss": "http://purl.org/rss/1.0/",
    "gldp": "http://www.w3.org/ns/people#",
    "bibo": "http://purl.org/ontology/bibo/",
    "event": "http://purl.org/NET/c4dm/event.owl#",
    "wd": "http://www.wikidata.org/entity/",
    "geonames": "http://www.geonames.org/ontology#",
    "fb": "http://rdf.freebase.com/ns/",
    "dcmit": "http://purl.org/dc/dcmitype/",
    "md": "http://www.w3.org/ns/md#",
    "pto": "http://www.productontology.org/id/",
    "sc": "http://purl.org/science/owl/sciencecommons/",
    "schema": "http://schema.org/",
    "vcard": "http://www.w3.org/2006/vcard/ns#",
    "cc": "http://creativecommons.org/ns#",
    "prog": "http://purl.org/prog/",
    "rr": "http://www.w3.org/ns/r2rml#",
    "doap": "http://usefulinc.com/ns/doap#",
    "swrc": "http://swrc.ontoware.org/ontology#",
    "ma": "http://www.w3.org/ns/ma-ont#",
    "nie": "http://www.semanticdesktop.org/ontologies/2007/01/19/nie#",
    "dbr": "http://dbpedia.org/resource/",
    "dbpprop": "http://dbpedia.org/property/",
    "vann": "http://purl.org/vocab/vann/",
    "oo": "http://purl.org/openorg/",
    "http": "http://www.w3.org/2011/http#",
    "content": "http://purl.org/rss/1.0/modules/content/",
    "tl": "http://purl.org/NET/c4dm/timeline.owl#",
    "akt": "http://www.aktors.org/ontology/portal#",
    "fn": "http://www.w3.org/2005/xpath-functions#",
    "vs": "http://www.w3.org/2003/06/sw-vocab-status/ns#",
    "dcterm": "http://purl.org/dc/terms/",
    "wot": "http://xmlns.com/wot/0.1/",
    "swc": "http://data.semanticweb.org/ns/swc/ontology#",
    "marcrel": "http://id.loc.gov/vocabulary/relators/",
    "aiiso": "http://purl.org/vocab/aiiso/schema#",
    "ical": "http://www.w3.org/2002/12/cal/ical#",
    "xmp": "http://ns.adobe.com/xap/1.0/",
    "gen": "http://purl.org/gen/0.1#",
    "earl": "http://www.w3.org/ns/earl#",
    "xhtml": "http://www.w3.org/1999/xhtml#",
    "prop": "http://dbpedia.org/property/",
    "mo": "http://purl.org/ontology/mo/",
    "rel": "http://purl.org/vocab/relationship/",
    "cv": "http://rdfs.org/resume-rdf/",
    "ad": "http://schemas.talis.com/2005/address/schema#",
    "bio": "http://purl.org/vocab/bio/0.1/",
    "dc11": "http://purl.org/dc/elements/1.1/",
    "crm": "http://www.cidoc-crm.org/cidoc-crm/",
    "factbook": "http://wifo5-04.informatik.uni-mannheim.de/factbook/ns#",
    "cyc": "http://sw.opencyc.org/concept/",
    "test2": "http://this.invalid/test2#",
    "bill": "http://www.rdfabout.com/rdf/schema/usbill/",
    "daia": "http://purl.org/ontology/daia/",
    "xhv": "http://www.w3.org/1999/xhtml/vocab#",
    "rdfg": "http://www.w3.org/2004/03/trix/rdfg-1/",
    "d2rq": "http://www.wiwiss.fu-berlin.de/suhl/bizer/D2RQ/0.1#",
    "pc": "http://purl.org/procurement/public-contracts#",
    "cs": "http://purl.org/vocab/changeset/schema#",
    "og": "http://ogp.me/ns#",
    "co": "http://rhizomik.net/ontologies/copyrightonto.owl#",
    "admin": "http://webns.net/mvcb/",
    "sdmxdim": "http://purl.org/linked-data/sdmx/2009/dimension#",
    "air": "http://dig.csail.mit.edu/TAMI/2007/amord/air#",
    "dv": "http://rdf.data-vocabulary.org/#",
    "log": "http://www.w3.org/2000/10/swap/log#",
    "book": "http://purl.org/NET/book/vocab#",
    "media": "http://search.yahoo.com/searchmonkey/media/",
    "ctag": "http://commontag.org/ns#",
    "tzont": "http://www.w3.org/2006/timezone#",
    "oa": "http://www.w3.org/ns/oa#",
    "afn": "http://jena.hpl.hp.com/ARQ/function#",
    "ir": "http://www.ontologydesignpatterns.org/cp/owl/informationrealization.owl#",
    "days": "http://ontologi.es/days#",
    "musim": "http://purl.org/ontology/similarity/",
    "biblio": "http://purl.org/net/biblio#",
    "tag": "http://www.holygoat.co.uk/owl/redwood/0.1/tags/",
    "xfn": "http://gmpg.org/xfn/11#",
    "con": "http://www.w3.org/2000/10/swap/pim/contact#",
    "botany": "http://purl.org/NET/biol/botany#",
    "osag": "http://www.ordnancesurvey.co.uk/ontology/AdministrativeGeography/v2.0/AdministrativeGeography.rdf#",
    "time": "http://www.w3.org/2006/time#",
    "cal": "http://www.w3.org/2002/12/cal/ical#",
    "mu": "http://mu.semte.ch/vocabularies/core/",
    "xs": "http://www.w3.org/2001/XMLSchema#",
    "cld": "http://purl.org/cld/terms/",
    "dcq": "http://purl.org/dc/qualifiers/1.0/",
    "sr": "http://www.openrdf.org/config/repository/sail#",
    "ome": "http://purl.org/ontomedia/core/expression#",
    "reco": "http://purl.org/reco#",
    "sism": "http://purl.oclc.org/NET/sism/0.1/",
    "myspace": "http://purl.org/ontology/myspace#",
    "photoshop": "http://ns.adobe.com/photoshop/1.0/",
    "xf": "http://www.w3.org/2002/xforms/",
    "dir": "http://schemas.talis.com/2005/dir/schema#",
    "af": "http://purl.org/ontology/af/",
    "cmp": "http://www.ontologydesignpatterns.org/cp/owl/componency.owl#",
    "frbr": "http://purl.org/vocab/frbr/core#",
    "ok": "http://okkam.org/terms#",
    "rif": "http://www.w3.org/2007/rif#",
    "lomvoc": "http://ltsc.ieee.org/rdf/lomv1p0/vocabulary#",
    "memo": "http://ontologies.smile.deri.ie/2009/02/27/memo#",
    "wfs": "http://schemas.opengis.net/wfs/",
    "sio": "http://semanticscience.org/resource/",
    "math": "http://www.w3.org/2000/10/swap/math#",
    "rev": "http://purl.org/stuff/rev#",
    "giving": "http://ontologi.es/giving#",
    "dcn": "http://www.w3.org/2007/uwa/context/deliverycontext.owl#",
    "swande": "http://purl.org/swan/1.2/discourse-elements/",
    "owlim": "http://www.ontotext.com/trree/owlim#",
    "swanq": "http://purl.org/swan/1.2/qualifiers/",
    "cfp": "http://sw.deri.org/2005/08/conf/cfp.owl#",
    "gn": "http://www.geonames.org/ontology#",
    "obo": "http://purl.obolibrary.org/obo/",
    "sdmxa": "http://purl.org/linked-data/sdmx/2009/attribute#",
    "jdbc": "http://d2rq.org/terms/jdbc/",
    "exif": "http://www.w3.org/2003/12/exif/ns#",
    "as": "https://www.w3.org/ns/activitystreams#",
    "xsi": "http://www.w3.org/2001/XMLSchema-instance#",
    "ov": "http://open.vocab.org/terms/",
    "swrl": "http://www.w3.org/2003/11/swrl#",
    "sdmx": "http://purl.org/linked-data/sdmx#",
    "swrcfe": "http://www.morelab.deusto.es/ontologies/swrcfe#",
    "om": "http://opendata.caceres.es/def/ontomunicipio#",
    "type": "http://info.deepcarbon.net/schema/type#",
    "adms": "http://www.w3.org/ns/adms#",
    "swrlb": "http://www.w3.org/2003/11/swrlb#",
    "cnt": "http://www.w3.org/2011/content#",
    "cert": "http://www.w3.org/ns/auth/cert#",
    "ore": "http://www.openarchives.org/ore/terms/",
    "sioct": "http://rdfs.org/sioc/types#",
    "scovo": "http://purl.org/NET/scovo#",
    "nfo": "http://www.semanticdesktop.org/ontologies/2007/03/22/nfo#",
    "openlinks": "http://www.openlinksw.com/schemas/virtrdf#",
    "gtfs": "http://vocab.gtfs.org/terms#",
    "siocserv": "http://rdfs.org/sioc/services#",
    "unit": "http://qudt.org/vocab/unit#",
    "lemon": "http://lemon-model.net/lemon#",
    "eat": "http://www.eat.rl.ac.uk/#",
    "coref": "http://www.rkbexplorer.com/ontologies/coref#",
    "voaf": "http://purl.org/vocommons/voaf#",
    "lyou": "http://purl.org/linkingyou/",
    "drugbank": "http://www4.wiwiss.fu-berlin.de/drugbank/resource/drugbank/",
    "sf": "http://www.opengis.net/ont/sf#",
    "omn": "http://open-multinet.info/ontology/omn#",
    "geoes": "http://geo.linkeddata.es/ontology/",
    "ptr": "http://www.w3.org/2009/pointers#",
    "isbd": "http://iflastandards.info/ns/isbd/elements/",
    "pmlj": "http://inference-web.org/2.0/pml-justification.owl#",
    "edm": "http://www.europeana.eu/schemas/edm/",
    "ac": "http://umbel.org/umbel/ac/",
    "fabio": "http://purl.org/spar/fabio/",
    "db": "http://dbpedia.org/",
    "prism": "http://prismstandard.org/namespaces/basic/2.0/",
    "ontology": "http://dbpedia.org/ontology/",
    "ldp": "http://www.w3.org/ns/ldp#",
    "dul": "http://www.ontologydesignpatterns.org/ont/dul/DUL.owl#",
    "acc": "http://purl.org/NET/acc#",
    "qudt": "http://qudt.org/1.1/schema/qudt#",
    "gold": "http://purl.org/linguistics/gold/",
    "aat": "http://vocab.getty.edu/aat/",
    "skosxl": "http://www.w3.org/2008/05/skos-xl#",
    "movie": "http://data.linkedmdb.org/resource/movie/",
    "wn": "http://xmlns.com/wordnet/1.6/",
    "rsa": "http://www.w3.org/ns/auth/rsa#",
    "core": "http://vivoweb.org/ontology/core#",
    "eg": "http://eulergui.sourceforge.net/engine.owl#",
    "geosparql": "http://www.opengis.net/ont/geosparql#",
    "akts": "http://www.aktors.org/ontology/support#",
    "nsogi": "http://prefix.cc/nsogi:",
    "lv": "http://purl.org/lobid/lv#",
    "room": "http://vocab.deri.ie/rooms#",
    "test": "http://test2.example.com/",
    "ssn": "http://www.w3.org/ns/ssn/",
    "wn20schema": "http://www.w3.org/2006/03/wn/wn20/schema/",
    "sider": "http://www4.wiwiss.fu-berlin.de/sider/resource/sider/",
    "gvp": "http://vocab.getty.edu/ontology#",
    "po": "http://purl.org/ontology/po/",
    "acm": "http://www.rkbexplorer.com/ontologies/acm#",
    "music": "http://musicontology.com/",
    "lgd": "http://linkedgeodata.org/triplify/",
    "lexinfo": "http://www.lexinfo.net/ontology/2.0/lexinfo#",
    "zoology": "http://purl.org/NET/biol/zoology#",
    "umbelrc": "http://umbel.org/umbel/rc/",
    "service": "http://purl.org/ontology/service#",
    "loc": "http://www.w3.org/2007/uwa/context/location.owl#",
    "tgn": "http://vocab.getty.edu/tgn/",
    "formats": "http://www.w3.org/ns/formats/",
    "uniprot": "http://purl.uniprot.org/core/",
    "ulan": "http://vocab.getty.edu/ulan/",
    "organism": "http://eulersharp.sourceforge.net/2003/03swap/organism#",
    "dce": "http://purl.org/dc/elements/1.1/",
    "georss": "http://www.georss.org/georss/",
    "cito": "http://purl.org/spar/cito/",
    "dcam": "http://purl.org/dc/dcam/",
    "whois": "http://www.kanzaki.com/ns/whois#",
    "acl": "http://www.w3.org/ns/auth/acl#",
    "space": "http://purl.org/net/schemas/space/",
    "prv": "http://purl.org/net/provenance/ns#",
    "nif": "http://persistence.uni-leipzig.org/nlp2rdf/ontologies/nif-core#",
    "ignf": "http://data.ign.fr/def/ignf#",
    "rec": "http://purl.org/ontology/rec/core#",
    "dbpediaowl": "http://dbpedia.org/ontology/",
    "sp": "http://spinrdf.org/sp#",
    "swid": "http://semanticweb.org/id/",
    "irw": "http://www.ontologydesignpatterns.org/ont/web/irw.owl#",
    "dbc": "http://dbpedia.org/resource/Category:",
    "ti": "http://www.ontologydesignpatterns.org/cp/owl/timeinterval.owl#",
    "dblp": "http://dblp.uni-trier.de/rdf/schema-2015-01-26#",
    "go": "http://purl.org/obo/owl/GO#",
    "pr": "http://purl.org/ontology/prv/core#",
    "lode": "http://linkedevents.org/ontology/",
    "ndl": "http://schemas.ogf.org/nml/2013/05/base#",
    "dbprop": "http://dbpedia.org/property/",
    "java": "http://www.w3.org/2007/uwa/context/java.owl#",
    "nco": "http://www.semanticdesktop.org/ontologies/2007/03/22/nco#",
    "abc": "http://www.metadata.net/harmony/ABCSchemaV5Commented.rdf#",
    "ecs": "http://rdf.ecs.soton.ac.uk/ontology/ecs#",
    "granatum": "http://chem.deri.ie/granatum/",
    "atom": "http://www.w3.org/2005/Atom/",
    "fresnel": "http://www.w3.org/2004/09/fresnel#",
    "daml": "http://www.daml.org/2001/03/daml+oil#",
    "spin": "http://spinrdf.org/spin#",
    "gndo": "http://d-nb.info/standards/elementset/gnd#",
    "sesame": "http://www.openrdf.org/schema/sesame#",
    "wo": "http://purl.org/ontology/wo/",
    "resist": "http://www.rkbexplorer.com/ontologies/resist#",
    "cro": "http://rhizomik.net/ontologies/copyrightonto.owl#",
    "admingeo": "http://data.ordnancesurvey.co.uk/ontology/admingeo/",
    "cco": "http://www.ontologyrepository.com/CommonCoreOntologies/",
    "cpa": "http://www.ontologydesignpatterns.org/schemas/cpannotationschema.owl#",
    "umbel": "http://umbel.org/umbel#",
    "rnews": "http://iptc.org/std/rNews/2011-10-07#",
    "imm": "http://schemas.microsoft.com/imm/",
    "doc": "http://www.w3.org/2000/10/swap/pim/doc#",
    "opm": "https://w3id.org/opm#",
    "bif": "http://www.openlinksw.com/schemas/bif#",
    "politico": "http://www.rdfabout.com/rdf/schema/politico/",
    "link": "http://www.w3.org/2006/link#",
    "protege": "http://protege.stanford.edu/system#",
    "ya": "http://blogs.yandex.ru/schema/foaf/",
    "pmt": "http://tipsy.googlecode.com/svn/trunk/vocab/pmt#",
    "biocore": "http://bio2rdf.org/core#",
    "bibtex": "http://purl.oclc.org/NET/nknouf/ns/bibtex#",
    "oc": "http://opencoinage.org/rdf/",
    "omt": "http://purl.org/ontomedia/ext/common/trait#",
    "scot": "http://rdfs.org/scot/ns#",
    "omb": "http://purl.org/ontomedia/ext/common/being#",
    "dctype": "http://purl.org/dc/dcmitype/",
    "user": "http://schemas.talis.com/2005/user/schema#",
    "biol": "http://purl.org/NET/biol/ns#",
    "sec": "https://w3id.org/security#",
    "kb": "http://deductions.sf.net/ontology/knowledge_base.owl#",
    "sparql": "http://www.w3.org/ns/sparql#",
    "oauth": "http://demiblog.org/vocab/oauth#",
    "taxo": "http://purl.org/rss/1.0/modules/taxonomy/",
    "climb": "http://climb.dataincubator.org/vocabs/climb/",
    "gd": "http://rdf.data-vocabulary.org/#",
    "profiling": "http://ontologi.es/profiling#",
    "disco": "http://rdf-vocabulary.ddialliance.org/discovery#",
    "myspo": "http://purl.org/ontology/myspace#",
    "wv": "http://vocab.org/waiver/terms/",
    "wgs84": "http://www.w3.org/2003/01/geo/wgs84_pos#",
    "doac": "http://ramonantonio.net/doac/0.1/#",
    "compass": "http://purl.org/net/compass#",
    "mit": "http://purl.org/ontology/mo/mit#",
    "label": "http://purl.org/net/vocab/2004/03/label#",
    "httph": "http://www.w3.org/2007/ont/httph#",
    "lx": "http://purl.org/NET/lx#",
    "am": "http://vocab.deri.ie/am#",
    "ddc": "http://purl.org/NET/decimalised#",
    "atomix": "http://buzzword.org.uk/rdf/atomix#",
    "bio2rdf": "http://bio2rdf.org/",
    "opensearch": "http://a9.com/-/spec/opensearch/1.1/",
    "wdrs": "http://www.w3.org/2007/05/powder-s#",
    "meteo": "http://purl.org/ns/meteo#",
    "courseware": "http://courseware.rkbexplorer.com/ontologies/courseware#",
    "tmo": "http://www.semanticdesktop.org/ontologies/2008/05/20/tmo#",
    "lfn": "http://www.dotnetrdf.org/leviathan#",
    "cdm": "http://publications.europa.eu/ontology/cdm#",
    "fec": "http://www.rdfabout.com/rdf/schema/usfec/",
    "powder": "http://www.w3.org/2007/05/powder#",
    "ngeo": "http://geovocab.org/geometry#",
    "usgov": "http://www.rdfabout.com/rdf/schema/usgovt/",
    "sdl": "http://purl.org/vocab/riro/sdl#",
    "swivt": "http://semantic-mediawiki.org/swivt/1.0#",
    "food": "http://purl.org/foodontology#",
    "os": "http://www.w3.org/2000/10/swap/os#",
    "mf": "http://www.w3.org/2001/sw/DataAccess/tests/test-manifest#",
    "rep": "http://www.openrdf.org/config/repository#",
    "postcode": "http://data.ordnancesurvey.co.uk/id/postcodeunit/",
    "chord": "http://purl.org/ontology/chord/",
    "omc": "http://purl.org/ontomedia/ext/common/bestiary#",
    "es": "http://eulersharp.sourceforge.net/2003/03swap/log-rules#",
    "worldbank": "http://worldbank.270a.info/dataset/",
    "conv": "http://purl.org/twc/vocab/conversion/",
    "spl": "http://spinrdf.org/spl#",
    "video": "http://purl.org/ontology/video#",
    "wgs": "http://www.w3.org/2003/01/geo/wgs84_pos#",
    "nmo": "http://nomisma.org/ontology.rdf#",
    "contact": "http://www.w3.org/2000/10/swap/pim/contact#",
    "web": "http://www.w3.org/2007/uwa/context/web.owl#",
    "biopax": "http://www.biopax.org/release/biopax-level3.owl#",
    "pmlp": "http://inference-web.org/2.0/pml-provenance.owl#",
    "affy": "http://www.affymetrix.com/community/publications/affymetrix/tmsplice#",
    "hlisting": "http://sindice.com/hlisting/0.1/",
    "sv": "http://schemas.talis.com/2005/service/schema#",
    "kwijibo": "http://kwijibo.talis.com/",
    "money": "http://purl.org/net/rdf-money/",
    "sit": "http://www.ontologydesignpatterns.org/cp/owl/situation.owl#",
    "gpt": "http://purl.org/vocab/riro/gpt#",
    "interval": "http://reference.data.gov.uk/def/intervals/",
    "hard": "http://www.w3.org/2007/uwa/context/hardware.owl#",
    "itsrdf": "http://www.w3.org/2005/11/its/rdf#",
    "audio": "http://purl.org/media/audio#",
    "so": "http://purl.org/ontology/symbolic-music/",
    "iswc": "http://annotation.semanticweb.org/2004/iswc#",
    "lang": "http://ontologi.es/lang/core#",
    "fed": "http://www.openrdf.org/config/sail/federation#",
    "nao": "http://www.semanticdesktop.org/ontologies/2007/08/15/nao#",
    "rdac": "http://rdaregistry.info/Elements/c/",
    "awol": "http://bblfish.net/work/atom-owl/2006-06-06/#",
    "wordmap": "http://purl.org/net/ns/wordmap#",
    "moat": "http://moat-project.org/ns#",
    "eztag": "http://ontologies.ezweb.morfeo-project.org/eztag/ns#",
    "dailymed": "http://www4.wiwiss.fu-berlin.de/dailymed/resource/dailymed/",
    "lgdo": "http://linkedgeodata.org/ontology/",
    "hcterms": "http://purl.org/uF/hCard/terms/",
    "prj": "http://purl.org/stuff/project/",
    "p3p": "http://www.w3.org/2002/01/p3prdfv1#",
    "site": "http://ns.ontowiki.net/SysOnt/Site/",
    "doclist": "http://www.junkwork.net/xml/DocumentList#",
    "gso": "http://www.w3.org/2006/gen/ont#",
    "resource": "http://purl.org/vocab/resourcelist/schema#",
    "tio": "http://purl.org/tio/ns#",
    "nrl": "http://www.semanticdesktop.org/ontologies/2007/08/15/nrl#",
    "bd": "http://www.bigdata.com/rdf#",
    "xro": "http://purl.org/xro/ns#",
    "obj": "http://www.openrdf.org/rdf/2009/object#",
    "code": "http://telegraphis.net/ontology/measurement/code#",
    "scv": "http://purl.org/NET/scovo#",
    "swandr": "http://purl.org/swan/1.2/discourse-relationships/",
    "smf": "http://topbraid.org/sparqlmotionfunctions#",
    "lfm": "http://purl.org/ontology/last-fm/",
    "nexif": "http://www.semanticdesktop.org/ontologies/2007/05/10/nexif#",
    "xl": "http://langegger.at/xlwrap/vocab#",
    "ire": "http://www.ontologydesignpatterns.org/cpont/ire.owl#",
    "nid3": "http://www.semanticdesktop.org/ontologies/2007/05/10/nid3#",
    "copyright": "http://rhizomik.net/ontologies/copyrightonto.owl#",
    "rei": "http://www.w3.org/2004/06/rei#",
    "product": "http://purl.org/commerce/product#",
    "sail": "http://www.openrdf.org/config/sail#",
    "xhe": "http://buzzword.org.uk/rdf/xhtml-elements#",
    "tdb": "http://jena.hpl.hp.com/2008/tdb#",
    "spc": "http://purl.org/ontomedia/core/space#",
    "library": "http://purl.org/library/",
    "ddl": "http://purl.org/vocab/riro/ddl#",
    "irrl": "http://www.ontologydesignpatterns.org/cp/owl/informationobjectsandrepresentationlanguages.owl#",
    "acco": "http://purl.org/acco/ns#",
    "icaltzd": "http://www.w3.org/2002/12/cal/icaltzd#",
    "ass": "http://uptheasset.org/ontology#",
    "vote": "http://www.rdfabout.com/rdf/schema/vote/",
    "npg": "http://ns.nature.com/terms/",
    "xen": "http://buzzword.org.uk/rdf/xen#",
    "ocd": "http://dati.camera.it/ocd/",
    "ontolex": "http://www.w3.org/ns/lemon/ontolex#",
    "dcmitype": "http://purl.org/dc/dcmitype/",
    "sim": "http://purl.org/ontology/similarity/",
    "omp": "http://purl.org/ontomedia/ext/common/profession#",
    "pat": "http://purl.org/hpi/patchr#",
    "swanqs": "http://purl.org/swan/1.2/qualifiers/",
    "meetup": "http://www.lotico.com/meetup/",
    "cycann": "http://sw.cyc.com/CycAnnotations_v1#",
    "swp": "http://www.w3.org/2004/03/trix/swp-2/",
    "cerif": "http://spi-fm.uca.es/neologism/cerif#",
    "ref": "http://purl.org/vocab/relationship/",
    "crypto": "http://www.w3.org/2000/10/swap/crypto#",
    "olia": "http://purl.org/olia/olia.owl#",
    "common": "http://www.w3.org/2007/uwa/context/common.owl#",
    "lastfm": "http://purl.org/ontology/last-fm/",
    "lom": "http://ltsc.ieee.org/rdf/lomv1p0/lom#",
    "prissma": "http://ns.inria.fr/prissma/v1#",
    "opo": "http://online-presence.net/opo/ns#",
    "omm": "http://purl.org/ontomedia/core/media#",
    "swh": "http://plugin.org.uk/swh-plugins/",
    "dady": "http://purl.org/NET/dady#",
    "soft": "http://www.w3.org/2007/uwa/context/software.owl#",
    "phss": "http://ns.poundhill.com/phss/1.0/",
    "sede": "http://eventography.org/sede/0.1/",
    "string": "http://www.w3.org/2000/10/swap/string#",
    "sysont": "http://ns.ontowiki.net/SysOnt/",
    "gnd": "http://d-nb.info/gnd/",
    "rdfa": "http://www.w3.org/ns/rdfa#",
    "sco": "http://purl.org/ontology/sco#",
    "sport": "http://www.bbc.co.uk/ontologies/sport/",
    "uco": "http://purl.org/uco/ns#",
    "ping": "http://purl.org/net/pingback/",
    "ne": "http://umbel.org/umbel/ne/",
    "resex": "http://resex.rkbexplorer.com/ontologies/resex#",
    "cgov": "http://reference.data.gov.uk/def/central-government/",
    "ero": "http://purl.obolibrary.org/obo/",
    "like": "http://ontologi.es/like#",
    "xforms": "http://www.w3.org/2002/xforms/",
    "net": "http://www.w3.org/2007/uwa/context/network.owl#",
    "bib": "http://zeitkunst.org/bibtex/0.1/bibtex.owl#",
    "dummy": "http://hello.com/",
    "rov": "http://www.w3.org/ns/regorg#",
    "ro": "http://purl.org/wf4ever/ro#",
    "ct": "http://data.linkedct.org/resource/linkedct/",
    "dgtwc": "http://data-gov.tw.rpi.edu/2009/data-gov-twc.rdf#",
    "rdrel": "http://rdvocab.info/RDARelationshipsWEMI/",
    "airport": "http://www.daml.org/2001/10/html/airport-ont#",
    "dwc": "http://rs.tdwg.org/dwc/terms/",
    "swanco": "http://purl.org/swan/1.2/swan-commons/",
    "ao": "http://purl.org/ontology/ao/core#",
    "custom": "http://www.openrdf.org/config/sail/custom#",
    "eu": "http://eulersharp.sourceforge.net/2003/03swap/log-rules#",
    "ezcontext": "http://ontologies.ezweb.morfeo-project.org/ezcontext/ns#",
    "lingvoj": "http://www.lingvoj.org/ontology#",
    "smiley": "http://www.smileyontology.com/ns#",
    "mysql": "http://web-semantics.org/ns/mysql/",
    "lotico": "http://www.lotico.com/resource/",
    "psych": "http://purl.org/vocab/psychometric-profile/",
    "rdagr1": "http://rdvocab.info/Elements/",
    "pobo": "http://purl.obolibrary.org/obo/",
    "gob": "http://purl.org/ontology/last-fm/",
    "rail": "http://ontologi.es/rail/vocab#",
    "c4n": "http://vocab.deri.ie/c4n#",
    "states": "http://www.w3.org/2005/07/aaa#",
    "pav": "http://purl.org/pav/",
    "xhtmlvocab": "http://www.w3.org/1999/xhtml/vocab/",
    "efo": "http://www.ebi.ac.uk/efo/",
    "trackback": "http://madskills.com/public/xml/rss/module/trackback/",
    "tags": "http://www.holygoat.co.uk/owl/redwood/0.1/tags/",
    "lime": "http://www.w3.org/ns/lemon/lime#",
    "xkos": "http://rdf-vocabulary.ddialliance.org/xkos#",
    "b2bo": "http://purl.org/b2bo#",
    "aifb": "http://www.aifb.kit.edu/id/",
    "cogs": "http://vocab.deri.ie/cogs#",
    "pmlr": "http://inference-web.org/2.0/pml-relation.owl#",
    "qdoslf": "http://foaf.qdos.com/lastfm/schema/",
    "ist": "http://purl.org/ontology/is/types/",
    "anca": "http://users.utcluj.ro/~raluca/rdf_ontologies_ralu/ralu_modified_ontology_pizzas2_0#",
    "freebase": "http://rdf.freebase.com/ns/",
    "sm": "http://topbraid.org/sparqlmotion#",
    "isothes": "http://purl.org/iso25964/skos-thes#",
    "lifecycle": "http://purl.org/vocab/lifecycle/schema#",
    "grddl": "http://www.w3.org/2003/g/data-view#",
    "push": "http://www.w3.org/2007/uwa/context/push.owl#",
    "pgterms": "http://www.gutenberg.org/2009/pgterms/",
    "game": "http://data.totl.net/game/",
    "prot": "http://www.proteinontology.info/po.owl#",
    "lt": "http://diplomski.nelakolundzija.org/LTontology.rdf#",
    "isi": "http://purl.org/ontology/is/inst/",
    "wdr": "http://www.w3.org/2007/05/powder#",
    "hydra": "http://www.w3.org/ns/hydra/core#",
    "evset": "http://dsnotify.org/vocab/eventset/0.1/",
    "rulz": "http://purl.org/NET/rulz#",
    "urn": "http://fliqz.com/",
    "list": "http://www.w3.org/2000/10/swap/list#",
    "puc": "http://purl.org/NET/puc#",
    "ibis": "http://purl.org/ibis#",
    "conversion": "http://purl.org/twc/vocab/conversion/",
    "name": "http://example.org/name#",
    "conserv": "http://conserv.deri.ie/ontology#",
    "cordis": "http://cordis.europa.eu/projects/",
    "ps": "https://w3id.org/payswarm#",
    "sawsdl": "http://www.w3.org/ns/sawsdl#",
    "eco": "http://www.ebusiness-unibw.org/ontologies/eclass/5.1.4/#",
    "meta": "http://www.openrdf.org/rdf/2009/metadata#",
    "oat": "http://openlinksw.com/schemas/oat/",
    "ttl": "http://www.w3.org/2008/turtle#",
    "act": "http://www.w3.org/2007/rif-builtin-action#",
    "places": "http://purl.org/ontology/places#",
    "bookmark": "http://www.w3.org/2002/01/bookmark#",
    "lark1": "http://users.utcluj.ro/~raluca/ontology/Ontology1279614123500.owl#",
    "viaf": "http://viaf.org/ontology/1.1/#",
    "fise": "http://fise.iks-project.eu/ontology/",
    "pimo": "http://www.semanticdesktop.org/ontologies/2007/11/01/pimo#",
    "apivc": "http://purl.org/linked-data/api/vocab#",
    "sml": "http://topbraid.org/sparqlmotionlib#",
    "ldap": "http://purl.org/net/ldap/",
    "frbre": "http://purl.org/vocab/frbr/extended#",
    "is": "http://purl.org/ontology/is/core#",
    "gml": "http://www.opengis.net/ont/gml#",
    "h5": "http://buzzword.org.uk/rdf/h5#",
    "tripfs": "http://purl.org/tripfs/2010/02#",
    "plink": "http://buzzword.org.uk/rdf/personal-link-types#",
    "asn": "http://purl.org/ASN/schema/core/",
    "arpfo": "http://vocab.ouls.ox.ac.uk/projectfunding#",
    "agg": "http://purl.org/twc/health/vocab/aggregate/",
    "inno": "http://purl.org/innovation/ns#",
    "bsbm": "http://www4.wiwiss.fu-berlin.de/bizer/bsbm/v01/vocabulary/",
    "txn": "http://lod.taxonconcept.org/ontology/txn.owl#",
    "lvont": "http://lexvo.org/ontology#",
    "ui": "http://www.w3.org/ns/ui#",
    "cpv": "http://purl.org/weso/cpv/",
    "dbpp": "http://dbpedia.org/property/",
    "timeline": "http://purl.org/NET/c4dm/timeline.owl#",
    "pbo": "http://purl.org/ontology/pbo/core#",
    "ncal": "http://www.semanticdesktop.org/ontologies/2007/04/02/ncal#",
    "phil": "http://philosurfical.open.ac.uk/ontology/philosurfical.owl#",
    "wnschema": "http://www.cogsci.princeton.edu/~wn/schema/",
    "geographis": "http://telegraphis.net/ontology/geography/geography#",
    "play": "http://uriplay.org/spec/ontology/#",
    "kontakt": "http://richard.cyganiak.de/",
    "sioca": "http://rdfs.org/sioc/actions#",
    "okkam": "http://models.okkam.org/ENS-core-vocabulary#",
    "human": "http://eulersharp.sourceforge.net/2003/03swap/human#",
    "yoda": "http://purl.org/NET/yoda#",
    "drug": "http://www.agfa.com/w3c/2009/drugTherapy#",
    "ean": "http://openean.kaufkauf.net/id/",
    "opus": "http://lsdis.cs.uga.edu/projects/semdis/opus#",
    "osgb": "http://data.ordnancesurvey.co.uk/id/",
    "dnr": "http://www.dotnetrdf.org/configuration#",
    "ub": "http://www.lehigh.edu/~zhp2/2004/0401/univ-bench.owl#",
    "osoc": "http://web-semantics.org/ns/opensocial#",
    "eprints": "http://eprints.org/ontology/",
    "muto": "http://purl.org/muto/core#",
    "dita": "http://purl.org/dita/ns#",
    "person": "http://www.w3.org/ns/person#",
    "httpvoc": "http://www.w3.org/2006/http#",
    "datafaqs": "http://purl.org/twc/vocab/datafaqs#",
    "wairole": "http://www.w3.org/2005/01/wai-rdf/GUIRoleTaxonomy#",
    "res": "http://dbpedia.org/resource/",
    "dbnary": "http://kaiko.getalp.org/dbnary#",
    "mods": "http://www.loc.gov/mods/v3#",
    "remus": "http://www.semanticweb.org/ontologies/2010/6/Ontology1279614123500.owl#",
    "pmlt": "http://inference-web.org/2.0/pml-trust.owl#",
    "posh": "http://poshrdf.org/ns/posh/",
    "address": "http://schemas.talis.com/2005/address/schema#",
    "lp": "http://launchpad.net/rdf/launchpad#",
    "rdfsharp": "https://rdfsharp.codeplex.com/",
    "coin": "http://purl.org/court/def/2009/coin#",
    "xesam": "http://freedesktop.org/standards/xesam/1.0/core#",
    "pml": "http://provenanceweb.org/ns/pml#",
    "cheminf": "http://www.semanticweb.org/ontologies/cheminf.owl#",
    "muo": "http://purl.oclc.org/NET/muo/muo#",
    "session": "http://redfoot.net/2005/session#",
    "wordnet": "http://wordnet-rdf.princeton.edu/ontology#",
    "swanpav": "http://purl.org/swan/1.2/pav/",
    "vso": "http://purl.org/vso/ns#",
    "prvtypes": "http://purl.org/net/provenance/types#",
    "agents": "http://eulersharp.sourceforge.net/2003/03swap/agent#",
    "enc": "http://www.w3.org/2001/04/xmlenc#",
    "vsr": "http://purl.org/twc/vocab/vsr#",
    "oslc": "http://open-services.net/ns/core#",
    "wisski": "http://wiss-ki.eu/",
    "imreg": "http://www.w3.org/2004/02/image-regions#",
    "pom": "http://maven.apache.org/POM/4.0.0#",
    "hospital": "http://www.agfa.com/w3c/2009/hospital#",
    "ceo": "http://www.ebusiness-unibw.org/ontologies/consumerelectronics/v1#",
    "swanci": "http://purl.org/swan/1.2/citations/",
    "locn": "http://www.w3.org/ns/locn#",
    "geospecies": "http://rdf.geospecies.org/ont/geospecies#",
    "nsa": "http://multimedialab.elis.ugent.be/organon/ontologies/ninsuna#",
    "ospost": "http://data.ordnancesurvey.co.uk/ontology/postcode/",
    "vitro": "http://vitro.mannlib.cornell.edu/ns/vitro/public#",
    "com": "http://purl.org/commerce#",
    "calli": "http://callimachusproject.org/rdf/2009/framework#",
    "purl": "http://www.purl.org/",
    "fab": "http://purl.org/fab/ns#",
    "payment": "http://reference.data.gov.uk/def/payment#",
    "zem": "http://s.zemanta.com/ns#",
    "mei": "http://www.music-encoding.org/ns/mei/",
    "xml": "http://www.w3.org/XML/1998/namespace/",
    "rdau": "http://rdaregistry.info/Elements/u/",
    "cidoc": "http://erlangen-crm.org/current/",
    "frir": "http://purl.org/twc/ontology/frir.owl#",
    "protons": "http://proton.semanticweb.org/2005/04/protons#",
    "cube": "http://purl.org/linked-data/cube#",
    "arg": "http://rdfs.org/sioc/argument#",
    "uri": "http://purl.org/NET/uri#",
    "languages": "http://eulersharp.sourceforge.net/2003/03swap/languages#",
    "zbwext": "http://zbw.eu/namespaces/zbw-extensions/",
    "arch": "http://purl.org/archival/vocab/arch#",
    "agetec": "http://www.agetec.org/",
    "penn": "http://purl.org/olia/penn.owl#",
    "edam": "http://edamontology.org/",
    "search": "http://sindice.com/vocab/search#",
    "ann": "http://www.w3.org/2000/10/annotation-ns#",
    "card": "http://www.ashutosh.com/test/",
    "care": "http://eulersharp.sourceforge.net/2003/03swap/care#",
    "nt": "http://ns.inria.fr/nicetag/2010/09/09/voc#",
    "tarot": "http://data.totl.net/tarot/card/",
    "cos": "http://www.inria.fr/acacia/corese#",
    "isq": "http://purl.org/ontology/is/quality/",
    "deo": "http://purl.org/spar/deo/",
    "status": "http://ontologi.es/status#",
    "xfnv": "http://vocab.sindice.com/xfn#",
    "swanag": "http://purl.org/swan/1.2/agents/",
    "linkedct": "http://data.linkedct.org/vocab/",
    "opmv": "http://purl.org/net/opmv/ns#",
    "pdo": "http://ontologies.smile.deri.ie/pdo#",
    "rooms": "http://vocab.deri.ie/rooms#",
    "xbrli": "http://www.xbrl.org/2003/instance#",
    "gc": "http://www.oegov.org/core/owl/gc#",
    "cmo": "http://purl.org/twc/ontologies/cmo.owl#",
    "olo": "http://purl.org/ontology/olo/core#",
    "ludo": "http://ns.inria.fr/ludo/v1#",
    "evopat": "http://ns.aksw.org/Evolution/",
    "esd": "http://def.esd.org.uk/",
    "exterms": "http://www.example.org/terms/",
    "pro": "http://purl.org/hpi/patchr#",
    "rdam": "http://rdaregistry.info/Elements/m/",
    "prefix": "http://prefix.cc/",
    "aair": "http://xmlns.notu.be/aair#",
    "sl": "http://www.semanlink.net/2001/00/semanlink-schema#",
    "coo": "http://purl.org/coo/ns#",
    "hemogram": "http://www.agfa.com/w3c/2009/hemogram#",
    "gsp": "http://www.opengis.net/ont/geosparql#",
    "bte": "http://purl.org/twc/vocab/between-the-edges/",
    "rdaa": "http://rdaregistry.info/Elements/a/",
    "geodata": "http://sws.geonames.org/",
    "example": "http://www.example.org/rdf#",
    "webtlab": "http://webtlab.it.uc3m.es/",
    "gazetteer": "http://data.ordnancesurvey.co.uk/ontology/50kGazetteer/",
    "geof": "http://www.opengis.net/def/function/geosparql/",
    "nndsr": "http://semanticdiet.com/schema/usda/nndsr/",
    "dt": "http://dbpedia.org/datatype/",
    "derecho": "http://purl.org/derecho#",
    "dgfoaf": "http://west.uni-koblenz.de/ontologies/2010/07/dgfoaf.owl#",
    "wikipedia": "http://wikipedia.no/rdf/",
    "govtrackus": "http://www.rdfabout.com/rdf/usgov/geo/us/",
    "blt": "http://www.bl.uk/schemas/bibliographic/blterms#",
    "opwn": "http://www.ontologyportal.org/WordNet.owl#",
    "voag": "http://voag.linkedmodel.org/schema/voag#",
    "linkedmdb": "http://data.linkedmdb.org/sparql/",
    "dayta": "http://dayta.me/resource#",
    "c4o": "http://purl.org/spar/c4o/",
    "ccom": "http://purl.org/ontology/cco/mappings#",
    "marl": "http://www.gsi.dit.upm.es/ontologies/marl/ns#",
    "fowl": "http://www.w3.org/TR/2003/PR-owl-guide-20031209/food#",
    "bioskos": "http://eulersharp.sourceforge.net/2003/03swap/bioSKOSSchemes#",
    "gv": "http://rdf.data-vocabulary.org/#",
    "oboso": "http://purl.org/obo/owl/SO#",
    "countries": "http://eulersharp.sourceforge.net/2003/03swap/countries#",
    "oboro": "http://obofoundry.org/ro/ro.owl#",
    "loticoowl": "http://www.lotico.com/ontology/",
    "dso": "http://purl.org/ontology/dso#",
    "wlp": "http://weblab-project.org/core/model/property/processing/",
    "idemo": "http://rdf.insee.fr/def/demo#",
    "theatre": "http://purl.org/theatre#",
    "aapi": "http://rdf.alchemyapi.com/rdf/v1/s/aapi-schema#",
    "gridworks": "http://purl.org/net/opmv/types/gridworks#",
    "life": "http://life.deri.ie/schema/",
    "atomowl": "http://bblfish.net/work/atom-owl/2006-06-06/#",
    "ncbitaxon": "http://purl.org/obo/owl/NCBITaxon#",
    "organiz": "http://eulersharp.sourceforge.net/2003/03swap/organization#",
    "muni": "http://vocab.linkeddata.es/urbanismo-infraestructuras/territorio#",
    "provenir": "http://knoesis.wright.edu/provenir/provenir.owl#",
    "agrelon": "http://d-nb.info/standards/elementset/agrelon#",
    "xsl": "http://www.w3.org/1999/XSL/Transform#",
    "ppo": "http://vocab.deri.ie/ppo#",
    "cex": "http://purl.org/weso/computex/ontology#",
    "sindice": "http://vocab.sindice.net/",
    "emotion": "http://ns.inria.fr/emoca#",
    "qa": "http://www.mit.jyu.fi/ai/TRUST_Ontologies/QA.owl#",
    "scsv": "http://purl.org/NET/schema-org-csv#",
    "br": "http://vocab.deri.ie/br#",
    "dnb": "http://d-nb.info/gnd/",
    "ogp": "http://ogp.me/ns#",
    "ens": "http://models.okkam.org/ENS-core-vocabulary.owl#",
    "req": "http://purl.org/req/",
    "pext": "http://www.ontotext.com/proton/protonext#",
    "toby": "http://tobyinkster.co.uk/#",
    "spatial": "http://geovocab.org/spatial#",
    "oboe": "http://ecoinformatics.org/oboe/oboe.1.0/oboe-core.owl#",
    "cpm": "http://catalogus-professorum.org/cpm/2/",
    "wai": "http://purl.org/wai#",
    "elog": "http://eulersharp.sourceforge.net/2003/03swap/log-rules#",
    "odp": "http://ontologydesignpatterns.org/",
    "wgspos": "http://www.w3.org/2003/01/geo/wgs84_pos#",
    "quak": "http://dev.w3.org/cvsweb/2000/quacken/vocab#",
    "prvr": "http://purl.org/ontology/prv/rules#",
    "pos": "http://www.w3.org/2003/01/geo/wgs84_pos#",
    "dive": "http://scubadive.networld.to/dive.rdf#",
    "agent": "http://eulersharp.sourceforge.net/2003/03swap/agent#",
    "tcga": "http://purl.org/tcga/core#",
    "rlog": "http://persistence.uni-leipzig.org/nlp2rdf/ontologies/rlog#",
    "lr": "http://linkedrecipes.org/schema/",
    "rdo": "http://purl.org/rdo/ns#",
    "kdo": "http://kdo.render-project.eu/kdo#",
    "genab": "http://eulersharp.sourceforge.net/2003/03swap/genomeAbnormality#",
    "uni": "http://purl.org/weso/uni/uni.html#",
    "ufmedia": "http://purl.org/microformat/hmedia/",
    "skip": "http://skipforward.net/skipforward/resource/",
    "ic": "http://imi.go.jp/ns/core/rdf#",
    "city": "http://datos.localidata.com/def/City#",
    "decl": "http://www.linkedmodel.org/1.0/schema/decl#",
    "w3p": "http://prov4j.org/w3p/",
    "pf": "http://jena.hpl.hp.com/ARQ/property#",
    "events": "http://eulersharp.sourceforge.net/2003/03swap/event#",
    "owltime": "http://www.w3.org/TR/owl-time#",
    "commons": "http://commons.psi.enakting.org/def/",
    "dtype": "http://www.linkedmodel.org/schema/dtype#",
    "wbp": "http://worldbank.270a.info/property/",
    "owls": "http://www.daml.org/services/owl-s/1.2/Service.owl#",
    "vivo": "http://vivoweb.org/ontology/core#",
    "metalex": "http://www.metalex.eu/schema/1.0#",
    "mygrid": "http://www.mygrid.org.uk/ontology#",
    "enhancer": "http://stanbol.apache.org/ontology/enhancer/enhancer#",
    "pns": "http://data.press.net/ontology/stuff/",
    "admssw": "http://purl.org/adms/sw/",
    "teach": "http://linkedscience.org/teach/ns#",
    "shv": "http://ns.aksw.org/spatialHierarchy/",
    "bing": "http://bing.com/schema/media/",
    "ms": "http://purl.org/obo/owl/MS#",
    "jita": "http://aims.fao.org/aos/jita/",
    "tr": "http://www.thomsonreuters.com/",
    "gelo": "http://krauthammerlab.med.yale.edu/ontologies/gelo#",
    "bne": "http://datos.bne.es/resource/",
    "pay": "http://reference.data.gov.uk/def/payment#",
    "nocal": "http://vocab.deri.ie/nocal#",
    "aneo": "http://akonadi-project.org/ontologies/aneo#",
    "r2r": "http://www4.wiwiss.fu-berlin.de/bizer/r2r/",
    "humanbody": "http://eulersharp.sourceforge.net/2003/03swap/humanBody#",
    "rda": "http://www.rdaregistry.info/",
    "dcr": "http://www.isocat.org/ns/dcr.rdf#",
    "pccz": "http://purl.org/procurement/public-contracts-czech#",
    "span": "http://www.ifomis.org/bfo/1.1/span#",
    "rad": "http://www.w3.org/ns/rad#",
    "clineva": "http://www.agfa.com/w3c/2009/clinicalEvaluation#",
    "arecipe": "http://purl.org/amicroformat/arecipe/",
    "dis": "http://stanbol.apache.org/ontology/disambiguation/disambiguation#",
    "units": "http://eulersharp.sourceforge.net/2003/03swap/units#",
    "omv": "http://omv.ontoware.org/2005/05/ontology#",
    "s4ac": "http://ns.inria.fr/s4ac/v2#",
    "scowt": "http://purl.org/weso/ontologies/scowt#",
    "fcm": "http://eulersharp.sourceforge.net/2006/02swap/fcm#",
    "np": "http://www.nanopub.org/nschema#",
    "igeo": "http://rdf.insee.fr/def/geo#",
    "cao": "http://purl.org/makolab/caont/",
    "govwild": "http://govwild.org/0.6/GWOntology.rdf/",
    "dco": "http://info.deepcarbon.net/schema#",
    "xds": "http://www.w3.org/2001/XMLSchema#",
    "un": "http://www.w3.org/2007/ont/unit#",
    "lex": "http://purl.org/lex#",
    "oper": "http://sweet.jpl.nasa.gov/2.0/mathOperation.owl#",
    "vaem": "http://www.linkedmodel.org/schema/vaem#",
    "chebi": "http://bio2rdf.org/chebi:",
    "lctr": "http://data.linkedct.org/vocab/resource/",
    "wbc": "http://worldbank.270a.info/classification/",
    "fls": "http://lukasblaho.sk/football_league_schema#",
    "dbpo": "http://dbpedia.org/ontology/",
    "iao": "http://purl.obolibrary.org/obo/iao.owl#",
    "wfdesc": "http://purl.org/wf4ever/wfdesc#",
    "wp": "http://vocabularies.wikipathways.org/wp#",
    "semtweet": "http://semantictweet.com/",
    "faldo": "http://biohackathon.org/resource/faldo#",
    "fc": "http://www.freeclass.eu/freeclass_v1#",
    "hartigprov": "http://purl.org/net/provenance/ns#",
    "out": "http://ontologies.hypios.com/out#",
    "odrl": "http://www.w3.org/ns/odrl/2/",
    "cidoccrm": "http://purl.org/NET/cidoc-crm/core#",
    "osn": "http://spatial.ucd.ie/lod/osn/",
    "httpm": "http://www.w3.org/2011/http-methods#",
    "healthcare": "http://www.agfa.com/w3c/2009/healthCare#",
    "infosys": "http://www.infosys.com/",
    "eclap": "http://www.eclap.eu/schema/eclap/",
    "dsp": "http://purl.org/metainfo/terms/dsp#",
    "oax": "http://www.w3.org/ns/openannotation/extensions/",
    "tei": "http://www.tei-c.org/ns/1.0/",
    "greg": "http://kasei.us/about/foaf.xrdf#",
    "tmpl": "http://purl.org/restdesc/http-template#",
    "intervals": "http://reference.data.gov.uk/def/intervals/",
    "rlno": "http://rdflivenews.aksw.org/ontology/",
    "ekaw": "http://data.semanticweb.org/conference/ekaw/2012/complete/",
    "pol": "http://escience.rpi.edu/ontology/semanteco/2/0/pollution.owl#",
    "hg": "http://www.holygoat.co.uk/owl/redwood/0.1/tags/",
    "set": "http://www.w3.org/2000/10/swap/set#",
    "artstor": "http://simile.mit.edu/2003/10/ontologies/artstor#",
    "fd": "http://foodable.co/ns/",
    "mte": "http://nl.ijs.si/ME/owl/",
    "geom": "http://data.ign.fr/def/geometrie#",
    "health": "http://purl.org/twc/health/vocab/",
    "visit": "http://purl.org/net/vocab/2004/07/visit#",
    "nytimes": "http://data.nytimes.com/elements/",
    "nxp": "http://purl.org/nxp/schema/v1/",
    "algo": "http://securitytoolbox.appspot.com/securityAlgorithms#",
    "gxa": "http://www.ebi.ac.uk/gxa/",
    "wao": "http://webtlab.it.uc3m.es/2010/10/WebAppsOntology#",
    "sgv": "http://www.w3.org/TR/SVG/",
    "doco": "http://purl.org/spar/doco/",
    "bp": "http://open-services.net/ns/basicProfile#",
    "nuts": "http://dd.eionet.europa.eu/vocabulary/common/nuts/",
    "re": "http://www.w3.org/2000/10/swap/reason#",
    "pkmn": "http://pokedex.dataincubator.org/pkm/",
    "bbc": "http://www.bbc.co.uk/ontologies/news/",
    "osmsemnet": "http://spatial.ucd.ie/2012/08/osmsemnet/",
    "spif": "http://spinrdf.org/spif#",
    "wikidata": "http://www.wikidata.org/entity/",
    "wm": "http://ns.inria.fr/webmarks#",
    "pne": "http://data.press.net/ontology/event/",
    "vocab": "http://rdf.ontology2.com/vocab#",
    "atomrdf": "http://atomowl.org/ontologies/atomrdf#",
    "recipe": "http://linkedrecipes.org/schema/",
    "api": "http://purl.org/linked-data/api/vocab#",
    "ccard": "http://purl.org/commerce/creditcard#",
    "soap": "http://www.w3.org/2003/05/soap-envelope/",
    "wfprov": "http://purl.org/wf4ever/wfprov#",
    "aersv": "http://aers.data2semantics.org/vocab/",
    "aigp": "http://swat.cse.lehigh.edu/resources/onto/aigp.owl#",
    "ipad": "http://www.padinthecity.com/",
    "wapp": "http://ns.rww.io/wapp#",
    "pna": "http://data.press.net/ontology/asset/",
    "clinproc": "http://www.agfa.com/w3c/2009/clinicalProcedure#",
    "role": "https://w3id.org/role/",
    "sql": "http://ns.inria.fr/ast/sql#",
    "rating": "http://www.tvblob.com/ratings/#",
    "transit": "http://vocab.org/transit/terms/",
    "mime": "https://www.iana.org/assignments/media-types/",
    "rv": "http://wifo-ravensburg.de/semanticweb.rdf#",
    "npgg": "http://ns.nature.com/graphs/",
    "hgnc": "http://bio2rdf.org/hgnc:",
    "aos": "http://rdf.muninn-project.org/ontologies/appearances#",
    "rdfdf": "http://www.openlinksw.com/virtrdf-data-formats#",
    "xch": "http://oanda2rdf.appspot.com/xch/",
    "ogorg": "http://opengraph.org/schema/",
    "fl": "http://eulersharp.sourceforge.net/2003/03swap/fl-rules#",
    "aerols": "http://xmlns.com/aerols/0.1/",
    "flow": "http://www.w3.org/2005/01/wf/flow#",
    "aims": "http://aims.fao.org/aos/common/",
    "crtv": "http://open-services.net/ns/crtv#",
    "environ": "http://eulersharp.sourceforge.net/2003/03swap/environment#",
    "goef": "http://purl.org/twc/vocab/goef#",
    "sem": "http://semanticweb.cs.vu.nl/2009/11/sem/",
    "fea": "http://vocab.data.gov/def/fea#",
    "myprefix": "http://myprefix.org/",
    "telix": "http://purl.org/telix#",
    "vcardx": "http://buzzword.org.uk/rdf/vcardx#",
    "htir": "http://www.w3.org/2011/http#",
    "up": "http://users.ugent.be/~tdenies/up/",
    "namespaces": "https://vg.no/",
    "category": "http://dbpedia.org/resource/Category:",
    "oac": "http://www.openannotation.org/ns/",
    "poder": "http://poderopedia.com/vocab/",
    "pronom": "http://reference.data.gov.uk/technical-registry/",
    "oj": "http://ontojob.at/",
    "frapo": "http://purl.org/cerif/frapo/",
    "ple": "http://pleiades.stoa.org/places/",
    "p20": "http://zbw.eu/beta/p20/vocab/",
    "hxl": "http://hxl.humanitarianresponse.info/ns/#",
    "d2r": "http://sites.wiwiss.fu-berlin.de/suhl/bizer/d2r-server/config.rdf#",
    "coeus": "http://bioinformatics.ua.pt/coeus/",
    "pim": "http://www.w3.org/ns/pim/space#",
    "eye": "http://jena.hpl.hp.com/Eyeball#",
    "zoomaterms": "http://rdf.ebi.ac.uk/vocabulary/zooma/",
    "sig": "http://purl.org/signature#",
    "wf": "http://www.w3.org/2005/01/wf/flow#",
    "osr": "http://dati.senato.it/osr/",
    "fingal": "http://vocab.deri.ie/fingal#",
    "kupkb": "http://www.e-lico.eu/data/kupkb/",
    "sdgp": "http://stats.data-gov.ie/property/",
    "ngeoi": "http://vocab.lenka.no/geo-deling#",
    "wfm": "http://purl.org/net/wf-motifs#",
    "kw": "http://kwantu.net/kw/",
    "mtecore": "http://purl.org/olia/mte/multext-east.owl#",
    "premis": "http://www.loc.gov/premis/rdf/v1#",
    "func": "http://www.w3.org/2007/rif-builtin-function#",
    "malignneo": "http://www.agfa.com/w3c/2009/malignantNeoplasm#",
    "okg": "http://openknowledgegraph.org/ontology/",
    "iso": "http://purl.org/iso25964/skos-thes#",
    "ends": "http://labs.mondeca.com/vocab/endpointStatus#",
    "mpeg7": "http://rhizomik.net/ontologies/2005/03/Mpeg7-2001.owl#",
    "xt": "http://purl.org/twc/vocab/cross-topix#",
    "dssn": "http://purl.org/net/dssn/",
    "protegedc": "http://protege.stanford.edu/plugins/owl/dc/protege-dc.owl#",
    "diseasome": "http://www4.wiwiss.fu-berlin.de/diseasome/resource/diseasome/",
    "saxon": "http://saxon.sf.net/",
    "l4a": "http://labels4all.info/ns/",
    "iot": "http://www.linkedthings.com/iot/",
    "prolog": "http://eulersharp.sourceforge.net/2003/03swap/prolog#",
    "xmls": "http://www.w3.org/2001/XMLSchema#",
    "cdtype": "http://purl.org/cld/cdtype/",
    "visko": "http://trust.utep.edu/visko/ontology/visko-operator-v3.owl#",
    "eseduc": "http://www.purl.org/ontologia/eseduc#",
    "opmw": "http://www.opmw.org/ontology/",
    "wscaim": "http://www.openk.org/wscaim.owl#",
    "marshall": "http://sites.google.com/site/xgmaitc/",
    "dl": "http://ontology.ip.rm.cnr.it/ontologies/DOLCE-Lite#",
    "centrifuge": "http://purl.org/twc/vocab/centrifuge#",
    "identity": "http://purl.org/twc/ontologies/identity.owl#",
    "lgv": "http://linkedgeodata.org/ontology/",
    "article": "http://ogp.me/ns/article#",
    "occult": "http://data.totl.net/occult/",
    "sh": "http://www.w3.org/ns/shacl#",
    "mohammad": "http://manesht.ir/",
    "fct": "http://openlinksw.com/services/facets/1.0/",
    "reve": "http://data.eurecom.fr/ontology/reve#",
    "hcard": "http://purl.org/uF/hCard/terms/",
    "lod2": "http://lod2.eu/schema/",
    "biordf": "http://purl.org/net/biordfmicroarray/ns#",
    "carfo": "http://purl.org/carfo#",
    "coun": "http://www.daml.org/2001/09/countries/iso-3166-ont#",
    "dbt": "http://dbpedia.org/resource/Template:",
    "genea": "http://www.owl-ontologies.com/generations.owl#",
    "osp": "http://data.lirmm.fr/ontologies/osp#",
    "b2rpubchem": "http://bio2rdf.org/ns/ns/ns/pubchem#",
    "wiki": "http://en.wikipedia.org/wiki/",
    "lh": "http://vocab.inf.ed.ac.uk/library/holdings#",
    "bcnnorms": "http://datos.bcn.cl/ontologies/bcn-norms#",
    "ling": "http://purl.org/voc/ling/",
    "c4dm": "http://purl.org/NET/c4dm/event.owl#",
    "moby": "http://www.mygrid.org.uk/mygrid-moby-service#",
    "place": "http://purl.org/ontology/places/",
    "geofla": "http://data.ign.fr/ontologies/geofla#",
    "gfo": "http://www.onto-med.de/ontologies/gfo.owl#",
    "cb": "http://cbasewrap.ontologycentral.com/vocab#",
    "daiaserv": "http://purl.org/ontology/daia/Service/",
    "transmed": "http://www.w3.org/2001/sw/hcls/ns/transmed/",
    "overheid": "http://standaarden.overheid.nl/owms/",
    "rpubl": "http://rinfo.lagrummet.se/ns/2008/11/rinfo/publ#",
    "italy": "http://data.kasabi.com/dataset/italy/schema/",
    "emp": "http://purl.org/ctic/empleo/oferta#",
    "s2s": "http://escience.rpi.edu/ontology/sesf/s2s/4/0/",
    "orca": "http://geni-orca.renci.org/owl/topology.owl#",
    "bsb": "http://opacplus.bsb-muenchen.de/title/",
    "prism21": "http://prismstandard.org/namespaces/basic/2.1/",
    "gesis": "http://lod.gesis.org/lodpilot/ALLBUS/vocab.rdf#",
    "open": "http://open.vocab.org/terms/",
    "cold": "http://purl.org/configurationontology#",
    "rec54": "http://www.w3.org/2001/02pd/rec54.rdf#",
    "xlink": "http://www.w3.org/1999/xlink/",
    "penis": "http://penis.to/#",
    "l4lod": "http://ns.inria.fr/l4lod/v2/",
    "ecb": "http://ecb.270a.info/class/1.0/",
    "frbrcore": "http://purl.org/vocab/frbr/core#",
    "dbyago": "http://dbpedia.org/class/yago/",
    "skiresort": "http://www.openlinksw.com/ski_resorts/schema#",
    "mm": "http://linkedmultimedia.org/sparql-mm/ns/2.0.0/function#",
    "tripfs2": "http://purl.org/tripfs/2010/06#",
    "kbp": "http://tackbp.org/2013/ontology#",
    "quantities": "http://eulersharp.sourceforge.net/2003/03swap/quantitiesExtension#",
    "campsite": "http://www.openlinksw.com/campsites/schema#",
    "bcncon": "http://datos.bcn.cl/ontologies/bcn-congress#",
    "rdaw": "http://rdaregistry.info/Elements/w/",
    "geovocab": "http://geovocab.org/",
    "puelia": "http://kwijibo.talis.com/vocabs/puelia#",
    "grs": "http://www.georss.org/georss/",
    "odcs": "http://opendata.cz/infrastructure/odcleanstore/",
    "oecd": "http://oecd.270a.info/dataset/",
    "nyt": "http://data.nytimes.com/",
    "nsl": "http://purl.org/ontology/storyline/",
    "ql": "http://www.w3.org/2004/ql#",
    "germplasm": "http://purl.org/germplasm/terms#",
    "laposte": "http://data.lirmm.fr/ontologies/laposte#",
    "wi": "http://purl.org/ontology/wi/core#",
    "npgd": "http://ns.nature.com/datasets/",
    "swpo": "http://sw-portal.deri.org/ontologies/swportal#",
    "soc": "http://purl.org/net/hdlipcores/ontology/soc#",
    "rso": "http://www.researchspace.org/ontology/",
    "comm": "http://vocab.resc.info/communication#",
    "prf": "http://www.openmobilealliance.org/tech/profiles/UAPROF/ccppschema-20021212#",
    "camelot": "http://vocab.ox.ac.uk/camelot#",
    "iron": "http://purl.org/ontology/iron#",
    "osukdt": "http://www.ordnancesurvey.co.uk/ontology/Datatypes.owl#",
    "rich": "http://rdf.data-vocabulary.org/",
    "wsc": "http://www.openk.org/wscaim.owl#",
    "sad": "http://vocab.deri.ie/sad#",
    "ec": "http://eulergui.sourceforge.net/contacts.owl.n3#",
    "dctypes": "http://purl.org/dc/dcmitype/",
    "itsmo": "http://ontology.it/itsmo/v1#",
    "frad": "http://iflastandards.info/ns/fr/frad/",
    "tvc": "http://www.essepuntato.it/2012/04/tvc/",
    "harrisons": "http://harrisons.cc/",
    "wkd": "http://schema.wolterskluwer.de/",
    "ebu": "http://semantic.eurobau.com/eurobau-utility.owl#",
    "sioctypes": "http://rdfs.org/sioc/types#",
    "sci": "http://data.scientology.org/ns/",
    "ecpo": "http://purl.org/ontology/ecpo#",
    "gbv": "http://purl.org/ontology/gbv/",
    "no": "http://km.aifb.kit.edu/projects/numbers/number#",
    "pam": "http://prismstandard.org/namespaces/pam/2.0/",
    "dcm": "http://dcm.com/",
    "alchemy": "http://rdf.alchemyapi.com/rdf/v1/s/aapi-schema#",
    "str": "http://nlp2rdf.lod2.eu/schema/string/",
    "omapi": "http://purl.org/omapi/0.2/#",
    "npgx": "http://ns.nature.com/extensions/",
    "hints2005": "http://purl.org/twc/cabig/model/HINTS2005-1.owl#",
    "dcndl": "http://ndl.go.jp/dcndl/terms/",
    "bcnbio": "http://datos.bcn.cl/ontologies/bcn-biographies#",
    "cts2": "http://schema.omg.org/spec/CTS2/1.0/",
    "bm": "http://bio2rdf.org/",
    "hifm": "http://purl.org/net/hifm/data#",
    "wl": "http://www.wsmo.org/ns/wsmo-lite#",
    "eunis": "http://eunis.eea.europa.eu/rdf/species-schema.rdf#",
    "oboinowl": "http://www.geneontology.org/formats/oboInOwl#",
    "eumida": "http://data.kasabi.com/dataset/eumida/terms/",
    "mp": "http://jicamaro.info/mp#",
    "refe": "http://orion.tw.rpi.edu/~xgmatwc/refe/",
    "stac": "http://securitytoolbox.appspot.com/stac#",
    "paia": "http://purl.org/ontology/paia#",
    "vsto": "http://escience.rpi.edu/ontology/vsto/2/0/vsto.owl#",
    "rssynd": "http://web.resource.org/rss/1.0/modules/syndication/",
    "aers": "http://aers.data2semantics.org/resource/",
    "dawgt": "http://www.w3.org/2001/sw/DataAccess/tests/test-dawg#",
    "tisc": "http://observedchange.com/tisc/ns#",
    "lexvo": "http://lexvo.org/ontology#",
    "steel": "http://ontorule-project.eu/resources/steel-30#",
    "fos": "http://futurios.org/fos/spec/",
    "vsw": "http://verticalsearchworks.com/ontology/",
    "cf": "http://mmisw.org/ont/cf/parameter/",
    "archdesc": "http://archdesc.info/archEvent#",
    "crv": "http://purl.org/twc/vocab/datacarver#",
    "webbox": "http://webbox.ecs.soton.ac.uk/ns#",
    "scms": "http://ns.aksw.org/scms/annotations/",
    "pso": "http://purl.org/spar/pso/",
    "prvt": "http://purl.org/net/provenance/types#",
    "tblcard": "http://www.w3.org/People/Berners-Lee/card#",
    "qrl": "http://www.aifb.kit.edu/project/ld-retriever/qrl#",
    "psh": "http://psh.techlib.cz/skos/",
    "r4ta": "http://ns.inria.fr/ratio4ta/v1#",
    "stream": "http://dbpedia.org/ontology/Stream/",
    "bihap": "http://bihap.kb.gov.tr/ontology/",
    "dpl": "http://dbpedialite.org/things/",
    "mrel": "http://id.loc.gov/vocabulary/relators/",
    "curr": "https://w3id.org/cc#",
    "qud": "http://qudt.org/1.1/schema/qudt#",
    "conf": "http://richard.cyganiak.de/2007/pubby/config.rdf#",
    "lcy": "http://purl.org/vocab/lifecycle/schema#",
    "eui": "http://institutions.publicdata.eu/#",
    "wsl": "http://www.wsmo.org/ns/wsmo-lite#",
    "csm": "http://purl.org/csm/1.0#",
    "dvia": "http://data.eurecom.fr/ontology/dvia#",
    "va": "http://code-research.eu/ontology/visual-analytics#",
    "oarj": "http://opendepot.org/reference/linked/1.0/",
    "vsws": "http://verticalsearchworks.com/ontology/synset#",
    "qvoc": "http://mlode.nlp2rdf.org/quranvocab#",
    "fincaselaw": "http://purl.org/finlex/schema/oikeus/",
    "meb": "http://rdf.myexperiment.org/ontologies/base/",
    "lldr": "http://purl.oclc.org/NET/lldr/ns#",
    "cvbase": "http://purl.org/captsolo/resume-rdf/0.2/base#",
    "cis": "http://purl.org/NET/cloudisus#",
    "dcite": "http://purl.org/spar/datacite/",
    "ptop": "http://www.ontotext.com/proton/protontop#",
    "te": "http://www.w3.org/2006/time-entry#",
    "r2rml": "http://www.w3.org/ns/r2rml#",
    "onyx": "http://www.gsi.dit.upm.es/ontologies/onyx/ns#",
    "infor": "http://www.ontologydesignpatterns.org/cp/owl/informationrealization.owl#",
    "ds": "http://purl.org/ctic/dcat#",
    "bwb": "http://doc.metalex.eu/bwb/ontology/",
    "pizza": "http://www.co-ode.org/ontologies/pizza/pizza.owl#",
    "w3con": "http://www.w3.org/2000/10/swap/pim/contact#",
    "iol": "http://www.ontologydesignpatterns.org/ont/dul/IOLite.owl#",
    "graffle": "http://purl.org/twc/vocab/vsr/graffle#",
    "qu": "http://purl.oclc.org/NET/ssnx/qu/qu#",
    "mil": "http://rdf.muninn-project.org/ontologies/military#",
    "opl": "http://openlinksw.com/schema/attribution#",
    "bibframe": "http://bibframe.org/vocab/",
    "pubmed": "http://bio2rdf.org/pubmed_vocabulary:",
    "sso": "http://nlp2rdf.lod2.eu/schema/sso/",
    "telmap": "http://purl.org/telmap/",
    "osgeom": "http://data.ordnancesurvey.co.uk/ontology/geometry/",
    "lodac": "http://lod.ac/ns/lodac#",
    "rdae": "http://rdaregistry.info/Elements/e/",
    "swpatho": "http://swpatho.ag-nbi.de/context/meta.owl#",
    "rlnr": "http://rdflivenews.aksw.org/resource/",
    "dqm": "http://purl.org/dqm-vocabulary/v1/dqm#",
    "mads": "http://www.loc.gov/mads/rdf/v1#",
    "nidm": "http://nidm.nidash.org/",
    "vrank": "http://purl.org/voc/vrank#",
    "twaapi": "http://purl.org/twc/vocab/aapi-schema#",
    "ru": "http://purl.org/imbi/ru-meta.owl#",
    "part": "http://purl.org/vocab/participation/schema#",
    "water": "http://escience.rpi.edu/ontology/semanteco/2/0/water.owl#",
    "rdarole": "http://rdvocab.info/roles/",
    "disease": "http://www.agfa.com/w3c/2009/humanDisorder#",
    "wikterms": "http://wiktionary.dbpedia.org/terms/",
    "fcp": "http://www.newmedialab.at/fcp/",
    "cosmo": "http://purl.org/ontology/cosmo#",
    "strdf": "http://strdf.di.uoa.gr/ontology#",
    "ctorg": "http://purl.org/ctic/infraestructuras/organizacion#",
    "rdacontent": "http://rdvocab.info/termList/RDAContentType/",
    "w3po": "http://purl.org/provenance/w3p/w3po#",
    "seas": "https://w3id.org/seas/",
    "rdamedia": "http://rdvocab.info/termList/RDAMediaType/",
    "won": "http://purl.org/webofneeds/model#",
    "infection": "http://www.agfa.com/w3c/2009/infectiousDisorder#",
    "osspr": "http://data.ordnancesurvey.co.uk/ontology/spatialrelations/",
    "msr": "http://www.telegraphis.net/ontology/measurement/measurement#",
    "lsc": "http://linkedscience.org/lsc/ns#",
    "sdmxd": "http://purl.org/linked-data/sdmx/2009/dimension#",
    "rdacarrier": "http://rdvocab.info/termList/RDACarrierType/",
    "radion": "http://www.w3.org/ns/radion#",
    "prviv": "http://purl.org/net/provenance/integrity#",
    "st": "http://semweb.mmlab.be/ns/stoptimes#",
    "oecc": "http://www.oegov.org/core/owl/cc#",
    "situ": "http://www.ontologydesignpatterns.org/cp/owl/situation.owl#",
    "viskoo": "http://trust.utep.edu/visko/ontology/visko-operator-v3.owl#",
    "vdpp": "http://data.lirmm.fr/ontologies/vdpp#",
    "roevo": "http://purl.org/wf4ever/roevo#",
    "jjd": "http://www.joshuajeeson.com/",
    "gastro": "http://www.ebsemantics.net/gastro#",
    "swperson": "http://data.semanticweb.org/person/",
    "odv": "http://reference.data.gov.uk/def/organogram/",
    "geop": "http://aims.fao.org/aos/geopolitical.owl#",
    "ox": "http://vocab.ox.ac.uk/projectfunding#",
    "pkgsrc": "http://pkgsrc.co/schema#",
    "particip": "http://purl.org/vocab/participation/schema#",
    "elec": "http://purl.org/ctic/sector-publico/elecciones#",
    "quty": "http://www.telegraphis.net/ontology/measurement/quantity#",
    "wdt": "http://www.wikidata.org/prop/direct/",
    "voidp": "http://www.enakting.org/provenance/voidp/",
    "finlaw": "http://purl.org/finlex/schema/laki/",
    "emoca": "http://ns.inria.fr/emoca#",
    "scufl2": "http://ns.taverna.org.uk/2010/scufl2#",
    "onssprel": "http://www.ordnancesurvey.co.uk/ontology/SpatialRelations/v0.2/SpatialRelations.owl#",
    "roterms": "http://purl.org/wf4ever/roterms#",
    "rdarel": "http://rdvocab.info/RDARelationshipsWEMI/",
    "ecos": "http://kmm.lboro.ac.uk/ecos/1.0#",
    "daisy": "http://www.daisy.org/z3998/2012/vocab/",
    "qb4o": "http://purl.org/olap#",
    "frbrer": "http://iflastandards.info/ns/fr/frbr/frbrer/",
    "hlygt": "http://www.holygoat.co.uk/owl/redwood/0.1/tags/",
    "wf4ever": "http://purl.org/wf4ever/wf4ever#",
    "op": "http://environment.data.gov.au/def/op#",
    "spt": "http://spitfire-project.eu/ontology/ns/",
    "stanford": "http://purl.org/olia/stanford.owl#",
    "cbase": "http://ontologycentral.com/2010/05/cb/vocab#",
    "viskov": "http://trust.utep.edu/visko/ontology/visko-view-v3.owl#",
    "moac": "http://observedchange.com/moac/ns#",
    "who": "http://www.who.int/vocab/ontology#",
    "od": "http://purl.org/twc/vocab/opendap#",
    "icane": "http://www.icane.es/opendata/vocab#",
    "rdafrbr": "http://rdvocab.info/uri/schema/FRBRentitiesRDA/",
    "biro": "http://purl.org/spar/biro/",
    "accom": "http://purl.org/acco/ns#",
    "agls": "http://www.agls.gov.au/agls/terms/",
    "dbpr": "http://dbpedia.org/resource/",
    "geosp": "http://rdf.geospecies.org/ont/geospecies#",
    "seq": "http://www.ontologydesignpatterns.org/cp/owl/sequence.owl#",
    "poste": "http://data.lirmm.fr/ontologies/poste#",
    "evident": "http://purl.org/net/evident#",
    "csp": "http://vocab.deri.ie/csp#",
    "vapour": "http://vapour.sourceforge.net/vocab.rdf#",
    "ostop": "http://www.ordnancesurvey.co.uk/ontology/Topography/v0.1/Topography.owl#",
    "mammal": "http://lod.taxonconcept.org/ontology/p01/Mammalia/index.owl#",
    "pingback": "http://purl.org/net/pingback/",
    "locwd": "http://purl.org/locwd/schema#",
    "saif": "http://wwwiti.cs.uni-magdeburg.de/~srahman/",
    "wn20": "http://www.w3.org/2006/03/wn/wn20/",
    "gadm": "http://gadm.geovocab.org/ontology#",
    "odapp": "http://vocab.deri.ie/odapp#",
    "lu": "http://www.ontologydesignpatterns.org/ont/framenet/abox/lu/",
    "lexcz": "http://purl.org/lex/cz#",
    "wlo": "http://purl.org/ontology/wo/",
    "vcard2006": "http://www.w3.org/2006/vcard/ns#",
    "geocontext": "http://www.geocontext.org/publ/2013/vocab#",
    "gawd": "http://gawd.atlantides.org/terms/",
    "dbtont": "http://dbtropes.org/ont/",
    "tsioc": "http://rdfs.org/sioc/types#",
    "opmo": "http://openprovenance.org/model/opmo#",
    "lingvo": "http://www.lingvoj.org/ontology#",
    "rdf123": "http://rdf123.umbc.edu/ns/",
    "olad": "http://openlad.org/vocab#",
    "ep": "http://eprints.org/ontology/",
    "rdai": "http://rdaregistry.info/Elements/i/",
    "graves": "http://rdf.muninn-project.org/ontologies/graves#",
    "vvo": "http://purl.org/vvo/ns#",
    "ontopic": "http://www.ontologydesignpatterns.org/ont/dul/ontopic.owl#",
    "li": "http://def.seegrid.csiro.au/isotc211/iso19115/2003/lineage#",
    "cmd": "http://clarin.eu/cmd#",
    "frsad": "http://iflastandards.info/ns/fr/frsad/",
    "ntag": "http://ns.inria.fr/nicetag/2010/09/09/voc#",
    "dbptmpl": "http://dbpedia.org/resource/Template:",
    "biotop": "http://purl.org/biotop/biotop.owl#",
    "dr": "http://purl.org/swan/2.0/discourse-relationships/",
    "eli": "http://data.europa.eu/eli/ontology#",
    "stats": "http://purl.org/rdfstats/stats#",
    "gnm": "http://www.geonames.org/ontology/mappings/",
    "mged": "http://mged.sourceforge.net/ontologies/MGEDOntology.owl#",
    "snarm": "http://rdf.myexperiment.org/ontologies/snarm/",
    "ecrm": "http://erlangen-crm.org/current/",
    "drm": "http://vocab.data.gov/def/drm#",
    "mvco": "http://purl.oclc.org/NET/mvco.owl#",
    "ssso": "http://purl.org/ontology/ssso#",
    "uta": "http://uptheasset.org/ontology#",
    "limoo": "http://purl.org/LiMo/0.1/",
    "dn": "http://purl.org/datanode/ns/",
    "amalgame": "http://purl.org/vocabularies/amalgame#",
    "bcngeo": "http://datos.bcn.cl/ontologies/bcn-geographics#",
    "coll": "http://purl.org/co/",
    "semio": "http://www.lingvoj.org/semio#",
    "ogbd": "http://www.ogbd.fr/2012/ontologie#",
    "pwo": "http://purl.org/spar/pwo/",
    "san": "http://www.irit.fr/recherches/MELODI/ontologies/SAN#",
    "delta": "http://www.w3.org/2004/delta#",
    "gnvc": "http://purl.org/gc/",
    "vin": "http://www.w3.org/TR/2003/PR-owl-guide-20031209/wine#",
    "obsm": "http://rdf.geospecies.org/methods/observationMethod#",
    "owlse": "http://www.daml.org/services/owl-s/1.2/generic/Expression.owl#",
    "tis": "http://www.ontologydesignpatterns.org/cp/owl/timeindexedsituation.owl#",
    "ebucore": "http://www.ebu.ch/metadata/ontologies/ebucore/ebucore#",
    "fao": "http://fao.270a.info/dataset/",
    "s3db": "http://www.s3db.org/core#",
    "asgv": "http://aims.fao.org/aos/agrovoc/",
    "ordf": "http://purl.org/NET/ordf/",
    "turismo": "http://idi.fundacionctic.org/cruzar/turismo#",
    "gq": "http://genomequest.com/",
    "dcoid": "http://dx.deepcarbon.net/",
    "crsw": "http://courseware.rkbexplorer.com/ontologies/courseware#",
    "viso": "http://purl.org/viso/",
    "kai": "http://kai.uni-kiel.de/",
    "gcis": "http://data.globalchange.gov/gcis.owl#",
    "rdag3": "http://rdvocab.info/ElementsGr3/",
    "rdag1": "http://rdvocab.info/Elements/",
    "eurlex": "http://eur-lex.publicdata.eu/ontology/",
    "pproc": "http://contsem.unizar.es/def/sector-publico/pproc#",
    "geod": "http://vocab.lenka.no/geo-deling#",
    "odpart": "http://www.ontologydesignpatterns.org/cp/owl/participation.owl#",
    "bk": "http://www.provbook.org/ns/#",
    "ses": "http://lod.taxonconcept.org/ses/",
    "parl": "https://id.parliament.uk/schema/",
    "vgo": "http://purl.org/net/VideoGameOntology#",
    "tddo": "http://databugger.aksw.org/ns/core#",
    "trait": "http://contextus.net/ontology/ontomedia/ext/common/trait#",
    "muldicat": "http://iflastandards.info/ns/muldicat#",
    "provone": "http://purl.org/provone#",
    "bmo": "http://collection.britishmuseum.org/id/ontology/",
    "passim": "http://data.lirmm.fr/ontologies/passim#",
    "laabs": "http://dbpedia.org/resource/",
    "raul": "http://vocab.deri.ie/raul#",
    "edgar": "http://edgarwrap.ontologycentral.com/vocab/edgar#",
    "ftcontent": "http://www.ft.com/ontology/content/",
    "wno": "http://wordnet-rdf.princeton.edu/ontology#",
    "oprovo": "http://openprovenance.org/ontology#",
    "oad": "http://lod.xdams.org/reload/oad/",
    "fam": "http://vocab.fusepool.info/fam#",
    "fma": "http://sig.uw.edu/fma#",
    "lmm2": "http://www.ontologydesignpatterns.org/ont/lmm/LMM_L2.owl#",
    "mocanal": "http://www.semanticweb.org/asow/ontologies/2013/9/untitled-ontology-36#",
    "my": "http://www.mobile.com/model/",
    "osadm": "http://data.ordnancesurvey.co.uk/ontology/admingeo/",
    "oplprod": "http://www.openlinksw.com/ontology/products#",
    "bis": "http://bis.270a.info/dataset/",
    "pois": "http://purl.oclc.org/POIS/vcblr#",
    "pvcs": "http://purl.org/twc/vocab/pvcs#",
    "lmm1": "http://www.ontologydesignpatterns.org/ont/lmm/LMM_L1.owl#",
    "rdfdata": "http://rdf.data-vocabulary.org/rdf.xml#",
    "dogont": "http://elite.polito.it/ontologies/dogont.owl#",
    "bag": "http://bag.basisregistraties.overheid.nl/def/bag#",
    "dbpedia2": "http://dbpedia.org/property/",
    "bco": "http://purl.obolibrary.org/obo/bco.owl#",
    "mb": "http://dbtune.org/musicbrainz/resource/instrument/",
    "agro": "http://agrinepaldata.com/vocab/",
    "being": "http://purl.org/ontomedia/ext/common/being#",
    "opllic": "http://www.openlinksw.com/ontology/licenses#",
    "ecc": "https://ns.eccenca.com/",
    "dpd": "http://www.kanzaki.com/ns/dpd#",
    "app": "http://jmvanel.free.fr/ontology/software_applications.n3#",
    "opencyc": "http://sw.opencyc.org/concept/",
    "defns": "http://www.openarchives.org/OAI/2.0/",
    "contsem": "http://contsem.unizar.es/def/sector-publico/contratacion#",
    "tw": "http://tw.rpi.edu/schema/",
    "location": "http://sw.deri.org/2006/07/location/loc#",
    "vra": "http://simile.mit.edu/2003/10/ontologies/vraCore3#",
    "tao": "http://vocab.deri.ie/tao#",
    "fbgeo": "http://rdf.freebase.com/ns/location/geocode/",
    "rdl": "http://data.posccaesar.org/rdl/",
    "onc": "http://www.ics.forth.gr/isl/oncm/core#",
    "mds": "http://doc.metalex.eu/id/",
    "of": "http://owlrep.eu01.aws.af.cm/fridge#",
    "lc": "http://semweb.mmlab.be/ns/linkedconnections#",
    "oliasystem": "http://purl.org/olia/system.owl#",
    "call": "http://webofcode.org/wfn/call:",
    "openskos": "http://openskos.org/xmlns#",
    "bv": "http://purl.org/vocommons/bv#",
    "uis": "http://uis.270a.info/dataset/",
    "ldr": "http://purl.oclc.org/NET/ldr/ns#",
    "lso": "http://linkedspending.aksw.org/ontology/",
    "odo": "http://ocean-data.org/schema/",
    "cjr": "http://vocab.linkeddata.es/datosabiertos/def/urbanismo-infraestructuras/callejero#",
    "thors": "http://resource.geosciml.org/ontology/timescale/thors#",
    "frb": "http://frb.270a.info/dataset/",
    "bbcprov": "http://www.bbc.co.uk/ontologies/provenance/",
    "cdc": "http://www.contextdatacloud.org/resource/",
    "pnt": "http://data.press.net/ontology/tag/",
    "mico": "http://www.mico-project.eu/ns/platform/1.0/schema#",
    "gov": "http://gov.genealogy.net/ontology.owl#",
    "oh": "http://semweb.mmlab.be/ns/oh#",
    "abs": "http://abs.270a.info/dataset/",
    "spdx": "http://spdx.org/rdf/terms#",
    "omnlife": "http://open-multinet.info/ontology/omn-lifecycle#",
    "maso": "http://securitytoolbox.appspot.com/MASO#",
    "mt": "http://www.w3.org/2001/sw/DataAccess/tests/test-manifest#",
    "nxs": "http://www.neclimateus.org/",
    "imf": "http://imf.270a.info/dataset/",
    "dbrc": "http://dbpedia.org/resource/Category:",
    "cmdm": "http://infra.clarin.eu/cmd/",
    "ext": "http://def.seegrid.csiro.au/isotc211/iso19115/2003/extent#",
    "geos": "http://www.telegraphis.net/ontology/geography/geography#",
    "pnc": "http://data.press.net/ontology/classification/",
    "guo": "http://purl.org/hpi/guo#",
    "tm": "http://def.seegrid.csiro.au/isotc211/iso19108/2002/temporal#",
    "cl": "http://advene.org/ns/cinelab/",
    "gl": "http://schema.geolink.org/",
    "gf": "http://def.seegrid.csiro.au/isotc211/iso19109/2005/feature#",
    "msm": "http://iserve.kmi.open.ac.uk/ns/msm#",
    "erce": "http://xxefe.de/",
    "estrn": "http://vocab.linkeddata.es/datosabiertos/def/urbanismo-infraestructuras/transporte#",
    "nex": "http://www.nexml.org/2009/",
    "doas": "http://deductions.github.io/doas.owl.ttl#",
    "csv": "http://vocab.sindice.net/csv/",
    "aws": "http://purl.oclc.org/NET/ssnx/meteo/aws#",
    "cmdi": "http://www.clarin.eu/cmd/",
    "pod": "https://project-open-data.cio.gov/v1.1/schema/#",
    "videogame": "http://purl.org/net/vgo#",
    "oplres": "http://www.openlinksw.com/ontology/restrictions#",
    "lfov": "https://w3id.org/legal_form#",
    "form": "http://deductions-software.com/ontologies/forms.owl.ttl#",
    "bevon": "http://rdfs.co/bevon/",
    "agrd": "http://agrinepaldata.com/",
    "bgcat": "http://bg.dbpedia.org/resource/?????????:",
    "mmd": "http://musicbrainz.org/ns/mmd-1.0#",
    "roadmap": "http://mappings.roadmap.org/",
    "fcs": "http://clarin.eu/fcs/resource#",
    "sam": "http://def.seegrid.csiro.au/isotc211/iso19156/2011/sampling#",
    "skos08": "http://www.w3.org/2008/05/skos#",
    "dq": "http://def.seegrid.csiro.au/isotc211/iso19115/2003/dataquality#",
    "shex": "http://www.w3.org/2013/ShEx/ns#",
    "language": "http://id.loc.gov/vocabulary/iso639-1/",
    "odapps": "http://semweb.mmlab.be/ns/odapps#",
    "isocat": "http://www.isocat.org/datcat/",
    "hp": "http://purl.org/voc/hp/",
    "cpant": "http://purl.org/NET/cpan-uri/terms#",
    "dbug": "http://ontologi.es/doap-bugs#",
    "wfn": "http://webofcode.org/wfn/",
    "sru": "http://www.loc.gov/zing/srw/",
    "omdoc": "http://omdoc.org/ontology/",
    "ruto": "http://rdfunit.aksw.org/ns/core#",
    "citof": "http://www.essepuntato.it/2013/03/cito-functions#",
    "deps": "http://ontologi.es/doap-deps#",
    "luc": "http://www.ontotext.com/owlim/lucene#",
    "wikim": "http://spi-fm.uca.es/spdef/models/genericTools/wikim/1.0#",
    "plo": "http://purl.org/net/po#",
    "employee": "http://www.employee.com/data#",
    "dicom": "http://purl.org/healthcarevocab/v1#",
    "olac11": "http://www.language-archives.org/OLAC/1.1/",
    "frame": "http://www.ontologydesignpatterns.org/ont/framenet/abox/frame/",
    "bgn": "http://bibliograph.net/schemas/",
    "static": "http://vocab-ld.org/vocab/static-ld#",
    "oils": "http://lemon-model.net/oils#",
    "jp1": "http://rdf.muninn-project.org/ontologies/jp1/",
    "sro": "http://salt.semanticauthoring.org/ontologies/sro#",
    "rdarel2": "http://metadataregistry.org/uri/schema/RDARelationshipsGR2/",
    "babelnet": "http://babelnet.org/2.0/",
    "vext": "http://ldf.fi/void-ext#",
    "topo": "http://data.ign.fr/def/topo#",
    "limo": "http://www.purl.org/limo-ontology/limo#",
    "esaloj": "http://vocab.linkeddata.es/datosabiertos/def/turismo/alojamiento#",
    "lexicon": "http://www.example.org/lexicon#",
    "mmt": "http://linkedmultimedia.org/sparql-mm/functions/temporal#",
    "basic": "http://def.seegrid.csiro.au/isotc211/iso19103/2005/basic#",
    "onisep": "http://rdf.onisep.fr/resource/",
    "cart": "http://purl.org/net/cartCoord#",
    "itm": "http://spi-fm.uca.es/spdef/models/genericTools/itm/1.0#",
    "lio": "http://purl.org/net/lio#",
    "pco": "http://purl.org/procurement/public-contracts#",
    "fp3": "http://vocab.fusepool.info/fp3#",
    "dannet": "http://www.wordnet.dk/owl/instance/2009/03/instances/",
    "acrt": "http://privatealpha.com/ontology/certification/1#",
    "gaf": "http://groundedannotationframework.org/",
    "psys": "http://www.ontotext.com/proton/protonsys#",
    "esadm": "http://vocab.linkeddata.es/datosabiertos/def/sector-publico/territorio#",
    "smg": "http://ns.cerise-project.nl/energy/def/cim-smartgrid#",
    "sw": "http://linkedwidgets.org/statisticalwidget/ontology/",
    "olac": "http://www.language-archives.org/OLAC/1.0/",
    "oan": "http://data.lirmm.fr/ontologies/oan/",
    "taxon": "http://purl.org/biodiversity/taxon/",
    "hr": "http://iserve.kmi.open.ac.uk/ns/hrests#",
    "voidext": "http://rdfs.org/ns/void-ext#",
    "lda": "http://purl.org/linked-data/api/vocab#",
    "rmo": "http://eatld.et.tu-dresden.de/rmo#",
    "dpc": "http://hospee.org/ontologies/dpc/",
    "wro": "http://purl.org/net/wf4ever/ro#",
    "shoah": "http://dati.cdec.it/lod/shoah/",
    "llo": "http://lodlaundromat.org/ontology/",
    "oss": "http://opendata.caceres.es/def/ontosemanasanta#",
    "holding": "http://purl.org/ontology/holding#",
    "irsteaont": "http://ontology.irstea.fr/weather/ontology#",
    "odrs": "http://schema.theodi.org/odrs#",
    "ccrel": "http://creativecommons.org/ns#",
    "ost": "http://w3id.org/ost/ns#",
    "pic": "http://www.ipaw.info/ns/picaso#",
    "xlime": "http://xlime-project.org/vocab/",
    "rvl": "http://purl.org/rvl/",
    "mmf": "http://linkedmultimedia.org/sparql-mm/ns/1.0.0/function#",
    "sbench": "http://swat.cse.lehigh.edu/onto/univ-bench.owl#",
    "gts": "http://resource.geosciml.org/ontology/timescale/gts#",
    "gm": "http://def.seegrid.csiro.au/isotc211/iso19107/2003/geometry#",
    "daq": "http://purl.org/eis/vocab/daq#",
    "bbccms": "http://www.bbc.co.uk/ontologies/cms/",
    "rs": "http://spektrum.ctu.cz/ontologies/radio-spectrum#",
    "physo": "http://merlin.phys.uni.lodz.pl/onto/physo/physo.owl#",
    "l2sp": "http://www.linked2safety-project.eu/properties/",
    "shw": "http://paul.staroch.name/thesis/SmartHomeWeather.owl#",
    "friends": "http://www.openarchives.org/OAI/2.0/friends/",
    "sao": "http://salt.semanticauthoring.org/ontologies/sao#",
    "oplcert": "http://www.openlinksw.com/schemas/cert#",
    "origins": "http://origins.link/",
    "tac": "http://ns.bergnet.org/tac/0.1/triple-access-control#",
    "pattern": "http://www.essepuntato.it/2008/12/pattern#",
    "dsn": "http://purl.org/dsnotify/vocab/eventset/",
    "esco": "http://data.europa.eu/esco/model#",
    "ls": "http://linkedspending.aksw.org/instance/",
    "scip": "http://lod.taxonconcept.org/ontology/sci_people.owl#",
    "sor": "http://purl.org/net/soron/",
    "tavprov": "http://ns.taverna.org.uk/2012/tavernaprov/",
    "wn31": "http://wordnet-rdf.princeton.edu/wn31/",
    "ipo": "http://purl.org/ipo/core#",
    "oslo": "http://purl.org/oslo/ns/localgov#",
    "security": "http://securitytoolbox.appspot.com/securityMain#",
    "esequip": "http://vocab.linkeddata.es/datosabiertos/def/urbanismo-infraestructuras/equipamiento#",
    "locah": "http://data.archiveshub.ac.uk/def/",
    "galaksiya": "http://ontoloji.galaksiya.com/vocab/",
    "fe": "http://www.ontologydesignpatterns.org/ont/framenet/abox/fe/",
    "kml": "http://www.opengis.net/kml/2.2#",
    "scoro": "http://purl.org/spar/scoro/",
    "hdo": "http://www.samos.gr/ontologies/helpdeskOnto.owl#",
    "lindt": "https://w3id.org/lindt/voc#",
    "whisky": "http://vocab.org/whisky/terms/",
    "travel": "http://www.co-ode.org/roberts/travel.owl#",
    "chembl": "http://rdf.ebi.ac.uk/terms/chembl#",
    "lmf": "http://www.lexinfo.net/lmf#",
    "estatwrap": "http://ontologycentral.com/2009/01/eurostat/ns#",
    "bner": "http://datos.bne.es/resource/",
    "zr": "http://explain.z3950.org/dtd/2.0/",
    "ldvm": "http://linked.opendata.cz/ontology/ldvm/",
    "lden": "http://www.linklion.org/lden/",
    "trig": "http://www.w3.org/2004/03/trix/rdfg-1/",
    "lpeu": "http://purl.org/linkedpolitics/vocabulary/eu/plenary/",
    "h2o": "http://def.seegrid.csiro.au/isotc211/iso19150/-2/2012/basic#",
    "rvdata": "http://data.rvdata.us/",
    "bbccore": "http://www.bbc.co.uk/ontologies/coreconcepts/",
    "pni": "http://data.press.net/ontology/identifier/",
    "ll": "http://lodlaundromat.org/resource/",
    "oplacl": "http://www.openlinksw.com/ontology/acl#",
    "uri4uri": "http://uri4uri.net/vocab#",
    "bridge": "http://purl.org/vocommons/bridge#",
    "rdag2": "http://rdvocab.info/ElementsGr2/",
    "llm": "http://lodlaundromat.org/metrics/ontology/",
    "cpsv": "http://purl.org/vocab/cpsv#",
    "xcql": "http://docs.oasis-open.org/ns/search-ws/xcql#",
    "rdapo": "http://rdaregistry.info/termList/RDAPolarity/",
    "xapi": "http://purl.org/xapi/ontology#",
    "pkm": "http://www.ontotext.com/proton/protonkm#",
    "naval": "http://rdf.muninn-project.org/ontologies/naval#",
    "spfood": "http://kmi.open.ac.uk/projects/smartproducts/ontologies/food.owl#",
    "metadata": "http://purl.oreilly.com/ns/meta/",
    "navm": "https://w3id.org/navigation_menu#",
    "vmm": "http://spi-fm.uca.es/spdef/models/genericTools/vmm/1.0#",
    "emtr": "http://purl.org/NET/ssnext/electricmeters#",
    "stories": "http://purl.org/ontology/stories/",
    "keys": "http://purl.org/NET/c4dm/keys.owl#",
    "rdafr": "http://rdaregistry.info/termList/frequency/",
    "wn30": "http://purl.org/vocabularies/princeton/wn30/",
    "koly": "http://www.ensias.ma/",
    "dcs": "http://ontologi.es/doap-changeset#",
    "nerd": "http://nerd.eurecom.fr/ontology#",
    "verb": "https://w3id.org/verb/",
    "lofv": "http://purl.org/legal_form/vocab#",
    "irstea": "http://ontology.irstea.fr/",
    "bn": "http://babelnet.org/rdf/",
    "dbcat": "http://dbpedia.org/resource/Category:",
    "c9d": "http://purl.org/twc/vocab/conversion/",
    "geovoid": "http://purl.org/geovocamp/ontology/geovoid/",
    "bibrm": "http://vocab.ub.uni-leipzig.de/bibrm/",
    "xslopm": "http://purl.org/net/opmv/types/xslt#",
    "rdagw": "http://rdaregistry.info/termList/grooveWidth/",
    "ljkl": "http://teste.com/",
    "affymetrix": "http://bio2rdf.org/affymetrix_vocabulary:",
    "rdacct": "http://rdaregistry.info/termList/CollTitle/",
    "csvw": "http://www.w3.org/ns/csvw#",
    "diag": "http://www.loc.gov/zing/srw/diagnostic/",
    "rdami": "http://rdaregistry.info/termList/modeIssue/",
    "ramon": "http://rdfdata.eionet.europa.eu/ramon/ontology/",
    "ecgl": "http://schema.geolink.org/",
    "rdafnm": "http://rdaregistry.info/termList/FormNoteMus/",
    "reegle": "http://reegle.info/schema#",
    "solid": "http://www.w3.org/ns/solid/terms#",
    "rdabm": "http://rdaregistry.info/termList/RDABaseMaterial/",
    "escjr": "http://vocab.linkeddata.es/datosabiertos/def/urbanismo-infraestructuras/callejero#",
    "oplmkt": "http://www.openlinksw.com/ontology/market#",
    "unspsc": "http://ontoview.org/schema/unspsc/1#",
    "oplecrm": "http://www.openlinksw.com/ontology/ecrm#",
    "yo": "http://yovisto.com/",
    "sakthi": "http://infotech.nitk.ac.in/research-scholars/sakthi-murugan-r/",
    "phdd": "http://rdf-vocabulary.ddialliance.org/phdd#",
    "goog": "http://schema.googleapis.com/",
    "d2d": "http://rdfns.org/d2d/",
    "xrd": "http://docs.oasis-open.org/ns/xri/xrd-1.0#",
    "rdatc": "http://rdaregistry.info/termList/trackConfig/",
    "rdamt": "http://rdaregistry.info/termList/RDAMediaType/",
    "loted": "http://loted.eu/ontology#",
    "npdv": "http://sws.ifi.uio.no/vocab/npd#",
    "rdagd": "http://rdaregistry.info/termList/gender/",
    "date": "http://contextus.net/ontology/ontomedia/misc/date#",
    "opengov": "http://www.w3.org/opengov#",
    "mtlo": "http://www.ics.forth.gr/isl/MarineTLO/v4/marinetlo.owl#",
    "rdasoi": "http://rdaregistry.info/termList/statIdentification/",
    "kegg": "http://bio2rdf.org/ns/kegg#",
    "eurostat": "http://wifo5-04.informatik.uni-mannheim.de/eurostat/resource/eurostat/",
    "lsd": "http://linkedwidgets.org/statisticaldata/ontology/",
    "swpm": "http://spi-fm.uca.es/spdef/models/deployment/swpm/1.0#",
    "voidwh": "http://www.ics.forth.gr/isl/VoIDWarehouse/VoID_Extension_Schema.owl#",
    "rdarr": "http://rdaregistry.info/termList/RDAReductionRatio/",
    "wb": "http://data.worldbank.org/",
    "gpml": "http://vocabularies.wikipathways.org/gpml#",
    "wikimedia": "http://upload.wikimedia.org/wikipedia/commons/f/f6/",
    "ilap": "http://data.posccaesar.org/ilap/",
    "insdc": "http://ddbj.nig.ac.jp/ontologies/sequence#",
    "rdaftn": "http://rdaregistry.info/termList/TacNotation/",
    "condition": "http://www.kinjal.com/condition:",
    "eccrev": "https://vocab.eccenca.com/revision/",
    "oml": "http://def.seegrid.csiro.au/ontology/om/om-lite#",
    "bgdbp": "http://bg.dbpedia.org/property/",
    "lcdr": "http://ns.lucid-project.org/revision/",
    "olca": "http://www.lingvoj.org/olca#",
    "bnf": "http://www.w3.org/2000/10/swap/grammar/bnf#",
    "cbo": "http://comicmeta.org/cbo/",
    "religion": "http://rdf.muninn-project.org/ontologies/religion#",
    "bgdbr": "http://bg.dbpedia.org/resource/",
    "rdaco": "http://rdaregistry.info/termList/RDAContentType/",
    "oplcb": "http://www.openlinksw.com/schemas/crunchbase#",
    "ontosec": "http://www.semanticweb.org/ontologies/2008/11/OntologySecurity.owl#",
    "oae": "http://www.ics.forth.gr/isl/oae/core#",
    "glview": "http://schema.geolink.org/dev/view/",
    "rdaemm": "http://rdaregistry.info/termList/emulsionMicro/",
    "orges": "http://datos.gob.es/def/sector-publico/organizacion#",
    "rdagrp": "http://rdaregistry.info/termList/groovePitch/",
    "foo": "http://filmontology.org/ontology/1.0/",
    "faq": "http://www.openlinksw.com/ontology/faq#",
    "beth": "http://www.google.com/",
    "lemonuby": "http://lemon-model.net/lexica/uby/",
    "kees": "http://linkeddata.center/kees/v1#",
    "bfo": "http://www.ifomis.org/bfo/1.1#",
    "caplibacl": "http://schemas.capita-libraries.co.uk/2015/acl/schema#",
    "rdarm": "http://registry.info/termList/recMedium/",
    "decision": "https://decision-ontology.googlecode.com/svn/trunk/decision.owl#",
    "wikibase": "http://wikiba.se/ontology#",
    "d0": "http://ontologydesignpatterns.org/ont/wikipedia/d0.owl#",
    "rdact": "http://rdaregistry.info/termList/RDACarrierType/",
    "clirio": "http://clirio.kaerle.com/clirio.owl#",
    "omnfed": "http://open-multinet.info/ontology/omn-federation#",
    "ncit": "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl#",
    "spcm": "http://spi-fm.uca.es/spdef/models/deployment/spcm/1.0#",
    "espresup": "http://vocab.linkeddata.es/datosabiertos/def/hacienda/presupuestos#",
    "leaks": "http://data.ontotext.com/resource/leak/",
    "lmx": "http://www.w3.org/XML/1998/namespace/",
    "vidont": "http://vidont.org/",
    "saws": "http://purl.org/saws/ontology#",
    "lw": "http://linkedwidgets.org/ontologies/",
    "rdacc": "http://rdaregistry.info/termList/RDAColourContent/",
    "ubiq": "http://server.ubiqore.com/ubiq/core#",
    "art": "http://w3id.org/art/terms/1.0/",
    "rml": "http://semweb.mmlab.be/ns/rml#",
    "rdasco": "http://rdaregistry.info/termList/soundCont/",
    "scor": "http://purl.org/eis/vocab/scor#",
    "td5": "http://td5.org/#",
    "tp": "http://tour-pedia.org/download/tp.owl#",
    "vstoi": "http://hadatac.org/ont/vstoi#",
    "ha": "http://sensormeasurement.appspot.com/ont/home/homeActivity#",
    "odbc": "http://www.openlinksw.com/ontology/odbc#",
    "rdafmn": "http://rdaregistry.info/termList/MusNotation/",
    "cwl": "https://w3id.org/cwl/cwl#",
    "owsom": "https://onlinesocialmeasures.wordpress.com/",
    "vag": "http://www.essepuntato.it/2013/10/vagueness/",
    "oxi": "http://omerxi.com/ontologies/core.owl.ttl#",
    "dicera": "http://semweb.mmlab.be/ns/dicera#",
    "merge": "http://jazz.net/ns/lqe/merge/",
    "leak": "http://data.ontotext.com/resource/leak/",
    "auto": "http://auto.schema.org/",
    "samfl": "http://def.seegrid.csiro.au/ontology/om/sam-lite#",
    "escom": "http://vocab.linkeddata.es/datosabiertos/def/comercio/tejidoComercial#",
    "figigii": "http://www.omg.org/spec/FIGI/GlobalInstrumentIdentifiers/",
    "rofrr": "http://rdaregistry.info/termList/rofrr/",
    "esdir": "http://vocab.linkeddata.es/datosabiertos/def/urbanismo-infraestructuras/direccionPostal#",
    "incident": "http://vocab.resc.info/incident#",
    "pcdt": "http://purl.org/procurement/public-contracts-datatypes#",
    "company": "http://intellimind.io/ns/company#",
    "lsmap": "http://ontology.cybershare.utep.edu/ELSEWeb/elseweb-data.owl#",
    "mexv": "http://mex.aksw.org/mex-algo#",
    "rdafs": "http://rdaregistry.info/termList/fontSize/",
    "latitude": "https://www.w3.org/2006/vcard/ns#latitude#",
    "aktivesa": "http://sa.aktivespace.org/ontologies/aktivesa#",
    "ogc": "http://www.opengis.net/def/",
    "hasneto": "http://hadatac.org/ont/hasneto#",
    "tadirah": "http://tadirah.dariah.eu/vocab/",
    "rdapmt": "http://rdaregistry.info/termList/prodTactile/",
    "orth": "http://purl.org/net/orth#",
    "cdt": "http://w3id.org/lindt/custom_datatypes#",
    "quantity": "http://qudt.org/schema/quantity#",
    "jolux": "http://data.legilux.public.lu/resource/ontology/jolux#",
    "fdbp": "http://fr.dbpedia.org/property/",
    "rofch": "http://rdaregistry.info/termList/rofch/",
    "proms": "http://promsns.org/def/proms#",
    "demlab": "http://www.demcare.eu/ontologies/demlab.owl#",
    "cpack": "http://cliopatria.swi-prolog.org/schema/cpack#",
    "moo": "http://www.movieontology.org/2009/11/09/movieontology.owl#",
    "mv": "http://schema.mobivoc.org/",
    "ecglview": "http://schema.geolink.org/view/",
    "rdabf": "http://rdaregistry.info/termList/bookFormat/",
    "rdatr": "http://rdaregistry.info/termList/typeRec/",
    "traffic": "http://www.sensormeasurement.appspot.com/ont/transport/traffic#",
    "lawd": "http://lawd.info/ontology/",
    "ev": "http://www.w3.org/2001/xml-events/",
    "ops": "http://vocabularies.bridgedb.org/ops#",
    "uneskos": "http://purl.org/voc/uneskos#",
    "uby": "http://purl.org/olia/ubyCat.owl#",
    "ofrd": "http://purl.org/opdm/refrigerator#",
    "lsqv": "http://lsq.aksw.org/vocab#",
    "airs": "https://raw.githubusercontent.com/airs-linked-data/lov/latest/src/airs_vocabulary.ttl#",
    "vacseen1": "http://www.semanticweb.org/parthasb/ontologies/2014/6/vacseen1/",
    "rofer": "http://rdaregistry.info/termList/rofer/",
    "rdaz": "http://rdaregistry.info/Elements/z/",
    "dio": "https://w3id.org/dio#",
    "dm2e": "http://onto.dm2e.eu/schemas/dm2e/",
    "rofit": "http://rdaregistry.info/termList/rofit/",
    "rdafnv": "http://rdaregistry.info/termList/noteForm/",
    "newsevents": "http://www.aifb.uni-karlsruhe.de/WBS/uhe/ontologies#",
    "data": "http://data.odw.tw/",
    "sg": "http://www.springernature.com/scigraph/ontologies/core/",
    "efrbroo": "http://erlangen-crm.org/efrbroo/",
    "gist": "http://ontologies.semanticarts.com/gist/",
    "essglobal": "http://purl.org/essglobal/vocab/v1.0/",
    "rut": "http://rdfunit.aksw.org/ns/core#",
    "jerm": "http://jermontology.org/ontology/JERMOntology#",
    "sdshare": "http://www.sdshare.org/2012/extension/",
    "gont": "https://gont.ch/",
    "datex": "http://vocab.datex.org/terms#",
    "webservice": "http://www.openlinksw.com/ontology/webservices#",
    "text": "http://jena.apache.org/text#",
    "hello": "https://www.youtube.com/user/SuperTellAFriend/featured/",
    "step": "http://purl.org/net/step#",
    "um": "http://intelleo.eu/ontologies/user-model/ns/",
    "imind": "http://schema.intellimind.ns/symbology#",
    "rofem": "http://rdaregistry.info/termList/rofem/",
    "mbgd": "http://mbgd.genome.ad.jp/owl/mbgd.owl#",
    "esapar": "http://vocab.linkeddata.es/datosabiertos/def/urbanismo-infraestructuras/aparcamiento#",
    "bsym": "http://bsym.bloomberg.com/sym/",
    "ruian": "https://data.cssz.cz/ontology/ruian/",
    "eccauth": "https://vocab.eccenca.com/auth/",
    "sgg": "http://www.springernature.com/scigraph/graphs/",
    "lswmo": "http://ontology.cybershare.utep.edu/ELSEWeb/elseweb-modelling.owl#",
    "fo": "http://www.w3.org/1999/XSL/Format#",
    "gci": "http://ontology.eil.utoronto.ca/GCI/Foundation/GCI-Foundation.owl#",
    "ttla": "https://w3id.org/ttla/",
    "mexcore": "http://mex.aksw.org/mex-core#",
    "fnabox": "http://www.ontologydesignpatterns.org/ont/framenet/abox/",
    "mdi": "http://w3id.org/multidimensional-interface/ontology#",
    "pp": "http://peoplesplaces.de/ontology#",
    "driver": "http://deductions.github.io/drivers.owl.ttl#",
    "oplweb": "http://www.openlinksw.com/schemas/oplweb#",
    "piero": "http://reactionontology.org/piero/",
    "lheo": "http://www.conjecto.com/ontology/2015/lheo#",
    "dqv": "http://www.w3.org/ns/dqv#",
    "tix": "http://toptix.com/2010/esro/",
    "html": "http://izmus.cz/#",
    "ianarel": "http://www.iana.org/assignments/relation/",
    "pv": "http://ns.inria.fr/provoc#",
    "rofsm": "http://rdaregistry.info/termList/rofsm/",
    "markus": "http://www.markus.com/",
    "output": "http://volt-name.space/vocab/output#",
    "mod": "http://www.isibang.ac.in/ns/mod#",
    "cwork": "http://www.bbc.co.uk/ontologies/creativework/",
    "vocnet": "http://schema.vocnet.org/",
    "clinic": "http://example.com/clinic#",
    "amsl": "http://vocab.ub.uni-leipzig.de/amsl/",
    "llont": "http://www.linklion.org/ontology#",
    "rofid": "http://rdaregistry.info/termList/rofid/",
    "regorg": "http://www.w3.org/ns/regorg#",
    "gs1": "http://gs1.org/voc/",
    "crowd": "http://purl.org/crowd/",
    "teamwork": "http://topbraid.org/teamwork#",
    "hto": "http://project-haystack.org/hto#",
    "sosa": "http://www.w3.org/ns/sosa/",
    "yd": "https://yodata.io/",
    "vf": "https://w3id.org/valueflows#",
    "iana": "http://www.iana.org/assignments/relation/",
    "glycan": "http://purl.jp/bio/12/glyco/glycan#",
    "remetca": "http://www.purl.org/net/remetca#",
    "opllog": "http://www.openlinksw.com/ontology/logging#",
    "ethc": "http://ethoinformatics.org/ethocore/",
    "owl2xml": "http://www.w3.org/2006/12/owl2-xml#",
    "mexalgo": "http://mex.aksw.org/mex-algo#",
    "geojson": "http://ld.geojson.org/vocab#",
    "edgarcik": "http://edgarwrap.ontologycentral.com/cik/",
    "itcat": "http://th-brandenburg.de/ns/itcat#",
    "exc": "http://example.com/",
    "literal": "http://www.essepuntato.it/2010/06/literalreification/",
    "lsweb": "http://ontology.cybershare.utep.edu/ELSEWeb/elseweb-data.owl#",
    "puml": "http://plantuml.com/ontology#",
    "ns1": "http://www.w3.org/1999/xhtml/vocab#",
    "dcatapit": "http://dati.gov.it/onto/dcatapit#",
    "rdaar": "http://rdaregistry.info/termList/AspectRatio/",
    "saref": "https://w3id.org/saref#",
    "cwrc": "http://sparql.cwrc.ca/ontology/cwrc#",
    "cff": "http://purl.oclc.org/NET/ssnx/cf/cf-feature#",
    "obeu": "http://data.openbudgets.eu/ontology/",
    "fntbox": "http://www.ontologydesignpatterns.org/ont/framenet/tbox/",
    "gns": "http://sws.geonames.org/",
    "lgdm": "http://linkedgeodata.org/meta/",
    "pcit": "http://public-contracts.nexacenter.org/id/propertiesRole/",
    "lswpm": "http://ontology.cybershare.utep.edu/ELSEWeb/elseweb-lifemapper-parameters.owl#",
    "rofrm": "http://rdaregistry.info/termList/rofrm/",
    "door": "http://kannel.open.ac.uk/ontology#",
    "changeset": "http://purl.org/vocab/changeset/schema#",
    "nlon": "http://lod.nl.go.kr/ontology/",
    "lslife": "http://ontology.cybershare.utep.edu/ELSEWeb/elseweb-lifemapper.owl#",
    "bb": "http://www.snik.eu/ontology/bb/",
    "lgdt": "http://linkedgeodata.org/triplify/",
    "pid": "http://permid.org/ontology/organization/",
    "bioc": "http://deductions.github.io/biological-collections.owl.ttl#",
    "tgm": "http://id.loc.gov/vocabulary/graphicMaterials/",
    "duv": "http://www.w3.org/ns/duv#",
    "wimpo": "http://rdfex.org/withImports?uri=",
    "swcomp": "https://github.com/ali1k/ld-reactor/blob/master/vocabulary/index.ttl#",
    "dpn": "http://purl.org/dpn#",
    "minim": "http://purl.org/minim/minim#",
    "uom": "http://www.opengis.net/def/uom/OGC/1.0/",
    "esair": "http://vocab.linkeddata.es/datosabiertos/def/medio-ambiente/calidad-aire#",
    "atlas": "http://rdf.ebi.ac.uk/resource/atlas/",
    "json": "https://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf#",
    "kbv": "https://id.kb.se/vocab/",
    "rpath": "https://w3id.org/lodsight/rdf-path#",
    "open311": "http://ontology.eil.utoronto.ca/open311#",
    "ou": "http://opendata.unex.es/def/ontouniversidad#",
    "alethio": "http://aleth.io/",
    "crmdig": "http://www.ics.forth.gr/isl/CRMdig/",
    "vartrans": "http://www.w3.org/ns/lemon/vartrans#",
    "fire": "http://tldp.org/HOWTO/XML-RPC-HOWTO/xmlrpc-howto-java.html#",
    "iiif": "http://iiif.io/api/image/2#",
    "rdapm": "http://rdaregistry.info/termList/RDAproductionMethod/",
    "dash": "http://datashapes.org/dash#",
    "rofhf": "http://rdaregistry.info/termList/rofhf/",
    "rgml": "http://purl.org/puninj/2001/05/rgml-schema#",
    "frgeo": "http://rdf.insee.fr/geo/",
    "b3kat": "http://lod.b3kat.de/title/",
    "pmo": "http://premon.fbk.eu/ontology/core#",
    "lsq": "http://lsq.aksw.org/vocab#",
    "eccdi": "https://vocab.eccenca.com/di/",
    "ipsv": "http://id.esd.org.uk/list/",
    "pm": "http://premon.fbk.eu/resource/",
    "dwciri": "http://rs.tdwg.org/dwc/iri/",
    "r4r": "http://guava.iis.sinica.edu.tw/r4r/",
    "neotec": "http://neotec.rc.unesp.br/resource/Neotectonics/",
    "dcosample": "http://info.deepcarbon.net/sample/schema#",
    "datacite": "http://purl.org/spar/datacite/",
    "pmonb": "http://premon.fbk.eu/ontology/nb#",
    "salad": "https://w3id.org/cwl/salad#",
    "sx": "http://shex.io/ns/shex#",
    "ensembl": "http://rdf.ebi.ac.uk/resource/ensembl/",
    "mmm": "http://www.mico-project.eu/ns/mmm/2.0/schema#",
    "si": "http://sisteminformasi.com/",
    "jpost": "http://rdf.jpostdb.org/ontology/jpost.owl#",
    "qms": "http://data.europa.eu/esco/qms#",
    "mmoon": "http://mmoon.org/mmoon/",
    "ws": "http://www.w3.org/ns/pim/space#",
    "isbdu": "http://iflastandards.info/ns/isbd/unc/elements/",
    "meshv": "http://id.nlm.nih.gov/mesh/vocab#",
    "dbkwik": "http://dbkwik.webdatacommons.org/",
    "pmhb": "http://pmhb.org/",
    "pmovn": "http://premon.fbk.eu/ontology/vn#",
    "lgt": "http://linkedgadget.com/wiki/Property:",
    "csdbp": "http://cs.dbpedia.org/",
    "biml": "http://schemas.varigence.com/biml.xsd#",
    "rdaterm": "http://rdaregistry.info/termList/RDATerms/",
    "rofet": "http://rdaregistry.info/termList/rofet/",
    "rofin": "http://rdaregistry.info/termList/rofin/",
    "tarql": "http://tarql.github.io/tarql#",
    "semiot": "http://w3id.org/semiot/ontologies/semiot#",
    "cd": "http://citydata.wu.ac.at/ns#",
    "planet": "http://dbpedia.org/",
    "wde": "http://www.wikidata.org/entity/",
    "sdterms": "http://statisticaldata.linkedwidgets.org/terms/",
    "rofsf": "http://rdaregistry.info/termList/rofsf/",
    "bblfish": "http://bblfish.net/people/henry/card#",
    "dcodata": "http://info.deepcarbon.net/data/schema#",
    "pmd": "http://publishmydata.com/def/dataset#",
    "dcap": "http://purl.org/ws-mmi-dc/terms/",
    "cue": "http://www.clarin.eu/cmdi/cues/display/1.0#",
    "crime": "http://purl.org/vocab/reloc/",
    "sdt": "http://statisticaldata.linkedwidgets.org/terms/",
    "ttp": "http://eample.com/test#",
    "wdv": "http://www.wikidata.org/value/",
    "customer": "http://www.valuelabs.com/",
    "volt": "http://volt-name.space/ontology/",
    "rofrt": "http://rdaregistry.info/termList/rofrt/",
    "rdacdt": "http://rdaregistry.info/termList/RDACartoDT/",
    "pdf": "http://ns.adobe.com/pdf/1.3/",
    "system": "http://www.univalle.edu.co/ontologies/System#",
    "pcdm": "http://pcdm.org/models#",
    "mls": "http://www.w3.org/ns/mls#",
    "rofim": "http://rdaregistry.info/termList/rofim/",
    "lyon": "http://dbpedia.org/resource/Lyon/",
    "scholl": "http://menemeneml.com/school#",
    "voc": "http://voc.odw.tw/",
    "neotecbib": "http://neotec.rc.unesp.br/resource/NeotectonicsBibliography/",
    "year": "http://www.w3.org/year/",
    "aozora": "http://purl.org/net/aozora/",
    "pmofn": "http://premon.fbk.eu/ontology/fn#",
    "esproc": "http://vocab.linkeddata.es/datosabiertos/def/sector-publico/procedimientos#",
    "rdfp": "https://w3id.org/rdfp/",
    "dcodt": "http://info.deepcarbon.net/datatype/schema#",
    "efd": "http://data.foodanddrinkeurope.eu/ontology#",
    "or": "http://openresearch.org/vocab/",
    "doi": "https://doi.org/",
    "rdacpc": "http://rdaregistry.info/termList/configPlayback/",
    "ali": "http://www.niso.org/schemas/ali/1.0/",
    "maet": "http://edg.topbraid.solutions/taxonomy/macroeconomics/",
    "rfd": "http://com.intrinsec//ontology#",
    "rdacarx": "http://rdaregistry.info/termList/RDACarrierEU/",
    "spv": "http://completeness.inf.unibz.it/sp-vocab#",
    "sct": "http://snomed.info/sct/",
    "eccpubsub": "https://vocab.eccenca.com/pubsub/",
    "sgfn": "http://w3id.org/sparql-generate/fn/",
    "vam": "http://www.metmuseum.org/",
    "apf": "http://jena.apache.org/ARQ/property#",
    "eame": "http://www.semanticweb.org/ontologia_EA#",
    "rdaft": "http://rdaregistry.info/termList/fileType/",
    "webac": "http://fedora.info/definitions/v4/webac#",
    "asawoo": "http://liris.cnrs.fr/asawoo/",
    "prohow": "https://w3id.org/prohow#",
    "rdabs": "http://rdaregistry.info/termList/broadcastStand/",
    "sdmxm": "http://purl.org/linked-data/sdmx/2009/measure#",
    "vogd": "http://ogd.ifs.tuwien.ac.at/vienna/geo/",
    "dbfo": "http://dbpedia.org/facts/ontology#",
    "juso": "http://rdfs.co/juso/",
    "tissue": "http://www.univalle.edu.co/ontologies/Tissue#",
    "pbody": "http://reference.data.gov.uk/def/public-body/",
    "llr": "http://lodlaundromat.org/resource/",
    "pmopb": "http://premon.fbk.eu/ontology/pb#",
    "sdmxc": "http://purl.org/linked-data/sdmx/2009/concept#",
    "smxm": "http://smxm.ga/",
    "fluidops": "http://www.fluidops.com/",
    "rdalay": "http://rdaregistry.info/termList/layout/",
    "fno": "http://w3id.org/function/ontology#",
    "ncicp": "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl#",
    "soch": "http://kulturarvsdata.se/ksamsok#",
    "rdagen": "http://rdaregistry.info/termList/RDAGeneration/",
    "ideotalex": "http://www.ideotalex.eu/datos/recurso/",
    "isidore": "http://www.rechercheisidore.fr/class/",
    "eol": "http://purl.org/biodiversity/eol/",
    "voidex": "http://www.swi-prolog.org/rdf/library/",
    "ioto": "http://www.irit.fr/recherches/MELODI/ontologies/IoT-O#",
    "ldqm": "http://linkeddata.es/resource/ldqm/",
    "ldq": "http://www.linkeddata.es/ontology/ldq#",
    "gobierno": "http://www.gobierno.es/gobierno/",
    "hva": "http://www.ebusiness-unibw.org/ontologies/hva/ontology#",
    "shacl": "http://www.w3.org/ns/shacl#",
    "odw": "http://odw.tw/",
    "eem": "http://purl.org/eem#",
    "undata": "http://citydata.wu.ac.at/Linked-UNData/data/",
    "rdax": "http://rdaregistry.info/Elements/x/",
    "sdmxcode": "http://purl.org/linked-data/sdmx/2009/code#",
    "provinsi": "http://provinsi.com/",
    "yso": "http://www.yso.fi/onto/yso/",
    "rm": "http://jazz.net/ns/rm#",
    "rdapf": "http://rdaregistry.info/termList/presFormat/",
    "vplan": "http://www.ifs.tuwien.ac.at/~miksa/ontologies/VPlan.owl#",
    "umls": "http://bioportal.bioontology.org/ontologies/umls/",
    "estatgph": "http://estatwrap.ontologycentral.com/id/nama_aux_gph#",
    "organ": "http://www.univalle.edu.co/ontologies/Organ#",
    "nature": "http://deductions.github.io/nature_observation.owl.ttl#",
    "dataid": "http://dataid.dbpedia.org/ns/core#",
    "agr": "http://promsns.org/def/agr#",
    "provoc": "http://ns.inria.fr/provoc/",
    "sdm": "http://standard.k-history.kr/resource/",
    "scco": "http://rdf.ebi.ac.uk/terms/surechembl#",
    "iso37120": "http://ontology.eil.utoronto.ca/ISO37120.owl#",
    "master1": "http://idl.u-grenoble3.fr/",
    "ctxdesc": "http://www.demcare.eu/ontologies/contextdescriptor.owl#",
    "cpov": "http://data.europa.eu/m8g/",
    "composer": "http://dbpedia.org/ontology/composer/",
    "vort": "http://rockets.topbraid.solutions/vort/",
    "ex": "http://example.org/",
    "wsdl": "http://www.w3.org/ns/wsdl-rdf#",
    "dsv": "http://purl.org/iso25964/DataSet/Versioning#",
    "elod": "http://linkedeconomy.org/ontology#",
    "maeco": "http://edg.topbraid.solutions/maeco/",
    "mexperf": "http://mex.aksw.org/mex-perf#",
    "srx": "http://www.w3.org/2005/sparql-results#",
    "wail": "http://www.eyrie.org/~zednenem/2002/wail/",
    "ecoll": "http://purl.org/ceu/eco/1.0#",
    "sto": "https://w3id.org/i40/sto#",
    "mesh": "http://id.nlm.nih.gov/mesh/",
    "dsfv": "http://sws.ifi.uio.no/vocab/dsf/henriwi/dsf#",
    "ispra": "http://dati.isprambiente.it/ontology/core#",
    "qbe": "http://citydata.wu.ac.at/qb-equations#",
    "bdc": "http://dbpedia.org/resource/Category:",
    "ifc": "http://ifcowl.openbimstandards.org/IFC2X3_Final#",
    "estrf": "http://vocab.linkeddata.es/datosabiertos/def/transporte/trafico#",
    "persee": "http://data.persee.fr/ontology/persee_ontology/",
    "rdb": "http://www.dbs.cs.uni-duesseldorf.de/RDF/relational#",
    "rdaill": "http://rdaregistry.info/termList/IllusContent/",
    "huto": "http://ns.inria.fr/huto/",
    "nobel": "http://data.nobelprize.org/terms/",
    "svcs": "http://rdfs.org/sioc/services#",
    "ndnp": "http://chroniclingamerica.loc.gov/terms#",
    "task": "http://deductions.github.io/task-management.owl.ttl#",
    "im": "http://imgpedia.dcc.uchile.cl/resource/",
    "tui": "http://data.ifs.tuwien.ac.at/study/resource/",
    "ppr": "http://purl.org/datanode/ppr/ns/",
    "bot": "https://w3id.org/bot#",
    "rdaspc": "http://rdaregistry.info/termList/specPlayback/",
    "psv": "http://www.wikidata.org/prop/statement/value/",
    "ontop": "https://w3id.org/ontop/",
    "km4c": "http://www.disit.org/km4city/schema#",
    "sfn": "http://semweb.datasciencelab.be/ns/sfn#",
    "dsw": "http://purl.org/dsw/",
    "aprov": "http://purl.org/a-proc#",
    "rdavf": "http://rdaregistry.info/termList/videoFormat/",
    "studiop": "http://purl.org/resource/pilatesstudio/",
    "edac": "http://ontology.cybershare.utep.edu/ELSEWeb/elseweb-edac.owl#",
    "sgiter": "http://w3id.org/sparql-generate/iter/",
    "opa": "https://w3id.org/laas-iot/adream#",
    "owms": "http://standaarden.overheid.nl/owms/terms/",
    "lcsh": "http://id.loc.gov/authorities/subjects/",
    "dqc": "http://semwebquality.org/ontologies/dq-constraints#",
    "pep": "https://w3id.org/pep/",
    "pato": "http://purl.obolibrary.org/obo/",
    "tx": "http://swtmp.gitlab.io/vocabulary/templates.owl#",
    "orgesv2": "http://datos.gob.es/sites/default/files/OntologiaDIR3/orges.owl#",
    "wab": "http://wab.uib.no/cost-a32_philospace/wittgenstein.owl#",
    "marcrole": "http://id.loc.gov/vocabulary/relators/",
    "emergelm": "http://purl.org/emergel/modules#",
    "ago": "http://awesemantic-geo.link/ontology/",
    "ogdl4m": "https://github.com/martynui/OGDL4M/",
    "orcid": "http://orcid.org/",
    "llalg": "http://www.linklion.org/algorithm/",
    "connard": "https://mail.google.com/mail/u/1/#",
    "oplbenefit": "http://www.openlinksw.com/ontology/benefits#",
    "memento": "http://mementoweb.org/ns#",
    "doacc": "http://purl.org/net/bel-epa/doacc#",
    "imo": "http://imgpedia.dcc.uchile.cl/ontology#",
    "tsn": "http://purl.org/net/tsn#",
    "gvoith": "http://assemblee-virtuelle.github.io/grands-voisins-v2/thesaurus.ttl#",
    "dgfr": "http://colin.maudry.com/ontologies/dgfr#",
    "dto": "http://www.datatourisme.fr/ontology/core/1.0#",
    "oplangel": "http://www.openlinksw.com/schemas/angel#",
    "gvoi": "http://assemblee-virtuelle.github.io/grands-voisins-v2/gv.owl.ttl#",
    "nkos": "http://w3id.org/nkos#",
    "pmc": "http://identifiers.org/pmc/",
    "ncbigene": "http://identifiers.org/ncbigene/",
    "ssno": "http://www.w3.org/ns/ssn/",
    "oplp": "http://www.openlinksw.com/ontology/purchases#",
    "emergel": "http://purl.org/emergel/core#",
    "rdaad": "http://rdaregistry.info/Elements/a/datatype/",
    "vehma": "http://deductions.github.io/vehicule-management.owl.ttl#",
    "allot": "https://w3id.org/akn/ontology/allot#",
    "rimmf": "http://rimmf.com/vocab/",
    "geoloc": "http://deductions.github.io/geoloc.owl.ttl#",
    "dk": "http://www.data-knowledge.org/dk/schema/rdf/latest/",
    "dpla": "http://dp.la/info/developers/map/",
    "vsearch": "http://vocab.sti2.at/vsearch#",
    "tosh": "http://topbraid.org/tosh#",
    "clapit": "http://dati.gov.it/onto/clapit/",
    "valueflows": "https://w3id.org/valueflows/",
    "geor": "http://www.opengis.net/def/rule/geosparql/",
    "hasco": "http://hadatac.org/ont/hasco/",
    "sciprov": "http://sweetontology.net/reprSciProvenance/",
    "frappe": "http://streamreasoning.org/ontologies/frappe#",
    "brt": "http://brt.basisregistraties.overheid.nl/def/top10nl#",
    "ceterms": "http://purl.org/ctdl/terms/",
    "ver": "https://w3id.org/version/ontology#",
    "input": "http://volt-name.space/vocab/input#",
    "ondc": "http://www.semanticweb.org/ontologies/2012/1/Ontology1329913965202.owl#",
    "fr": "https://w3id.org/fr/def/core#",
    "brk": "http://brk.basisregistraties.overheid.nl/def/brk#",
    "td": "http://www.w3.org/ns/td#",
    "it": "http://www.influencetracker.com/ontology#",
    "oplstocks": "http://www.openlinksw.com/ontology/stocks#",
    "meeting": "http://www.w3.org/2002/07/meeting#",
    "tg": "http://www.turnguard.com/turnguard#",
    "rdare": "http://rdaregistry.info/termList/RDARegionalEncoding/",
    "gdc": "https://portal.gdc.cancer.gov/cases/",
    "seeds": "http://deductions.github.io/seeds.owl.ttl#",
    "oplwebsrv": "http://www.openlinksw.com/ontology/webservices#",
    "eustd": "http://eurostat.linked-statistics.org/data#",
    "adr": "https://w3id.org/laas-iot/adream#",
    "ldl": "https://w3id.org/ldpdl/ns#",
    "dcatnl": "http://standaarden.overheid.nl/dcatnl/terms/",
    "ifcowl": "http://www.buildingsmart-tech.org/ifcOWL/IFC4_ADD2#",
    "amt": "http://academic-meta-tool.xyz/vocab#",
    "audit": "http://fedora.info/definitions/v4/audit#",
    "id": "http://identifiers.org/",
    "its": "http://www.w3.org/2005/11/its/rdf#",
    "tsnchange": "http://purl.org/net/tsnchange#",
    "ldn": "https://www.w3.org/TR/ldn/#",
    "assoc": "https://w3id.org/associations/vocab#",
    "pair": "http://virtual-assembly.org/pair/PAIR_LOD_V3.owl/",
    "ecowlim": "http://ecowlim.tfri.gov.tw/lode/resource/",
    "ns2": "http://ogp.me/ns#video:",
    "ims": "http://www.imsglobal.org/xsd/imsmd_v1p2/",
    "fssp": "http://linkeddata.fssprus.ru/resource/",
    "ido": "http://purl.obolibrary.org/obo/ido.owl#",
    "aseonto": "http://requirement.ase.ru/requirements_ontology#",
    "d3s": "http://vocbench.solidaridad.cloud/taxonomies#",
    "globalcube": "http://kalmar32.fzi.de/triples/global-cube.ttl#",
    "pand": "http://bag.basisregistraties.overheid.nl/bag/id/pand/",
    "w3cgeo": "http://www.w3.org/2003/01/geo/wgs84_pos#",
    "scra": "http://purl.org/net/schemarama#",
    "aml": "https://w3id.org/i40/aml#",
    "ja": "http://jena.hpl.hp.com/2005/11/Assembler#",
    "jpo": "http://rdf.jpostdb.org/ontology/jpost.owl#",
    "oplli": "http://www.openlinksw.com/schemas/linkedin#",
    "euvoc": "http://publications.europa.eu/ontology/euvoc#",
    "snac": "http://socialarchive.iath.virginia.edu/",
    "fun": "http://w3id.org/sparql-generate/fn/",
    "bdd": "https://api.bloomberg.com/eap/catalogs/bbg/fields/",
    "grel": "http://semweb.datasciencelab.be/ns/grel#",
    "cpi": "http://www.ebusiness-unibw.org/ontologies/cpi/ns#",
    "rankrage": "https://rankrage.de/",
    "theme": "http://voc.odw.tw/theme/",
    "bdr": "http://purl.bdrc.io/resource/",
    "property": "http://fr.dbpedia.org/property/",
    "lib": "http://purl.org/library/",
    "add": "http://www.datatourisme.fr/ontology/core/1.0#",
    "crml": "http://semweb.mmlab.be/ns/rml/condition#",
    "fnml": "http://semweb.mmlab.be/ns/fnml#",
    "halyard": "http://merck.github.io/Halyard/ns#",
    "iter": "http://w3id.org/sparql-generate/iter/",
    "vsso": "http://automotive.eurecom.fr/vsso#",
    "besluit": "http://data.vlaanderen.be/ns/besluit#",
    "s3n": "http://w3id.org/s3n/",
    "nih": "http://ncicb.nci.nih.gov/xml/owl/EVS/Thesaurus.owl#",
    "fhir": "http://hl7.org/fhir/",
    "osd": "http://a9.com/-/spec/opensearch/1.1/",
    "bci": "https://w3id.org/BCI-ontology#",
    "activity": "http://activitystrea.ms/specs/atom/1.0/",
    "fuseki": "http://jena.apache.org/fuseki#",
    "isaterms": "http://purl.org/isaterms/",
    "lmdb": "http://data.linkedmdb.org/movie/",
    "nrv": "http://ns.inria.fr/nrv#",
    "bob": "http://good.dad/meaning/bob#",
    "sfd": "http://semantic-forms.cc:9112/ldp/",
    "iab": "https://www.iab.com/guidelines/taxonomy/",
    "sylld": "http://www.semanticweb.org/syllabus/data/",
    "docker": "http://www.w3.org/ns/bde/docker/",
    "da": "https://www.wowman.org/index.php?id=1&type=get#",
    "bds": "http://www.bigdata.com/rdf/search#",
    "decprov": "http://promsns.org/def/decprov#",
    "pop": "http://wiki.dbpedia.org/",
    "iotlite": "http://purl.oclc.org/NET/UNIS/fiware/iot-lite#",
    "edg": "http://edg.topbraid.solutions/model/",
    "gg": "http://www.gemeentegeschiedenis.nl/gg-schema#",
    "cubeont": "http://ontology.cube.global/",
    "ottr": "http://ns.ottr.xyz/templates#",
    "s4bldg": "https://w3id.org/def/saref4bldg#",
    "datacron": "http://www.datacron-project.eu/datAcron#",
    "cwlgit": "https://w3id.org/cwl/view/git/",
    "uc": "http://ucuenca.edu.ec/ontology#",
    "bdo": "http://purl.bdrc.io/ontology/core/",
    "gdpr": "https://vocab.eccenca.com/gdpr/",
    "s4envi": "https://w3id.org/def/saref4envi#",
    "ddb": "http://www.deutsche-digitale-bibliothek.de/edm/",
    "bgt": "http://bgt.basisregistraties.overheid.nl/def/bgt#",
    "antenne": "https://id.milieuinfo.be/ns/zendantenne#",
    "imas": "https://sparql.crssnky.xyz/imasrdf/URIs/imas-schema.ttl#",
    "literature": "http://purl.org/net/cnyt-literature#",
    "linkrel": "https://www.w3.org/ns/iana/link-relations/relation#",
    "mdont": "http://ont.matchdeck.com/",
    "medred": "http://w3id.org/medred/medred#",
    "mmo": "http://purl.org/momayo/mmo/",
    "shui": "https://vocab.eccenca.com/shui/",
    "number": "http://km.aifb.kit.edu/projects/numbers/number#",
    "seo": "http://sda.tech/SEOontology/SEO/",
    "loupe": "http://ont-loupe.linkeddata.es/def/core/",
    "ontoneo": "http://purl.obolibrary.org/obo/ontoneo/",
    "vehman": "http://deductions.github.io/vehicule-management.owl.ttl#",
    "gt": "https://vocab.eccenca.com/geniustex/",
    "dossier": "https://lod.milieuinfo.be/ns/dossier#",
    "nosql": "http://purl.org/db/nosql#",
    "atlasterms": "http://rdf.ebi.ac.uk/terms/atlas/",
    "dcx": "http://dublincore.org/dcx/",
    "timex": "http://data.wu.ac.at/ns/timex#",
    "dead": "http://utpl.edu.ec/sbc/data/",
    "m3": "http://sensormeasurement.appspot.com/m3#",
    "mandaat": "http://data.vlaanderen.be/ns/mandaat#",
    "kmgeo": "http://km.aifb.kit.edu/services/geo/ontology#",
    "pcdmuse": "http://pcdm.org/use#",
    "smartapi": "http://smart-api.io/ontology/1.0/smartapi#",
    "mobivoc": "http://schema.mobivoc.org/",
    "dnbt": "http://d-nb.info/standards/elementset/dnb#",
    "foaffff": "http://gogl.com/",
    "geo7": "https://www.geo7.ch/",
    "led": "http://led.kmi.open.ac.uk/term/",
    "plg": "http://parliament.uk/ontologies/legislation/",
    "mydb": "http://mydb.org/",
    "tikag": "https://www.tikag.com/",
    "lido": "http://www.lido-schema.org/",
    "ecore": "http://www.eclipse.org/emf/2002/Ecore#",
    "cwlprov": "https://w3id.org/cwl/prov#",
    "faostat": "http://reference.eionet.europa.eu/faostat/schema/",
    "legal": "http://www.w3.org/ns/legal#",
    "mml": "http://www.w3.org/1998/Math/MathML/",
    "waarde": "https://lod.milieuinfo.be/ns/waarde#",
    "numbers": "http://km.aifb.kit.edu/projects/numbers/",
    "swo": "http://www.ebi.ac.uk/swo/",
    "az": "https://w3id.org/people/az/",
    "ids": "https://w3id.org/idsa/core/",
    "swrc2": "https://www.cs.vu.nl/~mcaklein/onto/swrc_ext/2005/05#",
    "frbroo": "http://iflastandards.info/ns/fr/frbr/frbroo/",
    "physics": "http://www.astro.umd.edu/~eshaya/astro-onto/owl/physics.owl#",
    "vss": "http://automotive.eurecom.fr/vsso#",
    "r3d": "http://www.re3data.org/schema/3-0#",
    "seokoeln": "http://rankrage.de/",
    "dao": "http://purl.org/dao#",
    "goaf": "http://goaf.fr/goaf#",
    "ldt": "https://www.w3.org/ns/ldt#",
    "ppn": "http://parliament.uk/ontologies/person-name/",
    "cocoon": "https://w3id.org/cocoon/v1.0#",
    "gbol": "http://gbol.life/0.1#",
    "mem": "http://mementoweb.org/ns#",
    "aksw": "http://aksw.org/",
    "bioentity": "http://bioentity.io/vocab/",
    "sirene": "https://sireneld.io/vocab/sirene#",
    "lmu": "https://w3id.org/laas-iot/lmu#",
    "agrovoc": "http://aims.fao.org/aos/agrovoc/",
    "logies": "https://data.vlaanderen.be/ns/logies#",
    "esdbpr": "http://es.dbpedia.org/resource/",
    "cdao": "http://purl.obolibrary.org/obo/",
    "fog": "https://w3id.org/fog#",
    "foam": "https://www.koerperfettwaage-test.de/",
    "cim": "http://iec.ch/TC57/2013/CIM-schema-cim16#",
    "lovc": "https://w3id.org/lovcube/ns/lovcube#",
    "prof": "http://www.w3.org/ns/dx/prof/",
    "omg": "https://w3id.org/omg#",
    "rvz": "http://rdfvizler.dyreriket.xyz/vocabulary/core#",
    "cbim": "http://www.coinsweb.nl/cbim-2.0.rdf#",
    "munc": "http://ns.inria.fr/munc#",
    "lmo": "http://linkedmultimedia.org/sparql-mm/ns/2.0.0/ontology#",
    "eepsa": "https://w3id.org/eepsa#",
    "ln": "https://w3id.org/ln#",
    "edr": "https://w3id.org/laas-iot/edr#",
    "yaco": "https://www.irit.fr/recherches/MELODI/ontologies/cinema#",
    "rls": "https://w3id.org/lovcube/ns/relovstats#",
    "fred": "http://www.ontologydesignpatterns.org/ont/fred/domain.owl#",
    "bl": "http://w3id.org/biolink/vocab/",
    "dot": "https://w3id.org/dot#",
    "iati": "http://purl.org/collections/iati/",
    "pfeepsa": "https://w3id.org/pfeepsa#",
    "afr": "http://purl.allotrope.org/ontologies/result#",
    "devuan": "https://devuan.net.br/",
    "hico": "http://purl.org/emmedi/hico/",
    "conference": "https://w3id.org/scholarlydata/ontology/conference-ontology.owl#",
    "mwapi": "https://www.mediawiki.org/ontology#API/",
    "afm": "http://purl.allotrope.org/ontologies/material/",
    "adf": "http://purl.allotrope.org/ontologies/datapackage#",
    "bpo": "https://w3id.org/bpo#",
    "brick": "http://brickschema.org/schema/1.0.3/",
    "sirext": "https://sireneld.io/vocab/sirext#",
    "beer": "http://beer.com/",
    "noise": "http://vocab.linkeddata.es/datosabiertos/def/medio-ambiente/contaminacion-acustica#",
    "odf": "http://docs.oasis-open.org/ns/office/1.2/meta/odf#",
    "tb": "https://w3id.org/timebank#",
    "ul": "http://underlay.mit.edu/ns/",
    "dbms": "http://www.openlinksw.com/ontology/dbms-app-ontology#",
    "occ": "http://w3id.org/occ#",
    "ksam": "http://kulturarvsdata.se/ksamsok#",
    "gdprov": "https://w3id.org/GDPRov#",
    "gdprtext": "https://w3id.org/GDPRtEXT#",
    "iospress": "http://ld.iospress.nl/rdf/ontology/",
    "ctrl": "https://w3id.org/ibp/CTRLont#",
    "lblodlg": "http://data.lblod.info/vocabularies/leidinggevenden/",
    "vir": "http://w3id.org/vir#",
    "chear": "http://hadatac.org/ont/chear#",
    "cska": "http://pfclitex.com/",
    "ca": "http://complyadvantage.com/",
    "crminf": "http://www.cidoc-crm.org/cidoc-crm/CRMinf/",
    "oop": "http://w3id.org/oop#"
}
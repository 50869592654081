import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Grid, RadioGroup, TextField as OtherTextField, Typography} from "@material-ui/core";
import {getChipWithDelete, getTypesOptions} from "../apiplayground/SearchFilter";
import {getOntologyClasses, isEmptyArray, sort, toArray, typesOptionsForLanguage} from "../../components/util";
import {
    getUiLabelTranslation,
    UI_LABELS_ADD_SHEETS_DIALOG_HELP_VALUE,
    UI_LABELS_APPLY,
    UI_LABELS_CANCEL,
    UI_LABELS_SHEETS,
    UI_LABELS_SHEETS_OPEN_FOR_TYPE,
    UI_LABELS_SHEETS_OPEN_SAVED
} from "./UILabel";
import {getSavedSpreadsheetsOptions} from "./DataGridView";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import SavedSpreadsheet from "./SavedSpreadsheet";

const styles = {
    dialogPaper: {
    },
};

class AddNewTabDialog extends Component {
    constructor(props) {
        super(props);
        let {aliasesMap, aliasesToIRIMap, configurations, selectedTypes, browseLanguage} = props;

        let types = getTypesOptions(aliasesMap, configurations, false);
        types = typesOptionsForLanguage(types, aliasesToIRIMap, getOntologyClasses(configurations), browseLanguage);

        let options = sort(types, 'label');

        this.state = {
            selectedTypes : toArray(selectedTypes).map(t => options.find(o => o.value === t)),
            typeOptions : options,
            savedSpreadsheets : []
        }
    }
    componentDidMount() {
        let {workspace} = this.props;
        getSavedSpreadsheetsOptions(workspace).then(saved => {
            this.setState({
                savedSpreadsheets : saved,
                spreadsheetFrom : (toArray(saved).length > 0 ? UI_LABELS_SHEETS_OPEN_SAVED : UI_LABELS_SHEETS_OPEN_FOR_TYPE)
            });
        });

    }

    getTypeValue = () => {
        let {classes, theme} = this.props;
        let {selectedTypes, typeOptions} = this.state;
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.largeWidthPropertyLabelRoot
                }
            }
            : {};


        return <Autocomplete
            disableCloseOnSelect={true}
            datatest={'autocompleteType'}
            id="typeValueSelect"
            options={typeOptions}
            value={selectedTypes}
            getOptionSelected={(option, value) => {
                return option.value === value.value;
            }}
            getOptionLabel={option => option.label ?  option.label : ''}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <div datatest={option.label} style={{paddingLeft : '8px'}}>
                        {option.count}{ ' '} {option.label}
                        {
                            <Typography variant={'caption'}>
                                <div>{option.tooltip}</div>
                            </Typography>
                        }
                    </div>
                </React.Fragment>
            )}
            multiple={true}
            onChange={(event, val) => {
                this.setState({selectedTypes : toArray(val)})
            }}
            renderInput={params => (
                <OtherTextField
                    label={'Type'}
                    {...params}
                    margin={"dense"}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={inputLabelProps}
                />
            )}
            renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                    let title = <div>
                        <div style={{marginBottom : '8px'}}>{option.value}</div>
                        <div>{option.tooltip}</div>
                    </div>;
                    let tagProps = getTagProps({index});
                    return getChipWithDelete(theme, index, option.label, title, tagProps);
                })
            }}
            size={"small"}
        />;
    }

    isOpenSaved = () => {
        let {spreadsheetFrom} = this.state;
        return spreadsheetFrom === UI_LABELS_SHEETS_OPEN_SAVED;
    }


    render() {
        let {onAdd, onClose, classes, settings, browseLanguage, workspace, onOpenSpreadsheet} = this.props;
        let {selectedTypes, spreadsheetFrom, savedSpreadsheets, showSavedSpreadsheet} = this.state;
        let newValues = selectedTypes.map(st => st.value);

        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={true}
                    datatest={'pageTitleDialog'}
                    classes={{ paper: classes.dialogPaper }}
                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <H2Title title={getUiLabelTranslation(settings, UI_LABELS_SHEETS, browseLanguage, UI_LABELS_SHEETS) }/>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12}>
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        datatest="openTabDialog"
                                        style={{marginLeft : '8px'}}
                                        value={spreadsheetFrom || UI_LABELS_SHEETS_OPEN_FOR_TYPE}
                                        onChange={(event, value) => {
                                            this.setState({spreadsheetFrom: value});
                                        }}
                                        row={true}
                                    >
                                        <FormControlLabel
                                            value={UI_LABELS_SHEETS_OPEN_FOR_TYPE}
                                            control={<Radio/>}
                                            label={getUiLabelTranslation(settings, UI_LABELS_SHEETS_OPEN_FOR_TYPE, browseLanguage, UI_LABELS_SHEETS_OPEN_FOR_TYPE)}
                                        />
                                        <FormControlLabel
                                            disabled={isEmptyArray(toArray(savedSpreadsheets))}
                                            value={UI_LABELS_SHEETS_OPEN_SAVED}
                                            control={<Radio/>}
                                            label={getUiLabelTranslation(settings, UI_LABELS_SHEETS_OPEN_SAVED, browseLanguage, UI_LABELS_SHEETS_OPEN_SAVED)}
                                        />
                                    </RadioGroup>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12}>
                                {
                                    this.isOpenSaved()
                                        ? <div>
                                            <SavedSpreadsheet
                                                label={'Saved'}
                                                workspace={workspace}
                                                options={savedSpreadsheets || []}
                                                value={showSavedSpreadsheet}
                                                onChange={(event, newValue, reason) => {
                                                    this.setState({showSavedSpreadsheet: newValue});
                                                }}
                                            />
                                        </div>
                                        : <div>
                                            <Typography
                                                style={{margin: '8px 0px'}}>{getUiLabelTranslation(settings, UI_LABELS_ADD_SHEETS_DIALOG_HELP_VALUE, browseLanguage, UI_LABELS_ADD_SHEETS_DIALOG_HELP_VALUE)}</Typography>
                                            {this.getTypeValue()}
                                        </div>
                                }
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow :'1'}}></div>
                        <Button
                            disabled={this.isOpenSaved() ? showSavedSpreadsheet?.value === undefined : isEmptyArray(newValues)}
                            datatest={'applyButton'}
                            variant={"contained"}
                            color="secondary"
                            onClick={() => {
                                this.isOpenSaved() ? onOpenSpreadsheet(showSavedSpreadsheet) : onAdd(newValues)
                            }}
                        >{this.isOpenSaved()
                            ? getUiLabelTranslation(settings, UI_LABELS_SHEETS_OPEN_SAVED, browseLanguage, UI_LABELS_SHEETS_OPEN_SAVED)
                            : getUiLabelTranslation(settings, UI_LABELS_APPLY, browseLanguage, UI_LABELS_APPLY)}</Button>
                    </DialogActions>
                </Dialog>
            }
        </>;
    }

}

AddNewTabDialog.propTypes = {
    workspace: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.any,
    selectedTypes: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onAdd: PropTypes.any,
    onOpenSpreadsheet : PropTypes.func
};

export default withStyles(styles, {withTheme: true})(AddNewTabDialog);


import {createMuiTheme, fade,} from '@material-ui/core/styles';
import {
    FONT_1,
    FONT_2,
    LINK_COLOR,
    PRIMARY_COLOR,
    RED_COLOR,
    SECONDARY_COLOR,
    SECONDARY_COLOR_DARK,
    SUCCESS_COLOR,
    SUCCESS_COLOR_BUTTON
} from "./Constants";

export const THEME_OPTIONS = {
    typography: {
        useNextVariants: true,
        h1 : {
            fontFamily : FONT_1,
            letterSpacing:'0',
            fontSize:'34px'
        },
        h2 : {
            fontFamily : FONT_1,
            letterSpacing:'0',
            fontSize:'24px'
        },
        h3 : {
            fontFamily : FONT_2,
            letterSpacing:'0',
            fontSize:'16px',
            fontWeight: '500'
        },
        h4 : {
            fontFamily : FONT_2,
            letterSpacing:'0',
            fontSize:'14px',
            fontWeight: '500'
        },
        caption : {
            fontFamily : FONT_2,
            letterSpacing: '30',
            fontSize:'12px',
            fontWeight: '400'
        }
    },
    palette: {
        action : {
            hoverOpacity : '.1'
        },
        primary: {
            main: PRIMARY_COLOR
        },
        secondary: {
            main: SECONDARY_COLOR,
            dark : SECONDARY_COLOR_DARK,
            darkFade : fade(SECONDARY_COLOR_DARK, .1),
            selected: fade(SECONDARY_COLOR, .1)
        },
        warning: {
            main : SECONDARY_COLOR,
            light : SECONDARY_COLOR
        },
        error: {
            main : SECONDARY_COLOR
        },
        redError: {
            main : RED_COLOR
        },
        success: {
            main : SUCCESS_COLOR,
            dark : SUCCESS_COLOR_BUTTON
        },
        grey: {
            background: '#F5F5F5',
            //background: '#f6f8fc',
            level2: '#9E9E9E',
            level61: '#616161',
            level21: '#212121',
            levelE0: '#E0E0E0',
            level3: '#5e5e5e',
            level4: '#5E5E5E',
            main: '#EEEEEE'
            //main: '#eaf1fb'
        },
        white: {
            main: '#FFFFFF'
        },
        border: {
            //main: '#eaf1fb'
            main: '#EEEEEE'
        },
        link : {
            main : LINK_COLOR
        }
    },
    overrides: {
        MuiTypography: {
            body1: {
                fontSize:'14px',
                fontWeight: '400'
            }
        },
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: SUCCESS_COLOR,
                },
                '&$active': {
                    color: SECONDARY_COLOR,
                },
            },
            active: {},
            completed: {},
        },
        MuiStepLabel : {
            label: {
                '&$active': {
                    color: SECONDARY_COLOR
                },
                '&$completed' : {
                    color: SUCCESS_COLOR
                }
            }
        },
        MuiInputBase : {
            root: {
                backgroundColor: 'white',
                borderRadius: '4px',
            },
            input: {
                backgroundColor: 'white',
                borderRadius: '4px',
                fontSize: 14,
                fontFamily: ['Roboto'].join(','),
                letterSpacing:'0',
                color: '#212121',
                fontWeight: '400'
            }
        },
        MuiInputLabel : {
            shrink : {
                color: PRIMARY_COLOR,
            }
        },
        MuiOutlinedInput : {
            adornedEnd : {
                paddingRight : '4px'
            }
        },
        MuiListItemText : {
            primary : {
                fontFamily : FONT_2,
                letterSpacing:'0',
                fontSize:'16px',
                fontWeight: '500'
            }
        },
        MuiIconButton : {
            root : {
                borderRadius: '4px'
            }
        },
        MuiTab : {
            textColorSecondary : {
                color : PRIMARY_COLOR
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: SECONDARY_COLOR,
                padding: 4,
                boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
                fontSize:'12px',

            },
            arrow: {
                color: SECONDARY_COLOR,
            }
        },
        MuiDialogActions : {
            root : {
                padding: '8px 24px 16px 24px'
            }
        },
        MuiExpansionPanelSummary : {
            content : {
                margin : '0px',
                "$expanded > &": {
                    margin : '0px',
                }
            }
        },
        MuiAccordionSummary : {
            content : {
                margin : '0px',
                "$expanded > &": {
                    margin : '0px',
                }
            }
        },
        MuiAutocomplete : {
            paper : {
                boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)'
            }
        }

    },
    props: {
        MuiTooltip: {
            arrow: true,
        },
        MuiChip : {
            color : 'primary'
        },
        MuiTextField :{
            variant: "outlined",
            margin: "dense"
        },
    },
};

export const theme = createMuiTheme(THEME_OPTIONS)

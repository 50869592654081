import DataLoader from 'dataloader';
import {graphSearchByMaps} from "./graph-api";
import {ID, PAGE_SIZE, QUERY} from "../Constants";
import {getConnectedResourcesObject, getSearchResult, toArray} from "../components/util";

async function batchFunction(resourceIds) {
    let paramMap = {
        [QUERY] : `{${ID}(eq:["${resourceIds.filter(f => f).join('", "')}"])}`,
        [PAGE_SIZE] : resourceIds.length
    }
    try {

        const response = await graphSearchByMaps(paramMap);
        if (response.status !== 200) {
            if(response.status == 400) {
                return resourceIds.map(r => null);
            }
            console.log(response);
            throw new Error(response.status);
        }

        let json = await response.json();
        let searchResult = getSearchResult(json);
        let resourceIdMap = {};
        searchResult.forEach(r => {
            resourceIdMap[r[ID]] = r
        })
        return resourceIds.map(resourceId => resourceIdMap[resourceId] || null);
    } catch (e) {
        throw new Error(e);
    }
}

const dataLoader = new DataLoader(batchFunction, {
    maxBatchSize : 15
});

export async function loadResource(resourceId) {
    let resourceArray = toArray(resourceId);
    let map = resourceArray.map(async r => {
        const result = await dataLoader.load(r);
        if (result === null) {
            dataLoader.clear(r);
        }
        return result;
    });
    return Promise.all(map);
}

export async function primeResource(resourceId, value) {
    dataLoader.clear(resourceId).prime(resourceId, value);
}

export async function clear(resourceId, value) {
    dataLoader.clear(resourceId);
}

export function primeFromSearchResult(searchResponse) {
    getSearchResult(searchResponse).forEach(r => {
        let resourceId = r[ID];
        dataLoader.clear(resourceId).prime(resourceId, r);
    })
    let connectedResourcesObject = getConnectedResourcesObject(searchResponse);
    let keys = Object.keys(connectedResourcesObject);
    keys.forEach(resourceId => {
        let r = connectedResourcesObject[resourceId];
        r[ID] = resourceId
        dataLoader.clear(resourceId).prime(resourceId, r);
    })
}

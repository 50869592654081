import React from 'react';
import PropTypes from "prop-types";
import {Typography, withStyles} from "@material-ui/core";
import {FONT_2} from "../Constants";

const styles = theme => ({
});

let H2TitleFont2 = props => {
  const { title, style, color, ...rest } = props;

  return (<Typography {...rest} variant="h2" color={color ? color : 'primary'} style={{fontWeight: '500',fontFamily: FONT_2, fontSize: '20px', ...style}}>
                  {title}
              </Typography>);
}

H2TitleFont2.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  style: PropTypes.object
};

export default  withStyles(styles)(H2TitleFont2);

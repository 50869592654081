import React from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import H2Title from "../../components/H2Title";
import {styles} from "../../components/styles";
import {
    centerVertically, GetEditableClassNames,
    getLocalName,
    getOntologyProperties,
    getPropertyName,
    getResourceId,
    sort,
    toArray
} from "../../components/util";
import Tree from "./Tree";
import {fade} from "@material-ui/core/styles/colorManipulator";
import cloneDeep from "lodash/cloneDeep";
import {Avatar, Badge, Grid, TextField as OtherTextField, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getChipWithDelete, ID_PROPERTY, TYPE_PROPERTY} from "../apiplayground/SearchFilter";
import {ID} from "../../Constants";
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import JSONEditorReact from "../../components/JSONEditorReact";
import FilterListIcon from '@material-ui/icons/FilterList';
import {renderTreeActions} from "./ListResultItem";
import {BrokenImageOutlined, DeleteOutlined, FileCopyOutlined} from "@material-ui/icons";
import {withPermissions} from "../../service/permission-service";
import {deleteResource} from "./WithObjectSummary";
import {getUiLabelTranslation, UI_LABELS_DELETE_RESOURCE} from "./UILabel";
import history from "../../history";
import {
    getSiteHomePath,
    SETTINGS_EDIT_CARD_IMAGE,
    SETTINGS_EDIT_CARD_TITLE,
    SETTINGS_EDIT_RESOURCE_VIEW
} from "./Workspace";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {theme} from "../../theme";
import {getValuesObject} from "./SearchResultItem";
import FeedbackPanel from "./FeedbackPanel";
import {WithAdminEdit} from "./WithAdminEdit";

export function MultiValueSelect({options, value, onChange, label, classes, style, disableClearable = true}) {
    let inputLabelProps = classes
        ? {
            classes : {
                shrink: classes.smallWidthLabelShrink,
                root : classes.smallWidthPropertyLabelRoot
            }
        }
        : {};

    return <Autocomplete
        style={style}
        datatest={'autocompleteMultiValueSelect'}
        id="textSearchPropertiesValueSelect"
        value={value || []}
        options={options}
        getOptionLabel={option => option.label ?  option.label : ''}
        getOptionSelected={(option, value) => {
            return option.value === value.value;
        }}
        multiple={true}
        onChange={onChange}
        renderInput={params => (
            <OtherTextField
                label={label}
                {...params}
                margin={"dense"}
                variant="outlined"
                fullWidth
                InputLabelProps={inputLabelProps}
            />
        )}
        renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
                return getChipWithDelete(theme, index, option.label, option.tooltip, getTagProps({index}));
            })
        }}
        renderOption={(option, { selected }) => (
            <div>
                <div datatest={option.label} style={{paddingLeft : '16px'}}>{option.label}</div>
                <div style={{paddingLeft : '16px'}}>
                    <Typography variant={'caption'}>{option.value}</Typography>
                </div>
            </div>
        )}
        size={"small"}
        disableClearable={disableClearable}
    />;
}

class FilterProperties extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPropertySelect : false
        }
    }

    renderPropertyDialog = () => {
        let {showPropertySelect} = this.state;
        let {onChange, viewProperties, configurations, aliasesToIRIMap, ontology, browseLanguage} = this.props;
        let allProperties = getOntologyProperties(configurations).map(p => {
            let propertyName = getPropertyName(aliasesToIRIMap, p[ID], ontology, browseLanguage);
            return {
                value : p[ID],
                label : propertyName,
                tooltip : getResourceId(p)
            }
        });
        allProperties.push(ID_PROPERTY);
        allProperties.push(TYPE_PROPERTY);
        allProperties = sort(allProperties, 'label').filter(p => viewProperties.find(vp => vp.value === p.value) === undefined);
        return showPropertySelect && <Dialog
            aria-labelledby="form-dialog-title"
            open={showPropertySelect}
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={'Select View Properties'}/></DialogTitle>
            <DialogContent>
                        <MultiValueSelect
                            options={allProperties}
                            value={viewProperties}
                            label={'Properties'}
                            onChange={(event, val) => {
                                viewProperties.length = 0;
                                toArray(val).forEach(v => viewProperties.push(v));
                                this.setState({})
                            }}
                            disableClearable={false}
                        />
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={() => {
                        this.setState({showPropertySelect: false});
                        onChange();
                    }}
                >Close</Button>
            </DialogActions>
        </Dialog>;
    }

    render() {
        let {viewProperties} = this.props;
        return <>
            <Tooltip title={'Configure view properties'}>
                <IconButton onClick={() => this.setState({showPropertySelect: true})} size={'small'}>
                    {
                        viewProperties.length === 0
                            ? <FilterListIcon/>
                            : <Badge badgeContent={viewProperties.length} color="secondary">
                                <FilterListIcon/>
                            </Badge>
                    }
                </IconButton>
            </Tooltip>
            {this.renderPropertyDialog()}
        </>;
    }
}

FilterProperties.propTypes = {
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    viewProperties: PropTypes.any,
    onChange: PropTypes.any,
};

export const FilterPropertiesComp = FilterProperties;

class DeveloperView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    renderDeveloperModeDialog = () => {
        let {open} = this.state;
        let {resource} = this.props;
        return open && <Dialog
            aria-labelledby="Data View"
            open={open}
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={'Data View'}/></DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <JSONEditorReact
                            modes={['code']}
                            enableSort={false}
                            text={JSON.stringify(resource, null, 4)}
                            indentation={4}
                            history={true}
                            height={'100%'}
                            expandAll={true}
                            search={false}
                            onEditable={() => {
                                return false
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    variant={"contained"}
                    color="secondary"
                    onClick={() => this.setState({open: false})}
                >Close</Button>
            </DialogActions>
        </Dialog>;
    }

    render() {
        return <>
            <Tooltip title={'View data'}>
                <IconButton onClick={() => this.setState({open: true})} size={'small'}>
                    <DeveloperModeIcon/>
                </IconButton>
            </Tooltip>
            {this.renderDeveloperModeDialog()}
        </>;
    }
}

DeveloperView.propTypes = {
    resource: PropTypes.object,
};

export const DeveloperViewComp = DeveloperView;

export function getHeader(title, resource, thumbnail, containerStyle = {}, actionRenderer, startComponent, titleRenderer, hideIdInTitle = false) {
    let maxWidthAdjustment = 108;
    if(startComponent) {
        maxWidthAdjustment = maxWidthAdjustment + 48;
    }
    if(actionRenderer) {
        maxWidthAdjustment = maxWidthAdjustment + 56;
    }
    let maxWidth = `calc( 100% - ${maxWidthAdjustment}px)`;
    let titleToRender = title || getLocalName(resource[ID]);
    return <div
        datatest={'headerPanel'}
        id={"additional-actions1-header" + title}
        style={{
            minHeight: 'unset',
            padding: '16px',
            margin: '0px',
            cursor: 'unset',
            display: 'flex',
            ...containerStyle
        }}
    >
        {startComponent && centerVertically(startComponent())}
        <div datatest={'accordianImage'} className={GetEditableClassNames(SETTINGS_EDIT_CARD_IMAGE, getResourceId(resource))} style={{width: '100px'}}>
            <WithAdminEdit
                style={{marginTop :'0px'}}
                data={{
                    action: SETTINGS_EDIT_CARD_IMAGE,
                    className : 'editableComponentHighlightOffsetIn3',
                    id : getResourceId(resource),
                    resource
                }}
            />
            {
                thumbnail
                    ? <Avatar datatest={'avatar'} style={{width: '80px', height: '80px'}} title={title} src={thumbnail}></Avatar>
                    : <Avatar datatest={'avatarBroken'} style={{width: '80px', height: '80px'}}><BrokenImageOutlined/></Avatar>
            }
        </div>
        {
            centerVertically(
            <div datatest={'accordianTitle'}>

                {titleRenderer ? titleRenderer(titleToRender) : <H2Title
                    className={GetEditableClassNames(SETTINGS_EDIT_CARD_TITLE, getResourceId(resource))}
                >
                    <WithAdminEdit
                        style={{marginTop :'-8px', marginLeft : '0px'}}
                        data={{
                            action: SETTINGS_EDIT_CARD_TITLE,
                            id : getResourceId(resource),
                            resource
                        }}
                    />
                    {titleToRender}
                </H2Title>}
                {hideIdInTitle ||
                    <Typography
                        style={{marginTop: '8px'}}
                        noWrap={true}
                        variant="body2"
                        component="div"
                    >
                        {
                            <Tooltip title={'Copy to clipboard'}>
                                <IconButton size={'small'} onClick={() => {navigator.clipboard.writeText(resource?.[ID] || '')}}>
                                    <FileCopyOutlined fontSize={'small'}/>
                                </IconButton>
                            </Tooltip>
                        }
                        {resource?.[ID] || ' '}
                    </Typography>}
            </div>, {flexGrow: '1',   maxWidth: maxWidth})
        }
        <div style={{display : "flex", flexGrow :  1}}>
            <div style={{flexGrow : '1'}}></div>
        {actionRenderer && centerVertically(actionRenderer())}
        </div>
    </div>;
}

class ResultAccordion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        let {resource, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;

        getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology).then(vo => {
            this.setState({rvo : vo});
        })
    }

    headerActionsProvider = () => {
        let { resource, location, settings, browseLanguage} = this.props;
        let {classes, permissionService, publishEvent} = this.props;
        let {loading} = this.state;
        let canDelete = resource && permissionService.canDeleteResource(resource);

        return <>
            {
                canDelete && <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_DELETE_RESOURCE, browseLanguage, UI_LABELS_DELETE_RESOURCE)}>
                    <IconButton
                        size={'small'}
                        onClick={async () => {
                            this.setState({loading : true});
                            let error = await deleteResource.call(this, resource[ID], publishEvent, resource, settings, browseLanguage);
                            if(!error) {
                                return history.push(`${getSiteHomePath(location)}`);
                            }
                            this.setState({loading : false})
                        }}
                    >{loading && <CircularProgress size={24} className={classes.buttonProgress} />}<DeleteOutlined/></IconButton>
                </Tooltip>
            }

        </>;
    }


    render() {
        let {theme, configurations, ontology, title, thumbnail, resource, location, aliasesMap, aliasesToIRIMap, settings, browseLanguage} = this.props;
        let {classes, viewProperties, graphViewProvider, onConceptClick, publishEvent} = this.props;
        let {updateFailed, rvo} = this.state;
        return <div datatest={'accordianContainer-'+title} style={{display : 'flex'}}>

        <Accordion
            style={{
                margin: '0px',
                borderRadius: '4px',
                flexGrow : '1',
                maxWidth :  '100%',
                overflow :  undefined
            }}
            expanded={true}
        >
            {
                updateFailed &&
                <AlertSnackbarContent
                    onClose={() => this.setState({updateFailed : undefined})}
                    variant={'error'}
                    open={true}
                    autoHide={true}
                    message={updateFailed}
                />
            }
            {
                getHeader(
                    title,
                    resource,
                    thumbnail,
                    undefined,
                    this.headerActionsProvider
                )
            }
            <AccordionDetails datatest={'middleContentAccordian'} style={{
                padding: '0px 0px 16px 0px',
                borderTop: '1px solid',
                borderTopColor: fade(theme.palette.secondary.main, 0.5)
            }}>
                {renderTreeActions.call(this, viewProperties, configurations, browseLanguage, aliasesToIRIMap, ontology, resource, graphViewProvider)}

                <div className={GetEditableClassNames(SETTINGS_EDIT_RESOURCE_VIEW)} style={{flexGrow : '1'}}>
                    <WithAdminEdit
                        style={{marginTop :'0px'}}
                        data={{
                            action: SETTINGS_EDIT_RESOURCE_VIEW,
                            resource : resource
                        }}
                    />
                    <Tree
                        viewProperties={viewProperties}
                        resource={cloneDeep(resource)}
                        location={location}
                        aliasesToIRIMap={aliasesToIRIMap}
                        settings={settings}
                        configurations={configurations}
                        ontology={ontology}
                        aliasesMap={aliasesMap}
                        browseLanguage={browseLanguage}
                        onConceptClick={onConceptClick}
                    />
                </div>
            </AccordionDetails>
        </Accordion>
            {
                <FeedbackPanel
                    resource={resource}
                    settings={settings}
                    ontology={ontology}
                    aliasesMap={aliasesMap}
                    browseLanguage={browseLanguage}
                    configurations={configurations}
                    location={location}
                    aliasesToIRIMap={aliasesToIRIMap}
                    resourceValueObject={rvo}
                    onOpen={() => this.setState({openComments: true})}
                    onClose={() => this.setState({openComments: false})}
                />
            }
        </div>;

    }

}

ResultAccordion.propTypes = {
    permissionService : PropTypes.any,
    location: PropTypes.object,
    actionAreaFooter: PropTypes.func,
    otherProperties: PropTypes.array,
    onSaveSettings: PropTypes.func,
    title: PropTypes.string,
    description: PropTypes.string,
    thumbnail: PropTypes.string,
    summary: PropTypes.object,
    resource: PropTypes.object,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    settings: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    viewProperties: PropTypes.any,
    graphViewProvider: PropTypes.func,
    onConceptClick: PropTypes.func

};

export default withStyles(styles, {withTheme: true})(withPermissions(ResultAccordion));

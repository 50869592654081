import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {styles} from "../components/styles";
import {STYLE_MAIN_SPACING} from "../Constants";

class Footer extends React.Component {

    render() {
        const {theme} = this.props;
        let padding = theme.spacing(STYLE_MAIN_SPACING)
        return (
            <div className={"footer"} style={{ borderTop: '1px solid '+theme.palette.border.main, backgroundColor: theme.palette.white.main, height: padding, paddingLeft: padding, paddingRight: padding}}>{
                <div style={{display: 'flex', marginTop: (padding / 8) + 6}}>{
                    this.props.children
                }</div>
            }</div>);
    }
}

Footer.propTypes = {
    component: PropTypes.array
};

export default withStyles(styles, {withTheme: true})(Footer);

import React from 'react';
import PropTypes from "prop-types";
import {Button, Grid, TextField, withStyles} from "@material-ui/core";
import RandExp from "randexp";
import LockIcon from "@material-ui/icons/Lock";
import {ALIAS_SH_MAX_LENGTH} from "../Constants";
import {isString} from "lodash";
import {
    computeMaxLength,
    getRegExValue,
    isDatatypeLiteral,
    isLangLiteral,
    parseDatatypeLiteral,
    parseLangStringLiteral
} from "./util";
import {styles} from "./styles";





class RegExField extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render () {
    let {readOnly, onChange, regexPattern, theme, property, classes} = this.props
    let {regexPatternTestValue, regexPatternError} = this.state
      let inputLabelProps = classes
          ? {
              classes : {
                  shrink: classes.smallWidthLabelShrink,
                  root : classes.largeWidthPropertyLabelRoot
              }
          }
          : {};
      return <><Grid item xs={12}>
        <TextField
            fullWidth={true}
            variant={'outlined'}
            readOnly={readOnly}
            label={'Regex Pattern for Random Values'}
            name={'regexPattern'}
            value={regexPattern}
            onChange={onChange}
            error={regexPatternError}
            helperText={regexPatternError}
            InputLabelProps={inputLabelProps}
            InputProps={{
              startAdornment: <span>/</span>,
              endAdornment: <><span>/</span><Button
                  size={'small'}
                  variant={"outlined"}
                  disabled={regexPattern ? false : true}
                  onClick={() => {
                    let testVal = getRegExValue(regexPattern, property)
                    if (testVal !== null) {
                      this.setState({
                        regexPatternTestValue: testVal,
                        regexPatternError: undefined
                      })
                    } else {
                      this.setState({regexPatternError: 'Invalid value'})
                    }
                  }}
              >Test</Button>
                {readOnly? <LockIcon style={{color: theme.palette.grey.level2}} fontSize={"small"}/> : <></> }
                </>
            }}
        />
    </Grid>
    <Grid item xs={12}>
        <TextField
            placeholder={'Test result'}
            value={regexPatternTestValue}
            readOnly={true}
            fullWidth={true}
            multiline={true}
            rowsMax="4"
            rows="4"
        />
    </Grid></>;
  }
}

RegExField.propTypes = {
    property: PropTypes.object,
    regexPattern: PropTypes.string,
    onChange: PropTypes.func,
    onTestError: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default withStyles(styles, {withTheme:true})(RegExField);

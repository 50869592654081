import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {traceRenderStart} from "../../components/Trace";
import {Grid} from "@material-ui/core";
import TextField from "../../components/TextField";
import {
    ALIAS_SYS_BASE_IRI,
    ALIAS_SYS_BASE_IRI_PREFIX,
    ALIAS_SYS_DESCRIPTION,
    ALIAS_SYS_ID_LABEL,
    LABEL_BASE_IRI,
    VALIDATION_BASE_IRI_MAX_LENGTH,
    VALIDATION_DESCRIPTION_LENGTH,
    VALIDATION_LANGUAGE_NAME_LENGTH,
    VALIDATION_PREFIX_MAX_LENGTH
} from "../../Constants";
import {
    getMaxLengthMessage,
    isValidMaxLengthWithoutTrim,
    restrictMaximumCharacters,
    validateBaseIRI,
    validatePrefix,
    validateRequiredAndMaxLength
} from "../../components/util";
import H2Title from "../../components/H2Title";

const styles = {
    dialogPaper: {
        minWidth: '600px',
        maxWidth: '600px',
        minHeight: '78vh'
    },
};

const COMPONENT = 'AddModelDialog'
class AddModelDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: props.name || '',
            description: props.description || ''
        }
    }

    shouldCreateBeDisabled = () => {
        let {name, nameError, baseIRI, baseIRIError, prefix, prefixError, descriptionError} = this.state
        if(!name || nameError || !baseIRI || baseIRIError || !prefix || prefixError || descriptionError) {
            return true;
        }
        return false;
    }

    handleFieldChange = (event) => {
        const {target: {name, value}} = event;
        this.setState({
            [name]: value
        });
    };

    onFieldChange = (label, errorKey, length, validator) => (event) => {
        this.handleFieldChange(restrictMaximumCharacters(event, length))
        const {target: {value}} = event;
        let error = validator ? validator(value, label, length) : '';
        if(error) {
            this.setState({[errorKey]: error});
        } else {
            this.setState({[errorKey]: ''})
        }
    }

    render() {
        traceRenderStart('', COMPONENT)

        let {classes, addButtonTitle, handleCancel, handleOk, title, open, helpText} = this.props
        let {name, nameError, baseIRI, baseIRIError, prefix, prefixError, description, descriptionError} = this.state
        return  <Dialog
            id={'addNewSchemaDialog'}
            aria-labelledby="form-dialog-title"
            open={open}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="form-dialog-title"><H2Title title={title}/></DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    {helpText && <Grid item xs={12}>{helpText}</Grid>}
                    <Grid item xs={12}>
                        <TextField
                            autoFocus={true}
                            label={'Name'}
                            id='name'
                            name='name'
                            value={name}
                            error={nameError}
                            onChange={this.onFieldChange('Name', 'nameError', VALIDATION_LANGUAGE_NAME_LENGTH, validateRequiredAndMaxLength)}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={'Namespace Prefix'}
                            id='prefix'
                            name='prefix'
                            value={prefix}
                            error={prefixError}
                            onChange={this.onFieldChange('Prefix', 'prefixError', VALIDATION_PREFIX_MAX_LENGTH, validatePrefix)}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label={LABEL_BASE_IRI}
                            id='baseIRI'
                            name='baseIRI'
                            value={baseIRI}
                            error={baseIRIError}
                            onChange={this.onFieldChange(LABEL_BASE_IRI, 'baseIRIError', VALIDATION_BASE_IRI_MAX_LENGTH, validateBaseIRI)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={'Description'}
                            id='description'
                            name='description'
                            value={description}
                            error={descriptionError}
                            multiline={true}
                            rowsMax="4"
                            rows="4"
                            onChange={(event) => {
                                this.handleFieldChange(restrictMaximumCharacters(event, VALIDATION_DESCRIPTION_LENGTH))
                                const {target: {value}} = event;
                                if (!isValidMaxLengthWithoutTrim(value, VALIDATION_DESCRIPTION_LENGTH)) {
                                    this.setState({descriptionError: getMaxLengthMessage(VALIDATION_DESCRIPTION_LENGTH)})
                                } else {
                                    this.setState({descriptionError: ''})
                                }
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} variant={"outlined"} color="secondary">Cancel</Button>
                <Button
                    datatest={'addButton'}
                    disabled={this.shouldCreateBeDisabled()}
                    variant={"contained"}
                    color="secondary"
                    onClick={() => handleOk({[ALIAS_SYS_ID_LABEL]: name, [ALIAS_SYS_BASE_IRI_PREFIX] : prefix, [ALIAS_SYS_BASE_IRI] : baseIRI, [ALIAS_SYS_DESCRIPTION]: description})}
                >{addButtonTitle || 'Add'}</Button>
            </DialogActions>
        </Dialog>;
    }

}

AddModelDialog.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    addButtonTitle: PropTypes.string,
    helpText: PropTypes.string,
    open: PropTypes.bool,
    handleCancel: PropTypes.func,
    handleOk: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(AddModelDialog);

import {withStyles} from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import React from "react";
import {
    GetEditableClassNames,
    getMultilingualValue,
    getPropertyName,
    getResourceId,
    isArrayOnly,
    isEmptyArray,
    isObjectOnly,
    sort,
    sortByFunction,
    toArray
} from "../../components/util";
import {BASE64_REGEX, ID, LANG, OBJECT, TYPE, VALUE} from "../../Constants";
import {
    DESCRIPTION_PROPERTY_FOR_SEARCH,
    getResourceViewOnClickURL,
    GRAPH_VIEW_PRECEDENCE,
    GRAPH_VIEW_SETTINGS,
    IMAGE_PROPERTY_FOR_SEARCH,
    LABEL_PROPERTY_FOR_SEARCH,
    SUMMARY_PROPERTIES,
    SUMMARY_PROPERTY_FOR_SEARCH,
} from "./DataViewSetup";
import {GRAPH_VIEW_BACKGROUND_COLOR,} from "./GraphViewStyleSettings";
import {cloneDeep, isArray, isObject, isString} from "lodash";
import {loadResource} from "../../service/data-loader";
import H4Title from "../../components/H4Title";
import Typography from "@material-ui/core/Typography";
import ResultCard from "./ResultCard";
import {deepOrange} from "@material-ui/core/colors";
import {getSiteHomePath, SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES, VIEW_MODE_LIST} from "./Workspace";
import PropTypes from "prop-types";
import {styles} from "../../components/styles";
import ResultAccordion from "./ResultAccordion";
import qs from "qs";
import {isDataValue, isLangValue} from "./Tree";
import ListResultItem from "./ListResultItem";
import {getBackgroundImageFromSettings} from "./navigator-cytoscape-util";
import {WithAdminEdit} from "./WithAdminEdit";

export function getTypeClassIRIs(resource, aliasesToIRIMap) {
    let types = toArray(resource[TYPE]);
    let classIRI = types.map(t => aliasesToIRIMap[t] || t);
    return classIRI;
}

export function getLabelProperties(settings, typeClassIRIs, propertyKey) {
    let found ;
    let typeArray = toArray(typeClassIRIs).sort();
    for(let i=0;i<typeArray.length;i++) {
        let t = typeArray[i];
        if(settings && settings.labelProperties && settings.labelProperties[t]) {

                if (!found) {
                    found = cloneDeep(settings.labelProperties[t][propertyKey]);
                } else {
                    let labelPropertyFilters = settings.labelProperties?.[t]?.[propertyKey]?.filters;
                    if(labelPropertyFilters) {
                        toArray(labelPropertyFilters).forEach(f => {
                            if (f.property) {
                                found.filters.push(cloneDeep(f));
                            }
                        })
                    }
                }
        }
    }
    return found;
}


function toViewValue(value, join = true) {
    let valueArray = toArray(value);
    const separator = ' , ';
    let stringValueArray;
    if (isLangValue(valueArray)){
        stringValueArray = sort(valueArray, LANG).map(v => v[VALUE]);
    }  else if (isDataValue(valueArray)) {
        stringValueArray = valueArray.map(v => v[VALUE]);
    } else if (!isEmptyArray(valueArray) && isString(valueArray[0])) {
        stringValueArray = valueArray;
    } else if (isObjectOnly(value)) {

        stringValueArray = Object.keys(value).map(k => value[k]).join(separator);
    } else if (isEmptyArray(valueArray)) {
        stringValueArray = valueArray
    } else {
        stringValueArray = JSON.stringify(value);
    }
    return join ? toArray(stringValueArray).sort().filter(v => v).join(separator) : stringValueArray;
}

export function isImageProperty(resource, settings, aliasesToIRIMap, propertyName) {
    let classIRI = getTypeClassIRIs(resource, aliasesToIRIMap);
    let labelProperties = getLabelProperties(settings, classIRI, IMAGE_PROPERTY_FOR_SEARCH);
    let found = toArray(labelProperties).map(lp => lp.filters).find(filters => {
        let found = filters.find(filter => {
            let {property} = filter;
            let label = property?.label;
            return (label && label === propertyName) || (label && aliasesToIRIMap[label] === aliasesToIRIMap[propertyName]);
        })
        return found ? true : false;
    })
    return found ? true : false;
}

export function toImageSrcValue(thumbnailValue) {
    if(thumbnailValue && !thumbnailValue.startsWith("http:")) {
        if(BASE64_REGEX.test(thumbnailValue)) {
            return  "data:image/*;charset=utf-8;base64, " + thumbnailValue;
        }
    }
    return thumbnailValue;
}

export async function getImageUrl(resource, settings, aliasesToIRIMap, browseLanguage, ontology) {
    let classIRI = getTypeClassIRIs(resource, aliasesToIRIMap);
    let thumbnailValue = await getStringValue(settings, browseLanguage, classIRI, resource, IMAGE_PROPERTY_FOR_SEARCH, aliasesToIRIMap, ontology);
    return thumbnailValue;
}

export function getMostRelevantGraphViewSettings(settings, typeIRIs) {
    const graphViews = toArray(typeIRIs).map(t => getLabelProperties(settings, t, GRAPH_VIEW_SETTINGS))
        .filter(gv => gv && gv[GRAPH_VIEW_PRECEDENCE]);
    const sortedGraphViews = sortByFunction(graphViews, (gv) => gv[GRAPH_VIEW_PRECEDENCE], 'desc');
    //if nothing is found where precedence is defined return as per logic in getLabelProperties
    return sortedGraphViews[0] || getLabelProperties(settings, typeIRIs, GRAPH_VIEW_SETTINGS);
}
export async function getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology) {
    let classIRI = getTypeClassIRIs(resource, aliasesToIRIMap);
    let titleMultilingualValue = await getStringValue(settings, browseLanguage, classIRI, resource, LABEL_PROPERTY_FOR_SEARCH, aliasesToIRIMap, ontology);
    let descriptionMultilingualValue = await getStringValue(settings, browseLanguage, classIRI, resource, DESCRIPTION_PROPERTY_FOR_SEARCH, aliasesToIRIMap, ontology);
    const imageFromInstanceProperty = await getStringValue(settings, browseLanguage, classIRI, resource, IMAGE_PROPERTY_FOR_SEARCH, aliasesToIRIMap, ontology);
    let thumbnailValue = imageFromInstanceProperty;
    if(thumbnailValue === undefined) {
        let images = getBackgroundImageFromSettings(classIRI, settings);
        thumbnailValue = images.length > 0 ? images[0] : undefined;
    }
    if(thumbnailValue === undefined) {
        const graphViews = toArray(classIRI).map(t => getLabelProperties(settings, t, GRAPH_VIEW_SETTINGS));
        let colors = graphViews.filter(gs => gs && gs[GRAPH_VIEW_BACKGROUND_COLOR]).map(gs => gs[GRAPH_VIEW_BACKGROUND_COLOR]);
        let typesCount = colors.length;
        if(typesCount > 0) {
            let svg = '<svg width="500" height="500" xmlns="http://www.w3.org/2000/svg">';
            let width = 100 / typesCount;
            let lastWidth = width + (100 - (width * typesCount));
            for (let i = 0; i < typesCount; i++) {
                let color = colors[i];
                let isLast = i == typesCount - 1;
                svg = svg + `<rect width="${isLast ? lastWidth : width}%" height="100%" x="${width * i}%" y="0" rx="0" ry="0" fill="${color}" />`
            }
            svg = svg + '</svg>';
            svg = 'data:image/svg+xml;utf8,' + encodeURIComponent(svg);
            thumbnailValue = svg;
        }
    }
    thumbnailValue = toImageSrcValue(thumbnailValue);

    let otherlabelPropertiesConfigs = getLabelProperties(settings, classIRI, SUMMARY_PROPERTIES);
    let otherProperties = toArray(otherlabelPropertiesConfigs).map(sp => {
        let label = sp.selectedProperty?.label;
        let value = label && resource[label];
        if (sp.isCount) {
            let multilingualValue = getMultilingualValue(sp.suffix, browseLanguage) || '';
            if (isArray(value)) {
                return value.length + " " + multilingualValue;
            } else if (isLangValue(value) || isDataValue(toArray(value))) {
                let valueArray = toArray(value).map(v => v[VALUE]);
                return valueArray.length + " " + multilingualValue;
            } else if (isObject(value)) {
                return Object.keys(value).length + " " + multilingualValue;
            } else if (value) {
                return "1 " + multilingualValue;
            } else {
                return "0 " + multilingualValue;
            }
        } else {
            if(sp.ignoreBrowseLanguage) {
                return toViewValue(value);
            } else {
                return getMultilingualValue(value, browseLanguage);
            }
        }
    }).map(p => ({value: isString(p) ? p : toViewValue(p)}));


    let summaryMultilingualValue = await getValue(settings, browseLanguage, classIRI, resource, SUMMARY_PROPERTY_FOR_SEARCH, aliasesToIRIMap, ontology);
    let summaryObject = getLabelProperties(settings, classIRI, SUMMARY_PROPERTY_FOR_SEARCH);
    let summaryTitle = toString(getMultilingualValue(summaryObject?.['title'], browseLanguage) || '');

    //thumbnailValue = `https://picsum.photos/id/${Math.floor(Math.random() * 1000)}/200/300`

    return {
        [ID] : resource[ID],
        title : titleMultilingualValue,
        description: descriptionMultilingualValue,
        thumbnail: thumbnailValue,
        instanceImage : toImageSrcValue(imageFromInstanceProperty),
        otherProperties : otherProperties,
        summary : {
            value : summaryMultilingualValue,
            title : summaryTitle,
            isCount :  summaryObject?.isCount
        }
    };
}

function hasConnectedObjectPath(filter) {
    let {value} = filter;
    let valueArray = toArray(value);
    let valueArrayFirst = valueArray.length > 0 ? valueArray[0] : undefined;
    if(valueArrayFirst) {
        return valueArrayFirst.property !== undefined ? true : false;
    } else {
        return false;
    }
}

async function getFilterValue(filters, resource, browseLanguage, aliasesToIRIMap, ontology, ignoreBrowseLanguage) {
    for(let i = 0; i < filters.length;i++) {
        let filter= filters[i];
        let {property, operator, value} = filter;
        let resourcePropertyValue = resource[property?.label];
        let operatorValue = operator ? operator.value : undefined;

        if (operatorValue === OBJECT && hasConnectedObjectPath(filter)) {
            let resources = await loadResource(resourcePropertyValue);
            let titleMultilingualValue = resources.map(async r => {
                if(r) {
                    let filterValue = await getFilterValue(value, r, browseLanguage, aliasesToIRIMap, ontology, ignoreBrowseLanguage);
                    return filterValue;
                }
            });
            let values = await Promise.all(titleMultilingualValue);
            let found = values.find(v => v);
            if(found) {
                return found;
            }
        } else {
            if(property?.value?.value === TYPE) {
                let propertyName = toArray(resourcePropertyValue)
                    .map(p => getPropertyName(aliasesToIRIMap, p, ontology, browseLanguage))
                    .filter(v => v);
                if(propertyName) {
                    return propertyName;
                }
            }
            if(ignoreBrowseLanguage) {
                let items = toViewValue(resourcePropertyValue, false);
                return sortByFunction(toArray(items), (v) => v);
            }
            let titleMultilingualValue = getMultilingualValue(resourcePropertyValue, browseLanguage, 'strict');
            if(titleMultilingualValue) {
                return titleMultilingualValue;
            }
        }
    }
    return;
}

async function getValue(settings, browseLanguage, classIRI, resource, propertyKey, aliasesToIRIMap, ontology) {
    let labelProperties = getLabelProperties(settings, classIRI, propertyKey);
    if(!labelProperties || !labelProperties.filters) {
        return ;
    }
    let ignoreBrowseLanguage = labelProperties?.ignoreBrowseLanguage === true;
    return await getFilterValue(labelProperties.filters, resource, browseLanguage, aliasesToIRIMap, ontology, ignoreBrowseLanguage);
}

async function getStringValue(settings, browseLanguage, classIRI, resource, propertyKey, aliasesToIRIMap, ontology) {
    let value = await getValue(settings, browseLanguage, classIRI, resource, propertyKey, aliasesToIRIMap, ontology);
    let labelProperties = getLabelProperties(settings, classIRI, propertyKey);
    let ignoreBrowseLanguage = labelProperties?.ignoreBrowseLanguage === true;
    return ignoreBrowseLanguage ? toViewValue(value) : toString(value);
}

function toString(val) {
    if(isArrayOnly(val) && isString(val[0])) {
        return val.join(' , ');
    }
    return isString(val) ? val : JSON.stringify(val);
}


export const NavigationChip = withStyles({
    root : {
        maxWidth : 'calc(100% - 12px)'
    }
})(Chip);

export const VIEW_MODE_ACCORDION = 'VIEW_MODE_ACCORDION';

export function getResourceUrl(location, resourceId, urlParams = {}) {
    let paramMap = {
        id : resourceId,
        ...urlParams
    }
    let params  = qs.stringify(paramMap);
    return `${getSiteHomePath(location)}/resource?${params}`;
}

class SearchResultItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            description: '',
            thumbnail: undefined,
            footer: <></>,
            otherProperties: []
        }
    }

    componentDidMount() {
        let {resource, settings, aliasesToIRIMap, browseLanguage, ontology} = this.props;
        getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology).then(o => {
            this.setState({...o});
        });
    }



    renderSummary = () => {
        let {summary} = this.state;
        let {theme, resource} = this.props;
        let valueArray = toArray(summary?.value);
        return <>
            <div style={{ width : '100%', backgroundColor : theme.palette.grey.main, height : '1px'}}></div>
            <div className={GetEditableClassNames(SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES, getResourceId(resource))} datatest={'summary'} style={{padding : '8px 16px'}}>
                <WithAdminEdit
                    style={{marginTop :'-8px', marginLeft : '40px'}}
                    data={{
                        action: SETTINGS_EDIT_CARD_SUMMARY_PROPERTIES,
                        id : getResourceId(resource),
                        resource
                    }}
                />
                <H4Title title={ (summary?.isCount === true ? valueArray.length + ' ' : '') + summary?.title }></H4Title>
                <div style={{marginTop :'4px'}}>
                    {
                        <Typography noWrap={true} variant="body2" color={"inherit"} component="div">{
                            valueArray.map((r, i) => {
                                return <NavigationChip variant={'outlined'} key={r} size={'small'} style={{marginRight : '16px'}} label={r}/>;
                            })
                        }</Typography>
                    }
                </div>
            </div>
        </>;
    }

    render() {
        let {title, description, thumbnail, otherProperties, summary} = this.state;
        let {graphViewProvider, onConceptClick, onItemFocus, viewProperties, configurations, ontology, resource, location, viewMode, aliasesToIRIMap, aliasesMap, settings, browseLanguage} = this.props;

        return viewMode === VIEW_MODE_ACCORDION
            ? <ResultAccordion
                viewProperties={viewProperties}
                configurations={configurations}
                ontology={ontology}
                title={title}
                description={description}
                otherProperties={otherProperties}
                summary={summary}
                thumbnail={thumbnail}
                resource={resource}
                location={location}
                aliasesToIRIMap={aliasesToIRIMap}
                settings={settings}
                aliasesMap={aliasesMap}
                browseLanguage={browseLanguage}
                graphViewProvider={graphViewProvider}
                onConceptClick={onConceptClick}
            />
            : viewMode === VIEW_MODE_LIST
                ? <ListResultItem
                    resource={cloneDeep(resource)}
                    settings={settings}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    location={location}
                    configurations ={configurations}
                    ontology={ ontology}
                    browseLanguage={ browseLanguage }
                    disableSelection={true}
                    graphViewProvider={graphViewProvider}
                    viewProperties={[]}
                />
                : <ResultCard
                    resource={resource}
                    otherProperties={otherProperties}
                    letter={"B"}
                    backgroundColor={deepOrange[500]}
                    url={getResourceViewOnClickURL(resource, settings, aliasesMap, location) || getResourceUrl(location, resource[ID])}
                    title={title}
                    description={description}
                    thumbnail={thumbnail}
                    actionAreaFooter={this.renderSummary}
                    onItemFocus={onItemFocus}
                />;
    }

}

SearchResultItem.propTypes = {
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    location: PropTypes.object,
    settings: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    resource: PropTypes.object,
    viewMode: PropTypes.string,
    viewProperties: PropTypes.any,
    graphViewProvider: PropTypes.func,
    onItemFocus : PropTypes.func,
    onConceptClick : PropTypes.func
};

export default withStyles(styles, {withTheme:true})(SearchResultItem);

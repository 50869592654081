import React, {Fragment} from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {styles} from "../styles";
import Collapse from "@material-ui/core/Collapse";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowDropDownIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import ArrowDropUpIcon from '@material-ui/icons/KeyboardArrowUpOutlined';
import {WithAdminEdit} from "../../layouts/navigator/WithAdminEdit";
import {SETTINGS_EDIT_ACTION_BANNER_CONTAINER, SETTINGS_EDIT_APP_BAR} from "../../layouts/navigator/Workspace";
import {GetEditableClassNames} from "../util";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {collapse: true}
    }

    render() {
        const {components, classes, collapsible} = this.props;
        const {collapse} = this.state;
        let header  = <div className={"header "+GetEditableClassNames(SETTINGS_EDIT_APP_BAR)} >
            <WithAdminEdit data={{action : SETTINGS_EDIT_APP_BAR}}></WithAdminEdit>
            {components.map((c, i) => <Fragment key={i}>{c}</Fragment>)}
        </div>
        let component =
            collapsible && collapsible === true
                ? <>
                    <Collapse in={collapse}>{header}</Collapse>
                    <div style={{overflow: "hidden"}}>
                        <div size={"small"} variant={"extended"} className={classes.appBarClose}
                             onClick={() => this.setState({collapse: !collapse})}>
                            {collapse === false && <Tooltip title={"Show the menus"}><ArrowDropDownIcon/></Tooltip>}
                            {collapse === true && <Tooltip title={"Hide the menus"}><ArrowDropUpIcon/></Tooltip>}
                        </div>
                    </div>
                </>
                : header
        return (component);
    }
}

Header.propTypes = {
    components: PropTypes.array,
    collapsible: PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(Header);

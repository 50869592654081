import {IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE} from "../Constants";

export const organisationPersonImage = `@prefix owl:  <http://www.w3.org/2002/07/owl#> .
@prefix rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix rdfs:  <http://www.w3.org/2000/01/rdf-schema#> .
@prefix xsd:  <http://www.w3.org/2001/XMLSchema#> .
@prefix ex:  <http://example.com/> .
@prefix skos:  <http://www.w3.org/2004/02/skos/core#> .

<${IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE}>  a owl:Ontology .

owl:Thing rdf:type owl:Class ;
    rdfs:label "Thing"@en .

ex:Thing rdf:type owl:Class;
    rdfs:subClassOf owl:Thing .

ex:CreativeWork rdf:type owl:Class ;
    rdfs:label "CreativeWork"@en ;
    rdfs:subClassOf ex:Thing ,
    [
        a owl:Restriction ;
        owl:onProperty ex:dateCreated ;
        owl:maxCardinality 1
    ].

ex:MediaObject  rdf:type  owl:Class ;
    rdfs:label       "MediaObject"@en ;
    rdfs:subClassOf  ex:CreativeWork ,
    [
        a owl:Restriction ;
        owl:onProperty ex:width ;
        owl:maxCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty ex:height ;
        owl:maxCardinality 1
    ].

ex:ImageObject rdf:type owl:Class ;
    rdfs:label "ImageObject"@en ;
    rdfs:subClassOf ex:MediaObject .

ex:Person  rdf:type  owl:Class ;
    rdfs:label    "Person" ;
    rdfs:subClassOf  ex:Thing ,
    [
        a owl:Restriction ;
        owl:onProperty ex:givenName ;
        owl:cardinality 1
    ].

ex:Organisation  rdf:type  owl:Class ;
    rdfs:label    "Organisation"@en ;
    rdfs:subClassOf ex:Thing ,
    [
        a owl:Restriction ;
        owl:onProperty ex:name ;
        owl:cardinality 1
    ] ,
    [
        a owl:Restriction ;
        owl:onProperty ex:alternateName ;
        owl:maxCardinality 1
    ] ,
    [
        a owl:Restriction ;
        owl:onProperty ex:langString ;
        owl:cardinality 1
    ].

ex:Product  rdf:type  owl:Class ;
    rdfs:label    "Product"@en ;
    rdfs:subClassOf ex:Thing ,
        [
            a owl:Restriction ;
            owl:onProperty ex:brand
        ],
        [
            a owl:Restriction ;
            owl:onProperty skos:notation;
            owl:minCardinality 1
        ],
        [
            a owl:Restriction ;
            owl:onProperty ex:mainBrand;
            owl:cardinality 1
        ].

ex:Square rdf:type owl:Class;
    rdfs:subClassOf ex:Thing .

ex:SquareImageObject rdf:type owl:Class;
    rdfs:subClassOf ex:ImageObject;
    rdfs:subClassOf ex:Square ;
    rdfs:subClassOf [
            a owl:Restriction ;
            owl:onProperty skos:notation;
            owl:maxCardinality 2
        ] .

ex:Brand rdf:type owl:Class ;
    rdfs:label "Brand"@en ;
    rdfs:subClassOf
        [
            a owl:Restriction ;
            owl:onProperty skos:notation;
            owl:cardinality 1
        ] .

ex:name  rdfs:domain  ex:Thing ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "name" .

ex:alternateName  rdfs:domain  ex:Thing ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "alternateName" .

ex:slogan  rdfs:domain  ex:Organisation ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "slogan" .

ex:description  rdfs:domain   ex:Thing ;
    rdfs:range  rdf:langString ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "description" .

ex:image  rdfs:domain   ex:Thing ;
    rdfs:range  ex:ImageObject ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "image" .

ex:imageOf  rdfs:domain   ex:ImageObject ;
    rdfs:range  ex:Thing ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "imageOf" ;
    owl:inverseOf ex:image .

ex:givenName  rdfs:domain  ex:Person ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "given name" .

ex:member rdfs:domain  ex:Organisation ;
    rdfs:range    ex:Person ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "member" ;
    owl:inverseOf ex:memberOf .

ex:memberOf rdfs:domain  ex:Person ;
    rdfs:range    ex:Organisation ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "memberOf" ;
    owl:inverseOf ex:member .

ex:dateCreated  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:dateTime ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "dateCreated" .

ex:dateSold  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:date ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "dateSold" .

ex:showTime  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:time ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "showTime" .

ex:playDuration  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:duration ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "playDuration" .

ex:height  rdfs:domain   ex:MediaObject ;
    rdfs:range  xsd:positiveInteger ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "height" .

ex:width  rdfs:domain   ex:MediaObject ;
    rdfs:range    xsd:positiveInteger ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "width" .

ex:hasExecutiveMembers  a owl:ObjectProperty, owl:FunctionalProperty, rdf:Property ;
    rdfs:domain ex:Organisation ;
    rdfs:range rdf:List .

ex:area a owl:DatatypeProperty;
    rdfs:range    xsd:positiveInteger ;
    rdfs:domain ex:Square .

ex:brand a owl:ObjectProperty;
    rdfs:domain ex:Product ;
    rdfs:range [
        a owl:Class ;
        owl:unionOf (
            ex:Brand
            ex:Organisation
        )
    ].

ex:mainBrand a owl:ObjectProperty;
    rdfs:domain ex:Product ;
    rdfs:range [
        a owl:Class ;
        owl:unionOf (
            ex:Brand
            ex:Organisation
        )
    ].

ex:representativeOfPage a owl:DatatypeProperty;
    rdfs:range    xsd:boolean ;
    rdfs:domain ex:ImageObject .

ex:langString  rdfs:domain   ex:Thing ;
    rdfs:range  rdf:langString ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "langString" .

ex:gYear a owl:DatatypeProperty;
    rdfs:range    xsd:gYear ;
    rdfs:domain ex:ImageObject .

ex:gMonth a owl:DatatypeProperty;
    rdfs:range    xsd:gMonth ;
    rdfs:domain ex:ImageObject .

ex:gDay a owl:DatatypeProperty;
    rdfs:range    xsd:gDay ;
    rdfs:domain ex:ImageObject .

ex:custom  rdfs:range  ex:customDatatype ;
    rdfs:domain ex:ImageObject ;
    rdf:type      owl:DatatypeProperty .

ex:noDomainOP  rdfs:range  ex:ImageObject ;
    rdf:type      owl:ObjectProperty .

ex:noDomainNoRangeOP rdf:type      owl:ObjectProperty .

ex:noDomainDP  rdfs:range  xsd:boolean ;
    rdf:type      owl:DatatypeProperty .

ex:noDomainNoRangeDP rdf:type      owl:DatatypeProperty .

ex:noDomainNoRangeRdfProperty rdf:type  rdf:Property .

skos:notation  a owl:DatatypeProperty, rdf:Property .

ex:owlThingDP  rdfs:domain  owl:Thing ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "owl thing DP" .

ex:owlThingOP  rdfs:domain  owl:Thing ;
    rdfs:range    owl:Thing ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "owl thing OP" .

`;

export const organisationPersonImageSmall = `@prefix owl:  <http://www.w3.org/2002/07/owl#> .
@prefix rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix rdfs:  <http://www.w3.org/2000/01/rdf-schema#> .
@prefix xsd:  <http://www.w3.org/2001/XMLSchema#> .
@prefix ex:  <http://example.com/> .
@prefix skos:  <http://www.w3.org/2004/02/skos/core#> .

<${IRI_TEST_ONTOLOGY_ORG_PERSON_IMAGE}>  a owl:Ontology .

ex:Thing rdf:type owl:Class;
    rdfs:label "Thing"@en ;
    rdfs:subClassOf owl:Thing .

ex:CreativeWork rdf:type owl:Class ;
    rdfs:label "Creative Work"@en ;
    rdfs:subClassOf ex:Thing ,
    [
        a owl:Restriction ;
        owl:onProperty ex:dateCreated ;
        owl:maxCardinality 1
    ].

ex:MediaObject  rdf:type  owl:Class ;
    rdfs:label       "Media Object"@en ;
    rdfs:subClassOf  ex:CreativeWork ,
    [
        a owl:Restriction ;
        owl:onProperty ex:contentUrl ;
        owl:maxCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty ex:width ;
        owl:maxCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty ex:height ;
        owl:maxCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty ex:dateSold ;
        owl:maxCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty ex:showTime ;
        owl:maxCardinality 1
    ].

ex:ImageObject rdf:type owl:Class ;
    rdfs:label "Image Object"@en ;
    rdfs:subClassOf ex:MediaObject .

ex:Person  rdf:type  owl:Class ;
    rdfs:label    "Person"@en ;
    rdfs:subClassOf  ex:Thing ,
    [
        a owl:Restriction ;
        owl:onProperty ex:givenName ;
        owl:cardinality 1
    ].

ex:Organisation  rdf:type  owl:Class ;
    rdfs:label    "Organisation"@en ;
    rdfs:subClassOf ex:Thing ,
    [
        a owl:Restriction ;
        owl:onProperty ex:name ;
        owl:cardinality 1
    ].

ex:inLanguage  rdfs:domain  ex:CreativeWork ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "In Language" .

ex:knowsLanguage  rdfs:domain  ex:Person ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Knows Language" .

ex:assesses  rdfs:domain  ex:CreativeWork ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Assesses" .

ex:audience  rdfs:domain  ex:CreativeWork ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Audience" .

ex:contentUrl  rdfs:domain  ex:MediaObject ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Content URL" .

ex:name  rdfs:domain  ex:Thing ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "Name" .

ex:slogan  rdfs:domain  ex:Organisation ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "Slogan" .

ex:description  rdfs:domain   ex:Thing ;
    rdfs:range  rdf:langString ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "Description" .

ex:image  rdfs:domain   ex:Thing ;
    rdfs:range  ex:ImageObject ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Image" .

ex:imageOf  rdfs:domain   ex:ImageObject ;
    rdfs:range  ex:Thing ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Image of" ;
    owl:inverseOf ex:image .

ex:givenName  rdfs:domain  ex:Person ;
    rdfs:range    xsd:string ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "Given Name" .

ex:member rdfs:domain  ex:Organisation ;
    rdfs:range    ex:Person ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Member" ;
    owl:inverseOf ex:memberOf .

ex:memberOf rdfs:domain  ex:Person ;
    rdfs:range    ex:Organisation ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "Member Of" ;
    owl:inverseOf ex:member .

ex:dateCreated  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:dateTime ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "Date Created" .

ex:dateSold  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:date ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "Date Sold" .

ex:showTime  rdfs:domain   ex:CreativeWork ;
    rdfs:range    xsd:time ;
    rdf:type  owl:DatatypeProperty ;
    rdfs:label    "Show Time" .


ex:height  rdfs:domain   ex:MediaObject ;
    rdfs:range  xsd:positiveInteger ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "Height" .

ex:width  rdfs:domain   ex:MediaObject ;
    rdfs:range    xsd:positiveInteger ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:label    "Width" .

ex:hasExecutiveMembers  a owl:ObjectProperty, owl:FunctionalProperty, rdf:Property ;
    rdfs:domain ex:Organisation ;
    rdfs:label    "Has Executive Members" ;
    rdfs:range rdf:List .

ex:representativeOfPage a owl:DatatypeProperty;
    rdfs:range    xsd:boolean ;
    rdfs:label    "Representative Of Page" ;
    rdfs:domain ex:ImageObject .


skos:notation  a owl:DatatypeProperty, rdf:Property ;
    rdfs:label    "Notation" ;
    rdfs:domain ex:Thing .

`;

export const publishing = `@prefix owl:  <http://www.w3.org/2002/07/owl#> .
@prefix rdf:  <http://www.w3.org/1999/02/22-rdf-syntax-ns#> .
@prefix rdfs:  <http://www.w3.org/2000/01/rdf-schema#> .
@prefix xsd:  <http://www.w3.org/2001/XMLSchema#> .
@prefix schema:  <http://schema.org/> .
@prefix ex:  <http://example.com/> .
@prefix skos:  <http://www.w3.org/2004/02/skos/core#> .

ex:testLearnOntology a owl:Ontology .

schema:Thing  rdfs:label  "Thing" ;
        rdf:type      owl:Class ;
        rdfs:comment  "The most generic type of item." .

schema:CreativeWork rdf:type owl:Class ;
    rdfs:label "CreativeWork"@en ;
    rdfs:subClassOf schema:Thing .

ex:Product rdf:type owl:Class ;
    rdfs:label "Product"@en ;
    rdfs:subClassOf schema:CreativeWork,
    [
        a owl:Restriction ;
        owl:onProperty ex:subject ;
        owl:minCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty schema:hasPart ;
        owl:allValuesFrom ex:LearningContainer ;
    ].

ex:LearningContainer rdf:type owl:Class ;
    rdfs:label "LearningContainer"@en ;
    rdfs:subClassOf schema:CreativeWork,
    [
        a owl:Restriction ;
        owl:onProperty schema:hasPart ;
        owl:allValuesFrom
        [
            a owl:Class ;
            owl:unionOf  ( ex:LearningObject ex:LearningContainer ) ;
        ];
    ],
    [
        a owl:Restriction ;
        owl:onProperty schema:isPartOf ;
        owl:allValuesFrom ex:Product ;
    ].

ex:LearningObject rdf:type owl:Class ;
    rdfs:label "LearningObject"@en ;
    rdfs:subClassOf schema:Thing,
    [
        a owl:Restriction ;
        owl:onProperty ex:subject ;
        owl:minCardinality 1
    ],
    [
        a owl:Restriction ;
        owl:onProperty schema:isPartOf ;
        owl:allValuesFrom ex:LearningContainer ;
    ].

ex:LearningObjective rdf:type owl:Class ;
    rdfs:label "LearningObjective"@en ;
    rdfs:subClassOf [
        a owl:Restriction ;
        owl:onProperty skos:inScheme ;
        owl:minCardinality 1 ;
    ],
    [
        a owl:Restriction ;
        owl:onProperty skos:prefLabel ;
        owl:minCardinality 1 ;
        owl:allValuesFrom rdf:langString ;
    ].

schema:name  rdfs:domain  schema:Thing ;
    rdfs:range    rdf:langString ;
    rdf:type      owl:DatatypeProperty ;
    rdfs:comment  "The name of the item." ;
    rdfs:label    "name"@en .

skos:prefLabel
    rdfs:label "preferred label"@en ;
    rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
    skos:definition "The preferred lexical label for a resource, in a given language."@en ;
    a owl:AnnotationProperty, rdf:Property ;
    rdfs:subPropertyOf rdfs:label ;
    rdfs:comment "A resource has no more than one value of skos:prefLabel per language tag, and no more than one value of skos:prefLabel without language tag."@en, "The range of skos:prefLabel is the class of RDF plain literals."@en, """skos:prefLabel, skos:altLabel and skos:hiddenLabel are pairwise
    disjoint properties."""@en .

skos:inScheme
    rdfs:label "is in scheme"@en ;
    rdfs:isDefinedBy <http://www.w3.org/2004/02/skos/core> ;
    skos:definition "Relates a resource (for example a concept) to a concept scheme in which it is included."@en ;
    skos:scopeNote "A concept may be a member of more than one concept scheme."@en ;
    a owl:ObjectProperty, rdf:Property  .

ex:instructs rdfs:domain  ex:LearningObject ;
    rdfs:range    ex:LearningObjective ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "instructs"@en ;
    owl:inverseOf ex:instructedBy .

ex:instructedBy rdfs:domain  ex:LearningObjective ;
    rdfs:range    ex:LearningObject ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "instructedBy"@en .

ex:subject rdfs:domain  ex:LearningObject ;
    rdf:type      owl:ObjectProperty ;
    rdfs:label    "subject"@en .

schema:isPartOf  rdfs:domain  schema:CreativeWork ;
    rdfs:comment   "Indicates a CreativeWork that this CreativeWork is (in some sense) part of." ;
    rdfs:label     "isPartOf"@en ;
    rdf:type       owl:ObjectProperty ;
    owl:inverseOf  schema:hasPart ;
    rdfs:range     schema:CreativeWork .

schema:hasPart  rdf:type  owl:ObjectProperty ;
    owl:inverseOf  schema:isPartOf ;
    rdfs:label     "hasPart"@en ;
    rdfs:domain    schema:CreativeWork ;
    rdfs:comment   "Indicates a CreativeWork that is (in some sense) a part of this CreativeWork." ;
    rdfs:range     schema:CreativeWork .
`;

export const microsoftKnowledgeGraph = `@prefix owl: <http://www.w3.org/2002/07/owl#> .
@prefix org: <http://www.w3.org/ns/org#> .
@prefix rdfs: <http://www.w3.org/2000/01/rdf-schema#> .
@prefix dc: <http://purl.org/dc/terms/> .
@prefix foaf: <http://xmlns.com/foaf/0.1/> .
@prefix xsd: <http://www.w3.org/2001/XMLSchema#> .
@prefix magprop: <http://ma-graph.org/property/> .
@prefix magcls: <http://ma-graph.org/class/> .
@prefix locid: <http://id.loc.gov/vocabulary/identifiers/> .

<http://ma-graph.org/vocab#> a owl:Ontology .
org:memberOf
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Author> ;
    rdfs:range <http://ma-graph.org/class/Affiliation> .

<http://ma-graph.org/property/hasParent>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

dc:isPartOf
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range <http://ma-graph.org/class/ConferenceSeries> .

dc:creator
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/Author> .

<http://purl.org/spar/cito/hasCitedEntity>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/Paper> .

<http://purl.org/spar/cito/hasCitingEntity>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/Paper> .

<http://purl.org/spar/fabio/hasDiscipline>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://purl.org/spar/cito/cites>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/Paper> .

<http://ma-graph.org/property/appearsInConferenceInstance>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/ConferenceInstance> .

<http://ma-graph.org/property/appearsInConferenceSeries>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/ConferenceSeries> .

<http://ma-graph.org/property/appearsInJournal>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range <http://ma-graph.org/class/Journal> .

<http://ma-graph.org/property/diseaseHasDiseaseCause>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://ma-graph.org/property/diseaseHasMedicalTreatment>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://ma-graph.org/property/diseaseHasSymptom>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://ma-graph.org/property/medicalTreatmentForDiseaseCause>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://ma-graph.org/property/medicalTreatmentForSymptom>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://ma-graph.org/property/symptomHasDiseaseCause>
    a owl:ObjectProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range <http://ma-graph.org/class/FieldOfStudy> .

<http://ma-graph.org/class/Affiliation>
    a owl:Class ;
    rdfs:label "Affiliation"@pa .

<http://ma-graph.org/class/Author>
    a owl:Class ;
    rdfs:label "Author"@en .

<http://ma-graph.org/class/ConferenceInstance>
    a owl:Class ;
    rdfs:label "Conference Instance"@en .

<http://ma-graph.org/class/ConferenceSeries>
    a owl:Class ;
    rdfs:label "Conference Series"@en .

<http://ma-graph.org/class/FieldOfStudy>
    a owl:Class ;
    rdfs:label "Field of study"@en .

<http://ma-graph.org/class/Journal>
    a owl:Class ;
    rdfs:label "Journal"@en .

<http://ma-graph.org/class/Citation>
    a owl:Class ;
    rdfs:label "Citation"@en .

<http://ma-graph.org/class/Paper>
    a owl:Class ;
    rdfs:label "Paper"@en .

foaf:homepage
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Affiliation>, <http://ma-graph.org/class/ConferenceInstance>, <http://ma-graph.org/class/Journal> ;
    rdfs:range xsd:anyURI .

<http://ma-graph.org/property/citationCount>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Author> ;
    rdfs:range xsd:integer .

dc:created
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Author> ;
    rdfs:range xsd:date .

<http://ma-graph.org/property/grid>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Affiliation> ;
    rdfs:range xsd:anyURI .

<http://ma-graph.org/property/paperCount>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Author> ;
    rdfs:range xsd:integer .

<http://ma-graph.org/property/rank>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Author> ;
    rdfs:range xsd:integer .

foaf:name
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Affiliation>, <http://ma-graph.org/class/Author>, <http://ma-graph.org/class/ConferenceInstance>, <http://ma-graph.org/class/ConferenceSeries>, <http://ma-graph.org/class/FieldOfStudy>, <http://ma-graph.org/class/Journal> ;
    rdfs:range xsd:string .

<http://id.loc.gov/vocabulary/identifiers/issn>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Journal> ;
    rdfs:range xsd:string .

dc:publisher
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Journal>, <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:string .

dc:abstract
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:string .

<http://purl.org/NET/c4dm/timeline.owl#start>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:date .

<http://dbpedia.org/ontology/location>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:anyURI .

<http://ma-graph.org/property/abstractRegistrationDate>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:date .

<http://ma-graph.org/property/finalVersionDueDate>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:date .

<http://ma-graph.org/property/notificationDueDate>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:date .

<http://ma-graph.org/property/submissionDeadlineDate>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:date .

<http://purl.org/NET/c4dm/timeline.owl#end>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/ConferenceInstance> ;
    rdfs:range xsd:date .

<http://ma-graph.org/property/category>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range xsd:string .

<http://ma-graph.org/property/level>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/FieldOfStudy> ;
    rdfs:range xsd:integer .

<http://purl.org/spar/c4o/hasContext>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Citation> ;
    rdfs:range xsd:string .

dc:language
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:language .

<http://purl.org/spar/fabio/hasURL>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:anyURI .

<http://prismstandard.org/namespaces/basic/2.0/startingPage>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:integer .

<http://prismstandard.org/namespaces/basic/2.0/publicationDate>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:date .

<http://prismstandard.org/namespaces/basic/2.0/endingPage>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:integer .

<http://prismstandard.org/namespaces/basic/2.0/issueIdentifier>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:string .

<http://prismstandard.org/namespaces/basic/2.0/volume>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:integer .

dc:title
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:string .

<http://purl.org/spar/datacite/doi>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:string .

<http://ma-graph.org/property/bookTitle>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:string .

<http://ma-graph.org/property/estimatedCitationCount>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:integer .

<http://ma-graph.org/property/referenceCount>
    a owl:DatatypeProperty ;
    rdfs:domain <http://ma-graph.org/class/Paper> ;
    rdfs:range xsd:integer .`;


export const testOntologies = [
    { id: 1, name: 'Organisation Person Image', data: organisationPersonImageSmall },
   // { id: 2, name: 'Publishing', data: publishing },
   // { id: 3, name: 'Microsoft Knowledge Graph', data: microsoftKnowledgeGraph },
];

export default testOntologies;

import {centerVertically, getLocalName, getResourceId, scrollToView, sort, toArray} from "../../components/util";
import {
    Badge,
    CircularProgress,
    LinearProgress,
    Menu,
    MenuItem,
    styled,
    Tooltip,
    Typography,
    useTheme
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import {CenterFocusStrongOutlined, ExpandLessOutlined, ExpandMoreOutlined, MoreVertOutlined} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import React, {Fragment, useEffect, useState} from "react";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {FONT_1, HTML_SPACE, ID} from "../../Constants";
import {lighten} from "@material-ui/core/styles";
import H3Title from "../../components/H3Title";
import {loadResource} from "../../service/data-loader";
import SearchResultItem, {getValuesObject, VIEW_MODE_ACCORDION} from "./SearchResultItem";
import qs from "qs";
import {getSiteHomePath} from "./Workspace";
import queryString from "query-string";
import uuid4 from "uuid/v4";

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: 0,
        top: 0,
        border: `1px solid ${theme.palette.text.primary}`,
        padding: '0 2px',
        backgroundColor : 'unset',
        color : theme.palette.text.primary
    },
}));

export function TaxonomyRowActions({onAction}) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleItemClick = (action) => () => {
        handleClose();
        onAction && onAction(action);
    };

    return (
        <div>
            <Tooltip title={'Actions'}>
                <IconButton
                    id="report-menu-button"
                    aria-controls={open ? 'report-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    size={'small'}
                >
                    <MoreVertOutlined fontSize={'small'}/>
                </IconButton>
            </Tooltip>
            <Menu
                id="report-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'report-menu',
                }}
            >
                <MenuItem onClick={() => {}}>
                    <ListItemIcon>
                        <CenterFocusStrongOutlined fontSize="small"/>
                    </ListItemIcon>
                    <ListItemText>Pin this term</ListItemText>
                </MenuItem>
            </Menu>
        </div>
    );
}

export function getNavigateToConceptLink(conceptSchemeId, conceptId, location, nodeId, treeTab) {
    let paramMap = {
        conceptSchemeId
    }
    if(conceptId) {
        paramMap.conceptId = conceptId;
    }
    if(nodeId) {
        paramMap.nodeId = nodeId;
    }
    if(treeTab) {
        paramMap.treeTab = treeTab;
    }

    let params = qs.stringify(paramMap);
    let nodeLinkURL = `${getSiteHomePath(location)}/taxonomy?${params}`;
    return nodeLinkURL;
}

export function getNavigateToOntologyLink(ontologyId, resourceId, location, nodeId, treeTab) {
    let paramMap = {
        ontologyId,
        resourceId,
    }
    if(nodeId) {
        paramMap.nodeId = nodeId;
    }
    if(treeTab) {
        paramMap.treeTab = treeTab;
    }
    let params = qs.stringify(paramMap);
    let nodeLinkURL = `${getSiteHomePath(location)}/ontology?${params}`;
    return nodeLinkURL;
}

export function ResourceView({resource, theme, middleLoading, viewProperties, configurations, ontology, location, aliasesToIRIMap, settings, aliasesMap, browseLanguage, graphViewProvider, onConceptClick}) {
    return resource ? <div style={{paddingBottom: '32px'}} key={resource?.[ID]}>

        <Typography style={{
            color: theme.palette.white.main,
            fontWeight: '500',
            fontFamily: FONT_1,
            fontSize: '20px',
            display: 'flex'
        }} component={'div'}>{middleLoading && <LinearProgress style={{flexGrow: '1'}}/>}{HTML_SPACE}</Typography>
        <div>
            <SearchResultItem
                viewProperties={viewProperties}
                configurations={configurations}
                ontology={ontology}
                resource={resource}
                location={location}
                aliasesToIRIMap={aliasesToIRIMap}
                settings={settings}
                aliasesMap={aliasesMap}
                browseLanguage={browseLanguage}
                viewMode={VIEW_MODE_ACCORDION}
                graphViewProvider={graphViewProvider}
                onConceptClick={onConceptClick}
            />
        </div>
    </div> : <></>;
}

export const TreeNode = ({
                             conceptSchemeId,
                             conceptId,
                             index,
                             depth,
                             onAction,
                             expansionPath,
                             selectedNodeId,
                             searchConceptId,
                             expand = false,
                             location,
                             settings,
                             aliasesToIRIMap,
                             browseLanguage,
                             ontology,
                             narrowerIdsProvider,
                             onClickNodeLinkProvider,
    resourceIdKey = 'conceptId',
    registerNode

}) => {
    const theme = useTheme();
    const [expanded, setExpanded] = useState();
    const [active, setActive] = useState();
    const [nodeId, setNodeId] = useState(uuid4());
    const [concept, setConcept] = useState();
    const [conceptTitle, setConceptTitle] = useState();
    const [narrower, setNarrower] = useState([]);
    const [narrowerIds, setNarrowerIds] = useState([]);
    const [loading, setLoading] = useState(false);
    const [firstLoading, setFirstLoading] = useState(false);
    const [apiErrorResponse, setApiErrorResponse] = useState();
    const [rest, setRest] = useState();
    let params = queryString.parse(location.search);
    const conceptIdFromURL = params[resourceIdKey] && decodeURIComponent(params[resourceIdKey]);
    const nodeIdFromURL = params['nodeId'] && decodeURIComponent(params['nodeId']);

    const getNarrowerIds = async (concept) => {
        let narrowerIds = narrowerIdsProvider ? await narrowerIdsProvider(concept) : concept?.narrower;
        return narrowerIds;
    }

    const loadNarrower = async (concept) => {
        setLoading(true);
        let narrowerIds1 = await getNarrowerIds(concept);
        let narrowers = await loadResource(narrowerIds1);
        let vos = narrowers.map(async tc => await getValuesObject(tc, settings, aliasesToIRIMap, browseLanguage, ontology));
        let tcVOs = await Promise.all(vos);
        setNarrower(sort(tcVOs));
        setNarrowerIds(narrowerIds1);
        setLoading(false);
    }

    const onEvent = async (data) => {
        let dataArray = toArray(data);
        if(dataArray.length > 0) {
            await handleExpand();
            if(dataArray.length > 1) {
                setRest(dataArray.filter((d , i) => i !==0 ));
            }
        }

    }

    useEffect(() => {
        if(expanded) {
            if(toArray(rest).length > 0) {
                let first = rest[0];
                let resourceId = getResourceId(first);
                first.treeRegister(resourceId)(rest);
            }
        }

    }, [expanded])

    useEffect(() => {
        const loadData = async () => {
            if (!concept) {
                let resources = await loadResource(conceptId);
                let resource = resources.find(r => r && getResourceId(r) === conceptId);
                let vo = await getValuesObject(resource, settings, aliasesToIRIMap, browseLanguage, ontology);
                setConcept(resource);
                setConceptTitle(vo.title || getLocalName(conceptId, false) || '');
                if((expanded && narrower.length === 0) || narrowerIdsProvider) {
                    await loadNarrower(resource);
                }
                if(conceptIdFromURL === conceptId) {
                    onAction('click', nodeLinkURL, nodeId);
                    setTimeout(() => {
                        const node = document.getElementById(nodeId);
                        node && scrollToView(node);
                    }, 300);
                }
                registerNode(resource, onEvent)
            } else {
                setApiErrorResponse(`Resource not found for id '${conceptId}'`);
            }

            setFirstLoading(true);

        };
        loadData().catch(e => {});
    }, [ conceptIdFromURL, setConcept,  setConceptTitle]);

    let narrowerCount = toArray(narrowerIds).length;

    const spanWidth = 48;
    const hasSuggestions = false;
    const selected = nodeId === selectedNodeId || searchConceptId === conceptId;
    if(searchConceptId === conceptId) {
        setTimeout(() => {
            const node = document.getElementById(nodeId);
            node && scrollToView(node);
        }, 200);
    }

    const handleExpand = async () => {
        if(!expanded) {
            await loadNarrower(concept);
        }
        setExpanded(!expanded);
    }

    let nodeLinkURL = onClickNodeLinkProvider ? onClickNodeLinkProvider(conceptSchemeId, conceptId, location) : getNavigateToConceptLink(conceptSchemeId, conceptId, location);

    let isExpanded = expand || expanded;
    return <Fragment>
        <div style={{display: 'flex'}}>

                <span style={{minWidth: spanWidth + 'px'}}>
                     {

                         narrowerCount > 0 &&

                         <IconButton
                             style={{marginTop : '4px'}}
                             disabled={narrowerCount < 1}
                             size={'small'}
                             onClick={handleExpand}>
                             {

                                 <StyledBadge
                                     badgeContent={narrowerCount}
                                     max={99}
                                 >
                                     {loading ? <CircularProgress size={24}/> : isExpanded ? <ExpandLessOutlined/> :
                                         <ExpandMoreOutlined/>}
                                 </StyledBadge>
                             }
                         </IconButton>
                     }
                </span>

            <div
                key={conceptId + "-" + index}
                style={{
                    display: 'flex',
                    borderRadius: '4px',
                    marginBottom: '8px',
                    border: '1px solid',
                    borderLeft:'3px solid',
                    borderColor: hasSuggestions? theme.palette.warning.dark : (selected || active ? theme.palette.secondary.light : theme.palette.divider),
                    minWidth: '280px',
                    minHeight: '40px',
                    backgroundColor: selected || active ? lighten(theme.palette.secondary.light, 0.85) : undefined,

                }}
                onClick={() => {}}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
            >
                <Tooltip title={conceptId}>
                <div onClick={() => {
                    onAction('click', nodeLinkURL, nodeId);
                }} style={{height : '100%', flexGrow : 1, padding: '0px 16px', textDecoration : 'none', }} >
                    {centerVertically(

                        <H3Title noWrap={true} style={{ maxWidth : '280px', color : selected || active ? theme.palette.secondary.dark : undefined}}>
                            {conceptTitle === undefined && <CircularProgress size={16}/>}
                            {conceptTitle || conceptId}
                        </H3Title>
                        , {height : '100%'}

                    )}
                </div>
                </Tooltip>

                {active && false &&


                    centerVertically(<TaxonomyRowActions onAction={(action) =>onAction(action, concept)}/>)

                }
            </div>
        </div>
        {isExpanded && <div  style={{marginLeft: spanWidth/2}}>
            <div style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                <Collapse in={isExpanded ? true : false} timeout="auto" unmountOnExit>
                    {narrower.map((c, i) => {
                        let newDepth = depth+1;
                        let resourceId1 = getResourceId(c);
                        let expand = toArray(expansionPath).length > newDepth && expansionPath[newDepth] === resourceId1;
                        return <TreeNode
                            expand={expand}
                            expansionPath={expansionPath}
                            key={resourceId1}
                            conceptId={resourceId1}
                            conceptSchemeId={conceptSchemeId}
                            index={i}
                            depth={newDepth}
                            aliasesToIRIMap={aliasesToIRIMap}
                            settings={settings}
                            ontology={ontology}
                            browseLanguage={browseLanguage}
                            location={location}
                            onAction={onAction}
                            selectedNodeId={selectedNodeId}
                            narrowerIdsProvider={narrowerIdsProvider}
                            onClickNodeLinkProvider={onClickNodeLinkProvider}
                            resourceIdKey={resourceIdKey}
                        />;
                    })}
                </Collapse>
            </div>
        </div>}
    </Fragment>;


}

export const TreeNode2 = ({node, treeData, path, selectedNodeId, expandedNodeIds, onNodeSelect, onNodeToggle}) => {
    const theme = useTheme();

    const [active, setActive] = useState();

    let narrowerCount = node.childCount;
    const spanWidth = 48;
    const hasSuggestions = false;
    const selected = node.nodeId === selectedNodeId;
    let isExpanded = expandedNodeIds.includes(node.nodeId);
    //console.log('TreeNode2 render', node.nodeId, node.title, selected)

    const getTooltip = () => {
        return <table>{
            path.map((p, i) => <tr key={i}><td style={{paddingLeft : `${16 * i}px`}}>{p}</td></tr>)
        }</table>
    }

    return <Fragment key={isExpanded}>
        <div datatest={'treeNodeContainerDiv'} id={node.nodeId} style={{display: 'flex'}}>

                <span style={{minWidth: spanWidth + 'px'}}>
                     {

                         narrowerCount > 0 &&

                         <IconButton
                             datatest={'expandCollapseButton'}
                             style={{marginTop : '4px'}}
                             disabled={narrowerCount < 1}
                             size={'small'}
                             onClick={() => {
                                 onNodeToggle(node);
                             }}>
                             {

                                 <StyledBadge
                                     datatest={'countBadge'}
                                     badgeContent={narrowerCount}
                                     max={99}
                                 >
                                     { isExpanded ? <ExpandLessOutlined datatest={'collapseIcon'}/> :
                                         <ExpandMoreOutlined datatest={'expandIcon'}/>}
                                 </StyledBadge>
                             }
                         </IconButton>
                     }
                </span>

            <div
                datatest={'nodeDiv-'+node?.title}
                style={{
                    display: 'flex',
                    borderRadius: '4px',
                    marginBottom: '8px',
                    border: '1px solid',
                    borderLeft:'3px solid',
                    borderColor: hasSuggestions? theme.palette.warning.dark : (selected || active ? theme.palette.secondary.light : theme.palette.divider),
                    minWidth: '280px',
                    minHeight: '40px',
                    backgroundColor: selected || active ? lighten(theme.palette.secondary.light, 0.85) : undefined,

                }}
                onClick={() => {}}
                onMouseEnter={() => setActive(true)}
                onMouseLeave={() => setActive(false)}
            >
                <Tooltip title={getTooltip()}>
                    <div onClick={() => {
                        onNodeSelect(node);
                    }} style={{height : '100%', flexGrow : 1, padding: '0px 16px', textDecoration : 'none', }} >
                        {centerVertically(

                            (node.title ? <H3Title id={node.nodeId+"-t"} noWrap={true} style={{ maxWidth : '280px', color : selected || active ? theme.palette.secondary.dark : undefined}}>
                                {node.title}
                            </H3Title> : <Typography noWrap={true} style={{ maxWidth : '280px', color : theme.palette.warning.dark}} variant={'caption'}>{getLocalName(getResourceId(node), true)}</Typography> )
                            , {height : '100%'}

                        )}
                    </div>
                </Tooltip>
            </div>
        </div>
        {isExpanded && <div datatest={'expandedDataDiv'} style={{marginLeft: spanWidth / 2}}>
            <div style={{paddingBottom: 0, paddingTop: 0}} colSpan={2}>
                <Collapse in={isExpanded ? true : false} timeout="auto" unmountOnExit>
                    {toArray(node.children).map((c, i) => {
                        let resourceId = getResourceId(c);
                        return <TreeNode2
                            onNodeToggle={onNodeToggle}
                            onNodeSelect={onNodeSelect}
                            selectedNodeId={selectedNodeId}
                            expandedNodeIds={expandedNodeIds}
                            key={resourceId+'-'+i}
                            path={[...path, c.title||resourceId]}
                            treeData={treeData}
                            node={c}
                        />;
                    })}
                </Collapse>
            </div>
        </div>
        }
    </Fragment>;


}

import {graphSearchByMaps} from "./graph-api";
import {
    ALIAS_SYS_ASSIGNED_TO,
    ALIAS_SYS_AUTHENTICATION, ALIAS_SYS_AUTHORIZATION,
    ALIAS_SYS_CLASS_IRI,
    ALIAS_SYS_CRAETABLE_BY,
    ALIAS_SYS_DELETABLE_BY,
    ALIAS_SYS_HAS_NEXT_PAGE,
    ALIAS_SYS_RESULTS,
    ALIAS_SYS_TYPE_CLASS_BASED_PERMISSION,
    ALIAS_SYS_TYPE_OPERATION_BASED_PERMISSION,
    ALIAS_SYS_TYPE_PERMISSION,
    ALIAS_SYS_TYPE_ROLE,
    ALIAS_SYS_UPDATABLE_BY,
    EASYGRAPH_DATA_AUTHENTICATION_ALL_REQUESTS,
    EASYGRAPH_DATA_AUTHENTICATION_ALL_UPDATES,
    EASYGRAPH_DATA_AUTHENTICATION_DISABLED,
    ID,
    PAGE,
    PAGE_SIZE,
    QUERY,
    TYPE, TYPE_CLASS_BASED_PERMISSION, TYPE_OPERATION_BASED_PERMISSION
} from "../Constants";
import React from "react";
import {canUpdateConfigs, getUserIri} from "../layouts/common/Profile";
import {
    getAliasesMap,
    getAliasToIRIMap,
    getApiConfigurationResource,
    getClassPermissions,
    getContainerData,
    getIdGeneratingClassIRIsSetWithSubclasses,
    getOntologyClasses, getOperationBasedPermissions,
    getRoles,
    toArray
} from "../components/util";
import GlobalsContext from "../components/GlobalsContext";
import {GLOBAL_CONTEXT_PERMISSIONS_SERVICE} from "../layouts/navigator/GlobalContextProvider";

export class PermissionService {
    constructor(allConfigurations) {
        this.permissions =  getClassPermissions(allConfigurations);
        this.operationBasedPermissions =  getOperationBasedPermissions(allConfigurations);
        this.roles = getRoles(allConfigurations);
        this.apiConfiguration = getApiConfigurationResource(allConfigurations);
        let aliasesMap = getAliasesMap(allConfigurations);
        this.aliasesMap = aliasesMap;
        this.aliasesToIRIMap = getAliasToIRIMap(aliasesMap);
        this.instantiableClassesSet = getIdGeneratingClassIRIsSetWithSubclasses(getContainerData(allConfigurations), getOntologyClasses(allConfigurations));
        this.operationBasedAuthorisationEnabled = this.apiConfiguration && this.apiConfiguration[ALIAS_SYS_AUTHORIZATION] && toArray(this.apiConfiguration[ALIAS_SYS_AUTHORIZATION]).includes(TYPE_OPERATION_BASED_PERMISSION);
        this.classBasedAuthorisationEnabled = this.apiConfiguration && this.apiConfiguration[ALIAS_SYS_AUTHORIZATION] && toArray(this.apiConfiguration[ALIAS_SYS_AUTHORIZATION]).includes(TYPE_CLASS_BASED_PERMISSION);

    }

    isAuthenticationEnabled(iri) {
        let authInConfigs = this.apiConfiguration[ALIAS_SYS_AUTHENTICATION];
        return authInConfigs === iri || (iri === EASYGRAPH_DATA_AUTHENTICATION_ALL_REQUESTS && authInConfigs === undefined);
    }

    isAuthenticateAllRequests() {
        return this.isAuthenticationEnabled(EASYGRAPH_DATA_AUTHENTICATION_ALL_REQUESTS);
    }

    isAuthenticateUpdateRequestsOnly() {
        return this.isAuthenticationEnabled(EASYGRAPH_DATA_AUTHENTICATION_ALL_UPDATES);
    }

    isAuthenticationDisabled() {
        return this.isAuthenticationEnabled(EASYGRAPH_DATA_AUTHENTICATION_DISABLED);
    }

    isReadOnlyModeEnabled() {
        return false;
    }

    canDeleteResource(resource) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        let resourceTypeIRIs = toArray(resource[TYPE]).map(t => this.aliasesToIRIMap[t] || t);
        return this.canDeleteAllTypesFrom({resourceTypeIRIs});
    }

    canDeleteAllTypesFrom({resourceTypeIRIs}) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        return resourceTypeIRIs.find(t => this.canDelete({resourceTypeIri : t}) === false) ? false : true;
    }

    canDelete({resourceTypeIri}) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        if(this.instantiableClassesSet.has(resourceTypeIri) === false) {
            return false;
        }
        if(canUpdateConfigs() || this.isAuthenticationDisabled()) {
            return true;
        }
        const userIri = getUserIri();
        let foundRole = this.roles.filter(ro => {
            return toArray(ro[ALIAS_SYS_ASSIGNED_TO]).includes(userIri);
        })
            .map(r => {
                return r[ID];
            })
            .find(r => {
                if(this.operationBasedAuthorisationEnabled && !this.classBasedAuthorisationEnabled) {
                    return this.findOperationBasedDeletePermission(r);
                } else {
                    let foundPermission = this.permissions.filter(p => {
                        return toArray(p[ALIAS_SYS_CLASS_IRI]).includes(resourceTypeIri);
                    }).find(p => {
                        let creatableBy = toArray(p[ALIAS_SYS_DELETABLE_BY]);
                        return creatableBy.includes(r);
                    });
                    return foundPermission;
                }
            });
        return foundRole ? true : false;

    }

    canUpdateResource(resource) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        let resourceTypeIRIs = toArray(resource[TYPE]).map(t => this.aliasesToIRIMap[t] || t);
        return this.canUpdateAnyTypeFrom({resourceTypeIRIs});
    }

    canUpdateAnyTypeFrom({resourceTypeIRIs}) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        return resourceTypeIRIs.find(t => this.canUpdate({resourceTypeIri : t})) ? true : false;
    }

    canUpdate({resourceTypeIri}) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        if(this.instantiableClassesSet.has(resourceTypeIri) === false) {
            return false;
        }
        if(canUpdateConfigs() || this.isAuthenticationDisabled()) {
            return true;
        }
        const userIri = getUserIri();
        let foundRole = this.roles.filter(ro => {
            return toArray(ro[ALIAS_SYS_ASSIGNED_TO]).includes(userIri);
        })
            .map(r => {
                return r[ID];
            })
            .find(r => {
                if(this.operationBasedAuthorisationEnabled && !this.classBasedAuthorisationEnabled) {
                    return this.findOperationBasedUpdatePermission(r);
                } else {
                    let foundPermission = this.permissions.filter(p => {
                        return toArray(p[ALIAS_SYS_CLASS_IRI]).includes(resourceTypeIri);
                    }).find(p => {
                        let creatableBy = toArray(p[ALIAS_SYS_UPDATABLE_BY]);
                        return creatableBy.includes(r);
                    });
                    return foundPermission;
                }
            });
        return foundRole ? true : false;
    }

    findOperationBasedCreatePermission(r) {
        let foundPermission = this.operationBasedPermissions.find(p => {
            let creatableBy = toArray(p[ALIAS_SYS_CRAETABLE_BY]);
            return creatableBy.includes(r);
        });
        return foundPermission;
    }

    findOperationBasedUpdatePermission(r) {
        let foundPermission = this.operationBasedPermissions.find(p => {
            let creatableBy = toArray(p[ALIAS_SYS_UPDATABLE_BY]);
            return creatableBy.includes(r);
        });
        return foundPermission;
    }

    findOperationBasedDeletePermission(r) {
        let foundPermission = this.operationBasedPermissions.find(p => {
            let creatableBy = toArray(p[ALIAS_SYS_DELETABLE_BY]);
            return creatableBy.includes(r);
        });
        return foundPermission;
    }

    canCreate({resourceTypeIri}) {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        if(this.instantiableClassesSet.has(resourceTypeIri) === false) {
            return false;
        }
        if(canUpdateConfigs() || this.isAuthenticationDisabled()) {
            return true;
        }
        const userIri = getUserIri();
        let foundRole = this.roles.filter(ro => {
            return toArray(ro[ALIAS_SYS_ASSIGNED_TO]).includes(userIri);
        })
        .map(r => {
            return r[ID];
        })
        .find(r => {
            if(this.operationBasedAuthorisationEnabled && !this.classBasedAuthorisationEnabled) {
                return this.findOperationBasedCreatePermission(r);
            } else {
                let foundPermission = this.permissions.filter(p => {
                    return toArray(p[ALIAS_SYS_CLASS_IRI]).includes(resourceTypeIri);
                }).find(p => {
                    let creatableBy = toArray(p[ALIAS_SYS_CRAETABLE_BY]);
                    return creatableBy.includes(r);
                });
                return foundPermission;
            }
        });
        return foundRole ? true : false;
    }


    canCreateAnyResourceType() {
        if(this.isReadOnlyModeEnabled()) {
            return false;
        }
        if(canUpdateConfigs() || this.isAuthenticationDisabled()) {
            return true;
        }

        let foundRole = this.roles.filter(ro => toArray(ro[ALIAS_SYS_ASSIGNED_TO]).includes(getUserIri()))
            .map(r => r[ID])
            .find(r => {
                if(this.operationBasedAuthorisationEnabled && !this.classBasedAuthorisationEnabled) {
                    return this.findOperationBasedCreatePermission(r);
                } else if (this.operationBasedAuthorisationEnabled && this.classBasedAuthorisationEnabled) {
                    let foundOperationBasedPermission = this.operationBasedPermissions.find(p => {
                        let creatableBy = toArray(p[ALIAS_SYS_CRAETABLE_BY]);
                        return creatableBy.includes(r);
                    });
                    let foundPermission = this.permissions.find(p => {
                        let creatableBy = toArray(p[ALIAS_SYS_CRAETABLE_BY]);
                        return creatableBy.includes(r);
                    });
                    return foundOperationBasedPermission && foundPermission;
                } else {
                    let foundPermission = this.permissions.find(p => {
                        let creatableBy = toArray(p[ALIAS_SYS_CRAETABLE_BY]);
                        return creatableBy.includes(r);
                    });
                    return foundPermission;
                }
            });
        return foundRole ? true : false;
    }
}

export function withPermissions(WrappedComponent) {

    return class extends React.Component {
        static contextType = GlobalsContext;

        constructor(props) {
            super(props);
        }

        render() {
            let globals = this.context;

            return <WrappedComponent
                {...this.props}
                permissionService={globals?.getGlobalsItem?.(GLOBAL_CONTEXT_PERMISSIONS_SERVICE)}
            />;
        }
    }
}

export async function loadAllPermissions(permissionsCollector = [], page = 1) {
    let paramMap = {
        [QUERY] : `{type(eq:["${ALIAS_SYS_TYPE_PERMISSION}" "${ALIAS_SYS_TYPE_CLASS_BASED_PERMISSION}" "${ALIAS_SYS_TYPE_OPERATION_BASED_PERMISSION}"])}`,
        [PAGE] : page,
        [PAGE_SIZE] : 1000
    }
    let permissions = await graphSearchByMaps(paramMap, undefined);
    let result = await permissions.json();
    result[ALIAS_SYS_RESULTS].forEach(p => permissionsCollector.push(p));
    if(result[ALIAS_SYS_HAS_NEXT_PAGE] === 'true') {
        await loadAllPermissions(permissionsCollector, page + 1)
    }
    return permissionsCollector;
}

export async function loadAllRoles(userId, rolesCollector = [], page = 1) {
    let paramMap = {
        [QUERY] : `{type(eq:["${ALIAS_SYS_TYPE_ROLE}"]) ${ALIAS_SYS_ASSIGNED_TO}(eq:["${userId}"])}`,
        [PAGE] : page,
        [PAGE_SIZE] : 1000
    }

    let roles = await graphSearchByMaps(paramMap);
    let result = await roles.json();
    result[ALIAS_SYS_RESULTS].forEach(p => rolesCollector.push(p));
    if(result[ALIAS_SYS_HAS_NEXT_PAGE] === 'true') {
        await loadAllPermissions(userId, rolesCollector, page + 1)
    }
    return rolesCollector;
}



import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {COUNT_UPTO, PAGE, PAGE_SIZE} from "../../Constants";
import PropTypes from "prop-types";
import {Box, Grid, TextField as OtherTextField} from "@material-ui/core";
import FieldContainer from "../../components/FieldContainer";
import Typography from "@material-ui/core/Typography";
import GlobalsContext from "../../components/GlobalsContext";
import {getUiLabelTranslationFromContext} from "../../components/util";
import {
    UI_LABELS_COUNT_UPTO,
    UI_LABELS_LABEL_COUNT_UPTO_HELP,
    UI_LABELS_PAGE,
    UI_LABELS_PAGE_SIZE
} from "../navigator/UILabel";

class SearchOtherOptions extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let {classes, theme, otherOptions, onChange} = this.props
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.smallWidthPropertyLabelRoot
                }
            }
            : {};

        return (<div datatest={'pagingCountingTabContent'}>
            <Box style={{marginTop:theme.spacing(1)}}>
                <FieldContainer>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <OtherTextField
                                datatest={'textField-Count Upto'}
                                label={getUiLabelTranslationFromContext(this, UI_LABELS_COUNT_UPTO)}
                                margin={"dense"}
                                variant="outlined"
                                fullWidth
                                value={otherOptions[COUNT_UPTO]}
                                onChange={(event) => {
                                    otherOptions[COUNT_UPTO] = event.target.value
                                    onChange(otherOptions)
                                }}
                                InputLabelProps={inputLabelProps}
                            />
                            <Typography variant="body1" color="inherit">{
                                getUiLabelTranslationFromContext(this, UI_LABELS_LABEL_COUNT_UPTO_HELP)
                            }</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <OtherTextField
                                datatest={'textField-Page Size'}

                                label={getUiLabelTranslationFromContext(this, UI_LABELS_PAGE_SIZE)}
                                margin={"dense"}
                                variant="outlined"
                                fullWidth
                                value={otherOptions[PAGE_SIZE]}
                                onChange={(event) => {
                                    otherOptions[PAGE_SIZE] = event.target.value
                                    onChange(otherOptions)
                                }}
                                InputLabelProps={inputLabelProps}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <OtherTextField
                                datatest={'textField-Page'}
                                label={getUiLabelTranslationFromContext(this, UI_LABELS_PAGE)}
                                margin={"dense"}
                                variant="outlined"
                                fullWidth
                                value={otherOptions[PAGE]}
                                onChange={(event) => {
                                    otherOptions[PAGE] = event.target.value
                                    onChange(otherOptions)
                                }}
                                InputLabelProps={inputLabelProps}
                            />
                        </Grid>
                    </Grid>
                </FieldContainer>
            </Box>
        </div>);

    }

}

SearchOtherOptions.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    otherOptions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}

export default withStyles(styles, {withTheme: true})(SearchOtherOptions);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {getAllConfigurations} from "../../service/graph-api";
import {
    computeAllSubClasses,
    computeShapeWithSuperClasses,
    createGraphQLQueryPayload,
    createNodeForView,
    flatten,
    getAliasesMap,
    getAllProperties,
    getAnnotationProperties,
    getContainerData,
    getDatatypeProperties,
    getObjectProperties,
    getOntologyClasses,
    getPropertyTypes,
    getRdfProperties,
    getSearchQuery,
    getShapesData, getUiLabelTranslationFromContext,
    langCodeToSelectValue,
    sort,
    toGraphQL,
} from "../../components/util";
import {
    ALIAS_RDFS_RANGE,
    ALIAS_SH_DATATYPE,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_CLASS_IRI,
    ALIAS_SYS_ETAG,
    API_MODE_GRAPHQL,
    COUNT_UPTO,
    DATA,
    ETAG_VALUE_PAGE,
    FACET,
    HTTP_HEADER_IF_NONE_MATCH,
    HTTP_HEADER_PREFER,
    ID,
    LABEL_PROPERTY_LANG,
    MIXIN,
    PAGE,
    PAGE_SIZE,
    PARAM_LANG,
    PATHS,
    QUERY,
    RDF_LANGSTRING,
    SH_IRI,
    SH_LITERAL,
    SORT_BY,
    TYPE_RDFS_LITERAL
} from "../../Constants";
import './APIPlayground.css';
import SearchFilter, {
    dedupeProperties,
    getDefaultProperties,
    getOperators,
    getOperatorsOptions,
    getSelectedTypeIRIs, ID_AND_TYPE, ID_PROPERTY, TYPE_PROPERTY
} from "./SearchFilter";
import SearchMixin, {
    initialiseConnectedData,
    initialiseFacetData,
    initialiseSortData,
    setClassIRIs
} from "./SearchMixin";
import SearchFacet from "./SearchFacet";
import {debounce, isArray, pickBy} from 'lodash';
import SearchOtherOptions from "./SearchOtherOptions";
import {
    CONDITIONAL_GET,
    CONNECTED_DATA,
    FILTERS,
    LANGUAGE,
    OTHER,
    searchLeftActionBar,
    SORT
} from "../../components/SearchLeftActionBar";
import RequestResponse from "../../components/RequestResponse";
import FieldContainer from "../../components/FieldContainer";
import GlobalsContext from "../../components/GlobalsContext";
import {traceRequestRenderStart, traceSearchBuilderUpdate} from "../../components/Trace";
import uuid4 from "uuid/v4";
import SearchSort from "../../layouts/apiplayground/SearchSort";
import SearchLang from "../../layouts/apiplayground/SearchLang";
import SearchConditionalGet from "../../layouts/apiplayground/SearchConditionalGet";

function getStateFromConfigurations(allConfigurations) {

    let aliasesMap = getAliasesMap(allConfigurations)
    let ontology = getOntologyClasses(allConfigurations)
    let shapes = getShapesData(allConfigurations)

    const langKeysSet=  new Set(flatten(shapes.map(s => s[ALIAS_SH_PROPERTY])
        ).filter(p => p && p[ALIAS_SH_DATATYPE] === RDF_LANGSTRING)
        .map(p => aliasesMap[p[ALIAS_SH_PATH]]))

    let shapesSet = new Set()
    getContainerData(allConfigurations)
        .map(c => [c[ALIAS_SYS_CLASS_IRI], ...computeAllSubClasses(c[ALIAS_SYS_CLASS_IRI], ontology)])
        .forEach(ar => ar.forEach(v => {
            let found = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === v)
            if(found) {
                shapesSet.add(found)
            } else {
                //console.log("Not found "+v)
            }
        }))
    const sortedTree = sort(
        [...shapesSet].map(o => createNodeForView(o, () => { return aliasesMap[o[ALIAS_SH_TARGET_CLASS]];}))
        , 'title'
    )
    let leftView = 'filters'
    return {
        leftView: leftView,
        aliasesMap: aliasesMap,
        treeData: sortedTree,
        loading: false,
        configurations: allConfigurations,
        langKeysSet: langKeysSet
    };
}

export function nonEmptyToUndefinedQuery(query) {
    let q = query !== '{  }' ? query : undefined
    return q;
}

export function getNonEmptySearchQuery(search) {
    let q = getSearchQuery(search)
    return nonEmptyToUndefinedQuery(q);
}

export function updateSearchRequest(searchRequestObject, search = {}, facet = {}, mixin = {}, sort = {}, otherOptions = {}) {
    let lang = otherOptions && otherOptions.lang ? otherOptions.lang.value : undefined
    let labelPropertyLang = facet && facet[LABEL_PROPERTY_LANG] ? facet[LABEL_PROPERTY_LANG].value : undefined

    searchRequestObject.search = search
    searchRequestObject.facet = facet
    searchRequestObject.mixin = mixin
    searchRequestObject.sort = sort
    searchRequestObject.otherOptions = otherOptions

    searchRequestObject.paramMap = {
        [QUERY] : getNonEmptySearchQuery(search),
        [FACET] : toGraphQL(facet),
        [SORT_BY] : toGraphQL(sort),
        [PAGE] : otherOptions[PAGE],
        [PAGE_SIZE] : otherOptions[PAGE_SIZE],
        [COUNT_UPTO] : otherOptions[COUNT_UPTO],
        [PARAM_LANG] : lang,
        [LABEL_PROPERTY_LANG] : labelPropertyLang,
    }
    // remove empty null or undefined params
    searchRequestObject.paramMap = pickBy(searchRequestObject.paramMap, v => v !== null && v !== undefined && v !== '');

    searchRequestObject.headerMap = {}
    if(otherOptions[HTTP_HEADER_IF_NONE_MATCH]) {
        searchRequestObject.headerMap[HTTP_HEADER_IF_NONE_MATCH] = otherOptions[HTTP_HEADER_IF_NONE_MATCH]
    }

    let mixinQuery = toGraphQL(mixin, 'filters', mixin.type === PATHS)
    if(mixinQuery) {
        let mixinType = mixin.type
        searchRequestObject.headerMap[HTTP_HEADER_PREFER] = `return=representation;${MIXIN}="${mixinQuery}"${mixinType ? ";"+DATA+"="+mixinType: ''}`
    }

    if(otherOptions.etagAll && otherOptions.etagAll === true) {
        let prefer = searchRequestObject.headerMap[HTTP_HEADER_PREFER]
        searchRequestObject.headerMap[HTTP_HEADER_PREFER] = (prefer ? prefer+',' : '')+`${ALIAS_SYS_ETAG}=${ETAG_VALUE_PAGE}`
    }
    let searchQuery = createGraphQLQueryPayload(searchRequestObject)
    if(!searchRequestObject.hasManualGraphQLQueryEdit || searchRequestObject.hasManualGraphQLQueryEdit === false) {
        searchRequestObject.query = searchQuery.query
    }
    if(!searchRequestObject.hasManualGraphQLVariablesEdit || searchRequestObject.hasManualGraphQLVariablesEdit === false) {
        searchRequestObject.variables = searchQuery.variables
    }

}

export function initialiseSearchRequestOptions() {
    return {
        q: '{  }',
        search : {
            textSearch: {},
            filters: []
        },
        mixin:  initialiseConnectedData(),
        facet : [],
        otherOptions : {},
    } ;
}

function isObjectInitialised(object) {
    return object && Object.keys(object).length > 0;
}

export function buildSearchRequestOptions(searchRequestObject = {}) {

    let searchOptions = {
        q: '{  }',
        search : isObjectInitialised(searchRequestObject.search)
            ? searchRequestObject.search
            : {
                textSearch: {},
                filters: []
            },
        mixin:  isObjectInitialised(searchRequestObject.mixin) ? searchRequestObject.mixin : initialiseConnectedData(),
        sort:  isObjectInitialised(searchRequestObject.sort) ? searchRequestObject.sort : initialiseSortData(),
        facet : isObjectInitialised(searchRequestObject.facet) ? searchRequestObject.facet : initialiseFacetData(),
        otherOptions : searchRequestObject.otherOptions ? searchRequestObject.otherOptions : {}
    };
    return searchOptions;
}

export function propertyAliasNameToSelectOption(configurations, propertyAliasOrIRI) {
    let properties = getDefaultProperties(configurations);
    let aliasesMap = getAliasesMap(configurations)
    let propertyIRI = aliasesMap[propertyAliasOrIRI] ? aliasesMap[propertyAliasOrIRI] : propertyAliasOrIRI
    return properties.find(p => {
        return p.label === propertyIRI
    });
}

export function addFilter(configurations , search, propertyNameAlias, operator, value, lang) {
    let property = propertyAliasNameToSelectOption(configurations, propertyNameAlias)
    let operatorOption = getOperatorsOptions(getOperators()).find(o => o.label === operator)
    let langValue = (lang ? (isArray(lang) ? lang.map(l => langCodeToSelectValue(l)) : langCodeToSelectValue(lang)) : undefined)
    let filter = {
        id : uuid4(),
        property: property,
        operator: operatorOption,
    }
    if(value) {
        filter.value = value
    }
    if(langValue) {
        //Only one language is allowed so pick first
        filter.lang = langValue[0];
    }
    search.filters.push(filter)
    return filter;
}

export function addConnectedFilter(configurations , parentFilter, propertyNameAlias, operator, value, lang) {
    let aliasesMap = getAliasesMap(configurations)
    let propertyOptions = computePropertyOptions(parentFilter.property.value, aliasesMap, configurations)
    let property = propertyAliasNameToSelectOption(configurations, propertyNameAlias)
    let operatorOption = getOperatorsOptions(getOperators()).find(o => o.label === operator)
    let langValue = (lang ? (isArray(lang) ? lang.map(l => langCodeToSelectValue(l)) : langCodeToSelectValue(lang)) : undefined)
    let filter = {
        id : uuid4(),
        propertyOptions : propertyOptions,
        property: property,
        operator: operatorOption,
    }
    if(value) {
        filter.value = value
    }
    if(langValue) {
        filter.lang = langValue
    }
    parentFilter.value.push(filter)
    return filter;
}

export function computePropertyOptions(shapeProperty, aliasesMap, configurations, addType, addId, customizations) {
    const allClasses = getPropertyTypes(shapeProperty, configurations);
    return computePropertyOptionsForClassIRIs(allClasses, aliasesMap, configurations, undefined, undefined, customizations);
}

export function computePropertyOptionsForClassIRIs(allClassIRIs, aliasesMap, configurations, addType, addId, customizations) {
    const shapes = getShapesData(configurations)
    const ontology = getOntologyClasses(configurations)
    //when no class it means nodeKind is IRI
    const shapeObjs = allClassIRIs.length > 0
        ? shapes.filter(o => allClassIRIs.includes(o[ALIAS_SH_TARGET_CLASS]))
        : shapes;
    let propertyOptions =[]
    shapeObjs.forEach(shapeObj => {
        let computedShape = computeShapeWithSuperClasses(shapeObj, shapes, ontology)
        let properties = computedShape[ALIAS_SH_PROPERTY] && isArray(computedShape[ALIAS_SH_PROPERTY])
            ? computedShape[ALIAS_SH_PROPERTY]
            : (computedShape[ALIAS_SH_PROPERTY] ? [computedShape[ALIAS_SH_PROPERTY]] : [])
        properties.forEach(p => propertyOptions.push(p))
    })
    let optionsFromOntology = computePropertyOptionsFromOntology(configurations, undefined, customizations);
    optionsFromOntology.forEach(o => propertyOptions.push(o));
    if(addId) {
        propertyOptions.push(ID_PROPERTY);
    }
    if(addType) {
        propertyOptions.push(TYPE_PROPERTY);
    }
    propertyOptions = dedupeProperties(propertyOptions, aliasesMap)
    return propertyOptions;
}

export function computePropertyOptionsFromOntology(configurations, objectOnly = false, customizations) {
    let allProperties = [];
    let allPropertiesSet = new Set(allProperties);
    let propertyOptions = [];
    let objectProperties = getObjectProperties(configurations);
    objectProperties.forEach(p => {
       if(allPropertiesSet.has(p[ID])) {
           // do nothing
       } else {
           allPropertiesSet.add(p[ID]);
           propertyOptions.push({
               [ID] : p[ID],
               [ALIAS_SH_PATH] : p[ID],
               [ALIAS_SH_NODE_KIND] : SH_IRI
           });
       }
    });

    if(objectOnly === false) {
        const rdfProperties = getRdfProperties(configurations).filter(p => {
            //By default filter
            if(customizations?.rdfPropertiesRangeLiteralOnly === undefined || customizations?.rdfPropRangeLiteralOnly === true) {
                return p[ALIAS_RDFS_RANGE] === TYPE_RDFS_LITERAL;
            } else {
                return true;
            }
        });
        let literalProperties = [
            ...getDatatypeProperties(configurations),
            ...getAnnotationProperties(configurations),
            ...rdfProperties
        ];
        literalProperties.forEach(p => {
            if (allPropertiesSet.has(p[ID])) {
                // do nothing
            } else {
                allPropertiesSet.add(p[ID]);
                propertyOptions.push({
                    [ID]: p[ID],
                    [ALIAS_SH_PATH]: p[ID],
                    [ALIAS_SH_NODE_KIND]: SH_LITERAL
                });
            }
        });
    }
    return propertyOptions;
}

const COMPONENT = "SearchRequest"
class SearchRequest extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        let {searchRequestObject} = this.props

        this.state = {
            treeData:[],
            loading: false,
            ...buildSearchRequestOptions(searchRequestObject),
            resTabValue : 2,
            reqTabValue : 0,
            patchResTabValue : 0,
            patchReqTabValue : 2,
            leftMenuMinimized: false,
        }
        this.search = debounce(this.search, 200);

    }

    componentDidMount() {
        let {aliasesMap} = this.state
        //Fetch configurations only if not provided
        if(!aliasesMap) {
            this.syncDataWithBackend()
        }
    }

    syncDataWithBackend = () => {
        this.setState({loading: true})
        let globals = this.context
        let allConfigurations = globals.allConfigurations
        if(allConfigurations) {
            this.setState(getStateFromConfigurations(allConfigurations))
        } else {
            const configurations = getAllConfigurations()
            configurations.then((allConfigurations) => {
                this.setState(getStateFromConfigurations(allConfigurations))
            })
        }
    }

    search = () => {
        let {search, facet, mixin, sort, otherOptions} = this.state;
        let {searchRequestObject} = this.props;
        updateSearchRequest(searchRequestObject, search, facet, mixin, sort, otherOptions)

        if(!searchRequestObject.dirty) {
            searchRequestObject.dirty = true
            this.setState({})
        }
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        return getUiLabelTranslationFromContext(this, key, defaultValue);
    }

    getSearchBuilder = () => {
        let {leftView} = this.state
        let {searchRequestObject} = this.props
        let {theme} = this.props
        let disabled = searchRequestObject.mode === API_MODE_GRAPHQL.value
            ? ['mixin']
            : [];

        return <FieldContainer  style={{padding : '8px', backgroundColor : theme.palette.white.main}}>
                <div datatest={'tabsBar'}>{searchLeftActionBar(theme, leftView, (state) => this.setState(state) , disabled, this.getUiLabelTranslationFor)}</div>
                <div>{this.getSearchParamsBuilder()}</div>
            </FieldContainer>;
    }

    getSearchParamsBuilder = () => {
        const {leftView, search, facet, mixin, sort, otherOptions, aliasesMap, configurations} = this.state;
        switch (leftView) {
            case CONNECTED_DATA:
                return <SearchMixin configurations={configurations} aliasesMap={aliasesMap}
                             onChange={(mixin) => this.setState({mixin: mixin}, this.search)}
                             mixin={mixin}></SearchMixin>
            case FILTERS :
                return <SearchFilter configurations={configurations} aliasesMap={aliasesMap}
                                     search={search}
                                     onChange={(search) => {
                                         traceSearchBuilderUpdate(() => {console.log(search)}, COMPONENT)
                                         let selectedTypeIRIs = getSelectedTypeIRIs(search, aliasesMap);
                                         setClassIRIs(mixin, selectedTypeIRIs);
                                         if(mixin.filters) {
                                             mixin.filters.forEach(f => {
                                                 f.parentClassIRIs = selectedTypeIRIs;
                                             })
                                         }
                                         this.setState({search: search}, () => {
                                             this.search()
                                         })
                                     }}></SearchFilter>;
            case FACET :
                return <SearchFacet configurations={configurations} aliasesMap={aliasesMap}
                                    onChange={(facet) => this.setState({facet: facet}, this.search)}
                                    facet={facet} />
            case SORT :
                return <SearchSort configurations={configurations} aliasesMap={aliasesMap}
                                    onChange={(sort) => this.setState({sort: sort}, this.search)}
                                    sort={sort} />
            case OTHER :
                return <SearchOtherOptions sort={sort}  configurations={configurations} aliasesMap={aliasesMap}
                                    onChange={(otherOptions) => this.setState({otherOptions: otherOptions}, this.search)}
                                    otherOptions={otherOptions} />
            case LANGUAGE :
                return <SearchLang sort={sort}  configurations={configurations} aliasesMap={aliasesMap}
                                           onChange={(otherOptions) => this.setState({otherOptions: otherOptions}, this.search)}
                                           otherOptions={otherOptions} />
            case CONDITIONAL_GET :
                return <SearchConditionalGet sort={sort}  configurations={configurations} aliasesMap={aliasesMap}
                                           onChange={(otherOptions) => this.setState({otherOptions: otherOptions}, this.search)}
                                           otherOptions={otherOptions} />

            default:
                return null;
        }

    }

    handleSaveRequest = () => {
        const {onSave, searchRequestObject} = this.props
        onSave(searchRequestObject)
    }

    setDirty = () => {
        let {searchRequestObject} = this.props
        if(!searchRequestObject.dirty) {
            searchRequestObject.dirty = true
            this.setState({})
        }
    }

    onModeChange = (obj) => {
        let {onRequestObjectChange} = this.props
        this.setDirty();
        //update state to ensure that components are re-rendered for new mode
        this.setState({}, () => (onRequestObjectChange && onRequestObjectChange(obj)))
    }

    getRequestResponse = () => {
        let {searchRequestObject, customizations, registerSendAction, onRequestObjectChange} = this.props
        return <RequestResponse
            key={searchRequestObject[ID]}
            requestBodyBuilder={this.getSearchBuilder}
            reqTabValue={0}
            requestObject={searchRequestObject}
            onRequestSave={this.handleSaveRequest}
            onModeChange={this.onModeChange}
            onRequestObjectChange={ (obj) => {this.setDirty(); onRequestObjectChange && onRequestObjectChange(obj)}}
            registerSendAction={registerSendAction}
            customizations={customizations}
        />;
    }

    render() {
        //If not configuration then do nothing
        if(!this.state.configurations) {
            return <></>;
        }

        //Render in Above condition is not even worth mentioning
        let {searchRequestObject} = this.props
        traceRequestRenderStart(searchRequestObject, 'SearchRequest')

        return (<>
            {this.getRequestResponse()}
        </>);
    }

}

SearchRequest.propTypes = {
    onSave: PropTypes.func,
    registerSendAction: PropTypes.func,
    exampleSetId: PropTypes.string,
    apiMode: PropTypes.object,
    aliasesMap: PropTypes.object,
    searchRequestObject: PropTypes.object,
    onRequestObjectChange: PropTypes.func,
    customizations: PropTypes.object,

};

export default withStyles(styles, {withTheme: true})(SearchRequest);

import React from 'react';
import PropTypes from 'prop-types';
import {FormControlLabel, Grid, Typography, withStyles} from '@material-ui/core';
import InstructionForAction from "./InstructionForAction";
import {
    ALIAS_RDFS_LABEL,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    FROM_SHAPE,
    ID,
    MESSAGE_SELECT_TO_VIEW_DETAILS,
    VIEW_MIDDLE_CARDS
} from "../Constants";
import DetailsTitle from "./DetailsTitle";
import {
    centerVertically,
    computeShapeWithSuperClasses,
    distinct,
    getProp,
    getPropertyArray,
    searchInTree,
    sort
} from "./util";
import TextField from "./TextField";
import Switch from "@material-ui/core/Switch";
import HelpIcon from "./HelpIcon";
import SHACLPropertyMocking from "../components/SHACLPropertyMocking";
import H2Title from "../components/H2Title";
import {isArray} from 'lodash';


class ShapeMockingDetails extends React.Component {
    constructor(props) {
      super(props);
      this.state = {}
    }

  getSuperClassPropsSwitch = () => {
    let {addSuperClassPropsInShape, onAddSuperClassPropsInShapeChange, theme} = this.props
    return  <FormControlLabel
            control={
                <Switch
                    checked={(addSuperClassPropsInShape && addSuperClassPropsInShape === true ? true : false)}
                    value={true}
                    size={"small"}
                    onChange={(e) => {
                        const {target: {checked}} = e
                        onAddSuperClassPropsInShapeChange(checked === true ? true: false)
                    }}
                    name="addSuperClassPropsInShape"
                />
            }
            label={<Typography style={{...theme.typography.caption}} variant={'caption'} component={'span'}>Include Details from Class Hierarchy</Typography>}
        />;
  }

  isMiddleViewCards = () => {
    let {columnAction} = this.state
    return !columnAction || columnAction === VIEW_MIDDLE_CARDS;
  }

  removeProperty = (shape, path) => {
    let newShape = shape
    if (isArray(shape[ALIAS_SH_PROPERTY])) {
      newShape[ALIAS_SH_PROPERTY] = shape[ALIAS_SH_PROPERTY].filter(v => v[ALIAS_SH_PATH] !== path)
    } else if (shape[ALIAS_SH_PROPERTY][ALIAS_SH_PATH] === path) {
      delete newShape[ALIAS_SH_PROPERTY]
    }
    this.props.backendEventHandler({
      action: "saveJSON",
      data: newShape
    }).then((r) => {
    })
  }

  getPropertiesByShape = (properties, classes, focusNode) => {
      let {aliasesMap, allShapes, ontology} = this.props
      let shapeIds = properties.filter(p => p[FROM_SHAPE] && p[FROM_SHAPE] !== focusNode[ID]).map(p => p[FROM_SHAPE]).filter(distinct)
    return shapeIds.map(id => {
      let shapeNode = searchInTree(id, allShapes)
      return <React.Fragment key={id}>
        <Grid item xs={12}>
          <H2Title title={getProp(shapeNode.backingObject, ALIAS_RDFS_LABEL)}/>
        </Grid>
        <Grid item xs={12}>
          <TextField value={"" + id} label="IRI" readOnly={true}/>
        </Grid>
        <Grid item xs={12}>
          <TextField tooltip={shapeNode.backingObject[ALIAS_SH_TARGET_CLASS]}
                     value={aliasesMap[shapeNode.backingObject[ALIAS_SH_TARGET_CLASS]]} label="Target Class"
                     readOnly={true}/>
        </Grid>
        {
          sort(properties.filter(p => p[FROM_SHAPE] === id), 'path').map((p, i) => {
            return <SHACLPropertyMocking
                key={p[ID]+'-'+i}
                aliasesMap={aliasesMap}
                classes={classes}
                focusNode={focusNode}
                property={p}
                allowDelete={false}
                mockingSetupObject={this.getMockingObject(p)}
                ontology={ontology}
            />;
          })
        }</React.Fragment>;
    });
  }


  getDetails = (focusNode) => {
    let {classes, theme, aliasesMap, helpText, allShapes, ontology, addSuperClassPropsInShape, isReadOnly, backendEventHandler} = this.props
    let jsonObj = focusNode
        ? (addSuperClassPropsInShape ? computeShapeWithSuperClasses(focusNode.backingObject, allShapes.map(o => o.backingObject), ontology) : focusNode.backingObject)
        : JSON.parse("{}")
    let json = JSON.stringify(jsonObj, null, 4)
    let properties = getPropertyArray(jsonObj)

    let titleActions = [
      <div style={{paddingTop: '4px'}}>{this.getSuperClassPropsSwitch()}</div>
    ]

    if(helpText) {
        titleActions.push(<HelpIcon helpText={helpText}/>)
    }
    return (<>
        <div style={{display: 'flex'}}>
            {
                (jsonObj || helpText)
                && centerVertically(<H2Title>{jsonObj ? getProp(jsonObj, ALIAS_RDFS_LABEL) : ''}</H2Title>)
            }
            <div style={{flexGrow: '1'}}></div>
            {
                titleActions && titleActions.map((a, i) => <div style={{minHeight : '36px'}} key={i}>{a}</div>)
            }
        </div>
        <Grid container spacing={1} xs={12}>
          <Grid item xs={12}>{
            jsonObj &&
            <TextField value={"" + jsonObj.id} label="Shape IRI" readOnly={true}/>
          }</Grid>
          <Grid item xs={12}>{
            jsonObj &&
            <TextField  value={jsonObj[ALIAS_SH_TARGET_CLASS]} label="Target Class IRI" readOnly={true}/>
          }</Grid>
          {
              sort(getPropertyArray(focusNode.backingObject), 'path')
                  .filter(p => !p[FROM_SHAPE])
                  .map((p, i) => {
                    return <SHACLPropertyMocking
                        key={p[ID]+'-'+i}
                        aliasesMap={aliasesMap}
                        allowEdit={!isReadOnly}
                        key={p[ALIAS_SH_PATH]}
                        classes={classes}
                        focusNode={focusNode}
                        property={p}
                        allowDelete={!isReadOnly}
                        ontology={ontology}
                        deleteHandler={this.removeProperty}
                        eventHandler={backendEventHandler}
                        mockingSetupObject={this.getMockingObject(p)}
                    />;
              })
          }
          { this.isMiddleViewCards() && addSuperClassPropsInShape && this.getPropertiesByShape(properties, classes, focusNode) }
        </Grid>
    </>);
  }

    getMockingObject = (p) => {
        let {mockingSetupObjects} = this.props
        if(mockingSetupObjects) {
            return mockingSetupObjects.find(o => o.propertyIRI === p[ALIAS_SH_PATH]);
        } else {
            return undefined;
        }
    }

  render() {
     let {shapeIRI, helpText, allShapes} = this.props
      let focusNode = allShapes.find(o => o.id === shapeIRI)
      let titleActions = []
      if(helpText) {
          titleActions.push(<HelpIcon helpText={helpText}/>)
      }

      return (<div>
          {!focusNode && <>
            {helpText && <Grid container spacing={1} xs={12}>
                    <Grid item xs={12}><DetailsTitle title={''} actions={titleActions}/>
                    </Grid>
                </Grid>
            }
            <InstructionForAction text={MESSAGE_SELECT_TO_VIEW_DETAILS}/>
            </>
          }
          {focusNode && this.getDetails(focusNode)}
        </div>);
    }

}


ShapeMockingDetails.propTypes = {
    shapeIRI: PropTypes.string,
    allShapes: PropTypes.array,
    addSuperClassPropsInShape: PropTypes.bool,
    onAddSuperClassPropsInShapeChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
    ontology: PropTypes.array,
    aliasesMap: PropTypes.object,
    backendEventHandler: PropTypes.func,
    helpText: PropTypes.array,
    mockingSetupObjects: PropTypes.array,
};

export default withStyles({}, {withTheme: true})(ShapeMockingDetails);

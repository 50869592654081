import React from 'react';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core";
import {styles} from "../styles";
import MainAppBar from "./MainAppBar";
import Header from "./Header";

export function getHeader(title, isAccountProfile, rightButtonsRenderer, homeLink, logoSectionRenderer, userPopperContent, expandRightToCenterSpace, toolbarStyle, disableProfile = false) {
    return (<Header collapsible={false} components={
            [
                <MainAppBar
                    userPopperContent={userPopperContent}
                    logoSectionRenderer={logoSectionRenderer}
                    homeLink={homeLink}
                    rightButtonsRenderer={rightButtonsRenderer}
                    isAccountProfile={isAccountProfile}
                    key={'main-app-bar'}
                    titleStyle={{marginLeft: '48px'}}
                    toolbarStyle={toolbarStyle}
                    title={title || 'API Playground'}
                    expandRightToCenterSpace={expandRightToCenterSpace}
                    disableProfile={disableProfile}
                />
            ]
        }/>
    );
}

class APIPlaygroundHeader extends React.Component {
    constructor(props) {
        super(props);
        this.state = {collapse: true}
    }

    render() {
        return getHeader();
    }

}

APIPlaygroundHeader.propTypes = {
    location: PropTypes.object.isRequired,
    rightButtonsRenderer: PropTypes.func,
    logoSectionRenderer: PropTypes.func
};

export default withStyles(styles, {withTheme: true})(APIPlaygroundHeader);

import React from "react";
import PropTypes from "prop-types";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import H2Title from "./H2Title";
import {STYLE_GRID_ITEM_SPACING} from "../Constants";
import withStyles from "@material-ui/core/styles/withStyles";

function ConfirmDelete(props) {
  const {theme, primaryButton, open, data, handleCancel, handleOk, title, okButtonTitle, cancelButtonTitle} = props
  let leftButtonTitle = primaryButton === 'cancel'
      ? okButtonTitle ? okButtonTitle : 'Delete'
      : cancelButtonTitle ? cancelButtonTitle:  'Cancel';
  let rightButtonTitle = primaryButton === 'cancel'
      ? cancelButtonTitle ? cancelButtonTitle:  'Cancel'
      : okButtonTitle ? okButtonTitle : 'Delete';

  return (<React.Fragment>{
    open && <Dialog
        open={open}
        aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title"><H2Title title={title ? title : 'Are you sure you want to Delete?'}/></DialogTitle>
      {data && <DialogContent>
        <DialogContentText>{data.value}</DialogContentText>
      </DialogContent>}
      <DialogActions>
        <Button datatest={'confirmDelete-'+leftButtonTitle} variant={"outlined"} onClick={() => {
          primaryButton === 'cancel' ? handleOk(data) : handleCancel();
        }} color="secondary">{leftButtonTitle}</Button>
        <Button datatest={'confirmDelete-'+rightButtonTitle} style={{marginLeft: theme.spacing(STYLE_GRID_ITEM_SPACING)}} variant={"contained"} color="secondary" onClick={() => {
          primaryButton === 'cancel' ?  handleCancel() : handleOk(data);
        }}>{rightButtonTitle}</Button>
      </DialogActions>
    </Dialog>
  }</React.Fragment>);
}

ConfirmDelete.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  primaryButton: PropTypes.string,
  okButtonTitle: PropTypes.string,
  cancelButtonTitle: PropTypes.string,
  data: PropTypes.object,
  handleCancel: PropTypes.func.isRequired,
  handleOk: PropTypes.func.isRequired,
};

export default withStyles({}, {withTheme:true})(ConfirmDelete);

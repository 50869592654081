import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import H2Title from "../../components/H2Title";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
    centerVertically, flatten,
    getUiLabelTranslationFromContext,
    isArrayOnly,
    isObjectOnly,
    toArray
} from "../../components/util";
import {InputLabel, MenuItem, TextField, Typography} from "@material-ui/core";
import {
    getUiLabelTranslation,
    UI_LABELS_CANCEL,
    UI_LABELS_DOWNLOAD,
    UI_LABELS_DOWNLOAD_FORMAT,
    UI_LABELS_DOWNLOAD_FORMAT_CSV,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_ADD_DATATYPE_AND_LANGUAGE,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE,
    UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB,
    UI_LABELS_DOWNLOAD_FORMAT_TURTLE,
    UI_LABELS_DOWNLOAD_FORMAT_TURTLE_INFORMATION,
    UI_LABELS_DOWNLOAD_LIMIT
} from "./UILabel";
import Select from "@material-ui/core/Select";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import {ALIAS_SH_PATH, ID, LANG, TYPE, VALUE, XSD_STRING} from "../../Constants";
import {isString} from "lodash";
import {isLangProperty} from "../apiplayground/SearchFilter";
import fileDownload from "js-file-download";
import {isObjectTypeValueOrProperty} from "./DataGridView";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = {
    dialogPaper: {
    },
};

export const quoteMark = '"';
export const doubleQuoteMark = '""';
export const quoteRegex = /"/g;
export const newLine = "\r\n";
export const newLineOnly = "\n";
const escape = /["\\\t\n\r\b\f\u0000-\u0019\ud800-\udbff]/;
const escapeAll = /["\\\t\n\r\b\f\u0000-\u0019]|[\ud800-\udbff][\udc00-\udfff]/g;
const escapedCharacters = {
        '\\': '\\\\', '"': '\\"', '\t': '\\t',
        '\n': '\\n', '\r': '\\r', '\b': '\\b', '\f': '\\f',
    };

export function encodeValues(values, delimiter) {
    let row = toArray(values);
    return row.map(rv => {
        let returnValue = rv;
        if(isString(returnValue)) {
            if (returnValue.indexOf(quoteMark) !== -1) {
                returnValue = returnValue.replace(quoteRegex, doubleQuoteMark);
            }
            if (returnValue.indexOf(quoteMark) !== -1 || returnValue.indexOf(delimiter) !== -1 || returnValue.indexOf(newLine) !== -1 || returnValue.indexOf(newLineOnly) !== -1) {
                returnValue = quoteMark + returnValue + quoteMark;
            }
            return returnValue;
        } else {
            console.log(returnValue)
            throw new Error(returnValue);
        }
    }).join(delimiter);
}

export function downloadCSV(sheets, sheetTabValue, options, rows, aliasesMap, ontology) {
    let sheet = sheets[sheetTabValue];
    let columnLabels = sheet.sheetColumns.map(cl => cl.columnLabel);
    let headerValuesData = encodeValues(columnLabels, options.delimiter);

    let sheetData = rows.map(row => {
        let grid = [];
        const arr = new Array(sheet.sheetColumns.length).fill("");
        grid[0] = arr;

        sheet.sheetColumns.forEach((cl, colIndex) => {
            let propertyIRI = cl[ALIAS_SH_PATH];
            let propertyAlias = aliasesMap[cl[ALIAS_SH_PATH]];
            let value = row[propertyAlias] || row[propertyIRI];
            if (!value) {
                grid[0][colIndex] = "";
            } else if (isString(value)) {
                grid[0][colIndex] = value;
            } else if (isObjectTypeValueOrProperty(value, ontology, {property: cl})) {
                if (isArrayOnly(value)) {
                    let stringValues = value.map(v => isString(v) ? v : getObjectValue(v, options));
                    setGridValues(stringValues, grid, colIndex);
                }
                if (isObjectOnly(value)) {
                    grid[0][colIndex] = value[ID] || value;
                }
            } else if (isLangProperty(cl) && isObjectOnly(value)) {
                let stringValues = Object.keys(value).map((lang) => {
                    let langValue = value[lang];
                    return toArray(langValue).map(v => {
                        if (options.addDatatypeAndLanguage) {
                            return serialiseWithLangAndDatatype(v, undefined, lang);
                        }
                        return v;
                    });
                });
                setGridValues(stringValues, grid, colIndex);
            } else if (isArrayOnly(value)) {
                let stringValues = value.map(v => {
                    if (isString(v)) {
                        return v;
                    }
                    if (isObjectOnly(v)) {
                        return getObjectValue(v, options);
                    }
                });
                setGridValues(stringValues, grid, colIndex);
            } else if (isObjectOnly(value)) {
                grid[0][colIndex] = getObjectValue(value, options);
            } else {
                console.log('Unknown value type', value);
            }

        });
        let cellValuesData = grid.map(r => encodeValues(r, options.delimiter));
        return cellValuesData;
    });
    let allSheetData = [headerValuesData, ...flatten(sheetData)].join(newLine);
    fileDownload(allSheetData, sheetTabValue + '_' + new Date().toISOString() + ".csv")
}

function setGridValues(stringValues, grid, colIndex) {
    flatten(stringValues).forEach((sv, rowIndex) => {
        if (grid[rowIndex] === undefined) {
            grid[rowIndex] = new Array(grid[0].length).fill("");
        }
        grid[rowIndex][colIndex] = sv;
    })
}

function getObjectValue(v, options) {
    let lang = v[LANG];
    let type = v[TYPE];
    let value = v[VALUE] || v[ID];
    if(options.addDatatypeAndLanguage) {
        if(v[ID]) {
            return `<${v[ID]}>`;
        }
        return serialiseWithLangAndDatatype(value, type, lang);
    }
    return value;
}

function serialiseWithLangAndDatatype(value, type, lang) {
    let s = encodeLiteral({value, datatype : {value : type}, language : lang });
    return s;
}

function characterReplacer(character) {
    // Replace a single character by its escaped version
    let result = escapedCharacters[character];
    if (result === undefined) {
        // Replace a single character with its 4-bit unicode escape sequence
        if (character.length === 1) {
            result = character.charCodeAt(0).toString(16);
            result = '\\u0000'.substr(0, 6 - result.length) + result;
        }
        // Replace a surrogate pair with its 8-bit unicode escape sequence
        else {
            result = ((character.charCodeAt(0) - 0xD800) * 0x400 +
                character.charCodeAt(1) + 0x2400).toString(16);
            result = '\\U00000000'.substr(0, 10 - result.length) + result;
        }
    }
    return result;
}

function encodeLiteral(literal) {

    // Escape special characters
    let value = literal.value;
    if (escape.test(value))
        value = value.replace(escapeAll, characterReplacer);

    // Write a language-tagged literal
    if (literal.language)
        return `"${value}"@${literal.language}`;

    // Only abbreviate strings in N-Triples or N-Quads
    if (literal.datatype.value === XSD_STRING)
        return `"${value}"`;
    let datatype = literal.datatype.value;
    if (escape.test(datatype))
        datatype = datatype.replace(escapeAll, characterReplacer);
    // Write a regular datatyped literal
    return `"${value}"^^<${datatype}>`;
}

class DownloadDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            format : UI_LABELS_DOWNLOAD_FORMAT_TURTLE,
            limit : 1000,
            delimiter : ',',
            addDatatypeAndLanguage : false
        }
    }

    apply = () => {
        let {format, limit, delimiter, addDatatypeAndLanguage, customDelimiter} = this.state;
        let delimiterToUse = delimiter === UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM ? customDelimiter : delimiter;
        this.setState({loading : true})
        this.props.onDownload({
            format, limit, delimiter : delimiterToUse  , addDatatypeAndLanguage
        });
    }

    render() {
        let {onClose, classes, settings, browseLanguage} = this.props;
        let {format, limit, delimiter, addDatatypeAndLanguage, customDelimiter, loading} = this.state;

        let widthStyle = {minWidth: '160px', maxWidth: '160px'};
        return <>
            {
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={true}
                    datatest={'downloadDialog'}
                    classes={{ paper: classes.dialogPaper }}
                    onKeyDown={(ev) => {
                        if(ev.ctrlKey && ev.key === 'Enter') {
                            this.apply();
                        } else if (ev.key === 'Escape') {
                            onClose();
                        } else if(ev.key === 'Enter') {
                            //Only stop propagation but do not preventDefault as we want to add new line in text field
                            ev.stopPropagation();
                        }
                    }}

                >
                    <DialogTitle disableTypography={true} id="form-dialog-title">
                        <div style={{display: 'flex'}}>
                            {centerVertically(<H2Title title={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD, browseLanguage, UI_LABELS_DOWNLOAD) }/>)}
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container xs={12} spacing={3}>
                            <Grid item xs={12}>
                                <FormControl style={widthStyle}
                                             size={'small'} variant="outlined">
                                    <InputLabel htmlFor="outlined-layout">{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT)}</InputLabel>
                                    <Select
                                        id={"outlined-layout"}
                                        datatest={'format'}
                                        value={format}
                                        label={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT)}
                                        onChange={(event) => {
                                            this.setState({format :  event.target.value})
                                        }}
                                        inputProps={{
                                            id: 'outlined-layout'
                                        }}
                                    >
                                        <MenuItem value={UI_LABELS_DOWNLOAD_FORMAT_TURTLE}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_TURTLE, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_TURTLE)}</MenuItem>
                                        <MenuItem value={UI_LABELS_DOWNLOAD_FORMAT_CSV}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV)}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {
                                format === UI_LABELS_DOWNLOAD_FORMAT_CSV && <>
                                    <Grid item xs={4}>
                                        {
                                            centerVertically(
                                                <FormControl style={widthStyle}
                                                             size={'small'} variant="outlined">
                                                    <InputLabel htmlFor="outlined-layout">{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR)}</InputLabel>
                                                    <Select
                                                        id={"outlined-layout"}
                                                        datatest={'separator'}
                                                        value={delimiter}
                                                        label={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR)}
                                                        onChange={(event) => {
                                                            this.setState({delimiter :  event.target.value})
                                                        }}
                                                        inputProps={{
                                                            id: 'outlined-layout'
                                                        }}
                                                    >
                                                        <MenuItem value={','}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_COMMA)}</MenuItem>
                                                        <MenuItem value={'\t'}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_TAB)}</MenuItem>
                                                        <MenuItem value={UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM}>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM)}</MenuItem>
                                                    </Select>
                                                </FormControl>
                                                , {height : '100%'}
                                            )
                                        }

                                    </Grid>
                                    <Grid item xs={8}>
                                        {
                                            centerVertically(
                                            <FormControlLabel
                                                style={{marginLeft: '1px'}}
                                                control={
                                                    <Switch
                                                        datatest={'addDatatypeAndLanguage'}
                                                        size="small"
                                                        checked={addDatatypeAndLanguage}
                                                        name="addDatatypeAndLanguage"
                                                        onChange={() => {
                                                            this.setState({addDatatypeAndLanguage: !addDatatypeAndLanguage})
                                                        }}
                                                    />
                                                }
                                                label={getUiLabelTranslationFromContext(this, UI_LABELS_DOWNLOAD_FORMAT_CSV_ADD_DATATYPE_AND_LANGUAGE)}
                                            />, {height : '100%'}
                                            )
                                        }

                                    </Grid>
                                    {
                                        delimiter === UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM &&
                                            <Grid item xs={4}>
                                                <TextField
                                                    datatest={'customSeparator'}
                                                    label={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_CSV_SEPARATOR_CUSTOM_VALUE)}
                                                    value={customDelimiter || ''}
                                                    onChange={(ev) => {
                                                        let value = ev.target.value;
                                                        this.setState({customDelimiter: value});
                                                    }}
                                                />
                                            </Grid>
                                    }
                                </>
                            }
                            {
                                format === UI_LABELS_DOWNLOAD_FORMAT_TURTLE && <>
                                    <Grid item xs={12}>
                                        <Typography>{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_FORMAT_TURTLE_INFORMATION, browseLanguage, UI_LABELS_DOWNLOAD_FORMAT_TURTLE_INFORMATION)}</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            datatest={'limit'}
                                            style={widthStyle}
                                            label={getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD_LIMIT, browseLanguage, UI_LABELS_DOWNLOAD_LIMIT)}
                                            value={limit}
                                            onChange={(ev) => {
                                                let value = ev.target.value;
                                                this.setState({limit: value});
                                            }}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <div style={{height : '40px'}}/>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            datatest={'cancelButton'}
                            onClick={onClose}
                            variant={"outlined"}
                            color="secondary"
                        >{getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}</Button>
                        <div style={{flexGrow :'1'}}/>
                        {
                            <Button
                                disabled={loading}
                                datatest={'downloadFileButton'}
                                variant={"contained"}
                                color="secondary"
                                onClick={this.apply}
                            >{loading && <CircularProgress size={24} className={classes.buttonProgress} />}{getUiLabelTranslation(settings, UI_LABELS_DOWNLOAD, browseLanguage, UI_LABELS_DOWNLOAD)}</Button>
                        }
                    </DialogActions>
                </Dialog>
            }
        </>;
    }
}

DownloadDialog.propTypes = {
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    browseLanguage: PropTypes.any,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    location: PropTypes.any,
    onClose: PropTypes.any,
    onDownload: PropTypes.any
};

export default withStyles(styles, {withTheme: true})(DownloadDialog);


import {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {Editor} from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FONT_1, FONT_2, FONT_3} from "../../Constants";
import {toArray} from "../../components/util";
import "./content.css";


const content = {"entityMap":{},"blocks":[{"key":"637gr","text":"Initialized from content state.","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}]};

class PageContentEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contentState : props.contentState,
        }
    }

    onContentStateChange = (contentState) => {
        this.setState({
            contentState,
        });
        this.props.onChange(contentState)
    };

    render() {
        const { contentState } = this.state;
        const {colors} = this.props;
        return (
            <div>
                <Editor
                    editorState={contentState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onContentStateChange}
                    toolbar={{
                        options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'remove', 'history'],
                        fontFamily: {
                            options: ['Arial', 'Georgia', 'Impact', FONT_1, FONT_2, 'Tahoma', 'Times New Roman', 'Verdana'],
                            className: undefined,
                            component: undefined,
                            dropdownClassName: undefined,
                        },
                        colorPicker: {
                            className: undefined,
                            component: undefined,
                            popupClassName: undefined,
                            colors: [  ...(toArray(colors)) ,'rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                                'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                                'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                                'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                                'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                                'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                        }
                    }}
                />
            </div>
        );
    }
}

PageContentEditor.propTypes = {
    contentState: PropTypes.object,
    onChange: PropTypes.func,
    colors : PropTypes.array
};

export default withStyles({}, {withTheme: true})(PageContentEditor);

import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    BETWEEN,
    EASYGRAPH_ETAG, EASYGRAPH_IS_CONFIGURATION_RESOURCE_OF,
    EQUAL_TO,
    EXISTS,
    GRAPH,
    GREATER_THAN,
    GREATER_THAN_EQUAL_TO,
    ID,
    LESS_THAN,
    LESS_THAN_EQUAL_TO,
    NOT_EQUAL_TO,
    OBJECT, OBJECT_INVERSE,
    RDF_LANGSTRING,
    SH_LITERAL,
    TEXT,
    TYPE,
    VALUE_END_KEY,
    XSD_DATE,
    XSD_DATETIME,
    XSD_STRING,
    XSD_TIME
} from "../../Constants";
import {
    addBorder,
    flatten,
    getAliasesMap,
    getAliasToIRIMap,
    getLanguageCodesSuggestions,
    getOntologyClasses, getPropertyName,
    getPropertyTypes,
    getShapesData,
    getUiLabelTranslationFromContext,
    isDateTimeType,
    isDateType,
    isObjectShapeProperty,
    isTimeType,
    sort,
    toArray
} from "../../components/util";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import AsyncSelect from 'react-select/async';
import NoSsr from "@material-ui/core/NoSsr";
import TextField from "../../components/TextField";
import {Box, FormHelperText, TextField as OtherTextField, Typography} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import uuid4 from 'uuid/v4'
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Label from "../../components/Label";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from "@material-ui/core/Chip";
import FieldContainer from "../../components/FieldContainer";
import Button from "@material-ui/core/Button";
import {
    computePropertyOptionsForClassIRIs,
    computePropertyOptionsFromOntology
} from "../../layouts/apiplayground/SearchRequest";
import {traceRenderStart} from "../../components/Trace";
import {getSystemOntology, getSystemShapes} from "../../layouts/apiplayground/SystemShapes";
import {theme} from "../../theme";
import {withDelete} from "../../components/ShapeToForm/Text";
import {AddButton, BlockActionButton} from "../../components/ShapeToForm/ArrayType";
import {isArray, isString} from "lodash";
import LanguageSearchSelect from "../../layouts/apiplayground/LanguageSearchSelect";
import H4Title from "../../components/H4Title";
import {
    getPropertiesSelect,
    getSelectOptions,
    hasAnyUnSelectedProperty,
    isSelectOptionObjectType
} from "../../layouts/apiplayground/SearchMixin";
import {DatePickerComponent} from "../../components/ShapeToForm/DatePicker";
import {TimePickerComponent} from "../../components/ShapeToForm/TimePicker";
import {DateTimePickerComponent} from "../../components/ShapeToForm/DateTimePicker";
import DatatypeSearchSelect from "../../layouts/apiplayground/DatatypeSearchSelect";
import {getLiteralTypeSelect, TYPE_DATATYPE, TYPES} from "../../components/ShapeToForm/Literal";
import SwapVertIcon from '@material-ui/icons/SwapVert';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {getUILabel} from "../navigator/WorkspaceSettingsDialog";
import ErrorMessage from "../../components/ErrorMessage";
import GlobalsContext from "../../components/GlobalsContext";
import {
    UI_LABELS_ADD,
    UI_LABELS_ADD_FILTER, UI_LABELS_BETWEEN, UI_LABELS_EQUAL_TO, UI_LABELS_EXISTS,
    UI_LABELS_FILTERS, UI_LABELS_GREATER_THAN, UI_LABELS_GREATER_THAN_EQUAL_TO,
    UI_LABELS_LABEL_PROPERTY,
    UI_LABELS_LANGUAGE, UI_LABELS_LESS_THAN, UI_LABELS_LESS_THAN_EQUAL_TO, UI_LABELS_NOT_EQUAL_TO, UI_LABELS_OBJECT,
    UI_LABELS_OPERATOR,
    UI_LABELS_PROPERTIES,
    UI_LABELS_REMOVE,
    UI_LABELS_REMOVE_BLOCK,
    UI_LABELS_RESET_ALL, UI_LABELS_TEXT,
    UI_LABELS_TEXT_SEARCH,
    UI_LABELS_VALUE
} from "../navigator/UILabel";
import {withWhiteBorder} from "../navigator/BasicSetup";

export const ID_PROPERTY = {label: ID, value: ID, tooltip: ID, [ALIAS_SH_PATH] : ID};
export const TYPE_PROPERTY = {label: TYPE, value: TYPE, tooltip: TYPE, [ALIAS_SH_PATH] : TYPE};
export const ID_AND_TYPE = [
    ID_PROPERTY,
    TYPE_PROPERTY
]

export function getDefaultProperties(configurations, customizations = {}) {
    let properties = [
        ID_PROPERTY,
        TYPE_PROPERTY,
        ...getDedupedPropertiesFromShape(configurations),
        //Add non shape properties after shape properties as if a property is defined in a shape we want to add relevant info for data type
        //Non shape props add generic datatype
        ...getNonShapeProperties(configurations, customizations),
        ...getEGSystemShapeProperties(configurations)
    ];
    let propSet = new Set()
    let dedupedProperties = []
    properties.filter(p => p).forEach(p => {
        let propId = p[ALIAS_SH_PATH] || p.value?.[ALIAS_SH_PATH];
        if (!propSet.has(propId)) {
            propSet.add(propId)
            dedupedProperties.push(p);
        }
    })

    return sort(dedupedProperties, 'label');
}

export function getEGSystemShapeProperties(configurations) {
    let shapesProperties = flatten(getSystemShapes()[GRAPH].filter(o => o[ALIAS_SH_PROPERTY]).map(o => o[ALIAS_SH_PROPERTY]))
    return dedupeProperties(shapesProperties, getAliasesMap(configurations))
}

export function getNonShapeProperties(configurations, customizations) {
    let aliasesMap = getAliasesMap(configurations)
    let eTagIRI = EASYGRAPH_ETAG
    let eTag = {label: aliasesMap[eTagIRI], value: aliasesMap[eTagIRI], tooltip: eTagIRI}
    let configurationResourceOf = {label: aliasesMap[EASYGRAPH_IS_CONFIGURATION_RESOURCE_OF], value: aliasesMap[EASYGRAPH_IS_CONFIGURATION_RESOURCE_OF], tooltip: EASYGRAPH_IS_CONFIGURATION_RESOURCE_OF}
    let optionsFromOntology = computePropertyOptionsFromOntology(configurations, undefined, customizations);
    return [eTag, configurationResourceOf, ...dedupeProperties(optionsFromOntology, getAliasesMap(configurations))];
}

export function getDedupedPropertiesFromShape(configurations) {
    let shapesProperties = flatten(getShapesData(configurations).filter(o => o[ALIAS_SH_PROPERTY]).map(o => o[ALIAS_SH_PROPERTY]))
    return dedupeProperties(shapesProperties, getAliasesMap(configurations))
}

export function dedupeProperties(shapesProperties, aliasesMap) {
    let propSet = new Set()
    let properties = []
    shapesProperties.forEach(p => {
        if (!propSet.has(p[ALIAS_SH_PATH])) {
            propSet.add(p[ALIAS_SH_PATH])
            let label = aliasesMap
                ? aliasesMap[p[ALIAS_SH_PATH]] || p[ALIAS_SH_PATH]
                : p[ALIAS_SH_PATH] ;
            let obj = {
                label: label,
                value: p,
                tooltip: p[ALIAS_SH_PATH]
            }
            properties.push(obj);
        }
    })
    return sort(properties, 'label');
}

export function getOperators() {
    return [EQUAL_TO, LESS_THAN, LESS_THAN_EQUAL_TO, GREATER_THAN, GREATER_THAN_EQUAL_TO, NOT_EQUAL_TO, TEXT, OBJECT, EXISTS];
}

export function getOperatorsOptions(operators) {
    return operators.map(o => ({label: o , value: o}));
}

export function getTypesOptions(aliasesMap, configurations, systemTypes = true) {

    let types = []
    let ontologyClasses = systemTypes ?
        getOntologyClasses(getSystemOntology()).map(c => ({
            label: aliasesMap[c[ID]],
            value: aliasesMap[c[ID]],
            tooltip: c[ID]
        }))
        : [];
    let typeOptions = [
        ...ontologyClasses,
        ...types.map(s => ({
            label: aliasesMap[s],
            value: aliasesMap[s],
            tooltip: s
        })), ...getShapesData(configurations).map(s => ({
            label: aliasesMap[s[ALIAS_SH_TARGET_CLASS]],
            value: aliasesMap[s[ALIAS_SH_TARGET_CLASS]],
            tooltip: s[ALIAS_SH_TARGET_CLASS]
        }))
    ];
    if(systemTypes) {
        let all = new Set();
        typeOptions.forEach(op => all.add(op.tooltip));
        getOntologyClasses(configurations).forEach(c => {
            let resourceId = c[ID];
            if(!all.has(resourceId)) {
                let option = {
                    label: aliasesMap[resourceId],
                    value: aliasesMap[resourceId],
                    tooltip: resourceId
                }
                typeOptions.push(option);
            }
    })}

    return typeOptions;
};

export function getAllDatatypeProperties(configurations) {
    let props = getDedupedPropertiesFromShape(configurations)
    let allDatatypeProps =[...getNonShapeProperties(configurations), ...(props ? props.filter(p => !isObjectShapeProperty(p.value)) : [])]
    return allDatatypeProps
}

export function getSelectedTypeIRIs(search, aliasesMap) {
    let reverseMap = getAliasToIRIMap(aliasesMap);
    return flatten(search.filters.filter(f => f.property && f.property.value === TYPE).map(f =>  {
        let normalisedValue = isArray(f.value) ? f.value : [f.value]
        return normalisedValue.filter(v => v).map(v => reverseMap[v.label]);
    }))
}

export function getLanguageSelection(value, onChange, multiple, label) {
    return <LanguageSearchSelect label={label} multiple={multiple} value={value} onChange={onChange}/>;
}

export const EmbeddedChip = withStyles({
    root: {
        margin : '1px',
        maxWidth: '100%',
    }
})(Chip);

export function getChipWithDelete(theme, key, label, tooltip, chipProps, hideDelete) {

    let {onDelete, ...rest} = chipProps;
    let propsToPass = hideDelete ? rest : chipProps;
    let chip = <EmbeddedChip
        datatest={label}
        color={'primary'}
        size={"small"}
        label={label}
        {...propsToPass}
    />;
    return tooltip
        ? <Tooltip key={key} title={tooltip}>{chip}</Tooltip>
        : chip;

}

export function renderBlock(title, component, containerStyle = {}) {
    return <FieldContainer style={{padding : '0px', backgroundColor : theme.palette.white.main, marginTop: theme.spacing(1), ...containerStyle}}>
        {
            addBorder(
                0,
                <div style={{padding: '8px'}}>
                    {title && <H4Title style={{paddingBottom: '8px'}}>{title}</H4Title>}
                    {component}
                </div>
            )
        }
    </FieldContainer>;
}

export function generateTree(data, level, hasAnyUnSelectedProperty, onDelete, renderProperty, renderOperator, renderValue, containerStyle ={}, renderBuilderMode, getUiLabelTranslationFor) {
    return <>{
        data && data.map((f, i) => {
            return <React.Fragment key={""+data[ID]+i}>
                <FieldContainer datatest={'filterBlock'} key={""+data[ID]+i} style={{padding : '0px', overflowX : 'auto', marginBottom: '8px', ...containerStyle}}>
                <FieldContainer style={{width : 'calc(100% - 16px)', display : 'inline-table', padding : '4px 8px 8px 8px'}}>
                    {   onDelete &&
                        <div style={{display : 'flex'}}>
                            <div style={{flexGrow : '1'}}/>
                            <div style={{paddingTop : '4px'}}>
                                <BlockActionButton
                                    buttonProps={{datatest : 'removeBlockButton'}}
                                    theme={theme}
                                    title={getUiLabelTranslationFor ? getUiLabelTranslationFor(UI_LABELS_REMOVE_BLOCK) : UI_LABELS_REMOVE_BLOCK}
                                    onClick={() => {
                                        data.splice(i, 1);
                                        onDelete();
                                    }}
                                    endIcon={<RemoveCircleIcon style={{color: theme.palette.grey.level3}}/>}
                                />
                            </div>
                        </div>
                    }

                    {renderBuilderMode && renderBuilderMode(f, level)}

                    <div style={{display : 'flex'}}>
                        {renderProperty && <div datatest={'property'} style={{marginRight : '4px'}}>{renderProperty(f, level)}</div>}
                        {renderOperator && <div datatest={'operator'} style={{marginRight : '4px'}}>{renderOperator(f)}</div>}
                        <div datatest={'value'} style={{flexGrow : '1'}}>
                            {renderValue && renderValue(f, level, hasAnyUnSelectedProperty)}
                        </div>
                    </div>
                </FieldContainer>
            </FieldContainer>
                {renderBuilderMode && i < (data.length - 1)  && level === 0
                    && <IconButton datatest={'swapButton'} style={{marginBottom : '8px'}} onClick={() => {
                        let nextIndex = i + 1;
                        let nextObject = data[nextIndex];
                        let currentObject = data[i];
                        data[nextIndex] = currentObject;
                        data[i] = nextObject;
                        onDelete();
                    }
                }>
                        <SwapVertIcon></SwapVertIcon>
                    </IconButton>
                }
            </React.Fragment>;
        })}</>;
}

export function generateList(data, level, hasAnyUnSelectedProperty, onDelete, renderProperty, renderOperator, renderValue, containerStyle ={}, renderBuilderMode, getUiLabelTranslationFor) {
    return <>{
        data && data.map((f, i) => {
            return <React.Fragment key={""+data[ID]+i}>
                <FieldContainer datatest={'filterBlock'} key={""+data[ID]+i} style={{padding : '0px', overflowX : 'auto', marginBottom: '8px', ...containerStyle}}>
                    <FieldContainer style={{width : 'calc(100% - 16px)', display : 'inline-table', padding : '4px 8px 8px 8px'}}>
                        {   onDelete &&
                            <div style={{display : 'flex'}}>
                                <div style={{flexGrow : '1'}}/>
                                <div style={{paddingTop : '4px'}}>
                                    <BlockActionButton
                                        buttonProps={{datatest : 'removeBlockButton'}}
                                        theme={theme}
                                        title={getUiLabelTranslationFor ? getUiLabelTranslationFor(UI_LABELS_REMOVE_BLOCK) : UI_LABELS_REMOVE_BLOCK}
                                        onClick={() => {
                                            data.splice(i, 1);
                                            onDelete();
                                        }}
                                        endIcon={<RemoveCircleIcon style={{color: theme.palette.grey.level3}}/>}
                                    />
                                </div>
                            </div>
                        }

                        {renderBuilderMode && renderBuilderMode(f, level)}

                        <div>
                            {renderProperty && <div datatest={'property'} style={{marginRight : '4px'}}>{renderProperty(f, level)}</div>}
                            {renderOperator && <div datatest={'operator'} style={{marginRight : '4px'}}>{renderOperator(f)}</div>}
                            <div datatest={'value'}>
                                {renderValue && renderValue(f, level, hasAnyUnSelectedProperty)}
                            </div>
                        </div>
                    </FieldContainer>
                </FieldContainer>
                {renderBuilderMode && i < (data.length - 1)  && level === 0
                    && <IconButton datatest={'swapButton'} style={{marginBottom : '8px'}} onClick={() => {
                        let nextIndex = i + 1;
                        let nextObject = data[nextIndex];
                        let currentObject = data[i];
                        data[nextIndex] = currentObject;
                        data[i] = nextObject;
                        onDelete();
                    }
                    }>
                        <SwapVertIcon></SwapVertIcon>
                    </IconButton>
                }
            </React.Fragment>;
        })}</>;
}

export function renderObjectValue(renderTree, theme, addButtonDisabled, addButtonTitle, onAddButtonClick, minWidth) {
    return <FieldContainer datatest={'objectContainer'} style={{ minWidth : (minWidth || '496px'), marginTop: '8px', padding : '8px', backgroundColor : theme.palette.white.main}}>
        {renderTree()}
        {   onAddButtonClick &&
            <div style={{display: 'flex', padding: '8px 0px'}}>
                <div style={{flexGrow: '1'}}></div>
                <Button
                    datatest={'addButton'}
                    disabled={addButtonDisabled}
                    variant={"text"}
                    onClick={onAddButtonClick}
                    color="secondary"
                >
                    {addButtonTitle}
                </Button>
            </div>
        }
    </FieldContainer>;
}

export function MultiValueSelect({options, value, onChange, label, classes, style, disableClearable = true}) {
    let inputLabelProps = classes
        ? {
            classes : {
                shrink: classes.smallWidthLabelShrink,
                root : classes.smallWidthPropertyLabelRoot
            }
        }
        : {};

    return <Autocomplete
        style={style}
        datatest={'autocompleteMultiValueSelect'}
        id="textSearchPropertiesValueSelect"
        value={value || []}
        options={options}
        getOptionLabel={option => option.label ?  option.label : ''}
        getOptionSelected={(option, value) => {
            return option.value === value.value;
        }}
        multiple={true}
        onChange={onChange}
        renderInput={params => (
            <OtherTextField
                label={label}
                {...params}
                margin={"dense"}
                variant="outlined"
                fullWidth
                InputLabelProps={inputLabelProps}
            />
        )}
        renderTags={(value, getTagProps) => {
            return value.map((option, index) => {
                return getChipWithDelete(theme, index, option.label, option.tooltip, getTagProps({index}));
            })
        }}
        size={"small"}
        disableClearable={disableClearable}
    />;
}

export const getMultiValuedLang = (filter, multiple, onChange, search , label, otherProps = {}) => {
    let {lang} = filter
    return <div datatest={'multiValuedLang'} style={{marginRight : '34px'}}>
        <LanguageSearchSelect
            label={label ? label : (multiple === true ? 'Languages' : 'Language')}
            multiple={multiple}
            value={lang}
            onChange={(val) => {
                filter.lang = val;
                delete filter.dataType;
                onChange(search);
            }}
            {...otherProps}
        />
    </div> ;
}


const StyledOperatorAutocomplete = withStyles({
    root : {width : '80px'},
    paper : { minWidth : '100px'}
})(Autocomplete)

const COMPONENT = 'SearchFilter'
export const SEARCH_COMPONENT_BUILDER_MODE = "SEARCH_COMPONENT_BUILDER_MODE";
export const SEARCH_COMPONENT_PATH_MODE = "SEARCH_COMPONENT_PATH_MODE";
export const SEARCH_COMPONENT_PROPERTY_MODE = "SEARCH_COMPONENT_PROPERTY_MODE";

export function isLangProperty(property) {
    return property?.[ALIAS_SH_DATATYPE] &&  [RDF_LANGSTRING].includes(property[ALIAS_SH_DATATYPE]) ? true : false;
}

export const OPERATOR_LABEL_MAP = {
    [EQUAL_TO] : UI_LABELS_EQUAL_TO,
    [NOT_EQUAL_TO] : UI_LABELS_NOT_EQUAL_TO,
    [LESS_THAN] : UI_LABELS_LESS_THAN,
    [LESS_THAN_EQUAL_TO] : UI_LABELS_LESS_THAN_EQUAL_TO,
    [GREATER_THAN] : UI_LABELS_GREATER_THAN,
    [GREATER_THAN_EQUAL_TO] : UI_LABELS_GREATER_THAN_EQUAL_TO,
    [OBJECT] : UI_LABELS_OBJECT,
    [BETWEEN] : UI_LABELS_BETWEEN,
    [TEXT] : UI_LABELS_TEXT,
    [EXISTS] : UI_LABELS_EXISTS
}


export function getLanguageLabelForSite(lv) {
    return lv.label + " (" + lv.value + ")";
}

export const SEARCH_LABEL_KEY_PATH = ['ui', 'label'];

export function SearchPaperLabelSettings({theme, onChange, settings, textSearch}) {
    const getUILabelInternal = (keyPathObject, keyPathArray, label = 'Label') => {
        return getUILabel(label , theme, settings, onChange, keyPathObject, keyPathArray, undefined, {padding : '0px', marginTop : '8px', marginBottom :'16px'})
    }

    return withWhiteBorder(getUILabelInternal(textSearch, SEARCH_LABEL_KEY_PATH));
}

class SearchFilter extends Component {
    static contextType = GlobalsContext;

    constructor(props) {
        super(props);
        this.state = this.initialiseState(props)
    }

    initialiseState = (props) => {
        let {aliasesMap, configurations, customizations} = props
        let properties = getDefaultProperties(configurations, customizations);

        return {
            properties: properties,
            operators: getOperators(),
            types: getTypesOptions(aliasesMap, configurations),
            allDatatypeProperties: sort(getAllDatatypeProperties(configurations), 'label'),
            languageOptions : getLanguageCodesSuggestions().map(o => ({value : o.value, label : o.value})),
            filteredLanguageOptions : getLanguageCodesSuggestions(),

        };
    }


    getLabel(shortName, longName) {
        return shortName + ( longName ? ' (' + longName + ')' : '');
    }

    loadProperties = (options) =>(inputValue, callback) => {
        setTimeout(() => {
            callback(
                options.filter(o => o.label.toLowerCase().includes(inputValue.toLowerCase()))
            )
        }, 150);
    }

    handleChange = name => target => {
        this.setState({
            [name]: target.target.value,
        });
    };

    handleSelectionChange = name => value => {
        let normalisedValue = isArray(value) ? value : [value]
        if(name === 'property') {
            if(normalisedValue[0].value === TYPE) {
                this.setState({
                    operators: [EQUAL_TO],
                    [name]: normalisedValue,
                });
            } else {
                this.setState({
                    operators: getOperators(),
                    [name]: normalisedValue,
                    value : undefined

                });
            }
        } else {
            this.setState({
                [name]: normalisedValue,
            });
        }
    };

    isTypeFilter = () => {
        let {property} = this.state
        return property && property[0].value === TYPE;
    };

    addToFilters = (filters) => {
        let {customizations} = this.props;
        let f = {id: uuid4()};
        if(customizations?.parentClassIRIs) {
            f.parentClassIRIs = customizations?.parentClassIRIs;
        }
        if(this.isSearchComponentPropertyMode() && customizations?.property) {
            f.property = customizations?.property
        }
        filters.push(f);
        this.props.onChange(this.props.search)

    }

    isLangProperty = (property) => {
        return isLangProperty(property);
    }

    isStringProperty = (property) => {
        return property[ALIAS_SH_DATATYPE] &&  [XSD_STRING].includes(property[ALIAS_SH_DATATYPE]) ? true : false;
    }

    isStringOrLangProperty = (property) => {
        return property[ALIAS_SH_DATATYPE] &&  [RDF_LANGSTRING, XSD_STRING].includes(property[ALIAS_SH_DATATYPE]) ? true : false;
    }

    isLiteralProperty = (property) => {
        return property[ALIAS_SH_NODE_KIND] &&  SH_LITERAL === property[ALIAS_SH_NODE_KIND] ? true : false;
    }

    propertyChange = (filter) => (value) => {
        filter.property = value
        filter.operator = undefined
        filter.value = undefined
        if(this.isSearchComponentPathMode()) {
            if(isObjectShapeProperty(value.value)) {
                filter.operator = {label: OBJECT , value: OBJECT};
                filter.value = [];
                this.addConnectedFilter(filter);
            }
        }
        this.props.onChange(this.props.search)
    }

    operatorChange = (filter) => (value) => {
        filter.operator = value
        this.setDefaultValueForOperator(filter)
        this.props.onChange(this.props.search)
    }

    addConnectedFilter = (filter) => {
        let {aliasesMap, configurations} = this.props
        let shapeProperty = filter.property.value
        const allClasses = getPropertyTypes(shapeProperty, configurations)
        filter.value.push({id: uuid4(), parentClassIRIs: allClasses })
    }

    setDefaultValueForOperator = (filter) => {
        let operator = filter.operator ? filter.operator.value : undefined;
        //delete filter.lang
        if(!this.isLangProperty(filter.property.value) || operator === EXISTS) {
            delete filter.lang;
            if(operator === EXISTS && this.isSearchComponentBuilderMode()) {
                //Setting the filter value to false means that filter can be enabled/disabled by user in SearchCard
                filter.value = false;
            }
        }
        if(operator === TEXT) {
            if(isArray(filter.value)) {
                // do nothing and use existing value
            } else {
                //convert to array
                filter.value = [filter.value];
            }
        } else if (operator === EQUAL_TO || operator === NOT_EQUAL_TO) {
            if(filter.property.value === TYPE) {
                if(isArray(filter.value)) {
                    // do nothing and use existing value
                } else {
                    filter.value = [];
                }
            } else {
                if (isArray(filter.value) && filter.value.find(v => !isString(v)) === undefined) {
                    // do nothing as we do not want to clear existing values
                } else if (isString(filter.value)) {
                    //if string then set it in array
                    filter.value = [filter.value];
                } else {
                    filter.value = [''];
                }
            }
        } else if (operator === OBJECT || operator === OBJECT_INVERSE) {
            filter.value = [];
            this.addConnectedFilter(filter);
        } else if ([LESS_THAN, LESS_THAN_EQUAL_TO, GREATER_THAN, GREATER_THAN_EQUAL_TO].includes(operator)) {
            this.setDefaultOrExistingString(filter);
        } else {
            filter.value = '';
        }
    }

    setDefaultOrExistingString = (filter) => {
        let valueArr = toArray(filter.value);
        let firstValue = valueArr[0];
        if(firstValue && isString(firstValue)) {
            // if first value is string set it
            filter.value = firstValue;
        } else {
            //else empty
            filter.value = '';
        }
    }

    typeSelectValueChange = (filter) => (value) => {
        filter.value = value
        this.props.onChange(this.props.search)
    }

    multiValueChange = (filter) => (i) => (value) => {
        filter.value[i] = value.target.value
        this.props.onChange(this.props.search)
    }

    multiValueRemove = (filter, i) => (e) => {
        filter.value.splice(i, 1)
        this.props.onChange(this.props.search)
    }

    multiValueDateTimeChange = (filter) => (i) => (value) => {
        filter.value[i] = value
        this.props.onChange(this.props.search)
    }

    singleValueChange = (filter, valueKey = 'value') => (value) => {
        filter[valueKey] = value.target.value
        this.props.onChange(this.props.search)
    }

    singleDateTimeValueChange = (filter, valueKey = 'value') => (value) => {
        filter[valueKey] = value;
        this.props.onChange(this.props.search);
    }

    langValueChange = (filter) => (value) => {
        filter.lang = value.target.value
        this.props.onChange(this.props.search)
    }

    multiValueAdd = (filter) => () => {
        filter.value = filter.value ? [...filter.value, ''] : [''];
        this.setState({autoFocus : true});
        this.props.onChange(this.props.search);
    }

    getUILabel = (keyPathObject, keyPathArray, label = 'Label') => {
        let {theme, onChange, settings} = this.props
        return getUILabel(label , theme, settings, onChange, keyPathObject, keyPathArray, undefined, {padding : '0px', marginTop : '8px', marginBottom :'16px'})
    }

    getBuilderModeOptions = (f, level) => {
        let {classes, theme, onChange, search, settings} = this.props
        if(level > 0 || this.isSearchComponentBuilderMode() === false) {
            return <></>;
        }
        return <React.Fragment key={f.id}>
            {
                withWhiteBorder(
                <div>
                    <FormControlLabel
                        control={
                            <Switch
                                datatest={'hiddenFilter'}
                                size={"small"}
                                checked={f.hidden || false}
                                value={true}
                                onChange={() => {
                                    f.hidden = !f.hidden;
                                    onChange(search);
                                    this.setState({})
                                }}
                                name="hiddenFilter"
                            ></Switch>
                        }
                        label={<Typography style={{color: theme.palette.primary.main}}>Hidden Filter</Typography>}
                    />
                    <FormHelperText>A hidden filter is always added to search request. If you want to restrict the
                        search you can set a hidden filter.</FormHelperText>
                </div>
                )
            }
            {
                f.hidden &&
                withWhiteBorder(<div>
                    <FormControlLabel
                        control={
                            <Switch
                                datatest={'applyOnEmpty'}
                                size={"small"}
                                checked={f.applyOnEmpty || false}
                                value={true}
                                onChange={() => {
                                    f.applyOnEmpty = !f.applyOnEmpty;
                                    onChange(search);
                                    this.setState({})
                                }}
                                name="applyOnEmpty"
                            ></Switch>
                        }
                        label={<Typography style={{color: theme.palette.primary.main}}>Apply Only If Other User Filters Are Empty</Typography>}
                    />
                        <FormHelperText>With this switch you can control what is shown on search if user removes all the filter values.</FormHelperText>
                </div>
                )

            }

            {
                f.hidden || <>
                    {withWhiteBorder(this.getUILabel( f, SEARCH_LABEL_KEY_PATH, 'Filter Label'))}
                    {
                       withWhiteBorder(<div>
                            <FormControlLabel
                                control={
                                    <Switch
                                        datatest={'enableFacet'}
                                        size={"small"}
                                        checked={f.enableFacet || false}
                                        value={true}
                                        onChange={() => {
                                            f.enableFacet = !f.enableFacet;
                                            onChange(search);
                                            this.setState({})
                                        }}
                                        name="enableFacetFilter"
                                    ></Switch>
                                }
                                label={<Typography style={{color: theme.palette.primary.main}}>Enable Facet</Typography>}
                            />
                           <FormHelperText>If this option is set, counts are shown in the left.</FormHelperText>
                       </div>)
                    }
                    {
                        f.enableFacet === true &&
                            withWhiteBorder(<div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            datatest={'facetSortByCount'}
                                            size={"small"}
                                            checked={f.facetSortByCount || false}
                                            value={true}
                                            onChange={() => {
                                                f.facetSortByCount = !f.facetSortByCount;
                                                onChange(search);
                                                this.setState({})
                                            }}
                                            name="facetSortByCount"
                                        ></Switch>
                                    }
                                    label={<Typography style={{color: theme.palette.primary.main}}>Sort Facet By Count</Typography>}
                                />
                                <FormHelperText>By default facets are sorted by label.</FormHelperText>
                            </div>)
                    }


                </>
            }
        </React.Fragment>
    }

    generateTree = (filters, level=0, parentFilterHidden = false) => {
        let {onChange, search, customizations} = this.props;
        let onDelete = (this.isSearchComponentPathMode() && level > 0) || customizations?.hideRemoveBlockButton === true
            ? undefined : () => onChange(search);
        let renderProperty = this.isSearchComponentPropertyMode() && level < 1 ? undefined : (f) => this.getProperty(f, level);
        let renderOperator = this.isSearchComponentPathMode() ? undefined :  (f) => this.getOperator(f);
        let renderValue = (f, level) => this.getValue(f, level, parentFilterHidden);
        let renderBuilderMode =  (f, level) => this.getBuilderModeOptions(f, level, parentFilterHidden);
        let filtersToPass = this.isSearchComponentBuilderMode() && customizations?.filterToShow ? [customizations?.filterToShow] : filters;
        return generateTree(filtersToPass, level, hasAnyUnSelectedProperty(filtersToPass), onDelete, renderProperty, renderOperator, renderValue, undefined, renderBuilderMode, this.getUiLabelTranslationFor);
    }

    getProperty = (filter, level) => {
        let {configurations, aliasesMap, customizations} = this.props;
        let {property, parentClassIRIs} = filter;

        let selectOptions = parentClassIRIs
        // If level 0 then allow filter on type and id
            ? computePropertyOptionsForClassIRIs(parentClassIRIs, aliasesMap, configurations, level === 0, level === 0, customizations)
            : getDefaultProperties(configurations, customizations)
        if (property) {
            return this.getPropertiesSelect(selectOptions, property, this.propertyChange(filter));
        } else {
            return this.getPropertiesSelect(selectOptions, undefined, this.propertyChange(filter));
        }
    }

    getOperator = (filter) => {
        let {property, operator} = filter;
        if (property) {
            if(this.isSearchComponentBuilderMode() && filter.enableFacet) {
                filter.operator = getOperatorsOptions([ EQUAL_TO])[0];
                return ;
            }
            let operatorValue = operator ? operator : ''
            if (property.value === TYPE) {
                return this.getOperatorSelect([EQUAL_TO, NOT_EQUAL_TO], operatorValue, this.operatorChange(filter));
            } else if (property.value === ID) {
                return this.getOperatorSelect([EQUAL_TO, NOT_EQUAL_TO], operatorValue, this.operatorChange(filter));
            } else if (isObjectShapeProperty(property.value)) {
                return this.getOperatorSelect([EQUAL_TO, NOT_EQUAL_TO, OBJECT, OBJECT_INVERSE, EXISTS], operatorValue, this.operatorChange(filter));
            } else if (false) {
                return this.getOperatorSelect([EQUAL_TO, NOT_EQUAL_TO, TEXT, EXISTS], operatorValue, this.operatorChange(filter));
            } else if (this.isStringOrLangProperty(property.value) || this.isLiteralProperty(property.value)) {
                return this.getOperatorSelect([EQUAL_TO, NOT_EQUAL_TO, TEXT, LESS_THAN, LESS_THAN_EQUAL_TO, GREATER_THAN, GREATER_THAN_EQUAL_TO, EXISTS], operatorValue, this.operatorChange(filter));
            } else {
                return this.getOperatorSelect([EQUAL_TO, NOT_EQUAL_TO, LESS_THAN, LESS_THAN_EQUAL_TO, GREATER_THAN, GREATER_THAN_EQUAL_TO, BETWEEN, EXISTS], operatorValue, this.operatorChange(filter));
            }
        }
    }

    labelPropertyChange = (filter) => (value) => {
        if(!filter.labelProperty || value.value !== filter.labelProperty.value) {
            filter.labelProperty = value;
            this.props.onChange()
        }
    }

    getValue = (filter, level, parentFilterHidden) => {
        let {classes, theme, aliasesMap, settings, configurations, onChange, search, customizations, aliasesToIRIMap, ontology, browseLanguage} = this.props
        let {property, operator, value, lang} = filter
        let operatorValue = operator && operator.value ? operator.value : operator;

        if(this.isSearchComponentBuilderMode() && filter.enableFacet) {
            if(isObjectShapeProperty(property?.value)) {
                let selectOptions = getSelectOptions(configurations, aliasesMap, filter, false);
                selectOptions = selectOptions.filter(p => !isSelectOptionObjectType(p));
                return getPropertiesSelect(selectOptions, filter.labelProperty, this.labelPropertyChange(filter), this.getUiLabelTranslationFor(UI_LABELS_LABEL_PROPERTY), classes, {width:'100%'}, undefined, 'largeWidthPropertyLabelRoot');
            }
            return ;
        }
        if(this.isSearchComponentBuilderMode() && operatorValue !== OBJECT) {
            let isHidden = (filter.hidden !== undefined && filter.hidden === true) || parentFilterHidden === true;
            if(!isHidden) {
                return <></>;
            }
        }
        const translateAllLabels = customizations?.translateAllLabels;
        const languageLabel = translateAllLabels && this.getUiLabelTranslationFor(UI_LABELS_LANGUAGE, UI_LABELS_LANGUAGE);
        const languageOtherProps = translateAllLabels && {
            addNameToLabel : true,
            suggestions : toArray(settings.browseLanguages).map(lv => {
                return {
                    value: lv.value,
                    label: getLanguageLabelForSite(lv)
                }
            })
        }
        if (operatorValue && operatorValue !== EXISTS) {
            if (operatorValue === EQUAL_TO || operatorValue === NOT_EQUAL_TO) {
                if (property.value === TYPE) {
                    let valuesSet = new Set(toArray(value).map(v => v.value));
                    let typesOptions = getTypesOptions(aliasesMap, configurations).filter(o => !valuesSet.has(o.value));
                    if(translateAllLabels) {
                        typesOptions = typesOptions.map(to => {
                            to.label = getPropertyName(aliasesToIRIMap, to.value, ontology, browseLanguage);
                            return to;
                        })
                    }
                    return <div style={{paddingRight : '34px'}}>{this.getTypeValue(typesOptions, value, this.typeSelectValueChange(filter))}</div>;
                } else if (this.isLangProperty(property.value)) {
                    return <>
                        {getMultiValuedLang(filter, false, onChange, this.props.search, languageLabel, languageOtherProps)}
                        <div></div>
                        {this.getMultiValued(filter)}
                    </>;
                } else if (this.isLiteralProperty(property.value)) {
                    return <>
                        <div style={{display: 'flex'}}>
                            {
                                getLiteralTypeSelect(
                                    classes,
                                    (filter.literalType || TYPES[0]),
                                    (ev, newLiteralType) => {
                                        filter.literalType = newLiteralType;
                                        this.props.onChange(this.props.search);
                                    },
                                    this.getUiLabelTranslationFor
                                )
                            }
                            <div style={{flexGrow : '1'}}>
                            {
                                filter.literalType && filter.literalType.value === TYPE_DATATYPE
                                    ? this.getDatatypeSelect(filter, false)
                                    : getMultiValuedLang(filter, false, onChange, this.props.search, languageLabel, languageOtherProps)
                            }
                            </div>
                        </div>
                        <div></div>
                        {this.getMultiValued(filter)}
                    </>;
                } else {
                    return this.getMultiValued(filter);
                }
            } else if (operatorValue === OBJECT || operatorValue === OBJECT_INVERSE) {
                let renderTree = () => this.generateTree(value, (level + 1), filter.hidden || parentFilterHidden);
                let onAddButtonClick = this.isSearchComponentPathMode() ? undefined : () => {
                    this.addConnectedFilter(filter);
                    onChange(search)
                };
                let addFilterButtonTitle = this.getAddFilterButtonTitle(customizations);
                return renderObjectValue(renderTree, theme, false ,addFilterButtonTitle, onAddButtonClick);
            } else if (operatorValue === TEXT) {

                return <>{(this.isLangProperty(property.value) || this.isLiteralProperty(property.value)) && getMultiValuedLang(filter, false, onChange, this.props.search, languageLabel, languageOtherProps)}<div></div>{this.getMultiValued(filter)}</>;
            } else {
                if(property.value[ALIAS_SH_DATATYPE] === XSD_DATETIME) {
                    return <>
                        {this.getSingleValuedDatetime(value, this.singleDateTimeValueChange(filter))}
                        {operatorValue === BETWEEN && this.getSingleValuedDatetime(filter[VALUE_END_KEY], this.singleDateTimeValueChange(filter, VALUE_END_KEY))}
                    </>;
                } else if(property.value[ALIAS_SH_DATATYPE] === XSD_DATE) {
                    return <>
                        {this.getSingleValuedDate(value, this.singleDateTimeValueChange(filter))}
                        {operatorValue === BETWEEN && this.getSingleValuedDate(filter[VALUE_END_KEY], this.singleDateTimeValueChange(filter, VALUE_END_KEY))}
                    </>
                } else if(property.value[ALIAS_SH_DATATYPE] === XSD_TIME) {
                    return <>
                        {this.getSingleValuedTime(value, this.singleDateTimeValueChange(filter))}
                        {operatorValue === BETWEEN && this.getSingleValuedTime(filter[VALUE_END_KEY], this.singleDateTimeValueChange(filter, VALUE_END_KEY))}
                    </>
                } else if (this.isLiteralProperty(property.value)) {
                    return <>
                        <div style={{display: 'flex'}}>
                            {
                                getLiteralTypeSelect(
                                    classes,
                                    (filter.literalType || TYPES[0]),
                                    (ev, newLiteralType) => {
                                        filter.literalType = newLiteralType;
                                        this.props.onChange(this.props.search);
                                    },
                                    this.getUiLabelTranslationFor
                                )
                            }
                            <div style={{flexGrow : '1'}}>
                                {
                                    filter.literalType && filter.literalType.value === TYPE_DATATYPE
                                        ? this.getDatatypeSelect(filter, false)
                                        : getMultiValuedLang(filter, false, onChange, this.props.search, languageLabel, languageOtherProps)
                                }
                            </div>
                        </div>
                        <div></div>
                        {<div style={{paddingRight : '34px'}}>{this.getSingleValued(value, this.singleValueChange(filter))}</div>}
                    </>;
                } else {
                    return <>
                        {this.isLangProperty(property.value) && getMultiValuedLang(filter, false, onChange, this.props.search, languageLabel, languageOtherProps)}
                        <div></div>
                        {
                            <div style={{paddingRight : '34px'}}>
                                {this.getSingleValued(value, this.singleValueChange(filter))}
                                {operatorValue === BETWEEN && this.getSingleValued(filter[VALUE_END_KEY], this.singleValueChange(filter, VALUE_END_KEY))}
                            </div>}
                    </>;
                }
            }
        }
    }

    getComponent = (v, filter, i) => {
        let {property} = filter;
        if(property.value[ALIAS_SH_DATATYPE] === XSD_DATETIME) {
            return this.getSingleValuedDatetime(v, this.multiValueDateTimeChange(filter)(i), true);
        } else if(property.value[ALIAS_SH_DATATYPE] === XSD_DATE) {
            return this.getSingleValuedDate(v, this.multiValueDateTimeChange(filter)(i), true);
        } else if(property.value[ALIAS_SH_DATATYPE] === XSD_TIME) {
            return this.getSingleValuedTime(v, this.multiValueDateTimeChange(filter)(i), true);
        } else {
            return this.getSingleValued(v, this.multiValueChange(filter)(i), true);
        }
    }

    getMultiValued = (filter) => {
        let {value} = filter
        return <FieldContainer style={{padding: '0px', paddingTop : '0px'}}>
            {
                value && value.map((v, i) => {
                    let component = this.getComponent(v, filter, i);
                    return <div datatest={'multiValued'} key={i}>
                        {
                            withDelete(
                                component,
                                <Tooltip title={'Remove Value'}>
                                    <IconButton datatest={'deleteButton'} size={'small'} onClick={this.multiValueRemove(filter, i)}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Tooltip>,
                                isDateTimeType(filter) || isDateType(filter) || isTimeType(filter)
                            )
                        }
                    </div>
                })
            }
            <AddButton onClick={this.multiValueAdd(filter)} tooltip={'Add OR Value'}/>
        </FieldContainer>;
    }


    getDatatypeSelect = (filter) => {
        let {configurations} = this.props;
        let {dataType} = filter
        return <div style={{marginRight : '34px'}}>
            <DatatypeSearchSelect
                configurations={configurations}
                label={'Datatype'}
                multiple={false}
                value={dataType}
                onChange={(val) => {
                    filter.dataType = val;
                    delete filter.lang;
                    this.props.onChange(this.props.search);
                }}
            />
        </div> ;
    }

    getSingleValuedDatetime = (value, onChange) => {
        return <DateTimePickerComponent onChange={onChange} value={value}/>;
    }

    getSingleValuedDate = (value, onChange) => {
        return <DatePickerComponent onChange={onChange} value={value} />;
    }

    getSingleValuedTime = (value, onChange) => {
        return <TimePickerComponent onChange={onChange} value={value}/>;
    }

    getSingleValued = (value, onChange) => {
        let {autoFocus} = this.state;
        let {classes} = this.props;
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.smallWidthPropertyLabelRoot
                }
            }
            : {};
        return <OtherTextField
            datatest={'textField-Value'}
            label={this.getUiLabelTranslationFor(UI_LABELS_VALUE)}
            onChange={onChange}
            value={value}
            autoFocus={autoFocus}
            fullWidth={true}
            InputLabelProps={inputLabelProps}
        />;
    }

    getLangValue = (value, onChange) => {
        return <TextField
            paperStyle={{padding : '0px'}}
            onChange={onChange}
            value={value}
        />;
    }

    getOperatorSelect = (operators, operator, onChange) => {
        let {classes, customizations} = this.props;
        let style;
        if(this.isSearchComponentPropertyMode()) {
            style = {width : '200px'};
        }

        let operatorsOptions = getOperatorsOptions(operators);
        if(customizations?.translateAllLabels) {
            operatorsOptions = sort(operatorsOptions.map(op => {
                op.label = this.getUiLabelTranslationFor(OPERATOR_LABEL_MAP[op.value]);
                return op;
            }), 'label');
        }
        return <StyledOperatorAutocomplete
            style={style}
            datatest={'autocompleteOperator'}
            value={operator}
            options={operatorsOptions}
            getOptionLabel={option => option.label ? option.label : ''}
            getOptionSelected={(option, value) => {
                return option.value === value.value;
            }}
            onChange={(event, val) => {
                onChange(val)
            }}
            renderInput={params => (
                <OtherTextField
                    label={this.getUiLabelTranslationFor(UI_LABELS_OPERATOR)}
                    {...params}
                    margin={"dense"}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                        classes : {
                            shrink: classes.smallWidthLabelShrink,
                            root : classes.smallWidthLabelRoot
                        }
                    }}
                />
            )}
            size={"small"}
            disableClearable
        />;

    }

    getPropertiesSelect = (properties, property, onChange) => {
        let {classes} = this.props;
        let style = undefined;
        if(this.isSearchComponentBuilderMode() || this.isSearchComponentPathMode()) {
            style = {width : '204px'};
        }
        return getPropertiesSelect(properties, property, onChange, undefined, classes, style, undefined, undefined);
    }


    getTypeValue = (types, type, onChange) => {
        let {classes, theme} = this.props;
        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.largeWidthPropertyLabelRoot
                }
            }
            : {};


        return <Autocomplete
                datatest={'autocompleteType'}
                id="typeValueSelect"
                value={type ? type  : []}
                options={types}
                getOptionLabel={option => option.label ?  option.label : ''}
                getOptionSelected={(option, value) => {
                    return option.value === value.value;
                }}
                multiple={true}
                onChange={(event, val) => onChange(val)}
                renderInput={params => (
                    <OtherTextField
                        label={this.getUiLabelTranslationFor(UI_LABELS_VALUE)}
                        {...params}
                        margin={"dense"}
                        variant="outlined"
                        fullWidth
                        InputLabelProps={inputLabelProps}
                    />
                )}
                renderTags={(value, getTagProps) => {
                    return value.map((option, index) => {
                        return getChipWithDelete(theme, index, option.label, option.tooltip, getTagProps({index}));
                    })
                }}
                size={"small"}
            />;
    }

    getObjectValue = (operator, classes, selectStyles, properties, pathOperator) => {
        return <>
            {

                <Box>
                    <NoSsr>
                        <AsyncSelect
                            classes={classes}
                            styles={selectStyles}
                            textFieldProps={{
                                label: 'Label',
                                InputLabelProps: {
                                    shrink: true,
                                },
                            }}
                            cacheOptions={false}
                            loadOptions={this.loadProperties(properties)}
                            defaultOptions
                            isMulti={true}
                            onChange={this.handleSelectionChange("path")}
                            value={this.state.path}
                        />
                    </NoSsr>
                    <Select
                        native
                        value={pathOperator}
                        name={'pathOperator'}
                        onChange={this.handleChange('pathOperator')}
                        inputProps={{
                            name: 'type',
                            id: 'file-type',
                        }}
                    >{
                        this.state.operators.map(o => <option key={o} value={o}>{o}</option>)
                    }</Select>
                    <TextField
                        label='Path Value'
                        id='pathValue'
                        name='pathValue'
                        onChange={this.handleChange('pathValue')}
                        value={this.state.pathValue}
                     />
                </Box>
            }
        </>;
    }

    isSearchComponentBuilderMode = () => {
        return this.props.customizations !== undefined && this.props.customizations.mode === SEARCH_COMPONENT_BUILDER_MODE;
    }

    isSearchComponentPropertyMode = () => {
        return this.props.customizations !== undefined && this.props.customizations.mode === SEARCH_COMPONENT_PROPERTY_MODE;
    }

    isSearchComponentPathMode = () => {
        return this.props.customizations !== undefined && this.props.customizations.mode === SEARCH_COMPONENT_PATH_MODE;
    }

    getUiLabelTranslationFor = (key, defaultValue) => {
        return getUiLabelTranslationFromContext(this, key, defaultValue);
    }

    getTextSearchBlock = () => {
        let {textSearch} = this.props.search
        let {classes, theme, onChange, search, settings, customizations} = this.props
        let {allDatatypeProperties, autoFocus} = this.state

        let inputLabelProps = classes
            ? {
                classes : {
                    shrink: classes.smallWidthLabelShrink,
                    root : classes.largeWidthPropertyLabelRoot
                }
            }
            : {};


        return textSearch && <FieldContainer datatest={'textSearch'} style={{marginTop: theme.spacing(1), padding : '8px 8px 8px 8px'}}>
            <div style={{display : 'flex'}}>
                <div style={{flexGrow : '1'}}/>
            </div>
            {
                this.isSearchComponentBuilderMode() && <>
                    {
                        withWhiteBorder(
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            datatest={'textSearchDisable'}
                                            size={"small"}
                                            checked={textSearch.disable}
                                            value={true}
                                            onChange={() => {
                                                textSearch.disable = !textSearch.disable;
                                                onChange(search);
                                                this.setState({})
                                            }}
                                        ></Switch>
                                    }
                                    label={<Typography style={{color: theme.palette.primary.main}}>Disable Text Search</Typography>}
                                />
                            </div>
                        )
                    }
                    { textSearch.disable || customizations?.hideLabelSettings === true ||  <SearchPaperLabelSettings textSearch={textSearch} theme={theme} onChange={onChange} settings={settings}/>}
                    { textSearch.disable || <>
                        {withWhiteBorder(<div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            datatest={'showInFilter'}

                                            size={"small"}
                                            checked={textSearch.showInFilter || false}
                                            value={true}
                                            onChange={() => {
                                                textSearch.showInFilter = !textSearch.showInFilter;
                                                onChange(search);
                                                this.setState({})
                                            }}
                                        ></Switch>
                                    }
                                    label={<Typography style={{color: theme.palette.primary.main}}>Show In
                                        Filters</Typography>}
                                />
                            <FormHelperText>By default text search is shown at top of the page. By setting this on text search is shown in filter on left.</FormHelperText>
                            </div>)
                        }
                        {
                            withWhiteBorder(
                            <div>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            datatest={'enableLanguageSelect'}
                                            size={"small"}
                                            checked={textSearch.ui && textSearch.ui.enableLanguageSelect}
                                            value={true}
                                            onChange={() => {
                                                if (textSearch.ui === undefined) {
                                                    textSearch.ui = {}
                                                }
                                                textSearch.ui.enableLanguageSelect = !(textSearch.ui && textSearch.ui.enableLanguageSelect);
                                                onChange(search);
                                                this.setState({})
                                            }}
                                            name="idGeneratingClasses"
                                        ></Switch>
                                    }
                                    label={<Typography style={{color: theme.palette.primary.main}}>Enable Language
                                        Selection</Typography>}
                                />
                                <FormHelperText>By setting this on you can enable search language selection.</FormHelperText>
                            </div>)
                        }
                        </>
                    }

                </>
            }
            {   (this.isSearchComponentBuilderMode() && textSearch.disable === true) ||
                <div style={{display : 'flex'}}>
                    {  this.isSearchComponentBuilderMode() ?
                        <div style={{flexGrow  :'1'}}>{
                            withWhiteBorder(<div>
                                <H4Title title={'Search In Properties'}></H4Title>
                            <MultiValueSelect
                                classes={classes}
                                options={allDatatypeProperties}
                                value={textSearch.props}
                                onChange={(event, val) => {
                                    textSearch.props = val
                                    onChange(search)
                                }}
                            />
                            <FormHelperText>By default search is run against all the properties of the resources. Here you
                                can select properties to use for search.</FormHelperText>
                        </div>)
                        }</div>
                        :
                        <div style={{width: '204px', marginRight: '4px'}}>
                            <MultiValueSelect
                                classes={classes}
                                options={allDatatypeProperties}
                                value={textSearch.props}
                                label={this.getUiLabelTranslationFor(UI_LABELS_PROPERTIES)}
                                onChange={(event, val) => {
                                    textSearch.props = val
                                    onChange(search)
                                }}
                            />
                        </div>
                    }
                    {
                     this.isSearchComponentBuilderMode() || <>
                    <div style={{width : '116px', marginRight : '4px'}}>
                        <LanguageSearchSelect
                            datatest={'autocompleteLanguage'}
                            value={textSearch && textSearch.lang ? textSearch.lang : ''}
                            label={this.getUiLabelTranslationFor(UI_LABELS_LANGUAGE)}
                            onChange={(val) => {
                                search.textSearch.lang = val;
                                onChange(search);
                            }}
                            multiple={false}
                        />
                    </div>
                    <div style={{flexGrow : '1'}}>
                        {
                            textSearch.q && textSearch.q.map((v, i) => {
                                return <React.Fragment key={"del-"+v+i}>{
                                    withDelete(
                                        <OtherTextField
                                            datatest={'textField-Value'}
                                            label={this.getUiLabelTranslationFor(UI_LABELS_VALUE)}
                                            key={i}
                                            onChange={(value) => {
                                                textSearch.q[i] = value.target.value
                                                onChange(search)
                                                this.setState({autoFocus:true})
                                            }}
                                            value={v}
                                            autoFocus={autoFocus}
                                            InputLabelProps={inputLabelProps}
                                            fullWidth={true}
                                            margin={"dense"}
                                            variant="outlined"
                                        />,
                                        <Tooltip title={this.getUiLabelTranslationFor(UI_LABELS_REMOVE)}>
                                            <IconButton datatest={'deleteButton'} size={'small'} onClick={() => {
                                                textSearch.q.splice(i, 1);
                                                onChange(search)
                                            }}>
                                                <DeleteIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }</React.Fragment>;
                            })
                        }
                        <div style={{display : 'flex'}}>
                            <div style={{flexGrow : '1'}}></div>
                            <AddButton
                                datatest={'resetAllButton'}
                                onClick={() => {
                                    search.textSearch = {};
                                    this.setState({autoFocus : true})
                                    onChange(search);
                                }}
                                title={this.getUiLabelTranslationFor(UI_LABELS_RESET_ALL)}
                                disabled={!search.textSearch || Object.keys(search.textSearch).length === 0}
                            />
                            <AddButton
                                title={this.getUiLabelTranslationFor(UI_LABELS_ADD)}
                                datatest={'addButton'}
                                onClick={() => {
                                    if(textSearch.q) {
                                        textSearch.q.push('')
                                    } else {
                                        textSearch.q = ['']
                                    }
                                    onChange(search)
                                }}
                            />
                        </div>

                    </div>
                    </>
                    }
                </div>
            }
        </FieldContainer>;

    }

    getAddFilterButtonTitle = (customizations) => {
        return customizations?.addFilterButtonTitle || this.getUiLabelTranslationFor(UI_LABELS_ADD_FILTER);
    }

    render() {
        traceRenderStart( "", COMPONENT)
        let {filters} = this.props.search;
        let {customizations, theme} = this.props;
        return (<div datatest={'filtersTabContent'}>
            {customizations && customizations.hideTextSearch === true ? <></> : this.getTextSearchBlock()}
            {   customizations?.hideFilters === true ? <></> :
                renderBlock(
                    customizations && customizations.title ? customizations.title : this.getUiLabelTranslationFor(UI_LABELS_FILTERS),
                    <>
                        {customizations && customizations.configurationOptionsProvider && customizations.configurationOptionsProvider()}
                        { this.generateTree(filters)}
                        {
                            customizations && customizations.hideAddFilter === true ? <></> :

                            <div style={{display: 'flex'}}>
                                <div style={{flexGrow: '1'}}></div>
                                <Button
                                    datatest={'addFilterButton'}
                                    variant={customizations?.addFilterButtonVariant ||  "contained"}
                                    onClick={() => this.addToFilters(filters)}
                                    color="secondary"
                                >
                                    {this.getAddFilterButtonTitle(customizations)}
                                </Button>
                            </div>
                        }
                    </>,
                    customizations?.noBlockBackgroundColor
                        ? {backgroundColor : 'rgb(238,238,238)'}
                        : this.isSearchComponentPathMode() ? {backgroundColor : theme.palette.white.main} :{}
                )
            }
        </div>);

    }

}

SearchFilter.propTypes = {
    configurations: PropTypes.object.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    aliasesToIRIMap: PropTypes.object,
    ontology: PropTypes.any,
    search: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    customizations: PropTypes.object,
    settings: PropTypes.object,
    browseLanguage : PropTypes.object
}

export default withStyles(styles, {withTheme: true})(SearchFilter);

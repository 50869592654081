import React from "react";
import {withStyles} from "@material-ui/core";
import IconButton from "../components/IconButton";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

export function LeftMenuToggleButton({theme, minimized, onClick}) {
    return <div style={{
        borderLeft: '1px solid',
        borderLeftColor: '#c05335',
        backgroundColor: theme.palette.secondary.dark,
        float: 'right',
        marginTop: theme.spacing(3),
        width: theme.spacing(3)
    }}>
        <IconButton datatest={'leftMenuToggle'} onClick={onClick} style={{padding: '8px 0px'}}
                    size={'small'}>
            {
                minimized
                    ? <ChevronRightIcon datatest={'expandIcon'} style={{color: theme.palette.white.main}}/>
                    : <ChevronLeftIcon datatest={'collapseIcon'} style={{color: theme.palette.white.main}}/>
            }
        </IconButton>
    </div>;


}

export default withStyles({}, {withTheme: true})(LeftMenuToggleButton);

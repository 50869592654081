import {ALIAS_SH_SHAPE, ID, TYPE} from "../Constants";
import {attachToWindowForTesting, isClass, isDatatypeProperty, isObjectProperty} from "../components/util";
import {cloneDeepWith} from 'lodash';
import {ERROR_KEY_SUFFIX} from "../layouts/modelbuilder/Property";
import {traceDebug} from "../components/Trace";

export const QUOTA_ERROR = "QUOTA_ERROR";

export function customizer(value, key) {
    if (['nameError', 'descriptionError', 'dirty'].includes(key) || (key && key.endsWith && key.endsWith(ERROR_KEY_SUFFIX))) {
        return null;
    } else {
        return undefined;
    }
}

function store(key, value) {
    let cloned = cloneDeepWith(value, customizer);
    try {
        let stringValue = JSON.stringify(cloned);
        traceDebug(() => "Storing " + key +" : size "+ stringValue.length, 'model-api.js');
        localStorage.setItem(key, stringValue);
    } catch (e) {
        let isStorageError = e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED');
        if(isStorageError) {
            throw new Error(QUOTA_ERROR);
        }
    }
}

export function getModels() {
    let collections = JSON.parse(localStorage.getItem('models'))
    return collections ? collections : [];
}

export function getModel(id) {
    let collections = getModels()
    return collections.find(c => c[ID] === id);
}

export function storeModels(collections) {
    store('models', collections)
}

export function addModel(collection) {
    storeModels([...getModels(), collection])
}

export function updateModel(collection) {
    let all = getModels()
    let remaining = all.filter(c => c[ID] !== collection[ID])
    storeModels([...remaining, collection])
}

const SHAPES_KEY_SUFFIX = '-shapes';

const CLASS_KEY_SUFFIX = '-ontologyClasses';

const PROPERTY_KEY_SUFFIX = '-ontologyProperties';

export function storeModelAndData(modelDetails, data) {
    let key = modelDetails[ID];
    let shapes = filterShapes(data);
    let ontologyClasses = filterOntologyClasses(data);
    let ontologyProperties = filterOntologyProperties(data);
    store(key+SHAPES_KEY_SUFFIX, shapes);
    store(key+CLASS_KEY_SUFFIX, ontologyClasses);
    store(key+PROPERTY_KEY_SUFFIX, ontologyProperties);
    updateModel(modelDetails);
}


export function getModelData(modelDetail) {
    let key = modelDetail[ID]
    let shapes = localStorage.getItem(key+ SHAPES_KEY_SUFFIX);
    let ontologyClasses = localStorage.getItem(key+ CLASS_KEY_SUFFIX);
    let ontologyProperties = localStorage.getItem(key+ PROPERTY_KEY_SUFFIX);
    let shapesArr = shapes ? JSON.parse(shapes) : [];
    let ontologyClassesArr = ontologyClasses ? JSON.parse(ontologyClasses) : [];
    let ontologyPropertiesArr = ontologyProperties ? JSON.parse(ontologyProperties) : [];
    return [...shapesArr, ...ontologyClassesArr, ...ontologyPropertiesArr];
}

export function filterShapes(modelData) {
    return modelData.filter(d => d[TYPE] === ALIAS_SH_SHAPE);
}

export function getModelShapesData(collection) {
    return filterShapes(getModelData(collection));
}

export function filterOntology(modelData) {
    return modelData.filter(d => isClass(d) || isObjectProperty(d) || isDatatypeProperty(d));
}

export function filterOntologyClasses(modelData) {
    return modelData.filter(d => isClass(d));
}

export function filterOntologyProperties(modelData) {
    return modelData.filter(d => isObjectProperty(d) || isDatatypeProperty(d) );
}

export function getModelOntologyData(collection) {
    return filterOntology(getModelData(collection));
}

export function deleteModel(modelDetails) {
    let all = getModels()
    let modelDetailsID = modelDetails[ID];
    let remaining = all.filter(c => c[ID] !== modelDetailsID)
    storeModels(remaining)
    localStorage.removeItem(modelDetailsID+SHAPES_KEY_SUFFIX);
    localStorage.removeItem(modelDetailsID+CLASS_KEY_SUFFIX);
    localStorage.removeItem(modelDetailsID+PROPERTY_KEY_SUFFIX);
}

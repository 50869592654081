import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import './APIPlayground.css';
import {Button, IconButton as MUIIconButton, TextField as MUITextField} from "@material-ui/core";
import Save from "@material-ui/icons/Save";
import H2Title from "../../components/H2Title";
import {getDirtyIndicator} from "../../components/RequestResponse";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SettingsIcon from '@material-ui/icons/Settings';
import {centerVertically} from "../../components/util";
import CircularProgress from "@material-ui/core/CircularProgress";
import {traceRenderStart} from "../../components/Trace";
import {GreyLockIcon} from "../../components/GreyStyleIcon";
import DescriptionField from "../../components/DescriptionField";


export function getTextField(theme, value, label, onChange, readOnly, inputLabelProps) {
    return <MUITextField
        datatest={'textField-'+label}
        fullWidth
        label={label}
        value={value}
        onChange={(event) => {
            const {target: {value}} = event;
            onChange(value, event);
        }}
        InputProps={{
            endAdornment : readOnly && <GreyLockIcon datatest={'readOnly'} theme={theme}/>,
            readOnly : readOnly
        }}
        InputLabelProps={inputLabelProps}
    /> ;

}

const COMPONENT = "CollectionDetails"

export function getPlayButton(running, disableRunButton, theme, onRun) {
    let shadow = '0 1px 3px rgba(0,0,0,0.2)'

    return <div style={{marginTop: '12px', marginRight: '16px'}}>
        <MUIIconButton
            datatest={'playAllButton'}
            disabled={running || disableRunButton}
            style={{
                boxShadow: shadow,
                borderRadius: '50%',
                backgroundColor: theme.palette.white.main,
                border: '1px solid #ccc'
            }}
            onClick={onRun}
        >
            <PlayArrowIcon

                style={{
                    fontSize: '64px',
                    color: running ? theme.palette.grey.level2 : theme.palette.secondary.main
                }}
            >

            </PlayArrowIcon>
            {running &&
            <CircularProgress datatest={'playAllButtonProgress'} color={"primary"} style={{marginLeft: '-64px'}}
                              size={64}/>}
        </MUIIconButton>
    </div>;
}

class CollectionDetails extends Component {
    constructor(props) {
        super(props);
        let {collectionDetails} = this.props
        this.state = {
            viewEditDescription: false,
            collectionDetails: collectionDetails
        }
    }

    onChange = (collectionDetails) => {
        let {onChange} = this.props
        if(!collectionDetails.dirty) {
            collectionDetails.dirty = true
        }
        onChange && onChange(collectionDetails)
    }

    render() {
        let {viewEditDescription, collectionDetails} = this.state
        let {classes, customizations, titlePropertyKey, descriptionPropertyKey, theme, running, formTitle, readOnly, hideActions, hideSettings, saveTitle, onRun, onSave, onSettings, disableRunButton, renderAtBottom} = this.props;
        let titleKey = titlePropertyKey || 'title';
        let descriptionKey = descriptionPropertyKey || 'description'

        traceRenderStart( () => collectionDetails[titleKey], COMPONENT)
        let inputLabelProps = classes
            ? {
                classes : {
                    root : classes.largeWidthPropertyLabelRoot,
                    shrink: classes.smallWidthLabelShrink
                },
                disabled : false
            }
            : {};

        return <>
            {

                <div datatest={'detailsHeader'} style={{display: 'flex'}}>
                    {formTitle && getDirtyIndicator(collectionDetails)}
                    {formTitle && centerVertically(<H2Title datatest={'detailsTitle'}>{formTitle}</H2Title>)}
                    <div style={{flexGrow: '1'}}></div>
                    {
                        readOnly || hideSettings || (onSettings && centerVertically(<MUIIconButton datatest={'settingsButton'} size={'small'} onClick={onSettings}><SettingsIcon/></MUIIconButton>))
                    }
                    {   hideActions || readOnly ||
                            <Button
                                datatest={'saveButton'}
                                disabled={collectionDetails.dirty === true ? false : true }
                                color={"secondary"}
                                variant={"text"}
                                onClick={() => onSave(collectionDetails)}
                            >{saveTitle}</Button>
                    }
                </div>
            }
            <div style={{display: 'flex'}}>
                {
                    readOnly || (onRun && getPlayButton(running, disableRunButton, theme, onRun))
                }
                <div style={{flexGrow : '1'}}>
                    {
                        customizations?.hideNameInDetails ||
                        getTextField(
                            theme,
                            collectionDetails[titleKey],
                            'Name',
                            (value) => {
                                let titleKey = titlePropertyKey || 'title'
                                collectionDetails[titleKey] = value
                                this.setState({collectionDetails})
                                this.onChange(collectionDetails)
                            },
                            readOnly,
                            inputLabelProps
                        )
                    }
                    {
                        customizations?.hideDescriptionInDetails ||
                        <DescriptionField
                            readOnly={readOnly}
                            fullWidth={true}
                            value={collectionDetails[descriptionKey]}
                            onChange={
                                (value) => {
                                    collectionDetails[descriptionKey] = value;
                                    this.setState({collectionDetails});
                                    this.onChange(collectionDetails);
                                }
                            }
                            inputLabelProps={{
                                shrink: true
                            }}
                        />
                    }
                    {
                         renderAtBottom
                    }
                </div>
            </div>
        </>;
    }

}

CollectionDetails.defaultProps = {
    saveTitle: 'Save',
    disableRunButton: false
};

CollectionDetails.propTypes = {
    readOnly : PropTypes.bool,
    onSave: PropTypes.func,
    onChange: PropTypes.func,
    onRun: PropTypes.func,
    disableRunButton: PropTypes.bool,
    running: PropTypes.bool,
    onSettings: PropTypes.func,
    collectionDetails: PropTypes.object,
    formTitle: PropTypes.any,
    saveTitle: PropTypes.string,
    titlePropertyKey: PropTypes.string,
    descriptionPropertyKey: PropTypes.string,
    hideActions : PropTypes.bool,
    hideSettings : PropTypes.bool,
    renderAtBottom : PropTypes.object,
    customizations : PropTypes.object,

};

export default withStyles(styles, {withTheme: true})(CollectionDetails);

import YASQE from "@triply/yasqe";

export const classesAutocomplete = {

    isValidCompletionPosition: function (yasqe) {
        return YASQE.Autocompleters.class.isValidCompletionPosition(yasqe);
    },
    preProcessToken: function (yasqe, token) {
        return YASQE.Autocompleters.class.preProcessToken(yasqe, token);
    },
    postProcessSuggestion: function (yasqe, token, suggestedString) {
        return YASQE.Autocompleters.class.postProcessSuggestion(yasqe, token, suggestedString);
    },
    name: "classesAutocomplete",
    bulk: true,
    async: false,
    autoShow: true,
    persistent: false,
    get: function (yasqe) {
        return yasqe.options.classList;
    }

};

export const propertiesAutocomplete = {

    isValidCompletionPosition: function (yasqe) {
        return YASQE.Autocompleters.property.isValidCompletionPosition(yasqe);
    },
    preProcessToken: function (yasqe, token) {
        return YASQE.Autocompleters.property.preProcessToken(yasqe, token);
    },
    postProcessSuggestion: function (yasqe, token, suggestedString) {
        return YASQE.Autocompleters.property.postProcessSuggestion(yasqe, token, suggestedString);
    },
    name: "propertiesAutocomplete",
    bulk: true,
    async: false,
    autoShow: true,
    persistent: false,
    get: function (yasqe) {
        return yasqe.options.propertyList;
    }

};

const namespaces = {
    "http://www.w3.org/2001/XMLSchema#": "xsd",
    "http://www.w3.org/2004/02/skos/core#": "skos",
    "http://www.w3.org/2008/05/skos-xl#": "skosxl",
    "http://purl.org/dc/terms/": "dct",
    "http://www.w3.org/1999/02/22-rdf-syntax-ns#": "rdf",
    "http://www.w3.org/2000/01/rdf-schema#": "rdfs",
    "http://www.w3.org/2002/07/owl#": "owl",
};
const tokenTypes = {
    "string-2": "prefixed",
    "atom": "var",
};

const NS_MAPPING = Object.keys(namespaces).map((key) => namespaces[key] + ": <" + key + ">").sort();

export const prefixAutocomplete = {

    onInitialize: function (yasqe) {
        yasqe.on("change", () => {
            var cur = yasqe.getDoc().getCursor();
            var token = yasqe.getTokenAt(cur);
            if (token.type && tokenTypes[token.type] == "prefixed") {
                var colonIndex = token.string.indexOf(":");
                if (colonIndex !== -1) {
                    // check previous token isnt PREFIX, or a '<'(which would mean we are in a uri)
                    //			var firstTokenString = yasqe.getNextNonWsToken(cur.line).string.toUpperCase();
                    var lastNonWsTokenString = yasqe.getPreviousNonWsToken(cur.line, token).string.toUpperCase();
                    var previousToken = yasqe.getTokenAt({
                        line: cur.line,
                        ch: token.start,
                    }); // needs to be null (beginning of line), or whitespace

                    if (
                        lastNonWsTokenString !== "PREFIX" &&
                        (previousToken.type == "ws" ||
                            previousToken.type == null ||
                            (previousToken.type === "punc" &&
                                (previousToken.string === "|" ||
                                    previousToken.string === "/" ||
                                    previousToken.string == "^^" ||
                                    previousToken.string == "{" ||
                                    previousToken.string === "(")))
                    ) {
                        // check whether it isn't defined already (saves us from looping
                        // through the array)
                        var currentPrefix = token.string.substring(0, colonIndex + 1);
                        var queryPrefixes = yasqe.getPrefixesFromQuery();
                        if (queryPrefixes[currentPrefix.slice(0, -1)] == null) {
                            // ok, so it isn't added yet!
                            token.autocompletionString = currentPrefix;
                            yasqe.autocompleters[this.name]?.getCompletions(token).then((suggestions) => {
                                if (suggestions.length) {
                                    yasqe.addPrefixes(suggestions[0]);
                                    // Re-activate auto-completer after adding prefixes, so another auto-completer can kick in
                                    yasqe.autocomplete();
                                }
                            }, console.warn);
                        }
                    }
                }
            }
        });
    },
    isValidCompletionPosition: function (yasqe) {
        return YASQE.Autocompleters.prefixes.isValidCompletionPosition(yasqe);
    },
    preProcessToken: function (yasqe, token) {
        return YASQE.Autocompleters.prefixes.preProcessToken(yasqe, token);
    },
    name: "prefixAutocomplete",
    bulk: true,
    async: false,
    autoShow: true,
    persistent: false,
    get: function () {
        return NS_MAPPING;
    }

};

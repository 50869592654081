import React, {Component} from "react";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import GridContainer from "../../components/GridContainer";
import Grid from "@material-ui/core/Grid";
import {IconButton, Tooltip} from "@material-ui/core";
import PageEditor from "./PageEditor";
import {getDefaultLanguage} from "./WorkspaceSettingsDialog";
import {
    centerVertically,
    getGraph,
    getMultilingualValue,
    getSearchResult,
    handleBackendError,
    isEmptyArray,
    isRequestSuccessful,
    toArray
} from "../../components/util";
import MainHeaderBar from "../../components/MainHeaderBar";
import {AddCircleOutline, DeleteOutline, EditOutlined, SwapHorizOutlined} from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import {convertFromRaw, convertToRaw, EditorState} from "draft-js";
import {
    ALIAS_MANAGEMENT_FOR_APPLICATION,
    ALIAS_MANAGEMENT_NAME,
    ALIAS_MANAGEMENT_IS_PART_OF,
    ALIAS_MANAGEMENT_LINKED_TO,
    ALIAS_MANAGEMENT_SETTINGS,
    ALIAS_MANAGEMENT_TITLE,
    ALIAS_MANAGEMENT_TYPE_CONFIGURATION,
    ALIAS_SYS_ETAG,
    ALIAS_SYS_EXAMPLE_TYPE_BATCH,
    ALIAS_SYS_EXAMPLE_TYPE_CREATE,
    ALIAS_SYS_EXAMPLE_TYPE_DELETE,
    ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA,
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    ALIAS_SYS_EXAMPLE_TYPE_UPDATE,
    AT_CONTEXT,
    COUNT_UPTO,
    DATA,
    EASYGRAPH_DATA_APPLICATION_EXPLORER_PAGE,
    EASYGRAPH_DATA_NAMESPACE,
    HTTP_HEADER_PREFER,
    ID,
    MIXIN,
    PAGE,
    PAGE_SIZE,
    PATHS,
    QUERY,
    TYPE, ALIAS_MANAGEMENT_HAS_PART
} from "../../Constants";
import {
    deleteManagementGraph,
    getBaseEndpointWithInstance,
    getData,
    getManagementContextURL,
    patchManagementGraph,
    postManagementGraph
} from "../../service/graph-api";
import uuid4 from "uuid/v4";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import {
    BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION,
    BACKEND_PATH_MANAGEMENT_GRAPH_SEARCH
} from "../../service/backend-paths";
import PageView from "./PageView";
import ProcessingBackdrop from "../../components/ProcessingBackdrop";
import ConfirmDelete from "../../components/ConfirmDeleteDialog";
import {setLinkedToId, WORKSPACE_PREFER_HEADER_VALUE} from "./Workspaces";
import PageTitleDialog from "./PageTitleDialog";

export const TYPE_GRID = 'TYPE_GRID';
export const TYPE_CONTENT = 'TYPE_CONTENT';
export const TYPE_DATA_VISUALISATION = 'TYPE_DATA_VISUALISATION';
export const TYPE_VISUALISATION = 'TYPE_VISUALISATION';
export const TYPE_SPREADSHEET = 'TYPE_SPREADSHEET';
export const TYPE_LINKS_TO_PAGES_BLOCK = 'TYPE_LINKS_TO_PAGES_BLOCK';
export const HAS_PARTS = 'hasParts';
export const PART_DATA = 'data';

const EXAMPLE_REQUEST_TYPES = [
    ALIAS_SYS_EXAMPLE_TYPE_SEARCH,
    ALIAS_SYS_EXAMPLE_TYPE_RESET_DATA,
    ALIAS_SYS_EXAMPLE_TYPE_CREATE,
    ALIAS_SYS_EXAMPLE_TYPE_UPDATE,
    ALIAS_SYS_EXAMPLE_TYPE_DELETE,
    ALIAS_SYS_EXAMPLE_TYPE_BATCH
];

export function isExampleRequest(p) {
    return EXAMPLE_REQUEST_TYPES.includes(p[TYPE]) ;
};

export function processForOpen(part) {
    if (part[TYPE] === TYPE_CONTENT) {
        Object.keys(part[PART_DATA]).forEach(k => {
            let contentState = part[PART_DATA][k];
            let contentStateConverted ;
            try {
                //If somehow object is in the wrong state try to convert to correct content type
                if(contentState._immutable && contentState._immutable.currentContent) {
                    let s = contentState._immutable.currentContent;
                    let content = {
                        entityMap: s.entityMap,
                        blocks : Object.keys(s.blockMap).map(k => s.blockMap[k])
                    }
                    contentStateConverted = convertFromRaw(content);
                    let convertedContentState = EditorState.createWithContent(contentStateConverted);
                    part[PART_DATA][k] = convertedContentState;
                } else {
                    contentStateConverted = convertFromRaw(contentState);
                    let convertedContentState = EditorState.createWithContent(contentStateConverted);
                    part[PART_DATA][k] = convertedContentState;
                }
            } catch (e) {
                contentStateConverted = contentState;
            }

        })
    } else if (part[TYPE] === TYPE_GRID) {
        toArray(part[PART_DATA]).forEach(p => processForOpen(p));
    }
}

export function processForSave(part) {
    if (part[TYPE] === TYPE_CONTENT) {
        Object.keys(part[PART_DATA]).forEach(valueKey => {
            let contentState = part[PART_DATA][valueKey];
            part[PART_DATA][valueKey] = convertToRaw(contentState.getCurrentContent());
        })
    }
    if(part[TYPE] === TYPE_GRID) {
        toArray(part[PART_DATA]).forEach(p => processForSave(p));
    }
}

export async function loadPageWorkspace(pageWorkspaceId, withSettings = true) {
    const workspaceSearchParams = {
        [ID] : pageWorkspaceId
    }
    let headerMap = {};
    if(withSettings === false) {
        headerMap[HTTP_HEADER_PREFER] = WORKSPACE_PREFER_HEADER_VALUE;
    }
    const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, headerMap).catch(handleBackendError(this));
    if(!isRequestSuccessful(workspaceSearchResult)) {
        return Promise.reject(workspaceSearchResult);
    }
    let workspaceJSON = await workspaceSearchResult.json();
    let pageWorkspace = getSearchResult(workspaceJSON).find(obj => obj[ID] === pageWorkspaceId);
    let pageWorkspaceSettings;
    if(withSettings === true && pageWorkspace) {
        pageWorkspaceSettings = JSON.parse(pageWorkspace[ALIAS_MANAGEMENT_SETTINGS]);
        toArray(pageWorkspaceSettings[HAS_PARTS]).forEach(part => {
            processForOpen(part);
        });
    }
    return {
        pageWorkspace : pageWorkspace,
        pageWorkspaceSettings : pageWorkspaceSettings
    }

};

export async function getPagesForWorkspace(workspace) {
    const searchParams = {
        [ALIAS_MANAGEMENT_IS_PART_OF] : workspace[ID]
    }
    let headerMap = {
        [HTTP_HEADER_PREFER] : `return=representation;${MIXIN}="{ ${ID} ${ALIAS_SYS_ETAG} ${TYPE} ${ALIAS_MANAGEMENT_TITLE} ${ALIAS_MANAGEMENT_NAME}}";${DATA}=${PATHS}`
    }

    return new Promise((resolve, reject) => {
        getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, searchParams, headerMap, false)
        .then(pageObjectsResults => {
            if(isRequestSuccessful(pageObjectsResults)) {
                pageObjectsResults.json().then(json => {
                    let pageObjects = getSearchResult(json);
                    resolve(pageObjects);
                })
            } else {
                resolve([]);
            }
        })
        .catch(reject);
    })
}

export function ConfirmAndSave({saveWarning, handleCancel, handleOk}) {
    return <ConfirmDelete
        data={{value: saveWarning}}
        title={'Warning'}
        cancelButtonTitle={'Cancel'}
        okButtonTitle={'OK'}
        handleCancel={handleCancel}
        handleOk={handleOk}
        open={true}
    />;
}

export function getNewIdForWorkspace(uuid) {
    return EASYGRAPH_DATA_NAMESPACE + 'configuration/' + uuid;
}

class PagesSetup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pagesMenuTabValue : 0,
            uuid : ''
        }
    }

    async componentDidMount() {
        let {settings, workspace} = this.props;
        let pageObjects = await this.syncPagesForWorkspace();
        this.setDefaultPageSelection(settings, pageObjects);
    }

    getPagesArray = () => {
        let {settings, pageArrayKey} = this.props;
        let settingPages = toArray(settings?.[pageArrayKey]);
        return settingPages;
    }

    setPagesArray = (pagesArray) => {
        let {settings, pageArrayKey} = this.props;
        settings[pageArrayKey] = pagesArray;
    }

    setDefaultPageSelection = (settings, pageObjects) => {
        let settingPages = this.getPagesArray();
        if (!isEmptyArray(settingPages)) {
            let pageWorkspaceId = settingPages.find(sp => {
                return pageObjects.find(po => po[ID] === sp);
            })
            if(pageWorkspaceId) {
                this.loadWorkspaceForPage(pageWorkspaceId).catch(handleBackendError(this));
            }
        } else {
            this.setState({pageWorkspace : undefined, pageWorkspaceSettings : undefined});
        }
    }

    syncPagesForWorkspace = async () => {
        let {workspace} = this.props;
        let pageObjects = await getPagesForWorkspace(workspace);
        let uuid = uuid4();
        this.setState({
            pageObjects: pageObjects,
            loading: false,
            uuid : uuid
        });
        return pageObjects;
    }

    loadWorkspaceForPage = async (id) => {
        let page = await loadPageWorkspace(id);
        let uuid = uuid4();
        this.setState({...page, uuid : uuid});
    }

    addNewPageWorkspace = async (obj) => {
        const {workspace} = this.props;
        obj[AT_CONTEXT] = getManagementContextURL();
        obj[TYPE] = ALIAS_MANAGEMENT_TYPE_CONFIGURATION;
        obj[ALIAS_MANAGEMENT_FOR_APPLICATION] = EASYGRAPH_DATA_APPLICATION_EXPLORER_PAGE;
        setLinkedToId(obj, workspace[ID]);
        obj[ALIAS_MANAGEMENT_IS_PART_OF] = workspace[ID];
        let settings = {}
        obj[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings)
        return new Promise((resolve, reject) => {
            postManagementGraph(JSON.stringify(obj)).then(d => {
                if(isRequestSuccessful(d)) {
                    d.json().then(j => {
                        resolve(j);
                    })
                } else {
                    reject(d);
                }
            })
        })
    }

    saveSettings = async () => {
        let {pageWorkspace, pageWorkspaceSettings} = this.state;

        let {onSaveSettings} = this.props;
        this.setState({loading:true});
        delete pageWorkspace.dirty;
        pageWorkspaceSettings && toArray(pageWorkspaceSettings[HAS_PARTS]).map((part, index) => {
            processForSave(part);
        });
        pageWorkspace[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(pageWorkspaceSettings);
        await this.saveWorkspace(pageWorkspace);
        await this.loadWorkspaceForPage(pageWorkspace[ID]);
        this.setState({loading : false});
    }

    saveWorkspace = async (pageWorkspace) => {
        let pageWorkspaceUpdated = await this.patchWorkspace(pageWorkspace);
        this.mergePageWorkspace(pageWorkspaceUpdated);
        return pageWorkspaceUpdated;
    }

    mergePageWorkspace = (updatedPageWorkspace) => {
        let {pageWorkspace, pageObjects} = this.state;
        let updateArray = [...toArray(pageObjects)];
        if(pageWorkspace) {
            updateArray.push(pageWorkspace);
        }
        [updateArray].forEach(po => {
            if(po[ID] === updatedPageWorkspace[ID]) {
                Object.keys(updatedPageWorkspace).forEach(k => {
                    let value = updatedPageWorkspace[k];
                    po[k] = value;
                })
            }
        })
    }

    patchWorkspace = (pageWorkspace) => {
        pageWorkspace[AT_CONTEXT] = getManagementContextURL();
        return new Promise((resolve, reject) => {
            patchManagementGraph(JSON.stringify(pageWorkspace)).then(response => {
                if (isRequestSuccessful(response)) {
                    response.json().then(responseJson => {
                        let graph = getGraph(responseJson);
                        let found = graph.find(r => r[ID] === pageWorkspace[ID]);
                        resolve(found);
                    })
                } else {
                    this.setState({loading: false, apiError: true, apiErrorResponse: response});
                }
            })
        })
    }

    getPagesTabs = () => {
        const {theme, settings, isQuickPageEdit, location} = this.props;
        const {pageObjects, pageWorkspace} = this.state;


        let orderedPageObjects = pageObjects && this.getPagesArray().map(sp => pageObjects.find(po => po[ID] === sp)).filter(o => o);

        return <>
            <div style={{display: 'flex'}}>
                {
                    pageObjects && orderedPageObjects.map((po, k) => {
                        let pageId = po[ID];
                        let isLast = this.getPagesArray().length - 1 === k;
                        let style = pageWorkspace?.[ID] === pageId && isQuickPageEdit !== true
                            ? {borderBottom: '2px solid', borderBottomColor: theme.palette.secondary.main}
                            : {}
                        let pageObject = pageObjects.find(po => po[ID] === pageId)
                        let title = getMultilingualValue(pageObject[ALIAS_MANAGEMENT_TITLE], getDefaultLanguage(settings));

                        return <React.Fragment key={pageId}>
                            <div style={{marginRight: '8px', paddingLeft :'8px', border : '1px solid', borderColor : theme.palette.primary.main, borderRadius : '4px', display: 'flex', ...style}}>
                                {
                                    centerVertically(
                                        <Tooltip title={'Open Page'}>
                                            <span datatest={'pageTab-'+title} onClick={isQuickPageEdit ? undefined : () => {
                                                this.loadWorkspaceForPage(pageId).then(r => {
                                                    this.setState({pagesMenuTabValue: k})
                                                })
                                            }}>{title}</span>
                                        </Tooltip>,
                                        {cursor: isQuickPageEdit ? undefined: 'pointer'}
                                    )
                                }
                                {
                                    centerVertically(
                                        <Tooltip title={'Edit Page Title'}>
                                            <IconButton disabled={location.pathname.endsWith('/page/'+title)} datatest={'editPage-'+title} size={'small'} onClick={ () => {
                                                this.loadWorkspaceForPage(pageId).then(r => {
                                                    this.setState({pagesMenuTabValue: k, editPageTitleId: pageId})
                                                })
                                            }}>
                                                <EditOutlined fontSize={'small'}></EditOutlined>
                                            </IconButton>
                                        </Tooltip>,
                                        {marginRight : '4px', marginLeft : '4px'}
                                    )
                                }
                                {
                                    isQuickPageEdit &&
                                    centerVertically(
                                        <Tooltip title={'Delete Page'}>
                                            <IconButton disabled={location.pathname.endsWith('/page/'+title)}  datatest={'deletePage-'+title} size={'small'} onClick={ () => {
                                                this.setState({confirmDeletePageId : pageId})
                                            }}>
                                                <DeleteOutline fontSize={'small'}></DeleteOutline>
                                            </IconButton>
                                        </Tooltip>,
                                        {marginRight : '4px'}
                                    )
                                }
                            </div>
                            { this.props.pageArrayKey === 'pages' ? isLast ||
                            centerVertically(
                                <Tooltip title={'Swap Pages'}>
                                    <IconButton size={'small'} onClick={async (e) => {
                                        this.handleSwapPage(pageId)
                                    }}>
                                        <SwapHorizOutlined></SwapHorizOutlined>
                                    </IconButton>
                                </Tooltip>
                                , {margin: '0px 20px 0px 8px'})
                             : <></>}
                        </React.Fragment>
                    })

                }
                {
                    pageObjects !== undefined &&
                        <Button
                            style={{marginLeft : '20px'}}
                            size={'small'}
                            onClick={() => this.setState({addPageDialogOpen: true})}
                            variant={'contained'}
                            color={'secondary'}
                            startIcon={<AddCircleOutline/>}
                        >Add Page</Button>
                }
            </div>

        </>;
    }

    handleAddPage = async (pageWorkspaceTitles) => {
        const {settings, workspace, onChange} = this.props;
        let {pageObjects} = this.state;
        const uuid = uuid4();
        let newIdForWorkspace = getNewIdForWorkspace(uuid);
        let newPageWorkspace = {
            [ID]: newIdForWorkspace,
            [ALIAS_MANAGEMENT_NAME]: 'Page_'+workspace[ALIAS_MANAGEMENT_NAME],
            [ALIAS_MANAGEMENT_TITLE]: pageWorkspaceTitles
        }
        //Close the dialog and show loading
        this.setState({loading : true});
        this.setState({addPageDialogOpen : undefined});

        let newPageWorkspaceUpdate = await this.addNewPageWorkspace(newPageWorkspace)
        // Fetch latest etag as adding new page above would have changed etag of parent
        let parentWorkspace = await loadPageWorkspace(workspace[ID], false);

        //Sync with pageobject just in case there was some failures in previous calls
        this.setPagesArray(this.getPagesArray().filter(p => pageObjects.find(po => po[ID] === p)));
        //Push to pages to maintain order
        this.getPagesArray().push(newIdForWorkspace);
        workspace[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings);

        //Save the parent workspace settings and update etag
        let parentWorkspacePatch = {
            [ID] : workspace[ID],
            [ALIAS_SYS_ETAG] : parentWorkspace.pageWorkspace[ALIAS_SYS_ETAG],
            [ALIAS_MANAGEMENT_SETTINGS] : workspace[ALIAS_MANAGEMENT_SETTINGS]
        }
        let pageWorkspaceUpdated = await this.patchWorkspace(parentWorkspacePatch);

        //Fetch parent configuration and update
        await this.updateParentWorkspace();

        //set etag and update local state with new workspace
        let found = getGraph(newPageWorkspaceUpdate).find(ob => ob[ID] === newIdForWorkspace);
        newPageWorkspace[ALIAS_SYS_ETAG] = found[ALIAS_SYS_ETAG];
        pageObjects.push(newPageWorkspace);
        let pageWorkspaceSettings = JSON.parse(newPageWorkspace.settings);

        this.setState({pageWorkspace : newPageWorkspace, pageWorkspaceSettings})
        this.setState({loading : false});
        onChange && onChange();
    }

    updateParentWorkspace = async () => {
        let {workspace} = this.props;
        //Fetch parent configuration and update
        const workspaceSearchParams = {
            [ID] : workspace[ID]
        }
        const workspaceSearchResult = await getData(getBaseEndpointWithInstance(), BACKEND_PATH_MANAGEMENT_APP_CONFIGURATION, workspaceSearchParams, {}, false).catch(handleBackendError(this));
        let updatedWorkspaceResult = await workspaceSearchResult.json();
        let updatedWorkspace = getSearchResult(updatedWorkspaceResult).find(obj => obj[ID] === workspace[ID]);

        workspace[ALIAS_SYS_ETAG] = updatedWorkspace[ALIAS_SYS_ETAG];
        if(toArray(updatedWorkspace[ALIAS_MANAGEMENT_HAS_PART]).length > 0) {
            workspace[ALIAS_MANAGEMENT_HAS_PART] = updatedWorkspace[ALIAS_MANAGEMENT_HAS_PART];
        } else {
            delete workspace[ALIAS_MANAGEMENT_HAS_PART];
        }

    }

    handleDeletePage = async (deletePageId) => {
        let {settings, workspace, onChange} = this.props;
        let {pageObjects} = this.state;

        //Close the dialog and show loading
        this.setState({loading : true});
        this.setState({confirmDeletePageId : undefined})

        //First delete pageWorkspace
        let deletePageObject = pageObjects.find(po => po[ID] === deletePageId);
        await deleteManagementGraph({
            [AT_CONTEXT] : getManagementContextURL(),
            [ID] : deletePageObject[ID],
            [ALIAS_SYS_ETAG] : deletePageObject[ALIAS_SYS_ETAG]
        });

        // Fetch latest etag as adding new page above would have changed etag of parent
        let parentWorkspace = await loadPageWorkspace(workspace[ID], false);

        //Sync settings with pageobjects just in case there was some failures in previous calls
        //and remove the deleted page object
        this.setPagesArray(this.getPagesArray().filter(p => p !== deletePageId && pageObjects.find(po => po[ID] === p)));
        workspace[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings);

        //Save the parent workspace with changed settings and update etag
        let parentWorkspacePatch = {
            [ID] : workspace[ID],
            [ALIAS_SYS_ETAG] : parentWorkspace.pageWorkspace[ALIAS_SYS_ETAG],
            [ALIAS_MANAGEMENT_SETTINGS] : workspace[ALIAS_MANAGEMENT_SETTINGS]
        }
        let pageWorkspaceUpdated = await this.patchWorkspace(parentWorkspacePatch);
        //Fetch parent configuration and update
        await this.updateParentWorkspace();

        //Update local pageObjects and set default
        pageObjects =  pageObjects.filter(po => po[ID] !== deletePageId);
        this.setDefaultPageSelection(settings, pageObjects);
        this.setState({loading : false, pageObjects : pageObjects});
        onChange && onChange();
    }

    handleSwapPage = async (pageId) => {
        let {settings, workspace, onChange} = this.props;

        this.setState({loading : true});
        const pagesArray = this.getPagesArray();
        let currentPageIndex = pagesArray.indexOf(pageId);
        let nextPageIndex = currentPageIndex + 1;
        let current = pagesArray[currentPageIndex];
        let next = pagesArray[nextPageIndex];
        pagesArray[currentPageIndex] = next;
        pagesArray[nextPageIndex] = current;

        //Set new settings
        workspace[ALIAS_MANAGEMENT_SETTINGS] = JSON.stringify(settings);

        //Save the parent workspace with changed settings and update etag
        let parentWorkspacePatch = {
            [ID] : workspace[ID],
            [ALIAS_SYS_ETAG] : workspace[ALIAS_SYS_ETAG],
            [ALIAS_MANAGEMENT_SETTINGS] : workspace[ALIAS_MANAGEMENT_SETTINGS]
        }
        let pageWorkspaceUpdated = await this.patchWorkspace(parentWorkspacePatch);
        workspace[ALIAS_SYS_ETAG] = pageWorkspaceUpdated[ALIAS_SYS_ETAG];

        this.setState({loading : false});
        onChange && onChange();
    }

    handlePageTitleEdit = async (valueObject) => {
        let {editPageTitleId, pageObjects} = this.state;
        let {onChange} = this.props;
        this.setState({editPageTitleId : undefined, loading : true});
        let po = pageObjects.find(po => po[ID] === editPageTitleId);
        let patchObject = {
            [ID]: editPageTitleId,
            [ALIAS_MANAGEMENT_TITLE]: valueObject,
            [ALIAS_SYS_ETAG]: po[ALIAS_SYS_ETAG]
        }
        await this.saveWorkspace(patchObject);
        await this.loadWorkspaceForPage(editPageTitleId);
        await this.syncPagesForWorkspace();
        this.setState({ loading : false});
        onChange && onChange();
    }


    editPageDialog = () => {
        let {pageWorkspace, pageWorkspaceSettings, editPageDialogOpen} = this.state;
        let {workspace, onExpand, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, shapes, location} = this.props;

        return pageWorkspaceSettings && <PageEditor
            editPageDialogOpen={editPageDialogOpen}
            pageWorkspace={pageWorkspace}
            pageWorkspaceSettings={pageWorkspaceSettings}
            workspace={workspace}
            settings={settings}
            aliasesMap={aliasesMap}
            aliasesToIRIMap={aliasesToIRIMap}
            browseLanguage={browseLanguage}
            configurations={configurations}
            ontology={ontology}
            shapes={shapes}
            location={location}
            onSave={this.saveSettings}
            onExpand={onExpand}
            onClose={async () =>  {
                await this.loadWorkspaceForPage(pageWorkspace[ID]).then(() => {
                    this.setState({editPageDialogOpen: false});
                })
            }}
        />;
    }

    editPageTitleDialog = () => {
        let {settings} = this.props;
        let {editPageTitleId, pageObjects} = this.state;
        let {pageWorkspace} = this.state;
        return <>
            {
                editPageTitleId &&
                    <PageTitleDialog
                        settings={settings}
                        pageWorkspace={pageWorkspace}
                        onCancel={() => this.setState({editPageTitleId: undefined})}
                        onSave={this.handlePageTitleEdit}
                    />
            }
        </>;
    }

    addPageDialog = () => {
        let {settings} = this.props;
        let {addPageDialogOpen} = this.state;
        return <>
            {
                addPageDialogOpen &&
                <PageTitleDialog
                    settings={settings}
                    onCancel={() => this.setState({addPageDialogOpen: undefined})}
                    onSave={this.handleAddPage}
                />
            }
        </>;
    }


    renderContent = () => {
        let { uuid, apiError, apiErrorResponse, pageWorkspaceSettings, pageWorkspace} = this.state;
        let {stepName, workspace, onExpand, settings, aliasesMap, aliasesToIRIMap, browseLanguage, configurations, ontology, shapes, location, isQuickPageEdit} = this.props;
        let pageWorkspaceId = pageWorkspace && pageWorkspace[ID];

        return <GridContainer key={(pageWorkspaceId || '') + uuid}>
            <Grid item xs={12} style={{paddingTop: 0}}>
                <MainHeaderBar title={stepName}></MainHeaderBar>
                {apiError &&
                    <BackendErrorDialog open={apiError} error={apiErrorResponse} handleClose={() => this.setState({
                        apiError: false,
                        apiErrorResponse: undefined
                    })}/>}
            </Grid>
            <Grid item xs={12}>
                {
                    this.getPagesTabs()
                }
                {
                    pageWorkspaceSettings && <>
                        {
                            isQuickPageEdit ? <></> :
                                <div style={{margin: '24px 0px'}}>
                                    {
                                        <Tooltip title={'To edit content of the page open the page in editor.'}>
                                            <Button style={{marginRight: '16px'}} size={'small'}
                                                    onClick={() => this.setState({editPageDialogOpen: true})}
                                                    variant={'contained'} color={'secondary'}>Open Page Editor</Button>
                                        </Tooltip>
                                    }
                                    <Button variant={'outlined'} size={'small'} onClick={
                                        () => this.setState({confirmDeletePageId: pageWorkspaceId})
                                    }>Delete Page</Button>
                                </div>
                        }
                        {
                            isQuickPageEdit ? <></> :
                                <div key={pageWorkspaceId}>
                                    <PageView
                                        settings={settings}
                                        aliasesMap={aliasesMap}
                                        browseLanguage={browseLanguage}
                                        pageWorkspaceId={pageWorkspaceId}
                                        aliasesToIRIMap={aliasesToIRIMap}
                                        configurations={configurations}
                                        ontology={ontology}
                                        shapes={shapes}
                                        location={location}
                                        onExpand={onExpand}
                                        workspace={workspace}
                                        isPreview={true}
                                    />
                                </div>
                        }
                    </>
                }

            </Grid>
        </GridContainer>;
    }

    render() {
        let {loading, editPageDialogOpen, confirmDeletePageId, addPageDialogOpen, editPageTitleId} = this.state;
        let {isQuickPageEdit} = this.props;
        return <>
            {loading && <ProcessingBackdrop loading={loading}/>}
            {
                confirmDeletePageId && <ConfirmAndSave
                    handleOk={() => this.handleDeletePage(confirmDeletePageId)}
                    handleCancel={() => this.setState({confirmDeletePageId: undefined})}
                    saveWarning={'Before deleting page all other changes will be saved. If you want do not want to save other changes refresh the page and then delete the page.'}
                />
            }
            {
                editPageTitleId && this.editPageTitleDialog()
            }
            {
                addPageDialogOpen && this.addPageDialog()
            }
            {
                editPageDialogOpen && this.editPageDialog()
            }
            { isQuickPageEdit ? this.getPagesTabs() : this.renderContent()}
        </>;

    }

}

PagesSetup.propTypes = {
    workspace: PropTypes.object,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    settings: PropTypes.object,
    pageArrayKey: PropTypes.string,
    onChange: PropTypes.func,
    stepName: PropTypes.string,
    browseLanguage: PropTypes.any,
    saveParentWorkspaceAndSettings: PropTypes.func,
    configurations: PropTypes.object,
    ontology: PropTypes.array,
    shapes: PropTypes.array,
    location: PropTypes.object,
    onExpand: PropTypes.func,
    isQuickPageEdit: PropTypes.func

};

export default withStyles(styles, {withTheme: true})(PagesSetup);

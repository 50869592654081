import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import {Button, TextField as OtherTextField, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddOutlined";
import {getAllConfigurations} from "../../service/graph-api";
import {
    addBorder,
    centerVertically,
    computeAllSubClasses,
    createFormFromShape,
    flatten,
    flattenRecursive,
    getAliasesMap,
    getContainerData,
    getIdGeneratingClassIRIsSetWithSubclasses,
    getOntologyClasses,
    getPropertyName,
    getShapePropertyArray,
    getShapesData,
    invokeMock,
    renderFormFromShape,
    searchInTree,
    sort,
    sortShapeProperties
} from "../../components/util";
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_PATH,
    ALIAS_SH_PROPERTY,
    ALIAS_SH_TARGET_CLASS,
    ALIAS_SYS_CLASS_IRI,
    API_MODE_HTTP_API,
    ID,
    RDF_LANGSTRING,
    RENDER_MODE_SITE,
    TYPE
} from "../../Constants";
import '../../layouts/apiplayground/APIPlayground.css';
import InstructionForAction from "../../components/InstructionForAction";
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import 'codemirror/addon/hint/show-hint';
import 'codemirror/addon/lint/lint';
import 'codemirror-graphql/hint';
import 'codemirror-graphql/lint';
import 'codemirror-graphql/mode';
import FieldContainer from "../../components/FieldContainer";
import GlobalsContext from "../../components/GlobalsContext";
import FieldLabel from "../../components/ShapeToForm/FieldLabel";
import UnfoldMoreIcon from "@material-ui/icons/UnfoldMore";
import UnfoldLessIcon from "@material-ui/icons/UnfoldLess";
import ConfirmDeleteDialog from "../../components/ConfirmDeleteDialog";
import uuid4 from "uuid/v4";
import H4Title from "../../components/H4Title";
import {BlockActionButton} from "../../components/ShapeToForm/ArrayType";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import {GLOBAL_CONTEXT_PERMISSIONS_SERVICE} from "../../layouts/navigator/GlobalContextProvider";
import {withPermissions} from "../../service/permission-service";
import {
    getUiLabelTranslationFrom,
    UI_LABELS_COLLAPSE,
    UI_LABELS_COLLAPSE_ALL,
    UI_LABELS_EXPAND,
    UI_LABELS_EXPAND_ALL,
    UI_LABELS_REMOVE_BLOCK,
    UI_LABELS_TYPE
} from "../../layouts/navigator/UILabel";

function getStateFromConfigurations(configurations, initialForms, onShapeFormChange, permissionService, customizations) {
    let aliasesMap = getAliasesMap(configurations)
    let ontology = getOntologyClasses(configurations)
    let shapes = getShapesData(configurations)
    const langKeysSet=  new Set( flatten(shapes.map(s => s[ALIAS_SH_PROPERTY])).filter(p => p && p[ALIAS_SH_DATATYPE] === RDF_LANGSTRING).map(p => aliasesMap[p[ALIAS_SH_PATH]]))

    let shapesSet = new Set()
    let containerData = getContainerData(configurations)
    containerData.filter(c => {
        if(permissionService) {
            return permissionService.canCreate({resourceTypeIri : c[ALIAS_SYS_CLASS_IRI]});
        } else {
            return true;
        }
    }).map(c => [c[ALIAS_SYS_CLASS_IRI], ...computeAllSubClasses(c[ALIAS_SYS_CLASS_IRI], ontology)])
        .forEach(ar => ar.forEach(v => {
            let shapeToAdd = shapes.find(s => s[ALIAS_SH_TARGET_CLASS] === v)
            if(shapeToAdd) {
                shapesSet.add(shapeToAdd)
            }
        }))
    let instantiableClasses = getIdGeneratingClassIRIsSetWithSubclasses(containerData, ontology);

    const sortedTree = sort([...shapesSet].map(o => {
        let item = {};
        item.id = o.id;
        item.value = o.id;
        item.label = customizations?.renderMode === RENDER_MODE_SITE ? getPropertyName(customizations.aliasesToIRIMap, o[ALIAS_SH_TARGET_CLASS], ontology, customizations.browseLanguage) : aliasesMap[o[ALIAS_SH_TARGET_CLASS]];
        item.backingObject = o;
        item.instantiable = instantiableClasses.has(item.backingObject[ALIAS_SH_TARGET_CLASS])
        return item;
    }), 'label')

    let forms  = initialForms
        ? initialForms.map((f, i) => renderFormFromShape(f, 0, configurations, aliasesMap, onShapeFormChange))
        : []
    let focusNodes = forms.map(f => searchInTree(f[ID], sortedTree) )
    forms.map((f,i) => initialForms[i] = f)
    return {
        focusNodes: focusNodes,
        aliasesMap: aliasesMap,
        treeData: sortedTree,
        loading: false,
        configurations: configurations,
        langKeysSet: langKeysSet
    };
}

export function renderForm(theme, shapeForm, aliasesMap, customizations) {
    return shapeForm &&
    sortShapeProperties(getShapePropertyArray(shapeForm), aliasesMap).filter(p => p[ID] !== TYPE).map((p, i) => {
        let label = (aliasesMap[p[ALIAS_SH_PATH]] || p[ALIAS_SH_PATH]);
        return <FieldContainer datatest={'textField-'+label} style={{ padding : '4px 8px 8px 8px', marginTop : theme.spacing(1), display: 'flex'}} key={p[ID]+'-'+i}>
            {
                <div style={{marginTop: '18px', width: customizations?.labelWidth ? customizations.labelWidth : '104px', marginRight: '8px', textAlign: 'right'}}>
                    <H4Title noWrap={true}>
                        <FieldLabel json={''} property={p} aliasesMap={aliasesMap} customizations={customizations}/>
                    </H4Title>
                </div>
            }
            <div style={{flexGrow: '1'}}>{p.component}</div>
        </FieldContainer>;
    });
}

class Form extends Component {
    static contextType = GlobalsContext

    constructor(props) {
        super(props);
        this.state = {
            treeData:[],
            loading: false,
            apiMode: API_MODE_HTTP_API,
            focusNodes: [],
            collapsed: [],
            mockAllUUID: uuid4()
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
        let {initialForms, addInitialForm, customizations} = this.props;
        this.setState({loading: true});
        let globals = this.context;
        let allConfigurations = globals.allConfigurations;
        let permissionService = globals?.getGlobalsItem?.(GLOBAL_CONTEXT_PERMISSIONS_SERVICE);
        if(allConfigurations) {
            this.setState(getStateFromConfigurations(allConfigurations, initialForms, this.onShapeFormChange, permissionService, customizations));
            if(addInitialForm) {
                this.addForm();
            }

        } else {
            const configurationsRequest = getAllConfigurations()
            configurationsRequest.then((configurations) => {
                this.setState(getStateFromConfigurations(configurations, initialForms, this.onShapeFormChange, permissionService, customizations));
                if(addInitialForm) {
                    this.addForm();
                }
            })
        }
    }

    onFocusNodeChange = (i, newFocusNode) => {
        let {configurations, aliasesMap} = this.state;
        let {initialForms, customizations} = this.props
        let containerClass = newFocusNode.backingObject[ALIAS_SH_TARGET_CLASS];
        let jsonObj = createFormFromShape(1, containerClass, configurations, aliasesMap, this.onShapeFormChange, undefined, customizations)
        initialForms[i] =  jsonObj
        this.setState({})
    }

    onShapeFormChange = (i) => {
        let {onChange, initialForms} = this.props
        if (onChange) {
            onChange(initialForms)
        }
    }

    getMiddleComponent = (i) => {
        const {configurations, focusNodes} = this.state;
        if(!configurations || !focusNodes[i] ) {
            return <InstructionForAction style={{marginTop: '8px', padding: '24px'}} text={'Select type to load form.'}/>;
        }
        return this.getFormComponent(i);
    }

    getFormComponent = (i) => {
        let {theme, initialForms, customizations} = this.props
        const {focusNodes, aliasesMap} = this.state;
        let shapeForm = initialForms[i]
        let focusNode = focusNodes[i]
        let displayForm = <>
            {
                focusNode && focusNode.id && <div>
                    {renderForm(theme, shapeForm, aliasesMap, customizations)}
                </div>
            }
        </>;
        return displayForm;
    }

    addForm = () => {
        const {focusNodes, collapsed} = this.state;
        focusNodes.push(undefined);
        collapsed.push(false);
        this.setState({focusNodes, collapsed});
    }

    toggleCollapse = (i) => {
        return () => {
            let {collapsed} = this.state;
            collapsed[i] = !collapsed[i];
            this.setState({collapsed});
        }
    }


    render() {
        const {treeData, focusNodes, collapsed, confirmMock,mockAllUUID} = this.state;
        const {classes, theme, style, header, initialForms, hideMockButton, addButtonTitle, customizations} = this.props;
        let colorStyle = {color: theme.palette.grey.level3, paddingRight: '0px'}
        let inputLabelProps = classes
            ? {
                classes : {
                    root : classes.largeWidthPropertyLabelRoot,
                    shrink: classes.smallWidthLabelShrink
                },
                disabled : false
            }
            : {};
        let disableActions = !focusNodes || focusNodes.length < 1;

        return <FieldContainer datatest={'form'} key={mockAllUUID} style={style}>
            {header}
            <div  style={{display: 'flex'}}>
                <div style={{flexGrow: '1'}}/>
                {
                    hideMockButton ? <></> :
                    <Button
                        datatest={'mockAllButton'}
                        disabled={disableActions || focusNodes.filter(f => f !== undefined) < 1}
                        color={"secondary"}
                        variant={"outlined"}
                        size={'small'}
                        style={{
                            marginLeft: '8px'
                        }}
                        onClick={() => {
                            this.setState({confirmMock: true})
                        }}
                    >Mock All</Button>
                }
                <BlockActionButton
                    theme={theme}
                    title={getUiLabelTranslationFrom(UI_LABELS_EXPAND_ALL, customizations)}
                    onClick={() => this.setState({collapsed: focusNodes.map(v => false)})}
                    endIcon={<UnfoldMoreIcon style={colorStyle}/>}
                    buttonProps={{
                        disabled : disableActions,
                        datatest : 'expandAllButton'
                    }}
                />
                <BlockActionButton
                    theme={theme}
                    title={getUiLabelTranslationFrom(UI_LABELS_COLLAPSE_ALL, customizations)}
                    onClick={() => this.setState({collapsed: focusNodes.map(v => true)})}
                    endIcon={<UnfoldLessIcon style={colorStyle}/>}
                    buttonProps={{
                        disabled : disableActions,
                        datatest : 'collapseAllButton'
                    }}
                />
                {
                    confirmMock === true &&
                    <ConfirmDeleteDialog
                        open={confirmMock}
                        title={"Warning"}
                        data={{value : 'This action will overwrite all the manually added data in forms.'}}
                        handleCancel={() => this.setState({confirmMock: false})}
                        handleOk={() => {
                            let {initialForms} = this.props
                            Promise.all(flattenRecursive(initialForms.map(f => invokeMock(f)))).then((r) => {
                                this.onShapeFormChange();
                                let uuid = uuid4();
                                this.setState({mockAllUUID : uuid, confirmMock: false});
                            })
                        }}
                        okButtonTitle={'OK'}
                    />

                }

            </div>
            {
                focusNodes.map((f, i) => <FieldContainer datatest={'block'} style={{padding : '0px', backgroundColor : theme.palette.white.main, marginTop: theme.spacing(1)}} key={(f ? f.id : '')+i}>
                    {
                        addBorder(0, <div style={{padding: '8px'}}>
                            <div style={{display: "flex"}}>
                                <div style={{flexGrow: 1}}/>
                                {   f &&
                                    centerVertically(
                                        <BlockActionButton
                                            buttonProps={{datatest : 'expandCollapseBlockButton'}}
                                            theme={theme}
                                            title={collapsed[i] ? getUiLabelTranslationFrom(UI_LABELS_EXPAND, customizations) : getUiLabelTranslationFrom(UI_LABELS_COLLAPSE, customizations)}
                                            onClick={this.toggleCollapse(i)}
                                            endIcon={ collapsed[i] ? <UnfoldMoreIcon style={colorStyle}/>: <UnfoldLessIcon style={colorStyle}/> }
                                        />
                                    )

                                }


                                {
                                    centerVertically(
                                        <BlockActionButton
                                            buttonProps={{datatest : 'removeBlockButton'}}
                                            theme={theme}
                                            title={getUiLabelTranslationFrom(UI_LABELS_REMOVE_BLOCK, customizations)}
                                            onClick={() => {
                                                focusNodes.splice(i, 1);
                                                initialForms.splice(i, 1);
                                                this.setState({focusNodes: focusNodes, mockAllUUID : uuid4()});
                                                this.onShapeFormChange();
                                            }}
                                            endIcon={<RemoveCircleIcon style={{color: theme.palette.grey.level3}}/>}
                                        />
                                    )
                                }
                            </div>
                            <div style={{display: "flex"}}>
                                <Autocomplete
                                    datatest={'autocompleteType'}
                                    style={{flexGrow: 1}}
                                    value={f}
                                    options={treeData}
                                    disableClearable={true}
                                    getOptionLabel={option => option.label ? option.label : ''}
                                    getOptionDisabled={option => option.instantiable === false}
                                    renderOption={(option, { selected }) => (
                                        <div>
                                            <div datatest={option.label} style={{paddingLeft : '16px'}}>{option.label}</div>
                                            <div style={{paddingLeft : '16px'}}>
                                                <Typography variant={'caption'}>{option.backingObject[ALIAS_SH_TARGET_CLASS]}</Typography>
                                            </div>
                                        </div>
                                    )}

                                    onChange={(event, val) => {
                                        focusNodes[i] = val
                                        this.setState({focusNodes}, () => this.onFocusNodeChange(i, val))
                                    }}
                                    renderInput={params => (
                                        <OtherTextField
                                            {...params}
                                            label={getUiLabelTranslationFrom(UI_LABELS_TYPE, customizations)}
                                            margin={"dense"}
                                            variant="outlined"
                                            fullWidth
                                            InputLabelProps={inputLabelProps}
                                        />
                                    )}
                                    size={"small"}
                                />
                            </div>
                            <div datatest={'formFields'} style={{display : collapsed[i] === true ? 'none' : 'block'}}>
                                {this.getMiddleComponent(i)}
                            </div>
                        </div>)
                    }
                </FieldContainer>)
            }
            <div style={{display: 'flex', marginTop: theme.spacing(1)}} >
                <div style={{flexGrow: 1}}/>
                <Button
                    datatest={'addTypeBlockButton'}
                    onClick={this.addForm}
                    color={"secondary"}
                    variant={customizations?.addButtonVariant ? customizations?.addButtonVariant :"contained"}
                    startIcon={<AddIcon/>}
                >{addButtonTitle || 'Add Type Block'}</Button>
            </div>
        </FieldContainer>;

    }

}

Form.defaultProps = {
    style: {},
};

Form.propTypes = {
    header: PropTypes.object,
    addInitialForm: PropTypes.bool,
    hideMockButton: PropTypes.bool,
    addButtonTitle: PropTypes.string,
    onChange: PropTypes.func,
    style: PropTypes.object,
    customizations : PropTypes.any
};

export default withStyles(styles, {withTheme: true})(withPermissions(Form));

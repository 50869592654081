import React from "react";
import uuid4 from "uuid/v4";
import {
    centerVertically,
    computeMinCount,
    createPayloadObjectForProperty,
    flatten,
    getDataContextURL,
    getShaclResult,
    isArrayOnly,
    isBadRequestError,
    isObjectOnly,
    isObjectShapeProperty,
    isPreconditionFailed,
    isRequestSuccessful,
    toArray
} from "../../components/util";
import cloneDeep from "lodash/cloneDeep";
import {
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ALIAS_SYS_ETAG,
    AT_CONTEXT,
    ID,
    LANG,
    SH_LITERAL,
    TYPE,
    VALUE,
    XSD_STRING
} from "../../Constants";
import {isString} from "lodash";
import {IconButton, LinearProgress, Paper, Tooltip, Typography} from "@material-ui/core";
import {
    CancelOutlined,
    DeleteOutlined,
    EditOutlined,
    ListAltOutlined,
    SaveOutlined,
    WarningOutlined
} from "@material-ui/icons";
import {updateGraph} from "../../service/graph-api";
import {fade} from "@material-ui/core/styles/colorManipulator";
import {DELETE_EVENT, getComponent} from "../../components/ShapeToForm/ArrayType";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {
    createNodeInEditStateEvent,
    createResourcePatchEvent,
    isNodeInEditStateEvent,
    mergeAndPublishEventForResourceUpdate,
    reloadResourceAndPublishRefreshEvent,
    withEvent
} from "./Event";
import {getBorderColor} from "./Tree";
import {
    getUiLabelTranslation,
    UI_LABELS_BULK_EDIT_PROPERTY,
    UI_LABELS_CANCEL,
    UI_LABELS_DELETE_VALUE,
    UI_LABELS_EDIT_PROPERTY,
    UI_LABELS_SAVE,
    UI_LABELS_SHAPE_NOT_CONFIGURED,
    UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC,
    UI_LABELS_UPDATE_FAILED,
    UI_LABELS_UPDATE_PRECONDITION_FAILED
} from "./UILabel";
import AlertSnackbarContent from "../../components/AlertSnackbarContent";
import AddDataPropertyValueDialog, {renderErrors} from "./AddDataPropertyValueDialog";
import {PermissionService, withPermissions} from "../../service/permission-service";
import {getLanguageSuggestionsForSite} from "./DataGridView";
import {isSSOEnabled} from "../../Configs";
import FeedbackWidget from "./FeedbackWidget";
import AddFeedbackDialog from "./AddFeedbackDialog";
import {isFeedbackEnabled} from "./BasicSetup";

export const NODE_ID_KEY = 'nodeId';
export const VALUE_NODE_WIDTH = '480';
export const VALUE_NODE_WIDTH_PX = `${VALUE_NODE_WIDTH}px`;

export async function patchResourceAndHandleMerge(patchPayload, publishEvent, settings, browseLanguage) {
    let patchResourceId = patchPayload[ID];
    let response = await updateGraph(patchPayload).catch(e => {
    });
    if (isRequestSuccessful(response)) {
        await mergeAndPublishEventForResourceUpdate(publishEvent, createResourcePatchEvent(patchPayload, response));
        return {};
    } else {
        if (isPreconditionFailed(response)) {
            await reloadResourceAndPublishRefreshEvent(publishEvent, patchResourceId);
            return {
                updateFailed: getUiLabelTranslation(settings, UI_LABELS_UPDATE_PRECONDITION_FAILED, browseLanguage, UI_LABELS_UPDATE_PRECONDITION_FAILED),
            };
        } else {
            let uiLabelTranslation = getUiLabelTranslation(settings, UI_LABELS_UPDATE_FAILED, browseLanguage, UI_LABELS_UPDATE_FAILED);
            let errors = [];
            if(isBadRequestError(response)) {
                let json = await response.json();
                errors = getShaclResult(json);
            }
            let returnObject = {
                updateFailed: uiLabelTranslation
            };
            if(errors.length > 0) {
                returnObject.errors = errors;
            }
            return returnObject;
        }
    }
}

export async function patchPropertyValue(shapePropertyClone, parentObject, renderedDataValueObject, aliasesMap, publishEvent, settings, browseLanguage, onCancelSave) {
    let newValue = toArray(shapePropertyClone.value)[0];
    let propertyAlias = aliasesMap[shapePropertyClone[ALIAS_SH_PATH]] || shapePropertyClone[ALIAS_SH_PATH];
    let oldValue = parentObject[propertyAlias];
    let mergedValues = toArray(oldValue).map(vl => {
        if(isString(vl)) {
            if(renderedDataValueObject[VALUE] === vl) {
                return newValue;
            } else {
                return vl;
            }
        } else if (isObjectOnly(vl)) {
            if(vl[LANG] || vl[TYPE]) {
                if(vl[LANG] && vl[LANG] === renderedDataValueObject[LANG] && vl[VALUE] === renderedDataValueObject[VALUE]) {
                    return newValue;
                } else if (vl[TYPE] && vl[TYPE] === renderedDataValueObject[TYPE] && vl[VALUE] === renderedDataValueObject[VALUE]) {
                    return newValue;
                } else {
                    return vl;
                }
            } else {
                // Create array of objects from language map object
                let languageMapValues = Object.keys(vl).map(lk => {
                    let langValues = vl[lk];
                    return toArray(langValues).map(lkv => {
                        return {[lk]: lkv}
                    })
                });
                languageMapValues = flatten(languageMapValues);
                languageMapValues = languageMapValues.map(langObj => {
                    // As we have flattened above at this point there will be only one key
                    let lk = Object.keys(langObj)[0];
                    let lv = langObj[lk];
                    if (lk === renderedDataValueObject[LANG] && lv === renderedDataValueObject[VALUE]) {
                        return newValue;
                    } else {
                        return langObj;
                    }
                })
                return languageMapValues;
            }
        } else {
            return vl;
        }
    })
    mergedValues = flatten(mergedValues);
    //filter undefined as that is what delete does
    mergedValues = mergedValues.filter(vl => vl);

    if(JSON.stringify(oldValue) === JSON.stringify(newValue)) {
        onCancelSave();
        return {cancel: true};
    }

    let newClone = cloneDeep(shapePropertyClone);
    newClone.value = mergedValues;

    let payload = {}
    createPayloadObjectForProperty(newClone, payload, aliasesMap, true);

    if(Object.keys(payload).length === 0) {
        payload = {
            [propertyAlias] : null
        }
    }

    let patchResourceId = parentObject[ID];
    let patchPayload = {
        [AT_CONTEXT] : getDataContextURL(),
        [ID] : patchResourceId,
        [ALIAS_SYS_ETAG] : parentObject[ALIAS_SYS_ETAG],
        ...payload
    }
    let result = await patchResourceAndHandleMerge(patchPayload, publishEvent, settings, browseLanguage);
    return result;
}

class NodeContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            focus : false,
            isInEditState : false,
            [NODE_ID_KEY] : uuid4()
        }
    }

    componentDidMount() {
        let {registerForEvents} = this.props;
        registerForEvents(this.onUpdateEvent);
    }

    onUpdateEvent = (event) => {
        if(isNodeInEditStateEvent(event)) {
            let {nodeId, isInEditState} = this.state;
            if(event[NODE_ID_KEY] !== nodeId && isInEditState === true) {
                this.handleCancelEdit();
                this.handleFocusOff();
            }
        }
    }

    handleEditEnable = () => {
        let {isInEditState, nodeId} = this.state;
        let {publishEvent, valueIndexInArray, shapeProperty} = this.props;
        if(this.isPropertyShapeNonEditable(shapeProperty)) {
            return;
        }
        publishEvent(createNodeInEditStateEvent(nodeId)).then(() => {
            if(isInEditState) {
                return;
            }
            let {shapeProperty, parentObject, aliasesMap, renderedDataValueObject} = this.props;

            let shapePropertyClone = cloneDeep(shapeProperty);
            let value = shapeProperty[ALIAS_SH_NODE_KIND] === SH_LITERAL
                ? renderedDataValueObject[LANG] || renderedDataValueObject[TYPE]
                    ? renderedDataValueObject
                    : {[TYPE] : XSD_STRING , [VALUE] : renderedDataValueObject[VALUE]}
                : renderedDataValueObject[LANG]
                    ? ({[renderedDataValueObject[LANG]] : renderedDataValueObject[VALUE]})
                    : renderedDataValueObject[TYPE]
                        ? renderedDataValueObject : renderedDataValueObject[VALUE];
            shapePropertyClone.value = toArray(value);
            this.shapePropertyClone = shapePropertyClone
            this.shapePropertyCloneStringify = JSON.stringify(shapePropertyClone)
            this.setState({isInEditState : true, loading : false , validationError : undefined})
        });
    }

    isPropertyShapeNonEditable = (shapeProperty) => {
        return shapeProperty === undefined || shapeProperty['FAKE_PATH_TYPE'];
    }

    getEditButton = () => {
        let {settings, browseLanguage, shapeProperty} = this.props;
        if(this.isPropertyShapeNonEditable(shapeProperty)) {
            return <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_SHAPE_NOT_CONFIGURED, browseLanguage, UI_LABELS_SHAPE_NOT_CONFIGURED)}>
                    <WarningOutlined color={'error'}></WarningOutlined>
            </Tooltip>;

        } else {
            return <Tooltip
                title={getUiLabelTranslation(settings, UI_LABELS_EDIT_PROPERTY, browseLanguage, UI_LABELS_EDIT_PROPERTY)}>
                <IconButton datatest={'editButton'} size={'small'} onClick={this.handleEditEnable}>
                    <EditOutlined></EditOutlined>
                </IconButton>
            </Tooltip>;
        }
    }

    handleCancelEdit = (ev) => {
        if(ev) {
            ev.preventDefault();
            ev.stopPropagation();
        }
        this.setState({isInEditState : false, loading : false, errors : undefined, updateFailed : undefined});
    }

    handleFocusOff = () => {
        let {focus} = this.state;
        if(focus === true) {
            this.setState({focus: false});
        }
    }

    handleFocusOn = () => {
        let {focus} = this.state;
        if(focus === false) {
            this.setState({focus: true});
        }
    }

    canEditNode = () => {
        let {permissionService, customizations, parentObject, nodeDataEditOnFocus} = this.props;
        if(nodeDataEditOnFocus !== true) {
            return false;
        }
        return parentObject && permissionService?.canUpdateResource(parentObject) && !(customizations?.readOnly === true)
    }

    getPropertyNodeButtons = () => {
        let {shapeProperty, propertyKey, parentObject, permissionService, customizations, settings, browseLanguage, publishEvent} = this.props;
        if(propertyKey === undefined || shapeProperty === undefined) {
            return undefined;
        }
        let objectShapeProperty = isObjectShapeProperty(shapeProperty);
        if(objectShapeProperty) {
            return undefined;
        }

        if(customizations?.readOnly === true || permissionService.canUpdateResource(parentObject) === false) {
            return undefined;
        }


        return <div style={{display : 'flex'}}>
            <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_BULK_EDIT_PROPERTY, browseLanguage, UI_LABELS_BULK_EDIT_PROPERTY)}>
            <IconButton datatest={'bulkEdit'} size={'small'} onClick={() => {
                this.setState({focus : false});
                this.setState({openAddData : true});
                publishEvent(createNodeInEditStateEvent(undefined));
            }}>
                <><ListAltOutlined></ListAltOutlined></>
            </IconButton>
            </Tooltip>
        </div>;
    }

    getNodeButtons = () => {
        let {settings, browseLanguage, shapeProperty, parentObject , aliasesMap} = this.props;
        let {validationError} = this.state;
        let disableDeleteButton = false;
        let disableSaveButton = validationError;
        let minCount = computeMinCount(shapeProperty);
        if(minCount !== undefined) {
            let property = aliasesMap[shapeProperty[ALIAS_SH_PATH]] || shapeProperty[ALIAS_SH_PATH];
            let propertyValues = toArray(parentObject[property]);
            if(propertyValues.length === minCount) {
                disableDeleteButton = true;
            }
        }
        if(disableSaveButton || JSON.stringify(this.shapePropertyClone) === this.shapePropertyCloneStringify) {
            disableSaveButton = true;
        }


        return <div style={{display : 'flex'}}>
            {centerVertically(<Typography variant={'caption'}>{getUiLabelTranslation(settings, UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC, browseLanguage, UI_LABELS_TO_SAVE_PRESS_CTRL_ENTER_OR_ESC)} </Typography>)}
            <div style={{flexGrow : '1'}}></div>
            {

                disableSaveButton ? <IconButton datatest={'saveButton'} style={{marginLeft: '4px'}} size={'small'} disabled={true}>
                        <SaveOutlined/>
                    </IconButton> :
                <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_SAVE, browseLanguage, UI_LABELS_SAVE)}>
                    <IconButton datatest={'saveButton'} style={{marginLeft: '4px'}} size={'small'} onClick={this.handleEditSave}>
                        <SaveOutlined/>
                    </IconButton>
                </Tooltip>
            }
            <Tooltip title={getUiLabelTranslation(settings, UI_LABELS_CANCEL, browseLanguage, UI_LABELS_CANCEL)}>
                <IconButton datatest={'cancelButton'} style={{marginLeft : '4px'}} size={'small'} onClick={() => {
                    this.handleCancelEdit();
                    this.handleFocusOff();
                }}>
                    <CancelOutlined/>
                </IconButton>
            </Tooltip>
            {
                disableDeleteButton ||
                <Tooltip
                    title={getUiLabelTranslation(settings, UI_LABELS_DELETE_VALUE, browseLanguage, UI_LABELS_DELETE_VALUE)}>
                    <IconButton datatest={'deleteButton'} style={{marginLeft: '4px'}} size={'small'} onClick={() => {
                        if (isArrayOnly(this.shapePropertyClone.value)) {
                            this.shapePropertyClone.value.splice(0, 1)
                        } else {
                            delete this.shapePropertyClone.value;
                        }
                        this.handleEditSave();
                    }}>
                        <DeleteOutlined/>
                    </IconButton>
                </Tooltip>
            }

        </div>;
    }

    isEditable = () => {
        let {propertyKey, nodeEditOnFocus} = this.props;
        if(propertyKey === ALIAS_SYS_ETAG) {
            return false;
        }
        let isDataNode = propertyKey === undefined

    }

    handleEditSave = () => {
        let {isInEditState} = this.state;
        let {publishEvent, settings, browseLanguage} = this.props;
        if(isInEditState === false) {
            return;
        }
        this.setState({loading : true}, async () => {
            let {shapePropertyClone} = this;
            let {aliasesMap, parentObject, valueIndexInArray, renderedDataValueObject} = this.props;
            let result = await patchPropertyValue(shapePropertyClone, parentObject, renderedDataValueObject, aliasesMap, publishEvent, settings, browseLanguage, this.handleCancelEdit);
            if(result.cancel) {
                return;
            }
            if(result.updateFailed || result.errors) {
                this.setState({updateFailed : result.updateFailed, errors : result.errors });
                if(toArray(result.errors).length > 0) {
                    //Do nor cancel edit state
                    this.setState({loading : false});
                    return;
                }
            }
            this.setState({loading : false});
            this.handleCancelEdit();
        });

    }

    renderNodeContent = () => {
        let {fragmentKey, customizations, renderedDataValueObject, location, nodeDataEditOnFocus, nodeContainerStyleToApply, browseLanguage, ontology, parentObject, configurations, shapeProperty, valueIndexInArray, settings, propertyKey, theme, aliasesToIRIMap, aliasesMap, nodeValue, nodeActions, nodeFocusActionsProvider, nodeSubTree, propertyKeyTitle, keyComponent} = this.props;
        let {focus, isInEditState, loading, openAddData, errors, validationError, addFeedback} = this.state;
        let showEdit = propertyKey === undefined && focus;
        let borderConnectionColor = getBorderColor(theme);
        let isEditState = nodeDataEditOnFocus === true && ((propertyKey === undefined && focus) || isInEditState === true );
        let isClickable =  nodeDataEditOnFocus && (propertyKey === undefined && focus)

        if(isInEditState) {
            return <Paper datatest={'editContainer'} elevation={12} style={{
                position : 'absolute',
                minHeight : propertyKey ? '32px' : '32px',
                border: '1px solid',
                padding: '8px',
                borderLeft: '3px solid',
                borderRadius: '4px',
                borderColor: borderConnectionColor,
                cursor : isClickable && 'pointer',
                borderLeftColor : (isEditState === true ?  theme.palette.primary.main : borderConnectionColor),
                backgroundColor: (propertyKey && fade(borderConnectionColor, 0.2)) || (isEditState && theme.palette.border.main),
                ...nodeContainerStyleToApply,

                width : '100%',
                top: 0,
                left: 0,
                zIndex: 10
            }}>
                {
                    centerVertically(
                        getComponent(propertyKey, 0, 0, undefined, this.shapePropertyClone, configurations, aliasesMap, (eventName, index) => {
                            console.log(this.shapePropertyClone.value, eventName, index);
                            if(eventName === DELETE_EVENT) {
                                this.handleEditSave();
                            }
                            this.setState({});
                        }, undefined, true, {hideDelete : true, languageSuggestions : getLanguageSuggestionsForSite(settings) , multiline : 'auto', rowsMax : 5, onValidationError : (validationError) => this.setState({validationError}) })
                        , {flexGrow: '1'}
                    )
                }
                {loading && <LinearProgress color={'secondary'}/>}
                {errors && renderErrors(errors)}
                <div>
                    { isInEditState === true && this.getNodeButtons()}
                </div>
            </Paper>;
        }

        let propertyNodeButtons = this.getPropertyNodeButtons();

        return <>

            {
                propertyKey && !keyComponent &&
                centerVertically(
                    <div datatest={'property-'+propertyKeyTitle}>
                        {
                            propertyKey &&
                            // DO not use H4Title as it cause issue with tooltip
                            <Tooltip arrow={true} placement={'bottom-start'} title={aliasesToIRIMap[propertyKey] || propertyKey}>
                                <Typography noWrap={true} component={'span'} variant="h4" color={'primary'} >{propertyKeyTitle}</Typography>
                            </Tooltip>
                        }
                    </div>, {flexGrow: '1'}
                )
            }
            {
                keyComponent && centerVertically(
                    <div>{keyComponent}</div>,
                    {flexGrow: '1'}
                )
            }
            {nodeValue && <>{nodeValue}{nodeFocusActionsProvider ? <div style={{flexGrow: '1'}}></div> : <></>}</> }
            {focus && isFeedbackEnabled(settings) && customizations?.feedback !== false && centerVertically(<FeedbackWidget
                onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this.setState({addFeedback: true});
                }}
                settings={settings} location={location} aliasesToIRIMap={aliasesToIRIMap} ontology={ontology} aliasesMap={aliasesMap} browseLanguage={browseLanguage} configurations={configurations}/>, {marginLeft: '8px'})}
            {
                addFeedback && <AddFeedbackDialog
                    location={location}
                    context={this.props.context}
                    parentObject={parentObject}
                    propertyKey={propertyKey}
                    shapeProperty={shapeProperty}
                    nodeValue={renderedDataValueObject}
                    ontology={ontology}
                    browseLanguage={browseLanguage}
                    aliasesToIRIMap={aliasesToIRIMap}
                    aliasesMap={aliasesMap}
                    settings={settings}
                    onClose={() => this.setState({focus: false, addFeedback: undefined})}
                    onSaveSuccess={() => {
                        this.setState({addFeedback: undefined})
                    }}
                />
            }
            { showEdit === true ?
                nodeDataEditOnFocus === true && isInEditState === false && centerVertically(this.getEditButton(), {marginLeft: '8px'})
                : (nodeDataEditOnFocus === true && isInEditState === false
                    // else reserve the space for icon
                    ? centerVertically(<div style={{width : '30px'}}></div>, {marginLeft: '8px'}) : <></>)
            }
            { isInEditState === true && centerVertically(this.getNodeButtons(), {marginLeft: '8px'})}
            { focus && nodeFocusActionsProvider && centerVertically(nodeFocusActionsProvider(), {marginLeft: '8px'}) }
            { focus && propertyNodeButtons && centerVertically(propertyNodeButtons, {marginLeft: '8px'}) }
            {
                nodeActions && centerVertically(nodeActions, {marginLeft: '8px'})
            }
        </>;
    }

    handleKeyboardInteraction = async (e) => {
        let {nodeDataEditOnFocus} = this.props;
        let {isInEditState} = this.state;
        if(nodeDataEditOnFocus === true && isInEditState === false && e.key === 'Enter') {
            this.handleEditEnable()
        }
        if(e.ctrlKey && e.key === 'Enter') {
            this.handleEditSave();
        } else if(e.key === 'Escape') {
            this.handleCancelEdit(e);
        }
    }

    renderNodeMarkup = () => {

        let {fragmentKey, permissionService, shapeProperty, customizations, parentObject, nodeDataEditOnFocus, valueIndexInArray, nodeContainerStyle, propertyKey, theme, aliasesToIRIMap, nodeValue, nodeActions, nodeFocusActionsProvider, nodeSubTree, propertyKeyTitle, keyComponent} = this.props;
        let {focus, isInEditState, openAddData, updateFailed} = this.state;
        let nodeContainerStyleToApply = nodeContainerStyle || {};
        let borderConnectionColor = getBorderColor(theme);
        let isEditState = nodeDataEditOnFocus === true && ((propertyKey === undefined && focus) || isInEditState === true );
        let isClickable =  nodeDataEditOnFocus && (propertyKey === undefined && focus)
        let isInFocus =  nodeDataEditOnFocus && (propertyKey === undefined && focus === true)
        let borderLeftColor = borderConnectionColor;
        if(focus === false && (this.canEditNode() || nodeFocusActionsProvider || this.getPropertyNodeButtons())) {
            borderLeftColor = theme.palette.success.main;
        }
        if(focus === true && (this.canEditNode() || nodeFocusActionsProvider || this.getPropertyNodeButtons())) {
            borderLeftColor = theme.palette.primary.main;
        }

        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderLeftColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            ...nodeContainerStyleToApply
        }
        if(isClickable) {
            computedStyle.cursor = 'pointer';
        }
        if(propertyKey) {
            if(focus && parentObject && permissionService.canUpdateResource(parentObject) && !(customizations?.readOnly === true)) {
                computedStyle.backgroundColor = theme.palette.border.main;
                if(this.canEditNode()) {
                    computedStyle.borderLeftColor = theme.palette.primary.main;
                }
            } else {
                computedStyle.backgroundColor = fade(borderConnectionColor, 0.2);
            }
        } else {
            if(isInEditState) {
                computedStyle.borderTopWidth = '0px'
                computedStyle.borderBottomWidth = '0px'
                computedStyle.borderLeftWidth = '0px'
                computedStyle.borderRightWidth = '0px'
            } else {
                if(isInFocus) {
                    computedStyle.borderLeftColor = theme.palette.primary.main
                    computedStyle.backgroundColor = theme.palette.border.main
                }
            }

        }
        return <div datatest={'container-'+(propertyKeyTitle ? propertyKeyTitle :  '')} key={(fragmentKey || propertyKey)+isInEditState}>
            <div style={{borderLeft: '2px solid', borderColor: borderConnectionColor, display: 'flex'}}>
                {
                    centerVertically(
                        <div
                            style={{width: '16px', border: '1px solid', borderColor: borderConnectionColor}}
                        />,
                        {paddingTop: '6px'}
                    )
                }
                <div style={{paddingTop: '8px', minWidth: VALUE_NODE_WIDTH_PX, maxWidth : VALUE_NODE_WIDTH_PX, paddingRight: '16px'}}>
                    <div
                        datatest={'focusContainer-'+(propertyKeyTitle || '')}
                        tabIndex={nodeDataEditOnFocus === true ? 0 : undefined}
                        style={computedStyle}
                        onFocus={this.handleFocusOn}
                        onBlur={(e) => {
                            if(isInEditState === false) {
                                this.handleFocusOff();
                            }
                        }}
                        onMouseEnter={this.handleFocusOn}
                        onMouseLeave={this.handleFocusOff}
                        onClick={() => {
                            if(isClickable) {
                                this.handleEditEnable();
                            }
                        }}
                        onKeyUp={this.handleKeyboardInteraction}

                    >
                        {updateFailed && <AlertSnackbarContent onClose={() => this.setState({updateFailed : undefined})} variant={'error'} autoHide={true} open={true} message={updateFailed} />}
                        {this.renderNodeContent()}
                    </div>
                </div>
            </div>
            {nodeSubTree}
        </div>;
    }

    render() {
        let {location, browseLanguage, ontology, configurations, shapeProperty, settings, aliasesMap} = this.props;
        let {parentObject, propertyKey, aliasesToIRIMap} = this.props;
        let {openAddData} = this.state;

        return <>
            {
                openAddData && shapeProperty && <AddDataPropertyValueDialog
                    configurations={configurations}
                    aliasesMap={aliasesMap}
                    aliasesToIRIMap={aliasesToIRIMap}
                    ontology={ontology}
                    settings={settings}
                    location={location}
                    sourceResource={parentObject}
                    browseLanguage={browseLanguage}
                    onClose={() => this.setState({openAddData : false})}
                    onSaveSuccess={() => this.setState({openAddData : false})}
                    propertyLabel={propertyKey}
                    propertyShape={shapeProperty}
                />
            }
            {this.renderNodeMarkup()}
        </>;
    }
}

NodeContainer.propTypes = {
    customizations : PropTypes.object,
    permissionService : PropTypes.instanceOf(PermissionService),
    registerForEvents : PropTypes.func,
    publishEvent : PropTypes.func,
    aliasesToIRIMap: PropTypes.object,
    aliasesMap: PropTypes.object,
    browseLanguage: PropTypes.any,
    settings: PropTypes.any,
    location: PropTypes.object,
    configurations: PropTypes.any,
    ontology: PropTypes.any,
    parentObject: PropTypes.any,
    valueIndexInArray: PropTypes.any,
    renderedDataValueObject: PropTypes.any,
    fragmentKey: PropTypes.any,
    propertyKey: PropTypes.any,
    shapeProperty: PropTypes.any,
    nodeValue: PropTypes.any,
    nodeActions: PropTypes.any,
    nodeFocusActionsProvider: PropTypes.any,
    nodeDataEditOnFocus: PropTypes.any,
    nodeSubTree: PropTypes.any,
    propertyKeyTitle: PropTypes.any,
    keyComponent: PropTypes.any,
    nodeContainerStyle : PropTypes.any,
    context : PropTypes.any
}

export default withEvent(withStyles(styles, {withTheme: true})(withPermissions(NodeContainer)));

import React from 'react';
import PropTypes from "prop-types";
import {Grid, Paper, Tooltip, Typography, withStyles} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import H3Title from "./H3Title";
import {getIdGeneratingClassIRIs} from "./util";

const styles = {};

let ClassHierarchyContainerSwitch = props => {
    const {theme, title, classesList, aliasesMap, idGeneratingClasses, handleFieldChange, showSwitch } = props;
    let idGeneratingClassIRIs = getIdGeneratingClassIRIs(idGeneratingClasses)
    return (<Paper datatest={title} elevation={0}  style={{height: '100%', padding: '12px', backgroundColor: theme.palette.border.main}}>
        <H3Title title={title} />
        <div style={{marginLeft: theme.spacing(1), paddingTop: theme.spacing(3)}}>
            <Grid container spacing={1}>{
                classesList.map(c =>
                    <Grid key={c} item xs={12}>
                        <Tooltip title={c} placement={"bottom"}>
                            {
                                showSwitch === undefined || showSwitch === true ?
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                disabled={true}
                                                size={"small"}
                                                checked={idGeneratingClassIRIs.includes(c)}
                                                value={c}
                                                onChange={handleFieldChange}
                                                name="idGeneratingClasses"
                                            ></Switch>
                                        }
                                        label={<Typography style={{color: theme.palette.primary.main}}>{aliasesMap[c]}</Typography>}
                                    />
                                    : <Typography component={'span'}>{aliasesMap[c]}</Typography>
                            }
                        </Tooltip>
                    </Grid>
                )
            }</Grid>
        </div>
    </Paper>);
}

ClassHierarchyContainerSwitch.propTypes = {
    title: PropTypes.string.isRequired,
    classesList: PropTypes.array.isRequired,
    aliasesMap: PropTypes.object.isRequired,
    idGeneratingClasses: PropTypes.array,
    handleFieldChange: PropTypes.func,
    showSwitch: PropTypes.bool
};

ClassHierarchyContainerSwitch.defaultProps = {
    idGeneratingClasses: [],
};

export default withStyles(styles, {withTheme: true})(ClassHierarchyContainerSwitch);

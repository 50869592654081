import React, {Component} from "react";
import {withStyles} from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes, {any, arrayOf, string} from "prop-types";


import {Autocomplete as BaseAutoComplete} from '@material-ui/lab';
import TextField from "./TextField";

const styles = {
  popupIndicatorOpen: {
    transform: 'none'
  }
};

class AutoComplete extends Component {
  static propTypes = {
    data: arrayOf(any),
    labelField: string,
  };

  static defaultProps = {
    labelField: 'title',
    freeSolo: false,
  };

  optionLabel = option => option[this.props.labelField];

  render() {
    const { data, placeholder, classes, theme, labelField, textFieldPaperStyle, ...props } = this.props;
    return <BaseAutoComplete
          options={data}
          getOptionLabel={this.optionLabel}
          popupIcon={<SearchIcon id={'searchIcon'} color='secondary' />}
          classes={{ popupIndicatorOpen: classes.popupIndicatorOpen }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              paperStyle={{ ...textFieldPaperStyle, backgroundColor: 'inherit' }}
              placeholder={'Search'}
            />
          )}
          {...props}
        />;
  }
}

AutoComplete.propTypes = {
  placeholder: PropTypes.string,
  textFieldPaperStyle: PropTypes.object,
}

export default withStyles(styles, { withTheme: true })(AutoComplete);

import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {styles} from "../../components/styles";
import {withEvent} from "./Event";
import PropTypes from "prop-types";
import FieldContainer from "../../components/FieldContainer";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {MAX_ZOOM_LEVEL, MIN_ZOOM_LEVEL, ZINDEX_NODE_DETAILS_FORM_DATA, ZOOM_STEP} from "./GraphView";
import {centerVertically, restrictMaximumCharacters} from "../../components/util";
import H3Title from "../../components/H3Title";
import {TuneOutlined} from "@material-ui/icons";
import {Checkbox, Grid, Typography} from "@material-ui/core";
import {
    LAYOUT_BREADTHFIRST,
    LAYOUT_CISE,
    LAYOUT_DAGREE,
    LAYOUT_DAGREE_LR,
    LAYOUT_DAGREE_TB,
    LAYOUT_FCOSE,
    LAYOUT_RANDOM,
    LAYOUT_TIDYTREE
} from "./GraphViewSettings";
import H4Title from "../../components/H4Title";
import TextField from "@material-ui/core/TextField";
import {DEFAULT_TAXI_TURN} from "./GraphViewStyleSettings";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {getGraphEdgeStyleValue} from "./navigator-cytoscape-util";
import {
    getUiLabelTranslation,
    UI_LABELS_AUTO_FIT,
    UI_LABELS_AUTO_REFRESH,
    UI_LABELS_EDGE_LENGTH, UI_LABELS_EDGE_TURN_DISTANCE,
    UI_LABELS_HORIZONTAL_SPACING, UI_LABELS_LAYOUT_SETTINGS, UI_LABELS_MAXIMUM_ZOOM, UI_LABELS_MINIMUM_ZOOM,
    UI_LABELS_NODE_SPACING,
    UI_LABELS_PADDING, UI_LABELS_VERTICAL_SPACING,
    UI_LABELS_ZOOM_ON_SCROLL_PINCH
} from "./UILabel";

const DEFAULT_NODE_SEP = 90;
const DEFAULT_IDEAL_EDGE_LENGTH = 200;
const DEFAULT_PADDING = 10;

const DEFAULT_VERTICAL_SPACING = 60;
const DEFAULT_HORIZONTAL_SPACING = 40;

export function getLayoutOptionsForView(layoutName, onStop) {
    switch (layoutName) {
        case LAYOUT_DAGREE_LR :
            return {
                name : LAYOUT_DAGREE,
                stop : onStop,
                nodeSep : DEFAULT_NODE_SEP,
                rankDir : 'LR',
                animate : true,
                animationDuration : 700,
                autoRefreshLayout : true
            }
        case LAYOUT_DAGREE_TB :
            return {
                name : LAYOUT_DAGREE,
                stop : onStop,
                nodeSep : DEFAULT_NODE_SEP,
                rankDir : 'TB',
                animate : true,
                animationDuration : 700,
                autoRefreshLayout : true
            }
        case LAYOUT_RANDOM :
            return {
                name : LAYOUT_RANDOM,
                idealEdgeLength : DEFAULT_IDEAL_EDGE_LENGTH,
                tilingPaddingVertical : 200,
                tilingPaddingHorizontal : 200,
                animate : true,
                animationDuration : 700,
                fit: true,
                autoRefreshLayout : true,
            }
        case LAYOUT_CISE :
            return {
                name : LAYOUT_CISE,
                idealEdgeLength : DEFAULT_IDEAL_EDGE_LENGTH,
                tilingPaddingVertical : 200,
                tilingPaddingHorizontal : 200,
                fit: true,
                autoRefreshLayout : true,
            }
        case LAYOUT_FCOSE :
            return {
                name : LAYOUT_FCOSE,
                idealEdgeLength : DEFAULT_IDEAL_EDGE_LENGTH,
                tilingPaddingVertical : 200,
                tilingPaddingHorizontal : 200,
                fit: true,
                autoRefreshLayout : true,
            }
        case LAYOUT_BREADTHFIRST :
            return {
                name: 'breadthfirst',
                directed: true,
                padding: DEFAULT_PADDING,
                animate : true,
                autoRefreshLayout : true,
            }
        case LAYOUT_TIDYTREE :
            return {
                name: 'tidytree',
                horizontalSpacing: DEFAULT_HORIZONTAL_SPACING,
                verticalSpacing: DEFAULT_VERTICAL_SPACING,
                animate : true,
                autoRefreshLayout : true,
            }
    }
}

class GraphViewLayoutSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }


    renderTextField(label, valueKey, defaultValue, helperText, type, inputProps={}) {
        let {layoutOptions, onLayoutSettingsChange} = this.props;
        return <div>
            <H4Title title={label}/>
            <TextField
                datatest={'textField-'+valueKey}
                type={type}
                fullWidth={true}
                defaultValue={defaultValue}
                inputProps={inputProps}
                onChange={(event) => {
                    const maxLength = 500;
                    let newEvent = restrictMaximumCharacters(event, maxLength);
                    const {target: {value}} = newEvent;
                    layoutOptions[valueKey] = Number(value);
                    onLayoutSettingsChange();
                }}
            />
            <Typography component={'div'} variant={"caption"}>{helperText}</Typography>
        </div>;
    }

    renderTidyTreeOptions = () => {
        let {layoutOptions, graphStyle, onLayoutSettingsChange, onStyleSettingsChange, theme} = this.props;

        return <Grid datatest={'tidyTreeOptions'} container spacing={2}>
            <Grid item xs={12}>
                <div>
                    <H4Title title={this.getTranslation(UI_LABELS_EDGE_TURN_DISTANCE)}/>
                    <TextField
                        datatest={'turnDistance'}
                        fullWidth={true}
                        defaultValue={ getGraphEdgeStyleValue(graphStyle, "taxi-turn") ||DEFAULT_TAXI_TURN}
                        onChange={(event) => {
                            const maxLength = 500;
                            let newEvent = restrictMaximumCharacters(event, maxLength);
                            const {target: {value}} = newEvent;
                            let style = [
                                {
                                    selector: 'edge',
                                    style: {
                                        "taxi-turn" : value
                                    }
                                }
                            ]
//                            const diagramStyle = getDiagramStyle(theme, false, style);
                            onLayoutSettingsChange(style);
                        }}
                    />
                    <Typography component={'div'} variant={"caption"}>{undefined}</Typography>
                </div>
            </Grid>
            <Grid item xs={12}>
                {this.renderTextField(this.getTranslation(UI_LABELS_HORIZONTAL_SPACING), 'horizontalSpacing', layoutOptions['horizontalSpacing'] === undefined ? DEFAULT_HORIZONTAL_SPACING : layoutOptions['horizontalSpacing'] )}
            </Grid>
            <Grid item xs={12}>
                {this.renderTextField(this.getTranslation(UI_LABELS_VERTICAL_SPACING), 'verticalSpacing', layoutOptions['verticalSpacing'] === undefined? DEFAULT_VERTICAL_SPACING : layoutOptions['verticalSpacing'])}
            </Grid>
            <Grid item xs={12}>
                {this.renderFitCheckBox()}
            </Grid>
        </Grid>;
    }

    renderDagreeOptions = () => {
        let {layoutOptions} = this.props;

        return <Grid datatest={'dagreeOptions'} container spacing={2}>
            <Grid item xs={12}>
                {
                    this.renderTextField(
                        this.getTranslation(UI_LABELS_NODE_SPACING),
                    'nodeSep',
                    layoutOptions['nodeSep'] === undefined ? DEFAULT_NODE_SEP : layoutOptions['nodeSep'],
                    undefined,
                    'number',
                    {
                        min : 0,
                        step : 5
                    })
                }
            </Grid>
            <Grid item xs={12}>
                {
                    this.renderTextField(
                        this.getTranslation(UI_LABELS_EDGE_LENGTH),
                        'rankSep',
                        layoutOptions['rankSep'] === undefined ? '' : layoutOptions['rankSep'],
                        undefined,
                        'number',
                        {
                            min : 0,
                            step : 5
                        }
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {this.renderFitCheckBox()}
            </Grid>

        </Grid>;
    }

    renderFcoseOptions = () => {
        let {layoutOptions} = this.props;

        return <Grid datatest={'fcoseOptions'} container spacing={2}>
            <Grid item xs={12}>
                {
                    this.renderTextField(
                        this.getTranslation(UI_LABELS_EDGE_LENGTH),
                        'idealEdgeLength',
                        layoutOptions['idealEdgeLength'] === undefined ?  DEFAULT_IDEAL_EDGE_LENGTH : layoutOptions['idealEdgeLength'],
                        undefined,
                        'number',
                        {
                            min: 0,
                            step: 5
                        }
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {this.renderFitCheckBox()}
            </Grid>
        </Grid>;
    }

    renderBreadthFirstOptions = () => {
        let {layoutOptions} = this.props;

        return <Grid datatest={'breadthFirstOptions'} container spacing={2}>
            <Grid item xs={12}>
                {
                    this.renderTextField(
                        this.getTranslation(UI_LABELS_NODE_SPACING),
                        'spacingFactor',
                        layoutOptions['spacingFactor'] === undefined ?  1 : layoutOptions['spacingFactor'],
                        undefined,
                        'number',
                        {
                            min: 0,
                            step: .1
                        }
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {
                    this.renderTextField(
                        this.getTranslation(UI_LABELS_PADDING),
                        'padding',
                        layoutOptions['padding'] === undefined ?  DEFAULT_PADDING : layoutOptions['padding'],
                        undefined,
                        'number',
                        {
                            min: 0,
                            step: 1
                        }
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {this.renderFitCheckBox()}
            </Grid>
        </Grid>;
    }

    renderRandomOptions = () => {
        let {layoutOptions} = this.props;

        return <Grid datatest={'randomOptions'} container spacing={2}>
            <Grid item xs={12}>
                {
                    this.renderTextField(
                        this.getTranslation(UI_LABELS_PADDING),
                        'padding',
                        layoutOptions['padding'] === undefined ?  DEFAULT_PADDING : layoutOptions['padding'],
                        undefined,
                        'number',
                        {
                            min: 0,
                            step: 1
                        }
                    )
                }
            </Grid>
            <Grid item xs={12}>
                {this.renderFitCheckBox()}
            </Grid>
        </Grid>;
    }

    getTranslation = (labelKey) => {
        let {settings, browseLanguage} = this.props;
        return getUiLabelTranslation(settings, labelKey, browseLanguage, labelKey);
    }

    renderFitCheckBox() {
        let {layoutOptions, graphStyle, onLayoutSettingsChange, onStyleSettingsChange, theme} = this.props;

        return <div>
            <FormControlLabel
                control={<Checkbox
                    datatest={'fit'}
                    name={'fit'}
                    checked={layoutOptions['fit'] === undefined ? false : layoutOptions['fit']}
                    onChange={(event) => {
                        const {target: {checked}} = event;
                        layoutOptions['fit'] = checked;
                        this.setState({}, () => {onLayoutSettingsChange()});
                    }}
                    value="fit"/>}
                label={this.getTranslation(UI_LABELS_AUTO_FIT)}
            />
            <FormControlLabel
                control={<Checkbox
                    datatest={'userZoom'}
                    name={'userZoom'}
                    checked={layoutOptions['userZoomingEnabled'] === undefined ? false : layoutOptions['userZoomingEnabled']}
                    onChange={(event) => {
                        const {target: {checked}} = event;
                        layoutOptions['userZoomingEnabled'] = checked;
                        this.setState({}, () => {onLayoutSettingsChange(undefined, 'userZoomingEnabled')});

                    }}
                    value="userZoom"/>}
                label={this.getTranslation(UI_LABELS_ZOOM_ON_SCROLL_PINCH)}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        datatest={'autoRefreshLayout'}
                        size="small"
                        checked={layoutOptions['autoRefreshLayout'] === undefined ? true : layoutOptions['autoRefreshLayout']}
                        value={"autoRefreshLayout"}
                        name="autoRefreshLayout"
                        onChange={(event) => {
                            const {target: {checked}} = event;
                            layoutOptions['autoRefreshLayout'] = checked;
                            this.setState({}, () => {onLayoutSettingsChange(undefined, 'autoRefreshLayout')});
                        }}
                    />
                }
                label={this.getTranslation(UI_LABELS_AUTO_REFRESH)}
            />
        </div>;
    }

    renderLayoutSettings = () => {
        let {layoutOptions, layoutName} = this.props;
        layoutOptions['layerHeight'] = 160;
        switch (layoutName) {
            case LAYOUT_TIDYTREE:
                return this.renderTidyTreeOptions();
            case LAYOUT_DAGREE_LR:
            case LAYOUT_DAGREE_TB:
                return this.renderDagreeOptions();
            case LAYOUT_FCOSE:
                return this.renderFcoseOptions();
            case LAYOUT_BREADTHFIRST:
                return this.renderBreadthFirstOptions();
            case LAYOUT_RANDOM:
                return this.renderRandomOptions();
            default:
                return <></>;
        }
    }

    render() {
        let {onClose, theme, styleContainerWidth, layoutOptions} = this.props;
        return <div datatest={'graphViewLayoutSettings'} style={{
            flexGrow : '1',
            padding: '8px',
            backgroundColor: theme.palette.white.main,
            borderRadius: '4px',
            zIndex : ZINDEX_NODE_DETAILS_FORM_DATA
        }}>
            <FieldContainer  style={{
                padding : '0px',
                backgroundColor: theme.palette.grey.background,
                height: '100%',
            }}>
                <div style={{display: 'flex', padding : '4px'}}>
                    {centerVertically(<TuneOutlined></TuneOutlined>)}
                    {centerVertically(<H3Title>{this.getTranslation(UI_LABELS_LAYOUT_SETTINGS)}</H3Title>, {marginLeft : '8px'})}
                    <div style={{flexGrow: '1'}}></div>
                    <Tooltip title={'Close'}>
                        <IconButton datatest={'closeStyleButton'} size={'small'} onClick={onClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Tooltip>
                </div>

                <div style={{ height: "calc(100% - 56px)", maxWidth : `${styleContainerWidth - 28}px`, overflowY : 'auto', overflowX : 'auto', borderRadius : '4px', margin : '0px 8px 8px 8px', paddingBottom : '8px', backgroundColor: theme.palette.white.main}}>
                    <FieldContainer style={{
                        backgroundColor: theme.palette.white.main,
                    }}>
                        {this.renderLayoutSettings()}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                {
                                    this.renderTextField(this.getTranslation(UI_LABELS_MINIMUM_ZOOM), '_eg_minimumZoom', layoutOptions['_eg_minimumZoom'] || MIN_ZOOM_LEVEL,undefined, 'number', {min:0, step : ZOOM_STEP})
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    this.renderTextField(this.getTranslation(UI_LABELS_MAXIMUM_ZOOM), '_eg_maximumZoom', layoutOptions['_eg_maximumZoom'] || MAX_ZOOM_LEVEL,undefined, 'number', {min:0, step : ZOOM_STEP})
                                }
                            </Grid>
                        </Grid>
                    </FieldContainer>
                </div>
            </FieldContainer>
        </div>;
    }
}

GraphViewLayoutSettings.propTypes = {
    workspace: PropTypes.any,
    minimized: PropTypes.any,
    languageCode: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.array,
    ontology: PropTypes.any,
    settings: PropTypes.any,
    aliasesMap: PropTypes.object,
    aliasesToIRIMap: PropTypes.object,
    layoutStyle: PropTypes.object,
    onClose: PropTypes.func,
    onLayoutSettingsChange: PropTypes.func,
    onStyleSettingsChange: PropTypes.func,
    styleContainerWidth: PropTypes.any,
    cytoscapeObj: PropTypes.any,
    layoutName: PropTypes.any,
    layoutOptions: PropTypes.any,
    graphStyle: PropTypes.object,

};

export default withStyles(styles, {withTheme: true})(withEvent(GraphViewLayoutSettings));

import React from "react";
import {Button, Tooltip, withStyles} from "@material-ui/core";
import {
    computeMaxCount,
    computeMinCount,
    getContainerData,
    getOntologyClasses,
    isObjectShapeProperty,
    toArray
} from "../util";
import uuid4 from 'uuid/v4'
import {
    ALIAS_SH_DATATYPE,
    ALIAS_SH_MAX_COUNT,
    ALIAS_SH_MIN_COUNT,
    ALIAS_SH_NODE_KIND,
    ALIAS_SH_PATH,
    ID,
    RDF_LANGSTRING,
    SH_LITERAL,
    XSD_BOOLEAN,
    XSD_DATE,
    XSD_DATETIME,
    XSD_TIME
} from "../../Constants";
import IconButton from "@material-ui/core/IconButton";
import {isArray} from 'lodash';
import DateTimePicker from "../../components/ShapeToForm/DateTimePicker";
import DatePicker from "../../components/ShapeToForm/DatePicker";
import TimePicker from "../../components/ShapeToForm/TimePicker";
import Boolean from "../../components/ShapeToForm/Boolean";
import LangString from "../../components/ShapeToForm/LangString";
import Text from "../../components/ShapeToForm/Text";
import Literal from "../../components/ShapeToForm/Literal";
import ObjectType from "../../components/ShapeToForm/ObjectType";
import {GreyDeleteIcon} from "../../components/GreyStyleIcon";
import LinkResourceDialog from "../../layouts/navigator/LinkResourceDialog";
import {
    getUiLabelTranslationFrom,
    UI_LABELS_ADD_ID_OR_CREATE,
    UI_LABELS_ADD_ID_OR_CREATE_TOOLTIP,
    UI_LABELS_SEARCH_AND_CONNECT,
    UI_LABELS_SEARCH_AND_CONNECT_TOOLTIP
} from "../../layouts/navigator/UILabel";

export const DELETE_EVENT = 'delete';

export function deleteButton(valueIndex, property, onChange) {
    let minCount = computeMinCount(property);
    return minCount !== undefined && (valueIndex || 0) < minCount ? <div style={{width:'30px'}}/>: <div style={{float: 'right'}}>
            <Tooltip placement="bottom" title={'Delete'}>
                <IconButton datatest={'deleteButton'} size={"small"} onClick={() => {
                    if(valueIndex !== undefined) {
                        property.value.splice(valueIndex, 1)
                        property.onMock?.splice(valueIndex, 1)
                        onChange(DELETE_EVENT,valueIndex)
                    } else {
                        delete property.value
                        onChange(DELETE_EVENT)
                    }
                }}>
                    <GreyDeleteIcon/>
                </IconButton>
            </Tooltip>
        </div>;
}

export function getComponent(uuid, depth, index, jsonObj, p, configurations, aliasesMap, onChange, label, autoFocus, customizations) {
    if(p[ALIAS_SH_DATATYPE]) {
        switch (p[ALIAS_SH_DATATYPE]) {
            case XSD_DATETIME: {
                return <DateTimePicker autoFocus={autoFocus} valueIndex={index} property={p} label={label} onChange={onChange} customizations={customizations}/>;
            }
            case XSD_DATE: {
                return <DatePicker autoFocus={autoFocus} valueIndex={index} property={p} label={label} onChange={onChange} customizations={customizations}/>;
            }
            case XSD_TIME: {
                return <TimePicker autoFocus={autoFocus} valueIndex={index} property={p} label={label} onChange={onChange} customizations={customizations}/>;
            }
            case XSD_BOOLEAN: {
                return <Boolean autoFocus={autoFocus} valueIndex={index} property={p} label={label} onChange={onChange} customizations={customizations}/>
            }
            case RDF_LANGSTRING: {
                return <LangString autoFocus={autoFocus} valueIndex={index} property={p} label={label} onChange={onChange} configurations={configurations} customizations={customizations}/>
            }
            default : {
                return <Text autoFocus={autoFocus} valueIndex={index} key={uuid} label={label} property={p} onChange={onChange} customizations={customizations}/>
            }
        }
    } else if(p[ALIAS_SH_NODE_KIND] === SH_LITERAL) {
        return <Literal autoFocus={autoFocus} valueIndex={index} property={p} label={label} onChange={onChange}  aliasesMap={aliasesMap} configurations={configurations} customizations={customizations}/>
    } else if(isObjectShapeProperty(p)) {
        const ontology = getOntologyClasses(configurations)
        const containers = getContainerData(configurations)
        return <ObjectType customizations={customizations} autoFocus={autoFocus} valueIndex={index} depth={depth + 1}  onChange={onChange} label={label} property={p} shape={jsonObj} aliasesMap={aliasesMap} ontology={ontology} containers={containers} configurations={configurations}/>
    } else {
        return <Text autoFocus={autoFocus} valueIndex={index} key={uuid} label={label} property={p} onChange={onChange} customizations={customizations}/>
        //throw new Error('Unknown type '+JSON.stringify(p, null, 4))
    }
};

export function AddButton({onClick, tooltip, title, disabled, datatest}) {

    const StyledButton = withStyles(theme => ({
        root: {
            '&:hover': {
                backgroundColor: theme.palette.secondary.darkFade,
            },
        },
    }))(Button);

    const button = <StyledButton
            datatest={datatest || 'addButton'}
            disabled={disabled}
            onClick={onClick}
            color={"secondary"}
            variant={"text"}
        >{title || 'Add'}</StyledButton>;

    return (<div style={{display: 'flex', marginBottom: '4px', marginTop: '8px'}}>
        <div style={{flexGrow: 1}}/>
        {
            tooltip
                ? <Tooltip title={tooltip}><div>{button}</div></Tooltip>
                : button
        }
    </div>);
}


const StyledBlockActionButton = withStyles(theme => ({
    root: {
        color: theme.palette.grey.level3,
        marginLeft: '8px'
    },
    label : {
        textTransform: 'none',
        fontWeight : '400'
    }
}))(Button);

export function BlockActionButton({theme, onClick, title, endIcon, buttonProps}) {
    let colorStyle = { textTransform: 'none', paddingRight: '0px'}
    return <StyledBlockActionButton
        size={'small'}
        onClick={onClick}
        endIcon={endIcon}
        variant={'text'}
        {...buttonProps}
    >
        {title}
    </StyledBlockActionButton>;
}

class ArrayType extends React.Component {
    constructor(props) {
        super(props)
        let {property, autoFocusIndex} = props
        let keysToSet = []
        if(property.value && (isArray(property.value))) {
            property.value.forEach((v, i) => {
                keysToSet.push(uuid4())
            })
        } else {
            keysToSet.push(uuid4());
        }
        let autoFocusId = autoFocusIndex !== undefined ? keysToSet[autoFocusIndex] : undefined;

        this.state = {
            keys: [...keysToSet],
            expanded : true,
            autoFocusId
        }
    }

    componentDidMount() {
        let {property} = this.props
        if(property[ALIAS_SH_MIN_COUNT] === "1") {
            //this.addItem()
        }
        //property.onMock = this.onMock;
    }


    removeItem = (eventType, index) => {
        let {keys} = this.state;
        let {onChange} = this.props;
        if(eventType === DELETE_EVENT) {
            if (index !== undefined) {
                keys.splice(index, 1);
                this.setState({keys: keys})
            } else {
                this.setState({keys: []})
            }
        }
        onChange();
    }

    addItem = (value) => {
        let {keys} = this.state
        let {property, onChange} = this.props
        if(this.isArrayType(property)) {
            property.value[property.value.length] = "";
        } else {
            property.value = "";
        }
        let uuid = uuid4();
        let newKeys = [...keys, uuid];
        this.setState({keys: newKeys, autoFocusId : uuid});
        onChange();
    }

    isArrayType(property) {
        return !property[ALIAS_SH_MAX_COUNT] || Number(property[ALIAS_SH_MAX_COUNT]) > 1;
    }

    getAddButton = () => {
        let {customizations, property} = this.props;
        let objectShapeProperty = isObjectShapeProperty(property);

        return customizations && objectShapeProperty ? <AddButton
            tooltip={getUiLabelTranslationFrom(UI_LABELS_ADD_ID_OR_CREATE_TOOLTIP, customizations)}
            onClick={this.addItem}
            title={getUiLabelTranslationFrom(UI_LABELS_ADD_ID_OR_CREATE, customizations)}
        />
        : <AddButton onClick={this.addItem}/>;
    }

    setRef = (block) => {
        this.blockRef = block
    }


    render() {
        let {keys, expanded, autoFocusId, openLink} = this.state
        let {shape, depth, configurations, classes, theme, label, property, onChange, aliasesMap, customizations} = this.props
        let propertyArrayValue = toArray(property.value);
        let maxCount= computeMaxCount(property);
        let hideAddButton = maxCount && propertyArrayValue.length >= maxCount
            ? true
            : false;
        let objectShapeProperty = isObjectShapeProperty(property);
        return <div datatest={'arrayType'} ref={this.setRef}>
            <div style={{display : !expanded ? 'none' : 'block'}}>
                {
                    property.value !== undefined && ( isArray(property.value) ? property.value.map((v, i) => {
                        let uuid = keys[i];
                        let autoFocus = autoFocusId === uuid;
                        return <div datatest={'arrayTypeItem'} key={uuid}>
                            {getComponent(uuid, depth, i, shape, property, configurations, aliasesMap, this.removeItem , undefined, autoFocus, customizations)}
                        </div>;

                    })
                    : <React.Fragment key={property.value}>
                        {getComponent(property[ID], depth, undefined, shape, property, configurations, aliasesMap, this.removeItem, undefined, autoFocusId !== undefined, customizations)}
                        </React.Fragment>
                    )

                }
                <div style={{display : 'flex'}} key={openLink+"-"+keys.length}>
                    <div style={{flexGrow : '1'}}></div>
                    {
                        openLink &&
                        <LinkResourceDialog
                            mode={'create'}
                            onClose={() => this.setState({openLink : undefined})}
                            onSaveSuccess={(connectIds) => {
                                let newKeys = [...keys]
                                if(this.isArrayType(property)) {
                                    toArray(connectIds).forEach(v => {
                                        property.value.push(v);
                                        let uuid = uuid4();
                                        newKeys.push(uuid);
                                    });
                                } else {
                                    property.value = toArray(connectIds)[0]
                                    let uuid = uuid4();
                                    newKeys = [uuid];
                                }
                                onChange();
                                this.setState({keys: newKeys});
                                this.setState({openLink : undefined});
                            }}
                            sourceResource={{}}
                            linkProperty={aliasesMap[property[ALIAS_SH_PATH]] || property[ALIAS_SH_PATH]}
                            settings={customizations.settings}
                            ontology={customizations.ontology}
                            configurations={configurations}
                            aliasesMap={aliasesMap}
                            aliasesToIRIMap={customizations.aliasesToIRIMap}
                            browseLanguage={customizations.browseLanguage}
                            location={customizations.location}
                            shapeProperty={property}
                        />
                    }
                    {
                        customizations && customizations.hideSearchConnected !== true && objectShapeProperty && <AddButton datatest={'searchAndConnect'} tooltip={getUiLabelTranslationFrom(UI_LABELS_SEARCH_AND_CONNECT_TOOLTIP, customizations)} onClick={() => this.setState({openLink : true})} title={getUiLabelTranslationFrom(UI_LABELS_SEARCH_AND_CONNECT, customizations)}></AddButton>
                    }
                    {
                        hideAddButton || this.getAddButton()
                    }
                </div>
            </div>
        </div>;
    }
}

export default withStyles({}, {withTheme: true})(ArrayType);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../../components/styles";
import './APIPlayground.css';
import RequestResponse from "../../components/RequestResponse";
import {traceRequestRenderStart} from "../../components/Trace";


class DeleteRequest extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        this.syncDataWithBackend()
    }

    syncDataWithBackend = () => {
    }

    saveRequest = () => {
        let {onSave, requestObject} = this.props
        onSave(requestObject)
    }

    setDirty = () => {
        let {requestObject} = this.props
        if(!requestObject.dirty) {
            requestObject.dirty = true
            this.setState({})
        }
    }

    onModeChange = (obj) => {
        let {onRequestObjectChange} = this.props
        this.setDirty();
        //update state to ensure that components are re-rendered for new mode
        this.setState({}, () => (onRequestObjectChange(obj)))
    }

    getRequestResponse = () => {
        let {customizations, requestObject, registerSendAction, onRequestObjectChange} = this.props
        return <RequestResponse
            reqTabValue={0}
            requestObject={requestObject}
            onRequestObjectChange={ (obj) => {this.setDirty(); onRequestObjectChange(obj)}}
            onRequestSave={this.saveRequest}
            onModeChange={this.onModeChange}
            registerSendAction={registerSendAction}
            customizations={customizations}
        />;
    }

    render() {
        let {requestObject} = this.props
        traceRequestRenderStart(requestObject, 'DeleteRequest')

        return (<>
                {this.getRequestResponse()}
        </>);
    }

}

DeleteRequest.propTypes = {
    onSave: PropTypes.func,
    registerSendAction: PropTypes.func,
    exampleSetId: PropTypes.string,
    apiMode: PropTypes.object,
    aliasesMap: PropTypes.object,
    requestObject: PropTypes.object,
    onRequestObjectChange: PropTypes.func,
    customizations: PropTypes.object,

};

export default withStyles(styles, {withTheme: true})(DeleteRequest);

import React from "react";
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';

import {styles} from "../components/styles";
import {TextField as MUITextField} from "@material-ui/core";
import {GreyLockIcon} from "../components/GreyStyleIcon";
import {traceRenderStart} from "../components/Trace";
import VisibilityIcon from '@material-ui/icons/Visibility';

const COMPONENT = 'DescriptionField.jsx'

//this is to hide scrollbar without this global scrollbar styles add scroll
const MUITextFieldStyled = withStyles({
    root: {
        '& .MuiOutlinedInput-input::-webkit-scrollbar': {
            width : '0px'
        }
    }
})(MUITextField);

class DescriptionField extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            viewEditDescription: false
        }
    }

    setRef = (input) => {
        this.inputRef = input
    }

    render() {
        traceRenderStart("", COMPONENT)
        let {classes, theme, inputLabelProps, value, fullWidth, readOnly, onChange} = this.props;
        let {viewEditDescription} = this.state;

        let iconStyle = readOnly ? {marginRight : '4px'} : {}
        let endAdornments = [
            <VisibilityIcon
                style={iconStyle}
                key={'viewEditDescription'}
                className={classes.svgIcon}
                onClick={(ev) => {
                    ev.stopPropagation();
                    this.setState({viewEditDescription: !viewEditDescription}, () => this.inputRef.focus());
                }}
                fontSize={"small"}
            />
        ]
        if(readOnly) {
            endAdornments.push(<GreyLockIcon key={'readOnly'} theme={theme}/>);
        }
        let label = 'Description';
        return <MUITextFieldStyled
            id={'description'}
            datatest={'textareaField-'+label}
            margin={'dense'}
            multiline={true}
            rows={viewEditDescription ? 4 : 1}
            fullWidth={fullWidth}
            label={label}
            value={value}
            onChange={
                (event) => {
                    const {target: {value}} = event;
                    readOnly || onChange(value, event);
                }
            }
            inputProps={{
                ref: this.setRef,
                readOnly: readOnly
            }}
            InputProps={{
                endAdornment: endAdornments,
                readOnly: readOnly
            }}
            InputLabelProps={inputLabelProps}
        />;
    }
}

DescriptionField.propTypes = {
    value: PropTypes.string,
    fullWidth: PropTypes.bool,
    readOnly: PropTypes.bool,
    inputLabelProps: PropTypes.object,
    onChange: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(DescriptionField);

import React, {Component} from "react";
import {IconButton, Paper, TextField, Tooltip} from "@material-ui/core";
import {ForumOutlined, SendRounded} from "@material-ui/icons";
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import {withPermissions} from "../../service/permission-service";
import Fab from "@material-ui/core/Fab";
import {searchFeedback} from "./AddFeedbackDialog";
import {
    centerVertically,
    getDataContextURL,
    getDataValue,
    getPropertyName,
    getResourceId,
    getShaclResult,
    isBadRequestError,
    isRequestSuccessful,
    scrollToView,
    toArray
} from "../../components/util";
import FieldContainer from "../../components/FieldContainer";
import H3Title from "../../components/H3Title";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import FeedbackComment from "./FeedbackComment";
import {deleteResource} from "./WithObjectSummary";
import {renderBrowseLanguageSelect} from "./Workspace";
import {
    ALIAS_SH_RESULT_MESSAGE,
    ALIAS_SH_RESULT_PATH,
    AT_CONTEXT,
    AT_ID,
    AT_TYPE,
    AT_VALUE,
    EASYGRAPH_DATA_FEEDBACKSTATUS_NEW,
    GRAPH,
    ID,
    IRI_FEEDBACK_DATE_CREATED,
    IRI_FEEDBACK_OBJECT,
    IRI_FEEDBACK_PREDICATE,
    IRI_FEEDBACK_SUBJECT,
    IRI_TYPE_FEEDBACK,
    LANG,
    TYPE,
    XSD_DATETIME,
    XSD_STRING
} from "../../Constants";
import {getUserIri, getUsername} from "../common/Profile";
import {postGraph} from "../../service/graph-api";
import ErrorMessage from "../../components/ErrorMessage";
import {getBorderColor, StringValue} from "./Tree";
import BackendErrorDialog from "../../components/BackendErrorDialog";
import {
    getFeedbackBackgroundColor,
    getFeedbackBorderColor,
    isFeedbackEnabled,
    isFeedbackPlacementOnLeft
} from "./BasicSetup";
import ObjectNode from "./ObjectNode";

export const leftCommentsWidth = '384px';

class FeedbackPanel extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    refreshFeedback = () => {
        let {resource, aliasesMap} = this.props;
        const subject = resource && getResourceId(resource);
        searchFeedback(subject, undefined, aliasesMap).then(r => {
            this.setState({feedbackResult : r, feedbackResultError : undefined}, this.scrollToLastComment)
        }).catch(e => {
            this.setState({feedbackResultError : e})
        });
    }

    scrollToLastComment = () => {
        const {feedbackResult} = this.state;
        const rArray = toArray(feedbackResult);
        let len = rArray.length;
        if(len > 0) {
            let lastR = rArray[len - 1];
            const node = document.getElementById('scroll-mark');
            scrollToView(node, 'instant');
        }
    }

    submitFeedback = () => {
        const {feedbackValue, feedbackValueLang} = this.state;
        const { aliasesToIRIMap, browseLanguage, ontology, resource} = this.props;
        const resourceId = getResourceId(resource);
        let payload = {
            [AT_CONTEXT] : [getDataContextURL()],
            [GRAPH] : [{
                [AT_TYPE]: [ IRI_TYPE_FEEDBACK],
                "https://easygraph.graphifi.com/schema/feedback/createdBy": {
                    [AT_ID]: getUserIri()
                },
                "https://easygraph.graphifi.com/schema/feedback/status": {
                    [AT_ID]: EASYGRAPH_DATA_FEEDBACKSTATUS_NEW
                },
                "https://easygraph.graphifi.com/schema/feedback/displayName": {
                    [AT_TYPE]: XSD_STRING,
                    [AT_VALUE]: getUsername()
                },
                [IRI_FEEDBACK_DATE_CREATED]: {
                    [AT_TYPE]: XSD_DATETIME,
                    [AT_VALUE]: new Date().toISOString()
                },
                "https://easygraph.graphifi.com/schema/feedback/comment": {
                    ["@language"] : feedbackValueLang || 'en',
                    [AT_VALUE] : feedbackValue
                },
            }]
        }
        if(resourceId) {
            payload[GRAPH][0][IRI_FEEDBACK_SUBJECT] = {
                [AT_ID]: resourceId
            }
        }
        this.setState({loading : true});
        let payloadString = JSON.stringify(payload);
        return postGraph(payloadString).then(async (response) => {
            if(isRequestSuccessful(response)) {
                this.setState({feedbackValue : '', feedbackSuccess : true, loading: false, apiErrorResponse: undefined, apiError: false, errorMessageProvider : undefined});
                this.refreshFeedback();
            }  else if(isBadRequestError(response)) {
                let json = await response.json();
                this.setState({loading: false, errorMessageProvider : () => {
                        let shaclResult = getShaclResult(json);
                        let errors =  toArray(shaclResult).map((e, i) => {
                            let propertyName = getPropertyName(aliasesToIRIMap, e[ALIAS_SH_RESULT_PATH], ontology, browseLanguage);
                            return <div key={i}>
                                <ErrorMessage
                                    error={`'${propertyName}' : ${e[ALIAS_SH_RESULT_MESSAGE]?.en || e[ALIAS_SH_RESULT_MESSAGE]}`}/>
                            </div>;
                        });
                        return errors;
                    }});
            } else {
                this.setState({loading: false, apiErrorResponse: response, apiError: true});
            }
        });

    }

    renderData = (context) => {
        let { theme, location, parentObject, customizations, nodeValue,onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage, shapeProperty} = this.props;
        let {dataValue, propertyKey} = context;
        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '2px 8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.white.main
        }
        let ovo = this.state.objectValueResourceVO;

        const resourceId = dataValue[ID] || dataValue[AT_ID];
        return <div style={{width : '100%', marginBottom : '16px'}} datatest={'container-'+propertyKey}>
            <div
                datatest={'focusContainer-'+(propertyKey || '')}
                style={computedStyle}
            >
                {   resourceId ? <ObjectNode aliasesMap={aliasesMap} location={location} resourceId={resourceId} settings={settings} ontology={ontology} browseLanguage={browseLanguage} aliasesToIRIMap={aliasesToIRIMap}  ></ObjectNode>
                    : <StringValue settings={settings} theme={theme} value={getDataValue(dataValue)}
                                   langCode={dataValue[LANG]} datatype={dataValue[TYPE]}
                                   shapeProperty={shapeProperty} isImageType={false}/>
                }
            </div>

        </div>;

    }

    renderProperty = (context) => {
        let { theme, location, parentObject, nodeValue,onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage, shapeProperty} = this.props;

        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '0px 8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.border.main,

        }

        let propertyKey  = context.propertyKey;
        if(!propertyKey) {
            return <></>;
        }
        let propertyTitle = getPropertyName(aliasesToIRIMap, propertyKey, ontology, browseLanguage);


        return <>
            <div style={{width : '100%', marginBottom : '16px'}} datatest={'container-'+propertyKey}>
                <div
                    datatest={'focusContainer-'+(propertyKey || '')}
                    style={computedStyle}
                >
                    {
                        centerVertically(
                            <div datatest={'property-' + propertyKey}>
                                {
                                    propertyKey &&
                                    // DO not use H4Title as it cause issue with tooltip
                                    <Tooltip arrow={true} placement={'bottom-start'}
                                             title={aliasesToIRIMap[propertyKey] || propertyKey}>
                                        <Typography noWrap={true} component={'span'} variant="h4"
                                                    color={'primary'}>{propertyTitle}</Typography>
                                    </Tooltip>
                                }
                            </div>, {flexGrow: '1'}
                        )
                    }

                </div>
            </div>
            {
                context.dataValue && this.renderData(context)
            }
        </>;

    }

    renderSubject = (context) => {
        let { theme, resource, location, parentObject, nodeValue,onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage, shapeProperty} = this.props;

        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '0px 8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.border.main,

        }
        //resource is given then do not render resource in each comment
        if(resource) {
            return this.renderProperty(context);
        }

        return <>
            {
                context.resourceId && this.renderResource(context.resourceId)
            }
            {
                this.renderProperty(context)
            }
        </>;

    }

    renderResource = (resourceId) => {
        let { theme, resource, location, parentObject, nodeValue,onClose, classes, settings, aliasesToIRIMap, configurations, aliasesMap, ontology, browseLanguage, shapeProperty} = this.props;

        let borderConnectionColor = getBorderColor(theme);
        let computedStyle = {
            position: 'relative',
            minHeight : '32px',
            display: 'flex',
            padding: '0px 8px',
            borderStyle: 'solid',
            borderTopWidth: '1px',
            borderBottomWidth: '1px',
            borderRightWidth: '1px',
            borderLeftWidth: '3px',
            borderRadius: '4px',
            borderLeftColor: borderConnectionColor,
            borderRightColor: borderConnectionColor,
            borderTopColor: borderConnectionColor,
            borderBottomColor: borderConnectionColor,
            backgroundColor : theme.palette.border.main,

        }

        return <div style={{width: '100%', marginBottom: '16px'}} datatest={'container-' + resourceId}>
            <div
                datatest={'focusContainer-' + (resourceId || '')}
                style={computedStyle}
            >
                <ObjectNode aliasesMap={aliasesMap} location={location} resourceId={resourceId} settings={settings}
                            aliasesToIRIMap={aliasesToIRIMap} ontology={ontology} browseLanguage={browseLanguage}></ObjectNode>

            </div>
        </div>;
    }


    render() {
        const {feedbackResultError, feedbackResult, openComments, feedbackValue, feedbackValueLang} = this.state;
        const {onClick, onClose, onOpen, resource, location, configurations, theme, ontology, aliasesMap, aliasesToIRIMap, settings, browseLanguage, publishEvent, resourceValueObject} = this.props;

        if(isFeedbackEnabled(settings) === false) {
            return <></>;
        }

        let feedbackBGColor = getFeedbackBackgroundColor(settings);// '#f0f4fb';// theme.palette.grey.background;
        let feedbackBorderColor = getFeedbackBorderColor(settings);//'#e9f1fb'; //theme.palette.grey.level2;
        let top = 80;
        let chatHeight = 152 + (resource ? 40 : 0) + top;

        const fabStyle = {
            position: 'fixed',
            bottom: 16,
            zIndex: '1'
        };
        const paperStyle = {
            zIndex: '1',
            backgroundColor: feedbackBGColor,
            minWidth: leftCommentsWidth,
            maxWidth: leftCommentsWidth,
            marginLeft: '16px',
            position: 'fixed',
            right: '16px',
            top: top + 'px',
            height: '100%',
            borderLeftColor: feedbackBorderColor
        };
        if(isFeedbackPlacementOnLeft(settings)) {
            fabStyle.left = '16px';
            paperStyle.left = '6px';
            paperStyle.marginLeft = '6px';
        } else {
            fabStyle.right = '16px'
            paperStyle.right = '8px';
        }

        return <>
            {
                !openComments &&
                <Fab
                    size={'medium'}
                    variant={'round'}
                    style={fabStyle}
                    color="primary"
                    aria-label="feedback"
                    datatest={'feedbackFab'}
                    onClick={() => {
                        onOpen && onOpen();
                        this.setState({openComments: true}, this.refreshFeedback);
                    }}
                >
                    <ForumOutlined></ForumOutlined>
                </Fab>
            }
            {
                openComments && <Paper elevation={6}  datatest={'feedbackPaper'} style={paperStyle}>
                    <FieldContainer  style={{
                        padding : '0px',
                        backgroundColor: feedbackBGColor,
                        height: '100%',
                    }}>
                        <div datatest={'feedbackPaperTopHeader'} style={{ borderBottom : '1px solid '+feedbackBorderColor}}>
                        <div style={{display: 'flex', padding : '4px'}}>
                            <ForumOutlined color={'primary'}></ForumOutlined>
                            {
                                centerVertically(
                                    <H3Title title={'Feedback'}></H3Title>
                                    , {marginLeft : '4px'})
                            }
                            {centerVertically(<Typography variant={'body1'}>{toArray(feedbackResult).length}</Typography>, {marginLeft : '16px'})}
                            <div style={{flexGrow: '1'}}></div>
                            <Tooltip title={'Close'}>
                                <IconButton datatest={'closeFeedbackPaperButton'} size={'small'} onClick={() => {
                                    onClose && onClose();
                                    this.setState({openComments: undefined})
                                }}>
                                    <CloseIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                        {resource && <div style={{margin : '0px 16px'}}>{this.renderResource(getResourceId(resource))}</div>   }
                        </div>
                        <div style={{ height: `calc(100% - ${chatHeight  + 64}px)`, maxHeight: `calc(100% - ${chatHeight  + 64}px)`,  overflowY : 'auto', overflowX : 'auto', margin : '0px 3px', padding : '8px', paddingBottom : '0px', backgroundColor: theme.palette.white.main}}>
                            {
                                toArray(feedbackResult).map((f, index) => {
                                    const key = index + "-" +getResourceId(f);
                                    return <div datatest={'feedbackCommentDiv'} key={key} id={key} style={{}}>
                                        <div style={{height : '24px'}}></div>

                                            <FeedbackComment
                                                containerBackgroundColor={feedbackBGColor}
                                                containerBorderColor={feedbackBorderColor}
                                                compact={true}
                                                feedback={f}
                                                settings={settings}
                                                aliasesMap={aliasesMap}
                                                aliasesToIRIMap={aliasesToIRIMap}
                                                location={location}
                                                browseLanguage={browseLanguage}
                                                configurations={configurations}
                                                ontology={ontology}
                                                contextProvider={() => {
                                                    const subjectAlias = aliasesMap[IRI_FEEDBACK_SUBJECT];
                                                    const predicateAlias = aliasesMap[IRI_FEEDBACK_PREDICATE];
                                                    const objectAlias = aliasesMap[IRI_FEEDBACK_OBJECT];
                                                    const resourceId = f[subjectAlias];
                                                    return this.renderSubject({resourceId, propertyKey : f[predicateAlias], dataValue : f[objectAlias]});
                                                }}
                                                onDelete={async () => {
                                                    this.setState({loading : true});
                                                    let error = await deleteResource.call(this, getResourceId(f), publishEvent, f, settings, browseLanguage);
                                                    if(!error) {
                                                        this.refreshFeedback();
                                                    }
                                                    this.setState({loading : false})
                                                }}
                                            />
                                    </div>;
                                })
                            }
                            {feedbackResultError && <BackendErrorDialog handleClose={() => this.setState({ feedbackResultError:undefined})} open={true} error={feedbackResultError}/>}
                            <div style={{height : '8px'}} datatest={'scroll-mark'} id={'scroll-mark'}></div>
                        </div>
                        <div style={{borderTop : '1px solid', borderTopColor: feedbackBorderColor, padding : '16px', height : chatHeight+'px'}}>

                            <div style={{marginBottom : '16px'}}>
                                {
                                    renderBrowseLanguageSelect(settings, feedbackValueLang || browseLanguage,
                                        (ev) => {
                                            this.setState({feedbackValueLang: ev.target.value});
                                        }, undefined, undefined, 'feedbackCommentLanguage')
                                }
                            </div>
                            <TextField
                                datatest={'textareaField-feedback'}
                                value={feedbackValue}
                                label={'Feedback'}
                                placeholder={'Type your feedback and submit'}
                                autoFocus={true}
                                rows={3}
                                fullWidth={true}
                                style={{marginTop : '0px'}}
                                multiline={true}
                                variant={'outlined'}
                                InputProps={{
                                    endAdornment : <IconButton
                                        datatest={'submitFeedbackButton'}
                                        disabled={feedbackValue ? false : true}
                                        color={'secondary'}
                                        onClick={this.submitFeedback}
                                    ><SendRounded></SendRounded></IconButton>

                                }}
                                onChange={(ev) => {
                                    const {target: {name, value}} = ev;
                                    this.setState({feedbackValue : value});
                                }}
                            ></TextField>

                        </div>
                    </FieldContainer>
                </Paper>
            }

        </>;
    }
}

FeedbackPanel.propTypes = {
    settings: PropTypes.any,
    aliasesMap: PropTypes.any,
    aliasesToIRIMap: PropTypes.any,
    location: PropTypes.any,
    configurations: PropTypes.object,
    ontology: PropTypes.any,
    browseLanguage: PropTypes.any,
    resource: PropTypes.any,
    resourceValueObject: PropTypes.any,
    onOpen: PropTypes.any,
    onClose: PropTypes.any,
};

export default  withStyles({}, {withTheme: true})(withPermissions(FeedbackPanel));
